/** Enum of possible statuses of chargebee hosted page */
export enum ChargebeeHostedPageStatus {
  /** Indicates the hosted page is just created */
  CREATED = 'CREATED',
  /** Indicates the hosted page is requested by the website */
  REQUESTED = 'REQUESTED',
  /** Indicates the hosted page is successfully submitted by the user and response is sent to the return url */
  SUCCEEDED = 'SUCCEEDED',
  /** Indicates the page is cancelled by the end user after requesting it */
  CANCELLED = 'CANCELLED',
  /** Indicates the succeeded hosted page is acknowledged */
  ACKNOWLEDGED = 'ACKNOWLEDGED',
}
