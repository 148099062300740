import { ButtonBase, ButtonBaseProps, styled } from '@mui/material'
import { GRAY_100, GRAY_400 } from 'constants/styling/theme'
import React, { ReactNode } from 'react'

/** @typedef BorderType Available border type */
type BorderType = 'left' | 'right'

/**
 * @interface Props Input properties
 */
export interface Props extends ButtonBaseProps {
  /** Border type on input button - default as left */
  borderType?: BorderType
  /** Custom button content */
  children?: ReactNode
}

const StyledInputButton = styled(ButtonBase)(() => ({
  background: GRAY_100,
  borderLeft: `.1rem solid ${GRAY_400}`,
  padding: '.6rem .8rem',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '.8rem',
  backgroundColor: GRAY_100,
  height: '4rem',
  boxSizing: 'border-box',
}))

/**
 * @component MUI Input Field Button - Side button inside input field with custom content.
 * @example
 * <MUIInputFieldButton />
 */
export const MUIInputFieldButton: React.FC<Props> = ({
  borderType = 'left',
  sx,
  children,
  ...props
}) => {

  return (
    <StyledInputButton
      sx={{
        borderLeft: borderType === 'left' ? `.1rem solid ${GRAY_400}` : 'unset',
        borderRight: borderType === 'right' ? `.1rem solid ${GRAY_400}` : 'unset',
        ...sx
      }}
      {...props}
    >
      {children}
    </StyledInputButton>
  )
}
