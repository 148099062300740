import React, { MouseEvent, ReactNode } from 'react'
import { SxProps, Theme } from '@mui/material'

import Stack from '@mui/material/Stack'
import TotalCount from 'components/common/TotalCount/TotalCount'
import styles from './FilterTab.module.sass'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** The additional classes to append */
  className?: string
  /** Whether the tab is active or not */
  isActive?: boolean
  /** Whether the tab is disabled or not */
  isDisabled?: boolean
  /** The amount of orders displayed on the right side */
  totalCount?: string
  /** The tab label displayed beside the icon  */
  label: string | ReactNode
  /** The tab badge displayed on the right side  */
  badge?: ReactNode
  /** The icon displayed on the left side */
  icon?: ReactNode
  /** Onclick action to be called */
  onClick?: (e: MouseEvent) => unknown
  /** The tab height  */
  height?: string
  /** The tab radius  */
  radius?: string,
  /** Custom tab sx styles props */
  sx?: SxProps<Theme>,
}

/**
 * @component FilterTab to filter content on page
 * @example
 * <FilterTab
 *  onClick={e => console.log(e)}
 *  className="class"
 *  isActive={true}
 *  totalCount="23"
 *  label="Ongoing"
 *  icon=<Icon />
 * />
 */
export const FilterTab: React.FC<Props> = ({
  className = '',
  radius = '.8rem',
  height = '4.8rem',
  isActive = false,
  isDisabled = false,
  totalCount = '',
  sx,
  badge,
  icon,
  label,
  onClick,
}) => {
  if (isActive) className += ` ${styles.isActive}`
  if (isDisabled) className += ` ${styles.isDisabled}`

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      padding=".8rem 1.2rem"
      alignItems="center"
      gap=".6rem"
      sx={sx}
      borderRadius={radius}
      height={height}
      className={`${styles.filterTab} ${className}`.replace(/\s+/igm, ' ').trim()}
      onClick={e => onClick && onClick(e)}
    >
      <div className={styles.iconLabelWrap}>
        {icon &&
          <div className={styles.icon}>{icon}</div>
        }
        <div>{label}</div>
      </div>
      {!!totalCount && <TotalCount totalCount={totalCount} isActive={isActive} />}
      {!!badge && badge}
    </Stack>
  )
}

export default FilterTab
