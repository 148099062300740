import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { ActionStopAutomatedOrder } from './stopAutomatedOrder.actions'
import { StopAutomatedOrderStore } from './stopAutomatedOrder.interfaces'
import { cloneDeep } from 'lodash'

/** Redux data reducer for stop automated order */
export const reduceStopAutomatedOrder = (state: StopAutomatedOrderStore, action: ActionStopAutomatedOrder) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.STOP_AUTOMATED_ORDER) return state

  const { request, orderId } = action.payload
  const newState = cloneDeep(state)

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
    case ActionTypeAPIEvent.FETCH:
    case ActionTypeAPIEvent.RECEIVED:
      newState[orderId] = request
      return newState
  }
}
