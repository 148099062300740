import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { Endpoint, RoleMimetype } from 'constants/API'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, put, takeEvery } from 'redux-saga/effects'

import API from 'utils/API/API'
import { ActionUserMeta } from '.'

/** Saga which fetches user meta */
export function* userMetaSaga(receivedAction: ActionUserMeta) {
  const url = Endpoint.USER_META
  const action: ActionUserMeta = yield generalFetch(ActionTypeAPIData.USER_META, () => API.get(url, {
    headers: {
      Accept: RoleMimetype.CLIENT, // TODO: Fix on BE so we can send proper accept header
    },
  }, {
    endpoint: Endpoint.USER_META,
  }))

  const userMetaAction: ActionUserMeta = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }

  yield put(userMetaAction)
}

/** Watcher of user meta actions */
export function* userMetaWatcher() {
  yield all([
    takeEvery((action: ActionUserMeta) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.USER_META]), userMetaSaga),
  ])
}
