import './NotLogged.sass'

import i18n, { transformLanguageToAuth0Value } from 'translations/i18n'

import Button from 'components/common/Button/Button'
import { Link } from 'react-router-dom'
import { PageTransition } from 'utils/animations'
import { logAnalyticsEvent } from 'utils/analytics'
import { useAuth0 } from 'utils/auth'
import { useTranslation } from 'react-i18next'

const NotLogged: React.FC = () => {
  const { loginWithRedirect } = useAuth0()
  const { t } = useTranslation(['notlogged', 'authentication'])
  const params = new URLSearchParams(window.location.search)
  return (
    <PageTransition>
      <div className="page triangle-stripe-bg notlogged">
        <div className="page-content">
          <div className="wrap">
            <div className="content">
              <h1>{t('title')}</h1>
              <p>{t('text')}</p>
              <div className="input-group">
                <Button type="primary" onClick={() => {
                  logAnalyticsEvent('clicks_login', { location: 'NotLogged' })
                  loginWithRedirect({
                    mode: 'login',
                    language: transformLanguageToAuth0Value(i18n.language),
                    login_hint: params.has('userEmail') ? params.get('userEmail') ?? undefined : undefined,
                    appState: { targetUrl: window.location.pathname },
                  })
                }}>
                  {t('authentication:log_in')}
                </Button>
                <Button type="secondary" onClick={() => {
                  logAnalyticsEvent('clicks_sign_up', { location: 'NotLogged' })
                  loginWithRedirect({
                    mode: 'signUp',
                    language: transformLanguageToAuth0Value(i18n.language),
                    login_hint: params.has('userEmail') ? params.get('userEmail') ?? undefined : undefined,
                    appState: { targetUrl: window.location.pathname },
                  })
                }}>
                  {t('authentication:sign_up')}
                </Button>

                <Link to="/">
                  <Button
                    type="link orange"
                  >
                    {t('button')}
                  </Button>
                </Link>

              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTransition>
  )
}

export default NotLogged
