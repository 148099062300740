import { DealDTO, DealDTOAdministrator, DealDTOClient } from 'models/deal'

import { AssignmentDTO } from 'models/assignment'

export function DealDTOIsClientDTO(dealDTO: DealDTO): dealDTO is DealDTOClient {
  return (
    (dealDTO as DealDTOClient).feePrice !== undefined &&
    (dealDTO as DealDTOClient).missions[0] !== undefined &&
    (dealDTO as DealDTOAdministrator).missions[0].assignmentAutomationStatus === undefined
  )
}

export function DealDTOIsAdministratorDTO(dealDTO: DealDTO): dealDTO is DealDTOAdministrator {
  return (
    (dealDTO as DealDTOAdministrator).missions[0] !== undefined &&
    (dealDTO as DealDTOAdministrator).missions[0].assignmentAutomationStatus !== undefined
  )
}

export function DealDTOOrAssignmentDTOIsDealDTO(unknownDTO: DealDTO | AssignmentDTO): unknownDTO is DealDTO {
  return (
    (unknownDTO as DealDTO).missions !== undefined
  )
}
