/** Links to Google Form used for cancelling subscription */
export enum CancelSubscriptionLinks {
  EN = 'https://docs.google.com/forms/d/e/1FAIpQLSfWv_-ubSaAbrIk9fGjZ4X5eWxhP02W5mvRCUfsZ2cbJluuvQ/viewform?usp=sf_link',
  DE = 'https://docs.google.com/forms/d/e/1FAIpQLScji8zgBOwiuyMdPC8cI2iTq0uvTEbrXhJfUjTgSooOtkYsHQ/viewform?usp=sf_link',
  FR = 'https://docs.google.com/forms/d/e/1FAIpQLSdd6P14wX1nHNEPj86bs-_MYMymoiIyjKGTG7Ut2pJn0pIBtg/viewform?usp=sf_link',
  ES = 'https://docs.google.com/forms/d/e/1FAIpQLSdKf-3QWhe2RGscVnrsHrXzUCzNvluH1eI6szqt0KVQNu0mjQ/viewform?usp=sf_link',
  NL = 'https://docs.google.com/forms/d/e/1FAIpQLSfAyjOIGvf8VUp742_Gn5j2j808nATvp2Nlv0LJURgznHoTIQ/viewform?usp=sf_link',
}

/** Links to Google Form used for deleting a user */
export enum DeleteUserLinks {
  EN = 'https://docs.google.com/forms/d/e/1FAIpQLSepzyQWGY7m8IybivWyYL18IOaunJylOB7qJO5MGBti6zLGgg/viewform?usp=sf_link',
  DE = 'https://docs.google.com/forms/d/e/1FAIpQLSc3jCAtC1thG-wvc_HqOArUSjdQn-1IdE3tQ_I6fvgW-L_UhQ/viewform?usp=sf_link',
  FR = 'https://docs.google.com/forms/d/e/1FAIpQLSfkH0YXHFinYqyE1uy-rNf-kwDlAcy3ywdmsEZPbzAgs43B1g/viewform?usp=sf_link',
  ES = 'https://docs.google.com/forms/d/e/1FAIpQLSe0KCB9jb8_JPCAjPsHU7y6H86HI7A1VmUaW0M3jnzF_aT93w/viewform?usp=sf_link',
  NL = 'https://docs.google.com/forms/d/e/1FAIpQLSfuueUXJh4Hg1V7Cawm3wAnGrKEyFkaEfMl3MUfcQOAMRpNbA/viewform?usp=sf_link',
}
