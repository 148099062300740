import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { ActionRequest } from 'models/redux'
import { createAPIAction } from 'redux/Helpers'
import { DownloadArchiveStoreItem } from './downloadArchive.interfaces'

/** Describes general action to get download link for visuals archive archive action */
export interface ActionDownloadArchiveVisuals extends ActionRequest {
  payload: {
    jobId: string
    missionId: string
    isAdmin: boolean
    request: DownloadArchiveStoreItem
  },
}

/** Creates an action to get archive (zip/rar) download link */
export function downloadArchiveVisuals(jobId: string, missionId: string, isAdmin = false): ActionDownloadArchiveVisuals {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.DOWNLOAD_ARCHIVE], {
    jobId,
    missionId,
    isAdmin,
    request: new APIRequest()
  })
}

/** Resets an action to get archive (zip/rar) download link */
export function resetDownloadArchiveVisuals(jobId: string, missionId: string, isAdmin = false): ActionDownloadArchiveVisuals {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.DOWNLOAD_ARCHIVE], {
    jobId,
    missionId,
    isAdmin,
  })
}
