import React, { Fragment, useMemo, useState } from 'react'

import { ClientType } from 'models/user'
import { MUIButton } from 'components/common/MUIButton'
import { PreferredPaymentMethodSelect } from '../PreferredPaymentMethodSelect'
import { ReceiptEmailInput } from 'components/common/ReceiptEmailInput'
import { STRIPE_PRIMARY_PRODUCT_KINDS } from 'constants/payments'
import { VATNumberPopup } from '../VATNumberPopup'
import { useAuth0 } from 'utils/auth'
import { useAvailablePaymentMethods } from '../_main/contexts'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @component User Account details displayed in Profile page
 * @example
 * <AccountDetails userData={userMeRequest.data} />
 */
export const AccountDetails: React.FC = () => {
  const { t } = useTranslation(['profile', 'country', 'topbar'])
  const { user, roles } = useAuth0()

  const { organizationData, clientData, baseUserData } = useUserData()

  const { availablePaymentMethods } = useAvailablePaymentMethods()

  const userHasStripePaymentMethod = useMemo(() => availablePaymentMethods.some((method) => STRIPE_PRIMARY_PRODUCT_KINDS.has(method)), [availablePaymentMethods])
  const userHasMultipleAvailablePaymentMethods = useMemo(() => availablePaymentMethods.length > 1, [availablePaymentMethods])

  const [isVATNumberPopupOpen, setIsVATNumberPopupOpen] = useState<boolean>(false)

  if (!user) return <Fragment></Fragment>

  return (
    <Fragment>
      <h2 className="decorated">{t('account_details')}</h2>
      <div className="section">
        <div className="input-group">
          <label htmlFor="email">{t('email.label')}</label>
          <input type="email" name="email" id="email" placeholder={t('email.placeholder')} value={user.email || ''} disabled />
        </div>
        <div className="input-group">
          <label htmlFor="phone">{t('phone.label')}</label>
          <input type="tel" name="phone" id="phone" placeholder={t('phone.placeholder')} value={baseUserData?.phone || ''} disabled />
        </div>
        <div className="input-group">
          <label htmlFor="name">{t('name.label')}</label>
          <input type="text" name="name" id="name" placeholder={t('name.placeholder')} value={baseUserData?.name || ''} disabled />
        </div>
        {!!clientData &&
          <Fragment>
            <div className="input-group">
              <label htmlFor="organization_name">{t('organization_name.label')}</label>
              <input type="text" name="organization_name" id="organization_name" placeholder={t('organization_name.placeholder')} value={organizationData?.name || ''} disabled />
            </div>
            <div className="input-group">
              <label htmlFor="billing_address">{t('billing_address.label')}</label>
              <input type="text" name="billing_address" id="billing_address" placeholder={t('billing_address.placeholder')} value={organizationData?.billingAddress || ''} disabled />
            </div>
          </Fragment>
        }
        <div className="input-group">
          <label htmlFor="country">{t('country.label')}</label>
          <input type="text" name="country" id="country" placeholder={t('country.placeholder')} value={(t(`country:${(baseUserData?.country || '').toUpperCase()}`) || '').toString()} disabled />
        </div>
        {!!clientData && clientData.clientType === ClientType.B2C &&
          <div className="input-group">
            <label htmlFor="vat_number">{t('vat_number.label')}</label>
            {clientData.vatNumber ?
              <input type="text" name="vat_number" id="vat_number" placeholder={t('vat_number.placeholder')} value={clientData.vatNumber} disabled />
              :
              <div className="button-wrapper">
                <MUIButton
                  type="secondaryBorder"
                  fullWidth
                  onClick={e => setIsVATNumberPopupOpen(true)}
                >
                  {t('vat_number.add')}
                </MUIButton>
                <span className="error-message">{t('vat_number.text')}</span>
              </div>
            }
            <VATNumberPopup
              isOpen={isVATNumberPopupOpen === true}
              onClickOutside={() => setIsVATNumberPopupOpen(false)}
              onClickClose={() => setIsVATNumberPopupOpen(false)}
              callbackAfterSuccessfulResponse={() => setIsVATNumberPopupOpen(false)}
            />
          </div>
        }
        {roles.isClient && userHasStripePaymentMethod &&
          <div className="input-group">
            <ReceiptEmailInput
              useStripeStyling={false}
            />
          </div>
        }
        {roles.isClient && userHasMultipleAvailablePaymentMethods &&
          <div className="input-group">
            <PreferredPaymentMethodSelect />
          </div>
        }
      </div>
    </Fragment>
  )
}
