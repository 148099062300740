import {
  EditorOptionLogoPreview,
  EditorOptionLogoUploader,
  EditorOptionPositionning,
  EditorOptionWatermarkSection
} from 'components/pages/VisualsEditing/EditorOptions/EditorOptionWatermark'
import React, { Fragment } from 'react'

import { APIRequestState } from 'constants/API'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import Button from 'components/common/Button/Button'
import Collapse from '@mui/material/Collapse'
import { EditorOption } from '../EditorOption'
import { EditorOptionButton } from 'components/pages/VisualsEditing/EditorOptions/EditorOptionButton'
import { MUIDivider } from 'components/common/MUIDivider'
import { Size } from 'constants/misc/editingOptions'
import classnames from 'classnames'
import styles from './EditorOptionWatermark.module.sass'
import { useTranslation } from 'react-i18next'
import { useVisualsEditing } from 'components/pages/VisualsEditing/VisualsEditing.context'

/**
 * @component EditorOptionWatermark 
 * @example
 * <EditorOptionWatermark />
 */
export const EditorOptionWatermark: React.FC = () => {
  const { t } = useTranslation(['visuals_editing'])

  const {
    isWatermarkActive,
    setIsWatermarkActive,
    selectedWatermarkOpacity,
    setSelectedWatermarkOpacity,
    selectedWatermarkSize,
    setSelectedWatermarkSize,
    resetWatermarkContext,
    logEditEvent,
    productParams,
    uploadedWatermarkLogo,
    uploadedWatermarkLogoResponse,
    handleEditingOptionChange
  } = useVisualsEditing()

  const isSelected = (key: string, enumType: any, selected: string) => Object.keys(enumType)[Object.values(enumType).indexOf(selected)] === key

  return (
    <EditorOption
      title={t('editor_options.watermark_option_title')}
      isActive={isWatermarkActive}
      onResetClick={resetWatermarkContext}
      isResetDisabled={uploadedWatermarkLogoResponse.request?.state === APIRequestState.RUNNING}
      button={
        <EditorOptionButton
          title={t('editor_options.add_logo')}
          icon={<AddOutlinedIcon fontSize='inherit' />}
          isActive={isWatermarkActive}
          onClick={() => {
            logEditEvent('Start logo bulk editing', productParams)
            handleEditingOptionChange(() => setIsWatermarkActive(true))
          }}
        />
      }
    >
      <Collapse in={isWatermarkActive}>
        <div className={styles.editorOptionWatermark}>

          {!uploadedWatermarkLogo?.signedURL && <EditorOptionLogoUploader />}

          {!!uploadedWatermarkLogo?.signedURL &&
            <Fragment>
              <EditorOptionLogoPreview image={uploadedWatermarkLogo.signedURL} />
              <EditorOptionWatermarkSection title={t('visuals_editing:editor_options:watermark_option_size:title')}>
                <div className={styles.sizeButtonContainer}>
                  {(Object.keys(Size) as (keyof typeof Size)[]).map(
                    (key, idx) => {
                      const capitalizedSizeTitle = Object.values(Size)[idx].charAt(0).toUpperCase() + Object.values(Size)[idx].slice(1).toLowerCase()
                      return (
                        <Button
                          key={idx}
                          type="secondary noborder"
                          className={classnames(styles.sizeButton, { [styles.selected]: isSelected(key, Size, selectedWatermarkSize) })}
                          onClick={() => handleEditingOptionChange(() => setSelectedWatermarkSize(Size[key]))}
                        >
                          {capitalizedSizeTitle}
                        </Button>
                      )
                    }
                  )}
                </div>
              </EditorOptionWatermarkSection>

              <EditorOptionWatermarkSection title={t('visuals_editing:editor_options:watermark_option_opacity')}>
                <div className={styles.opacity}>
                  <input
                    id="watermark-opacity"
                    name="watermark-opacity"
                    type="number"
                    min="0"
                    max="100"
                    value={selectedWatermarkOpacity.toString()}
                    onChange={(e) => {
                      const value = Math.abs(Number(e.currentTarget.value))
                      if (value > 100) return
                      handleEditingOptionChange(() => setSelectedWatermarkOpacity(value))
                    }}
                  />
                </div>
              </EditorOptionWatermarkSection>

              <EditorOptionWatermarkSection title={t('visuals_editing:editor_options:watermark_option_position:title')}>
                <EditorOptionPositionning isSelected={isSelected} />
              </EditorOptionWatermarkSection>
            </Fragment>
          }
        </div>
      </Collapse>
      <MUIDivider margin={16} />
    </EditorOption>
  )
}
