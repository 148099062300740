import { FC, Fragment, useMemo } from 'react'
import { bigFromFee, formatPrice, valueAfterDiscount } from 'utils/price'

import { FeeDTO } from 'models/fee'
import styles from './InstructionPrice.module.sass'
import { usePurchaseFlowConfig } from 'components/pages/PurchaseFlow/_main/contexts'

interface Props {
  feePrice: FeeDTO
}

/**
 * Displays formatted price of instruction including organization discount and currency.
 * 
 * DEPENDENCIES:
 * - InstructionStepStoreProvider
 * 
 * @example
 * <InstructionPrice feePrice={instructionOption.feePrice} />
 */
export const InstructionPrice: FC<Props> = ({ feePrice }) => {

  const { catalogueCurrency, catalogueDiscount } = usePurchaseFlowConfig()
  const priceAfterDiscount = useMemo(() => valueAfterDiscount(feePrice, catalogueDiscount), [catalogueDiscount, feePrice])

  if (bigFromFee(feePrice).eq(0)) return <hr />

  return (
    <Fragment>
      {catalogueDiscount.gt(0) &&
        <span className={`${styles.lineThrough} ${styles.grayText}`}>{formatPrice(feePrice, catalogueCurrency)}</span>
      }
      <strong>{formatPrice(priceAfterDiscount, catalogueCurrency)}</strong>
    </Fragment>
  )

}
