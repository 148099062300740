import { AssignmentCreativeCardState, useCreativeAssignmentCard } from '.'
import { AssignmentStage, CardColor } from 'constants/assignment'
import { CORAL_600, NO_RED, OCEAN_BLUE, YES_GREEN } from 'constants/styling/theme'
import { Divider, Stack } from '@mui/material'
import { Edit, PersonRemove } from '@mui/icons-material'
import { FC, Fragment, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { bigFromFee, formatPrice } from 'utils/price'
import { useAdminRemoveCreative, useAutomateAssignment, useStopAutomatedAssignment } from 'dataQueries'
import { useGalleryAssignment, useGalleryDeal } from '../../_main/contexts'

import { AssignCreativePopup } from 'components/common/AssignCreativePopup/AssignCreativePopup'
import { AssignmentDTOIsAdministratorDTO } from 'utils/typeguards'
import Button from 'components/common/Button/Button'
import CallIcon from '@mui/icons-material/Call'
import { Color } from 'constants/assets'
import EmailIcon from '@mui/icons-material/Email'
import { GalleryUnavailableCreativesPopup } from './GalleryUnavailableCreativesPopup'
import { KeyboardEventKey } from 'constants/misc'
import { LinkifyWrapper } from 'components/common/LinkifyWrapper/LinkifyWrapper.module'
import PeopleIcon from '@mui/icons-material/People'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { QueryStatus } from 'components/common/QueryStatus'
import ReactLoading from 'react-loading'
import RefreshIcon from '@mui/icons-material/Refresh'
import StopIcon from '@mui/icons-material/Stop'
import classnames from 'classnames'
import moment from 'moment-timezone'
import { multilineText } from 'utils/formatting'
import styles from './CreativeAssignmentCard.module.sass'
import { useActionPopup } from 'utils/hooks'
import { useAuth0 } from 'utils/auth'
import { useChangeRemunerationDrawer } from '../ChangeRemunerationDrawer'

enum PriceSignType {
  POSITIVE = '+',
  NEGATIVE = '-',
}

const statesUnlockingCreativeCardInformation = new Set([
  AssignmentCreativeCardState.CT_ACCEPTED,
  AssignmentCreativeCardState.CT_DELIVERED,
  AssignmentCreativeCardState.WAITING_FOR_ACCEPT,
])

const statesUnlockingAssignCreativeCard = new Set([
  AssignmentCreativeCardState.ASSIGN_MANUALLY,
  AssignmentCreativeCardState.ASSIGN_AUTOMATION,
])

const statesUnlockingChangeCT = new Set([
  AssignmentCreativeCardState.ASSIGN_MANUALLY,
  AssignmentCreativeCardState.CT_ACCEPTED,
  AssignmentCreativeCardState.WAITING_FOR_ACCEPT,
])

const cardColorMap: Record<CardColor, string> = {
  [CardColor.BLUE]: OCEAN_BLUE,
  [CardColor.GREEN]: YES_GREEN,
  [CardColor.RED]: NO_RED,
  [CardColor.ORANGE]: CORAL_600,
}

export const CreativeAssignmentController: FC = () => {

  const { roles } = useAuth0()
  const { dealId } = useGalleryDeal()
  const { openChangeRemunerationDrawer } = useChangeRemunerationDrawer()
  const { showConfirm, showAlert } = useActionPopup()
  const { t } = useTranslation(['gallery', 'assignment_creative_card_state', 'change_ct', 'remove_ct', 'actions', 'common', 'creatives_unavailable_list_popup'])
  const adminRemoveCreative = useAdminRemoveCreative()

  const {
    assignment,
    assignmentData,
  } = useGalleryAssignment()

  const {
    creativeCardState,
    cannotAssignmentBeAutomatedInfo,
    canStopAutomation,
    canAutomateOrder,
    isAssignmentAutomatedFromOrder,
    firstOutreachedCreative,
    noCTFound,
    assignmentScheduledByCT,
  } = useCreativeAssignmentCard()

  const [assignCreativePopup, setAssignCreativePopup] = useState<boolean>(false)
  const [isUnavailableListPopupOpen, setUnavailableListPopupOpen] = useState<boolean>(false)

  const automateAssignment = useAutomateAssignment()

  const cardColor: CardColor = useMemo(() => {
    if (!creativeCardState) return CardColor.ORANGE

    switch (creativeCardState) {
      case AssignmentCreativeCardState.ASSIGN_AUTOMATION:
      case AssignmentCreativeCardState.WAITING_FOR_ACCEPT:
      case AssignmentCreativeCardState.WAITING_FOR_AUTOMATION:
        return CardColor.BLUE

      case AssignmentCreativeCardState.ASSIGN_MANUALLY:
        return CardColor.ORANGE

      case AssignmentCreativeCardState.CT_DELIVERED:
      case AssignmentCreativeCardState.CT_ACCEPTED:
        return CardColor.GREEN

      default:
        return CardColor.BLUE
    }

  }, [creativeCardState])

  const ctMarginSign = useMemo(() => {
    if (!firstOutreachedCreative) return ''

    const marginAsBig = bigFromFee(firstOutreachedCreative.margin)

    if (marginAsBig.gt(0)) return PriceSignType.POSITIVE
    if (marginAsBig.lt(0)) return PriceSignType.NEGATIVE
    return ''
  }, [firstOutreachedCreative])

  const canChangeAndRemoveCT = useMemo(() => {
    if (!assignmentData || !AssignmentDTOIsAdministratorDTO(assignmentData)) return false
    if (!creativeCardState || !statesUnlockingChangeCT.has(creativeCardState)) return false
    if (canStopAutomation) return false
    if (isAssignmentAutomatedFromOrder && creativeCardState === AssignmentCreativeCardState.WAITING_FOR_ACCEPT) return false
    return true
  }, [assignmentData, creativeCardState, canStopAutomation, isAssignmentAutomatedFromOrder])

  const handleRemoveCT = useCallback(async () => {
    if (!assignmentData || !AssignmentDTOIsAdministratorDTO(assignmentData)) return

    const confirmResponse = await showConfirm(
      <Trans t={t} i18nKey="remove_ct:confirm_text">&nbsp;</Trans>,
      {
        title: t('remove_ct:title'),
        confirmAcceptText: t('remove_ct:proceed')
      }
    )

    if (!confirmResponse) return

    adminRemoveCreative.mutate({ assignmentId: assignmentData.id, dealId })
  }, [adminRemoveCreative, assignmentData, dealId, showConfirm, t])

  const handleChangeCTPopupOpen = useCallback(async () => {
    if (!assignmentData || !AssignmentDTOIsAdministratorDTO(assignmentData)) return

    // If shooting start is in past, throw alert and require admin to reschedule
    if (assignmentData.timezone && moment(assignmentData.shootingStartDateTime).tz(assignmentData.timezone).isBefore(new Date())) {
      await showAlert(
        <Trans parent="p" t={t} i18nKey="change_ct:change_expired_warning">&nbsp;</Trans>,
        { title: t('change_ct:change_expired_title') }
      )
      return
    }

    // Set correct translation key based on current state of CT
    let transKey = 'change_ct:change_warning'
    if (assignmentData.stage === AssignmentStage.CREATIVE_BOOKED) transKey = 'change_ct:change_accepted_warning'

    // Show confirm popup and store response
    const confirmResponse = await showConfirm(
      <Trans parent="p" t={t} i18nKey={transKey}>&nbsp;</Trans>,
      {
        title: t('change_ct:change_title'),
        confirmAcceptText: t('change_ct:proceed')
      }
    )

    // Exit if confirm denied
    if (!confirmResponse) return

    setAssignCreativePopup(true)

  }, [assignmentData, showAlert, showConfirm, t])

  const stopAutomatedAssignment = useStopAutomatedAssignment()

  const handleStopAutomation = useCallback(async () => {
    if (!assignmentData || !AssignmentDTOIsAdministratorDTO(assignmentData)) return

    const confirmResponse = await showConfirm(
      <Trans t={t} i18nKey="gallery:stop_automated_ct:confirm_text">&nbsp;</Trans>,
      {
        title: t('gallery:stop_automated_ct:confirm_title'),
        confirmAcceptText: t('gallery:stop_automated_ct:proceed'),
      }
    )
    if (!confirmResponse) return

    stopAutomatedAssignment.mutate({ assignmentId: assignmentData.id, orderId: dealId })

  }, [assignmentData, dealId, showConfirm, stopAutomatedAssignment, t])

  const handleRefreshAssignment = useCallback(() => {
    if (!assignmentData || !AssignmentDTOIsAdministratorDTO(assignmentData)) return
    assignment.refetch()
  }, [assignment, assignmentData])

  if (!roles.isAdmin || !assignmentData || !creativeCardState) return <Fragment></Fragment>

  return (
    <Fragment>
      {statesUnlockingAssignCreativeCard.has(creativeCardState) &&
        <div className={styles[cardColor]}>

          <Stack>

            {/** BUTTON CREATIVES UNAVAILABLE */}
            <Button
              className={styles.underlinedButton}
              type='secondary nobackground noborder'
              onClick={() => setUnavailableListPopupOpen(true)}
            >
              {t('gallery:creatives_unavailable_list_popup:show_list_button')}
            </Button>

            {/** Choose creative information */}
            {creativeCardState === AssignmentCreativeCardState.ASSIGN_MANUALLY &&
              <div className={styles.headerWrapper}>
                <h2>{t('choose_creative')}</h2>
                <p>
                  {cannotAssignmentBeAutomatedInfo || t(`assignment_creative_card_state:${creativeCardState}`)}
                </p>
              </div>
            }

          </Stack>


          {/* AUTOMATION POSSIBLE BODY */}
          {creativeCardState === AssignmentCreativeCardState.ASSIGN_AUTOMATION &&
            <div className={styles.headerWrapper}>

              <h3 className={styles.automationHeading}>
                {t('creative_assignment_card:automation_heading')}
              </h3>

              <div className={classnames(
                styles.firstOutreach,
                { [styles.isCenter]: !firstOutreachedCreative }
              )}>

                {!firstOutreachedCreative && !noCTFound &&
                  <ReactLoading className={styles.spinner} color={Color.GRAY_TEXT} type="spin" />
                }

                {noCTFound &&
                  <span className={styles.noCT}>
                    {t('creative_assignment_card:no_ct_Found')}
                  </span>
                }

                {!!firstOutreachedCreative &&
                  <Fragment>

                    <div className={styles.columnWrapper}>

                      <h3>{firstOutreachedCreative.name}</h3>
                      <div className={styles.ctInfo}>
                        {!!firstOutreachedCreative.email &&
                          <a href={`mailto:${firstOutreachedCreative.email}`} className={classnames(styles.iconsWrapper, styles.textGap)}>
                            <EmailIcon />
                            <span className={styles.text}>{firstOutreachedCreative.email}</span>
                          </a>
                        }

                        {!!firstOutreachedCreative.phone &&
                          <a href={`tel:${firstOutreachedCreative.phone}`} className={classnames(styles.iconsWrapper, styles.textGap)}>
                            <CallIcon />
                            <span className={styles.text}>{firstOutreachedCreative.phone}</span>
                          </a>
                        }
                      </div>

                    </div>

                    <div className={classnames(
                      styles.ctMargin,
                      {
                        [styles.positive]: ctMarginSign === PriceSignType.POSITIVE,
                        [styles.negative]: ctMarginSign === PriceSignType.NEGATIVE,
                      }
                    )}>
                      {/** Only display the sign for POSITIVE value as NEGATIVE is already included in the margin price */}
                      {ctMarginSign === PriceSignType.POSITIVE ? ctMarginSign : null} {formatPrice(firstOutreachedCreative.margin)}
                    </div>

                  </Fragment>
                }

              </div>

            </div>
          }

          {/** Card Footer */}
          {!canAutomateOrder &&
            <div className={classnames(styles.flexWrapper, styles.spaceBetween)}>

              {/** Automation button */}
              {creativeCardState === AssignmentCreativeCardState.ASSIGN_AUTOMATION &&
                <Fragment>

                  <Button
                    textAndIcon={true}
                    className={styles.primary}
                    onClick={async e => await showConfirm(t('automate_assignment_prompt')) && automateAssignment.mutate({ assignmentId: assignmentData.id, orderId: dealId })}
                  >
                    <PlayArrowIcon fontSize='large' />
                    <span>{t('automate_assignment')}</span>
                  </Button>

                  <span>{t('creative_assignment_card:or')}</span>

                </Fragment>
              }

              {/** Manually assign CT button */}
              <Button
                textAndIcon={true}
                disabled={!assignmentScheduledByCT && !assignmentData.shootingStartDateTime}
                className={creativeCardState === AssignmentCreativeCardState.ASSIGN_MANUALLY ? styles.primary : styles.secondary}
                onClick={() => setAssignCreativePopup(true)}
              >
                <PeopleIcon fontSize='large' />
                <span>{t('manually_assign_creative')}</span>
              </Button>

            </div>
          }

        </div>
      }

      {/** Automation is in process of pairing CT, needed to manually refresh the page to show results */}
      {creativeCardState === AssignmentCreativeCardState.WAITING_FOR_AUTOMATION &&
        <div className={styles['blue']}>

          <span className={styles.smallHeader}>
            {t(`assignment_creative_card_state:${creativeCardState}`)}
          </span>

          <div className={styles.automatedWrapper}>
            <p>{t('gallery:automation_in_process_card:description')}</p>
            <Button
              textAndIcon={true}
              className={styles.secondary}
              onClick={() => handleRefreshAssignment()}
            >
              <RefreshIcon fontSize='large' />
              <span>{t('gallery:automation_in_process_card:button')}</span>
            </Button>
          </div>

        </div>
      }

      {/** Chosen creative information */}
      {statesUnlockingCreativeCardInformation.has(creativeCardState) && AssignmentDTOIsAdministratorDTO(assignmentData) &&
        <div className={styles[cardColor]}>
          <div className={styles.columnWrapper}>

            {/** Small header of assignment status */}
            <span className={styles.smallHeader}>
              {t(`assignment_creative_card_state:${creativeCardState}`)}
            </span>

            <div className={styles.columnWrapper}>
              {/** Creative basic information */}
              <div className={styles.creativeWrapper}>
                <div className={classnames(styles.columnWrapper, styles.information)}>

                  <h3 className={styles.title}>{assignmentData.creative?.name}</h3>

                  {!!assignmentData.creative?.email &&
                    <a href={`mailto:${assignmentData.creative.email}`} className={classnames(styles.iconsWrapper, styles.textGap)}>
                      <EmailIcon />
                      <span className={styles.text}>{assignmentData.creative?.email}</span>
                    </a>
                  }

                  {!!assignmentData.creative?.phone &&
                    <a href={`tel:${assignmentData.creative.phone}`} className={classnames(styles.iconsWrapper, styles.textGap)}>
                      <CallIcon />
                      <span className={styles.text}>{assignmentData.creative?.phone}</span>
                    </a>
                  }

                </div>

                {canChangeAndRemoveCT &&
                  <div className={styles.sideButtonWrap}>

                    <Button
                      textAndIcon={true}
                      className={styles.secondary}
                      onClick={() => handleChangeCTPopupOpen()}
                    >
                      <Edit fontSize='large' />
                    </Button>

                    <Button
                      textAndIcon={true}
                      className={classnames(styles.secondary, styles.destructive)}
                      onClick={() => handleRemoveCT()}
                    >
                      <PersonRemove fontSize='large' />
                    </Button>

                  </div>
                }

                {canStopAutomation &&
                  <Fragment>
                    <Button
                      textAndIcon={true}
                      className={styles.secondary}
                      onClick={() => handleStopAutomation()}
                    >

                      <StopIcon fontSize='large' />

                      <span>{t('gallery:stop_automated_ct:title')}</span>

                    </Button>

                    <QueryStatus
                      spaceTopRem={2}
                      query={stopAutomatedAssignment}
                    />
                  </Fragment>
                }
              </div>

              {/** Creative comments for BKBN */}
              {!!assignmentData.creativeCommentsForAdmin &&
                <LinkifyWrapper>
                  <hr className={styles.divider} />
                  <div>
                    <span className={styles.smallHeader}>{t('creative_comments_for_admin')}</span>
                    <p>{multilineText(assignmentData.creativeCommentsForAdmin)}</p>
                  </div>
                </LinkifyWrapper>
              }
            </div>
          </div>


          <>
            <Divider sx={{
              marginTop: 2,
              marginBottom: 1.5,
              backgroundColor: cardColorMap[cardColor],
              opacity: .5,
            }} />

            <Stack
              className={styles.editRemunerationSection}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
            >
              <span>{t('common:Remuneration')}: <strong>{formatPrice(assignmentData?.creativeRemuneration?.totalRemuneration || 0)}</strong></span>

              {assignmentData.stage !== AssignmentStage.VISUALS_SENT_TO_CLIENT &&
                <Button
                  className={styles.editRemunerationButton}
                  type='link'
                  onClick={() => openChangeRemunerationDrawer()}
                >
                  {t('actions:Edit')}
                </Button>
              }
            </Stack>

            <QueryStatus
              query={adminRemoveCreative}
              spaceTopRem={2}
            />

            <QueryStatus
              query={automateAssignment}
              spaceTopRem={2}
              onPurge={() => automateAssignment.reset()}
            />
          </>

        </div>
      }

      <AssignCreativePopup
        assignment={assignmentData}
        isOpen={assignCreativePopup}
        addToSuggestionHistory={creativeCardState !== AssignmentCreativeCardState.ASSIGN_AUTOMATION}
        onClickOutside={() => setAssignCreativePopup(false)}
        onClickClose={() => setAssignCreativePopup(false)}
        onKeyDown={(e) => e.key === KeyboardEventKey.ESCAPE && setAssignCreativePopup(false)}
        callbackAfterSuccessfulResponse={() => setAssignCreativePopup(false)}
      />

      {/** CREATIVES UNAVAILABLE LIST POPUP */}
      <GalleryUnavailableCreativesPopup
        isOpen={isUnavailableListPopupOpen}
        onClickClose={() => setUnavailableListPopupOpen(false)}
      />

    </Fragment>
  )
}
