import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { GetSubscriptionCheckoutLinkStore, SubscriptionCheckoutDetails } from './getCheckoutLink.interfaces'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'
import { ActionRequest } from 'models/redux'
import { createAPIAction } from 'redux/Helpers'

/** Describes general action to get subscription checkout link */
export interface ActionGetSubscriptionCheckoutLink extends ActionRequest {
  payload: {
    workspaceId: string
    subscriptionDetails: SubscriptionCheckoutDetails
    request: GetSubscriptionCheckoutLinkStore
  },
}

/** Creates an action to get subscription checkout link */
export function getSubscriptionCheckoutLink(
  workspaceId: string,
  subscriptionDetails: SubscriptionCheckoutDetails
): ActionGetSubscriptionCheckoutLink {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.GET_SUBSCRIPTION_CHECKOUT_LINK], {
    workspaceId,
    subscriptionDetails,
    request: new APIRequest(APIRequestState.RUNNING),
  })
}

/** Creates an action to purge state of get subscription checkout link */
export function purgeGetSubscriptionCheckoutLink(): ActionGetSubscriptionCheckoutLink {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.GET_SUBSCRIPTION_CHECKOUT_LINK], {
    request: new APIRequest(APIRequestState.BEFORE_START),
  })
}
