import React, { useMemo } from 'react'
import { bigFromFee, formatPrice } from 'utils/price'

import { ProductPriceDTO } from 'models/product'
import styles from './ProductLine.module.sass'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
interface Props {
  product: ProductPriceDTO
}

/**
 * @component Product Line component
 * @example <ProductLine product={data} />
 */

export const ProductLine: React.FC<Props> = ({
  product
}) => {
  const { t } = useTranslation(['gallery'])
  const isAdditionalPhoto = useMemo(() => t(`product:p_${product.productId}`) === t('order_informations.additional_photo'), [product.productId, t])
  const productTitle = useMemo(() =>
    isAdditionalPhoto ?
      `${product.quantity > 1 ? `+${product.quantity}` : ''} ${t(`product:p_${product.productId}`)}` :
      t(`product:p_${product.productId}`,
        t(`product_kind:${product.kind}`,
          t(`product_type:${product.type}`,
            t(`category:${product.category}`)
          )
        )
      )
    , [isAdditionalPhoto, product.category, product.kind, product.productId, product.quantity, product.type, t])

  return (
    <div className={styles.productLine}>
      <div>
        {productTitle}
      </div>
      <div>
        {formatPrice(bigFromFee(product.unitPrice).times(product.quantity), product.unitPrice.currency)}
      </div>
    </div>
  )
}
