import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, put, takeEvery } from 'redux-saga/effects'
import { getInitRoles, getRoles } from 'utils/auth'

import API from 'utils/API/API'
import { ActionCreateWorkspace } from './createWorkspace.actions'
import { Endpoint } from 'constants/API'

/** Saga that creates a workspace */
export function* createWorkspaceSaga(receivedAction: ActionCreateWorkspace) {

  const { workspaceName } = receivedAction.payload

  let roles = getInitRoles()
  try {
    roles = yield getRoles()
  } catch (e) {
    throw new Error('Unable to get roles for user')
  }

  if (!roles || roles.all.size === 0) throw new Error('The user does not have any role!')

  const url = Endpoint.CREATE_WORKSPACE

  const action: ActionCreateWorkspace = yield generalFetch(ActionTypeAPIData.CREATE_WORKSPACE, () => API.post(
    url,
    {
      name: workspaceName
    },
    {
      headers: {
        Accept: API.decideRoleMimeType(roles)
      },
    },
    {
      endpoint: Endpoint.CREATE_WORKSPACE,
    }
  ))

  const createWorkspaceAction: ActionCreateWorkspace = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }

  yield put(createWorkspaceAction)

  // Return result action for consuming by other sagas
  return createWorkspaceAction
}

/** Watcher of create workspace actions */
export function* createWorkspaceWacher() {
  yield all([
    takeEvery((action: ActionCreateWorkspace) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.CREATE_WORKSPACE]), createWorkspaceSaga),
  ])
}
