import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDeleteLogo, useDeleteVisualsEditingLogoAndTemplate } from 'dataQueries'

import Button from 'components/common/Button/Button'
import Dropdown from 'components/common/Dropdown/Dropdown'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { WorkspaceRole } from 'models/user'
import classnames from 'classnames'
import styles from './EditorOptionLogoPreview.module.sass'
import { useActionPopup } from 'utils/hooks'
import { useUserData } from 'components/contexts/UserDataContext'
import { useVisualsEditing } from '../../VisualsEditing.context'
import { useWorkspacesProfileContext } from 'components/pages/Client/WorkspacesProfile/_main/WorkspacesProfile.context'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** The image to display */
  image: string
}

/**
 * @component EditorOptionLogoPreview for Visuals Editing's Watermark/logo loaded preview
 * @example
 * <EditorOptionLogoPreview />
 */
export const EditorOptionLogoPreview: React.FC<Props> = ({ image }) => {
  const { t } = useTranslation(['visuals_editing'])
  const { showConfirm } = useActionPopup()
  const { currentUserWorkspace } = useUserData()
  const { watermarkId, isWatermarkLockedToTemplate, templateId, logEditEvent } = useVisualsEditing()
  const { workspaceDataOfUser, workspaceMembersWithAdminRole } = useWorkspacesProfileContext()
  const deleteLogoAndTemplate = useDeleteVisualsEditingLogoAndTemplate()
  const deleteLogo = useDeleteLogo()

  const [isHover, setIsHover] = useState(false)


  const getWorkspaceAdminsNames = useMemo(() => {
    // We want to show to member max first 3 admin names
    const workspaceAdmins = workspaceMembersWithAdminRole && workspaceMembersWithAdminRole.slice(0, 3).map(obj => obj.name)

    if (workspaceAdmins?.length === 1) {
      return workspaceAdmins[0]
    } else {
      return workspaceAdmins?.join(', ')
    }
  }, [workspaceMembersWithAdminRole])

  const confirmPopupTranslationsKey = useMemo(() => {
    if (workspaceDataOfUser?.role === WorkspaceRole.ADMIN && isWatermarkLockedToTemplate) {
      return 'remove_logo_popup_locked_watermark_admin'
    } else if (workspaceDataOfUser?.role === WorkspaceRole.MEMBER && isWatermarkLockedToTemplate) {
      return 'remove_logo_popup_locked_watermark_member'
    }
    return 'remove_logo_popup'
  }, [isWatermarkLockedToTemplate, workspaceDataOfUser?.role])

  const confirmRemoveWatermark = useCallback(async () => {
    const confirmBody = (
      <Trans
        t={t}
        i18nKey={`${confirmPopupTranslationsKey}.content`}
        values={{ adminMemberNames: getWorkspaceAdminsNames }}
      >
        <span></span>
      </Trans>
    )

    const denyText = !isWatermarkLockedToTemplate || (isWatermarkLockedToTemplate && workspaceDataOfUser?.role === WorkspaceRole.ADMIN)
      ? t('remove_logo_popup.confirm_deny')
      : undefined

    return showConfirm(
      confirmBody,
      {
        title: t(`${confirmPopupTranslationsKey}.title`),
        confirmAcceptText: t(`${confirmPopupTranslationsKey}.confirm_accept`),
        confirmDenyText: denyText,
        hideSecondaryButton: isWatermarkLockedToTemplate && workspaceDataOfUser?.role !== WorkspaceRole.ADMIN
      }
    )
  }, [confirmPopupTranslationsKey, getWorkspaceAdminsNames, isWatermarkLockedToTemplate, showConfirm, t, workspaceDataOfUser?.role])

  const handleRemoveWatermark = useCallback(async () => {
    if (!currentUserWorkspace || !watermarkId) return
    if (!(await confirmRemoveWatermark())) return

    // when watermark is used by some template, only admin can delete logo and template will be deleted together with the watermark
    if (isWatermarkLockedToTemplate) {
      switch (workspaceDataOfUser?.role) {
        case WorkspaceRole.ADMIN:
          templateId && deleteLogoAndTemplate.mutate({ templateId, workspaceId: currentUserWorkspace.id, watermarkId })
          logEditEvent('Remove Logo & Delete Template', { userRole: workspaceDataOfUser?.role })
          break
        case WorkspaceRole.MEMBER:
          logEditEvent('Remove Logo Blocked', { userRole: workspaceDataOfUser?.role })
          break
        default:
          break
      }
    } else {
      // when watermark is not tied to any template, both member and admin can delete watermark
      deleteLogo.mutate({ workspaceId: currentUserWorkspace.id, watermarkId })
    }
  }, [currentUserWorkspace, watermarkId, confirmRemoveWatermark, isWatermarkLockedToTemplate, workspaceDataOfUser?.role, templateId, deleteLogoAndTemplate, logEditEvent, deleteLogo])

  return (
    <div className={styles.previewContainer} onMouseEnter={() => setIsHover(!isHover)} onMouseLeave={() => setIsHover(!isHover)}>
      <div className={styles.previewBorder}>
        <img src={image} alt={t('logo')} className={styles.previewImage} />
      </div>
      <Dropdown
        className={styles.dropdown}
        innerMenuClassName={styles.innerDropdown}
        attachment="right-attached"
        button={(isOpen, action) => (
          <Button type="secondary nobackground noborder" textAndIcon={true} onClick={action} className={classnames(styles.dropdownButton, { [styles.optionsActive]: isHover })}>
            <MoreHorizIcon fontSize='large' />
          </Button>
        )}>
        <Button
          type="secondary nobackground noborder"
          className={styles.removeButton}
          onClick={handleRemoveWatermark}
        >
          {t('editor_options.remove_logo')}
        </Button>
      </Dropdown>
    </div>
  )
}
