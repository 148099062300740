import { RestrictedAccessOption, WORKSPACE_MEMBER_LIMIT_BY_PLAN } from 'constants/workspaces'
import { Stack, Typography } from '@mui/material'
import { SubscriptionPlan, WorkspaceRole } from 'models/user'
import { Trans, useTranslation } from 'react-i18next'
import { useCallback, useMemo, useState } from 'react'

import { APIRequestErrorType } from 'constants/API'
import { AddMembersRole } from 'components/pages/SubscriptionFlow/Steps/AddMembers/AddMembersRole.module'
import BlockInfo from 'components/common/BlockInfo/BlockInfo'
import { KeyboardEventKey } from 'constants/misc'
import { MUIButton } from 'components/common/MUIButton'
import Modal from 'components/common/Modals/Modal/Modal'
import { MultiEmailInput } from 'components/common/MultiEmailInput'
import { QueryStatus } from 'components/common/QueryStatus'
import { UserAsMemberUsingEmailDTO } from 'models/workspaces'
import classnames from 'classnames'
import { decideErrorType } from 'utils/APIDecideErrorType'
import { getSubscriptionPricingLink } from 'utils/localization'
import i18n from 'translations/i18n'
import popupStyles from 'components/styles/popup.module.sass'
import styles from './AddMembersPopup.module.sass'
import { useAddWorkspaceMembers } from 'dataQueries'
import { useWorkspacesProfileContext } from '../_main/WorkspacesProfile.context'

/**
 * @interface Props Input properties
 */
interface Props {
  /** The additional classes to append */
  className?: string
  /** Decides when is modal open */
  isOpen: boolean
  /** Workspace ID */
  workspaceId: string
  /** Onclick action triggered when user clicks outside the .modal-content element */
  onClickOutside?: (e: React.MouseEvent) => unknown
  /** onKeyDown action triggered when user presses any key on the keyboard */
  onKeyDown?: (e: React.KeyboardEvent) => unknown
  /** Onclick action triggered when user clicks the close button */
  onClickClose?: (e: React.MouseEvent) => unknown
}

/**
 * @component Add members to workspace in a popup
 * @example
 * <AddMembersPopup isOpen={true} workspaceId={workspaceId} />
 */
export const AddMembersPopup: React.FC<Props> = ({
  className = '',
  isOpen,
  workspaceId,
  onClickOutside,
  onKeyDown,
  onClickClose,
}) => {

  const { t } = useTranslation(['workspace_profile', 'actions', 'add_workspace_members_list'])

  const {
    sortedWorkspaceMembers,
    workspaceMembersEmails,
    workspaceDataOfUser,
    pendingInvitations,
    isWorkspaceEnterprise
  } = useWorkspacesProfileContext()

  const addWorkspaceMembers = useAddWorkspaceMembers()

  const addWorkspaceMembersResponseData = addWorkspaceMembers.data
  const isWorkspaceRoleRestrictedMember = useMemo(() => workspaceDataOfUser?.role === WorkspaceRole.MEMBER && workspaceDataOfUser.isRestricted, [workspaceDataOfUser])
  const [emails, setEmails] = useState<string[]>([])
  const [memberAccess, setInvitedMemberAccess] = useState(isWorkspaceRoleRestrictedMember ? RestrictedAccessOption.RESTRICTED_ACCESS : RestrictedAccessOption.FULL_ACCESS)

  const membersCount = useMemo(() => (sortedWorkspaceMembers ? sortedWorkspaceMembers?.length : 0) + emails.length, [sortedWorkspaceMembers, emails])
  const isSubmitButtonDisabled = useMemo(() => emails.length === 0 || addWorkspaceMembers.isPending, [emails, addWorkspaceMembers.isPending])


  const hadnleInviteMembers = useCallback(() => {
    const newMembers: UserAsMemberUsingEmailDTO[] = emails.map((email) => ({ email, inSubscriptionFlow: false, restrictedMember: Boolean(memberAccess === RestrictedAccessOption.RESTRICTED_ACCESS) }))

    addWorkspaceMembers.mutate({ workspaceId, members: newMembers })

  }, [addWorkspaceMembers, emails, workspaceId, memberAccess])

  const onOutsideOrClose = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
    if (onClickOutside) onClickOutside(e)
    if (onClickClose) onClickClose(e)
    addWorkspaceMembers.reset()
    setEmails([])
    setInvitedMemberAccess(RestrictedAccessOption.FULL_ACCESS)
  }, [addWorkspaceMembers, onClickOutside, onClickClose])

  const onKeyboardEvent = useCallback((e: React.KeyboardEvent) => {
    if (onKeyDown) onKeyDown(e)
    if (onKeyDown && e.key === KeyboardEventKey.ESCAPE) {
      addWorkspaceMembers.reset()
      setEmails([])
      setInvitedMemberAccess(RestrictedAccessOption.FULL_ACCESS)
    }
  }, [addWorkspaceMembers, onKeyDown])

  const responseError = addWorkspaceMembers.error
  const pendingInvitationsEmailsSet = new Set(pendingInvitations?.map((invitation) => { return invitation.email }))

  const onEmailValidation = (inputString: string) => {
    if (pendingInvitationsEmailsSet.has(inputString)) {
      return 'multi_email_input:already_pending_invitations'
    }
  }

  return (
    <Modal
      className={classnames(styles.addMembersPopup, className)}
      isOpen={isOpen}
      modalContentClassName={classnames(styles.content, (addWorkspaceMembers.isSuccess || addWorkspaceMembers.isError) ? styles.successContent : undefined)}
      onKeyDown={onKeyboardEvent}
      onClose={onOutsideOrClose}
      title={t('invite_members_popup')}
      footerContent={
        // Show Invite members button & Cancel button only for popup content with multi invitation input
        !(addWorkspaceMembers.isSuccess || addWorkspaceMembers.isError) &&
        <Stack gap={.8} flexDirection="row" justifyContent="flex-end" flexWrap="wrap" marginBottom="0.8rem">

          <MUIButton type="secondaryNoBorder" onClick={onOutsideOrClose}>
            {t('actions:Cancel')}
          </MUIButton>

          <MUIButton onClick={hadnleInviteMembers} disabled={isSubmitButtonDisabled} isLoading={addWorkspaceMembers.isPending}>
            {t('send_invitation')}
          </MUIButton>

        </Stack>
      }
      queryData={addWorkspaceMembers}
    >

      <div className={popupStyles.inside}>

        {(typeof addWorkspaceMembersResponseData === 'undefined') ?
          <Stack gap={1.6} marginBottom={2.4} marginTop={.7}>

            <MultiEmailInput
              emails={emails}
              previouslyAddedEmails={workspaceMembersEmails}
              onChange={(emails) => setEmails(emails)}
              title={<Typography variant="text-md" fontWeight={'medium'}>{t('invite_by_email')}</Typography>}
              onEmailValidation={onEmailValidation}
            />

            {membersCount >= WORKSPACE_MEMBER_LIMIT_BY_PLAN[SubscriptionPlan.PRO_PER_SEAT] && !isWorkspaceEnterprise &&
              <BlockInfo className={styles.maxMembersWarning}>
                <Trans
                  t={t}
                  i18nKey="add_workspace_members_list:max_members_warning"
                  values={{ maxMembers: WORKSPACE_MEMBER_LIMIT_BY_PLAN[SubscriptionPlan.PRO_PER_SEAT] }}
                >
                  <a
                    href={getSubscriptionPricingLink(i18n.language)}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    &nbsp;
                  </a>
                </Trans>
              </BlockInfo>
            }

            <AddMembersRole onChange={setInvitedMemberAccess} memberAccess={memberAccess} disabled={isWorkspaceRoleRestrictedMember} />

          </Stack>
          :
          <Stack marginBottom={0.8}>
            <QueryStatus
              query={addWorkspaceMembers}
              successMessage={!!addWorkspaceMembersResponseData?.invitedUsers.some((invitedUser) => !invitedUser.userId)
                ? t('add_members_success_non_existing_user')
                : t('add_members_success')
              }
              errorMessage={responseError && decideErrorType(responseError) === APIRequestErrorType.PAYMENT_REQUIRED_ERROR ?
                t('error_payment_required')
                :
                undefined
              }
            />
          </Stack>
        }

      </div>

    </Modal>
  )
}
