import { FC, ReactNode } from 'react'

import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { PageTransition } from 'utils/animations'
import classNames from 'classnames'
import styles from 'components/common/Page/PageLayout/PageLayout.module.sass'
import useMediaQuery from '@mui/material/useMediaQuery'

interface Props {
  /** Custom header component */
  header: ReactNode
  /** Custom tabs component */
  tabs?: ReactNode
  /** Custom component to display in the page main content */
  children: ReactNode
  layoutErrorContent: ReactNode
}

/**
 * Layout component for organizing basic page setup
 * @param header - Accepts JSX element or <PageHeader /> and displays it on the top of the page. 
 * @param tabs - Accepts JSX element or <PageTabs /> and displays it at the buttom of the header.
 * @param layoutErrorContent - Accepts JSX element and displays it instead of any PageLayout content as a full height and width error page.
 * @example <PageLayout />
 */
export const PageLayout: FC<Props> = ({ header, tabs, children, layoutErrorContent }) => {
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)

  return (
    <PageTransition>
      <div className={styles.pageLayout}>

        {!!layoutErrorContent
          ? layoutErrorContent
          : <>
            <div className={classNames(styles.header, { [styles.mobile]: isMobileView })}>
              <div className={styles.container}>
                {header}
                {!!tabs && tabs}
              </div>
            </div>

            {children}
          </>
        }

      </div>
    </PageTransition>
  )
}
