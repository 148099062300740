import { Dispatch, FC, ReactNode, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { STRIPE_PRIMARY_PRODUCT_KINDS, StripeProductKindToPaymentMethodMap } from 'constants/payments'
import { getUpsellPaymentIntent, purgeGetUpsellPaymentIntent } from 'redux/Individual/Mission/GetUpsellPaymentIntent'
import { useDispatch, useSelector } from 'react-redux'

import { APIRequestState } from 'constants/API'
import { ActionTypeAPIData } from 'constants/redux'
import { InstructionOptionDTO } from 'models/purchaseFlow'
import { Nullable } from 'models/helpers'
import { PaymentIntentDTO } from 'models/stripe'
import { ProductKind } from 'constants/product'
import { RootStore } from 'models/redux'
import { SavedStripePaymentMethod } from 'models/user'
import { StripeError } from '@stripe/stripe-js'
import { productKindIsPreferredPaymentMethod } from 'utils/typeguards'
import { useAuth0 } from 'utils/auth'
import { useGetUpsellBillingConfiguration } from 'dataQueries/purchase.query'
import { useSavedStripePaymentMethods } from 'components/contexts/SavedStripePaymentMethodsContext'
import { useUpsellPurchaseConfiguration } from './PurchaseConfiguration.context'
import { useUserData } from 'components/contexts/UserDataContext'

interface UpsellPaymentInterface {
  paymentIntent: Nullable<PaymentIntentDTO>
  isStripeUsed: boolean
  paymentProducts: InstructionOptionDTO[]
  selectedPaymentProduct?: InstructionOptionDTO
  isForeignPurchase: boolean
  isPaymentElementReady: boolean
  isPaymentElementValid: boolean
  stripeError: StripeError | null
  isStripeProcessing: boolean
  isStripeConfirmed: boolean
  updatePreferredPaymentMethodState: APIRequestState
  receiptEmail: string
  isReceiptEmailValid: boolean
  isPurchaseReady: boolean
  saveStripePaymentMethod: boolean
  selectedSavedPaymentMethod: Nullable<SavedStripePaymentMethod>
  isPaymentLoading: boolean
  setStripeError: Dispatch<SetStateAction<StripeError | null>>
  setIsStripeProcessing: Dispatch<SetStateAction<boolean>>,
  setIsStripeConfirmed: Dispatch<SetStateAction<boolean>>,
  setIsPaymentElementReady: Dispatch<SetStateAction<boolean>>
  setIsPaymentElementValid: Dispatch<SetStateAction<boolean>>
  setReceiptEmail: Dispatch<SetStateAction<string>>
  setIsReceiptEmailValid: Dispatch<SetStateAction<boolean>>
  setSaveStripePaymentMethod: Dispatch<SetStateAction<boolean>>
  setSelectedPaymentProduct: Dispatch<SetStateAction<InstructionOptionDTO | undefined>>
  setSelectedSavedPaymentMethod: Dispatch<SetStateAction<Nullable<SavedStripePaymentMethod>>>
}

const defaultUpsellPaymentValue: UpsellPaymentInterface = {
  paymentIntent: null,
  isStripeUsed: false,
  paymentProducts: [],
  selectedPaymentProduct: undefined,
  isForeignPurchase: false,
  isPaymentElementReady: false,
  isPaymentElementValid: false,
  stripeError: null,
  isStripeProcessing: false,
  isStripeConfirmed: false,
  isPurchaseReady: false,
  updatePreferredPaymentMethodState: APIRequestState.BEFORE_START,
  receiptEmail: '',
  isReceiptEmailValid: false,
  saveStripePaymentMethod: false,
  selectedSavedPaymentMethod: undefined,
  isPaymentLoading: true,
  setStripeError: () => { throw new Error('setStripeError is not defined') },
  setIsStripeProcessing: () => { throw new Error('setIsStripeProcesing is not defined') },
  setIsStripeConfirmed: () => { throw new Error('setIsStripeConfirmed is not defined') },
  setIsPaymentElementReady: () => { throw new Error('setIsPaymentElementReady is not defined') },
  setIsPaymentElementValid: () => { throw new Error('setIsPaymentElementValid is not defined') },
  setSelectedPaymentProduct: () => { throw new Error('setSelectedPaymentProduct is not defined') },
  setReceiptEmail: () => { throw new Error('setReceiptEmail is not defined') },
  setIsReceiptEmailValid: () => { throw new Error('setIsReceiptEmailValid is not defined') },
  setSaveStripePaymentMethod: () => { throw new Error('setSaveStripePaymentMethod is not defined') },
  setSelectedSavedPaymentMethod: () => { throw new Error('setSelectedSavedPaymentMethod is not defined') },
}

/** Upsell payment context */
export const UpsellPaymentContext = createContext<UpsellPaymentInterface>(defaultUpsellPaymentValue)
/** Upsell payment context hook */
export const useUpsellPayment = (): UpsellPaymentInterface => useContext(UpsellPaymentContext)

/** Context provider for upsell payment */
export const UpsellPaymentContextProvider: FC<{
  children?: ReactNode
}> = ({
  children
}) => {

    const dispatch = useDispatch()
    const { roles } = useAuth0()

    const {
      isOpen,
      productId,
      productQuantity,
      assignmentId,
    } = useUpsellPurchaseConfiguration()

    const {
      fetchSavedStripePaymentMethods,
      savedStripePaymentMethodsMap,
      stripePaymentMethods
    } = useSavedStripePaymentMethods()

    const { clientData, userCanPayByInvoice } = useUserData()

    // Saving payment methods
    const [saveStripePaymentMethod, setSaveStripePaymentMethod] = useState<boolean>(defaultUpsellPaymentValue.saveStripePaymentMethod)
    const [selectedSavedPaymentMethod, setSelectedSavedPaymentMethod] = useState<Nullable<SavedStripePaymentMethod>>(defaultUpsellPaymentValue.selectedSavedPaymentMethod)

    // Preferred payment method
    const updatePreferredPaymentMethodState = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.UPDATE_PREFERRED_PAYMENT_METHOD].request.state)
    const preferredPaymentMethod = useMemo(() => clientData?.preferredPaymentMethod || undefined, [clientData])

    // Payment intent
    const paymentIntent = useSelector(!!assignmentId ? (state: RootStore) => state.APIData[ActionTypeAPIData.GET_UPSELL_PAYMENT_INTENT][assignmentId]?.response?.data : () => null)

    // Payment kinds
    const upsellBillingConfiguration = useGetUpsellBillingConfiguration(assignmentId, isOpen)
    const upsellBillingConfigurationData = useMemo(() => upsellBillingConfiguration.data?.data, [upsellBillingConfiguration])
    const paymentProducts = useMemo(() => upsellBillingConfigurationData?.paymentMethodList || [], [upsellBillingConfigurationData])
    const [selectedPaymentProduct, setSelectedPaymentProduct] = useState<InstructionOptionDTO>()
    const selectedStripePaymentMethod = useMemo(() => !!selectedPaymentProduct ? StripeProductKindToPaymentMethodMap.get(selectedPaymentProduct.kind) : undefined, [selectedPaymentProduct])

    // Whether it is foreign purchase (true = the purchaser is not the owner of the assignment)
    const isForeignPurchase = useMemo(() => upsellBillingConfigurationData?.foreignPurchase || false, [upsellBillingConfigurationData])

    // Payment element
    const [isPaymentElementReady, setIsPaymentElementReady] = useState<boolean>(defaultUpsellPaymentValue.isPaymentElementReady)
    const [isPaymentElementValid, setIsPaymentElementValid] = useState<boolean>(defaultUpsellPaymentValue.isPaymentElementValid)

    // Stripe status
    const [isStripeProcessing, setIsStripeProcessing] = useState<boolean>(defaultUpsellPaymentValue.isStripeProcessing)
    const [isStripeConfirmed, setIsStripeConfirmed] = useState<boolean>(defaultUpsellPaymentValue.isStripeConfirmed)
    const [stripeError, setStripeError] = useState<StripeError | null>(defaultUpsellPaymentValue.stripeError)

    // Receipt email
    const [receiptEmail, setReceiptEmail] = useState<string>(defaultUpsellPaymentValue.receiptEmail)
    const [isReceiptEmailValid, setIsReceiptEmailValid] = useState<boolean>(defaultUpsellPaymentValue.isReceiptEmailValid)

    // Meta
    const isStripeUsed = useMemo(() => !!selectedPaymentProduct && STRIPE_PRIMARY_PRODUCT_KINDS.has(selectedPaymentProduct.kind), [selectedPaymentProduct])
    const isPurchaseReady = useMemo(() => {
      if (!upsellBillingConfiguration.isSuccess) return false
      if (!roles.isAdmin && !stripePaymentMethods?.isSuccess) return false
      return true
    }, [upsellBillingConfiguration, roles.isAdmin, stripePaymentMethods])

    const isPaymentLoading = useMemo(() => {
      if (selectedSavedPaymentMethod === null && !isPaymentElementReady) return true
      return false
    }, [isPaymentElementReady, selectedSavedPaymentMethod])

    // Get payment intent when all data are set and stripe payment method is selected
    useEffect(() => {
      if (!isStripeUsed) return
      if (!assignmentId) return
      if (!productId) return
      if (!productQuantity) return
      if (!selectedStripePaymentMethod) return

      dispatch(getUpsellPaymentIntent(
        assignmentId,
        productId,
        productQuantity,
        selectedStripePaymentMethod
      ))
    }, [dispatch, isStripeUsed, assignmentId, productId, productQuantity, selectedPaymentProduct, selectedStripePaymentMethod])


    // Fetch billing configuration and saved methods when modal opens
    useEffect(() => {
      if (isOpen) {
        // Prevent backdrop stutter
        window.setTimeout(() => {
          if (assignmentId && !roles.isAdmin) fetchSavedStripePaymentMethods()
        }, 300)
      }
    }, [assignmentId, fetchSavedStripePaymentMethods, isOpen, roles.isAdmin])

    // Preselect either default or the only available method
    const handlePrefill = useCallback(() => {

      // Ignore if there is already selected payment method
      if (selectedPaymentProduct) return

      // If there is only 1 option, preselect it
      if (paymentProducts.length === 1) {
        // Check that the only option is not invoice and user is created after public launch with no subscriptions
        if (paymentProducts[0].kind === ProductKind.INVOICE_BY_EMAIL && !userCanPayByInvoice) return
        setSelectedPaymentProduct(paymentProducts[0])
        return
      }

      // Admins have no preferred method selection option
      if (!roles.isClient) return

      // Check that preferred payment method is in available options 
      const preferredProduct = paymentProducts.find((product) => product.kind === preferredPaymentMethod)

      // If preferred method is not in options do not select it
      if (!preferredPaymentMethod || !preferredProduct) return

      // Check that preferred payment is invoice and user is created after public launch with no subscriptions
      if (preferredProduct.kind === ProductKind.INVOICE_BY_EMAIL && !userCanPayByInvoice) return

      setSelectedPaymentProduct(preferredProduct)
    }, [selectedPaymentProduct, paymentProducts, roles.isClient, preferredPaymentMethod, userCanPayByInvoice])

    // Trigger the preselect
    useEffect(handlePrefill, [handlePrefill])

    // Reset paymentIntent and selectedSavedPaymentMethod when selected payment kind changes
    useEffect(() => {
      if (!!assignmentId) dispatch(purgeGetUpsellPaymentIntent(assignmentId))
      setSelectedSavedPaymentMethod(undefined)
    }, [dispatch, assignmentId, selectedPaymentProduct])

    // Reset readiness and validity of payment element when method changes to saved/undefined
    useEffect(() => {
      if (selectedSavedPaymentMethod !== null) {
        if (isPaymentElementValid) setIsPaymentElementValid(false)
        if (isPaymentElementReady) setIsPaymentElementReady(false)
      }
    }, [isPaymentElementReady, isPaymentElementValid, selectedSavedPaymentMethod, setIsPaymentElementReady, setIsPaymentElementValid])

    // If no saved methods for selected payment, push null to selectedSavedMethod
    useEffect(() => {
      if (!stripePaymentMethods?.isSuccess) return
      if (!selectedPaymentProduct || !productKindIsPreferredPaymentMethod(selectedPaymentProduct.kind)) return
      if (!STRIPE_PRIMARY_PRODUCT_KINDS.has(selectedPaymentProduct.kind)) return

      const savedMethods = savedStripePaymentMethodsMap[selectedPaymentProduct.kind]
      if (!savedMethods || savedMethods.length === 0) setSelectedSavedPaymentMethod(null)

    }, [savedStripePaymentMethodsMap, stripePaymentMethods, selectedPaymentProduct])

    return (
      <UpsellPaymentContext.Provider
        value={{
          paymentIntent,
          isStripeUsed,
          paymentProducts,
          selectedPaymentProduct,
          isForeignPurchase,
          isPaymentElementReady,
          isPaymentElementValid,
          isStripeProcessing,
          isStripeConfirmed,
          stripeError,
          isPurchaseReady,
          updatePreferredPaymentMethodState,
          receiptEmail,
          isReceiptEmailValid,
          saveStripePaymentMethod,
          selectedSavedPaymentMethod,
          isPaymentLoading,
          setIsStripeProcessing,
          setIsStripeConfirmed,
          setStripeError,
          setIsPaymentElementValid,
          setIsPaymentElementReady,
          setSelectedPaymentProduct,
          setReceiptEmail,
          setIsReceiptEmailValid,
          setSaveStripePaymentMethod,
          setSelectedSavedPaymentMethod,
        }}
      >
        {children}
      </UpsellPaymentContext.Provider>
    )
  }
