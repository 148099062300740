export enum FloorPlanCertificationPropertyTypes {
  FARMHOUSE = 'FARMHOUSE',
  BUNGALOW = 'BUNGALOW',
  OFFICE = 'OFFICE',
  SEMI_DETACHED_HOUSE = 'SEMI_DETACHED_HOUSE',
  CONDOMINIUM = 'CONDOMINIUM',
  DETACHED_HOUSE = 'DETACHED_HOUSE',
  RETAIL = 'RETAIL',
  GARAGE_PARKING_SPACE = 'GARAGE_PARKING_SPACE',
  GASTRONOMY = 'GASTRONOMY',
  PLOT_OF_LAND = 'PLOT_OF_LAND',
  HALL_WAREHOUSE_PRODUCTION = 'HALL_WAREHOUSE_PRODUCTION',
  HOUSE = 'HOUSE',
  STORE = 'STORE',
  APARTMENT_BUILDING_FLOOR = 'APARTMENT_BUILDING_FLOOR',
  PRACTICE = 'PRACTICE',
  END_TERRACED_HOUSE = 'END_TERRACED_HOUSE',
  TERRACED_HOUSE = 'TERRACED_HOUSE',
  RESIDENTIAL_AND_COMMERCIAL_BUILDING = 'RESIDENTIAL_AND_COMMERCIAL_BUILDING',
  RESIDENTIAL_BUILDING = 'RESIDENTIAL_BUILDING',
  APARTMENT = 'APARTMENT',
  OTHER = 'OTHER',
}

export const floorPlanCertificationPropertyTypes: Set<FloorPlanCertificationPropertyTypes> = new Set([
  FloorPlanCertificationPropertyTypes.FARMHOUSE,
  FloorPlanCertificationPropertyTypes.BUNGALOW,
  FloorPlanCertificationPropertyTypes.OFFICE,
  FloorPlanCertificationPropertyTypes.SEMI_DETACHED_HOUSE,
  FloorPlanCertificationPropertyTypes.CONDOMINIUM,
  FloorPlanCertificationPropertyTypes.DETACHED_HOUSE,
  FloorPlanCertificationPropertyTypes.RETAIL,
  FloorPlanCertificationPropertyTypes.GARAGE_PARKING_SPACE,
  FloorPlanCertificationPropertyTypes.GASTRONOMY,
  FloorPlanCertificationPropertyTypes.PLOT_OF_LAND,
  FloorPlanCertificationPropertyTypes.HALL_WAREHOUSE_PRODUCTION,
  FloorPlanCertificationPropertyTypes.HOUSE,
  FloorPlanCertificationPropertyTypes.STORE,
  FloorPlanCertificationPropertyTypes.APARTMENT_BUILDING_FLOOR,
  FloorPlanCertificationPropertyTypes.PRACTICE,
  FloorPlanCertificationPropertyTypes.END_TERRACED_HOUSE,
  FloorPlanCertificationPropertyTypes.TERRACED_HOUSE,
  FloorPlanCertificationPropertyTypes.RESIDENTIAL_AND_COMMERCIAL_BUILDING,
  FloorPlanCertificationPropertyTypes.RESIDENTIAL_BUILDING,
  FloorPlanCertificationPropertyTypes.APARTMENT,
  FloorPlanCertificationPropertyTypes.OTHER,
])

export enum AreaCalculationTypes {
  CALCULATE_WITH_25 = 'CALCULATE_WITH_25',
  CALCULATE_WITH_50 = 'CALCULATE_WITH_50',
}

export const floorPlanCertificationAreaCalculations: Set<AreaCalculationTypes> = new Set([
  AreaCalculationTypes.CALCULATE_WITH_25,
  AreaCalculationTypes.CALCULATE_WITH_50,
]) 
