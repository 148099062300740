import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { ActionCreateWorkspace } from './createWorkspace.actions'
import { CreateWorkspaceStore } from './createWorkspace.interfaces'

/** Redux data reducer for create workspace */
export const reduceCreateWorkspace = (state: CreateWorkspaceStore, action: ActionCreateWorkspace) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.CREATE_WORKSPACE) return state

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
    case ActionTypeAPIEvent.FETCH:
    case ActionTypeAPIEvent.RECEIVED:
      return action.payload.request
    default:
      return state
  }
}
