import { PurchaseFlowStepKey, usePurchaseFlowUtilities } from '../../_main/contexts'

import { DynamicFlowStep } from 'components/common/DynamicFlow'
import { FC } from 'react'
import { StyleSelectionStepController } from './StyleSelection.controller'

/**
 * @component
 * Page in PurchaseFlow step for selecting styles for staging images.
 * 
 * @example 
 * <StyleSelectionStep />
 */
export const StyleSelectionStep: FC = () => {
  const { isStagingStepValid } = usePurchaseFlowUtilities()

  return (
    <DynamicFlowStep
      stepKey={PurchaseFlowStepKey.STYLE_SECTION}
      isValid={isStagingStepValid}
    >
      <StyleSelectionStepController />
    </DynamicFlowStep>
  )
}
