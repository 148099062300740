import { FC } from 'react'
import { GRAY_500 } from 'constants/styling/theme'
import styles from 'components/common/DotDivider/DotDivider.module.sass'

export interface Props {
  /** Divider color */
  color?: string
}

/**
 * @component
 * DotDivider component is decoration divider in dot shape
 *
 * @example
 * <DotDivider />
 */
export const DotDivider: FC<Props> = ({ color = GRAY_500 }) =>
  <span className={styles.dot} style={{ backgroundColor: color }}></span>
