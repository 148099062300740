import { GRAY_900, WHITE } from 'constants/styling/theme'

import { AssignmentStage } from 'constants/assignment/assignmentStates'
import { DotDivider } from 'components/common/DotDivider/DotDivider.module'
import { MUITooltip } from 'components/common/MUITooltip'
import { ProductDTO } from 'models/product'
import React from 'react'
import Stack from '@mui/material/Stack'
import classnames from 'classnames'
import { getProgressCategoryData } from './AssignmentProgressSteps.utils'
import styles from './AssignmentProgress.module.sass'
import { useTranslation } from 'react-i18next'

export interface Props {
  /** Current assignment id */
  assignmentId: string
  /** Current assignment stage */
  stage: AssignmentStage
  /** Current assignment product */
  products: Array<ProductDTO>
}

export const AssignmentProgress: React.FC<Props> = ({ assignmentId, stage, products }) => {
  const { t } = useTranslation(['gallery'])

  const steps = getProgressCategoryData(stage, products)

  if (!steps.length) return null

  const activeStepData = steps?.find((step) => step.activeStep)

  return (
    <Stack direction="column" flex={{ xs: '100%', md: '66%' }} className={styles.assignmentStageProgress}>
      <Stack direction="column">
        <span className={styles.status}>{t('order_informations.status')}</span>

        <Stack direction="row">
          <span className={classnames(styles.statusTitle, styles.jobDone)}>{t(`order_informations.stages.${activeStepData?.jobDone}`)}</span>
          {activeStepData?.jobTodo && <DotDivider color={GRAY_900} />}
          {activeStepData?.jobTodo && <span className={styles.statusTitle}>{t(`order_informations.stages.${activeStepData?.jobTodo}`)}</span>}
        </Stack>

      </Stack>

      <Stack direction="row">

        {steps!.map(({ jobDone, jobTodo, step }, index) => {
          const isProgressDone = activeStepData!.step === steps.length

          return (
            <MUITooltip
              key={`assignment_progress_${assignmentId}_${jobDone}`}
              id={`assignment_progress_${assignmentId}_${index}`}
              placement='bottom'
              content={<Stack direction="row">
                <span>{t(`order_informations.stages.${jobDone}`)}</span>
                {jobTodo && <DotDivider color={WHITE} />}
                {jobTodo && <span>{t(`order_informations.stages.${jobTodo}`)}</span>}
              </Stack>}>

              <div
                data-tip data-for={`assignment_progress_${assignmentId}_${index}`}
                key={index}
                className={classnames(styles.stage, {
                  [styles.done]: isProgressDone && step <= index + 1,
                  [styles.inProgress]: !isProgressDone && step <= activeStepData!.step,
                })}
              ></div>
            </MUITooltip>
          )
        })}

      </Stack>
    </Stack>
  )
}
