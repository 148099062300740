import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'
import { ActionUserMeAlternative } from '.'
import { UserMeStore } from '../UserMe'

/** Redux data reducer for user me alternative */
export const reduceUserMeAlternative = (state: UserMeStore, action: ActionUserMeAlternative) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.USER_ME_ALTERNATIVE) return state

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
      return new APIRequest(APIRequestState.BEFORE_START)
    case ActionTypeAPIEvent.FETCH:
      return new APIRequest(APIRequestState.RUNNING)
    case ActionTypeAPIEvent.RECEIVED:
      return action.payload.request
    default:
      return state
  }
}