import { ConstructionType, LightweightConstructionType, SolidConstructionType } from 'constants/purchaseFlow'
import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'

import { GRAY_900 } from 'constants/styling/theme'
import { Label } from 'components/common/Label'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import React from 'react'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import { ToggleButton } from 'components/common/ToggleButton'
import Typography from '@mui/material/Typography'
import { useConstructionMethod } from './_main/ConstructionMethod.context'
import { useTranslation } from 'react-i18next'

/**
 * @component Shape renders options for the user to select the global shape and construction type of the building.
 *
 * @example
 *   <Shape />
 */
export const Shape: React.FC = () => {

  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.construction_method_page' })

  const {
    constructionType,
    setConstructionType,
    lightweightConstructionType,
    setLightweightConstructionType,
    solidConstructionType,
    setSolidConstructionType,
  } = useConstructionMethod()

  return (
    <SectionedBorderBox
      title={
        <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
          {t('shape_title')}
        </Typography>
      }
    >
      <Stack
        sx={{
          flexDirection: { xs: 'column', lg: 'row' }
        }}
        spacing={2}
        width="100%"
      >

        <Stack alignItems="flex-start" gap={1.2} marginBottom={{ xs: '2.8rem', sm: 0 }}>

          <Label text={t('global_shape')} />

          {/* GLOBAL SHAPE TYPES BUTTONS SECTION */}
          <Stack gap={0.8} flexDirection="row" flexWrap="wrap">
            {Object.values(ConstructionType).map((type) => (
              <ToggleButton
                key={type}
                isSelected={constructionType === type}
                label={t(`global_shape_type.${type}`)}
                sx={{ width: '21.6rem', height: '4rem', justifyContent: 'center' }}
                onClick={() => setConstructionType(type)}
                typographyVariant='text-md'
              />
            ))}
          </Stack>

          {/* CONSTRUCTION SHAPE OPTIONS - SOLID CONSTRUCTION == MASSIVE CONSTRUCTION */}
          {constructionType && (
            <Stack gap="1.8rem" width={{ xs: '100%', lg: '55rem' }} position="relative">
              <MUIDropdown
                sx={{ width: '100%' }}
                button={(isOpen, action) => (
                  <MUIDropdownInput
                    readOnly
                    required
                    label={t(`global_shape_type.${constructionType}`)}
                    type={DropdownInputTypes.SELECTOR}
                    value={t(
                      `${constructionType === ConstructionType.MASSIVE_CONSTRUCTION
                        ? !!solidConstructionType
                          ? `solid_construction_type.${solidConstructionType}`
                          : 'select_solid_construction_option'
                        : !!lightweightConstructionType
                          ? `lightweight_construction_type.${lightweightConstructionType}`
                          : 'select_lightweight_construction_option'}`
                    )}
                    onClick={action}
                  />
                )}
              >
                {Object.values(constructionType === ConstructionType.MASSIVE_CONSTRUCTION ? SolidConstructionType : LightweightConstructionType).map(type => (
                  <MUIDropdownItem key={type} onClick={() => constructionType === ConstructionType.MASSIVE_CONSTRUCTION ? setSolidConstructionType(type) : setLightweightConstructionType(type)}>
                    <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                      {t(`${constructionType === ConstructionType.MASSIVE_CONSTRUCTION ? 'solid' : 'lightweight'}_construction_type.${type}`)}
                    </Typography>
                  </MUIDropdownItem>
                ))}
              </MUIDropdown>
            </Stack>
          )}

        </Stack>
      </Stack>

    </SectionedBorderBox>
  )
}
