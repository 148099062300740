import React, { Fragment } from 'react'

/** Splits string by \n and wraps each line with <p></p> or returns <br> if string empty */
export const multilineText = (text: string) => {
  return text.split('\n').map((line, index) => (
    <Fragment key={index}>
      {!!line ?
        <p>{line}</p>
        :
        <br />
      }
    </Fragment>
  ))
}