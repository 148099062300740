import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { Endpoint, RoleMimetype } from 'constants/API'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, put, takeEvery } from 'redux-saga/effects'

import API from 'utils/API/API'
import { ActionOrganizationMe } from './organizationMe.actions'

/** Saga for getting organization me */
export function* getOrganizationMeSaga(receivedAction: ActionOrganizationMe) {
  const url = Endpoint.ORGANIZATION_ME
  const action: ActionOrganizationMe = yield generalFetch(ActionTypeAPIData.ORGANIZATION_ME, () => API.get(
    url,
    {
      headers: {
        Accept: RoleMimetype.CLIENT, // TODO: Fix on BE so we can send proper accept header
      }
    },
    {
      endpoint: Endpoint.ORGANIZATION_ME
    }
  ))

  const organizationMeAction: ActionOrganizationMe = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload
    }
  }

  yield put(organizationMeAction)
}

/** Watcher for saga getting organization me */
export function* getOrganizationMeSagaWatcher() {
  yield all([
    takeEvery((action: ActionOrganizationMe) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.ORGANIZATION_ME]), getOrganizationMeSaga),
  ])
}
