import { useMemo, useState } from 'react'

import constate from 'constate'
import { useMarketingMaterials } from './MarketingMaterials.context'

/** Enum representing the different steps in the text flow generation process. */
export enum GenerateTextFlowStep {
  /** Represents the empty text flow step. */
  EMPTY = 'EMPTY',
  /** Represents the loading text flow step. */
  LOADING = 'LOADING',
  /** Represents the validated text flow step. */
  TEXT_EXISTS = 'TEXT_EXISTS',
}

export const [VisualsMarketingContextProvider, useVisualsMarketing] = constate(() => {

  const { generators, marketingMaterials } = useMarketingMaterials()

  const [isPropertyDetailsEditing, setIsPropertyDetailsEditing] = useState(false)

  /** Describes state of materials */
  const generateTextStep = useMemo<GenerateTextFlowStep>(() => {
    if (generators.REAL_ESTATE_BROCHURE.isPending && !marketingMaterials.REAL_ESTATE_BROCHURE) return GenerateTextFlowStep.LOADING
    if (marketingMaterials?.REAL_ESTATE_BROCHURE) return GenerateTextFlowStep.TEXT_EXISTS
    return GenerateTextFlowStep.EMPTY
  }, [generators.REAL_ESTATE_BROCHURE.isPending, marketingMaterials?.REAL_ESTATE_BROCHURE])

  return {
    generateTextStep,
    isPropertyDetailsEditing,
    setIsPropertyDetailsEditing,
  }
})
