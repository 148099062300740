import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { FC, Fragment, ReactNode } from 'react'

import ActionPopupStyles from './ActionPopup.module.sass'
import { ActionPopupTypes } from 'redux/Individual/ActionPopup'
import Button from 'components/common/Button/Button'
import Icon from 'components/common/Icon/Icon'
import { IconType } from 'constants/assets'
import classnames from 'classnames'
import closeButtonStyles from 'components/styles/close-button.module.sass'
import { createPortal } from 'react-dom'
import { multilineText } from 'utils/formatting'
import { useActionPopup } from 'utils/hooks'
import { useTranslation } from 'react-i18next'

/**
 * @component Display overlay popup requiring user's interaction, normally use in confirmation and notification purpose.
 * Controlled by showConfirm in useActionPopup() in global way. Can be used as independent component by setting disableGlobalActionPopup.
 * @example <ActionPopup isGlobal={false}>
 *  <span>Some text here</span>
 * </ActionPopup>
 */
export const ActionPopup: FC<{
  /** Decides if the ActionPopup is global (top-level) */
  isGlobal?: boolean
  /** Decided if the Confirm button is disabled */
  isConfirmDisabled?: boolean
  children?: ReactNode
}> = ({
  children,
  isGlobal = false,
  isConfirmDisabled = false,
}) => {
    const { t } = useTranslation(['action_popup'])
    const {
      onConfirmAccept,
      onConfirmDeny,
      onAlertClose,
      actionPopupState
    } = useActionPopup()
    const {
      type,
      body,
      isShown,
      options: {
        title,
        alertCloseText,
        confirmDenyText,
        confirmAcceptText,
        confirmDisabledText,
        disableGlobalActionPopup,
        hideSecondaryButton,
      }
    } = actionPopupState

    const actionPopup: React.ReactNode = (
      <TransitionGroup component={null}>
        {isShown && !(isGlobal && disableGlobalActionPopup) &&
          <CSSTransition
            in={isShown}
            timeout={150}
            classNames={{
              enter: ActionPopupStyles.popupEnter,
              enterActive: ActionPopupStyles.popupEnterActive,
              exit: ActionPopupStyles.popupExit,
              exitActive: ActionPopupStyles.popupExitActive,
            }}
          >
            <div className={ActionPopupStyles.backdrop} onClick={(e) => e.stopPropagation()}>
              <div className={ActionPopupStyles.content}>
                <Button className={closeButtonStyles.closeBtnRight} type="secondary noborder" onClick={() => onConfirmDeny()}>
                  <Icon icon={IconType.CROSS} />
                </Button>
                {title && <div className={ActionPopupStyles.title}>{title}</div>}
                <div className={ActionPopupStyles.body}>
                  {!!children
                    ? children
                    : typeof body === 'string'
                      ? multilineText(body)
                      : body
                  }
                </div>

                {type === ActionPopupTypes.ALERT &&
                  <div className={ActionPopupStyles.actions}>
                    <Button onClick={(e) => onAlertClose()}>{alertCloseText || t('action_popup:close')}</Button>
                  </div>
                }

                {type === ActionPopupTypes.CONFIRM &&
                  <Fragment>

                    <div className={classnames(ActionPopupStyles.actions, { [ActionPopupStyles.hiddenSecondaryButtonActions]: hideSecondaryButton })}>
                      {!hideSecondaryButton && <Button type="secondary" onClick={() => onConfirmDeny()}>{confirmDenyText || t('action_popup:deny')}</Button>}
                      <Button disabled={isConfirmDisabled} onClick={() => onConfirmAccept()}>{confirmAcceptText || t('action_popup:confirm')}</Button>
                    </div>
                    {isConfirmDisabled &&
                      <div className={ActionPopupStyles.disabledMessage}>{confirmDisabledText}</div>
                    }
                  </Fragment>
                }
              </div>
            </div>
          </CSSTransition>
        }
      </TransitionGroup>
    )

    return createPortal(actionPopup, document.querySelector('.App') || document.getElementById('root') || document.body)
  }
