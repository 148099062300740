import { BEIGE_600, GRAY_700, GRAY_900, WHITE } from 'constants/styling/theme'
import { FileRejection, useDropzone } from 'react-dropzone'
import { MessageDTO, MessageThreadDTO, MessageThreadState } from 'models/messageThreads'
import { MessageType, ThreadMessage } from './ThreadMessage'
import { Options, useFileAPI } from 'components/common/FileAPI'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import Box from '@mui/material/Box'
import { DraggableAreaView } from '../DraggableAreaView'
import Fade from '@mui/material/Fade'
import { MUIDivider } from 'components/common/MUIDivider'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import { ThreadInput } from './ThreadInput'
import { ThreadStatusBadge } from './ThreadStatusBadge.component'
import Typography from '@mui/material/Typography'
import { acceptedMimetypesAny } from 'constants/misc'
import moment from 'moment-timezone'
import { standardTimeoutConfig } from 'utils/animations'
import { useFetchMessageFiles } from './hooks'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useThreads } from '../context'
import { useTranslation } from 'react-i18next'

/** Represents an item in a thread message. */
export interface ThreadMessageItem extends MessageDTO {
  /** An optional file attached to the message. */
  attachedFilenames?: string[]
}

/** @interface Props for the Thread component extends. */
export interface Props extends MessageThreadDTO {
  /** ID of the assignment associated with the thread.  */
  assignmentId: string
  /** The messages in the thread. */
  messages: ThreadMessageItem[]
  /** Options for the file upload API handler. */
  fileUploadOptions: Options
}

const MAX_NUMBER_OF_FILES = 5

/**
 * @component
 * Thread component that represents a discussion thread.
 * 
 * @example
 * <Thread
 *   id="1"
 *   title="Discussion Thread"
 *   state="open"
 *   messages={[{ timestamp: new Date(), content: "Hello", attachedFiles: [], authorId: "123" }]}
 *   createdAt={new Date()}
 *   assignmentId="assignment-1"
 * />
 */
export const Thread: React.FC<Props> = ({
  id,
  title,
  state,
  messages,
  createdAt,
  assignmentId,
  fileUploadOptions,
}) => {
  const { t } = useTranslation(['threads', 'upload_files'])
  const { sendAssignmentMessageThread } = useThreads()
  const { spawnWarningToast } = useSnackbar()

  const messagesEndRef = useRef<HTMLDivElement | null>(null)

  const { isFetchingMessageFiles, messageFiles } = useFetchMessageFiles(messages, assignmentId)

  const scope = useMemo(() => `thread-${id.toString()}`, [id])

  /** Initializes a file upload handler for a thread component using the `useFileAPI` hook.  */
  const threadFileUpload = useFileAPI(scope, { ...fileUploadOptions })

  const isSendingMessage = useMemo(() => {
    return sendAssignmentMessageThread.isPending && sendAssignmentMessageThread.variables?.threadId === id
  }, [id, sendAssignmentMessageThread.isPending, sendAssignmentMessageThread.variables?.threadId])

  const isFirstMessageSentByUser = useMemo(() => {
    return messages.length > 0 && !!messages[0].authorId
  }, [messages])

  /**
   * Handles the drop event for file uploads.
   * 
   * This function performs the following tasks:
   * 1. Checks for file rejections and displays appropriate warnings if any.
   * 2. Filters out duplicate file names from the accepted files.
   * 3. Displays a warning if there are duplicate file names.
   * 4. Initiates the upload process for the accepted files.
   */
  const onDrop = useCallback(async (acceptedFiles: File[], fileRejections: FileRejection[]) => {

    const duplicateFileNames: string[] = []
    const okFiles: File[] = []

    if (fileRejections && fileRejections.length > 0) {
      if (fileRejections.length > 1) {
        spawnWarningToast(
          t('upload_files:unsupported_upload_max_number_files', { MAX_NUMBER_OF_FILES }),
          { timeout: 5000 }
        )
        return
      }

      const formats: string[] = []
      for (let fileRejection of fileRejections) {
        const split = fileRejection.file.name.split('.')
        if (split.length < 2) formats.push(fileRejection.file.name)
        else formats.push(split.slice(1).join('.'))
      }

      const formatsString = Array.from(new Set(formats).keys()).join(', ')
      spawnWarningToast(
        `${t('upload_files:unsupported_file_format')}\n${formatsString}`,
        { timeout: 5000 }
      )
    }

    for (let file of acceptedFiles) {
      if (!threadFileUpload.allFilesArray.find(foundFile => foundFile.originalFilename === file.name)) {
        okFiles.push(file)
      } else duplicateFileNames.push(file.name)
    }

    if (duplicateFileNames.length > 0) {
      spawnWarningToast(
        t('upload_files:duplicate_file_alert', { files: duplicateFileNames.join(', ') }),
        { timeout: 5000 }
      )
      return
    }

    threadFileUpload.uploadFiles(acceptedFiles)

  }, [spawnWarningToast, t, threadFileUpload])

  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    noClick: true,
    accept: acceptedMimetypesAny,
    maxFiles: MAX_NUMBER_OF_FILES,
    disabled: state === MessageThreadState.CLOSED,
    onDrop,
  })

  /** Handles the click event from ThreadInput submitting an attachment file. */
  const handleSubmitAttachmentClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight
    }
  }, [])

  // Scroll to the bottom of the thread when the messages and attachments are loaded.
  useEffect(() => {
    if (messages.length > 0 && !isFetchingMessageFiles) {
      scrollToBottom()
    }
  }, [messages.length, scrollToBottom, messageFiles, isFetchingMessageFiles])

  return (
    <SectionedBorderBox
      borderColor={BEIGE_600}
      sx={{
        flex: 1,
        padding: 0,
        height: '100%',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
      }}
      title={
        <Stack direction="row" justifyContent="space-between" padding="1rem 2rem 0">

          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography variant="text-md" fontWeight={600} color={GRAY_900}>{title}</Typography>

            {state && <ThreadStatusBadge status={state} />}
          </Stack>

          {createdAt &&
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Typography variant="text-sm" fontWeight={500} color={GRAY_700}>
                {`${t('started')}:`}
              </Typography>

              <Typography variant="text-sm" color={GRAY_900}>
                {moment(createdAt).format('DD/MM/YYYY')}
              </Typography>

            </Stack>
          }

        </Stack>
      }
    >
      <Box position="relative" display="contents" {...getRootProps()}>

        <Stack
          sx={{
            flexGrow: 1,
            maxHeight: '40rem',
            overflowY: 'auto',
            marginTop: '-1.2rem',
            padding: '0 2rem 2rem 2rem',
          }}
          ref={messagesEndRef}
        >

          {/** MESSAGES */}
          {messages.length > 0 && messages.map(({ timestamp, content, authorId, id }) => {
            // Determine the type of message (sent or received). If there is no authorId, it is a received message.
            const type: MessageType = !authorId ? 'received' : 'sent'

            return (
              <Fade in={!isFetchingMessageFiles} timeout={standardTimeoutConfig} key={timestamp}>
                <Stack gap={1.5} flex={1} marginTop={1.5} justifyContent={isFirstMessageSentByUser ? 'flex-end' : 'flex-start'} alignItems={type === 'received' ? 'flex-start' : 'flex-end'}>

                  <Typography variant="text-sm" color={GRAY_700} alignSelf={{ xs: 'center' }}>
                    {moment(timestamp).format('dddd DD MMM, HH:mm')}
                  </Typography>

                  <ThreadMessage
                    type={type}
                    message={content}
                    attachedFiles={messageFiles.get(id)}
                    width={{ xs: '95%', sm: '80%', md: '45rem' }}
                  />

                </Stack>
              </Fade>
            )
          })}

          {isSendingMessage &&
            <Fade in={true} timeout={standardTimeoutConfig}>
              <Stack gap={1.5} flex={1} marginTop={1.5} alignItems="flex-end" justifyContent="flex-end">
                <ThreadMessage
                  type="sent"
                  message=""
                  isSending={true}
                  attachedFiles={[]}
                />
              </Stack>
            </Fade>
          }

        </Stack>

        {/** FOOTER */}
        {messages.length > 0 &&
          <Box sx={{
            bottom: 0,
            zIndex: 1,
            position: 'sticky',
            backgroundColor: WHITE,
            borderRadius: '0 0 .8rem .8rem',
          }}>
            <MUIDivider margin={2} />

            <Box padding={1}>
              <ThreadInput
                threadId={id}
                assignmentId={assignmentId ?? ''}
                isDisabled={state === MessageThreadState.CLOSED || isDragActive}
                attachedFiles={threadFileUpload.allFilesArray}
                isSendingMessage={isSendingMessage}
                onUploadClick={handleSubmitAttachmentClick}
                onDeleteFile={(filesId) => threadFileUpload.deleteFiles(filesId)}
                onPurgeFiles={() => threadFileUpload.allFilesArray.length > 0 && threadFileUpload.purgeFilesScope(scope)}
              />
            </Box>
          </Box>
        }

        {/** DRAG AND DROP OVERLAY (only if thread is OPEN) */}
        {isDragActive && <DraggableAreaView threadName={title} />}
        <input {...getInputProps()} />

      </Box>
    </SectionedBorderBox>
  )
}
