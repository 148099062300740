import { AnimationComponent } from '../animations.interfaces'
import { Transition } from 'react-transition-group'
import gsap from 'gsap'

/** 
 * Slide-fade in on Enter and slide-fade out on Exit   
 * Slide is from top-down
*/
export const SlideFadeAppearHide: AnimationComponent = ({
  children,
  visible,
  delay = 0,
  exitDelay = 0,
}) => {

  return (
    <Transition
      appear={true}
      in={visible}
      onEnter={(node: HTMLElement) => {
        gsap.from(node, { autoAlpha: 0, translateY: -20, duration: .3, delay: delay / 1000 })
      }}
      addEndListener={(node: HTMLElement, done) => {
        gsap.to(node, { autoAlpha: 0, translateY: 20, duration: .3, delay: exitDelay / 1000, onComplete: done })
      }}
      timeout={300}
      unmountOnExit
      mountOnEnter
    >
      {children}
    </Transition>
  )
}
