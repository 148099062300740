import { BEIGE_300, BOX_SHADOW_BOTTOM, GRAY_300, WHITE } from 'constants/styling/theme'
import { Box, Stack } from '@mui/material'
import { FC, ReactNode } from 'react'

import { Close } from '@mui/icons-material'
import { MUIButton } from 'components/common/MUIButton'
import { MUIStepper } from 'components/common/MUIStepper'
import styles from './ActionPage.module.sass'

interface Props {
  /** DOM id of the Action page */
  id: string
  /** Content of the Action page */
  children: ReactNode
  /** Action page header title */
  title?: string
  /** Action page header subtitle */
  subtitle?: string
  /** Action page header onClose callback */
  onClose: () => void
  /** Content of the Action page footer */
  footerChildren?: ReactNode
  /** Stepper active step */
  flowStepperActiveStep?: number
  /** Stepper steps */
  flowStepperSteps?: Array<string>
  /** Custom stepper component */
  customStepper?: ReactNode
}

/**
 * Action Page component with header and custom footerChildren
 * @example <ActionPage id={id} title={title} onClose={onClose} footerChildren={<div>footerContent</div>}/>
 */
export const ActionPage: FC<Props> = ({
  id,
  children,
  title,
  subtitle,
  footerChildren,
  flowStepperActiveStep = 0,
  flowStepperSteps,
  customStepper,
  onClose,
}) =>
  <Stack
    id={`actionPage-${id}`}
    height="100vh"
    justifyContent="flex-start"
    bgcolor={BEIGE_300}
    overflow="hidden"
  >

    {/* HEADER */}
    <Stack
      flex="0 0 auto"
      direction="row"
      flexWrap="nowrap"
      paddingLeft="2rem"
      paddingRight="2rem"
      gap={2}
      justifyContent="space-evenly"
      alignItems="center"
      height="6.8rem"
      bgcolor={WHITE}
      boxShadow={BOX_SHADOW_BOTTOM}
    >

      {(title || subtitle) && (
        <Stack flex="1 1 25%" direction="row" alignItems="baseline">
          {title && <span className={styles.title}>{title}</span>}
          {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
        </Stack>
      )}

      {flowStepperSteps && (
        <Stack
          flex="1 1 50%"
          direction="row"
          justifyContent="center"
        >
          <MUIStepper steps={flowStepperSteps} activeStep={flowStepperActiveStep} />
        </Stack>
      )}

      {customStepper &&
        <Box flex="1 1 50%">
          {customStepper}
        </Box>
      }

      <Stack
        flex="1 1 25%"
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <MUIButton
          variant="outlined"
          type="secondaryBorder"
          className={styles.closeButton}
          onClick={() => onClose()} isIconButton
        >
          <Close fontSize='large' />
        </MUIButton>
      </Stack>

    </Stack>

    {/* CONTENT */}
    <Box
      flex="1 1 100%"
      width="100%"
      height="100%"
      overflow="hidden"
    >
      {children}
    </Box>

    {/* FOOTER */}
    {footerChildren && (
      <Box
        flex="0 0 6.8rem"
        width="100%"
        height="6.8rem"
        borderTop={`.1rem solid ${GRAY_300}`}
        bgcolor={WHITE}
      >
        {footerChildren}
      </Box>
    )}

  </Stack>
