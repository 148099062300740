import { Fragment, useMemo } from 'react'

import { DealDTO } from 'models/deal'
import { ManageAssignmentOrderDateTimeChange } from './ManageAssignmentOrderDateTimeChange.component'
import { ManageAssignmentsPopupItem } from './ManageAssignmentsPopupItem'
import Modal from '../Modals/Modal/Modal'
import { ProductCategory } from 'models/product'
import styles from './ManageAssignmentsPopup.module.sass'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** The additional classes to append */
  className?: string
  /** Decides when is modal open */
  isOpen: boolean
  /** The deal data object */
  dealData: DealDTO
  /** Id of currently viewed assignment */
  currentAssignmentId: string
  /** Onclick action triggered when user clicks outside the modal content element */
  onClickOutside?: (e: React.MouseEvent) => unknown
  /** onKeyDown action triggered when user presses any key on the keyboard */
  onKeyDown?: (e: React.KeyboardEvent) => unknown
  /** Onclick action triggered when user clicks the close button */
  onClickClose?: (e: React.MouseEvent) => unknown
}

/**
 * @component Display manage assignments detail in a popup for admin
 * @example
 * <ManageAssignmentsPopup isOpen={true} dealData={deal} currentAssignmentId={assignment.id} />
 */
export const ManageAssignmentsPopup: React.FC<Props> = ({
  className = '',
  isOpen,
  dealData,
  currentAssignmentId,
  onClickOutside,
  onKeyDown,
  onClickClose,
}) => {
  const { t } = useTranslation(['manage_assignments_popup', 'deal_assignment', 'product_kind'])

  const category = useMemo(() => dealData.productCategory as ProductCategory, [dealData])
  const currentAssignment = useMemo(() => dealData.missions.find((assignment) => assignment.id === currentAssignmentId), [dealData, currentAssignmentId])
  const otherAssignments = useMemo(() => dealData.missions.filter((assignment) => assignment.id !== currentAssignmentId && !assignment.isDeleted), [dealData, currentAssignmentId])

  return (
    <Modal
      className={`${styles.manageAssignmentsPopup} ${className}`.trim()}
      modalContentClassName={styles.modalContent}
      isOpen={isOpen}
      onClickOutside={onClickOutside}
      onKeyDown={onKeyDown}
      onClose={onClickClose}
      title={t('title')}
    >

      <div className={styles.inside}>

        {otherAssignments.length > 0 &&
          <ManageAssignmentOrderDateTimeChange dealData={dealData} />
        }

        <div className={styles.currentAssignment}>

          {otherAssignments.length > 0 && <h4 className={styles.subtitle}>{t('current_assignment')}</h4>}

          {!!currentAssignment &&
            <ManageAssignmentsPopupItem
              assignment={currentAssignment}
              category={category}
            />
          }

        </div>

        {otherAssignments.length > 0 &&
          <div className={styles.assignmentList}>

            <h4 className={styles.subtitle}>{t('related_assignments')}</h4>

            <div className={styles.productList}>
              {otherAssignments.map((assignment, index) =>
                <Fragment key={assignment.id}>

                  <ManageAssignmentsPopupItem
                    assignment={assignment}
                    category={category}
                  />

                  {index < (otherAssignments.length - 1) && <div className={styles.divider}></div>}

                </Fragment>
              )}
            </div>

          </div>
        }

      </div>
    </Modal>
  )
}
