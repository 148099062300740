import { ActionRequest, StatusResponseBody } from 'models/redux'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { FileManipulationKey, VisualFileType } from 'constants/visual'

import { APIRequest } from 'models/API'
import { CancelTokenSource } from 'axios'
import { UrlDTO } from 'models/visuals'
import { createAPIAction } from 'redux/Helpers'

/** Describes general action to load purchase flow session visual */
export interface ActionLoadPurchaseSessionVisual extends ActionRequest {
  payload: {
    manipulation: FileManipulationKey
    sessionId: string
    productId: number
    // TODO: Set productSerial type to number (productSerial: number)
    productSerial: 1
    file: File
    type: VisualFileType
    request: APIRequest<UrlDTO | null | StatusResponseBody>
    email?: string
    signedUrl?: string
    cancelToken?: CancelTokenSource
    progress?: number
    originalFilename?: string
    replaces?: string
    droppedIn?: number
  },
}

/** Action which initializes upload of a file in purchase flow session */
export function uploadPurchaseSessionVisual(sessionId: string, productId: number, productSerial: number = 1, file: File, email?: string): ActionLoadPurchaseSessionVisual {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.LOAD_PURCHASE_SESSION_VISUAL], {
    manipulation: FileManipulationKey.UPLOAD,
    sessionId,
    productId,
    productSerial,
    file,
    email,
    type: VisualFileType.RAW,
    droppedIn: Date.now(),
    request: new APIRequest()
  })
}

/** Action which initializes upload of a file replacement in purchase flow session */
export function uploadPurchaseSessionVisualReplacement(sessionId: string, productId: number, productSerial: number = 1, replaces: string, file: File, email?: string): ActionLoadPurchaseSessionVisual {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.LOAD_PURCHASE_SESSION_VISUAL], {
    manipulation: FileManipulationKey.UPLOAD,
    sessionId,
    productId,
    productSerial,
    file,
    email,
    type: VisualFileType.RAW,
    replaces,
    droppedIn: Date.now(),
    request: new APIRequest()
  })
}

/** Action which initializes download of a file in purchase flow session */
export function downloadPurchaseSessionVisual(sessionId: string, productId: number, productSerial: number = 1, filename: string, type: Extract<VisualFileType, VisualFileType.RAW_THUMB | VisualFileType.RAW_WEB>, originalFilename?: string, droppedIn?: number): ActionLoadPurchaseSessionVisual {
  return createAPIAction<ActionLoadPurchaseSessionVisual>([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.LOAD_PURCHASE_SESSION_VISUAL], {
    manipulation: FileManipulationKey.DOWNLOAD,
    sessionId,
    productId,
    productSerial,
    type,
    file: new File([], filename),
    originalFilename,
    droppedIn,
    request: new APIRequest()
  })
}

/** Action which initializes deletion of a file in purchase flow session */
export function deletePurchaseSessionVisual(sessionId: string, productId: number, productSerial: number = 1, filename: string): ActionLoadPurchaseSessionVisual {
  return createAPIAction<ActionLoadPurchaseSessionVisual>([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.LOAD_PURCHASE_SESSION_VISUAL], {
    manipulation: FileManipulationKey.DELETE,
    sessionId,
    productId,
    productSerial,
    type: VisualFileType.RAW,
    file: new File([], filename),
    request: new APIRequest()
  })
}