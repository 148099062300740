import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'
import { ActionRequest } from 'models/redux'
import { CreateWorkspaceSubscriptionStore } from './createWorkspaceSubscription.interfaces'
import { Nullable } from 'models/helpers'
import { SubscriptionPeriod } from 'models/user'
import { createAPIAction } from 'redux/Helpers'

/** Describes create workspace subscription action  */
export interface ActionCreateWorkspaceSubscription extends ActionRequest {
  payload: {
    workspaceName: string,
    members: string[]
    workspaceId: Nullable<string>
    period: SubscriptionPeriod,
    request: CreateWorkspaceSubscriptionStore
  },
}

/** Creates create workspace subscription action */
export function createWorkspaceSubscription(
  workspaceName: string,
  members: string[] = [],
  period: SubscriptionPeriod,
  workspaceId: Nullable<string> = null
): ActionCreateWorkspaceSubscription {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.CREATE_WORKSPACE_SUBSCRIPTION], {
    workspaceName,
    members,
    period,
    workspaceId,
    request: new APIRequest(APIRequestState.RUNNING),
  })
}

/** Purges stage of create workspace subscription action */
export function purgeCreateWorkspaceSubscription(): ActionCreateWorkspaceSubscription {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.CREATE_WORKSPACE_SUBSCRIPTION], {
    request: new APIRequest(APIRequestState.BEFORE_START),
  })
}
