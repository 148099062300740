import { Positioning } from 'constants/misc/editingOptions'
import React from 'react'
import classnames from 'classnames'
import styles from './EditorOptionPositionning.module.sass'
import { useTranslation } from 'react-i18next'
import { useVisualsEditing } from 'components/pages/VisualsEditing/VisualsEditing.context'

interface Props {
  /** Callback to check if selection is active */
  isSelected: (key: string, enumType: any, selected: string) => boolean
}

/**
 * @component EditorOptionPositionning 
 * @example
 * <EditorOptionPositionning isSelected={isSelected}/>
 */
export const EditorOptionPositionning: React.FC<Props> = ({ isSelected }) => {
  const {
    selectedWatermarkPosition,
    setSelectedWatermarkPosition,
    handleEditingOptionChange
  } = useVisualsEditing()

  const { t } = useTranslation(['visuals_editing'])

  return (<div className={styles.positionningContainer}>
    <div className={styles.positionningVisual}>
      <div className={styles.positionningGrid}>
        {(Object.keys(Positioning) as (keyof typeof Positioning)[]).map(
          (key, idx) => {
            return (
              <div
                key={idx}
                className={classnames(styles.position, { [styles.selected]: isSelected(key, Positioning, selectedWatermarkPosition) })}
                onClick={() => handleEditingOptionChange(() => setSelectedWatermarkPosition(Positioning[key]))}
              >
                <div className={classnames(styles.dot, { [styles.selected]: isSelected(key, Positioning, selectedWatermarkPosition) })}></div>
              </div>
            )
          }
        )}
      </div>
    </div>
    <span className={styles.selectedPositionTitle}>{t(`visuals_editing:editor_options:watermark_positioning.${selectedWatermarkPosition}`)}</span>
  </div>
  )
}
