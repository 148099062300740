import { BasementCeilingType, BasementType, ConstructionType, LightweightConstructionType, RoofConditionType, RoofPitchType, SolidConstructionType, ThermalBridgeType, WindowType } from 'constants/purchaseFlow'

import { Nullable } from 'models/helpers'
import constate from 'constate'
import { useState } from 'react'

export const [ConstructionMethodContextProvider, useConstructionMethod] = constate(() => {
  const [constructionType, setConstructionType] = useState<ConstructionType>(ConstructionType.MASSIVE_CONSTRUCTION)
  const [lightweightConstructionType, setLightweightConstructionType] = useState<LightweightConstructionType | null>(null)
  const [solidConstructionType, setSolidConstructionType] = useState<SolidConstructionType | null>(null)
  const [roofConditionType, setRoofConditionType] = useState<RoofConditionType | null>(null)
  const [basementType, setBasementType] = useState<BasementType | null>(null)
  const [basementCeilingType, setBasementCeilingFloorType] = useState<BasementCeilingType | null>(null)
  const [windowType, setWindowType] = useState<WindowType | null>(null)
  const [thermalBridgeType, setThermalBridgeType] = useState<ThermalBridgeType>(ThermalBridgeType.STANDARD)
  const [subsequentRoofInsulation, setSubsequentRoofInsulation] = useState<Nullable<number>>(null)
  const [subsequentBasementInsulation, setSubsequentBasementInsulation] = useState<Nullable<number>>(null)
  const [soilDepth, setSoilDepth] = useState<string>('')
  const [heatedBasementArea, setHeatedBasementArea] = useState<Nullable<number>>(null)
  const [roofPitchType, setRoofPitchType] = useState<RoofPitchType>(RoofPitchType.FLAT_ROOF)
  const [numberOfFullFloors, setNumberOfFullFloors] = useState<Nullable<number>>(1)
  const [floorHeight, setFloorHeight] = useState<string>('')
  const [yearOfManufacture, setYearOfManufacture] = useState<Nullable<number>>(null)

  return {
    constructionType,
    setConstructionType,
    basementType,
    setBasementType,
    lightweightConstructionType,
    setLightweightConstructionType,
    roofConditionType,
    setRoofConditionType,
    solidConstructionType,
    setSolidConstructionType,
    basementCeilingType,
    setBasementCeilingFloorType,
    windowType,
    setWindowType,
    thermalBridgeType,
    setThermalBridgeType,
    subsequentRoofInsulation,
    setSubsequentRoofInsulation,
    subsequentBasementInsulation,
    setSubsequentBasementInsulation,
    roofPitchType,
    setRoofPitchType,
    numberOfFullFloors,
    setNumberOfFullFloors,
    floorHeight,
    setFloorHeight,
    yearOfManufacture,
    setYearOfManufacture,
    soilDepth,
    setSoilDepth,
    heatedBasementArea,
    setHeatedBasementArea,
  }
})
