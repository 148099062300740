import { Context, createContext, useContext } from 'react'

import { FieldValues } from 'react-hook-form'
import { GetFormContextReturn } from 'models/forms/getFormContext'

/**
 * Creates a Context and Hook suited for creating form providers with 'useExtendedForm'
 * @returns [Context, Hook]
 * @typeParam T - Interface describing form values
 * @example 
 * interface FormValues {
 *  foo: string
 *  bar: string
 * }
 * const [FieldsContext, useFieldsContext] = getFormContext<FormValues>()
 */
export function getFormContext<T extends FieldValues>(): [Context<GetFormContextReturn<T>>, () => GetFormContextReturn<T>] {
  const formContext = createContext<GetFormContextReturn<T>>({
    fields: undefined,
    formUtils: undefined
  })
  const useFormContext = (): GetFormContextReturn<T> => useContext(formContext)

  return [formContext, useFormContext]
}
