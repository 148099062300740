import { Box, Typography } from '@mui/material'
import { FC, ReactNode } from 'react'

import { Color } from 'constants/assets'
import DoneRoundedIcon from '@mui/icons-material/DoneRounded'
import { MUIButton } from 'components/common/MUIButton'
import Stack from '@mui/material/Stack'

interface Props {
  /** Title of the popup */
  title: string
  /** Subtitle of the popup */
  subTitle: string
  /** Button label */
  buttonLabel: ReactNode
  /** OnClick action */
  onClick: () => void
}

/**
 * Gallery payment success popup.
 * @example <GalleryPaymentSuccessPopup title="success" subTitle="woolala" buttonLabel="Don't click!" onClick={() => {}} />
 */
export const GalleryPaymentSuccessPopup: FC<Props> = ({
  title,
  subTitle,
  buttonLabel,
  onClick
}) =>
  <Stack flexDirection="column" alignItems="center" gap="1.5rem">
    <DoneRoundedIcon sx={{ fontSize: '6rem', color: Color.SECONDARY_GREEN_DARK }} />
    <Typography variant='h6'>{title}</Typography>
    <Typography variant='text-sm' fontWeight='medium'>{subTitle}</Typography>
    <Box sx={{ padding: '2rem 0' }}>
      <MUIButton onClick={onClick}>{buttonLabel}</MUIButton>
    </Box>
  </Stack>
