import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { cloneDeep } from 'lodash'
import { ActionSelectVisualsForStaging } from '.'
import { SelectVisualsForStagingStore } from './SelectVisualsForStaging.interfaces'

/** Redux data reducer for select visuals for staging */
export const reduceSelectVisualsForStaging = (state: SelectVisualsForStagingStore, action: ActionSelectVisualsForStaging) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.SELECT_VISUALS_FOR_STAGING) return state

  const { stagingAssignmentId, request } = action.payload
  const newState = cloneDeep(state)

  switch (eventType) {
    case ActionTypeAPIEvent.FETCH:
    case ActionTypeAPIEvent.BEFORE_START:
    case ActionTypeAPIEvent.RECEIVED:
      return {
        ...newState,
        [stagingAssignmentId]: request
      }
    default:
      return state
  }
}
