import { useEffect, useMemo, useState } from 'react'

import { usePurchaseFlowProducts } from 'components/pages/PurchaseFlow/_main/contexts'
import constate from 'constate'
import { useGetAvailableCTs$ } from 'dataQueries/purchase.query'
import { Nullable } from 'models/helpers'
import { FavouriteCreative } from 'models/purchaseFlow'

export const [BookCreativeContextProvider, useBookCreative] = constate(() => {

  const { selectedProductOptions, selectedProducts } = usePurchaseFlowProducts()

  const getAvailableCTs = useGetAvailableCTs$()

  const availableCreatives = useMemo(() => {
    if (!getAvailableCTs.isSuccess) return []
    const { personalCreatives } = getAvailableCTs.data.data.data

    return personalCreatives
  }, [getAvailableCTs.data?.data.data, getAvailableCTs.isSuccess])

  const workspaceCreatives = useMemo(() => {
    if (!getAvailableCTs.isSuccess) return []

    return getAvailableCTs.data.data.data.workspaceCreatives ?? []
  }, [getAvailableCTs?.data?.data?.data, getAvailableCTs.isSuccess])

  const [selectedCreative, setSelectedCreative] = useState<Nullable<FavouriteCreative>>(null)

  useEffect(() => {
    getAvailableCTs.reset()

    // Omit the mutation to prevent looping
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducts, selectedProductOptions])

  return {
    availableCreatives,
    workspaceCreatives,
    selectedCreative,
    getAvailableCTs,
    setSelectedCreative,
  }
})
