import Button from 'components/common/Button/Button'
import { FC } from 'react'
import { Trans } from 'react-i18next'
import styles from './SignupCard.module.sass'

interface Props {
  /** Sign up card title */
  title: string,
  /** Sign up card image */
  image: string,
  /** Sign up card description */
  description: string,
  /** Sign up card CTA */
  button: string,
  /** Sign up card note under the card */
  note?: string,
  /** OnClick action leads to sign up flow */
  onClick: () => void,
}

/**
 * Sign up Card 
 * @example
 * <SignupCard title="Client" image="card_img" description="tl;dr" button="Sign up" onClick={() => {}} /> 
 */
export const SignupCard: FC<Props> = ({
  title,
  image,
  description,
  button,
  onClick,
  note
}) => {
  return (
    <div className={styles.signupCard}>
      <div className={styles.cardWrap}>
        <div className={styles.cardContent}>
          <div className={styles.title}>{title}</div>
          <img className={styles.image} src={image} alt="" />
          <div className={styles.description}>
            <Trans parent="p">
              {description}
            </Trans>
          </div>
        </div>
        <Button
          className={styles.button}
          onClick={onClick}>
          {button}
        </Button>
      </div>
      <div className={styles.note}>{note}</div>
    </div>
  )
}
