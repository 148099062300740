import { ActionRequest, RootStore } from 'models/redux'
import { Endpoint, RoleMimetype } from 'constants/API'
import { call, put } from 'redux-saga/effects'
import { listPurchaseSessionVisuals, listPurchaseSessionVisualsSaga } from '../ListPurchaseSessionVisuals'

import API from 'utils/API/API'
import { ActionLoadPurchaseSessionVisual } from '../LoadPurchaseSessionVisual'
import { ActionTypeAPIData } from 'constants/redux'
import { FileManipulationKey } from 'constants/visual'
import { generalFetch } from 'redux/Helpers'
import { select } from 'typed-redux-saga'

/** Saga which handles deletion of purchase flow session visual */
export function* deletePurchaseSessionVisualSaga(receivedAction: ActionLoadPurchaseSessionVisual) {
  const { sessionId, productId, productSerial, type, file } = receivedAction.payload
  const cancelToken = yield* select((state: RootStore) => state.APIData[ActionTypeAPIData.LOAD_PURCHASE_SESSION_VISUAL]?.[sessionId]?.[productId]?.[productSerial]?.[FileManipulationKey.UPLOAD]?.[file.name]?.[type]?.cancelToken)
  if (cancelToken) cancelToken.cancel()

  const url = Endpoint.VISUAL_PURCHASE_SESSION_DELETE
    .replace('{sessionId}', encodeURIComponent(sessionId.toString()))
    .replace('{filename}', encodeURIComponent(file.name.toString()))

  const actionFetch: ActionRequest = yield generalFetch(ActionTypeAPIData.LOAD_PURCHASE_SESSION_VISUAL, () => API.delete<null>(url, {
    headers: {
      Accept: RoleMimetype.CLIENT, // TODO: Fix on BE so we can send proper accept header
    },
  }, {
    endpoint: Endpoint.VISUAL_PURCHASE_SESSION_DELETE,
  }))

  const deletedAction: ActionLoadPurchaseSessionVisual = {
    ...actionFetch,
    payload: {
      ...receivedAction.payload,
      ...actionFetch.payload,
    }
  }

  yield put(deletedAction)

  const getListedVisuals = (state: RootStore) => state.APIData[ActionTypeAPIData.LIST_PURCHASE_SESSION_VISUALS]?.[sessionId]
  const listedVisuals = yield* select(getListedVisuals)

  for (const productVisuals of Object.values(listedVisuals?.data?.visuals || {})) {
    if (productVisuals?.includes(file.name)) {
      yield call(listPurchaseSessionVisualsSaga, listPurchaseSessionVisuals(sessionId))
    }
  }
}
