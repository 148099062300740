import { APIRequestErrorType, Endpoint, JobStatus, RoleMimetype } from 'constants/API'
import { ActionDownloadArchiveVisuals, downloadArchiveVisuals } from '.'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, call, delay, put, takeEvery } from 'typed-redux-saga'

import API from 'utils/API/API'
import { APIRequest } from 'models/API'
import { ActionArchiveVisuals } from '../ArchiveVisuals/ArchiveVisuals.actions'
import { AxiosError } from 'axios'
import { DownloadArchiveResponse } from 'models/visuals'
import { JobDTO } from 'models/misc'
import { cloneDeep } from 'lodash'
import { decideErrorType } from 'utils/APIDecideErrorType'

const delayTime = 5000
const iterationCount = 120

/** Saga which handles visual archive download request */
export function* downloadArchiveSaga(receivedAction: ActionDownloadArchiveVisuals) {
  const { isAdmin } = receivedAction.payload

  const url = Endpoint.VISUAL_DOWNLOAD_ARCHIVE
    .replace('{assignmentId}', encodeURI(receivedAction.payload.missionId.toString()))
    .replace('{jobId}', encodeURI(receivedAction.payload.jobId.toString()))
  const archiveAction: ActionDownloadArchiveVisuals = yield generalFetch(ActionTypeAPIData.DOWNLOAD_ARCHIVE, () => API.get(url, {
    headers: {
      Accept: isAdmin ? RoleMimetype.ADMIN : RoleMimetype.CLIENT,
    },
  }, {
    endpoint: Endpoint.VISUAL_DOWNLOAD_ARCHIVE,
  }))

  const downloadArchiveAction: ActionDownloadArchiveVisuals = {
    ...archiveAction,
    payload: {
      ...receivedAction.payload,
      ...archiveAction.payload,
    }
  }

  yield put(downloadArchiveAction)
}

/** Saga which long polls GCP visual archive job and dispatches visual archive download actions based on the results */
export function* longPollVisualArchiveJob(receivedAction: ActionArchiveVisuals) {
  const { missionId, isAdmin } = receivedAction.payload

  const jobId = receivedAction.payload.request.data?.id || ''
  const downloadArchiveAction = downloadArchiveVisuals(jobId, missionId, isAdmin)
  const downloadArchiveActionReceived = cloneDeep(downloadArchiveAction)
  downloadArchiveActionReceived.type[0] = ActionTypeAPIEvent.RECEIVED

  if (!jobId) {
    const errorRequest = APIRequest.factoryError<DownloadArchiveResponse>(APIRequestErrorType.UNKNOWN_ERROR, 'NO JOB ID SPECIFIED')
    downloadArchiveActionReceived.payload.request = errorRequest
    yield put(downloadArchiveActionReceived)
    return
  }

  const jobURL = Endpoint.JOB.replace('{id}', encodeURI(jobId))

  for (let i = 0; i < iterationCount; i++) {
    try {
      const fetchFunction = () => API.get<JobDTO>(jobURL, {
        headers: {
          Accept: isAdmin ? RoleMimetype.ADMIN : RoleMimetype.CLIENT,
        }
      }, {
        endpoint: Endpoint.JOB,
      })

      const jobResponse = yield* call(fetchFunction)

      if (!jobResponse) {
        const errorRequest = APIRequest.factoryError<DownloadArchiveResponse>(APIRequestErrorType.UNKNOWN_ERROR, 'NO RESPONSE')
        downloadArchiveActionReceived.payload.request = errorRequest
        yield put(downloadArchiveActionReceived)
        return
      }

      switch (jobResponse.data.status) {
        case JobStatus.FINISHED:
          yield put(downloadArchiveAction)
          return
        case JobStatus.FAILED:
          const errorRequest = APIRequest.factoryError<DownloadArchiveResponse>(APIRequestErrorType.UNKNOWN_ERROR, 'JOB FAILED')
          downloadArchiveActionReceived.payload.request = errorRequest
          yield put(downloadArchiveActionReceived)
          return
        default:
          yield delay(delayTime)
          continue
      }
    } catch (error) {
      const axiosErr = error as AxiosError
      const errorRequest = APIRequest.factoryError<DownloadArchiveResponse>(decideErrorType(axiosErr), axiosErr)
      downloadArchiveActionReceived.payload.request = errorRequest
      yield put(downloadArchiveActionReceived)
      return
    }
  }

  const errorRequest = APIRequest.factoryError<DownloadArchiveResponse>(APIRequestErrorType.TIMEOUT_ERROR, 'LONG POLL TIMED OUT')
  downloadArchiveActionReceived.payload.request = errorRequest
  yield put(downloadArchiveActionReceived)
}

/** Watcher of visual archive download actions */
export function* downloadArchiveWatcher() {
  yield all([
    takeEvery((action: ActionDownloadArchiveVisuals) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.DOWNLOAD_ARCHIVE]), downloadArchiveSaga),
  ])
}
