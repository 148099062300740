import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequestState } from 'constants/API'
import { APIRequest } from 'models/API'
import { ActionRequest } from 'models/redux'
import { createAPIAction } from 'redux/Helpers'
import { WatermarkLogoStore } from './loadWatermarkLogo.interfaces'

/** Describes action for getting watermark logo under workspace */
export interface ActionLoadWatermarkLogo extends ActionRequest {
  payload: {
    workspaceId: string
    watermarkId: string
    request: WatermarkLogoStore
  }
}

/** Action which initializes loading of user's uploaded Watermark logo */
export function loadWatermarkLogo(workspaceId: string, watermarkId: string): ActionLoadWatermarkLogo {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.LOAD_WATERMARK_LOGO], {
    workspaceId,
    watermarkId,
    request: new APIRequest(APIRequestState.RUNNING)
  })
}

/** Action that purges initialization of user's uploaded Watermark logo */
export function purgeLoadWatermarkLogo(): ActionLoadWatermarkLogo {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.LOAD_WATERMARK_LOGO], {
    request: new APIRequest(APIRequestState.BEFORE_START)
  })
}
