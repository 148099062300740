import { AssignmentDTO, AssignmentDTOAdministrator, AssignmentDTOClient, AssignmentDTOCreative } from 'models/assignment'

import { DealDTO } from 'models/deal'

export function AssignmentDTOIsClientDTO(assignmentDTO: AssignmentDTO): assignmentDTO is AssignmentDTOClient {
  return (
    (assignmentDTO as AssignmentDTOCreative).creativeRemuneration?.baseRemuneration === undefined &&
    (assignmentDTO as AssignmentDTOClient).feePrice !== undefined
  )
}

export function AssignmentDTOIsCreativeDTO(assignmentDTO: AssignmentDTO): assignmentDTO is AssignmentDTOCreative {
  return (
    (assignmentDTO as AssignmentDTOCreative).creativeRemuneration?.baseRemuneration !== undefined &&
    (assignmentDTO as AssignmentDTOClient).feePrice === undefined
  )
}

export function AssignmentDTOIsAdministratorDTO(assignmentDTO: AssignmentDTO): assignmentDTO is AssignmentDTOAdministrator {
  return (
    (assignmentDTO as AssignmentDTOAdministrator).creativeRemuneration?.baseRemuneration !== undefined &&
    (assignmentDTO as AssignmentDTOAdministrator).feePrice !== undefined
  )
}

export function DealDTOOrAssignmentDTOIsAssignmentDTO(unknownDTO: DealDTO | AssignmentDTO): unknownDTO is AssignmentDTO {
  return (
    (unknownDTO as DealDTO).missions === undefined
  )
}
