import { MUIInputField, MUIInputFieldProps, StyledMUIInput } from '../MUIInputField/MUIInputField.component'

import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { MUIInputFieldButton } from '../MUIInputField/MUIInputFieldButton'
import React from 'react'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'

/**
 * @interface Props Input properties
 */
export interface MUIInputFieldCounterProps extends MUIInputFieldProps {
  /** The minimum allowed value of the counter. */
  minValue?: number
  /** The maximum allowed value of the counter. */
  maxValue?: number
  /** The initial value of the counter. */
  counterValue?: number
  /** Indicates whether the input is centered or not. */
  isCenterInput?: boolean
  /** Callback function triggered on value change. */
  counterOnChange?: (value: number) => void
}

/**
 * @component MUI Input Field Counter - The extended MUIInputField with increment and decrement buttons.
 * @example
 * <MUIInputFieldCounter />
 */
export const MUIInputFieldCounter: React.FC<MUIInputFieldCounterProps> = ({
  minValue = 0,
  maxValue,
  counterValue = 0,
  isCenterInput,
  counterOnChange,
  ...props
}) => {

  const handleIncrement = () => {
    if (counterValue === maxValue) return // Ensure it doesn't exceed maxValue
    const newCount = counterValue + 1
    counterOnChange?.(newCount)
  }

  const handleDecrement = () => {
    if (counterValue === minValue) return // Ensure it doesn't go below minValue
    const newCount = counterValue - 1
    counterOnChange?.(newCount)
  }

  return (
    <MUIInputField
      {...props}
      customInput={
        <>
          <MUIInputFieldButton borderType="right" onClick={handleDecrement}>
            <RemoveRoundedIcon fontSize="large" />
          </MUIInputFieldButton>

          <StyledMUIInput
            value={counterValue}
            placeholder="##"
            sx={{
              input: { textAlign: isCenterInput ? 'center' : 'left' },
            }}
            onChange={(e) => {
              const value = parseInt(e.target.value.replace(/\D/g, ''))
              if (isNaN(value)) {
                counterOnChange?.(minValue)
              } else {
                counterOnChange?.(value)
              }
            }}
          />

          <MUIInputFieldButton onClick={handleIncrement}>
            <AddRoundedIcon fontSize="large" />
          </MUIInputFieldButton>
        </>
      }
    />
  )
}
