import axios, { AxiosProgressEvent, CancelTokenSource } from 'axios'
import { APIRequestErrorType, APIRequestState, Endpoint } from 'constants/API'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { ActionRequest, StatusResponseBody } from 'models/redux'
import { SignedURLDTO, SignedUrlUploadRequestBody } from 'models/visuals'
import { END, EventChannel, eventChannel } from 'redux-saga'
import { fork, take } from 'typed-redux-saga'
import { ActionLoadVisual, downloadVisual } from '../LoadVisual'

import { VisualFileType } from 'constants/visual'
import { APIRequest } from 'models/API'
import { put } from 'redux-saga/effects'
import { generalFetch } from 'redux/Helpers'
import API from 'utils/API/API'

const removeFileExtensionRegex = /\..+/

const contentRangeRegex = /bytes (\d+)-(\d+)\/(\d+)/

const VISUAL_MAP = {
  THUMB: {
    [VisualFileType.RAW]: VisualFileType.RAW_THUMB as VisualFileType.RAW_THUMB,
    [VisualFileType.POST]:
      VisualFileType.POST_THUMB as VisualFileType.POST_THUMB,
  },
  WEB: {
    [VisualFileType.RAW]: VisualFileType.RAW_WEB as VisualFileType.RAW_WEB,
    [VisualFileType.POST]: VisualFileType.POST_WEB as VisualFileType.POST_WEB,
  },
}

const maxFailures = 10

interface UploadChunkParams {
  uri: SignedURLDTO
  chunk: Blob
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void
  source: CancelTokenSource
  receivedAction: ActionLoadVisual
  filename: string
  contentRange: {
    start: number
    end: number
    totalSize: number
  } | null
  emit: (input: END | ActionLoadVisual) => void
}

interface ContentRange {
  start: number
  end: number
  totalSize: number
}

function parseContentRange(contentRange: string): ContentRange {
  const match = contentRange.match(
    contentRangeRegex
  )
  if (!match) throw new Error('Invalid Content-Range header')

  const [, start, end, totalSize] = match
  return {
    start: parseInt(start),
    end: parseInt(end),
    totalSize: parseInt(totalSize),
  }
}

function uploadChunk(params: UploadChunkParams) {
  const { source, onUploadProgress, uri, chunk } = params
  const contentRangeHeader = params.contentRange ? {
    'Content-Range': `bytes ${params.contentRange.start}-${params.contentRange.end}/${params.contentRange.totalSize}`,
  } : {}
  return generalFetch(ActionTypeAPIData.LOAD_VISUAL, () => axios.put(uri.signedURL, chunk, {
    cancelToken: source.token,
    onUploadProgress,
    headers: {
      'Content-Type': chunk.type,
      ...contentRangeHeader,
      ...uri.headers,
    },
  }))
}

interface StandardUploadParams {
  signedUrlDTO: SignedURLDTO
  file: File
  source: CancelTokenSource
  receivedAction: ActionLoadVisual
  filename: string
  emit: (input: END | ActionLoadVisual) => void
}

async function standardUpload(
  params: StandardUploadParams
): Promise<ActionLoadVisual | null> {
  const { source, filename, emit, signedUrlDTO, file, receivedAction } = params
  const actionContainingResponseFromStorage = await uploadChunk({
    uri: signedUrlDTO,
    chunk: file,
    source,
    receivedAction,
    filename,
    emit,
    contentRange: null,
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / (progressEvent.total || 1)
      )
      const progressAction: ActionLoadVisual = {
        type: [ActionTypeAPIEvent.PROGRESS, ActionTypeAPIData.LOAD_VISUAL],
        payload: {
          ...receivedAction.payload,
          progress: percentCompleted,
          file: new File([], filename),
          signedUrl: signedUrlDTO.signedURL,
          cancelToken: source,
          request: new APIRequest(APIRequestState.RUNNING),
        },
      }

      emit(progressAction)
    },
  })

  if (
    actionContainingResponseFromStorage.payload.request.error_type ===
    APIRequestErrorType.CANCEL_ERROR
  ) {
    emit(END)
    return null
  }

  return {
    ...actionContainingResponseFromStorage,
    payload: {
      ...receivedAction.payload,
      ...actionContainingResponseFromStorage.payload,
      file: new File([], filename),
      signedUrl: signedUrlDTO.signedURL,
      cancelToken: source,
    },
  }
}

interface ResumableUploadParams {
  signedURLDTO: SignedURLDTO
  file: File
  source: CancelTokenSource
  receivedAction: ActionLoadVisual
  filename: string
  emit: (input: END | ActionLoadVisual) => void
}

async function getRange(uploadURI: string, file: File, chunkResult: ActionRequest, ranges: [number, number][]) {
  await axios.put(uploadURI, null, {
    headers: {
      'Content-Length': '0',
      'Content-Range': `bytes */${file.size}`,
    }
  })
  const contentRangeHeader = chunkResult.payload.request.response?.headers['Content-Range'] as string | undefined
  if (!contentRangeHeader) throw new Error('Content-Range header not found')
  return parseContentRange(contentRangeHeader)

}

async function resumableUpload(
  params: ResumableUploadParams
): Promise<ActionLoadVisual | null> {
  const { signedURLDTO, file, source, receivedAction, filename, emit } = params
  let uploaded = 0
  const ranges: [number, number][] = [[0, file.size]]

  // initiate resumable upload
  const { headers, signedURL } = signedURLDTO
  const result = await axios.post(signedURL, null, {
    headers: {
      ...headers,
      'Content-Type': file.type,
      'X-Upload-Content-Type': file.type,
    }
  })
  const uploadURI: string = result.headers.location

  const handleUploadProgress = (
    file: File,
    receivedAction: ActionLoadVisual,
    signedURL: string,
    source: CancelTokenSource,
    emit: (input: END | ActionLoadVisual) => void,
    progressEvent: AxiosProgressEvent
  ) => {
    uploaded += progressEvent.bytes

    const percentCompleted = Math.round((uploaded * 100) / (file.size || 1))
    const progressAction: ActionLoadVisual = {
      type: [ActionTypeAPIEvent.PROGRESS, ActionTypeAPIData.LOAD_VISUAL],
      payload: {
        ...receivedAction.payload,
        progress: percentCompleted,
        file: new File([], filename),
        signedUrl: signedURL,
        cancelToken: source,
        request: new APIRequest(APIRequestState.RUNNING),
      },
    }

    emit(progressAction)
  }

  // upload chunk by chunk
  let failures = 0
  while (ranges.length > 0 && failures < maxFailures) {
    const [start, end] = ranges.shift()!
    const chunk = file.slice(start, end, file.type)

    const chunkResult = await uploadChunk({
      uri: { signedURL: uploadURI, headers: {}, filename },
      onUploadProgress: (progressEvent) =>
        handleUploadProgress(
          file,
          receivedAction,
          signedURL,
          source,
          emit,
          progressEvent
        ),
      contentRange: {
        start,
        end: end - 1,
        totalSize: file.size,
      },
      chunk,
      source,
      receivedAction,
      filename,
      emit,
    })

    const status = chunkResult.payload.request.response?.status
    if (status && (status === 500 || status === 503)) {
      const range = await getRange(uploadURI, file, chunkResult, ranges)
      if (range.end < file.size) {
        ranges.push([range.end, file.size])
      }
      failures++
    }
    if (
      chunkResult.payload.request.error_type ===
      APIRequestErrorType.CANCEL_ERROR
    ) {
      emit(END)
      return null
    }
  }

  return {
    type: [ActionTypeAPIEvent.RECEIVED, ActionTypeAPIData.LOAD_VISUAL],
    payload: {
      ...receivedAction.payload,
      progress: 0,
      file: new File([], filename),
      signedUrl: signedURLDTO.signedURL,
      cancelToken: source,
    },
  }
}

/** A method which generates eventChannel that emits information about upload progress and finished upload response */
function createUploadChannel(
  receivedAction: ActionLoadVisual,
  SignedURLDTO: SignedURLDTO,
  delayBeforeDownload?: number
) {
  return eventChannel<ActionLoadVisual>((emit) => {
    (async () => {
      const { missionId, type, file, originalFilename, droppedIn, uploadType } =
        receivedAction.payload
      const split = SignedURLDTO.signedURL.split('?')[0].split('/')
      const filename = split[split.length - 1].replace(
        removeFileExtensionRegex,
        ''
      )
      const source = axios.CancelToken.source()
      let uploadedAction: ActionLoadVisual | null

      switch (uploadType) {
        case 'standard':
          uploadedAction = await standardUpload({
            signedUrlDTO: SignedURLDTO,
            file,
            source,
            receivedAction,
            filename,
            emit,
          })
          break
        case 'resumable':
          uploadedAction = await resumableUpload({
            signedURLDTO: SignedURLDTO,
            file,
            source,
            receivedAction,
            filename,
            emit,
          })
      }

      if (!uploadedAction) return
      emit(uploadedAction)

      const emitDownload = () => {
        if (type !== VisualFileType.RAW && type !== VisualFileType.POST) return
        emit(
          downloadVisual(
            missionId,
            filename,
            VISUAL_MAP.THUMB[type],
            originalFilename,
            droppedIn
          )
        )
        emit(
          downloadVisual(
            missionId,
            filename,
            VISUAL_MAP.WEB[type],
            originalFilename,
            droppedIn
          )
        )
        emit(END)
      }

      if (delayBeforeDownload) {
        window.setTimeout(() => {
          emitDownload()
        }, delayBeforeDownload)
      } else emitDownload()
    })()

    return () => {
    }
  })
}

/** Saga that listens to all actions emitted from upload channel and passes them to redux */
function* uploadProgressListenerSaga(channel: EventChannel<ActionLoadVisual>) {
  while (true) {
    const action = yield* take(channel)
    yield put(action)
  }
}

/** Saga which handles uploading visual */
export function* uploadVisualSaga(receivedAction: ActionLoadVisual) {
  const { replaces, file, type, missionId, uploadType } =
    receivedAction.payload
  if (replaces) return

  const dataForSignedUrl: SignedUrlUploadRequestBody = {
    filename: file.name,
    contentType: file.type,
    type,
  }

  const url = `${Endpoint.VISUAL_UPLOAD_URL.replace(
    '{assignmentId}',
    encodeURI(missionId.toString())
  )}?uploadType=${uploadType.toUpperCase()}`
  const actionContainingSignedUrl: ActionRequest = yield generalFetch(
    ActionTypeAPIData.LOAD_VISUAL,
    () =>
      API.post<SignedURLDTO>(url, dataForSignedUrl, undefined, {
        endpoint: Endpoint.VISUAL_UPLOAD_URL,
      })
  )
  const SignedURLDTO: SignedURLDTO = actionContainingSignedUrl.payload.request.data
  const uploadAction: ActionLoadVisual = {
    ...receivedAction,
    payload: {
      ...receivedAction.payload,
      originalFilename: file.name,
    },
  }

  if (uploadAction.payload.request.state === APIRequestState.ERROR || !SignedURLDTO?.signedURL) {
    const response = uploadAction.payload.request.response?.data as StatusResponseBody
    let resultCode: string | undefined = ''

    if (response) {
      resultCode = response?.message
    } else {
      resultCode = uploadAction.payload.request.error.message
    }

    const error_message = `Image processing result code: ${resultCode}`
    console.error(error_message)
    uploadAction.payload.request.error = resultCode
    uploadAction.type = [ActionTypeAPIEvent.RECEIVED, ActionTypeAPIData.LOAD_VISUAL]
    yield put(uploadAction)

    return
  }

  const channel = createUploadChannel(uploadAction, SignedURLDTO)
  yield fork(uploadProgressListenerSaga, channel)
}

/** Saga which handles uploading visual replacement */
export function* uploadVisualReplacementSaga(receivedAction: ActionLoadVisual) {
  const { replaces, file, type, missionId, uploadType } =
    receivedAction.payload
  if (!replaces) return

  const dataForSignedUrl: SignedUrlUploadRequestBody = {
    filename: replaces,
    contentType: file.type,
    type,
  }

  const url = `${Endpoint.VISUAL_UPLOAD_URL_REPLACE.replace(
    '{assignmentId}',
    encodeURI(missionId.toString())
  )}?uploadType=${uploadType.toUpperCase()}`
  const actionContainingSignedUrl: ActionRequest = yield generalFetch(
    ActionTypeAPIData.LOAD_VISUAL,
    () =>
      API.put<SignedURLDTO>(url, dataForSignedUrl, undefined, {
        endpoint: Endpoint.VISUAL_UPLOAD_URL_REPLACE,
      })
  )
  const SignedURLDTO: SignedURLDTO =
    actionContainingSignedUrl.payload.request.data
  const uploadAction: ActionLoadVisual = {
    ...receivedAction,
    payload: {
      ...receivedAction.payload,
      originalFilename: file.name,
    },
  }

  if (uploadAction.payload.request.state === APIRequestState.ERROR || !SignedURLDTO?.signedURL) {
    const response = uploadAction.payload.request.response?.data as StatusResponseBody
    let resultCode: string | undefined = ''

    if (response) {
      resultCode = response.message
    } else {
      resultCode = uploadAction.payload.request.error.message
    }

    const error_message = `Image processing result code: ${resultCode}`
    console.error(error_message)
    uploadAction.payload.request.error = resultCode
    uploadAction.type = [ActionTypeAPIEvent.RECEIVED, ActionTypeAPIData.LOAD_VISUAL]
    yield put(uploadAction)

    return
  }

  const channel = createUploadChannel(uploadAction, SignedURLDTO, 10000)
  yield fork(uploadProgressListenerSaga, channel)
}
