import React from 'react'

import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import { useWorkspacesProfileContext } from '../_main/WorkspacesProfile.context'
import { ResendInvitationButton } from '../ResendInvitationButton'
import { MUIButton } from 'components/common/MUIButton'
import { useRevokeWorkspaceInvitation } from 'dataQueries'
import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'

/**
 * @component Pending invitation drawer content
 * @example <PendingInvitationDrawerContent />
 */
export const PendingInvitationDrawerContent: React.FC = () => {
  const { t } = useTranslation(['workspace_profile'])

  const revokeInvitation = useRevokeWorkspaceInvitation()
  const {
    workspaceId,
    workspaceDataOfUser,
    pendingInvitationSetting,
  } = useWorkspacesProfileContext()

  if (!pendingInvitationSetting) return <></>

  const { email, id } = pendingInvitationSetting

  return (
    <Stack gap="1.6rem">
      <ResendInvitationButton
        workspaceId={workspaceId}
        invitation={{ ...pendingInvitationSetting }}
        workspaceDataOfUser={workspaceDataOfUser}
        size="lg"
      />

      <MUIButton
        fullWidth
        size="lg"
        type="orangeSecondaryBorder"
        variant="outlined"
        onClick={() => {
          revokeInvitation.mutate({ workspaceId, invitationId: id })
          !!workspaceDataOfUser && logAnalyticsEvent(AnalyticsEvent.REVOKE_INVITATION, {
            userEmail: workspaceDataOfUser.email,
            userRole: workspaceDataOfUser.role,
            planId: workspaceId,
            InvitedUserEmail: email
          })
        }}
      >
        {t('remove')}
      </MUIButton>
    </Stack>
  )
}
