import React, { useEffect, useLayoutEffect } from 'react'
import i18n, { transformLanguageToAuth0Value } from 'translations/i18n'

import { PageTransition } from 'utils/animations'
import { Path } from 'constants/router'
import { logAnalyticsEvent } from 'utils/analytics'
import { useAuth0 } from 'utils/auth'
import { useNavigate } from 'react-router-dom'

const SignupRedirect: React.FC = () => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0()
  const navigate = useNavigate()

  useLayoutEffect(() => {
    if (loading || isAuthenticated) {
      return
    }
    const fn = async () => {
      const params = new URLSearchParams(window.location.search)
      logAnalyticsEvent('initiates_login', { location: 'SignupRedirect' })
      await loginWithRedirect({
        mode: 'signUp',
        language: transformLanguageToAuth0Value(i18n.language),
        login_hint: params.has('userEmail') ? params.get('userEmail') ?? undefined : undefined,
        appState: { targetUrl: Path.INDEX },
      })
    }
    fn()
  }, [loading, isAuthenticated, loginWithRedirect])

  useEffect(() => {
    if (isAuthenticated) navigate(Path.INDEX, { replace: true })
  }, [isAuthenticated, navigate])

  return (
    <PageTransition>
      <div className="page signup-redirect">
        <div className="page-content">
        </div>
      </div>
    </PageTransition>
  )
}

export default SignupRedirect
