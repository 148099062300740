import { ImmutableMap, Nullable } from 'models/helpers'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import constate from 'constate'

/** Interface for DynamicFlow node entry */
export interface DynamicFlowNode {
  /** Key of the Node */
  key: string
  /** Pointer (key) to the previous Node */
  prev: Nullable<string>
  /** Pointer (key) to the next Node */
  next: Nullable<string>
  /** Component to be rendered for the Node */
  component: ReactNode
  /** Hierarchical type of the Node */
  type: 'main' | 'secondary'
  // This should belong to some custom metadata attr, but the generic typing of providers looks impossible to me rn
  /** Whether footer should be rendered for this Node */
  hasFooter: boolean
}

interface NodeCreator extends Omit<DynamicFlowNode, 'key' | 'prev' | 'next'> { }

interface ProviderProps {
  enableLogging?: boolean
}

/**
 * Mechanism for crafting multi-step flows utilizing a linked-list approach for each step
 * 
 * Validation can be handled by manipulating *validityMap* prop by stepKey 
*/
export const [DynamicFlowProvider, useDynamicFlowController] = constate(({
  enableLogging = false
}: ProviderProps) => {

  const [nodes, setNodes] = useState<ImmutableMap<string, DynamicFlowNode>>(ImmutableMap([]))
  const [entryNode, setEntryNode] = useState<string | null>(null)
  const [head, setHead] = useState<string | undefined>()
  const [validityMap, setValidityMap] = useState<Map<string, boolean>>(new Map([]))

  const currentNode = useMemo(() => {
    if (!head) return undefined
    return nodes.get(head)
  }, [head, nodes])

  const insertNode = useCallback((key: string, step: Omit<DynamicFlowNode, 'key'>) => {

    if (step.prev && !nodes.get(step.prev)) {
      console.error(`Invalid node binding - ${step.prev} does not exist in the list and thus cannot be linked to.`)
      return
    }

    if (step.next && !nodes.get(step.next)) {
      console.error(`Invalid node binding - ${step.next} does not exist in the list and thus cannot be linked to.`)
      return
    }

    setNodes((prev) => prev.set(
      key,
      {
        ...step,
        key,
      }
    ))
  }, [nodes])

  const createEntryNode = useCallback((key: string, step: Omit<DynamicFlowNode, 'key' | 'prev' | 'next'>, override: boolean = false) => {
    insertNode(key, { ...step, prev: null, next: null })

    if (entryNode && !override) return

    setEntryNode(key)

    if (!head) setHead(key)
  }, [entryNode, head, insertNode])

  const removeNode = useCallback((key: string) => {
    setNodes((prev) => {
      const currentNode = prev.get(key)
      const prevNode = currentNode?.prev ? prev.get(currentNode.prev) : undefined
      const nextNode = currentNode?.next ? prev.get(currentNode.next) : undefined

      if (!prevNode && !nextNode) return prev.delete(key)

      let newNodes = ImmutableMap(prev).delete(key)


      if (prevNode) newNodes = newNodes.set(prevNode.key, { ...prevNode, next: nextNode ? nextNode.key : null })
      if (nextNode) newNodes = newNodes.set(nextNode.key, { ...nextNode, prev: prevNode ? prevNode.key : null })

      return newNodes
    })
  }, [])

  const insertAfter = useCallback((targetNode: string, key: string, step: NodeCreator) => {
    setNodes((prev) => {
      const target = prev.get(targetNode)

      if (!target) return ImmutableMap(prev)

      const targetNext = target?.next ? prev.get(target.next) : undefined
      const newStep: DynamicFlowNode = {
        ...step,
        key,
        prev: target?.key,
        next: targetNext?.key,
      }

      target.next = key

      let newNodes = prev.set(key, newStep).set(target.key, target)

      if (targetNext) {
        targetNext.prev = key

        newNodes = newNodes.set(targetNext.key, targetNext)
      }

      return newNodes
    })

  }, [])

  const insertBefore = useCallback((targetNode: string, key: string, step: NodeCreator) => {
    setNodes((prev) => {
      const target = prev.get(targetNode)

      if (!target) return ImmutableMap(prev)

      const targetPrev = target?.prev ? prev.get(target.prev) : undefined
      const newStep: DynamicFlowNode = {
        ...step,
        key,
        prev: targetPrev?.key,
        next: target?.key,
      }

      target.prev = key

      let newNodes = prev.set(key, newStep).set(target.key, target)

      if (targetPrev) {
        targetPrev.next = key

        newNodes = newNodes.set(targetPrev.key, targetPrev)
      }

      return newNodes
    })

  }, [])

  const getOrderedNodeList = useCallback(() => {
    let nodeList: Array<DynamicFlowNode> = []

    if (!entryNode) return nodeList

    let currentNode = nodes.get(entryNode)

    if (!currentNode) return nodeList

    while (currentNode) {
      nodeList.push(currentNode)

      if (!currentNode.next) break
      currentNode = nodes.get(currentNode.next)
    }

    return nodeList
  }, [entryNode, nodes])

  // Link logger
  useEffect(() => {
    if (!enableLogging) return
    const nodeList = getOrderedNodeList()

    console.log(nodeList.map(node => `(${node.key})`).join(' <==> '))
  }, [entryNode, enableLogging, getOrderedNodeList])

  return {
    nodes,
    head,
    currentNode,
    entryNode,
    validityMap,
    setValidityMap,
    setHead,
    insertNode,
    removeNode,
    insertAfter,
    insertBefore,
    createEntryNode,
    getOrderedNodeList,
  }
})
