import { BLUE_600, CORAL_100, CORAL_600, GRAY_100, GRAY_300, GRAY_400, GRAY_800, GRAY_900, WHITE } from 'constants/styling/theme'
import {
  Unstable_NumberInput as BaseNumberInput,
  numberInputClasses
} from '@mui/base/Unstable_NumberInput'
import React, { FC, useMemo } from 'react'

import { BorderBoxWrapper } from '../BorderBoxWrapper'
import { Label } from '../Label'
import { MUIInputFieldButton } from '../MUIInputField/MUIInputFieldButton'
import { Nullable } from 'models/helpers'
import {
  NumberInputProps
} from '@mui/base/Unstable_NumberInput'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

/** @interface Props for the MUINumberField component and extends the NumberInputProps. */
export interface Props extends Omit<NumberInputProps, 'onChange'> {
  /** The label for the number field. */
  label?: string
  /** Whether the field is in an error state. */
  isError?: boolean
  /** Indicates if the field is disabled. */
  disabled?: boolean
  /** Indicates if the field is required. */
  required?: boolean
  /** The error text to display when in an error state. */
  errorText?: string | null
  /** A suffix to display after the number. */
  suffix?: string
  /** The value of the number field. */
  value?: Nullable<number>
  /** The width of the input box. */
  inputBoxWidth?: string
  /** Callback function to handle value changes. */
  onChange?: (value: Nullable<number>) => void
  /** Callback function to handle blur events. */
  onBlur?: () => void
}

const NumberInput = React.forwardRef(function CustomNumberInput(
  props: NumberInputProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <BaseNumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInputElement,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        incrementButton: {
          children: '▴',
        },
        decrementButton: {
          children: '▾',
        },
      }}
      {...props}
      ref={ref}
    />
  )
})

/**
 * @component
 * MUINumberField renders a number input field with optional label, suffix, and error handling.
 *
 * @example
 * <MUINumberField
 *  label='Number field'
 *  value={0}
 *  min={0}
 *  max={100}
 *  suffix='Kg'
 *  onChange={(value) => console.log(value)}
 * />
 */
export const MUINumberField: FC<Props> = ({
  label,
  value,
  suffix,
  isError,
  required,
  disabled,
  errorText,
  inputBoxWidth,
  onBlur,
  onChange,
  ...props
}) => {

  const hoverBorderColor = useMemo(() => {
    if (isError) return 'inherit'
    if (disabled || props.readOnly) return GRAY_400
    return BLUE_600
  }, [disabled, isError, props.readOnly])

  const inputBgColor = useMemo(() => {
    if (isError) return CORAL_100
    if (disabled || props.readOnly) return GRAY_100
    return 'inherit'
  }, [disabled, isError, props.readOnly])

  return (
    <Stack gap={0.6}>
      {!!label &&
        <Label
          text={label}
          isError={isError}
          required={required}
        />
      }

      <BorderBoxWrapper
        component="form"
        backgroundColor={inputBgColor}
        borderColor={isError ? CORAL_600 : GRAY_400}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          transition: '.3s ease',
          overflow: 'hidden',
          width: inputBoxWidth,
          '&:hover': {
            border: `.1rem solid ${hoverBorderColor}`,
          }
        }}
      >
        <NumberInput
          error={isError}
          disabled={disabled}
          value={value}
          onBlur={onBlur}
          onChange={(e, value) => {
            e.preventDefault()
            onChange?.(value)
          }}
          {...props}
        />

        {suffix &&
          <MUIInputFieldButton disabled>
            <Typography variant="text-md" color={GRAY_900} fontWeight={500}>
              {suffix}
            </Typography>
          </MUIInputFieldButton>
        }

      </BorderBoxWrapper>

      {isError && errorText &&
        <Label text={errorText} isError={true} />
      }
    </Stack>
  )
}

const StyledInputRoot = styled('div')(() => `
  width: 100%;
  height: 4rem;
  font-family: 'HK Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  border-radius: 0;
  color: ${GRAY_900};
  border: unset;
  box-shadow: unset;
  display: grid;
  grid-template-columns: 1fr 19px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  column-gap: 1rem;
  background: ${WHITE};

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
)

const StyledInputElement = styled('input')(() => `
  width: 100%;
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  grid-column: 1/2;
  grid-row: 1/3;
  color: ${GRAY_900};
  background: inherit;
  border: none !important;
  border-radius: inherit;
  padding: 0 !important;
  outline: 0;
  box-sizing: border-box;
  padding: .8rem 1.2rem !important;
  
  &:focus {
    box-shadow: unset !important;
  }
`
)

const StyledButton = styled('button')(() => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  width: 80%;
  height: 80%;
  font-family: 'HK Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.4rem;
  line-height: 1;
  border: 0;
  box-sizing: border-box;
  background: ${GRAY_100};
  color: ${GRAY_900};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${GRAY_300};
    border-color: ${GRAY_300};
    cursor: pointer;
  }

  &.${numberInputClasses.incrementButton} {
    margin-top: auto;
    grid-column: 2/3;
    grid-row: 1/2;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid;
    border-bottom: 0;
    &:hover {
      cursor: pointer;
      background: ${GRAY_300};
    }

    border-color: ${GRAY_400};
    background: ${GRAY_100};
    color: ${GRAY_800};
  }

  &.${numberInputClasses.decrementButton} {
    margin-bottom: auto;
    grid-column: 2/3;
    grid-row: 2/3;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid;
    &:hover {
      cursor: pointer;
      background: ${GRAY_300};
    }

    border-color: ${GRAY_400};
    background: ${GRAY_100};
    color: ${GRAY_800};
  }
  & .arrow {
    transform: translateY(-1px);
  }
`
)
