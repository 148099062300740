import { AnalyticsEvent } from 'utils/analytics'
import { FC, useCallback, useMemo } from 'react'
import { RoomItemPopupType, useStyleSelection } from '../main/contexts'
import { StagingItemSelectionPopup, StagingItemSelectionTab } from '../StagingItemSelectionPopup'
import { roomItemClosePopupEventName, roomItemPopupSelectEventName } from 'utils/purchaseFlow'

import { VirtualFurnitureRoomType } from 'constants/virtualFurniture'
import { useRoomAPI } from 'components/contexts/RoomAPI.context'
import { useTranslation } from 'react-i18next'
import { useVirtualFurnitureUpgrades } from 'components/contexts/VirtualFurnitureUpgradesContext'

/** 
 * @component
 * Displays options for selecting each item styles.
 * 
 * @example
 * <RoomItemSelectionPopup />
 */
export const RenovationsSelectionPopup: FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const { t } = useTranslation(['style_selection_order_step'])
  const { updateRoom } = useRoomAPI()

  const { virtualFurnitureUpgradesMap } = useVirtualFurnitureUpgrades()

  const {
    activeRoom,
    roomItemPopupType,
    setRoomItemPopupType,
    stagingFlowAnalytics,
  } = useStyleSelection()

  const availableRenovations = useMemo(() => {
    switch (roomItemPopupType) {
      case RoomItemPopupType.FLOOR:
        return virtualFurnitureUpgradesMap.get(VirtualFurnitureRoomType.FLOORING) ?? []
      case RoomItemPopupType.WALL:
        return virtualFurnitureUpgradesMap.get(VirtualFurnitureRoomType.WALL_MATERIALS) ?? []
      default:
        return []
    }
  }, [roomItemPopupType, virtualFurnitureUpgradesMap])

  const popupTitle = useMemo(() => {
    switch (roomItemPopupType) {
      case RoomItemPopupType.FLOOR:
        return t('room_items.FLOOR')
      case RoomItemPopupType.WALL:
        return t('room_items.WALL')
      default:
        return ''
    }
  }, [roomItemPopupType, t])

  const selectedRenovation = useMemo(() => {
    switch (roomItemPopupType) {
      case RoomItemPopupType.FLOOR:
        return activeRoom.renovations.get(VirtualFurnitureRoomType.FLOORING)
      case RoomItemPopupType.WALL:
        return activeRoom.renovations.get(VirtualFurnitureRoomType.WALL_MATERIALS)
      default:
        return undefined
    }
  }, [activeRoom.renovations, roomItemPopupType])

  const popupOnSelect = useCallback((selectedStyle: string | undefined) => {
    switch (roomItemPopupType) {
      case RoomItemPopupType.FLOOR: {
        if (!selectedStyle) updateRoom(activeRoom.key, { renovations: activeRoom.renovations.delete(VirtualFurnitureRoomType.FLOORING) })
        else updateRoom(activeRoom.key, { renovations: activeRoom.renovations.set(VirtualFurnitureRoomType.FLOORING, selectedStyle) })
        stagingFlowAnalytics(AnalyticsEvent.FLOOR_TEMPLATES_SAVED, { TemplateIDs: selectedStyle })
        break
      }

      case RoomItemPopupType.WALL: {
        if (!selectedStyle) updateRoom(activeRoom.key, { renovations: activeRoom.renovations.delete(VirtualFurnitureRoomType.WALL_MATERIALS) })
        else updateRoom(activeRoom.key, { renovations: activeRoom.renovations.set(VirtualFurnitureRoomType.WALL_MATERIALS, selectedStyle) })
        stagingFlowAnalytics(AnalyticsEvent.WALL_TEMPLATES_SAVED, { TemplateIDs: selectedStyle })
        break
      }

      default:
        break
    }

    setRoomItemPopupType(null)
  }, [activeRoom.key, activeRoom.renovations, roomItemPopupType, setRoomItemPopupType, stagingFlowAnalytics, updateRoom])

  return (
    <StagingItemSelectionPopup
      availableItems={{ [StagingItemSelectionTab.ALL]: availableRenovations }}
      title={popupTitle}
      isOpen={isOpen}
      selectedItems={{ [StagingItemSelectionTab.ALL]: selectedRenovation || '' }}
      onClose={() => {
        stagingFlowAnalytics(roomItemClosePopupEventName(roomItemPopupType), {})
        setRoomItemPopupType(null)
      }}
      onSelect={(selected) => popupOnSelect(selected[StagingItemSelectionTab.ALL])}
      onSelectAnalyticEvent={(style) => stagingFlowAnalytics(roomItemPopupSelectEventName(roomItemPopupType), { TemplateIDs: style })}
    />
  )
}
