import { BEIGE_500, GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { FC, useCallback } from 'react'
import { Fade, Typography } from '@mui/material'
import { InformationType, useConsumptionCertificateStep } from './ConsumptionCertificateStep.context'

import { AdditionalInformation } from 'components/pages/PurchaseFlow/common/AdditionalInformation'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { ConsumptionInformation } from '../ConsumptionInformation'
import { HeatingSystem } from '../HeatingSystem'
import { PropertyInformation } from '../PropertyInformation'
import Stack from '@mui/material/Stack'
import { StepWrapper } from 'components/pages/PurchaseFlow/common/StepWrapper'
import { ToggleButton } from 'components/common/ToggleButton'
import { useTranslation } from 'react-i18next'

/**
 * Controller for the ConsumptionCertificate requirements step in the Purchase Flow.
 * 
 * @example
 * <ConsumptionCertificateStepController />
 */
export const ConsumptionCertificateStepController: FC = () => {
  const { t } = useTranslation(['purchase_flow'])
  const {
    infoType,
    setInfoType,
    isPropertyInformationValid,
    isHeatingSystemValid,
    isConsumptionInformationValid,
    isAdditionalInformationValid,
  } = useConsumptionCertificateStep()

  const isInformationToggled = useCallback((infoType: InformationType) => {
    switch (infoType) {
      case InformationType.PROPERTY_INFORMATION:
        return isPropertyInformationValid
      case InformationType.HEATING_SYSTEM:
        return isHeatingSystemValid
      case InformationType.CONSUMPTION_INFORMATION:
        return isConsumptionInformationValid
      case InformationType.ADDITIONAL_INFORMATION:
        return isAdditionalInformationValid
      default:
        return false
    }
  }, [isAdditionalInformationValid, isConsumptionInformationValid, isHeatingSystemValid, isPropertyInformationValid])

  return (
    <StepWrapper
      title={t('consumption_certificate_step.section_title')}
      subtitle={t('consumption_certificate_step.section_description')}
    >
      <Fade in={true}>
        <Stack gap="1.6rem" width="100%" display="flex" justifyContent="space-between">

          <BorderBoxWrapper backgroundColor={BEIGE_500} padding="2.4rem">
            <Stack gap="2.4rem" display="column" flexWrap="nowrap" alignItems="center" justifyContent="center">

              <Stack gap=".4rem" alignItems="center">
                <Typography variant="text-md" fontWeight={600} color={GRAY_900}>{t('consumption_certificate_step.information_fill')}</Typography>
                <Typography variant="text-sm" fontWeight={400} color={GRAY_700}>{t('consumption_certificate_step.information_fill_description')}</Typography>
              </Stack>

              <Stack gap=".8rem" flexDirection="row" flexWrap="wrap">
                {Object.values(InformationType).map((type) => (
                  <ToggleButton
                    key={type}
                    isToggled={isInformationToggled(type)}
                    isSelected={infoType === type}
                    label={t(`consumption_certificate_step.${type.toLowerCase()}`)}
                    sx={{ width: 'fit-content', justifyContent: 'center' }}
                    onClick={() => setInfoType(type)}
                  />
                ))}
              </Stack>

            </Stack>
          </BorderBoxWrapper>

          {infoType === InformationType.PROPERTY_INFORMATION && <PropertyInformation />}

          {infoType === InformationType.HEATING_SYSTEM && <HeatingSystem />}

          {infoType === InformationType.CONSUMPTION_INFORMATION && <ConsumptionInformation />}

          {infoType === InformationType.ADDITIONAL_INFORMATION && <AdditionalInformation />}

        </Stack>
      </Fade>
    </StepWrapper>
  )
}
