import { BillingPeriodItem, useHeatingSystem } from '../HeatingSystem.context'
import { GRAY_400, GRAY_800, GRAY_900 } from 'constants/styling/theme'
import { HeaterConsumptionUnit, HotWaterGenerationType } from 'constants/purchaseFlow'
import React, { useMemo } from 'react'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import Box from '@mui/material/Box'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import IconButton from '@mui/material/IconButton'
import { MUIDatePicker } from 'components/common/MUIDatePicker'
import { MUIFloatlNumberField } from 'components/common/MUIFloatNumberField'
import { MUINumberField } from 'components/common/MUINumberField'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props for the BillingPeriod component.
 * @extends BillingPeriodItem
 */
interface Props extends BillingPeriodItem {
  /** Unique identifier for the billing period. */
  id: string
  /** Identifier for the associated heating system. */
  heatingSystemId: string
  /** Type of hot water */
  hotWaterType?: HotWaterGenerationType
  /** Type of hot water */
  unit?: HeaterConsumptionUnit
  /** Index to display the billing period numeration. */
  index: number
  /** Flag to show field errors in the component. */
  showErrors: boolean
  /** Callback to handle the input blur event. */
  handleOnInputBlur?: () => void
}

/**
 * @component 
 * BillingPeriod represents a single billing period within the heating system's consumption certificate step.
 * 
 * @example
 * <BillingPeriod 
 *   index={0} 
 *   id="billing-period-1" 
 *   consumption={150} 
 *   hotWaterType={HotWaterGenerationType.ELECTRIC} 
 *   heatingSystemId="heating-system-1" 
 *   unit={HeaterConsumptionUnit.KWH}
 *   endDate={new Date('2023-12-31')} 
 *   startDate={new Date('2023-01-01')} 
 *   showErrors={false}
 *   handleOnInputBlur={() => console.log('Input blurred')}
 * />
 */
export const BillingPeriod: React.FC<Props> = ({
  id,
  index,
  showErrors,
  heatingSystemId,
  consumption,
  hotWaterConsumptionKwh,
  hotWaterType,
  unit,
  endDate,
  startDate,
  handleOnInputBlur,
}) => {

  const { t } = useTranslation(['heating_system', 'common'])

  const { handleUpdateBillingPeriod, handleRemoveBillingPeriod } = useHeatingSystem()

  const isValidConsumption = useMemo(() => {
    return consumption !== null && consumption !== undefined && Number(consumption) >= 0
  }, [consumption])

  const isValidHotWaterConsumption = useMemo(() => {
    return hotWaterType === HotWaterGenerationType.KNOW_HOT_WATER_CONSUMPTION_IN_KWH && hotWaterConsumptionKwh !== null && hotWaterConsumptionKwh !== undefined && hotWaterConsumptionKwh >= 0
  }, [hotWaterConsumptionKwh, hotWaterType])

  return (
    <BorderBoxWrapper padding={2} borderColor={GRAY_400} gap={2} display="flex" flexDirection="column">

      {/** HEADER */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">

        <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
          {t('billing_period')} {index + 1}
        </Typography>

        {/** Remove billing period if more than 3 items are added */}
        {index > 2 &&
          <IconButton
            aria-label='delete-billing-period'
            sx={{ width: '3rem', height: '3rem' }}
            onClick={() => handleRemoveBillingPeriod(heatingSystemId, id)}
          >
            <CloseRoundedIcon fontSize='large' sx={{ color: GRAY_800 }} />
          </IconButton>
        }

      </Stack>

      <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">

        <MUIDatePicker
          required
          value={startDate}
          label={t('beginning')}
          views={['year', 'month', 'day']}
          isError={showErrors && !startDate}
          onInputBlur={handleOnInputBlur}
          onChange={(newValue) => handleUpdateBillingPeriod(heatingSystemId, id, { startDate: newValue })}
        />

        <Typography variant='text-md' color={GRAY_900} marginTop={2}>
          {t('common:to')}
        </Typography>

        <MUIDatePicker
          required
          value={endDate}
          label={t('end')}
          views={['year', 'month', 'day']}
          isError={showErrors && !endDate}
          onInputBlur={handleOnInputBlur}
          onChange={(newValue) => handleUpdateBillingPeriod(heatingSystemId, id, { endDate: newValue })}
        />

      </Stack>

      <Box width={{ xs: '80%', lg: '45%' }}>
        <MUIFloatlNumberField
          required
          placeholder={unit ? t(`unit_capture_type.${unit}`) : ''}
          inputBoxWidth='100%'
          label={t('consumption')}
          value={consumption}
          isError={showErrors && !isValidConsumption}
          onBlur={handleOnInputBlur}
          onChange={(value) => handleUpdateBillingPeriod(heatingSystemId, id, { consumption: value })}
        />
      </Box>

      {hotWaterType === HotWaterGenerationType.KNOW_HOT_WATER_CONSUMPTION_IN_KWH &&
        <Box width={{ xs: '80%', lg: '45%' }}>
          <MUINumberField
            min={0}
            required
            suffix={t('unit_capture_type.KWH')}
            inputBoxWidth='100%'
            label={t('hot_water_consumption')}
            value={hotWaterConsumptionKwh}
            isError={showErrors && !isValidHotWaterConsumption}
            onBlur={handleOnInputBlur}
            onChange={(value) => handleUpdateBillingPeriod(heatingSystemId, id, { hotWaterConsumptionKwh: value })}
          />
        </Box>}

    </BorderBoxWrapper>
  )
}
