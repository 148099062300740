import { CustomerReview } from 'constants/pricing/customerReviews'
import { FC } from 'react'
import styles from './CustomerReviewCard.module.sass'
import { useTranslation } from 'react-i18next'

interface Props {
  /** Customer's review */
  review: CustomerReview
}

/**
 * Customer Review Card 
 * @example
 * <CustomerReviewCard review={customerReviews} /> 
 */
export const CustomerReviewCard: FC<Props> = ({
  review,
}) => {
  const { t } = useTranslation(['pricing_page'])

  return (
    <div className={styles.customerReviewCard}>
      <div className={styles.content}>“{t(`review.${review.content}`)}”</div>
      <div className={styles.customerWrap}>
        <img className={styles.img} src={review.img} alt={review.content} />
        <div className={styles.customer}>
          <div className={styles.name}>{review.name}</div>
          <div className={styles.title}>{review.title}</div>
        </div>
      </div>
    </div>
  )
}
