import { Trans, useTranslation } from 'react-i18next'

import { AddWorkspaceMembersList } from './AddMembersList'
import Button from 'components/common/Button/Button'
import { FC } from 'react'
import { Path } from 'constants/router'
import { SubscriptionStepTemplate } from '../../SubscriptionStepTemplate'
import { logAnalyticsEvent } from 'utils/analytics'
import { useNavigate } from 'react-router-dom'
import { useSubscriptionFlow } from '../../_main/SubscriptionFlow.context'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * Add members step (STEP 2) of subscription flow.    
 * Extends base template.   
 * This step is optional and can be skipped.   
 * Currently no possibility to go back.   
 * Redirects to root route if previousStep doesn't match the requirement.   
 * 
 * @example
 *  <AddMembers />
 */
export const AddMembers: FC = () => {

  const navigate = useNavigate()
  const { t } = useTranslation(['subscription_flow'])
  const {
    workspaceMembers,
    maxInvitesCount,
    workspaceName,
  } = useSubscriptionFlow()

  const { organizationData } = useUserData()

  return (
    <SubscriptionStepTemplate
      title={t('steps.add_members.title')}
      boxContent={<AddWorkspaceMembersList />}
      alignAction='between'
      action={
        <>
          <Button
            onClick={() => navigate(Path.SUBSCRIPTION_CREATION)}
            type="secondary nobackground"
          >
            {t('back')}
          </Button>
          <Button
            type={!!workspaceMembers.length ? 'primary' : 'secondary nobackground'}
            onClick={() => {
              navigate(Path.SUBSCRIPTION_VALIDATION)
              !!workspaceMembers.length && workspaceMembers.forEach((member) => logAnalyticsEvent('Add Member Click - Subscription Flow', {
                invitedMember: member,
                organizationId: organizationData?.id,
                planName: workspaceName
              }))
            }}
          >
            {t(!!workspaceMembers.length ? 'next' : 'steps.add_members.skip')}
          </Button>
        </>
      }
    >

      <Trans
        parent="p"
        t={t}
        i18nKey="steps.add_members.description"
        values={{
          maxMembers: maxInvitesCount
        }}
      >
        <a
          href={Path.PRICING}
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          &nbsp;
        </a>
      </Trans>

    </SubscriptionStepTemplate>
  )
}
