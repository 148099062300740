import { EntityKeys, QueryType, queryClient } from 'utils/reactQuery'
import { FC, ReactNode, createContext, useCallback, useContext, useEffect, useMemo } from 'react'
import { PreferredPaymentMethodType, SavedStripePaymentMethod } from 'models/user'

import { UseQueryResult } from '@tanstack/react-query'
import { UserQueryKeys } from 'dataQueries'
import { useGetStripePaymentMethods } from 'dataQueries/payment.query'

type SavedStripePaymentMethodsMap = {
  [key in PreferredPaymentMethodType]?: SavedStripePaymentMethod[]
}
interface SavedStripePaymentMethodsContextInterface {
  savedStripePaymentMethods: SavedStripePaymentMethod[]
  savedStripePaymentMethodsMap: SavedStripePaymentMethodsMap
  stripePaymentMethods?: UseQueryResult<SavedStripePaymentMethod[] | null, Error>
  fetchSavedStripePaymentMethods: () => void
}

const defaultSavedStripePaymentMethodsContext: SavedStripePaymentMethodsContextInterface = {
  savedStripePaymentMethods: [],
  savedStripePaymentMethodsMap: {},
  stripePaymentMethods: undefined,
  fetchSavedStripePaymentMethods: () => { },
}

/** SavedStripePaymentMethods context */
export const SavedStripePaymentMethodsContext = createContext<SavedStripePaymentMethodsContextInterface>(defaultSavedStripePaymentMethodsContext)
/** SavedStripePaymentMethods context hook */
export const useSavedStripePaymentMethods = (): SavedStripePaymentMethodsContextInterface => useContext(SavedStripePaymentMethodsContext)

/**
 * Context provider for SavedStripePaymentMethods
 * @param autoFetch - If set to true, context will fetch saved methods on init (default false)
 */
export const SavedStripePaymentMethodsContextProvider: FC<{
  autoFetch?: boolean
  children?: ReactNode
}> = ({
  autoFetch = false,
  children
}) => {
    const stripePaymentMethods = useGetStripePaymentMethods()
    const savedStripePaymentMethods = useMemo(() => stripePaymentMethods.data || [], [stripePaymentMethods.data])

    const savedStripePaymentMethodsMap: SavedStripePaymentMethodsMap = useMemo(() => {

      return savedStripePaymentMethods.reduce((savedMethodMap: SavedStripePaymentMethodsMap, savedMethod) => ({
        ...savedMethodMap,
        // append savedMethod to the array of its type, if type is undefined, use empty array as base for merging
        [savedMethod.type]: [...(savedMethodMap[savedMethod.type] || []), savedMethod]
      }), {})

    }, [savedStripePaymentMethods])

    const fetchSavedStripePaymentMethods = useCallback(() => {
      stripePaymentMethods.refetch()
    }, [stripePaymentMethods])

    // Fetch/purge saved stripe payment methods
    useEffect(() => {
      if (autoFetch) {
        fetchSavedStripePaymentMethods()
      }

      return () => {
        queryClient.resetQueries({ queryKey: [EntityKeys.USER, QueryType.GET, UserQueryKeys.STRIPE_PAYMENT_METHOD] })
      }

      // Omit fetchSavedStripePaymentMethods to prevent infinite loop
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoFetch])

    return (
      <SavedStripePaymentMethodsContext.Provider value={{
        savedStripePaymentMethods,
        stripePaymentMethods,
        savedStripePaymentMethodsMap,
        fetchSavedStripePaymentMethods,
      }}>
        {children}
      </SavedStripePaymentMethodsContext.Provider>
    )
  }
