import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { Endpoint, RoleMimetype } from 'constants/API'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, takeEvery } from 'typed-redux-saga'

import API from 'utils/API/API'
import { ActionArchiveVisuals } from './ArchiveVisuals.actions'
import { longPollVisualArchiveJob } from '../DownloadArchive'
import { put } from 'redux-saga/effects'

/** Saga which initiates archive download */
export function* archiveVisualsSaga(receivedAction: ActionArchiveVisuals) {
  const { missionId, types, filenames, isAdmin } = receivedAction.payload

  const archiveVisualsUrl = Endpoint.VISUAL_ARCHIVE.replace('{assignmentId}', encodeURI(missionId.toString()))
  const archiveAction: ActionArchiveVisuals = yield generalFetch(ActionTypeAPIData.ARCHIVE_VISUALS, () => API.post(archiveVisualsUrl, {
    types,
    filenames,
  }, {
    headers: {
      Accept: isAdmin ? RoleMimetype.ADMIN : RoleMimetype.CLIENT,
    },
  }, {
    endpoint: Endpoint.VISUAL_ARCHIVE,
  }))

  const archiveVisualsAction: ActionArchiveVisuals = {
    ...archiveAction,
    payload: {
      ...receivedAction.payload,
      ...archiveAction.payload,
    }
  }

  yield put(archiveVisualsAction)

  // Start long polling archive visual GCP job
  yield longPollVisualArchiveJob(archiveVisualsAction)
}

/** Watcher of archive visuals actions */
export function* archiveVisualsWatcher() {
  yield all([
    takeEvery((action: ActionArchiveVisuals) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.ARCHIVE_VISUALS]), archiveVisualsSaga),
  ])
}
