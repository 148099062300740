import { APIRequestState, Endpoint } from 'constants/API'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { getUserWorkspaces, getUserWorkspacesSaga } from 'redux/Individual/User/GetUserWorkspaces'
import { getInitRoles, getRoles } from 'utils/auth'

import API from 'utils/API/API'
import { ActionAddWorkspaceMembers } from './addWorkspaceMembers.actions'

/** Saga that adds multiple workspace members */
export function* addWorkspaceMembersSaga(receivedAction: ActionAddWorkspaceMembers) {

  const { members, workspaceId } = receivedAction.payload

  let roles = getInitRoles()
  try {
    roles = yield getRoles()
  } catch (e) {
    throw new Error('Unable to get roles for user')
  }

  if (!roles || roles.all.size === 0) throw new Error('The user does not have any role!')

  const url = Endpoint.ADD_WORKSPACE_MEMBERS
    .replace('{workspaceId}', encodeURI(workspaceId))

  const action: ActionAddWorkspaceMembers = yield generalFetch(ActionTypeAPIData.ADD_WORKSPACE_MEMBERS, () => API.post(
    url,
    [...members],
    {
      headers: {
        Accept: API.decideRoleMimeType(roles),
      },
    },
    {
      endpoint: Endpoint.ADD_WORKSPACE_MEMBERS,
    }
  ))

  const addWorkspaceMembersAction: ActionAddWorkspaceMembers = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }

  yield put(addWorkspaceMembersAction)

  if (action.payload.request.state !== APIRequestState.OK) return addWorkspaceMembersAction
  yield call(getUserWorkspacesSaga, getUserWorkspaces())

  return addWorkspaceMembersAction
}

/** Watcher of add workspace members actions */
export function* addWorkspaceMembersWatcher() {
  yield all([
    takeEvery((action: ActionAddWorkspaceMembers) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.ADD_WORKSPACE_MEMBERS]), addWorkspaceMembersSaga),
  ])
}
