import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'
import { ActionRequest } from 'models/redux'
import { UserWorkspacesStore } from './getUserWorkspaces.interfaces'
import { createAPIAction } from 'redux/Helpers'

/** Describes action for getting all workspaces the user is a member of */
export interface ActionGetUserWorkspaces extends ActionRequest {
  payload: {
    request: UserWorkspacesStore
  }
}

/** Creates action to get all workspaces the user is a member of */
export function getUserWorkspaces(): ActionGetUserWorkspaces {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.USER_GET_WORKSPACES], {
    request: new APIRequest(APIRequestState.RUNNING)
  })
}

/** Creates action to purge state of all workspaces the user is a member of */
export function purgeGetUserWorkspaces(): ActionGetUserWorkspaces {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.USER_GET_WORKSPACES], {
    request: new APIRequest(APIRequestState.BEFORE_START)
  })
}
