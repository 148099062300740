import protrait_radv from 'assets/img/protrait/user_radv.svg'
import protrait_win from 'assets/img/protrait/user_win.svg'

export interface CustomerReview {
  img: string
  name: string
  title: string
  content: string
}

export const customerReviews: CustomerReview[] = [
  {
    img: protrait_win,
    name: 'Celine',
    title: 'Project Manager Solutions & Innovations • Wincasa',
    content: 'wincasa'
  },
  {
    img: protrait_radv,
    name: 'Stéphanie',
    title: 'Sales Manager • RealAdvisor',
    content: 'real_advisor'
  }
]
