import { FC } from 'react'
import { PageTransition } from 'utils/animations'
import { TermsAndPrivacyController } from './TermsAndPrivacy.controller'

/**
 * Page with legal imprint and links to terms, cookie policy and privacy policy
 * @example <TermsAndPrivacyPage />
 */
export const TermsAndPrivacyPage: FC = () => {
  return (
    <PageTransition>
      <TermsAndPrivacyController />
    </PageTransition>
  )
}
