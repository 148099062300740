import { FC, Fragment, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Button from 'components/common/Button/Button'
import { Color } from 'constants/assets'
import { EditSavedPaymentMethodPopup } from '../EditSavedPaymentMethodPopup'
import { QueryStatus } from 'components/common/QueryStatus'
import ReactLoading from 'react-loading'
import { SavedPaymentMethodListCard } from 'components/common/SavedPaymentMethodCard/ListCard'
import styles from './SavedPaymentMethods.module.sass'
import { useActionPopup } from 'utils/hooks'
import { useDeleteStripePaymentMethod } from 'dataQueries/payment.query'
import { useEditPaymentMethodContext } from './contexts'
import { useSavedStripePaymentMethods } from 'components/contexts/SavedStripePaymentMethodsContext'

/**
 * Show list of saved payment methods with edit and delete actions.
 * MUST to be inside *SavedStripePaymentMethods* context provider!
 * @example <SavedPaymentMethodsController />
 */
export const SavedPaymentMethodsController: FC = () => {

  const { setEditPaymentMethodModal } = useEditPaymentMethodContext()

  const { t } = useTranslation(['saved_payment_methods_list', 'saved_payment_methods_delete'])
  const { showConfirm } = useActionPopup()
  const {
    savedStripePaymentMethods,
    stripePaymentMethods
  } = useSavedStripePaymentMethods()

  const deleteStripePaymentMethod = useDeleteStripePaymentMethod()

  const handleDelete = useCallback(async (paymentMethodId: string) => {
    if (!(await showConfirm(
      t('saved_payment_methods_delete:confirm_text'),
      {
        title: t('saved_payment_methods_delete:confirm_title')
      }
    ))) return

    deleteStripePaymentMethod.mutate({ paymentMethodId }, {
      onSuccess: () => {
        stripePaymentMethods?.refetch()
      }
    })
  }, [deleteStripePaymentMethod, showConfirm, stripePaymentMethods, t])

  return (
    <div className={styles.savedPaymentMethods}>

      <h2 className="decorated">
        {t('title')}
      </h2>

      {/* LOADING */}
      {stripePaymentMethods?.isPending &&
        <ReactLoading
          type="cylon"
          color={Color.GRAY_TEXT}
        />
      }

      {(stripePaymentMethods?.isSuccess || stripePaymentMethods?.isError) &&
        <Fragment>

          {/* EMPTY */}
          {(!savedStripePaymentMethods || savedStripePaymentMethods.length === 0) &&
            <Fragment>

              <h3 className={styles.emptyTitle}>
                {t('empty_title')}
              </h3>

              <p className={styles.emptyText}>
                {/* Translation has strong tags */}
                <Trans t={t} i18nKey="empty_text">
                  &nbsp;
                </Trans>
              </p>

            </Fragment>
          }

          {/* NOT EMPTY */}
          {!!savedStripePaymentMethods && savedStripePaymentMethods.length > 0 &&
            <Fragment>
              <div className={styles.list}>

                <QueryStatus
                  query={deleteStripePaymentMethod}
                  errorMessage={t('saved_payment_methods_delete:error')}
                  showStates={['error', 'pending']}
                  spaceTopRem={2}
                />

                {savedStripePaymentMethods.map((savedMethod) => {
                  return (
                    <SavedPaymentMethodListCard
                      key={savedMethod.stripeId}
                      paymentMethod={savedMethod}
                    >

                      <Button
                        onClick={() => setEditPaymentMethodModal(savedMethod.stripeId)}
                        type="secondary"
                        isLoading={deleteStripePaymentMethod.isPending}
                        disabled={deleteStripePaymentMethod.isPending}
                      >
                        {t('edit')}
                      </Button>

                      <Button
                        type="secondary red"
                        onClick={() => handleDelete(savedMethod.stripeId)}
                        isLoading={deleteStripePaymentMethod.isPending}
                        disabled={deleteStripePaymentMethod.isPending}
                      >
                        {t('delete')}
                      </Button>

                    </SavedPaymentMethodListCard>
                  )
                })}
              </div>

              <EditSavedPaymentMethodPopup />

            </Fragment>
          }

        </Fragment>
      }

    </div>
  )
}
