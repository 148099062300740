import React, { useState } from 'react'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { Box } from '@mui/material'
import { GRAY_900 } from 'constants/styling/theme'
import { Label } from 'components/common/Label'
import { MUICheckbox } from 'components/common/MUICheckBox'
import { MUINumberField } from 'components/common/MUINumberField'
import { MUIRadio } from 'components/common/MUIRadio'
import { PRIMARY_FIELD_WIDTH } from './_main/HeatingSystem.component'
import Stack from '@mui/material/Stack'
import { VentilationHeatingProps } from '../_main/VentilationAndHeating.component'
import { WaterLocationType } from 'constants/purchaseFlow'
import { useTranslation } from 'react-i18next'
import { useVentilationAndHeating } from '../_main/VentilationAndHeating.context'

/**
 * @component CentralizedHotWater renders options for the user to provide information about the centralized hot water production. 
 *
 * @example
 *   <CentralizedHotWater />
 */
export const CentralizedHotWater: React.FC<VentilationHeatingProps> = ({ id }) => {

  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.energy_heating_page.heating_system' })

  const {
    currentHeatingSystem,
    handleUpdateHeatingSystemItem,
  } = useVentilationAndHeating()

  const {
    waterLocation,
    yearOfConstruction,
    isHotWaterCirculation,
  } = currentHeatingSystem(id) || {}

  const [showError, setShowError] = useState<boolean>(false)

  // If any of the required fields are empty, show error for all of them.
  const handleOnInputBlur = () => {
    if (!showError) setShowError(true)
  }

  return (
    <BorderBoxWrapper padding={2} width='100%'>
      <Stack gap=".8rem">

        {/* CENTRAL WATER LOCATION */}
        <Label text={t('central_water_location')} />

        {Object.values(WaterLocationType).map(type => {
          return (
            <Stack key={type} gap=".8rem" flexDirection="row">
              <MUIRadio
                checked={waterLocation === type}
                onChange={() => handleUpdateHeatingSystemItem(id, { waterLocation: type })}
              />

              <Label color={GRAY_900} text={t(`centralized_water_location_type.${type}`)} />
            </Stack>
          )
        })}

      </Stack>

      {/* DECENTRALIZED - YEAR OF CONSTRUCTION */}
      <Box width={{ xs: '80%', lg: PRIMARY_FIELD_WIDTH }} paddingTop={1.6}>
        <MUINumberField
          min={0}
          required
          inputBoxWidth='100%'
          label={t('year_of_construction')}
          value={yearOfConstruction}
          isError={showError && !yearOfConstruction}
          onBlur={handleOnInputBlur}
          onChange={(value) => handleUpdateHeatingSystemItem(id, { yearOfConstruction: value })}
        />
      </Box>

      {/* HYDRAULIC BALANCING TAKES PLACE? */}
      <Stack flexDirection="row" justifyContent="flex-start" alignItems="center" gap=".8rem" marginTop={1.6}>
        <MUICheckbox
          checked={isHotWaterCirculation}
          required
          onChange={(e) => handleUpdateHeatingSystemItem(id, { isHotWaterCirculation: !isHotWaterCirculation })}
        />

        <Label color={GRAY_900} text={t('isHotWaterCirculation')} />
      </Stack>

    </BorderBoxWrapper>
  )
}
