import { BEIGE_300, BEIGE_400, GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { Box, Stack } from '@mui/material'
import React, { useCallback } from 'react'

import { DateTimeListItem } from '../common/DateTimeListItem'
import { DrawerContentSection } from '../common/DrawerContentSection'
import { MUIButton } from 'components/common/MUIButton'
import { OrderCreator } from '../common/OrderCreator/OrderCreator.component'
import { ProductDTOClient } from 'models/product'
import { ResponsiveDrawer } from 'components/common/ResponsiveDrawer'
import { SectionContentList } from '../common/SectionContentList'
import moment from 'moment'
import { useGalleryDeal } from '../../contexts'
import { useTranslation } from 'react-i18next'

export interface Props {
  /** Whether the order detail drawer is open or not */
  isOpen: boolean,
  /** OnClick action to close the drawer */
  onClose: () => void
}

/**
 * @component Client Gallery Order detail drawer
 * @example <ClientGalleryOrderDetailDrawer isOpen={true} onClose={onClose} />
 */
export const ClientGalleryOrderDetailDrawer: React.FC<Props> = ({
  isOpen,
  onClose
}) => {
  const { t } = useTranslation(['gallery', 'actions'])
  const { dealData } = useGalleryDeal()

  const productTitle = useCallback((product: ProductDTOClient) => {
    return (
      t(`product:p_${product.id}`,
        t(`product_kind:${product.kind}`,
          t(`product_type:${product.type}`,
            t(`category:${product.category}`)
          )
        )
      )
    )
  }, [t])

  if (!dealData) return <></>

  return (
    <ResponsiveDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={`${t('order_informations.order')} #${dealData.id}`}
      actionsSlot={
        <Stack
          flexDirection="column"
          justifyContent="flex-end"
        >
          <MUIButton
            type='secondaryNoBorder'
            onClick={onClose}
          >
            {t('Close')}
          </MUIButton>
        </Stack>
      }
      subTitleSlot={<OrderCreator />}
    >

      <DrawerContentSection title={t('shooting_instructions')}>
        <Box
          fontSize="1.4rem"
          fontWeight={400}
          color={GRAY_900}
          padding="1rem"
          borderRadius=".8rem"
          bgcolor={BEIGE_300}
          sx={{
            border: `1px solid ${BEIGE_400}`
          }}
        >
          {dealData.shootingInstructions || t('not_applicable')}
        </Box>
      </DrawerContentSection>

      <DrawerContentSection title={t('logistics')}>

        <SectionContentList title={t('meeting')}>
          <Box >{t('contact_on_site')}</Box>
        </SectionContentList>

        <SectionContentList title={t('date_and_time')}>
          {dealData?.missions.map((assignment) =>
            <DateTimeListItem
              key={assignment.id}
              title={productTitle(assignment.products[0])}
              date={moment(assignment.shootingStartDateTime).format('LL')}
              time={moment(assignment.shootingStartDateTime).format('LT')}
            />
          )}
        </SectionContentList>

        <SectionContentList title={t('contact_person')}>
          <Box
            fontWeight={600}
            lineHeight="2rem"
          >
            {dealData.contactPerson.name}
          </Box>
          <Stack height="2rem" flexDirection="row" alignItems="center" gap=".4rem">
            <Box color={GRAY_700} fontWeight={400} display="inline-block">{t('email')}</Box> {dealData.contactPerson.email}
          </Stack>
          <Stack height="2rem" flexDirection="row" alignItems="center" gap=".4rem">
            <Box color={GRAY_700} fontWeight={400} display="inline-block">{t('phone')}</Box> {dealData.contactPerson.phone}
          </Stack>
        </SectionContentList>

        <SectionContentList title={t('meeting_instructions')}>
          <Box
            fontSize="1.4rem"
            fontWeight={400}
            color={GRAY_900}
            padding="1rem"
            borderRadius=".8rem"
            bgcolor={BEIGE_300}
            marginTop=".2rem"
            sx={{
              border: `1px solid ${BEIGE_400}`
            }}
          >
            {dealData.meetingInstructions || t('not_applicable')}
          </Box>
        </SectionContentList>

      </DrawerContentSection>

    </ResponsiveDrawer>
  )
}
