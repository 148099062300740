import { useEffect, useState } from 'react'

import moment from 'moment-timezone'

export const useDynamicNow = () => {
  const [dynamicNow, setDynamicNow] = useState(moment.utc())

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDynamicNow(moment.utc())
    }, 1000 * 30)

    return () => clearInterval(intervalId)
  }, [])

  return dynamicNow
}
