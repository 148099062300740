// TODO add ES and NL
export enum TermsLinks {
  EN = 'https://bkbn.com/en/terms-conditions',
  FR = 'https://bkbn.com/fr/terms-conditions',
  DE = 'https://bkbn.com/de/terms-conditions',
  ALL_PURPOSE = 'https://bkbn.com/terms-conditions'
}

// TODO add missing links once they are live
export enum CookiePolicyLinks {
  EN = 'https://bkbn.com/en/about-us/cookie-policy',
  DE = 'https://bkbn.com/de/%C3%BCber-uns/cookie-policy'
}

// TODO add missing links once they are live
export enum PrivacyPolicyLinks {
  EN = 'https://bkbn.com/en/about-us/privacy-policy',
  DE = 'https://bkbn.com/de/%C3%BCber-uns/privacy-policy'
}
