import { FC, ReactNode, useState } from 'react'

import Button from 'components/common/Button/Button'
import Drawer from '@mui/material/Drawer'
import Icon from 'components/common/Icon/Icon'
import { IconType } from 'constants/assets/iconType'
import { MUIDivider } from 'components/common/MUIDivider/MUIDivider.component'
import classnames from 'classnames'
import closeButtonStyles from 'components/styles/close-button.module.sass'
import { logAnalyticsEvent } from 'utils/analytics'
import styles from './MobileControlPanel.module.sass'
import { useAuth0 } from 'utils/auth'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @interface Props Input properties of MobileControlPanel component
 */
interface Props {
  /** Content of the mobile control panel */
  children: ReactNode
  /** Trigger button title and control panel title */
  title: string
  /** Trigger button icon */
  buttonIcon?: ReactNode
}

/**
 * @component MobileControlPanel 
 * @example <MobileControlPanel children={children} />
 * */
export const MobileControlPanel: FC<Props> = ({ children, title, buttonIcon }) => {
  const { clientData } = useUserData()
  const { roles } = useAuth0()
  const [isMobileControlPanelOpen, setIsMobileControlPanelOpen] = useState(false)

  return (
    <div className={styles.controlPanelMobileContainer}>
      <Button
        type="primary"
        textAndIcon
        className={styles.controlPanelMobileButton}
        onClick={() => {
          logAnalyticsEvent(`${roles.isCreative ? 'CT Mobile Filter Floating Button' : 'Mobile Filter Floating Button'}`, {
            userEmail: clientData?.email,
            organizationId: clientData?.organizationId,
          })
          setIsMobileControlPanelOpen(true)
        }}
      >
        {buttonIcon && <div className={styles.icon}>{buttonIcon}</div>}
        {title}
      </Button>

      <Drawer
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(37, 42, 46, 0.95)',
            backdropFilter: 'blur(.2rem)',
          }
        }}
        PaperProps={{
          sx: {
            borderRadius: '2rem 2rem 0 0',
          }
        }}
        anchor="bottom"
        open={isMobileControlPanelOpen}
        onClose={() => setIsMobileControlPanelOpen(false)}
        transitionDuration={300}
      >
        <div className={styles.mobileContainer}>
          <div className={classnames(styles.controlPanel)}>
            <div className={styles.headerContainer}>
              <div className={styles.header}>
                <Button className={classnames(closeButtonStyles.closeBtnRight, styles.closeButton)} type="secondary noborder" onClick={() => setIsMobileControlPanelOpen(false)}>
                  <Icon icon={IconType.CROSS} />
                </Button>

                <span className={styles.title}>{title}</span>
              </div>

              <MUIDivider margin={20} />
            </div>

            <div className={styles.children}>{children}</div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}
