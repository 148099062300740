import { APIRequestState, Endpoint, RoleMimetype } from 'constants/API'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { all, put, takeEvery } from 'redux-saga/effects'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { EntityKeys, QueryType } from 'utils/reactQuery'

import API from 'utils/API/API'
import { ActionEditMissionAdminCommentsForEditor } from '.'

/** Saga which edits mission admin comments for editor */
export function* editMissionAdminCommentsForEditorSaga(receivedAction: ActionEditMissionAdminCommentsForEditor) {
  const { missionId, comments } = receivedAction.payload
  const url = Endpoint.ADMIN_EDIT_ASSIGNMENT_ADMIN_COMMENTS_FOR_EDITOR
    .replace('{assignmentId}', encodeURI(missionId.toString()))
  const action: ActionEditMissionAdminCommentsForEditor = yield generalFetch(ActionTypeAPIData.EDIT_ASSIGNMENT_ADMIN_COMMENTS_FOR_EDITOR, () => API.put(url, {
    message: comments,
  }, {
    headers: {
      Accept: RoleMimetype.ADMIN,
    },
  }, {
    endpoint: Endpoint.ADMIN_EDIT_ASSIGNMENT_ADMIN_COMMENTS_FOR_EDITOR,
  }))

  const editMissionAdminCommentsForEditorAction: ActionEditMissionAdminCommentsForEditor = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }

  yield put(editMissionAdminCommentsForEditorAction)

  if (action.payload.request.state !== APIRequestState.OK) return
  receivedAction.queryClient?.invalidateQueries({ queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET_ONE, { assignmentId: receivedAction.payload.missionId }] })
}

/** Watcher of edit mission admin comments for editor actions */
export function* editMissionAdminCommentsForEditorWatcher() {
  yield all([
    takeEvery((action: ActionEditMissionAdminCommentsForEditor) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.EDIT_ASSIGNMENT_ADMIN_COMMENTS_FOR_EDITOR]), editMissionAdminCommentsForEditorSaga),
  ])
}
