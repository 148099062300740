import { CORAL_600, GRAY_700 } from 'constants/styling/theme'
import React, { ReactNode, useMemo } from 'react'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

/** @interface LabelProps for the Label component. */
export interface LabelProps {
  /** The text or ReactNode to be displayed inside the label. */
  text: string | ReactNode
  /** Whether the label is for a required field. */
  required?: boolean
  /** Whether the label should be styled as an error. */
  isError?: boolean
  /** Custom tooltip besides label. */
  tooltip?: ReactNode
  /** Custom color for the text, default is GRAY_700. */
  color?: string
}

/**
 * @component
 * Label component to display a text label with optional required indicator and tooltip.
 *
 * @example
 * <Label text='Label Text' required isError tooltip={<Tooltip text='Tooltip Text' />} />
 */
export const Label: React.FC<LabelProps> = ({ text, required, isError, tooltip, color = GRAY_700 }) => {

  const textColor = useMemo(() => isError ? CORAL_600 : color, [isError, color])

  return (
    <Stack gap=".4rem" flexDirection="row" justifyContent="flex-start" alignItems="center">

      <Typography variant="text-sm" fontWeight="medium" color={textColor}>
        {text}{required ? '*' : ''}
      </Typography>

      {tooltip}

    </Stack>
  )
}
