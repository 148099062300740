import { DistrictHeatingFuelType, ElectricHeatingFuelType, FuelTypeAllowedEnums, GasFuelType, HeatPumpFuelType, HeatingFuelType, IndividualStoveFuelType, OilFuelType, WoodPelletsEnergySourceType, WoodPelletsFuelType } from 'constants/purchaseFlow'
import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'
import { Stack, Typography } from '@mui/material'

import { GRAY_900 } from 'constants/styling/theme'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { PRIMARY_FIELD_WIDTH } from './_main/HeatingSystem.component'
import React from 'react'
import { VentilationHeatingProps } from '../_main/VentilationAndHeating.component'
import { useTranslation } from 'react-i18next'
import { useVentilationAndHeating } from '../_main/VentilationAndHeating.context'

/**
 * @component SelectedHeatingFuelOptions
 * @description Renders all options to select from, for the selected heating fuel.
 *
 * @example
 * <SelectedHeatingFuelOptions id="heating-system-1" />
 */
export const SelectedHeatingFuelOptions: React.FC<VentilationHeatingProps> = ({ id }) => {

  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.energy_heating_page.heating_system' })

  const {
    currentHeatingSystem,
    handleUpdateHeatingSystemItem,
  } = useVentilationAndHeating()

  const {
    heatingFuel,
    heatingFuelOption,
    woodPalletsEnergySource,
  } = currentHeatingSystem(id) || {}

  const getFuelType = (): { key: string, enum: any } => {
    switch (heatingFuel) {
      case HeatingFuelType.OIL:
        return { key: 'oil_fuel_type', enum: OilFuelType }
      case HeatingFuelType.GAS:
        return { key: 'gas_fuel_type', enum: GasFuelType }
      case HeatingFuelType.WOOD_PELLETS_SOLIDS:
        return { key: 'wood_pelets_fuel_type', enum: WoodPelletsFuelType }
      case HeatingFuelType.DISTRICT_HEATING:
        return { key: 'district_heating_fuel_type', enum: DistrictHeatingFuelType }
      case HeatingFuelType.HEAT_PUMP:
        return { key: 'heat_pump_fuel_type', enum: HeatPumpFuelType }
      case HeatingFuelType.ELECTRIC_HEATING:
        return { key: 'electric_heating_fuel_type', enum: ElectricHeatingFuelType }
      case HeatingFuelType.INDIVIDUAL_STOVE:
        return { key: 'individual_stove_fuel_type', enum: IndividualStoveFuelType }

      default:
        return { key: '', enum: null }
    }
  }

  const SelectedEnum: FuelTypeAllowedEnums = getFuelType().enum

  return (
    <>

      <Stack gap="1.8rem" width={{ xs: '100%', lg: PRIMARY_FIELD_WIDTH }} position="relative">
        <MUIDropdown
          sx={{ width: '100%' }}
          button={(isOpen, action) => (
            <MUIDropdownInput
              readOnly
              required
              label={t(`heating_fuel_type.${heatingFuel}`)}
              type={DropdownInputTypes.SELECTOR}
              value={t(
                !!heatingFuelOption && Object.values(SelectedEnum).includes(heatingFuelOption)
                  ? `${getFuelType().key}.${heatingFuelOption}`
                  : 'select_heating_fuel_option'
              )}
              onClick={action}
            />
          )}
        >
          {Object.values(SelectedEnum).map((type) => (
            <MUIDropdownItem
              key={type}
              onClick={() => handleUpdateHeatingSystemItem(id, {
                heatingFuelOption: type as FuelTypeAllowedEnums
              })}
            >
              <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                {t(`${getFuelType().key}.${type}`)}
              </Typography>
            </MUIDropdownItem>
          ))}
        </MUIDropdown>
      </Stack>

      {heatingFuel === HeatingFuelType.WOOD_PELLETS_SOLIDS &&
        <Stack gap="1.8rem" width={{ xs: '100%', lg: PRIMARY_FIELD_WIDTH }} position="relative">
          <MUIDropdown
            sx={{ width: '100%' }}
            button={(isOpen, action) => (
              <MUIDropdownInput
                readOnly
                required
                label={t('wood_pallets_energy_source_title')}
                type={DropdownInputTypes.SELECTOR}
                value={t(
                  !!woodPalletsEnergySource
                    ? `wood_pallets_energy_source.${woodPalletsEnergySource}`
                    : 'select_wood_pallets_energy_source_option'
                )}
                onClick={action}
              />
            )}
          >
            {Object.values(WoodPelletsEnergySourceType).map((type) => (
              <MUIDropdownItem
                key={type}
                onClick={() => handleUpdateHeatingSystemItem(id, {
                  woodPalletsEnergySource: type
                })}
              >
                <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                  {t(`wood_pallets_energy_source.${type}`)}
                </Typography>
              </MUIDropdownItem>
            ))}
          </MUIDropdown>
        </Stack>
      }

    </>
  )
}
