import { FC, Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { MUIButton } from 'components/common/MUIButton'
import { Path } from 'constants/router'
import { externalNavigate } from 'utils/helpers'
import styles from './SubscriptionInstructionUpsell.module.sass'

interface Props {
  title: string
  text: string
  onClick?: () => unknown
}

/**
 * Standardized upsell message with title, info text and link to subscription pricing landing page.
 * 
 * @example
 * <SubscriptionInstructionUpsell
 *   title="This is awesome!"
 *   info="If you wanna be my subscriber, you gotta get with my friends. Make it last forever, friendship never ends..."
 *   onClick={onClickAction}
 * />
 */
export const SubscriptionInstructionUpsell: FC<Props> = ({
  title,
  text,
  onClick
}) => {

  const { t } = useTranslation(['instruction_upsell'])

  return (
    <Fragment>

      <h4 className={styles.upsellTitle}>
        {title}
      </h4>

      <div className={styles.upsellInfo}>

        <Trans parent="p">
          {text}
        </Trans>

        <MUIButton
          type="secondaryBorder"
          className={styles.upsellAction}
          onClick={() => {
            if (onClick) onClick()
            externalNavigate(Path.PRICING)
          }}
        >
          {t('link_cta')}
        </MUIButton>

      </div>

    </Fragment>
  )
}
