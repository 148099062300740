import './AutoFireActionInput.sass'

import { useEffect, useRef, useState } from 'react'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'
import { DEBOUNCE_TIMEOUT } from 'constants/application'
import { Nullable } from 'models/helpers'
import { debounceEffect } from 'utils/helpers'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** The request from which is state taken */
  request?: Nullable<APIRequest<any>>,
  /** Value of the input */
  value: string
  /** The render method passing className and state */
  render: (className: string, state: APIRequestState) => JSX.Element
  /** Action to be called upon value change (after debounce) */
  action: (...params: unknown[]) => void
}

/**
 * @component Automatically fires an action upon value change with debounce
 * @example
 * <AutoFireActionInput
    value={someValue}
    request={someRequest}
    action={() => doSomething()}
    render={(className, state) => (
      <textarea
        className={`original-class ${className}`}
        value={someValue}
        onChange={e => setSomeValue(e.target.value)}
      >
      </textarea>
    )}
  />
 */
const AutoFireActionInput: React.FC<Props> = ({
  request = new APIRequest(),
  value,
  render,
  action,
}) => {
  if (!request) request = new APIRequest()

  const [lastFiredValue, setLastFiredValue] = useState(value)
  const [requiresFiring, setRequiresFiring] = useState(false)
  const debounceTimeoutRef = useRef<number | undefined>(undefined)
  const valueRef = useRef<string>(value)
  const actionRef = useRef<(...params: unknown[]) => void>(action)

  // Save ref to current value
  useEffect(() => {
    valueRef.current = value
  }, [value])

  // Save ref to current action
  useEffect(() => {
    actionRef.current = action
  }, [action])

  // Debounce value
  useEffect(() => {
    // Do not fire action if value equals last fired value
    if (value === lastFiredValue) return

    debounceEffect(() => {
      setRequiresFiring(true)
    }, debounceTimeoutRef, DEBOUNCE_TIMEOUT)
  }, [value, lastFiredValue])

  // Fire the action if required
  useEffect(() => {
    if (request?.state === APIRequestState.RUNNING) return
    if (requiresFiring === false) return

    // Fire the action
    actionRef.current()
    setLastFiredValue(valueRef.current)
    setRequiresFiring(false)
  }, [request, requiresFiring])

  let className = 'autofireactioninput'
  let state = request.state
  switch (request.state) {
    case APIRequestState.OK:
      if (value === lastFiredValue) className += ' ok'
      else state = APIRequestState.BEFORE_START
      break
    case APIRequestState.ERROR:
      if (value === lastFiredValue) className += ' error'
      else state = APIRequestState.BEFORE_START
      break
    case APIRequestState.RUNNING:
      className += ' running'
      break
  }

  return render(className, state)
}

export default AutoFireActionInput