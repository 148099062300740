import { Color } from 'constants/assets'
import { FC } from 'react'
import ReactLoading from 'react-loading'
import styles from 'components/pages/VisualsEditing/VisualsEditingPreviewGalleryImage.module.sass'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** The image URL to display */
  imageUrl?: string
  /** File name below the image */
  fileName?: string
  /** OnClick action on preview image */
  onClick?: () => void
}

/**
 * Gallery Image for Visual Editing flow preview
 * @example <VisualsEditingPreviewGalleryImage imageUrl={imageUrl} fileName="Visual_005" />
 */
export const VisualsEditingPreviewGalleryImage: FC<Props> = ({ imageUrl, fileName, onClick }) => {
  let style: React.CSSProperties = {}

  if (imageUrl) {
    style.backgroundImage = `url('${imageUrl}')`
  }

  if (onClick) {
    style.cursor = 'pointer'
  }

  return (
    <div className={styles.container} onClick={onClick}>

      <div className={styles.previewGalleryImage} style={style}>
        {!imageUrl && <ReactLoading color={Color.GRAY_TEXT} type="cylon" className={`spinner loading infinite ${styles.spinner}`} />}
      </div>

      {fileName && <span className={styles.fileName}>{fileName}</span>}
    </div>
  )
}
