import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'
import { HeaterLocationType, HeatingFuelType, handoverAndPumpingFields } from 'constants/purchaseFlow'

import { GRAY_900 } from 'constants/styling/theme'
import { GeneratorYearDesignationPower } from '../GeneratorYearDesignationPower.component'
import { HandoverAndPumping } from '../HandoverAndPumping.component'
import { HeatingCombinedWith } from '../HeatingCombinedWith.component'
import { HotWater } from '../HotWater.component'
import { Label } from 'components/common/Label'
import { MUICheckbox } from 'components/common/MUICheckBox'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUIRadio } from 'components/common/MUIRadio'
import { NightService } from '../NightService.component'
import React from 'react'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import { SelectedHeatingFuelOptions } from '../SelectedHeatingFuelOptions.component'
import Stack from '@mui/material/Stack'
import { TotalHeatingOutput } from '../TotalHeatingOutput.component'
import Typography from '@mui/material/Typography'
import { VentilationHeatingProps } from '../../_main/VentilationAndHeating.component'
import { useTranslation } from 'react-i18next'
import { useVentilationAndHeating } from '../../_main/VentilationAndHeating.context'

export const PRIMARY_FIELD_WIDTH = '39.6rem'

/**
 * @component HeatingSystem 
 * @description Renders options for the user to provide information about the heating. 
 * 
 * @example
 *   <HeatingSystem id="heating-system-1" />
 */
export const HeatingSystem: React.FC<VentilationHeatingProps> = ({ id }) => {

  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.energy_heating_page.heating_system' })

  const {
    currentHeatingSystem,
    handleUpdateHeatingSystemItem,
  } = useVentilationAndHeating()

  const {
    heatingFuel,
    heaterLocation,
    isHydraulicBalancing,
  } = currentHeatingSystem(id) || {}

  const showHandoverAndPumping = !!heatingFuel && handoverAndPumpingFields?.has(heatingFuel)

  return (
    <SectionedBorderBox
      title={
        <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
          {t('heating_system_title')}
        </Typography>
      }
    >
      <Stack
        sx={{
          flexDirection: { xs: 'column', lg: 'row' }
        }}
        spacing={2}
        width="100%"
      >

        <Stack alignItems="flex-start" gap={1.6} width={{ xs: '100%', sm: '50%' }} marginBottom={{ xs: '2.8rem', sm: 0 }}>

          {/* GENERATOR YEAR OF MANUFACTURE, HEATING DESIGNATION - optional field, HEATING POWER - optional field */}
          <GeneratorYearDesignationPower id={id} />

          {/* HEATER LOCATION */}
          <Stack gap=".8rem">

            <Label text={t('heater_location')} />

            {Object.values(HeaterLocationType).map(type => {
              return (
                <Stack key={type} gap=".8rem" flexDirection="row">
                  <MUIRadio
                    checked={heaterLocation === type}
                    onChange={() => handleUpdateHeatingSystemItem(id, { heaterLocation: type })}
                  />

                  <Label color={GRAY_900} text={t(`heater_location_type.${type}`)} />
                </Stack>
              )
            })}

          </Stack>

          {/* NIGHT SERVICE */}
          <NightService id={id} />

          {/* HEATING FUEL */}
          <Stack gap="1.8rem" width={{ xs: '100%', lg: PRIMARY_FIELD_WIDTH }} position="relative">
            <MUIDropdown
              sx={{ width: '100%' }}
              button={(isOpen, action) => (
                <MUIDropdownInput
                  readOnly
                  required
                  label={t('heating_fuel')}
                  type={DropdownInputTypes.SELECTOR}
                  value={t(!!heatingFuel ? `heating_fuel_type.${heatingFuel}` : 'select_heating_fuel_option')}
                  onClick={action}
                />
              )}
            >
              {Object.values(HeatingFuelType).map(type => (
                <MUIDropdownItem key={type} onClick={() => handleUpdateHeatingSystemItem(id, { heatingFuel: type })}>
                  <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                    {t(`heating_fuel_type.${type}`)}
                  </Typography>
                </MUIDropdownItem>
              ))}
            </MUIDropdown>
          </Stack>

          {/* SELECTED HEATING FUEL OPTIONS */}
          {!!heatingFuel &&
            <SelectedHeatingFuelOptions id={id} />
          }

          {/* HYDRAULIC BALANCING TAKES PLACE? */}
          <Stack flexDirection="row" justifyContent="flex-start" alignItems="center" gap=".8rem">
            <MUICheckbox
              checked={isHydraulicBalancing}
              required
              onChange={(e) => handleUpdateHeatingSystemItem(id, { isHydraulicBalancing: !isHydraulicBalancing })}
            />

            <Label color={GRAY_900} text={t('hydraulic_balancing')} />
          </Stack>

          {/* HANDOVER AND PUMPING */}
          {showHandoverAndPumping &&
            <HandoverAndPumping id={id} />
          }

          {/* HEATING COMBINED WITH... */}
          <HeatingCombinedWith id={id} />

          {/* HOT WATER */}
          <HotWater id={id} />

          {/* TOTAL HEATING OUTPUT */}
          <TotalHeatingOutput id={id} />

        </Stack>
      </Stack>
    </SectionedBorderBox>
  )
}
