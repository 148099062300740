import moment from 'moment-timezone'
import i18n from 'translations/i18n'

/** Config for datetime format, the type is either UTCOffset or Timezone */
export type MomentConfig = MomentUTCOffsetConfig | MomentTimezoneConfig

export interface MomentUTCOffsetConfig {
  utcOffset: string
}

export interface MomentTimezoneConfig {
  timezone: string
}

interface DifferenceValues {
  days: number
  hours: number
  minutes: number
  seconds: number
  miliseconds: number
}

/** Typeguard */
function IMomentConfigIsIMomentUTCOffsetConfig(config: MomentConfig): config is MomentUTCOffsetConfig {
  return (
    (config as MomentUTCOffsetConfig).utcOffset !== undefined
  )
}

/** Typeguard */
function IMomentConfigIsIMomentTimezoneConfig(config: MomentConfig): config is MomentTimezoneConfig {
  return (
    (config as MomentTimezoneConfig).timezone !== undefined
  )
}

/**
 * Takes startDateTime and endDateTime parameters in a format 'YYYY-MM-DDThh:mm:ss.ffffff+/-hh:mm'
 * and returns object containing difference in hours and minutes
 * 
 * @example difference('2020-11-10T10:00:00Z', '2020-11-10T11:30:00Z') //{days: 0, hours: 1, minutes: 30, seconds: 0, miliseconds: 0}
 */
export function difference(startDateTime: string, endDateTime: string, config?: MomentConfig): DifferenceValues {
  if (!isISOString(startDateTime)) throw new Error(`${startDateTime} is not in a valid ISO 8601 format`)
  if (!isISOString(endDateTime)) throw new Error(`${endDateTime} is not in a valid ISO 8601 format`)

  if (!isISOStringWithOffset(startDateTime)) throw new Error(`${startDateTime} is not in a valid ISO 8601 format with offset`)
  if (!isISOStringWithOffset(endDateTime)) throw new Error(`${endDateTime} is not in a valid ISO 8601 format with offset`)

  moment.locale(i18n.language)
  let start = moment(startDateTime)
  let end = moment(endDateTime)

  if (config) {
    if (IMomentConfigIsIMomentUTCOffsetConfig(config) && config.utcOffset) {
      start = start.utcOffset(config.utcOffset)
      end = end.utcOffset(config.utcOffset)
    }

    if (IMomentConfigIsIMomentTimezoneConfig(config) && config.timezone) {
      start = start.tz(config.timezone)
      end = end.tz(config.timezone)
    }
  }

  const duration = start.isSameOrBefore(end) ? moment.duration(end.diff(start)) : moment.duration(start.diff(end))

  const result: DifferenceValues = {
    days: duration.days(),
    hours: duration.hours(),
    minutes: duration.minutes(),
    seconds: duration.seconds(),
    miliseconds: duration.milliseconds(),
  }

  return result
}

/**
 * Takes arguments 'minutes' and 'hours' (optional) as a number and returns a time in format 'h:mm'
 * 
 * @example getShortTimeString(5, 21) // 21h05
 * @example getShortTimeString(15) // 0h15
 * @example getShortTimeString(90) // 1h30
 */
export function getShortTimeString(minutes: number, hours = 0): string {
  moment.locale(i18n.language)
  if (hours < 0) hours = Math.abs(hours)
  if (minutes < 0) minutes = Math.abs(minutes)
  if (minutes > 60) {
    hours += Math.floor(minutes / 60)
    minutes = minutes % 60
  }

  const minutesString = minutes.toLocaleString(undefined, { minimumIntegerDigits: 2 })
  return i18n.t('time_translations:time', { hour: hours, minute: minutesString })
}

/**
 * Takes startDateTime in format 'YYYY-MM-DDThh:mm:ss.ffffff+/-hh:mm'
 * and returns a shootingDate in format 'dddd MMMM D, YYYY'
 * 
 * @example getDateString('2020-12-02T21:00:00Z') // Saturday December 2, 2020 
 */
export function getDateString(startDateTime: string, config?: MomentConfig): string {
  if (!isISOString(startDateTime)) throw new Error(`${startDateTime} is not in a valid ISO 8601 format`)

  if (!isISOStringWithOffset(startDateTime)) throw new Error(`${startDateTime} is not in a valid ISO 8601 format with offset`)

  moment.locale(i18n.language)
  if (startDateTime) {
    let shootingDate = moment(startDateTime)
    if (config) {
      if (IMomentConfigIsIMomentUTCOffsetConfig(config) && config.utcOffset) shootingDate = shootingDate.utcOffset(config.utcOffset)
      if (IMomentConfigIsIMomentTimezoneConfig(config) && config.timezone) shootingDate = shootingDate.tz(config.timezone)
    }
    // return shootingDate.format('dddd MMMM D, YYYY')
    return shootingDate.format('dddd LL')
  } else return ''
}

/** 
 * Takes startDateTime in format 'YYYY-MM-DDThh:mm:ss.ffffff+/-hh:mm'
 * and returns a shootingTime in format 'h:mm'
 * 
 * @example getTimeString('2020-12-02T21:05:00Z') // 21h05
 */
export function getTimeString(startDateTime: string, config?: MomentConfig): string {
  if (!isISOString(startDateTime)) throw new Error(`${startDateTime} is not in a valid ISO 8601 format`)

  if (!isISOStringWithOffset(startDateTime)) throw new Error(`${startDateTime} is not in a valid ISO 8601 format with offset`)

  moment.locale(i18n.language)
  if (startDateTime) {
    let start = moment(startDateTime)
    if (config) {
      if (IMomentConfigIsIMomentUTCOffsetConfig(config) && config.utcOffset) start = start.utcOffset(config.utcOffset)
      if (IMomentConfigIsIMomentTimezoneConfig(config) && config.timezone) start = start.tz(config.timezone)
    } else {
      start = start.utcOffset('Z', false)
    }
    return getShortTimeString(start.get('minutes'), start.get('hours'))
  } else return ''
}

/** 
 * Takes startDateTime and endDateTime parameters in format 'YYYY-MM-DDThh:mm:ss.ffffff+/-hh:mm'
 * and returns timeDuration string (difference in days, hours, minutes, seconds)
 * 
 * @example getTimeDurationString('2020-11-10T00:00:00Z', '2020-11-12T18:30:42Z') // 2 days 18 hours 30 minutes 42 seconds
 */
export function getTimeDurationString(startDateTime: string, endDateTime: string, config?: MomentConfig): string {
  if (!isISOString(startDateTime)) throw new Error(`${startDateTime} is not in a valid ISO 8601 format`)
  if (!isISOString(endDateTime)) throw new Error(`${endDateTime} is not in a valid ISO 8601 format`)

  if (!isISOStringWithOffset(startDateTime)) throw new Error(`${startDateTime} is not in a valid ISO 8601 format with offset`)
  if (!isISOStringWithOffset(endDateTime)) throw new Error(`${endDateTime} is not in a valid ISO 8601 format with offset`)

  moment.locale(i18n.language)
  let timeDuration = ''
  if (startDateTime && endDateTime) {
    const timeDurationData = difference(startDateTime, endDateTime, config)
    if (timeDurationData.days) timeDuration += `${timeDurationData.days} ${i18n.t('time_translations:day', { count: timeDurationData.days })}`
    if (timeDurationData.days && timeDurationData.hours) timeDuration += ' '
    if (timeDurationData.hours) timeDuration += `${timeDurationData.hours} ${i18n.t('time_translations:hour', { count: timeDurationData.hours })}`
    if (timeDurationData.hours && timeDurationData.minutes) timeDuration += ' '
    if (timeDurationData.minutes) timeDuration += `${timeDurationData.minutes} ${i18n.t('time_translations:minute', { count: timeDurationData.minutes })}`
    if (timeDurationData.minutes && timeDurationData.seconds) timeDuration += ' '
    if (timeDurationData.seconds) timeDuration += `${timeDurationData.seconds} ${i18n.t('time_translations:second', { count: timeDurationData.seconds })}`
  }
  return timeDuration
}

/**
 * Takes arguments 'minutes' and 'hours' (optional) as a number
 * and returns timeDuration string (difference in days, hours, minutes, seconds)
 * 
 * @example getTimeDurationStringFromMinutesAndHours(90) // 1 hour 30 minutes
 * @example getTimeDurationStringFromMinutesAndHours(20, 3) // 3 hours 20 minutes
 */
export function getTimeDurationStringFromMinutesAndHours(minutes: number, hours = 0, config?: MomentConfig): string {
  if (hours < 0) hours = Math.abs(hours)
  if (minutes < 0) minutes = Math.abs(minutes)
  if (minutes > 60) {
    hours += Math.floor(minutes / 60)
    minutes = minutes % 60
  }

  const startMomentDate = moment()
  const endMomentDate = moment(startMomentDate).add(hours, 'hours').add(minutes, 'minutes')
  return getTimeDurationString(startMomentDate.toISOString(), endMomentDate.toISOString(), config)
}

/**
 * Compares current date in two timezones and returns a boolean value
 * 
 * @example isSameTimezone('Europe/Berlin', 'Europe/Prague') // true
 * @example isSameTimezone('Europe/Berlin', 'US/Arizona') // false
 */
export function isSameTimezone(timezoneA: string, timezoneB: string) {
  const dateString = new Date().toISOString()

  const dateA = moment(dateString).tz(timezoneA)
  const dateB = moment(dateString).tz(timezoneB)

  return dateA.toISOString(true) === dateB.toISOString(true)
}

/** 
 * Decides if string is a valid ISO 8601 DateTime string
 * 
 * @example isISOString('2020-10-10T18:23:00') // true
 * @example isISOString('2020-10-10T18:23:00.000') // true
 * @example isISOString('2020-10-10T18:23:00+04:00') // true
 * @example isISOString('2020-10-10T18:23:00-06:59') // true
 * @example isISOString('2020-10-10T18:23:00+0600') // true
 * @example isISOString('2020-10-10T18:23:00-1259') // true
 * @example isISOString('2020-10-10T18:23:00Z') // true
 * @example isISOString('2020-10-10T18:23:00.00Z') // true
 * @example isISOString('2020-10-10T18:23:00.000000+04:00') // true
 * @example isISOString('') // false
 * @example isISOString('AAA_2020-10-10T18:23:00.00Z_AAA') // false
 */
export function isISOString(isoString: string) {
  const regex = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]+)?((Z|((\+|-)(2[0-3]|[01][0-9]):?([0-5][0-9]))))?$/
  return !!isoString.match(regex)
}

/** 
 * Decides if string is a valid ISO 8601 DateTime string with offset in the form of either Z (Zulu) or +/- hh:mm/hhmm offset
 * 
 * @example isISOStringWithOffset('2020-10-10T18:23:00+04:00') // true
 * @example isISOStringWithOffset('2020-10-10T18:23:00-06:59') // true
 * @example isISOStringWithOffset('2020-10-10T18:23:00+0600') // true
 * @example isISOStringWithOffset('2020-10-10T18:23:00-1259') // true
 * @example isISOStringWithOffset('2020-10-10T18:23:00Z') // true
 * @example isISOStringWithOffset('2020-10-10T18:23:00.00Z') // true
 * @example isISOStringWithOffset('2020-10-10T18:23:00.000000+04:00') // true
 * @example isISOStringWithOffset('2020-10-10T18:23:00') // false
 * @example isISOStringWithOffset('2020-10-10T18:23:00.000') // false
 * @example isISOStringWithOffset('') // false
 * @example isISOStringWithOffset('AAA_2020-10-10T18:23:00.00Z_AAA') // false
 */
export function isISOStringWithOffset(isoString: string) {
  const regex = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]+)?(Z|((\+|-)(2[0-3]|[01][0-9]):?([0-5][0-9])))$/
  return !!isoString.match(regex)
}

/** 
 * Decides if string is a valid ISO 8601 DateTime string without offset
 * 
 * @example isISOString('2020-10-10T18:23:00') // true
 * @example isISOString('2020-10-10T18:23:00.000') // true
 * @example isISOString('2020-10-10T18:23:00+04:00') // false
 * @example isISOString('2020-10-10T18:23:00-06:59') // false
 * @example isISOString('2020-10-10T18:23:00+0600') // false
 * @example isISOString('2020-10-10T18:23:00-1259') // false
 * @example isISOString('2020-10-10T18:23:00Z') // false
 * @example isISOString('2020-10-10T18:23:00.00Z') // false
 * @example isISOString('2020-10-10T18:23:00.000000+04:00') // false
 * @example isISOString('') // false
 * @example isISOString('AAA_2020-10-10T18:23:00.00Z_AAA') // false
 */
export function isISOStringWithoutOffset(isoString: string) {
  const regex = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]+)?$/
  return !!isoString.match(regex)
}

/**
 * Takes date and rounds it up to nearest chosen time segment.
 * If Date is within the same minute as a first minute of the time segment, returns the date with seconds and milliseconds zeroed.
 * 
 * @param date either Date or Moment object to be rounded
 * @param segment time segment to which rounding should happen (quarter-hour | half-hour | hour)
 * 
 * @example roundUpToNearestTimeSegment(new Date('2022-06-08T14:15:10.000Z'), 'quarter-hour') // 2022-06-08T14:15:00.000Z
 * @example roundUpToNearestTimeSegment(new Date('2022-06-08T14:16:10.000Z'), 'quarter-hour') // 2022-06-08T14:30:00.000Z
 * @example roundUpToNearestTimeSegment(new Date('2022-06-08T14:29:59.000Z'), 'half-hour') // 2022-06-08T14:30:00.000Z
 * @example roundUpToNearestTimeSegment(new Date('2022-06-08T14:28:10.000Z'), 'hour') // 2022-06-08T15:00:00.000Z
 */
export function roundUpToNearestTimeSegment(
  date: Date | moment.Moment,
  segment: 'quarter-hour' | 'half-hour' | 'hour'
): Date {

  const lookup: Record<typeof segment, number> = {
    'hour': 60,
    'half-hour': 30,
    'quarter-hour': 15,
  }
  const segmentValueInMinutes = lookup[segment] || 1

  const dateToRound: moment.Moment = date instanceof Date
    ? moment(date)
    : date

  const reminder = segmentValueInMinutes - (dateToRound.get('minutes') % segmentValueInMinutes)

  const roundedDate = reminder !== segmentValueInMinutes
    ? dateToRound.add(reminder, 'minutes')
    : dateToRound

  return roundedDate.seconds(0).milliseconds(0).toDate()
}
