import { PurchaseFlowStepKey, usePurchaseFlowUtilities } from '../../../_main/contexts'

import { DynamicFlowStep } from 'components/common/DynamicFlow'
import { FC } from 'react'
import { InstructionStepController } from './InstructionStep.controller'

export const InstructionStep: FC = () => {

  const { isInstructionStepValid } = usePurchaseFlowUtilities()

  return (
    <DynamicFlowStep
      stepKey={PurchaseFlowStepKey.INSTRUCTIONS}
      isValid={isInstructionStepValid}
    >
      <InstructionStepController />
    </DynamicFlowStep>
  )
}
