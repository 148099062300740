import { FC, ReactNode } from 'react'

import { GRAY_900 } from 'constants/styling/theme'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

/** Props for the StepWrapper component. */
interface Props {
  /** The content to be wrapped by the StepWrapper component.  */
  children: ReactNode
  /** The main title displayed in the step wrapper. */
  title: string
  /** An optional subtitle displayed below the main title. */
  subtitle?: string
}

/**
 * @component
 * StepWrapper component wraps its children with a standardized layout for step components.
 *
 * @example
 * <StepWrapper
 *   title="That's what she said!"
 *   subtitle="Said Michael Scott during a meeting at Dunder Mifflin."
 * >
 *   <StepContent />
 * </StepWrapper>
 */
export const StepWrapper: FC<Props> = ({ title, subtitle, children }) => {
  return (
    <Stack paddingBottom={4}>

      {/** STEP HEADER */}
      <Stack spacing={0.5} marginBottom={4}>

        <Typography color={GRAY_900} variant='text-xl' fontWeight={600}>
          {title}
        </Typography>

        {subtitle && <Typography variant="text-sm">{subtitle}</Typography>}

      </Stack>

      {/** STEP CONTENT */}
      {children}

    </Stack>
  )
}
