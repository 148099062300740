import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'
import { GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { RoofConditionType, RoofPitchType } from 'constants/purchaseFlow'

import { Label } from 'components/common/Label'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUINumberField } from 'components/common/MUINumberField'
import { MUIRadio } from 'components/common/MUIRadio'
import React from 'react'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useConstructionMethod } from './_main/ConstructionMethod.context'
import { useTranslation } from 'react-i18next'

/**
 * @component Roof renders options for the user to select the roof condition, pitch and subsequent insulation. 
 *
 * @example
 *   <Roof />
 */
export const Roof: React.FC = () => {

  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.construction_method_page' })

  const {
    roofConditionType,
    setRoofConditionType,
    roofPitchType,
    setRoofPitchType,
    subsequentRoofInsulation,
    setSubsequentRoofInsulation
  } = useConstructionMethod()

  return (
    <SectionedBorderBox
      title={
        <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
          {t('roof_title')}
        </Typography>
      }
    >
      <Stack
        sx={{
          flexDirection: { xs: 'column', lg: 'row' }
        }}
        spacing={2}
        width="100%"
      >
        <Stack alignItems="flex-start" gap={1.2} marginBottom={{ xs: '2.8rem', sm: 0 }}>

          {/* ROOOF CONDITION OPTIONS */}
          <Stack gap="1.8rem" width={{ xs: '100%', lg: '32rem' }} position="relative">
            <MUIDropdown
              sx={{ width: '100%' }}
              button={(isOpen, action) => (
                <MUIDropdownInput
                  readOnly
                  required
                  label={t('roof_condition')}
                  type={DropdownInputTypes.SELECTOR}
                  value={t(!!roofConditionType ? `roof_condition_type.${roofConditionType}` : 'select_roof_condition_option')}
                  onClick={action}
                />
              )}
            >
              {Object.values(RoofConditionType).map(type => (
                <MUIDropdownItem key={type} onClick={() => setRoofConditionType(type)}>
                  <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                    {t(`roof_condition_type.${type}`)}
                  </Typography>
                </MUIDropdownItem>
              ))}
            </MUIDropdown>
          </Stack>

          {/* ROOF PITCH */}
          <Stack gap=".8rem">
            <Label color={GRAY_700} text={t('roof_pitch')} />

            {Object.values(RoofPitchType).map(type => {
              return (
                <Stack key={type} gap=".8rem" flexDirection="row">
                  <MUIRadio
                    checked={roofPitchType === type}
                    onChange={() => setRoofPitchType(type)}
                  />

                  <Label color={GRAY_900} text={t(`roof_pitch_type.${type}`)} />
                </Stack>
              )
            })}

          </Stack>

          {/* SUBSEQUENT INSULATION */}
          <Stack marginTop={.8}>
            <MUINumberField
              min={0}
              suffix={t('cm')}
              inputBoxWidth='100%'
              label={t('insulation_roof')}
              value={subsequentRoofInsulation}
              onChange={(value) => setSubsequentRoofInsulation(value)}
            />
          </Stack>

        </Stack>
      </Stack>

    </SectionedBorderBox>
  )
}
