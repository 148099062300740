import { getBrowserTimezone, getUserTimezone } from 'utils/time'

import constate from 'constate'
import moment from 'moment-timezone'
import { useMemo } from 'react'
import { useUserData } from './UserDataContext'

export const [TimezoneContextProvider, useTimezone] = constate(() => {
  const { baseUserData } = useUserData()

  const userTimezone = useMemo(() => getUserTimezone(baseUserData), [baseUserData])
  const browserTimezone = useMemo(() => getBrowserTimezone(), [])

  const isBrowserAndUserSameTimezone = useMemo(() => userTimezone === browserTimezone, [browserTimezone, userTimezone])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isBrowserAndUserSameOffset = useMemo(() => moment(new Date()).tz(moment.tz.guess()).format('Z') === moment().format('Z'), [userTimezone])

  return {
    userTimezone,
    browserTimezone,
    isBrowserAndUserSameOffset,
    isBrowserAndUserSameTimezone,
  }
})
