import { FC, Fragment, useCallback, useEffect } from 'react'

import { Color } from 'constants/assets'
import { Nullable } from 'models/helpers'
import ReactLoading from 'react-loading'
import TransitionAppear from 'components/common/TransitionAppear/TransitionAppear'
import { createPortal } from 'react-dom'
import { fadeInEnterEffect } from 'utils/animations'
import styles from './ChargebeeModal.module.sass'

interface Props {
  /** Title for iframe */
  title: string
  /** Url to provide for iframe src */
  hostedPageUrl: Nullable<string>
  /** Wheter modal should be open */
  isOpen: boolean
  /** Callback handler for closing modal (called on backdrop click) */
  handleClose?: () => void
  /** If provided and true, shows loading spinner */
  isLoading?: boolean
  /** Callback triggered on iframe onLoad event */
  onIframeLoaded?: () => void
}

/**
 * Renders iframe for given url, styled as a modal window for chargebee subscription management and checkout hosted pages.
 * Rendered as a Portal
 * @example
 * <ChargebeeModal
 *   title="Sunscribtion checkout"
 *   hostedPageUrl="https://some-valid-url"
 *   isOpen={isOpen}
 *   handleClose={() => setIsOpen(false)}
 * /> 
 */
export const ChargebeeModal: FC<Props> = ({
  title,
  hostedPageUrl,
  isOpen,
  isLoading = false,
  handleClose = () => undefined,
  onIframeLoaded = () => undefined,
}) => {

  // Looks for message marking 
  const handleMessageClose = useCallback((message: MessageEvent) => {
    if (message.data === 'cb.close') handleClose()
  }, [handleClose])

  // Handle adding/removing of event listener for handling closing of chargebee modal
  useEffect(() => {
    window.addEventListener('message', handleMessageClose)

    return () => window.removeEventListener('message', handleMessageClose)

  }, [handleMessageClose])

  return createPortal((
    <Fragment>
      {isOpen && !!hostedPageUrl &&
        <TransitionAppear
          onEnter={fadeInEnterEffect}
          visible
        >
          <div className={styles.backdrop} onClick={handleClose}>

            {isLoading &&
              <ReactLoading className={styles.loading} color={Color.WHITE} type="spin" />
            }

            <iframe
              title={title}
              src={hostedPageUrl}
              onLoad={onIframeLoaded}
            />

          </div>
        </TransitionAppear>
      }
    </Fragment>
  ), document.querySelector('.App') || document.getElementById('root') || document.body)

}
