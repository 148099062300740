import { BEIGE_600, BLUE_600, BOX_SHADOW_LG, BOX_SHADOW_XS } from 'constants/styling/theme'
import Card, { CardProps } from '@mui/material/Card'
import CardMedia, { CardMediaProps } from '@mui/material/CardMedia'

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import React from 'react'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'

/** Direction of the card layout, either 'column' or 'row'. */
type CardDirection = 'column' | 'row'

/** Props for the CategoryCard component. */
type Props = {
  /** Direction of the card layout, either 'column' or 'row'. */
  direction?: CardDirection
  /** URL of the image to be displayed on the card. */
  image?: string
  /** Title text to be displayed on the card. */
  title: string
  /** Description text to be displayed on the card. */
  description?: string
  /** Function to be executed when the card is clicked. */
  onClick?: () => void
} & CardProps

/** Props for the StyledCard component. */
type CategoryCardProps = {
  /** Direction of the card layout, either 'column' or 'row'. */
  direction: CardDirection
} & CardProps

/** MUI Card component with custom styles */
const StyledCard = styled(({ direction, ...defaultProps }: CategoryCardProps) => (
  <Card {...defaultProps} />
))(({ direction, ...props }) => ({
  gap: '1rem',
  display: 'flex',
  cursor: 'pointer',
  borderRadius: '8px',
  flex: direction === 'column' ? '0 23%' : '0 48%',
  boxSizing: 'border-box',
  boxShadow: BOX_SHADOW_XS,
  flexDirection: direction,
  padding: direction === 'column' ? '2.4rem' : '2rem',
  alignItems: direction === 'row' ? 'center' : 'flex-start',
  ':hover': {
    outline: `1px solid ${BLUE_600}`,
    boxShadow: BOX_SHADOW_LG,
  },
  [props.theme.breakpoints.down('lg')]: {
    flex: direction === 'column' ? '0 48%' : '0 100%',
  },
  [props.theme.breakpoints.down('sm')]: {
    flex: 1,
  }
}))

/** MUI CardMedia component with custom styles */
const StyledCardMedia = styled(({ direction, ...defaultProps }: { direction: CardDirection } & CardMediaProps) => (
  <CardMedia {...defaultProps} />
))(({ direction, ...props }) => ({
  borderRadius: '4px',
  boxSizing: 'border-box',
  width: direction === 'row' ? 100 : '100%',
  minHeight: direction === 'column' ? 180 : 80,
  marginRight: direction === 'row' ? '1rem' : '0',
  [props.theme.breakpoints.down('md')]: {
    display: direction === 'row' ? 'none' : 'block'
  },
}))

/**
 * @component
 * CategoryCard component displaying a custom Card component using the MUI Card component as a base.
 *
 * @example
 * <CategoryCard
 *   title="Bacon ipsum dolor"
 *   description="Short ribs brisket chislic shoulder turkey boudin pork belly sirloin."
 *   image="path/to/bacon_image.jpg"
 *   onClick={handleCardClick}
 * />
 */
export const CategoryCard: React.FC<Props> = ({
  title,
  image,
  description,
  onClick,
  direction = 'column',
  ...defaultProps
}) => {
  return (
    <StyledCard direction={direction} {...defaultProps} onClick={onClick}>

      <StyledCardMedia
        image={image}
        direction={direction}
      />

      <CardContent sx={{
        flex: 1,
        padding: 0,
        marginRight: { xs: 'auto' },
        paddingTop: direction === 'column' ? '1.5rem' : '0',
      }}>
        <Typography
          component="div"
          gutterBottom
          fontWeight={500}
          variant={direction === 'column' ? 'text-lg' : 'text-md'}
        >
          {title}
        </Typography>

        <Typography color="text.secondary" variant={direction === 'column' ? 'text-md' : 'text-sm'}>
          {description}
        </Typography>
      </CardContent>

      <CardActions sx={{ marginLeft: 'auto', paddingX: 0 }}>
        <IconButton aria-label="arrow forward" size="medium" sx={{
          border: `1px solid  ${BEIGE_600}`
        }}>
          <ArrowForwardRoundedIcon fontSize="large" />
        </IconButton>
      </CardActions>

    </StyledCard>
  )
}
