import { FC, ReactNode } from 'react'

import { Stack } from '@mui/material'

interface Props {
  children: ReactNode
}

/**
 * Page actions component.
 * @example <PageActions />
 */
export const PageActions: FC<Props> = ({ children }) =>
  <Stack flexDirection="row" gap="1.6rem" flexShrink={0}>
    {children}
  </Stack>
