import { FC, ReactNode } from 'react'
import { SlideFadeAppearHide, StaggerGroup } from 'utils/animations'
import { Trans, useTranslation } from 'react-i18next'

import Button from 'components/common/Button/Button'
import React from 'react'
import classnames from 'classnames'
import styles from './VisualDownloadStatuses.module.sass'

interface BaseVisualDownloadProps {
  visible: boolean
  isMobile: boolean
  className?: string
}

interface VisualDownloadDownloadStepProps extends BaseVisualDownloadProps {
  onDownloadClick: (e?: React.MouseEvent) => void
}

interface VisualDownloadErrorProps extends BaseVisualDownloadProps {
  onRetryClick: (e?: React.MouseEvent) => void
  // Error data are unfortunately defined as any :(
  errorData?: any
}

interface VisualDownloadFinishedProps extends BaseVisualDownloadProps {
  onRestartDownload: () => void
  onClickClose?: (e?: React.MouseEvent) => unknown
}

interface TemplateProps extends BaseVisualDownloadProps {
  mobileLeft?: ReactNode
  mobileRight?: ReactNode
  children: ReactNode
}

/**
 * Base template for visual download statuses for popup
 * 
 * @example
 * <VisualStatusTemplate
 *  visible={true}
 *  isMobile={false}
 *  mobileLeft={<h1>Hello there</h1>}
 *  mobileRight={<h2>General Kenobi</h2>}
 * >
 *  1 2 3, 1 2 3,...does it work?
 * </ VisualDownloadStatusTemplate>
 */
const VisualDownloadStatusTemplate: FC<TemplateProps> = ({
  className,
  visible,
  isMobile,
  mobileLeft,
  mobileRight,
  children,
}) => {
  return (
    <div className={classnames(styles.wrap, className, { [styles.active]: visible, [styles.isMobile]: isMobile })}>

      {!isMobile && (
        <StaggerGroup staggeredBy={30} visible={visible} delay={300} animation={SlideFadeAppearHide}>
          {children}
        </StaggerGroup>
      )}

      {isMobile && (!!mobileLeft || !!mobileRight) && (
        <>
          {!!mobileRight &&
            <SlideFadeAppearHide visible={visible} delay={300}>
              <div className={styles.right}>
                {mobileRight}
              </div>
            </SlideFadeAppearHide>
          }
          {!!mobileLeft &&
            <SlideFadeAppearHide visible={visible} delay={300}>
              <div className={styles.left}>
                {mobileLeft}
              </div>
            </SlideFadeAppearHide>
          }
        </>
      )}

    </div>
  )
}

/**
 * Visual download status for loading state
 * 
 * @example
 * <VisualDownloadLoading visible={true} isMobile={false} />
 */
export const VisualDownloadLoading: FC<BaseVisualDownloadProps> = (props) => {
  const { t } = useTranslation(['visual_download_statuses'])

  return (
    <VisualDownloadStatusTemplate
      mobileLeft={<h1>{t('loading_title_mobile')}</h1>}
      mobileRight={<span className={classnames(styles.bigEmoji, styles.jump)}>📦</span>}
      {...props}
    >
      <span className={classnames(styles.bigEmoji, styles.jump)}>📦</span>
      <h1>{t('loading_title')}</h1>
      <h3>{t('loading_subtitle')}</h3>
    </VisualDownloadStatusTemplate>
  )
}

/**
 * Visual download status for download-ready state
 * 
 * @example
 * <VisualDownloadReady visible={true} isMobile={false} onDownloadClick={() => undefined} />
 */
export const VisualDownloadReady: FC<VisualDownloadDownloadStepProps> = ({ onDownloadClick, ...props }) => {
  const { t } = useTranslation(['visual_download_statuses'])

  return (
    <VisualDownloadStatusTemplate
      {...props}
      mobileLeft={<h1>{t('ready_title_mobile')}</h1>}
      mobileRight={
        <Button onClick={onDownloadClick} type='primary'>
          {t('download')}
        </Button>
      }
    >
      <span className={classnames(styles.bigEmoji, styles.thumbsUp)}>👍</span>
      <h1>{t('ready_title')}</h1>
      <h3>{t('ready_subtitle')}</h3>
      <p>{t('ready_prompt')}</p>
      <Button onClick={onDownloadClick} type='primary'>
        {t('download')}
      </Button>
    </VisualDownloadStatusTemplate>
  )
}

/**
 * Visual download status for download-error state
 * 
 * @example
 * <VisualDownloadReady visible={true} isMobile={false} onRetryClick={() => undefined} errorData={request.error?.error?.data} />
 */
export const VisualDownloadError: FC<VisualDownloadErrorProps> = ({ onRetryClick, errorData, ...props }) => {
  const { t } = useTranslation(['visual_download_statuses'])

  return (
    <VisualDownloadStatusTemplate
      {...props}
      mobileLeft={(
        <>
          <h1>{t('error_title_mobile')}</h1>
          {errorData &&
            <p className={styles.bottomText}>
              {errorData.status} - <span className={styles.errorMessage}>{errorData.message}</span> ({errorData.timestamp})
            </p>
          }
        </>
      )}
      mobileRight={
        <Button type='primary' onClick={onRetryClick}>
          {t('retry_download')}
        </Button>
      }
    >
      <span className={styles.bigEmoji}>🐞</span>
      <h1>{t('error_title')}</h1>
      <h3>{t('error_subtitle')}</h3>
      {errorData &&
        <p className={styles.bottomText}>
          {errorData.status} - <span className={styles.errorMessage}>{errorData.message}</span> ({errorData.timestamp})
        </p>
      }
      <br /><br />
      <Button type='primary' onClick={onRetryClick}>
        {t('retry_download')}
      </Button>
    </VisualDownloadStatusTemplate>
  )
}


/**
 * Visual download status for download-finished state
 * 
 * @example
 * <VisualDownloadFinished visible={true} isMobile={false} onRestartDownload={() => undefined} onClickClose={() => undefined} />
 */
export const VisualDownloadFinished: FC<VisualDownloadFinishedProps> = ({ onRestartDownload, onClickClose, ...props }) => {
  const { t } = useTranslation(['visual_download_statuses'])

  return (
    <VisualDownloadStatusTemplate
      {...props}
      mobileLeft={<h1>{t('finished_no_staging_title_mobile')}</h1>}
      mobileRight={
        <Button onClick={onRestartDownload} type='link' className={styles.restartButton}>
          {t('restart_download')}
        </Button>
      }
    >
      <span className={styles.bigEmoji}>🤩🤩🤩</span>
      <h1>{t('finished_no_staging_title')}</h1>
      {/* Handle br tags */}
      <Trans t={t} i18nKey="finished_no_staging_subtitle" parent="h3">
        &nbsp;
      </Trans>
      <br /><br /><br />
      <Button onClick={onClickClose} type='secondary nobackground'>
        {t('close')}
      </Button>
      <Button onClick={onRestartDownload} type='link' className={styles.restartButton}>
        {t('restart_download')}
      </Button>
    </VisualDownloadStatusTemplate>
  )
}
