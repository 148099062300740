import { FC, ReactNode } from 'react'
import { IconButton, Stack } from '@mui/material'
import { Tag, TagColor } from '../Tags'

import CloseIcon from '@mui/icons-material/Close'
import styles from './HeaderBar.module.sass'

/**
 * Represents the header badge properties.
 * @interface HeaderBarBadge
 */
interface HeaderBarBadge {
  color: TagColor,
  text: string,
  materialIcon?: JSX.Element
}

/**
 * @interface Props Input properties
 */
interface Props {
  /** Header title */
  title: string
  /** Header ubtitle */
  subtitle?: string
  /** Optional badges next to the title or subtitle if displayed */
  badges?: HeaderBarBadge[]
  /** Extra content for the header displayed in the middle of the bar */
  extraChildren?: ReactNode
  /** Onclick action to close the current page  */
  onClose: () => void
}

/**
 * @component
 * HeaderBar component shows a top bar with title and/or subtitle including a close button and custom content.
 * 
 * @example <HeaderBar title="Backbone" onClose={onClose} />
 */
export const HeaderBar: FC<Props> = ({ extraChildren, badges, title, subtitle, onClose }) => {

  return (
    <div className={styles.header}>

      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        className={styles.headerContent}
      >

        {/** TITLE, SUBTITLE & BADGES */}
        {(title || subtitle || badges) &&
          <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }} className={styles.titleContainer}>

            {!!title &&
              <span className={styles.title}>{title}</span>
            }
            {!!subtitle &&
              <span className={styles.subtitle}>{subtitle}</span>
            }
            {!!badges &&
              <Stack spacing={1} alignItems="center">
                {
                  badges.map(({ color, text, materialIcon }) =>
                    <Tag
                      color={color}
                      key={`${color}_${text}`}
                      materialIcon={materialIcon}
                    >
                      <span>{text}</span>
                    </Tag>
                  )}
              </Stack>
            }

          </Stack>
        }

        {/** MIDDLE CONTENT */}
        <Stack className={styles.extraContent}>
          {extraChildren}
        </Stack>

        {/** CLOSE ICON BUTTON */}
        <IconButton className={styles.closeButton} onClick={onClose}>
          <CloseIcon fontSize='large' />
        </IconButton>

      </Stack>

    </div>
  )
}
