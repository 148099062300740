import { FC, useCallback, useMemo } from 'react'
import { SavedCardDetailsFormFields, useSavedCardDetailsFields } from 'components/forms/SavedCardDetails'
import { SavedSepaDetailsFormFields, useSavedSepaDetailsFields } from 'components/forms/SavedSepaDetails'

import Button from 'components/common/Button/Button'
import { Nullable } from 'models/helpers'
import { PreferredPaymentMethodEnum } from 'constants/user'
import { QueryStatus } from 'components/common/QueryStatus'
import { UpdateStripePaymentValue } from 'dataQueries/payment.query'
import { getCardExpirationFromString } from 'utils/helpers'
import styles from './EditSavedPaymentMethodPopupContent.module.sass'
import { useEditPaymentMethodContext } from '../_main/contexts'
import { useSavedStripePaymentMethods } from 'components/contexts/SavedStripePaymentMethodsContext'
import { useTranslation } from 'react-i18next'

/**
 * Controller for Edit saved payment method popup content
 * Shows form fields based on edited payment method type and handles firing update/close actions
 * @example <EditSavedPaymentMethodPopupContentController />
 */
export const EditSavedPaymentMethodPopupContentController: FC = () => {

  const { t } = useTranslation(['saved_payment_methods_edit', 'product_kind'])

  const { formUtils: sepaFormUtils } = useSavedSepaDetailsFields()
  const { formUtils: cardFormUtils, hasChanges: cardHasChanges } = useSavedCardDetailsFields()
  const { stripePaymentMethods } = useSavedStripePaymentMethods()
  const {
    setIsEditModalOpen,
    editedPaymentMethod,
    updateStripePaymentMethod,
  } = useEditPaymentMethodContext()

  // Checks field validity according to current payment method type
  const areFieldsValid = useMemo(() => {
    if (!sepaFormUtils || !cardFormUtils || !editedPaymentMethod) return false

    if (editedPaymentMethod.type === PreferredPaymentMethodEnum.CARD_PAYMENT) {
      return cardFormUtils.formState.isValid
    }

    if (editedPaymentMethod.type === PreferredPaymentMethodEnum.SEPA_DIRECT_DEBIT) {
      return sepaFormUtils.formState.isValid
    }

    return false
  }, [cardFormUtils, editedPaymentMethod, sepaFormUtils])

  // Serialize values from the forms according to current payment method type
  const editValues: Nullable<UpdateStripePaymentValue> = useMemo(() => {
    if (!editedPaymentMethod) return undefined

    if (editedPaymentMethod.type === PreferredPaymentMethodEnum.CARD_PAYMENT) {
      if (!cardFormUtils?.values) return undefined

      const expiration = getCardExpirationFromString(cardFormUtils.values.cardExpiration)

      if (!expiration) return undefined

      return {
        card: {
          cardExpirationMonth: expiration.month,
          cardExpirationYear: expiration.year,
        }
      }
    }

    if (editedPaymentMethod.type === PreferredPaymentMethodEnum.SEPA_DIRECT_DEBIT) {
      if (!sepaFormUtils?.values) return undefined
      return {
        sepa: {
          billingAddress: sepaFormUtils?.values,
        }
      }
    }

    return undefined
  }, [cardFormUtils?.values, editedPaymentMethod, sepaFormUtils?.values])

  // Check if form has changes based on edited payment method type
  const formHasChanges = useMemo(() => {
    if (!sepaFormUtils || !cardFormUtils || !editedPaymentMethod) return false

    if (editedPaymentMethod.type === PreferredPaymentMethodEnum.CARD_PAYMENT) return cardHasChanges

    if (editedPaymentMethod.type === PreferredPaymentMethodEnum.SEPA_DIRECT_DEBIT) return sepaFormUtils.hasChanges

    return false
  }, [sepaFormUtils, editedPaymentMethod, cardHasChanges, cardFormUtils])

  const handleSubmit = useCallback(() => {
    if (!editValues || !editedPaymentMethod) return

    updateStripePaymentMethod.mutate({
      paymentMethodType: editedPaymentMethod.type,
      paymentMethodId: editedPaymentMethod.stripeId,
      updateValues: editValues
    }, {
      onSuccess: () => {
        stripePaymentMethods?.refetch()
      }
    })
  }, [editValues, editedPaymentMethod, updateStripePaymentMethod, stripePaymentMethods])

  return (
    <div className={styles.modalContent}>

      <h2 className={styles.title}>
        {t('popup_title', { type: t(`product_kind:${editedPaymentMethod?.type}`) })}
      </h2>

      {editedPaymentMethod?.type === PreferredPaymentMethodEnum.CARD_PAYMENT &&
        <SavedCardDetailsFormFields />
      }

      {editedPaymentMethod?.type === PreferredPaymentMethodEnum.SEPA_DIRECT_DEBIT &&
        <SavedSepaDetailsFormFields />
      }

      <div className={styles.popupFooter}>

        <QueryStatus
          query={updateStripePaymentMethod}
          spaceBottomRem={1}
          successMessage={t('success')}
          errorMessage={t('error')}
        />

        <div className={styles.actions}>

          <Button
            type="secondary"
            onClick={() => setIsEditModalOpen(false)}
            isLoading={updateStripePaymentMethod.isPending}
            disabled={updateStripePaymentMethod.isPending}
          >
            {t('close')}
          </Button>

          <Button
            type="primary"
            disabled={!areFieldsValid || !formHasChanges || updateStripePaymentMethod.isPending}
            isLoading={updateStripePaymentMethod.isPending}
            onClick={handleSubmit}
          >
            {t('save')}
          </Button>

        </div>

      </div>
    </div>
  )
}
