import React, { Fragment, useMemo } from 'react'
import { UnavailabilityDrawer, useUnavailabilityDrawer } from './UnavailabilityDrawer'

import Button from 'components/common/Button/Button'
import { UnavailabilityItem } from './UnavailabilityItem'
import classnames from 'classnames'
import styles from './Unavailabilities.module.sass'
import { useLoggedInCTUnavailabilityList } from 'dataQueries/creativeUnavailabilities.query'
import { useTranslation } from 'react-i18next'

/**
 * @component User Unavailabilities displayed in Profile page only for creatives
 * @example
 * <Unavailabilities />
 */
export const Unavailabilities: React.FC = () => {

  const { t } = useTranslation(['profile'])
  const { openAddUnavailabilityDrawer } = useUnavailabilityDrawer()

  const loggedInCTUnavailabilities = useLoggedInCTUnavailabilityList()

  const unavailabilitiesList = useMemo(() => {
    if (!loggedInCTUnavailabilities.data || loggedInCTUnavailabilities.data.length < 1) return []
    return loggedInCTUnavailabilities.data.sort((unavailability) => new Date(unavailability.to).getTime() - new Date(unavailability.from).getTime())
  }, [loggedInCTUnavailabilities.data])

  // Whether the client has active unavailabilities or not
  const hasUnavailabilities: boolean = useMemo(() => {
    return !!unavailabilitiesList && !!unavailabilitiesList.length
  }, [unavailabilitiesList])

  return (
    <Fragment>

      <div className={styles.unavailabilities}>

        <div className={classnames({ [styles.headerWithButton]: hasUnavailabilities })}>
          <h3 className={styles.header}>{t('work_preferences.unavailabilities.title')}</h3>
          {hasUnavailabilities && (
            <Button
              type='secondary nobackground noborder'
              onClick={openAddUnavailabilityDrawer}
            >
              {t('work_preferences.unavailabilities.button_add_more')}
            </Button>
          )}
        </div>

        <div className={classnames({ [styles.blankContainer]: !hasUnavailabilities, [styles.itemsContainer]: hasUnavailabilities })}>
          {hasUnavailabilities
            ? unavailabilitiesList.length &&
            unavailabilitiesList.map((item, index) => (
              <UnavailabilityItem
                key={item.id}
                from={item.from}
                to={item.to}
                id={item.id}
                reason={item.reason}
              />
            ))
            : <Fragment>
              <p>{t('work_preferences.unavailabilities.no_unavailabilities')}</p>
              <Button
                type='secondary nobackground noborder'
                onClick={() => openAddUnavailabilityDrawer()}
              >
                {t('work_preferences.unavailabilities.button_add_new')}
              </Button>
            </Fragment>
          }
        </div>

      </div>

      <UnavailabilityDrawer />

    </Fragment>
  )
}
