import { getQuoteLinks } from 'constants/staticLinks'

export const getQuoteLink = (language: string) => {
  switch (language.toLowerCase()) {
    case 'de':
      return getQuoteLinks.DE
    case 'fr':
      return getQuoteLinks.FR
    case 'nl':
      return getQuoteLinks.NL
    case 'es':
      return getQuoteLinks.ES
    case 'en':
    default:
      return getQuoteLinks.EN
  }
}
