import './Index.sass'

import i18n, { transformLanguageToAuth0Value } from 'translations/i18n'
import { useCallback, useLayoutEffect, useState } from 'react'

import { AdminDashboard } from '../Administrator/Dashboard'
import Button from 'components/common/Button/Button'
import { ClientDashboard } from '../Client/Dashboard'
import { CreativeDashboard } from '../Creative/Dashboard'
import Modal from 'components/common/Modals/Modal/Modal'
import { PageTransition } from 'utils/animations'
import { createPortal } from 'react-dom'
import { getOnboardingCTLink } from 'utils/localization/getOnboardingCTLink'
import { getQuoteLink } from 'utils/localization'
import { logAnalyticsEvent } from 'utils/analytics'
import new_index_decoration from '../../../assets/img/decoration/index/new_index_decoration.jpg'
import { useAuth0 } from 'utils/auth'
import { useTranslation } from 'react-i18next'

const HeadPortal: React.FC = () => {
  return createPortal((
    <style>
      {`
        :root {
          --new-beige: #F2F2E8;
          --new-orange: #DE6C48;
          --new-black: #13171D;
          --new-white: #F3F3EA;
        }
        html[bkbn-style="old"] body {
          background-color: var(--new-beige);
          background-image: url('${new_index_decoration}');
          background-repeat: no-repeat;
          background-size: 50rem auto;
          background-position: right 32rem center;
          min-height: 100vh;
        }
        @media screen and (max-width: 1400px) {
          html[bkbn-style="old"] body {
            background-position: right 15rem center;
          }
        }
        @media screen and (max-width: 1200px) {
          html[bkbn-style="old"] body {
            background-position: right -15rem center;
          }
        }
        @media screen and (max-width: 800px) {
          html[bkbn-style="old"] body {
            background-image: none
          }
        }
        html[bkbn-style="old"] .mobile-menu-wrap .mobile-menu {
          background-color: var(--new-beige);
        }
        html[bkbn-style="old"] .menu-wrap .mobile-main-menu {
          background-color: var(--new-beige);
        }
        html[bkbn-style="old"] .button.secondary {
          background-color: transparent;
        }
        html[bkbn-style="old"] .dropdown .inner-menu {
          background-color: var(--new-beige);
        }
        html[bkbn-style="old"] .dropdown .inner-menu {
          box-shadow: 0 0 1px 0 var(--new-black);
        }
        html[bkbn-style="old"] .dropdown .inner-menu > button {
          border-bottom: 1px solid var(--new-black);
        }
        html[bkbn-style="old"] .dropdown .inner-menu > button:hover {
          border-bottom: 1px solid var(--new-orange);
        }
        html[bkbn-style="old"] .dropdown .inner-menu > button:last-child,
        html[bkbn-style="old"] .dropdown .inner-menu > button:last-child:hover {
          border-bottom: 1px solid transparent;
        }
        html[bkbn-style="old"] .topbar .login-buttons > button {
          font-family: 'Sailec', 'HK Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
          border-radius: 10em;
        }
        html[bkbn-style="old"] .topbar .login-buttons > button:hover {
          background-color: var(--new-orange);
          border-color: var(--new-orange);
          color: var(--new-white);
        }
      `}
    </style>
  ), document.head)
}

const Index: React.FC = (props: any) => {

  const { isAuthenticated, roles, loginWithRedirect } = useAuth0()
  const { t } = useTranslation(['index', 'authentication'])

  const getFontSize = () => `${Math.max(1, window.innerWidth / 1600) * 100}%`
  const [fontSize, setFontSize] = useState(getFontSize())
  const handleResize = useCallback(() => setFontSize(getFontSize()), [])
  const params = new URLSearchParams(window.location.search)

  const isUserBlocked = params.get('error_description') === 'user is blocked' && params.get('error') === 'unauthorized'
  const isClientLoginPage = params.has('login_client')
  const [isUserBlockedModalOpen, setIsUserBlockedModalOpen] = useState(isUserBlocked)

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  if (isAuthenticated && (roles.isAdmin)) return <AdminDashboard {...props} />
  if (isAuthenticated && (roles.isCreative)) return <CreativeDashboard {...props} />
  if (isAuthenticated && (roles.isClient)) return <ClientDashboard {...props} />

  return (
    <PageTransition>
      <div className="page index">
        <HeadPortal />
        <div className="page-content" style={{ fontSize }}>
          <div className="wrap">
            <div className="content">
              <h1>{t('heading1')}</h1>
              <h2>{t('heading2')}</h2>
              <p className="black">{t('text1')} <strong>{t('text2')}</strong></p>
              <Modal
                className="blocked-user-popup"
                isOpen={isUserBlockedModalOpen}
                modalContentClassName="blocked-user-popup-modal-content"
              >
                <div className="inside">
                  <div className="line">
                    <h3>{t('authentication:blocked_user')}</h3>
                  </div>

                  <div className="line">
                    <Button
                      onClick={() => setIsUserBlockedModalOpen(false)}
                    >
                      {t('authentication:blocked_user_confirmed').toUpperCase()}
                    </Button>
                  </div>

                </div>
              </Modal>
              <div className="login-buttons">
                <Button type="primary" onClick={() => {
                  logAnalyticsEvent('clicks_login', { location: 'Index' })
                  loginWithRedirect({
                    mode: 'login',
                    language: transformLanguageToAuth0Value(i18n.language),
                    login_hint: params.has('userEmail') ? params.get('userEmail') ?? undefined : undefined,
                    appState: { targetUrl: window.location.pathname },
                  })
                }}>
                  {t('authentication:log_in')}
                </Button>
                <span>{t('or')}</span>
                <Button type="secondary nobackground" onClick={() => {
                  logAnalyticsEvent('clicks_sign_up', { location: 'Index' })
                  loginWithRedirect({
                    mode: 'signUp',
                    language: transformLanguageToAuth0Value(i18n.language),
                    login_hint: params.has('userEmail') ? params.get('userEmail') ?? undefined : undefined,
                    appState: { targetUrl: window.location.pathname },
                  })
                }}>
                  {/* On the Client page (with 'login_client' in the URL parameters), we don't distinguish between Clients and Creatives. */}
                  {t(isClientLoginPage ? 'authentication:sign_up' : 'authentication:client_sign_up')}
                </Button>
                {!isClientLoginPage && <Button type="secondary nobackground" onClick={() => {
                  logAnalyticsEvent('clicks_creative_sign_up', { location: 'Index' })
                  window.open(getOnboardingCTLink(i18n.language), '_self', 'noopener noreferrer')
                }}>
                  {t('authentication:creative_sign_up')}
                </Button>}
              </div>
              <div className="login-buttons">
                <a className="lost-password" href="#lostpassword" onClick={e => {
                  e.preventDefault()
                  logAnalyticsEvent('clicks_lost_password', { location: 'Index' })
                  loginWithRedirect({
                    mode: 'forgotPassword',
                    language: transformLanguageToAuth0Value(i18n.language),
                    login_hint: params.has('userEmail') ? params.get('userEmail') ?? undefined : undefined,
                    appState: { targetUrl: window.location.pathname },
                  })
                }}>
                  {t('authentication:lost_password')}
                </a>
              </div>
              <div className="login-buttons quote">
                <Button type="primary" onClick={() => {
                  logAnalyticsEvent('clicks_get_a_quote', { location: 'Index' })
                  window.open(getQuoteLink(i18n.language), '_blank', 'noopener noreferrer')
                }}>
                  {t('get_a_quote')}
                </Button>
              </div>
            </div>
            <div className="decoration">
              <h3>{t('motto')}</h3>
            </div>
          </div>
        </div>
      </div>
    </PageTransition>
  )
}

export default Index
