import React, { useMemo, useState } from 'react'

import { Box } from '@mui/material'
import { GRAY_900 } from 'constants/styling/theme'
import { MUIDivider } from 'components/common/MUIDivider'
import { MUINumberField } from 'components/common/MUINumberField'
import { Nullable } from 'models/helpers'
import { PRIMARY_FIELD_WIDTH } from './_main/HeatingSystem.component'
import Typography from '@mui/material/Typography'
import { VentilationHeatingProps } from '../_main/VentilationAndHeating.component'
import { useTranslation } from 'react-i18next'
import { useVentilationAndHeating } from '../_main/VentilationAndHeating.context'

/**
 * @component TotalHeatingOutput
 * @description Renders options for the user to provide information about the total heating output.
 * 
 * @example
 *   <TotalHeatingOutput id="heating-system-1" />
 */
export const TotalHeatingOutput: React.FC<VentilationHeatingProps> = ({ id }) => {
  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.energy_heating_page.heating_system' })

  const {
    currentHeatingSystem,
    handleUpdateHeatingSystemItem,
    ventilationAndHeatingSystem,
    sortHeatingItemsById
  } = useVentilationAndHeating()

  const { totalHeatingOutput } = currentHeatingSystem(id) || {}

  const [showError, setShowError] = useState<boolean>(false)

  // If any of the required fields are empty, show error for all of them.
  const handleOnInputBlur = () => {
    if (!showError) setShowError(true)
  }

  const sortedHeatingSystemItems = useMemo(() => sortHeatingItemsById(ventilationAndHeatingSystem), [sortHeatingItemsById, ventilationAndHeatingSystem])
  const firstNonEditableSystemId = sortedHeatingSystemItems && sortedHeatingSystemItems[0][0]

  const updateFirstHeatingSystem = (value: Nullable<number>) => {

    if (firstNonEditableSystemId && sortedHeatingSystemItems && sortedHeatingSystemItems.length > 1) {
      const totalEditableOutputsCount = sortedHeatingSystemItems.slice(1).reduce((acc, system) => acc + (system[0] === id ? (value || 0) : (system[1].totalHeatingOutput || 0)), 0)

      handleUpdateHeatingSystemItem(id, { totalHeatingOutput: value })
      handleUpdateHeatingSystemItem(firstNonEditableSystemId, { totalHeatingOutput: 100 - totalEditableOutputsCount })
    }
  }

  return (
    <>
      <Box paddingTop={2} width="100%">
        <Typography color={GRAY_900} variant='text-md' fontWeight={600}>{t('total_heating_output')}</Typography>
        <MUIDivider margin={2} />
      </Box>

      <Box width={{ xs: '80%', lg: PRIMARY_FIELD_WIDTH }}>
        <MUINumberField
          readOnly={id === 'heating-system-1'}
          min={0}
          max={100}
          suffix='%'
          required
          inputBoxWidth='100%'
          label={t('share_of_total_heating_output')}
          value={totalHeatingOutput}
          isError={showError && !totalHeatingOutput}
          onBlur={handleOnInputBlur}
          onChange={(value) => updateFirstHeatingSystem(value)}
        />
      </Box>
    </>
  )
}
