import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { cloneDeep } from 'lodash'
import { ActionArchiveEditingVisuals } from './ArchivedEditingVisuals.actions'
import { ArchiveEditingVisualsStore } from './ArchivedEditingVisuals.interface'

/** Redux data reducer for archive editing visuals */
export const reduceArchiveEditingVisuals = (state: ArchiveEditingVisualsStore, action: ActionArchiveEditingVisuals) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.ARCHIVE_EDITING_VISUALS) return state
  const newState = cloneDeep(state)

  const { missionId, tag, request } = action.payload

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
    case ActionTypeAPIEvent.FETCH:
    case ActionTypeAPIEvent.RECEIVED:
      newState[missionId] = {
        ...newState[missionId],
        [tag]: request,
      }
      return newState
    default:
      return state
  }
}
