import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { FC, Fragment, useEffect } from 'react'
import { FloorPlanRenderType, FloorPlanSelectionType, useFloorPlanConfig } from './FloorPlanConfigStep.context'
import { usePurchaseFlowConfig, usePurchaseFlowProducts } from 'components/pages/PurchaseFlow/_main/contexts'

import Box from '@mui/material/Box'
import { FloorPlanDesign } from '../FloorPlanDesign'
import { FloorPlanLayout } from '../FloorPlanLayout'
import { FloorPlanPreview2D } from '../FloorPlanPreview'
import { FloorPlanPreview3D } from '../FloorPlanPreview/FloorPlanPreview3D.component'
import { FloorPlanRenderTypeController } from '../FloorPlanRenderType/FloorPlanRenderTypeController.controller'
import { FloorPlanTemplateTypeSelection } from '../FloorPlanTemplateTypeSelection'
import Stack from '@mui/material/Stack'
import { StepWrapper } from '../../../common/StepWrapper'
import TransitionAppear from 'components/common/TransitionAppear/TransitionAppear'
import { useTranslation } from 'react-i18next'

/**
 * Controller for handling configuration of floor plan details and such things
 *
 * @example <FloorPlanConfigStepController />
 */
export const FloorPlanConfigStepController: FC = () => {
  const { t } = useTranslation(['purchase_flow'])
  const { selectedCategory } = usePurchaseFlowConfig()
  const { currentFloorPlanRenderType, floorPlanTemplateType } = useFloorPlanConfig()
  const {
    selectedKinds,
    selectedProductTypes,
    selectedProducts,
  } = usePurchaseFlowProducts()

  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvent.ENTER_ORDER_FLOOR_PLAN_PAGE_SCREEN, {
      productTypes: Array.from(selectedProductTypes),
      productKinds: Array.from(selectedKinds),
      products: Object.values(selectedProducts),
      category: selectedCategory,
    })

    // Only trigger when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TransitionAppear visible>
      <StepWrapper title={t('floor_plan_step.step_title')} subtitle={t('floor_plan_step.step_subtitle')}>
        <Stack
          gap={4}
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >

          {/** FLOOR PLAN PREVIEWS */}
          <Box
            top="-0.4rem" // So it aligns with the Cart component
            height="100%"
            flex="1 0 300px"
            position="sticky"
          >
            {currentFloorPlanRenderType === FloorPlanRenderType.RENDER_2D &&
              <FloorPlanPreview2D />
            }

            {currentFloorPlanRenderType === FloorPlanRenderType.RENDER_3D &&
              <FloorPlanPreview3D />
            }
          </Box>

          {/** SELECT OR CREATE TEMPLATES */}
          <Stack flex="1 1 75%" gap={2}>

            {/** Select whether to display custom template or create one from scratch */}
            {floorPlanTemplateType !== FloorPlanSelectionType.CUSTOM &&
              <FloorPlanTemplateTypeSelection />
            }

            {/** If template type is NEW_TEMPLATE display the render type selection */}
            {floorPlanTemplateType === FloorPlanSelectionType.CUSTOM &&
              <FloorPlanRenderTypeController />
            }

            {/** Floor Plan configuration inputs */}
            {floorPlanTemplateType &&
              <Fragment>
                <FloorPlanDesign />
                {currentFloorPlanRenderType === FloorPlanRenderType.RENDER_2D && <FloorPlanLayout />}
              </Fragment>
            }

          </Stack>

        </Stack>
      </StepWrapper>
    </TransitionAppear>
  )
}
