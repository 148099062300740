import { FC, ReactNode } from 'react'

import { Stack } from '@mui/material'
import classnames from 'classnames'
import styles from './InputWrap.module.sass'

interface Props {
  /** Input and other nodes, wrapped inputs are styled to look same */
  children: ReactNode
  /** Label for input */
  label: string
  /** Text that will be shown below the children. During error state replaced by error message. */
  fieldNote?: string
  /** Optional class name for main wrapper */
  className?: string
  /** Current error message - replaces fieldNote and is red */
  error?: string
  /** Whether error should be shown or not (eg. before first touch, you may want to not show the error) */
  showError?: boolean
  /** If true, appends asterisk (*) to the label */
  required?: boolean
}

/**
 * Wrapper for inputs providing styled label, error messages, fields descriptions etc.
 * @example
 * <InputWrap label="Full name" form={formUtils} field={rhf.fields.fullName}>
 *   <input type="text" {...rhf.fields.fullName} />
 * </InputWrap>
 */
export const InputWrap: FC<Props> = ({
  children,
  label,
  fieldNote,
  error,
  className,
  required = false,
  showError = true,
}) => {

  return (
    <Stack className={classnames(styles.formField, className, { [styles.error]: showError && !!error })} gap={.5} >

      <Stack direction="row" justifyContent="space-between" className={styles.header}>
        <span className={styles.label}>
          {label}{required ? '*' : ''}
        </span>
      </Stack>

      <div className={styles.inputWrap}>
        {children}
      </div>

      <div className={styles.footer}>
        {showError && !!error && <span className={styles.error}>{error}</span>}
        {!!fieldNote && (!error || !showError) && <span className={styles.note}>{fieldNote}</span>}
      </div>

    </Stack>
  )
}
