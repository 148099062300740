import { ProductKind } from 'constants/product'

/** Enum of possible payment method types */
export enum PaymentMethodTypes {
  CARD = 'CARD',
  SEPA_DEBIT = 'SEPA_DEBIT'
}

/** Enum of all posible statuses of payment intent */
export enum StripePaymentIntentStatuses {
  canceled = 'canceled',
  processing = 'processing',
  requires_action = 'requires_action',
  requires_capture = 'requires_capture',
  requires_confirmation = 'requires_confirmation',
  requires_payment_method = 'requires_payment_method',
  succeeded = 'succeeded',
}

/** Maps Stripe product kinds to their payment method counterparts */
export const StripeProductKindToPaymentMethodMap = new Map<ProductKind, PaymentMethodTypes>([
  [ProductKind.SEPA_DIRECT_DEBIT, PaymentMethodTypes.SEPA_DEBIT],
  [ProductKind.CARD_PAYMENT, PaymentMethodTypes.CARD],
])