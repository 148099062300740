import { CookiePolicyLinks, PrivacyPolicyLinks, TermsLinks } from 'constants/staticLinks'

// TODO add links for ES and NL
export const getTermsLink = (language: string) => {
  switch (language.toLowerCase()) {
    case 'en':
      return TermsLinks.EN
    case 'fr':
      return TermsLinks.FR
    case 'de':
      return TermsLinks.DE
    default:
      return TermsLinks.ALL_PURPOSE
  }
}

// TODO add missing links once they are live
export const getPrivacyPolicyLink = (language: string) => {
  switch (language.toLowerCase()) {
    case 'de':
      return PrivacyPolicyLinks.DE
    case 'en':
    default:
      return PrivacyPolicyLinks.EN
  }
}

// TODO add missing links once they are live
export const getCookiePolicyLink = (language: string) => {
  switch (language.toLowerCase()) {
    case 'de':
      return CookiePolicyLinks.DE
    case 'en':
    default:
      return CookiePolicyLinks.EN
  }
}
