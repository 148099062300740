import { APIRequestErrorType, Endpoint } from 'constants/API'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { getInitRoles, getRoles } from 'utils/auth'

import API from 'utils/API/API'
import { ActionChangeLocale } from '.'
import { ActionUserMe } from '../UserMe'
import Cookies from 'js-cookie'
import { RootStore } from 'models/redux'
import { select } from 'typed-redux-saga'

/** Saga which changes user locale */
export function* changeLocaleSaga(receivedAction: ActionChangeLocale) {
  let roles = getInitRoles()
  try {
    roles = yield getRoles()
  } catch (e) {
    throw new Error('Unable to get roles for user')
  }

  if (!roles || roles.all.size === 0) throw new Error('The user does not have any role!')

  const userMeSelector = (state: RootStore) => state.APIData.USER_ME
  const userMeSlice = yield* select(userMeSelector)
  const email = userMeSlice.data?.email

  if (!email) return

  const {
    timezone,
    language,
  } = receivedAction.payload

  const url = Endpoint.USER_CHANGE_LOCALE.replace('{email}', encodeURI(email))
  const action: ActionChangeLocale = yield generalFetch(ActionTypeAPIData.USER_CHANGE_LOCALE, () => API.put(url, {
    language: language || null,
    timezone: timezone || null,
  }, {
    headers: {
      Accept: API.decideRoleMimeType(roles),
    },
  }, {
    endpoint: Endpoint.USER_CHANGE_LOCALE,
  }))
  const changeLocaleAction: ActionChangeLocale = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }
  yield put(changeLocaleAction)

  // Set language cookie for the user
  if (language) Cookies.set('i18next', language.toUpperCase())

  if (action.payload.request.error_type !== APIRequestErrorType.NONE || !action.payload.request.data) return
  yield call(changeUserMe, changeLocaleAction)
}

/** Saga which changes user me */
export function* changeUserMe(receivedAction: ActionChangeLocale) {
  const userMeAction: ActionUserMe = {
    type: [ActionTypeAPIEvent.RECEIVED, ActionTypeAPIData.USER_ME],
    payload: {
      request: receivedAction.payload.request,
    },
  }
  yield put(userMeAction)
}

/** Watcher of change user locale actions */
export function* changeLocaleWatcher() {
  yield all([
    takeEvery((action: ActionChangeLocale) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.USER_CHANGE_LOCALE]), changeLocaleSaga),
  ])
}
