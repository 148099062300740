import { PurchaseFlowStepKey, usePurchaseFlowUtilities } from '../../../_main/contexts'

import { ClientStepController } from './ClientStep.controller'
import { DynamicFlowStep } from 'components/common/DynamicFlow'
import { FC } from 'react'

export const ClientStep: FC = () => {

  const { isUserStepValid } = usePurchaseFlowUtilities()

  return (
    <DynamicFlowStep
      stepKey={PurchaseFlowStepKey.CLIENT}
      isValid={isUserStepValid}
    >
      <ClientStepController />
    </DynamicFlowStep>
  )
}
