import './VATNumberPopup.sass'

import React, { useMemo, useState } from 'react'

import BlockInfo from 'components/common/BlockInfo/BlockInfo'
import { MUIButton } from 'components/common/MUIButton'
import Modal from 'components/common/Modals/Modal/Modal'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useTranslation } from 'react-i18next'
import { useUpdateVatNumber } from 'dataQueries'

/**
 * @interface Props Input properties
 */
interface Props {
  /** The additional classes to append */
  className?: string
  /** Decides when is modal open */
  isOpen: boolean
  /** Onclick action triggered when user clicks outside the .modal-content element */
  onClickOutside?: (e: React.MouseEvent) => unknown
  /** onKeyDown action triggered when user presses any key on the keyboard */
  onKeyDown?: (e: React.KeyboardEvent) => unknown
  /** Onclick action triggered when user clicks the close button */
  onClickClose?: (e: React.MouseEvent) => unknown
  /** Function called after VAT number added with successful response */
  callbackAfterSuccessfulResponse?: () => void
}

/**
 * @component Add VAT number in a popup
 * @example
 * <VATNumberPopup isOpen={true} />
 */
export const VATNumberPopup: React.FC<Props> = ({
  className = '',
  isOpen,
  onClickOutside,
  onKeyDown,
  onClickClose,
  callbackAfterSuccessfulResponse,
}) => {
  const { spawnSuccessToast } = useSnackbar()
  const { t } = useTranslation(['profile'])

  const updateVatNumber = useUpdateVatNumber()

  const [VATNumber, setVATNumber] = useState('')

  const maxVATNumberCharacters = 50
  const isMaxVATNumberCharacters = useMemo(() => VATNumber.length === maxVATNumberCharacters, [VATNumber])

  return (
    <Modal
      className={`vat-number-popup ${className}`.trim()}
      modalContentClassName="vat-number-popup-modal-content"
      isOpen={isOpen}
      onClickOutside={onClickOutside}
      onKeyDown={onKeyDown}
      onClose={onClickClose}
      title={t('vat_number.add')}
    >
      <div className="inside">

        <BlockInfo>
          <p>{t('vat_number.contact_us')}</p>
        </BlockInfo>

        <div className="vat-box">
          <div className="input-row">

            <div className="input vat-number-input">
              <input
                className="vat-number"
                type="text"
                name="vat_number"
                id="vat_number"
                placeholder={t('vat_number.placeholder')}
                value={VATNumber}
                onChange={e => setVATNumber(e.target.value.replace(/(\r\n|\n|\r)/gm, '').slice(0, maxVATNumberCharacters))}
              />
            </div>

            <div className="input">
              <MUIButton
                type="defaultPrimary"
                fullWidth
                onClick={e => updateVatNumber.mutate({ vatNumber: VATNumber }, {
                  onSuccess: () => {
                    spawnSuccessToast(t('vat_number.success'))
                    callbackAfterSuccessfulResponse?.()
                  }
                })}
                disabled={updateVatNumber.isPending || !VATNumber || isMaxVATNumberCharacters}
                isLoading={updateVatNumber.isPending}
              >
                {t('vat_number.submit')}
              </MUIButton>
            </div>

          </div>

        </div>

        {!!isMaxVATNumberCharacters &&
          <span className="error-message">{t('vat_number.characters_length_message', { count: maxVATNumberCharacters })}</span>
        }

      </div>
    </Modal >
  )
}
