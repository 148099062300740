import { ClientType, PlatformUser } from 'models/user'

import { UserIsPlatformUserClient } from 'utils/typeguards'

/** Decides whether the organizationMe endpoint should load (or is supposed to load) based on the PlatformUser */
export const shouldUserRequestOrganizationMe = (user: PlatformUser | null) => {
  if (!user) return false
  if (!UserIsPlatformUserClient(user)) return false
  if (!user.organizationId || user.clientType !== ClientType.B2B) return false
  return true
}
