import { PurchaseFlowStepKey, usePurchaseFlowUtilities } from '../../_main/contexts'

import { DynamicFlowStep } from 'components/common/DynamicFlow'
import { FC } from 'react'
import { PropertyInformationStepController } from './PropertyInformationStep.controller'

/**
 * @component
 * Page in PurchaseFlow step for specifying Property Information.
 * 
 * @example 
 * <PropertyInformationStep />
 */
export const PropertyInformationStep: FC = () => {
  const { isPropertyInformationStepValid } = usePurchaseFlowUtilities()

  return (
    <DynamicFlowStep
      stepKey={PurchaseFlowStepKey.PROPERTY_INFORMATION}
      isValid={isPropertyInformationStepValid}
    >
      <PropertyInformationStepController />
    </DynamicFlowStep>
  )
}
