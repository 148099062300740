import { Dispatch, FC, ReactNode, SetStateAction, createContext, useCallback, useContext, useMemo, useState } from 'react'
import { TypeOfAvailableOrderings, useGalleryConstants } from './GalleryConstants.context'

import { POST_WATERMARK } from 'constants/visual'
import { useAuth0 } from 'utils/auth'
import { useGalleryVisualType } from './GalleryVisualType.context'

interface GalleryOrderInterface {
  currentOrder: TypeOfAvailableOrderings
  setCurrentOrder: Dispatch<SetStateAction<TypeOfAvailableOrderings>>
  currentAdjustedOrder: TypeOfAvailableOrderings
  sortFunction: (aKey: string, bKey: string) => 1 | -1 | 0
  sortEntriesFunction: ([aKey, aObject]: [string, any], [bKey, bObject]: [string, any]) => 1 | -1 | 0
}

const defaultGalleryOrderValue: GalleryOrderInterface = {
  currentOrder: 'DESCENDING',
  setCurrentOrder: () => { throw new Error('setCurrentOrder is undefined') },
  currentAdjustedOrder: 'DESCENDING',
  sortFunction: () => { throw new Error('sortFunction is undefined') },
  sortEntriesFunction: () => { throw new Error('sortEntriesFunction is undefined') },
}

/** Gallery visual order context */
export const GalleryOrderContext = createContext<GalleryOrderInterface>(defaultGalleryOrderValue)
/** Gallery visual order context hook */
export const useGalleryOrder = (): GalleryOrderInterface => useContext(GalleryOrderContext)

/** Context provider for gallery visual order */
export const GalleryOrderContextProvider: FC<{
  assignmentId: string
  children?: ReactNode
}> = ({
  assignmentId,
  children,
}) => {
    const { AvailableOrderings } = useGalleryConstants()
    const { originalType } = useGalleryVisualType()
    const { roles } = useAuth0()

    const [currentOrder, setCurrentOrder] = useState(roles.isClient ? AvailableOrderings.ASCENDING : AvailableOrderings.DESCENDING)
    const currentAdjustedOrder = useMemo(() => roles.isClient || originalType === POST_WATERMARK ? AvailableOrderings.ASCENDING : currentOrder, [AvailableOrderings, currentOrder, roles, originalType])
    const sortFunction = useCallback((aKey: string, bKey: string) => {
      if (aKey < bKey) return currentAdjustedOrder === AvailableOrderings.DESCENDING ? 1 : -1
      else if (aKey > bKey) return currentAdjustedOrder === AvailableOrderings.DESCENDING ? -1 : 1
      else return 0
    }, [currentAdjustedOrder, AvailableOrderings])
    const sortEntriesFunction = useCallback(([aKey, aObject]: [string, any], [bKey, bObject]: [string, any]) => {
      return sortFunction(aKey, bKey)
    }, [sortFunction])

    return (
      <GalleryOrderContext.Provider
        value={{
          currentOrder,
          setCurrentOrder,
          currentAdjustedOrder,
          sortFunction,
          sortEntriesFunction,
        }}
      >
        {children}
      </GalleryOrderContext.Provider>
    )
  }
