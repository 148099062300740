/** Enum representing a state of any API request */
export enum APIRequestState {
  /** Initial state before the request starts */
  BEFORE_START = 'BEFORE_START',
  /** The request is currently running and has not finished yet */
  RUNNING = 'RUNNING',
  /** The request finished with 200-299 status code "OK" or "SUCCESS" */
  OK = 'OK',
  /** The request finished with 400+ status code indicating an error or did not finish at all [timeout, server not available, network error, cancel] */
  ERROR = 'ERROR',
}

/** Enum representing an error type of any API request */
export enum APIRequestErrorType {
  /** The request finished with 200-299 status code "OK" or "SUCCESS" indicating no error */
  NONE = 'NONE',
  /** The request finished with 400-499 status code indicating client error */
  CLIENT_ERROR = 'CLIENT_ERROR',
  /** The request finished with 401 status code indicating unauthorized error */
  UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR',
  /** The request finished with 402 status code indicating payment required error */
  PAYMENT_REQUIRED_ERROR = 'PAYMENT_REQUIRED_ERROR',
  /** The request finished with 403 status code indicating forbidden error */
  FORBIDDEN_ERROR = 'FORBIDDEN_ERROR',
  /** The request finished with 404 status code indicating not found error */
  NOTFOUND_ERROR = 'NOTFOUND_ERROR',
  /** The request finished with 500-599 status code indicating server error */
  SERVER_ERROR = 'SERVER_ERROR',
  /** The request did not finish in time resulting in timeout error */
  TIMEOUT_ERROR = 'TIMEOUT_ERROR',
  /** The request did not come through to server indicating server not available or bad DNS error */
  CONNECTION_ERROR = 'CONNECTION_ERROR',
  /** The request failed due to a network unavailable error */
  NETWORK_ERROR = 'NETWORK_ERROR',
  /** The request has been cancelled. Only possible if `cancelToken` is provided in config, see axios `Cancellation` */
  CANCEL_ERROR = 'CANCEL_ERROR',
  /** An unknown error occured */
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}
