import { ConfiguredFormFieldConfig, UseExtendedFormReturn } from 'models/forms'
import { FC, ReactNode, createContext, useContext, useEffect, useMemo, useRef } from 'react'
import { FieldValues, Path, UseFormRegisterReturn } from 'react-hook-form'
import { cloneDeep, isEqual, mapValues } from 'lodash'

import { useExtendedForm } from './useExtendedForm'

type Fields<T> = { [key in keyof T]?: UseFormRegisterReturn }

interface FormReturn<T extends FieldValues, U> {
  fields: Fields<U> | undefined
  hasChanges: boolean
  formUtils: UseExtendedFormReturn<T> | undefined
}

type Config<T> = {
  [key in Path<T>]?: ConfiguredFormFieldConfig
}

type DefaultValues<T> = {
  [key in Path<T>]?: string
}

/**
 * Automates registering fields and creating formProvider.
 * Suited for forms with non-dynamic validators (not dependent on other values of form)
 * Creates a Context, Hook and Provider with pre-registered fields and special hasChanges and dynamic values
 * @returns [Provider, Hook]
 * @typeParam T - Interface describing form values
 * @param config - Object with field definitions *(value, validators, ...)* indexed by path of key in T
 * @example 
 * interface FormValues {
 *  foo: string
 *  bar: {
 *    fooBar: string
 *  }
 * }
 * const [FieldsProvider, useFieldsContext] = getConfiguredForm<FormValues>({
 *  'foo': { value: '', required: true },
 *  'bar.fooBar': { value: 'some value' }
 * })
 */
export function getConfiguredForm<T extends FieldValues>(config: Config<T>): [
  FC<{
    defaultValues?: DefaultValues<T>,
    children?: ReactNode,
  }>,
  () => FormReturn<T, typeof config>
] {

  const context = createContext<FormReturn<T, typeof config>>({
    fields: undefined,
    hasChanges: false,
    formUtils: undefined
  })
  const useFormContext = (): FormReturn<T, typeof config> => useContext(context)

  const Provider: FC<{
    defaultValues?: DefaultValues<T>
    children?: ReactNode
  }> = ({
    defaultValues,
    children,
  }) => {

      // Flag to prevent unnecessary resyncing of default values
      const formsSynced = useRef(false)

      // Holds default values after fields are registered
      const formDefaultValues = useRef({})

      // Create form context and extract its utils to a variable
      const formUtils = useExtendedForm<T>({})

      // Map config fields to registered fields
      // and prefill them with default values from config OR defautValues (where/if set) from provider parameter
      const fields = useMemo(() => {
        return mapValues(config, (field, key) =>
          formUtils.register(key as Path<T>, {
            ...field,
            value: defaultValues && defaultValues[key as Path<T>]
              ? defaultValues[key as Path<T>]
              : field?.value,
          })
        )

        // Omit formUtils dependency to prevent formUtils value is being reset to default value
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [defaultValues])

      // Save default values of form to ref for reference after fields
      // and are update form values and validity after registering inputs
      if (!formsSynced.current) {
        formsSynced.current = true

        // Phantom timeout needed to let the form update after registering fields
        // explanation for this issue in a great event-loop video: https://www.youtube.com/watch?v=8aGhZQkoFbQ
        window.setTimeout(() => {
          // Save default values
          formDefaultValues.current = cloneDeep(formUtils.getValues())

          // Trigger validation if default values have been prefilled through provider
          if (!!defaultValues) formUtils.trigger()
        }, 0)
      }

      // When default values change, update ref
      // e.g. entity has been updated and new values have been pushed to form
      useEffect(() => {
        formDefaultValues.current = cloneDeep(formUtils.getValues())

        // ONLY trigger when defaultValues change
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [defaultValues])

      // Compare defaultFormValues to current values
      const hasChanges = useMemo(() => !isEqual(formDefaultValues.current, formUtils.values), [formUtils.values])

      return (
        <context.Provider value={{
          fields,
          hasChanges,
          formUtils,
        }}>
          {children}
        </context.Provider>
      )
    }

  return [Provider, useFormContext]
}
