import { WorkspaceUserProfile } from 'models/user'

import { Auth0Roles } from 'models/auth'
import { Nullable } from 'models/helpers'
import { ProductDTO } from 'models/product'
import { logAnalyticsEvent } from 'utils/analytics'

export const logDownloadRetryAfterError = (product: ProductDTO, roles: Auth0Roles, organizationId: Nullable<string>, userId: Nullable<string>, nonPersonalWorkspaces: WorkspaceUserProfile[]) => {
  logAnalyticsEvent('download_retried_after_error', {
    productCategory: product.category,
    productType: product.type,
    productKind: product.kind,
    productSegments: product.segments.join(','),
    role: Array.from(roles.all.values()).join(','),
    userId,
    organizationId,
    workspaceIDs: nonPersonalWorkspaces.length ? nonPersonalWorkspaces.map(({ id }) => id).join(', ') : '',
  })
}
