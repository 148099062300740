import React, { ReactNode } from 'react'

import styles from './EditorOptionWatermarkSection.module.sass'

interface Props {
  /** Title for section */
  title: string
  /** Content for the section */
  children: ReactNode
}

/**
 * @component EditorOptionWatermarkSection 
 * @example
 * <EditorOptionWatermarkSection>
 *    <div>Some content</div>
 * </EditorOptionWatermarkSection>
 */
export const EditorOptionWatermarkSection: React.FC<Props> = ({ children, title }) =>
  <div className={styles.section}>
    <div className={styles.title}>{title}</div>
    {children}
  </div>
