import { Box, Chip, CircularProgress, Divider, Stack, SxProps } from '@mui/material'
import { FC, ReactNode, useEffect, useMemo, useRef } from 'react'
import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query'
import { Power2, gsap } from 'gsap'
import { Transition, TransitionGroup } from 'react-transition-group'

import { AxiosResponse } from 'axios'
import { Color } from 'constants/assets'
import { Nullable } from 'models/helpers'
import { PageableResponse } from 'models/misc'
import { QueryStatus } from '../QueryStatus'
import { SearchOff } from '@mui/icons-material'
import classNames from 'classnames'
import styles from './InfiniteList.module.sass'
import { useTranslation } from 'react-i18next'

const _enterEffect = (timeline: gsap.core.Timeline, node: HTMLElement) => {
  if (!node) return
  if (node.nodeType === node.TEXT_NODE) return
  timeline.totalProgress(1).clear(true).totalProgress(0)

  const children = node.childNodes || []
  if (children.length > 0) {
    let omittedNodes = 0
    children.forEach((child, key) => {
      if (child.nodeType === child.TEXT_NODE) {
        omittedNodes++
        return
      }
      timeline.fromTo(child, { autoAlpha: 0, y: true ? 25 : 0, ease: Power2.easeIn }, { autoAlpha: 1, y: 0, ease: Power2.easeIn, duration: 0.3 }, (true ? 0.45 + (key - omittedNodes) * 0.075 : 0.01 * key))
    })
  }

  timeline.play()
}

/**
 * Component for triggering load of next page when in view
 * @example <Trigger observer={observerRef} />
 */
const Trigger: FC<{ observer: IntersectionObserver }> = ({ observer }) => {
  const triggerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let observant: Nullable<HTMLDivElement> = null

    if (triggerRef.current) {
      observer.observe(triggerRef.current)
      observant = triggerRef.current
    }

    return () => {
      if (observant) observer.unobserve(observant)
    }
  }, [observer])

  return <div ref={triggerRef}>&nbsp;</div>
}

/**
 * Helper function for serializing items from standard PagableResponse endpoint response
 * @param query - infinite query
 * @returns Serialized data T[]
 */
function _serializeInfinitePageableData<T>(query: UseInfiniteQueryResult<InfiniteData<AxiosResponse<PageableResponse<T[]>>>>) {
  const dataArray: T[] = []

  if (!query.data) return []

  for (const page of query.data.pages) {
    dataArray.push(...page.data.content)
  }

  return dataArray
}

interface Props<T> {
  /** Infinite query hook return */
  query: UseInfiniteQueryResult<any, any>
  /** Function to use for rendering list items */
  renderItem: (data: T) => ReactNode
  /** How far below the viewport should the trigger register (default 600px) */
  thresholdDistance?: number
  /** SX properties for main parent wrapper element */
  sx?: SxProps
  /** Optional header content to be rendered above list data (scrolls with content) */
  header?: ReactNode
  /** Function that serializes data from API into simple array T[] (defaults to PageableResponse serializer) */
  dataSerializerFnc?: (query: UseInfiniteQueryResult<any, any>) => T[]
  /** Function to get reference of HTML element that should be used as viewport for infinite list trigger (by default uses entire screen) */
  viewportRefGetter?: () => Element
}

/**
 * List of items dedicated to infinite loading of data using infiite queries.   
 * Provide generic type to specify what data will be rendered.   
 * @example   
 * <InfiniteList<DealDTO>
 *  query={dealListQuery}
 *  renderItem={(deal) => <DealCard data={deal} />}
 * />
 */
export function InfiniteList<T extends unknown>({
  header,
  query,
  sx = {},
  thresholdDistance = 600,
  dataSerializerFnc = _serializeInfinitePageableData,
  viewportRefGetter,
  renderItem,
}: Props<T>) {
  const { t } = useTranslation('list')

  const triggerRef = useRef<HTMLSpanElement>(null)
  const timelineEnter = useRef(gsap.timeline({ paused: true }))

  const data = useMemo(() => dataSerializerFnc(query), [dataSerializerFnc, query])

  const intersectionObserver = useRef(new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        query.fetchNextPage()
      }
    },
    {
      rootMargin: `0px 0px ${thresholdDistance}px 0px`,
      root: viewportRefGetter?.(),
    }
  ))

  useEffect(() => {
    if (triggerRef.current) {
      intersectionObserver.current.observe(triggerRef.current)
    }
  })

  return (
    <Stack sx={{ gap: 2, ...sx }}>

      {/* HEADER */}
      {!!header &&
        <Box flex="0 0 auto">
          {header}
        </Box>
      }

      {/* INITIAL LOADING */}
      {query.isInitialLoading &&
        <Stack direction="row" justifyContent="center" paddingTop={4}>
          <CircularProgress
            sx={{
              display: 'block',
              color: Color.GRAY_DISABLED,
            }}
          />
        </Stack>
      }

      {/* NO DATA EXIST */}
      {query.isSuccess && !data.length &&
        <Stack alignItems="center" className={styles.noData} paddingTop={4}>
          <SearchOff sx={{ fontSize: '5rem' }} />
          <span>{t('filter_no_data')}</span>
        </Stack>
      }

      {/* MAIN LIST */}
      <TransitionGroup component={null}>
        {!!data && !!data.length &&
          <Transition
            appear={true}
            in={true}
            onEnter={(node, _) => _enterEffect(timelineEnter.current, node)}
            timeout={{ enter: 600, exit: 0 }}
          >
            <Stack gap={2} className={classNames(styles.list, { [styles.isRefetching]: query.isRefetching })}>

              {/* DATA */}
              {data.map((item) => renderItem(item))}

              {/* END OF LIST */}
              {!query.isFetchingNextPage && !query.hasNextPage &&
                <Divider sx={{ paddingBottom: '4rem', paddingTop: '2.5rem' }}>
                  <Chip
                    label={t('end_of_list')}
                    variant="outlined"
                    sx={{
                      fontSize: '1.3rem',
                      marginBottom: '-15px',
                      color: Color.GRAY_TEXT,
                    }}
                  />
                </Divider>
              }

              {/* MORE LOADING */}
              {query.isFetchingNextPage && !!query.hasNextPage &&
                <Divider sx={{ paddingBottom: '4rem', paddingTop: '2rem' }}>
                  <CircularProgress size={30} sx={{ marginBottom: '-15px', color: Color.GRAY_DISABLED }} />
                </Divider>
              }

            </Stack>
          </Transition>
        }
      </TransitionGroup>

      <QueryStatus query={query} showStates={['error']} />

      {/* LOAD MORE TRIGGER */}
      {!query.isPending && !!data.length && !!query.hasNextPage &&
        <Trigger observer={intersectionObserver.current} />
      }

    </Stack>
  )
}
