import { VirtualFurnitureRoomRemovableOption, VirtualFurnitureRoomType, VirtualFurnitureStyleCategory } from 'constants/virtualFurniture'

import { InlineFilterItem } from 'components/common/InlineFilters'
import { VirtualFurnitureStyleDTO } from 'models/virtualFurniture'
import constate from 'constate'
import { useMemo } from 'react'
import { useStyleSelection } from './StyleSelection.context'
import { useTranslation } from 'react-i18next'
import { useVirtualFurnitureData } from 'components/contexts/VirtualFurnitureData.context'

function useVirtualFurnitureStylesFilterSetup() {

  const { t } = useTranslation(['virtual_furniture_style_category'])

  const { virtualFurnitureStyles } = useVirtualFurnitureData()
  const { activeRoom } = useStyleSelection()

  /** Serializes data to provide available filters, and filtered types / categories and styles */
  const {
    availableCategories,
    availableRoomTypes,
    filteredStyles,
    availableRoomRemovableOptions,
    filteredStylesByRoomType,
    availableFiltersByRoomType,
  } = useMemo(() => {
    const availableRoomTypes: Set<VirtualFurnitureRoomType> = new Set([])
    const availableCategories: Set<VirtualFurnitureStyleCategory> = new Set([])
    const availableRoomRemovableOptions: Set<VirtualFurnitureRoomRemovableOption> = new Set(Object.values(VirtualFurnitureRoomRemovableOption))
    const filteredStyles: VirtualFurnitureStyleDTO[] = []
    const filteredStylesByRoomType: Partial<Record<VirtualFurnitureRoomType, VirtualFurnitureStyleDTO[]>> = {}
    const finalMappedFilters: Record<string, InlineFilterItem<string>[]> = {}

    if (activeRoom) {
      const { propertyType, roomTypes, styleCategory } = activeRoom

      const filters: Partial<Record<VirtualFurnitureRoomType, Record<string, InlineFilterItem<string>>>> = {}

      for (const style of virtualFurnitureStyles) {

        // Ignore styles from other propertyTypes other than of the active room
        if (!!propertyType && propertyType !== style.propertyType) continue

        // Prepare new filter object
        const newFilters = { ...filters[style.roomType] }

        // Push new entry for the style's category or increase its count in case entry already exists
        newFilters[style.style] = newFilters[style.style]
          ? { ...newFilters[style.style], count: (newFilters?.[style.style]?.count || 0) + 1 }
          : {
            label: t(style.style),
            value: style.style,
            count: 1,
          }

        // And put those updated filters back to the main filter accumulator
        filters[style.roomType] = newFilters

        // Push style's room type to the set of available ones
        availableRoomTypes.add(style.roomType)

        // If style's room type is not the same as the one of the room, no need to do anything else with it
        if (!roomTypes.has(style.roomType)) continue

        // Initialize entry for room type in filters if not already
        if (!filteredStylesByRoomType[style.roomType]) filteredStylesByRoomType[style.roomType] = []

        // Push style's category to set of available ones
        availableCategories.add(style.style)

        // No need to do anything else unless style's category and room's category match
        if (!!styleCategory && styleCategory !== style.style) continue

        // All filters have been passed, so add the style to list and map
        filteredStyles.push(style)
        filteredStylesByRoomType[style.roomType] = [...(filteredStylesByRoomType[style.roomType] || []), style]
      }

      // Convert the Record to just have array of filters by room type
      for (let [roomType, value] of Object.entries(filters)) {
        finalMappedFilters[roomType] = Object.values(value)
      }
    }

    return {
      availableRoomTypes: Array.from(availableRoomTypes),
      availableCategories: Array.from(availableCategories),
      availableRoomRemovableOptions: Array.from(availableRoomRemovableOptions),
      filteredStyles,
      filteredStylesByRoomType,
      availableFiltersByRoomType: finalMappedFilters,
    }
  }, [activeRoom, virtualFurnitureStyles, t])

  return {
    filteredVirtualFurnitureStyles: filteredStyles,
    availableRoomTypes,
    availableCategories,
    availableRoomRemovableOptions,
    filteredStylesByRoomType,
    availableFiltersByRoomType,
  }
}

export const [VirtualFurnitureStylesFilterContextProvider, useVirtualFurnitureStylesFilter] = constate(useVirtualFurnitureStylesFilterSetup)
