import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequestState } from 'constants/API'
import { APIRequest } from 'models/API'
import { ActionRequest } from 'models/redux'
import { PlatformUser } from 'models/user'
import { createAPIAction } from 'redux/Helpers'
import { UserMeStore } from '.'

export interface ActionUserMe extends ActionRequest {
  payload: {
    request: UserMeStore
  }
}

export function userMe(): ActionUserMe {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.USER_ME], {})
}

export function updateUserMeStoreData(data: Partial<PlatformUser>): ActionUserMe {
  return createAPIAction([ActionTypeAPIEvent.RECEIVED, ActionTypeAPIData.UPDATE_USER_ME_STORE_DATA], {
    request: new APIRequest(APIRequestState.OK, undefined, data),
  })
}

export function purgeUserMe(): ActionUserMe {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.USER_ME], {})
}
