import { useCallback, useMemo, useState } from 'react'

import { useSavedStripePaymentMethods } from 'components/contexts/SavedStripePaymentMethodsContext'
import { PreferredPaymentMethodEnum } from 'constants/user'
import constate from 'constate'
import { useUpdateStripePaymentMethod } from 'dataQueries/payment.query'
import { getCardExpirationString } from 'utils/helpers'

/** Hook setup with edit payment method logic to be used with constate */
function useEditPaymentMethod() {

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [editedPaymentMethodId, setEditedPaymentMethodId] = useState<string>()

  const { savedStripePaymentMethods } = useSavedStripePaymentMethods()
  const editedPaymentMethod = savedStripePaymentMethods.find((paymentMethod) => paymentMethod.stripeId === editedPaymentMethodId)

  const updateStripePaymentMethod = useUpdateStripePaymentMethod()

  const setEditPaymentMethodModal = useCallback((paymentMethodId: string) => {
    setEditedPaymentMethodId(paymentMethodId)
    setIsEditModalOpen(true)
  }, [])

  const expirationDateString = useMemo(() => {
    if (!editedPaymentMethod || editedPaymentMethod.type !== PreferredPaymentMethodEnum.CARD_PAYMENT) return undefined

    const { cardExpirationMonth, cardExpirationYear } = editedPaymentMethod

    if (!cardExpirationMonth || !cardExpirationYear) return undefined

    return getCardExpirationString(cardExpirationMonth, cardExpirationYear)
  }, [editedPaymentMethod])

  return {
    editedPaymentMethod,
    expirationDateString,
    isEditModalOpen,
    setEditedPaymentMethodId,
    setIsEditModalOpen,
    setEditPaymentMethodModal,
    updateStripePaymentMethod,
  }

}

/** Provider and hook for editing payment method */
export const [EditPaymentMethodProvider, useEditPaymentMethodContext] = constate(useEditPaymentMethod)
