import React, { useState } from 'react'

import { Box } from '@mui/material'
import { MUIInputField } from 'components/common/MUIInputField'
import { MUINumberField } from 'components/common/MUINumberField'
import { PRIMARY_FIELD_WIDTH } from './_main/HeatingSystem.component'
import { VentilationHeatingProps } from '../_main/VentilationAndHeating.component'
import { useTranslation } from 'react-i18next'
import { useVentilationAndHeating } from '../_main/VentilationAndHeating.context'

/**
 * @component GeneratorYearDesignationPower
 * @description Renders options for the user to provide information about the generator year, designation and power. 
 * 
 * @example
 *   <GeneratorYearDesignationPower id="heating-system-1" />
 */
export const GeneratorYearDesignationPower: React.FC<VentilationHeatingProps> = ({ id }) => {

  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.energy_heating_page.heating_system' })

  const {
    currentHeatingSystem,
    handleUpdateHeatingSystemItem,
  } = useVentilationAndHeating()

  const {
    generatorYearOfManufacture,
    heatingDesignation,
    heatingPower,
  } = currentHeatingSystem(id) || {}

  const [showError, setShowError] = useState<boolean>(false)

  // If any of the required fields are empty, show error for all of them.
  const handleOnInputBlur = () => {
    if (!showError) setShowError(true)
  }

  return (
    <>
      {/* GENERATOR YEAR OF MANUFACTURE */}
      <Box width={{ xs: '80%', lg: PRIMARY_FIELD_WIDTH }}>
        <MUINumberField
          min={0}
          required
          inputBoxWidth='100%'
          label={t('generator_year_manufacture')}
          value={generatorYearOfManufacture}
          isError={showError && !generatorYearOfManufacture}
          onBlur={handleOnInputBlur}
          onChange={(value) => handleUpdateHeatingSystemItem(id, { generatorYearOfManufacture: value })}
        />
      </Box>

      {/* HEATER DESIGNATION */}
      <Box width={{ xs: '80%', lg: PRIMARY_FIELD_WIDTH }}>
        <MUIInputField
          label={t('heater_designation')}
          value={heatingDesignation}
          onBlur={handleOnInputBlur}
          onChange={(e) => handleUpdateHeatingSystemItem(id, { heatingDesignation: e.target.value })}
        />
      </Box>

      {/* HEATING POWER */}
      <Box width={{ xs: '80%', lg: PRIMARY_FIELD_WIDTH }}>
        <MUINumberField
          min={0}
          inputBoxWidth='100%'
          label={t('heating_power')}
          value={heatingPower}
          suffix='kW'
          onChange={(value) => handleUpdateHeatingSystemItem(id, { heatingPower: value })}
        />
      </Box>
    </>
  )
}
