import { BEIGE_600, GRAY_700 } from 'constants/styling/theme'
import { FC, Fragment, ReactNode, useMemo } from 'react'

import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import Box from '@mui/material/Box'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import { CircularProgress } from '@mui/material'
import { Color } from 'constants/assets'
import { CopyRoundButton } from '../../CopyRoundButton'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { MUIDivider } from 'components/common/MUIDivider'
import { QueryStatus } from 'components/common/QueryStatus'
import { RoundButton } from '../../RoundButton'
import Stack from '@mui/material/Stack'
import { VisualsMarketingTargetPlatform } from '../../_main/VisulalsMarketing.constants'
import { useMarketingMaterials } from '../../_main/contexts/MarketingMaterials.context'
import { useTranslation } from 'react-i18next'
import { useVisualsMarketing } from '../../_main/contexts'

/**
 * Props for the PostActionsWrapper component.
 * @interface Props
 */
interface Props {
  /** The child components or content to be rendered inside the PostActionsWrapper. */
  children: ReactNode
  platform: VisualsMarketingTargetPlatform
}

/**
 * @component
 * PostActionsWrapper component that wraps content with post actions.
 * It displays a header with a title, actions for editing, copying, and regenerating text,
 * the content passed as children, and footer actions for discarding and saving changes.
 * 
 * @example
 * <PostActionsWrapper title="Social Media Post" platform="INSTAGRAM"}>
 *   <p>Content goes here</p>
 * </PostActionsWrapper>
 */
export const PostActionsWrapper: FC<Props> = ({ children, platform }) => {

  const { t } = useTranslation(['gallery_marketing_section', 'visual_marketing_social_media'])

  const {
    marketingMaterials,
    generateText,
    openEdit,
    openedEdits,
    savePost,
    closeEdit,
    editors,
    generators
  } = useMarketingMaterials()

  const { isPropertyDetailsEditing } = useVisualsMarketing()

  const isReGenerating = useMemo(
    () => generators[platform].isPending && !!marketingMaterials[platform]?.id,
    [generators, marketingMaterials, platform]
  )

  return (
    <BorderBoxWrapper
      width="100%"
      elevation={'none'}
      backgroundColor={isReGenerating ? BEIGE_600 : Color.WHITE}
      position="relative"
    >

      <Box sx={{
        opacity: isReGenerating || isPropertyDetailsEditing ? 0.3 : 1,
        pointerEvents: isReGenerating || isPropertyDetailsEditing ? 'none' : 'all',
        transition: 'opacity 400ms ease',
      }}>

        {/** HEADER TITLE */}
        <Box sx={{ padding: '2rem 2rem 0.8rem', userSelect: 'none' }}>
          <h4>{t(`visual_marketing_social_media:post_title.${platform}`)}</h4>
        </Box>

        <MUIDivider margin={12} />

        {/** HEADER ACTIONS */}
        <Stack
          gap={1}
          marginBottom={2}
          padding="0 2rem"
          direction="row"
          flexWrap="wrap"
        >

          <RoundButton
            color='inherit'
            disabled={openedEdits[platform]}
            startIcon={<EditOutlinedIcon />}
            onClick={() => openEdit(platform)}
            sx={{
              '&.MuiButton-outlined:disabled': {
                backgroundColor: BEIGE_600
              }
            }}
          >
            {t('edit_text')}
          </RoundButton>

          <CopyRoundButton
            copyText={marketingMaterials[platform]?.text || ''}
            disabled={openedEdits[platform]}
            targetPlatform={platform}
          />

          <RoundButton
            color='inherit'
            disabled={openedEdits[platform]}
            startIcon={<AutoAwesomeOutlinedIcon />}
            onClick={() => generateText(platform, true)}
          >
            {t('regenerate_text')}
          </RoundButton>

        </Stack>

        <Box padding={2}>

          <QueryStatus
            query={generators[platform]}
            showStates={['error']}
            onPurge={() => generators[platform].reset()}
            spaceBottomRem={2}
          />

          {children}

        </Box>


        {/** FOOTER ACTIONS */}
        {openedEdits[platform] &&
          <Fragment>

            <MUIDivider margin={12} />

            <Stack
              gap={1}
              marginBottom={1}
              direction="row"
              flexWrap="wrap"
              justifyContent="flex-end"
            >

              <RoundButton
                color='inherit'
                startIcon={<DeleteOutlineIcon />}
                onClick={() => closeEdit(platform)}
              >
                {t('discard_changes')}
              </RoundButton>

              <RoundButton
                color='primary'
                startIcon={<CheckCircleOutlineRoundedIcon />}
                onClick={() => savePost(platform)}
              >
                {t('save_changes')}
              </RoundButton>

              <Box flex="1 1 100%">
                <QueryStatus
                  query={editors[platform]}
                  spaceTopRem={1}
                />
              </Box>

            </Stack>

          </Fragment>
        }

      </Box>

      {isReGenerating &&
        <CircularProgress
          size={72}
          sx={{
            position: 'absolute',
            top: 'calc(50% - 36px)',
            left: 'calc(50% - 36px)',
            '& svg': {
              color: GRAY_700,
            },
          }}
        />
      }

    </BorderBoxWrapper>
  )
}
