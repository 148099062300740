import { AxiosResponse } from 'axios'
import { Organization } from 'models/organization'
import { useAPI } from 'utils/API'
import { getMutation } from 'utils/reactQuery'

enum Endpoints {
  GET_ORGANIZATION = '/organization/{organizationId}',
}

// QUERIES


// MUTATIONS
export function useGetOrganization$() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<Organization>, { organizationId: string }>(
    ({ organizationId }) => api.get(
      Endpoints.GET_ORGANIZATION,
      { organizationId },
      true
    )
  )
}
