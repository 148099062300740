import { FloorPlanBasicOptions } from './floorPlanOptions'
import basic_black from 'assets/img/floorplan/2D/2D_commercial_bw.webp'
import basic_blue from 'assets/img/floorplan/2D/2D_commercial_blue.webp'
import basic_fixtures_black from 'assets/img/floorplan/2D/2D_commercial_bw_fixtures.webp'
import basic_fixtures_blue from 'assets/img/floorplan/2D/2D_commercial_blue_fixtures.webp'
import basic_fixtures_furniture_black from 'assets/img/floorplan/2D/2D_commercial_bw_fixtures_furniture.webp'
import basic_fixtures_furniture_blue from 'assets/img/floorplan/2D/2D_commercial_blue_fixtures_furniture.webp'
import basic_fixtures_furniture_green from 'assets/img/floorplan/2D/2D_commercial_green_fixtures_furniture.webp'
import basic_fixtures_furniture_neutral from 'assets/img/floorplan/2D/2D_commercial_neutral_fixtures_furniture.webp'
import basic_fixtures_green from 'assets/img/floorplan/2D/2D_commercial_green_fixtures.webp'
import basic_fixtures_neutral from 'assets/img/floorplan/2D/2D_commercial_neutral_fixtures.webp'
import basic_furniture_black from 'assets/img/floorplan/2D/2D_commercial_bw_furniture.webp'
import basic_furniture_blue from 'assets/img/floorplan/2D/2D_commercial_blue_furniture.webp'
import basic_furniture_green from 'assets/img/floorplan/2D/2D_commercial_green_furniture.webp'
import basic_furniture_neutral from 'assets/img/floorplan/2D/2D_commercial_neutral_furniture.webp'
import basic_green from 'assets/img/floorplan/2D/2D_commercial_green.webp'
import basic_neutral from 'assets/img/floorplan/2D/2D_commercial_neutral.webp'
import compass from 'assets/img/floorplan/general/compass.webp'
import dimensions_chains from 'assets/img/floorplan/general/dimension_chains.webp'
import dimensions_each_room from 'assets/img/floorplan/general/dimensions_each_room.webp'
import disclaimer_text from 'assets/img/floorplan/general/disclaimer_text.webp'
import logo_placeholder from 'assets/img/floorplan/general/logo_placeholder.webp'
import room_area from 'assets/img/floorplan/general/room_area.webp'
import room_names from 'assets/img/floorplan/general/room_names_ENG.webp'
import scale_bar from 'assets/img/floorplan/general/scale_bar.webp'

export enum FloorPlanColors {
  BLACK = 'BLACK',
  BLUE = 'BLUE',
  GREEN = 'GREEN',
  NEUTRAL = 'NEUTRAL',
  // Next colors are not used in the current implementation
  // BROWN = 'BROWN',
  // GOLD = 'GOLD',
  // NAVY = 'NAVY',
  // RED = 'RED',
}

const colorMap: Record<FloorPlanColors, string> = {
  [FloorPlanColors.BLACK]: basic_black,
  [FloorPlanColors.BLUE]: basic_blue,
  [FloorPlanColors.GREEN]: basic_green,
  [FloorPlanColors.NEUTRAL]: basic_neutral,
}

const fixturesMap: Record<FloorPlanColors, string> = {
  [FloorPlanColors.BLACK]: basic_fixtures_black,
  [FloorPlanColors.BLUE]: basic_fixtures_blue,
  [FloorPlanColors.GREEN]: basic_fixtures_green,
  [FloorPlanColors.NEUTRAL]: basic_fixtures_neutral,
}

const furnitureMap: Record<FloorPlanColors, string> = {
  [FloorPlanColors.BLACK]: basic_furniture_black,
  [FloorPlanColors.BLUE]: basic_furniture_blue,
  [FloorPlanColors.GREEN]: basic_furniture_green,
  [FloorPlanColors.NEUTRAL]: basic_furniture_neutral,
}

const fixturesFurnitureMap: Record<FloorPlanColors, string> = {
  [FloorPlanColors.BLACK]: basic_fixtures_furniture_black,
  [FloorPlanColors.BLUE]: basic_fixtures_furniture_blue,
  [FloorPlanColors.GREEN]: basic_fixtures_furniture_green,
  [FloorPlanColors.NEUTRAL]: basic_fixtures_furniture_neutral,
}

// Non colored options
const basicLogo = logo_placeholder
const basicRoomNames = room_names
const basicDimensionsChains = dimensions_chains
const basicDimensionsEachRoom = dimensions_each_room
const basicScalebar = scale_bar
const basicRoomArea = room_area
const basicCompass = compass
const basicDisclaimer = disclaimer_text

/** Gets an array of image URLs based on the selected floor plan options. */
export const getFloorPlanPreviewImages = ({
  color,
  showEachRoomDimensions,
  showDimensionChain,
  showScalebar,
  showFurniture,
  showLogo,
  showRoomArea,
  showRoomNames,
  showCompass,
  showFixtures,
  showDisclaimer,
}: FloorPlanBasicOptions) => {

  const images: string[] = []

  // Base image
  if (showDisclaimer) images.push(basicDisclaimer)
  if (showFurniture && !showFixtures) images.push(furnitureMap[color])
  if (showFixtures && !showFurniture) images.push(fixturesMap[color])
  if (showFurniture && showFixtures) images.push(fixturesFurnitureMap[color])
  if (!showFixtures && !showFurniture) images.push(colorMap[color])
  if (showEachRoomDimensions) images.push(basicDimensionsEachRoom)
  if (showDimensionChain) images.push(basicDimensionsChains)
  if (showScalebar) images.push(basicScalebar)
  if (showLogo) images.push(basicLogo)
  if (showRoomArea) images.push(basicRoomArea)
  if (showRoomNames) images.push(basicRoomNames)
  if (showCompass) images.push(basicCompass)

  return images
}
