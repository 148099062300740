import { FC, Fragment, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IconType } from 'constants/assets'
import { PIPEDRIVE_INFINITY } from 'constants/pipedrive'
import { useSendVisualsToClient } from 'dataQueries'
import { useActionPopup } from 'utils/hooks'
import { StageOrder, compareAssignmentStageOrder } from 'utils/stages'

import AssignmentStageAdvice from 'components/common/AssignmentStageAdvice/AssignmentStageAdvice'
import Button from 'components/common/Button/Button'
import Icon from 'components/common/Icon/Icon'
import { useGalleryConstants } from 'components/pages/Gallery/_main/contexts/GalleryConstants.context'
import { useGalleryAssignment } from 'components/pages/Gallery/_main/contexts/GalleryAssignment.context'
import { QueryStatus } from 'components/common/QueryStatus'

interface Props {
  canSendAssignmentVisualsToClient: Boolean,
  assignmentId: string,
  visualsCount: number,
  selectMaxValue: number,
}

export const SendToClient: FC<Props> = ({
  canSendAssignmentVisualsToClient,
  assignmentId,
  visualsCount,
  selectMaxValue,
}) => {
  const { t } = useTranslation(['gallery'])
  const { showConfirm } = useActionPopup()
  const {
    stagesUnlockingSendAssignmentVisualsToClient,
  } = useGalleryConstants()
  const {
    assignmentStage,
  } = useGalleryAssignment()
  const sendAssignmentVisualsToClient = useSendVisualsToClient()

  const canBeSendToClient = useMemo(() => {
    return canSendAssignmentVisualsToClient && (!sendAssignmentVisualsToClient || sendAssignmentVisualsToClient.isIdle)
  }, [canSendAssignmentVisualsToClient, sendAssignmentVisualsToClient])
  const assignmentVisualsAlreadySentToClient = useMemo(() => (!!assignmentStage && (compareAssignmentStageOrder(assignmentStage, stagesUnlockingSendAssignmentVisualsToClient) === StageOrder.COMPARING_AFTER_COMPARED_TO)), [assignmentStage, stagesUnlockingSendAssignmentVisualsToClient])
  const cannotSendAssignmentVisualsToClientInfo = useMemo(() => {
    if ((!(!sendAssignmentVisualsToClient || sendAssignmentVisualsToClient.isIdle)) || assignmentVisualsAlreadySentToClient) {
      return t('sent_to_client')
    } else if (!assignmentStage || !stagesUnlockingSendAssignmentVisualsToClient.has(assignmentStage)) {
      return <AssignmentStageAdvice id="send_to_client" currentStage={assignmentStage} validStages={stagesUnlockingSendAssignmentVisualsToClient} />
    } else if (!(visualsCount >= selectMaxValue || selectMaxValue === PIPEDRIVE_INFINITY)) {
      return t('select_more_visuals')
    } else {
      return ''
    }
  }, [assignmentStage, assignmentVisualsAlreadySentToClient, selectMaxValue, sendAssignmentVisualsToClient, stagesUnlockingSendAssignmentVisualsToClient, t, visualsCount])

  const initiateSendAssignmentVisualsToClient = useCallback(async () => {
    if (!canSendAssignmentVisualsToClient) return
    if (!(await showConfirm(t('send_to_client_prompt')))) return
    sendAssignmentVisualsToClient.mutate({ assignmentId })
  }, [assignmentId, canSendAssignmentVisualsToClient, sendAssignmentVisualsToClient, showConfirm, t])

  return (
    <Fragment>
      <div className="part flex">
        <Button
          type="secondary green"
          disabled={!canBeSendToClient}
          onClick={initiateSendAssignmentVisualsToClient}
          textAndIcon={true}
          className="space-right"
        >
          <Icon icon={IconType.CHECK} />
          <span>{t('send_to_client')}</span>
        </Button>
        {!canBeSendToClient && cannotSendAssignmentVisualsToClientInfo &&
          <span>{cannotSendAssignmentVisualsToClientInfo}</span>
        }
      </div>
      <QueryStatus
        query={sendAssignmentVisualsToClient}
        successMessage={t('sent_to_client')}
        spaceBottomRem={2}
      />
    </Fragment>
  )
}
