import { FC, Fragment } from 'react'

import { ConfiguredInput } from '../../common/ConfiguredInput'
import { useSavedCardDetailsFields } from './SavedCardDetails.form'
import { useTranslation } from 'react-i18next'

/**
 * Displays form fields of saved card details 
 * @example <SavedCardDetailsFormFields />
 */
export const SavedCardDetailsFormFields: FC = () => {

  const { t } = useTranslation(['card_edit_fields'])

  const { formUtils, fields } = useSavedCardDetailsFields()

  if (!formUtils || !fields) return <Fragment></Fragment>

  const { errors } = formUtils.formState

  return (
    <ConfiguredInput
      name="card-expiration"
      label={t('card_expiration')}
      placeholder="MM/YYYY"
      error={errors.cardExpiration}
      control={fields.cardExpiration}
    />
  )
}