import 'moment/locale/de'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/nl'

import { RoleMimetype } from 'constants/API'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import I18NextHttpBackend from 'i18next-http-backend'
import I18NextMultiloadBackendAdapter from 'i18next-multiload-backend-adapter'
import Cookies from 'js-cookie'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import { initReactI18next } from 'react-i18next'
import API from 'utils/API/API'
import { auth0ClientModule } from 'utils/auth'
import { unflattenObject } from 'utils/serialization'
import de from './assets/de.json'
import en from './assets/en.json'
import es from './assets/es.json'
import fr from './assets/fr.json'
import it from './assets/it.json'
import nl from './assets/nl.json'

/** Copy moment locale configs to moment-timezone */
for (const locale of moment.locales().filter(locale => !momentTZ.locales().includes(locale))) {
  momentTZ.defineLocale(locale, (moment.localeData(locale) as any)._config)
}

/** i18n translations by language */
const resources = {
  en,
  fr,
  de,
  es,
  nl,
  it,
}

/** All translation namespaces */
export const namespaces = Object.keys(Object.values(resources)[0])

/** All translation languages */
export const languages = Object.keys(resources).map(lang => lang.toLowerCase())

/** Bundle of all resources */
export const languageResources = resources

/** Custom headers for i18n backend */
export const customHeaders = {
  Authorization: '',
  Accept: RoleMimetype.CLIENT,
}

/** Reloads all backend resources for all languages and namespaces */
export const reloadAllResources = () => {
  auth0ClientModule.getTokenSilently().then((token: string) => {
    customHeaders.Authorization = `Bearer ${token}`
    i18n.reloadResources(
      languages,
      namespaces,
      () => {
        i18n.changeLanguage(i18n.language)
        // Set language cookie for the user
        Cookies.set('i18next', i18n.language.toUpperCase())
      })
  })
}

/**
 * Transforms a language string to one of Auth0's allowed value
 * @example 'en-GB' -> 'en'
 */
export const transformLanguageToAuth0Value = (language: string) => language.replace(/-\w+/gmi, '')

const apiPath = process.env.REACT_APP_DISABLE_FETCH_TRANSLATIONS !== '1'
  ? `${API.baseUrl}/translation/all`
  : undefined

const MultiloadBackend = new I18NextMultiloadBackendAdapter(null, {
  backend: I18NextHttpBackend, // loads resources from the api
  backendOption: {
    loadPath: (lngs: string[], namespaces: string[]) => apiPath,
    parse: (data: string) => {
      const result = JSON.parse(data)
      for (const key in result) result[key] = unflattenObject(result[key])
      return result
    },
    customHeaders,
    allowMultiLoading: true,
  }
})

/**
 * Temporary fix for type issues with latest i18n library
 * Will be solved in next major update v23
 * https://github.com/i18next/i18next/issues/1884
 */
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

i18n
  .use(LanguageDetector) // detect user language
  .use(MultiloadBackend) // allows backend multiloading
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,

    ns: namespaces,

    supportedLngs: languages,
    fallbackLng: languages,
    returnNull: false,

    nonExplicitSupportedLngs: true,

    maxParallelReads: Number.MAX_VALUE,

    // debug: true,

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    react: {
      useSuspense: false,
    },

    detection: {
      lookupQuerystring: 'lang',
      caches: ['localStorage'],
    },
  })

/** i18n initialization object */
export default i18n
