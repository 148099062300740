import 'styles/inputs.sass'

import { BaseInstructionCard, BaseInstructionCardPassDownProps } from '../BaseInstructionCard'
import { FC, useEffect } from 'react'
import { OrganizationKindsType, OrganizationProductKinds } from 'constants/product'

import { InstructionPrice } from '../../InstructionPrice/InstructionPrice.component'
import { MeetingInstructionsFields } from './MeetingInstructionsFields.component'
import { usePurchaseFlowOrderMeta } from 'components/pages/PurchaseFlow/_main/contexts/PurchaseFlowOrderMeta.context'

/**
 * Card for meeting instructions.
 * If client checks the checkbox that they will be available on-site, client's data is prefilled to "name/phone number/email" fields and will stay editable
 *   
 * Admins are treated as clients by default
 * 
 * DEPENDENCIES:
 * - MeetingInstructionsContextProvider
 * 
 * @example
 * <MeetingInstructionsCard
 *  isCardDisabled={false}
 *  isSelectionDisabled={false}
 *  instructionOption={instructionOptionDTO}
 *  instructionType={InstructionType.BILLING_PRIMARY}
 *  onCheck={(checked) => console.log}
 * />
 */
export const MeetingInstructionsCard: FC<BaseInstructionCardPassDownProps> = ({
  isCardPreselected = false,
  isCardDisabled,
  isSelectionDisabled,
  instructionOption,
  instructionType,
}) => {

  const { selectedMeetingType, onCheckMeetingType } = usePurchaseFlowOrderMeta()

  useEffect(() => {
    if (isCardPreselected) onCheckMeetingType(instructionOption.kind)

    // Only run once when the card is preselected
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCardPreselected])

  if (!OrganizationProductKinds.has(instructionOption.kind)) return null

  return (
    <BaseInstructionCard
      instructionType={instructionType}
      isSelectionDisabled={isSelectionDisabled}
      instructionOption={instructionOption}
      isCardDisabled={isCardDisabled}
      onCheck={() => onCheckMeetingType(instructionOption.kind)}
      isChecked={selectedMeetingType === instructionOption.kind}
      rightHeaderContent={<InstructionPrice feePrice={instructionOption.feePrice} />}
    >
      <MeetingInstructionsFields kind={instructionOption.kind as OrganizationKindsType} />
    </BaseInstructionCard >
  )
}
