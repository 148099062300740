import './NotFound.sass'

import Button from 'components/common/Button/Button'
import { Link } from 'react-router-dom'
import { PageTransition } from 'utils/animations'
import React from 'react'
import { useTranslation } from 'react-i18next'

const NotFound: React.FC = () => {
  const { t } = useTranslation('notfound')
  return (
    <PageTransition>
      <div className="page triangle-stripe-bg notfound">
        <div className="page-content">
          <div className="wrap">
            <div className="content">
              <h1>{t('title')}</h1>
              <p>{t('text')}</p>
              <br /><br />
              <Link to="/">
                <Button>
                  {t('button')}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </PageTransition>
  )
}

export default NotFound
