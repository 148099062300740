import './ClientOnboarding.sass'

import { ColorClass, IconType } from 'constants/assets'
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getUserTimezone, timezoneNames } from 'utils/time'
import { useAdminOnboardClient, useAdminResendClientInvitation } from 'dataQueries'

import BlockInfo from 'components/common/BlockInfo/BlockInfo'
import Button from 'components/common/Button/Button'
import { DEBOUNCE_TIMEOUT } from 'constants/application'
import Dropdown from 'components/common/Dropdown/Dropdown'
import Icon from 'components/common/Icon/Icon'
import { Language } from 'translations/Language'
import { Organization } from 'models/organization'
import { PageTransition } from 'utils/animations'
import { Path } from 'constants/router'
import { QueryStatus } from 'components/common/QueryStatus'
import TriangleIcon from 'components/common/TriangleIcon/TriangleIcon'
import { debounceEffect } from 'utils/helpers'
import { searchOrganizations } from 'redux/Individual/Organization/SearchOrganizations'
import { useAuth0 } from 'utils/auth'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTimezone } from 'components/contexts/timezone.context'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

enum EntryType {
  ONBOARD,
  RESEND,
}

interface ClientData {
  email: string
  language: string
  timezone: string
  timestamp: number
  type: EntryType
}

interface ClientState {
  data: ClientData
  query: any
}

interface ClientsState {
  [email: string]: ClientState
}

const ClientOnboarding: React.FC = () => {
  const dispatch = useDispatch()
  const { roles } = useAuth0()
  const navigate = useNavigate()
  const { t } = useTranslation(['language'])

  const [email, setEmail] = useState('')
  const [clientLanguage, setClientLanguage] = useState<Language>(Language.EN)
  const [clientTimezone, setClientTimezone] = useState(getUserTimezone)
  const [justSent, setJustSent] = useState(false)
  const [onboardStore, setOnboardStore] = useState<ClientsState>({})
  const [resendStore, setResendStore] = useState<ClientsState>({})

  const { baseUserData } = useUserData()
  const { userTimezone } = useTimezone()

  const adminOnboardClient = useAdminOnboardClient()
  const adminResendInvitationClient = useAdminResendClientInvitation()

  const sortedOnboardingEntries = useMemo(() => [
    ...Object.entries(onboardStore),
    ...Object.entries(resendStore)
  ].sort(([aKey, aObject], [bKey, bObject]) => {
    if (!aObject?.data?.timestamp || !bObject?.data?.timestamp) return 0
    if (aObject.data.timestamp < bObject.data.timestamp) return 1
    else if (aObject.data.timestamp > bObject.data.timestamp) return -1
    else return 0
  }), [onboardStore, resendStore])
  const onboardedEmails = useMemo(() => new Set(Object.values(onboardStore).map(item => item?.data?.email || '').filter(item => !!item)), [onboardStore])
  const resentEmails = useMemo(() => new Set(Object.values(resendStore).map(item => item?.data?.email || '').filter(item => !!item)), [resendStore])

  const isEmptyEmail = useMemo(() => email === '', [email])
  const isValidEmail = useMemo(() => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return emailRegex.test(email)
  }, [email])
  const isAlreadyOnboarded = useMemo(() => onboardedEmails.has(email), [onboardedEmails, email])
  const isAlreadyOnboardedPreviously = useMemo(() => isAlreadyOnboarded && !justSent, [isAlreadyOnboarded, justSent])
  const isAlreadyResent = useMemo(() => resentEmails.has(email), [resentEmails, email])
  const isAlreadyResentPreviously = useMemo(() => isAlreadyResent && !justSent, [isAlreadyResent, justSent])
  const isOnboardDisabled = useMemo(() => isEmptyEmail || !isValidEmail || isAlreadyOnboarded, [isEmptyEmail, isValidEmail, isAlreadyOnboarded])
  const isOnboardError = useMemo(() => isEmptyEmail || !isValidEmail || isAlreadyOnboardedPreviously, [isEmptyEmail, isValidEmail, isAlreadyOnboardedPreviously])
  const isResendDisabled = useMemo(() => isEmptyEmail || !isValidEmail || isAlreadyResent, [isEmptyEmail, isValidEmail, isAlreadyResent])
  const isResendError = useMemo(() => isEmptyEmail || !isValidEmail || isAlreadyResentPreviously, [isEmptyEmail, isValidEmail, isAlreadyResentPreviously])

  // Searching an organization by VAT number
  const [searchOrganizationVatNumber] = useState('')
  const [, setProcessedSearchOrganizationVatNumber] = useState('')
  const debounceSearchOrganizationVatNumberTimeoutRef = useRef<number | undefined>(undefined)

  // Searched organizations
  const [chosenOrganization] = useState<Organization | undefined>(undefined)

  const clientInfoObject = useCallback((type: EntryType) => {
    return {
      email,
      language: clientLanguage,
      timezone: clientTimezone,
      timestamp: Date.now(),
      type
    }
  }, [clientLanguage, clientTimezone, email])

  const handleResendInvitation = useCallback(() => {
    if (isResendDisabled) return

    adminResendInvitationClient.mutate(
      { email },
      {
        onSettled: (data, error) => {
          setResendStore(prev => ({
            ...prev,
            [email]: {
              data: clientInfoObject(EntryType.RESEND),
              query: {
                data,
                error,
                status: error ? 'error' : 'success'
              },
            }
          }))
        }
      }
    )
    setJustSent(true)
  }, [adminResendInvitationClient, clientInfoObject, email, isResendDisabled])

  const handleOnboardClient = useCallback(() => {
    if (isOnboardDisabled) return

    adminOnboardClient.mutate(
      { email, language: clientLanguage, timezone: clientTimezone, organizationId: chosenOrganization?.id, country: undefined },
      {
        onSettled: (data, error) => {
          setOnboardStore(prev => ({
            ...prev,
            [email]: {
              data: clientInfoObject(EntryType.ONBOARD),
              query: {
                data,
                error,
                status: error ? 'error' : 'success'
              },
            }
          }))
        }
      }
    )
    setJustSent(true)
  }, [adminOnboardClient, chosenOrganization?.id, clientInfoObject, clientLanguage, clientTimezone, email, isOnboardDisabled])

  // Debounce search creative filter
  useEffect(() => {
    if (searchOrganizationVatNumber) {
      debounceEffect(() => {
        dispatch(searchOrganizations(searchOrganizationVatNumber))
        setProcessedSearchOrganizationVatNumber(searchOrganizationVatNumber)
      }, debounceSearchOrganizationVatNumberTimeoutRef, DEBOUNCE_TIMEOUT)
    }
  }, [dispatch, searchOrganizationVatNumber])

  /** React to userMe request and set default timezone */
  useEffect(() => {
    if (baseUserData) setClientTimezone(userTimezone)
  }, [baseUserData, userTimezone])

  if (!roles.isAdmin) {
    navigate(Path.INDEX)
    return (
      <PageTransition>
        <div className="page">
          <div className="page-content">
            <h1>ADMIN ONLY AREA</h1>
          </div>
        </div>
      </PageTransition>
    )
  }

  return (
    <PageTransition>
      <div className="page client-onboarding">
        <div className="page-content">
          <h1>
            <span className="group">
              <TriangleIcon icon={IconType.HANDSHAKE} type={ColorClass.PRIMARY_ORANGE} />
              Onboard Client
            </span>
          </h1>
          <BlockInfo color="orange" className="full-width">
            <h4>Instructions</h4>
            <ol className="instructions-list">
              <li className="instruction-item">On Pipedrive, make sure the Client email address is <span className="bold">unique</span> (no duplicate persons are allowed).</li>
              <li className="instruction-item">On Pipedrive, make sure the person is linked the correct organisation.</li>
              <li className="instruction-item">On Pipedrive, make sure the person's organisation has the right <span className="bold">address</span>.</li>
            </ol>
          </BlockInfo>
          <div className="inputs">

            <div className="input-row">
              <div className="input-group language-group">
                <label htmlFor="client-language">Client language</label>
                <Dropdown className="language-dropdown" attachment="right-attached" button={(isOpen, action) => (
                  <Button type="secondary" onClick={action} textAndIcon={true}>
                    <span>{(t(`language:${(clientLanguage || '').toUpperCase()}`) || '').toString()}</span>
                    <Icon icon={IconType.CARET_DOWN} className={`caret ${isOpen ? 'up' : 'down'}`} />
                  </Button>
                )}>
                  <Fragment>
                    {Object.values(Language).map(lang => {
                      const selected = lang === clientLanguage
                      return (
                        <button
                          key={lang}
                          className={`language-button ${selected ? 'selected' : ''}`}
                          onClick={() => setClientLanguage(lang)}
                        >
                          {t(`language:${lang.toUpperCase()}`, lang)}
                        </button>
                      )
                    })}
                  </Fragment>
                </Dropdown>
              </div>

              <div className="input-group timezone-group">
                <label htmlFor="client-timezone">Client timezone</label>
                <select
                  name="client-timezone"
                  id="client-timezone"
                  value={clientTimezone}
                  onChange={e => setClientTimezone(e.target.value)}
                >
                  {timezoneNames.map(tzn => (
                    <option value={tzn} key={tzn}>{tzn}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="input-row">
              <div className="input-group email-group">
                <label htmlFor="client-email">Client email address</label>
                <input
                  className={`email-input withicon right ${IconType.ENVELOPE} ${isOnboardError || isResendError ? 'error-input' : ''}`.trim()}
                  type="email"
                  name="client-email"
                  id="client-email"
                  placeholder="Client email address"
                  value={email}
                  onChange={e => {
                    setJustSent(false)
                    setEmail(e.target.value)
                  }}
                />
                {isEmptyEmail &&
                  <span className="error-message">Client email address cannot be empty</span>
                }
                {!isValidEmail && !isEmptyEmail &&
                  <span className="error-message">Client email address is not valid</span>
                }
                {isAlreadyOnboardedPreviously &&
                  <span className="error-message">Client email address already submitted for onboarding</span>
                }
                {isAlreadyResentPreviously &&
                  <span className="error-message">Client email address already submitted for invitation resend</span>
                }
              </div>

              <div className="input-group button-group">
                <Button
                  className="onboard-button"
                  type="primary"
                  disabled={isOnboardDisabled}
                  onClick={handleOnboardClient}
                >
                  Onboard Client
                </Button>
                <Button
                  className="resend-button"
                  type="secondary"
                  disabled={isResendDisabled}
                  onClick={handleResendInvitation}
                >
                  Resend invitation
                </Button>
              </div>

            </div>
          </div>

          <h2>List of currently submitted Clients</h2>
          <div className="onboarding-list">
            {sortedOnboardingEntries.map(([emailKey, item]) => (
              <div key={emailKey} className="item">
                <h3 className="email">{emailKey}</h3>
                <div className="status">
                  {item ?
                    <QueryStatus
                      query={item.query}
                      successMessage={item.data?.type === EntryType.RESEND
                        ? 'Resent succesfully!'
                        : 'Onboarded successfully!'
                      }
                    />
                    :
                    <BlockInfo color="blue">
                      <h4>Unknown</h4>
                    </BlockInfo>
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </PageTransition>
  )
}

export default ClientOnboarding
