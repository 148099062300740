import { ClientDashboardController } from './ClientDashboard.controller'
import React from 'react'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { redesignMUITheme } from 'constants/styling/redesignTheme'

/**
 * Page representing the overall client dashboard content.
 *
 * @example <ClientDashboard />
*/
export const ClientDashboard: React.FC = () => {

  return (
    <ThemeProvider theme={redesignMUITheme}>

      <ClientDashboardController />

    </ThemeProvider>
  )
}
