import { GeneratedByType, OrientationType, PhotovoltaicSystemType, VentilationSystemType } from 'constants/purchaseFlow'
import { HeatingSystemItemDTO, PhotovoltaicFeatures, VentilationFeaturesDTO } from 'models/purchaseFlow/energyCertificateOfNeedDTO'
import { useCallback, useMemo, useState } from 'react'

import { ImmutableMap } from 'models/helpers'
import constate from 'constate'
import { uniqueId } from 'lodash'

// HELPER FUNCTIONS
/**
 * Adds a heating system item to the state.
 * @returns A new immutable map with the added heating system item.
 */
const addItem = (state: ImmutableMap<string, HeatingSystemItemDTO>, itemId: string, item: HeatingSystemItemDTO) =>
  state.set(itemId, item)

/**
 * Updates an item in the state with the given new properties.
 * @returns The updated state with the modified item.
 */
const updateItem = (state: ImmutableMap<string, HeatingSystemItemDTO>, itemId: string, newItem: Partial<HeatingSystemItemDTO>) =>
  state.update(itemId, (value) => ({ ...value!, ...newItem }))

/**
 * Removes an item from the heating system state.
 * @returns A new immutable map with the specified item removed.
 */
const removeItem = (state: ImmutableMap<string, HeatingSystemItemDTO>, itemId: string) =>
  state.delete(itemId)

/** Generates a unique identifier for a heating system. */
const _generateHeatingSystemId = (): string => uniqueId('heating-system-')

const defaultVentilationAndHeatingState: HeatingSystemItemDTO = {
  isHydraulicBalancing: false,
  heatingFuel: null,
  heatingFuelOption: null,
  woodPalletsEnergySource: null,
  heatingDesignation: '',
  heaterLocation: null,
  waterLocation: null,
  nightService: null,
  circulatingPumpType: null,
  heatTransferType: null,
  heatingPipeType: null,
  generatedBy: GeneratedByType.ELECTRIC,
  generatorYearOfManufacture: null,
  heatingPower: null,
  yearOfConstruction: null,
  decentralizedHeatingType: null,
  totalHeatingOutput: 100,
  heatingCombinedWith: new Set([]),
  isHotWaterCirculation: false,
}

const defaultPhotovoltaicFeaturesState = {
  areaOfFacility: '',
  peakPower: '',
  orientationType: OrientationType.NORTH,
}

const defaultVentilationSystemState = {
  ventilationSystem: new Set([VentilationSystemType.WINDOW_VENTILATION]),
  distribution: null,
  ventilation: null,
  fans: null,
  heatRecovery: null,
}

export const [VentilationAndHeatingContextProvider, useVentilationAndHeating] = constate(() => {

  const [ventilationAndHeatingSystem, setVentilationAndHeatingSystem] = useState<ImmutableMap<string, HeatingSystemItemDTO>>(ImmutableMap({ 'heating-system-1': defaultVentilationAndHeatingState }))
  const [photovoltaicFeatures, setPhotovoltaicFeatures] = useState<PhotovoltaicFeatures>(defaultPhotovoltaicFeaturesState)
  const [photovoltaicSystem, setPhotovoltaicSystem] = useState<PhotovoltaicSystemType>(PhotovoltaicSystemType.NO)
  const [ventilationFeatures, setVentilationFeatures] = useState<VentilationFeaturesDTO>(defaultVentilationSystemState)

  /** Adds a new heating system item to the list of heating system items. */
  const handleAddHeatingSystemItem = useCallback(() => {
    const itemId = _generateHeatingSystemId()
    setVentilationAndHeatingSystem(prevState => {
      const newState = addItem(prevState, itemId, defaultVentilationAndHeatingState)
      const totalHeatingSystems = newState.size
      if (totalHeatingSystems > 1) {
        return newState.update(itemId, item => ({ ...defaultVentilationAndHeatingState, ...item, totalHeatingOutput: 0 }))
      }
      return newState
    })
  }, [])

  /** Updates a heating system item with the given ID using the provided partial item data. */
  const handleUpdateHeatingSystemItem = useCallback((id: string, newItem: Partial<HeatingSystemItemDTO>) => {
    setVentilationAndHeatingSystem(prevState => updateItem(prevState, id, newItem))
  }, [])

  /** Removes a heating system item from the list based on the provided item ID. */
  const handleRemoveHeatingSystemItem = useCallback((itemId: string) => {
    setVentilationAndHeatingSystem(prevState => removeItem(prevState, itemId))
  }, [])

  const sortHeatingItemsById = (items: ImmutableMap<string, HeatingSystemItemDTO>) => {
    if (!items) return
    return Array.from(items.entries()).sort(([idA], [idB]) => idA.localeCompare(idB, undefined, { numeric: true }))
  }

  const currentHeatingSystem = useMemo(() => (id: string) => ventilationAndHeatingSystem.get(id), [ventilationAndHeatingSystem])

  return {
    photovoltaicSystem,
    setPhotovoltaicSystem,
    ventilationAndHeatingSystem,
    photovoltaicFeatures,
    setPhotovoltaicFeatures,
    ventilationFeatures,
    setVentilationFeatures,
    currentHeatingSystem,
    handleAddHeatingSystemItem,
    handleRemoveHeatingSystemItem,
    handleUpdateHeatingSystemItem,
    sortHeatingItemsById,
  }
})
