import { FC } from 'react'
import { RoomSelection } from '../RoomSelection'
import Stack from '@mui/material/Stack'
import { VisualStyleSelectionsContainer } from '../VisualStyleSelectionsContainer'

/**
 * @component
 * Controller for staging flow style selection
 * 
 * @example 
 * <StagingFlowController />
 */
export const StagingFlowController: FC = () => {

  return (
    <Stack
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="flex-start"
      gap={2.4}
    >

      {/* LEFT CONTENT (selection of rooms to style) */}
      <RoomSelection />

      {/* MAIN CONTENT (room type selection, room items...) */}
      <VisualStyleSelectionsContainer />

    </Stack>
  )
}
