import React, { Fragment } from 'react'

import Button from 'components/common/Button/Button'
import { Path } from 'constants/router'
import classnames from 'classnames'
import { externalNavigate } from 'utils/helpers'
import { logAnalyticsEvent } from 'utils/analytics'
import styles from './DidYouKnow.module.sass'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @component User Did you know information displayed in Profile page
 * @example
 * <DidYouKnow userData={userMeRequest.data} />
 */
export const DidYouKnow: React.FC = () => {
  const { t } = useTranslation(['profile'])
  const { clientData } = useUserData()

  return (
    <Fragment>
      <div className={styles.borderWrapper}>
        <div className={styles.header}>
          <h2>{t('did_you_know.title')}</h2>
          <div className={classnames(styles.premiumBadge, 'light')}>
            <span className={styles.badgeText}>{t('did_you_know.badge')}</span>
          </div>
        </div>
        <p>{t('did_you_know.description')}</p>
        <Button
          type="primary"
          onClick={() => {
            logAnalyticsEvent('unblock_saas_profile_page', {
              userEmail: clientData?.email,
              organizationId: clientData?.organizationId,
            })
            externalNavigate(Path.PRICING)
          }}
        >
          {t('did_you_know.button')}
        </Button>
      </div>
    </Fragment>
  )
}
