import { EditingCategory, INTEGER_PRODUCTS, ProductFlavor, ProductKind, ProductType, ShootingCategory } from 'constants/product'
import { IntegerProductTuple, ProductCategory, ProductDTO, ProductPriceDTO } from 'models/product'

/** Defines tuple array type for comparing product classification properties with patterns */
type ProductClassificationTuple = [ProductType | null, ProductCategory | null, ProductKind | null]

/** Defines patterns for product classification properties to be compared to. null will equal to any value */
const nonCTProductPatterns: ProductClassificationTuple[] = [
  [null, EditingCategory.EDITING, null],
  [null, EditingCategory.STAGING, null],
  [null, ShootingCategory.REAL_ESTATE, ProductKind.COMMERCIAL_STAGING],
  [null, ShootingCategory.REAL_ESTATE, ProductKind.RESIDENTIAL_STAGING],
  [null, ShootingCategory.REAL_ESTATE, ProductKind.SLIDESHOW],
  [ProductType.VIRTUAL_VISIT, null, ProductKind.MATTERPORT_FLOOR_PLAN_WITH_VISIT],
  [ProductType.FLOOR_PLAN, null, ProductKind.MATTERPORT_FLOOR_PLAN_WITH_VISIT],
  [null, null, ProductKind.MATTERPORT_PHOTO],
  [null, null, ProductKind.MATTERTAG],
  [null, null, ProductKind.FLOOR_PLAN_EDITING_2D],
  [null, null, ProductKind.FLOOR_PLAN_EDITING_3D],
]

// TODO @tomas refactor to be sublinear
/**
 * Returns true for products which require creative to be set
 * @returns After comparing provided product with patterns returns false if product matches any nonCT pattern. Otherwise returns true.
*/
export function isProductWithCT(product: ProductDTO | ProductPriceDTO) {
  if ((product as ProductPriceDTO).flavor && (product as ProductPriceDTO).flavor !== ProductFlavor.STANDARD) return false
  const productTupleArray: ProductClassificationTuple = [product.type || null, product.category || null, product.kind || null]

  return !nonCTProductPatterns.some((pattern) =>
    (!pattern[0] || pattern[0] === productTupleArray[0])
    && (!pattern[1] || pattern[1] === productTupleArray[1])
    && (!pattern[2] || pattern[2] === productTupleArray[2])
  )
}

/**
 * Lazy function which compiles and memoizes a set of keys from integer product tuple array
 * @returns Function which evaluates whether provided combination of product category and product kind is and integer product
*/
function lazyIntegerProducts(integerProductTuples: IntegerProductTuple[]): (category: ProductCategory, kind: ProductKind) => boolean {
  let keys: Set<string>

  return (category: ProductCategory, kind: ProductKind) => {

    if (!keys) keys = new Set<string>(integerProductTuples.map((tuple) => tuple.join('::')))

    return keys.has(`${category}::${kind}`)
  }

}

/**
 * Function to evaluate whether provided category and kind combination is a integer product
 * @returns true if provided combination of product kind and category should have an integer quantity, false otherwise
*/
export const isIntegerProduct = lazyIntegerProducts(INTEGER_PRODUCTS)
