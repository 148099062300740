import { FC, ReactNode, createContext, useContext } from 'react'
import { POST_WATERMARK, VisualFileType } from 'constants/visual'

import { AssignmentStage } from 'constants/assignment'
import { IconType } from 'constants/assets'
import { ProductKind } from 'constants/product'
import { stringArrayAsType } from 'utils/helpers'

const NONE_EDITOR_PRODUCT: 'NONE' = 'NONE'
const VIRTUAL_VISIT_LINK_TYPES: ('BRANDED' | 'UNBRANDED' | 'MLS_READY')[] = ['BRANDED', 'MLS_READY']
const VisualTypeSelectEnum = stringArrayAsType([VisualFileType.RAW, VisualFileType.POST, POST_WATERMARK])
export type VisualTypeSelectEnumType = keyof typeof VisualTypeSelectEnum
const AvailableFileTypes = stringArrayAsType(['WEB', 'HD'])
export type TypeOfAvailableFileTypes = keyof typeof AvailableFileTypes
const AvailableOrderings = stringArrayAsType(['ASCENDING', 'DESCENDING'])
export type TypeOfAvailableOrderings = keyof typeof AvailableOrderings
const AvailableVirtualVisitLinkTypes = stringArrayAsType(VIRTUAL_VISIT_LINK_TYPES)
export type TypeOfAvailableVirtualVisitLinkTypes = keyof typeof AvailableVirtualVisitLinkTypes

const defaultGalleryConstantsValue = {
  NONE_EDITOR_PRODUCT,
  VIRTUAL_VISIT_LINK_TYPES,
  VisualTypeSelectEnum,
  AvailableFileTypes,
  AvailableOrderings,
  AvailableVirtualVisitLinkTypes,
  visualTypeIconMap: {
    [VisualTypeSelectEnum.RAW]: IconType.CAMERA,
    [VisualTypeSelectEnum.POST]: IconType.PAINT_BRUSH,
    [VisualTypeSelectEnum.POST_WATERMARK]: IconType.PHOTO,
  },
  originalTypeMap: {
    [VisualTypeSelectEnum.RAW]: VisualFileType.RAW as VisualFileType.RAW,
    [VisualTypeSelectEnum.POST]: VisualFileType.POST as VisualFileType.POST,
    [VisualTypeSelectEnum.POST_WATERMARK]: POST_WATERMARK as typeof POST_WATERMARK,
  },
  thumbnailTypeMap: {
    [VisualTypeSelectEnum.RAW]: VisualFileType.RAW_THUMB as VisualFileType.RAW_THUMB,
    [VisualTypeSelectEnum.POST]: VisualFileType.POST_THUMB as VisualFileType.POST_THUMB,
    [VisualTypeSelectEnum.POST_WATERMARK]: VisualFileType.POST_THUMB_WATERMARK as VisualFileType.POST_THUMB_WATERMARK,
  },
  webTypeMap: {
    [VisualTypeSelectEnum.RAW]: VisualFileType.RAW_WEB as VisualFileType.RAW_WEB,
    [VisualTypeSelectEnum.POST]: VisualFileType.POST_WEB as VisualFileType.POST_WEB,
    [VisualTypeSelectEnum.POST_WATERMARK]: VisualFileType.POST_WEB_WATERMARK as VisualFileType.POST_WEB_WATERMARK,
  },
  stagesUnlockingAssignCreative: new Set([
    AssignmentStage.MISSION_ORDER_PLACED,
    AssignmentStage.PRE_PRODUCTION,
  ]),
  stagesUnlockingSendAssignmentVisualsToEditor: new Set([
    AssignmentStage.MISSION_ORDER_PLACED,
    AssignmentStage.PRE_PRODUCTION,
    AssignmentStage.AUTOMATED,
    AssignmentStage.WAITING_FOR_CT_TO_ACCEPT,
    AssignmentStage.CREATIVE_BOOKED,
    AssignmentStage.VISUALS_SENT_BY_CT_TO_BKBN,
  ]),
  stagesAfterCTSubmit: new Set([
    AssignmentStage.VISUALS_SENT_TO_CLIENT,
    AssignmentStage.VISUALS_SENT_BY_EDITOR_TO_BKBN,
    AssignmentStage.VISUALS_SENT_BY_CT_TO_BKBN,
    AssignmentStage.VISUALS_SENT_BY_BKBN_TO_EDITOR,
    AssignmentStage.DOCUMENTS_RECEIVED_FROM_PROCESSOR,
    AssignmentStage.DOCUMENTS_SENT_TO_CLIENT,
  ]),
  stagesUnlockingSendAssignmentVisualsToClient: new Set([
    AssignmentStage.VISUALS_SENT_BY_BKBN_TO_EDITOR,
    AssignmentStage.VISUALS_SENT_BY_EDITOR_TO_BKBN,
  ]),
  stagesUnlockingCreativeSendAssignmentVisualsToAdmin: new Set([
    AssignmentStage.MISSION_ORDER_PLACED,
    AssignmentStage.PRE_PRODUCTION,
    AssignmentStage.AUTOMATED,
    AssignmentStage.WAITING_FOR_CT_TO_ACCEPT,
    AssignmentStage.CREATIVE_BOOKED,
  ]),
  stagesUnlockingCreativeManipulateVisuals: new Set([
    AssignmentStage.MISSION_ORDER_PLACED,
    AssignmentStage.PRE_PRODUCTION,
    AssignmentStage.AUTOMATED,
    AssignmentStage.WAITING_FOR_CT_TO_ACCEPT,
    AssignmentStage.CREATIVE_BOOKED,
  ]),
  stagesUnlockingDeliveredAssignment: new Set([
    AssignmentStage.VISUALS_SENT_TO_CLIENT,
  ]),
  productKindsLockingAssignCreative: new Set([
    ProductKind.MATTERPORT_PHOTO,
    ProductKind.MATTERTAG,
    ProductKind.RESIDENTIAL_STAGING,
    ProductKind.COMMERCIAL_STAGING,
  ]),
  productKindsUnlockingMatterportLinks: new Set([
    ProductKind.HD,
    ProductKind.MATTERPORT_FLOOR_PLAN_WITHOUT_VISIT,
  ]),
  stagesUnlockingStagingFlow: new Set([
    AssignmentStage.MISSION_ORDER_PLACED,
    AssignmentStage.PRE_PRODUCTION,
  ]),
  beforeUnloadHandler: (e: BeforeUnloadEvent) => {
    // Cancel the event
    e.preventDefault() // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // Chrome requires returnValue to be set
    e.returnValue = ''
  }
}

type GalleryConstantsValueInterface = typeof defaultGalleryConstantsValue

/** Gallery constants context */
export const GalleryConstantsContext = createContext<GalleryConstantsValueInterface>(defaultGalleryConstantsValue)
/** Gallery constants context hook */
export const useGalleryConstants = (): GalleryConstantsValueInterface => useContext(GalleryConstantsContext)

/** Context provider for gallery constant values */
export const GalleryConstantsContextProvider: FC<{
  children?: ReactNode
}> = ({
  children,
}) => {

    return (
      <GalleryConstantsContext.Provider value={defaultGalleryConstantsValue}>
        {children}
      </GalleryConstantsContext.Provider>
    )
  }
