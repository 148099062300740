import { BuildingLocation, BuildingType, ConsumptionCertificateBasementType, ConsumptionCertificateVentilationType } from 'constants/purchaseFlow'
import { useEffect, useMemo, useState } from 'react'

import { Nullable } from 'models/helpers'
import constate from 'constate'
import { debounce } from 'lodash'

export const [PropertyInformationContextProvider, usePropertyInformation] = constate(() => {
  const [buildingType, setBuildingType] = useState<Nullable<BuildingType>>(null)
  const [buildingLocationType, setBuildingLocationType] = useState<Nullable<BuildingLocation>>(null)
  const [residentialUnit, setResidentialUnit] = useState<number>(1)
  const [commercialArea, setCommercialArea] = useState<string>('')
  const [commercialAreaType, setCommercialAreaType] = useState('')
  const [constructionYear, setConstructionYear] = useState<string>('')
  const [isConstructionConfirmed, setIsConstructionConfirmed] = useState<boolean>(false)
  const [livingSpace, setLivingSpace] = useState<string>('')
  const [yearOfRenovation, setYearOfRenovation] = useState<string>('')
  const [basementType, setBasementType] = useState<ConsumptionCertificateBasementType>(ConsumptionCertificateBasementType.NO_BASEMENT)
  const [ventilationType, setVentilationType] = useState<ConsumptionCertificateVentilationType>(ConsumptionCertificateVentilationType.STANDARD_WINDOW_VENTILATION)

  const [debouncedConstructionYear, setDebouncedConstructionYear] = useState(constructionYear)
  const [debouncedResidentialUnit, setDebouncedResidentialUnit] = useState(residentialUnit)

  useEffect(() => {
    const handler = debounce(() => {
      setDebouncedConstructionYear(constructionYear)
      setDebouncedResidentialUnit(residentialUnit)
    }, 500)

    handler()

    return () => {
      handler.cancel()
    }
  }, [constructionYear, residentialUnit])

  const isShowConstructionNote = useMemo(() =>
    debouncedResidentialUnit < 5
    && parseInt(debouncedConstructionYear) < 1978
    && debouncedConstructionYear.length >= 4,
    [debouncedConstructionYear, debouncedResidentialUnit])

  return {
    buildingType,
    setBuildingType,
    buildingLocationType,
    setBuildingLocationType,
    residentialUnit,
    setResidentialUnit,
    constructionYear,
    setConstructionYear,
    basementType,
    setBasementType,
    ventilationType,
    setVentilationType,
    livingSpace,
    setLivingSpace,
    yearOfRenovation,
    setYearOfRenovation,
    commercialArea,
    setCommercialArea,
    commercialAreaType,
    setCommercialAreaType,
    isConstructionConfirmed,
    setIsConstructionConfirmed,
    isShowConstructionNote
  }
})
