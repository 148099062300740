import { BLUE_100, BLUE_400, DEEP_SPACE_BLACK, ORANGE_100, ORANGE_400 } from 'constants/styling/theme'
import { Collapse, Grid, Stack } from '@mui/material'
import { FC, Fragment, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { OrganizationKindsType, OrganizationProductKinds, ProductKind } from 'constants/product'
import { Trans, useTranslation } from 'react-i18next'
import { getDateString, getTimeString, isSameTimezone } from 'utils/time'
import { standardEnterEffect, standardExitEffect, standardTimeoutConfig } from 'utils/animations'
import { usePurchaseFlowConfig, usePurchaseFlowOrderMeta, useTargetOrderUser } from 'components/pages/PurchaseFlow/_main/contexts'

import { Country } from 'constants/country'
import { InputWrap } from 'components/common/InputWrap'
import { MUIDatePicker } from 'components/common/MUIDatePicker'
import { MeetingInstructionsAddressField } from './MeetingInstructionsAddressField.component'
import { MeetingInstructionsContactPersonCheckbox } from './MeetingInstructionsContactPersonCheckbox.component'
import { PhoneInput } from 'components/common/PhoneInput'
import moment from 'moment'
import styles from './MeetingInstructionsFields.module.sass'
import { useOnUnmountWithDeps } from 'utils/helpers'

interface Props {
  kind: OrganizationKindsType
}

interface FieldsTouched {
  name: boolean
  phone: boolean
  address: boolean
  date: boolean
  email: boolean
}

const initialTouchedFields: FieldsTouched = {
  name: false,
  phone: false,
  address: false,
  date: false,
  email: false,
}

/** 
 * Renders Organization Meeting instructions fields
 * field_name * === (Required field)
 *
 * ProductKind.MEETING_ON_SITE
 * - Date *
 * - Time *
 * - Name of contact person *
 * - Contact person telephone number *
 * - Contact person email address
 * - Instructions, details or comments
 *
 * ProductKind.ORGANIZATION_THIRD_PARTY
 * - Name of contact person *
 * - Contact person telephone number *
 * - Contact person email address
 * - Instructions, details or comments
 *
 * ProductKind.KEYS_PICKUP
 * - Date *
 * - Time *
 * - Name of contact person *
 * - Contact person telephone number *
 * - Contact person email address
 * - Address *
 * - Instructions, details or comments
 *
 * - When Date is deleted by user, Time is deleted by us as well
 *
 * DEPENDENCIES:
 * - TargetOrderUserProvider
 * - InstructionStepStoreProvider
 * - MeetingInstructionsContextProvider
 * 
 * @example
 * <MeetingInstructionsField
 *   field={field}
 *   instructionOption={instructionOption}
 *   instructionTypeKey={InstructionType.ORGANIZATION}
 *   disabledFields={false}
 * />
*/
export const MeetingInstructionsFields: FC<Props> = ({ kind }) => {
  const { t } = useTranslation(['instruction_option_field', 'order', 'step_address'])

  const { targetUser } = useTargetOrderUser()
  const { catalogueTimezone, selectedCountryCode, fallbackCountryCode } = usePurchaseFlowConfig()
  const {
    meetingInformation,
    minOrderDateTime,
    currentFieldErrors,
    meetingInfoFillInCheckedMap,
    phoneInputValidationError,
    isCreativeExtraServiceSelected,
    selectedMeetingType,
    updateMeetingInformation,
    setPhoneInputValidationError,
  } = usePurchaseFlowOrderMeta()

  const userTimezone = useMemo(() => targetUser.timezone, [targetUser.timezone])
  const isEqualTimezone = useMemo(() => !!userTimezone && !!catalogueTimezone && isSameTimezone(userTimezone, catalogueTimezone), [userTimezone, catalogueTimezone])

  const fields = useMemo(() => {
    return {
      ...meetingInformation[kind]
    }
  }, [kind, meetingInformation])

  const [touchedFields, setTouchedFields] = useState<FieldsTouched>(initialTouchedFields)

  const touchField = useCallback((key: keyof FieldsTouched) => {
    setTouchedFields((prev) => ({
      ...prev,
      [key]: true
    }))
  }, [])

  // Prefill default Date if empty
  const datePrefilled = useRef(false)
  useLayoutEffect(() => {
    if (kind === ProductKind.ORGANIZATION_THIRD_PARTY) return
    if (!catalogueTimezone) return
    if (fields.date !== undefined) return
    if (datePrefilled.current) return

    const defaultDate = moment(minOrderDateTime).add(2, 'days')

    datePrefilled.current = true
    updateMeetingInformation(kind, { date: defaultDate })
  }, [catalogueTimezone, fields, kind, minOrderDateTime, updateMeetingInformation])

  // If phone is invalid when component is destroyed, clear the field to prevent it defaulting to wrong country code
  useOnUnmountWithDeps(() => {
    if (fields.phone && phoneInputValidationError) {
      updateMeetingInformation(kind, { phone: '' })
    }
  }, [fields.phone, phoneInputValidationError, kind, updateMeetingInformation])


  if (!OrganizationProductKinds.has(kind) || !catalogueTimezone) return null

  return (
    <Grid container spacing={2} rowGap={2} className={styles.fieldGrid}>

      {/* CONTACT PERSON PRE-FILL CHECKBOX, DATE & TIME rendered for MEETING_ON_SITE & KEYS_PICKUP only */}
      {kind !== ProductKind.ORGANIZATION_THIRD_PARTY &&
        <Fragment>

          {!!catalogueTimezone && !isEqualTimezone &&
            <Grid item xs={12}>
              <Stack
                gap={1}
                sx={{
                  background: ORANGE_100,
                  border: `1px solid ${ORANGE_400}`,
                  color: DEEP_SPACE_BLACK,
                  padding: '1rem',
                  borderRadius: '8px',
                  marginTop: '.5rem'
                }}
              >
                <span>
                  {t('order:step_instruction:timezone_instruction_differs', { userTimezone, locationTimezone: catalogueTimezone })}
                </span>
                <span>
                  <Trans t={t} i18nKey="order:step_instruction:timezone_instruction_location" values={catalogueTimezone}>
                    <strong className={styles.normalSize}></strong>
                  </Trans>
                </span>
              </Stack>
            </Grid>
          }

          <Grid item xs={12}>
            <MeetingInstructionsContactPersonCheckbox meetingType={kind} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputWrap
              label={t(!isCreativeExtraServiceSelected ? 'instruction_option_field:DATE_HINT' : 'instruction_option_field:DATE')}
              className={styles.dateInput}
              error={currentFieldErrors.date}
              showError={touchedFields.date}
              required
            >
              <MUIDatePicker
                sx={{
                  width: '100%',
                  borderRadius: '12px',
                }}
                timezone={catalogueTimezone}
                value={fields.date || null}
                onChange={(date) => updateMeetingInformation(kind, { date: date || undefined })}
                timeSteps={{ minutes: 15 }}
                disablePast
                onAccept={() => touchField('date')}
                format='DD/MM/YYYY HH:mm'
                minDateTime={moment(minOrderDateTime)}
                ampm={false}
                isError={touchedFields.date && !!currentFieldErrors.date}
                onInputBlur={() => touchField('date')}
              />

              {!!fields.date && fields.date instanceof moment && !!catalogueTimezone && !isEqualTimezone &&
                <Collapse
                  appear={true}
                  in={!!fields.date && fields.date instanceof moment && !!catalogueTimezone && !isEqualTimezone}
                  onEnter={standardEnterEffect}
                  onExit={standardExitEffect}
                  timeout={standardTimeoutConfig}
                >
                  {(() => {
                    return (
                      <Stack
                        gap={1}
                        sx={{
                          background: BLUE_100,
                          border: `1px solid ${BLUE_400}`,
                          color: DEEP_SPACE_BLACK,
                          padding: '1rem',
                          borderRadius: '8px',
                          marginTop: '.5rem'
                        }}
                      >
                        <span className={styles.line}>
                          {getDateString(fields.date.toISOString(true), { timezone: catalogueTimezone })} - {getTimeString(fields.date.toISOString(true), { timezone: catalogueTimezone })} ({catalogueTimezone} {t('order:step_instruction:timezone')})
                        </span>
                        <span className={styles.line}>
                          {getDateString(fields.date.clone().tz(userTimezone).toISOString(true), { timezone: userTimezone })} - {getTimeString(fields.date.clone().tz(userTimezone).toISOString(true), { timezone: userTimezone })} ({userTimezone} {t('order:step_instruction:timezone')})
                        </span>
                      </Stack>
                    )
                  })()}
                </Collapse>
              }

            </InputWrap>
          </Grid>

        </Fragment>
      }

      {/* NAME */}
      <Grid item xs={12} sm={6}>
        <InputWrap
          label={selectedMeetingType ? t(`instruction_option_field:${selectedMeetingType}:NAME`) : ''}
          error={currentFieldErrors.name}
          showError={touchedFields.name}
          required
        >
          <input
            type="text"
            value={fields.name}
            onChange={(e) => updateMeetingInformation(kind, { name: e.target.value })}
            onBlur={() => touchField('name')}
          />
        </InputWrap>
      </Grid>

      {/* PHONE */}
      <Grid item xs={12} sm={6}>
        <InputWrap
          label={selectedMeetingType ? t(`instruction_option_field:${selectedMeetingType}:PHONE`) : ''}
          error={currentFieldErrors.phone}
          showError={touchedFields.phone}
          required
        >
          <PhoneInput
            value={fields.phone}
            onChange={(value: string) => {
              updateMeetingInformation(kind, { phone: value })
            }}
            onValidityChange={(isValid, validationError) => setPhoneInputValidationError(!isValid ? validationError : undefined)}
            initialCountry={selectedCountryCode || fallbackCountryCode || Country.CH}
            onBlur={() => touchField('phone')}
          />
        </InputWrap>
      </Grid>

      {/* EMAIL */}
      <Grid item xs={12} sm={6}>
        <InputWrap
          label={selectedMeetingType ? t(`instruction_option_field:${selectedMeetingType}:EMAIL`) : ''}
          error={currentFieldErrors.email}
          showError={touchedFields.email}
          fieldNote={t(`order:step_instruction:${kind !== ProductKind.ORGANIZATION_THIRD_PARTY && meetingInfoFillInCheckedMap[kind] ? 'no_email_hint' : 'contact_person_email_hint'}`)}
        >
          <input
            type="email"
            value={typeof fields.email === 'string' ? fields.email : ''}
            onChange={(e) => updateMeetingInformation(kind, { email: e.target.value })}
            onBlur={() => touchField('email')}
          />
        </InputWrap>
      </Grid>

      {/* ADDRESS */}
      {kind === ProductKind.KEYS_PICKUP &&
        <Grid item xs={12}>
          <InputWrap
            label={t('instruction_option_field:ADDRESS')}
            error={currentFieldErrors.address}
            fieldNote={!currentFieldErrors.address ? fields.address?.formatted_address : undefined}
            showError={touchedFields.address}
            required
          >
            <MeetingInstructionsAddressField
              selectedAddress={fields.address}
              onAddressChange={(selectedPlace) => updateMeetingInformation(kind, { address: selectedPlace || undefined })}
              onInputBlur={() => touchField('address')}
            />
          </InputWrap>
        </Grid>
      }

    </Grid>
  )
}
