import { Box, CircularProgress, Stack } from '@mui/material'
import { PFClientSelectionError, usePurchaseFlowConfig, useTargetOrderUser } from 'components/pages/PurchaseFlow/_main/contexts'
import { useEffect, useMemo } from 'react'

import { DEBOUNCE_TIMEOUT } from 'constants/application'
import { InputWrap } from 'components/common/InputWrap'
import { MUIButton } from 'components/common/MUIButton'
import { MUISwitch } from 'components/common/MUISwitch'
import { StepWrapper } from 'components/pages/PurchaseFlow/common/StepWrapper'
import TransitionAppear from 'components/common/TransitionAppear/TransitionAppear'
import { UserIsPlatformUserClient } from 'utils/typeguards'
import { getDefaultErrorMessage } from 'utils/forms/getDefaultErrorMessage'
import { isEmail } from 'utils/forms'
import { logAnalyticsEvent } from 'utils/analytics'
import { useAuth0 } from 'utils/auth'
import { useDebouncedEffect } from 'utils/helpers'
import { useTranslation } from 'react-i18next'

/**
 * Step for admins to select on whom's behalf they're ordering and switch automation
 * @example <ClientStepController />
 */
export const ClientStepController: React.FC = () => {
  const { user } = useAuth0()
  const { t } = useTranslation(['purchase_flow'])
  const {
    adminSelectedUserEmail,
    getPFClient,
    getPFOrganization,
    clientSelectionError,
    setAdminSelectedUserEmail
  } = useTargetOrderUser()
  const { enableSkipAutomation, setEnableSkipAutomation } = usePurchaseFlowConfig()

  /** Computed error for field taking in account both validation AND fetch errors */
  const fieldError = useMemo(() => {
    const requiredError = adminSelectedUserEmail === ''
    const emailFormatError = isEmail({ value: adminSelectedUserEmail })

    if (requiredError) return getDefaultErrorMessage('required')
    if (emailFormatError && emailFormatError !== '') return emailFormatError

    if (!clientSelectionError || clientSelectionError === PFClientSelectionError.NO_DATA) return undefined

    return t(`client_step.client_error.${clientSelectionError}`)
  }, [adminSelectedUserEmail, clientSelectionError, t])

  /**
   * Fetches user client when email changes and if user is client and has Org., feches Org. data as well
  */
  useDebouncedEffect(
    () => {
      if (!adminSelectedUserEmail || fieldError) return

      getPFClient.mutate(
        { email: adminSelectedUserEmail },
        {
          onSuccess: (response) => {
            if (!UserIsPlatformUserClient(response.data)) return
            if (!response.data.organizationId) return

            getPFOrganization.mutate({ organizationId: response.data.organizationId })
          }
        }
      )
    },
    [fieldError, adminSelectedUserEmail, getPFClient.mutate, getPFOrganization.mutate],
    DEBOUNCE_TIMEOUT
  )

  // log entering this page
  useEffect(() => {
    logAnalyticsEvent('enters_order_client_selection_screen', {})
  }, [])

  return (
    <TransitionAppear visible>
      <StepWrapper
        title={t('client_step.section_title')}
        subtitle={t('client_step.section_description')}
      >

        <InputWrap
          label={t('client_step.client_field_label')}
          error={fieldError}
          showError
        >
          <Stack direction="row" gap={2} maxWidth="800px" alignItems="center">

            <input
              type="email"
              value={adminSelectedUserEmail}
              placeholder={t('client_step.client_field_placeholder')}
              onChange={e => {
                setAdminSelectedUserEmail(e.target.value)
                getPFClient.reset()
              }}
              autoFocus
            />

            {(getPFClient.isPending || getPFOrganization.isPending) &&
              <Box>
                <CircularProgress size={25} />
              </Box>
            }

            {!!user?.email &&
              <Box flex="0 0 auto">
                <MUIButton
                  type="secondaryBorder"
                  onClick={_ => {
                    setAdminSelectedUserEmail(user.email)
                    getPFClient.reset()
                  }}
                  disabled={adminSelectedUserEmail === user.email}
                >
                  {t('client_step.select_myself')}
                </MUIButton>
              </Box>
            }

          </Stack>
        </InputWrap>

        <Box marginTop={2}>
          <MUISwitch
            label={t('client_step.skip_automation')}
            labelPlacement="start"
            checked={enableSkipAutomation}
            onChange={() => setEnableSkipAutomation((prevState) => !prevState)}
          />
        </Box>

      </StepWrapper>
    </TransitionAppear>
  )
}
