export const handleScroll = (datePickerTimeDiv: HTMLDivElement | null) => {
  if (!datePickerTimeDiv) return

  setTimeout(() => {
    const timeListItems = datePickerTimeDiv.querySelectorAll('li.react-datepicker__time-list-item')
    const inputValue = datePickerTimeDiv.querySelector('input')?.value

    if (inputValue) {
      const targetTime = Array.from(timeListItems as NodeListOf<HTMLElement>).find(el => el.innerText.startsWith(inputValue))
      if (targetTime?.parentElement) {
        targetTime.parentElement.scrollTop = targetTime.offsetTop - targetTime.parentElement.offsetTop
      }
    }
  }, 500)
}
