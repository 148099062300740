import { CurrentActionType, ToolBarType, useClientGallery } from '../_main/contexts'
import { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useGalleryAdditionalVisuals, useGalleryAssignment, useGalleryVisualsDownloadArchive } from '../../contexts'

import { AnalyticsEvent } from 'utils/analytics'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { MUIButton } from 'components/common/MUIButton'
import { MUITooltip } from 'components/common/MUITooltip'
import { Path } from 'constants/router'
import { WorkspaceRole } from 'models/user'
import { externalNavigate } from 'utils/helpers'
import { useUserData } from 'components/contexts/UserDataContext'
import { useVisualsEditingSection } from '../../../common'
import { useWorkspacesProfileContext } from 'components/pages/Client/WorkspacesProfile/_main/WorkspacesProfile.context'

/**
 * Client Visuals Editing Express component
 *
 * @example
 * <ClientVisualsEditingExpress />
 */

export const ClientVisualsEditingExpress: FC = () => {
  const { t } = useTranslation(['gallery'])

  const { hasUserActiveSubscription } = useUserData()
  const { selectedAdditionalVisualsCount } = useGalleryAdditionalVisuals()
  const { initiateArchiveDownload } = useGalleryVisualsDownloadArchive()
  const { workspaceDataOfUser } = useWorkspacesProfileContext()
  const {
    toolBarType,
    setCurrentActionType
  } = useClientGallery()
  const {
    templateId,
    isTemplateLoading,
    currentWorkspaceData,
    isSubmitExpressDisabled,
    onSubmitExpress
  } = useVisualsEditingSection()
  const { logGalleryEvent } = useGalleryAssignment()

  const isWorkspaceAdmin = workspaceDataOfUser?.role === WorkspaceRole.ADMIN

  const expressTooltipTitle = useMemo(() => {
    let title
    if (!hasUserActiveSubscription) {
      title = 'only_for_subscription'
    } else if (isWorkspaceAdmin) {
      title = 'visual_editing.no_templates_des_admin'
    } else {
      title = 'visual_editing.no_templates_des_member'
    }

    if (!templateId) {
      return (<Trans
        t={t}
        i18nKey={title}
      >
        &nbsp;
      </Trans>)
    }
    return t('worspace_template', { workspace: currentWorkspaceData?.name })
  }, [currentWorkspaceData?.name, hasUserActiveSubscription, isWorkspaceAdmin, t, templateId])

  const handleSubmitExpress = useCallback(() => {
    if (!hasUserActiveSubscription) {
      if (toolBarType === ToolBarType.DOWNLOAD) {
        logGalleryEvent(AnalyticsEvent.GALLERY_SAAS_UPSELL_DOWNLOAD_WITH_TEMPLATE)
      } else {
        logGalleryEvent(AnalyticsEvent.GALLERY_SAAS_UPSELL_FLOATING_BAR_APPLY_TEMPLATE)
      }
      return externalNavigate(Path.PRICING)
    }

    if (selectedAdditionalVisualsCount > 0) {
      setCurrentActionType(CurrentActionType.APPLY_TEMPLATE_AND_DOWNLOAD)
      initiateArchiveDownload()
      return
    }

    // This event is fired for both edit and download toolbar types as it results into the same result
    logGalleryEvent(AnalyticsEvent.CLICK_APPLY_TEMPLATE_TO_ALL_PURCHASED)

    onSubmitExpress()
  }, [hasUserActiveSubscription, initiateArchiveDownload, logGalleryEvent, onSubmitExpress, selectedAdditionalVisualsCount, setCurrentActionType, toolBarType])

  return (
    <MUITooltip
      placement='top'
      arrow={false}
      content={expressTooltipTitle}
    >
      <MUIButton
        type='darkPrimary'
        disabled={isSubmitExpressDisabled}
        onClick={handleSubmitExpress}
        isLoading={isTemplateLoading}
        startIcon={toolBarType !== ToolBarType.EDITING && <EditOutlinedIcon />}
      >
        {toolBarType === ToolBarType.DOWNLOAD ? t('download_with_template') : t('apply_template')}
      </MUIButton>
    </MUITooltip>

  )
}
