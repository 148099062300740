import { PurchaseFlowStepKey, usePurchaseFlowUtilities } from '../../../_main/contexts'

import { DynamicFlowStep } from 'components/common/DynamicFlow'
import { PurchaseFlowPaymentIntentContextProvider } from './contexts'
import { ValidationController } from './Validation.controller'

/**
 * @component 
 * ValidationStep module displays the validation step also know as order recap.
 *  
 * dependencies: 
 * - PurchaseFlowPaymentIntentContextProvider
 * - SavedStripePaymentMethodsContextProvider
 * - PurchaseFlowPaymentStatusContextProvider
 * 
 * @example
 * <ValidationStep />
 */
export const ValidationStep: React.FC = () => {

  const { isRecapStepValid } = usePurchaseFlowUtilities()

  return (
    <DynamicFlowStep
      stepKey={PurchaseFlowStepKey.VALIDATION}
      isValid={isRecapStepValid}
    >
      <PurchaseFlowPaymentIntentContextProvider>

        <ValidationController />

      </PurchaseFlowPaymentIntentContextProvider>
    </DynamicFlowStep>
  )
}
