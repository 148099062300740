import { Nullable, NullableStringIndexSignature } from 'models/helpers'

import { DownloadImage } from 'models/redux'

export function getDownloadErrorsString(downloadVisualsWithErrorEntries: [string, Nullable<NullableStringIndexSignature<DownloadImage>>][], thumbnailType: string) {
  const downloadErrorsString = downloadVisualsWithErrorEntries.map(([key, allTypes]) => {
    const img = allTypes?.[thumbnailType]
    if (!img) return ''
    return img.request.error
  })

  return downloadErrorsString
}
