/** Measurement property type */
export enum MeasurementOnSitePropertyType {
  APARTMENT_SINGLE_FLOORS = 'APARTMENT_SINGLE_FLOORS',
  SINGLE_FAMILY_HOUSE = 'SINGLE_FAMILY_HOUSE',
  TWO_FAMILY_HOUSE = 'TWO_FAMILY_HOUSE',
  APARTMENT_BUILDING = 'APARTMENT_BUILDING',
}

export enum MeasurementOnSiteAdditionalInformationType {
  DOCUMENTS_FOR_THE_BANK = 'DOCUMENTS_FOR_THE_BANK',
  RENTAL_DISPUTE = 'RENTAL_DISPUTE',
  BUY_OR_SELL = 'BUY_OR_SELL',
  PROPERTY_TAX_RETURN = 'PROPERTY_TAX_RETURN',
  OTHER = 'OTHER',
}

/** Measurement on site request DTO */
export interface MeasurementOnSiteRequestDTO {
  /** Property type for measurement on site */
  propertyType: MeasurementOnSitePropertyType,
  /** Surface area of the property */
  surfaceArea: number,
  /** Number of rooms in the property */
  numberOfRooms: number,
  /** Number of floors in the property */
  numberOfFloors: number,
  /** Number of residential units */
  numberOfResidentialUnits?: number,
  /** Additional area for basement */
  basementAreaToMeasure: boolean,
  /** Additional area for attic */
  atticAreaToMeasure: boolean,
  /** Additional information */
  additionalInformation?: MeasurementOnSiteAdditionalInformationType[],
  /** Comment */
  comment?: string,
}
