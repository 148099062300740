import React, { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { EditorOption } from '../EditorOption'
import { EditorOptionButton } from '../EditorOptionButton'
import { ExportOption } from 'constants/misc'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined'
import { useVisualsEditing } from 'components/pages/VisualsEditing/VisualsEditing.context'

/**
 * @component EditorOptionExporting 
 * @example
 * <EditorOptionExporting />
 */

export const EditorOptionExporting: React.FC = () => {
  const { t } = useTranslation(['visuals_editing'])

  const {
    exportOption,
    setExportOption,
    handleEditingOptionChange
  } = useVisualsEditing()

  const onSelectExportOption = (option: ExportOption) => {
    setExportOption(option)
  }

  const resetExportOption = () => {
    setExportOption(ExportOption.ORIGINAL)
  }

  return (
    <EditorOption
      title={t('editor_options.exporting_option_title')}
      hint={t('editor_options.exporting_option_hint')}
      data-tip
      data-for="visual_editing_exporting_description"
      tooltipData={
        <Trans
          t={t}
          i18nKey="editor_options.exporting_description"
        />
      }
      isActive={!(exportOption === ExportOption.ORIGINAL)}
      onResetClick={resetExportOption}
      button={
        <Fragment>
          <EditorOptionButton
            title={t('editor_options.for_the_web')}
            icon={<LanguageOutlinedIcon fontSize='inherit' />}
            isActive={exportOption === ExportOption.WEB}
            onClick={() => handleEditingOptionChange(() => onSelectExportOption(ExportOption.WEB))}
          />
          <EditorOptionButton
            title={t('editor_options.for_the_print')}
            icon={<PrintOutlinedIcon fontSize='inherit' />}
            isActive={exportOption === ExportOption.PRINT}
            onClick={() => handleEditingOptionChange(() => onSelectExportOption(ExportOption.PRINT))}
          />
        </Fragment>
      }
    />
  )
}
