import { WorkspaceRole } from 'models/user'

export interface MemberRoleItem {
  role: WorkspaceRole,
  title: string,
  subTitle: string
}

export interface MemberAccessItem {
  isRestricted: boolean,
  title: string,
  subTitle: string
}

export const memberTableHead = ['name', 'role_title', 'access', '']

export enum RestrictedAccessOption {
  FULL_ACCESS = 'full_access',
  RESTRICTED_ACCESS = 'restricted_access',
}

export const accessDropdownItems: MemberAccessItem[] = [
  {
    isRestricted: false,
    title: RestrictedAccessOption.FULL_ACCESS,
    subTitle: 'full_access_description'
  },
  {
    isRestricted: true,
    title: RestrictedAccessOption.RESTRICTED_ACCESS,
    subTitle: 'restricted_access_description'
  }
]

export const addMemebersAccessDropdownItems: MemberAccessItem[] = [
  {
    isRestricted: false,
    title: RestrictedAccessOption.FULL_ACCESS,
    subTitle: 'add_members_full_access_description'
  },
  {
    isRestricted: true,
    title: RestrictedAccessOption.RESTRICTED_ACCESS,
    subTitle: 'add_members_restricted_access_description'
  }
]

export const memberRoleItems: MemberRoleItem[] = [
  {
    role: WorkspaceRole.ADMIN,
    title: 'role.ADMIN',
    subTitle: 'admin_description'
  },
  {
    role: WorkspaceRole.MEMBER,
    title: 'role.MEMBER',
    subTitle: 'member_description'
  }
]
