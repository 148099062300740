import { PlanPeriod, Plans, PlansInfo, customerReviews } from 'constants/pricing'
import { useMemo, useState } from 'react'

import { ClientReference } from '../ClientReference'
import { CustomerReviewCard } from '../CustomerReviewCard'
import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { MUISwitch } from 'components/common/MUISwitch'
import { PlanCard } from '../PlanCard'
import { UpcomingFeature } from '../UpcomingFeature'
import classNames from 'classnames'
import styles from './Pricing.module.sass'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'

export const PricingController: React.FC = () => {
  const { t } = useTranslation(['pricing_page'])
  const [planPeriod, setPlanPeriod] = useState(PlanPeriod.MONTHLY)
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)
  const isMonthly = useMemo(() => planPeriod === PlanPeriod.MONTHLY, [planPeriod])
  const isYearly = useMemo(() => planPeriod === PlanPeriod.YEARLY, [planPeriod])

  return (
    <div className={classNames('page', styles.pricingPage, { [styles.mobile]: isMobileView })}>
      <div className={styles.pricingContent}>
        <div className={styles.header}>
          <div className={styles.title}>{t('backbone_plan')}</div>
          <div className={styles.subTitle}>{t('sub_title')}</div>
        </div>

        <div className={styles.planPeriod}>
          <span className={classNames({ [styles.active]: isMonthly })}>{t('monthly')}</span>
          <MUISwitch
            className={styles.planPeriodSwitch}
            checked={isYearly}
            onChange={() => setPlanPeriod(isMonthly ? PlanPeriod.YEARLY : PlanPeriod.MONTHLY)}
          />
          <span className={classNames({ [styles.active]: isYearly })}>{t('yearly')}</span>
          <span className={styles.discountLabel}>{t('yearly_discount')}</span>
        </div>

        <div className={classNames(styles.plans, { [styles.mobile]: isMobileView })}>
          {Object.values(Plans).map((key) =>
            <PlanCard key={key} plan={key} info={PlansInfo[key]} planPeriod={planPeriod} />
          )}
        </div>

        <ClientReference />

        <div className={classNames(styles.reviewCardWrap, { [styles.mobile]: isMobileView })}>
          {customerReviews.map((review, idx) =>
            <CustomerReviewCard key={idx} review={review} />
          )}
        </div>
      </div>

      <UpcomingFeature />
    </div>
  )
}
