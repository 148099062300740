import { BEIGE_300, BEIGE_600 } from 'constants/styling/theme'
import { PurchaseFlowProductOption, usePurchaseFlowProducts } from 'components/pages/PurchaseFlow/_main/contexts/PurchaseFlowProducts.context'
import { bigFromFee, formatPrice, valueAfterDiscount, valueMultiplied } from 'utils/price'
import { useCart, usePurchaseFlowConfig } from 'components/pages/PurchaseFlow/_main/contexts'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { ProductItemSummary } from './ProductItemSummary'
import React from 'react'
import { TransitionGroup } from 'react-transition-group'
import Typography from '@mui/material/Typography'
import { standardTimeoutConfig } from 'utils/animations'
import { useTranslation } from 'react-i18next'

/**
 * @component List of selected products with prices
 * @example <ProductList />
 */
export const ProductList: React.FC = () => {
  const { t } = useTranslation(['order'])

  const { catalogueCurrency, catalogueDiscount } = usePurchaseFlowConfig()
  const { selectedProductOptions } = usePurchaseFlowProducts()
  const { cartProductList } = useCart()

  const getOptionPriceAfterDiscount = (option: PurchaseFlowProductOption) => {
    return valueAfterDiscount(option.feePrice, catalogueDiscount)
  }

  return (
    <TransitionGroup component={null}>
      {cartProductList.length > 0
        ? (
          <Collapse in={cartProductList.length > 0} timeout={standardTimeoutConfig}>
            <List disablePadding>
              <TransitionGroup component={null}>
                {cartProductList.map(({ id, quantity, feePrice }) =>
                  <Collapse key={id} in={!!quantity && quantity > 0} timeout={standardTimeoutConfig}>
                    <ListItem disablePadding>
                      <ListItemText
                        disableTypography
                        primary={
                          <ProductItemSummary
                            name={t(`product:p_${id}`)}
                            price={formatPrice(valueMultiplied(feePrice, quantity ?? 1), catalogueCurrency)}
                            quantity={quantity}
                            priceAfterDiscount={
                              catalogueDiscount.gt(0)
                                ? formatPrice(
                                  valueMultiplied(valueAfterDiscount(feePrice, catalogueDiscount), quantity ?? 1),
                                  catalogueCurrency
                                )
                                : undefined
                            }
                          />
                        }
                        secondary={
                          <TransitionGroup component={null}>
                            {!!selectedProductOptions[id] && Object.values(selectedProductOptions[id]).length > 0 &&
                              Object.values(selectedProductOptions[id]).map(option =>
                                option.quantity > 0 && (
                                  <Collapse key={option.id} in={option.quantity > 0} timeout={standardTimeoutConfig}>
                                    <ProductItemSummary
                                      key={option.id}
                                      type='secondary'
                                      quantity={option.quantity}
                                      name={t(`product:p_${option.id}`)}
                                      price={formatPrice(
                                        valueMultiplied(bigFromFee(option.feePrice), option.quantity),
                                        catalogueCurrency
                                      )}
                                      priceAfterDiscount={
                                        catalogueDiscount.gt(0)
                                          ? formatPrice(
                                            valueMultiplied(getOptionPriceAfterDiscount(option), option.quantity),
                                            catalogueCurrency
                                          )
                                          : undefined
                                      }
                                    />
                                  </Collapse>
                                )
                              )}
                          </TransitionGroup>
                        }
                      />
                    </ListItem>
                  </Collapse>
                )}
              </TransitionGroup>
            </List>

          </Collapse>
        )
        : (
          <Collapse in={cartProductList.length === 0} timeout={standardTimeoutConfig}>

            {/** NO PRODUCTS SELECTED */}
            <BorderBoxWrapper
              padding={1}
              elevation='none'
              borderColor={BEIGE_600}
              backgroundColor={BEIGE_300}
            >

              <Typography variant='text-sm'>
                {t('cart.no_product_selected')}
              </Typography>

            </BorderBoxWrapper>

          </Collapse>
        )
      }
    </TransitionGroup>
  )
}
