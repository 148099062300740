import { FC, KeyboardEvent, useCallback, useState } from 'react'

import { Badge } from 'components/common/Badges'
import Button from 'components/common/Button/Button'
import ClearIcon from '@mui/icons-material/Clear'
import { KeyboardEventKey } from 'constants/misc'
import { Nullable } from 'models/helpers'
import classnames from 'classnames'
import { logAnalyticsEvent } from 'utils/analytics'
import styles from './KeywordInput.module.sass'
import { useGalleryAssignment } from 'components/pages/Gallery/_main/contexts/GalleryAssignment.context'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

const KEYWORD_CHAR_LIMIT = 100
const VALID_DELIMITERS = /[,;\r\t\n]/g

enum KeywordError {
  INVALID_KEYWORD = 'INVALID_KEYWORD',
  CHAR_LIMIT = 'CHAR_LIMIT',
  CONTAINS_DELIMITERS = 'CONTAINS_DELIMITERS',
  MAX_WORDS = 'MAX_WORDS',
}

const _checkKeywordForErrors = (string: Nullable<string>, maxWords: number = 5): string | undefined => {
  if (typeof string !== 'string') return KeywordError.INVALID_KEYWORD
  if (string.length > KEYWORD_CHAR_LIMIT) return KeywordError.CHAR_LIMIT
  if (!!string.match(VALID_DELIMITERS)) return KeywordError.CONTAINS_DELIMITERS
  if (string.trim().split(' ').length > maxWords) return KeywordError.MAX_WORDS
  return undefined
}

const _isValidKeyword = (string: Nullable<string>, maxWords: number = 5) => {
  return !_checkKeywordForErrors(string, maxWords)
}

const recognizedSpecialKeys: Set<string> = new Set([
  KeyboardEventKey.ENTER,
  KeyboardEventKey.BACKSPACE,
  KeyboardEventKey.COMMA,
  KeyboardEventKey.SEMICOLON
])

interface Props {
  keywords: string[]
  autofocus?: boolean
  maxKeywords?: number
  placeholder?: string
  fieldInfo?: string
  className?: string
  /** If true, keywords left hanging in input will be ignored and deleted (default: false) */
  forceKeywordConfirmation?: boolean
  onChange: (keywords: string[]) => void
}

/**
 * Shows input to type/paste keywords in and badges for all validated keywords
 * 
 * @example
 * <KeywordInput
 *   maxKeywords={5}
 *   keywords={[]}
 *   onChange={(keywords) => console.log(keywords)}
 * />
 */
export const KeywordInput: FC<Props> = ({
  keywords,
  maxKeywords = Number.POSITIVE_INFINITY,
  forceKeywordConfirmation = false,
  autofocus = true,
  fieldInfo,
  className,
  placeholder,
  onChange,
}) => {

  const { t } = useTranslation(['keyword_input'])
  const { currentUserWorkspace, baseUserData } = useUserData()
  const { assignmentData } = useGalleryAssignment()

  const [inputValue, setInputValue] = useState('')
  const [error, setError] = useState<Nullable<string>>(null)

  /** Handles parsing and triggering onChange callback */
  const handleParseKeywords = useCallback((inputString: string, isForceConfirm = false) => {
    setError(null)

    const keywordString = inputString
    const existingKeywords = [...keywords]

    // Is confirmed by ENTER/COMMA/SEMI; paste is handled below
    if (isForceConfirm) {

      // ignore empty string, it is empty, don't kick the lying man
      if (!inputString) return

      const error = _checkKeywordForErrors(keywordString)
      if (error) {
        setError(t('invalid_keyword'))

        // Track length errors
        if (error === KeywordError.CHAR_LIMIT || error === KeywordError.MAX_WORDS) {
          logAnalyticsEvent('Keyword error', {
            userEmail: baseUserData?.email,
            planId: currentUserWorkspace?.id,
            planName: currentUserWorkspace?.name,
            assignmentId: assignmentData?.id,
            errorText: keywordString,
            errorType: error,
          })
        }

        return
      }

      if (existingKeywords.length >= maxKeywords) {
        setError(t('max_count'))
        return
      }

      onChange([...keywords, keywordString.trim()])
      setInputValue('')
      return

    }

    if (!keywordString) {
      setInputValue('')
      return
    }

    // NO DELIMITERS FOUND - can be valid keyword, but not confirmed with a delimiter
    if (!VALID_DELIMITERS.test(keywordString)) {
      setInputValue(keywordString)
      return
    }

    // IS ENTIRE STRING VALID KEYWORD?
    if (_isValidKeyword(keywordString)) {
      onChange([...keywords, keywordString.trim()])
      setInputValue('')
      return
    }

    const resolvedKeywords = []

    // Split the string using delimiters
    const tokens = keywordString.split(VALID_DELIMITERS)

    // Validate tokens and push valid keywords into the array
    for (let token of tokens) {
      const potentialKeyword = token.trim()

      const error = _checkKeywordForErrors(potentialKeyword)

      // Track length errors
      if (error === KeywordError.CHAR_LIMIT || error === KeywordError.MAX_WORDS) {
        logAnalyticsEvent('Keyword error', {
          userEmail: baseUserData?.email,
          planId: currentUserWorkspace?.id,
          planName: currentUserWorkspace?.name,
          assignmentId: assignmentData?.id,
          errorText: keywordString,
          errorType: error,
        })
      }

      if (error) continue

      resolvedKeywords.push(potentialKeyword.trim())
    }

    // No valid keywords found
    if (!resolvedKeywords.length) {
      setInputValue(keywordString)
      setError(t('invalid_keyword'))
      return
    }

    const allKeywords = [...keywords, ...resolvedKeywords]

    if (allKeywords.length > maxKeywords) {
      setError(t('max_count'))
      allKeywords.length = maxKeywords
    }

    setInputValue('')
    onChange(allKeywords)
  }, [assignmentData?.id, baseUserData?.email, currentUserWorkspace?.id, currentUserWorkspace?.name, keywords, maxKeywords, onChange, t])

  /** Handles special key presses */
  const handleSpecialKeys = (e: KeyboardEvent<HTMLInputElement>) => {
    const key = e.key

    if (recognizedSpecialKeys.has(key)) {
      if (key === KeyboardEventKey.BACKSPACE && inputValue !== '') return

      e.preventDefault()

      switch (key) {
        case KeyboardEventKey.BACKSPACE:
          const newKeywords = [...keywords]
          newKeywords.pop()
          onChange(newKeywords)
          break

        case KeyboardEventKey.ENTER:
        case KeyboardEventKey.COMMA:
        case KeyboardEventKey.SEMICOLON:
          handleParseKeywords(inputValue, true)
          break

        default:
          return
      }
    }
  }

  /** Removes keyword at specified index and triggers onChange callback with new keyword array */
  const handleDeleteAt = useCallback((deleteIndex: number) => {
    const newKeywords = [...keywords]
    newKeywords.splice(deleteIndex, 1)
    onChange(newKeywords)
  }, [keywords, onChange])

  return (
    <div>

      <div className={classnames(
        styles.inputWrap,
        className,
        { [styles.hasError]: !!error }
      )}>

        {/* Badges for existing validated keywords */}
        {keywords.map((keyword, index) => (
          <Badge key={`keyword-${index}`} className={styles.keywordWrap} color="gray" type="outline">

            {keyword}

            <Button
              className={styles.deleteButton}
              type="secondary nobackground noborder"
              onClick={() => handleDeleteAt(index)}
            >
              <ClearIcon className={styles.deleteIcon} />
            </Button>

          </Badge>
        ))}

        <input
          id={styles.keywordInput}
          className={classnames({ [styles.hasError]: !!error })}
          type="text"
          placeholder={placeholder || t('placeholder')}
          value={inputValue}
          autoFocus={autofocus}
          onBlur={_ => {
            if (forceKeywordConfirmation) handleParseKeywords('')
            else handleParseKeywords(inputValue, true)
          }}
          onKeyDown={e => handleSpecialKeys(e)}
          onChange={e => handleParseKeywords(e.target.value)}
        />

      </div>

      {(maxKeywords !== Number.POSITIVE_INFINITY || !!error || !!fieldInfo) &&
        <div className={classnames(styles.extras, { [styles.hasError]: !!error })}>

          <span className={styles.info}>
            {error || fieldInfo}
          </span>

          {maxKeywords !== Number.POSITIVE_INFINITY &&
            <span className={styles.counter}>
              {keywords.length}/{maxKeywords}
            </span>
          }

        </div>
      }

    </div>
  )
}
