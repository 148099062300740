import { FileRejection, useDropzone } from 'react-dropzone'
import React, { Fragment, useCallback, useEffect } from 'react'
import { purgeUploadWatermarkLogo, uploadWatermarkLogo } from 'redux/Individual/VisualsEditing/UploadWatermarkLogo/uploadWatermarkLogo.actions'

import { APIRequestState } from 'constants/API'
import { Color } from 'constants/assets'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import ReactLoading from 'react-loading'
import { RequestStatus } from 'components/common/RequestStatus'
import classnames from 'classnames'
import { purgeLoadWatermarkLogo } from 'redux/Individual/VisualsEditing/LoadWatermarkLogo'
import styles from './EditorOptionLogoUploader.module.sass'
import { useDispatch } from 'react-redux'
import { useDraggingFile } from 'utils/hooks/useDraggingFile'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'
import { useVisualsEditing } from 'components/pages/VisualsEditing/VisualsEditing.context'

/**
 * @component EditorOptionLogoUploader for Visuals Editing's Watermark/logo edit option
 * @example
 * <EditorOptionLogoUploader />
 */
export const EditorOptionLogoUploader: React.FC = () => {
  const { t } = useTranslation(['visuals_editing'])
  const dispatch = useDispatch()
  const { currentUserWorkspace } = useUserData()

  const {
    uploadedWatermarkLogoResponse,
    loadWatermarkLogoResponse
  } = useVisualsEditing()

  const isDraggingFile = useDraggingFile()

  useEffect(() => {
    return () => {
      dispatch(purgeUploadWatermarkLogo())
    }
  }, [dispatch])

  const onDrop = useCallback(async (acceptedFiles: File[], fileRejections: FileRejection[]) => {

    if (fileRejections && fileRejections.length > 0) {

      // useDropzone onDrop options set to maxFiles: 1
      if (fileRejections.length > 1) {
        alert(`${t('unsupported_upload_max_1_file')}`)
      } else {
        alert(`${t('unsupported_upload')}`)
      }
    }

    for (let file of acceptedFiles) {
      dispatch(purgeLoadWatermarkLogo())
      if (currentUserWorkspace) dispatch(uploadWatermarkLogo(currentUserWorkspace.id, file))
    }
  }, [t, dispatch, currentUserWorkspace])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop, accept: {
      'image/*': ['.png', '.jpeg', '.jpg', '.svg'],
    }, maxFiles: 1
  })

  const isWatermarkLogoUploadingInProgress = uploadedWatermarkLogoResponse.request && uploadedWatermarkLogoResponse.request.state === APIRequestState.RUNNING && uploadedWatermarkLogoResponse?.progress !== 100
  const isWatermarkLoading = loadWatermarkLogoResponse.state === APIRequestState.RUNNING

  return (
    <Fragment>
      <div className={classnames('drop-zone', styles.dropZone, { [styles.dragActive]: isDraggingFile, [styles.dragOver]: isDragActive })} {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive || isDraggingFile ?
          <h3>{t('editor_options.drop_here')}</h3>
          :
          (isWatermarkLogoUploadingInProgress || isWatermarkLoading) ?
            <Fragment>
              <ReactLoading
                type="spin"
                color={Color.GRAY_TEXT}
                height="3.6rem"
                width="3.6rem"
              />
              <p className={styles.uploading}>
                {isWatermarkLogoUploadingInProgress ?
                  `${t('editor_options.uploading_file')} ${uploadedWatermarkLogoResponse.progress}%`
                  :
                  t('editor_options.loading')
                }</p>
            </Fragment>
            :
            <Fragment>
              <div className={styles.dragIconContainer}>
                <FileUploadOutlinedIcon className={styles.dragIcon} fontSize='inherit' />
              </div>
              <p className={styles.dragInstructions}>{t('editor_options.drop_instructions')}</p>
              <p className={styles.fileRecommendation}>{t('editor_options.file_recommendation')}</p>
            </Fragment>
        }
      </div>
      <RequestStatus
        request={uploadedWatermarkLogoResponse.request}
        errorMessage={t('error_status_upload_logo')}
        showStates={[APIRequestState.ERROR]}
        spaceTopRem={1}
      />
      <RequestStatus
        request={loadWatermarkLogoResponse}
        errorMessage={t('error_status_load_logo')}
        showStates={[APIRequestState.ERROR]}
        spaceTopRem={1}
      />
    </Fragment>
  )
}
