import { StagingDecorationDTO } from 'models/virtualFurniture'
import { VirtualFurnitureRoomType } from 'constants/virtualFurniture'
import constate from 'constate'
import { useGetVirtualStagingDecorations } from 'dataQueries'
import { useMemo } from 'react'

function useStagingDecorationsSetup() {
  /** Array of StagingDecoration items */
  const decorations = useGetVirtualStagingDecorations().data

  /** Map of StagingDecoration items grouped by roomType */
  const decorationRoomMap = useMemo(() => {

    const mappedDecorations: { [Key in VirtualFurnitureRoomType]?: StagingDecorationDTO[] } = {}

    if (!decorations || !decorations.length) return mappedDecorations

    for (let decoration of decorations) {
      mappedDecorations[decoration.roomType] = [
        ...(mappedDecorations[decoration.roomType] || []),
        decoration
      ]
    }

    return mappedDecorations
  }, [decorations])

  return {
    stagingDecorations: decorations,
    stagingDecorationsByRoom: decorationRoomMap
  }
}

export const [StagingDecorationsProvider, useStagingDecorations] = constate(useStagingDecorationsSetup)
