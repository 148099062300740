import { Basement } from '../Basement.component'
import { Floor } from '../Floor.component'
import React from 'react'
import { Roof } from '../Roof.component'
import { Shape } from '../Shape.component'
import Stack from '@mui/material/Stack'
import { ThermalBridges } from '../ThermalBridges.component'
import { Window } from '../Window.component'

/**
 * @component
 * Content for the ConstructionMethod tab in the EnergyCertificate component.
 *
 * @example
 * <ConstructionMethod />
 */
export const ConstructionMethod: React.FC = () => (
  <Stack gap={1.6}>

    {/* SHAPE SECTION */}
    <Shape />

    {/* ROOF SECTION */}
    <Roof />

    {/* FLOORS SECTION */}
    <Floor />

    {/* BASEMENT SECTION */}
    <Basement />

    {/* WINDOWS SECTION */}
    <Window />

    {/* THERMAL BRIDGE TYPE OPTIONS */}
    <ThermalBridges />

  </Stack>
)
