import { StagingDecorationDTO, VirtualFurnitureStyleDTO } from 'models/virtualFurniture'
import { EntityKeys, QueryType } from 'utils/reactQuery'

import { useQuery } from '@tanstack/react-query'
import { useAPI } from 'utils/API'

enum Endpoints {
  GET_VIRTUAL_STAGING_DECORATIONS = '/virtualStaging/decoration',
  GET_VIRTUALSTAGING__FURNITURE_DATA = '/virtualStaging/virtualFurniture'
}

// QUERIES

export function useGetVirtualStagingDecorations() {
  const api = useAPI<Endpoints>()

  return useQuery<Array<StagingDecorationDTO>, Error>({
    queryKey: [EntityKeys.VIRTUAL_STAGING_DECORATIONS, QueryType.LIST],
    queryFn: () => api.get<Array<StagingDecorationDTO>>(
      Endpoints.GET_VIRTUAL_STAGING_DECORATIONS,
      {},
      false
    )
  })
}

export function useGetVirtualStagingFurnitureStyles() {
  const api = useAPI<Endpoints>()

  return useQuery<Array<VirtualFurnitureStyleDTO>, Error>({
    queryKey:
      [EntityKeys.VIRTUAL_STAGING_FURNITURE_STYLES, QueryType.LIST],
    queryFn: () => api.get<Array<VirtualFurnitureStyleDTO>>(
      Endpoints.GET_VIRTUALSTAGING__FURNITURE_DATA,
      {},
      false
    )
  })
}
