import { BEIGE_600, GRAY_900 } from 'constants/styling/theme'
import { Box, Stack, Typography } from '@mui/material'
import { FC, useMemo } from 'react'
import { RoomItemPopupType, useStyleSelection } from '../main/contexts'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { ChildrenWithDivider } from 'components/common/ChildrenWithDivider'
import { SelectedStagingItemPreview } from '../SelectedStagingItemPreview'
import { VirtualFurnitureRoomType } from 'constants/virtualFurniture'
import { stagingDecorationLink } from 'constants/staticLinks'
import { useRoomAPI } from 'components/contexts/RoomAPI.context'
import { useTranslation } from 'react-i18next'
import { useVirtualFurnitureData } from 'components/contexts/VirtualFurnitureData.context'

/** Displays detailed information regarding the selected item. */
const SelectedItemDescription: FC<{ title: string, content: string }> = ({
  title,
  content
}) => (
  <>
    {`${title}: `}
    <Typography variant="text-sm" color={GRAY_900}>
      {content}
    </Typography>
  </>
)

/** 
 * @component
 * SelectedRoomItemSection displaying selected room item list.
 * 
 * @example
 * <SelectedRoomItemSection />
 */
export const SelectedRoomItemSection: FC = () => {
  const { t } = useTranslation(['style_selection_order_step', 'common'])

  const { updateRoom } = useRoomAPI()

  const { virtualFurnitureStylesMap } = useVirtualFurnitureData()

  const {
    activeRoom,
    setRoomItemPopupType,
  } = useStyleSelection()

  const selectedFurniture = useMemo(() => {
    return Array.from(activeRoom.styleCodeMap.values()).map((styleCode) => virtualFurnitureStylesMap[styleCode])
  }, [activeRoom.styleCodeMap, virtualFurnitureStylesMap])

  const selectedDecorationsStringList = useMemo(() => {
    return activeRoom?.decorations.map((decoration) => t(`staging_decorations:${decoration}`)).join(', ')
  }, [activeRoom?.decorations, t])

  const selectedFlooring = useMemo(() => {
    const selectedCode = activeRoom?.renovations.get(VirtualFurnitureRoomType.FLOORING)

    if (!selectedCode) return undefined

    return virtualFurnitureStylesMap[selectedCode]
  }, [activeRoom?.renovations, virtualFurnitureStylesMap])

  const selectedWallPainting = useMemo(() => {
    const selectedCode = activeRoom?.renovations.get(VirtualFurnitureRoomType.WALL_MATERIALS)

    if (!selectedCode) return undefined

    return virtualFurnitureStylesMap[selectedCode]
  }, [activeRoom?.renovations, virtualFurnitureStylesMap])

  return (
    <BorderBoxWrapper padding={2} height="100%" width="100%">

      <Stack>

        <Box paddingBottom="1.2rem" borderBottom={`.1rem solid ${BEIGE_600}`} marginBottom="1.6rem">
          <Typography variant="text-md" fontWeight="semiBold" color={GRAY_900}>
            {t('added_items')}
          </Typography>
        </Box>

        <ChildrenWithDivider dividerProps={{ margin: 12 }}>

          {/* FURNITURE */}
          {selectedFurniture.map(({ stagingImageUrl, style, roomType, code }) => (
            <SelectedStagingItemPreview
              key={code}
              imageUrl={stagingImageUrl}
              title={t('room_items.FURNITURE')}
              description={
                <SelectedItemDescription
                  title={t('style')}
                  content={t(`virtual_furniture_style_category:${style}`)}
                />
              }
              onEdit={() => setRoomItemPopupType(RoomItemPopupType.FURNITURE)}
              onDelete={() => updateRoom(activeRoom.key, { styleCodeMap: activeRoom.styleCodeMap.delete(roomType) })}
            />
          ))}

          {/* DECORATIONS */}
          {activeRoom.decorations.size > 0 &&

            <SelectedStagingItemPreview
              imageUrl={stagingDecorationLink}
              title={t('room_items.DECORATION')}
              description={
                <SelectedItemDescription
                  title={t('item')}
                  content={selectedDecorationsStringList}
                />
              }
              onEdit={() => setRoomItemPopupType(RoomItemPopupType.DECORATION)}
              onDelete={() => updateRoom(activeRoom.key, { decorations: activeRoom.decorations.clear() })}
            />
          }

          {/* FLOORING */}
          {selectedFlooring &&
            <SelectedStagingItemPreview
              imageUrl={selectedFlooring.stagingImageUrl}
              title={t('room_items.FLOOR')}
              description={
                <SelectedItemDescription
                  title={t('style')}
                  content={t(`virtual_furniture_style_category:${selectedFlooring.style}`)}
                />
              }
              onEdit={() => setRoomItemPopupType(RoomItemPopupType.FLOOR)}
              onDelete={() => updateRoom(activeRoom.key, { renovations: activeRoom.renovations.delete(VirtualFurnitureRoomType.FLOORING) })}
            />
          }

          {/* WALL PAINTING */}
          {selectedWallPainting &&
            <SelectedStagingItemPreview
              imageUrl={selectedWallPainting.stagingImageUrl}
              title={t('room_items.WALL')}
              description={
                <SelectedItemDescription
                  title={t('style')}
                  content={t(`virtual_furniture_style_category:${selectedWallPainting.style}`)}
                />
              }
              onEdit={() => setRoomItemPopupType(RoomItemPopupType.WALL)}
              onDelete={() => updateRoom(activeRoom.key, { renovations: activeRoom.renovations.delete(VirtualFurnitureRoomType.WALL_MATERIALS) })}
            />
          }

        </ChildrenWithDivider>

      </Stack>

    </BorderBoxWrapper>
  )
}
