import { FC } from 'react'
import Logo from 'components/common/Logo/Logo'
import styles from './maintenance.module.sass'
import { useTranslation } from 'react-i18next'

export const MaintenancePage: FC = () => {

  const { t } = useTranslation(['maintenance'])

  return (
    <div className={styles.page}>

      <Logo />

      <img className={styles.gif} src="https://media.tenor.com/Kxm0E4H9PR4AAAAi/blukittie-blu.gif" alt="typing-cat-gif" />

      <h2>{t('title')}</h2>
      <h3>{t('text')}</h3>

    </div>
  )
}
