import { BLACK_TEXT, BLUE_100, BLUE_600, CORAL_600, GRAY_400, GRAY_BORDER, GRAY_TEXT, HKGroteskFamily } from 'constants/styling/theme'
import { FC, ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { initStripe, stripeController } from 'utils/payment'

import { Country } from 'constants/country'
import { Elements } from '@stripe/react-stripe-js'
import { Stripe } from '@stripe/stripe-js'
import { useUserData } from './UserDataContext'

const RULES = {
  '.Tab, .Input, .Label': {
  },
  '.Tab': {
    border: `1px solid ${GRAY_BORDER}`
  },
  '.Input': {
    border: `1px solid ${GRAY_BORDER}`,
    outline: 'none',
    fontSize: '.7rem',
    color: BLACK_TEXT
  },
  '.Input--invalid': {
    border: `1px solid ${CORAL_600}`,
    outline: 'none',
  },
  '.Input:focus': {
    border: `1px solid ${BLACK_TEXT}`
  },
  '.Checkbox': {
    fontSize: '.9rem',
    border: `1px solid ${GRAY_BORDER}`
  },
  '.CheckboxInput': {
    border: `1px solid ${GRAY_400}`,
    color: BLUE_600,
  },
  '.CheckboxInput--checked': {
    border: `1px solid ${BLUE_600}`,
    backgroundColor: BLUE_100,
    fill: BLUE_600,
  },
  '.Label': {
    fontWeight: '400',
    marginBottom: '.3rem',
    fontSize: '.7rem',
    color: BLACK_TEXT
  },
  '.Label--invalid': {
    color: CORAL_600
  },
  '.Error': {
    marginTop: '.3rem',
    fontWeight: '400',
    fontSize: '.7rem',
    color: CORAL_600
  },
}

const VARIABLES = {
  borderRadius: '8px',
  fontFamily: HKGroteskFamily,
  colorText: GRAY_TEXT,
  colorPrimary: GRAY_TEXT,
  colorDanger: CORAL_600,
  spacingGridRow: '1.5rem',
  iconCheckmarkColor: BLUE_600
}

/** Gallery additional visuals context */
export const StripePaymentContext = createContext({})

/** Gallery additional visuals context hook */
export const useStripePayment = () => useContext(StripePaymentContext)

/** Context provider for gallery additional visuals */
export const StripePaymentContextProvider: FC<{
  clientSecret: string
  children?: ReactNode
}> = ({
  clientSecret, children
}) => {

    const { baseUserData } = useUserData()

    const userCountry = useMemo(() => baseUserData?.country, [baseUserData?.country])
    const [stripePromise, setStripePromise] = useState<null | Promise<Stripe | null>>(null)

    useEffect(() => {
      if (!userCountry) throw new Error('User country is undefined, stripe failed to initialize!')
      const userCountryAsCountry = Country[userCountry as Country]
      if (!userCountryAsCountry) throw new Error('User country is of unknown type, stripe failed to initialize!')
      const stripeInitialized = initStripe(userCountryAsCountry)
      if (!stripeInitialized) throw new Error('Stripe failed to initialize!')
      setStripePromise(stripeController.stripePromise)
    }, [userCountry])

    const stripeOptions = useMemo(() => ({
      clientSecret,
      appearance: {
        theme: 'none' as 'none',
        variables: VARIABLES,
        rules: RULES
      },
    }), [clientSecret])

    return (
      <StripePaymentContext.Provider
        value={{}}
      >
        <Elements stripe={stripePromise} options={stripeOptions}>
          {children}
        </Elements>
      </StripePaymentContext.Provider>
    )
  }
