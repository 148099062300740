import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { ActionGetUserWorkspaces } from './getUserWorkspaces.actions'
import { UserWorkspacesStore } from './getUserWorkspaces.interfaces'

export const reduceGetUserWorkspaces = (state: UserWorkspacesStore, action: ActionGetUserWorkspaces) => {
  const [eventType, dataType] = action.type

  if (dataType !== ActionTypeAPIData.USER_GET_WORKSPACES) return state

  const { request } = action.payload

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
    case ActionTypeAPIEvent.FETCH:
    case ActionTypeAPIEvent.RECEIVED:
      return request

    default:
      return state
  }
}
