import { Trans, useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { Tag } from 'components/common/Tags'
import classNames from 'classnames'
import { features } from 'constants/pricing/features'
import styles from './UpcomingFeature.module.sass'
import useMediaQuery from '@mui/material/useMediaQuery'

/**
 * @component 
 * @example
 * <UpcomingFeature />
 */
export const UpcomingFeature = () => {
  const { t } = useTranslation(['pricing_page'])
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)

  return (
    <div className={styles.upcomingFeature}>
      <div className={styles.header}>{t('feature_header')}</div>
      <Grid
        container
        rowSpacing={3}
        spacing={2}
        className={classNames(styles.featureWrap, { [styles.wide]: !isMobileView })}
      >
        {features.map((feature, idx) =>
          <Grid key={idx} item xs={6} sm={6} md={4}>
            <div className={classNames(styles.feature, { [styles.mobile]: isMobileView })}>
              <img className={styles.featureImg} src={feature.image} alt={feature.title} />
              <div className={styles.title}>
                <Trans t={t} i18nKey={`features.${feature.title}`}>
                  &nbsp;
                </Trans>
              </div>
              {feature.tag && <Tag color='orange' className={styles.tag}>🚀 {t('launched')}</Tag>}
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  )
}
