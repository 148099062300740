import { ActionRequest, RootStore } from 'models/redux'
import { FileManipulationKey, downloadVisualTypeToBaseType } from 'constants/visual'
import { call, put } from 'redux-saga/effects'
import { listVisuals, listVisualsSaga } from '../ListVisuals'

import API from 'utils/API/API'
import { ActionLoadVisual } from '../LoadVisual'
import { ActionTypeAPIData } from 'constants/redux'
import { Endpoint } from 'constants/API'
import { generalFetch } from 'redux/Helpers'
import { select } from 'typed-redux-saga'

/** Saga which handles deletion of visual */
export function* deleteVisualSaga(receivedAction: ActionLoadVisual) {
  const { missionId, type, file } = receivedAction.payload
  const cancelToken = yield* select((state: RootStore) => state.APIData[ActionTypeAPIData.LOAD_VISUAL]?.[missionId]?.[FileManipulationKey.UPLOAD]?.[file.name]?.[type]?.cancelToken)
  if (cancelToken) cancelToken.cancel()

  const url = Endpoint.VISUAL_DELETE
    .replace('{assignmentId}', encodeURIComponent(missionId.toString()))
    .replace('{filename}', encodeURIComponent(file.name.toString()))

  const actionFetch: ActionRequest = yield generalFetch(ActionTypeAPIData.LOAD_VISUAL, () => API.delete<null>(url, {
    params: {
      type: type.toString(),
    },
  }, {
    endpoint: Endpoint.VISUAL_DELETE,
  }))

  const deletedAction: ActionLoadVisual = {
    ...actionFetch,
    payload: {
      ...receivedAction.payload,
      ...actionFetch.payload,
    }
  }

  yield put(deletedAction)

  const getListedVisuals = (state: RootStore) => state.APIData[ActionTypeAPIData.LIST_VISUALS]?.[missionId]?.[downloadVisualTypeToBaseType[type]]
  const listedVisuals = yield* select(getListedVisuals)

  const visualsKeyList = (listedVisuals?.data?.visuals || []).map(visual => visual.name)
  if (visualsKeyList.includes(file.name)) {
    yield call(listVisualsSaga, listVisuals(missionId, type))
  }
}
