import { Children, FC, ReactNode } from 'react'
import { MUIDivider, MUIDividerProps } from '../MUIDivider'

interface Props {
  children: ReactNode
  dividerProps?: MUIDividerProps
}

/**
 * Utility wrapper component that inserts divider between it's children
 * 
 * @example
 * <Stack>
 *   <ChildrenWithDivider>
 *     <Item name="Acid pops" />
 *     <Item name="Cockroach Clusters" />
 *     <Item name="Bertie Bott's Every Flavor Beans" />
 *   </ChildrenWithDivider>
 * </Stack>
 */
export const ChildrenWithDivider: FC<Props> = ({
  children,
  dividerProps = {},
}) => {

  const childrenArray = Children.toArray(children)

  const childrenWithDivider = childrenArray.reduce<ReactNode[]>((acc, child, index) => {

    acc.push(child)

    if (index < childrenArray.length - 1) {
      acc.push(<MUIDivider {...dividerProps} key={index} />)
    }

    return acc
  }, [])

  return <>{childrenWithDivider}</>
}
