import { useMemo, useState } from 'react'

import Button from 'components/common/Button/Button'
import TotalCount from 'components/common/TotalCount/TotalCount'
import { WorkspaceMember } from 'models/user'
import classnames from 'classnames'
import styles from './UserFilter.module.sass'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
interface Props {
  /** The additional classes to append */
  className?: string
  /** Users displayed as checkbox options */
  users: WorkspaceMember[]
  /** Whether the filter is expandable */
  isExpandable?: boolean
  /** How many users is displayed in filter when expandable but not expanded, ignored if isExpandable = false */
  maxUnexpandedUserCount?: number
  /** OnChange action with userId parameter to be called after checkbox option was checked */
  selectedUserIds?: Set<string>
  onChange?: (userId: string, e?: any) => void
}

/**
 * @component A checkbox filter by users
 * @example <UserFilter users=[user1, user2] onChange={onChangeAction(userId, e)} isExpandable maxUnexpandedUserCount={12}/>
 */
export const UserFilter: React.FC<Props> = ({
  className = '',
  users,
  isExpandable = true,
  maxUnexpandedUserCount,
  selectedUserIds,
  onChange
}) => {
  const { t } = useTranslation(['user_filter'])

  const [isExpanded, setIsExpanded] = useState(false)

  const usersMembershipMap = useMemo(() => {
    const map = new Map<string, WorkspaceMember[]>()
    for (let user of users) {
      // Remove users with 0 orders
      if (user.numberOfOrders === 0) continue
      if (!map.has(user.userId)) map.set(user.userId, [user])
      else {
        map.set(user.userId, [
          ...map.get(user.userId) || [],
          user
        ])
      }
    }

    // Sort users alphabetically by first name
    const sortedMap = new Map([...map.entries()]
      .sort((userA, userB) => {
        const lastNameA = userA[1][0].name.toLowerCase()
        const lastNameB = userB[1][0].name.toLowerCase()
        if (lastNameA > lastNameB) return 1
        if (lastNameA < lastNameB) return -1
        return 0
      }))

    return sortedMap

  }, [users])

  const usersArray = useMemo(() => Array.from(usersMembershipMap.entries()), [usersMembershipMap])
  // Sliced users array if content expandable but not expanded
  const slicedUsersArray = useMemo(() => !!maxUnexpandedUserCount ? usersArray.slice(0, maxUnexpandedUserCount) : usersArray, [usersArray, maxUnexpandedUserCount])
  // Users displayed in user filter
  const displayedUsersArray = useMemo(() => (isExpandable && !isExpanded) ? slicedUsersArray : usersArray, [isExpandable, isExpanded, slicedUsersArray, usersArray])

  return (

    <div className={classnames(
      styles.userFilter,
      className
    )}>
      {displayedUsersArray.length !== 0 ?
        <div className={styles.usersWrap}>
          {displayedUsersArray.map(([userId, usersArray]) => {
            const user = usersArray[0]
            const { name, numberOfOrders } = user

            return (
              <div key={userId} className={styles.user}>
                <label
                  key={userId}
                  className={classnames('checkbox blue rounded redesigned', styles.label)}
                  htmlFor={`users_checkbox_${userId}`}>
                  <input
                    type="checkbox"
                    name={`users_checkbox_${userId}`}
                    id={`users_checkbox_${userId}`}
                    value={userId}
                    checked={selectedUserIds && selectedUserIds.has(userId)}
                    onChange={e => onChange && onChange(userId, e)}
                  />
                  <span className="checkmark"></span>
                  <div className={classnames('label-after', styles.labelAfter)}>
                    <span>{name}</span>
                    <TotalCount totalCount={numberOfOrders} />
                  </div>
                </label>
              </div>
            )
          })}
        </div>
        :
        <span>
          {t('no_user_with_order')}
        </span>
      }

      {isExpandable && !!maxUnexpandedUserCount && (maxUnexpandedUserCount < usersArray.length) &&
        <div className={styles.expandButtonWrap}>
          <Button
            type="secondary"
            height="thin"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {!isExpanded
              ? <span>{t('expand')}</span>
              : <span>{t('close')}</span>
            }
          </Button>
        </div>
      }
    </div>
  )
}
