import { Action, ActionRequest, ActionTupleType } from 'models/redux'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import axios, { AxiosError, AxiosResponse } from 'axios'

import { APIRequest } from 'models/API'
import { decideErrorType } from 'utils/APIDecideErrorType'

/** Function that returns true if all components of an action type tuple match, false otherwise */
export function actionTypeTupleTest(action: Action, array: ActionTupleType): any {
  return Array.isArray(action.type) && array.length === action.type.length && action.type[0] === array[0] && action.type[1] === array[1]
}

/** A general reusable fetch saga. Accepts data type and fetch function */
export async function generalFetch(data_type: ActionTypeAPIData, fetchFunction: () => Promise<AxiosResponse<unknown>>): Promise<ActionRequest> {
  const action: ActionRequest = {
    type: [ActionTypeAPIEvent.RECEIVED, data_type],
    payload: {
      request: new APIRequest()
    }
  }

  try {
    const response = await fetchFunction()
    action.payload.request = APIRequest.factoryOK(response.data, response)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosErr: AxiosError = error
      const finalError = {
        ...axiosErr,
        data: axiosErr.response?.data,
        status: axiosErr.response?.status,
        statusText: axiosErr.response?.statusText,
      }
      action.payload.request = APIRequest.factoryError(decideErrorType(axiosErr), finalError, axiosErr?.response)
    }
  } finally {
    return action
  }
}
