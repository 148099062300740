import React, { useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Button from 'components/common/Button/Button'
import Modal from 'components/common/Modals/Modal/Modal'
import { QueryStatus } from 'components/common/QueryStatus'
import { WorkspaceUserProfile } from 'models/user'
import { logAnalyticsEvent } from 'utils/analytics'
import styles from './VisualsEditingSaveTemplatePopup.module.sass'
import { useUpdateVisualsEditingTemplate } from 'dataQueries'
import { useUserData } from 'components/contexts/UserDataContext'
import { useVisualsEditing } from '../VisualsEditing.context'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Whether the popup is open */
  isOpen: boolean
  /** OnClick callback trigger for closing popup */
  onClose: () => void
  /** Callback after saved template */
  onSaved: () => void
  /** Optional template name - not used right now, implemented on BE only */
  templateName?: string | null
  /** Current workspace data */
  currentUserWorkspace?: WorkspaceUserProfile | null
}

/**
 * @component VisualsEditingSaveTemplatePopup 
 * @example
 * <VisualsEditingSaveTemplatePopup isOpen={true} />
 */

export const VisualsEditingSaveTemplatePopup: React.FC<Props> = ({
  isOpen,
  onClose,
  currentUserWorkspace,
  onSaved
}) => {
  const { t } = useTranslation(['visuals_editing'])
  const updateVisualTemplate = useUpdateVisualsEditingTemplate()
  const { clientData } = useUserData()
  const { selectedWatermarkSize,
    selectedWatermarkOpacity,
    selectedWatermarkPosition,
    selectedRatioPattern,
    exportOption,
    watermarkId,
    isWatermarkActive,
    templateId
  } = useVisualsEditing()

  const isTemplateOverride = Boolean(templateId)

  const onSaveAsTemplate = useCallback(() => {
    if (!currentUserWorkspace) return
    updateVisualTemplate.mutate({
      watermarkId: isWatermarkActive ? watermarkId : null,
      watermarkSize: selectedWatermarkSize,
      watermarkOpacity: selectedWatermarkOpacity,
      watermarkPositioning: selectedWatermarkPosition,
      imageRatio: selectedRatioPattern.value,
      exportOption,
      workspaceId: currentUserWorkspace.id
    })

    logAnalyticsEvent(`${isTemplateOverride ? 'Override Template' : 'Save Template'}`, {
      userEmail: clientData?.email,
      organizationId: clientData?.organizationId,
      logoSetting: isWatermarkActive ? `${selectedWatermarkSize},${selectedWatermarkOpacity},${selectedWatermarkPosition}` : '',
      ratioSetting: selectedRatioPattern.value,
      exportSetting: exportOption,
    })
  }, [currentUserWorkspace, updateVisualTemplate, isWatermarkActive, watermarkId, selectedWatermarkSize, selectedWatermarkOpacity, selectedWatermarkPosition, selectedRatioPattern.value, exportOption, isTemplateOverride, clientData?.email, clientData?.organizationId])

  useEffect(() => {
    if (isOpen && updateVisualTemplate.isSuccess) {
      onSaved()
      onClose()
    }
  }, [updateVisualTemplate, onSaved, onClose, isOpen])

  return (
    <Modal
      modalContentClassName={styles.saveTemplatePopup}
      isOpen={isOpen}
      onClickOutside={onClose}
      onClose={onClose}
    >
      <div className={styles.title}>
        {t('save_template.title')}
      </div>
      <div className={styles.content}>
        <span className={styles.content_default}>
          <Trans t={t} i18nKey={isTemplateOverride ? 'save_template.content_override' : 'save_template.content'} values={{ plan_name: currentUserWorkspace?.name }}>
            <span></span>
          </Trans>
        </span>
        {isTemplateOverride &&
          <span className={styles.content_override}>{t('save_template.content_override_confirmation')}</span>
        }
      </div>

      {updateVisualTemplate.isError &&
        <QueryStatus
          query={updateVisualTemplate}
          spaceBottomRem={2}
          showStates={['error']}
          onPurge={() => updateVisualTemplate.reset()}
        />
      }

      <div className={styles.buttons}>
        <Button className={styles.button} type="secondary" onClick={onClose}>
          <span>{t('save_template.cancel_button')}</span>
        </Button>

        <Button className={styles.button} type={isTemplateOverride ? 'primary red' : 'primary'} onClick={onSaveAsTemplate}>
          <span>{t(isTemplateOverride ? 'save_template.override_button' : 'save_template.save_button')}</span>
        </Button>
      </div>
    </Modal>
  )
}
