import { hotjar } from 'react-hotjar'

const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID
const HOTJAR_VERSION = 6

const options = {
  id: Number(HOTJAR_ID),
  sv: HOTJAR_VERSION,
}

export const initHotjar = () => {
  if (HOTJAR_ID) {
    hotjar.initialize(options)
  }
}
