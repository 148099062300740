import i18n from 'translations/i18n'

/**
 * Returns default error message translation for given errorKey.
 * If key is not defined, general fallback translation *"This field is invalid"* will be provided
 * @param errorKey - translation key for lookup in *validators_default_errors*
 * @returns Default error message for provided key OR General fallback error message
 */
export function getDefaultErrorMessage(errorKey: string, values?: Record<string, string | number>) {
  const translationKey = `validators_default_errors:${errorKey}`

  if (!i18n.exists(translationKey)) return i18n.t('validators_default_errors:fallback')
  return i18n.t(translationKey, { ...(values || {}) })
}
