import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'
import { ActionRequest } from 'models/redux'
import { ArchiveVisualsStoreItem } from './ArchiveVisuals.interfaces'
import { ArchiveVisualsTag } from './ArchiveVisuals.constants'
import { createAPIAction } from 'redux/Helpers'

/** Describes general action to archive visuals */
export interface ActionArchiveVisuals extends ActionRequest {
  payload: {
    missionId: string
    tag: ArchiveVisualsTag
    types: string[]
    filenames: string
    isAdmin: boolean
    request: ArchiveVisualsStoreItem
  }
}

/** Creates an action to generate archive (zip/rar) download */
export function archiveVisuals(missionId: string, types: string[], filenames: string[], tag: ArchiveVisualsTag, isAdmin = false): ActionArchiveVisuals {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.ARCHIVE_VISUALS], {
    missionId,
    types,
    filenames,
    isAdmin,
    tag,
    request: new APIRequest(APIRequestState.RUNNING),
  })
}

/** Creates an action to purge archive visuals state */
export function purgeArchiveVisuals(missionId: string, tag: ArchiveVisualsTag): ActionArchiveVisuals {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.ARCHIVE_VISUALS], {
    tag,
    missionId,
    request: new APIRequest(APIRequestState.BEFORE_START),
  })
}
