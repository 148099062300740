import { BEIGE_300, BEIGE_600 } from 'constants/styling/theme'
import { InstructionProductList, ProductList } from './ProductList'
import { Trans, useTranslation } from 'react-i18next'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { Fragment } from 'react'
import { MUIDivider } from 'components/common/MUIDivider'
import { OrderAdditionalContent } from './OrderAdditionalContent'
import { OrderSummaryContainer } from './OrderSummaryContainer'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import { formatPrice } from 'utils/price'
import { useCart } from '../../_main/contexts'
import { usePurchaseFlowConfig } from '../../_main/contexts/PurchaseFlowConfig.context'

/**
 * @component Cart (product summary) of PF selected products and instructions with connected charges
 * @example <Cart />
 */
export const Cart: React.FC = () => {
  const { t } = useTranslation(['order'])
  const {
    total: {
      totalAfterDiscount,
      totalAfterVat,
      totalBeforeDiscount,
      totalVat,
      totalDiscount
    },
  } = useCart()

  const { catalogueCurrency, catalogueDiscount, catalogueVat } = usePurchaseFlowConfig()

  return (
    <Stack gap={2} data-cy="cart">

      {/** SUMMARY SECTION */}
      <SectionedBorderBox title={t('cart.summary')}>

        {/** PRODUCT LIST */}
        <ProductList />

        {/* INSTRUCTION PRODUCT LIST */}
        <InstructionProductList />

        <MUIDivider margin={12} />

        {/** SUB-TOTAL (with discount) */}
        {catalogueDiscount.gt(0) &&
          <Fragment>

            <Stack gap={1}>

              {/** Sub-total */}
              <OrderSummaryContainer
                type="subtotal"
                summaryText={t('cart.sub_total')}
                price={formatPrice(totalBeforeDiscount, catalogueCurrency)}
              />

              <BorderBoxWrapper
                padding={1}
                elevation='none'
                justifyContent="flex-end"
                borderColor={BEIGE_600}
                backgroundColor={BEIGE_300}
              >
                {/** Total discount */}
                <OrderSummaryContainer
                  type='discount'
                  summaryText={
                    <Trans
                      t={t}
                      i18nKey="cart.special_discount"
                      values={{ percentage: Math.trunc(catalogueDiscount.toNumber()) }}
                    />
                  }
                  price={`-${formatPrice(totalDiscount, catalogueCurrency)}`}
                />
              </BorderBoxWrapper>

            </Stack>

            <MUIDivider margin={12} />

          </Fragment>
        }

        {/** TOTAL */}
        <Stack direction="column">

          {/** Total of order */}
          <OrderSummaryContainer
            summaryText={t('cart.total')}
            price={formatPrice(totalAfterDiscount, catalogueCurrency)}
            type="total"
          />

          <Stack alignItems="flex-end" gap={1}>
            {/** Total VAT */}
            <OrderSummaryContainer
              summaryText={`${t('cart.vat')} (${formatPrice(catalogueVat)}%)`}
              price={formatPrice(totalVat, catalogueCurrency)}
            />

            {/** Total after VAT */}
            <OrderSummaryContainer
              summaryText={t('cart.after_vat')}
              price={formatPrice(totalAfterVat, catalogueCurrency)}
            />
          </Stack>

        </Stack>

        {/** ADDITIONAL SECTION */}
        <OrderAdditionalContent />

      </SectionedBorderBox>

    </Stack>
  )
}
