import { APIRequestState, awaitedUserMeSignupErrorStatusCodes } from 'constants/API'
import { UNFINISHED_REQUEST_STATES } from 'constants/misc'
import { ActionTypeAPIData } from 'constants/redux'

import constate from 'constate'
import { RootStore } from 'models/redux'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { shouldUserRequestOrganizationMe, useAuth0 } from 'utils/auth'

const useAppMetaSetup = () => {
  const { loading, isAuthenticated, user, logout } = useAuth0()

  const userMeRequest = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.USER_ME])
  const userMetaRequest = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.USER_META])
  const organizationMeRequest = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.ORGANIZATION_ME])

  const userData = useMemo(() => userMeRequest.data, [userMeRequest])
  const isUserLoaded = useMemo(() => !loading && isAuthenticated && !!user && userMeRequest.state === APIRequestState.OK && !!userData, [isAuthenticated, loading, user, userData, userMeRequest])
  const isOrganizationMeSupposedToLoad = useMemo(() => shouldUserRequestOrganizationMe(userData), [userData])
  const isUserMeLoading = useMemo(() => isAuthenticated && UNFINISHED_REQUEST_STATES.has(userMeRequest.state), [isAuthenticated, userMeRequest])
  const isUserMetaLoading = useMemo(() => userMetaRequest.state === APIRequestState.RUNNING, [userMetaRequest])
  const isUserSet = useMemo(() => !awaitedUserMeSignupErrorStatusCodes.has(userMeRequest.response?.status ?? Number.MIN_VALUE), [userMeRequest])
  const isOrganizationMeLoading = useMemo(() => isAuthenticated && isUserSet && UNFINISHED_REQUEST_STATES.has(organizationMeRequest.state), [isAuthenticated, isUserSet, organizationMeRequest.state])
  const isLoading = useMemo(() => loading || isUserMeLoading || isUserMetaLoading || (isUserLoaded && isOrganizationMeSupposedToLoad && isOrganizationMeLoading), [loading, isUserMeLoading, isUserMetaLoading, isUserLoaded, isOrganizationMeSupposedToLoad, isOrganizationMeLoading])
  const isUserVerified = useMemo(() => !user || !!(user && user.email_verified), [user])

  useEffect(() => {
    if (userMetaRequest.state === APIRequestState.ERROR && userMetaRequest.error?.status === 500) {
      logout({ returnTo: window.location.href })
    }
  }, [logout, userMetaRequest])

  return {
    isLoading,
    isUserVerified,
    isUserSet,
  }
}

export const [AppMetaProvider, useAppMeta] = constate(useAppMetaSetup)
