import { FC, useCallback } from 'react'

import Modal from 'components/common/Modals/Modal/Modal'
import { SavedPaymentMethodEditPopupContent } from '../EditSavedPaymentMethodPopupContent'
import styles from './EditSavedPaymentMethodPopup.module.sass'
import { useEditPaymentMethodContext } from '../_main/contexts/'

/**
 * Modal for editing saved payment method
 * MUST be within PaymentMethodEditContext provider
 * @example <EditSavedPaymentMethodPopup />
 */
export const EditSavedPaymentMethodPopup: FC = () => {

  const {
    setEditedPaymentMethodId,
    isEditModalOpen,
    setIsEditModalOpen,
    updateStripePaymentMethod,
  } = useEditPaymentMethodContext()

  const handleClose = useCallback(() => {
    if (updateStripePaymentMethod.isPending) return
    setIsEditModalOpen(false)
  }, [setIsEditModalOpen, updateStripePaymentMethod])

  return (
    <Modal
      isOpen={isEditModalOpen}
      modalContentClassName={styles.editModal}
      afterClosed={() => {
        setEditedPaymentMethodId(undefined)
        updateStripePaymentMethod.reset()
      }}
      onClickOutside={handleClose}
    >
      <SavedPaymentMethodEditPopupContent />
    </Modal>
  )
}
