import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { formatPrice, valueMultiplied } from 'utils/price'

import { FC } from 'react'
import { FavouriteCreative } from 'models/purchaseFlow'
import { MUIButton } from 'components/common/MUIButton'
import styles from './BookCreativeCard.module.sass'
import { useBookCreative } from '../../_main'
import { useTranslation } from 'react-i18next'

interface Props {
  /** Creative list */
  creatives: FavouriteCreative[]
  /** Creative table title */
  tableTitle: string
}

/** 
 * Table for displaying creatives
 * @example
 * <CreativesTable creatives={list[]} />
*/
export const CreativesTable: FC<Props> = ({
  creatives,
  tableTitle
}) => {
  const { t } = useTranslation(['book_your_ct_card'])
  const {
    setSelectedCreative,
  } = useBookCreative()

  return (
    <div className={styles.creatives}>

      <h2>{tableTitle}</h2>

      <table cellSpacing={0}>

        {/* HEADER */}
        <thead>
          <tr>
            <th>{t('name')}</th>
            <th>{t('phone_number')}</th>
            <th>{t('travel_cost')}</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {(creatives).map(creative => {
            const { id, fullName, phoneNumber, travelCost: { productUnitPrice, productQuantity } } = creative

            return (
              <tr className={styles.creativeRow} key={id}>
                <td>{fullName}</td>
                <td>{phoneNumber || '-'}</td>
                <td>{formatPrice(valueMultiplied(productUnitPrice, productQuantity), productUnitPrice.currency)}</td>
                <td className={styles.actionCol}>
                  <MUIButton
                    type="secondaryBorder"
                    size="sm"
                    onClick={() => {
                      logAnalyticsEvent(AnalyticsEvent.CREATIVE_SELECTED_BY_CLIENT, {
                        transportCosts: formatPrice(valueMultiplied(productUnitPrice, productQuantity), productUnitPrice.currency),
                        creativeFullName: creative.fullName
                      })
                      setSelectedCreative(creative)
                    }}>
                    {t('choose')}
                  </MUIButton>
                </td>
              </tr>
            )
          })}
        </tbody>

      </table>

    </div>
  )
}
