import { DownloadImage, RootStore, UploadImage } from 'models/redux'
import { FC, MutableRefObject, ReactNode, SetStateAction, createContext, createRef, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Nullable, NullableStringIndexSignature } from 'models/helpers'
import { useDispatch, useSelector } from 'react-redux'

import { APIRequestState } from 'constants/API'
import { ActionTypeAPIData } from 'constants/redux'
import { Dispatch } from 'react'
import { FileManipulationKey } from 'constants/visual'
import { VisualClientDTO } from 'models/visuals'
import { debounceEffect } from 'utils/helpers'
import { downloadVisual } from 'redux/Individual/Visual/LoadVisual'
import { useAuth0 } from 'utils/auth'
import { useGalleryConstants } from './GalleryConstants.context'
import { useGalleryOrder } from './GalleryOrder.context'
import { useGalleryProduct } from './GalleryProduct.context'
import { useGalleryVisualType } from './GalleryVisualType.context'
import { useGalleryVisualsMeta } from './GalleryVisualsMeta.context'
import { useStateRef } from 'utils/hooks/useStateRef'

type UploadImageStore = Nullable<NullableStringIndexSignature<NullableStringIndexSignature<UploadImage>>>
type DownloadImageStore = Nullable<NullableStringIndexSignature<NullableStringIndexSignature<DownloadImage>>>

interface GalleryVisualsInterface {
  uploadVisuals?: UploadImageStore
  downloadVisualsSelector?: DownloadImageStore
  downloadVisuals?: DownloadImageStore
  downloadVisualsRef: MutableRefObject<DownloadImageStore>
  downloadVisualsEntries: [string, Nullable<NullableStringIndexSignature<DownloadImage>>][]
  uploadVisualsEntries: [string, Nullable<NullableStringIndexSignature<UploadImage>>][]
  downloadVisualsKeys: string[]
  uploadVisualsKeys: string[]
  downloadVisualsCount: number
  uploadVisualsCount: number
  uploadVisualsKeyToOriginalNameDictionary: Record<string, string>
  downloadVisualsKeyToOriginalNameDictionary: Record<string, string>
  uploadVisualsOriginalFilenames: string[]
  favoritedVisuals: Set<string>
  visualsKeys: string[]
  visualsCount: number
  listedVisuals: VisualClientDTO[]
  listedVisualsKeyToOriginalNameDictionary: Record<string, string>
  visualsKeyToOriginalNameDictionary: Record<string, string>
  downloadVisualsWithErrorEntries: [string, Nullable<NullableStringIndexSignature<DownloadImage>>][],
  setFavoritedVisuals: Dispatch<SetStateAction<Set<string>>>
  unPurchasedFavoritedVisualsLength: number
  visualMetadataMap: Record<string, VisualClientDTO>
}

const defaultGalleryVisualsValue: GalleryVisualsInterface = {
  downloadVisualsRef: createRef(),
  downloadVisualsEntries: [],
  uploadVisualsEntries: [],
  downloadVisualsKeys: [],
  uploadVisualsKeys: [],
  downloadVisualsCount: 0,
  uploadVisualsCount: 0,
  uploadVisualsKeyToOriginalNameDictionary: {},
  downloadVisualsKeyToOriginalNameDictionary: {},
  uploadVisualsOriginalFilenames: [],
  visualsKeys: [],
  visualsCount: 0,
  listedVisuals: [],
  listedVisualsKeyToOriginalNameDictionary: {},
  visualsKeyToOriginalNameDictionary: {},
  downloadVisualsWithErrorEntries: [],
  favoritedVisuals: new Set(),
  setFavoritedVisuals: () => { throw new Error('setFavoritedVisuals is undefined') },
  unPurchasedFavoritedVisualsLength: 0,
  visualMetadataMap: {}
}

/** Gallery visuals context */
export const GalleryVisualsContext = createContext<GalleryVisualsInterface>(defaultGalleryVisualsValue)
/** Gallery visuals context hook */
export const useGalleryVisuals = (): GalleryVisualsInterface => useContext(GalleryVisualsContext)

/** Context provider for gallery visuals */
export const GalleryVisualsContextProvider: FC<{
  assignmentId: string
  children?: ReactNode
}> = ({
  assignmentId,
  children,
}) => {
    const dispatch = useDispatch()
    const { roles } = useAuth0()

    const {
      AvailableOrderings,
    } = useGalleryConstants()

    const {
      webType,
      thumbnailType,
      normalizedOriginalType,
    } = useGalleryVisualType()

    const {
      isVirtualVisit,
    } = useGalleryProduct()

    const {
      sortFunction,
      sortEntriesFunction,
      currentAdjustedOrder,
    } = useGalleryOrder()

    const {
      allVisuals,
      purchasedVisualsKeys
    } = useGalleryVisualsMeta()

    const uploadVisuals = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.LOAD_VISUAL]?.[assignmentId]?.[FileManipulationKey.UPLOAD])
    const downloadVisualsSelector = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.LOAD_VISUAL]?.[assignmentId]?.[FileManipulationKey.DOWNLOAD])
    const [downloadVisuals, setDownloadVisuals] = useState(downloadVisualsSelector)
    const downloadVisualsDebounceTimeoutRef = useRef<number | undefined>(undefined)
    const downloadVisualsDebounceTimeoutStartRef = useRef<number | undefined>(undefined)
    const downloadVisualsRef = useStateRef(downloadVisuals)

    const downloadVisualsEntries = useMemo(() => (downloadVisuals ? Object.entries(downloadVisuals).filter(([key, allTypes]) => allTypes?.[thumbnailType]).sort(sortEntriesFunction) : []), [downloadVisuals, thumbnailType, sortEntriesFunction])
    const uploadVisualsEntries = useMemo(() => (uploadVisuals ? Object.entries(uploadVisuals).filter(([key, allTypes]) => allTypes?.[normalizedOriginalType]).sort(sortEntriesFunction) : []), [uploadVisuals, normalizedOriginalType, sortEntriesFunction])
    const downloadVisualsKeys = useMemo(() => (downloadVisualsEntries.filter(([key, allTypes]) => allTypes?.[thumbnailType]).map(([key, allTypes]) => key)), [downloadVisualsEntries, thumbnailType])
    const uploadVisualsKeys = useMemo(() => (uploadVisualsEntries.filter(([key, allTypes]) => allTypes?.[normalizedOriginalType]).map(([key, allTypes]) => key)), [uploadVisualsEntries, normalizedOriginalType])
    const downloadVisualsCount = useMemo(() => (downloadVisualsKeys.length), [downloadVisualsKeys])
    const uploadVisualsCount = useMemo(() => (uploadVisualsKeys.length), [uploadVisualsKeys])
    const uploadVisualsKeyToOriginalNameDictionary = useMemo(() => Object.fromEntries(uploadVisualsEntries.map(([key, allTypes]) => [key, allTypes?.[normalizedOriginalType]?.originalFilename || ''] as [string, string]).filter(([key, originalName]) => !!originalName)), [uploadVisualsEntries, normalizedOriginalType])
    const downloadVisualsKeyToOriginalNameDictionary = useMemo(() => Object.fromEntries(downloadVisualsEntries.map(([key, allTypes]) => [key, allTypes?.[normalizedOriginalType]?.originalFilename || ''] as [string, string]).filter(([key, originalName]) => !!originalName)), [downloadVisualsEntries, normalizedOriginalType])
    const uploadVisualsOriginalFilenames = useMemo(() => Object.values(uploadVisualsKeyToOriginalNameDictionary), [uploadVisualsKeyToOriginalNameDictionary])
    const visualsKeys = useMemo(() => [...uploadVisualsKeys, ...downloadVisualsKeys].sort(sortFunction), [uploadVisualsKeys, downloadVisualsKeys, sortFunction])
    const visualsCount = useMemo(() => visualsKeys.length, [visualsKeys])
    const listedVisuals = useMemo(() => allVisuals?.data ? Object.values((allVisuals.data?.visuals as VisualClientDTO[])).sort((visualA, visualB) => sortFunction(visualA.name, visualB.name)) : [], [allVisuals, sortFunction])
    const listedVisualsKeyToOriginalNameDictionary = useMemo(() => Object.fromEntries(listedVisuals.filter(visual => !!visual.originalName).map(visual => [visual.name, visual.originalName || ''] as [string, string])), [listedVisuals])
    const visualsKeyToOriginalNameDictionary: typeof listedVisualsKeyToOriginalNameDictionary = useMemo(() => { return { ...listedVisualsKeyToOriginalNameDictionary, ...downloadVisualsKeyToOriginalNameDictionary, ...uploadVisualsKeyToOriginalNameDictionary } }, [listedVisualsKeyToOriginalNameDictionary, downloadVisualsKeyToOriginalNameDictionary, uploadVisualsKeyToOriginalNameDictionary])
    const downloadVisualsWithErrorEntries = useMemo(() => downloadVisualsEntries.filter(([key, allTypes]) => allTypes?.[thumbnailType]?.request.state === APIRequestState.ERROR), [downloadVisualsEntries, thumbnailType])

    const fetchedFavoritedVisuals = useMemo(() => {
      if (allVisuals?.data) {

        return (allVisuals.data?.visuals as VisualClientDTO[]).reduce((acc: Set<string>, item: VisualClientDTO) => {
          if (item.favorited) acc.add(item.name)
          return acc
        }, new Set<string>())
      }

      return new Set<string>()
    }, [allVisuals])

    const visualMetadataMap: Record<string, VisualClientDTO> = useMemo(() => {
      const visuals = (allVisuals?.data?.visuals ?? []) as VisualClientDTO[]

      return visuals.reduce((acc: Record<string, VisualClientDTO>, item: VisualClientDTO) => {
        acc[item.name] = item

        return acc
      }, {}) ?? {}
    }, [allVisuals])

    const [favoritedVisuals, setFavoritedVisuals] = useState<Set<string>>(fetchedFavoritedVisuals)

    useEffect(() => {
      if (allVisuals?.data) {
        setFavoritedVisuals(fetchedFavoritedVisuals)
      }
    }, [allVisuals?.data, fetchedFavoritedVisuals])

    // when user marks unpurchased visual as favourite, we track length of these unpurchased favourited to display/hide the title of section
    const unPurchasedFavoritedVisualsLength = useMemo(() => (
      [...favoritedVisuals].filter(visual => !purchasedVisualsKeys.has(visual))
        .reduce((unpurchasedSet, visual) => unpurchasedSet.add(visual), new Set())
        .size
    ), [favoritedVisuals, purchasedVisualsKeys])

    // Debounce downloadVisuals
    useEffect(() => {
      debounceEffect(
        () => setDownloadVisuals(downloadVisualsSelector),
        downloadVisualsDebounceTimeoutRef,
        100,
        downloadVisualsDebounceTimeoutStartRef,
        150
      )
    }, [downloadVisualsSelector])

    // Download new visuals when visual list changes
    useEffect(() => {
      if (isVirtualVisit) return
      if (!allVisuals) return
      if (allVisuals.state !== APIRequestState.OK) return
      if (!allVisuals.data) return
      if (!allVisuals.data.visuals) return
      const allVisualsList = allVisuals.data.visuals
      const visualsList = currentAdjustedOrder === AvailableOrderings.ASCENDING ? allVisualsList : allVisualsList.reverse()
      const expiredTime = Date.now() - (1000 * 60 * 50)
      const delay = 200
      const webDelay = visualsList.length * 100
      const step = 50
      let counter = 0
      for (const visual of visualsList) {
        const originalName = visual.originalName && (roles.isAdmin || roles.isCreative) ? visual.originalName : undefined
        const thumbnail = downloadVisualsRef.current?.[visual.name]?.[thumbnailType]
        const web = downloadVisualsRef.current?.[visual.name]?.[webType]

        if (!thumbnail || thumbnail.lastUpdated <= expiredTime) {
          setTimeout(() => {
            dispatch(downloadVisual(assignmentId, visual.name, thumbnailType, originalName))
          }, delay + counter * step)
          counter++
        }

        if (!web || web.lastUpdated <= expiredTime) {
          setTimeout(() => {
            dispatch(downloadVisual(assignmentId, visual.name, webType, originalName))
          }, webDelay + delay + counter * step)
          counter++
        }
      }
    }, [AvailableOrderings, allVisuals, currentAdjustedOrder, dispatch, downloadVisualsRef, isVirtualVisit, assignmentId, roles, thumbnailType, webType])

    return (
      <GalleryVisualsContext.Provider
        value={{
          uploadVisuals,
          downloadVisualsSelector,
          downloadVisuals,
          downloadVisualsRef,
          downloadVisualsEntries,
          uploadVisualsEntries,
          downloadVisualsKeys,
          uploadVisualsKeys,
          downloadVisualsCount,
          uploadVisualsCount,
          uploadVisualsKeyToOriginalNameDictionary,
          downloadVisualsKeyToOriginalNameDictionary,
          uploadVisualsOriginalFilenames,
          favoritedVisuals,
          setFavoritedVisuals,
          visualsKeys,
          visualsCount,
          listedVisuals,
          listedVisualsKeyToOriginalNameDictionary,
          visualsKeyToOriginalNameDictionary,
          downloadVisualsWithErrorEntries,
          unPurchasedFavoritedVisualsLength,
          visualMetadataMap
        }}
      >
        {children}
      </GalleryVisualsContext.Provider>
    )
  }
