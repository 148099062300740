import { AddressStep, CategoryStep, ClientStep, InstructionStep, ProductStep, ValidationStep } from '../steps'
import { Box, Stack } from '@mui/material'
import { DynamicFlowStepper, DynamicFlowView, useDynamicFlowController } from 'components/common/DynamicFlow'
import { PurchaseFlowStepKey, usePurchaseFlowUtilities } from './contexts'
import React, { useCallback, useEffect } from 'react'

import { ActionPage } from 'components/common/ActionPage'
import { BillingStep } from '../steps/Billing'
import { Cart } from '../common'
import { PurchaseFlowFooter } from './PurchaseFlowFooter.component'
import { useAuth0 } from 'utils/auth'
import { useTranslation } from 'react-i18next'

const stepsWithCart = new Set<string>([
  PurchaseFlowStepKey.PRODUCT,
  PurchaseFlowStepKey.PROPERTY_INFORMATION,
  PurchaseFlowStepKey.FLOOR_PLAN_CERTIFICATION,
  PurchaseFlowStepKey.POWER_OF_ATTORNEY,
  PurchaseFlowStepKey.FLOOR_PLAN_CONFIG,
  PurchaseFlowStepKey.STYLE_SECTION,
  PurchaseFlowStepKey.INSTRUCTIONS,
  PurchaseFlowStepKey.BILLING,
  PurchaseFlowStepKey.VALIDATION,
  PurchaseFlowStepKey.MEASUREMENT_REQUIREMENTS,
  PurchaseFlowStepKey.CONSUMPTION_CERTIFICATE,
  PurchaseFlowStepKey.ENERGY_CERTIFICATE_OF_NEED,
])

/**
 * @component
 * Controller for the purchase flow displaying the steps header and action footer.
 *
 * @example <PurchaseFlowController />
 */
export const PurchaseFlowController: React.FC = () => {
  const { roles } = useAuth0()
  const { t } = useTranslation(['purchase_flow'])
  const { exitPurchaseFlow } = usePurchaseFlowUtilities()

  const flowCtrl = useDynamicFlowController()

  // Create base PF flow scaffold
  useEffect(() => {

    if (roles.isAdmin) {
      flowCtrl.createEntryNode(
        PurchaseFlowStepKey.CLIENT,
        {
          component: <ClientStep />,
          hasFooter: true,
          type: 'main',
        }
      )

      flowCtrl.insertAfter(
        PurchaseFlowStepKey.CLIENT,
        PurchaseFlowStepKey.ADDRESS,
        {
          component: <AddressStep />,
          hasFooter: true,
          type: 'main',
        }
      )
    } else {
      flowCtrl.createEntryNode(
        PurchaseFlowStepKey.ADDRESS,
        {
          component: <AddressStep />,
          hasFooter: true,
          type: 'main',
        }
      )
    }

    flowCtrl.insertAfter(
      PurchaseFlowStepKey.ADDRESS,
      PurchaseFlowStepKey.CATEGORY,
      {
        component: <CategoryStep />,
        hasFooter: true,
        type: 'main',
      }
    )
    flowCtrl.insertAfter(
      PurchaseFlowStepKey.CATEGORY,
      PurchaseFlowStepKey.PRODUCT,
      {
        component: <ProductStep />,
        hasFooter: true,
        type: 'main',
      }
    )
    flowCtrl.insertAfter(
      PurchaseFlowStepKey.PRODUCT,
      PurchaseFlowStepKey.INSTRUCTIONS,
      {
        component: <InstructionStep />,
        hasFooter: true,
        type: 'main',
      }
    )
    flowCtrl.insertAfter(
      PurchaseFlowStepKey.INSTRUCTIONS,
      PurchaseFlowStepKey.BILLING,
      {
        component: <BillingStep />,
        hasFooter: true,
        type: 'main',
      }
    )
    flowCtrl.insertAfter(
      PurchaseFlowStepKey.BILLING,
      PurchaseFlowStepKey.VALIDATION,
      {
        component: <ValidationStep />,
        hasFooter: true,
        type: 'main',
      }
    )

    // No dependencies so it runs only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleExit = useCallback(async () => {
    exitPurchaseFlow(true)
  }, [exitPurchaseFlow])

  return (
    <ActionPage
      id="purchase-flow"
      title={t('header_title')}
      customStepper={<DynamicFlowStepper stepNameTranslatorFnc={(key) => t(`steps.${key}`)} />}
      onClose={handleExit}
      footerChildren={flowCtrl.currentNode?.hasFooter && <PurchaseFlowFooter />}
    >
      <Stack
        width="100%"
        alignItems="center"
        height="100%"
        padding="0 4rem"
        boxSizing="border-box"
      >
        <Stack
          direction="row"
          flexWrap="wrap"
          height="100%"
          width="100%"
          gap={2}
          maxWidth={{
            xs: '95%',
            sm: '1600px',
          }}
        >

          <Box
            flex="1 0 350px"
            height="100%" sx={{ overflowY: 'auto' }}
            paddingLeft={4}
            paddingRight={2}
            paddingTop={8}
            boxSizing="border-box"
          >
            <DynamicFlowView />
          </Box>

          {!!flowCtrl.currentNode && stepsWithCart.has(flowCtrl.currentNode.key) &&
            <Box
              flex="0 0 300px"
              height="100%"
              paddingTop={8}
              paddingBottom={2}
              sx={{ overflowY: 'auto' }}
              boxSizing="border-box"
            >
              <Cart />
            </Box>
          }

        </Stack>
      </Stack>
    </ActionPage>
  )
}
