import { Power2, gsap } from 'gsap'
import React, { FC, MouseEventHandler, ReactNode, useCallback, useEffect, useRef } from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'

import Button from 'components/common/Button/Button'
import Icon from 'components/common/Icon/Icon'
import { IconType } from 'constants/assets'
import { KeyboardEventKey } from 'constants/misc'
import { MUIDivider } from 'components/common/MUIDivider/MUIDivider.component'
import { QueryDataType } from 'components/common/QueryStatus'
import classnames from 'classnames'
import closeButtonStyles from 'components/styles/close-button.module.sass'
import { createPortal } from 'react-dom'
import styles from './Modal.module.sass'
import { useStateRef } from 'utils/hooks'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** Modal id */
  id?: string
  /** The additional classes to append */
  className?: string
  /** The additional classes to append to the modal content element */
  modalContentClassName?: string
  /** The additional classes to append to the modal's scrollable content element */
  scrollableContentClassName?: string
  /** Decides when is modal open */
  isOpen: boolean,
  /** Modal title */
  title?: string
  /** Modal subtitle */
  subtitle?: string
  /** Onclick action triggered when user clicks outside the modal content element */
  onClickOutside?: (e: React.MouseEvent) => void
  /** onKeyDown action triggered when user presses any key on the keyboard */
  onKeyDown?: (e: React.KeyboardEvent) => void
  /** onClick action to close Modal */
  onClose?: (e: React.MouseEvent) => void
  /** Timeline animation effect for enter transition */
  enterEffect?: { (node: HTMLElement, appears: boolean): void }
  /** Timeline animation effect for exit transition */
  exitEffect?: { (node: HTMLElement): void }
  /** Action to be triggered after modal is visibly closed */
  afterClosed?: () => void
  /** Timeline for enter transition */
  timelineEnter?: gsap.core.Timeline
  /** Timeline for exit transition */
  timelineExit?: gsap.core.Timeline
  /** Timeout for enter transition, default=600 */
  timeoutEnter?: number
  /** Timeout for exit transition, default=300 */
  timeoutExit?: number
  /** Whether title and body is divided */
  hasHeaderContentDivider?: boolean
  /** Footer content component with custom styles */
  footerContent?: ReactNode
  /** Whether click events propagation should be disabled or not (default false) */
  stopClickEventPropagation?: boolean
  /** React query call data */
  queryData?: QueryDataType
  /** Extra content displayed in header under title/subtitle (if provided) */
  extraHeaderContent?: ReactNode
  children?: ReactNode
}

/**
 * @component Display overlay popup with highly customize content requiring user's interaction, normally use in showing multiple info or actions.
 * @example
 * <Modal isOpen={true}>
 *  <span>Content of the modal</span>
 * </Modal>
 */
const Modal: FC<Props> = ({
  id,
  children,
  className = '',
  modalContentClassName = '',
  scrollableContentClassName,
  isOpen = false,
  title = '',
  subtitle,
  onClickOutside,
  onKeyDown,
  onClose,
  timelineEnter = gsap.timeline({ paused: true }),
  timelineExit = gsap.timeline({ paused: true }),
  enterEffect = (node: HTMLElement, appears: boolean) => {
    if (!node) return
    if (node.nodeType === node.TEXT_NODE) return
    timelineEnter.totalProgress(1).clear(true).totalProgress(0)
    timelineEnter.fromTo(node, { autoAlpha: 0, ease: Power2.easeIn }, { autoAlpha: 1, ease: Power2.easeIn, duration: 0.3 }, 0)
    timelineEnter.play()
  },
  exitEffect = (node: HTMLElement) => {
    if (!node) return
    if (node.nodeType === node.TEXT_NODE) return
    timelineExit.totalProgress(1).clear(true).totalProgress(0)
    timelineExit.fromTo(node, { autoAlpha: 1, ease: Power2.easeIn }, { autoAlpha: 0, ease: Power2.easeIn, duration: 0.3 }, 0)
    timelineExit.play()
  },
  timeoutEnter = 600,
  timeoutExit = 300,
  afterClosed,
  hasHeaderContentDivider = false,
  stopClickEventPropagation = false,
  extraHeaderContent,
  footerContent,
  queryData
}) => {
  const modalContentRef = useRef<HTMLDivElement>(null)
  const isOpenRef = useStateRef(isOpen)

  /** action called upon clicking anywhere */
  const clickAction: MouseEventHandler<HTMLDivElement> = useCallback((e: any) => {

    if (stopClickEventPropagation) e.stopPropagation()

    // Modal is not opened
    if (!isOpenRef.current) return

    // Clicked inside the modal content
    if (modalContentRef.current?.contains(e.target)) return

    // onClickOutside action exists
    if (onClickOutside) onClickOutside(e)
  }, [stopClickEventPropagation, isOpenRef, onClickOutside])

  /** action called upon pressing any keyboard key, with default close action in pressing Esc */
  const keyAction = useCallback((e: any) => {
    // Modal is not opened
    if (!isOpenRef.current) return

    // Customize onKeyDown actions
    if (onKeyDown) return onKeyDown(e)
    // Default Esc keyDown to close Modal
    if (e.key === KeyboardEventKey.ESCAPE) {
      onClickOutside && onClickOutside(e)
    }
  }, [isOpenRef, onKeyDown, onClickOutside])


  // Bind key press event listeners
  useEffect(() => {
    document.addEventListener('keydown', keyAction)

    return () => {
      document.removeEventListener('keydown', keyAction)
    }
  }, [keyAction])

  const handleClose = useCallback((node: HTMLElement) => {
    if (!!afterClosed) setTimeout(afterClosed, timeoutExit)
    exitEffect(node)
  }, [afterClosed, exitEffect, timeoutExit])

  return createPortal((
    <TransitionGroup component={null}>
      {isOpen &&
        <Transition
          appear={true}
          in={isOpen}
          onEnter={(node, appears) => enterEffect(node, appears)}
          onExit={(node) => handleClose(node)}
          timeout={{ enter: timeoutEnter, exit: timeoutExit }}
        >
          <div id={id} className={`${styles.modal} ${className} ${!!footerContent && styles.footer}`.trim()} onClick={clickAction}>
            <div className={`${styles.modalContent} ${modalContentClassName}`.trim()} ref={modalContentRef}>

              {!!onClose &&
                <Button className={closeButtonStyles.closeBtnRight} type="secondary noborder" onClick={onClose}>
                  <Icon icon={IconType.CROSS} />
                </Button>
              }

              {(!!title || !!subtitle || !!extraHeaderContent) &&
                <div className={styles.header}>

                  {!!title &&
                    <div className={classnames(
                      styles.title,
                      { [styles.hasSubtitle]: !!subtitle }
                    )}>
                      {title}
                    </div>
                  }

                  {!!subtitle &&
                    <div className={styles.subtitle}>
                      {subtitle}
                    </div>
                  }

                  {extraHeaderContent}

                  {hasHeaderContentDivider && <MUIDivider />}

                </div>
              }

              <div className={classnames(styles.scrollableContent, scrollableContentClassName)}>
                {children}
              </div>

              {!!footerContent &&
                <div className={styles.footer}>
                  {!(queryData?.isSuccess || queryData?.error) && <MUIDivider margin={8} />}
                  {footerContent}
                </div>
              }

            </div>
          </div>
        </Transition>
      }
    </TransitionGroup>
  ), document.querySelector('.App') || document.getElementById('root') || document.body)
}

export default Modal
