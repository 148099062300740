import { AssignmentQueryKeys, useCalculateCreativeRenumeration, useGetCreativeSearch } from 'dataQueries'
import { Badge, CreativeStatusBadge } from '../Badges'
import { EntityKeys, QueryType, queryClient } from 'utils/reactQuery'
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import AssignChosenCreativeDetail from '../AssignChosenCreativeDetail/AssignChosenCreativeDetail'
import AssignCreativeDetail from '../AssignCreativeDetail/AssignCreativeDetail'
import { AssignmentDTO } from 'models/assignment'
import { AssignmentDTOIsAdministratorDTO } from 'utils/typeguards'
import { CreativeAvailabilityStatus } from 'constants/creative'
import { CreativeState } from 'constants/assignment'
import { DEBOUNCE_TIMEOUT } from 'constants/application'
import Dropdown from '../Dropdown/Dropdown'
import DynamicQueryContent from '../DynamicQueryContent/DynamicQueryContent'
import { EventTime } from '../EventTime/EventTime'
import { KeyboardEventKey } from 'constants/misc'
import Modal from '../Modals/Modal/Modal'
import { SuggestedCreativeDTO } from 'models/creative'
import TransitionAppear from '../TransitionAppear/TransitionAppear'
import { bigFromFee } from 'utils/price'
import classnames from 'classnames'
import { debounceEffect } from 'utils/helpers'
import gridStyles from '../../styles/grid.module.sass'
import { isProductWithCT } from 'utils/product'
import popupStyles from '../../styles/popup.module.sass'
import searchStyles from '../../styles/search.module.sass'
import styles from '../AssignCreative/AssignCreative.module.sass'
import { useCreativeAssignmentCard } from 'components/pages/Gallery/common'
import { useDispatch } from 'react-redux'
import { useGalleryAssignment } from 'components/pages/Gallery/_main/contexts'
import { useTimezone } from 'components/contexts/timezone.context'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** The additional classes to append */
  className?: string
  /** Decides when is modal open */
  isOpen: boolean
  /** The assignment */
  assignment: AssignmentDTO
  /** Whether the suggestion call should be addeed to history on BE (TODO: temporary flag workaround) */
  addToSuggestionHistory?: boolean
  /** Onclick action triggered when user clicks outside the .modal-content element */
  onClickOutside?: (e: React.MouseEvent) => unknown
  /** onKeyDown action triggered when user presses any key on the keyboard */
  onKeyDown?: (e: React.KeyboardEvent) => unknown
  /** Onclick action triggered when user clicks the close button */
  onClickClose?: (e: React.MouseEvent) => unknown
  /** Function called after creative selected with successful response */
  callbackAfterSuccessfulResponse?: () => void
}

/**
 * @component Assign creative in a popup for admin
 * @example
 * <AssignCreativePopup isOpen={true} assignment={assignment} />
 */
export const AssignCreativePopup: React.FC<Props> = ({
  className = '',
  isOpen,
  assignment,
  addToSuggestionHistory = true,
  onClickOutside,
  onKeyDown,
  onClickClose,
  callbackAfterSuccessfulResponse,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['assign_creative'])

  const { userTimezone } = useTimezone()
  const { selectedFavouriteCreativeId } = useGalleryAssignment()
  const { suggestedCreatives, suggestedCreativesData } = useCreativeAssignmentCard()

  // Suggested creatives
  const sortedSuggestedCreatives = useMemo(() => suggestedCreativesData?.sort((creativeA, creativeB) => bigFromFee(creativeA.transportRemuneration).minus(bigFromFee(creativeB.transportRemuneration)).toNumber()), [suggestedCreativesData])

  // Selected creative from search
  const [selectedSearchedCreativeId, setSelectedSearchedCreativeId] = useState<string>('')
  const searchedCreativeRemuneration = useCalculateCreativeRenumeration(assignment.id, selectedSearchedCreativeId)
  const selectedSearchedCreative = searchedCreativeRemuneration?.data?.data

  // Preferred creative
  const preferredCreativeRemuneration = useCalculateCreativeRenumeration(assignment.id, selectedFavouriteCreativeId ?? '')
  const preferredCreativeResponse = useMemo(() => !!selectedFavouriteCreativeId && preferredCreativeRemuneration.isFetched
    ? preferredCreativeRemuneration
    : undefined
    , [preferredCreativeRemuneration, selectedFavouriteCreativeId])
  const preferredCreative = useMemo(() => preferredCreativeResponse?.data?.data, [preferredCreativeResponse])
  const isPreferredStatusDeclined = useMemo(() => !!preferredCreative && preferredCreative.availabilityStatus === CreativeAvailabilityStatus.DECLINED, [preferredCreative])

  // Searching a creative
  const [searchCreative, setSearchCreative] = useState('')
  const [processedSearchCreative, setProcessedSearchCreative] = useState('')
  const debounceSearchCreativeTimeoutRef = useRef<number | undefined>(undefined)

  // Searched creatives
  const searchedCreatives = useGetCreativeSearch(assignment.id, searchCreative)
  const searchedCreativesData = useMemo(() => processedSearchCreative ? searchedCreatives.data?.data.content : [], [processedSearchCreative, searchedCreatives.data?.data.content])

  // Chosen creative
  const [chosenCreative, setChosenCreative] = useState<SuggestedCreativeDTO | null>(null)
  const [isAssignChosenCreativeDetailDisplayed, setIsAssignChosenCreativeDetailDisplayed] = useState(false)

  // Show date
  const hasProductWithCT = useMemo(() => !!assignment && !!assignment.shootingStartDateTime && assignment.products.some(product => isProductWithCT(product)), [assignment])

  const assignedCT = useMemo(() => {
    if (!AssignmentDTOIsAdministratorDTO(assignment)) return null
    if (assignment.creativeState === CreativeState.UNASSIGNED) return null
    return assignment.creative
  }, [assignment])

  useEffect(() => {
    if (isOpen) suggestedCreatives.refetch()
    // Only trigger once when refetch otherwise goes to infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    // Validates if the order has a preferred creative selected by the client
    if (isOpen && selectedFavouriteCreativeId && !preferredCreativeRemuneration.isFetched) {
      preferredCreativeRemuneration.refetch()
    }
  }, [isOpen, dispatch, assignment, selectedFavouriteCreativeId, selectedSearchedCreativeId, preferredCreativeRemuneration])

  useEffect(() => {
    // Calculates the remuneration of searched creatives
    if (isOpen && selectedSearchedCreativeId && !searchedCreativeRemuneration.isFetched) {
      searchedCreativeRemuneration.refetch()
    }
  }, [isOpen, dispatch, assignment, selectedFavouriteCreativeId, selectedSearchedCreativeId, preferredCreativeRemuneration, searchedCreativeRemuneration])


  // Debounce search creative filter
  useEffect(() => {
    if (searchCreative) {
      debounceEffect(() => {
        searchedCreatives.refetch()
        setProcessedSearchCreative(searchCreative)
      }, debounceSearchCreativeTimeoutRef, DEBOUNCE_TIMEOUT)
    }
    // Skip RQ searchedCreatives function to prevent infinity fetching.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignment.id, searchCreative])

  const onChoose = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
    setTimeout(() => {
      setIsAssignChosenCreativeDetailDisplayed(true)
    }, 200)
  }, [])

  const onBack = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
    setChosenCreative(null)
    setTimeout(() => {
      setIsAssignChosenCreativeDetailDisplayed(false)
    }, 200)
  }, [])

  const resetState = useCallback(() => {
    setSearchCreative('')
    setProcessedSearchCreative('')
    setChosenCreative(null)
    setIsAssignChosenCreativeDetailDisplayed(false)
    queryClient.resetQueries({ queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET_ONE, AssignmentQueryKeys.SEARCH_CREATIVE] })
    if (selectedFavouriteCreativeId) {
      queryClient.resetQueries({ queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET_ONE, AssignmentQueryKeys.CALCULATE_REMUNERATION, { assignmentId: assignment.id, creativeId: selectedFavouriteCreativeId ?? '' }] })
    }
    if (selectedSearchedCreativeId) {
      setSelectedSearchedCreativeId('')
      queryClient.resetQueries({ queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET_ONE, AssignmentQueryKeys.CALCULATE_REMUNERATION, { assignmentId: assignment.id, selectedSearchedCreativeId }] })
    }
  }, [assignment.id, selectedFavouriteCreativeId, selectedSearchedCreativeId])

  const handleSuccessfullResponseCallback = useCallback(() => {
    resetState()
    if (callbackAfterSuccessfulResponse) callbackAfterSuccessfulResponse()
  }, [callbackAfterSuccessfulResponse, resetState])

  const onOutsideOrClose = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
    if (onClickOutside) onClickOutside(e)
    if (onClickClose) onClickClose(e)
    resetState()
  }, [onClickOutside, onClickClose, resetState])

  const onKeyboardEvent = useCallback((e: React.KeyboardEvent) => {
    if (onKeyDown) onKeyDown(e)
    if (onKeyDown && e.key === KeyboardEventKey.ESCAPE) {
      resetState()
    }
  }, [onKeyDown, resetState])

  return (
    <Modal
      className={`assign-creative-popup ${className}`.trim()}
      modalContentClassName={!isAssignChosenCreativeDetailDisplayed ? popupStyles.modalContent : popupStyles.modalContentSmall}
      isOpen={isOpen}
      onClickOutside={onOutsideOrClose}
      onKeyDown={onKeyboardEvent}
      onClose={onOutsideOrClose}
    >
      <div className={popupStyles.inside}>
        <div className={styles.line}>
          <h3 className={styles.heading}>{t('assign_creative')}</h3>
        </div>
        {hasProductWithCT && !!assignment.shootingStartDateTime &&
          <div className={styles.line}>
            <EventTime
              eventStart={assignment.shootingStartDateTime}
              eventDuration={assignment.shootingDuration}
              eventTimezone={assignment.timezone}
              userTimezone={userTimezone}
            />
          </div>
        }
        <div className={styles.midline}></div>
        {preferredCreativeResponse &&
          <DynamicQueryContent
            query={preferredCreativeResponse}
          >
            <Fragment>
              {!isAssignChosenCreativeDetailDisplayed &&
                <div className={popupStyles.creatives}>
                  {preferredCreative && (
                    <Fragment>
                      <div className={styles.line}>
                        <h3 className={styles.heading}>{t('preferred_creative')}</h3>
                      </div>
                      <div className={classnames(popupStyles.creativeList, popupStyles.selectedByClient, gridStyles.grid)} >
                        <div className={classnames(popupStyles.borderWrapper, isPreferredStatusDeclined ? popupStyles.redBorder : popupStyles.defaultBorder)}>
                          <AssignCreativeDetail
                            columnNumber={1}
                            creativeData={preferredCreative}
                            chosenCreative={chosenCreative}
                            isAlreadyAssigned={assignedCT?.creativeId === preferredCreative.id}
                            onClickChoose={(e) => {
                              setChosenCreative(preferredCreative)
                              onChoose(e)
                            }}
                          />
                        </div>
                      </div>
                      <div className={styles.midline}></div>
                    </Fragment>
                  )}
                </div>
              }
            </Fragment>
          </DynamicQueryContent>
        }
        {suggestedCreatives &&
          <DynamicQueryContent query={suggestedCreatives}>
            {!isAssignChosenCreativeDetailDisplayed &&
              <div className={popupStyles.creatives}>
                {!!sortedSuggestedCreatives && sortedSuggestedCreatives.length > 0 && (
                  <Fragment>
                    <div className={styles.line}>
                      <h3 className={styles.heading}>{t('suggested_creatives')}</h3>
                    </div>
                    <div className={classnames(popupStyles.creativeList, gridStyles.grid)} >
                      {sortedSuggestedCreatives?.map((creative, index) => {
                        return (
                          <Fragment key={`${index}-${creative.id}`}>
                            <AssignCreativeDetail
                              columnNumber={index + 1}
                              creativeData={creative}
                              isAlreadyAssigned={assignedCT?.creativeId === creative.id}
                              chosenCreative={chosenCreative}
                              onClickChoose={e => {
                                setChosenCreative(sortedSuggestedCreatives[index])
                                onChoose(e)
                              }}
                            />
                          </Fragment>
                        )
                      })}
                    </div>
                  </Fragment>
                )}
              </div>
            }
          </DynamicQueryContent>
        }
        {/* REMUNERATION DETAIL */}
        {isAssignChosenCreativeDetailDisplayed &&
          <TransitionAppear visible={!!chosenCreative}>
            {!!chosenCreative &&
              <AssignChosenCreativeDetail
                assignmentId={assignment.id}
                creativeData={chosenCreative}
                onClickBack={e => onBack(e)}
                callbackAfterSuccessfulResponse={handleSuccessfullResponseCallback}
              />
            }
          </TransitionAppear>
        }
        {!isAssignChosenCreativeDetailDisplayed &&
          <Fragment>
            <div className={styles.midline}></div>
            <div className={styles.line}>
              <h3 className={styles.heading}>{t('search_creative')}</h3>
            </div>
            <div className={classnames(searchStyles.searchWrap, gridStyles.grid)}>
              <div className={classnames(searchStyles.searchBox, gridStyles.row1, gridStyles.column1)}>
                <label htmlFor="search_creative">{t('search_different_creative')}</label>
                <Dropdown
                  innerMenuClassName={searchStyles.creativeSuggestion}
                  closeAfterButtonClick={false}
                  button={(isOpen, action) => (
                    <input
                      type="search"
                      name="search_creative"
                      id="search_creative"
                      placeholder={t('search_creative_placeholder')}
                      value={searchCreative}
                      onChange={e => setSearchCreative(e.target.value)}
                      autoComplete="off"
                      onClick={action}
                    />
                  )}
                >
                  {!!searchCreative && !!processedSearchCreative &&
                    <Fragment>
                      {searchedCreativesData?.map(creative => {
                        return (
                          <button
                            key={creative.id}
                            onClick={() => {
                              setSelectedSearchedCreativeId(creative.id)
                            }}
                          >
                            <div className={searchStyles.line}>
                              <span className={searchStyles.marginLeft}>{creative.name}</span>
                              {creative.id !== assignedCT?.creativeId
                                ?
                                <CreativeStatusBadge
                                  className={searchStyles.marginLeft}
                                  creativeStatus={creative.availabilityStatus}
                                />
                                :
                                <Badge color="orange" className={searchStyles.marginLeft}>
                                  {t('currently_assigned')}
                                </Badge>
                              }
                            </div>
                          </button>
                        )
                      })}
                    </Fragment>
                  }
                </Dropdown>
              </div>
            </div>
            {!!processedSearchCreative &&
              <Fragment>
                <div className={styles.midline}></div>
                <div className={classnames(gridStyles.grid, styles.searchedCreativeWrap)}>
                  {selectedSearchedCreativeId && selectedSearchedCreative &&
                    <AssignCreativeDetail
                      columnNumber={1}
                      creativeData={selectedSearchedCreative}
                      isAlreadyAssigned={assignedCT?.creativeId === selectedSearchedCreative.id}
                      chosenCreative={chosenCreative}
                      onClickChoose={e => {
                        setChosenCreative(selectedSearchedCreative)
                        onChoose(e)
                      }}
                    />
                  }
                </div>
              </Fragment>
            }
          </Fragment>
        }
      </div>

    </Modal>
  )
}
