import { ChangeMemberData, ChangeMemberType } from '../_main/WorkspacesProfile.controller'
import { Fragment, useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Button from 'components/common/Button/Button'
import Icon from 'components/common/Icon/Icon'
import { IconType } from 'constants/assets'
import { KeyboardEventKey } from 'constants/misc'
import Modal from 'components/common/Modals/Modal/Modal'
import { Nullable } from 'models/helpers/helperTypes'
import { QueryStatus } from 'components/common/QueryStatus'
import { TIMEOUT_AFTER_SUCCESSFUL_CALLBACK } from 'constants/application'
import classnames from 'classnames'
import closeButtonStyles from 'components/styles/close-button.module.sass'
import { logAnalyticsEvent } from 'utils/analytics'
import popupStyles from 'components/styles/popup.module.sass'
import styles from './ChangeMemberRolePopup.module.sass'
import { useChangeWorkspaceMemberRole } from 'dataQueries'
import { useDispatch } from 'react-redux'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Decides when is modal open */
  isOpen: boolean
  /** Workspace ID */
  workspaceId: string
  /** Member info for action and confirmation popup values */
  changeMemberRole: Nullable<ChangeMemberData>
  /** Onclick action triggered when user clicks outside the .modal-content element or clicks the close button */
  onClickOutsideOrClose?: (e: React.MouseEvent) => unknown
  /** onKeyDown action triggered when user presses any key on the keyboard */
  onKeyDown?: (e: React.KeyboardEvent) => unknown
  /** Function called after a member's role changed with successful response */
  callbackAfterSuccessfulResponse?: () => void
}

/**
 * @component Change member role confirmation popup
 * @example
 * <ChangeMemberRolePopup isOpen={true} workspaceId={workspaceId} memberEmail={email} newRole={role} />
 */
export const ChangeMemberRolePopup: React.FC<Props> = ({
  isOpen,
  workspaceId,
  changeMemberRole,
  onClickOutsideOrClose,
  onKeyDown,
  callbackAfterSuccessfulResponse,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['workspace_profile'])
  const changeWorkspaceMemberRole = useChangeWorkspaceMemberRole()
  const translationRole = t(`role.${changeMemberRole?.newRole}`)
  const translationAccess = changeMemberRole?.isRestricted ? t('restricted_access') : t('full_access')

  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false)
  const { currentUserWorkspace } = useUserData()

  const changeMemberSettingText = useMemo(() => {
    switch (changeMemberRole?.type) {
      case ChangeMemberType.ROLE:
        return {
          title: 'member_role_change_popup',
          prompt: 'member_role_change_prompt',
          successMessage: 'member_role_changed_success',
          errorMessage: 'member_role_change_error',
          analyticsTitle: 'Switch Role',
          switchTo: translationRole
        }
      case ChangeMemberType.ACCESS:
        return {
          title: 'member_access_change_popup',
          prompt: 'member_access_change_prompt',
          successMessage: 'member_access_changed_success',
          errorMessage: 'member_access_change_error',
          analyticsTitle: 'Switch Access Setting',
          switchTo: translationAccess
        }
      default:
        return {
          title: '',
          prompt: '',
          successMessage: '',
          errorMessage: '',
          analyticsTitle: ''
        }
    }
  }, [changeMemberRole, translationAccess, translationRole])

  const onOutsideOrClose = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
    if (onClickOutsideOrClose) onClickOutsideOrClose(e)
    changeWorkspaceMemberRole.reset()
  }, [changeWorkspaceMemberRole, onClickOutsideOrClose])

  const onKeyboardEvent = useCallback((e: React.KeyboardEvent) => {
    if (onKeyDown) onKeyDown(e)
    if (onKeyDown && e.key === KeyboardEventKey.ESCAPE) {
      changeWorkspaceMemberRole.reset()
    }
  }, [changeWorkspaceMemberRole, onKeyDown])

  const onConfirmChange = (e: React.MouseEvent<Element, MouseEvent>) => {
    setIsConfirmDisabled(true)
    changeWorkspaceMemberRole.mutate({ workspaceId, auth0Id: changeMemberRole?.memberId!, role: changeMemberRole?.newRole!, isRestricted: changeMemberRole?.isRestricted! })
    logAnalyticsEvent(changeMemberSettingText.analyticsTitle, {
      switchedUserEmail: changeMemberRole?.email,
      planId: workspaceId,
      switchTo: changeMemberSettingText.switchTo,
      planName: currentUserWorkspace?.name
    })
  }

  useLayoutEffect(() => {
    if (!callbackAfterSuccessfulResponse) return
    if (changeWorkspaceMemberRole.isSuccess) {
      window.setTimeout(() => {
        callbackAfterSuccessfulResponse()
        changeWorkspaceMemberRole.reset()
      }, TIMEOUT_AFTER_SUCCESSFUL_CALLBACK)
    }
  }, [changeWorkspaceMemberRole, callbackAfterSuccessfulResponse, dispatch])

  return (
    <Modal
      className={styles.changeMemberRolePopup}
      isOpen={isOpen}
      modalContentClassName={classnames(popupStyles.modalContentSmall, styles.content)}
      onClickOutside={onOutsideOrClose}
      onKeyDown={onKeyboardEvent}
      title={t(changeMemberSettingText.title)}
    >

      <div className={popupStyles.inside}>

        {changeWorkspaceMemberRole.isIdle ?
          <Fragment>
            <p className={styles.text}>
              <Trans t={t} i18nKey={changeMemberSettingText.prompt} values={{ name: changeMemberRole?.name, email: changeMemberRole?.email, newRole: translationRole, newAccess: translationAccess }}>
                <span></span>
              </Trans>
            </p>

            <div className={classnames(styles.line, styles.buttonWrapper)}>
              <Button
                onClick={(e) => onConfirmChange(e)}
                disabled={isConfirmDisabled}
              >
                {t('confirm_role_change')}
              </Button>
            </div>

          </Fragment>
          :
          <QueryStatus
            query={changeWorkspaceMemberRole}
            successMessage={t(changeMemberSettingText.successMessage)}
            errorMessage={t(changeMemberSettingText.errorMessage)}
          />
        }

      </div>

      <div className={closeButtonStyles.closeWrap}>
        <Button
          type="secondary nobackground noborder"
          onClick={onOutsideOrClose}
        >
          <Icon icon={IconType.CROSS} />
        </Button>
      </div>
    </Modal>
  )
}
