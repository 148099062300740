import { BEIGE_600, GRAY_800, GRAY_900 } from 'constants/styling/theme'
import { FC, ReactNode, useCallback, useMemo } from 'react'
import { Stack, Typography } from '@mui/material'
import { VirtualFurnitureRoomRemovableOption, VirtualFurnitureRoomType } from 'constants/virtualFurniture'

import BedRoundedIcon from '@mui/icons-material/BedRounded'
import BorderAllRoundedIcon from '@mui/icons-material/BorderAllRounded'
import { ChildrenWithDivider } from 'components/common/ChildrenWithDivider'
import { CircleIcon } from 'components/common/CircleIcon'
import { ComputedStagingRoom } from 'models/virtualStaging'
import LightRoundedIcon from '@mui/icons-material/LightRounded'
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined'
import { RoomItemPopupType } from 'components/common/StagingFlow/main/contexts'
import { SelectedStagingItemPreview } from 'components/common/StagingFlow/SelectedStagingItemPreview'
import { useRoomAPI } from 'components/contexts/RoomAPI.context'
import { useTranslation } from 'react-i18next'

const SelectedItemDescription: FC<{ title: string, icon: ReactNode }> = ({
  title,
  icon
}) => (
  <Stack flexDirection="row" gap=".8rem" justifyContent="flex-start" alignItems="center" sx={{ color: GRAY_800 }}>
    <CircleIcon
      size="2.4rem"
      circleColor={BEIGE_600}
      icon={icon}
    />
    <Typography variant="text-sm" color={GRAY_900}>{title}</Typography>
  </Stack>
)

/** 
 * @component
 * StagingProductCardContent displaying selected room item list.
 * 
 * @example
 * <StagingProductCardContent />
 */
export const StagingProductCardContent: FC<{ productId: number }> = ({ productId }) => {
  const { t } = useTranslation(['style_selection_order_step', 'virtual_furniture_room_removable_option', 'virtual_furniture_room_type', 'common'])
  const { getAllRoomsOfProduct } = useRoomAPI()

  const selectedRooms = useMemo(() => getAllRoomsOfProduct(productId), [getAllRoomsOfProduct, productId])

  const getSelectedItemsOfRoom = useCallback((room: ComputedStagingRoom) => {
    const selectedItems: Array<ReactNode> = []

    if (room.hasSelectedAllStyleCodes) selectedItems.push(
      <SelectedItemDescription
        key={`${room.key}_${RoomItemPopupType.FURNITURE}`}
        title={t(`room_items.${RoomItemPopupType.FURNITURE}`)}
        icon={<BedRoundedIcon fontSize="inherit" />}
      />
    )

    if (room.decorations.size) selectedItems.push(
      <SelectedItemDescription
        key={`${room.key}_${RoomItemPopupType.DECORATION}`}
        title={t(`room_items.${RoomItemPopupType.DECORATION}`)}
        icon={<LightRoundedIcon fontSize="inherit" />}
      />
    )

    if (room.renovations.get(VirtualFurnitureRoomType.WALL_MATERIALS)) selectedItems.push(
      <SelectedItemDescription
        key={`${room.key}_${RoomItemPopupType.WALL}`}
        title={t(`room_items.${RoomItemPopupType.WALL}`)}
        icon={<PaletteOutlinedIcon fontSize="inherit" />}
      />
    )

    if (room.renovations.get(VirtualFurnitureRoomType.FLOORING)) selectedItems.push(
      <SelectedItemDescription
        key={`${room.key}_${RoomItemPopupType.FLOOR}`}
        title={t(`room_items.${RoomItemPopupType.FLOOR}`)}
        icon={<BorderAllRoundedIcon fontSize="inherit" />}
      />
    )

    return selectedItems
  }, [t])

  return (
    <ChildrenWithDivider dividerProps={{ margin: 12 }}>

      {selectedRooms.map((room) => {
        const roomTypes = room.roomTypes.toArray()
        const image = room.images[0].url

        return (
          <SelectedStagingItemPreview
            notEditable
            key={room.key}
            imageUrl={image}
            title={roomTypes.map((roomType) => t(`virtual_furniture_room_type:${roomType}`)).join(', ')}
            description={
              <Stack gap={1}>

                <Stack
                  direction="row"
                  gap="1rem"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  marginTop=".8rem"
                >
                  {getSelectedItemsOfRoom(room)}
                </Stack>

                {room.furnitureRemoval !== VirtualFurnitureRoomRemovableOption.NONE &&
                  <Typography variant="text-sm">
                    <strong>{t('common:Remove')}:</strong> {t(`virtual_furniture_room_removable_option:${room.furnitureRemoval}`)}
                  </Typography>
                }

                {!!room.comment &&
                  <Typography variant="text-sm">
                    <strong>{t('common:Comment')}:</strong> {room.comment}
                  </Typography>
                }

              </Stack>
            }
          />
        )
      })}

    </ChildrenWithDivider>
  )
}
