import { DependencyList, useCallback, useEffect, useRef, useState } from 'react'

import { debounceEffect } from './debounceEffect'

/**
 * Debounced useMemo hook
 * @param memoFunction - function whose output is to be memoized
 * @param deps - Dependency array
 * @param debounceTime - debounce time in milliseconds (default 500)
 * @param maxDebounceTime - maximum debounce time in milliseconds after which the effect is guaranteed to take place (default undefined)
 */
export function useDebouncedMemo<T>(
  memoFunction: () => T,
  deps: DependencyList,
  debounceTime: number = 500,
  maxDebounceTime?: number
): T {
  const debounceTimeoutRef = useRef<number | undefined>(undefined)
  const debounceTimeoutStartRef = useRef<number | undefined>(undefined)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoFunctionMemoized = useCallback(() => memoFunction, deps)

  const [value, setValue] = useState(memoFunctionMemoized())

  useEffect(() => {
    debounceEffect(() => {
      setValue(memoFunctionMemoized())
    },
      debounceTimeoutRef,
      debounceTime,
      maxDebounceTime ? debounceTimeoutStartRef : undefined,
      maxDebounceTime
    )
  }, [memoFunctionMemoized, debounceTime, maxDebounceTime])

  return value
}
