import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { ActionGetUpsellPaymentIntent } from './getUpsellPaymentIntent.actions'
import { GetUpsellPaymentIntentStore } from './getUpsellPaymentIntent.interface'

/** Reducer function for get upsell payment intent sate */
export const reduceGetUpsellPaymentIntent = (state: GetUpsellPaymentIntentStore, action: ActionGetUpsellPaymentIntent) => {
  const [eventType, dataType] = action.type

  if (dataType !== ActionTypeAPIData.GET_UPSELL_PAYMENT_INTENT) return state

  const { request, missionId } = action.payload

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
    case ActionTypeAPIEvent.FETCH:
    case ActionTypeAPIEvent.RECEIVED:
      return {
        [missionId]: request
      }
    default:
      return state
  }
}