import React, { useMemo, useRef, useState } from 'react'
import moment, { Moment } from 'moment-timezone'

import { AssignmentDTOAdministrator } from 'models/assignment'
import DatePicker from 'react-datepicker'
import { DealDTO } from 'models/deal'
import { IconType } from 'constants/assets'
import { MUIButton } from 'components/common/MUIButton'
import { QueryStatus } from 'components/common/QueryStatus'
import TransitionAppear from 'components/common/TransitionAppear/TransitionAppear'
import { getUserDatePickerLocale } from 'utils/localization'
import { getUserTimezone } from 'utils/time'
import { handleScroll } from 'utils/reactDatepicker'
import styles from './ManageAssignmentOrderDateTimeChange.module.sass'
import { useDateAndTime } from 'utils/hooks/useDateAndTime'
import { useTranslation } from 'react-i18next'
import { useUpdateStartDateTime } from 'dataQueries'

export interface Props {
  /** The deal data object */
  dealData: DealDTO
}

export const ManageAssignmentOrderDateTimeChange: React.FC<Props> = ({
  dealData,
}) => {
  const { t } = useTranslation(['manage_assignments_popup'])
  const updateOrderDateTime = useUpdateStartDateTime()

  const userBrowserTimezone = useMemo(() => getUserTimezone(undefined, undefined, true), [])
  const locationTimezone = useMemo(() => (dealData.missions[0]?.timezone) || userBrowserTimezone, [dealData, userBrowserTimezone])
  const orderDateTime = useMemo(() => {
    return (dealData.missions as AssignmentDTOAdministrator[]).reduce((lowestDate: null | Moment, item: AssignmentDTOAdministrator) => {
      if (!item.shootingStartDateTime) return lowestDate

      const assignmentShootingDate = moment(item.shootingStartDateTime).tz(locationTimezone)
      if (lowestDate === null || lowestDate > assignmentShootingDate) {
        return assignmentShootingDate
      }

      return lowestDate
    }, null)?.toDate()
  }, [dealData, locationTimezone])
  const {
    date,
    time,
    dateTime,
    onDateChange,
    onTimeChange,
  } = useDateAndTime(orderDateTime)

  const [isOrderTimeEditOpen, setIsOrderTimeEditOpen] = useState(false)
  const datePickerTimeRef = useRef<HTMLDivElement | null>(null)

  return (
    <div className={styles.box}>
      <div className={styles.info}>
        <p>{t('order_update_start_date_note')}</p>
        <MUIButton
          type="defaultPrimary"
          size="xs"
          onClick={_ => setIsOrderTimeEditOpen(!isOrderTimeEditOpen)}
        >
          {isOrderTimeEditOpen ? t('close_edit') : t('edit')}
        </MUIButton>
      </div>

      <TransitionAppear visible={isOrderTimeEditOpen}>
        <div className={styles.inputRow}>

          <div className={styles.input}>

            <label htmlFor={`date-input-${dealData.id}`}>{t('instruction_option_field:DATE')}</label>

            <DatePicker
              className={`withicon right ${IconType.DATE}`}
              name={`date-input-${dealData.id}`}
              id={`date-input-${dealData.id}`}
              selected={date}
              autoComplete="off"
              onChange={onDateChange}
              dateFormat="P"
              shouldCloseOnSelect={false}
              locale={getUserDatePickerLocale()}
            />

          </div>

          <div className={styles.input} ref={datePickerTimeRef}>

            <label htmlFor={`time-input-${dealData.id}`}>{t('instruction_option_field:TIME')}</label>

            <DatePicker
              className={`withicon right ${IconType.CLOCK}`}
              name={`time-input-${dealData.id}`}
              selected={time}
              autoComplete="off"
              onChange={onTimeChange}
              onChangeRaw={_ => handleScroll(datePickerTimeRef.current)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat="p"
              shouldCloseOnSelect={false}
              locale={getUserDatePickerLocale()}
            />

          </div>

          <MUIButton
            type="defaultPrimary"
            onClick={_ => updateOrderDateTime.mutate({ orderId: dealData.id, startDateTime: moment(dateTime).tz(locationTimezone, true).toISOString() })}
            disabled={updateOrderDateTime.isPending}
          >
            {t('submit')}
          </MUIButton>

          <QueryStatus
            query={updateOrderDateTime}
            successMessage={t('success')}
            spaceTopRem={1}
            onPurge={() => updateOrderDateTime.reset()}
          />

        </div>
      </TransitionAppear>
    </div>
  )
}
