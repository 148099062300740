import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { applyFeeDiscount, bigFromDecimal, formatPrice } from 'utils/price'
import { getTimeDurationStringFromMinutesAndHours, getUserTimezone, isSameTimezone } from 'utils/time'
import { useDeleteAssignmentDateTime, useEditAssignmentDateTime } from 'dataQueries'

import { AssignmentDTOClient } from 'models/assignment'
import DatePicker from 'react-datepicker'
import { EventTime } from 'components/common/EventTime/EventTime'
import Icon from 'components/common/Icon/Icon'
import { IconType } from 'constants/assets'
import { MUIButton } from 'components/common/MUIButton'
import { ProductCategory } from 'models/product'
import { QueryStatus } from 'components/common/QueryStatus'
import TransitionAppear from 'components/common/TransitionAppear/TransitionAppear'
import { chooseProductContainingVisuals } from 'utils/product'
import { getUserDatePickerLocale } from 'utils/localization'
import { handleScroll } from 'utils/reactDatepicker'
import { isEditingCategory } from 'utils/validators'
import moment from 'moment-timezone'
import styles from './ManageAssignmentsPopupItem.module.sass'
import { useActionPopup } from 'utils/hooks/useActionPopup'
import { useAuth0 } from 'utils/auth'
import { useDateAndTime } from 'utils/hooks/useDateAndTime'
import { useTimezone } from 'components/contexts/timezone.context'
import { useTranslation } from 'react-i18next'

/** @interface Props Describes properties of ManageAssignmentsPopupItem */
interface Props {
  assignment: AssignmentDTOClient
  category: ProductCategory
}

/**
 * @component Displays assignment item with dateTime editing inside ManageAssignmentsPopup
 * @example <ManageAssignmentsPopupItem assignment={assignmentData} category={dealProductCategory} />
*/
export const ManageAssignmentsPopupItem: React.FC<Props> = ({
  assignment,
  category,
}) => {

  const { t } = useTranslation(['manage_assignments_popup', 'instruction_option_field', 'product_kind', 'product'])
  const { roles } = useAuth0()
  const editAssignmentDateTime = useEditAssignmentDateTime()
  const deleteAssignmentDateTime = useDeleteAssignmentDateTime()

  const { showConfirm } = useActionPopup()
  const { userTimezone } = useTimezone()

  const memoizedNow = useMemo(() => moment.utc(), [])
  const userBrowserTimezone = useMemo(() => getUserTimezone(undefined, undefined, true), [])
  const locationTimezone = useMemo(() => (assignment && assignment.timezone) || userBrowserTimezone, [assignment, userBrowserTimezone])
  const locationTimezoneOffset = useMemo(() => moment.tz.zone(locationTimezone)?.utcOffset(memoizedNow.valueOf()) || 0, [locationTimezone, memoizedNow])
  const userTimezoneOffset = useMemo(() => moment.tz.zone(userBrowserTimezone)?.utcOffset(memoizedNow.valueOf()) || 0, [userBrowserTimezone, memoizedNow])
  const offsetMinutes = useMemo(() => locationTimezoneOffset - userTimezoneOffset, [locationTimezoneOffset, userTimezoneOffset])
  const initDateTime = useMemo(() => assignment && assignment.shootingStartDateTime
    ? moment(assignment.shootingStartDateTime).tz(locationTimezone).subtract(offsetMinutes, 'minutes').toDate()
    : undefined, [assignment, locationTimezone, offsetMinutes])
  const product = useMemo(() => chooseProductContainingVisuals(assignment.products), [assignment])
  const currency = useMemo(() => assignment.feePrice.currency, [assignment])
  const pricePerProduct = useMemo(() => bigFromDecimal(applyFeeDiscount(assignment.feePrice).value.value || 0), [assignment])
  const totalPrice = useMemo(() => product && pricePerProduct.times(product.quantity), [product, pricePerProduct])

  const [isTimeEditOpen, setIsTimeEditOpen] = useState(false)

  const datePickerTimeRef = useRef<HTMLDivElement | null>(null)
  const isInitialized = useRef<boolean>(false)

  const {
    date,
    time,
    dateTime,
    onDateChange,
    onTimeChange,
  } = useDateAndTime(initDateTime)

  useEffect(() => {
    if (!isInitialized.current) {
      isInitialized.current = true
    } else if (!isTimeEditOpen) {
      editAssignmentDateTime.reset()
    }
  }, [isTimeEditOpen, editAssignmentDateTime])

  const editBoxDateTime = (
    <TransitionAppear visible={isTimeEditOpen}>
      <div className={styles.editBox}>
        <div className={styles.inputRow}>

          <div className={styles.input}>

            <label htmlFor={`date-input-${assignment.id}`}>{t('instruction_option_field:DATE')}</label>

            <DatePicker
              className={`withicon right ${IconType.DATE}`}
              name={`date-input-${assignment.id}`}
              id={`date-input-${assignment.id}`}
              selected={date}
              autoComplete="off"
              onChange={onDateChange}
              dateFormat="P"
              shouldCloseOnSelect={false}
              locale={getUserDatePickerLocale()}
            />

          </div>

          <div className={styles.input} ref={datePickerTimeRef}>

            <label htmlFor={`time-input-${assignment.id}`}>{t('instruction_option_field:TIME')}</label>

            <DatePicker
              className={`withicon right ${IconType.CLOCK}`}
              name={`time-input-${assignment.id}`}
              selected={time}
              autoComplete="off"
              onChange={onTimeChange}
              onChangeRaw={_ => handleScroll(datePickerTimeRef.current)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat="p"
              shouldCloseOnSelect={false}
              locale={getUserDatePickerLocale()}
            />

          </div>

          <MUIButton
            type="defaultPrimary"
            onClick={_ => editAssignmentDateTime.mutate({ assignmentId: assignment.id, dateTime: moment(dateTime).tz(locationTimezone, true).toISOString(), orderId: assignment.dealId })}
            disabled={editAssignmentDateTime.isPending}
          >
            {t('submit')}
          </MUIButton>

        </div>

        {!assignment.shootingStartDateTime && !!assignment.timezone && !isSameTimezone(assignment.timezone, userTimezone) &&
          <div className={styles.info}>
            <span>{t('add_date_location_timezone')}</span>
          </div>
        }

        <QueryStatus
          query={editAssignmentDateTime}
          successMessage={t('success')}
          spaceTopRem={1}
          onPurge={() => editAssignmentDateTime.reset()}
        />

      </div>
    </TransitionAppear>
  )

  return (
    <div className={`${styles.assignment} ${assignment.isDeleted ? 'deleted' : ''}`.replace(/\s+/g, ' ').trim()}>

      <div className={styles.heading}>
        <strong className={styles.title}>{t('assignment')}</strong>
        <span className={styles.assignmentId}>{`#${assignment.id}`}</span>
      </div>

      <div className={styles.productWrapper}>

        <strong className={styles.product}>
          {product ? (product.quantity > 1 ? `${product.quantity} x ` : '') + t(`product_kind:${product.kind}`) + ' - ' + t(`product:p_${product.id}`) : 'N/A'}
        </strong>

        <strong className={styles.total}>
          {formatPrice(totalPrice, currency)}
        </strong>

      </div>

      {!!category && !isEditingCategory(category) && !!assignment.shootingDuration && assignment.shootingDuration > 0 &&
        <div className={styles.dateTime}>

          {!!assignment.shootingStartDateTime &&
            <EventTime
              eventStart={assignment.shootingStartDateTime}
              eventDuration={assignment.shootingDuration}
              eventTimezone={assignment.timezone}
              userTimezone={userTimezone}
            />
          }

          {!assignment.shootingStartDateTime &&
            <div className={styles.duration}>
              <Icon className={styles.stopwatch} icon={IconType.STOPWATCH} />
              <span className={styles.text}>{getTimeDurationStringFromMinutesAndHours(assignment.shootingDuration)}</span>
            </div>
          }

          {roles.isAdmin &&
            <Fragment>
              <div className={styles.actions}>

                {!!assignment.shootingStartDateTime &&
                  <Fragment>

                    <MUIButton
                      type="defaultPrimary"
                      size="sm"
                      onClick={e => setIsTimeEditOpen(!isTimeEditOpen)}
                    >
                      {isTimeEditOpen ? t('close_edit') : t('edit')}
                    </MUIButton>

                    <MUIButton
                      type="orangePrimary"
                      size="sm"
                      onClick={async () => {
                        await showConfirm(t('delete_date_prompt'))
                        deleteAssignmentDateTime.mutate({ assignmentId: assignment.id, orderId: assignment.dealId })
                        setIsTimeEditOpen(false)
                      }}
                    >
                      {t('delete_date')}
                    </MUIButton>

                  </Fragment>
                }

                {!assignment.shootingStartDateTime &&
                  <MUIButton
                    type="defaultPrimary"
                    size="sm"
                    onClick={e => setIsTimeEditOpen(!isTimeEditOpen)}
                  >
                    {isTimeEditOpen ? t('close_add_date') : t('add_date')}
                  </MUIButton>
                }
              </div>

              {editBoxDateTime}

              <QueryStatus
                query={deleteAssignmentDateTime}
                successMessage={t('success')}
                spaceTopRem={1}
                onPurge={() => deleteAssignmentDateTime.reset()}
              />

            </Fragment>
          }

        </div>
      }

    </div>
  )
}
