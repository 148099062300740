import { FC } from 'react'

import { PersonOutlineOutlined } from '@mui/icons-material'
import { Stack } from '@mui/material'
import classnames from 'classnames'
import styles from './OrderCreator.module.sass'
import { useGalleryDeal } from '../../../contexts'

export const OrderCreator: FC = () => {
  const { dealData } = useGalleryDeal()

  if (!dealData) return <></>

  return (
    <Stack flexDirection="row" alignItems="center" flexWrap="wrap" gap="1rem">
      <Stack flexDirection="row" alignItems="center">
        <PersonOutlineOutlined className={classnames(styles.icon, styles.person)} />
        <span className={styles.owner}>{dealData.customer.name}</span>
      </Stack>
    </Stack>
  )
}
