import { Box, IconButton } from '@mui/material'
import { DeleteOutlineRounded, InsertPhotoOutlined } from '@mui/icons-material'
import { GRAY_700, GRAY_900, WHITE } from 'constants/styling/theme'
import { Trans, useTranslation } from 'react-i18next'

import { CircleIcon } from 'components/common/CircleIcon'
import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { UploadWrapper } from './UploadWrapper.component'

interface Props {
  /** Handler function called on delete button press */
  onDelete: () => void
}

/** 
 * @component 
 * Visual error component for images with insufficient quality
 * 
 * @example
 * <ImageQualityError onDelete={() => console.log('You shall not pass!!')}/>
 */
export const ImageQualityError: React.FC<Props> = ({ onDelete }) => {
  const { t } = useTranslation(['purchase_flow'])

  return (
    <UploadWrapper type="error">
      <Stack direction="row" gap={1} width="100%">

        <Box flex="0 0 auto">
          <CircleIcon
            size="3rem"
            icon={<InsertPhotoOutlined sx={{ color: GRAY_900, fontSize: 18 }} />}
            circleColor={WHITE}
          />
        </Box>

        <Stack flex="1 1 auto">

          <Typography variant="text-sm" color={GRAY_900} fontWeight={600}>
            {t('image_quality_error.title')}
          </Typography>

          <Typography variant="text-xs" color={GRAY_700}>
            <Trans parent={undefined} t={t} i18nKey="image_quality_error.description">
              &nbsp;
            </Trans>
          </Typography>

        </Stack>

        <Box flex="0 0 auto">
          <IconButton
            size='medium'
            onClick={onDelete}
          >
            <DeleteOutlineRounded sx={{ color: GRAY_900, fontSize: 18 }} />
          </IconButton>
        </Box>

      </Stack>
    </UploadWrapper>
  )
}
