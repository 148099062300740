import { BLUE_100, BLUE_400, BLUE_600, BOX_SHADOW_LG, GRAY_1000, GRAY_300, WHITE } from 'constants/styling/theme'
import { SliderProps, styled } from '@mui/material'

import { FC } from 'react'
import Slider from '@mui/material/Slider'

/** Props for the SliderBar component. */
interface Props extends SliderProps { }

/** MUI Slider component with custom styles */
const BlueStyledSlider = styled((props: SliderProps) => (
  <Slider {...props} />
))(() => ({
  color: BLUE_600,
  paddingRight: 6,
  maxWidth: '98%',
  '& .MuiSlider-rail': {
    height: 16,
    background: GRAY_300,
    paddingLeft: '0.2rem',
  },
  '& .MuiSlider-track': {
    height: 16,
    opacity: 1,
    border: 'none',
    background: BLUE_100,
  },
  '& .MuiSlider-mark': {
    width: 4,
    height: 4,
    borderRadius: 2,
    backgroundColor: BLUE_400,
    '& .MuiSlider-markActive': {
      opacity: 1,
    },
  },
  '& .MuiSlider-thumb': {
    width: 12,
    height: 12,
    boxShadow: BOX_SHADOW_LG
  },
  '& .MuiSlider-valueLabelOpen': {
    height: 22,
    color: WHITE,
    fontSize: 12,
    borderRadius: 4,
    backgroundColor: GRAY_1000
  }
}))

/**
 * @component
 * SliderBar component represents a styled slider with marks and a blue color.
 *
 * @example
 * <SliderBar value={50} onChange={(event, value) => console.log(value)} />
 */
export const SliderBar: FC<Props> = ({ ...defaultProps }) => {
  return (
    <BlueStyledSlider
      marks
      valueLabelDisplay="on"
      {...defaultProps}
    />
  )
}
