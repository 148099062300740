import axios from 'axios'
import { APIRequestErrorType } from 'constants/API'

/** Decided error type from error object */
export const decideErrorType = (error: any): APIRequestErrorType => {
  if (axios.isCancel(error)) return APIRequestErrorType.CANCEL_ERROR
  if (!error.isAxiosError || !error.response || !error.response?.status) {
    return APIRequestErrorType.UNKNOWN_ERROR
  }

  const status = error.response?.status

  // Specific errors
  if (status === 401) return APIRequestErrorType.UNAUTHORIZED_ERROR
  if (status === 402) return APIRequestErrorType.PAYMENT_REQUIRED_ERROR
  if (status === 403) return APIRequestErrorType.FORBIDDEN_ERROR
  if (status === 404) return APIRequestErrorType.NOTFOUND_ERROR

  // Error ranges, general errors
  if (status >= 200 && status <= 299) return APIRequestErrorType.NONE
  if (status >= 400 && status <= 499) return APIRequestErrorType.CLIENT_ERROR
  if (status >= 500 && status <= 599) return APIRequestErrorType.SERVER_ERROR

  // If nothing else matches
  return APIRequestErrorType.UNKNOWN_ERROR
}
