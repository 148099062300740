import { FC, ReactNode } from 'react'

import styles from './SubscriptionStepTemplate.module.sass'

interface Props {
  /** Title of step */
  title: string
  /** Informations/description of the step */
  children: ReactNode
  /** Content displayed in padded and outlined container */
  boxContent?: ReactNode
  /** Primary action - primary button */
  action?: ReactNode
  /** How the action should be aligned */
  alignAction?: 'left' | 'center' | 'right' | 'between'
}

/**
 * Base template for Subscription flow step subpage.   
 * Provides unified styling and layout.   
 *    
 * @example
 * <SubscriptionStepTemplate
 *   title="Step 4: Profit"
 *   boxContent={<h2>OH YEAAAAAAAAH!</h2>}
 * >
 *   <span>This step is completely awesome!</span>
 * </SubscriptionStepTemplate>
 */
export const SubscriptionStepTemplate: FC<Props> = ({
  title,
  boxContent,
  children,
  action,
  alignAction = 'right'
}) => {

  return (
    <section className={styles.subscriptionStep}>

      <header>
        <h1>{title}</h1>
      </header>

      <div className={styles.stepInfo}>
        {children}
      </div>

      {!!boxContent &&
        <article>
          {boxContent}
        </article>
      }

      {action &&
        <footer className={styles[alignAction]}>
          {action}
        </footer>
      }

    </section>

  )
}
