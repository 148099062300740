import { BOX_SHADOW_XL, GRAY_100, GRAY_200, GRAY_800, MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { Box, Drawer, DrawerProps, IconButton, Stack, Typography, backdropClasses, drawerClasses, styled } from '@mui/material'
import { FC, ReactNode } from 'react'

import { Close } from '@mui/icons-material'
import styles from './ResponsiveDrawer.module.sass'
import useMediaQuery from '@mui/material/useMediaQuery'

interface Props {
  /** Whether the drawer is open or not */
  isOpen: boolean
  /** OnClick action to the drawer */
  onClose: () => void
  /** Drawer title */
  title: string
  /** Custom components in subTitle section */
  subTitleSlot?: ReactNode
  /** Drawer show up anchor */
  anchor?: DrawerProps['anchor']
  /** Drawer layout breakpoints */
  breakpointQuery?: string
  /** Whether using default close button or not */
  useDefaultCloseButton?: boolean
  /** Custom components in action section */
  actionsSlot?: ReactNode
  /** Drawer height in small size */
  height?: string
  children: ReactNode
}

/** MUI Drawer extension props */
interface MUIDrawerProps extends DrawerProps {
  height?: string
}

/**
 * MUI Drawer component with additional styles
 */
const StyledDrawer = styled(Drawer)<MUIDrawerProps>(({ anchor, height }) => {
  return {
    [`.${drawerClasses.paper}`]: {
      boxSizing: 'border-box',
      height: anchor === 'bottom' ? height : '100%',
      boxShadow: BOX_SHADOW_XL,
      borderRadius: anchor === 'bottom' ? '2rem 2rem 0 0' : '',

      [`&.${drawerClasses.paperAnchorBottom}`]: {
        height,
        width: 'auto',
      },
      [`&.${drawerClasses.paperAnchorLeft}`]: {
        width: '400px'
      },
      [`&.${drawerClasses.paperAnchorRight}`]: {
        width: '400px'
      },
    },
    [`.${backdropClasses.root}`]: {
      background: 'unset'
    },
  }
})

/**
 * Drawer component for containing content that needs to be accessed only after some interaction.
 * Utilizes MUI Drawer component but automatically switches orientation.
 *
 * @example
 * <ResponsiveDrawer title="Struggles of the Dark Lord" isOpen={true} onClose={() => alert('I want my nose back :/')}>
 *  <h5>Harry Potter</h5>
 *  <p>the boy who lived, comes to die</p>
 *  <h1>AAAAAVADA KEDAVRA!</h1>
 * </ResponsiveDrawer>
 */
export const ResponsiveDrawer: FC<Props> = ({
  children,
  isOpen,
  onClose,
  title,
  anchor = 'right',
  breakpointQuery = MOBILE_VIEW_QUERY,
  useDefaultCloseButton = true,
  height = '90vh',
  actionsSlot,
  subTitleSlot,
}) => {

  const isSmall = useMediaQuery(breakpointQuery)

  return (
    <StyledDrawer
      className={styles.drawer}
      anchor={isSmall ? 'bottom' : anchor}
      open={isOpen}
      onClose={onClose}
      elevation={10}
      height={height}
    >
      <Stack direction="column" height="100%">

        <Box sx={{
          borderBottom: `1px solid ${GRAY_200}`
        }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            gap="2rem"
            className={styles.topBar}
            sx={{
              padding: '1rem 2rem'
            }}
          >
            {!!title &&
              <Typography variant="text-xl" fontWeight="semiBold" sx={{ wordBreak: 'break-all' }}>
                {title}
              </Typography>
            }
            {useDefaultCloseButton &&
              <IconButton
                size="small"
                sx={{
                  color: GRAY_800,
                  border: `1px solid ${GRAY_200}`
                }}
                onClick={onClose}
              >
                <Close fontSize="large" />
              </IconButton>
            }
          </Stack>
          {!!subTitleSlot &&
            <Stack
              direction="row"
              justifyContent="start"
              sx={{
                padding: '0 2rem 1rem 2rem'
              }}
            >
              {subTitleSlot}
            </Stack>
          }
        </Box>

        <Box
          flex="1 1 20rem"
          overflow="auto"
          boxSizing="border-box"
          p="2rem"
        >
          {children}
        </Box>

        {!!actionsSlot &&
          <Stack
            p="1rem 2rem"
            spacing={1}
            direction="row"
            flexWrap="wrap"
            justifyContent="flex-end"
            borderTop={`1px solid ${GRAY_100}`}
          >
            {actionsSlot}
          </Stack>
        }

      </Stack>
    </StyledDrawer>
  )
}
