import { APIRequestState, Endpoint, RoleMimetype } from 'constants/API'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { all, put, takeEvery } from 'redux-saga/effects'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { EntityKeys, QueryType } from 'utils/reactQuery'

import API from 'utils/API/API'
import { ActionEditMissionCreativeInstructionsFromAdmin } from '.'

/** Saga which edits mission creative instructions from admin */
export function* editMissionCreativeInstructionsFromAdminSaga(receivedAction: ActionEditMissionCreativeInstructionsFromAdmin) {
  const { missionId, instructions } = receivedAction.payload
  const url = Endpoint.ADMIN_EDIT_ASSIGNMENT_CREATIVE_INSTRUCTIONS_FROM_ADMIN
    .replace('{assignmentId}', encodeURI(missionId.toString()))
  const action: ActionEditMissionCreativeInstructionsFromAdmin = yield generalFetch(ActionTypeAPIData.EDIT_ASSIGNMENT_CREATIVE_INSTRUCTIONS_FROM_ADMIN, () => API.put(url, {
    message: instructions,
  }, {
    headers: {
      Accept: RoleMimetype.ADMIN,
    },
  }, {
    endpoint: Endpoint.ADMIN_EDIT_ASSIGNMENT_CREATIVE_INSTRUCTIONS_FROM_ADMIN,
  }))

  const editMissionCreativeInstructionsFromAdminAction: ActionEditMissionCreativeInstructionsFromAdmin = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }

  yield put(editMissionCreativeInstructionsFromAdminAction)

  if (action.payload.request.state !== APIRequestState.OK) return
  receivedAction.queryClient?.invalidateQueries({ queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET_ONE, { assignmentId: receivedAction.payload.missionId }] })
}

/** Watcher of edit mission creative instructions from admin actions */
export function* editMissionCreativeInstructionsFromAdminWatcher() {
  yield all([
    takeEvery((action: ActionEditMissionCreativeInstructionsFromAdmin) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.EDIT_ASSIGNMENT_CREATIVE_INSTRUCTIONS_FROM_ADMIN]), editMissionCreativeInstructionsFromAdminSaga),
  ])
}
