export enum ErrorMessageType {
  EMPTY_IMAGE = 'EMPTY_IMAGE',
  EVENT_TOO_OLD = 'EVENT_TOO_OLD',
  ILLEGAL_NAME_FORMAT = 'ILLEGAL_NAME_FORMAT',
  UNSUPPORTED_IMAGE_FORMAT = 'UNSUPPORTED_IMAGE_FORMAT',
  UNKNOWN = 'UNKNOWN',
  OTHER = 'OTHER'
}

export const errorMessageType = (code?: string) => {
  switch (code) {
    case ErrorMessageType.EMPTY_IMAGE:
    case ErrorMessageType.EVENT_TOO_OLD:
    case ErrorMessageType.ILLEGAL_NAME_FORMAT:
    case ErrorMessageType.UNSUPPORTED_IMAGE_FORMAT:
    case ErrorMessageType.UNKNOWN:
      return code
    default:
      return ErrorMessageType.OTHER
  }
}
