import { ActionDownloadArchiveVisuals, DownloadArchiveStore } from '.'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'
import { cloneDeep } from 'lodash'

/** Redux data reducer for download visuals archive */
export const reduceDownloadArchiveVisuals = (state: DownloadArchiveStore, action: ActionDownloadArchiveVisuals) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.DOWNLOAD_ARCHIVE) return state
  const newState = cloneDeep(state)

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
      newState[action.payload.jobId] = new APIRequest(APIRequestState.BEFORE_START)
      return newState
    case ActionTypeAPIEvent.FETCH:
      newState[action.payload.jobId] = new APIRequest(APIRequestState.RUNNING)
      return newState
    case ActionTypeAPIEvent.RECEIVED:
      newState[action.payload.jobId] = action.payload.request
      return newState
    default:
      return state
  }
}