import { wrap } from 'comlink'
import { RoleMimetype } from 'constants/API'
import { ActionLoadEditedVisual } from 'redux/Individual/VisualsEditing/LoadEditedVisuals'
import API from 'utils/API/API'
import { auth0ClientModule } from 'utils/auth'
import { loadEditedVisualWorkerType } from 'workers/loadEditedVisualWorker'

// Initiates workers for thumbnails for Visual Editing
const initWorkers = () => {
  const workerCount = process.env.NODE_ENV === 'test'
    ? 0
    : navigator.hardwareConcurrency || 4

  const workerPool = [...Array(workerCount).keys()]
    .map(() => {
      const worker = new Worker(
        new URL('workers/loadEditedVisualWorker', import.meta.url),
        {
          type: 'module',
        }
      )
      return wrap<loadEditedVisualWorkerType>(worker)
    })

  let i = 0

  /** Calls loadEditedVisualWorker */
  return (action: ActionLoadEditedVisual, token: string, mimetype: RoleMimetype) => {
    const result = workerPool[i](action, token, mimetype)
    i = ++i % workerCount
    return result
  }
}

/** Calls loadEditedVisualWorker */
const getWorkDone = initWorkers()

export const runWorker = async (action: ActionLoadEditedVisual) => {

  // needed to make exception for environment where we run all tests
  if (process.env.NODE_ENV === 'test') return
  const token = await auth0ClientModule.getTokenSilently()

  const mimetype = await API.getRoleMimeType()

  const result = await getWorkDone(action, token, mimetype)

  return result
}
