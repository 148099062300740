import { FC, useMemo } from 'react'
import { useGalleryVisualSelection, useGalleryVisualType, useGalleryVisuals, useGalleryVisualsMeta } from '../../../_main/contexts'

import classnames from 'classnames'
import styles from './VisualDownloadImagesShowcase.module.sass'

/**
 * Displays showcase of images that have been selected for download or all purchased if none were selected.   
 * 
 * DEPENDENCIES:
 * - GalleryVisualsContext
 * - GalleryVisualsMetaContext
 * - GalleryVisualSelectionContext
 * - GalleryVisualTypeContext
 * 
 * @example
 * <VisualDownloadImagesShowcase />
 */
export const VisualDownloadImagesShowcase: FC = () => {

  const { downloadVisualsEntries } = useGalleryVisuals()
  const { purchasedVisualsKeys } = useGalleryVisualsMeta()
  const { selected } = useGalleryVisualSelection()
  const { thumbnailType } = useGalleryVisualType()

  const visualsForShowcase = useMemo(() => {
    let visuals = downloadVisualsEntries.filter(([key]) => purchasedVisualsKeys.has(key))
    if (selected.size > 0) visuals = visuals.filter((visual) => selected.has(visual[0]))
    return visuals.map(([name, visual]) => ({ name, url: visual?.[thumbnailType]?.signedUrl }))
  }, [downloadVisualsEntries, selected, purchasedVisualsKeys, thumbnailType])

  return (
    <div className={classnames(
      styles.showcase,
      { [styles.smallSample]: visualsForShowcase.length <= 3 }
    )}>
      {visualsForShowcase.map(({ name, url }, index) => (
        <div
          className={styles.image}
          key={name}
          style={{
            transform: visualsForShowcase.length >= 5 ? `translateY(${index % 2 !== 0 ? '50%' : '0%'})` : '',
            backgroundImage: `url(${url})`,
          }}
        >
        </div>
      ))}
    </div>
  )
}
