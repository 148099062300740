import { BLUE_100, BLUE_600, GRAY_400, WHITE } from 'constants/styling/theme'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'

import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import React from 'react'
import { styled } from '@mui/material/styles'

/**
 * @interface Props Input properties
 */
export interface Props extends CheckboxProps { }

const StyledBox = styled('span')(() => ({
  width: '1.6rem',
  height: '1.6rem',
  borderRadius: '.4rem',
  border: `solid .1rem ${GRAY_400}`,
  backgroundColor: WHITE,
  'input:hover ~ &': {
    borderColor: BLUE_600
  }
}))

const StyledCheckedBox = styled((props) => (
  <StyledBox {...props}><CheckRoundedIcon /></StyledBox>
))(() => ({
  border: `solid .1rem ${BLUE_600}`,
  backgroundColor: BLUE_100,
  color: BLUE_600,
  'input:disabled ~ &': {
    opacity: 0.5,
  },
}))

/**
 * @component
 * MUICheckbox represents a customized Checkbox with blue theme.
 *
 * @example
 * <MUICheckbox
 *   checked={isChecked}
 *   onClick={() => handleCheckboxClick()}
 * />
 */
export const MUICheckbox: React.FC<Props> = ({
  checked,
  onClick,
  ...defaultProps
}) => {

  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
        padding: 0
      }}
      checked={checked}
      disableRipple
      checkedIcon={<StyledCheckedBox />}
      icon={<StyledBox />}
      onClick={onClick}
      {...defaultProps}
    />
  )
}
