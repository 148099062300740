import { ProductKind } from '../product'

export const editingProductKindSortingOrder = [
  ProductKind.PHOTO,
  ProductKind.RESIDENTIAL_STAGING,
  ProductKind.COMMERCIAL_STAGING,
  ProductKind.RESIDENTIAL_RENDERING,
  ProductKind.COMMERCIAL_RENDERING,
  ProductKind.MATTERPORT_FLOOR_PLAN_WITH_VISIT,
  ProductKind.FLOOR_PLAN_EDITING_2D,
  ProductKind.FLOOR_PLAN_EDITING_3D,
  ProductKind.FLOOR_PLAN_CERTIFICATION,
  ProductKind.MATTERPORT_PHOTO,
  ProductKind.MATTERTAG,
]
