import { FC } from 'react'
import { StagingFlowController } from './StagingFlow.controller'

/**
 * Staging flow for selecting styles for staging images
 * @example <StyleSelection />
 */
export const StagingFlow: FC = () => {
  return (
    <StagingFlowController />
  )
}
