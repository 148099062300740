import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequestState } from 'constants/API'
import { APIRequest } from 'models/API'
import { ActionRequest } from 'models/redux'
import { UserAsMemberUsingEmailDTO } from 'models/workspaces'
import { createAPIAction } from 'redux/Helpers'
import { AddWorkspaceMembersStoreItem } from './addWorkspaceMembers.interfaces'

/** Describes add workspace members action  */
export interface ActionAddWorkspaceMembers extends ActionRequest {
  payload: {
    workspaceId: string
    members: UserAsMemberUsingEmailDTO[]
    request: AddWorkspaceMembersStoreItem
  },
}

/** Creates add workspace members action */
export function addWorkspaceMembers(workspaceId: string, members: UserAsMemberUsingEmailDTO[]): ActionAddWorkspaceMembers {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.ADD_WORKSPACE_MEMBERS], {
    workspaceId,
    members,
    request: new APIRequest(APIRequestState.RUNNING),
  })
}

/** Purges stage of add workspace members action */
export function purgeAddWorkspaceMembers(workspaceId: string): ActionAddWorkspaceMembers {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.ADD_WORKSPACE_MEMBERS], {
    workspaceId,
    request: new APIRequest(APIRequestState.BEFORE_START),
  })
}
