import { FC } from 'react'

import Button from 'components/common/Button/Button'
import { KeyboardEventKey } from 'constants/misc'
import SearchIcon from '@mui/icons-material/Search'
import styles from './SearchFilter.module.sass'

const SEARCH_INPUT_NAME = 'search_query'

/**
 * @interface Props Input properties of custom Search component
 */
interface Props {
  /** Placeholder in search input */
  placeholder: string
  /** Search string value */
  value: string
  /** OnChange action triggered when user type in the search input */
  onChange: (value: string) => void
  /** OnClick action triggered when user clicks the search button */
  onClick?: (e: React.MouseEvent) => void
  /** On enter keydown action */
  onEnter?: (e: React.KeyboardEvent) => void
}

/**
 * @component Styled input for filtering/search by text
 * @example <SearchFilter value={params.search} onChange={(query) => console.log(query)}/>
 * */
export const SearchFilter: FC<Props> = ({
  placeholder,
  value,
  onChange,
  onClick,
  onEnter,
}) => {

  return (
    <div className={styles.searchWrap}>
      <div className={styles.searchBox}>
        <input
          type="search"
          name={SEARCH_INPUT_NAME}
          id={SEARCH_INPUT_NAME}
          placeholder={placeholder}
          value={value}
          onChange={e => onChange(e.target.value)}
          onKeyDown={e => e.key === KeyboardEventKey.ENTER && onEnter?.(e)}
        />
        <Button
          className={styles.searchButton}
          type="secondary nobackground noborder"
          onClick={(e) => onClick?.(e)}>
          <SearchIcon className={styles.searchIcon} fontSize='large' />
        </Button>
      </div>
    </div>
  )
}
