import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { Endpoint, RoleMimetype } from 'constants/API'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, put, takeEvery } from 'redux-saga/effects'

import API from 'utils/API/API'
import { ActionGetSubscriptionCheckoutLink } from './getCheckoutLink.actions'
import { Path } from 'constants/router'

/** Saga which gets subscription checkout link from API */
export function* getSubscriptionCheckoutLinkSaga(receivedAction: ActionGetSubscriptionCheckoutLink) {

  const { workspaceId, subscriptionDetails } = receivedAction.payload

  const url = Endpoint.SUBSCRIPTION_CHECKOUT_LINK.replace('{workspaceId}', encodeURI(workspaceId))

  const action: ActionGetSubscriptionCheckoutLink = yield generalFetch(ActionTypeAPIData.GET_SUBSCRIPTION_CHECKOUT_LINK, () => API.post(
    url,
    {
      ...subscriptionDetails,
      returnUrl: encodeURI(`${window.location.origin}${Path.SUBSCRIPTION_STATUS}`)
    },
    {
      headers: {
        Accept: RoleMimetype.CLIENT, // TODO: Fix on BE so we can send proper accept header
      },
    },
    {
      endpoint: Endpoint.SUBSCRIPTION_CHECKOUT_LINK,
    }
  ))

  const getSubscriptionCheckoutLinkAction: ActionGetSubscriptionCheckoutLink = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }

  yield put(getSubscriptionCheckoutLinkAction)
  return getSubscriptionCheckoutLinkAction
}

/** Watcher of get subscription checkout link */
export function* getSubscriptionCheckoutLinkWatcher() {
  yield all([
    takeEvery((action: ActionGetSubscriptionCheckoutLink) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.GET_SUBSCRIPTION_CHECKOUT_LINK]), getSubscriptionCheckoutLinkSaga),
  ])
}
