import { PlatformUser, PlatformUserBase, PlatformUserClient, PlatformUserCreative } from 'models/user'

import { Auth0User } from 'models/auth'

export function UserIsPlatformUser(user: Auth0User | PlatformUser): user is PlatformUser {
  return (
    (user as PlatformUser).country !== undefined ||
    (user as PlatformUser).phone !== undefined ||
    (user as PlatformUser).defaultTimezone !== undefined ||
    (user as PlatformUser).id !== undefined
  )
}

export function UserIsPlatformUserClient(user: PlatformUser): user is PlatformUserClient {
  return (
    (user as PlatformUserClient).preferredPaymentMethod !== undefined
  )
}

export function UserIsPlatformUserCreative(user: PlatformUser): user is PlatformUserCreative {
  return (
    (user as PlatformUserCreative).enabled !== undefined
  )
}

export function UserIsPlatformUserBase(user: PlatformUser): user is PlatformUserBase {
  return (
    (user as PlatformUserCreative).enabled === undefined &&
    (user as PlatformUserClient).preferredPaymentMethod === undefined &&
    (user as PlatformUserClient).clientType === undefined
  )
}

export function UserIsAuth0User(user: Auth0User | PlatformUser): user is Auth0User {
  return (
    (user as Auth0User)?.sub !== undefined ||
    (user as Auth0User)?.email_verified !== undefined ||
    (user as Auth0User)?.updated_at !== undefined
  )
}
