import { useCallback, useMemo, useReducer } from 'react'
import { FileAPIEntry, FileAction, FileDeleteFnc, FileState, LoadUrlResolver, ReUploadOptions, UploadOptions, UploadUrlResolver } from './fileAPI.types'
import { AddEntryAction, InitScopeAction, PurgeScopeAction, RemoveEntryAction, UpdateEntryAction, reduceFileAPIInventory } from './fileInventoryUtils'
import { startLoadWorker, uploadToGC } from './fileUtils'

import axios from 'axios'
import constate from 'constate'
import { uniqueId } from 'lodash'
import { useAPI } from 'utils/API'

function* getOrderGenerator() {
  let index = 0
  while (true) {
    yield index++
  }
}

export const [FileAPIProvider, useFileAPIController] = constate(() => {
  const [fileInventory, dispatch] = useReducer(reduceFileAPIInventory, {})

  const getNewIndex = useMemo(() => getOrderGenerator(), [])

  const createFileAPIEntry = useCallback((overrides?: Partial<FileAPIEntry>): FileAPIEntry => {
    return {
      id: uniqueId('file-entry:'),
      state: FileState.IDLE,
      action: FileAction.INIT,
      progress: 0,
      fileObject: new File([], ''),
      abortController: new AbortController(),
      signedUrl: null,
      displayUrl: null,
      originalFilename: null,
      gcFilename: null,
      tag: null,
      order: getNewIndex.next().value as number,
      ...overrides,
    }
  }, [getNewIndex])

  const api = useAPI<string>()

  /** Updated file entry with partially updated data */
  const updateFileEntry = (scope: string, id: string, fileUpdates: Partial<FileAPIEntry>) => {
    dispatch(new UpdateEntryAction(scope, id, fileUpdates))
  }

  /** Adds file entry into scope */
  const addFileEntry = (scope: string, id: string, file: FileAPIEntry, duplicityPolicy: 'skip' | 'overwrite' = 'skip') => {
    dispatch(new AddEntryAction(scope, id, file, duplicityPolicy))
  }

  /** Removes file entry from scope */
  const removeFileEntry = (scope: string, id: string) => {
    dispatch(new RemoveEntryAction(scope, id))
  }

  /** Creates new empty scope in inventory */
  const initScope = (scope: string) => {
    dispatch(new InitScopeAction(scope))
  }

  /** Removes scope from inventory */
  const purgeScope = (scope: string) => {
    dispatch(new PurgeScopeAction(scope))
  }

  /** Adds all provided files to scope - mostly utility now, subject to change or be privatized */
  const initFiles = (scope: string, files: Array<FileAPIEntry>) => {
    const addedEntries: Record<string, FileAPIEntry> = {}

    for (const file of files) {

      dispatch(new AddEntryAction(
        scope,
        file.id,
        file,
        'overwrite'
      ))

      addedEntries[file.id] = file
    }

    return addedEntries
  }

  /** Calls delete fnc handler for each file and removes entry from scope if successful */
  async function deleteFiles(scope: string, ids: string[], deleteFnc: FileDeleteFnc) {
    for (const id of ids) {
      let fileEntry: FileAPIEntry = fileInventory?.[scope]?.[id]
      if (!fileEntry) continue

      updateFileEntry(scope, id, { state: FileState.RUNNING, action: FileAction.DELETE, progress: 0 })

      deleteFnc(fileEntry, api)
        .then(() => {
          updateFileEntry(scope, id, { state: FileState.SUCCESS, action: FileAction.DELETE, progress: 100 })
          removeFileEntry(scope, id)
        })
        .catch(() => {
          updateFileEntry(scope, id, { state: FileState.ERROR, progress: 0 })
        })
    }
  }

  /** Obtains signedUrl for each file and proceeds with parallel upload of all files updating progress */
  async function uploadFiles(
    scope: string,
    files: FileList | Array<File>,
    signedUrlResolver: UploadUrlResolver,
    options: UploadOptions
  ) {

    // Should be separated probably
    const initializedFiles = Object.values(initFiles(
      scope,
      Array.from(files).map((file) => createFileAPIEntry({ fileObject: file, tag: options.tag, originalFilename: file.name }))
    ))

    // Set all files to prep state
    for (const file of initializedFiles) {
      updateFileEntry(scope, file.id, { state: FileState.RUNNING, action: FileAction.UPLOAD, progress: 0 })
    }

    const successIds: string[] = []
    const cancelledIds: string[] = []
    const errorIds: string[] = []

    // Sequentially obtain signed urls for all files
    // Sequentially because if called too quickly GC can return duplicated filename => duplicated urls
    for (const file of initializedFiles) {
      await signedUrlResolver(file, api)
        .then((response) => {
          const signedUrl = response.data

          // Assign signedUrl and change temporary id to GC filename
          updateFileEntry(scope, file.id, {
            signedUrl,
            gcFilename: signedUrl.filename,
            id: signedUrl.filename,
          })

          // Add signed url to entry
          file.signedUrl = signedUrl
          // Replace temporary id by GC filename
          file.id = signedUrl.filename
        })
        .catch((e) => {
          if (axios.isCancel(e)) {
            updateFileEntry(scope, file.id, { state: FileState.IDLE, progress: 0 })
            cancelledIds.push(file.id)
          } else {
            updateFileEntry(scope, file.id, { state: FileState.ERROR, progress: 0 })
            errorIds.push(file.id)
          }
        })
    }

    // In case all signed url retrievals failed / been cancelled
    if (cancelledIds.length + errorIds.length === initializedFiles.length) {
      options.onSettled?.(successIds, errorIds, cancelledIds)
      return
    }

    // Trigger parallel upload for all files
    const allUploads: Promise<unknown>[] = []

    for (const file of initializedFiles) {

      // Sanity check
      if (!file || !file.fileObject || !file.signedUrl || !file.abortController) continue

      allUploads.push(uploadToGC(
        file.fileObject,
        file.signedUrl,
        file.abortController.signal,
        (progress) => {
          updateFileEntry(scope, file.id, { progress })
        },
        // on success
        () => {
          updateFileEntry(scope, file.id, { state: FileState.SUCCESS, progress: 0 })
          successIds.push(file.id)
        },
        // on cancel
        () => {
          updateFileEntry(scope, file.id, { state: FileState.CANCELLED, progress: 0 })
          cancelledIds.push(file.id)
        },
        // on error
        () => {
          updateFileEntry(scope, file.id, { state: FileState.ERROR, progress: 0 })
          errorIds.push(file.id)
        }
      ))
    }

    Promise.allSettled(allUploads).then(() => options.onSettled?.(successIds, errorIds, cancelledIds))
  }

  /** Obtains signedUrl for each file and proceeds with parallel upload of all files updating progress */
  async function reUploadFile(
    scope: string,
    replaceId: string,
    file: File,
    signedUrlResolver: UploadUrlResolver,
    options: ReUploadOptions
  ) {

    const entry = fileInventory[scope]?.[replaceId]

    if (!entry) {
      console.error('No existing entry for this scope and id, nothing to replace, aborting!')
      return
    }

    // Set state and action and update file object data in state
    updateFileEntry(scope, entry.id, {
      state: FileState.RUNNING,
      action: FileAction.RE_UPLOAD,
      progress: 0,
      fileObject: file,
      originalFilename: file.name,
      tag: options.tag ?? entry.tag,
    })

    // Update crucial details in fnc kept entry
    entry.fileObject = file
    entry.originalFilename = file.name
    entry.tag = options.tag ?? entry.tag

    // Obtain signed url
    await signedUrlResolver(entry, api)
      .then((response) => {
        const signedUrl = response.data

        // Assign signedUrl and change temporary id to GC filename
        updateFileEntry(scope, entry.id, {
          signedUrl,
          gcFilename: signedUrl.filename,
          // Should be the same, just to be sure
          id: signedUrl.filename,
        })

        // Update signed url of entry
        entry.signedUrl = signedUrl
        // Should be the same, just to be sure
        entry.id = signedUrl.filename
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          updateFileEntry(scope, entry.id, { state: FileState.IDLE, progress: 0 })
          options.onCancel?.()
        } else {
          updateFileEntry(scope, entry.id, { state: FileState.ERROR, progress: 0 })
          options.onError?.()
        }
      })

    // Sanity check
    if (!entry || !entry.fileObject || !entry.signedUrl || !entry.abortController) return

    uploadToGC(
      entry.fileObject,
      entry.signedUrl,
      entry.abortController.signal,
      (progress) => {
        updateFileEntry(scope, entry.id, { progress })
      },
      // on success
      () => {
        updateFileEntry(scope, entry.id, { state: FileState.SUCCESS, progress: 0 })
        options.onSuccess?.()
      },
      // on cancel
      () => {
        updateFileEntry(scope, entry.id, { state: FileState.CANCELLED, progress: 0 })
        options.onCancel?.()
      },
      // on error
      () => {
        updateFileEntry(scope, entry.id, { state: FileState.ERROR, progress: 0 })
        options.onError?.()
      }
    )
  }

  // TODO: Make this blasphemy work - DO NOT USE IN MEANTIME
  async function loadFiles(scope: string, files: Array<FileAPIEntry>, urlResolver: LoadUrlResolver) {

    const initedFiles = initFiles(scope, files)

    for (const fileId in initedFiles) {
      const entry = initedFiles[fileId]

      if (!entry) continue

      urlResolver(entry, api)
        .then((resp) => startLoadWorker(resp.data))
        .catch(err => console.error(err))
    }
  }

  return {
    uploadFiles,
    reUploadFile,
    fileInventory,
    addFileEntry,
    updateFileEntry,
    initScope,
    purgeScope,
    deleteFiles,
    initFiles,
    loadFiles,
    createFileAPIEntry,
  }
})
