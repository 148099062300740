import React, { Fragment, ReactNode, useMemo } from 'react'

import Icon from 'components/common/Icon/Icon'
import { IconType } from 'constants/assets'
import classnames from 'classnames'
import styles from './Badge.module.sass'

/**
 * @typedef BadgeType Available badge types (reflect different styling)
 */
export type BadgeType = 'fill' | 'fillAlt' | 'outline'

/**
 * @typedef BadgeColor Available badge colors
 */
export type BadgeColor = 'blue' | 'orange' | 'red' | 'green' | 'gray'

/**
 * @typedef BadgeBorderStyle Available badge border styles
 */
export type BadgeBorderStyle = 'sharp' | 'rounded'

/**
 * @interface Props Input properties
 */
interface Props {
  /** The additional classes to append */
  className?: string
  /** Type of the badge, either filled or outlined. Default is "fillAlt" */
  type?: BadgeType
  /** Color of the badge. Default is "gray" */
  color?: BadgeColor
  /** Icon displayed on the left side */
  icon?: IconType
  /** Style of the badge border. Default is "sharp" */
  borderStyle?: BadgeBorderStyle
  /** Whether the arrow should be displayed on the right side */
  displayArrow?: boolean
  /** Whether the icon should be displayed full size without the circle background */
  displayFullSizeIcon?: boolean
  children?: ReactNode
}

/**
 * @component Badge with various available colors and types, children can be any string
 * @example
 * <Badge type="outline" color="red">
 *   Some text
 * </Badge>
 */
export const Badge: React.FC<Props> = ({
  className = '',
  type = 'fillAlt',
  color = 'gray',
  icon,
  borderStyle = 'sharp',
  displayArrow = false,
  displayFullSizeIcon = false,
  children,
}) => {

  const arrowStyles = useMemo(() => {
    if (icon) return 'badgeWithIconAndArrow'
    return 'badgeWithArrow'
  }, [icon])

  return (
    <div className={
      classnames(
        className,
        styles.badge,
        styles[type],
        styles[color],
        icon ? styles.badgeWithIcon : '',
        displayArrow ? styles[arrowStyles] : '',
        borderStyle === 'rounded' ? styles.badgeWithBorder : ''
      )
    }>
      {icon ?
        <div className={styles.textAndIconWrap}>
          {displayFullSizeIcon ?
            <div className={styles.fullSize}>
              <Icon icon={icon} className={styles[color]} />
            </div>
            :
            <div className={`${styles.dot} ${(type === 'outline') ? styles.outline : ''}`}>
              <Icon icon={icon} />
            </div>
          }
          {children}
        </div>
        :
        <Fragment>
          {children}
        </Fragment>
      }
    </div>
  )
}
