import { FC, Fragment } from 'react'
import { FieldError, UseFormRegisterReturn } from 'react-hook-form'

import { Nullable } from 'models/helpers'
import styles from './ConfiguredInput.module.sass'

interface Props {
  name: string
  label: string
  control: Nullable<UseFormRegisterReturn>
  error: Nullable<FieldError>
  disabled?: boolean
  placeholder?: string
  className?: string
}

/**
 * Input field with built-in label and error state handlers suited for usage with form system controls
 * @example
 * <ConfiguredInput
 *  name="input-name"
 *  label="Input name"
 *  error={formUtils.error['input-name']}
 *  control={...control}
 * />
 */
export const ConfiguredInput: FC<Props> = ({
  name,
  label,
  control,
  error,
  disabled = false,
  placeholder = '',
  className,
}) => {
  if (control === undefined) return <Fragment></Fragment>

  return (
    <div className={`${styles.inputGroup} ${className || ''}`}>

      <label
        htmlFor={name}
        className={`${styles.label} ${!!error ? styles.isError : ''}`}
      >
        {label}
      </label>

      <input
        type="text"
        id={name}
        name={name}
        className={!!error ? styles.isError : ''}
        placeholder={placeholder}
        {...control}
        disabled={disabled}
      />

      {!!error &&
        <span className={styles.error}>{error.message}</span>
      }

    </div>
  )
}
