import { FC, Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { supportEmail, supportEmailHref } from 'constants/contacts'

import BlockInfo from 'components/common/BlockInfo/BlockInfo'
import classNames from 'classnames'
import styles from './LegalImprint.module.sass'

/**
 * Legal imprint text with information about company based on location
 * @param location - used for sourcing information from trans .json file
 * @example <LegalImprint location="germany" />
 */
export const LegalImprint: FC<{
  location: 'germany' | 'switzerland'
}> = ({
  location
}) => {
    const { t, i18n } = useTranslation(['terms_and_privacy'])

    // Safety check for existing translations for given location
    if (!i18n.exists(`terms_and_privacy:${location}`)) return (
      <BlockInfo color="red">
        {t('location_error')}
      </BlockInfo>
    )

    return (
      <Fragment>
        <h2>
          {t(`${location}.title`)}
        </h2>

        <h3 className={styles.subheading}>
          {t(`${location}.subtitle`)}
        </h3>

        <p className={styles.text}>
          <Trans t={t} i18nKey={`${location}.address`}>
            &nbsp;
          </Trans>
        </p>

        <h3 className={styles.subheading}>
          {t('represented')}
        </h3>
        <p className={styles.text}>
          {t(`${location}.representants`)}
        </p>

        <h3 className={styles.subheading}>
          {t('offices')}
        </h3>
        <p className={styles.text}>
          <Trans t={t} i18nKey={`${location}.office_address`}>
            &nbp;
          </Trans>
        </p>

        <h3 className={styles.subheading}>
          {t('contact')}
        </h3>
        <p className={styles.text}>
          <Trans
            t={t}
            i18nKey={`${location}.contact`}
            values={{ email: supportEmail }}
          >
            <a href={supportEmailHref()} className={styles.link}>
              &nbsp;
            </a>
          </Trans>
        </p>

        <p className={classNames(styles.text, styles.footer)}>
          <Trans t={t} i18nKey={`${location}.footer`}>
            &nbsp;
          </Trans>
        </p>
      </Fragment>
    )
  }
