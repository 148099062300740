import { BuildingLocationType, BuildingType } from 'constants/purchaseFlow'
import { useMemo, useState } from 'react'

import { Nullable } from 'models/helpers'
import constate from 'constate'

export const [PropertyInformationContextProvider, usePropertyInformation] = constate(() => {
  const [buildingType, setBuildingType] = useState<Nullable<BuildingType>>(null)
  const [buildingLocationType, setBuildingLocationType] = useState<Nullable<BuildingLocationType>>(null)
  const [residentialUnit, setResidentialUnit] = useState<number>(1)
  const [commercialArea, setCommercialArea] = useState('')
  const [commercialAreaType, setCommercialAreaType] = useState('')
  const [constructionYear, setConstructionYear] = useState('')
  const [livingSpace, setLivingSpace] = useState('')

  const isPropertyInformationValid = useMemo(() => {

    if (buildingType === BuildingType.SINGLE_FAMILY_HOUSE || buildingType === BuildingType.TWO_FAMILY_HOUSE || buildingType === BuildingType.MULTI_FAMILY_HOUSE) {
      return !!residentialUnit && !!constructionYear && !!livingSpace
    }

    if (buildingType === BuildingType.MIXED_USE_BUILDING) {
      return !!residentialUnit && !!constructionYear && !!residentialUnit && !!commercialArea && !!commercialAreaType
    }

    return false
  }, [buildingType, residentialUnit, constructionYear, livingSpace, commercialArea, commercialAreaType])

  return {
    buildingType,
    setBuildingType,
    buildingLocationType,
    setBuildingLocationType,
    residentialUnit,
    setResidentialUnit,
    constructionYear,
    setConstructionYear,
    livingSpace,
    setLivingSpace,
    commercialArea,
    setCommercialArea,
    commercialAreaType,
    setCommercialAreaType,
    isPropertyInformationValid,
  }
})
