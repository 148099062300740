import { useEditCTWorkingHours, useWorkingHoursCTList } from 'dataQueries'
import { useEffect, useState } from 'react'

import constate from 'constate'
import { useWorkingHoursAPI } from '../WorkingHoursAPI.context'

export const [WorkingHoursDrawerContextProvider, useWorkingHoursDrawer] = constate(() => {

  const { initializeData } = useWorkingHoursAPI()

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)

  // Query actions
  const loggedCTlistWorkingHours = useWorkingHoursCTList()
  const editWorkingHours = useEditCTWorkingHours()

  const openEditWorkingHoursDrawer = () => {
    setIsDrawerOpen(true)
  }

  const closeWorkingHoursDrawer = () => {
    setIsDrawerOpen(false)
  }

  useEffect(() => {
    if (!isDrawerOpen) return
    initializeData(loggedCTlistWorkingHours.data || {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializeData, isDrawerOpen])

  return {
    isDrawerOpen,
    editWorkingHours,
    workingHours: loggedCTlistWorkingHours.data,
    closeWorkingHoursDrawer,
    openEditWorkingHoursDrawer,
  }
})
