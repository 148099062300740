import { UserIsAuth0User, UserIsPlatformUser } from 'utils/typeguards'
import { getRoles, getRolesFromUser } from 'utils/auth'

import { Auth0User } from 'models/auth'
import { PlatformUser } from 'models/user'
import mixpanel from 'mixpanel-browser'

export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN

export const mixpanelController: {
  instance: typeof mixpanel | undefined
} = {
  instance: undefined,
}

export const trackMixpanelUser = (user: Auth0User | PlatformUser) => {
  if (!user) return
  // Auth0 User
  if (UserIsAuth0User(user)) {
    if (user.email) mixpanelController.instance?.people.set('$email', user.email)
    if (user.name) mixpanelController.instance?.people.set('$name', user.name)
    if (user.picture) mixpanelController.instance?.people.set('$avatar', user.picture)
    if (user.sub) mixpanelController.instance?.identify(user.sub)
    if (user.email) mixpanelController.instance?.alias(user.email, user.sub)
    const roles = getRolesFromUser(user)
    mixpanelController.instance?.people.set('roles', Array.from(roles.all.keys()).join(', '))
    // Platform User
  } else if (UserIsPlatformUser(user)) {
    if (user.email) mixpanelController.instance?.people.set('$email', user.email)
    if (user.name) mixpanelController.instance?.people.set('$name', user.name)
    if (user.phone) mixpanelController.instance?.people.set('$phone', user.phone)
    if (user.country) mixpanelController.instance?.people.set('$country_code', user.country)
    if (user.defaultTimezone) mixpanelController.instance?.people.set('$timezone', user.defaultTimezone)
    if (user.email) mixpanelController.instance?.identify(user.email)
    // if (user.id) mixpanelController.instance?.alias(user.id, user.email)
    // if (user.pipedriveId) mixpanelController.instance?.alias(user.pipedriveId, user.email)
    getRoles().then(roles => mixpanelController.instance?.people.set('roles', Array.from(roles.all.keys()).join(', ')))
  }
}

export const initMixpanel = () => {
  if (MIXPANEL_TOKEN) {
    mixpanel.init(MIXPANEL_TOKEN, {
      // Route data to Mixpanel's EU servers by setting the api_host config property.
      api_host: 'https://api-eu.mixpanel.com',
    })
    mixpanelController.instance = mixpanel
    return true
  }
  return false
}