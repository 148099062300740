import { FC } from 'react'
import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import classNames from 'classnames'
import styles from './GalleryHeader.module.sass'
import useMediaQuery from '@mui/material/useMediaQuery'

interface Props {
  /** Title of the Gallery header */
  title: string
  /** Sub title of the Gallery header */
  subTitle?: string
}

/**
 * Gallery header component.
 * @example <GalleryHeader title={reference} subTitle={address} />
 */
export const GalleryHeader: FC<Props> = ({ title, subTitle }) => {
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)

  return (
    <div className={styles.galleryHeader}>
      <div className={classNames(styles.title, { [styles.mobile]: isMobileView })}>{title}</div>
      <div className={classNames(styles.subTitle, { [styles.mobile]: isMobileView })}>{subTitle}</div>
    </div>
  )
}
