import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded'
import Box from '@mui/material/Box'
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import React from 'react'
import Stack from '@mui/material/Stack'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Title */
  title: string
  /** Date */
  date: string
  /** Time */
  time: string
}

const style = {
  padding: '.2rem 0',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'end',
  gap: '.8rem'
}

/**
 * @component List item of date and time component.
 * @example 
 * <DateTimeListItem />
 */
export const DateTimeListItem: React.FC<Props> = ({
  title,
  date,
  time
}) => (
  <Stack
    sx={{ ':not(:last-child)': { marginBottom: 3.2 } }}
  >
    <Box
      fontWeight={600}
      lineHeight="2rem"
    >
      {title}
    </Box>
    <Stack
      flexDirection="row"
      gap="1rem"
    >
      <Stack sx={style}>
        <CalendarTodayRoundedIcon />
        <Box fontWeight={400}>{date}</Box>
      </Stack>
      <Stack sx={style}>
        <AccessTimeRoundedIcon />
        <Box fontWeight={400}>{time}</Box>
      </Stack>
    </Stack>
  </Stack>
)
