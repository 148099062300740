import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { ActionOrganizationMe } from './organizationMe.actions'
import { OrganizationMeStore } from './organizationMe.interfaces'

/** Reducer for organization me */
export const reduceOrganizationMe = (state: OrganizationMeStore, action: ActionOrganizationMe) => {
  const [eventType, dataType] = action.type

  if (dataType !== ActionTypeAPIData.ORGANIZATION_ME) return state

  const { request } = action.payload

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
    case ActionTypeAPIEvent.FETCH:
    case ActionTypeAPIEvent.RECEIVED:
      return request

    default:
      return state
  }
}