import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'
import { ActionRequest } from 'models/redux'
import { CreateWorkspaceStore } from './createWorkspace.interfaces'
import { createAPIAction } from 'redux/Helpers'

/** Describes create workspace action  */
export interface ActionCreateWorkspace extends ActionRequest {
  payload: {
    workspaceName: string
    request: CreateWorkspaceStore
  },
}

/** Creates create workspace action */
export function createWorkspace(workspaceName: string): ActionCreateWorkspace {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.CREATE_WORKSPACE], {
    workspaceName,
    request: new APIRequest(APIRequestState.RUNNING),
  })
}

/** Purges stage of create workspace action */
export function purgeCreateWorkspace(): ActionCreateWorkspace {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.CREATE_WORKSPACE], {
    request: new APIRequest(APIRequestState.BEFORE_START),
  })
}
