import { AssignmentJob, AssignmentStage } from 'constants/assignment'
import { BEIGE_600, GRAY_900, MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { FC, Fragment, useCallback, useMemo } from 'react'
import { PRODUCTS_WITH_OPTIONAL_STAGING, STAGING_PRODUCT_KINDS } from 'constants/product'
import { useGalleryDeal, useGalleryProduct } from '../../contexts'

import { AssignmentDTOClient } from 'models/assignment'
import { CircleIcon } from 'components/common/CircleIcon'
import { ClientGalleryStagingTypeFilter } from './ClientGalleryStagingTypeFilter.module'
import FilterTab from 'components/common/FilterTab/FilterTab.module'
import { MUIBadge } from 'components/common/MUIBadge'
import { Path } from 'constants/router'
import { getProgressCategoryData } from 'components/common/Gallery/AssignmentProgress/AssignmentProgressSteps.utils'
import { useClientGallery } from '../_main/contexts'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

/**
 * Client gallery order type filters.
 * 
 * @example <ClientGalleryOrderTypeFilter />
 */
export const ClientGalleryOrderTypeFilter: FC = () => {
  const { t } = useTranslation(['gallery'])
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)
  const { enableStagingFlow } = useClientGallery()
  const navigate = useNavigate()

  const { assignmentId } = useClientGallery()
  const { dealData, dealAssignmentsForTab } = useGalleryDeal()

  const { getProductKindIcon } = useGalleryProduct()

  const badgeColor = useCallback((status?: string) => {
    if (status !== AssignmentJob.ORDER_DELIVERED) return 'orange'
    return 'green'
  }, [])

  const productName = useCallback((assignment: AssignmentDTOClient) => {
    return assignment.products.map((product) => t(`product:p_${product.id}`)).join(', ')
  }, [t])

  /**
   * Comparator function for sorting by product kind.
   * Sorts staging products based on their kind, prioritizing certain types to appear first.
   * Staging products matching STAGING_PRODUCT_KINDS are sorted to the end of the array.
   */
  const sortByKind = (a: AssignmentDTOClient, b: AssignmentDTOClient): number => {
    // Retrieve the KIND values of the compared objects
    const kindA = a.products[0].kind
    const kindB = b.products[0].kind

    // Check if the kinds match the staging types defined in STAGING_PRODUCT_KINDS
    const isKindAStaging = STAGING_PRODUCT_KINDS.has(kindA)
    const isKindBStaging = STAGING_PRODUCT_KINDS.has(kindB)

    // Prioritize staging products to appear on the end of the sorted array
    if (isKindAStaging && !isKindBStaging) return 1
    else if (!isKindAStaging && isKindBStaging) return -1
    else return 0
  }

  const filterTabList = useMemo(() => {
    const sortedData = dealData?.missions.sort(sortByKind)

    return sortedData?.map((assignment) => {
      const productKind = assignment.products[0].kind

      // Show all product kind filters except the additional staging filters which has no active GROUND PHOTO kind or are not delivered
      if (
        dealAssignmentsForTab.some((tabAssignment) => PRODUCTS_WITH_OPTIONAL_STAGING.has(tabAssignment.products[0].kind))
        && STAGING_PRODUCT_KINDS.has(productKind)
        && assignment.stage !== AssignmentStage.VISUALS_SENT_TO_CLIENT
      )
        return null

      const steps = getProgressCategoryData(assignment.stage, assignment.products)
      const activeStepData = steps?.find((step) => step.activeStep)

      return (
        <FilterTab
          key={assignment.id}
          height='5.6rem'
          isActive={assignmentId === assignment.id}
          icon={
            <CircleIcon
              size="3.2rem"
              circleColor={assignmentId !== assignment.id ? BEIGE_600 : undefined}
              icon={getProductKindIcon(productKind)}
            />
          }
          label={
            <Typography
              variant="text-sm"
              fontWeight="semiBold"
              color={GRAY_900}
              sx={{
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                lineClamp: '2',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {productName(assignment)}
            </Typography>
          }
          badge={
            !!activeStepData &&

            <MUIBadge
              label={t(`order_informations.stages.${activeStepData?.jobDone}`)}
              size="sm"
              color={badgeColor(activeStepData.jobDone)}
            />
          }
          sx={{
            maxWidth: '35rem',
            flexShrink: 0
          }}
          onClick={() => navigate(Path.GALLERY.replace(':id', assignment.id.toString()))}
        />

      )
    })
    // t, navigate skipped from deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentId, dealData?.missions, dealAssignmentsForTab, productName, badgeColor])

  return (
    <Fragment>
      {isMobileView ?
        <Box
          position="absolute"
          top="3.2rem"
          left="0"
          width="100vw"
          display="flex"
          gap=".8rem"
          overflow="auto"
          padding="0 2rem"
          boxSizing="border-box"
          sx={{
            '::-webkit-scrollbar': {
              display: 'none'
            }
          }}
        >
          {filterTabList}
          {enableStagingFlow && <ClientGalleryStagingTypeFilter />}
        </Box>
        :
        <Grid container marginTop="3.2rem" gap=".8rem">
          {filterTabList}
          {enableStagingFlow && <ClientGalleryStagingTypeFilter />}
        </Grid>
      }
    </Fragment>
  )
}
