import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { FIXED_DECIMAL_PLACES, bigFromFee } from 'utils/price'

import { InstructionListing } from '../../../common'
import TransitionAppear from 'components/common/TransitionAppear/TransitionAppear'
import { isShootingCategory } from 'utils/validators'
import { useAuth0 } from 'utils/auth'
import { useEffect } from 'react'
import { usePurchaseFlowConfig } from 'components/pages/PurchaseFlow/_main/contexts'
import { usePurchaseFlowProducts } from 'components/pages/PurchaseFlow/_main/contexts/PurchaseFlowProducts.context'
import { useTargetOrderUser } from '../../../_main/contexts'
import { useTranslation } from 'react-i18next'

/**
 * @component Wrapper and main controller for instruction step part of PF 
 * @example <InstructionStepController />
 */
export const InstructionStepController: React.FC = () => {
  const { t } = useTranslation(['order', 'product'])

  const { roles } = useAuth0()
  const { targetUser, adminSelectedUserEmail } = useTargetOrderUser()

  const {
    selectedCategory,
    sessionId,
    selectedCountryCode,
    selectedAssignmentPlace,
    selectedCity,
    getInstructionCatalogue,
  } = usePurchaseFlowConfig()

  const {
    selectedProducts,
    selectedSegment,
    selectedKinds,
    selectedProductTypes,
  } = usePurchaseFlowProducts()

  /** Retrieves the configuration from the API  */
  useEffect(() => {
    if (!getInstructionCatalogue.isIdle) return
    if (!selectedCategory || !sessionId) return
    if (roles.isAdmin && !adminSelectedUserEmail) return
    if (isShootingCategory(selectedCategory) && (!selectedCity || !selectedCountryCode)) return
    if (!Object.keys(selectedProducts).length) return

    getInstructionCatalogue.mutate({
      sessionId,
      area: selectedSegment,
      city: selectedCity,
      email: targetUser.email,
      category: selectedCategory,
      countryCode: selectedCountryCode || targetUser.country,
      coordinates: selectedAssignmentPlace?.geometry.location,
      productKinds: Array.from(selectedKinds),
      productTypes: Array.from(selectedProductTypes),
      products: Object.values(selectedProducts).map((product) => ({
        id: product.id,
        name: t(`product:p_${product.id}`),
        type: product.type,
        kind: product.kind,
        unitPrice: bigFromFee(product.feePrice).toFixed(FIXED_DECIMAL_PLACES),
        count: product.quantity,
      }))
    })
  }, [adminSelectedUserEmail, getInstructionCatalogue, roles.isAdmin, selectedAssignmentPlace?.geometry.location, selectedCategory, selectedCity, selectedCountryCode, selectedKinds, selectedProductTypes, selectedProducts, selectedSegment, sessionId, t, targetUser.country, targetUser.email])

  // log entering this page
  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvent.PURCHASE_FLOW_ENTERS_ORDER_INSTRUCTIONS_STEP, {})
  }, [])

  return (
    <TransitionAppear visible>
      <InstructionListing />
    </TransitionAppear>
  )
}
