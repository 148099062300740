import { Children, FC, ReactNode } from 'react'

import { AnimationComponent } from '../animations.interfaces'

interface Props {
  children: ReactNode
  /** Animation component that will be used as a wrapper */
  animation: AnimationComponent
  /** Whether content is visible or not */
  visible: boolean
  /** How many milliseconds is animation staggered by */
  staggeredBy?: number
  /** How many milliseconds is enter and exit effect delayed by */
  delay?: number
}

/**
 * Wraps each child in specified animation wrapper and staggers the enter/exit by specified amount 
 * @example
 * <StaggerGroup visible={true} animation={AnimationComponent} staggeredBy={20}>
 *  <h1>Hello world</h1>
 *  <p>How are ya?</p>
 * </StaggerGroup> 
 */
export const StaggerGroup: FC<Props> = ({
  children,
  animation: Animation,
  visible,
  delay = 0,
  staggeredBy = 0,
}) => (
  <>
    {Children.map(children, (child, index) => {
      if (!child) return
      return (
        <Animation visible={visible} delay={delay + (staggeredBy * index)} exitDelay={staggeredBy * index}>
          {child as React.ReactElement}
        </Animation>
      )
    })}
  </>
)
