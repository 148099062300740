import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'
import { ActionRequest } from 'models/redux'
import { GetUpsellPaymentIntentStoreItem } from './getUpsellPaymentIntent.interface'
import { PaymentMethodTypes } from 'constants/payments'
import { createAPIAction } from 'redux/Helpers'

/** Describes action that gets upsell payment intent */
export interface ActionGetUpsellPaymentIntent extends ActionRequest {
  payload: {
    missionId: string
    productId: number
    quantity: number
    paymentMethod: PaymentMethodTypes
    request: GetUpsellPaymentIntentStoreItem
  }
}

/** Generates action to get upsell payment intent */
export function getUpsellPaymentIntent(
  missionId: string,
  productId: number,
  quantity: number,
  paymentMethod: PaymentMethodTypes
): ActionGetUpsellPaymentIntent {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.GET_UPSELL_PAYMENT_INTENT], {
    missionId,
    productId,
    quantity,
    paymentMethod,
    request: new APIRequest(APIRequestState.RUNNING),
  })
}

/** Generates action to purge get upsell payment intent action state */
export function purgeGetUpsellPaymentIntent(missionId: string): ActionGetUpsellPaymentIntent {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.GET_UPSELL_PAYMENT_INTENT], {
    missionId,
    request: new APIRequest(APIRequestState.BEFORE_START),
  })
}
