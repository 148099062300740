import { BLUE_600, CORAL_100, CORAL_600, GRAY_100, GRAY_400, GRAY_800, GRAY_900, WHITE } from 'constants/styling/theme'
import { ButtonBase, InputBase, Stack, inputBaseClasses, styled } from '@mui/material'
import React, { ChangeEvent, MouseEvent, ReactNode, useMemo } from 'react'

import { BorderBoxWrapper } from '../BorderBoxWrapper/BorderBoxWrapper.component'
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { Label } from '../Label'

export enum DropdownInputTypes {
  SELECTOR,
  TEXT
}

/**
 * @typedef InputType Available input types
 */
export type InputType = DropdownInputTypes.SELECTOR | DropdownInputTypes.TEXT

/**
 * @interface Props Input properties
 */
export interface Props {
  /** Dropdown input id */
  id?: string,
  /** Dropdown input type */
  type?: InputType,
  /** Dropdown input value */
  value?: string | null,
  /** Dropdown input placeholder */
  placeholder?: string,
  /** Whether the dropdown input is read only or not */
  readOnly?: boolean,
  /** Dropdown input label */
  label?: string
  /** Whether th ropdown input is error */
  isError?: boolean
  /** Dropdown input error text */
  errorText?: string
  /** Whether the dropdown input is disabled or not */
  disabled?: boolean
  /** Whether the dropdown input is required or not */
  required?: boolean
  /** Custom component in input such as a list of badges. */
  customInputContent?: ReactNode
  /** OnChange action to update the input value */
  onChange?: (event: ChangeEvent) => void
  /** OnClick action in input icon */
  onClick?: (event: MouseEvent<HTMLElement>) => void,
  /** Custom tooltip besides label */
  tooltip?: ReactNode,
}

// Globle old input styles will overwrite most of the mui input styles. When we redesign and migrate all the old input, we can remove the !important attribute.
const StyledMUIInput = styled(InputBase)(({ error }) => {
  return {
    width: '100%',
    flex: '1 1 6rem',
    [`.${inputBaseClasses.input}`]: {
      height: '2.4rem !important',
      padding: '0 !important',
      border: 'unset !important',
      color: `${error ? CORAL_600 : GRAY_900} !important`,
      backgroundColor: 'transparent !important',
      boxShadow: 'unset !important',
      ':focus': {
        color: `${GRAY_900} !important`
      }
    },
  }
})

const StyledIconButton = styled(ButtonBase)({
  color: GRAY_800,
  padding: '.8rem',
  height: '3.6rem'
})

/**
 * @component MUI Dropdown Input
 * @example
 * <MUIDropdownInput
 *  type="DropdownInputTypes.SELECTOR"
 *  value="owo!"
 *  label="text"
 *  onChange={() => {}}
 *  tooltip={
 *    <MUITooltip content="You are awesome!">
 *      <Icon fontSize="medium" sx={{ paddingTop: '.4rem' }}/>
 *    </MUITooltip>
 *  }
 * />
 */
export const MUIDropdownInput: React.FC<Props> = ({
  id,
  type = DropdownInputTypes.SELECTOR,
  value,
  placeholder,
  readOnly = false,
  label = undefined,
  isError = false,
  errorText,
  disabled,
  required,
  tooltip,
  customInputContent,
  onChange,
  onClick,
}) => {

  const hoverBorderColor = useMemo(() => {
    if (isError) return 'inherit'
    if (disabled) return GRAY_400
    return BLUE_600
  }, [disabled, isError])

  const inputBgColor = useMemo(() => {
    if (isError) return CORAL_100
    if (disabled) return GRAY_100
    return WHITE
  }, [disabled, isError])

  const inputEndIcon = useMemo(() => {
    switch (type) {
      case DropdownInputTypes.SELECTOR:
        return <KeyboardArrowDownRoundedIcon />
      case DropdownInputTypes.TEXT:
        return <HelpOutlineRoundedIcon />
      default:
        return <></>
    }
  }, [type])

  return (
    <Stack gap={.4}>

      {!!label &&
        <Label
          text={label}
          tooltip={tooltip}
          isError={isError}
          required={required}
        />
      }

      <BorderBoxWrapper
        borderColor={isError ? CORAL_600 : GRAY_400}
        backgroundColor={inputBgColor}
        component="form"
        tabIndex={0}
        onClick={onClick}
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          transition: '.3s ease',
          '&:hover': {
            border: `.1rem solid ${hoverBorderColor}`,
          },
          ':has(input:focus)': {
            backgroundColor: WHITE,
          }
        }}
      >

        <Stack gap=".4rem" flexDirection="row" flexWrap="wrap" padding=".6rem 1.2rem" width="100%">

          {customInputContent}

          <StyledMUIInput
            id={id}
            value={value}
            error={isError}
            disabled={disabled}
            placeholder={placeholder}
            readOnly={readOnly}
            onChange={onChange}
          />

        </Stack>

        <StyledIconButton disableRipple>
          {inputEndIcon}
        </StyledIconButton>

      </BorderBoxWrapper>

      {isError && errorText &&
        <Label text={errorText} isError={true} />
      }

    </Stack>
  )
}
