import { BadgeColor, MUIBadge } from 'components/common/MUIBadge'

import { MessageThreadState } from 'models/messageThreads'
import React from 'react'
import { useTranslation } from 'react-i18next'

/** 
 * @component
 * ThreadStatusBadge is a memoized component that renders a badge styled based on the provided thread status. 
 * */
export const ThreadStatusBadge: React.FC<{ status: MessageThreadState }> = React.memo(({ status }) => {
  const { t } = useTranslation(['threads'])
  let color: BadgeColor

  switch (status) {
    case MessageThreadState.OPEN:
      color = 'green'
      break

    case MessageThreadState.CLOSED:
      color = 'gray'
      break

    default:
      color = 'green'
  }

  return <MUIBadge label={t(`status.${status}`)} color={color} size='sm' />
})
