import { EntityKeys, QueryType, getMutation } from 'utils/reactQuery'

import { useQuery } from '@tanstack/react-query'
import { CreativeWorkingHoursDTO } from 'models/creative'
import { useAPI } from 'utils/API'

enum Endpoints {
  LIST_MINE = '/user/me/workingHours',
  EDIT = '/user/me/workingHours'
}

// QUERIES

export function useWorkingHoursCTList() {
  const api = useAPI<Endpoints>()

  return useQuery<CreativeWorkingHoursDTO, Error>({
    queryKey: [EntityKeys.WORKING_HOURS, QueryType.LIST_MINE],
    queryFn: () => api.get<CreativeWorkingHoursDTO>(
      Endpoints.LIST_MINE,
      {},
      false
    )
  })
}

// MUTATIONS

export function useEditCTWorkingHours() {
  const api = useAPI<Endpoints>()

  return getMutation<CreativeWorkingHoursDTO, CreativeWorkingHoursDTO>(
    (data) => api.put(
      Endpoints.EDIT,
      {},
      { ...data },
      false
    ),
    (client) => {
      client.invalidateQueries({ queryKey: [EntityKeys.WORKING_HOURS, QueryType.LIST_MINE] })
    }
  )
}
