import { AssignmentAutomatedStates, ctDeliveredAssignmentStages } from '../CreativeAssignmentCard'
import { AssignmentAutomationStatus, AssignmentStage, CreativeState } from 'constants/assignment'
import { chooseProductContainingVisuals, isProductWithCT } from 'utils/product'
import { useCallback, useMemo } from 'react'
import { useGalleryAssignment, useGalleryDeal } from '../../_main/contexts'

import { ActionTypeAPIData } from 'constants/redux'
import { AssignmentDTOAdministrator } from 'models/assignment'
import { DealDTOIsAdministratorDTO } from 'utils/typeguards'
import { OrderAutomationStatus } from 'models/deal'
import { ProductKind } from 'constants/product'
import { RootStore } from 'models/redux'
import constate from 'constate'
import { useSelector } from 'react-redux'

/** Enumeration of all possible extra states of the automate order card in relation to each assignment details */
export enum AutomateOrderCardStatus {
  /**  The order can be automated but some of the assignments have conflicts (date and time missing) */
  ASSIGNMENT_CONFLICTS = 'ASSIGNMENT_CONFLICTS',
  /** Automation started but the CT hasn't accept all the assignments */
  AUTOMATION_IN_PROCESS = 'AUTOMATION_IN_PROCESS',
  /** All assignments are have been successfully concluded */
  CT_DELIVERED_ORDER = 'CT_DELIVERED_ORDER',
}

/** All possible automation order card states in relation to automation order statuses */
export type AutomateOrderCardState = AutomateOrderCardStatus | OrderAutomationStatus

/** Enumeration of all possible extra states of the assignment in relation to automate order */
export enum AutomateOrderAssignmentState {
  /** Assignment has conflicts to resolve before it can be automated */
  CONFLICTS = 'CONFLICTS',
  /** Assignment has been successfully concluded */
  DONE = 'DONE',
  /** Waiting for the CT to accept the assignment */
  WAITING_FOR_ACCEPT = 'WAITING_FOR_ACCEPT',
  /** CT accepted the assignment */
  CT_ACCEPTED = 'CT_ACCEPTED'
}

/** All possible automation states of the particular assignment in relation to automate order */
export type AssignmentAutomationStates = AutomateOrderAssignmentState | AssignmentAutomationStatus

/** Assignment DTO describing a single assignment as the administrator sees is in relation to automate order  */
export interface AssignmentDTOAdministratorCard extends AssignmentDTOAdministrator {
  /** The different states of the assignment in relation to automate order */
  state: AssignmentAutomationStates
}

export const [AutomateOrderCardContextProvider, useAutomateOrderCard] = constate(() => {
  const { dealData, dealId, dealAssignmentsForTab } = useGalleryDeal()
  const { assignmentData } = useGalleryAssignment()

  const callbackAssignmentState = useCallback((data: AssignmentDTOAdministrator): AssignmentAutomationStates => {
    const product = chooseProductContainingVisuals(data.products)
    // Assignment has a drone kind of product
    if (!!product && (product.kind === ProductKind.DRONE_PHOTO || product.kind === ProductKind.STANDARD_DRONE_VIDEO)) {
      if (ctDeliveredAssignmentStages.has(data.stage)) return AutomateOrderAssignmentState.DONE
      return data.assignmentAutomationStatus
    }
    // Assignment has been successfully delivered
    if (!!data.creative && ctDeliveredAssignmentStages.has(data.stage))
      return AutomateOrderAssignmentState.DONE
    // Waiting for CT to accept assignment
    if (data.creativeState === CreativeState.WAITING_FOR_ACCEPT && data.assignmentAutomationStatus)
      return AutomateOrderAssignmentState.WAITING_FOR_ACCEPT
    // Creative accepted the assignment
    if (AssignmentAutomatedStates.has(data.assignmentAutomationStatus) && data.stage === AssignmentStage.CREATIVE_BOOKED)
      return AutomateOrderAssignmentState.CT_ACCEPTED
    // Assignment is taking place within the next 36 hours and can be automated
    if (data.assignmentAutomationStatus !== AssignmentAutomationStatus.MANUAL && !data.scheduledByCT && !data.shootingStartDateTime)
      return AutomateOrderAssignmentState.CONFLICTS
    // Assignment has a selected favourite creative
    if (!!data.selectedFavouriteCreative) return AssignmentAutomationStatus.MANUAL

    return data.assignmentAutomationStatus
  }, [])

  const assignments: AssignmentDTOAdministratorCard[] | undefined = useMemo(() => {
    if (!dealAssignmentsForTab || !dealAssignmentsForTab.length) return undefined
    return (dealAssignmentsForTab as AssignmentDTOAdministrator[])
      .filter((assignmentData) => assignmentData.products.some(
        (product) => isProductWithCT(product)
      ))
      .map(
        (assignment) => {
          const state = callbackAssignmentState(assignment)
          return { ...assignment, state }
        }
      )
  }, [callbackAssignmentState, dealAssignmentsForTab])

  const showAutomateOrder = useMemo(() => {
    if (!assignments || !assignments.length) return false
    return true
  }, [assignments])

  const orderAutomationState: AutomateOrderCardState = useMemo(() => {
    if (dealData && DealDTOIsAdministratorDTO(dealData)) {
      const automatableAssignments = assignments?.filter(
        assignment => assignment.state !== AssignmentAutomationStatus.AUTOMATION_OUT_OF_SCOPE
      )
      // Some of the assignments are out of scope
      if (automatableAssignments?.length !== assignments?.length)
        return OrderAutomationStatus.AUTOMATION_OUT_OF_SCOPE
      // All assignments are completed
      if (assignments?.every((assignment) => assignment.state === AutomateOrderAssignmentState.DONE))
        return AutomateOrderCardStatus.CT_DELIVERED_ORDER
      // Some of the automatable assignments have conflicts
      if (automatableAssignments?.some((assignment) => assignment.state === AutomateOrderAssignmentState.CONFLICTS))
        return AutomateOrderCardStatus.ASSIGNMENT_CONFLICTS
      // Some of the assignments have a selected favourite CT
      if (automatableAssignments?.some((assignment) => assignment.selectedFavouriteCreative))
        return OrderAutomationStatus.MANUAL
      // Waiting for CT to accept or CT is booked (some assignments and not all)
      if (dealData.orderAutomationStatus !== OrderAutomationStatus.MANUAL
        && automatableAssignments?.some((assignment) => assignment.state === AutomateOrderAssignmentState.WAITING_FOR_ACCEPT)
        || (!automatableAssignments?.every((assignment) => assignment.state === AutomateOrderAssignmentState.CT_ACCEPTED)
          && automatableAssignments?.some((assignment) => assignment.state === AutomateOrderAssignmentState.CT_ACCEPTED))
        // TO REVIEW: Remove and handle differently when BE is done
        || dealData.orderAutomationStatus === OrderAutomationStatus.AUTOMATED_INDIVIDUAL_ASSIGNMENTS
      )
        return AutomateOrderCardStatus.AUTOMATION_IN_PROCESS

      // Order automation status from BE
      return dealData.orderAutomationStatus
    }

    return OrderAutomationStatus.AUTOMATION_OUT_OF_SCOPE
  }, [assignments, dealData])

  const currentAssignmentId = useMemo(() => !!assignmentData ? assignmentData.id : null, [assignmentData])
  const automateOrderResponse = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.AUTOMATE_ORDER][dealId])
  const stopAutomatedOrderResponse = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.STOP_AUTOMATED_ORDER][dealId])

  return {
    assignments,
    automateOrderResponse,
    currentAssignmentId,
    orderAutomationState,
    showAutomateOrder,
    stopAutomatedOrderResponse,
  }
})
