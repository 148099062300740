export const supportEmail = 'support@bkbn.com'
export const supportEmailHref = () => `mailto:${supportEmail}`

/** Phone support number */
export enum phoneSupportNumber {
  /** Switzerland */
  CH = '+41 43 508 23 80',

  /** Germany */
  DE = '+49 30 31198368',

  /** Austria */
  AT = '+43 720 231778',

  /** France */
  FR = '',

  /** Spain */
  ES = '+34 919 49 74 11',

  /** Luxembourg */
  LU = '',

  /** United Kingdom (ISO compliant) */
  GB = '',

  /** Croatia */
  HR = '',

  /** Belgium */
  BE = '',

  /** Netherlands */
  NL = '',
}

/** Turns phone support number string into href string such as tel:XXXXXXXXX */
export const phoneSupportNumberToHref = (supportPhoneNumber: phoneSupportNumber) => {
  return `tel:${supportPhoneNumber.replace(/\W/igm, '')}`
}