import { RoomItemPopupType } from 'components/common/StagingFlow/main/contexts'
import { AnalyticsEvent } from 'utils/analytics'

export const roomItemClosePopupEventName = (popupType: RoomItemPopupType | null) => {
  switch (popupType) {
    case RoomItemPopupType.WALL:
      return AnalyticsEvent.WALL_FLOW_CLOSED
    case RoomItemPopupType.FLOOR:
      return AnalyticsEvent.FLOOR_FLOW_CLOSED

    default:
      return ''
  }
}

export const roomItemOpenPopupEventName = (popupType: RoomItemPopupType | null) => {
  switch (popupType) {
    case RoomItemPopupType.FURNITURE:
      return AnalyticsEvent.FURNITURE_FLOW_OPENED
    case RoomItemPopupType.DECORATION:
      return AnalyticsEvent.DECORATION_FLOW_OPENED
    case RoomItemPopupType.WALL:
      return AnalyticsEvent.WALL_FLOW_OPENED
    case RoomItemPopupType.FLOOR:
      return AnalyticsEvent.FLOOR_FLOW_OPENED

    default:
      return ''
  }
}

export const roomItemPopupSelectEventName = (popupType: RoomItemPopupType | null) => {
  switch (popupType) {
    case RoomItemPopupType.WALL:
      return AnalyticsEvent.WALL_TEMPLATES_SELECTED
    case RoomItemPopupType.FLOOR:
      return AnalyticsEvent.FLOOR_TEMPLATES_SELECTED

    default:
      return ''
  }
}
