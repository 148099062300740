/** available sort direction options */
export enum SortDirection {
  NONE = 'NONE',
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

/** available sort by options */
export enum SortBy {
  ORDER_PUBLIC_ID = 'ORDER_PUBLIC_ID',
  CREATION_DATE = 'CREATION_DATE',
  SHOOTING_DATE = 'SHOOTING_DATE',
  DEFAULT = ORDER_PUBLIC_ID,
}

/** available filter options for deal/assignment listing */
export enum OrderAssignmentStatusFilter {
  ALL = 'ALL',
  AVAILABLE = 'AVAILABLE',
  ONGOING = 'ONGOING',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED',
  NEEDS_ATTENTION = 'NEEDS_ATTENTION',
}

/** ownership scope of the orders (individual/organization) */
export enum OwnershipScope {
  INDIVIDUAL = 'INDIVIDUAL',
  ORGANIZATION = 'ORGANIZATION',
  WORKSPACE = 'WORKSPACE',
}

/** For subscribed users who have access to all orders from their organization */
export enum OwnershipTab {
  MY_ORDERS = 'MY_ORDERS',
  ALL_ORDERS = 'ALL_ORDERS',
}

export enum ListKey {
  FINISHED = 'FINISHED',
  ONGOING = 'ONGOING',
}

/** available date filter types for deal/assignment listing */
export enum DateTypeFilter {
  CREATION = 'CREATION',
  SHOOTING = 'SHOOTING',
}
