import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AnalyticsEvent } from 'utils/analytics'
import { Path } from 'constants/router'
import { Tab } from '../ClientGallery/_main/ClientGallery.controller'
import constate from 'constate'
import { useGalleryAssignment } from './GalleryAssignment.context'

export const ORDER_INFO_PATH = 'order-info'

/** Context provider for gallery navigation tabs */
export const [GalleryNavigationContextProvider, useGalleryNavigation] = constate(() => {
  const navigate = useNavigate()
  const { id, tab } = useParams<{ id: string, tab?: string }>()
  const { logGalleryEvent } = useGalleryAssignment()

  const [activeTab, setActiveTab] = useState<Tab>(tab === ORDER_INFO_PATH ? Tab.ORDER_INFO : Tab.GALLERY)

  const goToGalleryPath = (selectedTab: Tab) => {
    if (selectedTab === Tab.ORDER_INFO && id)
      navigate(Path.GALLERY_INFO.replace(':id', id.toString()).replace(':tab', ORDER_INFO_PATH))
    else if (selectedTab !== Tab.ORDER_INFO && id) navigate(Path.GALLERY.replace(':id', id.toString()))
  }

  const handleChangeTab = (tab: Tab) => {
    setActiveTab(tab)
    goToGalleryPath(tab)

    let eventName = AnalyticsEvent.CLICK_GALLERY_TAB

    switch (tab) {
      case Tab.ORDER_INFO:
        eventName = AnalyticsEvent.CLICK_ORDER_INFO_TAB
        break
      default:
        eventName = AnalyticsEvent.CLICK_GALLERY_TAB
        break
    }

    if (eventName) {
      logGalleryEvent(eventName)
    }
  }

  useEffect(() => {
    if (activeTab) goToGalleryPath(activeTab)
    // no goToGalleryPath in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    handleChangeTab,
    setActiveTab,
    activeTab,
  }
})
