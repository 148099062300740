// Product Segment enum - Describes product segment (all, area, duration, etc...)
export enum ProductSegment {
  // All

  all = 'all',

  // Area
  m0000_0099 = 'm0000_0099',
  m0100_0199 = 'm0100_0199',
  m0200_0299 = 'm0200_0299',
  m0300_0399 = 'm0300_0399',
  m0400_0499 = 'm0400_0499',
  m0500_0599 = 'm0500_0599',
  m0600_0699 = 'm0600_0699',
  m0700_0799 = 'm0700_0799',
  m0800_0899 = 'm0800_0899',
  m0900_0999 = 'm0900_0999',
  m1000_p = 'm1000_p',

  // Duration
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  h7 = 'h7',
  h8 = 'h8',
  h8_p = 'h8_p',
}

// Product Type enum - Describes product type (PHOTO, VIDEO, VIRTUAL_VISIT, FLOOR_PLAN, NONE)
export enum ProductType {
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO',
  VIRTUAL_VISIT = 'VIRTUAL_VISIT',
  FLOOR_PLAN = 'FLOOR_PLAN',
  STAGING_360 = 'STAGING_360',

  // DOCUMENTS
  CONSTRUCTION_FILE = 'CONSTRUCTION_FILE',
  LAND_REGISTRATION = 'LAND_REGISTRATION',
  PROTECTION = 'PROTECTION',
  MUNICIPAL = 'MUNICIPAL',
  DOCUMENTATION = 'DOCUMENTATION',
  LAW_INQUIRIES = 'LAW_INQUIRIES',
  ENERGY_CERTIFICATE_OF_NEED = 'ENERGY_CERTIFICATE_OF_NEED',
  CONSUMPTION_CERTIFICATE = 'CONSUMPTION_CERTIFICATE',

  // BUNDLES
  GROUND_PHOTO_DRONE_PHOTO_BUNDLE = 'GROUND_PHOTO_DRONE_PHOTO_BUNDLE',
  GROUND_PHOTO_STAGING_BUNDLE = 'GROUND_PHOTO_STAGING_BUNDLE',
  GROUND_PHOTO_FLOOR_PLAN_BUNDLE = 'GROUND_PHOTO_FLOOR_PLAN_BUNDLE',
  CUSTOM_CLIENT_BUNDLE = 'CUSTOM_CLIENT_BUNDLE',
  PHOTO_VIDEO_TEASER_BUNDLE = 'PHOTO_VIDEO_TEASER_BUNDLE',
  PHOTO_VIRTUAL_VISIT_BUNDLE = 'PHOTO_VIRTUAL_VISIT_BUNDLE',
  PHOTO_SEVERAL_FLATS_AT_ONE_ADDRESS = 'PHOTO_SEVERAL_FLATS_AT_ONE_ADDRESS',
  PHOTO_FLOOR_PLAN_VIDEO_TEASER = 'PHOTO_FLOOR_PLAN_VIDEO_TEASER',

  // Special type for purchase flow.
  NONE = 'NONE',
}

/** Product Kind enum - Describes product kind (GROUND_PHOTO, COMMERCIAL_STAGING, MEETING_ON_SITE, etc...)  */
export enum ProductKind {
  // MARK: - Core

  GROUND_PHOTO = 'GROUND_PHOTO',
  STANDARD_GROUND_VIDEO = 'STANDARD_GROUND_VIDEO',
  DRONE_PHOTO = 'DRONE_PHOTO',
  STANDARD_DRONE_VIDEO = 'STANDARD_DRONE_VIDEO',
  STANDARD_GROUND_AND_DRONE_VIDEO = 'STANDARD_GROUND_AND_DRONE_VIDEO',
  GROUND_PHOTO_PRESTIGE = 'GROUND_PHOTO_PRESTIGE',
  PRESTIGE_GROUND_AND_DRONE_VIDEO = 'PRESTIGE_GROUND_AND_DRONE_VIDEO',
  OTHER = 'OTHER',
  BUNDLE = 'BUNDLE',
  MAST_PHOTO = 'MAST_PHOTO',
  REEL_VIDEO = 'REEL_VIDEO',
  TEASER_VIDEO = 'TEASER_VIDEO',

  // MARK: - Documents
  AUTHORITIES_DOCUMENTS = 'AUTHORITIES_DOCUMENTS',
  EXTRA_LAND_REGISTRATION_SEARCH = 'EXTRA_LAND_REGISTRATION_SEARCH',
  EXTRA_OFFICE_FEE = 'EXTRA_OFFICE_FEE',

  // MARK: - Energy certificates
  ENERGY_CERTIFICATE = 'ENERGY_CERTIFICATE',

  // MARK: - Options

  RESIDENTIAL_STAGING = 'RESIDENTIAL_STAGING',
  COMMERCIAL_STAGING = 'COMMERCIAL_STAGING',
  RESIDENTIAL_RENDERING = 'RESIDENTIAL_RENDERING',
  COMMERCIAL_RENDERING = 'COMMERCIAL_RENDERING',
  MATTERPORT_FLOOR_PLAN_WITH_VISIT = 'MATTERPORT_FLOOR_PLAN_WITH_VISIT', // -- matterport floor plan
  MATTERPORT_FLOOR_PLAN_WITHOUT_VISIT = 'MATTERPORT_FLOOR_PLAN_WITHOUT_VISIT', // -- matterport floor plan
  MATTERPORT_PHOTO = 'MATTERPORT_PHOTO',
  MATTERTAG = 'MATTERTAG',
  PHOTO = 'PHOTO',
  PORTRAIT = 'PORTRAIT',
  CORPORATE = 'CORPORATE',
  MOBILE_APPLICATION_FLOOR_PLAN = 'MOBILE_APPLICATION_FLOOR_PLAN', // -- basic floor plan
  FLOOR_PLAN_EDITING_2D = 'FLOOR_PLAN_EDITING_2D', // -- floor plan editing
  FLOOR_PLAN_EDITING_3D = 'FLOOR_PLAN_EDITING_3D', // -- floor plan editing
  FLOOR_PLAN_CERTIFICATION = 'FLOOR_PLAN_CERTIFICATION', // -- floor plan certification
  EXTRA_CERTIFIED_FLOOR = 'EXTRA_CERTIFIED_FLOOR', // -- floor plan certification
  EXTRA_CERTIFIED_ROOM = 'EXTRA_CERTIFIED_ROOM', // -- floor plan certification
  EXTRA_ARCHITECT_STAMP = 'EXTRA_ARCHITECT_STAMP', // -- floor plan certification
  SLIDESHOW = 'SLIDESHOW',
  MEASUREMENT_ON_SITE = 'MEASUREMENT_ON_SITE',
  EXTRA_DETACHED_HOUSE_TYPE = 'EXTRA_DETACHED_HOUSE_TYPE', // measurement on site - property type extra
  EXTRA_SINGLE_FLOOR_HOUSE_TYPE = 'EXTRA_SINGLE_FLOOR_HOUSE_TYPE', // measurement on site - property type extra
  EXTRA_SINGLE_FAMILY_HOUSE_2ND_FLOOR = 'EXTRA_SINGLE_FAMILY_HOUSE_2ND_FLOOR', // measurement on site - property type extra
  EXTRA_SURFACE_MEASUREMENT = 'EXTRA_SURFACE_MEASUREMENT',
  EXTRA_MEASURED_ROOM = 'EXTRA_MEASURED_ROOM',
  EXTRA_MEASURED_FLOOR = 'EXTRA_MEASURED_FLOOR',
  EXTRA_MEASURED_BASEMENT = 'EXTRA_MEASURED_BASEMENT',
  EXTRA_MEASURED_ATTIC = 'EXTRA_MEASURED_ATTIC',
  EXTRA_MEASURED_RESIDENTIAL_UNIT = 'EXTRA_MEASURED_RESIDENTIAL_UNIT',

  PHOTO_BLURRING = 'PHOTO_BLURRING',

  // MARK: - Admin

  HOSTING = 'HOSTING',
  DRONE_AUTHORIZATION = 'DRONE_AUTHORIZATION',

  // MARK: - Quality

  SD = 'SD',
  HD = 'HD',

  // MARK: - Extras

  HOUR_ON_SITE = 'HOUR_ON_SITE',
  EQUIPMENT = 'EQUIPMENT',

  // MARK: - Delivery speed
  FAST_DELIVERY = 'FAST_DELIVERY',

  // MARK: - Organization

  MEETING_ON_SITE = 'MEETING_ON_SITE',
  ORGANIZATION_THIRD_PARTY = 'ORGANIZATION_THIRD_PARTY',
  KEYS_PICKUP = 'KEYS_PICKUP',

  // MARK: - Billing

  EXTERNAL_REPORTING = 'EXTERNAL_REPORTING',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  INVOICE_BY_EMAIL = 'INVOICE_BY_EMAIL',
  INVOICE_BY_POST = 'INVOICE_BY_POST',
  STRIPE_PAYMENT = 'STRIPE_PAYMENT',
  PERIODICAL_INVOICE = 'PERIODICAL_INVOICE',
  SEPA_DIRECT_DEBIT = 'SEPA_DIRECT_DEBIT',
  CARD_PAYMENT = 'CARD_PAYMENT',
  FAILED_SEPA_DIRECT_DEBIT = 'FAILED_SEPA_DIRECT_DEBIT',

  // MARK: Book CT
  FAVOURITE_CREATIVE = 'FAVOURITE_CREATIVE',

  // MARK: - Special type for purchase flow.

  NONE = 'NONE',

  // MARK: Helper kind just for FE

  BILLING_ADDRESS = 'BILLING_ADDRESS',
  VAT_NUMBER = 'VAT_NUMBER',
  PRODUCTION_COMMENTS = 'PRODUCTION_COMMENTS',
  REFERENCE = 'REFERENCE',
  PROPERTY_OWNER = 'PROPERTY_OWNER',
}

/** Product Flavor - Describes flavor of the product (STANDARD, ORGANIZATION, ADMIN, BILLING, EXTRA) */
export enum ProductFlavor {
  /** Standard product offered to the customer. */
  STANDARD = 'STANDARD',
  /** Organizational activity needed to be performed to produce the product. */
  ORGANIZATION = 'ORGANIZATION',
  /** Administrative cost of the product. */
  ADMIN = 'ADMIN',
  /** Billing related products. */
  BILLING = 'BILLING',
  /** Extra product alongside with product. */
  EXTRA = 'EXTRA'
}

/** Product Option Type enum - Describes available types of product options */
export enum ProductOptionType {
  /** a certain amount of the option can be ordered */
  INTEGER = 'INTEGER',
  /** the options is included or not */
  BOOLEAN = 'BOOLEAN',
}

export const BundleProductTypes = new Set([
  ProductType.GROUND_PHOTO_DRONE_PHOTO_BUNDLE,
  ProductType.GROUND_PHOTO_FLOOR_PLAN_BUNDLE,
  ProductType.GROUND_PHOTO_STAGING_BUNDLE,
  ProductType.CUSTOM_CLIENT_BUNDLE,
  ProductType.PHOTO_VIDEO_TEASER_BUNDLE,
  ProductType.PHOTO_VIRTUAL_VISIT_BUNDLE,
  ProductType.PHOTO_SEVERAL_FLATS_AT_ONE_ADDRESS,
  ProductType.PHOTO_FLOOR_PLAN_VIDEO_TEASER
])

export const PrestigeProductKinds = new Set([
  ProductKind.PRESTIGE_GROUND_AND_DRONE_VIDEO,
  ProductKind.GROUND_PHOTO_PRESTIGE
])

export const OrganizationProductKinds = new Set([
  ProductKind.KEYS_PICKUP,
  ProductKind.MEETING_ON_SITE,
  ProductKind.ORGANIZATION_THIRD_PARTY
])

export const NoUploadEditingKinds = new Set([
  ProductKind.RESIDENTIAL_RENDERING,
  ProductKind.COMMERCIAL_RENDERING,
  ProductKind.EXTRA_ARCHITECT_STAMP,
  ProductKind.EXTRA_CERTIFIED_FLOOR,
  ProductKind.EXTRA_CERTIFIED_ROOM
])

export const UnlimitedUploadKinds = new Set([
  ProductKind.SLIDESHOW,
  ProductKind.FLOOR_PLAN_EDITING_2D,
])

export const ProductTypesFloorPlan = new Set([
  ProductType.PHOTO_FLOOR_PLAN_VIDEO_TEASER,
  ProductType.GROUND_PHOTO_FLOOR_PLAN_BUNDLE,
])

export const ProductKindsFloorPlan = new Set([
  ProductKind.FLOOR_PLAN_EDITING_2D,
  ProductKind.MATTERPORT_FLOOR_PLAN_WITHOUT_VISIT,
  ProductKind.MATTERPORT_FLOOR_PLAN_WITH_VISIT,
  ProductKind.MOBILE_APPLICATION_FLOOR_PLAN,
])

export const ProductTypesRequiringFloorPlanCertification = new Set([
  ProductType.FLOOR_PLAN,
])

export const ProductKindsRequiringFloorPlanCertification = new Set([
  ProductKind.FLOOR_PLAN_CERTIFICATION,
])

export const ProductKindsWithPFDocumentUpload = new Set([
  ProductKind.FLOOR_PLAN_CERTIFICATION,
])

export const ProductKindsWithDocumentGallery = new Set([
  ProductKind.FLOOR_PLAN_CERTIFICATION,
  ProductKind.AUTHORITIES_DOCUMENTS,
  ProductKind.MEASUREMENT_ON_SITE,
  ProductKind.ENERGY_CERTIFICATE,
])

export const ProductKindsWithDocumentGalleryWithoutMeasurementOnSite = new Set([
  ProductKind.FLOOR_PLAN_CERTIFICATION,
  ProductKind.AUTHORITIES_DOCUMENTS,
  ProductKind.ENERGY_CERTIFICATE,
])

export const ProductKindsWithDocumentDraftCheck = new Set([
  ProductKind.FLOOR_PLAN_CERTIFICATION,
  ProductKind.ENERGY_CERTIFICATE,
])

export type OrganizationKindsType = ProductKind.KEYS_PICKUP | ProductKind.MEETING_ON_SITE | ProductKind.ORGANIZATION_THIRD_PARTY

export type ProductKindsWithCheckbox = ProductKind.MEETING_ON_SITE | ProductKind.KEYS_PICKUP
