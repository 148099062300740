import './Icon.sass'

import * as Icons from 'constants/assets/icons'

import { ColorClass, IconType } from 'constants/assets'

import React from 'react'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** The concrete icon to be used */
  icon: IconType
  /** The additional classes to append */
  className?: string
  /** Icon color (in hex) */
  color?: string
  /** Color class to use instead of hex color */
  colorClass?: ColorClass
  /** Additional inline styling */
  style?: React.CSSProperties
  /** react-tooltip property for tooltip enabling */
  'data-tip'?: boolean
  /** react-tooltip property for tooltip assignment */
  'data-for'?: string
}

/**
 * @component Graphical component containing an icon to be inserted into a text, button or a link. The icon can be determined by icon property and color by color or colorClass property
 * @example
 * <Icon icon={IconType.PHONE} colorClass={ColorClass.BLACK} style={{marginRight: '2rem'}} className="class" />
 * 
 * @example
 * <Icon icon={IconType.PHONE} color="#FF0000" style={{marginRight: '2rem'}} className="class" />
 */
const Icon: React.FC<Props> = ({
  icon,
  className = '',
  color,
  colorClass = ColorClass.BLACK,
  style = {},
  'data-tip': dataTip,
  'data-for': dataFor,
}: Props) => {
  let allClasses = `icon ${className}`

  if (color) {
    style = {
      ...style,
      fill: color,
    }
  } else allClasses += ` ${colorClass}`

  allClasses = allClasses.replace(/\s+/igm, ' ').trim()

  const IconComponent = Icons[icon]

  return (
    <IconComponent
      style={style}
      className={allClasses}
      data-tip={dataTip}
      data-for={dataFor}
    />
  )
}

export default Icon
