import { GRAY_400 } from 'constants/styling/theme'
import React, { useCallback, useEffect, useState } from 'react'
import { supportedDocumentFileTypes, supportedImageFileTypes } from 'constants/misc'
import { useGalleryDeal, useGalleryProduct } from 'components/pages/Gallery/_main/contexts'

import { AssignmentDocumentsEndpoint } from 'constants/API'
import { DocumentUploadType } from 'constants/documents'
import { FileAPIUpload } from 'components/common/FileAPIUpload'
import { Label } from 'components/common/Label'
import { MUIButton } from 'components/common/MUIButton'
import { MUIInputField } from 'components/common/MUIInputField'
import { QueryStatus } from 'components/common/QueryStatus'
import { ResponsiveDrawer } from 'components/common/ResponsiveDrawer'
import Stack from '@mui/material/Stack'
import { useClientGallery } from 'components/pages/Gallery/_main/ClientGallery/_main'
import { useCreateAssignmentMessageThread } from 'dataQueries/messageThreads'
import { useFileAPI } from 'components/common/FileAPI'
import { useTranslation } from 'react-i18next'

/** interface @Props for the ClientGalleryCreateRequestDrawer component */
interface Props {
  /** Determines if the drawer is open. */
  isOpen: boolean
  /** Whether the user can upload documents in the drawer. */
  allowUploadOfDocuments?: boolean
  /** Function to call when the drawer is closed. */
  onClose: () => void
}

/** File API scope for the thread drawer upload */
const thread_drawer_upload_scope = 'CREATE_THREAD_DRAWER'

/**
 * @component
 * Drawer for creating a new request thread in the client gallery.
 *
 * @example
 * <ClientGalleryCreateRequestDrawer isOpen={true} onClose={() => setOpenDrawer(false)} allowUploadOfDocuments={true} />
 */
export const ClientGalleryCreateRequestDrawer: React.FC<Props> = ({
  isOpen,
  allowUploadOfDocuments,
  onClose,
}) => {

  const { t } = useTranslation(['threads', 'actions', 'common'])

  const { assignmentId } = useClientGallery()
  const createAssignmentMessageThread = useCreateAssignmentMessageThread()
  const { refetchTotalRequestsCount } = useGalleryDeal()
  const { isEnergyCertificateProduct } = useGalleryProduct()

  const [newThreadTitle, setNewThreadTitle] = useState('')
  const [newThreadMessage, setNewThreadMessage] = useState('')
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false)

  /** Initializes File API for the request drawer */
  const drawerThreadUpload = useFileAPI(thread_drawer_upload_scope, {
    uploadUrlResolver: (fileEntry, api) => {
      return api.post(
        AssignmentDocumentsEndpoint.GET_UPLOAD_URL,
        { assignmentId },
        {
          filename: fileEntry.fileObject.name,
          contentType: fileEntry.fileObject.type,
          type: DocumentUploadType.INPUT,
        },
        true
      )
    },
    deleteHandler: (fileEntry, api) => api.delete(
      AssignmentDocumentsEndpoint.DELETE_DOCUMENT,
      { assignmentId },
      true,
      {
        params: {
          type: DocumentUploadType.INPUT,
          filename: fileEntry.gcFilename,
        }
      }
    )
  })

  /** Handles closing the drawer, clear the input fields and purge files. */
  const handleCloseDrawer = useCallback(() => {
    setNewThreadTitle('')
    setNewThreadMessage('')

    drawerThreadUpload.purgeFilesScope(thread_drawer_upload_scope)
    onClose()
  }, [drawerThreadUpload, onClose])

  /** Handles the creation of a new request thread. */
  const handleCreateRequestThread = useCallback(() => {
    const newThreadFilenames = drawerThreadUpload.allFilesArray.map((file) => file.gcFilename).filter((filename): filename is string => !!filename)

    createAssignmentMessageThread.mutate({
      assignmentId,
      title: newThreadTitle,
      externalReference: isEnergyCertificateProduct ? assignmentId : null,
      initialMessage: {
        content: newThreadMessage,
        attachmentFilenames: newThreadFilenames,

      }
    })

    refetchTotalRequestsCount()
  }, [assignmentId, createAssignmentMessageThread, drawerThreadUpload.allFilesArray, isEnergyCertificateProduct, newThreadMessage, newThreadTitle, refetchTotalRequestsCount])

  // Disable the submit button if the title or message are empty
  useEffect(() => {
    setDisabledSubmitButton(!newThreadTitle || !newThreadMessage)
  }, [newThreadTitle, newThreadMessage])

  // Close the drawer when the thread is successfully created
  useEffect(() => {
    if (createAssignmentMessageThread.isSuccess) {
      setTimeout(() => handleCloseDrawer(), 2000)
    }
  }, [createAssignmentMessageThread.isSuccess, handleCloseDrawer])

  // Set the default thread title for energy certificate products
  useEffect(() => {
    if (isOpen && isEnergyCertificateProduct) {
      setNewThreadTitle(t('new_thread_title', { assignmentId }))
    }
  }, [assignmentId, isEnergyCertificateProduct, isOpen, t])

  return (
    <ResponsiveDrawer
      isOpen={isOpen}
      onClose={handleCloseDrawer}
      title={t('new_request_title')}
      actionsSlot={
        <Stack direction="row" gap={1}>

          <MUIButton type="secondaryBorder" onClick={handleCloseDrawer}>
            {t('actions:Cancel')}
          </MUIButton>

          <MUIButton
            disabled={disabledSubmitButton || !createAssignmentMessageThread.isIdle}
            isLoading={createAssignmentMessageThread.isPending}
            onClick={handleCreateRequestThread}
          >
            {t('send_request')}
          </MUIButton>

        </Stack>
      }
    >
      <Stack gap={2}>

        <MUIInputField
          required
          label={t('create_thread.label_title')}
          value={newThreadTitle}
          disabled={isEnergyCertificateProduct}
          onChange={(e) => setNewThreadTitle(e.target.value)}
        />

        <MUIInputField
          required
          isMultiline={true}
          value={newThreadMessage}
          label={t('create_thread.label_message')}
          onChange={(e) => setNewThreadMessage(e.target.value)}
        />

        {allowUploadOfDocuments && (
          <Stack gap={0.6}>
            <Label text={t('create_thread.label_attachments')} />

            <FileAPIUpload
              boxElevation="xs"
              borderBoxColor={GRAY_400}
              files={drawerThreadUpload.allFilesArray}
              maxNumberOfFiles={5}
              acceptedFileTypes={[...supportedImageFileTypes, ...supportedDocumentFileTypes]}
              uploadHandler={(acceptedFiles) => drawerThreadUpload.uploadFiles(acceptedFiles)}
              onDelete={(fileId) => drawerThreadUpload.deleteFiles([fileId])}
            />
          </Stack>
        )}

      </Stack>

      <QueryStatus
        query={createAssignmentMessageThread}
        successMessage={t('create_thread.success')}
        spaceTopRem={2}
      />

    </ResponsiveDrawer>
  )
}
