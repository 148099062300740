
/** ConsumptionCertificateLivingUnits enum */
export enum ConsumptionCertificateLivingUnits {
  FIVE_OR_MORE = 'FIVE_OR_MORE',
  LESS_THAN_FIVE = 'LESS_THAN_FIVE',
}

/** ConsumptionCertificateBuildingPeriod enum */
export enum ConsumptionCertificateBuildingPeriod {
  BEFORE_1978 = 'BEFORE_1978',
  FROM_1978_ON = 'FROM_1978_ON',
}

/** BuildingLocation enum */
export enum BuildingLocation {
  DETACHED = 'DETACHED',
  TERRACED_HOUSE_MIDDLE = 'TERRACED_HOUSE_MIDDLE',
  SEMI_DETACHED = 'SEMI_DETACHED',
}

/** RenewableEnergySource enum */
export enum RenewableEnergySource {
  NONE = 'NONE',
  SOLAR_THERMAL = 'SOLAR_THERMAL',
  HEAT_PUMP = 'HEAT_PUMP',
  SOLAR_THERMAL_AND_HEAT_PUMP = 'SOLAR_THERMAL_AND_HEAT_PUMP',
}

/** RenewableEnergySourceUsage enum */
export enum RenewableEnergySourceUsage {
  NONE = 'NONE',
  HEATING = 'HEATING',
  HOT_WATER = 'HOT_WATER',
  HEATING_AND_HOT_WATER = 'HEATING_AND_HOT_WATER',
}

/** ConsumptionCertificateBasementType enum */
export enum ConsumptionCertificateBasementType {
  HEATED_BASEMENT = 'HEATED_BASEMENT',
  UNHEATED_BASEMENT = 'UNHEATED_BASEMENT',
  NO_BASEMENT = 'NO_BASEMENT',
}

/** HeaterConsumptionUnit enum */
export enum HeaterConsumptionUnit {
  KWH_CALORIFIC_VALUE = 'KWH_CALORIFIC_VALUE',
  KWH_COMBUSTION_VALUE = 'KWH_COMBUSTION_VALUE',
  LITER = 'LITER',
  KILOGRAM = 'KILOGRAM',
  CUBIC_METER = 'CUBIC_METER',
  CUBIC_METER_ROOM = 'CUBIC_METER_ROOM',
  KWH = 'KWH',
}

/** ConsumptionCertificateVentilationType enum */
export enum ConsumptionCertificateVentilationType {
  STANDARD_WINDOW_VENTILATION = 'STANDARD_WINDOW_VENTILATION',
  SHAFT_VENTILATION = 'SHAFT_VENTILATION',
  VENTILATION_WITH_HEAT_RECOVERY = 'VENTILATION_WITH_HEAT_RECOVERY',
  VENTILATION_WITHOUT_HEAT_RECOVERY = 'VENTILATION_WITHOUT_HEAT_RECOVERY',
}

/** HotWaterGenerationType enum */
export enum HotWaterGenerationType {
  CENTRAL_HEATING_STANDARD = 'CENTRAL_HEATING_STANDARD',
  INSTANT_WATER_HEATER_BOILER = 'INSTANT_WATER_HEATER_BOILER',
  INSTANT_WATER_HEATER_BOILER_WITH_SOLAR = 'INSTANT_WATER_HEATER_BOILER_WITH_SOLAR',
  KNOW_HOT_WATER_CONSUMPTION_IN_KWH = 'KNOW_HOT_WATER_CONSUMPTION_IN_KWH',
  NO_HOT_WATER_GENERATION = 'NO_HOT_WATER_GENERATION',
}

/** EnergeticMeasure enum */
export enum EnergeticMeasure {
  CONDENSING_BOILER = 'CONDENSING_BOILER',
  HYDRAULIC_BALANCING = 'HYDRAULIC_BALANCING',
  BASEMENT_CEILING_INSULATION = 'BASEMENT_CEILING_INSULATION',
  EXTERNAL_WALL_INSULATION = 'EXTERNAL_WALL_INSULATION',
  ROOF_INSULATION = 'ROOF_INSULATION',
  LOW_TEMPERATURE_BOILER = 'LOW_TEMPERATURE_BOILER',
  SOLAR_THERMAL_WATER_HEATING = 'SOLAR_THERMAL_WATER_HEATING',
  SOLAR_THERMAL_HEATING = 'SOLAR_THERMAL_HEATING',
  TOP_FLOOR_CEILING_INSULATION = 'TOP_FLOOR_CEILING_INSULATION',
  WINDOW_REPLACEMENT_25_YEARS = 'WINDOW_REPLACEMENT_25_YEARS',
}

/** FuelType enum */
export enum FuelType {
  BIOGAS = 'BIOGAS',
  BIO_OIL = 'BIO_OIL',
  BROWN_COAL = 'BROWN_COAL',
  DISTRICT_HEATING = 'DISTRICT_HEATING',
  ELECTRICITY = 'ELECTRICITY',
  FIREWOOD = 'FIREWOOD',
  HARD_COAL = 'HARD_COAL',
  HEATING_OIL = 'HEATING_OIL',
  LIQUEFIED_GAS = 'LIQUEFIED_GAS',
  NATURAL_GAS = 'NATURAL_GAS',
  WOOD_CHIPS = 'WOOD_CHIPS',
  WOOD_PELLETS = 'WOOD_PELLETS',
}

/** Matching heater consumption units with fuel type */
export const heaterConsumptionUnitList: Record<FuelType, HeaterConsumptionUnit[]> = {
  [FuelType.HEATING_OIL]: [
    HeaterConsumptionUnit.LITER,
    HeaterConsumptionUnit.KWH_CALORIFIC_VALUE,
    HeaterConsumptionUnit.KWH_COMBUSTION_VALUE
  ],
  [FuelType.BIO_OIL]: [
    HeaterConsumptionUnit.LITER,
    HeaterConsumptionUnit.KWH_CALORIFIC_VALUE,
    HeaterConsumptionUnit.KWH_COMBUSTION_VALUE
  ],
  [FuelType.NATURAL_GAS]: [
    HeaterConsumptionUnit.CUBIC_METER,
    HeaterConsumptionUnit.KWH_CALORIFIC_VALUE,
    HeaterConsumptionUnit.KWH_COMBUSTION_VALUE
  ],
  [FuelType.BIOGAS]: [
    HeaterConsumptionUnit.CUBIC_METER,
    HeaterConsumptionUnit.KWH_CALORIFIC_VALUE,
    HeaterConsumptionUnit.KWH_COMBUSTION_VALUE
  ],
  [FuelType.LIQUEFIED_GAS]: [
    HeaterConsumptionUnit.CUBIC_METER,
    HeaterConsumptionUnit.KWH_CALORIFIC_VALUE,
    HeaterConsumptionUnit.KWH_COMBUSTION_VALUE,
    HeaterConsumptionUnit.LITER,
    HeaterConsumptionUnit.KILOGRAM
  ],
  [FuelType.HARD_COAL]: [
    HeaterConsumptionUnit.KILOGRAM,
    HeaterConsumptionUnit.KWH_CALORIFIC_VALUE
  ],
  [FuelType.BROWN_COAL]: [
    HeaterConsumptionUnit.KILOGRAM,
    HeaterConsumptionUnit.KWH_CALORIFIC_VALUE
  ],
  [FuelType.FIREWOOD]: [
    HeaterConsumptionUnit.KILOGRAM,
    HeaterConsumptionUnit.KWH_CALORIFIC_VALUE,
    HeaterConsumptionUnit.KWH_COMBUSTION_VALUE,
    HeaterConsumptionUnit.CUBIC_METER_ROOM
  ],
  [FuelType.WOOD_CHIPS]: [
    HeaterConsumptionUnit.KILOGRAM,
    HeaterConsumptionUnit.KWH_CALORIFIC_VALUE,
    HeaterConsumptionUnit.KWH_COMBUSTION_VALUE,
    HeaterConsumptionUnit.CUBIC_METER
  ],
  [FuelType.WOOD_PELLETS]: [
    HeaterConsumptionUnit.KILOGRAM,
    HeaterConsumptionUnit.KWH_CALORIFIC_VALUE,
    HeaterConsumptionUnit.KWH_COMBUSTION_VALUE,
    HeaterConsumptionUnit.CUBIC_METER
  ],
  [FuelType.DISTRICT_HEATING]: [
    HeaterConsumptionUnit.KWH,
  ],
  [FuelType.ELECTRICITY]: [
    HeaterConsumptionUnit.KWH,
  ],
}
