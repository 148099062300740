import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { Endpoint } from 'constants/API'
import { useAuth0 } from 'utils/auth'
import API from './API'

const _getUrl = <EndpointType extends string>(url: EndpointType, replacements: Record<string, string>) => {
  let finalUrl: string = url

  for (let [key, value] of Object.entries(replacements)) {
    finalUrl = finalUrl.replace(`{${key}}`, encodeURI(value))
  }

  return finalUrl
}

/**
 * Provides wrapped API methods with automatically resolved mimetypes and easy param replacement with automatic URI encoding
 */
export const useAPI = <EndpointType extends string = Endpoint>() => {
  const { roles } = useAuth0()

  function get<ReturnType>(endpoint: EndpointType, replacements: Record<string, string>, keepResponse: true, options?: AxiosRequestConfig): Promise<AxiosResponse<ReturnType>>
  function get<ReturnType>(endpoint: EndpointType, replacements: Record<string, string>, keepResponse: false, options?: AxiosRequestConfig): Promise<ReturnType>
  function get<ReturnType>(endpoint: EndpointType, replacements: Record<string, string>, keepResponse = false, options?: AxiosRequestConfig): Promise<ReturnType | AxiosResponse<ReturnType>> {
    return API.get<ReturnType>(
      _getUrl<EndpointType>(endpoint, replacements),
      {
        headers: { Accept: API.decideRoleMimeType(roles) },
        ...options,
      },
      {
        endpoint
      }
    ).then(resp => keepResponse ? resp : resp.data)
  }

  function del<ReturnType>(endpoint: EndpointType, replacements: Record<string, string>, keepResponse: true, options?: Omit<AxiosRequestConfig, 'headers'>): Promise<AxiosResponse<ReturnType>>
  function del<ReturnType>(endpoint: EndpointType, replacements: Record<string, string>, keepResponse: false, options?: Omit<AxiosRequestConfig, 'headers'>): Promise<ReturnType>
  function del<ReturnType>(endpoint: EndpointType, replacements: Record<string, string>, keepResponse = false, options?: Omit<AxiosRequestConfig, 'headers'>): Promise<ReturnType | AxiosResponse<ReturnType>> {
    return API.delete<ReturnType>(
      _getUrl<EndpointType>(endpoint, replacements),
      {
        headers: { Accept: API.decideRoleMimeType(roles) },
        ...options,
      },
      {
        endpoint
      }
    ).then(resp => keepResponse ? resp : resp.data)
  }

  function put<ReturnType>(endpoint: EndpointType, replacements: Record<string, string>, body: object | undefined, keepResponse: true, options?: AxiosRequestConfig): Promise<AxiosResponse<ReturnType>>
  function put<ReturnType>(endpoint: EndpointType, replacements: Record<string, string>, body: object | undefined, keepResponse: false, options?: AxiosRequestConfig): Promise<ReturnType>
  function put<ReturnType>(endpoint: EndpointType, replacements: Record<string, string>, body: object | undefined, keepResponse = false, options?: AxiosRequestConfig): Promise<ReturnType | AxiosResponse<ReturnType>> {
    return API.put<ReturnType>(
      _getUrl<EndpointType>(endpoint, replacements),
      body,
      {
        headers: { Accept: API.decideRoleMimeType(roles) },
        ...options,
      },
      {
        endpoint,
      }
    ).then(resp => keepResponse ? resp : resp.data)
  }

  function post<ReturnType>(endpoint: EndpointType, replacements: Record<string, string>, body: object, keepResponse: true, options?: AxiosRequestConfig): Promise<AxiosResponse<ReturnType>>
  function post<ReturnType>(endpoint: EndpointType, replacements: Record<string, string>, body: object, keepResponse: false, options?: AxiosRequestConfig): Promise<ReturnType>
  function post<ReturnType>(endpoint: EndpointType, replacements: Record<string, string>, body: object, keepResponse = false, options?: AxiosRequestConfig): Promise<ReturnType | AxiosResponse<ReturnType>> {
    return API.post<ReturnType>(
      _getUrl<EndpointType>(endpoint, replacements),
      body,
      {
        headers: { Accept: API.decideRoleMimeType(roles) },
        ...options,
      },
      {
        endpoint
      }
    ).then(resp => keepResponse ? resp : resp.data)
  }

  return {
    get,
    put,
    post,
    delete: del,
  }
}

export type UseAPIReturn<T extends string> = ReturnType<typeof useAPI<T>>
