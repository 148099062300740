import { AvailablePaymentMethodsContextProvider } from './contexts'
import { FC } from 'react'
import { PageTransition } from 'utils/animations'
import { ProfileController } from './Profile.controller'

export const Profile: FC = () => {
  return (
    <PageTransition>
      <AvailablePaymentMethodsContextProvider>
        <ProfileController />
      </AvailablePaymentMethodsContextProvider>
    </PageTransition>
  )
}
