import { Badge, CreativeStatusBadge } from '../Badges'
import React, { Fragment, useMemo } from 'react'
import { bigFromFee, formatPrice } from 'utils/price'

import BlockInfo from '../BlockInfo/BlockInfo'
import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import { IconType } from 'constants/assets'
import { SuggestedCreativeDTO } from 'models/creative'
import gridStyles from '../../styles/grid.module.sass'
import { multilineText } from 'utils/formatting'
import styles from '../AssignCreative/AssignCreative.module.sass'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** Column number in grid used for styling purposes */
  columnNumber?: number
  /** Data of the suggested creative */
  creativeData: SuggestedCreativeDTO
  /** Data of the chosen creative */
  chosenCreative?: SuggestedCreativeDTO | null
  /** Whether this CT is already assigned */
  isAlreadyAssigned?: boolean
  /** On button "Choose" click action to be called */
  onClickChoose?: (e: React.MouseEvent) => void
}

/**
 * @component Non-editable assign creative detail for admin
 * @example
 * <AssignCreativeDetail columnNumber={1} creativeData={SuggestedCreative} />
 */
const AssignCreativeDetail: React.FC<Props> = ({
  columnNumber,
  creativeData,
  chosenCreative,
  isAlreadyAssigned = false,
  onClickChoose,
}) => {
  const { t } = useTranslation(['assign_creative'])

  const remuneration = useMemo(() => bigFromFee(creativeData.remuneration || 0), [creativeData])
  const transportCost = useMemo(() => bigFromFee(creativeData.transportRemuneration || 0), [creativeData])
  const bonus = useMemo(() => bigFromFee(creativeData.bonus || 0), [creativeData])
  const totalRemuneration = useMemo(() => bigFromFee(remuneration.plus(transportCost).plus(bonus)), [remuneration, transportCost, bonus])
  const margin = useMemo(() => bigFromFee(creativeData.margin || 0), [creativeData])
  const currency = useMemo(() => creativeData.remuneration.currency, [creativeData])
  const availabilityStatus = useMemo(() => creativeData.availabilityStatus, [creativeData])
  const columnWithNumber = useMemo(() => `column${columnNumber}`, [columnNumber])

  return (
    <Fragment>
      <div className={`${styles.line} ${styles.badges} ${gridStyles.row1} ${gridStyles[columnWithNumber]}`}>
        {!isAlreadyAssigned
          ?
          <CreativeStatusBadge
            className={`${styles.creativeBadge} ${styles.marginRight}`}
            creativeStatus={availabilityStatus}
          />
          :
          <Badge color="orange" className={styles.marginRight}>
            {t('currently_assigned')}
          </Badge>
        }
        <Badge color={margin.gte(0) ? 'gray' : 'red'}>
          <strong className={`${styles.total} ${styles.marginRight}`}>{t('margin')}</strong>
          <strong className={margin.gte(0) ? `${styles.black}` : ''}>{formatPrice(margin, currency)}</strong>
        </Badge>
      </div>
      <div className={`${styles.line} ${gridStyles.row2} ${gridStyles[columnWithNumber]}`}>
        <div className={styles.nameWrapper}>
          <span className={`${styles.name} ${styles.marginRight}`}>{t('name')}</span>
          <strong className={`${styles.title} ${styles.black}`}>{creativeData.name}</strong>
        </div>
      </div>
      <div className={`${styles.line} ${styles.detail} ${styles.marginBottom} ${gridStyles.row3} ${gridStyles[columnWithNumber]}`}>
        <div className={styles.contactWrapper}>
          {!!creativeData.phone &&
            <span className={styles.group}>
              <a href={`tel:${creativeData.phone}`}>
                <Icon className={styles.smallIcon} icon={IconType.PHONE} />
                <span>{creativeData.phone}</span>
              </a>
            </span>
          }
          <span className={styles.group}>
            <span className={styles.separateLeft}>
              <a href={`mailto:${creativeData.email}`}>
                <Icon className={styles.smallIcon} icon={IconType.ENVELOPE} />
                <span>{creativeData.email}</span>
              </a>
            </span>
          </span>
        </div>
      </div>
      <div className={`${styles.line} ${gridStyles.row4} ${gridStyles[columnWithNumber]}`}>
        <strong className={`${styles.total} ${styles.marginRight}`}>{t('total_remuneration')}</strong>
        <strong className={styles.black}>{formatPrice(totalRemuneration, currency)}</strong>
      </div>
      <div className={`${styles.line} ${styles.detail} ${gridStyles.row5} ${gridStyles[columnWithNumber]}`}>
        <span className={styles.marginRight}>{t('remuneration')}</span>
        <strong>{formatPrice(remuneration, currency)}</strong>
      </div>
      <div className={`${styles.line} ${styles.detail} ${gridStyles.row6} ${gridStyles[columnWithNumber]}`}>
        <span className={styles.marginRight}>{t('transport_cost')}</span>
        <strong>{formatPrice(transportCost, currency)}</strong>
      </div>
      <div className={`${styles.line} ${styles.detail} ${styles.marginBottom} ${gridStyles.row7} ${gridStyles[columnWithNumber]}`}>
        <span className={styles.marginRight}>{t('bonus')}</span>
        <strong>{formatPrice(bonus, currency)}</strong>
      </div>
      <div className={`${styles.line} ${gridStyles.row8} ${gridStyles[columnWithNumber]}`}>
        <strong>{t('admin_comments_about_creative')}</strong>
      </div>
      <BlockInfo className={`${styles.blockinfo} ${gridStyles.row9} ${gridStyles[columnWithNumber]}`} color="blue">
        {multilineText(creativeData.adminComments || '')}
      </BlockInfo>
      {!isAlreadyAssigned && <div className={`${styles.line} ${styles.marginBottomLarge} ${gridStyles.row10} ${gridStyles[columnWithNumber]}`}>
        <Button
          type="secondary"
          onClick={onClickChoose}
          disabled={!!chosenCreative}
        >
          {t('choose')}
        </Button>
      </div>
      }
    </Fragment>
  )
}

export default AssignCreativeDetail
