import { Color, IconType } from 'constants/assets'
import { FC, useMemo } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import Button from 'components/common/Button/Button'
import { ChargebeeHostedPageStatus } from 'constants/chargebee'
import Icon from 'components/common/Icon/Icon'
import { Path } from 'constants/router'
import styles from './SubscriptionStatus.module.sass'

enum Statuses {
  SUCCESS = 'SUCCESS',
  PROCESSING = 'PROCESSING',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
}

/**
 * Page to be redirected to by chargebee after subscribing.
 * Shows status indication based on "state" url param sent by chargebee in redirect url.
 * 
 * @example <SubscriptionStatusPage />
*/
export const SubscriptionStatusPage: FC = () => {

  const statusColorMap = new Map<Statuses, Color>([
    [Statuses.SUCCESS, Color.SECONDARY_GREEN_DARK],
    [Statuses.PROCESSING, Color.SECONDARY_BLUE_DARK],
    [Statuses.CANCELLED, Color.SECONDARY_ORANGE_DARK],
    [Statuses.ERROR, Color.SECONDARY_RED_DARK],
  ])

  const statusIconMap = new Map<Statuses, IconType>([
    [Statuses.SUCCESS, IconType.CHECK],
    [Statuses.PROCESSING, IconType.LOADING],
    [Statuses.CANCELLED, IconType.DANGER],
    [Statuses.ERROR, IconType.DANGER],
  ])

  const { t } = useTranslation(['subscription_status_page'])
  const [params] = useSearchParams()

  const subscriptionStatus = useMemo(() => {
    const rawStatus = params.get('state')
    if (!rawStatus) return Statuses.ERROR

    const status = rawStatus.toUpperCase()
    if (!(status in ChargebeeHostedPageStatus)) return Statuses.ERROR

    switch (status as ChargebeeHostedPageStatus) {
      case ChargebeeHostedPageStatus.SUCCEEDED:
        return Statuses.SUCCESS

      case ChargebeeHostedPageStatus.CANCELLED:
        return Statuses.CANCELLED

      default:
        return Statuses.PROCESSING
    }
  }, [params])

  return (
    <div className={`page ${styles.paymentStatus} ${!!subscriptionStatus ? styles[subscriptionStatus] : ''}`}>
      <div className="page-content">
        {!!subscriptionStatus &&
          <div className={styles.statusBox}>

            <Icon
              className={`${styles.statusIcon} ${styles[subscriptionStatus]}`}
              color={statusColorMap.get(subscriptionStatus) || Color.SECONDARY_RED_DARK}
              icon={statusIconMap.get(subscriptionStatus) || IconType.CROSS}
            />

            <h2 className={styles.title}>
              {t(`subscription_status_page:${subscriptionStatus}:title`)}
            </h2>

            <p className={styles.text}>
              <Trans
                t={t}
                i18nKey={`subscription_status_page:${subscriptionStatus}:text`}
              >
                <span></span>
              </Trans>
            </p>

            <Link to={Path.INDEX}>
              <Button className={styles.cta}>
                {t('go_to_dashboard')}
              </Button>
            </Link>

          </div>
        }
      </div>
    </div>
  )
}
