import { BLUE_600, GRAY_600, MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { FC, ReactNode, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Box } from '@mui/material'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import EditIcon from '@mui/icons-material/Edit'
import { MUIBadge } from 'components/common/MUIBadge'
import { Nullable } from 'models/helpers'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
import { VisualClientDTO } from 'models/visuals'
import classNames from 'classnames'
import styles from './GalleryImage.module.sass'
import useMediaQuery from '@mui/material/useMediaQuery'

/**
 * @interface Props
 */
interface Props {
  /** Label of the image badge */
  label?: string
  /** The image to display */
  imageUrl?: string
  /** Error message of the image */
  errorMessage?: string
  /** Whether the image has error or not */
  isError?: boolean
  /** Whether the gallery image is selected */
  isSelected?: boolean
  /** Whether the gallery image is selectable or not */
  isSelectable?: boolean
  /** Whether the gallery image show select button when hover on image */
  isHoverSelectable?: boolean
  /** Whether the gallery image can be marked as favorite or not */
  isFavoriteSelectable?: boolean
  /** Whether the gallery image is marked as favorite or not */
  isFavorite?: boolean
  /** Whether the favorite button has effect when hover on it */
  isFavoriteHoverActive?: boolean
  /** Whether the favorite button has disabled clicking */
  isFavoriteDisabled?: boolean
  /** OnSelect action to be called */
  onSelect?: (e: React.MouseEvent) => void
  /** OnFavSelect action to be called */
  onFavSelect?: (e: React.MouseEvent) => void
  /** OnClick action to be called */
  onClick?: (e: React.MouseEvent) => void
  /** Custom component to display in the image box */
  children?: ReactNode
  /** Metadata of a visual */
  visualMetadata?: Nullable<VisualClientDTO>
  /** Callback for clicking on the floor planner link */
  onFloorPlannerLinkClick?: (e: React.MouseEvent) => void
}

/**
 * Gallery image component
 * 
 * @example <GalleryImage />
 */
export const GalleryImage: FC<Props> = ({
  label,
  imageUrl,
  errorMessage,
  isError,
  isSelected,
  isSelectable = false,
  isHoverSelectable = false,
  isFavoriteSelectable = false,
  isFavorite = false,
  isFavoriteHoverActive,
  isFavoriteDisabled = false,
  children,
  visualMetadata,
  onSelect,
  onFavSelect,
  onClick,
  onFloorPlannerLinkClick,
}) => {
  const { t } = useTranslation(['gallery'])

  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)

  let style: React.CSSProperties = {}

  if (imageUrl) {
    style.backgroundImage = `url('${imageUrl}')`
  }

  const onFavoriteClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation()

    if (!isFavoriteDisabled) {
      onFavSelect?.(e)
    }
  }

  const floorPlannerLink = useMemo(() => {
    return visualMetadata?.floorPlanSelfEditUrl
  }, [visualMetadata])

  return (
    <div
      className={classNames(styles.galleryImage,
        {
          [styles.selected]: isSelected,
          [styles.hoverSelectButton]: isHoverSelectable,
          [styles.hoverFavoriteButton]: isFavoriteHoverActive,
          [styles.hoverEditButton]: !!floorPlannerLink,
          [styles.clickable]: !!onClick,
          [styles.error]: isError,
          [styles.mobile]: isMobileView
        })
      }
      style={style}
      onClick={(e) => onClick?.(e)}
    >

      {isSelectable &&
        <span
          className={classNames(styles.iconButton, styles.selectButton, { [styles.selected]: isSelected })}
          onClick={e => {
            e.stopPropagation()
            onSelect?.(e)
          }}>
          <CheckRoundedIcon className={styles.checkIcon} sx={{ color: BLUE_600 }} />
        </span>
      }

      {isFavoriteSelectable &&
        <span
          className={classNames(styles.iconButton, styles.favButton, { [styles.favSelected]: isFavorite, [styles.favDisabled]: isFavoriteDisabled })}
          onClick={e => onFavoriteClick(e)}
        >
          {isFavorite ?
            <StarIcon className={styles.favIcon} sx={{ color: BLUE_600 }} />
            :
            <StarBorderIcon className={styles.favIcon} sx={{ color: isFavoriteDisabled ? GRAY_600 : BLUE_600 }} />
          }
        </span>
      }

      {!!floorPlannerLink &&
        <a
          className={classNames(styles.iconButton, styles.editButton)}
          onClick={e => onFloorPlannerLinkClick?.(e)}
          href={floorPlannerLink}
          target="_blank" rel="noreferrer"
          title={t('edit_floor_plan')}
        >
          <EditIcon className={styles.editIcon} sx={{ color: BLUE_600 }} />
        </a>
      }

      {!!label &&
        <Box position="absolute" left=".8rem" bottom=".8rem">
          <MUIBadge label={label} size="sm" color="gray" />
        </Box>
      }

      {/* TODO: New error message for load/upload/download image will be in another task, can handle it like <ErrorMessage /> component. */}
      {isError && !!errorMessage && <Trans className={styles.errorMsg} parent="p">{errorMessage}</Trans>}

      {children}

    </div>
  )
}
