import { FC, useMemo } from 'react'
import { isEditingCategory, isShootingCategory } from 'utils/validators/categoryUtils'

import { CORAL_600 } from 'constants/styling/theme'
import { ReactComponent as ClickCursor } from 'assets/img/icons/cursor-click.svg'
import Collapse from '@mui/material/Collapse'
import { DocumentCategory } from 'constants/product'
import { EmptyContent } from 'components/common/EmptyContent'
import { usePurchaseFlowConfig } from 'components/pages/PurchaseFlow/_main/contexts'
import { usePurchaseFlowProducts } from 'components/pages/PurchaseFlow/_main/contexts/PurchaseFlowProducts.context'
import { useTranslation } from 'react-i18next'

type EmptyProductContent = {
  /** The title of the empty card. */
  title: string
  /** The description of the empty card. */
  description?: string
  /** Whether the animation can start. */
  animationIn: boolean
}

/**
 * @component
 * EmptyProductList represents the UI when there are no products to display.
 *
 * @example
 * <EmptyProductList />
 */
export const EmptyProductList: FC = (() => {
  const { t } = useTranslation(['order'])
  const { hasSegments, selectedCategory } = usePurchaseFlowConfig()
  const { selectedSegment, selectedFilterTypes, selectedFilterKinds } = usePurchaseFlowProducts()

  const data: EmptyProductContent = useMemo(() => {
    // No products to display for the current country
    if (!hasSegments) {
      return {
        title: t('step_product.none_for_country'),
        description: undefined,
        animationIn: true
      }
    }
    // The user hasn't select any filter needed for the selected category
    if (selectedSegment === null && hasSegments) {
      return {
        title: t(`step_product.segment_untouched_${selectedCategory}`),
        description: undefined,
        animationIn: true
      }
    }
    // The user hasn't select any product
    if (!!selectedCategory && hasSegments && selectedSegment !== null) {
      if (
        ((isShootingCategory(selectedCategory) || selectedCategory === DocumentCategory.AUTHORITIES_DOCUMENTS) && !selectedFilterTypes.size) ||
        (isEditingCategory(selectedCategory) && !selectedFilterKinds.size)
      ) {
        return {
          title: t('step_product.select_product'),
          description: t('step_product.not_selected'),
          animationIn: true
        }
      }
    }

    return {
      title: t('step_product.select_product'),
      description: undefined,
      animationIn: false
    }
  }, [hasSegments, selectedCategory, selectedFilterKinds.size, selectedFilterTypes.size, selectedSegment, t])

  return (
    <Collapse in={data.animationIn}>
      <EmptyContent
        title={data.title}
        description={data.description}
        icon={
          <ClickCursor width={24} height={24} color={CORAL_600} />
        }
      />
    </Collapse>
  )
})
