import { APIRequestErrorType, APIRequestState, Endpoint } from 'constants/API'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { EventChannel, eventChannel } from 'redux-saga'
import { all, put, takeEvery } from 'redux-saga/effects'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { fork, take } from 'typed-redux-saga'

import axios from 'axios'
import { APIRequest } from 'models/API'
import { ActionRequest } from 'models/redux'
import { SignedURLDTO } from 'models/visuals'
import API from 'utils/API/API'
import { ActionUploadWatermarkLogo } from './uploadWatermarkLogo.actions'

const removeFileExtensionRegex = /\..+/

/** Saga that listens to all actions emitted from upload channel and passes them to redux */
function* uploadProgressListenerSaga(channel: EventChannel<ActionUploadWatermarkLogo>) {
  while (true) {
    const action = yield* take(channel)
    yield put(action)
  }
}

/** A method which generates eventChannel that emits information about upload progress and finished upload response */
function createUploadChannel(receivedAction: ActionUploadWatermarkLogo, SignedURLDTO: SignedURLDTO) {
  return eventChannel<ActionUploadWatermarkLogo>(emit => {
    (async () => {
      const { file } = receivedAction.payload
      const split = SignedURLDTO.signedURL.split('?')[0].split('/')
      const filename = split[split.length - 1].replace(removeFileExtensionRegex, '')
      const source = axios.CancelToken.source()
      const actionContainingResponseFromStorage: ActionRequest = await generalFetch(ActionTypeAPIData.UPLOAD_WATERMARK_LOGO, () => axios.put(SignedURLDTO.signedURL, file, {
        cancelToken: source.token,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1))
          const progressAction: ActionUploadWatermarkLogo = {
            type: [ActionTypeAPIEvent.PROGRESS, ActionTypeAPIData.UPLOAD_WATERMARK_LOGO],
            payload: {
              ...receivedAction.payload,
              progress: percentCompleted,
              file: new File([], filename),
              signedUrl: SignedURLDTO.signedURL,
              request: new APIRequest(APIRequestState.RUNNING),
            }
          }

          emit(progressAction)
        },
        headers: {
          'Content-type': file.type,
          ...SignedURLDTO.headers,
        },
      }))

      const uploadedAction: ActionUploadWatermarkLogo = {
        ...actionContainingResponseFromStorage,
        payload: {
          ...receivedAction.payload,
          ...actionContainingResponseFromStorage.payload,
          file: new File([], filename),
          signedUrl: SignedURLDTO.signedURL,
        }
      }

      emit(uploadedAction)
    })()

    return () => { }
  })
}

/** Saga which handles uploading visual */
export function* uploadWatermarkLogoSaga(receivedAction: ActionUploadWatermarkLogo) {
  const { file, workspaceId } = receivedAction.payload

  const url = Endpoint.WATERMARK_CRUD
    .replace('{workspaceId}', encodeURI(workspaceId))

  const actionFetch: ActionRequest = yield generalFetch(ActionTypeAPIData.UPLOAD_WATERMARK_LOGO, () => API.post<SignedURLDTO>(url, {
    filename: file.name,
    contentType: file.type,
  }, undefined, {
    endpoint: Endpoint.WATERMARK_CRUD,
  }))
  const SignedURLDTO: SignedURLDTO = actionFetch.payload.request.data
  const uploadAction: ActionUploadWatermarkLogo = {
    ...actionFetch,
    payload: {
      ...receivedAction.payload,
    }
  }

  if (!SignedURLDTO?.signedURL) {
    const error_message = 'signedUrl is undefined, null or empty'
    console.error(error_message)
    uploadAction.payload.request.error_type = APIRequestErrorType.UNKNOWN_ERROR
    uploadAction.payload.request.error = error_message
    uploadAction.type = [ActionTypeAPIEvent.RECEIVED, ActionTypeAPIData.UPLOAD_WATERMARK_LOGO]
    yield put(uploadAction)
    return
  }

  const channel = createUploadChannel(uploadAction, SignedURLDTO)

  yield fork(uploadProgressListenerSaga, channel)
}

/** Watcher of Watermark logo upload dispatching actions */
export function* uploadWatermarkLogoWatcher() {
  yield all([
    takeEvery((action: ActionUploadWatermarkLogo) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.UPLOAD_WATERMARK_LOGO]), uploadWatermarkLogoSaga),
  ])
}
