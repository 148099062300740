import { CardMedia, Grid, Stack, useMediaQuery } from '@mui/material'
import { useGalleryAssignment, useGalleryVirtualVisit } from '../../contexts'

import { ClientGalleryVirtualVisitInfoCard } from '../ClientGalleryVirtualVisitInfoCard'
import { ClientGalleryVirtualVisitRating } from '../ClientGalleryVirtualVisitRating'
import { ClientGalleryVirtualVisitShareCard } from '../ClientGalleryVirtualVisitShareCard'
import { FC } from 'react'
import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import ratingStyles from '../ClientGalleryVirtualVisitRating/ClientGalleryVirtualVisitRating.module.sass'

/**
 * Client gallery virtual visit component
 * 
 * @example
 * <ClientGalleryVirtualVisit />
 */

export const ClientGalleryVirtualVisit: FC = () => {
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)

  const {
    virtualVisitID,
    virtualVisitExpired,
  } = useGalleryVirtualVisit()

  const { isMinimalRatingsFinished } = useGalleryAssignment()

  const replayAnimations = () => {
    const element = document.getElementById('csat-animation')
    if (element) {
      element.classList.remove(ratingStyles.boxShadowPulse)
      // Force to restart CSAT banner animation for 2 seconds
      void element.offsetWidth
      element.classList.add(ratingStyles.boxShadowPulse)
    }
  }

  const showRatingBanner = !isMinimalRatingsFinished

  return (
    <Stack width="100%" marginTop="3rem">
      <Grid container spacing={2}>

        <Grid item xs={isMobileView ? 12 : 8}>
          <CardMedia
            component="iframe"
            src={`https://my.matterport.com/show/?m=${virtualVisitID}`}
            allowFullScreen
            allow="xr-spatial-tracking"
            width="100%"
            height="100%"
            sx={{
              border: 'unset',
              borderRadius: '.8rem',
              maxHeight: '42rem',
              aspectRatio: '3/2'
            }}
          />
        </Grid>

        <Grid item xs={isMobileView ? 12 : 4} paddingRight={1.6}>
          <Stack gap="1.6rem">
            <ClientGalleryVirtualVisitShareCard replayAnimations={showRatingBanner ? replayAnimations : undefined} />

            {showRatingBanner && !virtualVisitExpired && <ClientGalleryVirtualVisitRating />}

            <ClientGalleryVirtualVisitInfoCard />

            {/* Render Virtual Visit Rating under Expiration banner, only when Virtual Visit is Expired*/}
            {showRatingBanner && virtualVisitExpired && <ClientGalleryVirtualVisitRating />}
          </Stack>
        </Grid>

      </Grid>
    </Stack>
  )
}
