import { useAdminGetFloorPlanTemplateDetails, useAdminGetFloorPlanTemplates, useGetFloorPlanTemplateDetails, useGetFloorPlanTemplateLogo, useGetFloorPlanTemplates } from 'dataQueries'
import { useEffect, useMemo, useState } from 'react'
import { createFileFromBlob, downloadLogo } from 'utils/helpers'

import basic_2D from 'assets/img/floorplan/2D/2D_commercial_bw.webp'
import basic_3D from 'assets/img/floorplan/3D/3D_basic_variant1.webp'
import default_template_2D_neutral from 'assets/img/floorplan/default_template_2D_neutral.webp'
import { useUserData } from 'components/contexts/UserDataContext'
import { useTargetOrderUser } from 'components/pages/PurchaseFlow/_main/contexts'
import constate from 'constate'
import { TFunction } from 'i18next'
import { FloorPlanTemplateDetailsDTO } from 'models/purchaseFlow'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from 'utils/auth'
import { FloorPlanFormat } from '../FloorPlanLayout'
import { FloorPlanColors } from '../FloorPlanPreview/basicFloorPlanConstants'
import { FloorPlanConfigOptions } from '../FloorPlanPreview/floorPlanOptions'
import { FloorPlanRenderType } from './FloorPlanConfigStep.context'

export type CompositeTemplate = {
  id: string
  name: string
  renderType: FloorPlanRenderType
  imagePlaceholder: string
  config: FloorPlanConfigOptions
}

export const DEFAULT_TEMPLATE_2D_ID = 'default-template-2d'

const basicFloorPlanSimpleConfig: FloorPlanConfigOptions = {
  style: FloorPlanColors.NEUTRAL,
  showFixtures: true,
  showFurniture: true,
  showRoomNames: true,
  showRoomArea: true,
}

export const getDefault2DPlanTemplate = (t: TFunction) => ({
  id: DEFAULT_TEMPLATE_2D_ID,
  config: basicFloorPlanSimpleConfig,
  name: t('floor_plan_step.templates.default_template_name_2d'),
  imagePlaceholder: default_template_2D_neutral,
  renderType: FloorPlanRenderType.RENDER_2D
})

export const [FloorPlanTemplatesProvider, useFloorPlanTemplates] = constate(() => {
  const { t } = useTranslation(['purchase_flow'])
  const { currentUserWorkspace } = useUserData()
  const { roles } = useAuth0()
  const { targetUser } = useTargetOrderUser()
  const targetUserId = useMemo(() => targetUser?.id, [targetUser])

  const floorPlanTemplates = roles.isClient ? useGetFloorPlanTemplates() : (targetUserId ? useAdminGetFloorPlanTemplates(targetUserId) : undefined)
  const floorPlanTemplateDetail = useGetFloorPlanTemplateDetails()
  const floorPlanAdminTemplateDetail = useAdminGetFloorPlanTemplateDetails()
  const floorPlanTemplateLogo = useGetFloorPlanTemplateLogo()

  const [templateDetails, setTemplateDetails] = useState<FloorPlanTemplateDetailsDTO[]>([])

  const organizationTemplates: Record<string, CompositeTemplate> = useMemo(() => {
    const templates: Record<string, CompositeTemplate> = {}

    for (const template of templateDetails) {

      const floorPlanFormat = template.outputFileFormat.reduce((obj: Record<string, FloorPlanFormat>, format) => {
        if (FloorPlanFormat[format]) {
          obj[FloorPlanFormat[format]] = FloorPlanFormat[format]
        }
        return obj
      }, {})

      const orientation = template.renderType === FloorPlanRenderType.RENDER_3D ? template.orientation3d : undefined
      const defaultPlaceholder = template.renderType === FloorPlanRenderType.RENDER_2D ? basic_2D : basic_3D

      templates[template.id] = {
        id: template.id,
        name: template.templateName,
        renderType: template.renderType,
        imagePlaceholder: template.previewImageUrl ?? defaultPlaceholder,
        config: {
          style: template.style,
          orientation,
          showFixtures: template.fixtures,
          showFurniture: template.furniture,
          showRoomNames: template.roomNames,
          showScalebar: template.scaleBar,
          showCompass: template.compass,
          companyLogo: template.companyLogoId,
          outputLanguage: template.outputFileLanguage,
          showEachRoomDimensions: template.showEachRoomDimensions,
          showDimensionChain: template.showDimensionChain,
          showRoomArea: template.showEachRoomArea,
          floorPlanFormat,
          templateLogoFile: template.templateLogoFile
        }
      }
    }

    return templates

  }, [templateDetails])

  const availableTemplates = useMemo(() => {
    const templates = organizationTemplates

    templates[DEFAULT_TEMPLATE_2D_ID] = getDefault2DPlanTemplate(t)

    return templates
  }, [t, organizationTemplates])

  useEffect(() => {
    if (!floorPlanTemplates) return

    floorPlanTemplates.data?.forEach(async template => {
      const data = roles.isClient
        ? await floorPlanTemplateDetail.mutateAsync({ templateId: template.templateId })
        : (targetUserId && await floorPlanAdminTemplateDetail.mutateAsync({ templateId: template.templateId, userId: targetUserId }))

      if (!data) return
      const templateDetails = data.data

      let logoFile: File | undefined = undefined

      try {
        if (templateDetails.companyLogoId) {
          const logoData = await floorPlanTemplateLogo.mutateAsync({ logoId: templateDetails.companyLogoId, workspaceId: currentUserWorkspace?.id ?? '' })
          if (logoData.data) {
            const getLogo = await downloadLogo(logoData.data.signedURL, logoData.data.headers)
            logoFile = createFileFromBlob(getLogo, logoData.data.filename)
          }
        }
      } catch (error) {
        console.error('Failed to fetch logo:', error)
      }
      templateDetails && setTemplateDetails(prev => [...prev, { ...templateDetails, templateLogoFile: logoFile }])
    })

    // Omit floorPlanTemplateDetail and floorPlanTemplateLogo dependency to prevent loop 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floorPlanTemplates?.data])

  return {
    availableTemplates,
    floorPlanTemplates,
  }
})
