import { ActionRequest, ActionString } from 'models/redux'
import { ActionStringType, ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { createAPIAction, createStringAction } from 'redux/Helpers'

import { Language } from 'translations/Language'
import { UserChangeLocaleStore } from '.'

/** Change locale */
export interface ActionChangeLocale extends ActionRequest {
  payload: {
    language?: Language
    timezone?: string
    request: UserChangeLocaleStore
  },
}

/** Create an action for change locale */
export function changeLocale(language?: Language, timezone?: string): ActionChangeLocale {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.USER_CHANGE_LOCALE], {
    language,
    timezone,
  })
}

/** Create an action for purging change locale */
export function purgeChangeLocale(): ActionChangeLocale {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.USER_CHANGE_LOCALE], {})
}

/** Change language */
export interface ActionChangeLanguage extends ActionString {
  payload: {
    language: Language
  },
}

/** Create an action for changing language */
export function changeLanguage(language: Language): ActionChangeLanguage {
  return createStringAction(ActionStringType.CHANGE_LANGUAGE, {
    language,
  })
}