import { DecentralizedElectricHeatingType, DecentralizedGasHeatingType, DecentralizedHeatPumpHeatingType, GeneratedByType } from 'constants/purchaseFlow'
import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'
import React, { useState } from 'react'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { Box } from '@mui/material'
import { GRAY_900 } from 'constants/styling/theme'
import { Label } from 'components/common/Label'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUINumberField } from 'components/common/MUINumberField'
import { MUIRadio } from 'components/common/MUIRadio'
import { PRIMARY_FIELD_WIDTH } from './_main/HeatingSystem.component'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { VentilationHeatingProps } from '../_main/VentilationAndHeating.component'
import { useTranslation } from 'react-i18next'
import { useVentilationAndHeating } from '../_main/VentilationAndHeating.context'

/**
 * @component DecentralizedHotWater renders options for the user to provide information about the decentralized hot water production. 
 *
 * @example
 *   <DecentralizedHotWater />
 */
export const DecentralizedHotWater: React.FC<VentilationHeatingProps> = ({ id }) => {

  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.energy_heating_page.heating_system' })

  const {
    currentHeatingSystem,
    handleUpdateHeatingSystemItem,
  } = useVentilationAndHeating()

  const {
    generatedBy,
    yearOfConstruction,
    decentralizedHeatingType,
  } = currentHeatingSystem(id) || {}

  const [showError, setShowError] = useState<boolean>(false)

  // If any of the required fields are empty, show error for all of them.
  const handleOnInputBlur = () => {
    if (!showError) setShowError(true)
  }

  const getDecentralizedHeatingTypeKey = () => {
    switch (generatedBy) {
      case GeneratedByType.ELECTRIC:
        return 'decentralized_electric_heating_type'
      case GeneratedByType.GAS:
        return 'decentralized_gas_heating_type'
      case GeneratedByType.HEAT_PUMP:
        return 'decentralized_heat_pump_heating_type'

      default:
        return ''
    }
  }

  const getDecentralizedHeatingTypeEnum = () => {
    switch (generatedBy) {
      case GeneratedByType.GAS:
        return DecentralizedGasHeatingType
      case GeneratedByType.HEAT_PUMP:
        return DecentralizedHeatPumpHeatingType
      case GeneratedByType.ELECTRIC:
        return DecentralizedElectricHeatingType

      default:
        return null
    }
  }

  return (
    <BorderBoxWrapper padding={2} width='100%'>
      <Stack gap=".8rem">

        {/* DECENTRALIZED - GENERATED BY */}
        <Label text={t('generated_by')} />

        {Object.values(GeneratedByType).map(type => {
          return (
            <Stack key={type} gap=".8rem" flexDirection="row">
              <MUIRadio
                checked={generatedBy === type}
                onChange={() => handleUpdateHeatingSystemItem(id, { generatedBy: type })}
              />

              <Label color={GRAY_900} text={t(`generated_by_type.${type}`)} />
            </Stack>
          )
        })}

      </Stack>

      {/* DECENTRALIZED - YEAR OF CONSTRUCTION */}
      <Box width={{ xs: '80%', lg: PRIMARY_FIELD_WIDTH }} paddingTop={1.6}>
        <MUINumberField
          min={0}
          required
          inputBoxWidth='100%'
          label={t('year_of_construction')}
          value={yearOfConstruction}
          isError={showError && !yearOfConstruction}
          onBlur={handleOnInputBlur}
          onChange={(value) => handleUpdateHeatingSystemItem(id, { yearOfConstruction: value })}
        />
      </Box>

      {/* DECENTRALIZED - PRODUCTION TYPE */}
      <Stack gap="1.8rem" paddingTop={1.6} width={{ xs: '100%', lg: PRIMARY_FIELD_WIDTH }} position="relative">
        <MUIDropdown
          sx={{ width: '100%' }}
          button={(isOpen, action) => (
            <MUIDropdownInput
              required
              readOnly
              label={t('decentralized_production')}
              type={DropdownInputTypes.SELECTOR}
              value={t(!!decentralizedHeatingType
                ? `decentralized_production_type.${getDecentralizedHeatingTypeKey()}.${decentralizedHeatingType}`
                : 'select_decentralized_production_option')}
              onClick={action}
            />
          )}
        >
          {Object.values(getDecentralizedHeatingTypeEnum() || {}).map(type => (
            <MUIDropdownItem key={type} onClick={() => handleUpdateHeatingSystemItem(id, { decentralizedHeatingType: type })}>
              <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                {t(`decentralized_production_type.${getDecentralizedHeatingTypeKey()}.${type}`)}
              </Typography>
            </MUIDropdownItem>
          ))}
        </MUIDropdown>
      </Stack>

    </BorderBoxWrapper>
  )
}
