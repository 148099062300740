import { Box, Divider, Stack } from '@mui/material'
import { FC, useEffect, useRef } from 'react'

import Button from 'components/common/Button/Button'
import { Nullable } from 'models/helpers'
import { QueryStatus } from 'components/common/QueryStatus'
import { ResponsiveDrawer } from 'components/common/ResponsiveDrawer'
import { formatPrice } from 'utils/price'
import styles from './ChangeRemunerationDrawer.module.sass'
import { useChangeRemunerationDrawer } from './ChangeRemunerationDrawer.context'
import { useTranslation } from 'react-i18next'

/**
 * Uses Responsive drawer to render slide-out form for editing assigned CT remuneration
 * 
 * DEPENDENCIES:
 * - ChangeRemunerationDrawerContext
 * 
 * @example <ChangeRemunerationDrawer />
 */
export const ChangeRemunerationDrawer: FC = () => {

  const { t } = useTranslation(['assign_creative', 'actions', 'common'])

  const {
    isDrawerOpen,
    baseRemuneration,
    bonus,
    transportCost,
    displayTotal,
    currency,
    updateAction,
    updateRemuneration,
    setTransportCost,
    setBonus,
    setBaseRemuneration,
    closeChangeRemunerationDrawer,
  } = useChangeRemunerationDrawer()

  // Automatically close the drawer after action success
  const closeTimeoutRef = useRef<Nullable<number>>(null)
  useEffect(() => {
    if (!updateAction.isSuccess) return
    if (!!closeTimeoutRef.current) window.clearTimeout(closeTimeoutRef.current)
    closeTimeoutRef.current = window.setTimeout(() => {
      closeChangeRemunerationDrawer()
      closeTimeoutRef.current = null
    }, 1000)
  })

  return (
    <ResponsiveDrawer
      isOpen={isDrawerOpen}
      onClose={closeChangeRemunerationDrawer}
      title={t('change_remuneration')}
      actionsSlot={
        <>

          {/* request or query status */}
          <Box flex="0 0 100%">
            <QueryStatus query={updateAction} spaceBottomRem={2} />
          </Box>

          <Button
            type="secondary nobackground noborder"
            onClick={closeChangeRemunerationDrawer}
          >
            {t(updateAction.isSuccess ? 'actions:Close' : 'actions:Cancel')}
          </Button>

          {!updateAction.isSuccess &&
            <Button
              disabled={updateAction.isPending}
              onClick={() => updateRemuneration()}
            >
              {t('change_remuneration')}
            </Button>
          }

        </>
      }
    >
      <Stack direction="column" gap={2} className={styles.changeRemuneration}>

        {/* BASE REMUNERATION */}
        <Stack
          className={styles.inputWrap}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >

          <label>
            {t('remuneration')}
          </label>

          <Stack direction="row" gap={1} alignItems="center">
            <input
              type="number"
              value={formatPrice(baseRemuneration)}
              onChange={(e) => setBaseRemuneration(formatPrice(e.target.value || ''))}
              onWheel={(e) => e.currentTarget.blur()}
              onBlur={(e) => (!e.target.value || Number(e.target.value) < 0) && setBaseRemuneration(formatPrice(0))}
              min="0"
              step="0.01"
            />
            <strong>{currency}</strong>
          </Stack>

        </Stack>

        {/* TRANSPORT COST */}
        <Stack
          className={styles.inputWrap}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >

          <label>
            {t('transport_cost')}
          </label>

          <Stack direction="row" gap={1} alignItems="center">
            <input
              type="number"
              value={formatPrice(transportCost)}
              onChange={(e) => setTransportCost(formatPrice(e.target.value || ''))}
              onWheel={(e) => e.currentTarget.blur()}
              onBlur={(e) => (!e.target.value || Number(e.target.value) < 0) && setTransportCost(formatPrice(0))}
              min="0"
              step="0.01"
            />
            <strong>{currency}</strong>
          </Stack>

        </Stack>

        {/* BONUS */}
        <Stack
          className={styles.inputWrap}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >

          <label>
            {t('bonus')}
          </label>

          <Stack direction="row" gap={1} alignItems="center">
            <input
              type="number"
              value={bonus}
              onChange={(e) => setBonus(formatPrice(e.target.value || ''))}
              onWheel={(e) => e.currentTarget.blur()}
              onBlur={(e) => (!e.target.value || Number(e.target.value) < 0) && setBonus(formatPrice(0))}
              min="0"
              step="0.01"
            />
            <strong>{currency}</strong>
          </Stack>

        </Stack>

        <Divider />

        {/* TOTAL */}
        <Stack
          direction="row"
          justifyContent="space-between"
          gap={2}
        >
          <h3>{t('total_remuneration')}</h3>
          <h3>{formatPrice(displayTotal)} {currency}</h3>
        </Stack>

      </Stack>
    </ResponsiveDrawer>
  )
}
