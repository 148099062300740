import moment from 'moment'

export const CARD_EXPIRATION_REGEX = /^((0[1-9])|(1[0-2]))\/([0-9]){4}$/

/**
 * Transforms expiration month and year parameters to string in format of MM/YYYY
 * @param expirationMonth - month of expiration
 * @param expirationYear - year of expiration
 * @returns Date string in format MM/YYYY
 */
export function getCardExpirationString(expirationMonth: number, expirationYear: number) {
  const date = moment.utc(`${expirationYear}-${expirationMonth < 10 ? '0' : ''}${expirationMonth}-01T23:59:59.000Z`)
  const dateString = date.format('MM/YYYY')

  return dateString
}

/**
 * Transforms string in format MM/YYYY into numeric values for month and year
 * @param expirationString
 * @returns "{ expirationMonth: number, expirationYear: number }"
 */
export function getCardExpirationFromString(expirationString: string) {
  if (!CARD_EXPIRATION_REGEX.test(expirationString)) return undefined

  const [month, year] = expirationString.split('/')

  return {
    month: parseInt(month),
    year: parseInt(year),
  }
}
