import Analytics, { AnalyticsInstance } from 'analytics'

import { Auth0User } from 'models/auth'
// @ts-ignore
import googleAnalytics from '@analytics/google-analytics-v3'

export const GA_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
export const app_name = `backbone-platform-${process.env.NODE_ENV}`

export const googleAnalyticsController: {
  instance: AnalyticsInstance | undefined
  app_name: string
} = {
  instance: undefined,
  app_name,
}

export const trackGoogleAnalyticsUser = (user: Auth0User) => {
  if (user?.sub) googleAnalyticsController.instance?.identify(user.sub)
}

export const initGoogleAnalytics = () => {
  if (GA_ID) {
    googleAnalyticsController.instance = Analytics({
      app: app_name,
      plugins: [
        googleAnalytics({
          trackingId: GA_ID,
          anonymizeIp: true,
        })
      ]
    })
    googleAnalyticsController.instance.ready(() => window.ga && window.ga('require', 'ec'))
    return true
  }
  return false
}
