import { AssignmentEventIsAutomationFail, AssignmentEventIsOutreach, AssignmentEventIsOutreachResult } from 'utils/typeguards/assignmentEventsTypeguards'
import { Trans, useTranslation } from 'react-i18next'
import { getDateString, getTimeString } from 'utils/time'
import { useEffect, useMemo } from 'react'

import { DeclineAssignmentReason } from 'constants/assignment'
import DynamicQueryContent from 'components/common/DynamicQueryContent/DynamicQueryContent'
import Modal from 'components/common/Modals/Modal/Modal'
import { OutreachResultStatus } from 'constants/assignment/assignmentChangelog'
import { Timeline } from 'components/common/Timeline/Timeline.component'
import { TimelineEvent } from 'components/common/Timeline/TimelineEvent/TimelineEvent.component'
import popupStyles from 'components/styles/popup.module.sass'
import styles from './AssignmentChangelogPopup.module.sass'
import { useGetAssignmentChangelog } from 'dataQueries'
import { useTimezone } from 'components/contexts/timezone.context'

/**
 * @interface Props Input properties
 */
interface Props {
  /** The additional classes to append */
  className?: string
  /** The assignment ID */
  assignmentId: string
  /** Decides when is modal open */
  isOpen: boolean
  /** Onclick action triggered when user clicks outside the .modal-content element */
  onClickOutside?: (e: React.MouseEvent) => unknown
  /** onKeyDown action triggered when user presses any key on the keyboard */
  onKeyDown?: (e: React.KeyboardEvent) => unknown
  /** Onclick action triggered when user clicks the close button */
  onClickClose?: (e: React.MouseEvent) => unknown
}

/**
 * @component Assignment changelog displayed in a popup for admin
 * @example
 * <AssignmentChangelogPopup isOpen={true} />
 */
export const AssignmentChangelogPopup: React.FC<Props> = ({
  className = '',
  assignmentId,
  isOpen,
  onClickOutside,
  onKeyDown,
  onClickClose,
}) => {
  const { t, i18n } = useTranslation(['assignment_changelog'])
  const { userTimezone } = useTimezone()

  const assignmentChangelog = useGetAssignmentChangelog(assignmentId)
  const assignmentChangelogEvents = useMemo(() => assignmentChangelog?.data?.data.events, [assignmentChangelog])

  // Only refetch assignmentChangelog on popup open
  useEffect(() => {
    if (isOpen) assignmentChangelog.refetch()
    // Omit assignmentChangelog dependency to prevent loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <Modal
      className={`assignment-changelog-popup ${className}`.trim()}
      modalContentClassName={`${popupStyles.modalContentSmall} ${popupStyles.changelogContent}`}
      isOpen={isOpen}
      onClickOutside={onClickOutside}
      onKeyDown={onKeyDown}
      onClose={onClickClose}
    >
      <div className={`${popupStyles.inside} ${styles.changelogWrapper}`}>

        <div className={styles.line}>
          <h3 className={styles.heading}>{t('title')}</h3>
        </div>

        <DynamicQueryContent query={assignmentChangelog}>
          {!!assignmentChangelogEvents &&
            <Timeline>
              {assignmentChangelogEvents.map(event => {
                const date = getDateString(event.timestamp, { timezone: userTimezone })
                const time = getTimeString(event.timestamp, { timezone: userTimezone })
                const creative = (AssignmentEventIsOutreach(event) || AssignmentEventIsAutomationFail(event) && (event.creative)) ? event.creative : undefined
                const outreachStatus = AssignmentEventIsOutreachResult(event) ? event.result : undefined
                const failureReason = AssignmentEventIsAutomationFail(event) ? event.reason : undefined
                const declinedReason = AssignmentEventIsOutreachResult(event) && outreachStatus === OutreachResultStatus.DECLINED ? event.rejectionReason : undefined
                const otherDeclineReason = AssignmentEventIsOutreachResult(event) && outreachStatus === OutreachResultStatus.DECLINED ? event.rejectionReasonFreetext : ''

                return (
                  <TimelineEvent
                    key={event.id}
                    dateString={date}
                    timeString={time}
                  >
                    {t(`assignment_changelog:event_type:${event.type}`)}
                    {!!creative &&
                      <strong>{creative}</strong>
                    }
                    {!!outreachStatus &&
                      <strong>{t(`assignment_changelog:outreach_result_status:${outreachStatus}`)}</strong>
                    }
                    {!!declinedReason &&
                      <div>
                        <span>{t('assignment_changelog:declined_reason_title')}</span>
                        <strong>
                          {i18n.exists(`assignment_changelog:declined_reason:${declinedReason}`)
                            ? <Trans t={t} i18nKey={`assignment_changelog:declined_reason:${declinedReason}`} />
                            : declinedReason
                          }
                        </strong>
                        {declinedReason === DeclineAssignmentReason.OTHER &&
                          <strong>{`${otherDeclineReason}`}</strong>
                        }
                      </div>
                    }
                    {!!failureReason &&
                      <strong>{t(`assignment_changelog:automation_failed_reason:${failureReason}`)}</strong>
                    }
                  </TimelineEvent>
                )
              })}
            </Timeline>
          }
        </DynamicQueryContent>
      </div>

    </Modal>
  )
}
