import { Alert, AlertColor, AlertProps, AlertTitle, Collapse, IconButton, Typography } from '@mui/material'
import { BLUE_600, CORAL_700, GREEN_600, ORANGE_600 } from 'constants/styling/theme'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Close } from '@mui/icons-material'
import { Trans } from 'react-i18next'

const ANIMATION_TIMEOUT = 300

const DarkColorVariantMap: Record<AlertColor, string> = {
  error: CORAL_700,
  success: GREEN_600,
  warning: ORANGE_600,
  info: BLUE_600,
}

interface Props {
  /** Text content of the toast */
  message: string
  /**
   * Number of ms until the snack automatically hides itself.   
   * If set to 0, snack remains visible until manually closed or until it overflows max queue size
  */
  autoHideTimeout: number
  /** Title of the toast message */
  title?: string
  /** Style variant of the Snackbar. */
  variant?: AlertProps['severity'],
  /** Optional text displayed in small font under the main message */
  subtext?: string
  /** Close event called after snackbar is visually hidden */
  onClose: () => void,
}

/**
 * Toast message component for displaying notifications in snackbar
 * 
 * @example
 * <SnackbarToast message="I love snacks!" variant="info"/> 
 */
export const SnackbarToast: React.FC<Props> = ({
  message,
  title,
  autoHideTimeout,
  variant = 'info',
  subtext,
  onClose,
}) => {

  const [open, setOpen] = useState(true)

  const close = useCallback(() => {
    setOpen(false)

    window.setTimeout(() => {
      onClose()
    }, ANIMATION_TIMEOUT)

  }, [onClose])

  const timeoutRef = useRef<number | undefined>(undefined)
  useEffect(() => {
    if (!timeoutRef.current && autoHideTimeout !== 0) {
      timeoutRef.current = window.setTimeout(() => {
        close()
      }, autoHideTimeout)
    }
  }, [autoHideTimeout, close])

  return (
    <Collapse
      in={open}
      timeout={ANIMATION_TIMEOUT}
      appear
    >
      <Alert
        severity={variant}
        sx={{
          width: { xs: 'calc(100vw - 7rem)', sm: 350 },
          fontSize: '1.4rem',
          border: '1px solid transparent',
          borderColor: DarkColorVariantMap[variant],
        }}
        action={
          <IconButton onClick={close} size="medium">
            <Close color={variant} />
          </IconButton>
        }
      >

        {title &&
          <AlertTitle
            sx={{
              fontWeight: '600',
              fontSize: '1.45rem',
            }}
          >
            {title}
          </AlertTitle>
        }

        <Trans parent={undefined}>
          {message}
        </Trans>

        {subtext &&
          <Typography
            variant="text-xs"
            fontSize="1.2rem"
            display="block"
            mt={1}
            sx={{
              opacity: 0.7
            }}
          >
            <Trans parent={undefined}>
              {subtext}
            </Trans>
          </Typography>
        }

      </Alert>
    </Collapse>
  )
}
