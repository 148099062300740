import { ENVIRONMENT, EnvironmentValue } from 'constants/application'
import { FC, ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { RemoteConfig, Value, fetchAndActivate, getAll, getValue } from 'firebase/remote-config'

import { useFirebase } from './FirebaseContext'

/**
 * Minimum interval between possible firebase remote config fetches
 * 1m for production
 * 30s for development
 * 10s for localhost
*/
const minimumFetchIntervalMillis = (() => {
  switch (ENVIRONMENT) {
    case EnvironmentValue.LOCALHOST:
      return 1000 * 10
    case EnvironmentValue.DEVELOPMENT:
      return 1000 * 30
    case EnvironmentValue.PRODUCTION:
    default:
      return 1000 * 60
  }
})()

/**
 * Interval between fetch attempts
 * 10m for production
 * 5m for development
 * 1m for localhost
*/
const fetchIntervalMillis = (() => {
  switch (ENVIRONMENT) {
    case EnvironmentValue.LOCALHOST:
      return 1000 * 60 * 1
    case EnvironmentValue.DEVELOPMENT:
      return 1000 * 60 * 5
    case EnvironmentValue.PRODUCTION:
    default:
      return 1000 * 60 * 10
  }
})()

/** Type of remote config object */
export type RemoteConfigType = {
  remoteConfigIsWorking: boolean
  promotionalPricingOriginalPricesMap: string
  ignoredOrganizationsForWorkspaceName: string
  // MARK - STEP 1 - Insert parameter name and type here
}

/** Default remote config object */
export const defaultRemoteConfig: RemoteConfigType = {
  remoteConfigIsWorking: false,
  promotionalPricingOriginalPricesMap: '',
  ignoredOrganizationsForWorkspaceName: '',
}
// Everything should be alowed for tests to have access to all features, unless it prevents using the site
/** Default remote config object for test runners */
export const defaultTestingRemoteConfig: RemoteConfigType = {
  remoteConfigIsWorking: true,
  promotionalPricingOriginalPricesMap: '',
  ignoredOrganizationsForWorkspaceName: '',
  // MARK - STEP 3 - Insert default value for test runners
}

/** A context describing values computed from the remote config */
interface RemoteConfigComputedValuesInterface {
  promotionalPricingOriginalPricesMapAsObject: Record<string, string>
  ignoredOrganizationsForWorkspaceNameAsSet: Set<string>
}

/** Default values for the computed values */
const defaultRemoteConfigComputedValues: RemoteConfigComputedValuesInterface = {
  promotionalPricingOriginalPricesMapAsObject: {},
  ignoredOrganizationsForWorkspaceNameAsSet: new Set([]),
}

interface RemoteConfigContextInterface extends RemoteConfigType, RemoteConfigComputedValuesInterface {
  remoteConfigController?: RemoteConfig
  remoteConfigIsLoaded: boolean
  remoteConfigFailedToLoad: boolean
  allParameters?: Record<string, Value>
}

/** Decide the default remote config object based on the NODE_ENV value */
const defaultRemoteConfigObject = process.env.NODE_ENV === 'test' ? defaultTestingRemoteConfig : defaultRemoteConfig

const defaultRemoteConfigContext: RemoteConfigContextInterface = {
  ...{
    remoteConfigIsLoaded: false,
    remoteConfigFailedToLoad: false,
  },
  ...defaultRemoteConfigComputedValues,
  ...defaultRemoteConfigObject,
}

/** Remote config context */
export const RemoteConfigContext = createContext<RemoteConfigContextInterface>(defaultRemoteConfigContext)
/** Remote config context hook */
export const useRemoteConfig = (): RemoteConfigContextInterface => useContext(RemoteConfigContext)

/** Context provider for firebase remote config */
export const RemoteConfigContextProvider: FC<{
  children?: ReactNode
}> = ({
  children,
}) => {
    const { firebaseInitialized, firebaseController } = useFirebase()
    const remoteConfigController = useMemo(() => firebaseInitialized ? firebaseController?.remoteConfig : undefined, [firebaseInitialized, firebaseController])
    const checkInterval = useRef<number | null>(null)
    const [remoteConfigIsLoaded, setRemoteConfigIsLoaded] = useState(false)
    const [remoteConfigFailedToLoad, setRemoteConfigFailedToLoad] = useState(false)

    const [allParameters, setAllParameters] = useState<RemoteConfigContextInterface['allParameters']>()
    const [remoteConfigIsWorking, setRemoteConfigIsWorking] = useState<RemoteConfigType['remoteConfigIsWorking']>(defaultRemoteConfig.remoteConfigIsWorking)
    const [promotionalPricingOriginalPricesMap, setPromotionalPricingOriginalPricesMap] = useState<RemoteConfigType['promotionalPricingOriginalPricesMap']>(defaultRemoteConfig.promotionalPricingOriginalPricesMap)
    const [ignoredOrganizationsForWorkspaceName, setIgnoredOrganizationsForWorkspaceName] = useState<RemoteConfigType['ignoredOrganizationsForWorkspaceName']>(defaultRemoteConfig.ignoredOrganizationsForWorkspaceName)
    // MARK - STEP 4 - Implement state for the parameter in a format of: const [parameterName, setParameterName] = useState<RemoteConfigType['parameterName']>(defaultRemoteConfig.parameterName)

    useEffect(() => {
      if (!remoteConfigController) return
      remoteConfigController.settings.minimumFetchIntervalMillis = minimumFetchIntervalMillis
      remoteConfigController.defaultConfig = defaultRemoteConfig

      const fetchConfig = async () => {
        const onSuccess = () => {
          setRemoteConfigIsLoaded(true)
          setRemoteConfigFailedToLoad(false)
        }

        const onError = () => {
          setRemoteConfigFailedToLoad(true)
        }

        if (process.env.NODE_ENV === 'test') return onSuccess()

        try {
          await fetchAndActivate(remoteConfigController)
          onSuccess()
        } catch (err) {
          console.log(err)
          onError()
        }
      }

      fetchConfig()
      checkInterval.current = window.setInterval(fetchConfig, fetchIntervalMillis)

      // Clear the interval
      return () => {
        if (typeof checkInterval.current === 'number') {
          window.clearInterval(checkInterval.current)
          checkInterval.current = null
        }
      }
    }, [remoteConfigController])

    useEffect(() => {
      if (!remoteConfigController) return
      if (!remoteConfigIsLoaded) return

      setAllParameters(getAll(remoteConfigController))

      setRemoteConfigIsWorking(getValue(remoteConfigController, 'remoteConfigIsWorking').asBoolean())
      setPromotionalPricingOriginalPricesMap(getValue(remoteConfigController, 'promotionalPricingOriginalPricesMap').asString())
      setIgnoredOrganizationsForWorkspaceName(getValue(remoteConfigController, 'ignoredOrganizationsForWorkspaceName').asString())
      // MARK - STEP 5 - Implement getter for remote parameter in a format: setParameterName(getValue(remoteConfigController, 'parameterName').asBoolean/asString/asNumber())
    }, [remoteConfigController, remoteConfigIsLoaded])

    const promotionalPricingOriginalPricesMapAsObject: Record<string, string> = useMemo(() => JSON.parse(promotionalPricingOriginalPricesMap || '{}'), [promotionalPricingOriginalPricesMap])
    const ignoredOrganizationsForWorkspaceNameAsSet: Set<string> = useMemo(() => new Set(JSON.parse(ignoredOrganizationsForWorkspaceName || '[]')), [ignoredOrganizationsForWorkspaceName])

    return (
      <RemoteConfigContext.Provider
        value={{
          ...{
            remoteConfigController,
            remoteConfigIsLoaded,
            remoteConfigFailedToLoad,
            allParameters,
          },
          ...{
            remoteConfigIsWorking,
            promotionalPricingOriginalPricesMap,
            promotionalPricingOriginalPricesMapAsObject,
            ignoredOrganizationsForWorkspaceName,
            ignoredOrganizationsForWorkspaceNameAsSet,
            // MARK - STEP 6 - Pass down the value of the parameter
          },
        }}
      >
        {children}
      </RemoteConfigContext.Provider>
    )
  }
