import { ProductKind, ProductSegment, ProductType, ShootingCategory } from 'constants/product'
import React, { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ProductCategory } from 'models/product'
import i18n from 'translations/i18n'

/** Options required for creativeProductInstruction */
export interface CreativeProductInstructionOptions {
  attributeX: number
  attributeY: number,
  attributeXMin: number,
  attributeXMax: number,
  segment: string,
}

/** Return creative product instruction translation options */
export const creativeProductInstructionOptions = function (category: ProductCategory, type: ProductType, kind: ProductKind, attributeX: number, attributeY: number, count: number = 1, segment?: ProductSegment): CreativeProductInstructionOptions | null {
  const segmentText = segment ? i18n.t(`segment:${segment}`).replace(/\W\w<(\w+)>.*<\/\w+>/gim, '') || '' : ''
  const options = {
    attributeX: attributeX * count,
    attributeY,
    attributeXMin: attributeX * count,
    attributeXMax: attributeX * count,
    segment: segmentText,
  }

  if (kind === ProductKind.OTHER) return options

  switch (category) {
    case ShootingCategory.REAL_ESTATE:
      switch (type) {
        case ProductType.PHOTO:
          switch (kind) {
            case ProductKind.GROUND_PHOTO:
              return options
            case ProductKind.DRONE_PHOTO:
              return options
            case ProductKind.GROUND_PHOTO_PRESTIGE:
              return options
            case ProductKind.MAST_PHOTO:
              return options
            case ProductKind.SD:
              return options
          }
          break
        case ProductType.VIDEO:
          switch (kind) {
            case ProductKind.STANDARD_GROUND_VIDEO:
              return options
            case ProductKind.STANDARD_DRONE_VIDEO:
              return options
            case ProductKind.STANDARD_GROUND_AND_DRONE_VIDEO:
              return options
            case ProductKind.PRESTIGE_GROUND_AND_DRONE_VIDEO:
              return options
            case ProductKind.REEL_VIDEO:
              return options
            case ProductKind.TEASER_VIDEO:
              return options
          }
          break
        case ProductType.VIRTUAL_VISIT:
          switch (kind) {
            case ProductKind.SD:
              return options
            case ProductKind.HD:
              return options
            case ProductKind.MATTERPORT_FLOOR_PLAN_WITH_VISIT:
              return options
          }
          break
        case ProductType.FLOOR_PLAN:
          switch (kind) {
            case ProductKind.MATTERPORT_FLOOR_PLAN_WITH_VISIT:
              return options
            case ProductKind.MATTERPORT_FLOOR_PLAN_WITHOUT_VISIT:
              return options
            case ProductKind.MOBILE_APPLICATION_FLOOR_PLAN:
              return options
            case ProductKind.MEASUREMENT_ON_SITE:
              return options
          }
          break
      }
      break
    case ShootingCategory.CORPORATE:
      switch (type) {
        case ProductType.PHOTO:
          switch (kind) {
            case ProductKind.PORTRAIT:
              return {
                ...options,
                attributeXMin: 2 * options.attributeXMin,
                attributeXMax: 3 * options.attributeXMax,
              }
            case ProductKind.CORPORATE:
              return {
                ...options,
                attributeXMin: 2 * options.attributeXMin,
                attributeXMax: 3 * options.attributeXMax,
              }
            case ProductKind.EQUIPMENT:
              return options
          }
          break
        case ProductType.VIDEO:
          switch (kind) {
            case ProductKind.STANDARD_GROUND_VIDEO:
              return options
          }
          break
      }
      break
    case ShootingCategory.FOOD:
      switch (type) {
        case ProductType.PHOTO:
          switch (kind) {
            case ProductKind.GROUND_PHOTO:
              return {
                ...options,
                attributeXMin: 2 * options.attributeXMin,
                attributeXMax: 3 * options.attributeXMax,
              }
          }
          break
      }
      break
    case ShootingCategory.PRODUCT:
      switch (type) {
        case ProductType.PHOTO:
          switch (kind) {
            case ProductKind.GROUND_PHOTO:
              return {
                ...options,
                attributeXMin: 2 * options.attributeXMin,
                attributeXMax: 3 * options.attributeXMax,
              }
          }
          break
      }
      break
    case ShootingCategory.EVENT:
      switch (type) {
        case ProductType.PHOTO:
          switch (kind) {
            case ProductKind.GROUND_PHOTO:
              return options
          }
          break
        case ProductType.VIDEO:
          switch (kind) {
            case ProductKind.STANDARD_GROUND_VIDEO:
              return options
          }
          break
        case ProductType.VIRTUAL_VISIT:
          switch (kind) {
            case ProductKind.HD:
              return options
          }
          break
      }
      break
  }

  // console.log({ category }, { type }, { kind }, { count })
  // console.log({ options })
  return null
}

interface Props {
  category: ProductCategory
  type: ProductType
  kind: ProductKind
  attributeX: number
  attributeY: number
  count?: number
  segment?: ProductSegment
}

/** Helper component util which interpolates creative product instruction translation */
export const CreativeProductInstruction: React.FC<Props> = ({
  category,
  type,
  kind,
  attributeX,
  attributeY,
  count = 1,
  segment,
}) => {
  const { t } = useTranslation('creative_instruction')
  const instructionOptions = creativeProductInstructionOptions(category, type, kind, attributeX, attributeY, count, segment)
  if (!instructionOptions) return (<Fragment>{'N/A'}</Fragment>)

  return (
    <Trans
      t={t}
      tOptions={instructionOptions}
      i18nKey={(kind === ProductKind.OTHER) ? `creative_instruction:*:*:${kind}` : `creative_instruction:${category}:${type}:${kind}`}
      defaultValue={'NA'}
    >
      <sup></sup>
    </Trans>
  )
}
