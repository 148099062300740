import './LinkifyWrapper.sass'

import { FC, ReactNode } from 'react'

import Linkify from 'react-linkify'

/** Turn links into clickable for children
 * @example
 *  <LinkifyWrapper>
 *    <div>
 *      Links in this comments/instructions block will be clickable.
 *    </div>
 *  </LinkifyWrapper>
 */
export const LinkifyWrapper: FC<{
  /** Color class of the link hover */
  color?: 'blue' | 'orange' | 'green' | 'red'
  /** Blocks or components to wrap */
  children: ReactNode
}> = ({
  color = 'orange',
  children
}) => (
    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
      <a target="blank" className={`linkify-wrapper ${color}`} href={decoratedHref} key={key} rel="nofollow noopener noreferrer">
        {decoratedText}
      </a>
    )}>
      {children}
    </Linkify>
  )
