import { BEIGE_300, CORAL_500, GRAY_800 } from 'constants/styling/theme'
import { ChangeEvent, FC, useMemo } from 'react'
import { ProductKind, ProductType } from 'constants/product'

import AddRoundedIcon from '@mui/icons-material/AddRounded'
import Checkbox from '@mui/material/Checkbox'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import { FormControlLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface StandardProps {
  selected: boolean
  disabled: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

interface KindProps extends StandardProps {
  productKind: ProductKind
  productType?: never
}

interface TypeProps extends StandardProps {
  productType: ProductType
  productKind?: never
}

/**
 * Displays checkbox with name of productType/Kind with onChange trigger.
 * 
 * Can accept EITHER productType OR productKind
 * 
 * @example
 * <ProductListItem
 *   selected={false}
 *   disabled={false}
 *   productKind={ProductKind.DRONE_PHOTO}
 *   onChange={() => console.log("Winter is coming.")}
 * />
 */
export const ProductListItem: FC<KindProps | TypeProps> = ({
  disabled,
  selected,
  productKind,
  productType,
  onChange = () => { throw new Error('Function onChange for this ProductFilterItem is not defined') }
}) => {

  const { t } = useTranslation(['order'])

  const { labelAfter, name, dataCy } = useMemo(() => {

    if (productKind) return {
      labelAfter: t(`product_kind:${productKind}`),
      name: `product_kind_${productKind}_check`,
      dataCy: `product-type-checkbox-${productKind}`,
    }

    return {
      labelAfter: t(`product_type:${productType}`),
      name: `product_type_${productType}_check`,
      dataCy: `product-type-checkbox-${productType}`,
    }

  }, [productKind, productType, t])

  if (Boolean(productType) === Boolean(productKind)) return null

  return (
    <FormControlLabel
      control={
        <Checkbox
          id={name}
          name={name}
          data-cy={dataCy}
          checked={selected}
          disabled={disabled}
          onChange={(e) => onChange(e)}
          icon={<AddRoundedIcon fontSize='large' sx={{ color: GRAY_800 }} />}
          checkedIcon={
            <DeleteOutlineRoundedIcon fontSize='large' sx={{ color: CORAL_500 }} />
          }
          sx={{ color: `${BEIGE_300} !important`, ':hover': { backgroundColor: BEIGE_300 } }}
        />
      }
      disableTypography
      labelPlacement="start"
      label={labelAfter}
      sx={{
        margin: 0,
        paddingLeft: 1,
        fontSize: '1.4rem',
        userSelect: 'none',
        borderRadius: '4px',
        justifyContent: 'space-between',
        ':hover': {
          backgroundColor: disabled ? 'transparent' : BEIGE_300
        }
      }}
    />
  )
}
