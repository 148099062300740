import { ReactNode, useCallback } from 'react'

import { MUIButton } from '../MUIButton'
import { MUICheckbox } from '../MUICheckBox'
import { MUIRadio } from '../MUIRadio'
import { Nullable } from 'models/helpers'
import classNames from 'classnames'
import styles from './SelectableButtonsGrid.module.sass'

export interface SelectableButtonsGridValue<T> {
  value: T
  displayName?: string
  // Optional param for values that should be displayed but cannot be selected
  disabled?: boolean
}

interface Props<T extends number | string = string> {
  /** The additional classes to append */
  className?: string
  /** The value for grid button to display */
  values: SelectableButtonsGridValue<T>[]
  /** Whether the active button responds to click or not */
  activeButtonClickable?: boolean
  /** The value of selected tab */
  selectedValue: Nullable<T | T[]>
  /** Whether checkbox should be displayed inside of buttons */
  showCheckbox?: boolean
  /** Whether Radio should be displayed inside of buttons */
  showRadio?: boolean
  /** Optional icons children to be displayed on the left of the grid button. */
  icons?: {
    [key in T]?: ReactNode
  }
  /** OnSelect action to be called */
  onSelect: (value: T) => void
  /** OnUnSelect action to be called */
  onUnselect?: (value: T) => void
  /** Prevent unselecting the only selected option */
  preventUnselect?: boolean
}

/**
 * @component SelectableButtonsGrid 
 * Displays grid of buttons for each provided value and highlights the selected ones.
 * This component can be assigned a generic type.
 * Handles both singular or plural selection.
 * @example
 * <SelectableButtonsGrid<ProductKind>
 *  values={mockValues}
 *  isActiveButtonDisabled={false}
 *  selectedValue={selectedValue}
 *  onSelect={value => setSelectedValue(value)}
 * />
 */
export const SelectableButtonsGrid = <T extends number | string = string>({
  className,
  values,
  activeButtonClickable = false,
  selectedValue,
  showCheckbox = false,
  showRadio = false,
  icons,
  onSelect,
  onUnselect,
  preventUnselect = false
}: Props<T>) => {

  /** Check if value is selected */
  const isValueSelected = useCallback((value: T) => {
    if (!selectedValue) return false
    if (selectedValue instanceof Array) return selectedValue.includes(value)

    return selectedValue === value
  }, [selectedValue])

  const handleSelect = useCallback((value: T) => {
    if (!onSelect) return

    const isSelected = isValueSelected(value)

    // Prevent unselecting if it's the only selected option
    if (preventUnselect && isSelected && Array.isArray(selectedValue) && selectedValue.length === 1) return

    if (!isSelected) {
      onSelect(value)
      return
    }

    if (onUnselect) {
      onUnselect(value)
      return
    }

    return
  }, [onSelect, isValueSelected, onUnselect, selectedValue, preventUnselect])

  return (
    <div className={classNames(styles.selectableGrid, className)}>
      {values.map(({ value, displayName, disabled }) => {

        const isSelected = isValueSelected(value)

        return (
          <MUIButton
            className={classNames(
              styles.selectableGridButton,
              {
                [styles.active]: isSelected,
                [styles.lockedActiveButton]: !activeButtonClickable
              }
            )}
            key={value}
            disabled={disabled}
            type="secondaryBorder"
            endIcon={icons && icons[value]}
            onClick={() => handleSelect(value)}
          >
            {showCheckbox && <MUICheckbox checked={isSelected} />}
            {showRadio && <MUIRadio checked={isSelected} />}
            {displayName ?? value}
          </MUIButton>
        )
      })}
    </div>
  )
}
