import './ProgressCard.sass'

import { ColorClass, IconType } from 'constants/assets'
import { FC, ReactNode } from 'react'

import Icon from '../Icon/Icon'
import { MUITooltip } from '../MUITooltip'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** The additional classes to append */
  className?: string
  /** Title */
  title?: string
  /** Under title text */
  underTitleText?: string
  /** Progress max value */
  max?: number
  /** Progress value */
  value?: number
  /** Text displayed on the left */
  leftText?: string
  /** Text displayed on the right with line through, depends on the non-empty rightText to be displayed */
  textWithLineThrough?: string
  /** Text displayed on the right */
  rightText?: string
  /** Green highlighted additional text displayed on the left side under the other left text */
  leftTextAdditional?: string
  /** Whether is the card highlighted */
  highlighted?: boolean
  /** Color for highlight */
  color?: 'orange' | 'green' | 'blue'
  /** react-tooltip property for tooltip assignment for bottom text on the right side */
  'data-for'?: string
  /** Data displayed in the react-tooltip, depends on the non-empty rightText to be displayed */
  tooltipData?: JSX.Element
  children?: ReactNode
}

/**
 * @component ProgressCard displaying a progress in for example gallery photo selection
 * @example
 * <ProgressCard title="Pack 20 Photos" underTitleText="10 visuals purchased" max={20} value={18} text="2 Photos supplémentaires" right="CHF 80" />
 */
const ProgressCard: FC<Props> = ({
  title = '',
  underTitleText = '',
  max = 100,
  value,
  className = '',
  leftText = '',
  textWithLineThrough = '',
  rightText = '',
  leftTextAdditional,
  highlighted = false,
  color = 'orange',
  'data-for': dataFor,
  tooltipData,
  children,
}) => {
  className += ` ${color}`
  if (highlighted) className += ' highlighted'

  return (
    <div
      className={`progress-card ${className}`.replace(/\s+/igm, ' ').trim()}
    >
      {title &&
        <h4 className="title">{title}</h4>
      }
      {!!underTitleText &&
        <div className="above">
          <div className="left margin-bottom">
            <span className="text">{underTitleText}</span>
          </div>
        </div>
      }
      {value !== undefined &&
        <progress max={max} value={value}>{value}</progress>
      }
      <div className="bottom">
        {!!leftText &&
          <div className="left margin-top">
            <span className="text">{leftText}</span>
          </div>
        }
        {!!rightText &&
          <div className="right margin-top">

            {!!textWithLineThrough &&
              <span className="text line-through margin-right">{textWithLineThrough}</span>
            }

            <span className="text bold">{rightText}</span>

            {!!tooltipData &&
              <MUITooltip id={dataFor} content={tooltipData} type="secondary">
                <Icon
                  icon={IconType.QUESTION}
                  colorClass={ColorClass.SECONDARY_ORANGE_DARK}
                  className="tooltip-icon"
                />
              </MUITooltip>
            }

          </div>
        }
      </div>
      {!!leftTextAdditional &&
        <div className="bottom">
          <div className="left green margin-top">
            <span className="text">{leftTextAdditional}</span>
          </div>
        </div>
      }
      {children}
    </div>
  )
}

export default ProgressCard
