import React, { ReactNode } from 'react'

import Button from 'components/common/Button/Button'
import classnames from 'classnames'
import styles from './EditorOptionButton.module.sass'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Whether it is button for action (different design) */
  isActionButton?: boolean
  /** Title of Editor Option button */
  title?: string
  /** Icon of Editor Option button */
  icon?: ReactNode,
  /** Whether the button is active or not */
  isActive?: boolean,
  /** Whether the button is available */
  disabled?: boolean
  /** OnClick action to be called */
  onClick?: (e: React.MouseEvent) => void
  /** The additional classes to append */
  className?: string
}

/**
 * @component EditorOptionButton 
 * @example
 * <EditorOptionButton title="Editing type" />
 */

export const EditorOptionButton: React.FC<Props> = ({
  isActionButton = false,
  title,
  icon,
  isActive = false,
  disabled = false,
  onClick,
  className
}) => (
  <Button
    type="secondary noborder"
    textAndIcon
    className={classnames(styles.editorOptionButton, isActive && styles.active, disabled && styles.disabled, isActionButton && styles.actionButton, className)}
    onClick={onClick}
    disabled={disabled}
  >
    <div className={styles.icon}>{icon}</div>
    {title}
  </Button>
)
