import { ActionSearchOrganizations, SearchOrganizationsStore } from '.'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'
import { cloneDeep } from 'lodash'

/** Redux data reducer for search organizations according to VAT number */
export const reduceSearchOrganizations = (state: SearchOrganizationsStore, action: ActionSearchOrganizations) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.SEARCH_ORGANIZATIONS) return state
  const { page, request } = action.payload
  let newState = cloneDeep(state)

  if (!newState || !Array.isArray(newState)) newState = []

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
      if (page < 0) newState = []
      else {
        const array = [...newState || []]
        array[page] = new APIRequest(APIRequestState.BEFORE_START)
        newState = array
      }
      return newState
    case ActionTypeAPIEvent.FETCH:
      if (page < 0) return newState
      else {
        const array = [...newState || []]
        array[page] = new APIRequest(APIRequestState.RUNNING)
        newState = array
      }
      return newState
    case ActionTypeAPIEvent.RECEIVED:
      if (page < 0) return newState
      else {
        const array = [...newState || []]
        array[page] = request
        newState = array
      }
      return newState
    default:
      return state
  }
}
