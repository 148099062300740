import { InstructionOptionDTO, TravelCostProduct } from 'models/purchaseFlow'
import { PrestigeProductKinds, ProductKind } from 'constants/product'
import { PurchaseFlowProduct, PurchaseFlowProductOption, usePurchaseFlowProducts } from './PurchaseFlowProducts.context'
import { bigFromFee, percentageValue, valueAfterDiscount } from 'utils/price'
import { useBookCreative } from '../../common'

import Big from 'big.js'
import { InstructionType } from 'constants/purchaseFlow'
import constate from 'constate'
import { useMemo } from 'react'
import { usePurchaseFlowConfig } from './PurchaseFlowConfig.context'
import { usePurchaseFlowOrderMeta } from './PurchaseFlowOrderMeta.context'

export interface InstructionCartItem extends InstructionOptionDTO {
  instructionProducts: TravelCostProduct[]
  type?: InstructionType
}

export interface InstructionExtraItem extends TravelCostProduct {
  kind: ProductKind
}

type PurchaseFlowCartProduct = {
  options: PurchaseFlowProductOption[]
} & PurchaseFlowProduct

type CartTotal = {
  /** The total amount after applying Value Added Tax (VAT). */
  totalAfterVat: Big
  /** The total amount before applying any discounts. */
  totalBeforeDiscount: Big
  /** The total amount after applying discounts. */
  totalAfterDiscount: Big
  /** The total amount of Value Added Tax (VAT). */
  totalVat: Big
  /** The total amount of discounts applied. */
  totalDiscount: Big
}

export const [CartContextProvider, useCart] = constate(() => {
  const { selectedCreative } = useBookCreative()
  const { catalogueVat, catalogueDiscount, extraServices, organizationInstructions } = usePurchaseFlowConfig()
  const { selectedProducts, selectedProductOptions } = usePurchaseFlowProducts()
  const { selectedMeetingType, selectedExtraServices, keyPickupTravelCostProduct } = usePurchaseFlowOrderMeta()

  /** Sorted list of products and options marked as selected */
  const cartProductList = useMemo(() => {
    if (Object.values(selectedProducts).length === 0) return []

    const cartProducts: Record<number, PurchaseFlowCartProduct> = {}

    // Merges the product options according ID and sorts by price
    for (const productKey in selectedProducts) {
      if (selectedProductOptions.hasOwnProperty(productKey)) {

        const sortedProductOptions = Object.values(selectedProductOptions[productKey])
          .sort((optionA, optionB) => bigFromFee(optionA.feePrice).minus(bigFromFee(optionB.feePrice)).toNumber())

        cartProducts[productKey] = { ...selectedProducts[productKey], options: [...sortedProductOptions] }

      } else {
        cartProducts[productKey] = { ...selectedProducts[productKey], options: [] }
      }
    }

    return Object.values(cartProducts)
      .sort((prodA, prodB) => bigFromFee(prodA.feePrice).minus(bigFromFee(prodB.feePrice)).toNumber())
  }, [selectedProducts, selectedProductOptions])

  /** Total duration of the order */
  const sumDuration = useMemo(() => {
    if (cartProductList.length === 0) return 0

    const productListDuration = cartProductList.reduce((amount, product) => {
      let optionsDuration = 0

      if (product.options && product.options.length > 0) {
        optionsDuration = product.options
          .reduce((amount, option) => amount + (option.shootingDuration ?? 0), 0)
      }

      return amount + (product.shootingDuration ?? 0) + optionsDuration
    }, 0)

    return productListDuration
  }, [cartProductList])

  /** Whether the order has a prestige product or not */
  const orderHasPrestigeProduct = useMemo(() => !!cartProductList.find((product) => PrestigeProductKinds.has(product.kind)), [cartProductList])

  /** List of selected instructions sent by BE (artificial ignored) */
  const cartInstructionList = useMemo(() => {
    const list: InstructionCartItem[] = []

    // HANDLE THE EXTRA SERVICES
    for (const serviceKind of selectedExtraServices) {
      const serviceInstruction = extraServices[serviceKind]

      if (!serviceInstruction) throw new Error(`Couldn't find an entry for ${serviceKind} in extraServices Record.`)

      const extraProducts: TravelCostProduct[] = []

      if (serviceKind === ProductKind.FAVOURITE_CREATIVE && selectedCreative?.travelCost) {
        extraProducts.push(selectedCreative.travelCost)
      }

      list.push({
        ...serviceInstruction,
        instructionProducts: extraProducts,
      })
    }

    // HANDLE MEETING INSTRUCTIONS
    if (selectedMeetingType) {
      const meetingInstruction = organizationInstructions[selectedMeetingType]
      const meetingProducts: TravelCostProduct[] = []

      if (selectedMeetingType === ProductKind.KEYS_PICKUP && keyPickupTravelCostProduct) {
        meetingProducts.push(keyPickupTravelCostProduct)
      }

      if (meetingInstruction) {
        list.push({
          ...meetingInstruction,
          instructionProducts: meetingProducts
        })
      }
    }

    return list
  }, [selectedMeetingType, selectedExtraServices, extraServices, selectedCreative?.travelCost, organizationInstructions, keyPickupTravelCostProduct])

  /** Total price of all products and sub-products (options) */
  const totalProductsPrice = useMemo(() => {
    if (cartProductList.length === 0) return Big(0)

    return cartProductList.reduce((accumulator, product) => {
      let optionsPrice: Big = new Big(0)

      if (product.options && product.options.length > 0) {
        optionsPrice = product.options.reduce((optionsAccumulator, option) => {
          return optionsAccumulator.plus(bigFromFee(option.feePrice).times(option.quantity ?? 0))
        }, Big(0))
      }

      return accumulator.plus(bigFromFee(product.feePrice).times(product.quantity || 0)).plus(optionsPrice)
    }, Big(0))
  }, [cartProductList])

  /** Total price of all products, options and instructions marked as selected (no discounts applied) */
  const totalBeforeDiscount = useMemo(() => {

    const instructionPrice = cartInstructionList.reduce((accumulator, instructionOption) => {

      const instructionProductsPrice = instructionOption.instructionProducts.reduce((productsAcc, product) => productsAcc.plus(
        bigFromFee(product.productUnitPrice).times(product.productQuantity)
      ), Big(0))

      return accumulator.plus(bigFromFee(instructionOption.feePrice).plus(instructionProductsPrice))
    }, Big(0))

    return totalProductsPrice.plus(instructionPrice)
  }, [cartInstructionList, totalProductsPrice])

  const total: CartTotal = useMemo(() => {
    const totalAfterDiscount = valueAfterDiscount(totalBeforeDiscount, catalogueDiscount)
    const totalVat = percentageValue(totalAfterDiscount, (catalogueVat ?? Big(0)))

    return {
      totalVat,
      totalAfterDiscount,
      totalBeforeDiscount,
      totalAfterVat: totalAfterDiscount.plus(totalVat),
      totalDiscount: totalBeforeDiscount.minus(totalAfterDiscount)
    }
  }, [catalogueDiscount, catalogueVat, totalBeforeDiscount])

  return {
    cartProductList,
    cartInstructionList,
    orderHasPrestigeProduct,
    sumDuration,
    total,
  }
})
