import React, { Fragment, ReactNode } from 'react'

import classnames from 'classnames'
import styles from './Tag.module.sass'

/**
 * @typedef TagColor Available tag colors
 */
export type TagColor = 'blue' | 'orange' | 'red' | 'green' | 'gray'

/**
 * @typedef TagSize Available tag sizes
 */
export type TagSize = 'small' | 'large'

/**
 * @interface Props Input properties
 */
interface Props {
  /** The additional classes to append */
  className?: string
  /** Color of the tag. Default is "gray" */
  color?: TagColor
  /** Size of the tag. Default is "small" */
  size?: TagSize
  /** Material icon displayed on the left side https://mui.com/material-ui/api/icon/ */
  materialIcon?: JSX.Element
  /** Children rendered on the Tag */
  children?: ReactNode
}

/**
 * @component Tag with various available colors, optional icon, children can be any string
 * @example
 * <Tag color="red" size="large" materialIcon={<StopIcon fontSize='large' />}>
 *   Some text
 * </Tag>
 */

export const Tag: React.FC<Props> = ({
  className = '',
  color = 'gray',
  size = 'small',
  materialIcon,
  children,
}) => {

  return (
    <div className={
      classnames(
        className,
        styles.tag,
        styles[color],
        styles[size]
      )
    }>
      {materialIcon ?
        <div className={styles.textAndIconWrap}>
          <div className={styles.icon}>
            {materialIcon}
          </div>

          {children}
        </div>
        :
        <Fragment>
          {children}
        </Fragment>
      }
    </div>
  )
}
