import { ActionPopupOptions, ActionPopupTypes, closeActionPopup, openActionPopup } from '../../redux/Individual/ActionPopup'
import { useDispatch, useSelector } from 'react-redux'

import { ReactNode } from 'react'
import { RootStore } from 'models/redux'

let resolveCallback: (value?: boolean | PromiseLike<boolean>) => void

/**
 * A React hook which provides functions for opening / closing / interacting with action popup and its state
 * 
 * @example
 *
 * import { useActionPopup } from '../../../utils/hooks/useActionPopup'
 * 
 * const { showConfirm } = useActionPopup()
 * 
 * const handleActionNeedingConfirmation = async () => await showConfirm('Are you sure?') && dispatch(actionCreator) 
 */
export function useActionPopup() {
  const dispatch = useDispatch()
  const actionPopupState = useSelector((state: RootStore) => state.actionPopup)

  /** Closes popup and after 300ms resolves Promise with value true */
  const onConfirmAccept = (delay = 300) => {
    resolve(true, delay)
  }

  /** Closes popup and after 300ms resolves Promise with value false */
  const onConfirmDeny = (delay = 300) => {
    resolve(false, delay)
  }

  /** Closes popup and after 300ms resolves Promise with value true */
  const onAlertClose = (delay = 300) => {
    resolve(true, delay)
  }

  /** Dispatches action to open confirm popup with provided configuration and returns Promise to be resolved later*/
  const showConfirm = (body: string | ReactNode, options?: ActionPopupOptions) => {
    dispatch(openActionPopup(body, ActionPopupTypes.CONFIRM, options))
    return new Promise((res, rej) => {
      resolveCallback = res
    })
  }

  /** Dispatches action to open alert popup with provided configuration and returns Promise to be resolved later*/
  const showAlert = (body: string | ReactNode, options?: ActionPopupOptions) => {
    dispatch(openActionPopup(body, ActionPopupTypes.ALERT, options))
    return new Promise((res, rej) => {
      resolveCallback = res
    })
  }

  /** Dispatches action to close action popup */
  const close = () => {
    dispatch(closeActionPopup())
  }

  /** Helper function which calls close() and after provided delay resolves Promise with provided value */
  const resolve = (value: boolean, delay: number) => {
    close()
    setTimeout(() => {
      resolveCallback(value)
    }, delay)
  }

  return { showAlert, showConfirm, onConfirmAccept, onConfirmDeny, onAlertClose, actionPopupState }
}
