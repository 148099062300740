import { getContentLibraryLink, getHelpCenterLink } from 'utils/localization'

import { AnalyticsEvent } from 'utils/analytics'
import Button from 'components/common/Button/Button'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import Dropdown from 'components/common/Dropdown/Dropdown'
import { Fragment } from 'react'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { Link } from 'react-router-dom'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { MUIBadge } from 'components/common/MUIBadge'
import { MUIDivider } from 'components/common/MUIDivider'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import { Path } from 'constants/router'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import i18n from 'translations/i18n'
import { useAppMeta } from 'components/higher-order/App/appMeta.context'
import { useAuth0 } from 'utils/auth'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @interface Props for TopBarDropdown component
 */
export interface Props {
  /** Invitation workspace name property */
  invitationWorkspaceName?: string
  /** Function to set the language open */
  setIsLanguageOpen: () => void
  /** Function to close the menu handler */
  closeMenuHandler: (e: React.MouseEvent<Element, MouseEvent>) => void
  /** Function to handle logout */
  handleLogout: (e: React.MouseEvent<Element, MouseEvent>) => void
  /** Function to handle log analytics event */
  handleLogAnalyticsEvent: (event: AnalyticsEvent, options?: {}) => void
}

/**
 * @component TopBarDropdown displayed TopBar
 * @example <TopBarDropdown />
 */
export const TopBarDropdown: React.FC<Props> = ({
  invitationWorkspaceName,
  setIsLanguageOpen,
  closeMenuHandler,
  handleLogout,
  handleLogAnalyticsEvent
}) => {
  const { t } = useTranslation('topbar')
  const { isUserSet } = useAppMeta()
  const { user, roles } = useAuth0()
  const {
    activeSubscribedUserWorkspaces,
    hasUserActiveSubscription,
    clientData
  } = useUserData()

  return (
    <Dropdown
      innerMenuClassName='menu-wrap'
      attachment="right-attached"
      button={(isOpen, action) => (
        <Button
          type="secondary"
          className='burger-menu-button'
          onClick={(e) => {
            handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU)
            action(e)
          }}
        >
          <img src={user?.picture} alt={t('profile')} />
          {isOpen ? <CloseRoundedIcon /> : <MenuRoundedIcon />}
        </Button>
      )}>
      <div className='main-menu'>

        <div className='section'>

          {isUserSet &&
            <Link
              to={Path.INDEX}
              onClick={() => handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU_DASHBOARD)}
            >
              <HomeOutlinedIcon className='icon' />
              {t('dashboard')}
            </Link>
          }

          <Button
            type="secondary nobackground noborder"
            className="language-settings-button"
            textAndIcon
            onClick={() => {
              handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU_LANGUAGE)
              setIsLanguageOpen()
            }}
          >
            <ChatOutlinedIcon className='icon' />
            {t('language_settings')}
          </Button>

          {roles.isClient && hasUserActiveSubscription && !!activeSubscribedUserWorkspaces &&
            <Link
              to={Path.WORKSPACES_PROFILE.replace(':id', activeSubscribedUserWorkspaces[0].id)}
              onClick={() => handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU_MY_PLAN, { planId: activeSubscribedUserWorkspaces[0].id })}
            >
              <CreditCardOutlinedIcon className='icon' />
              {t('workspace_profile')}
              {!!invitationWorkspaceName && <MUIBadge color="coral" size="sm" label={t('pending')} />}
            </Link>
          }

          {roles.isClient && !hasUserActiveSubscription &&
            <Link
              to={Path.PRICING}
              onClick={() => handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU_LOCKED_PLAN_UPSELL)}
            >
              <LockOpenOutlinedIcon className='icon' />
              {t('unlock_workspaces')}
            </Link>
          }

          {roles.isCreative &&
            <a href={getContentLibraryLink(i18n.language)} rel="nofollow noopener noreferrer" target="_blank">
              <LockOpenOutlinedIcon className='icon' />
              {t('content_library')}
              <OpenInNewOutlinedIcon className='icon external-link' />
            </a>
          }

          {isUserSet &&
            <Link
              to={Path.PROFILE}
              onClick={(e) => {
                handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU_PROFILE)
                closeMenuHandler(e)
              }}
            >
              <PersonOutlineIcon className='icon' />
              {t('profile')}
            </Link>
          }

        </div>

        <MUIDivider margin={12} />

        {roles.isAdmin &&
          <Fragment>

            <div className='section light'>

              <Link to={Path.API}>
                <DataObjectOutlinedIcon className='icon' />
                API
              </Link>

              <Link to={Path.ADMIN_CLIENT_ONBOARDING}>
                <PersonAddAltOutlinedIcon className='icon' />
                Client onboarding
              </Link>

              <Link to={Path.ADMIN_CREATIVE_ONBOARDING}>
                <CameraAltOutlinedIcon className='icon' />
                Creative onboarding
              </Link>

            </div>

            <MUIDivider margin={12} />

            <div className='section light'>
              <a href={getHelpCenterLink(i18n.language)} rel="nofollow noopener noreferrer" target="_blank">
                <HelpOutlineOutlinedIcon className='icon' />
                {t('help_center')}
                <OpenInNewOutlinedIcon className='icon external-link' />
              </a>
            </div>

            <MUIDivider margin={12} />

          </Fragment>
        }

        {!roles.isAdmin &&
          <Fragment>

            <div className='section light'>
              <Link
                to={Path.TERMS_AND_PRIVACY}
                onClick={() => handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU_TERMS_AND_POLICY)}
              >
                <DescriptionOutlinedIcon className='icon' />
                {t('terms_and_privacy')}
              </Link>
            </div>

            <MUIDivider margin={12} />

          </Fragment>
        }

        <div className='section light'>

          <Link
            to={Path.PROFILE}
            onClick={() => handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU_USER_PROFILE)}
          >
            <div className='user'>
              <div className='name'>{clientData?.name ?? user?.name}</div>
              <div className='email'>{user?.email}</div>
            </div>
          </Link>

          <a
            href="#logout"
            onClick={(e) => {
              handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU_LOG_OUT)
              handleLogout(e)
            }}>
            <LogoutOutlinedIcon className='icon' />
            {t('authentication:log_out')}
          </a>

        </div>

      </div>
    </Dropdown>
  )
}
