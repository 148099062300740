// TODO: Revisit everything in this file with the release of the 3D version after closed deal with Esoft

import { FloorPlan3DOptions } from './floorPlanOptions'
import basic_variant1 from 'assets/img/floorplan/3D/3D_basic_variant1.webp'
import basic_variant2 from 'assets/img/floorplan/3D/3D_basic_variant2.webp'
import basic_variant3 from 'assets/img/floorplan/3D/3D_basic_variant3.webp'
import compass from 'assets/img/floorplan/general/compass.webp'
import dimensions_chains from 'assets/img/floorplan/general/dimension_chains.webp'
import dimensions_each_room from 'assets/img/floorplan/general/dimensions_each_room.webp'
import disclaimer_text from 'assets/img/floorplan/general/disclaimer_text.webp'
import fixtures_furniture_variant1 from 'assets/img/floorplan/3D/3D_fixtures_furniture_variant1.webp'
import fixtures_furniture_variant2 from 'assets/img/floorplan/3D/3D_fixtures_furniture_variant2.webp'
import fixtures_furniture_variant3 from 'assets/img/floorplan/3D/3D_fixtures_furniture_variant3.webp'
import fixtures_variant1 from 'assets/img/floorplan/3D/3D_fixtures_variant1.webp'
import fixtures_variant2 from 'assets/img/floorplan/3D/3D_fixtures_variant2.webp'
import fixtures_variant3 from 'assets/img/floorplan/3D/3D_fixtures_variant3.webp'
import furniture_variant1 from 'assets/img/floorplan/3D/3D_furniture_variant1.webp'
import furniture_variant2 from 'assets/img/floorplan/3D/3D_furniture_variant2.webp'
import furniture_variant3 from 'assets/img/floorplan/3D/3D_furniture_variant3.webp'
import logo_placeholder from 'assets/img/floorplan/general/logo_placeholder.webp'
import room_area from 'assets/img/floorplan/general/room_area.webp'
import room_names from 'assets/img/floorplan/general/room_names_ENG.webp'
import scale_bar from 'assets/img/floorplan/general/scale_bar.webp'

export enum Styles {
  STYLE_1 = 'STYLE_1', // Might be renamed to an actual style name or color
  STYLE_2 = 'STYLE_2',
  STYLE_3 = 'STYLE_3',
}

export enum Orientations {
  BIRD_VIEW = 'BIRD_VIEW',
  ANGLE_VIEW = 'ANGLE_VIEW',
}

export const stylesMap: Record<Styles, string> = {
  [Styles.STYLE_1]: basic_variant1,
  [Styles.STYLE_2]: basic_variant2,
  [Styles.STYLE_3]: basic_variant3,
}

export const styleAngleViewMap: Record<Styles, string> = {
  [Styles.STYLE_1]: basic_variant1,
  [Styles.STYLE_2]: basic_variant2,
  [Styles.STYLE_3]: basic_variant3,
}

const fixturesMap: Record<Styles, string> = {
  [Styles.STYLE_1]: fixtures_variant1,
  [Styles.STYLE_2]: fixtures_variant2,
  [Styles.STYLE_3]: fixtures_variant3,
}

const furnitureMap: Record<Styles, string> = {
  [Styles.STYLE_1]: furniture_variant1,
  [Styles.STYLE_2]: furniture_variant2,
  [Styles.STYLE_3]: furniture_variant3,
}

const fixturesFurnitureMap: Record<Styles, string> = {
  [Styles.STYLE_1]: fixtures_furniture_variant1,
  [Styles.STYLE_2]: fixtures_furniture_variant2,
  [Styles.STYLE_3]: fixtures_furniture_variant3,
}

const logo3D = logo_placeholder
const roomNames3D = room_names
const basicDimensionsChains = dimensions_chains
const basicDimensionsEachRoom = dimensions_each_room
const basicScalebar = scale_bar
const roomArea3D = room_area
const compass3D = compass
const disclaimer = disclaimer_text

/** Gets an array of image URLs based on the selected options. */
export const getPreview = ({
  style,
  orientation,
  showEachRoomDimensions,
  showDimensionChain,
  showScalebar,
  showFurniture,
  showLogo,
  showRoomArea,
  showRoomNames,
  showCompass,
  showFixtures,
  showDisclaimer,
}: FloorPlan3DOptions) => {

  const images: string[] = []

  // 3D image
  if (showFurniture && !showFixtures) images.push(furnitureMap[style])
  if (showFixtures && !showFurniture) images.push(fixturesMap[style])
  if (showFurniture && showFixtures) images.push(fixturesFurnitureMap[style])
  if (!showFixtures && !showFurniture && orientation === Orientations.BIRD_VIEW) images.push(stylesMap[style])
  if (!showFixtures && !showFurniture && orientation === Orientations.ANGLE_VIEW) images.push(styleAngleViewMap[style])
  if (showEachRoomDimensions) images.push(basicDimensionsEachRoom) //used basic version as placeholder
  if (showDimensionChain) images.push(basicDimensionsChains) // used basic version as placeholder
  if (showScalebar) images.push(basicScalebar) // used basic version as placeholder
  if (showLogo) images.push(logo3D)
  if (showRoomArea) images.push(roomArea3D)
  if (showRoomNames) images.push(roomNames3D)
  if (showCompass) images.push(compass3D)
  if (showDisclaimer) images.push(disclaimer)

  return images
}
