import { Stack, Typography } from '@mui/material'

import { BKBNThemeWrapper } from '../BKBNThemeWrapper'
import { FilterTab } from '../FilterTab'
import { Nullable } from 'models/helpers'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export interface InlineFilterItem<T> {
  label: string
  count?: number
  value: T
}

interface Props<FilterValueType> {
  /** Array of filter specifying objects */
  readonly filters: InlineFilterItem<FilterValueType>[]
  /** Currently active filter value */
  readonly activeFilter: Nullable<FilterValueType>
  /** Optional label replacing default "Filter by:" */
  readonly filterLabel?: string
  /** Optional height for FilterTab components replacing default 3rem */
  readonly filterHeight?: string
  /** Handler function for onFilterUpdate event */
  onFilterUpdate: (newFilter: Nullable<FilterValueType>) => void
}

/**
 * Component putting FilterTab components together in like making them into one row of filters.  
 * Provides generic type for filter value param. - default is *string*   
 * 
 * @example
 * <InlineFilters<number>
 *  filters={[
 *    { value: 1, label: 'Onek' },
 *    { value: 2, label: 'Twok' },
 *    { value: 3, label: 'Threek' },
 *    { value: 4, label: 'Fork' },
 *  ]},
 *  activeFilter={2}
 *  filterLabel="Filter cutlery:"
 *  onFilterUpdate={(selectedFilterValue) => setSelectedCutleryType(selectedFilterValue)}
 * />
 */
export function InlineFilters<FilterValueType = string>({
  filters,
  activeFilter,
  filterLabel,
  filterHeight,
  onFilterUpdate,
}: Props<FilterValueType>) {

  const { t } = useTranslation(['common'])

  const totalCount = useMemo(() => {
    let total = undefined

    for (const filter of filters) {
      if (!filter.count) continue
      total = (total ?? 0) + filter.count
    }

    return total
  }, [filters])

  return (
    <BKBNThemeWrapper>
      <Stack direction="row" alignItems="center" flexWrap="wrap" gap={1} marginBottom={2}>

        <Typography component="span" fontSize={16}>
          {filterLabel ?? t('Filter_by')}
        </Typography>

        <FilterTab
          height="3rem"
          isActive={!activeFilter}
          label={t('All')}
          totalCount={totalCount !== undefined ? totalCount.toString() : undefined}
          onClick={() => onFilterUpdate(null)}
        />

        {filters.map(({ count, label, value }) => (
          <FilterTab
            key={`${label}_${count}`}
            height={filterHeight ?? '3rem'}
            isActive={activeFilter === value}
            totalCount={count ? String(count) : undefined}
            label={label}
            onClick={() => onFilterUpdate(value)}
          />
        ))}

      </Stack>
    </BKBNThemeWrapper>
  )
}
