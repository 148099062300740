import { PurchaseFlowStepKey, usePurchaseFlowUtilities } from 'components/pages/PurchaseFlow/_main/contexts'

import { DynamicFlowStep } from 'components/common/DynamicFlow'
import { FC } from 'react'
import { FloorPlanCertificationStepController } from './FloorPlanCertificationStep.controller'

/**
 * @component
 * Page in PurchaseFlow step for floor plan certifications.
 * 
 * @example 
 * <FloorPlanCertificationStep />
 */
export const FloorPlanCertificationStep: FC = () => {
  const { isFloorPlanCertificationStepValid } = usePurchaseFlowUtilities()

  return (
    <DynamicFlowStep
      stepKey={PurchaseFlowStepKey.FLOOR_PLAN_CERTIFICATION}
      isValid={isFloorPlanCertificationStepValid}
    >
      <FloorPlanCertificationStepController />
    </DynamicFlowStep>
  )
}
