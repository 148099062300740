import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { Box, Stack, Typography, useMediaQuery } from '@mui/material'
import { CORAL_600, GRAY_300, GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { MUIButton } from 'components/common/MUIButton'
import { MUITooltip } from 'components/common/MUITooltip'
import { PendingInvitationMemberTable } from '../PendingInvitationMemberTable'
import { WorkspaceMemberTable } from '../WorkspaceMemberTable'
import { WorkspaceRole } from 'models/user'
import { externalNavigate } from 'utils/helpers'
import { getDeleteUserLink } from 'utils/localization/subscriptionGoogleForms'
import i18n from 'translations/i18n'
import { redesignMUITheme } from 'constants/styling/redesignTheme'
import { useWorkspacesProfileContext } from '../_main/WorkspacesProfile.context'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** Workspace ID */
  workspaceId: string
}

/**
 * @component Workspace member table card
 * @example <WorkspaceMemberCard />
 */
export const WorkspaceMemberCard: FC<Props> = ({ workspaceId }) => {
  const { t } = useTranslation(['workspace_profile'])
  const isMobileView = useMediaQuery(redesignMUITheme.breakpoints.down('sm'))

  const {
    workspaceData,
    workspaceDataOfUser,
    pendingInvitations,
    setAddMembersPopup,
  } = useWorkspacesProfileContext()

  const isWorkspaceRoleMember = useMemo(() => workspaceDataOfUser?.role === WorkspaceRole.MEMBER, [workspaceDataOfUser?.role])

  if (!workspaceData) return <></>

  return (
    <Stack
      className="workspaceMemberCard"
      boxSizing="border-box"
      gap="3rem"
      justifyContent="center"
      alignItems="center"
      margin="5rem 0"
      sx={{
        padding: isMobileView ? 'unset' : '5rem',
        border: isMobileView ? 'unset' : `.1rem solid ${GRAY_300}`
      }}
    >
      <Stack flexDirection={isMobileView ? 'column' : 'row'} gap="1.6rem" justifyContent="space-between" width="100%">
        <Box>
          <Typography variant="text-lg" fontWeight="semiBold" color={GRAY_900}>{t('plan_members')}</Typography>
          <Typography variant="text-lg" fontWeight="semiBold" color={GRAY_700} marginLeft=".5rem">({`${workspaceData.members.length} ${t('people')}`})</Typography>
        </Box>

        <MUIButton
          type="secondaryBorder"
          variant="outlined"
          size="lg"
          sx={{ borderColor: GRAY_900 }}
          onClick={() => {
            setAddMembersPopup(true)
            logAnalyticsEvent(AnalyticsEvent.WORKSPACE_PROFILE_CLICK_ADD_MEMBER, {
              userId: workspaceDataOfUser?.userId,
              userRole: workspaceDataOfUser?.role,
              planId: workspaceId,
            })
          }}
        >
          {t('add_member')}
        </MUIButton>
      </Stack>

      {/* Admin and member table */}
      <WorkspaceMemberTable />

      {!isWorkspaceRoleMember &&
        <Stack width="100%" gap="1rem" flexDirection="row" justifyContent={isMobileView ? 'center' : 'flex-end'} alignItems="center">
          <MUITooltip content={<Trans t={t} i18nKey="delete_user_tooltip" />}>
            <InfoOutlinedIcon fontSize="large" sx={{ color: CORAL_600 }} />
          </MUITooltip>
          <MUIButton size="lg" type="orangeSecondaryBorder" variant="outlined" onClick={() => {
            externalNavigate(getDeleteUserLink(i18n.language))
            logAnalyticsEvent(AnalyticsEvent.WORKSPACE_PROFILE_CLICK_DELETE_USER, {
              userId: workspaceDataOfUser?.userId,
              userRole: workspaceDataOfUser?.role,
              planId: workspaceId
            })
          }}
          >
            {t('delete_user')}
          </MUIButton>
        </Stack>
      }

      {/* Pending invitation member table */}
      {!!pendingInvitations?.length && <PendingInvitationMemberTable />}

    </Stack>
  )
}
