/** Enum of all available Signup "How did you hear about us?" answers */
export enum SignupHowDidYouHearAnswers {
  BACKBONE = 'backbone',
  SEARCH_ENGINE = 'search_engine',
  LINKEDIN = 'linkedin',
  FACEBOOK = 'facebook_instagram',
  RECOMMENDATION = 'recommendation',
  OFFLINE = 'offline_event',
  OTHER = 'other'
}
