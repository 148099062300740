import { FC, Fragment } from 'react'

import { useDynamicFlowController } from './DynamicFlow.context'

/**
 * View component for DynamicFlow that renders all nodes.
 * 
 * @example <DynamicFlowView />
 */
export const DynamicFlowView: FC = () => {

  const flowCtrl = useDynamicFlowController()

  return (
    <Fragment>
      {flowCtrl.nodes.toArray().map(([, step]) => (
        <Fragment key={step.key}>
          {step.component}
        </Fragment>
      ))}
    </Fragment>
  )
}
