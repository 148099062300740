import { fileIsImage } from 'utils/typeguards'

export type ImageMetadata = {
  height: number
  width: number
  byteSize: number
}

/**
 * Function that accepts File and returns metadata for image type files.
 * Returns error when file's mimetype is not *image/**
 * @param file - Image file which metadata are to be extracted
 * @returns Metadata object of type  {@link ImageMetadata}
 */
export function getImageMetadata(file: File) {
  return new Promise<ImageMetadata>((resolve, reject) => {

    if (!fileIsImage(file)) reject('Unsupported file type')

    try {
      const reader = new FileReader()

      reader.onload = () => {
        const img = new Image()

        img.onload = () => {
          resolve({
            width: img.width,
            height: img.height,
            byteSize: file.size,
          })
        }

        if (typeof reader.result === 'string') img.src = reader.result
      }

      reader.readAsDataURL(file)
    } catch (e) {
      reject('Couldn\'t read uploaded file')
    }

  })
}
