import * as Sentry from '@sentry/react'

import { applyMiddleware, compose, createStore } from 'redux'

import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { logger } from 'redux-logger'
import { reduxEnhancerOptions } from 'utils/analytics'
import { rootReducer } from '../RootReducer'
import { rootSaga } from '../RootSaga'

const sentryReduxEnhancer = Sentry.createReduxEnhancer(reduxEnhancerOptions)
const sagaMiddleware = createSagaMiddleware()

export const composedStore = process.env.NODE_ENV === 'development' ?
  createStore(
    rootReducer,
    composeWithDevTools(
      applyMiddleware(sagaMiddleware, logger), sentryReduxEnhancer
    )
  )
  :
  createStore(
    rootReducer,
    compose(
      applyMiddleware(sagaMiddleware), sentryReduxEnhancer
    )
  )

sagaMiddleware.run(rootSaga)