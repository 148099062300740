import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { ActionRequest, StatusResponse } from 'models/redux'

import { QueryClient } from '@tanstack/react-query'
import { createAPIAction } from 'redux/Helpers'

/** Describes edit mission admin comments for editor action */
export interface ActionEditMissionAdminCommentsForEditor extends ActionRequest {
  payload: {
    missionId: string,
    comments: string,
    request: StatusResponse,
  },
}

/** Start edit mission admin comments for editor */
export function editMissionAdminCommentsForEditor(missionId: string, comments: string, queryClient: QueryClient): ActionEditMissionAdminCommentsForEditor {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.EDIT_ASSIGNMENT_ADMIN_COMMENTS_FOR_EDITOR], {
    missionId,
    comments,
  }, queryClient)
}

/** Reset edit mission admin comments for editor action */
export function resetEditMissionAdminCommentsForEditor(missionId: string): ActionEditMissionAdminCommentsForEditor {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.EDIT_ASSIGNMENT_ADMIN_COMMENTS_FOR_EDITOR], {
    missionId,
  })
}
