import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { ActionCreateWorkspaceSubscription } from './createWorkspaceSubscription.actions'
import { CreateWorkspaceSubscriptionStore } from './createWorkspaceSubscription.interfaces'

/** Redux data reducer for create workspace subscription */
export const reduceCreateWorkspaceSubscription = (state: CreateWorkspaceSubscriptionStore, action: ActionCreateWorkspaceSubscription) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.CREATE_WORKSPACE_SUBSCRIPTION) return state

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
    case ActionTypeAPIEvent.FETCH:
    case ActionTypeAPIEvent.RECEIVED:
      return action.payload.request
    default:
      return state
  }
}
