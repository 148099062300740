import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { InvitedUserDTO, UserAsMemberUsingEmailDTO } from 'models/workspaces'
import { all, put, takeEvery } from 'redux-saga/effects'
import { getSubscriptionCheckoutLink, getSubscriptionCheckoutLinkSaga } from 'redux/Individual/Subscriptions/GetCheckoutLink'
import { addWorkspaceMembers, addWorkspaceMembersSaga, purgeAddWorkspaceMembers } from '../AddWorkspaceMembers'

import { APIRequestState } from 'constants/API'
import { APIRequest } from 'models/API'
import { SubscriptionPlan } from 'models/user'
import { actionTypeTupleTest } from 'redux/Helpers'
import { createWorkspace } from '../createWorkspace/createWorkspace.actions'
import { createWorkspaceSaga } from '../createWorkspace/createWorkspace.sagas'
import { ActionCreateWorkspaceSubscription } from './createWorkspaceSubscription.actions'
import { CreateWorkspaceSubscriptionStore } from './createWorkspaceSubscription.interfaces'

/** Saga which creates workspace subscription */
export function* createWorkspaceSubscriptionSaga(receivedAction: ActionCreateWorkspaceSubscription) {

  const { workspaceName, members, period, workspaceId } = receivedAction.payload

  /* 
    null / undefined causes type propagation issues in conditions/maps
    so i've decided to use empty string to keep the type static
  */
  let createdWorkspaceId: string = workspaceId || ''

  // Prepare mock agregate request 
  const returnRequest: CreateWorkspaceSubscriptionStore = new APIRequest(
    APIRequestState.OK,
    undefined,
    {
      noAccountUsers: []
    }
  )

  // Prepare action for returning
  const returnAction: ActionCreateWorkspaceSubscription = {
    ...receivedAction,
    type: [ActionTypeAPIEvent.RECEIVED, ActionTypeAPIData.CREATE_WORKSPACE_SUBSCRIPTION],
  }

  /*
    Skip creating and populating workspace createdWorkspaceId exists
    => creation has been done already and later action failed
  */
  if (createdWorkspaceId === '') {

    // CREATE WORKSPACE
    const workspaceAction = yield* createWorkspaceSaga(createWorkspace(workspaceName))
    const { request: workspaceRequest } = workspaceAction.payload

    // If createWorkspace failed, use createWorkspace request error and return failed action
    if (workspaceRequest.state !== APIRequestState.OK || !workspaceRequest.data) {
      returnAction.payload.request = {
        ...returnRequest,
        state: workspaceRequest.state,
        error: workspaceRequest.error,
        error_type: workspaceRequest.error_type,
      }

      yield put(returnAction)
      return returnAction
    }

    createdWorkspaceId = workspaceRequest.data.id

    // Will hold emails of members that have been invited because they do not have a bkbn account
    const noAccountUsers: InvitedUserDTO[] = []

    // ADD MEMBERS -- skip if no emails have been passed
    if (!!members.length) {

      const newMembers: UserAsMemberUsingEmailDTO[] = members.map((email) => ({ email, inSubscriptionFlow: false }))

      // Call addWorkspaceMembers for multiple emails and save the action for reference
      const addMembersAction = yield* addWorkspaceMembersSaga(addWorkspaceMembers(createdWorkspaceId, newMembers))

      // Purge state of the actions and keep track of no-account emails
      yield put(purgeAddWorkspaceMembers(createdWorkspaceId))

      // Error handling
      if (addMembersAction.payload.request.state === APIRequestState.OK) {
        if (addMembersAction.payload.request.data) {
          // Push user to the noAccountUsers if request data has user without userId (without account)
          const invited = addMembersAction.payload.request.data.invitedUsers.filter((invitedUser) => !invitedUser.userId)
          if (invited && invited.length) noAccountUsers.push(...invited)
        }
      }

    }

    returnRequest.data = {
      noAccountUsers,
    }

  }

  // GET CHECKOUT PAGE URL
  const getLinkAction = yield* getSubscriptionCheckoutLinkSaga(
    getSubscriptionCheckoutLink(
      createdWorkspaceId,
      {
        plan: SubscriptionPlan.PRO_PER_SEAT,
        period,
      }
    )
  )

  const { request: getLinkRequest } = getLinkAction.payload

  // If getSubscriptionCheckoutLink failed, use its request error and return failed action
  if (getLinkRequest.state !== APIRequestState.OK || !getLinkRequest.data?.url) {
    returnAction.payload.request = {
      ...returnRequest,
      state: getLinkRequest.state,
      error: getLinkRequest.error,
      error_type: getLinkRequest.error_type
    }

    yield put(returnAction)
    return returnAction
  }

  // Use final mutated returnRequest and yield final action
  returnAction.payload.request = returnRequest
  yield put(returnAction)
  return returnAction
}

/** Watcher of create workspace subscription actions */
export function* createWorkspaceSubscriptionWacher() {
  yield all([
    takeEvery((action: ActionCreateWorkspaceSubscription) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.CREATE_WORKSPACE_SUBSCRIPTION]), createWorkspaceSubscriptionSaga),
  ])
}
