import { APIRequestErrorType, APIRequestState, Endpoint } from 'constants/API'
import { ActionLoadPurchaseSessionVisual, downloadPurchaseSessionVisual } from '../LoadPurchaseSessionVisual'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { END, EventChannel, eventChannel } from 'redux-saga'
import { SignedURLDTO, SignedUrlUploadPurchaseSessionRequestBody } from 'models/visuals'
import { fork, take } from 'typed-redux-saga'

import API from 'utils/API/API'
import { APIRequest } from 'models/API'
import { ActionRequest } from 'models/redux'
import { VisualFileType } from 'constants/visual'
import axios from 'axios'
import { generalFetch } from 'redux/Helpers'
import { put } from 'redux-saga/effects'

const removeFileExtensionRegex = /\..+/

/** A method which generates eventChannel that emits information about upload progress and finished upload response */
function createUploadChannel(receivedAction: ActionLoadPurchaseSessionVisual, SignedURLDTO: SignedURLDTO, delayBeforeDownload?: number) {
  return eventChannel<ActionLoadPurchaseSessionVisual>(emit => {
    (async () => {
      const { sessionId, productId, productSerial, file, originalFilename, droppedIn } = receivedAction.payload
      const split = SignedURLDTO.signedURL.split('?')[0].split('/')
      const filename = split[split.length - 1].replace(removeFileExtensionRegex, '')
      const source = axios.CancelToken.source()
      const actionContainingResponseFromStorage: ActionRequest = await generalFetch(ActionTypeAPIData.LOAD_PURCHASE_SESSION_VISUAL, () => axios.put(SignedURLDTO.signedURL, file, {
        cancelToken: source.token,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1))
          const progressAction: ActionLoadPurchaseSessionVisual = {
            type: [ActionTypeAPIEvent.PROGRESS, ActionTypeAPIData.LOAD_PURCHASE_SESSION_VISUAL],
            payload: {
              ...receivedAction.payload,
              progress: percentCompleted,
              file: new File([], filename),
              signedUrl: SignedURLDTO.signedURL,
              cancelToken: source,
              request: new APIRequest(APIRequestState.RUNNING),
            }
          }

          emit(progressAction)
        },
        headers: {
          'Content-type': file.type,
          ...SignedURLDTO.headers,
        },
      }))

      // If upload cancelled, don't proceed
      if (actionContainingResponseFromStorage.payload.request.error_type === APIRequestErrorType.CANCEL_ERROR) {
        emit(END)
        return
      }

      const uploadedAction: ActionLoadPurchaseSessionVisual = {
        ...actionContainingResponseFromStorage,
        payload: {
          ...receivedAction.payload,
          ...actionContainingResponseFromStorage.payload,
          file: new File([], filename),
          signedUrl: SignedURLDTO.signedURL,
          cancelToken: source,
        }
      }

      const emitDownload = () => {
        emit(downloadPurchaseSessionVisual(sessionId, productId, productSerial, filename, VisualFileType.RAW_THUMB, originalFilename, droppedIn))
        emit(downloadPurchaseSessionVisual(sessionId, productId, productSerial, filename, VisualFileType.RAW_WEB, originalFilename, droppedIn))
        emit(END)
      }

      emit(uploadedAction)

      if (delayBeforeDownload) {
        window.setTimeout(() => {
          emitDownload()
        }, delayBeforeDownload)
      } else emitDownload()
    })()

    return () => { }
  })
}

/** Saga that listens to all actions emitted from upload channel and passes them to redux */
function* uploadProgressListenerSaga(channel: EventChannel<ActionLoadPurchaseSessionVisual>) {
  while (true) {
    const action = yield* take(channel)
    yield put(action)
  }
}

/** Saga which handles uploading purchase flow session visual */
export function* uploadPurchaseSessionVisualSaga(receivedAction: ActionLoadPurchaseSessionVisual) {
  const { replaces, file, type, sessionId, productId, email } = receivedAction.payload
  if (replaces) return

  const dataForSignedUrl: SignedUrlUploadPurchaseSessionRequestBody = {
    email,
    productId,
    visualMetadata: {
      filename: file.name,
      contentType: file.type,
      type,
    },
  }

  const url = Endpoint.VISUAL_PURCHASE_SESSION_UPLOAD_URL
    .replace('{sessionId}', encodeURI(sessionId.toString()))
  const actionContainingSignedUrl: ActionRequest = yield generalFetch(ActionTypeAPIData.LOAD_PURCHASE_SESSION_VISUAL, () => API.post<SignedURLDTO>(url, dataForSignedUrl, {}, {
    endpoint: Endpoint.VISUAL_PURCHASE_SESSION_UPLOAD_URL,
  }))
  const SignedURLDTO: SignedURLDTO = actionContainingSignedUrl.payload.request.data
  const uploadAction: ActionLoadPurchaseSessionVisual = {
    ...receivedAction,
    payload: {
      ...receivedAction.payload,
      originalFilename: file.name,
    },
  }
  if (!SignedURLDTO?.signedURL) {
    const error_message = 'signedUrl is undefined, null or empty'
    console.error(error_message)
    uploadAction.payload.request.error_type = APIRequestErrorType.UNKNOWN_ERROR
    uploadAction.payload.request.error = error_message
    uploadAction.type = [ActionTypeAPIEvent.RECEIVED, ActionTypeAPIData.LOAD_PURCHASE_SESSION_VISUAL]
    yield put(uploadAction)
    return
  }
  const channel = createUploadChannel(uploadAction, SignedURLDTO)
  yield fork(uploadProgressListenerSaga, channel)
}

/** Saga which handles uploading purchase flow session visual replacement */
export function* uploadPurchaseSessionVisualReplacementSaga(receivedAction: ActionLoadPurchaseSessionVisual) {
  const { replaces, file, type, sessionId, productId, email } = receivedAction.payload
  if (!replaces) return

  const dataForSignedUrl: SignedUrlUploadPurchaseSessionRequestBody = {
    email,
    productId,
    visualMetadata: {
      filename: replaces,
      contentType: file.type,
      type,
    },
  }

  const url = Endpoint.VISUAL_PURCHASE_SESSION_UPLOAD_URL_REPLACE
    .replace('{sessionId}', encodeURI(sessionId.toString()))
  const actionContainingSignedUrl: ActionRequest = yield generalFetch(ActionTypeAPIData.LOAD_PURCHASE_SESSION_VISUAL, () => API.put<SignedURLDTO>(url, dataForSignedUrl, {}, {
    endpoint: Endpoint.VISUAL_PURCHASE_SESSION_UPLOAD_URL_REPLACE,
  }))
  const SignedURLDTO: SignedURLDTO = actionContainingSignedUrl.payload.request.data
  const uploadAction: ActionLoadPurchaseSessionVisual = {
    ...receivedAction,
    payload: {
      ...receivedAction.payload,
      originalFilename: file.name,
    },
  }
  if (!SignedURLDTO?.signedURL) {
    const error_message = 'signedUrl is undefined, null or empty'
    console.error(error_message)
    uploadAction.payload.request.error_type = APIRequestErrorType.UNKNOWN_ERROR
    uploadAction.payload.request.error = error_message
    uploadAction.type = [ActionTypeAPIEvent.RECEIVED, ActionTypeAPIData.LOAD_PURCHASE_SESSION_VISUAL]
    yield put(uploadAction)
    return
  }
  const channel = createUploadChannel(uploadAction, SignedURLDTO, 10000)
  yield fork(uploadProgressListenerSaga, channel)
}
