import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { Endpoint, RoleMimetype } from 'constants/API'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, takeEvery } from 'typed-redux-saga'

import API from 'utils/API/API'
import { ActionListPurchaseSessionVisuals } from '.'
import { put } from 'redux-saga/effects'

/** Saga which fetches list of all available purchase flow session visuals */
export function* listPurchaseSessionVisualsSaga(receivedAction: ActionListPurchaseSessionVisuals) {
  const url = Endpoint.VISUAL_PURCHASE_SESSION_LIST_ALL
    .replace('{assignmentId}', encodeURI(receivedAction.payload.sessionId.toString()))

  const action: ActionListPurchaseSessionVisuals = yield generalFetch(ActionTypeAPIData.LIST_PURCHASE_SESSION_VISUALS, () => API.get(url, {
    headers: {
      Accept: RoleMimetype.CLIENT, // TODO: Fix on BE so we can send proper accept header
    },
  }, {
    endpoint: Endpoint.VISUAL_PURCHASE_SESSION_LIST_ALL,
  }))
  const listVisualsAction: ActionListPurchaseSessionVisuals = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }

  yield put(listVisualsAction)
}

/** Watcher of purcahse flow session visual list actions */
export function* listPurchaseSessionVisualsWatcher() {
  yield all([
    takeEvery((action: ActionListPurchaseSessionVisuals) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.LIST_PURCHASE_SESSION_VISUALS]), listPurchaseSessionVisualsSaga),
  ])
}
