import { Action, ActionAPI, ActionString, ActionTupleType, ActionType } from 'models/redux'

import { QueryClient } from '@tanstack/react-query'
import { ActionStringType } from 'constants/redux'

/** Creates a uniform action with type and payload properties */
export function createAction<action_type>(type: ActionType, payload: any): action_type | Action {
  return {
    type,
    payload,
  }
}

/** Creates a uniform API action with tuple type and payload properties */
export function createAPIAction<action_type>(type: ActionTupleType, payload: any, queryClient?: QueryClient): action_type | ActionAPI {
  return {
    type,
    payload,
    queryClient,
  }
}

/** Creates a uniform string action with type and payload properties */
export function createStringAction<action_type>(type: ActionStringType, payload: any): action_type | ActionString {
  return {
    type,
    payload,
  }
}
