import { PlatformUser } from 'models/user'
import moment from 'moment'
import { tz } from 'moment-timezone'

/** A string array containing all moment-timezone timezones */
export const timezoneNames = tz.names()

/** Default timezone used when no other found/available */
export const defaultTimezone = 'Europe/Zurich'

/** Returns a default timezone based on timezone property in user object. If not available, it returns a timezone from user (browser) location, defaults to parameter fallbackTimezone = defaultTimezone = 'Europe/Zurich' */
export const getUserTimezone = (user?: PlatformUser | null, fallbackTimezone = defaultTimezone, useBrowserTimezone = true) => {
  if (user?.defaultTimezone) return user.defaultTimezone

  if (useBrowserTimezone) return getBrowserTimezone(fallbackTimezone)

  return fallbackTimezone
}

/** Returns timezone based on a timezone from machine (browser) location, defaults to parameter fallbackTimezone = defaultTimezone = 'Europe/Zurich' */
export const getBrowserTimezone = (fallbackTimezone = defaultTimezone) => {
  if (process.env.NODE_ENV === 'test' || process.env.CI === 'true') return fallbackTimezone
  let browserTimezone = fallbackTimezone

  try {
    browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || browserTimezone
  } catch (e) {
    browserTimezone = fallbackTimezone
  }

  return browserTimezone
}

/**
 * Retrieves the timezone offset for a given timezone.
 * @param {string} timezone - The name of the timezone.
 * @returns {string|false} The timezone offset formatted as 'Z', or false if the timezone is invalid.
 */
export function getTimezoneZOffset(timezone: string) {
  if (!moment.tz.zone(timezone)) return false
  return moment().tz(timezone).format('Z')
}
