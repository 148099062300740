import { useCheckCTUnavailabilityConflicts_, useCreateCTUnavailability, useUpdateCTUnavailability } from 'dataQueries'
import { useMemo, useState } from 'react'

import { useUserData } from 'components/contexts/UserDataContext'
import constate from 'constate'
import moment from 'moment-timezone'
import { logAnalyticsEvent } from 'utils/analytics'
import { useDebouncedEffect } from 'utils/helpers'

export const [UnavailabilityDrawerContextProvider, useUnavailabilityDrawer] = constate(() => {
  const { creativeData } = useUserData()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [unavailabilityId, setUnavailabilityId] = useState<string | null>(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const baseAvailableDate = useMemo(() => moment().add(1, 'minute').seconds(0).milliseconds(0), [isDrawerOpen])
  const defaultFromDate = useMemo(() => baseAvailableDate.clone().add(1, 'hour'), [baseAvailableDate])
  const defaultToDate = useMemo(() => defaultFromDate.clone().add(1, 'day'), [defaultFromDate])

  const [fromDate, setFromDate] = useState(defaultFromDate)
  const [toDate, setToDate] = useState(defaultToDate)

  const fromError = useMemo(() => {
    if (!fromDate) return 'required'
    if (fromDate.isBefore(baseAvailableDate)) return 'in_past'
    return null
  }, [baseAvailableDate, fromDate])

  const toError = useMemo(() => {
    if (!toDate) return 'required'
    if (!!fromDate && toDate.isSameOrBefore(fromDate)) return 'is_same_or_before'
    return null
  }, [fromDate, toDate])

  // Query actions
  const createUnavailability = useCreateCTUnavailability()
  const updateUnavailability = useUpdateCTUnavailability()
  const checkConflicts = useCheckCTUnavailabilityConflicts_(fromDate, toDate)

  const doesUnavailabilityClash = useMemo(() => (checkConflicts.data || []).length > 0, [checkConflicts.data])

  const isFormValid = useMemo(() => {
    if (!!fromError) return false
    if (!!toError) return false
    if (doesUnavailabilityClash) return false
    return true
  }, [doesUnavailabilityClash, fromError, toError])

  const openAddUnavailabilityDrawer = () => {
    setUnavailabilityId(null)
    setFromDate(defaultFromDate)
    setToDate(defaultToDate)
    setIsDrawerOpen(true)

    logAnalyticsEvent('save_new_unavailability_opened', {
      creativeId: creativeData?.id,
    })
  }

  const openEditUnavailabilityDrawer = (id: string, from: moment.Moment, to: moment.Moment) => {
    setUnavailabilityId(id)
    if (from.isValid()) setFromDate(from)
    if (to.isValid()) setToDate(to)
    setIsDrawerOpen(true)
  }

  const closeUnavailabilityDrawer = () => {
    createUnavailability.reset()
    updateUnavailability.reset()
    setUnavailabilityId(null)
    setIsDrawerOpen(false)
    setFromDate(defaultFromDate)
    setToDate(defaultToDate)
  }

  useDebouncedEffect(() => {
    if (!isDrawerOpen) return
    if (!fromDate || !toDate) return
    if (!fromDate.isValid() || !toDate.isValid()) return
    if (!!fromError || !!toError) return
    checkConflicts.refetch()
  }, [checkConflicts, fromError, isDrawerOpen, toDate, toError, fromDate], 300)

  return {
    unavailabilityId,
    isDrawerOpen,
    fromDate,
    toDate,
    fromError,
    toError,
    isFormValid,
    baseAvailableDate,
    defaultFromDate,
    defaultToDate,
    doesUnavailabilityClash,
    createUnavailability,
    updateUnavailability,
    setFromDate,
    setToDate,
    openAddUnavailabilityDrawer,
    openEditUnavailabilityDrawer,
    closeUnavailabilityDrawer,
  }
})
