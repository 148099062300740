import { useEffect, useRef } from 'react'

/** Creates a reference pointing at the passed state value. The reference changes its current value upon detecting a change to the original state value */
export function useStateRef<T>(stateValue: T) {
  const stateValueRef = useRef<T>(stateValue)

  // Set stateValueRef upon downloadVisuals changing
  useEffect(() => {
    stateValueRef.current = stateValue
  }, [stateValue])

  return stateValueRef
}