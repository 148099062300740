import { DependencyList, EffectCallback, useEffect, useRef } from 'react'

/**
 * A function used for debouncing of useEffect hook  - setup only
 * @deprecated - replaced with new UseDebouncedMemo that has the timers built in, this function now only serves as helper for these higher level hooks
 */
export const debounceEffect = (
  effect: () => void,
  timeoutRef: React.MutableRefObject<number | undefined>,
  timeout: number,
  timeoutStartRef?: React.MutableRefObject<number | undefined>,
  maximumTimeout?: number
) => {
  const clearDebounceTimeout = () => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current)
      timeoutRef.current = undefined
    }
  }

  if (timeoutStartRef?.current && maximumTimeout) {
    if ((timeoutStartRef.current + maximumTimeout) < Date.now()) {
      clearDebounceTimeout()
      timeoutStartRef.current = undefined
      effect()
      return
    }
  }

  clearDebounceTimeout()
  timeoutRef.current = window.setTimeout(effect, timeout)
  if (timeoutStartRef && !timeoutStartRef.current) timeoutStartRef.current = Date.now()
}

/** Debounced memo hook with timers built in */
export function useDebouncedEffect(
  effectFnc: EffectCallback,
  deps: DependencyList,
  debounceTime: number = 500,
  maxDebounceTime?: number
) {
  const debounceTimeoutRef = useRef<number | undefined>(undefined)
  const debounceTimeoutStartRef = useRef<number | undefined>(undefined)

  useEffect(() => {
    debounceEffect(
      effectFnc,
      debounceTimeoutRef,
      debounceTime,
      maxDebounceTime ? debounceTimeoutStartRef : undefined,
      maxDebounceTime
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceTime, maxDebounceTime, ...deps])
}
