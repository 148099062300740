import './AssignmentStageAdvice.sass'

import { Color, ColorClass, IconType } from 'constants/assets'

import { AssignmentStage } from 'constants/assignment'
import Icon from '../Icon/Icon'
import { MUITooltip } from '../MUITooltip'
import { Typography } from '@mui/material'
import { useAuth0 } from 'utils/auth'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** Unique ID used for ReactTooltip HTML element id */
  id: string
  /** Current stage */
  currentStage: AssignmentStage | undefined
  /** Set of valid stages */
  validStages: Set<AssignmentStage>
}

/** 
 * @component AssignmentStageAdvice containing a tooltip with a list of all assignment stages, highlighted current stage and the distinction between correct and incorrect assignment stages for specific action (when f.e. button can be clicked)
 * @example 
 * <AssignmentStageAdvice currentStage={assignmentStage} validStages={stagesUnlockingSendAssignmentVisualsToClient} />
 */
const AssignmentStageAdvice: React.FC<Props> = ({
  id,
  currentStage,
  validStages
}) => {
  const { t } = useTranslation(['gallery', 'assignment_stage'])

  const { roles } = useAuth0()
  const userRole = useMemo(() => {
    if (roles.isAdmin) return 'admin'
    if (roles.isCreative) return 'creative'
    else return 'client'
  }, [roles])

  return (
    <div className="tooltip-wrapper">

      <MUITooltip
        id={`assignment_stage_tooltip_${id}`}
        className="tooltip-list"
        type="secondary"
        content={
          <div>
            <Typography variant='text-md' fontWeight={600}>{t('assignment_stage_tooltip')}</Typography>
            <ul className="tooltip-list">
              {Object.values(AssignmentStage).map(stage => {
                let icon = validStages.has(stage) ? <Icon color={Color.BLACK} icon={IconType.CHECK} /> : <Icon color={Color.SECONDARY_RED_DARK} icon={IconType.CROSS} />
                let textDecoration = validStages.has(stage) ? 'bold' : 'line-through'
                let currentStageLabel = stage === currentStage ? <span className="italic bold"> ({t('current')})</span> : null
                return (
                  <li className="tooltip-list-item" key={stage}>
                    {icon}
                    <span className={textDecoration}>{t(`assignment_stage:${userRole}:${stage}`)}</span>
                    {currentStageLabel}
                  </li>
                )
              })
              }
            </ul>
          </div>
        }
      >
        <Icon
          icon={IconType.QUESTION}
          colorClass={ColorClass.SECONDARY_ORANGE_DARK}
          className="tooltip-icon space-larger-right"
          data-tip
          data-for={`assignment_stage_tooltip_${id}`}
        />
      </MUITooltip>

      <Typography variant='text-md'>
        {t('not_in_correct_assignment_stage')}
      </Typography>

    </div>
  )
}

export default AssignmentStageAdvice
