import { AutomateOrderCardContextProvider } from './AutomateOrderCard.context'
import { AutomateOrderCardController } from './AutomateOrderCard.controller'
import { FC } from 'react'

export const AutomateOrderCard: FC = () => {
  return (
    <AutomateOrderCardContextProvider>
      <AutomateOrderCardController />
    </AutomateOrderCardContextProvider>
  )
}
