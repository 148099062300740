import { Fragment, useState } from 'react'
import { useResetPassword, useToggleLoggedInCTStatus, useVerifyUser } from 'dataQueries/user.query'

import { ChangeEmailPopup } from '../ChangeEmailPopup'
import { KeyboardEventKey } from 'constants/misc'
import { MUIButton } from 'components/common/MUIButton'
import { QueryStatus } from 'components/common/QueryStatus'
import { Switch } from '@mui/material'
import styles from './Actions.module.sass'
import { useAuth0 } from 'utils/auth'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @component User Actions displayed in Profile page
 */
export const Actions: React.FC = () => {
  const { t } = useTranslation(['profile', 'language'])
  const { creativeData } = useUserData()
  const { user, roles } = useAuth0()
  const toggleLoggedInCTActivity = useToggleLoggedInCTStatus()
  const resetPassword = useResetPassword()
  const { spawnSuccessToast } = useSnackbar()

  const [changeEmailPopup, setChangeEmailPopup] = useState<boolean>(false)

  const verifyUser = useVerifyUser()

  if (!user) return <Fragment></Fragment>

  return (
    <Fragment>
      <h2 className="decorated">{t('actions')}</h2>
      <div className="section align-start">
        {!user.email_verified &&
          <div className="input-group">
            <label htmlFor="verification">{t('verification.label')}</label>
            <MUIButton
              type="defaultPrimary"
              className="verification"
              fullWidth
              isLoading={verifyUser.isPending}
              disabled={verifyUser.isPending}
              onClick={e => verifyUser.mutate({}, {
                onSuccess: () => {
                  spawnSuccessToast(t('verification.success'))
                },
              })}
            >
              {t('verification.text')}
            </MUIButton>
          </div>
        }

        {/* Change email */}
        <div className="input-group">
          <label htmlFor="changeEmail">{t('change_email.label')}</label>
          <MUIButton
            type="secondaryBorder"
            className="changeEmail"
            fullWidth
            onClick={() => setChangeEmailPopup(true)}
          >
            {t('change_email.text')}
          </MUIButton>
        </div>


        {/* Change password */}
        <div className="input-group">
          <label htmlFor="changepassword">{t('changepassword.label')}</label>
          <MUIButton
            type="secondaryBorder"
            className="changepassword"
            isLoading={resetPassword.isPending}
            disabled={resetPassword.isPending}
            fullWidth
            onClick={e => resetPassword.mutate({}, {
              onSuccess: () => {
                spawnSuccessToast(t('changepassword.success'))
              },
            })}
          >
            {t('changepassword.text')}
          </MUIButton>
        </div>

        {/* Check if the Cookiebot object is propely defined */}
        {typeof Cookiebot === 'object' && !!Cookiebot.renew &&
          <div className="input-group">
            <label htmlFor="resetcookies">{t('reset_cookies.label')}</label>
            <MUIButton
              type="secondaryBorder"
              className="resetcookies"
              onClick={() => Cookiebot.renew()}
              fullWidth
            >
              {t('reset_cookies.cta')}
            </MUIButton>
          </div>
        }

      </div>

      {roles.isCreative && !!creativeData &&
        <div className={styles.activityToggle} onClick={() => toggleLoggedInCTActivity.mutate({ enabled: !creativeData.enabled })}>

          <h4>
            {t('account_deactivation.title')}
          </h4>

          <Switch
            className={styles.toggleButton}
            checked={!creativeData.enabled}
          />

          <p>
            {t('account_deactivation.description')}
          </p>

          <QueryStatus
            query={toggleLoggedInCTActivity}
            spaceTopRem={1}
            successMessage={t('account_deactivation.success')}
          />
        </div>
      }

      <ChangeEmailPopup
        isOpen={changeEmailPopup}
        onClickOutside={() => setChangeEmailPopup(false)}
        onClickClose={() => setChangeEmailPopup(false)}
        onKeyDown={(e) => e.key === KeyboardEventKey.ESCAPE && setChangeEmailPopup(false)}
      />

    </Fragment>
  )
}
