import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { FileManipulationKey, downloadVisualTypeToBaseType } from 'constants/visual'
import { Channel, Task } from 'redux-saga'
import { call, cancel, delay, fork, put, race, take, takeEvery } from 'redux-saga/effects'
import { actionChannel, flush, fork as typedFork, take as typedTake } from 'typed-redux-saga'
import { uploadVisualReplacementSaga, uploadVisualSaga } from '../UploadVisual'

import { actionTypeTupleTest } from 'redux/Helpers'
import { ActionLoadVisual } from '.'
import { deleteVisualSaga } from '../DeleteVisual'
import { downloadVisualSaga } from '../DownloadVisual'

/** An object which stores ongoing (running) DOWNLOAD / UPLOAD sagas for the purpose of cancelling them in the future */
const runningSagaKeeper: {
  [key: string]: Task[]
} = {}

/** Generates key from ACTION_LOAD_VISUAL for running saga keeper */
const generateKeyForKeeper = (action: ActionLoadVisual) => {
  const { missionId, file, type } = action.payload
  return `${missionId}-${file.name}-${downloadVisualTypeToBaseType[type]}`
}

/** Adds saga to running saga keeper */
const addSagaToSagaKeeper = (receivedAction: ActionLoadVisual, actionToAdd: Task) => {
  if (!runningSagaKeeper[generateKeyForKeeper(receivedAction)]) runningSagaKeeper[generateKeyForKeeper(receivedAction)] = []
  runningSagaKeeper[generateKeyForKeeper(receivedAction)] = [...runningSagaKeeper[generateKeyForKeeper(receivedAction)], actionToAdd]
}

/** Cleans up asynchronously running saga keeper from finished sagas / tasks */
function* cleanUpSagaKeeper() {
  yield delay(2000)
  for (const sagaKey in runningSagaKeeper) {
    runningSagaKeeper[sagaKey] = runningSagaKeeper[sagaKey].filter(keptAction => keptAction.isRunning())
    if (!runningSagaKeeper[sagaKey] || runningSagaKeeper[sagaKey].length === 0) delete runningSagaKeeper[sagaKey]
  }
}

/** Watcher of visual fetch actions except for upload */
export function* loadVisualWatcher() {
  const requestChannel = yield* actionChannel<ActionLoadVisual>((action: ActionLoadVisual) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.LOAD_VISUAL]))
  while (true) {
    const receivedAction = yield* typedTake(requestChannel)
    yield fork(cleanUpSagaKeeper)
    switch (receivedAction.payload.manipulation) {
      case FileManipulationKey.DOWNLOAD:
        const downloadAction = yield* typedFork(downloadVisualSaga, receivedAction)
        addSagaToSagaKeeper(receivedAction, downloadAction)
        break
      case FileManipulationKey.DELETE:
        // Cancel all DOWNLOAD / UPLOAD sagas
        const downloadActionRetrieve = runningSagaKeeper[generateKeyForKeeper(receivedAction)]
        if (downloadActionRetrieve) {
          for (const saga of downloadActionRetrieve) {
            if (saga.isRunning()) yield cancel(saga)
          }
        }
        runningSagaKeeper[generateKeyForKeeper(receivedAction)] = []

        // Delete visuals
        yield fork(deleteVisualSaga, receivedAction)
        break
    }
  }
}

/** Watcher of visual upload fetch actions */
export function* uploadVisualWatcher() {
  // Action channel of all upload actions
  const requestChannel = yield* actionChannel<ActionLoadVisual>((action: ActionLoadVisual) => (
    actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.LOAD_VISUAL]) &&
    (action.payload.manipulation === FileManipulationKey.UPLOAD)
  ))

  // Action channel of all delete actions
  yield takeEvery((action: ActionLoadVisual) => (
    actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.LOAD_VISUAL]) &&
    (action.payload.manipulation === FileManipulationKey.DELETE)
  ), deleteVisualActionChannelWorker, requestChannel)

  // Create channel handler
  yield fork(uploadVisualActionChannelWorker, requestChannel)
}

function* deleteVisualActionChannelWorker(requestChannel: Channel<ActionLoadVisual>, receivedAction: ActionLoadVisual) {
  // Take actions one by one and rebuild the upload channel by removing any outdated upload actions (upload terminated by delete)
  switch (receivedAction.payload.manipulation) {
    case FileManipulationKey.DELETE:
      // Flush action channel of uploads
      const flushedActions = yield* flush(requestChannel)
      if (!flushedActions || (Array.isArray(flushedActions) && flushedActions.length === 0)) break

      for (const flushedAction of flushedActions) {
        // TODO: Check also for product name
        if (flushedAction.payload.file.name === receivedAction.payload.file.name) continue
        yield put(requestChannel, flushedAction)
      }

      break
    default:
      break
  }
}

function* uploadVisualActionChannelWorker(requestChannel: Channel<ActionLoadVisual>) {
  // Take actions one by one and produce blocking calls
  while (true) {
    const receivedAction = yield* typedTake(requestChannel)
    switch (receivedAction.payload.manipulation) {
      case FileManipulationKey.UPLOAD:
        yield race({
          upload: call(!!receivedAction.payload.replaces ? uploadVisualReplacementSaga : uploadVisualSaga, receivedAction),
          cancel: take((action: ActionLoadVisual) => (
            actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.LOAD_VISUAL]) &&
            (
              action.payload.manipulation === FileManipulationKey.DELETE &&
              // TODO: Check also for product name
              action.payload.file.name === receivedAction.payload.file.name
            )
          )),
        })
        break
      default:
        break
    }
  }
}
