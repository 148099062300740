import './EmailVerified.sass'

import React, { useCallback, useEffect, useMemo } from 'react'

import { Path } from 'constants/router'
import { useAuth0 } from 'utils/auth'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useVerifyEmail } from 'dataQueries'

const ChangedEmailMode: React.FC = () => {
  const navigate = useNavigate()
  const { logout } = useAuth0()
  const { t } = useTranslation(['email_verified'])

  const params = useMemo(() => new URLSearchParams(window.location.search), [])

  const verifyChengedEmail = useVerifyEmail()

  const cardString = useMemo(() => {
    if (verifyChengedEmail.isSuccess) {
      return { title: t('change_email_success_title'), text: t('change_email_success_text') }
    } else if (verifyChengedEmail.isError) {
      return { title: t('change_email_failed_title'), text: t('change_email_failed_text') }
    }
  }, [t, verifyChengedEmail.isError, verifyChengedEmail.isSuccess])

  const handleVerifyChangedEmail = useCallback(() => {
    const token = params.get('token') ?? ''
    verifyChengedEmail.mutate(
      { token },
      {
        onSuccess: () => setTimeout(() => logout(), 3000),
        onError: () => setTimeout(() => navigate(Path.INDEX), 3000)
      }
    )
  }, [logout, navigate, params, verifyChengedEmail])

  useEffect(() => {
    handleVerifyChangedEmail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section>
      <h1>{cardString?.title}</h1>
      <p>{cardString?.text}</p>
    </section>
  )
}

export default ChangedEmailMode
