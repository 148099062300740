import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { FC, Fragment } from 'react'
import { GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material'

import { MUIButton } from 'components/common/MUIButton'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MemberDrawerType } from '../WorkspaceProfileSettingDrawer'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { ResendInvitationButton } from '../ResendInvitationButton'
import { WorkspaceRole } from 'models/user'
import { redesignMUITheme } from 'constants/styling/redesignTheme'
import { useRevokeWorkspaceInvitation } from 'dataQueries'
import { useTranslation } from 'react-i18next'
import { useWorkspacesProfileContext } from '../_main/WorkspacesProfile.context'

/**
 * @component Pending Invitation Member Table
 * @example
 * <PendingInvitationMemberTable />
 */
export const PendingInvitationMemberTable: FC = () => {
  const { t } = useTranslation(['workspace_profile'])
  const isMobileView = useMediaQuery(redesignMUITheme.breakpoints.down('sm'))
  const revokeInvitation = useRevokeWorkspaceInvitation()

  const {
    workspaceId,
    workspaceDataOfUser,
    pendingInvitations,
    setPendingInvitationSetting,
    setMemberDrawerType,
    setIsSettingDrawerOpen
  } = useWorkspacesProfileContext()

  if (!pendingInvitations) return <></>

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell component="th" scope="row" colSpan={3} sx={{ padding: isMobileView ? '1.6rem .8rem' : '.6rem 1.6rem', borderBottom: 'unset' }}>
            <Typography variant="text-lg" fontWeight="semiBold" color={GRAY_900}>
              {t('pending_invitations')}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {pendingInvitations.map(({ email, id, invitedBy }) => (
          <TableRow
            key={id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell
              component="th"
              scope="row"
              sx={{ padding: isMobileView ? '1.6rem .8rem' : '.8rem 1.6rem' }}
            >
              <Typography
                variant="text-sm"
                color={GRAY_700}
                sx={{
                  display: 'block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: isMobileView ? '25rem' : 'unset'
                }}
              >
                {email}
              </Typography>
            </TableCell>

            {!isMobileView &&
              <TableCell align="right" sx={{ width: '16rem' }}>
                <ResendInvitationButton
                  workspaceId={workspaceId}
                  invitation={{ email, id, invitedBy }}
                  workspaceDataOfUser={workspaceDataOfUser}
                />
              </TableCell>
            }

            <TableCell
              align="right"
              sx={{
                padding: isMobileView ? '.6rem .8rem' : 'unset',
                width: isMobileView ? '4rem' : '6rem'
              }}
            >
              <MUIDropdown
                button={(isOpen, action) => (
                  <Fragment>
                    {(invitedBy === workspaceDataOfUser?.userId || workspaceDataOfUser?.role === WorkspaceRole.ADMIN) &&
                      <MUIButton
                        isIconButton
                        size="sm"
                        type="secondaryNoBorder"
                        onClick={(e) => {
                          if (isMobileView) {
                            setIsSettingDrawerOpen(true)
                            setMemberDrawerType(MemberDrawerType.RESEND_INVITATION)
                            setPendingInvitationSetting({ email, id, invitedBy })
                            return
                          }
                          return action(e)
                        }}
                      >
                        <MoreHorizIcon fontSize="large" />
                      </MUIButton>}
                  </Fragment>
                )}
              >
                {(invitedBy === workspaceDataOfUser?.userId || workspaceDataOfUser?.role === WorkspaceRole.ADMIN) &&
                  <MUIDropdownItem>
                    <MUIButton
                      size="sm"
                      type="orangeSecondaryNoBorder"
                      onClick={() => {
                        revokeInvitation.mutate({ workspaceId, invitationId: id })
                        !!workspaceDataOfUser && logAnalyticsEvent(AnalyticsEvent.REVOKE_INVITATION, {
                          userEmail: workspaceDataOfUser.email,
                          userRole: workspaceDataOfUser.role,
                          planId: workspaceId,
                          InvitedUserEmail: email
                        })
                      }}
                    >
                      {t('remove')}
                    </MUIButton>
                  </MUIDropdownItem>
                }
              </MUIDropdown>
            </TableCell>

          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
