import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import i18n from 'translations/i18n'
import { getSubscriptionPricingLink } from 'utils/localization/subscriptionPricing'

import { Path } from 'constants/router'
import { APIRequestErrorType } from 'constants/API'
import { BEIGE_600 } from 'constants/styling/theme'

import { Box, Stack } from '@mui/material'
import { supportEmail } from 'constants/contacts'
import Button from 'components/common/Button/Button'
import ErrorMessage from 'components/common/ErrorMessage/ErrorMessage'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'

import styles from './GalleryNotFound.module.sass'

interface Props {
  errorType?: APIRequestErrorType
}

export const GalleryNotFound: FC<Props> = ({ errorType }) => {
  const { t } = useTranslation('gallery')
  const navigate = useNavigate()

  return (
    <Box maxWidth="60rem" width="100%" margin="auto" marginTop="8rem">
      <BorderBoxWrapper backgroundColor={BEIGE_600} padding="0">
        <div className={styles.galleryNotFound}>
          <Stack direction="column" alignItems="center">
            <h3>{t('forbidden_access_heading')}</h3>
            {(errorType === APIRequestErrorType.FORBIDDEN_ERROR || APIRequestErrorType.PAYMENT_REQUIRED_ERROR) ?
              <div>
                <p>
                  <Trans t={t} i18nKey="forbidden_access">
                  <span>
                    <a href={getSubscriptionPricingLink(i18n.language)} target="_blank" rel="noreferrer">&nbsp;</a>
                  </span>
                  </Trans>
                </p>

                <p>
                  <Trans t={t} i18nKey={'forbidden_contact_us'}>
                  <span>
                    <a href={`mailto:${supportEmail}`}>&nbsp;</a>
                  </span>
                  </Trans>
                </p>
              </div>
              :
              <p><ErrorMessage error_type={errorType ?? 'error'} /></p>
            }

            <Button
              className={styles.marginTop}
              onClick={() => navigate(Path.INDEX)}
            >
              {t('back_to_my_orders')}
            </Button>
          </Stack>
        </div>
      </BorderBoxWrapper>
    </Box>
  )
}