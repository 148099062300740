import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { ActionRequest, StatusResponse } from 'models/redux'

import { QueryClient } from '@tanstack/react-query'
import { createAPIAction } from 'redux/Helpers'

/** Describes edit mission creative instructions from admin action  */
export interface ActionEditMissionCreativeInstructionsFromAdmin extends ActionRequest {
  payload: {
    missionId: string,
    instructions: string,
    request: StatusResponse,
  },
}

/** Start edit mission creative instructions from admin */
export function editMissionCreativeInstructionsFromAdmin(missionId: string, instructions: string, queryClient: QueryClient): ActionEditMissionCreativeInstructionsFromAdmin {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.EDIT_ASSIGNMENT_CREATIVE_INSTRUCTIONS_FROM_ADMIN], {
    missionId,
    instructions,
  }, queryClient)
}

/** Reset edit mission creative instructions from admin action */
export function resetEditMissionCreativeInstructionsFromAdmin(missionId: string): ActionEditMissionCreativeInstructionsFromAdmin {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.EDIT_ASSIGNMENT_CREATIVE_INSTRUCTIONS_FROM_ADMIN], {
    missionId,
  })
}
