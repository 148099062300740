import React, { Fragment } from 'react'
import Skeleton, { SkeletonProps } from '@mui/material/Skeleton'

import { GRAY_700 } from 'constants/styling/theme'
import Stack from '@mui/material/Stack'

type LoadingStyle = 'circular'

export interface LoadingWrapperProps {
  /** Determines if the component is loading. */
  isLoading: boolean
  /** The children to render. */
  children: React.ReactNode
  /** The loading style to use. */
  style?: LoadingStyle
}

const circularSkeleton: Pick<SkeletonProps, 'variant' | 'sx' | 'width' | 'height'>[] = [
  { variant: 'circular', width: 6, height: 6, sx: { bgcolor: GRAY_700 } },
  { variant: 'circular', width: 6, height: 6, sx: { bgcolor: GRAY_700 } },
  { variant: 'circular', width: 6, height: 6, sx: { bgcolor: GRAY_700 } },
]

export const LoadingWrapper: React.FC<LoadingWrapperProps> = ({ isLoading, children, style = 'circular' }) => {
  return (
    <Fragment>
      {isLoading ? (
        <Fragment>
          {style === 'circular' && (
            <Stack direction="row" gap={0.6}>
              {circularSkeleton.map((skeleton, index) => (
                <Skeleton
                  key={index}
                  variant={skeleton.variant}
                  width={skeleton.width}
                  height={skeleton.height}
                  sx={skeleton.sx}
                />
              ))}
            </Stack>
          )}
        </Fragment>
      ) : (
        children
      )}
    </Fragment>
  )
}
