import { useGalleryAssignment, useGalleryProduct, useGalleryVisuals, useGalleryVisualsMeta } from '../../contexts'

import { ClientGalleryFilters } from '../ClientGalleryFilters'
import { ClientGallerySendRequest } from '../ClientGallerySendRequest'
import { FC } from 'react'
import { GalleryFilter } from '../_main/contexts'
import { GallerySection } from 'components/common/Gallery/GallerySection'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props
 */
interface Props {
  /** JSX elements that we render in section as unpurchased */
  unPurchasedVisuals: JSX.Element
}

/**
 * Client gallery photo section for not purchased visuals only.
 * 
 * @example <ClientGalleryPhotoSectionNotPurchasedAny />
 */
export const ClientGalleryPhotoSectionNotPurchasedAny: FC<Props> = ({ unPurchasedVisuals }) => {
  const { t } = useTranslation(['gallery'])

  const { favoritedVisuals } = useGalleryVisuals()
  const { isVideo } = useGalleryProduct()
  const { isFeedbackButtonAllowed } = useGalleryAssignment()
  const {
    purchasedVisualsCount,
    unPurchasedVisualsCount
  } = useGalleryVisualsMeta()

  return (
    <>

      <ClientGalleryFilters
        purchasedCount={purchasedVisualsCount}
        totalCount={purchasedVisualsCount + unPurchasedVisualsCount}
        favoritesCount={favoritedVisuals.size ?? 0}
        hideTab={GalleryFilter.PURCHASED} // in FLOW A (unselected visuals) we want show only filter All & Favourites
      />

      <GallerySection
        title={t('visuals_ready')}
        subTitle={t('visuals_ready_description')}
        action={!isVideo && isFeedbackButtonAllowed && <ClientGallerySendRequest />}
      >
        {unPurchasedVisuals}
      </GallerySection>

    </>
  )
}
