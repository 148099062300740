import { FC } from 'react'
import { Outlet } from 'react-router'
import { SubscriptionFlowContextProvider } from './SubscriptionFlow.context'
import { SubscriptionFlowNavigationGuardProvider } from './SubscriptionFlowNavigationGuard.context'

/**
 * Root page for subscription flow process with outlet for subpages.
 * 
 * @example
 * <SubscriptionFlowPage />
 */
export const SubscriptionFlowPage: FC = () => {

  return (
    <SubscriptionFlowNavigationGuardProvider>
      <SubscriptionFlowContextProvider>
        <div className='page'>
          <div className='page-content'>
            <Outlet />
          </div>
        </div>
      </SubscriptionFlowContextProvider>
    </SubscriptionFlowNavigationGuardProvider>
  )
}
