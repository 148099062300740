import { ExportOption, Opacity, Positioning, Size } from 'constants/misc/editingOptions'
import { RatioDTO, VisualEditingTemplateOptionsDTO } from 'models/visualsEditing'
import { EntityKeys, QueryType, getMutation } from 'utils/reactQuery'

import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { Nullable } from 'models/helpers'
import { JobDTO } from 'models/misc'
import { NewWorkspaceDTO } from 'models/workspaces'
import { useDispatch } from 'react-redux'
import { ArchiveVisualsTag } from 'redux/Individual/Visual/ArchiveVisuals'
import { archiveEditingVisuals } from 'redux/Individual/VisualsEditing/ArchivedEditingVisuals'
import { useAPI } from 'utils/API'
import { useJobLongPoll } from './job.query'

enum Endpoints {
  UPDATE_VISUAL_EDITING_TEMPLATE = '/workspace/{workspaceId}/assets/template/',
  GET_VISUAL_EDITING_TEMPLATE_OPTIONS = '/workspace/{workspaceId}/assets/template/{templateId}',
  DELETE_VISUAL_EDITING_TEMPLATE = '/workspace/{workspaceId}/assets/template/{templateId}',
  VISUAL_EDITING_TEMPLATE = '/workspace/{workspaceId}/assets/template/',
  VISUAL_EDITING_SUBMIT_EXPRESS = '/assignment/{assignmentId}/visualEditing/submitExpress',
  TRIGGER_VISUALS_EDITING = '/assignment/{assignmentId}/visualEditing/submit',
  WATERMARK_DELETE = '/workspace/{workspaceId}/assets/watermark/{watermarkId}',
}

interface UpdateVisualEditingTemplatePayload {
  templateName?: string | null,
  watermarkId?: string | null,
  watermarkSize: Size,
  watermarkOpacity: Opacity,
  watermarkPositioning: Positioning,
  imageRatio: RatioDTO['value'],
  exportOption: ExportOption,
  workspaceId: string
}

interface useVisualEditingSubmitExpressPayload {
  templateId: string,
  filenames: Array<File['name']>,
  assignmentId: string,
  types: string[]
}

interface VisualsEditingOptionsPayload {
  assignmentId: string
  filenames: Array<File['name']>
  applyWatermark: boolean
  watermarkSize: Size
  watermarkOpacity: number
  watermarkPositioning: Positioning
  imageRatio: RatioDTO['value']
  exportOption: ExportOption
  workspaceId: string
}

// QUERIES

export function useVisualsEditingTemplateOptions(workspaceId: Nullable<NewWorkspaceDTO['id']>, templateId: Nullable<VisualEditingTemplateOptionsDTO['id']>, enabled: boolean) {
  const api = useAPI<Endpoints>()

  return useQuery<VisualEditingTemplateOptionsDTO, Error>({
    queryKey: [EntityKeys.VISUALS_EDITING, 'GET_TEMPLATE'],
    queryFn: () => api.get<VisualEditingTemplateOptionsDTO>(
      Endpoints.GET_VISUAL_EDITING_TEMPLATE_OPTIONS,
      {
        workspaceId: workspaceId?.toString() || '',
        templateId: templateId?.toString() || ''
      },
      false
    ),
    enabled
  })
}

// MUTATIONS

/**
 * We support only single template on FE.
 * If templateName is empty "${workspace.name} template" as a name will be used by default
 */
export const useUpdateVisualsEditingTemplate = () => {
  const api = useAPI<Endpoints>()

  return getMutation<{}, UpdateVisualEditingTemplatePayload>(
    ({ templateName = null, watermarkId = null, watermarkSize, watermarkOpacity, watermarkPositioning, imageRatio, exportOption, workspaceId }) => api.post(
      Endpoints.UPDATE_VISUAL_EDITING_TEMPLATE,
      {
        workspaceId
      },
      {
        templateName,
        watermarkId,
        watermarkSize,
        watermarkOpacity,
        watermarkPositioning,
        imageRatio,
        export: exportOption
      },
      false
    ),
    (client) => {
      client.invalidateQueries({ queryKey: [EntityKeys.USER_WORKSPACE, QueryType.LIST_MINE] })
    }
  )
}

export const useDeleteVisualsEditingLogoAndTemplate = () => {
  const api = useAPI<Endpoints>()
  const deleteLogo = useDeleteLogo()

  return getMutation<{}, { templateId: string, workspaceId: string, watermarkId: string }>(
    ({ templateId, workspaceId }) => api.delete(
      Endpoints.DELETE_VISUAL_EDITING_TEMPLATE,
      {
        templateId,
        workspaceId
      },
      false
    ),
    (_, { workspaceId, watermarkId }) => {
      deleteLogo.mutate({ workspaceId, watermarkId })
    }
  )
}

/** Mutation for delete logo */
export function useDeleteLogo() {
  const api = useAPI<Endpoints>()

  return getMutation<unknown, { workspaceId: string, watermarkId: string }>(
    ({ workspaceId, watermarkId }) => api.delete(
      Endpoints.WATERMARK_DELETE,
      {
        workspaceId: workspaceId.toString(),
        watermarkId: watermarkId.toString(),
      },
      false
    ),
    (client) => {
      client.invalidateQueries({ queryKey: [EntityKeys.USER_WORKSPACE, QueryType.LIST_MINE] })
    }
  )
}

/** Mutation for submit express visuals editing */
export function useVisualEditingSubmitExpress() {
  const api = useAPI<Endpoints>()
  const dispatch = useDispatch()
  const longPollJob = useJobLongPoll()

  return getMutation<AxiosResponse<{ id: string }>, useVisualEditingSubmitExpressPayload>(
    ({ templateId, filenames, assignmentId }) => api.post(
      Endpoints.VISUAL_EDITING_SUBMIT_EXPRESS,
      { assignmentId },
      {
        templateId,
        filenames
      },
      true
    ),
    async (_, { assignmentId, filenames, types }, data) => {
      const jobId = data.data.id
      if (!jobId) return

      try {
        await longPollJob.mutateAsync(jobId)
        dispatch(archiveEditingVisuals(assignmentId, jobId, types, filenames, ArchiveVisualsTag.CLIENT_ARCHIVE, false))
      } catch (e) {
        return Promise.reject(e)
      }

    }
  )
}

/**
 * Mutation for triggering visuals editing
 */
export const useTriggerVisualsEditing = () => {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<JobDTO>, VisualsEditingOptionsPayload>(
    ({ filenames, applyWatermark, watermarkSize, watermarkOpacity, watermarkPositioning, imageRatio, exportOption, workspaceId, assignmentId }) => api.post(
      Endpoints.TRIGGER_VISUALS_EDITING,
      {
        assignmentId: assignmentId.toString()
      },
      {
        filenames,
        applyWatermark,
        watermarkSize,
        watermarkOpacity,
        watermarkPositioning,
        imageRatio,
        export: exportOption,
        workspaceId
      },
      true
    )
  )
}
