import { Endpoint, RoleMimetype } from 'constants/API'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, takeEvery } from 'typed-redux-saga'

import { put } from 'redux-saga/effects'
import { longPollVisualArchiveJob } from 'redux/Individual/Visual/DownloadArchive'
import API from 'utils/API/API'
import { ActionArchiveEditingVisuals } from './ArchivedEditingVisuals.actions'

/** Saga which initiates archive download */
export function* archiveEditingVisualsSaga(receivedAction: ActionArchiveEditingVisuals) {
  const { missionId, exportId, types, filenames } = receivedAction.payload

  const archiveEditingVisualsUrl = Endpoint.VISUAL_EDITING_ARCHIVE
    .replace('{assignmentId}', encodeURI(missionId.toString()))
    .replace('{exportId}', encodeURI(exportId.toString()))

  const archiveAction: ActionArchiveEditingVisuals = yield generalFetch(ActionTypeAPIData.ARCHIVE_EDITING_VISUALS, () => API.post(archiveEditingVisualsUrl, {
    types,
    filenames,
  }, {
    headers: {
      Accept: RoleMimetype.CLIENT, // TODO: Fix on BE so we can send proper accept header
    },
  }, {
    endpoint: Endpoint.VISUAL_EDITING_ARCHIVE,
  }))

  const archiveEditingVisualsAction: ActionArchiveEditingVisuals = {
    ...archiveAction,
    payload: {
      ...receivedAction.payload,
      ...archiveAction.payload,
    }
  }

  yield put(archiveEditingVisualsAction)

  // Start long polling archive visual GCP job
  yield longPollVisualArchiveJob(archiveEditingVisualsAction)
}

/** Watcher of archive visuals actions */
export function* archiveEditingVisualsWatcher() {
  yield all([
    takeEvery((action: ActionArchiveEditingVisuals) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.ARCHIVE_EDITING_VISUALS]), archiveEditingVisualsSaga),
  ])
}
