import { APIRequestState, Endpoint, RoleMimetype } from 'constants/API'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { all, put, takeEvery } from 'redux-saga/effects'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { EntityKeys, QueryType } from 'utils/reactQuery'

import API from 'utils/API/API'
import { ActionEditMissionInternalComments } from '.'

/** Saga which edits mission internal comments */
export function* editMissionInternalCommentsSaga(receivedAction: ActionEditMissionInternalComments) {
  const { missionId, comments } = receivedAction.payload
  const url = Endpoint.ADMIN_EDIT_ASSIGNMENT_INTERNAL_COMMENTS
    .replace('{assignmentId}', encodeURI(missionId.toString()))
  const action: ActionEditMissionInternalComments = yield generalFetch(ActionTypeAPIData.EDIT_ASSIGNMENT_INTERNAL_COMMENTS, () => API.put(url, {
    message: comments,
  }, {
    headers: {
      Accept: RoleMimetype.ADMIN,
    },
  }, {
    endpoint: Endpoint.ADMIN_EDIT_ASSIGNMENT_INTERNAL_COMMENTS,
  }))

  const editMissionInternalCommentsAction: ActionEditMissionInternalComments = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }

  yield put(editMissionInternalCommentsAction)

  if (action.payload.request.state !== APIRequestState.OK) return
  receivedAction.queryClient?.invalidateQueries({ queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET_ONE, { assignmentId: receivedAction.payload.missionId }] })
}

/** Watcher of edit mission internal comments actions */
export function* editMissionInternalCommentsWatcher() {
  yield all([
    takeEvery((action: ActionEditMissionInternalComments) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.EDIT_ASSIGNMENT_INTERNAL_COMMENTS]), editMissionInternalCommentsSaga),
  ])
}
