/** Availability status of the creative for the particular assignment */
export enum CreativeAvailabilityStatus {
  /** Creative is available for the assignment */
  AVAILABLE = 'AVAILABLE',
  /** Creative is already booked for related assignment on the same order */
  ON_THIS_ORDER = 'ON_THIS_ORDER',
  /** Creative is already booked for another assignment */
  ON_AN_ASSIGNMENT = 'ON_AN_ASSIGNMENT',
  /** Creative has declined the assignment */
  DECLINED = 'DECLINED',
  /** Creative is not responding */
  UNRESPONSIVE = 'UNRESPONSIVE',
  /** Creative is selected on multiple orders (only for BE internal purposes for assignment automation, not used for creative status label) */
  SELECTED_ON_MULTIPLE_ORDERS = 'SELECTED_ON_MULTIPLE_ORDERS',
  /** CT is unavailable (unavailability has been set up in the profile page)  */
  UNAVAILABLE = 'UNAVAILABLE',
  /** Assignment is outside CT's working hours */
  NOT_WORKING = 'NOT_WORKING',
}
