import { EditPaymentMethodProvider } from './contexts'
import { FC } from 'react'
import { SavedPaymentMethodsController } from './SavedPaymentMethods.controller'
import { SavedStripePaymentMethodsContextProvider } from 'components/contexts/SavedStripePaymentMethodsContext'

/**
 * Shows list of saved payment methods with options to edit and delet each one.
 * Automatically fetches user's saved payment methods.
 * @example <SavedPaymentMethods />
 */
export const SavedPaymentMethods: FC = () => {
  return (
    <SavedStripePaymentMethodsContextProvider autoFetch={true}>
      <EditPaymentMethodProvider>
        <SavedPaymentMethodsController />
      </EditPaymentMethodProvider>
    </SavedStripePaymentMethodsContextProvider>
  )
}
