import { Trans, useTranslation } from 'react-i18next'

import { CreativeWorkingHours } from 'models/creative/creativeWorkingHoursDTO'
import React from 'react'
import { Stack } from '@mui/material'
import styles from './WorkingHoursItem.module.sass'

/** 
 * @interface Props Input properties
 */
interface Props {
  /** Key of the weekday */
  dayKey: string
  /** Array of creative's hours */
  timeSlots: CreativeWorkingHours[]
}

/**
 * @component WorkingHoursItem displays client working timeSlots details
 * @example
 * <WorkingHoursItem dayKey="MONDAY" timeSlots={[{ from:"6:00:00", to:"00:00:00" }]} />
 */
export const WorkingHoursItem: React.FC<Props> = ({ dayKey, timeSlots }) => {
  const { t } = useTranslation(['profile'])

  return (
    <div className={styles.contentWrapper}>

      {/** DAY OF THE WEEK */}
      <Trans
        t={t}
        parent="p"
        i18nKey={`work_preferences.working_hours.${dayKey}`}
      />

      {/** LIST OF HOURS */}
      <Stack direction="column" spacing={1}>
        {timeSlots.length
          ? timeSlots.map((hours) =>

            <div key={`${dayKey}-${hours.from}-${hours.to}`}>

              {/** Starting and ending hours */}
              <span>{hours.from.slice(0, -3)}</span>
              <span> - </span>
              <span>{hours.to.slice(0, -3)}</span>

            </div>

          ) : <span>{t('work_preferences.unavailable')}</span>
        }
      </Stack>

    </div>
  )

}
