import './RatingGeneral.sass'

import { EmojiValue, RatingType } from 'constants/misc'
import { Fragment, useMemo, useState } from 'react'

import Button from '../Button/Button'
import { Nullable } from 'models/helpers'
import { QueryStatus } from '../QueryStatus'
import { Rating } from 'models/assignment'
import RatingEmojis from '../RatingEmojis/RatingEmojis'
import RatingText from '../RatingText/RatingText'
import TransitionAppear from '../TransitionAppear/TransitionAppear'
import { UseMutationResult } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** The additional classes to append */
  className?: string
  /** Textarea name */
  textareaName?: string
  /** Textarea ID */
  textareaId?: string
  /** The already submitted rating to be displayed */
  rating: Nullable<Rating>
  /** The request data of submitting rating */
  ratingRequest: Nullable<UseMutationResult<any, any, any, any>>
  /** Rating type */
  ratingType: RatingType
  /** Event for submitting rating */
  onSubmitRating: (emoji: Nullable<EmojiValue>, text: Nullable<string>, ratingType: RatingType, isUpdate: boolean) => void
  /** Event for text change */
  onTextChange?: (text: string, rating: Nullable<EmojiValue>, ratingType: RatingType) => void
}

/**
 * @component Display rating with emoji rate and after clicking on emoji display rating with text field
 * @example
 * <RatingGeneral assignmentId={12345} ratingType={RatingType.VISUALS} isRatingPopupOpen={true} setAwaitTextRating={setAwaitTextRating} setRatingPopupOpen={setRatingPopupOpen} />
 */
const RatingGeneral: React.FC<Props> = ({
  className = '',
  textareaName,
  textareaId,
  ratingType,
  rating,
  ratingRequest,
  onSubmitRating,
  onTextChange,
}) => {

  const { t } = useTranslation(['rating'])

  const [emoji, setEmoji] = useState<EmojiValue | undefined>(rating?.rating)
  const [text, setText] = useState('')

  const textValue = useMemo(() => {
    return rating?.ratingText || text
  }, [rating?.ratingText, text])

  return (
    <Fragment>
      <div className={`rating-general ${className}`} id={ratingType}>

        <div className="rating-general-primary-box">
          <RatingEmojis
            activeEmoji={emoji}
            ratingType={ratingType}
            disabled={!!rating?.rating}
            onClick={(emojiToSet) => setEmoji(emojiToSet)}
          />
        </div>

        <TransitionAppear visible={!!emoji}>
          <RatingText
            ratingType={ratingType}
            textareaName={textareaName}
            textareaId={textareaId}
            textareaDisabled={!emoji || !!rating?.ratingText}
            textareaValue={textValue}
            activeEmoji={rating?.rating || emoji}
            isSubmitted={(!!rating?.rating || !!ratingRequest) && (!ratingRequest?.isError || !ratingRequest?.isPending)}
            onChange={(v) => {
              setText(v)
              onTextChange?.(v, emoji, ratingType)
            }}
          />
        </TransitionAppear>

        <TransitionAppear visible={!!emoji && !rating?.ratingText}>
          <Button
            id={ratingType}
            type="primary"
            disabled={!!rating?.rating && !text}
            onClick={e => onSubmitRating(emoji, text, ratingType, !!rating?.rating)}
          >
            {t('submit')}
          </Button>
        </TransitionAppear>

        {ratingRequest &&
          <QueryStatus
            query={ratingRequest}
            successMessage={t('feedback_sent')}
            errorMessage={t('feedback_error')}
            spaceTopRem={2}
          />
        }

      </div>
    </Fragment>
  )
}

export default RatingGeneral
