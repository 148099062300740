import * as Sentry from '@sentry/react'

import { FC, useLayoutEffect } from 'react'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { AppController } from './App.controller'
import { AppHeaderSwitchProvider } from '../AppHeaderSwitch'
import { AppMetaProvider } from './appMeta.context'
import { AppStyleSwitchProvider } from '../AppStyleSwitch'
import { BrowserRouter } from 'react-router-dom'
import { FlagProvider } from '@unleash/proxy-client-react'
import GlobalContextProvider from '../ContextProviders/GlobalContextProvider'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { config } from 'utils/featureFlags/unleashConfig'
import moment from 'moment-timezone'
import { queryClient } from 'utils/reactQuery'
import { useAuth0 } from 'utils/auth'
import { useDispatch } from 'react-redux'
import { userMe } from 'redux/Individual/User/UserMe'

const AppModule: FC = () => {

  const dispatch = useDispatch()
  const { isAuthenticated } = useAuth0()

  useLayoutEffect(() => {
    if (isAuthenticated) dispatch(userMe())
  }, [dispatch, isAuthenticated])

  return (
    <FlagProvider config={config}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <GlobalContextProvider>
            <AppMetaProvider>
              <AppStyleSwitchProvider>
                <AppHeaderSwitchProvider>
                  <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>

                    <ReactQueryDevtools initialIsOpen={false} />

                    <div className="App">
                      <AppController />
                    </div>

                  </LocalizationProvider>
                </AppHeaderSwitchProvider>
              </AppStyleSwitchProvider>
            </AppMetaProvider>
          </GlobalContextProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </FlagProvider>
  )
}

export const App = Sentry.withProfiler(AppModule)
