import React, { Fragment } from 'react'
import { WorkingHoursDrawer, useWorkingHoursDrawer } from './WorkingHoursDrawer'

import Button from 'components/common/Button/Button'
import Stack from '@mui/material/Stack'
import { WorkingHoursItem } from './WorkingHoursItem'
import styles from './WorkingHours.module.sass'
import { useTranslation } from 'react-i18next'

/**
 * @component User WorkingHours displayed in Profile page only for creatives
 * @example
 * <WorkingHours />
 */
export const WorkingHours: React.FC = () => {

  const { t } = useTranslation(['profile', 'actions'])
  const { openEditWorkingHoursDrawer, workingHours } = useWorkingHoursDrawer()

  return (
    <Fragment>

      <div className={styles.workingHours}>

        {/** HEADER WITH EDIT BUTTON */}
        <div className={styles.headerWithButton}>

          <h3 className={styles.header}>
            {t('work_preferences.working_hours.title')}
          </h3>

          <Button
            type='secondary nobackground noborder'
            onClick={openEditWorkingHoursDrawer}
          >
            {t('actions:Edit')}
          </Button>

        </div>

        {/** LIST OF WORKING HOURS BY WEEKDAY */}
        <Stack direction="column" spacing={2}>

          {workingHours &&
            Object.entries(workingHours).map(([dayKey, timeSlots]) => (

              <WorkingHoursItem
                key={`dayOfWeek-${dayKey}`}
                dayKey={dayKey}
                timeSlots={timeSlots}
              />

            ))
          }

        </Stack>

      </div>

      <WorkingHoursDrawer />

    </Fragment>
  )
}
