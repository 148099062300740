import React, { Fragment, useMemo } from 'react'

import { Nullable } from 'models/helpers'
import Stack from '@mui/material/Stack'
import classnames from 'classnames'
import i18n from 'translations/i18n'
import { isISOString } from 'utils/time'
import moment from 'moment'
import styles from './DatesRange.module.sass'
import { useTranslation } from 'react-i18next'

/**
 * Information of the range date
 * @interface RangeItemDate
 */
interface RangeItemDate {
  date: string
  year: string
  time: string
  isValid: boolean
}


/** Helper function that serializes received date into dateItems */
const _getDateItem = (date: Nullable<string>, timezone?: Nullable<string>): RangeItemDate => {

  const dateItem: RangeItemDate = {
    date: '--/--',
    year: '----',
    time: '--:--',
    isValid: false,
  }

  if (!date) return dateItem

  if (!isISOString(date)) {
    dateItem.date = 'Invalid format'
    return dateItem
  }

  const momentDate = moment(date).locale(i18n.language)

  if (!!timezone) momentDate.tz(timezone)

  dateItem.date = momentDate.format('dddd DD/MM')
  dateItem.year = momentDate.year().toString()
  dateItem.time = momentDate.format('HH:mm')
  dateItem.isValid = true

  return dateItem
}

/** 
 * @interface Props Input properties
 */
interface Props {
  /** Starting date to be formatted and displayed */
  from: string
  /** Ending date to be formatted and displayed */
  to?: string
  /** Timezone to be used to convert the dates */
  timezone?: Nullable<string>
}

/**
 * @component DatesRange displays two ranged dates formatted and styled
 * @example
 * <DatesRange from="2023-04-19T10:00:30Z" to="2023-05-19T10:00:30Z" />
 */
export const DatesRange: React.FC<Props> = ({ from, to, timezone }) => {

  const { t } = useTranslation(['profile'])

  // Starting and ending dates information including the formatted dates with error messages if found
  const itemDates = useMemo(() => ({
    from: _getDateItem(from, timezone),
    to: _getDateItem(to, timezone),
  }), [from, timezone, to])

  const showYear = useMemo(() => {
    if (!itemDates.from.isValid || !itemDates.to.isValid) return false
    return itemDates.from.year !== itemDates.to.year
  }, [itemDates.from.isValid, itemDates.from.year, itemDates.to.isValid, itemDates.to.year])

  if (!itemDates) return <Fragment />

  return (
    <Stack
      gap={2}
      width="100%"
      alignItems={{
        xs: 'flex-start',
        sm: 'center',
      }}
      justifyContent="space-between"
      direction={{ xs: 'column', sm: 'row' }}
    >

      {/** STARTING DATE */}
      <div className={
        classnames(
          styles.dateWrapper,
          { [styles.error]: !itemDates.from.isValid }
        )
      }>
        <p>{itemDates.from.date}{showYear && `/${itemDates.from.year}`}</p>
        <span>{itemDates.from.time}</span>
      </div>

      {/** TO */}
      <span>{t('work_preferences.unavailabilities.to_text')}</span>

      {/** ENDING DATE */}
      <div className={
        classnames(
          styles.dateWrapper,
          styles.endDate,
          { [styles.error]: !itemDates.to.isValid }
        )
      }>
        <p>{itemDates.to.date}{showYear && `/${itemDates.to.year}`}</p>
        <span>{itemDates.to.time}</span>
      </div>

    </Stack>
  )
}
