if (!process.env.REACT_APP_AUTH0_DOMAIN) throw new Error('AUTH0_DOMAIN env variable is missing')
if (!process.env.REACT_APP_AUTH0_CLIENT_ID) throw new Error('AUTH0_CLIENT_ID env variable is missing')
if (!process.env.REACT_APP_AUTH0_AUDIENCE) throw new Error('AUTH0_AUDIENCE env variable is missing')

/** API configuration */
const Auth0Config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
}

export default Auth0Config