import Collapse from '@mui/material/Collapse'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { EditorOption } from '../EditorOption'
import { EditorOptionButton } from '../EditorOptionButton'
import { MUIDivider } from 'components/common/MUIDivider'
import React from 'react'
import classnames from 'classnames'
import { patternList } from 'constants/misc'
import styles from './EditorOptionRatio.module.sass'
import { useTranslation } from 'react-i18next'
import { useVisualsEditing } from '../../VisualsEditing.context'

/**
 * @component EditorOptionRatio 
 * @example
 * <EditorOptionRatio />
 */

export const EditorOptionRatio: React.FC = () => {
  const { t } = useTranslation(['visuals_editing'])
  const {
    productParams,
    isRatioActive,
    selectedRatioPattern,
    setIsRatioActive,
    setSelectedRatioPattern,
    logEditEvent,
    handleEditingOptionChange
  } = useVisualsEditing()

  const resetRatioSetting = () => {
    setSelectedRatioPattern(patternList[0])
    setIsRatioActive(false)
  }

  // ORIGINAL pattern is only default value for us, we don't want to show it in UI Ratio selection
  const ratioPatternsGrid = patternList.filter((pattern) => pattern.value !== 'ORIGINAL')

  return (
    <EditorOption
      title={t('editor_options.ratio_option_title')}
      data-tip
      data-for="visual_editing_ratio_description"
      tooltipData={t('editor_options.ratio_description')}
      isActive={isRatioActive}
      onResetClick={resetRatioSetting}
      button={
        <EditorOptionButton
          title={t('editor_options.edit')}
          icon={<EditOutlinedIcon fontSize='inherit' />}
          isActive={isRatioActive}
          onClick={() => {
            logEditEvent('Start ratio bulk editing', productParams)
            handleEditingOptionChange(() => setIsRatioActive(true))
          }}
        />
      }
    >
      <Collapse in={isRatioActive}>
        <div className={styles.editorOptionRatio}>
          <div className={styles.ratioPatternWrap}>
            {ratioPatternsGrid.map((pattern, index) =>
              <div
                key={index}
                className={classnames(styles.ratioPattern, selectedRatioPattern.title === pattern.title && styles.selected)}
                onClick={() => handleEditingOptionChange(() => setSelectedRatioPattern(pattern))}
              >
                <div className={styles.ratioSize} style={{ aspectRatio: pattern.ratio }}></div>
                <div className={styles.ratioTitle}>
                  {pattern.title}
                  {pattern.description && <span>{t(`editor_options.${pattern.description}`)}</span>}
                </div>
              </div>
            )}
          </div>
        </div>
      </Collapse>
      <MUIDivider margin={16} />
    </EditorOption>
  )
}
