const ADMIN_PATH = '/admin'
const API_PATH = '/API'
const ASSIGNMENT_PATH = '/assignment'
const EMAIL_VERIFICATION_PATH = '/email-verification'
const EMAIL_VERIFIED_PATH = '/email-verified'
const GALLERY_PATH = '/gallery'
const LOGIN_PATH = '/login'
const PAYMENT_STATUS_PATH = '/payment-status'
const PRICING_PATH = '/pricing'
const PROFILE_PATH = '/profile'
const PURCHASE_FLOW_PATH = '/purchase-flow'
const SIGNUP_PATH = '/signup'
const SIGNUP_PORTAL_PATH = '/signup-portal'
const SUBSCRIPTION_PATH = '/subscription'
const SUBSCRIPTION_STATUS_PATH = '/subscription-status'
const TERMS_AND_PRIVACY_PATH = '/terms-and-privacy'
const WORKSPACES_PROFILE_PATH = '/workspaces-profile'

/** Contains all available router paths */
export const Path = {
  INDEX: '/',
  TERMS_AND_PRIVACY: `${TERMS_AND_PRIVACY_PATH}`,
  PROFILE: `${PROFILE_PATH}`,
  LOGIN: `${LOGIN_PATH}`,
  SIGNUP: `${SIGNUP_PATH}`,
  SIGNUP_PORTAL: `${SIGNUP_PORTAL_PATH}`,
  GALLERY: `${GALLERY_PATH}/:id`,
  GALLERY_INFO: `${GALLERY_PATH}/:id/:tab`,
  GALLERY_NO_SLUG: `${GALLERY_PATH}`,
  GALLERY_STAGING: `${GALLERY_PATH}/:id/staging`,
  GALLERY_VISUALS_EDIT: `${GALLERY_PATH}/:id/edit`,
  GALLERY_VISUALS_MARKETING: `${GALLERY_PATH}/:id/marketing`,
  EMAIL_VERIFIED: `${EMAIL_VERIFIED_PATH}`,
  EMAIL_VERIFICATION: `${EMAIL_VERIFICATION_PATH}`,
  PAYMENT_STATUS: `${PAYMENT_STATUS_PATH}`,
  SUBSCRIPTION_STATUS: `${SUBSCRIPTION_STATUS_PATH}`,
  ADMIN_CLIENT_ONBOARDING: `${ADMIN_PATH}/client-onboarding`,
  ADMIN_CREATIVE_ONBOARDING: `${ADMIN_PATH}/creative-onboarding`,
  AVAILABLE_ASSIGNMENT: `${ASSIGNMENT_PATH}/:id`,
  SUBSCRIPTION: `${SUBSCRIPTION_PATH}`,
  SUBSCRIPTION_CREATION: `${SUBSCRIPTION_PATH}/plan-creation`,
  SUBSCRIPTION_MEMBERS: `${SUBSCRIPTION_PATH}/add-members`,
  SUBSCRIPTION_VALIDATION: `${SUBSCRIPTION_PATH}/validation`,
  API: `${API_PATH}`,
  PRICING: `${PRICING_PATH}`,
  WORKSPACES_PROFILE: `${WORKSPACES_PROFILE_PATH}/:id`,
  PURCHASE_FLOW: `${PURCHASE_FLOW_PATH}`,
} as const

export type PathType = typeof Path
export type PathTypeValue = PathType[keyof PathType]

/** Array of paths that use new design language */
export const NEW_DESIGN_PATHS: Array<PathTypeValue> = [
  Path.GALLERY_VISUALS_MARKETING,
]

/** Array of paths that don't use header */
export const NO_HEADER_PATHS: Array<PathTypeValue> = [
  Path.GALLERY_VISUALS_EDIT,
  Path.GALLERY_VISUALS_MARKETING,
  Path.GALLERY_STAGING,
  Path.SIGNUP_PORTAL,
  Path.PURCHASE_FLOW,
]
