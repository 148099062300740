import { DocumentCategory, EditingCategory } from 'constants/product'
import { EditingProductListing, ProductListing } from '../../../common'
import { isEditingCategory, isShootingCategory } from 'utils/validators'

import { Box } from '@mui/material'
import { EmptyProductList } from 'components/pages/PurchaseFlow/common/ProductListing/EmptyProductList'
import { EnergyProductListing } from 'components/pages/PurchaseFlow/common/EnergyProductListing/EnergyProductListing.component'
import { ProductCategory } from 'models/product'
import { ProductFilter } from '../ProductFilters'
import Stack from '@mui/material/Stack'
import { StepWrapper } from 'components/pages/PurchaseFlow/common/StepWrapper'
import TransitionAppear from 'components/common/TransitionAppear/TransitionAppear'
import { TransitionGroup } from 'react-transition-group'
import { logAnalyticsEvent } from 'utils/analytics'
import { useAuth0 } from 'utils/auth'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { usePurchaseFlowConfig } from 'components/pages/PurchaseFlow/_main/contexts'
import { usePurchaseFlowProducts } from 'components/pages/PurchaseFlow/_main/contexts/PurchaseFlowProducts.context'
import { useTargetOrderUser } from '../../../_main/contexts'
import { useTranslation } from 'react-i18next'

/**
 * @component
 * ProductStepController represents the controller for the product selection step in the purchase flow.
 *
 * @example
 * <ProductStepController />
 */
export const ProductStepController: React.FC = () => {
  const dispatch = useDispatch()
  const { roles } = useAuth0()
  const { targetUser, adminSelectedUserEmail } = useTargetOrderUser()
  const { selectedSegment } = usePurchaseFlowProducts()
  const {
    sessionId,
    selectedCity,
    getProductCatalogue,
    selectedCategory,
    selectedCountryCode,
    getExtraProducts,
    regionCode,
    postalCode,
  } = usePurchaseFlowConfig()

  const { t } = useTranslation(['order', 'segment', 'segment_title', 'product_type'])

  // Log entering this page
  useEffect(() => {
    logAnalyticsEvent('enters_order_products_selection_screen', {
      category: selectedCategory,
      countryCode: selectedCountryCode,
      city: selectedCity
    })
  }, [selectedCategory, selectedCountryCode, selectedCity])

  /** Retrieves the configuration from the API  */
  useEffect(() => {
    if (!getProductCatalogue.isIdle) return
    if (!getExtraProducts.isIdle) return
    if (!selectedCategory || !sessionId) return
    if (roles.isAdmin && !adminSelectedUserEmail) return
    if (isShootingCategory(selectedCategory) && (!selectedCity || !selectedCountryCode)) return

    getProductCatalogue.mutate({
      sessionId,
      email: targetUser.email,
      category: selectedCategory,
      countryCode: selectedCountryCode || targetUser.country,
      city: selectedCity,
      regionCode,
      postalCode,
    })

    getExtraProducts.mutate({
      sessionId,
      email: targetUser.email,
      category: selectedCategory,
      countryCode: new Set<ProductCategory>([EditingCategory.EDITING, EditingCategory.STAGING]).has(selectedCategory) || !selectedCountryCode
        ? targetUser.country
        : selectedCountryCode,
      regionCode,
      postalCode,
    })

  }, [dispatch, selectedCategory, sessionId, adminSelectedUserEmail, roles, targetUser, getProductCatalogue, selectedCity, selectedCountryCode, getExtraProducts, regionCode, postalCode])

  return (
    <TransitionAppear visible>
      <StepWrapper
        title={t('step_product.title')}
        subtitle={t('step_product.text', { segment: t(`segment_title:${selectedCategory}`).toLocaleLowerCase() })}
      >
        <Stack
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          gap={4}
        >

          {/** LEFT CONTENT */}
          {selectedCategory !== DocumentCategory.ENERGY_CERTIFICATE &&
            <Box flex="1 0 300px">
              <ProductFilter />
            </Box>
          }

          {/** MAIN CONTENT */}
          <Stack flex="1 1 75%">
            <TransitionGroup component={null}>

              {getProductCatalogue.isSuccess &&
                <EmptyProductList />
              }

              {!!selectedCategory && isEditingCategory(selectedCategory)
                ? (
                  <EditingProductListing
                    disabled={!selectedSegment}
                  />
                )
                : (
                  selectedCategory === DocumentCategory.ENERGY_CERTIFICATE
                    ? <EnergyProductListing />
                    : <ProductListing disabled={!selectedSegment} />
                )
              }

            </TransitionGroup>
          </Stack>

        </Stack>

      </StepWrapper>
    </TransitionAppear>
  )
}
