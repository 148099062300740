import { BLUE_500, BOX_SHADOW_MD } from 'constants/styling/theme'

import { Box } from '@mui/material'
import { ComputedStagingRoom } from 'models/virtualStaging'
import { MUIBadge } from 'components/common/MUIBadge'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import { VirtualFurnitureRoomType } from 'constants/virtualFurniture'
import { useMemo } from 'react'
import { useStyleSelection } from '../main/contexts'
import { useTranslation } from 'react-i18next'

const MIN_IMAGE_WIDTH = 250
const IMAGE_HEIGHT = 150

/** Enumeration representing the possible status of a room. */
enum RoomStatus {
  NOT_SET = 'NOT_SET'
}

/** @interface Room represents a room with details. */
interface Room extends ComputedStagingRoom {
  /** The type or status of the room. */
  types: VirtualFurnitureRoomType[]
  /** The image details of the room. */
  image: {
    /** The unique identifier of the image. */
    id: string
    /** The URL of the image. */
    url?: string
  }
  /** Indicates whether the room is currently active. */
  isActive: boolean
}

/** 
 * @component
 * RoomSelection displays a set of rooms to select, each with its status or type and the uploaded image.
 * 
 * @example
 * <RoomSelection />
 */
export const RoomSelection = () => {
  const {
    allRooms,
    activeRoomIndex,
    setActiveRoomIndex,
  } = useStyleSelection()

  const { t } = useTranslation(['virtual_furniture_room_type', 'style_selection_order_step', 'common'])

  const totalRooms = useMemo(() => allRooms.length, [allRooms])

  const completedRooms = useMemo(() => allRooms.reduce((count, room) => {
    if (room.isComplete) return ++count
    else return count
  }, 0), [allRooms])

  const roomItems: Room[] = useMemo(() => {
    return allRooms.map((room, index) => ({
      image: room.images[0],
      types: room.roomTypes.toArray(),
      isActive: activeRoomIndex === index,
      ...room
    }))
  }, [activeRoomIndex, allRooms])

  return (
    <SectionedBorderBox
      height="100%"
      title={`${t('style_selection_order_step:rooms_stage')} ${completedRooms}/${totalRooms}`}
    >
      <Stack gap={2} width="100%" alignItems="center">
        {roomItems.map(((room, index) =>
          <Box
            key={room.image.id}
            onClick={() => setActiveRoomIndex(index)}
            sx={{
              cursor: 'pointer',
              borderRadius: '1rem',
              position: 'relative',
              minWidth: MIN_IMAGE_WIDTH,
              ':hover': {
                boxShadow: BOX_SHADOW_MD,
              }
            }}
          >

            <img
              alt={room.image.id}
              src={room.image.url}
              style={{
                minWidth: MIN_IMAGE_WIDTH,
                height: IMAGE_HEIGHT,
                display: 'block',
                contain: 'content',
                borderRadius: '1rem',
                boxSizing: 'border-box',
                border: '4px solid transparent',
                borderColor: room.isActive
                  ? BLUE_500
                  : 'transparent',
              }}
            />

            <Box position="absolute" top="1rem" right="1rem">
              <MUIBadge
                label={
                  room.types.length
                    ? room.types.map(type => t(type)).join(', ')
                    : t(`style_selection_order_step:status:${RoomStatus.NOT_SET}`)
                }
                color='gray'
                sx={{
                  maxWidth: MIN_IMAGE_WIDTH - 20,
                  textOverflow: 'ellipsis',
                }}
              />
            </Box>

          </Box>
        ))}
      </Stack>
    </SectionedBorderBox>
  )
}
