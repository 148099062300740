import { PurchaseFlowStepKey, usePurchaseFlowUtilities } from '../../../_main/contexts'

import { CategoryStepController } from './CategoryStep.controller'
import { DynamicFlowStep } from 'components/common/DynamicFlow'
import { FC } from 'react'

export const CategoryStep: FC = () => {

  const { isCategoryStepValid } = usePurchaseFlowUtilities()

  return (
    <DynamicFlowStep
      stepKey={PurchaseFlowStepKey.CATEGORY}
      isValid={isCategoryStepValid}
    >
      <CategoryStepController />
    </DynamicFlowStep>
  )
}
