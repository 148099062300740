import 'intl-tel-input/build/css/intlTelInput.css'

import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react'

import iti from 'intl-tel-input'
import { normalizePhoneNumber } from 'utils/formatting/normalizePhoneNumber'
import { useTranslation } from 'react-i18next'
import utilsScript from 'intl-tel-input/build/js/utils.js'

/**
 * @interface Props Input properties
 */
export interface Props extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onChange'> {
  /** The additional classes to append */
  className?: string
  /** intl-tel-input options object */
  options?: iti.Options
  /** Handler for value change event */
  onChange: (value: string) => void
  /** Optional event handler for phone validity updates */
  onValidityChange?: (isValid: boolean, errorMessage: string | undefined) => void
  /** Set the initial country */
  initialCountry: string
}

/**
 * @component   
 * Phone number input that auto-formats text and handles country code displaying.
 * Reworked clone of original TelephoneInput fixing reactivity issues for controlled values.
 * 
 * @example
 * <PhoneInput
 *  value={variable}
 *  onChange={(formattedValue) => console.log(`formattedValue: ${formattedValue}`)}
 *  onValidityChange={({ isValid, errorMessage }) => setIsValid(isValid)}
 * />
 */
export const PhoneInput: React.FC<Props> = (props) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const itiPlugin = useRef<iti.Plugin | undefined>(undefined)
  const { t } = useTranslation('telephone_input')

  const _getCountrifiedNumber = useCallback((bareNumber: string) => {
    return intlTelInputUtils.formatNumber(
      bareNumber,
      itiPlugin.current?.getSelectedCountryData().iso2 || props.initialCountry,
      intlTelInputUtils.numberFormat.E164
    )
  }, [props.initialCountry])

  useLayoutEffect(() => {
    if (!inputRef.current) return
    if (itiPlugin.current) return
    itiPlugin.current = iti(inputRef.current, {
      utilsScript,
      initialCountry: props.initialCountry,
    })
  }, [inputRef, props.initialCountry])

  // Propagate country change from props
  useLayoutEffect(() => {
    if (!itiPlugin.current) return
    if (!props.initialCountry) return
    itiPlugin.current.setCountry(props.initialCountry)
  }, [props.initialCountry])

  // Propagate outer state to intlPlugin to display and format number and relay it to the input value property
  useEffect(() => {
    itiPlugin.current?.setNumber(normalizePhoneNumber(props.value?.toString() || ''))
  }, [props.value])

  const numberValidation = useMemo(() => {
    const initValidations: {
      isValid: boolean,
      errorCode: number,
      errorMessage: string | undefined
    } = {
      isValid: true,
      errorCode: 0,
      errorMessage: undefined,
    }

    if (!itiPlugin.current) return initValidations

    const normalizedNumber = normalizePhoneNumber(props.value?.toString() || '')
    itiPlugin.current?.setNumber(normalizedNumber || '')

    initValidations.isValid = itiPlugin.current.isValidNumber()
    initValidations.errorCode = itiPlugin.current.getValidationError()
    initValidations.errorMessage = t(`errors.${initValidations.errorCode}`, t(`errors.${4}`, '')) || undefined

    return initValidations
  }, [props.value, t])

  useEffect(() => {
    props.onValidityChange?.(numberValidation.isValid, numberValidation.errorMessage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberValidation.isValid, numberValidation.errorMessage])

  useEffect(() => {
    // Any, because otherwise it complaints about unknown custom event - not sure how to extend it without extending all inputs' events
    const handler = (e: any) => {
      props.onChange(_getCountrifiedNumber(e.target?.value))
    }

    const telInput = inputRef.current
    if (!telInput) return

    telInput.addEventListener('countrychange', handler)
    return () => {
      telInput.removeEventListener('countrychange', handler)
    }
  }, [_getCountrifiedNumber, props])

  const { options, value, initialCountry, onValidityChange, ...passedProps } = { ...props }

  return (
    <input
      {...passedProps}
      type={props.type || 'tel'}
      className={props.className}
      ref={inputRef}
      onChange={(e) => {
        props.onChange(_getCountrifiedNumber(e.target.value))
      }}
      onFocus={(e) => props.onFocus?.(e)}
      onBlur={(e) => {
        e.target.value = e.target.value.replace(/\s+/gmi, ' ').trim()
        props.onBlur?.(e)
      }}
    />
  )
}
