import { EntityKeys, QueryType, getMutation } from 'utils/reactQuery'

import { CreativeUnavailabilityDTO } from 'models/creative'
import { trackIntercomEvent } from 'utils/analytics'
import { useAPI } from 'utils/API'
import { useQuery } from '@tanstack/react-query'

type CTUnavailabilityPayload = {
  id: string,
  from: moment.Moment,
  to: moment.Moment,
  reason?: string,
  creativeId: string,
}

enum Endpoints {
  ADD = '/user/me/unavailabilities',
  DELETE = '/user/me/unavailabilities/{unavailabilityId}',
  UPDATE = '/user/me/unavailabilities/{unavailabilityId}',
  CHECK_CONFLICTS = '/user/me/validateUnavailability',
  LIST_MINE = '/user/me/unavailabilities',
}

// QUERIES

export function useLoggedInCTUnavailabilityList() {
  const api = useAPI<Endpoints>()

  return useQuery<CreativeUnavailabilityDTO[], Error>({
    queryKey: [EntityKeys.UNAVAILABILITY, QueryType.LIST_MINE],
    queryFn: () => api.get<CreativeUnavailabilityDTO[]>(
      Endpoints.LIST_MINE,
      {},
      false
    )
  })
}

/**
 * Disabled query => must be manually triggered by calling *refetch*   
 * For now marked with underscore suffix *_*
*/
export function useCheckCTUnavailabilityConflicts_(from: moment.Moment, to: moment.Moment) {
  const api = useAPI<Endpoints>()

  return useQuery<CreativeUnavailabilityDTO[]>({
    queryKey: [EntityKeys.UNAVAILABILITY, 'CHECK_CONFLICTS'],
    queryFn: () => api.post(
      Endpoints.CHECK_CONFLICTS,
      {},
      {
        from: from.toISOString(true),
        to: to.toISOString(true),
      },
      false
    ),
    // Makes query not run automatically
    enabled: false
  })
}

// MUTATIONS

export function useCreateCTUnavailability() {
  const api = useAPI<Endpoints>()

  return getMutation<CreativeUnavailabilityDTO, Omit<CTUnavailabilityPayload, 'id'>>(
    ({ from, to }) => api.post(
      Endpoints.ADD,
      {},
      {
        from: from.toISOString(true),
        to: to.toISOString(true),
      },
      false
    ),
    (client, { creativeId, from, to }) => {
      client.invalidateQueries({ queryKey: [EntityKeys.UNAVAILABILITY, QueryType.LIST_MINE] })
      trackIntercomEvent('creative_unavailability_created', {
        startDateAndTime: from.toISOString(true),
        endDateAndTime: to.toISOString(true),
        creativeId,
      })
    }
  )
}

export function useUpdateCTUnavailability() {
  const api = useAPI<Endpoints>()
  return getMutation<CreativeUnavailabilityDTO[], Omit<CTUnavailabilityPayload, 'creativeId'>>(
    ({ id, from, to }) => api.put(
      Endpoints.UPDATE,
      { unavailabilityId: id },
      {
        from: from.toISOString(true),
        to: to.toISOString(true),
      },
      false
    ),
    (client) => {
      client.invalidateQueries({ queryKey: [EntityKeys.UNAVAILABILITY, QueryType.LIST_MINE] })
    }
  )
}

export function useRemoveCTUnavailability() {
  const api = useAPI<Endpoints>()

  return getMutation<unknown, string>(
    (id) => api.delete(
      Endpoints.DELETE,
      { unavailabilityId: id },
      false
    ),
    (client) => {
      client.invalidateQueries({ queryKey: [EntityKeys.UNAVAILABILITY, QueryType.LIST_MINE] })
    }
  )
}
