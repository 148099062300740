import { FC, Fragment, ReactNode, createContext, useContext, useEffect, useMemo, useRef } from 'react'
import { STRIPE_PRIMARY_PRODUCT_KINDS, StripeProductKindToPaymentMethodMap } from 'constants/payments'
import { useProductAndInstructionLists, usePurchaseFlowConfig, usePurchaseFlowOrderMeta } from 'components/pages/PurchaseFlow/_main/contexts'

import { Currency } from 'constants/misc'
import DynamicQueryContent from 'components/common/DynamicQueryContent/DynamicQueryContent'
import { ProductKind } from 'constants/product'
import { StripePaymentContextProvider } from 'components/contexts/StripePaymentContext'
import { useAuth0 } from 'utils/auth'
import { useDispatch } from 'react-redux'

interface PurchaseFlowPaymentIntentContextInterface {
  clientSecret?: string
  isStripePaymentMethod: boolean
}

const defaultPurchaseFlowPaymentIntentContextValue: PurchaseFlowPaymentIntentContextInterface = {
  isStripePaymentMethod: false
}

/** payment intent provider context */
export const PurchaseFlowPaymentIntentContext = createContext<PurchaseFlowPaymentIntentContextInterface>(defaultPurchaseFlowPaymentIntentContextValue)
/** payment intent provider context hook */
export const usePurchaseFlowPaymentIntent = (): PurchaseFlowPaymentIntentContextInterface => useContext(PurchaseFlowPaymentIntentContext)

/** Context provider for payment intent provider values */
export const PurchaseFlowPaymentIntentContextProvider: FC<{
  children?: ReactNode
}> = ({
  children,
}) => {
    const dispatch = useDispatch()
    const { roles } = useAuth0()
    const {
      getProductQuantityList,
      getInstructionQuantityList,
    } = useProductAndInstructionLists()
    const {
      selectedPaymentMethod,
      isStripePaymentMethod,
      paymentIntentMutation,
    } = usePurchaseFlowOrderMeta()
    const { sessionId, catalogueCurrency } = usePurchaseFlowConfig()

    const paymentIntent = useMemo(() => paymentIntentMutation.data?.data.data, [paymentIntentMutation.data?.data.data])
    const paymentIntentWasRequested = useRef(false)
    const clientSecret = useMemo(() => paymentIntent?.clientSecret, [paymentIntent?.clientSecret])

    // Get payment intent on page load
    useEffect(() => {
      if (!selectedPaymentMethod) return
      if (!STRIPE_PRIMARY_PRODUCT_KINDS.has(selectedPaymentMethod)) return
      if (!sessionId) return
      if (!roles.isClient) return

      const stripeMethod = StripeProductKindToPaymentMethodMap.get(selectedPaymentMethod)
      if (!stripeMethod) return

      // (SEPA_DEBIT is only allowed for EUR currency)
      if (selectedPaymentMethod === ProductKind.SEPA_DIRECT_DEBIT && catalogueCurrency !== Currency.EUR) return

      // Already requested the payment intent
      if (paymentIntentWasRequested.current) return

      paymentIntentMutation.mutate({
        sessionId,
        products: [
          ...getProductQuantityList(),
          // filter out synthetic products (404 error from BE otherwise)
          ...getInstructionQuantityList().filter((product) => product.id < 9999000),
        ],
        paymentMethod: stripeMethod,
        // Either get a new one or update old payment intent
        isUpdate: !!paymentIntent,
      })

      // Mark payment intent was already requested
      paymentIntentWasRequested.current = true

    }, [dispatch, getProductQuantityList, getInstructionQuantityList, paymentIntent, selectedPaymentMethod, roles, sessionId, catalogueCurrency, paymentIntentMutation])

    return (
      <PurchaseFlowPaymentIntentContext.Provider
        value={{
          clientSecret,
          isStripePaymentMethod
        }}
      >
        <Fragment>
          {selectedPaymentMethod && STRIPE_PRIMARY_PRODUCT_KINDS.has(selectedPaymentMethod) ?
            <Fragment>
              {!roles.isClient && 'Admins are not allowed to place orders using stripe on behalf of clients!'}
              {!!paymentIntent &&
                <DynamicQueryContent query={paymentIntentMutation}>
                  {!!paymentIntent.clientSecret &&
                    <StripePaymentContextProvider clientSecret={paymentIntent.clientSecret}>
                      {children}
                    </StripePaymentContextProvider>
                  }
                </DynamicQueryContent>
              }
            </Fragment>
            :
            <Fragment>
              {children}
            </Fragment>
          }
        </Fragment>
      </PurchaseFlowPaymentIntentContext.Provider>
    )
  }
