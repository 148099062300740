import { PurchaseFlowStepKey, usePurchaseFlowUtilities } from '../../../_main/contexts'

import { BillingStepController } from './BillingStep.controller'
import { DynamicFlowStep } from 'components/common/DynamicFlow'
import { FC } from 'react'

export const BillingStep: FC = () => {

  const { isBillingStepValid } = usePurchaseFlowUtilities()

  return (
    <DynamicFlowStep
      stepKey={PurchaseFlowStepKey.BILLING}
      isValid={isBillingStepValid}
    >
      <BillingStepController />
    </DynamicFlowStep>
  )
}
