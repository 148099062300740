import { AssignmentDTO } from 'models/assignment'
import { AssignmentStage } from 'constants/assignment'
import { Auth0Roles } from 'models/auth'

/** A set containing all assignment stages considered as finished for all roles except creative */
const finishedAssignmentStages = new Set([
  AssignmentStage.VISUALS_SENT_TO_CLIENT,
])

/** A set containing all assignment stages considered as finished for creative */
const finishedCreativeAssignmentStages = new Set([
  AssignmentStage.VISUALS_SENT_BY_BKBN_TO_EDITOR,
  AssignmentStage.VISUALS_SENT_BY_EDITOR_TO_BKBN,
  AssignmentStage.VISUALS_SENT_TO_CLIENT,
])

/** A set containing all assignment stages considered as available for creative */
const availableCreativeAssignmentStages = new Set([
  AssignmentStage.MISSION_ORDER_PLACED,
  AssignmentStage.PRE_PRODUCTION,
  AssignmentStage.AUTOMATED,
  AssignmentStage.WAITING_FOR_CT_TO_ACCEPT,
])

/** Method which decides if assignment should be marked as finished */
export const isAssignmentFinished = (assignment: AssignmentDTO, roles: Auth0Roles) => {
  if (roles.isCreative) return finishedCreativeAssignmentStages.has(assignment.stage)
  else return finishedAssignmentStages.has(assignment.stage)
}

/** Method which decides if assignment is marked as available for creative to accept */
export const isAssignmentAvailable = (assignment: AssignmentDTO) => availableCreativeAssignmentStages.has(assignment.stage)
