import './GalleryImage.sass'

import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone'
import React, { FC, Fragment, ReactNode, useCallback, useMemo } from 'react'
import { VisualAdminDTO, VisualCreativeDTO } from 'models/visuals'

import Icon from '../Icon/Icon'
import { IconType } from 'constants/assets'
import { MUITooltip } from '../MUITooltip'
import { Nullable } from 'models/helpers'
import { VisualMetadataIsAdministratorResponse } from 'utils/typeguards'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** The additional classes to append */
  className?: string
  /** OnClick action to be called */
  onClick?: (e: React.MouseEvent) => void
  /** OnSelect action to be called */
  onSelect?: (e: React.MouseEvent) => void
  /** OnDelete action to be called */
  onDelete?: (e: React.MouseEvent) => void
  /** OnDrop action to be called */
  onDrop?: (acceptedFiles: File[], fileRejections: FileRejection[]) => void
  /** The image to display */
  image?: string
  /** Label at the top left corner of the image */
  label?: string
  /** Whether the gallery image is expandable */
  expandable?: boolean
  /** Whether the gallery image has a delete button */
  deletable?: boolean
  /** Whether the gallery image has a select button */
  selectable?: boolean
  /** Whether the gallery image is selected */
  selected?: boolean
  /** Whether the gallery image is a drop-zone */
  droppable?: boolean
  /** Whether the user is currently dragging a file */
  isUserDraggingFile?: boolean
  /** Options to be passed down to useDropzone hook */
  dropZoneOptions?: DropzoneOptions
  /** Whether the gallery image is faded (so for example upload progress is more visible) */
  faded?: boolean
  /** Whether the gallery image is in waiting state (cursor: wait) */
  wait?: boolean
  /** Whether the gallery image is purchased */
  purchased?: boolean
  /** Content to be displayed inside "hover" element */
  insideHover?: React.ReactNode
  /** Makes image interactive even without image while displaying "no preview available" info (default false) */
  ignoreMissingImage?: boolean
  /** Metadata of a visual */
  visualMetadata?: Nullable<VisualAdminDTO | VisualCreativeDTO>
  children?: ReactNode
  /** Callback for clicking on the floor planner link */
  onFloorPlannerLinkClick?: (e: React.MouseEvent) => void
}

/**
 * @component Display additional information in a styled orange box
 * @example
 * <GalleryImage onClick={action('clicked an image')} image="https://picsum.photos/300/200?random=5" label="14" selectable={true} onSelect={action('clicked select/unselect')} selected={true} />
 * <GalleryImage onClick={action('clicked an image')} label="14" deletable={true} onDelete={action('clicked delete')}>
 *  <span>Uploading... 73% done</span>
 * </GalleryImage>
 */
const GalleryImage: FC<Props> = ({
  className = '',
  onClick,
  onSelect,
  onDelete,
  onDrop,
  image,
  label,
  expandable = false,
  deletable = false,
  selectable = false,
  selected = false,
  droppable = false,
  isUserDraggingFile = false,
  dropZoneOptions = { noClick: true, multiple: false },
  faded = false,
  wait = false,
  purchased = false,
  insideHover = null,
  ignoreMissingImage = false,
  visualMetadata,
  children,
  onFloorPlannerLinkClick,
}) => {

  const { t } = useTranslation(['gallery', 'gallery_image'])

  let style: React.CSSProperties = {}

  const onDropHandler = useCallback(async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (onDrop) onDrop(acceptedFiles, fileRejections)
  }, [onDrop])

  if (image) {
    className += ' withimage'
    style.backgroundImage = `url('${image || ''}')`
  } else if (ignoreMissingImage) {
    className += ' withimage'
  }

  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    ...dropZoneOptions,
    onDrop: onDropHandler,
  })
  const indicateDragging = isDragActive || isUserDraggingFile

  if (deletable) className += ' deletable'
  if (selectable) className += ' selectable'
  if (selected) className += ' selected'
  if (faded) className += ' faded'
  if (wait) className += ' wait'
  if (purchased) className += ' purchased'
  if (droppable) className += ' droppable'
  if (indicateDragging) className += ' dragging'
  if (isDragActive) className += ' dragging-over'

  const hoverProps = droppable ? { ...getRootProps() } : {}

  const floorPlannerLink = useMemo(() => {
    if (!visualMetadata || !VisualMetadataIsAdministratorResponse(visualMetadata)) return

    return visualMetadata.floorPlanServiceEditorUrl
  }, [visualMetadata])

  return (
    <div className={`galleryimage ${className}`.replace(/\s+/igm, ' ').trim()} style={style}>
      <div className="hover" {...hoverProps} onClick={e => onSelect?.(e)}>
        {insideHover}
        {!insideHover && !image && ignoreMissingImage &&
          <span className="noimageinfo">
            {t('no_preview')}
          </span>
        }
      </div>

      {children}

      {label &&
        <span className="label">{label}</span>
      }

      {selectable &&
        <span className={`check circular large select ${selected ? 'checked' : ''}`.replace(/\s+/igm, ' ').trim()} onClick={e => onSelect && onSelect(e)}><Icon icon={IconType.CHECK} /></span>
      }

      {deletable &&
        <span className="check circular large withicon delete" onClick={e => onDelete?.(e)}>
          <MUITooltip placement='top' content={t('remove')}>
            <Icon icon={IconType.TRASH} data-tip data-for="remove-tooltip" />
          </MUITooltip>
        </span>
      }

      {image && !!floorPlannerLink &&
        <a
          className="check circular large withicon floorplanner"
          href={floorPlannerLink}
          title={t('edit_floor_plan')}
          target="_blank"
          rel="noreferrer"
          onClick={e => onFloorPlannerLinkClick?.(e)}
        >
          <Icon icon={IconType.EDIT} />
        </a>
      }

      {image && expandable && onClick &&
        <span className="check circular large withicon expand" onClick={e => onClick?.(e)}><Icon icon={IconType.EYE} /></span>
      }

      {droppable &&
        <Fragment>
          <span className="check circular large withicon replace" onClick={e => inputRef.current?.click()}>
            <MUITooltip placement='top' content={t('re_upload')}>
              <Icon icon={IconType.REFRESH} data-tip data-for="re-upload-tooltip" />
            </MUITooltip>
          </span>
          <input {...getInputProps()} />
        </Fragment>
      }
    </div>
  )
}

export default GalleryImage
