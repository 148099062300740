import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { ActionAutomateOrder } from './automateOrder.actions'
import { AutomateOrderStore } from './automateOrder.interfaces'
import { cloneDeep } from 'lodash'

/** Redux data reducer for automate order */
export const reduceAutomateOrder = (state: AutomateOrderStore, action: ActionAutomateOrder) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.AUTOMATE_ORDER) return state

  const { request, orderId } = action.payload
  const newState = cloneDeep(state)

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
    case ActionTypeAPIEvent.FETCH:
    case ActionTypeAPIEvent.RECEIVED:
      newState[orderId] = request
      return newState
  }
}
