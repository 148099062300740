import { CreativeWorkingHours, CreativeWorkingHoursDTO } from 'models/creative'
import { Reducer, useCallback, useReducer } from 'react'

import constate from 'constate'
import { uniqueId } from 'lodash'
import { Nullable } from 'models/helpers'

// TYPES
/**
 * Represents a working hour slot with error and hours properties.
 */
interface WorkingHourSlot {
  error: boolean
  hours: CreativeWorkingHours
}

/**
 * Represents an inventory item with error and slots properties.
 */
interface InventoryItem {
  error: Nullable<string>
  slots: Record<string, WorkingHourSlot>
}

/**
 * Enumerates the possible error types for working hours.
 */
export enum WorkingHoursError {
  INVALID_TIMES = 'INVALID_TIMES',
  SLOT_COLLISIONS = 'SLOT_COLLISIONS',
}

/**
 * Represents a working hours inventory as a record of inventory items.
 */
type WorkingHoursInventory = Record<string, InventoryItem>

/**
 * Enumerates the possible action types for working hours reducer.
 */
enum WorkingHoursActionType {
  ADD_SLOT = 'ADD_SLOT',
  ADD_DAY = 'ADD_DAY',
  REMOVE_SLOT = 'REMOVE_SLOT',
  EDIT_SLOT = 'EDIT_SLOT',
  RESET = 'RESET',
}

// ACTIONS

/**
 * Represents a working hours action with a type property.
 */
interface WorkingHoursAction {
  type: WorkingHoursActionType
}

/**
 * Represents an "Add Day" action with the dayKey and slots properties.
 */
class AddDayAction implements WorkingHoursAction {
  readonly type = WorkingHoursActionType.ADD_DAY

  constructor(public dayKey: string, public slots: InventoryItem['slots']) { }
}

/**
 * Represents an "Add Slot" action with the dayKey and data properties.
 */
class AddSlotAction implements WorkingHoursAction {
  readonly type = WorkingHoursActionType.ADD_SLOT

  constructor(public dayKey: string, public data: WorkingHourSlot) { }
}

/**
 * Represents a "Remove Slot" action with the dayKey and slotId properties.
 */
class RemoveSlotAction implements WorkingHoursAction {
  readonly type = WorkingHoursActionType.REMOVE_SLOT

  constructor(public dayKey: string, public slotId: string) { }
}

/**
 * Represents an "Edit Slot" action with the dayKey, slotId, and data properties.
 */
class EditSlotAction implements WorkingHoursAction {
  readonly type = WorkingHoursActionType.EDIT_SLOT

  constructor(public dayKey: string, public slotId: string, public data: Partial<CreativeWorkingHours>) { }
}

/**
 * Represents a "Reset Slots" action.
 */
class ResetSlotsAction implements WorkingHoursAction {
  readonly type = WorkingHoursActionType.RESET
}

/**
 * Represents the available actions for working hours reducer.
 */
type Actions = AddDayAction | AddSlotAction | RemoveSlotAction | EditSlotAction | ResetSlotsAction


// TIME UTILS
const DEFAULT_FROM = '06:00'
const DEFAULT_TO = '20:00'

/**
 * Removes the seconds from a time string.
 *
 * @param time - The time string.
 * @returns The time string without seconds.
 */
const _stripSecondsFromTime = (time: string) => time.split(':').slice(0, 2).join(':')

/**
 * Checks if a string is in the format HH:MM.
 *
 * @param string - The string to check.
 * @returns `true` if the string is in the format HH:MM, `false` otherwise.
 */
const _isHH_MM = (string: string) => /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(string)

/**
 * Coerces a time string to the HH:MM format.
 *
 * @param timeString - The time string to coerce.
 * @returns The coerced time string in the HH:MM format, or an empty string if the coercion fails.
 */
const _coerceTimeString = (timeString: string) => {
  const coercedString = _stripSecondsFromTime(timeString)

  if (!_isHH_MM(coercedString)) return ''

  return coercedString
}

/**
 * Converts a time string to a numerical representation.
 *
 * @param time - The time string.
 * @returns The numerical representation of the time, or `false` if the conversion fails.
 */
const _getNumericalTime = (time: string) => {
  if (!_isHH_MM(time)) return false

  return Number(time.replaceAll(':', ''))
}

// SLOT UTILS
/**
 * Generates a unique ID for a working hour slot.
 *
 * @returns The generated unique ID.
 */
const _generateSlotId = () => uniqueId('working-hours-slot-')

/**
 * Checks if there are any collisions between time slots.
 * 
 * @param timeSlots - An array of working hour slots to check for collisions.
 * @returns A boolean indicating whether there are any collisions between the time slots.
 */
const _areSlotsColliding = (timeSlots: WorkingHourSlot[]) => {
  if (timeSlots.length <= 1) {
    return false
  }

  const sortedSlots = [...timeSlots].sort((slotA, slotB) => {
    const fromA = _getNumericalTime(slotA.hours.from)
    const fromB = _getNumericalTime(slotB.hours.from)

    // Handle invalid time format
    if (fromA === false || fromB === false) {
      return 0
    }

    return fromA - fromB
  })

  for (let i = 1; i < sortedSlots.length; i++) {
    const currentSlot = sortedSlots[i]
    const previousSlot = sortedSlots[i - 1]

    const fromA = _getNumericalTime(previousSlot.hours.from)
    const toA = _getNumericalTime(previousSlot.hours.to)
    const fromB = _getNumericalTime(currentSlot.hours.from)
    const toB = _getNumericalTime(currentSlot.hours.to)

    // Handle invalid time format
    if (
      fromA === false ||
      toA === false ||
      fromB === false ||
      toB === false
    ) {
      return false
    }

    // Check for collision between two slots
    if (fromB < toA && toB > fromA) {
      return true
    }
  }

  return false
}


/**
 * Validates a day in the working hours inventory.
 *
 * @param day - The inventory item representing a day.
 * @returns The validated inventory item with updated error status.
 */
const _validateDay = (day: InventoryItem): InventoryItem => {
  const newDay = { ...day }

  if (Object.values(newDay.slots).some((slot) => slot.error)) {
    newDay.error = WorkingHoursError.INVALID_TIMES
    return newDay
  }

  if (_areSlotsColliding(Object.values(newDay.slots))) {
    newDay.error = WorkingHoursError.SLOT_COLLISIONS
    return newDay
  }

  newDay.error = null
  return newDay
}

/**
 * Validates a working hour slot.
 *
 * @param slot - The working hour slot to validate.
 * @returns The validated working hour slot with updated error status.
 */
const _validateSlot = (slot: WorkingHourSlot): WorkingHourSlot => {
  const newSlot = { ...slot }
  newSlot.error = _getNumericalTime(newSlot.hours.from) >= _getNumericalTime(newSlot.hours.to)
  return newSlot
}

/**
 * Creates a working hour slot with the given start and end times.
 *
 * @param from - The start time of the slot.
 * @param to - The end time of the slot.
 * @returns The created working hour slot.
 */
const _getSlot = (from: string, to: string): WorkingHourSlot => {
  return _validateSlot({
    hours: {
      from: _coerceTimeString(from),
      to: _coerceTimeString(to),
    },
    error: false,
  })
}

/**
 * Updates the slots within a day of the Working Hours inventory.
 * @param day - The existing InventoryItem object or null if it doesn't exist.
 * @param slotsToAdd - An object containing new slots to be added.
 * @param slotsToRemove - An array of slot IDs to be removed.
 * @returns The updated InventoryItem object with validated slots.
 */
const _updateSlots = (
  day: Nullable<InventoryItem>,
  slotsToAdd: Record<string, WorkingHourSlot>,
  slotsToRemove: string[] = []
): InventoryItem => {
  if (!day) {
    return {
      error: null,
      slots: slotsToAdd,
    }
  }

  const updatedSlots = { ...day.slots }

  // Add new slots
  for (const slotId in slotsToAdd) {
    updatedSlots[slotId] = slotsToAdd[slotId]
  }

  // Remove slots
  for (const slotId of slotsToRemove) {
    delete updatedSlots[slotId]
  }

  return _validateDay({
    ...day,
    slots: updatedSlots,
  })
}


/**
 * Generates a default working hour slot with the default start and end times.
 *
 * @returns The generated default working hour slot.
 */
const _generateDefaultSlot = (): WorkingHourSlot => _getSlot(DEFAULT_FROM, DEFAULT_TO)

/**
 * Maps an array of slot times to a record of working hour slots.
 *
 * @param slotTimes - An array of slot times containing start and end times.
 * @returns The mapped record of working hour slots.
 */
const _slotMapper = (slotTimes: { from: string, to: string }[]) => {
  return slotTimes.reduce<Record<string, WorkingHourSlot>>((slotMap, { from, to }) => {
    return {
      ...(slotMap || {}),
      [_generateSlotId()]: _getSlot(from, to)
    }
  }, {})
}


// API code
export const [WorkingHoursAPIProvider, useWorkingHoursAPI] = constate(() => {

  const initialInventory = {}

  const reduceInventory: Reducer<WorkingHoursInventory, Actions> = (state = initialInventory, action) => {
    const newState = { ...state } // Create a shallow copy of the state

    switch (action.type) {
      case WorkingHoursActionType.ADD_DAY:
        newState[action.dayKey] = _validateDay({
          slots: action.slots,
          error: null,
        })
        break

      case WorkingHoursActionType.ADD_SLOT:
        newState[action.dayKey] = _updateSlots(newState[action.dayKey], {
          [_generateSlotId()]: _generateDefaultSlot(),
        })
        break

      case WorkingHoursActionType.REMOVE_SLOT:
        newState[action.dayKey] = _updateSlots(newState[action.dayKey], {}, [action.slotId])
        break

      case WorkingHoursActionType.EDIT_SLOT:
        newState[action.dayKey] = _updateSlots(newState[action.dayKey], {
          [action.slotId]: _validateSlot({
            ...newState[action.dayKey]?.slots[action.slotId],
            hours: {
              ...newState[action.dayKey]?.slots[action.slotId]?.hours,
              ...action.data,
            },
          }),
        })
        break

      case WorkingHoursActionType.RESET:
        return {}

      default:
        return state
    }

    return newState
  }

  const [workingHours, dispatch] = useReducer(reduceInventory, initialInventory)

  const addSlot = useCallback((day: string) => {
    dispatch(new AddSlotAction(day, _generateDefaultSlot()))
  }, [])

  const editSlot = useCallback((day: string, slotId: string, data: Partial<CreativeWorkingHours>) => {
    dispatch(new EditSlotAction(day, slotId, data))
  }, [])

  const removeSlot = useCallback((day: string, slotId: string) => {
    dispatch(new RemoveSlotAction(day, slotId))
  }, [])

  const initializeData = useCallback((data: CreativeWorkingHoursDTO) => {

    dispatch(new ResetSlotsAction())

    for (let dayKey in data) {
      const slots = _slotMapper(data[dayKey])
      dispatch(new AddDayAction(dayKey, slots))
    }
  }, [])

  const getSerializedData = useCallback(() => {
    const days: CreativeWorkingHoursDTO = {}

    for (let dayKey in workingHours) {
      days[dayKey] = Object.values(workingHours[dayKey].slots).map((slot) => ({ from: slot.hours.from, to: slot.hours.to }))
    }

    return days
  }, [workingHours])

  return {
    workingHours,
    addSlot,
    editSlot,
    removeSlot,
    initializeData,
    getSerializedData,
  }
})
