import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { QueryClient } from '@tanstack/react-query'
import { APIRequestState } from 'constants/API'
import { APIRequest } from 'models/API'
import { ActionRequest } from 'models/redux'
import { createAPIAction } from 'redux/Helpers'
import { AutomateOrderStoreItem } from './automateOrder.interfaces'

/** Describes general action to automate order */
export interface ActionAutomateOrder extends ActionRequest {
  payload: {
    orderId: number,
    assignmentId: string,
    request: AutomateOrderStoreItem
  }
}

/** Creates an action to automate order */
export function automateOrder(orderId: number, assignmentId: string | null, queryClient: QueryClient): ActionAutomateOrder {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.AUTOMATE_ORDER], {
    orderId,
    assignmentId,
    request: new APIRequest(APIRequestState.RUNNING)
  }, queryClient)
}

/** Creates an action to purge the state of automate order */
export function purgeAutomateOrder(orderId: number): ActionAutomateOrder {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.AUTOMATE_ORDER], {
    orderId,
    request: new APIRequest(APIRequestState.BEFORE_START)
  })
}
