import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { ActionArchiveVisuals } from './ArchiveVisuals.actions'
import { ArchiveVisualsStore } from './ArchiveVisuals.interfaces'
import { cloneDeep } from 'lodash'

/** Redux data reducer for archive visuals */
export const reduceArchiveVisuals = (state: ArchiveVisualsStore, action: ActionArchiveVisuals) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.ARCHIVE_VISUALS) return state
  const newState = cloneDeep(state)

  const { missionId, tag, request } = action.payload

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
    case ActionTypeAPIEvent.FETCH:
    case ActionTypeAPIEvent.RECEIVED:
      newState[missionId] = {
        ...newState[missionId],
        [tag]: request,
      }
      return newState
    default:
      return state
  }
}