import { PreferredPaymentMethodType, SavedStripePaymentMethod } from 'models/user'

import { CheckCard } from '../ExpandableCard'
import { FC } from 'react'
import { MUICheckCard } from '../MUICards'
import { Nullable } from 'models/helpers'
import { SavedPaymentMethodSelectCard } from '../SavedPaymentMethodCard/SelectCard'
import styles from './SavedPaymentMethodsSelect.module.sass'
import { useTranslation } from 'react-i18next'

interface Props {
  savedPaymentMethods: SavedStripePaymentMethod[],
  paymentMethodType: PreferredPaymentMethodType,
  selectedPaymentMethod: Nullable<SavedStripePaymentMethod>,
  onSelected: (method: Nullable<SavedStripePaymentMethod>) => void,
  className?: string
  isNewDesign?: boolean
}

/**
 * Displays list of check cards with info about payment method.
 * Null method is added to the end of the list
 * Selected state is to be handled outside of component, component accepts state and callback for selection change.
 * @component SavedPaymentMethodSelectCard
 * @example
 * <SavedPaymentMethodsSelect
 *  savedPaymentMethods={methodList}
 *  paymentMethodType={PreferredPaymentMethodEnum.CARD_PAYMENT}
 *  selectedPaymentMethod={selectedMethod}
 *  onSelected={(method: Nullable<SavedStripePaymentMethod>) => selectionHandlerFnc(selected)}
 * />
 */
export const SavedPaymentMethodsSelect: FC<Props> = ({
  savedPaymentMethods,
  paymentMethodType,
  selectedPaymentMethod,
  onSelected,
  className,
  isNewDesign = false,
}) => {

  const { t } = useTranslation(['saved_payment_methods_select'])

  return (
    <div className={`${styles.savedMethodsSelect} ${className}`}>

      <h3 className={styles.title}>
        {t('saved_methods', { context: paymentMethodType })}
      </h3>

      {savedPaymentMethods.map((paymentMethod) => (
        <SavedPaymentMethodSelectCard
          isNewDesign={isNewDesign}
          key={paymentMethod.stripeId}
          isSelected={!!selectedPaymentMethod && selectedPaymentMethod.stripeId === paymentMethod.stripeId}
          onSelected={(selected) => selected && onSelected(paymentMethod)}
          paymentMethod={paymentMethod}>
        </SavedPaymentMethodSelectCard>
      ))}

      {isNewDesign
        ? (
          <MUICheckCard
            isRadio={true}
            isHeaderClickable={true}
            checked={selectedPaymentMethod === null}
            onCheck={() => selectedPaymentMethod !== null && onSelected(null)}
          >
            {t('use_other', { context: paymentMethodType })}
          </MUICheckCard>
        )
        : (
          <CheckCard
            className={styles.nullCard}
            isRadio={true}
            isHeaderClickable={true}
            checkbox='circle'
            checked={selectedPaymentMethod === null}
            onCheck={(checked) => checked && onSelected(null)}
          >
            {t('use_other', { context: paymentMethodType })}
          </CheckCard>
        )
      }

    </div>
  )
}
