import '../../Dashboard/Dashboard.sass'

import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { FC, useCallback } from 'react'
import { OrderAssignmentStatusFilter, Params } from 'constants/misc'

import CameraOutlinedIcon from '@mui/icons-material/CameraOutlined'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import FilterTab from 'components/common/FilterTab/FilterTab.module'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { NotificationsActiveOutlined } from '@mui/icons-material'
import { OrderListFilters } from 'dataQueries/order.query'
import UpdateRoundedIcon from '@mui/icons-material/UpdateRounded'
import { useBkbnSearchParams } from 'utils/hooks/useBkbnSearchParams'
import { useListAssignments } from 'dataQueries'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Placeholder in search input */
  filters: OrderListFilters
}

/**
 * @component Creative dashboard assignment status filters tabs
 * @example <CreativeAssignmentStatusFilters filters={filters}/>
 * */
export const CreativeAssignmentStatusFilters: FC<Props> = ({ filters }) => {
  const { t } = useTranslation(['dashboard_client', 'dashboard'])
  const params = useBkbnSearchParams()
  const { creativeData } = useUserData()


  const handleLogOrderStatusSelection = useCallback((status: OrderAssignmentStatusFilter) => {
    logAnalyticsEvent(AnalyticsEvent.CREATIVE_DASHBOARD_ORDER_STATUS_SELECTION, {
      userEmail: creativeData?.email,
      status,
    })
  }, [creativeData?.email])

  // ASSIGNMENTS
  const availableAssignments = useListAssignments(
    'CREATIVE_AVAILABLE_ASSIGNMENT_LIST',
    {
      ...filters,
      status: OrderAssignmentStatusFilter.AVAILABLE,
    }
  )

  const ongoingAssignments = useListAssignments(
    'CREATIVE_ONGOING_ASSIGNMENT_LIST',
    {
      ...filters,
      status: OrderAssignmentStatusFilter.ONGOING,
    }
  )

  const finishedAssignments = useListAssignments(
    'CREATIVE_FINISHED_ASSIGNMENT_LIST',
    {
      ...filters,
      status: OrderAssignmentStatusFilter.FINISHED,
    }
  )

  const cancelledAssignments = useListAssignments(
    'CREATIVE_CANCELLED_ASSIGNMENT_LIST',
    {
      ...filters,
      status: OrderAssignmentStatusFilter.CANCELLED,
    }
  )

  const assignmentsNeedingAttention = useListAssignments(
    'CREATIVE_ASSIGNMENT_NEEDING_ATTENTION_LIST',
    {
      ...filters,
      status: OrderAssignmentStatusFilter.NEEDS_ATTENTION,
    }
  )


  return (
    <div className="order-status-filter-tabs">
      <span className="filter-section-label">{t('dashboard:filter_by_status')}</span>
      <FilterTab
        isActive={filters.status === OrderAssignmentStatusFilter.AVAILABLE}
        totalCount={availableAssignments.data?.data.totalElements.toString()}
        icon={<CameraOutlinedIcon fontSize='inherit' />}
        label={t('dashboard:available')}
        onClick={() => {
          handleLogOrderStatusSelection(OrderAssignmentStatusFilter.AVAILABLE)
          params.set([Params.STATUS, OrderAssignmentStatusFilter.AVAILABLE])
        }}
      />
      <FilterTab
        isActive={filters.status === OrderAssignmentStatusFilter.NEEDS_ATTENTION}
        totalCount={assignmentsNeedingAttention.data?.data.totalElements.toString()}
        icon={<NotificationsActiveOutlined fontSize='inherit' />}
        label={t('dashboard:need_attention')}
        onClick={() => {
          handleLogOrderStatusSelection(OrderAssignmentStatusFilter.NEEDS_ATTENTION)
          params.set([Params.STATUS, OrderAssignmentStatusFilter.NEEDS_ATTENTION])
        }}
      />
      <FilterTab
        isActive={filters.status === OrderAssignmentStatusFilter.ONGOING}
        totalCount={ongoingAssignments.data?.data.totalElements.toString()}
        icon={<UpdateRoundedIcon fontSize='inherit' />}
        label={t('dashboard:ongoing')}
        onClick={() => {
          handleLogOrderStatusSelection(OrderAssignmentStatusFilter.ONGOING)
          params.set([Params.STATUS, OrderAssignmentStatusFilter.ONGOING])
        }}
      />
      <FilterTab
        isActive={filters.status === OrderAssignmentStatusFilter.FINISHED}
        totalCount={finishedAssignments.data?.data.totalElements.toString()}
        icon={<CheckCircleOutlineRoundedIcon fontSize='inherit' />}
        label={t('dashboard:finished')}
        onClick={() => {
          handleLogOrderStatusSelection(OrderAssignmentStatusFilter.FINISHED)
          params.set([Params.STATUS, OrderAssignmentStatusFilter.FINISHED])
        }}
      />

      <FilterTab
        isActive={filters.status === OrderAssignmentStatusFilter.CANCELLED}
        totalCount={cancelledAssignments.data?.data.totalElements.toString()}
        icon={<HighlightOffIcon fontSize='inherit' />}
        label={t('dashboard:cancelled')}
        onClick={() => {
          handleLogOrderStatusSelection(OrderAssignmentStatusFilter.CANCELLED)
          params.set([Params.STATUS, OrderAssignmentStatusFilter.CANCELLED])
        }}
      />
    </div>
  )
}
