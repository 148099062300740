import { RoomInventory, StagingConfiguration, StagingRequestDTO } from 'models/virtualStaging'
import { useCallback, useMemo, useState } from 'react'

import { ProductDTO } from 'models/product'
import { STAGING_PRODUCT_KINDS_PROPERTY_TYPES } from 'constants/product'
import constate from 'constate'
import { getAPIRoomItemFromStagingRoom } from 'utils/serialization/getAPIRoomItemFromStagingRoom'
import { purgeSelectVisualsForStaging } from 'redux/Individual/Visual/SelectVisualsForStaging'
import { useDispatch } from 'react-redux'
import { useGalleryStagingVisualSelection } from './GalleryStagingVisualSelection.context'
import { useRoomAPI } from 'components/contexts/RoomAPI.context'
import { useRoomInventory } from 'components/contexts/RoomInventory.context'
import { useStyleSelectionContext } from 'components/common/StagingSelection'

export enum GalleryStagingStep {
  VISUAL_SELECTION = 'VISUAL_SELECTION',
  VISUAL_STAGING = 'VISUAL_STAGING'
}

export const [GalleryStagingFlowContextProvider, useGalleryStagingFlow] = constate(() => {

  const dispatch = useDispatch()
  const { setActiveRoomIndex } = useStyleSelectionContext()
  const { selectedVisuals, setSelectedVisuals } = useGalleryStagingVisualSelection()
  const { createRoomObject, setRooms, roomInventory } = useRoomInventory()
  const { assignRoomToImage, unassignAll, getAllRooms } = useRoomAPI()

  const [stagingAssignmentId, setStagingAssignmentId] = useState<string | null>(null)
  const [stagingProduct, setStagingProduct] = useState<ProductDTO | null>(null)
  const [activeStep, setActiveStep] = useState<GalleryStagingStep>(GalleryStagingStep.VISUAL_SELECTION)

  /**
   * Generates one room for each selected image.
   * Assigns room key of visual id and property type according to staging product.
   * Pushes rooms to inventory and assigns images to rooms 
   */
  const generateRoomsForSelectedImages = useCallback(() => {
    if (!stagingProduct) return false

    const rooms: RoomInventory = {}

    for (const visual of Object.values(selectedVisuals)) {

      if (!visual) continue

      rooms[visual.id] = roomInventory[visual.id] || createRoomObject(stagingProduct.id, {
        key: visual.id,
        propertyType: STAGING_PRODUCT_KINDS_PROPERTY_TYPES[stagingProduct.kind]
      })

      assignRoomToImage(visual.id, visual.id)
    }

    setRooms(rooms)

    return true
  }, [assignRoomToImage, createRoomObject, roomInventory, selectedVisuals, setRooms, stagingProduct])

  /** Derives how many visuals have to be selected from staging product quantity */
  const requiredSelectionCount = useMemo(() => stagingProduct ? stagingProduct.quantity : 0, [stagingProduct])

  /** Resets states of image selection and staging */
  const cleanupStaging = (stagingAssignmentId: string | undefined) => {
    setStagingProduct(null)
    setStagingAssignmentId(null)
    setActiveStep(GalleryStagingStep.VISUAL_SELECTION)
    setSelectedVisuals({})
    unassignAll()
    setRooms({})
    setActiveRoomIndex(0)

    if (!stagingAssignmentId) return
    dispatch(purgeSelectVisualsForStaging(stagingAssignmentId))
  }

  /** Sets the staging product and stagingAssignmentId */
  const initializeStaging = (stagingAssignmentId: string, stagingProduct: ProductDTO) => {
    setStagingAssignmentId(stagingAssignmentId)
    setStagingProduct(stagingProduct)
  }

  const allRooms = useMemo(() => getAllRooms(), [getAllRooms])

  /** Serializes staging configuration information into payload */
  const getStagingConfig = useCallback((): StagingRequestDTO | undefined => {

    // Reduce room objects to Records of config items indexed by image id
    const roomDetails = allRooms.reduce((stagingMap, room) => {
      // typeguards
      if (room.roomTypes.isEmpty()) return stagingMap

      const roomConfig = getAPIRoomItemFromStagingRoom(room)

      const imageStyleMap: StagingConfiguration = room.images.reduce((imageMap, { id }) => ({
        ...imageMap,
        [id]: roomConfig
      }), {})

      return {
        ...stagingMap,
        ...imageStyleMap,
      }
    }, {})

    return {
      details: roomDetails
    }

  }, [allRooms])

  return {
    allRooms,
    activeStep,
    stagingProduct,
    requiredSelectionCount,
    stagingAssignmentId,
    getStagingConfig,
    setActiveStep,
    generateRoomsForSelectedImages,
    initializeStaging,
    cleanupStaging,
  }
})
