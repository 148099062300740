/** Instruction Type enum - Describes type of instruction (ORGANIZATION, BILLING, BILLING_PRIMARY, COMMENTS, REFERENCE) */
export enum InstructionType {
  ORGANIZATION = 'ORGANIZATION',
  BILLING = 'BILLING',
  BILLING_PRIMARY = 'BILLING_PRIMARY',
  VAT_NUMBER = 'VAT_NUMBER',
  COMMENTS = 'COMMENTS',
  STAGING_COMMENTS = 'STAGING_COMMENTS',
  REFERENCE = 'REFERENCE',
  CT_BOOKING = 'CT_BOOKING',
  DELIVERY = 'DELIVERY',
  PROPERTY_OWNER = 'PROPERTY_OWNER',
}

/** Instruction Option Field Type - Describes the type of the instruction option field (TIME, DATE, PHONE, EMAIL, COMMENT, etc...) */
export enum InstructionOptionFieldType {
  TIME = 'TIME',
  DATE = 'DATE',
  NAME = 'NAME',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  COMMENT = 'COMMENT',
  ADDRESS = 'ADDRESS',
  TIMEZONE = 'TIMEZONE',
  USER_TIMEZONE_DATETIME = 'USER_TIMEZONE_DATETIME',
  USER_TIMEZONE_DATETIME_INSTRUCTIONS = 'USER_TIMEZONE_DATETIME_INSTRUCTIONS',
}
