import { AssignmentStage } from 'constants/assignment'
import { DealStage } from 'constants/deal'
import { NullableStringIndexSignature } from 'models/helpers'

/** Enumeration of stage order */
export enum StageOrder {
  COMPARING_BEFORE_COMPARED_TO = 'COMPARING_BEFORE_COMPARED_TO',
  COMPARING_EQUAL_TO_COMPARED_TO = 'COMPARING_EQUAL_TO_COMPARED_TO',
  COMPARING_AFTER_COMPARED_TO = 'COMPARING_AFTER_COMPARED_TO',
  COMPARING_WITHIN_BOUNDS_OF_COMPARED_TO = 'COMPARING_WITHIN_BOUNDS_OF_COMPARED_TO',
}

/** Array containing all deal stages (defined in DealStage enum) */
export const allDealStageArray = Object.values(DealStage)

/** Array containing all assignment stages (defined in AssignmentStage enum) */
export const allAssignmentStageArray = Object.values(AssignmentStage)

/** All deal stage indexes map */
export const allDealStageIndexes = new Map(allDealStageArray.map((stage, index) => [stage, index]))

/** All deal stage indexes object */
export const allDealStageIndexesObject: NullableStringIndexSignature<number> = allDealStageArray.reduce((object, stage, index) => Object.assign(object, { [stage]: index }), {})

/** All assignment stage indexes map */
export const allAssignmentStageIndexes = new Map(allAssignmentStageArray.map((stage, index) => [stage, index]))

/** All assignment stage indexes object */
export const allAssignmentStageIndexesObject: NullableStringIndexSignature<number> = allAssignmentStageArray.reduce((object, stage, index) => Object.assign(object, { [stage]: index }), {})

/** Utility function to compare deal stage order */
export function compareDealStageOrder(comparing: DealStage, comparedTo: DealStage | DealStage[] | Set<DealStage>): StageOrder {
  if (typeof comparedTo === 'string') return compareTwoDealStageOrder(comparing, comparedTo)
  if (Array.isArray(comparedTo)) return compareDealStageToArrayOrder(comparing, comparedTo)
  return compareDealStageToSetOrder(comparing, comparedTo)
}

/** Utility function to compare an order of two single deal stages
 * @example compareTwoDealStageOrder(DealStage.ORDER_PLACED, DealStage.INVOICE_CREATED) // 'COMPARING_BEFORE_COMPARED_TO'
 */
function compareTwoDealStageOrder(comparing: DealStage, comparedTo: DealStage): StageOrder {
  const comparingIndex = allDealStageIndexesObject[comparing] || -1
  const comparedToIndex = allDealStageIndexesObject[comparedTo] || -1

  if (comparingIndex < comparedToIndex) return StageOrder.COMPARING_BEFORE_COMPARED_TO
  if (comparingIndex > comparedToIndex) return StageOrder.COMPARING_AFTER_COMPARED_TO
  return StageOrder.COMPARING_EQUAL_TO_COMPARED_TO
}

/** Utility function to compare an order of deal stage to deal stages array
 * @example compareDealStageToArrayOrder(DealStage.ORDER_PLACED, dealStagesArray) // 'COMPARING_WITHIN_BOUNDS_OF_COMPARED_TO' == the comparing deal stage is not present in the array
 */
function compareDealStageToArrayOrder(comparing: DealStage, comparedTo: DealStage[]): StageOrder {
  if (comparedTo.includes(comparing)) return StageOrder.COMPARING_EQUAL_TO_COMPARED_TO

  const comparedToIndexes = comparedTo.map(stage => allDealStageIndexes.get(stage) || -1).sort((a, b) => a - b)

  let comparingIndex = allDealStageIndexes.get(comparing) || -1
  let comparedToEarliestIndex = comparedToIndexes[0]
  let comparedToLatestIndex = comparedToIndexes[comparedToIndexes.length - 1]

  if (comparingIndex < comparedToEarliestIndex) return StageOrder.COMPARING_BEFORE_COMPARED_TO
  if (comparingIndex > comparedToLatestIndex) return StageOrder.COMPARING_AFTER_COMPARED_TO
  return StageOrder.COMPARING_WITHIN_BOUNDS_OF_COMPARED_TO
}

/** Utility function to compare an order of deal stage to deal stages set
 * @example compareDealStageToSetOrder(DealStage.ORDER_PLACED, dealStagesSet) // 'COMPARING_WITHIN_BOUNDS_OF_COMPARED_TO' == the comparing deal stage is not present in the set
 */
function compareDealStageToSetOrder(comparing: DealStage, comparedTo: Set<DealStage>): StageOrder {
  if (comparedTo.has(comparing)) return StageOrder.COMPARING_EQUAL_TO_COMPARED_TO

  let comparingIndex = allDealStageIndexes.get(comparing) || -1
  let comparedToEarliestIndex = -1
  let comparedToLatestIndex = -1

  for (let i = 0; i < allDealStageArray.length; i++) {
    if (comparedTo.has(allDealStageArray[i])) {
      if (comparedToEarliestIndex === -1) comparedToEarliestIndex = i
      comparedToLatestIndex = i
    }
  }

  if (comparingIndex < comparedToEarliestIndex) return StageOrder.COMPARING_BEFORE_COMPARED_TO
  if (comparingIndex > comparedToLatestIndex) return StageOrder.COMPARING_AFTER_COMPARED_TO
  return StageOrder.COMPARING_WITHIN_BOUNDS_OF_COMPARED_TO
}

/** Utility function to compare assignment stage order */
export function compareAssignmentStageOrder(comparing: AssignmentStage, comparedTo: AssignmentStage | AssignmentStage[] | Set<AssignmentStage>): StageOrder {
  if (typeof comparedTo === 'string') return compareTwoAssignmentStageOrder(comparing, comparedTo)
  if (Array.isArray(comparedTo)) return compareAssignmentStageToArrayOrder(comparing, comparedTo)
  return compareAssignmentStageToSetOrder(comparing, comparedTo)
}

/** Utility function to compare an order of two single assignment stages
 * @example compareTwoAssignmentStageOrder(AssignmentStage.MISSION_ORDER_PLACED, AssignmentStage.VISUALS_SENT_TO_CLIENT) // 'COMPARING_BEFORE_COMPARED_TO'
 */
function compareTwoAssignmentStageOrder(comparing: AssignmentStage, comparedTo: AssignmentStage): StageOrder {
  const comparingIndex = allAssignmentStageIndexesObject[comparing] || -1
  const comparedToIndex = allAssignmentStageIndexesObject[comparedTo] || -1

  if (comparingIndex < comparedToIndex) return StageOrder.COMPARING_BEFORE_COMPARED_TO
  if (comparingIndex > comparedToIndex) return StageOrder.COMPARING_AFTER_COMPARED_TO
  return StageOrder.COMPARING_EQUAL_TO_COMPARED_TO
}

/** Utility function to compare an order of assignment stage to assignment stages array
 * @example compareAssignmentStageToArrayOrder(AssignmentStage.MISSION_ORDER_PLACED, assignmentStagesArray) // 'COMPARING_WITHIN_BOUNDS_OF_COMPARED_TO' == the comparing assignment stage is not present in the array
 */
function compareAssignmentStageToArrayOrder(comparing: AssignmentStage, comparedTo: AssignmentStage[]): StageOrder {
  if (comparedTo.includes(comparing)) return StageOrder.COMPARING_EQUAL_TO_COMPARED_TO

  const comparedToIndexes = comparedTo.map(stage => allAssignmentStageIndexes.get(stage) || -1).sort((a, b) => a - b)

  let comparingIndex = allAssignmentStageIndexes.get(comparing) || -1
  let comparedToEarliestIndex = comparedToIndexes[0]
  let comparedToLatestIndex = comparedToIndexes[comparedToIndexes.length - 1]

  if (comparingIndex < comparedToEarliestIndex) return StageOrder.COMPARING_BEFORE_COMPARED_TO
  if (comparingIndex > comparedToLatestIndex) return StageOrder.COMPARING_AFTER_COMPARED_TO
  return StageOrder.COMPARING_WITHIN_BOUNDS_OF_COMPARED_TO
}

/** Utility function to compare an order of assignment stage to assignment stages set
 * @example compareAssignmentStageToSetOrder(AssignmentStage.MISSION_ORDER_PLACED, assignmentStagesSet) // 'COMPARING_WITHIN_BOUNDS_OF_COMPARED_TO' == the comparing assignment stage is not present in the set
 */
function compareAssignmentStageToSetOrder(comparing: AssignmentStage, comparedTo: Set<AssignmentStage>): StageOrder {
  if (comparedTo.has(comparing)) return StageOrder.COMPARING_EQUAL_TO_COMPARED_TO

  let comparingIndex = allAssignmentStageIndexes.get(comparing) || -1
  let comparedToEarliestIndex = -1
  let comparedToLatestIndex = -1

  for (let i = 0; i < allAssignmentStageArray.length; i++) {
    if (comparedTo.has(allAssignmentStageArray[i])) {
      if (comparedToEarliestIndex === -1) comparedToEarliestIndex = i
      comparedToLatestIndex = i
    }
  }

  if (comparingIndex < comparedToEarliestIndex) return StageOrder.COMPARING_BEFORE_COMPARED_TO
  if (comparingIndex > comparedToLatestIndex) return StageOrder.COMPARING_AFTER_COMPARED_TO
  return StageOrder.COMPARING_WITHIN_BOUNDS_OF_COMPARED_TO
}