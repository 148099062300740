import './DealAssignmentPriceBreakdown.sass'

import { bigFromFee, formatPrice } from 'utils/price'
import { useCallback, useMemo } from 'react'
import { useOrderPriceBreakdown, useOrderProducts } from 'utils/hooks'

import { AssignmentDTO } from 'models/assignment'
import { DealDTO } from 'models/deal'
import { DealDTOOrAssignmentDTOIsDealDTO } from 'utils/typeguards'
import { DealState } from 'constants/deal'
import { ProductPriceDTO } from 'models/product'
import { useTranslation } from 'react-i18next'

/** @interface Props Input properties */
export interface Props {
  /** The additional classes to append */
  className?: string
  /** The data object */
  data: DealDTO | AssignmentDTO
}

/**
 * @component Display price breakdown for deal or assignment
 * @example
 */
export const DealAssignmentPriceBreakdown: React.FC<Props> = ({
  className = '',
  data,
}) => {
  const { t } = useTranslation(['deal_assignment_price_breakdown', 'product', 'product_kind', 'product_type', 'category'])

  const {
    discountedTotal,
    totalWithoutDiscount,
    vat,
    percentageDiscount,
    currency,
    discountedTotalVat,
    discountedTotalAfterVat,
    computedTotalVat,
    computedTotalAfterVat,
    computedTotalAfterDiscount
  } = useOrderPriceBreakdown(data)
  const {
    purchasedProducts,
    organizationProducts
  } = useOrderProducts(data)

  // Is deal cancelled
  const isCancelled = useMemo(() => DealDTOOrAssignmentDTOIsDealDTO(data) && data.state === DealState.CANCELLED, [data])

  const renderProductLine = useCallback((product: ProductPriceDTO, index: number) => (
    <div className="line" key={`${index}-${product.productId}`}>
      <span>
        {(product.quantity > 1 ? `${product.quantity} x - ` : '')}
        {t(`product:p_${product.productId}`,
          t(`product_kind:${product.kind}`,
            t(`product_type:${product.type}`,
              t(`category:${product.category}`)
            )
          )
        )}
      </span>
      <strong className="nowrap">
        {product.quantity > 1 &&
          <span>
            {'('}
            {`${product.quantity} x `}
            {formatPrice(bigFromFee(product.unitPrice), product.unitPrice.currency)}
            {')'}
          </span>
        }
        {formatPrice(bigFromFee(product.unitPrice).times(product.quantity), product.unitPrice.currency)}
      </strong>
    </div>
  ), [t])

  return (
    <div className={`deal-assignment-price-breakdown ${className}`}>
      {purchasedProducts.length > 0 &&
        <div className="section products">
          <h3 className="heading">{t('purchased_products')}</h3>
          {purchasedProducts.map((product, index) => renderProductLine(product, index))}
        </div>
      }
      {organizationProducts.length > 0 &&
        <div className="section organization">
          <h3 className="heading">{t('organization')}</h3>
          {organizationProducts.map((product, index) => renderProductLine(product, index))}
        </div>
      }
      {!isCancelled &&
        <div className="section total">
          <h3 className="heading">
            {t('total')}
            {percentageDiscount.gt(0) &&
              <span className="gray">
                ({formatPrice(percentageDiscount)} % {t('discount')})
              </span>
            }
          </h3>
          <div className="line total">
            <span>
              {t('total_price')}
            </span>
            {percentageDiscount.gt(0) &&
              <strong className="total-before-discount nowrap">{formatPrice(totalWithoutDiscount, currency)}</strong>
            }
            <strong className="total">{formatPrice(computedTotalAfterDiscount, currency)}</strong>
          </div>
          <div className="vat">
            <div className="line">
              <span>{t('vat')} ({formatPrice(vat)} %)</span>
              <strong className="nowrap">{formatPrice(computedTotalVat, currency)}</strong>
            </div>
            <div className="line">
              <span>{t('after_vat')}</span>
              <strong className="nowrap">{formatPrice(computedTotalAfterVat, currency)}</strong>
            </div>
          </div>
        </div>
      }
      {isCancelled &&
        <div className="section total">
          <h3 className="heading">{t('total')}</h3>
          <div className="line total">
            <span>{t('total_price')}</span>
            <strong className="total">{formatPrice(discountedTotal, currency)}</strong>
          </div>
          <div className="vat">
            <div className="line">
              <span>{t('vat')} ({formatPrice(vat)} %)</span>
              <strong className="nowrap">{formatPrice(discountedTotalVat, currency)}</strong>
            </div>
            <div className="line">
              <span>{t('after_vat')}</span>
              <strong className="nowrap">{formatPrice(discountedTotalAfterVat, currency)}</strong>
            </div>
          </div>
        </div>
      }
    </div>
  )
}
