export enum VirtualFurniturePropertyType {
  RESIDENTIAL = 'RESIDENTIAL',
  COMMERCIAL = 'COMMERCIAL',
  SINGLE_ITEMS = 'SINGLE_ITEMS',
  MATERIALS = 'MATERIALS',
  STANDARD_CATALOG = 'STANDARD_CATALOG',
}

export enum VirtualFurnitureRoomType {
  LIVING_ROOM = 'LIVING_ROOM',
  BEDROOM = 'BEDROOM',
  BATHROOM = 'BATHROOM',
  OUTDOOR = 'OUTDOOR',
  DINING_KITCHEN = 'DINING_KITCHEN',
  OFFICE = 'OFFICE',
  ENTERTAINMENT = 'ENTERTAINMENT',
  FITNESS = 'FITNESS',
  DECORATION = 'DECORATION',
  FLOORING = 'FLOORING',
  WALL_MATERIALS = 'WALL_MATERIALS',
  DINING = 'DINING',
  KITCHEN = 'KITCHEN',
  APPLIANCES = 'APPLIANCES',
  SHOP = 'SHOP',
  KIDSROOM = 'KIDSROOM',
  CURTAIN = 'CURTAIN',
  PLANT = 'PLANT',
  RESTAURANT = 'RESTAURANT',
  DAYBED = 'DAYBED',
  SIDETABLE = 'SIDETABLE',
  LIGHT = 'LIGHT',
  WHOLE_ROOM = 'WHOLE_ROOM',
  HOSPITAL = 'HOSPITAL',
  STORAGE = 'STORAGE',
  DOOR = 'DOOR',
  HALLWAY = 'HALLWAY',
  EMPTY_ROOM = 'EMPTY_ROOM',
  RECEPTION = 'RECEPTION',
}

export enum VirtualFurnitureStyleCategory {
  MODERN = 'MODERN',
  HAMPTON = 'HAMPTON',
  SCANDINAVIAN = 'SCANDINAVIAN',
  TRADITIONAL = 'TRADITIONAL',
  CONTEMPORARY = 'CONTEMPORARY',
  COMMERCIAL = 'COMMERCIAL',
  HOME = 'HOME',
  ROMANTIC = 'ROMANTIC',
  NORDIC = 'NORDIC',
  MINIMAL = 'MINIMAL',
  LUXURY = 'LUXURY',
  TRANSITIONAL = 'TRANSITIONAL',
  PLANTS = 'PLANTS',
  TILES = 'TILES',
  CARPET = 'CARPET',
  WOOD = 'WOOD',
}

export enum VirtualFurnitureRoomRemovableOption {
  NONE = 'NONE',
  FIXTURES = 'FIXTURES',
  FURNITURE = 'FURNITURE',
  FIXTURES_AND_FURNITURE = 'FIXTURES_AND_FURNITURE',
}

export enum OldVirtualRemoveOptions {
  REMOVE_FIXTURES_AND_FURNITURE = 'REMOVE_FIXTURES_AND_FURNITURE',
  REMOVE_FIXTURES = 'REMOVE_FIXTURES',
  REMOVE_FURNITURE = 'REMOVE_FURNITURE',
}
