import { APIRequestErrorType, APIRequestState } from 'constants/API'

import { AxiosResponse } from 'axios'

/** Type representing an error */
export type APIError = any

/** Class representing any API request */
export class APIRequest<DataInterface> {
  /** The request state */
  state: APIRequestState = APIRequestState.BEFORE_START
  /** The request error type */
  error_type: APIRequestErrorType = APIRequestErrorType.NONE
  /** The request response data in case of 'SUCCESS' (status code 200-299), undefined otherwise */
  data: DataInterface | null = null
  /** The request response error in case of 'ERROR' (status code 400+ or request did not finish at all [timeout, server not available, network error, cancel]), undefined otherwise */
  error: APIError = null
  /** The original (raw) received axios response */
  response?: AxiosResponse<DataInterface>

  /**
   * Construct a new APIRequest, set the 
   * @param state - The the API request state (optional), defaults to APIRequestState.BEFORE_START
   * @param error_type - The the API request error type (optional), defaults to APIRequestErrorType.NONE
   * @param data - The object representing API response when 'SUCCESS' (optional)
   * @param error - The object representing API response when 'ERROR' (optional)
   */
  constructor(state?: APIRequestState, error_type?: APIRequestErrorType, data?: DataInterface | null, error?: APIError, response?: AxiosResponse<DataInterface>) {
    if (state) this.state = state
    if (error_type) this.error_type = error_type
    if (data) this.data = data
    if (error) this.error = error
    if (response) this.response = response
  }

  /**
   * Factory method which returns APIRequest object with state ERROR, data null, error_type and error
   *
   * @static
   * @param {APIRequestErrorType} error_type: APIRequestErrorType - error type
   * @param {error} error: any - error object
   * @returns new APIRequest
   * @memberof APIRequest
   */
  static factoryError<response_type>(error_type: APIRequestErrorType, error: APIError, response?: AxiosResponse<response_type>) {
    return new this(APIRequestState.ERROR, error_type, null, error, response)
  }

  /**
   * Factory method which returns APIRequest object with state OK, error_type NONE, error null and data
   *
   * @static
   * @param {*} data: DataInterface | null
   * @returns new APIRequest
   * @memberof APIRequest
   */
  static factoryOK<response_type>(data: response_type, response?: AxiosResponse<response_type>) {
    return new this(APIRequestState.OK, APIRequestErrorType.NONE, data, null, response)
  }

  /**
   * Factory method which returns APIRequest object with state RUNNING, error_type NONE, error null and data
   *
   * @static
   * @param {*} data: DataInterface | null
   * @returns new APIRequest
   * @memberof APIRequest
   */
  static factoryRunning<response_type>(data: response_type, response?: AxiosResponse<response_type>) {
    return new this(APIRequestState.RUNNING, APIRequestErrorType.NONE, data, null, response)
  }
}
