import React, { ReactNode } from 'react'
import { SxProps, Theme } from '@mui/material/styles'

import Divider from '@mui/material/Divider'
import classnames from 'classnames'
import styles from './MUIDivider.module.sass'

/**
 * @typedef DividerMargin Available margins
 */
export type DividerMargin = 2 | 8 | 12 | 16 | 20 | 24

/**
 * @typedef DividerOrientation Available orientation
 */
export type DividerOrientation = 'horizontal' | 'vertical'

/**
 * @typedef DividerTextAlign Available text alignment
 */
export type DividerTextAlign = 'center' | 'left' | 'right'

/**
 * @interface Props Input properties
 */
export interface MUIDividerProps {
  /** Override or extend the styles applied to the component. See CSS API for more details.  https://mui.com/material-ui/api/divider/#css */
  className?: string
  /** Type of margin size */
  margin?: DividerMargin
  /** Divider orientation */
  orientation?: DividerOrientation
  /** Absolutely position the element. */
  absolute?: boolean
  /** The content of the component. */
  children?: ReactNode
  /** The text alignment. */
  textAlign?: DividerTextAlign
  /** Custom sx styles */
  sx?: SxProps<Theme>
}

/**
 * @component MUIDivider 
 * @example
 * <MUIDivider margin={16} />
 */

export const MUIDivider: React.FC<MUIDividerProps> = ({
  className = '',
  margin = 24,
  orientation = 'horizontal',
  absolute = false,
  children,
  textAlign = 'center',
  sx,
}) =>
  <Divider
    className={classnames(className, styles.divider, styles[margin], styles[orientation])}
    orientation={orientation}
    absolute={absolute}
    textAlign={textAlign}
    sx={sx}
  >
    {children}
  </Divider>
