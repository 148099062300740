import './Emoji.sass'

import * as Emojis from 'constants/assets/emojis'

import { EmojiType } from 'constants/assets/emojiType'
import React from 'react'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** The concrete emoji to be used */
  emoji: EmojiType
  /** The additional classes to append */
  className?: string
  /** Additional inline styling */
  style?: React.CSSProperties
  /** react-tooltip property for tooltip enabling */
  'data-tip'?: boolean
  /** react-tooltip property for tooltip assignment */
  'data-for'?: string
}

/**
 * @component Graphical component containing an emoji to be inserted into a text, button or a link. The emoji can be determined by emoji property
 * @example
 * <Emoji emoji={EmojiType.HAPPY} style={{marginRight: '2rem'}} className="class" />
 */
const Emoji: React.FC<Props> = ({
  emoji,
  className = '',
  style = {},
  'data-tip': dataTip,
  'data-for': dataFor,
}: Props) => {
  let allClasses = `emoji ${className}`

  allClasses = allClasses.replace(/\s+/igm, ' ').trim()

  const EmojiComponent = Emojis[emoji]

  return (
    <EmojiComponent
      style={style}
      className={allClasses}
      data-tip={dataTip}
      data-for={dataFor}
    />
  )
}

export default Emoji
