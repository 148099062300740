import { FC, ReactNode } from 'react'

import { FileAPIProvider } from 'components/common/FileAPI'
import { FirebaseContextProvider } from 'components/contexts/FirebaseContext'
import { RemoteConfigContextProvider } from 'components/contexts/RemoteConfigContext'
import { SnackbarServiceProvider } from 'components/contexts/SnackbarService.context'
import { TimezoneContextProvider } from 'components/contexts/timezone.context'
import { UserDataContextProvider } from 'components/contexts/UserDataContext'

/** A higher order component which wraps all the global context providers */
const GlobalContextProvider: FC<{
  children?: ReactNode
}> = ({
  children,
}) => {
    return (
      <FirebaseContextProvider>
        <RemoteConfigContextProvider>
          <UserDataContextProvider>
            <TimezoneContextProvider>
              <FileAPIProvider>
                <SnackbarServiceProvider>
                  {children}
                </SnackbarServiceProvider>
              </FileAPIProvider>
            </TimezoneContextProvider>
          </UserDataContextProvider>
        </RemoteConfigContextProvider>
      </FirebaseContextProvider>
    )
  }

export default GlobalContextProvider
