import '../../Dashboard/Dashboard.sass'

import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { AssignmentListFilters, useListAssignments } from 'dataQueries'
import { OrderAssignmentStatusFilter, Params } from 'constants/misc'

import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import FilterTab from 'components/common/FilterTab/FilterTab.module'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { MUIDivider } from 'components/common/MUIDivider'
import { Type } from './AdminDashboard.page'
import UpdateRoundedIcon from '@mui/icons-material/UpdateRounded'
import { useBkbnSearchParams } from 'utils/hooks/useBkbnSearchParams'
import { useCallback } from 'react'
import { useListOrders } from 'dataQueries/order.query'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Placeholder in search input */
  filters: AssignmentListFilters & {
    type: string
    search: string | null
  }
}

/**
 * @component Admin dashboard assignment status filters tabs
 * @example <AdminAssignmentStatusFilters filters={filters}/>
 * */
export const AdminAssignmentStatusFilters: React.FC<Props> = ({ filters }) => {
  const { t } = useTranslation(['dashboard_client', 'dashboard'])
  const params = useBkbnSearchParams()
  const { adminData } = useUserData()

  const logStatuses: Record<Type, Partial<Record<OrderAssignmentStatusFilter, string>>> = {
    [Type.ORDER]: {
      [OrderAssignmentStatusFilter.ONGOING]: 'Ongoing Orders',
      [OrderAssignmentStatusFilter.FINISHED]: 'Delivered Orders',
    },
    [Type.ASSIGNMENT]: {
      [OrderAssignmentStatusFilter.ONGOING]: 'Ongoing Assignments',
      [OrderAssignmentStatusFilter.FINISHED]: 'Delivered Assignments',
    }
  }

  const handleLogOrderStatusSelection = useCallback((type: Type, status: OrderAssignmentStatusFilter) => {
    logAnalyticsEvent(AnalyticsEvent.ADMIN_DASHBOARD_ORDER_STATUS_SELECTION, {
      userEmail: adminData?.email,
      status: logStatuses[type][status] || undefined
    })
    // don't use logStatuses as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminData?.email])

  // ORDERS
  const ongoingOrders = useListOrders(
    'ADMIN_ONGOING_ORDER_LIST',
    {
      ...filters,
      status: OrderAssignmentStatusFilter.ONGOING,
    }
  )

  const finishedOrders = useListOrders(
    'ADMIN_FINISHED_ORDER_LIST',
    {
      ...filters,
      status: OrderAssignmentStatusFilter.FINISHED
    }
  )

  const cancelledOrders = useListOrders(
    'ADMIN_CANCELLED_ORDER_LIST',
    {
      ...filters,
      status: OrderAssignmentStatusFilter.CANCELLED,
    }
  )

  // ASSIGNMENTS
  const ongoingAssignments = useListAssignments(
    'ADMIN_ONGOING_ASSIGNMENT_LIST',
    {
      ...filters,
      status: OrderAssignmentStatusFilter.ONGOING,
    }
  )

  const finishedAssignments = useListAssignments(
    'ADMIN_FINISHED_ASSIGNMENT_LIST',
    {
      ...filters,
      status: OrderAssignmentStatusFilter.FINISHED
    }
  )


  const cancelledAssignments = useListAssignments(
    'ADMIN_CANCELLED_ASSIGNMENT_LIST',
    {
      ...filters,
      status: OrderAssignmentStatusFilter.CANCELLED,
    }
  )

  return (

    <div className="order-status-filter-tabs">

      <span className="filter-section-label">{t('dashboard:filter_by_status')}</span>

      <FilterTab
        isActive={filters.type === Type.ORDER && filters.status === OrderAssignmentStatusFilter.ONGOING}
        totalCount={ongoingOrders.data?.data.totalElements.toString()}
        icon={<UpdateRoundedIcon fontSize='inherit' />}
        label={t('dashboard:ongoing_deals')}
        onClick={() => {
          params.set(
            [Params.TYPE, Type.ORDER],
            [Params.STATUS, OrderAssignmentStatusFilter.ONGOING]
          )
          handleLogOrderStatusSelection(Type.ORDER, OrderAssignmentStatusFilter.ONGOING)
        }}
      />
      <FilterTab
        isActive={filters.type === Type.ORDER && filters.status === OrderAssignmentStatusFilter.FINISHED}
        totalCount={finishedOrders.data?.data.totalElements.toString()}
        icon={<CheckCircleOutlineRoundedIcon fontSize='inherit' />}
        label={t('dashboard:finished_deals')}
        onClick={() => {
          params.set(
            [Params.TYPE, Type.ORDER],
            [Params.STATUS, OrderAssignmentStatusFilter.FINISHED]
          )
          handleLogOrderStatusSelection(Type.ORDER, OrderAssignmentStatusFilter.FINISHED)
        }}
      />
      <FilterTab
        isActive={filters.type === Type.ASSIGNMENT && filters.status === OrderAssignmentStatusFilter.ONGOING}
        totalCount={ongoingAssignments.data?.data.totalElements.toString()}
        icon={<UpdateRoundedIcon fontSize='inherit' />}
        label={t('dashboard:ongoing_assignments')}
        onClick={() => {
          params.set(
            [Params.TYPE, Type.ASSIGNMENT],
            [Params.STATUS, OrderAssignmentStatusFilter.ONGOING]
          )
          handleLogOrderStatusSelection(Type.ASSIGNMENT, OrderAssignmentStatusFilter.ONGOING)
        }}
      />
      <FilterTab
        isActive={filters.type === Type.ASSIGNMENT && filters.status === OrderAssignmentStatusFilter.FINISHED}
        totalCount={finishedAssignments.data?.data.totalElements.toString()}
        icon={<CheckCircleOutlineRoundedIcon fontSize='inherit' />}
        label={t('dashboard:finished_assignments')}
        onClick={() => {
          params.set(
            [Params.TYPE, Type.ASSIGNMENT],
            [Params.STATUS, OrderAssignmentStatusFilter.FINISHED]
          )
          handleLogOrderStatusSelection(Type.ASSIGNMENT, OrderAssignmentStatusFilter.FINISHED)
        }}
      />

      <MUIDivider margin={2} />

      <FilterTab
        isActive={filters.type === Type.ORDER && filters.status === OrderAssignmentStatusFilter.CANCELLED}
        totalCount={cancelledOrders.data?.data.totalElements.toString()}
        icon={<HighlightOffIcon fontSize='inherit' />}
        label={t('dashboard:cancelled_deals')}
        onClick={() => {
          params.set(
            [Params.TYPE, Type.ORDER],
            [Params.STATUS, OrderAssignmentStatusFilter.CANCELLED]
          )
          handleLogOrderStatusSelection(Type.ORDER, OrderAssignmentStatusFilter.CANCELLED)
        }}
      />
      <FilterTab
        isActive={filters.type === Type.ASSIGNMENT && filters.status === OrderAssignmentStatusFilter.CANCELLED}
        totalCount={cancelledAssignments.data?.data.totalElements.toString()}
        icon={<HighlightOffIcon fontSize='inherit' />}
        label={t('dashboard:cancelled_assignments')}
        onClick={() => {
          params.set(
            [Params.TYPE, Type.ASSIGNMENT],
            [Params.STATUS, OrderAssignmentStatusFilter.CANCELLED]
          )
          handleLogOrderStatusSelection(Type.ASSIGNMENT, OrderAssignmentStatusFilter.CANCELLED)
        }}
      />

    </div>

  )
}
