import { BEIGE_300, BEIGE_600, BLUE_100, BLUE_300, GRAY_800, GRAY_900 } from 'constants/styling/theme'
import { FC, Fragment } from 'react'
import { useCart, usePurchaseFlowOrderMeta } from '../../../_main/contexts'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import Collapse from '@mui/material/Collapse'
import { MUIDivider } from 'components/common/MUIDivider'
import { PurchaseFlowStepKey } from '../../../_main/contexts/PurchaseFlowUtilities.context' // Webpack crashes when imported from index in this file
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { getTimeDurationStringFromMinutesAndHours } from 'utils/time'
import { standardTimeoutConfig } from 'utils/animations'
import { useDynamicFlowController } from 'components/common/DynamicFlow'
import { useTranslation } from 'react-i18next'

const purchaseStepsWithPaymentTypeInfo = new Set<string>([
  PurchaseFlowStepKey.INSTRUCTIONS,
  PurchaseFlowStepKey.BILLING,
  PurchaseFlowStepKey.VALIDATION
])

/**
 * @component Summary of extra PF information like duration and selected payment type
 * @example <OrderAdditionalContent />
 */
export const OrderAdditionalContent: FC = () => {
  const { t } = useTranslation(['order', 'product_kind', 'purchase_flow'])
  const { selectedPaymentMethod } = usePurchaseFlowOrderMeta()
  const { sumDuration, cartInstructionList } = useCart()
  const { currentNode } = useDynamicFlowController()

  if (sumDuration === 0 && cartInstructionList.length === 0 && !selectedPaymentMethod) return null

  return (
    <Fragment>

      <MUIDivider margin={12} />

      <Stack gap={2}>

        {/** DURATION */}
        {sumDuration > 0 &&
          <Collapse
            appear={true}
            in={sumDuration > 0}
            timeout={standardTimeoutConfig}
          >
            <BorderBoxWrapper backgroundColor={BLUE_100} borderColor={BLUE_300} padding={1.5}>

              <Stack spacing={0.5} alignItems="baseline">

                <Typography variant="text-sm" color={GRAY_800}>
                  {t('cart.duration')}
                </Typography>

                <Typography variant="text-sm" fontWeight="600" color={GRAY_900}>
                  {`${t('cart.maximum')} ${getTimeDurationStringFromMinutesAndHours(sumDuration)}`}
                </Typography>

              </Stack>

            </BorderBoxWrapper>
          </Collapse>
        }

        {/* PAYMENT TYPE */}
        <Collapse
          appear={true}
          in={selectedPaymentMethod !== null && currentNode && purchaseStepsWithPaymentTypeInfo.has(currentNode.key)}
          timeout={standardTimeoutConfig}
        >
          <BorderBoxWrapper backgroundColor={BEIGE_300} borderColor={BEIGE_600} padding={1.5}>

            <Stack gap={.5}>

              <Typography variant="text-sm" color={GRAY_800}>
                {t('purchase_flow:common.payment_method')}
              </Typography>

              <Typography variant="text-sm" fontWeight="600" color={GRAY_900}>
                {t(`product_kind:${selectedPaymentMethod}`)}
              </Typography>

            </Stack>

          </BorderBoxWrapper>
        </Collapse>

      </Stack>

    </Fragment>
  )
}
