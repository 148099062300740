import { BLUE_600, GRAY_900 } from 'constants/styling/theme'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'

import Box from '@mui/material/Box'
import React from 'react'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

interface Props extends LinearProgressProps {
  barBackgroundColor?: string
  barColor?: string
  isShowPercentage?: boolean
}

const StyledLinearProgress = styled(({ barBackgroundColor, barColor, ...otherProps }: Props) => (
  <LinearProgress {...otherProps} />
))<Props>(({ theme, barBackgroundColor, barColor }) => ({
  height: 6,
  borderRadius: 5,
  backgroundColor: barBackgroundColor || theme.palette.grey[200],
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: barColor || BLUE_600,
  },
}))

/**
 * @component
 * LinearProgressBar displays a styled linear progress bar with a percentage label.
 *
 * @example
 * <LinearProgressBar value={50} />
 */
export const LinearProgressBar: React.FC<Props> = ({
  barBackgroundColor,
  barColor,
  isShowPercentage = false,
  ...defaultProps
}) => {

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>

      <Box sx={{ width: '100%' }}>
        <StyledLinearProgress
          variant="determinate"
          barBackgroundColor={barBackgroundColor}
          barColor={barColor}
          {...defaultProps}
        />
      </Box>

      {isShowPercentage &&
        <Box sx={{ minWidth: 35, ml: 1 }}>
          <Typography variant="text-sm" color={GRAY_900}>
            {`${Math.round(defaultProps.value ?? 0)}%`}
          </Typography>
        </Box>
      }

    </Box>
  )
}
