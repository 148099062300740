import { VirtualFurnitureRoomType, VirtualFurnitureStyleCategory } from 'constants/virtualFurniture'
import { useStyleSelection, useVirtualFurnitureStylesFilter } from '../main/contexts'

import { AnalyticsEvent } from 'utils/analytics'
import { FC } from 'react'
import { ImmutableMap } from 'models/helpers'
import { StagingItemSelectionPopup } from '../StagingItemSelectionPopup'
import { useRoomAPI } from 'components/contexts/RoomAPI.context'
import { useTranslation } from 'react-i18next'

/** 
 * @component
 * Displays popup with all available furniture templates for selection.
 * 
 * @example
 * <FurnitureItemSelectionPopup isOpen={true} />
 */
export const FurnitureItemSelectionPopup: FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const { t } = useTranslation(['style_selection_order_step', 'virtual_furniture_room_type'])
  const { updateRoom } = useRoomAPI()

  const {
    activeRoom,
    setRoomItemPopupType,
    stagingFlowAnalytics,
  } = useStyleSelection()

  const {
    filteredStylesByRoomType,
    availableFiltersByRoomType,
  } = useVirtualFurnitureStylesFilter()

  return (
    <StagingItemSelectionPopup
      availableItems={filteredStylesByRoomType}
      title={t('room_items.FURNITURE')}
      isOpen={isOpen}
      selectedItems={activeRoom.styleCodeMap.toObject()}
      filters={availableFiltersByRoomType}
      activeFilter={activeRoom.styleCategory}
      tabTranslationResolver={(tab) => t(`virtual_furniture_room_type:${tab}`)}
      onClose={() => {
        stagingFlowAnalytics(AnalyticsEvent.FURNITURE_FLOW_CLOSED)
        setRoomItemPopupType(null)
        updateRoom(activeRoom.key, { styleCategory: null })
      }}
      onFilterUpdate={style => {
        stagingFlowAnalytics(AnalyticsEvent.FURNITURE_TEMPLATES_FILTER, { FilterTypes: style })
        updateRoom(activeRoom.key, { styleCategory: style as VirtualFurnitureStyleCategory })
      }}
      onSelect={(selectedStyles) => {
        stagingFlowAnalytics(AnalyticsEvent.FURNITURE_TEMPLATES_SAVED, { TemplateIDs: selectedStyles })
        updateRoom(activeRoom.key, { styleCodeMap: ImmutableMap(selectedStyles) as ImmutableMap<VirtualFurnitureRoomType, string> })
        setRoomItemPopupType(null)
      }}
      onSelectAnalyticEvent={(style) => stagingFlowAnalytics(AnalyticsEvent.FURNITURE_TEMPLATES_SELECTED, { TemplateIDs: style })}
    />
  )
}
