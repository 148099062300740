import { BEIGE_300, BEIGE_600, GRAY_900 } from 'constants/styling/theme'
import { MenuItem, MenuItemProps, SxProps, Theme, Typography, menuItemClasses, styled } from '@mui/material'
import React, { ReactNode } from 'react'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** Dropdown item title */
  title?: string,
  /** Whether the dropdown item is disabled or not */
  disabled?: boolean,
  /** Whether the dropdown item is selected or not */
  isSelected?: boolean,
  /** OnClick action on dropdown item */
  onClick?: () => void
  children?: ReactNode
  /** Custom styles on dropdown item */
  sx?: SxProps<Theme>
  /** MenuItem props */
  props?: MenuItemProps
}

const StyledMenuItem = styled(MenuItem)({
  [`&.${menuItemClasses.selected}`]: {
    backgroundColor: BEIGE_600,
    ':hover': {
      backgroundColor: BEIGE_600
    }
  }
})

/**
 * @component MUI Dropdown item
 * @example
 * <MUIDropdownItem title="Deploy!" disabled={false} isSelected={true} />
 */
export const MUIDropdownItem: React.FC<Props> = ({
  title,
  disabled = false,
  isSelected,
  onClick,
  children,
  sx,
  props
}) => {

  return (
    <StyledMenuItem
      disabled={disabled}
      selected={isSelected}
      onClick={onClick}
      sx={{
        padding: '.8rem 1.2rem',
        ':hover': {
          backgroundColor: BEIGE_300
        },
        ...sx
      }}
      {...props}
    >
      {title && <Typography variant="text-md" color={GRAY_900}>{title}</Typography>}

      {children}
    </StyledMenuItem>
  )
}
