import { ActionEditMissionInternalComments, EditMissionInternalCommentsStore } from '.'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'
import { cloneDeep } from 'lodash'

/** Redux data reducer for edit mission internal comments */
export const reduceEditMissionInternalComments = (state: EditMissionInternalCommentsStore, action: ActionEditMissionInternalComments) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.EDIT_ASSIGNMENT_INTERNAL_COMMENTS) return state
  const newState = cloneDeep(state)

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
      newState[action.payload.missionId] = new APIRequest(APIRequestState.BEFORE_START)
      return newState
    case ActionTypeAPIEvent.FETCH:
      newState[action.payload.missionId] = new APIRequest(APIRequestState.RUNNING)
      return newState
    case ActionTypeAPIEvent.RECEIVED:
      newState[action.payload.missionId] = action.payload.request
      return newState
    default:
      return state
  }
}