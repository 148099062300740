import '../../Dashboard/Dashboard.sass'

import { AnalyticsEvent, logAnalyticsEvent, standardDebouncedLogAnalyticsEvent, trackIntercomEvent } from 'utils/analytics'
import { Box, Stack } from '@mui/material'
import { OrderAssignmentStatusFilter, Params } from 'constants/misc'
import { useEffect, useMemo } from 'react'

import { AssignmentDTO } from 'models/assignment'
import { AssignmentDTOIsCreativeDTO } from 'utils/typeguards'
import { BEIGE_300 } from 'constants/styling/theme'
import { ControlPanel } from 'components/pages/Dashboard/ControlPanel'
import { CreativeAssignmentListCard } from 'components/common/DealAssignmentCard/CreativeAssignmentListCard'
import { CreativeAssignmentStatusFilters } from './CreativeAssignmentStatusFilters.module'
import { InfiniteList } from 'components/common/InfiniteList/InfiniteList.component'
import { MUIDivider } from 'components/common/MUIDivider'
import { PageTransition } from 'utils/animations'
import { SearchFilter } from 'components/pages/Dashboard/SearchFilter'
import { useBkbnSearchParams } from 'utils/hooks/useBkbnSearchParams'
import { useDebouncedMemo } from 'utils/helpers'
import { useInfiniteListAssignments } from 'dataQueries'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * Page listing assignments of logged in creative with filters
 * @example <CreativeDashboard />
 */
export const CreativeDashboard: React.FC = () => {
  const { t } = useTranslation(['dashboard_creative', 'dashboard'])
  const { creativeData } = useUserData()
  const params = useBkbnSearchParams()

  // SEARCH DEBOUNCE HANDLING
  const search = useMemo(() => params.get(Params.SEARCH) || '', [params])
  const debouncedSearch = useDebouncedMemo(() => search, [search], 300, 600)

  // FILTERS
  const filters = useMemo(() => ({
    status: params.get(Params.STATUS) as OrderAssignmentStatusFilter || OrderAssignmentStatusFilter.ONGOING,
    userIds: params.getArray(Params.USERS),
    search: debouncedSearch || null,
  }), [params, debouncedSearch])

  const listTitle = useMemo(() => {
    switch (filters.status) {
      case OrderAssignmentStatusFilter.AVAILABLE:
        return t('dashboard:available_assignments')
      case OrderAssignmentStatusFilter.ONGOING:
        return t('dashboard:ongoing_assignments')
      case OrderAssignmentStatusFilter.FINISHED:
        return t('dashboard:finished_assignments')
      case OrderAssignmentStatusFilter.CANCELLED:
        return t('dashboard:cancelled_assignments')
      case OrderAssignmentStatusFilter.NEEDS_ATTENTION:
        return t('dashboard:assignments_needing_attention')

      default:
        return ''
    }
  }, [filters.status, t])

  // ASSIGNMENTS
  const assignmentList = useInfiniteListAssignments(
    'CREATIVE_INFINITE_ASSIGNMENT_LIST',
    filters
  )

  /* TODO:
      In future, make an actual system for these things to work like flags OR have some data to create calculated condition for the trigger
      For now, this is kinda handled by our Intercom settings which allows a tour to be only triggered once (per user), so all the subsequent triggers will be ignored
  */
  // Trigger "first time" dashboard visit intercom tour
  useEffect(() => {
    trackIntercomEvent(AnalyticsEvent.CREATIVE_FIRST_TIME_DASHBOARD)
  }, [])

  return (
    <PageTransition>
      <Stack
        direction="row"
        alignItems="stretch"
        sx={{
          position: 'absolute',
          top: '6.4rem',
          left: 0,
          bottom: 0,
          width: '100%',
          height: 'calc(100vh - 6.4rem)',
          overflow: 'hidden',
          background: BEIGE_300,
        }}
      >

        <Box flex="0 0 auto">
          <ControlPanel title={t('dashboard:filters')}>
            <div className='dashboard-filters'>

              <SearchFilter
                placeholder={t('search_assignment')}
                value={search}
                onChange={(searchValue) => {
                  standardDebouncedLogAnalyticsEvent('CT Filter - Search Bar', {
                    userEmail: creativeData?.email,
                  })

                  params.set([Params.SEARCH, searchValue])
                }}
                onClick={() => {
                  logAnalyticsEvent('CT Filter - Click Search Icon', {
                    userEmail: creativeData?.email,
                  })
                }}
              />

              <CreativeAssignmentStatusFilters filters={filters} />

            </div>
          </ControlPanel>
        </Box>

        <Stack flex="1 1 100%" alignItems="center" sx={{ overflowY: 'auto', paddingTop: '4rem' }}>

          <InfiniteList<AssignmentDTO>
            query={assignmentList}
            header={(
              <div>
                <h2>{listTitle}</h2>
                <MUIDivider />
              </div>
            )}
            renderItem={(data) => {
              if (!AssignmentDTOIsCreativeDTO(data)) return null

              return <CreativeAssignmentListCard key={data.id} assignment={data} />
            }}
            sx={{
              boxSizing: 'border-box',
              width: '95%',
              maxWidth: '90rem',
            }}
          />

        </Stack>

      </Stack>
    </PageTransition>
  )
}
