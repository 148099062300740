import { useGalleryAssignment, useGalleryDeal } from '../../_main/contexts'

import Button from 'components/common/Button/Button'
import CampaignIcon from '@mui/icons-material/Campaign'
import { FC } from 'react'
import { logAnalyticsEvent } from 'utils/analytics'
import styles from './GalleryProductFeedback.module.sass'
import { useTranslation } from 'react-i18next'

/**
 * @component
 * GalleryProductFeedback component displays a feedback section for a gallery product.
 * 
 * @example
 * <GalleryProductFeedback />
 */
export const GalleryProductFeedback: FC = () => {

  const { product, assignmentData } = useGalleryAssignment()
  const { dealData } = useGalleryDeal()

  const { t } = useTranslation(['gallery_product_feedback'])

  /** Sending the feedback is being handled by intercom. */
  const handleFeedbackClick = () => {
    logAnalyticsEvent('visual_feedback_triggered', {
      productCategory: product?.category,
      productType: product?.type,
      productKind: product?.kind,
      assignmentId: assignmentData?.id,
      orderID: dealData?.id
    })
  }

  return (
    <div className={styles.galleryFeedbackWrapper}>

      <h3>{t('description')}</h3>

      <Button
        id={product?.kind}
        textAndIcon
        onClick={handleFeedbackClick}
      >
        <CampaignIcon fontSize='large' />
        <span>{t('send_feedback')}</span>
      </Button>
    </div>
  )
}
