import { AutoAwesomeOutlined, ExpandLess } from '@mui/icons-material'
import { Box, IconButton, Stack, SwipeableDrawer, useMediaQuery } from '@mui/material'
import { CORAL_600, MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { CircularIconLoading, Loading } from '../Loading'
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { GenerateTextFlowStep, useVisualsMarketing } from './contexts/VisualsMarketing.context'

import { Color } from 'constants/assets'
import { EmptyContent } from '../../../common/EmptyContent'
import { PropertyIntroduction } from '../PropertyIntroduction'
import { VisualsMarketingSidebar } from '../VisualsMarketingSidebar/VisualsMarketingSidebar.module'
import { VisualsMarketingSocialMedia } from '../SocialMedia'
import styles from './VisualsMarketing.module.sass'
import { useTranslation } from 'react-i18next'
import { useVisualsMarketingForm } from './contexts/VisualsMarketing.form'

/**
 * Controller for Visuals Marketing page.
 * @example <VisualsMarketingController />
 */
export const VisualsMarketingController: FC = () => {
  const { t } = useTranslation(['gallery_marketing_section'])

  const isMobile = useMediaQuery(MOBILE_VIEW_QUERY)

  const { savedPropertyDetailsQuery } = useVisualsMarketingForm()
  const { generateTextStep, setIsPropertyDetailsEditing } = useVisualsMarketing()

  const [isDrawerOpened, setIsDrawerOpened] = useState(false)

  const handleMobileClose = useCallback(() => {
    setIsDrawerOpened(false)
    setIsPropertyDetailsEditing(false)
  }, [setIsPropertyDetailsEditing])

  const sidebar = useMemo(() => (
    <VisualsMarketingSidebar
      isMobile={isMobile}
      onClose={isMobile ? handleMobileClose : undefined} />
  ), [handleMobileClose, isMobile])

  useEffect(() => {
    if (!isMobile) setIsDrawerOpened(false)
  }, [isMobile])

  if (savedPropertyDetailsQuery.isPending) return (
    <Box sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}>
      <CircularIconLoading
        icon={<AutoAwesomeOutlined sx={{ fontSize: 24, color: CORAL_600 }} />}
      />
    </Box>
  )

  return (
    <div className={styles.contentWrapper}>
      <Stack
        direction={{ sx: 'column-reverse', sm: 'row' }}
        sx={{ height: isMobile ? 'calc(100% - 6rem)' : '100%', width: '100%' }}
        alignItems="stretch"
      >

        {/* CONTENT */}
        <Stack
          height="100%"
          flex="1 1 100%"
          overflow="auto"
          padding="0 4rem"
          alignItems="center"
          justifyContent={generateTextStep === GenerateTextFlowStep.TEXT_EXISTS ? 'flex-start' : 'center'}
        >

          {/** CENTERED SECTION */}
          {(
            generateTextStep === GenerateTextFlowStep.EMPTY ||
            generateTextStep === GenerateTextFlowStep.LOADING
          ) &&
            <>

              {/** EMPTY STEP */}
              {generateTextStep === GenerateTextFlowStep.EMPTY &&
                <Box maxWidth="40rem" width="100%">
                  <EmptyContent
                    icon={<AutoAwesomeOutlined className={styles.icon} sx={{ fontSize: 32 }} />}
                    title={t('gallery_marketing_section:empty.title')}
                    description={t('gallery_marketing_section:empty.description')}
                  />
                </Box>
              }

              {/** LOADING */}
              {generateTextStep === GenerateTextFlowStep.LOADING &&
                <Box maxWidth="40rem" width="100%">
                  <Loading />
                </Box>
              }

            </>
          }

          {/** GENERATED TEXT SECTION */}
          <Stack sx={{
            maxWidth: '100rem',
            width: '100%',
            padding: '5rem 0',
          }}>

            {generateTextStep === GenerateTextFlowStep.TEXT_EXISTS &&
              <Fragment>
                {/** PROPERTY DETAILS */}
                <PropertyIntroduction />

                {/** SOCIAL MEDIA */}
                <VisualsMarketingSocialMedia />
              </Fragment>
            }

          </Stack>

        </Stack>

        {/* SIDEBAR */}
        {!isMobile &&
          <Box flex="0 0 350px" bgcolor={Color.WHITE}>
            {sidebar}
          </Box>
        }

      </Stack>
      {isMobile &&
        <Box position="absolute" bottom="0" width="100%">

          <Stack
            boxSizing="border-box"
            bgcolor={Color.WHITE}
            borderRadius="24px 24px 0 0"
            width="100%"
            height="6rem"
            boxShadow="0 0 20px 0 rgba(0, 0, 0, 0.07)"

            direction="row"
            alignItems="center"
            padding="0 2rem"
            justifyContent="space-between"
          >
            <h2>Property information</h2>
            <IconButton onClick={() => setIsDrawerOpened(true)}>
              <ExpandLess fontSize="large" />
            </IconButton>
          </Stack>


          <SwipeableDrawer
            BackdropProps={{
              sx: {
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
              }
            }}
            PaperProps={{
              sx: {
                borderRadius: '2rem 2rem 0 0',
                height: '90vh',
                backgroundColor: 'white'
              }
            }}
            anchor="bottom"
            swipeAreaWidth={0}
            open={isDrawerOpened}
            onClose={() => handleMobileClose()}
            onOpen={() => setIsDrawerOpened(true)}
          >
            {sidebar}
          </SwipeableDrawer>
        </Box>
      }
    </div>
  )
}
