/** Reasons for declining an assignment */
export enum DeclineAssignmentReason {
  // Shooting too far away
  SHOOTING_TOO_FAR_AWAY = 'SHOOTING_TOO_FAR_AWAY',
  // Not interested in a cooperation with BKBN anymore
  NOT_INTERESTED_COOP_WITH_BKBN = 'NOT_INTERESTED_COOP_WITH_BKBN',
  // Not available
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  // Illness
  ILLNESS = 'ILLNESS',
  // Disagree with the remuneration
  DISAGREE_WITH_REMUNERATION = 'DISAGREE_WITH_REMUNERATION',
  // Not possible to fly in this zone
  NOT_POSSIBLE_TO_FLY_IN_THIS_ZONE = 'NOT_POSSIBLE_TO_FLY_IN_THIS_ZONE',
  // Other
  OTHER = 'OTHER',
}

/** Maximum number of characters for specifying the reason for decline of the assignment when "OTHER" reason is selected */
export const maxDeclineReasonTextCharacters = 150
