import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'

import { AxiosResponse } from 'axios'
import { StatusResponse } from 'models/redux'
import { Language } from 'translations/Language'
import { useAPI } from 'utils/API'
import { getMutation } from 'utils/reactQuery'

enum Endpoints {
  ADMIN_ONBOARD_CLIENT = '/user/client/onboard',
  ADMIN_RESEND_CLIENT_INVITATION = '/user/resendInvitation',
}

// MUTATIONS

interface AdminOnboardClientPayload {
  email: string,
  language?: Language,
  timezone?: string,
  organizationId?: string,
  country?: string,
}

/** Mutation for admin onboarding clinet */
export function useAdminOnboardClient() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, AdminOnboardClientPayload>(
    ({ email, language = undefined, timezone = undefined, organizationId = undefined, country = undefined }) => api.post(
      Endpoints.ADMIN_ONBOARD_CLIENT,
      {},
      {
        email,
        language,
        timezone,
        organizationId,
        country,
      },
      true
    ),
    (_, { email, language, timezone, organizationId, country }) => {
      logAnalyticsEvent(AnalyticsEvent.CLIENT_ONBOARDED_BY_ADMIN, {
        email,
        language,
        timezone,
        organizationId,
        country,
      })
    }
  )
}

/** Mutation for admin resending invitation to client */
export function useAdminResendClientInvitation() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, { email: string }>(
    ({ email }) => api.post(
      Endpoints.ADMIN_RESEND_CLIENT_INVITATION,
      {},
      {},
      true,
      {
        params: { email },
      }
    )
  )
}
