import './Logo.sass'

import React from 'react'
import logo from '../../../assets/img/logo/logo-backbone.svg'
import logo_white from '../../../assets/img/logo/logo-backbone-white.svg'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** Color of the logo, default to black */
  type?: 'black' | 'white'
  /** The additional classes to append */
  className?: string
}

/**
 * @component Graphical component containing a logo image
 * @example
 * <Logo type="black" className="class" />
 */
const Logo: React.FC<Props> = ({
  type = 'black',
  className = '',
}) => {
  let logoImg = logo
  if (type === 'white') logoImg = logo_white

  return (
    <div className={`logo ${className}`}><img src={logoImg} alt="Logo" /></div>
  )
}

export default Logo