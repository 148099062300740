import { SubscriptionPlan, SubscriptionPlanType } from 'models/user'

export const WORKSPACE_MEMBER_LIMIT_BY_PLAN: Record<SubscriptionPlan, number> = {
  [SubscriptionPlan.ENTERPRISE_FIXED_RATE]: Number.POSITIVE_INFINITY,
  [SubscriptionPlan.ENTERPRISE_PER_SEAT]: Number.POSITIVE_INFINITY,
  [SubscriptionPlan.ENTERPRISE]: Number.POSITIVE_INFINITY,
  [SubscriptionPlan.PERSONAL]: 1,
  [SubscriptionPlan.PRO]: 25,
  [SubscriptionPlan.PRO_PER_SEAT]: 25,
}

export const workspacePlanType: Record<SubscriptionPlan, string> = {
  [SubscriptionPlan.ENTERPRISE]: SubscriptionPlanType.ENTERPRISE,
  [SubscriptionPlan.ENTERPRISE_FIXED_RATE]: SubscriptionPlanType.ENTERPRISE,
  [SubscriptionPlan.ENTERPRISE_PER_SEAT]: SubscriptionPlanType.ENTERPRISE,
  [SubscriptionPlan.PRO]: SubscriptionPlanType.PRO,
  [SubscriptionPlan.PRO_PER_SEAT]: SubscriptionPlanType.PRO,
  [SubscriptionPlan.PERSONAL]: SubscriptionPlanType.PERSONAL,
}
