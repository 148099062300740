import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'
import { ActionRequest } from 'models/redux'
import { OrganizationMeStore } from './organizationMe.interfaces'
import { createAPIAction } from 'redux/Helpers'

/** Describes Action to get organization me */
export interface ActionOrganizationMe extends ActionRequest {
  payload: {
    request: OrganizationMeStore
  }
}

/** Creates action to get organization me */
export function getOrganizationMe(): ActionOrganizationMe {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.ORGANIZATION_ME], {
    request: new APIRequest(APIRequestState.RUNNING)
  })
}

/** Creates action to purge get organization me state */
export function purgeOrganizationMe(): ActionOrganizationMe {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.ORGANIZATION_ME], {
    request: new APIRequest(APIRequestState.BEFORE_START)
  })
}