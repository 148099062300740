import { FC, useCallback, useEffect, useState } from 'react'

import moment from 'moment-timezone'
import { useTimezone } from 'components/contexts/timezone.context'

/**
 * Displays unstyled span with time in provided format in provided timezone updated each second.
 * Time can be frozen to defined value to accomodate potential storyshots
*/
export const Clock: FC<{ className?: string, timezone?: string, format?: string, freezeTime?: moment.Moment }> = ({
  timezone,
  className,
  format = 'HH:mm',
  freezeTime,
}) => {
  const { userTimezone } = useTimezone()

  const getTime = useCallback(() => moment().tz(timezone || userTimezone).format(format), [format, timezone, userTimezone])
  const [time, setTime] = useState(getTime)

  useEffect(() => {
    if (!!freezeTime) {
      const intervalId = window.setInterval(() => setTime(getTime()), 1000)

      return () => window.clearInterval(intervalId)
    }
  }, [freezeTime, getTime, timezone, userTimezone])

  return (
    <span className={className}>{freezeTime ? freezeTime.format(format) : time}</span>
  )
}
