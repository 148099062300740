import { BEIGE_300, BEIGE_500, BEIGE_600, GRAY_700 } from 'constants/styling/theme'
import { Box, Stack, Typography, useMediaQuery } from '@mui/material'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { ToolBarType, useClientGallery } from '../_main/contexts'
import { Trans, useTranslation } from 'react-i18next'
import { supportEmail, supportEmailHref } from 'constants/contacts'
import { useGalleryAssignment, useGalleryProduct, useGalleryVisualSelection, useGalleryVisualType, useGalleryVisualsDownloadArchive, useGalleryVisualsMeta } from '../../contexts'

import { AnalyticsEvent } from 'utils/analytics'
import { ClientGallerySendRequest } from '../ClientGallerySendRequest'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { MUIButton } from 'components/common/MUIButton'
import { MUICheckbox } from 'components/common/MUICheckBox'
import { MUIDivider } from 'components/common/MUIDivider'
import { Path } from 'constants/router'
import { SettingDropdown } from '../common/SettingDropdown'
import { externalNavigate } from 'utils/helpers'
import { redesignMUITheme } from 'constants/styling/redesignTheme'
import { useNavigate } from 'react-router-dom'
import { useUserData } from 'components/contexts/UserDataContext'
import { useVisualsEditingSection } from '../../../common'

const editingServiceList = ['advanced_editing', 'home_staging', 'timelapse', 'slideshow']

/**
 * Client gallery actions panel which including feedback button, editing and download setting.
 * 
 * @example <ClientGalleryActionsPanel />
 */
export const ClientGalleryActionsPanel: FC = () => {
  const { t } = useTranslation(['gallery'])
  const navigate = useNavigate()

  const { hasUserActiveSubscription } = useUserData()
  const { purchasedVisualsCount } = useGalleryVisualsMeta()
  const { initiateArchiveDownload } = useGalleryVisualsDownloadArchive()
  const {
    templateId,
    currentWorkspaceData,
    onSubmitExpress
  } = useVisualsEditingSection()
  const {
    assignmentId,
    setIsToolBarOpen,
    setToolBarType
  } = useClientGallery()
  const {
    selectAllPurchased,
    selectedPurchasedVisualsCount,
  } = useGalleryVisualSelection()
  const {
    fileTypes,
    setFileTypes,
    downloadableTypes,
  } = useGalleryVisualType()
  const {
    isFeedbackButtonAllowed,
    logGalleryEvent,
  } = useGalleryAssignment()

  const { isVideo, isFloorPlan } = useGalleryProduct()

  const isMobileView = useMediaQuery(redesignMUITheme.breakpoints.down('sm'))

  const downloadAllPurchasedTriggered = useRef(false)
  const applyTemplateAllPurchasedTriggered = useRef(false)
  const [editAnchorEl, setEditAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [downloadAnchorEl, setDownloadAnchorEl] = useState<HTMLButtonElement | null>(null)

  useEffect(() => {
    if (downloadAllPurchasedTriggered.current && (selectedPurchasedVisualsCount === purchasedVisualsCount)) {
      downloadAllPurchasedTriggered.current = false
      initiateArchiveDownload()
    }
  }, [initiateArchiveDownload, purchasedVisualsCount, selectedPurchasedVisualsCount])

  useEffect(() => {
    if (applyTemplateAllPurchasedTriggered.current && (selectedPurchasedVisualsCount === purchasedVisualsCount)) {
      applyTemplateAllPurchasedTriggered.current = false
      onSubmitExpress()
    }
  }, [onSubmitExpress, purchasedVisualsCount, selectedPurchasedVisualsCount])

  const onDownloadAllPurchased = useCallback(() => {
    selectAllPurchased()
    setDownloadAnchorEl(null)
    downloadAllPurchasedTriggered.current = true
    logGalleryEvent(AnalyticsEvent.CLICK_DOWNLOAD_ALL_PURCHASED)
  }, [logGalleryEvent, selectAllPurchased])

  const onApplyTemplateAllPurchased = useCallback(() => {
    selectAllPurchased()
    setEditAnchorEl(null)
    applyTemplateAllPurchasedTriggered.current = true
  }, [selectAllPurchased])

  const onEditAllPurchased = useCallback(() => {
    if (!hasUserActiveSubscription) {
      logGalleryEvent(AnalyticsEvent.GALLERY_SAAS_UPSELL_EDIT_ALL_PURCHASED)
      return externalNavigate(Path.PRICING)
    }

    selectAllPurchased()
    navigate(Path.GALLERY_VISUALS_EDIT.replace(':id', assignmentId.toString()))
    logGalleryEvent(AnalyticsEvent.CLICK_EDIT_ALL_PURCHASED)
  }, [assignmentId, hasUserActiveSubscription, logGalleryEvent, navigate, selectAllPurchased])
  return (
    <Stack flexDirection={{ xs: 'column-reverse', sm: 'row' }} justifyContent="space-between" alignItems={{ xs: 'flex-start', sm: 'space-between' }} gap={{ xs: '1rem', sm: '0' }}>

      {!isVideo && isFeedbackButtonAllowed &&
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <ClientGallerySendRequest />
          {!isMobileView && <MUIDivider orientation="vertical" margin={12} sx={{ height: '2.8rem' }} />}
        </Stack>
      }

      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        {!isVideo && !isFloorPlan &&
          <SettingDropdown
            dropdownId='edit-dropdown'
            buttonLabel={t('edit_visuals')}
            anchorEl={editAnchorEl}
            setAnchorEl={setEditAnchorEl}
            disabled={!hasUserActiveSubscription}
            disabledActions={() => !hasUserActiveSubscription && externalNavigate(Path.PRICING)}
            tooltipTitle={!hasUserActiveSubscription ? t('only_for_subscription') : ''}
            onClick={() => {
              selectAllPurchased()
              setToolBarType(ToolBarType.EDITING)
              setIsToolBarOpen(true)
            }}
            startIcon={<EditOutlinedIcon />}
            endIcon={!hasUserActiveSubscription && <LockOutlinedIcon />}
          >
            <Stack sx={{ opacity: !hasUserActiveSubscription ? '.5' : '' }}>
              <Stack
                sx={{
                  padding: '1.6rem',
                  borderBottom: `solid .1rem ${BEIGE_600}`
                }}
              >
                <Typography variant="text-md" fontWeight="semiBold" paddingBottom=".8rem">{t('apply_template_to_all_purchased')}</Typography>

                {templateId ?
                  <MUIButton
                    type='secondaryBorder'
                    variant='outlined'
                    onClick={onApplyTemplateAllPurchased}
                  >
                    {t('worspace_template', { workspace: currentWorkspaceData?.name })}
                  </MUIButton>
                  :
                  <Stack
                    borderRadius=".8rem"
                    padding="1.6rem 3rem"
                    bgcolor={BEIGE_500}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="text-sm" fontWeight="medium">{t('visual_editing.no_available_templates_button')}</Typography>
                  </Stack>
                }
              </Stack>

              <Stack
                sx={{
                  padding: '1.6rem',
                  alignItems: 'flex-start'
                }}
              >
                <Typography variant="text-md" fontWeight="semiBold" paddingBottom=".8rem">{t('edit_options')}</Typography>

                <MUIButton
                  onClick={onEditAllPurchased}
                >
                  {t('edit_all_purchased')}
                </MUIButton>

                <Stack
                  padding=".8rem"
                  borderRadius=".8rem"
                  bgcolor={BEIGE_300}
                  marginTop="1.3rem"
                  sx={{
                    border: `1px solid ${BEIGE_600}`
                  }}
                >
                  <Typography variant="text-sm" fontWeight="regular">
                    <Trans
                      t={t}
                      i18nKey="visuals_editing_upsell.upsell_description"
                      values={{ supportEmail }}
                    >
                      <strong>
                        <a style={{ textDecoration: 'none', color: 'inherit' }} href={supportEmailHref()}>&nbsp;</a>
                      </strong>
                    </Trans>
                    <Stack sx={{ listStylePosition: 'inside', paddingInlineStart: '1rem', listStyle: 'disc', marginTop: '1rem' }}>
                      {editingServiceList.map(item => <li key={item}>{t(`visuals_editing_upsell.${item}`)}</li>)}
                    </Stack>
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </SettingDropdown>
        }

        {!isVideo && !isFloorPlan && <MUIDivider orientation="vertical" margin={12} sx={{ height: '2.8rem' }} />}

        <SettingDropdown
          dropdownId='download-dropdown'
          buttonLabel={t('download')}
          anchorEl={downloadAnchorEl}
          setAnchorEl={setDownloadAnchorEl}
          onClick={() => {
            selectAllPurchased()
            setToolBarType(ToolBarType.DOWNLOAD)
            setIsToolBarOpen(true)
          }}
          startIcon={<FileDownloadOutlinedIcon />}
          displaySecondaryButton={downloadableTypes.length > 1}
        >
          <Stack>
            <Stack
              sx={{
                padding: '1.6rem',
                borderBottom: `solid .1rem ${BEIGE_600}`
              }}
            >
              <Typography variant="text-md" fontWeight="semiBold" paddingBottom="1.2rem">{t('download_file_type')}</Typography>

              <Stack gap=".8rem">
                {Object.values(downloadableTypes).map(type => (
                  <Stack key={type} direction="row" justifyContent="flex-start" alignItems="start" gap=".8rem">
                    <Box paddingTop=".1rem">
                      <MUICheckbox
                        checked={fileTypes.has(type)}
                        onClick={() => {
                          if (fileTypes.has(type)) {
                            fileTypes.delete(type)
                            setFileTypes(new Set(fileTypes))
                          } else {
                            setFileTypes(new Set(fileTypes.add(type)))
                          }
                        }}
                      />
                    </Box>
                    <Stack>
                      <Typography variant="text-sm" fontWeight="semiBold">{t(`file_type.${type}`)}</Typography>
                      <Typography variant="text-sm" fontWeight="regular" color={GRAY_700} maxWidth="20rem">{t(`file_description.${type}`)}</Typography>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </Stack>

            <Stack
              sx={{
                padding: '1.6rem',
                alignItems: 'flex-start'
              }}
            >
              <Typography variant="text-md" fontWeight="semiBold" paddingBottom=".8rem">{t('download_options')}</Typography>

              <MUIButton onClick={onDownloadAllPurchased}>
                {t('download_all_purchased')}
              </MUIButton>
            </Stack>
          </Stack>
        </SettingDropdown>
      </Stack>

    </Stack>
  )
}
