import { AnalyticsEvent } from 'utils/analytics'
import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { VirtualFurnitureRoomRemovableOption, VirtualFurnitureRoomType } from 'constants/virtualFurniture'
import { useRenovationProducts, useStyleSelection, useVirtualFurnitureStylesFilter } from '../main/contexts'

import { GRAY_900 } from 'constants/styling/theme'
import { SelectableButtonsGrid } from 'components/common/SelectableButtonsGrid'
import Stack from '@mui/material/Stack'
import { ToggleFilterValue } from 'components/common/ToggleFilter'
import Typography from '@mui/material/Typography'
import styles from './RoomStyle.module.sass'
import { useRoomAPI } from 'components/contexts/RoomAPI.context'

export const removableObjectsType = VirtualFurnitureRoomType.WHOLE_ROOM

/**
 * @component
 * RoomRemovableObjects display selectable buttons for removing specific objects in the active room.
 * 
 * @example
 * <RoomRemovableObjects />
 */
export const RoomRemovableObjects: React.FC = () => {
  const { t } = useTranslation(['style_selection_order_step'])

  const { updateRoom } = useRoomAPI()
  const { activeRoom, isRoomEmptyType, stagingFlowAnalytics } = useStyleSelection()
  const { availableRoomRemovableOptions } = useVirtualFurnitureStylesFilter()
  const { renovationProductStringPrice } = useRenovationProducts()

  /** Available removable room items mapped to objects */
  const roomRemovableOptionToggleValues: ToggleFilterValue<VirtualFurnitureRoomRemovableOption>[] = useMemo(
    () => availableRoomRemovableOptions.map((removableOption) => ({
      value: removableOption,
      displayName: t(`virtual_furniture_room_removable_option:${removableOption}`)
    })),
    [availableRoomRemovableOptions, t]
  )

  const onSelectRemovableObjects = useCallback((item: VirtualFurnitureRoomRemovableOption) => {
    stagingFlowAnalytics(AnalyticsEvent.REMOVED_OBJECT_TYPE, { ObjectType: item })

    updateRoom(activeRoom.key, {
      furnitureRemoval: item,
    })
  }, [activeRoom.key, stagingFlowAnalytics, updateRoom])

  return (
    <div>

      <Stack gap={0.4} marginBottom={1.6} textAlign="center">

        <Typography color={GRAY_900} variant='text-lg' fontWeight={600}>
          {t('style_selection_order_step:room_removable_options')}
        </Typography>

        {!isRoomEmptyType &&
          <Typography variant="text-sm">
            <Trans
              t={t}
              i18nKey="style_selection_order_step:room_removable_options_description"
              values={{ price: renovationProductStringPrice }}
            />
          </Typography>
        }

      </Stack>

      <SelectableButtonsGrid
        values={roomRemovableOptionToggleValues}
        selectedValue={activeRoom.furnitureRemoval}
        className={styles.roomGridContainer}
        onSelect={item => onSelectRemovableObjects(item)}
      />

    </div>
  )
}
