import { BEIGE_300, BEIGE_600, BOX_SHADOW_LG, WHITE } from 'constants/styling/theme'
import { Popper, PopperProps, autocompleteClasses, styled } from '@mui/material'

import React from 'react'

/**
 * @interface DropdownPopperProps Input properties
 * @extends PopperProps
 */
export interface DropdownPopperProps extends PopperProps {
  /** Dropdown popper id */
  id?: string
}

const StyledDropdownPopper = styled(Popper)({
  zIndex: 1,
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: BOX_SHADOW_LG,
    borderRadius: '.8rem',
    backgroundColor: WHITE,
    marginTop: '.4rem',
  },
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 'unset',
    [`& .${autocompleteClasses.option}`]: {
      '&[aria-selected="true"]': {
        backgroundColor: BEIGE_600,
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
        backgroundColor: BEIGE_300
      },
    }
  },
  [`&. ${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
})

/**
 * @component MUI Dropdown Popper
 * @example
 * <MUIDropdownPopper />
 */
export const MUIDropdownPopper: React.FC<DropdownPopperProps> = ({
  id,
  ...props
}) => (
  <StyledDropdownPopper
    id={id}
    {...props}
  />
)
