import '../../Dashboard/Dashboard.sass'

import { Params, SortDirection } from 'constants/misc'
import { Stack, Typography } from '@mui/material'

import { FC } from 'react'
import { FilterTab } from 'components/common/FilterTab'
import { GRAY_700 } from 'constants/styling/theme'
import { OrderListFilters } from 'dataQueries'
import SwapVert from '@mui/icons-material/SwapVert'
import { useBkbnSearchParams } from 'utils/hooks/useBkbnSearchParams'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Current order list filters */
  filters: OrderListFilters
}

/**
 * @component Dashboard assignment sort direction filters
 * @example <AssignmentSortDirectionFilters />
 * */
export const AssignmentSortDirectionFilters: FC<Props> = ({ filters }) => {
  const { t } = useTranslation(['dashboard'])
  const params = useBkbnSearchParams()

  return (
    <Stack>

      <Typography variant="text-sm" fontWeight={600} color={GRAY_700} height="2rem">{t('sort_direction')}</Typography>

      <Stack flexDirection="column" gap="1rem" justifyContent="flex-start" margin=".8rem 0 2.4rem 0">

        <FilterTab
          isActive={filters.sortDirection === SortDirection.ASCENDING}
          label={t('ascending')}
          icon={<SwapVert fontSize='inherit' />}
          onClick={() => {
            params.set([Params.SORT_DIRECTION, SortDirection.ASCENDING])
          }}
        />

        <FilterTab
          isActive={filters.sortDirection === SortDirection.DESCENDING}
          label={t('descending')}
          icon={<SwapVert fontSize='inherit' />}
          onClick={() => {
            params.set([Params.SORT_DIRECTION, SortDirection.DESCENDING])
          }}
        />

      </Stack>
    </Stack>
  )
}
