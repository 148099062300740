import { FC, Fragment, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { SubscriptionPeriod, SubscriptionPlan } from 'models/user'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { APIRequestState } from 'constants/API'
import { ActionTypeAPIData } from 'constants/redux'
import BlockInfo from 'components/common/BlockInfo/BlockInfo'
import Button from 'components/common/Button/Button'
import { ChargebeeModal } from 'components/common/ChargebeeModal'
import { Path } from 'constants/router'
import { RequestStatus } from 'components/common/RequestStatus'
import { RootStore } from 'models/redux'
import { SubscriptionStepTemplate } from '../../SubscriptionStepTemplate'
import classnames from 'classnames'
import { createWorkspaceSubscription } from 'redux/Individual/Workspace/CreateWorkspaceSubscription'
import { logAnalyticsEvent } from 'utils/analytics'
import styles from './CompleteSubscription.module.sass'
import { useSubscriptionFlow } from '../../_main/SubscriptionFlow.context'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * Complete subscription step (STEP 3) of subscription flow.    
 * Extends base template.   
 * Redirects to root route if previousStep doesn't match the requirement.   
 * 
 * @example
 *  <CompleteSubscription />
 */
export const CompleteSubscription: FC = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation(['subscription_flow'])
  const { baseUserData, organizationData } = useUserData()
  const {
    workspaceSeatCount,
    createWorkspaceSubscriptionRequest,
    workspaceMembers,
    workspaceName,
    createdWorkspace,
    subscriptionBillingPeriod,
    setSubscriptionBillingPeriod,
  } = useSubscriptionFlow()

  const [confirmCheckout, setConfirmCheckout] = useState<boolean>(false)
  const [agreedWithTos, setAgreedWithTos] = useState<boolean>(false)

  const showCheckout = useMemo(() => {
    if (createWorkspaceSubscriptionRequest.state !== APIRequestState.OK) return false
    if (createWorkspaceSubscriptionRequest.data?.noAccountUsers.length) return confirmCheckout

    return true
  }, [confirmCheckout, createWorkspaceSubscriptionRequest.data, createWorkspaceSubscriptionRequest.state])

  const checkoutUrl = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.GET_SUBSCRIPTION_CHECKOUT_LINK].data?.url)

  // Warn about loosing progress on exit (before checkout window is reached)
  useEffect(() => {
    const handler = (e: BeforeUnloadEvent) => {
      // Cancel the event
      e.preventDefault() // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = t('steps.validation.leave_warning')
    }
    if (!showCheckout) {
      window.addEventListener('beforeunload', handler)
    }
    return () => {
      window.removeEventListener('beforeunload', handler)
    }
  }, [showCheckout, t])

  /** Log checkout modal opened */
  useEffect(() => {
    if (showCheckout) {
      logAnalyticsEvent('Check Out Subscription', {
        userEmail: baseUserData?.email,
        organizationId: organizationData?.id,
        planType: SubscriptionPlan.PRO_PER_SEAT,
        seats: workspaceSeatCount,
      })
    }
  }, [baseUserData?.email, organizationData?.id, showCheckout, workspaceSeatCount])

  // SHOULD NOT HAPPEN
  if (!workspaceName) return <BlockInfo color="red">Unexpected error</BlockInfo>

  return (
    <SubscriptionStepTemplate
      title={t('steps.validation.title')}
      alignAction="left"
      action={
        <Button
          disabled={!!createdWorkspace}
          onClick={() => navigate(Path.SUBSCRIPTION_CREATION)}
          type="secondary nobackground"
        >
          {t('back')}
        </Button>
      }
      boxContent={
        <div className={styles.boxContent}>

          <RequestStatus
            request={createWorkspaceSubscriptionRequest}
            spaceBottomRem={2}
          />

          {createWorkspaceSubscriptionRequest.state !== APIRequestState.OK &&
            <Fragment>

              <div className={styles.billingPeriods}>

                <h3>{t('steps.validation.period_title')}</h3>

                <div className={styles.billingPeriodsRow}>

                  {Object.values(SubscriptionPeriod).map((period) => (
                    <span
                      key={period}
                      className={classnames(
                        styles.billingPeriod,
                        { [styles.isSelected]: subscriptionBillingPeriod === period }
                      )}
                      onClick={() => setSubscriptionBillingPeriod(period)}
                    >
                      {t(`steps.validation.periods.${period}`)}
                    </span>
                  ))}

                </div>

              </div>

              <label
                className="checkbox square"
                id={styles.tosCheck}
                htmlFor="tosCheckbox"
              >
                <input
                  type="checkbox"
                  id="tosCheckbox"
                  checked={agreedWithTos}
                  onChange={_ => setAgreedWithTos(!agreedWithTos)}
                />
                <span className="checkmark"></span>
                <Trans t={t} parent="span" i18nKey="steps.validation.tos_check">
                  <Link
                    to={Path.TERMS_AND_PRIVACY}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className={styles.link}
                  >
                    &nbsp;
                  </Link>
                </Trans>
              </label>

              <Button
                disabled={createWorkspaceSubscriptionRequest.state === APIRequestState.RUNNING || !agreedWithTos}
                onClick={() => {
                  logAnalyticsEvent('Click Confirm Subscription', {
                    userEmail: baseUserData?.email,
                    organizationId: organizationData?.id,
                    planType: SubscriptionPlan.PRO_PER_SEAT,
                  })
                  dispatch(createWorkspaceSubscription(
                    workspaceName,
                    workspaceMembers,
                    subscriptionBillingPeriod,
                    createdWorkspace?.id
                  ))
                }}
              >
                {t('steps.validation.action')}
              </Button>

            </Fragment>
          }

          {/* NO ACCOUNT EMAILS */}
          {createWorkspaceSubscriptionRequest.state === APIRequestState.OK && !!createWorkspaceSubscriptionRequest.data?.noAccountUsers.length &&
            <div className={styles.failedEmailsWrap}>

              <h3>{t('steps.validation.no_account_emails_title')}</h3>

              <Trans parent="p" t={t} i18nKey="steps.validation.no_account_emails_text">
                &nbsp;
              </Trans>

              <div className={styles.failedEmails}>
                {createWorkspaceSubscriptionRequest.data.noAccountUsers.map(user => (
                  <div key={user.email} className={styles.failedEmail}>{user.email}</div>
                ))}
              </div>

            </div>
          }

          {createWorkspaceSubscriptionRequest.state === APIRequestState.OK
            && !!createWorkspaceSubscriptionRequest.data?.noAccountUsers.length
            &&
            <Button onClick={() => setConfirmCheckout(true)}>
              {t('steps.validation.continue')}
            </Button>
          }

        </div>
      }
    >

      <Trans parent="p" t={t} i18nKey="subscription_pricing_link">
        <a href={Path.PRICING} target="_blank" rel="nofollow noopener noreferrer">
          &nbsp;
        </a>
      </Trans>

      <p>
        <strong>
          {t('steps.validation.seats', { count: workspaceSeatCount })}
        </strong>
      </p>

      <Trans parent="p" t={t} i18nKey="steps.validation.monthly_price">
        <a href={Path.PRICING} target="_blank" rel="nofollow noopener noreferrer">
          &nbsp;
        </a>
      </Trans>

      <p>{t('steps.validation.trial')}</p>

      <ChargebeeModal
        title={t('steps.validation.subscription_checkout')}
        hostedPageUrl={checkoutUrl}
        isOpen={showCheckout}
      />

    </SubscriptionStepTemplate>
  )
}
