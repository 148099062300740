import { EntityKeys, QueryType, queryClient } from 'utils/reactQuery'
import { FC, ReactNode, createContext, useContext, useEffect, useMemo } from 'react'
import { UserQueryKeys, useGetAvailableBillingProducts } from 'dataQueries'

import { PreferredPaymentMethodType } from 'models/user'
import { productKindIsPreferredPaymentMethod } from 'utils/typeguards'
import { useAuth0 } from 'utils/auth'
import { useDispatch } from 'react-redux'

interface AvailablePaymentMethodsInterface {
  availablePaymentMethods: PreferredPaymentMethodType[]
}

const defaultAvailablePaymentMethodsValue: AvailablePaymentMethodsInterface = {
  availablePaymentMethods: []
}

/** Available payment methods context */
export const AvailablePaymentMethodsContext = createContext<AvailablePaymentMethodsInterface>(defaultAvailablePaymentMethodsValue)
/** Available payment methods context hook */
export const useAvailablePaymentMethods = (): AvailablePaymentMethodsInterface => useContext(AvailablePaymentMethodsContext)

/** Available payment methods context provider */
export const AvailablePaymentMethodsContextProvider: FC<{
  children?: ReactNode
}> = ({
  children
}) => {

    const dispatch = useDispatch()
    const { roles } = useAuth0()

    const userAvailableBillingProducts = useGetAvailableBillingProducts(roles.isClient)
    const userAvailableBillingProductsData = useMemo(() => userAvailableBillingProducts.data, [userAvailableBillingProducts.data])

    const availablePaymentMethods: PreferredPaymentMethodType[] = useMemo(() => {
      if (!userAvailableBillingProductsData) return []

      // Filter and retype products to payment methods
      return userAvailableBillingProductsData.reduce((methods: PreferredPaymentMethodType[], product) => {
        if (!productKindIsPreferredPaymentMethod(product.kind)) return methods
        return [...methods, product.kind as PreferredPaymentMethodType]
      }, [])
    }, [userAvailableBillingProductsData])

    // Reset available payment methods on destroy
    useEffect(() => {
      return () => {
        queryClient.resetQueries({ queryKey: [EntityKeys.USER_WORKSPACE, QueryType.GET, UserQueryKeys.AVAILABLE_BILLING_PRODUCTS] })
      }
    }, [dispatch, roles.isClient])


    return (
      <AvailablePaymentMethodsContext.Provider value={{ availablePaymentMethods }}>
        {children}
      </AvailablePaymentMethodsContext.Provider>
    )
  }
