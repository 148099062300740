import { FC, useEffect, useState } from 'react'
import { firebaseAnalytics, firebaseController, googleAnalyticsController, mixpanelController } from 'utils/analytics'

import { useLocation } from 'react-router-dom'

/** Logs pathnames to analytics */
const PathLogger: FC = () => {
  const location = useLocation()
  const [lastPath, setLastPath] = useState<string>()

  // Debounce last pathname
  useEffect(() => {
    const { pathname } = location
    if (lastPath !== pathname) setLastPath(pathname)
  }, [location, lastPath])

  // Analytics page tracking
  useEffect(() => {
    if (!lastPath) return
    if (firebaseController.analytics) {
      firebaseAnalytics.setCurrentScreen(firebaseController.analytics, lastPath)
      firebaseAnalytics.logEvent(firebaseController.analytics, 'screen_view', { firebase_screen: lastPath, firebase_screen_class: 'PathLogger', app_name: firebaseController.analytics?.app.name, screen_name: lastPath })
    }
    googleAnalyticsController.instance?.page()
    googleAnalyticsController.instance?.track('screen_view', { app_name: googleAnalyticsController.app_name, screen_name: lastPath })
    mixpanelController.instance?.track('screen_view', { app_name: googleAnalyticsController.app_name, screen_name: lastPath })
  }, [lastPath])

  return null
}

export default PathLogger
