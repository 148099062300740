/** Enum of available information types */
export enum InformationType {
  PROPERTY_INFORMATION = 'PROPERTY_INFORMATION',
  BUILDING_LAYOUT = 'BUILDING_LAYOUT',
  CONSTRUCTION_METHOD = 'CONSTRUCTION_METHOD',
  ENERGY_AND_HEATING = 'ENERGY_AND_HEATING',
  ADDITIONAL_INFORMATION = 'ADDITIONAL_INFORMATION',
}

/** Enum of available building types */
export enum BuildingType {
  SINGLE_FAMILY_HOUSE = 'SINGLE_FAMILY_HOUSE',
  TWO_FAMILY_HOUSE = 'TWO_FAMILY_HOUSE',
  MULTI_FAMILY_HOUSE = 'MULTI_FAMILY_HOUSE',
  MIXED_USE_BUILDING = 'MIXED_USE_BUILDING',
}

/** Enum of available building location types */
export enum BuildingLocationType {
  DETACHED = 'DETACHED',
  TERRACED_HOUSE_MIDDLE = 'TERRACED_HOUSE_MIDDLE',
  SEMI_DETACHED = 'SEMI_DETACHED',
}

/** Enum of available global shape types */
export enum GlobalShapeType {
  RECTANGLE = 'RECTANGLE',
  L_SHAPE = 'L_SHAPE',
  T_SHAPE = 'T_SHAPE',
  U_SHAPE = 'U_SHAPE',
  OTHER = 'OTHER',
}

/** Enum of available walls lengths */
export enum Wall {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
}

/** Enum of available construction types */
export enum ConstructionType {
  MASSIVE_CONSTRUCTION = 'MASSIVE_CONSTRUCTION',
  LIGHTWEIGHT_CONSTRUCTION = 'LIGHTWEIGHT_CONSTRUCTION',
}

/** Enum of available solid/massive construction types */
export enum SolidConstructionType {
  SOLID_DOUBLE_SHELL_WALL_NO_INSULATION = 'SOLID_DOUBLE_SHELL_WALL_NO_INSULATION',
  SOLID_WALL_FULL_BRICKS_UP_TO_20CM = 'SOLID_WALL_FULL_BRICKS_UP_TO_20CM',
  SOLID_WALL_FULL_BRICKS_20_TO_30CM = 'SOLID_WALL_FULL_BRICKS_20_TO_30CM',
  SOLID_WALL_FULL_BRICKS_OVER_30CM = 'SOLID_WALL_FULL_BRICKS_OVER_30CM',
  SOLID_WALL_HOLLOW_BRICKS = 'SOLID_WALL_HOLLOW_BRICKS',
  SOLID_OTHER_CONSTRUCTION_UP_TO_20CM = 'SOLID_OTHER_CONSTRUCTION_UP_TO_20CM',
  SOLID_OTHER_CONSTRUCTION_OVER_20CM = 'SOLID_OTHER_CONSTRUCTION_OVER_20CM'
}

/** Enum of available lightweight construction types */
export enum LightweightConstructionType {
  LIGHTWEIGHT_TIMBER_WITH_CLAY_BRICK_INFILL = 'LIGHTWEIGHT_TIMBER_WITH_CLAY_BRICK_INFILL',
  LIGHTWEIGHT_TIMBER_WITH_SOLID_BRICK_INFILL = 'LIGHTWEIGHT_TIMBER_WITH_SOLID_BRICK_INFILL',
  LIGHTWEIGHT_OTHER_WOOD_CONSTRUCTION = 'LIGHTWEIGHT_OTHER_WOOD_CONSTRUCTION',
  LIGHTWEIGHT_SOLID_WOOD_WALL = 'LIGHTWEIGHT_SOLID_WOOD_WALL'
}

/** Enum of available roof condition types */
export enum RoofConditionType {
  NOT_EXPANDED_TOP_FLOOR_INSULATED = 'NOT_EXPANDED_TOP_FLOOR_INSULATED',
  NOT_EXPANDED_UNINSULATED = 'NOT_EXPANDED_UNINSULATED',
  NOT_EXPANDED_ROOF_INSULATED = 'NOT_EXPANDED_ROOF_INSULATED',
  EXPANDED_ROOF_INSULATED = 'EXPANDED_ROOF_INSULATED',
  EXPANDED_UNINSULATED = 'EXPANDED_UNINSULATED'
}

/** Enum of available roof pitch types */
export enum RoofPitchType {
  FLAT_ROOF = 'FLAT_ROOF',
  MEDIUM = 'MEDIUM',
  STEEP = 'STEEP',
}

/** Enum of available basement types */
export enum BasementType {
  NO_BASEMENT = 'NO_BASEMENT',
  UNHEATED_NO_WINDOW = 'UNHEATED_NO_WINDOW',
  UNHEATED_WINDOW_SOLAR_GAIN = 'UNHEATED_WINDOW_SOLAR_GAIN',
  HEATED_WINDOW_SOLAR_GAIN = 'HEATED_WINDOW_SOLAR_GAIN',
  HEATED_NO_WINDOW = 'HEATED_NO_WINDOW',
}

/** Enum of available basement ceiling types */
export enum BasementCeilingType {
  REINFORCED_CONCRETE = 'REINFORCED_CONCRETE',
  WOOD_BEAM_CEILING = 'WOOD_BEAM_CEILING',
  BRICK_OR_HOLLOW_STONE = 'BRICK_OR_HOLLOW_STONE',
}

/** Enum of available window types */
export enum WindowType {
  WOODEN_WINDOWS_SINGLE_GLAZING = 'WOODEN_WINDOWS_SINGLE_GLAZING',
  WOODEN_WINDOWS_DOUBLE_GLAZING = 'WOODEN_WINDOWS_DOUBLE_GLAZING',
  PLASTIC = 'PLASTIC',
  ALU_STEEL = 'ALU_STEEL',
  TRIPLE_THERMAL_INSULATION_GLAZING = 'TRIPLE_THERMAL_INSULATION_GLAZING',
  TRIPLE_THERMAL_INSULATION_GLAZING_PLASTIC_FRAME = 'TRIPLE_THERMAL_INSULATION_GLAZING_PLASTIC_FRAME',
}

/** Enum of available thermal bridge types */
export enum ThermalBridgeType {
  STANDARD = 'STANDARD',
  NEW_BUILDING = 'NEW_BUILDING',
  INTERNAL_WALL_INSULATION = 'INTERNAL_WALL_INSULATION',
}

/** Enum of available photovoltaic system types */
export enum PhotovoltaicSystemType {
  YES = 'YES',
  NO = 'NO'
}

/** Enum of available ventilation system types */
export enum VentilationSystemType {
  WINDOW_VENTILATION = 'WINDOW_VENTILATION',
  VENTILATION_SYSTEM_AVAILABLE = 'VENTILATION_SYSTEM_AVAILABLE'
}

/** Enum of available distribution types */
export enum DistributionType {
  WITHIN_THERMAL_ENVELOPE = 'WITHIN_THERMAL_ENVELOPE',
  OUTSIDE_THERMAL_ENVELOPE_IN_ROOF = 'OUTSIDE_THERMAL_ENVELOPE_IN_ROOF',
  OUTSIDE_THERMAL_ENVELOPE_IN_CELLAR = 'OUTSIDE_THERMAL_ENVELOPE_IN_CELLAR'
}

/** Enum of available ventilation types */
export enum VentilationType {
  CENTRAL = 'CENTRAL',
  DECENTRALIZED = 'DECENTRALIZED',
  EXHAUST_SYSTEM_NO_HEAT_RECOVERY = 'EXHAUST_SYSTEM_NO_HEAT_RECOVERY'
}

/** Enum of available fans types */
export enum FansType {
  AC_FAN = 'AC_FAN',
  DC_FAN = 'DC_FAN'
}

/** Enum of available heat recovery percentage types */
export enum HeatRecoveryPercentageTypes {
  SIXTY = 'SIXTY',
  SIXTY_FIVE = 'SIXTY_FIVE',
  SEVENTY = 'SEVENTY',
  SEVENTY_FIVE = 'SEVENTY_FIVE',
  EIGHTY = 'EIGHTY',
  EIGHTY_FIVE = 'EIGHTY_FIVE',
  NINETY = 'NINETY',
  NINETY_FIVE = 'NINETY_FIVE',
}

/** Enum of available heating fuel types */
export enum HeatingFuelType {
  OIL = 'OIL',
  GAS = 'GAS',
  WOOD_PELLETS_SOLIDS = 'WOOD_PELLETS_SOLIDS',
  DISTRICT_HEATING = 'DISTRICT_HEATING',
  HEAT_PUMP = 'HEAT_PUMP',
  ELECTRIC_HEATING = 'ELECTRIC_HEATING',
  INDIVIDUAL_STOVE = 'INDIVIDUAL_STOVE'
}

/** Enum of available orientation types */
export enum OrientationType {
  NORTH_WEST = 'NORTH_WEST',
  NORTH = 'NORTH',
  NORTH_EAST = 'NORTH_EAST',
  WEST = 'WEST',
  CENTERED = 'CENTERED',
  EAST = 'EAST',
  SOUTH_WEST = 'SOUTH_WEST',
  SOUTH = 'SOUTH',
  SOUTH_EAST = 'SOUTH_EAST',
}

/** Enum of available heater location types */
export enum HeaterLocationType {
  APARTMENT = 'APARTMENT',
  BOILER_ROOM = 'BOILER_ROOM'
}

/** Enum of available centralized water location types */
export enum WaterLocationType {
  IN_APARTMENT = 'IN_APARTMENT',
  IN_HEATING_ROOM = 'IN_HEATING_ROOM'
}

/** Enum of available night service types */
export enum NightServiceType {
  NO_SHUTDOWN_OR_REDUCTION = 'NO_SHUTDOWN_OR_REDUCTION',
  NIGHT_SHUTDOWN = 'NIGHT_SHUTDOWN',
  NIGHT_REDUCTION = 'NIGHT_REDUCTION'
}

/** Enum of available heating combination types */
export enum HeatingCombinationType {
  CENTRAL_HOT_WATER_GENERATION = 'CENTRAL_HOT_WATER_GENERATION',
  SOLAR_THERMAL_ENERGY_FOR_HEATING_SUPPORT = 'SOLAR_THERMAL_ENERGY_FOR_HEATING_SUPPORT',
  SOLAR_THERMAL_ENERGY_FOR_HOT_WATER_SUPPORT = 'SOLAR_THERMAL_ENERGY_FOR_HOT_WATER_SUPPORT'
}

/** Enum of available generated by types */
export enum GeneratedByType {
  ELECTRIC = 'ELECTRIC',
  GAS = 'GAS',
  HEAT_PUMP = 'HEAT_PUMP'
}

/** Enum of available decentralized electric heating types */
export enum DecentralizedElectricHeatingType {
  ELECTRIC_DAY_STORAGE = 'ELECTRIC_DAY_STORAGE',
  ELECTRIC_NIGHT_STORAGE = 'ELECTRIC_NIGHT_STORAGE',
  ELECTRIC_SMALL_STORAGE = 'ELECTRIC_SMALL_STORAGE',
  ELECTRIC_INSTANT_WATER_HEATER = 'ELECTRIC_INSTANT_WATER_HEATER',
  ELECTRIC_HEATER_ROD = 'ELECTRIC_HEATER_ROD'
}

/** Enum of available decentralized gas heating types */
export enum DecentralizedGasHeatingType {
  GAS_STORAGE_WATER_HEATER = 'GAS_STORAGE_WATER_HEATER',
  GAS_INSTANT_WATER_HEATER = 'GAS_INSTANT_WATER_HEATER'
}

/** Enum of available decentralized heat pump heating types */
export enum DecentralizedHeatPumpHeatingType {
  HEAT_PUMP_AIR_WATER_HEATER = 'HEAT_PUMP_AIR_WATER_HEATER',
  HEAT_PUMP_BASEMENT_AIR_WATER_HEATER = 'HEAT_PUMP_BASEMENT_AIR_WATER_HEATER'
}

/** Enum of available oil fuel types */
export enum OilFuelType {
  OIL_STANDARD_BLOWER_BOILER = 'OIL_STANDARD_BLOWER_BOILER',
  OIL_GAS_STANDARD_BURNER_EXCHANGE = 'OIL_GAS_STANDARD_BURNER_EXCHANGE',
  OIL_LOW_TEMPERATURE_BLOWER_BOILER = 'OIL_LOW_TEMPERATURE_BLOWER_BOILER',
  OIL_LOW_TEMPERATURE_BURNER_EXCHANGE_BLOWER_BOILER = 'OIL_LOW_TEMPERATURE_BURNER_EXCHANGE_BLOWER_BOILER',
  OIL_GAS_CONDENSING_BOILER = 'OIL_GAS_CONDENSING_BOILER'
}

/** Enum of available gas fuel types */
export enum GasFuelType {
  GAS_STANDARD_SPECIAL_HEATING_BOILER = 'GAS_STANDARD_SPECIAL_HEATING_BOILER',
  OIL_GAS_STANDARD_BURNER_EXCHANGE = 'OIL_GAS_STANDARD_BURNER_EXCHANGE',
  GAS_LOW_TEMPERATURE_SPECIAL_HEATING_BOILER = 'GAS_LOW_TEMPERATURE_SPECIAL_HEATING_BOILER',
  GAS_LOW_TEMPERATURE_BURNER_EXCHANGE = 'GAS_LOW_TEMPERATURE_BURNER_EXCHANGE',
  OIL_GAS_CONDENSING_BOILER = 'OIL_GAS_CONDENSING_BOILER'
}

/** Enum of available wood pellet fuel types */
export enum WoodPelletsFuelType {
  SOLIDS_AFTER_1994_BOILER_TYPE = 'SOLIDS_AFTER_1994_BOILER_TYPE',
  SOLIDS_AFTER_1994_PELLET_CENTRAL_HEATING_BOILER = 'SOLIDS_AFTER_1994_PELLET_CENTRAL_HEATING_BOILER',
  SOLIDS_TILE_STOVE_FIREPLACE = 'SOLIDS_TILE_STOVE_FIREPLACE'
}

export enum WoodPelletsEnergySourceType {
  SOLIDS_HARD_COAL = 'SOLIDS_HARD_COAL',
  SOLIDS_LIGNITE = 'SOLIDS_LIGNITE',
  SOLIDS_WOOD = 'SOLIDS_WOOD',
  SOLIDS_PELLETS = 'SOLIDS_PELLETS',
  SOLIDS_WOODCHIPS = 'SOLIDS_WOODCHIPS',
}

/** Enum of available district heating fuel types */
export enum DistrictHeatingFuelType {
  DISTRICT_HEATING_KWK_FOSSIL_FUEL_07 = 'DISTRICT_HEATING_KWK_FOSSIL_FUEL_07',
  //this enum has to be without 0 on the end for translations, but with 0 for BE enums
  DISTRICT_HEATING_KWK_RENEWABLE_FUEL = 'DISTRICT_HEATING_KWK_RENEWABLE_FUEL_0',
  DISTRICT_HEATING_HW_FOSSIL_FUEL_13 = 'DISTRICT_HEATING_HW_FOSSIL_FUEL_13',
  DISTRICT_HEATING_HW_RENEWABLE_FUEL_01 = 'DISTRICT_HEATING_HW_RENEWABLE_FUEL_01'
}

/** Enum of available heat pump fuel types */
export enum HeatPumpFuelType {
  HEAT_PUMP_WATER_WATER = 'HEAT_PUMP_WATER_WATER',
  HEAT_PUMP_GROUND_WATER = 'HEAT_PUMP_GROUND_WATER',
  HEAT_PUMP_AIR_WATER = 'HEAT_PUMP_AIR_WATER',
  HEAT_PUMP_EXHAUST_AIR_WATER = 'HEAT_PUMP_EXHAUST_AIR_WATER'
}

/** Enum of available electric heating fuel types */
export enum ElectricHeatingFuelType {
  ELECTRIC_HEATING_DIRECT_HEATING = 'ELECTRIC_HEATING_DIRECT_HEATING',
  ELECTRIC_HEATING_STORAGE_HEATING = 'ELECTRIC_HEATING_STORAGE_HEATING'
}

/** Enum of available individual stove fuel types */
export enum IndividualStoveFuelType {
  SINGLE_OVEN_GAS_ROOM_HEATER_CHIMNEY_BOUND = 'SINGLE_OVEN_GAS_ROOM_HEATER_CHIMNEY_BOUND',
  SINGLE_OVEN_GAS_ROOM_HEATER_WALL_UNIT = 'SINGLE_OVEN_GAS_ROOM_HEATER_WALL_UNIT',
  SINGLE_OVEN_OIL_FIRED_SINGLE_OVEN_EVAPORATOR_BURNER = 'SINGLE_OVEN_OIL_FIRED_SINGLE_OVEN_EVAPORATOR_BURNER',
  SINGLE_OVEN_TILE_STOVE_FIREPLACE = 'SINGLE_OVEN_TILE_STOVE_FIREPLACE',
  SINGLE_OVEN_COAL_FIRED_IRON_STOVE = 'SINGLE_OVEN_COAL_FIRED_IRON_STOVE'
}

export type FuelTypeAllowedEnums = OilFuelType | GasFuelType | WoodPelletsFuelType | DistrictHeatingFuelType | HeatPumpFuelType | ElectricHeatingFuelType | IndividualStoveFuelType


/** Enum of available heat transfer types */
export enum HeatTransferType {
  RADIATOR = 'RADIATOR',
  FLOOR_HEATING = 'FLOOR_HEATING',
  RADIATOR_FLOOR_HEATING_30_70 = 'RADIATOR_FLOOR_HEATING_30_70',
  RADIATOR_FLOOR_HEATING_50_50 = 'RADIATOR_FLOOR_HEATING_50_50'
}

/** Enum of available heating pipe types */
export enum HeatingPipeType {
  FLOOR_HEATING = 'FLOOR_HEATING',
  THERMAL_ENVELOPE_DISTRIBUTION = 'THERMAL_ENVELOPE_DISTRIBUTION'
}

/** Enum of available circulation pumps types */
export enum CirculatingPumpType {
  UNREGULATED = 'UNREGULATED',
  MULTI_STAGE_CONTROLLED = 'MULTI_STAGE_CONTROLLED',
  HIGH_EFFICIENCY_PUMP = 'HIGH_EFFICIENCY_PUMP'
}

export const handoverAndPumpingFields = new Set([
  HeatingFuelType.OIL,
  HeatingFuelType.GAS,
  HeatingFuelType.WOOD_PELLETS_SOLIDS,
  HeatingFuelType.DISTRICT_HEATING,
  HeatingFuelType.HEAT_PUMP,
])
