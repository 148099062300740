// QUERIES

import { APIRequestErrorType, Endpoint, JobStatus } from 'constants/API'
import { AxiosError, AxiosResponse } from 'axios'
import { EntityKeys, QueryType, getMutation } from 'utils/reactQuery'
import { GenerateMarketingMaterialsPayloadDTO, MarketingMaterialDTO, MarketingPropertyDetailsDTO, SavePropertyDetailsPayloadDTO } from 'models/visualsMarketing'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { JobDTO } from 'models/misc'
import { StatusResponse } from 'models/redux'
import { VisualsMarketingTargetPlatform } from 'components/pages/VisualsMarketing/_main/VisulalsMarketing.constants'
import { decideErrorType } from 'utils/APIDecideErrorType'
import { useAPI } from 'utils/API'

enum Endpoints {
  GET_PROPERTY_DETAILS = '/marketing/{orderId}/propertyDetails',
  GET_SAVED_MATERIALS = '/marketing/{orderId}/materials',
  SAVE_PROPERTY_DETAILS = '/marketing/{orderId}/propertyDetails',
  SAVE_MATERIAL = '/marketing/{orderId}/materials/{marketingMaterialId}',
  GENERATE_MATERIAL = '/marketing/{orderId}/materials/generate/text',
}

/** Query for obtaining saved property details */
export function useSavedMarketingPropertyDetails(orderId: number) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.VISUALS_MARKETING_PROPERTY_DETAILS, QueryType.GET, { orderId }],
    queryFn: () => api.get<MarketingPropertyDetailsDTO>(Endpoints.GET_PROPERTY_DETAILS, { orderId: orderId.toString() }, false)
  })
}

/** Query for obtaining saved marketing materials (returns 404 if no materials are yet saved) */
export function useSavedMarketingMaterials(orderId: number) {
  const api = useAPI<Endpoints>()

  return useQuery<Partial<Record<VisualsMarketingTargetPlatform, MarketingMaterialDTO>>, AxiosError>({
    queryKey: [EntityKeys.VISUALS_MARKETING_MATERIALS, QueryType.GET, { orderId }],
    queryFn: () => api.get<Partial<Record<VisualsMarketingTargetPlatform, MarketingMaterialDTO>>>(
      Endpoints.GET_SAVED_MATERIALS,
      { orderId: orderId.toString() },
      false
    ),
    enabled: orderId > 0,
  })
}

// MUTATIONS

/** Mutation for saving property details */
export function useSaveMarketingPropertyDetails() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, SavePropertyDetailsPayloadDTO & { onSuccess?: () => void }>(
    ({ orderId, data }) => api.post(
      Endpoints.SAVE_PROPERTY_DETAILS,
      { orderId: orderId.toString() },
      data,
      true
    ),
    (client, { orderId, onSuccess }) => {
      client.invalidateQueries({ queryKey: [EntityKeys.VISUALS_MARKETING_PROPERTY_DETAILS, QueryType.GET, { orderId }] })
      onSuccess?.()
    }
  )
}

/**
 * Mutation with flow for generating text
 * Call endpoint for receiving a jobID for generation and start longPolling it.
 * Resolve if obtaining jobId and long-polling succeeds
 * Reject otherwise
*/
export function useGenerateMarketingMaterial(targetPlatform: VisualsMarketingTargetPlatform) {
  const api = useAPI<Endpoints | Endpoint>()

  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

  return useMutation<boolean, unknown, GenerateMarketingMaterialsPayloadDTO>({
    mutationFn: async ({ orderId, payload }) => {

      const isError = (res: AxiosResponse) => {
        if (!res.request?.error) return false
        return decideErrorType(res.request.error) !== APIRequestErrorType.NONE
      }

      const job = await api.post<JobDTO>(
        Endpoints.GENERATE_MATERIAL,
        { orderId: orderId.toString() },
        {
          ...payload,
          targetPlatform
        },
        true
      )

      if (isError(job)) return Promise.reject(job.request?.error)

      // Long poll preparations
      let retries = 100
      let done = false
      let error = false
      let poll: AxiosResponse<JobDTO>

      // Long-poll until job is Finished, Failed or Retry limit has been reached
      do {
        poll = await api.get<JobDTO>(Endpoint.JOB, { id: job.data.id }, true)

        if ((!poll.status || isError(poll) || ![JobStatus.FINISHED, JobStatus.FAILED].includes(poll.data.status)) && retries-- > 0) {
          await delay(2000)
          continue
        }

        if (poll.data.status === JobStatus.FAILED || isError(poll) || retries <= 0) error = true
        done = true
      } while (done === false)

      if (error === true) {
        if (!poll.request.error && retries <= 0) {
          return Promise.reject({
            ...poll,
            response: {
              data: {
                status: 400,
                message: 'Polling retry limit reached',
                timestamp: new Date().getTime()
              }
            }
          })
        }
        return Promise.reject(poll.request.error)
      }

      return Promise.resolve(true)
    }
  })
}

/** 
 * Mutation with combined saving and generating flow.
 * Saving can be skipped via flag - by default saves always
*/
export function useSaveAndGenerateMarketingMaterial(targetPlatform: VisualsMarketingTargetPlatform) {
  const client = useQueryClient()

  const saveInfo = useSaveMarketingPropertyDetails()
  const generate = useGenerateMarketingMaterial(targetPlatform)

  return useMutation<boolean, unknown, GenerateMarketingMaterialsPayloadDTO & SavePropertyDetailsPayloadDTO & { shouldSave: boolean }>({
    mutationFn: async ({ orderId, payload, data, shouldSave = true }) => {

      if (shouldSave) {
        try {
          await saveInfo.mutateAsync({ orderId, data })
        } catch (e) {
          return Promise.reject(e)
        }
      }

      try {
        await generate.mutateAsync({ orderId, payload })
      } catch (e) {
        return Promise.reject(e)
      }

      return Promise.resolve(true)
    },
    onSuccess: (_, { orderId }) => client.invalidateQueries({ queryKey: [EntityKeys.VISUALS_MARKETING_MATERIALS, QueryType.GET, { orderId }] })
  })
}

/** Mutation for updating text of target platform */
export function useSaveMarketingMaterial() {
  const api = useAPI<Endpoints>()

  return getMutation<StatusResponse, { orderId: number, id: string, text: string, platform: VisualsMarketingTargetPlatform, onSuccess?: () => void }>(
    ({ id, orderId, text }) => api.put(
      Endpoints.SAVE_MATERIAL,
      {
        orderId: orderId.toString(),
        marketingMaterialId: id
      },
      { value: text },
      false
    ),
    (client, { orderId, platform, text, onSuccess }) => {
      client.setQueryData<Partial<Record<VisualsMarketingTargetPlatform, MarketingMaterialDTO>>>([EntityKeys.VISUALS_MARKETING_MATERIALS, QueryType.GET, { orderId }], (existingData) => ({
        ...existingData,
        [platform]: {
          ...(existingData?.[platform] || {}),
          text
        }
      }))
      client.invalidateQueries({ queryKey: [EntityKeys.VISUALS_MARKETING_MATERIALS, QueryType.GET, { orderId }] })
      onSuccess?.()
    }
  )
}
