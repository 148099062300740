import './LinkifyTextarea.sass'

import { FC, useState } from 'react'

import { LinkifyWrapper } from '../LinkifyWrapper/LinkifyWrapper.module'
import { multilineText } from 'utils/formatting'

export interface Props {
  /** The additional classes for textarea */
  className?: string
  /** The name attribute */
  name?: string
  /** The id attribute */
  id?: string
  /** Value of the input */
  value: string
  /** OnChange action to be called after textarea value has changed */
  onChange: (e: any) => void
}

/** Switch teaxtarea with clickable link block
 * @example
 *  <LinkifyTextarea 
 *    className={className}
 *    name={name}
 *    id={id}
 *    value={value}
 *    onChange={setValue}
 *  />
 */
export const LinkifyTextarea: FC<Props> = ({
  className,
  name,
  id,
  value,
  onChange
}) => {

  const [isEdit, setIsEdit] = useState(false)

  return (
    <LinkifyWrapper>
      {isEdit ?
        <textarea
          name={name}
          id={id}
          className={className}
          value={value}
          onChange={onChange}
          onBlur={() => setIsEdit(false)}
          autoFocus={isEdit}
        >
        </textarea> :
        <div
          id={id}
          className="mock-textarea"
          onClick={() => setIsEdit(true)}
        >
          {multilineText(value)}
        </div>
      }
    </LinkifyWrapper>
  )
}
