import { Box, Stack } from '@mui/material'

import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import { FC } from 'react'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import { GRAY_300 } from 'constants/styling/theme'
import { PostProps } from '../SocialMedia.module'
import SendIcon from '@mui/icons-material/Send'
import styles from './InstagramPost.module.sass'

/**
 * @component
 * InstagramPost component that represents an Instagram-like post.
 * 
 * @example
 * <InstagramPost
 *   textInput={<p>This is an awesome post!</p>}
 * />
 */
export const InstagramPost: FC<PostProps> = ({ textInput }) => {

  return (
    <BorderBoxWrapper elevation={'lg'}>

      {/** HEADER */}
      <div className={styles.header}>
        <div className={styles.photo} />
        <p>AwesomeRealEstate</p>
      </div>

      {/** IMAGE PLACEHOLDER */}
      <Box sx={{
        width: '100%',
        height: 240,
        backgroundColor: GRAY_300
      }}
      />

      {/** FOOTER */}
      <div className={styles.footer}>

        <Stack spacing={2} direction="row">

          <FavoriteBorderOutlinedIcon fontSize='large' />
          <ChatBubbleOutlineIcon fontSize='large' />
          <SendIcon fontSize='large' />

          <div className={styles.rightIcon}>
            <BookmarkBorderIcon fontSize='large' />
          </div>

        </Stack>

        {/** GENERATED TEXT INPUT */}
        {textInput}

      </div>

    </BorderBoxWrapper>
  )
}
