import { EditSavedPaymentMethodPopupContentController } from './EditSavedPaymentMethodPopupContent.controller'
import { FC } from 'react'
import { SavedCardDetailsFieldsProvider } from 'components/forms/SavedCardDetails/'
import { SavedSepaDetailsFieldsContextProvider } from 'components/forms/SavedSepaDetails/'
import { useEditPaymentMethodContext } from '../_main/contexts'

/** 
 * Shows form fields and action buttons for editing saved payment method
 * @example <SavedPaymentMethodEditPopupContent />
 */
export const SavedPaymentMethodEditPopupContent: FC = () => {

  const { editedPaymentMethod, expirationDateString } = useEditPaymentMethodContext()

  return (
    <SavedSepaDetailsFieldsContextProvider defaultValues={editedPaymentMethod?.billingAddress}>
      <SavedCardDetailsFieldsProvider defaultValues={{ cardExpiration: expirationDateString }}>
        <EditSavedPaymentMethodPopupContentController />
      </SavedCardDetailsFieldsProvider>
    </SavedSepaDetailsFieldsContextProvider>
  )
}