import { ActionLoadPurchaseSessionVisual, PurchaseSessionVisualStore } from '.'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { FileManipulationKey, downloadVisualTypeToBaseType } from 'constants/visual'

import { cloneDeep } from 'lodash'

/** THUMB & WEB */
const downloadTypeCount = 2

// general reducer for all purchase flow session loads
const reduceAction = (state: PurchaseSessionVisualStore, action: ActionLoadPurchaseSessionVisual, progress: number) => {
  const { sessionId, productId, productSerial, manipulation, file, type, originalFilename, replaces } = cloneDeep(action.payload)
  const fileName = !replaces ? file.name : replaces
  const newState = cloneDeep(state)

  switch (manipulation) {
    case FileManipulationKey.UPLOAD:
      // if (action.type[0] === ACTION_TYPE_API_EVENTS.FETCH) return newState
      const uploadState = {
        ...newState,
        [sessionId]: {
          ...newState[sessionId],
          [productId]: {
            ...newState[sessionId]?.[productId],
            [productSerial]: {
              ...newState[sessionId]?.[productId]?.[productSerial],
              [manipulation]: {
                ...newState[sessionId]?.[productId]?.[productSerial]?.[manipulation],
                [fileName]: {
                  ...newState[sessionId]?.[productId]?.[productSerial]?.[manipulation]?.[fileName],
                  [type]: {
                    ...newState[sessionId]?.[productId]?.[productSerial]?.[manipulation]?.[fileName]?.[type],
                    ...cloneDeep(action.payload),
                    progress,
                  }
                }
              }
            }
          }
        }
      }

      // Delete (originalFilename) upload file object
      if (originalFilename && uploadState?.[sessionId]?.[productId]?.[productSerial]?.[FileManipulationKey.UPLOAD]?.[originalFilename]?.[type]) {
        if (Object.keys(uploadState?.[sessionId]?.[productId]?.[productSerial]?.[FileManipulationKey.UPLOAD]?.[originalFilename] || {}).length === 1) {
          // Delete the whole wrapper
          delete uploadState?.[sessionId]?.[productId]?.[productSerial]?.[FileManipulationKey.UPLOAD]?.[originalFilename]
        } else delete uploadState?.[sessionId]?.[productId]?.[productSerial]?.[FileManipulationKey.UPLOAD]?.[originalFilename]?.[type]
      }

      // Delete download file object if replacing
      if (replaces) {
        // Download file object wrapper is present
        if (uploadState?.[sessionId]?.[productId]?.[productSerial]?.[FileManipulationKey.DOWNLOAD]?.[replaces]) {
          // Delete whole wrapper (all types)
          delete uploadState?.[sessionId]?.[productId]?.[productSerial]?.[FileManipulationKey.DOWNLOAD]?.[replaces]
        }
      }

      return uploadState
    case FileManipulationKey.DOWNLOAD:
      if (!type) throw new Error('No folder specified')
      // Don't set if donwload not finished (signedUrl not obtained)
      if (action.type[0] === ActionTypeAPIEvent.FETCH) return newState
      const replacingState = {
        ...newState,
        [sessionId]: {
          ...newState[sessionId],
          [productId]: {
            ...newState[sessionId]?.[productId],
            [productSerial]: {
              ...newState[sessionId]?.[productId]?.[productSerial],
              [manipulation]: {
                ...newState[sessionId]?.[productId]?.[productSerial]?.[manipulation],
                [fileName]: {
                  ...newState[sessionId]?.[productId]?.[productSerial]?.[manipulation]?.[fileName],
                  [type]: {
                    ...newState[sessionId]?.[productId]?.[productSerial]?.[manipulation]?.[fileName]?.[type],
                    ...cloneDeep(action.payload),
                    lastUpdated: Date.now(),
                    progress,
                  }
                }
              }
            }
          }
        }
      }

      // Upload file is present
      if (replacingState?.[sessionId]?.[productId]?.[productSerial]?.[FileManipulationKey.UPLOAD]?.[fileName]?.[downloadVisualTypeToBaseType[type]]) {
        // If both THUMB & WEB are downloaded
        if (Object.keys(replacingState?.[sessionId]?.[productId]?.[productSerial]?.[FileManipulationKey.DOWNLOAD]?.[fileName] || {}).length === downloadTypeCount) {
          // Upload file wrapper present
          if (replacingState?.[sessionId]?.[productId]?.[productSerial]?.[FileManipulationKey.UPLOAD]?.[fileName]) {
            // Delete upload file wrapper object
            delete replacingState?.[sessionId]?.[productId]?.[productSerial]?.[FileManipulationKey.UPLOAD]?.[fileName]
          }
        }
      }
      return replacingState
    case FileManipulationKey.DELETE:
      const deletedState = {
        ...newState,
        [sessionId]: {
          ...newState[sessionId],
          [productId]: {
            ...newState[sessionId]?.[productId],
            [productSerial]: {
              ...newState[sessionId]?.[productId]?.[productSerial],
            }
          }
        }
      }

      // If file object for upload
      const fileObjectForUpload = deletedState?.[sessionId]?.[productId]?.[productSerial]?.[FileManipulationKey.UPLOAD]?.[fileName]?.[type]
      if (fileObjectForUpload) {
        // Set file as deleting
        fileObjectForUpload.deleting = true
        // If action received (delete finished), delete the file object
        if (action.type[0] === ActionTypeAPIEvent.RECEIVED) {
          delete deletedState?.[sessionId]?.[productId]?.[productSerial]?.[FileManipulationKey.UPLOAD]?.[fileName]?.[type]
        }
      }

      // If file object for download
      const fileObjectWrapperForDownload = deletedState?.[sessionId]?.[productId]?.[productSerial]?.[FileManipulationKey.DOWNLOAD]?.[fileName]
      if (fileObjectWrapperForDownload) {
        // Set all file types as deleting
        for (let type of Object.keys(fileObjectWrapperForDownload)) {
          const fileObjectForDownload = fileObjectWrapperForDownload[type]
          if (!fileObjectForDownload) continue
          fileObjectForDownload.deleting = true
        }
        // If action received (delete finished), delete all file objects
        if (action.type[0] === ActionTypeAPIEvent.RECEIVED) {
          delete deletedState?.[sessionId]?.[productId]?.[productSerial]?.[FileManipulationKey.DOWNLOAD]?.[fileName]
        }
      }
      return deletedState
    default:
      return newState
  }
}

/** Redux data reducer for (up/down)load purchase flow session visual */
export const reduceLoadPurchaseSessionVisual = (state: PurchaseSessionVisualStore, action: ActionLoadPurchaseSessionVisual) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.LOAD_PURCHASE_SESSION_VISUAL) return state

  switch (eventType) {
    case ActionTypeAPIEvent.FETCH:
      return reduceAction(state, action, 0)
    case ActionTypeAPIEvent.PROGRESS:
      return reduceAction(state, action, action.payload.progress || 0)
    case ActionTypeAPIEvent.RECEIVED:
      return reduceAction(state, action, 100)
    default:
      return state
  }
}