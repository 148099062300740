import { PurchaseFlowStepKey, usePurchaseFlowUtilities } from '../../../_main/contexts'

import { DynamicFlowStep } from 'components/common/DynamicFlow'
import { FC } from 'react'
import { ProductStepController } from './ProductStep.controller'

export const ProductStep: FC = () => {

  const { isProductStepValid } = usePurchaseFlowUtilities()

  return (
    <DynamicFlowStep
      stepKey={PurchaseFlowStepKey.PRODUCT}
      isValid={isProductStepValid}
    >
      <ProductStepController />
    </DynamicFlowStep>
  )
}
