import { BEIGE_500, CORAL_100, CORAL_600, GRAY_700 } from 'constants/styling/theme'
import { Step, StepIcon, StepLabel, stepIconClasses, stepLabelClasses, styled, svgIconClasses } from '@mui/material'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'

import Stepper from '@mui/material/Stepper'
import { capitalize } from 'lodash'

/**
 * @typedef StepperOrientation Available orientations
 */
export type StepperOrientation = 'horizontal' | 'vertical'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Array of steps */
  steps: Array<string>
  /** Active step */
  activeStep?: number
  /** Stepper orientation */
  orientation?: StepperOrientation
}

/**
 * @component Stepper reusing MUI Linear Stepper
 * @example
 * <MUIStepper steps={["First", "Second", "Third"]} activeStep={2} />
 */

/** {@link StepConnector} */
const StyledStepConnector = styled(StepConnector)(() => ({
  flex: 'none',
  [`& .${stepConnectorClasses.line}`]: { width: '2.4rem' },
  [`& .${stepConnectorClasses.completed}, &.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: { borderColor: CORAL_600 },
  },
}))

/** {@link Step} */
const StyledStep = styled(Step)(() => ({
  [`& .${svgIconClasses.root}`]: { color: BEIGE_500, borderRadius: '50%', width: '2.4rem', height: '2.4rem' },
  [`& .${stepIconClasses.text}`]: { fill: GRAY_700, fontSize: '1.2rem' },
  [`& .${stepIconClasses.active}`]: {
    [`& .${svgIconClasses.root}`]: { color: CORAL_100 },
    [`& .${stepIconClasses.text}`]: { fill: CORAL_600 },
  },
  [`& .${stepIconClasses.completed}`]: {
    [`& .${svgIconClasses.root}`]: { color: CORAL_100 },
    [`& .${stepIconClasses.text}`]: { fill: CORAL_600 },
  }
}))

/** {@link StepLabel} */
const StyledStepLabel = styled(StepLabel)(() => ({

  [`& .${stepLabelClasses.label}`]: {
    fontWeight: 600,
    fontSize: '1.4rem',
    [`&.${stepLabelClasses.active}`]: {
      fontWeight: 600,
      fontSize: '1.4rem'
    },
    [`&.${stepLabelClasses.completed}`]: {
      fontWeight: 600,
      fontSize: '1.4rem'
    }
  },

}))

export const MUIStepper: React.FC<Props> = ({
  steps,
  activeStep = 0,
  orientation = 'horizontal'
}) =>
  <Stepper
    activeStep={activeStep}
    orientation={orientation}
    connector={
      <StyledStepConnector />
    }
  >
    {steps.map((label) => {
      const stepProps: { completed?: boolean } = {}
      return (
        <StyledStep key={label} {...stepProps}>
          <StyledStepLabel StepIconComponent={(props) => (
            <StepIcon
              {...props}
              icon={props.icon}
              active={props.active || props.completed}
              completed={false} // BKBN design is using number instead of MUI Checkmark icon on completed
            />
          )}>{capitalize(label)}</StyledStepLabel>
        </StyledStep>
      )
    })}
  </Stepper>
