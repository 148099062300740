import { BaseInstructionCard, BaseInstructionCardPassDownProps } from '../BaseInstructionCard'
import { Bolt, SellOutlined, Whatshot } from '@mui/icons-material'

import { FC } from 'react'
import { InstructionPrice } from '../../InstructionPrice'
import { SubscriptionInstructionUpsell } from '../../../SubscriptionInstructionUpsell'
import { logAnalyticsEvent } from 'utils/analytics'
import { usePurchaseFlowOrderMeta } from 'components/pages/PurchaseFlow/_main/contexts/PurchaseFlowOrderMeta.context'
import { useTargetOrderUser } from 'components/pages/PurchaseFlow/_main/contexts'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * Instruction card for FAST_DELIVERY kind.
 * Shows badges and upsell prompt for non-subscribed users.
 * 
 * @example
 * <FastDeliveryInstructionCard
 *  isCardDisabled={false}
 *  isSelectionDisabled={false}
 *  instructionOption={instructionOptionDTO}
 *  instructionType={InstructionType.BILLING_PRIMARY}
 *  onCange={(changed) => console.log}
 * />
 */
export const FastDeliveryInstructionCard: FC<BaseInstructionCardPassDownProps> = ({
  instructionOption,
  instructionType,
  isCardDisabled,
  isSelectionDisabled,
}) => {

  const { t } = useTranslation(['fast_delivery_instruction_card'])
  const { clientData } = useUserData()
  const { targetUser } = useTargetOrderUser()
  const { selectedExtraServices, toggleExtraService } = usePurchaseFlowOrderMeta()

  return (
    <BaseInstructionCard
      title={targetUser.isSubscribed ? t('fast_delivery_instruction_card:title_subscribed') : undefined}
      instructionType={instructionType}
      isSelectionDisabled={isSelectionDisabled}
      instructionOption={instructionOption}
      isCardDisabled={isCardDisabled}
      onCheck={() => toggleExtraService(instructionOption.kind)}
      isChecked={selectedExtraServices.has(instructionOption.kind) || targetUser.isSubscribed}
      badges={
        !targetUser.isSubscribed
          ? [
            { color: 'orange', text: t('new_badge'), materialIcon: <Whatshot /> },
            { color: 'blue', text: t('fast_delivery_badge'), materialIcon: <Bolt /> }
          ]
          : [{ color: 'green', text: t('free_badge'), materialIcon: <SellOutlined /> }]
      }
      rightHeaderContent={
        !targetUser.isSubscribed && (
          <InstructionPrice feePrice={instructionOption.feePrice} />
        )
      }
      additionalBottomBoxContent={
        !targetUser.isSubscribed && (
          <SubscriptionInstructionUpsell
            title={t('title')}
            text={t('description')}
            onClick={() =>
              logAnalyticsEvent('unblock_SaaS_fast_delivery', {
                userEmail: clientData?.email,
                organizationId: clientData?.organizationId,
              })
            }
          />
        )
      }
    />
  )
}
