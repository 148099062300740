import { FC, Fragment, useMemo } from 'react'

import { CreativeAssignmentCardContextProvider } from '.'
import { CreativeAssignmentController } from './CreativeAssignmentCard.controller'
import { useParams } from 'react-router-dom'

export const CreativeAssignmentCard: FC = (props: any) => {
  const { id } = useParams<{ id?: string }>()
  const assignmentId = useMemo(() => (id && id) || undefined, [id])

  return (
    <Fragment>
      {typeof assignmentId !== 'undefined' &&
        <CreativeAssignmentCardContextProvider assignmentId={assignmentId}>
          <CreativeAssignmentController />
        </CreativeAssignmentCardContextProvider>
      }
    </Fragment>
  )
}
