import { APIRequestState, Endpoint, RoleMimetype } from 'constants/API'
import { ActionSelectVisuals, purgeSelectVisuals } from '.'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, select, takeEvery } from 'typed-redux-saga'
import { call, put } from 'redux-saga/effects'
import { listVisuals, listVisualsSaga } from '../ListVisuals'

import API from 'utils/API/API'
import { ArchiveVisualsTag } from '../ArchiveVisuals'
import { PIPEDRIVE_INFINITY } from 'constants/pipedrive'
import { RootStore } from 'models/redux'
import { VisualListResponseIsClientResponse } from 'utils/typeguards'
import { archiveVisuals } from '../ArchiveVisuals/ArchiveVisuals.actions'
import { archiveVisualsSaga } from '../ArchiveVisuals/ArchiveVisuals.sagas'
import { logAnalyticsEvent } from 'utils/analytics'

/** Saga which selects visuals */
export function* selectVisualsSaga(receivedAction: ActionSelectVisuals) {
  const {
    missionId,
    filenames,
    productId,
    types,
    type,
    billingProductId,
    immediatelyDownload,
    paymentIntentId,
    filenamesToDownload,
  } = receivedAction.payload

  const selectVisualsUrl = Endpoint.VISUAL_SELECT
    .replace('{assignmentId}', encodeURI(missionId.toString()))

  const selectAction: ActionSelectVisuals = yield generalFetch(ActionTypeAPIData.SELECT_VISUALS, () => API.post(
    selectVisualsUrl,
    {
      filenames,
      productId,
      stripePaymentIntentId: paymentIntentId,
      primaryBillingProductId: billingProductId,
    },
    {
      headers: {
        Accept: RoleMimetype.CLIENT, // TODO: Fix on BE so we can send proper accept header
      }
    },
    {
      endpoint: Endpoint.VISUAL_SELECT
    }
  ))

  const selectVisualsAction: ActionSelectVisuals = {
    ...selectAction,
    payload: {
      ...receivedAction.payload,
      ...selectAction.payload,
    }
  }
  yield put(selectVisualsAction)

  if (selectVisualsAction.payload.request.state !== APIRequestState.OK) return

  // Track additional visuals purchase event
  const getAllVisuals = (state: RootStore) => state.APIData[ActionTypeAPIData.LIST_VISUALS]?.[missionId]?.[type]
  const allVisuals = yield* select(getAllVisuals)
  const selectMaxValue = allVisuals?.data && VisualListResponseIsClientResponse(allVisuals.data) ? allVisuals.data.downloadCount : null

  if (selectMaxValue !== null && selectMaxValue !== PIPEDRIVE_INFINITY && filenames.length > selectMaxValue) {
    const additionalVisualsCount = selectMaxValue - filenames.length
    logAnalyticsEvent('additional_visuals_purchased', {
      assignmentId: missionId,
      productId,
      types,
      type,
      filenames,
      additionalVisualsCount,
    })
  }

  // Update listed visuals if stripe is not used (prevent data update before finalizing payment)
  if (!paymentIntentId) yield call(listVisualsSaga, listVisuals(missionId, type))

  // Start download if called for and purge select state
  if (immediatelyDownload) {
    yield call(archiveVisualsSaga, archiveVisuals(missionId, types, filenamesToDownload, ArchiveVisualsTag.CLIENT_ARCHIVE))
    yield put(purgeSelectVisuals(missionId))
  }
}

/** Watcher of select visuals action */
export function* selectVisualsWatcher() {
  yield all([
    takeEvery((action: ActionSelectVisuals) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.SELECT_VISUALS]), selectVisualsSaga),
  ])
}
