import { GRAY_100, GRAY_200, GRAY_400, GRAY_600, GRAY_900 } from 'constants/styling/theme'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import React, { useState } from 'react'

import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'

/** @interface Props for the CounterInput component. */
interface Props {
  /** The initial value of the counter. */
  value?: number
  /** The minimum allowed value of the counter. */
  minValue?: number
  /** The maximum allowed value of the counter. */
  maxValue?: number
  /** Indicates whether the counter is disabled. */
  disabled?: boolean
  /** When set to true, increment/decrement buttons will be hidden */
  hideControls?: boolean
  /** Callback function triggered on value change. */
  onChange?: (value: number) => void
}

type StyledIconButtonProps = {
  /** Indicates if the button is on the left side. */
  isLeftButton?: boolean
} & IconButtonProps

/** MUI IconButton component with custom styles. */
const StyledIconButton = styled(({ isLeftButton, ...defaultProps }: StyledIconButtonProps) => (
  <IconButton {...defaultProps} />
))(({ isLeftButton }) => ({
  color: GRAY_600,
  backgroundColor: GRAY_200,
  padding: '0.4rem 0.6rem',
  borderLeft: !isLeftButton ? `1px solid ${GRAY_400}` : 'unset',
  borderRight: isLeftButton ? `1px solid ${GRAY_400}` : 'unset',
  borderRadius: isLeftButton ? '8px 0 0 8px' : '0 8px 8px 0'
}))

/**
 * @component
 * CounterInput provides an input for numeric values with increment and decrement buttons.
 *
 * @example
 * <CounterInput
 *   value={5}
 *   minValue={0}
 *   maxValue={10}
 *   onChange={(value) => console.log(`New value: ${value}`)}
 * />
 */
export const CounterInput: React.FC<Props> = ({
  value = 0,
  minValue = 0,
  maxValue = 100,
  disabled,
  hideControls = false,
  onChange
}) => {
  const [count, setCount] = useState<number>(value)

  const handleIncrement = () => {
    if (count === maxValue || disabled) return // Ensure count doesn't go up maxValue

    setCount((prevCount) => {
      const newCount = prevCount + 1

      onChange?.(newCount)

      return newCount
    })
  }

  const handleDecrement = () => {
    if (disabled || count === minValue) return

    setCount((prevCount) => {
      const newCount = Math.max(prevCount - 1, minValue) // Ensure count doesn't go below minValue

      onChange?.(newCount)

      return newCount
    })
  }

  return (
    <BorderBoxWrapper
      height="3rem"
      width={hideControls ? '3rem' : '9rem'}
      minWidth={hideControls ? '3rem' : '8rem'}
      overflow="hidden"
      alignContent="center"
      borderColor={GRAY_400}
      backgroundColor={GRAY_100}
    >

      <Stack direction="row" height="100%">
        {/** Decrement button */}
        {!hideControls &&
          <StyledIconButton aria-label='increase' isLeftButton={true} onClick={handleDecrement}>
            <RemoveRoundedIcon sx={{ color: GRAY_900 }} />
          </StyledIconButton>
        }

        {/** Counter display */}
        <Stack flex={1} alignItems="center" justifyContent="center">
          <Typography color={count > 0 ? GRAY_900 : GRAY_600} variant='text-sm'>
            {count}
          </Typography>
        </Stack>

        {/** Increment button */}
        {!hideControls &&
          <StyledIconButton aria-label='increase' onClick={handleIncrement}>
            <AddRoundedIcon sx={{ color: GRAY_900 }} />
          </StyledIconButton>
        }

      </Stack>

    </BorderBoxWrapper>
  )
}
