import { SavedStripePaymentMethod } from 'models/user'

/**
 * @function getSavedPaymentMethodAddressString
 * @param method 
 * @returns Comma separated stringified format of billing address in format <line1?, line2?, city?>
 */
export const getSavedPaymentMethodAddressString = (method: SavedStripePaymentMethod): string => {
  const { line1, line2, city } = method.billingAddress
  const addressParameters = [line1, line2, city].filter((param) => !!param)

  return addressParameters.join(', ')
}