import { FC, ReactNode } from 'react'
import { Instagram, LinkedIn } from '@mui/icons-material'

import Box from '@mui/material/Box'
import { CreateSocialMediaPost } from './CreateSocialMediaPost'
import { GRAY_1000 } from 'constants/styling/theme'
import { InstagramPost } from './InstagramPost'
import { LinkedInPost } from './LinkedInPost'
import { PostActionsWrapper } from './PostActionsWrapper'
import { SocialMediaPostInput } from './SocialMediaPostInput'
import Stack from '@mui/material/Stack'
import { VisualsMarketingTargetPlatform } from '../_main/VisulalsMarketing.constants'
import styles from './SocialMedia.module.sass'
import { useMarketingMaterials } from '../_main/contexts/MarketingMaterials.context'
import { useTranslation } from 'react-i18next'

/**
 * Props for the Post component.
 * @interface PostProps
 */
export interface PostProps {
  /** The input for the post text. */
  textInput: ReactNode
}

const { REAL_ESTATE_BROCHURE, ...SocialPlatforms } = VisualsMarketingTargetPlatform

const iconMap: Partial<Record<VisualsMarketingTargetPlatform, ReactNode>> = {
  [VisualsMarketingTargetPlatform.LINKEDIN]: <LinkedIn sx={{ fontSize: 40, color: GRAY_1000 }} />,
  [VisualsMarketingTargetPlatform.INSTAGRAM]: <Instagram sx={{ fontSize: 40, color: GRAY_1000 }} />,
}

/**
 * Component for managing social media posts.
 * @example <VisualsMarketingSocialMedia />
 */
export const VisualsMarketingSocialMedia: FC = () => {

  const { t } = useTranslation(['visual_marketing_social_media'])

  const { marketingMaterials, generateText, generators } = useMarketingMaterials()

  return (
    <div className={styles.socialMedia}>

      {/** HEADER SECTION */}
      <Box sx={{ userSelect: 'none' }}>
        <h3>{t('title')}</h3>
      </Box>

      <Stack
        gap={3}
        width="100%"
        flexWrap="wrap"
        alignItems="flex-start"
        justifyContent="space-between"
        direction={{ xs: 'column', sm: 'row' }}
      >
        {Object.values(SocialPlatforms).map((platform: VisualsMarketingTargetPlatform) => {

          return (
            <Stack direction="column" flex="1" key={platform}>

              {/** CREATE POST */}
              {(!marketingMaterials[platform] || (!marketingMaterials[platform] && generators[platform].isPending)) &&
                <CreateSocialMediaPost
                  icon={iconMap[platform]}
                  isLoading={generators[platform].isPending}
                  platform={platform}
                  onCreate={() => generateText(platform, false)}
                />
              }

              {/** SOCIAL MEDIA POST */}
              {(marketingMaterials[platform] || (marketingMaterials[platform] && !generators[platform].isPending)) &&
                <PostActionsWrapper platform={platform}>

                  {/** INSTAGRAM POST */}
                  {platform === VisualsMarketingTargetPlatform.INSTAGRAM && (
                    <InstagramPost
                      textInput={
                        <SocialMediaPostInput platform={platform} />
                      }
                    />
                  )}

                  {/** LINKEDIN POST */}
                  {platform === VisualsMarketingTargetPlatform.LINKEDIN && (
                    <LinkedInPost
                      textInput={
                        <SocialMediaPostInput platform={platform} />
                      }
                    />
                  )}

                </PostActionsWrapper>
              }

            </Stack>
          )
        })}

      </Stack>

    </div>
  )
}
