import './Button.sass'

import React, { Fragment, ReactNode } from 'react'

import { Color } from 'constants/assets'
import ReactLoading from 'react-loading'
import classnames from 'classnames'

/**
 * @type ButtonType Available button types (reflect different styling)
 */
export type ButtonType = 'primary' | 'primary red' | 'primary orange' | 'primary blue' | 'primary green' | 'primary light' | 'primary light red' | 'primary light orange' | 'primary light blue' | 'primary light green' | 'secondary' | 'secondary noborder' | 'secondary red noborder' | 'secondary nobackground' | 'secondary nobackground noborder' | 'secondary red' | 'secondary orange' | 'secondary blue' | 'secondary green' | 'secondary red noborder' | 'secondary orange noborder' | 'secondary blue noborder' | 'secondary green noborder' | 'secondary red nobackground' | 'secondary orange nobackground' | 'secondary blue nobackground' | 'secondary green nobackground' | 'link' | 'link orange'

/**
 * @type ButtonHeight Available button heights
 */
export type ButtonHeight = 'default' | 'thin'

/**
 * @type ButtonBorder Available button border styles
 */
export type ButtonBorder = 'sharp' | 'rounded'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** Type of the button, either primary or secondary. Defines styling the default is "primary" */
  type?: ButtonType
  /** Button height */
  height?: ButtonHeight
  /** Button border style */
  borderStyle?: ButtonBorder
  /** The additional classes to append */
  className?: string
  /** Whether is the button disabled or not */
  disabled?: boolean
  /** Name for button element */
  name?: string
  /** Id for button element */
  id?: string
  /** Onclick action to be called */
  onClick?: (e: React.MouseEvent) => void
  /** Whether there is a text and icon as well */
  textAndIcon?: boolean
  /** Whether is loading in the button or not */
  isLoading?: boolean
  /** react-tooltip property for tooltip enabling */
  'data-tip'?: boolean
  /** react-tooltip property for tooltip assignment */
  'data-for'?: string
  children?: ReactNode
}

/**
 * @component Button with various available styling types (type), children can be a single Icon component or any other React.ReactNode or string
 * @example
 * <Button type="primary" height="default" onClick={e => console.log(e) className="class" id="id" disabled={true} name="buton"}>
 *    <Icon icon={IconType.ARROW} colorClass={ColorClass.BLACK} />
 *    <span>Some Text</span>
 * </Button>
 */
const Button: React.FC<Props> = React.forwardRef<HTMLButtonElement, Props>(({
  type = 'primary',
  height = 'default',
  borderStyle = 'rounded',
  onClick,
  className = '',
  disabled,
  name = '',
  id = '',
  textAndIcon = false,
  isLoading = false,
  'data-tip': dataTip,
  'data-for': dataFor,
  children,
}, ref) => (
  <button
    className={classnames('button', className, type, height, borderStyle, textAndIcon ? 'textandicon' : '')}
    disabled={disabled}
    onClick={e => onClick && onClick(e)}
    name={name}
    id={id}
    data-tip={dataTip}
    data-for={dataFor}
    ref={ref}
  >
    {isLoading ?
      <ReactLoading color={Color.GRAY_TEXT} type="spin" className="loading" /> :
      <Fragment>
        {textAndIcon &&
          <span className="textandicon-wrap">
            {children}
          </span>
        }
        {!textAndIcon &&
          <Fragment>
            {children}
          </Fragment>
        }
      </Fragment>
    }
  </button>
))

export default Button
