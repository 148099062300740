import React, { Fragment, ReactNode } from 'react'

import Button from 'components/common/Button/Button'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { MUITooltip } from 'components/common/MUITooltip'
import { Typography } from '@mui/material'
import classnames from 'classnames'
import styles from './EditorOption.module.sass'
import { useTranslation } from 'react-i18next'
import { useVisualsEditing } from '../../VisualsEditing.context'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Title of editor option */
  title: string,
  /** Hint of editor option */
  hint?: string,
  /** Whether the Editor Option button is active or not */
  isActive?: boolean
  /** react-tooltip property for tooltip */
  'data-for'?: string
  /** Data displayed in the react-tooltip */
  tooltipData?: string | React.ReactNode
  /** Whether the action to reset the editing setting is disabled */
  isResetDisabled?: boolean
  /** OnClick action to reset the editing setting */
  onResetClick?: () => void
  /** Toggle button in Editor Option */
  button?: ReactNode
  /** Extend content in Editor Option  */
  children?: ReactNode
}

/**
 * @component EditorOption 
 * @example
 * <EditorOption title="Editing title" button={<MUIIcon />} />
 */

export const EditorOption: React.FC<Props> = ({
  title = '',
  hint = '',
  isActive = false,
  'data-for': dataFor,
  tooltipData,
  onResetClick,
  isResetDisabled = false,
  button,
  children
}) => {
  const { t } = useTranslation(['visuals_editing'])
  const { handleEditingOptionChange } = useVisualsEditing()

  return (
    <div className={styles.editorOption}>
      <div className={styles.header}>
        <div className={styles.titleWrap}>
          {title}
          {!!tooltipData &&
            <Fragment>
              <MUITooltip
                id={dataFor}
                enterDelay={300}
                className={styles.tooltip}
                content={<Typography variant='text-sm'>{tooltipData}</Typography>}
              >
                <HelpOutlineOutlinedIcon className={styles.tooltipIcon} />
              </MUITooltip>
            </Fragment>
          }
        </div>

        {onResetClick &&
          <Button
            type="secondary nobackground noborder"
            height="thin"
            className={classnames(styles.resetButton, { [styles.active]: isActive })}
            onClick={() => handleEditingOptionChange(onResetClick)}
            disabled={isResetDisabled}
          >
            <MUITooltip
              id="visual_editing_reset_button"
              content={<Typography variant='text-sm'>{t('editor_options.reset_icon')}</Typography>}
              placement='left'
              enterDelay={300}
              className={styles.tooltipLeft}
            >
              <DeleteOutlineOutlinedIcon className={styles.resetIcon} data-for="visual_editing_reset_button" />
            </MUITooltip>
          </Button>
        }
      </div>

      {hint && <p className={styles.hint}>{hint}</p>}

      <div className={styles.buttonWrap}>
        {button}
      </div>
      {children}
    </div>
  )
}
