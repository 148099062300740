import { Color, IconType } from 'constants/assets'
import { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { UseMutationResult, UseQueryResult } from '@tanstack/react-query'

import Button from '../Button/Button'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import Icon from '../Icon/Icon'
import ReactLoading from 'react-loading'
import classnames from 'classnames'
import closeButtonStyles from 'components/styles/close-button.module.sass'
import { debounce } from 'lodash'
import { decideErrorType } from 'utils/APIDecideErrorType'
import styles from './QueryStatus.module.sass'

export type QueryStatusStateVariant = 'error' | 'idle' | 'pending' | 'success'

export type QueryDataType = UseMutationResult<any, any, any, any> | UseQueryResult<any, any>

interface Props {
  query: QueryDataType
  heading?: string
  successMessage?: string
  errorMessage?: string
  className?: string
  spaceTopRem?: number
  spaceBottomRem?: number
  showStates?: QueryStatusStateVariant[]
  onPurge?: () => void
}

const statesAllowingPurge = new Set(['success', 'error'])

/**
 * Displays loading/success/error states for query/mutation.
 * Accepts custom messages and optional purge action.
 * If purge action specified, X button appears to trigger it on click
 * 
 * States to be shown can be defined as Array of APIRequestStates  
 * *Default is ['loading', 'success', 'error']*
 * 
 * Special space top/bottom attributes for smooth close/open height transform.
 * 
 * @example
 * <QueryStatus query={useQuery result} />
*/
export const QueryStatus: FC<Props> = ({
  query,
  heading,
  successMessage,
  errorMessage,
  className,
  spaceBottomRem = 0,
  spaceTopRem = 0,
  showStates = ['error', 'success', 'pending'],
  onPurge,
}) => {

  const { t } = useTranslation(['request_status'])

  const divRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState<number>(0)

  // Not memo for convenient access to "prev value"
  const [showState, setShowState] = useState('idle')

  const isOpen = useMemo(() => {
    if (!query.status) return false

    return showStates.includes(query.status)
  }, [query.status, showStates])

  /** Calculated spacing based on open/close state */
  const { spaceTop, spaceBottom } = useMemo(() => {
    if (!isOpen) return {
      spaceTop: 0,
      spaceBottom: 0,
    }

    return {
      spaceTop: spaceTopRem,
      spaceBottom: spaceBottomRem,
    }
  }, [isOpen, spaceTopRem, spaceBottomRem])

  // Cache state to prevent blinking of states that are not supposed to be shown
  // Not memo for convenient access to "prev value"
  useEffect(() => {
    setShowState((currentShowState) => {
      if (showStates.includes(query.status)) {
        return query.status
      }

      return currentShowState
    })
  }, [query.status, showStates])

  // Calculate height on state change
  useEffect(
    () => {
      if (!isOpen) setHeight(0)
      else setHeight(divRef.current?.scrollHeight || 0)
    },
    // Needs to be recalculated on showState change as well to calculate appropriate height
    [isOpen, showState]
  )

  // Recalculate height on window resize - debounced
  useEffect(() => {
    const handlerFn = debounce(
      () => {
        if (!isOpen) setHeight(0)
        else setHeight(divRef.current?.scrollHeight || 0)
      },
      300,
      { maxWait: 500 }
    )

    // add listener
    window.addEventListener('resize', handlerFn)

    // cleanup listener
    return () => {
      window.removeEventListener('resize', handlerFn)
    }

  }, [isOpen])

  return (
    <div
      className={classnames(
        styles.requestStatus,
        className,
        !!query ? styles[showState] : undefined,
        { [styles.isClosed]: !isOpen }
      )}
      style={{
        height: `${height}px`,
        marginTop: `${spaceTop}rem`,
        marginBottom: `${spaceBottom}rem`
      }}
    >
      {!!query &&
        <div className={styles.content} ref={divRef}>

          {/* MAIN CONTENT */}
          <div className={classnames(styles.main, { [styles.isCenter]: !heading })}>

            {(showState === 'error' || showState === 'success') &&
              <div>
                <Icon
                  className={styles.icon}
                  icon={showState === 'error' ? IconType.DANGER : IconType.CHECK}
                  color={Color.WHITE}
                />
              </div>
            }

            {/* RUNNING */}
            {showState === 'pending' &&
              <ReactLoading
                type="spin"
                color={Color.GRAY_TEXT}
                className={styles.loading}
              />
            }

            <div className={styles.wrapper}>
              {showState !== 'idle' && !!heading &&
                <p className={styles.heading}>
                  <strong className={showState === 'pending' ? styles.gray : styles.white}>
                    {heading}
                  </strong>
                </p>
              }

              {/* ERROR */}
              {showState === 'error' && !!query.error &&
                <Fragment>

                  {/* To escape tags like strong/br/... */}
                  <Trans parent="p">
                    {errorMessage || <ErrorMessage error_type={decideErrorType(query.error)} />}
                  </Trans>

                </Fragment>
              }

              {/* OK */}
              {showState === 'success' &&
                <Fragment>

                  {/* To escape tags like strong/br/... */}
                  <Trans parent="p">
                    {successMessage || t('success')}
                  </Trans>

                </Fragment>
              }

            </div>

            {/* Button for onPurge trigger */}
            {!!onPurge && statesAllowingPurge.has(showState) &&
              <div className={closeButtonStyles.closeWrap}>
                <Button
                  className={styles.close}
                  type="secondary nobackground noborder"
                  onClick={onPurge}
                  height="thin"
                >
                  <Icon icon={IconType.CROSS} color={Color.WHITE} />
                </Button>
              </div>
            }

          </div>

          {/* META */}
          {/* ERROR META INFO */}
          {showState === 'error' && !!query.error &&
            <div className={styles.meta}>
              {query.error.response.data.status} - <span className={styles.errorMessage}>{query.error.response.data.message}</span> ({query.error.response.data.timestamp})
            </div>
          }

        </div>
      }
    </div>
  )
}
