import { Fragment, useState } from 'react'

import { AssignmentDTO } from 'models/assignment'
import { DealAssignmentCard } from 'components/common/DealAssignmentCard'
import { DealDTO } from 'models/deal'
import { DealDTOOrAssignmentDTOIsDealDTO } from 'utils/typeguards'
import { DealDetailPopup } from 'components/common/DealDetailPopup/DealDetailPopup'

interface AdminInfiniteListItemProps {
  data: AssignmentDTO | DealDTO
}

/**
 *  @component Admin dashboard Infinite List Item
 * @example <AdminInfiniteListItem data={data} />
 */
export const AdminInfiniteListItem: React.FC<AdminInfiniteListItemProps> = ({ data }) => {
  const [orderPopupId, setOrderPopupId] = useState<number | null>(null)

  return (
    <Fragment key={data.id}>
      <DealAssignmentCard
        data={data}
        onMoreInfoClick={() => setOrderPopupId(Number(data.id) || null)}
      />
      {DealDTOOrAssignmentDTOIsDealDTO(data) &&
        <DealDetailPopup
          data={data}
          isOpen={orderPopupId === data.id}
          onClickOutside={() => setOrderPopupId(null)}
          onClickClose={() => setOrderPopupId(null)}
        />
      }
    </Fragment>
  )
}
