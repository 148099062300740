import { FC, ReactNode } from 'react'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { WHITE } from 'constants/styling/theme'

/**
 * Props for the CircleIcon component.
 * @interface Props
 */
interface Props {
  /** The icon component to be displayed inside the circle. */
  icon: ReactNode
  /** The color of the circle background. */
  circleColor?: string
  /** The size of the circle. */
  size?: string
}

/**
 * @component 
 * CircleIcon component that displays an icon inside a circular container with a background color.
 * 
 * @example
 * <CircleIcon
 *   size="4rem"
 *   icon={<MyCustomIcon />}
 *   circleColor="blue"
 * />
 */
export const CircleIcon: FC<Props> = ({
  icon,
  size = '6rem',
  circleColor = WHITE
}) => {
  return (
    <Box
      sx={{
        width: size,
        height: size,
        borderRadius: '50%',
        backgroundColor: circleColor,
      }}
    >

      <Stack height="100%" alignItems="center" justifyContent="center">
        {icon}
      </Stack>

    </Box>
  )
}
