import { GRAY_200, GRAY_800, GRAY_900, MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { IconButton, Stack, Typography } from '@mui/material'
import React, { Fragment, KeyboardEvent, MouseEvent, useCallback } from 'react'

import { APIRequestErrorType } from 'constants/API'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { Close } from '@mui/icons-material'
import { Nullable } from 'models/helpers'
import { VisualClientDTO } from 'models/visuals'
import { Direction } from '../VisualsEditingPreviewGallery.module'
import EastRoundedIcon from '@mui/icons-material/EastRounded'
import ErrorMessage from 'components/common/ErrorMessage/ErrorMessage'
import { ExpandedVisual } from './ExpandedVisual.component'
import { KeyboardEventKey } from 'constants/misc'
import { MUIBadge } from 'components/common/MUIBadge'
import { MUIButton } from 'components/common/MUIButton'
import Modal from 'components/common/Modals/Modal/Modal'
import { ResponsiveDrawer } from 'components/common/ResponsiveDrawer'
import WestRoundedIcon from '@mui/icons-material/WestRounded'
import styles from './ExpandedVisualPopup.module.sass'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Whether the popup is open */
  isOpen: boolean
  /** Whether the preview image is selected or not */
  isSelected?: boolean
  /** Whether the preview image is selectable or not */
  isSelectable?: boolean
  /** Whether the gallery image can be marked as favorite or not */
  isFavoriteSelectable?: boolean
  /** Whether the gallery image is marked as favorite or not */
  isFavorite?: boolean
  /** Signed Url for displaying image in popup */
  signedUrl?: string
  /** File name of image */
  filename?: string
  /** Label of image title badge */
  label?: string
  /** OnClick action to switch to previous/next visual */
  switchExpandedVisual: (direction: Direction) => void
  /** OnClick action to close the popup */
  onClose: () => void
  /** OnSelect action to be called */
  onSelect?: (e: MouseEvent) => void
  /** onClick action to toggle favorite image */
  toggleFavoriteOnImage?: (filename?: string) => void
  /** Metadata of a visual */
  visualMetadata?: Nullable<VisualClientDTO>
}

/**
 * @component ExpandedVisualPopup 
 * @example
 * <ExpandedVisualPopup isOpen={true} signedUrl={url} filename="photo_01" switchExpandedVisual={() => {}} onClose={()=> {}} />
 */

export const ExpandedVisualPopup: React.FC<Props> = ({
  isOpen,
  isSelected,
  isSelectable = false,
  isFavoriteSelectable = false,
  isFavorite = false,
  signedUrl,
  filename,
  label,
  switchExpandedVisual,
  onClose,
  onSelect,
  toggleFavoriteOnImage,
  visualMetadata
}) => {

  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)
  const { t } = useTranslation(['actions'])

  const keyDownHandler = useCallback((e: KeyboardEvent) => {
    switch (e.key) {
      case KeyboardEventKey.ESCAPE:
        return onClose()
      case KeyboardEventKey.ARROW_LEFT:
        return switchExpandedVisual(Direction.LEFT)
      case KeyboardEventKey.ARROW_RIGHT:
        return switchExpandedVisual(Direction.RIGHT)
    }
  }, [onClose, switchExpandedVisual])

  let style: React.CSSProperties = {}
  if (signedUrl) {
    style.backgroundImage = `url('${signedUrl}')`
  }

  return (
    <Fragment>
      {isMobileView ?
        <ResponsiveDrawer
          title={filename || ''}
          isOpen={isOpen}
          onClose={onClose}
          height="70vh"
        >

          <ExpandedVisual
            signedUrl={signedUrl}
            isSelected={isSelected}
            isSelectable={isSelectable}
            isFavorite={isFavorite}
            isFavoriteSelectable={isFavoriteSelectable}
            visualMetadata={visualMetadata}
            onSelect={onSelect}
            onClickFavButton={() => toggleFavoriteOnImage?.(filename)}
          />

          <Stack flexDirection="row" justifyContent="center" alignItems="center" gap=".8rem" marginTop="2.4rem">
            <MUIButton
              type="secondaryBorder"
              variant="outlined"
              size="lg"
              fullWidth
              startIcon={<ArrowBackRoundedIcon fontSize="large" />}
              onClick={() => switchExpandedVisual(Direction.LEFT)}
            >
              {t('Previous')}
            </MUIButton>
            <MUIButton
              type="secondaryBorder"
              variant="outlined"
              size="lg"
              fullWidth
              endIcon={<ArrowForwardRoundedIcon fontSize="large" />}
              onClick={() => switchExpandedVisual(Direction.RIGHT)}
            >
              {t('Next')}
            </MUIButton>
          </Stack>
        </ResponsiveDrawer>
        :
        <Modal
          modalContentClassName={styles.expandedVisualPopup}
          isOpen={isOpen}
          onClickOutside={onClose}
          onKeyDown={keyDownHandler}
        >
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            padding="1.6rem 2.4rem"
          >
            <Stack gap=".8rem" flexDirection="row" alignItems="center">
              <Typography variant="text-xl" fontWeight="semiBold" color={GRAY_900}>{filename}</Typography>
              <MUIBadge label={label} size="sm" color="gray" />
            </Stack>
            <IconButton
              size="small"
              sx={{
                color: GRAY_800,
                border: `1px solid ${GRAY_200}`,
                padding: '.6rem'
              }}
              onClick={onClose}
            >
              <Close fontSize="large" />
            </IconButton>
          </Stack>

          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            gap="2.4rem"
            padding="4.8rem 2.4rem"
            sx={{
              height: 'calc(100% - 7.3rem)',
              boxSizing: 'border-box'
            }}
          >
            <Stack height="100%" flexDirection="row" alignItems="center">
              <IconButton
                size="small"
                sx={{
                  color: GRAY_800,
                  border: `1px solid ${GRAY_200}`,
                  padding: '.8rem'
                }}
                onClick={() => switchExpandedVisual(Direction.LEFT)}
              >
                <WestRoundedIcon fontSize="large" />
              </IconButton>
            </Stack>

            <ExpandedVisual
              signedUrl={signedUrl}
              isSelected={isSelected}
              isSelectable={isSelectable}
              isFavorite={isFavorite}
              isFavoriteSelectable={isFavoriteSelectable}
              visualMetadata={visualMetadata}
              onSelect={onSelect}
              onClickFavButton={() => toggleFavoriteOnImage?.(filename)}
            />

            {!filename &&
              <ErrorMessage className={styles.error} error_type={APIRequestErrorType.NOTFOUND_ERROR} />
            }

            <Stack height="100%" flexDirection="row" alignItems="center">
              <IconButton
                size="small"
                sx={{
                  color: GRAY_800,
                  border: `1px solid ${GRAY_200}`,
                  padding: '.8rem'
                }}
                onClick={() => switchExpandedVisual(Direction.RIGHT)}
              >
                <EastRoundedIcon fontSize="large" />
              </IconButton>
            </Stack>
          </Stack>
        </Modal>
      }
    </Fragment>
  )
}
