import { FC, Fragment } from 'react'

import NotLogged from 'components/pages/General/NotLogged/NotLogged'
import { useAuth0 } from 'utils/auth'

interface Props {
  component: FC
}

export const PrivateRoute: FC<Props> = ({
  component: Component
}) => {
  const { isAuthenticated } = useAuth0()

  return (
    <Fragment>
      {isAuthenticated
        ? <Component />
        : <NotLogged />
      }
    </Fragment>
  )
}
