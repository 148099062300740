import { CreativeUnavailabilityDTO } from 'models/creative'
import { DatesRange } from 'components/common/DatesRange'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { IconButton } from '@mui/material'
import { ListItem } from 'components/common/ListItem'
import { NON_BLOCKING_QUERY_STATES } from 'constants/misc'
import { QueryStatus } from 'components/common/QueryStatus'
import React from 'react'
import moment from 'moment'
import styles from './UnavailabilityItem.module.sass'
import { useRemoveCTUnavailability } from 'dataQueries'
import { useTimezone } from 'components/contexts/timezone.context'
import { useUnavailabilityDrawer } from '../UnavailabilityDrawer'

/** 
 * @interface Props Input properties
 */
interface Props extends CreativeUnavailabilityDTO { }

/**
 * @component UnavailabilityItem displays client unavailability card details
 * @example
 * <UnavailabilityItem from="2023-04-19T10:00:30Z" to="2023-05-19T10:00:30Z" />
 */
export const UnavailabilityItem: React.FC<Props> = ({ from, to, id }) => {

  const { openEditUnavailabilityDrawer } = useUnavailabilityDrawer()
  const { userTimezone } = useTimezone()

  const removeUnavailability = useRemoveCTUnavailability()

  return (

    <ListItem
      isLoading={removeUnavailability.isPending}
      actionButtons={
        NON_BLOCKING_QUERY_STATES.has(removeUnavailability.status) &&
        <div>
          {/** Edit icon button */}
          <IconButton
            size="large"
            aria-label="edit"
            component="label"
            className={styles.defaultIcon}
            onClick={() => openEditUnavailabilityDrawer(id, moment(from), moment(to))}
          >
            <EditOutlinedIcon fontSize="large" className={styles.defaultIcon} />
          </IconButton>

          {/** Delete icon button */}
          <IconButton
            size="large"
            aria-label="delete"
            component="label"
            className={styles.redIcon}
            onClick={() => removeUnavailability.mutate(id)}
          >
            <DeleteOutlineOutlinedIcon fontSize="large" className={styles.redIcon} />
          </IconButton>
        </div>
      }
      requestStatus={
        <QueryStatus
          spaceTopRem={2}
          className={styles.requestStatus}
          query={removeUnavailability}
          showStates={['error']}
          onPurge={() => removeUnavailability.reset()}
        />
      }
    >
      {/** Starting and ending dates of unavailability */}
      <DatesRange
        from={from}
        to={to}
        timezone={userTimezone}
      />

    </ListItem>

  )

}
