/** Enum representing the types of correction reasons. */
export enum CorrectionReasonType {
  WRONG_IMPLEMENTATION = 'wrong_implementation',
  SUBSEQUENT_CHANGE = 'subsequent_change',
  NEW_INFORMATION = 'new_info',
  ORDER_DEVIATIONS = 'order_deviations',
}

/** DTO representing a correction order for GrundrissSchmiede orders (also known as floor plan certificate). */
export interface GrundrissSchmiedeOrderCorrectionDTO {
  /** The name of the file that needs to be corrected. */
  fileNameToCorrect: string
  /** The message describing the correction details. */
  message: string
  /** The filename of the correction template to be used. */
  correctionTemplateFileName: string
  /** The reason for the correction. */
  correctionReason: CorrectionReasonType
}
