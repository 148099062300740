import React, { useMemo } from 'react'

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import Collapse from '@mui/material/Collapse'
import Fade from '@mui/material/Fade'
import { HeatingSystemItem } from './HeatingSystemItem'
import { MUIButton } from 'components/common/MUIButton'
import { MUIDivider } from 'components/common/MUIDivider'
import Stack from '@mui/material/Stack'
import { TransitionGroup } from 'react-transition-group'
import { useHeatingSystem } from './HeatingSystem.context'
import { useTranslation } from 'react-i18next'

/**
 * @component HeatingSystem renders a list of heating system items and provides functionality
 * to add new items.
 *
 * @example
 *   <HeatingSystem />
 */
export const HeatingSystem: React.FC = () => {

  const { t } = useTranslation(['heating_system'])

  const { heatingSystemItems, handleAddHeatingSystemItem } = useHeatingSystem()

  /** Sorted heating system items by their IDs considering numeric values for proper ordering. */
  const sortedHeatingSystemItems = useMemo(() => {
    if (!heatingSystemItems) return
    const arrayHeatinngSystemItems = heatingSystemItems.toArray()

    return arrayHeatinngSystemItems.sort(([idA], [idB]) => idA.localeCompare(idB, undefined, { numeric: true }))
  }, [heatingSystemItems])

  return (
    <Fade in={true}>
      <Stack gap={2}>
        {/** Heating system items */}
        <TransitionGroup component={null}>
          {sortedHeatingSystemItems && sortedHeatingSystemItems.map(([id], index) =>
            <Collapse key={id}>
              <HeatingSystemItem id={id} index={index} />
            </Collapse>
          )}
        </TransitionGroup>

        {/** Add new items */}
        <MUIDivider>
          <MUIButton
            type='secondaryBorder'
            startIcon={<AddCircleOutlineRoundedIcon fontSize='large' />}
            onClick={() => handleAddHeatingSystemItem()}
          >
            {t('add_heating_system')}
          </MUIButton>
        </MUIDivider>

      </Stack>
    </Fade>
  )
}
