import { APIRequestErrorType, APIRequestState, Endpoint, RoleMimetype } from 'constants/API'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, put, takeEvery } from 'typed-redux-saga'

import { ActionRequest } from 'models/redux'
import { SignedURLDTO } from 'models/visuals'
import API from 'utils/API/API'
import { ActionLoadWatermarkLogo } from './loadWatermarkLogo.actions'

const awaitedErrorCodes = new Set([404])

/** Saga that loads Watermark logo for workspace ID */
export function* loadWatermarkLogoSaga(receivedAction: ActionLoadWatermarkLogo) {
  const { workspaceId, watermarkId } = receivedAction.payload

  const url = Endpoint.WATERMARK_LOAD
    .replace('{workspaceId}', encodeURI(workspaceId)).replace('{watermarkId}', encodeURI(watermarkId))
  const action: ActionRequest = yield generalFetch(ActionTypeAPIData.LOAD_WATERMARK_LOGO, () => API.get(url, {
    headers: {
      Accept: RoleMimetype.CLIENT, // TODO: Fix on BE so we can send proper accept header
    },
  }, {
    endpoint: Endpoint.WATERMARK_LOAD,
  }
  ))

  const loadWatermarkLogoAction: ActionLoadWatermarkLogo = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }

  if (awaitedErrorCodes.has(action.payload.request.response?.status ?? Number.MIN_VALUE)) {
    loadWatermarkLogoAction.payload.request.state = APIRequestState.OK
    loadWatermarkLogoAction.payload.request.error_type = APIRequestErrorType.NONE
    loadWatermarkLogoAction.payload.request.error = null
    yield put(loadWatermarkLogoAction)
    return
  }

  const SignedURLDTO: SignedURLDTO = action.payload.request.data

  if (!SignedURLDTO?.signedURL) {
    const error_message = 'signedUrl is undefined, null or empty'
    console.error(error_message)
    loadWatermarkLogoAction.payload.request.error_type = APIRequestErrorType.UNKNOWN_ERROR
    loadWatermarkLogoAction.payload.request.error = error_message
    yield put(loadWatermarkLogoAction)
    return
  }

  yield put(loadWatermarkLogoAction)
}

/** Watcher of visuals editing watermark fetch actions except for upload */
export function* loadWatermarkLogoWatcher() {
  yield all([
    takeEvery((action: ActionLoadWatermarkLogo) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.LOAD_WATERMARK_LOGO]), loadWatermarkLogoSaga),
  ])
}
