import { Fragment, useCallback, useLayoutEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { APIRequestErrorType } from 'constants/API'
import Button from 'components/common/Button/Button'
import { KeyboardEventKey } from 'constants/misc'
import Modal from 'components/common/Modals/Modal/Modal'
import { QueryStatus } from 'components/common/QueryStatus'
import { TIMEOUT_AFTER_SUCCESSFUL_CALLBACK } from 'constants/application'
import classnames from 'classnames'
import { decideErrorType } from 'utils/APIDecideErrorType'
import popupStyles from 'components/styles/popup.module.sass'
import styles from './RemoveMemberPopup.module.sass'
import { useDispatch } from 'react-redux'
import { useRemoveWorkspaceMember } from 'dataQueries'

/**
 * @interface Props Input properties
 */
interface Props {
  /** The additional classes to append */
  className?: string
  /** Decides when is modal open */
  isOpen: boolean
  /** Workspace ID */
  workspaceId: string
  /** Member email address */
  memberEmail: string
  /** Onclick action triggered when user clicks outside the .modal-content element or clicks the close button */
  onClickOutsideOrClose?: (e: React.MouseEvent) => unknown
  /** onKeyDown action triggered when user presses any key on the keyboard */
  onKeyDown?: (e: React.KeyboardEvent) => unknown
  /** Function called after a member removed with successful response */
  callbackAfterSuccessfulResponse?: () => void
}

/**
 * @component Remove member from a workspace in a popup
 * @example
 * <RemoveMemberPopup isOpen={true} workspaceId={workspaceId} memberEmail={email} />
 */
export const RemoveMemberPopup: React.FC<Props> = ({
  className = '',
  isOpen,
  workspaceId,
  memberEmail,
  onClickOutsideOrClose,
  onKeyDown,
  callbackAfterSuccessfulResponse,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['workspace_profile'])
  const removeMember = useRemoveWorkspaceMember()
  const [isRemoveDisabled, setIsRemoveDisabled] = useState(false)

  const onOutsideOrClose = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
    if (onClickOutsideOrClose) onClickOutsideOrClose(e)
    removeMember.reset()
  }, [removeMember, onClickOutsideOrClose])

  const onKeyboardEvent = useCallback((e: React.KeyboardEvent) => {
    if (onKeyDown) onKeyDown(e)
    if (onKeyDown && e.key === KeyboardEventKey.ESCAPE) {
      removeMember.reset()
    }
  }, [removeMember, onKeyDown])

  useLayoutEffect(() => {
    if (!callbackAfterSuccessfulResponse) return
    if (removeMember.isSuccess) {
      window.setTimeout(() => {
        callbackAfterSuccessfulResponse()
        removeMember.reset()
      }, TIMEOUT_AFTER_SUCCESSFUL_CALLBACK)
    }
  }, [removeMember, callbackAfterSuccessfulResponse, dispatch])

  return (
    <Modal
      className={classnames(styles.removeMemberPopup, className)}
      isOpen={isOpen}
      modalContentClassName={classnames(popupStyles.modalContentSmall, styles.content)}
      onClickOutside={onOutsideOrClose}
      onKeyDown={onKeyboardEvent}
      onClose={onOutsideOrClose}
      title={t('remove_member_popup')}
    >

      <div className={popupStyles.inside}>

        {removeMember.isIdle ?
          <Fragment>
            <p className={styles.text}>
              <Trans t={t} i18nKey="remove_member_prompt" />
            </p>

            <div className={classnames(styles.line, styles.buttonWrapper)}>
              <Button
                onClick={(e) => {
                  setIsRemoveDisabled(true)
                  removeMember.mutate({ workspaceId, email: memberEmail })
                }}
                disabled={isRemoveDisabled}
              >
                {t('confirm_removal')}
              </Button>
            </div>

          </Fragment>
          :
          <div className={styles.line}>
            <QueryStatus
              query={removeMember}
              successMessage={t('member_removed')}
              errorMessage={removeMember.error && decideErrorType(removeMember.error) === APIRequestErrorType.PAYMENT_REQUIRED_ERROR
                ? t('error_payment_required')
                : undefined
              }
            />
          </div>
        }

      </div>

    </Modal>
  )
}
