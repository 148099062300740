import { FuelType, HeaterConsumptionUnit, HotWaterGenerationType } from 'constants/purchaseFlow'
import { ImmutableMap, Nullable } from 'models/helpers'
import { useCallback, useState } from 'react'

import constate from 'constate'
import { uniqueId } from 'lodash'

/** @interface BillingPeriodItem Represents a billing period item. */
export interface BillingPeriodItem {
  /** The start date of the billing period. */
  startDate: Nullable<moment.Moment>
  /** The end date of the billing period. */
  endDate: Nullable<moment.Moment>
  /** The optional consumption value for the billing period. */
  consumption: string
  /** The value of hot water consumption in Kwh */
  hotWaterConsumptionKwh: Nullable<number>
}

/** @interface HeatingSystemItem Represents an item in the heating system. */
interface HeatingSystemItem {
  /** The type of fuel used by the heating system. */
  fuelType?: FuelType
  /** The type of hot water system. */
  hotWaterType?: HotWaterGenerationType
  /** The type of unit capture. */
  unitCaptureType?: HeaterConsumptionUnit
  /** The year the heating system was manufactured. */
  yearOfManufacture: Nullable<moment.Moment>
  /** The primary energy factor. */
  primaryEnergyFactor?: string
  /** A map of billing period items. */
  billingPeriodItems: ImmutableMap<string, BillingPeriodItem>
}

/** 
 * Default Billing Period Items
 * Chose IDs 01, 02, 03. because there could be issues with the uniqueId as these are default options.
 */
const defaultBillingPeriodItems: ImmutableMap<string, BillingPeriodItem> = ImmutableMap([
  ['billing-period-01', {
    startDate: null,
    endDate: null,
    consumption: '',
    hotWaterConsumptionKwh: null,
  }],
  ['billing-period-02', {
    startDate: null,
    endDate: null,
    consumption: '',
    hotWaterConsumptionKwh: null,
  }],
  ['billing-period-03', {
    startDate: null,
    endDate: null,
    consumption: '',
    hotWaterConsumptionKwh: null,
  }],
])

const defaultHeatingSystemItem: HeatingSystemItem = {
  fuelType: undefined,
  hotWaterType: undefined,
  unitCaptureType: undefined,
  yearOfManufacture: null,
  primaryEnergyFactor: '',
  billingPeriodItems: defaultBillingPeriodItems,
}

const defaultBillingPeriodItem: BillingPeriodItem = {
  startDate: null,
  endDate: null,
  consumption: '',
  hotWaterConsumptionKwh: null,
}

/** Generates a unique billing period ID. */
const _generateBillingPeriodId = (): string => uniqueId('billing-period-')
/** Generates a unique identifier for a heating system. */
const _generateHeatingSystemId = (): string => uniqueId('heating-system-')

// Helper functions
/**
 * Adds a heating system item to the state.
 * @returns A new immutable map with the added heating system item.
 */
const addItem = (state: ImmutableMap<string, HeatingSystemItem>, itemId: string, item: HeatingSystemItem) =>
  state.set(itemId, item)

/**
 * Updates an item in the state with the given new properties.
 * @returns The updated state with the modified item.
 */
const updateItem = (state: ImmutableMap<string, HeatingSystemItem>, itemId: string, newItem: Partial<HeatingSystemItem>) =>
  state.update(itemId, (value) => ({ ...value!, ...newItem }))

/**
 * Removes an item from the heating system state.
 * @returns A new immutable map with the specified item removed.
 */
const removeItem = (state: ImmutableMap<string, HeatingSystemItem>, itemId: string) =>
  state.delete(itemId)

/**
 * Adds a billing period to the specified heating system item in the state.
 * @returns The updated state with the new billing period added to the specified heating system item.
 */
const addBillingPeriod = (state: ImmutableMap<string, HeatingSystemItem>, itemId: string, billingPeriodId: string, billingPeriodItem: BillingPeriodItem) =>
  state.updateIn([itemId, 'billingPeriodItems'], (prevBillingPeriodItems) => (prevBillingPeriodItems as ImmutableMap<string, BillingPeriodItem>).set(billingPeriodId, billingPeriodItem))

/**
 * Updates the billing period for a specific heating system item in the state.
 * @returns The updated state with the modified billing period.
 */
const updateBillingPeriod = (state: ImmutableMap<string, HeatingSystemItem>, itemId: string, billingPeriodId: string, newBillingPeriod: Partial<BillingPeriodItem>) =>
  state.updateIn([itemId, 'billingPeriodItems', billingPeriodId], (value) => ({ ...value!, ...newBillingPeriod }))

/**
 * Removes a billing period from the specified heating system item in the state.
 * @returns The updated state with the specified billing period removed from the heating system item.
 */
const removeBillingPeriod = (state: ImmutableMap<string, HeatingSystemItem>, itemId: string, periodId: string) =>
  state.updateIn([itemId, 'billingPeriodItems'], (prevBillingPeriodItems) => (prevBillingPeriodItems as ImmutableMap<string, BillingPeriodItem>).delete(periodId))

// Provider and hook
export const [HeatingSystemContextProvider, useHeatingSystem] = constate(() => {

  const [heatingSystemItems, setHeatingSystemItems] = useState<ImmutableMap<string, HeatingSystemItem>>(ImmutableMap({ 'heating-system-0': defaultHeatingSystemItem }))

  /** Adds a new heating system item to the list of heating system items. */
  const handleAddHeatingSystemItem = useCallback(() => {
    const itemId = _generateHeatingSystemId()
    setHeatingSystemItems(prevState => addItem(prevState, itemId, defaultHeatingSystemItem))
  }, [])

  /** Updates a heating system item with the given ID using the provided partial item data. */
  const handleUpdateHeatingSystemItem = useCallback((id: string, newItem: Partial<HeatingSystemItem>) => {
    setHeatingSystemItems(prevState => updateItem(prevState, id, newItem))
  }, [])

  /** Removes a heating system item from the list based on the provided item ID. */
  const handleRemoveHeatingSystemItem = useCallback((itemId: string) => {
    setHeatingSystemItems(prevState => removeItem(prevState, itemId))
  }, [])

  /** Adds a new billing period to a heating system item. */
  const handleAddBillingPeriod = useCallback((itemId: string) => {
    const billingPeriodId = _generateBillingPeriodId()
    setHeatingSystemItems(prevState => addBillingPeriod(prevState, itemId, billingPeriodId, defaultBillingPeriodItem))
  }, [])

  /** Updates the billing period for a specific heating system item. */
  const handleUpdateBillingPeriod = useCallback((itemId: string, billingPeriodId: string, newBillingPeriod: Partial<BillingPeriodItem>) => {
    setHeatingSystemItems(prevState => updateBillingPeriod(prevState, itemId, billingPeriodId, newBillingPeriod))
  }, [])

  /** Handles the removal of a billing period from a heating system item. */
  const handleRemoveBillingPeriod = useCallback((itemId: string, billingPeriodId: string) => {
    setHeatingSystemItems(prevState => removeBillingPeriod(prevState, itemId, billingPeriodId))
  }, [])

  return {
    heatingSystemItems,
    handleAddHeatingSystemItem,
    handleUpdateHeatingSystemItem,
    handleRemoveHeatingSystemItem,
    handleAddBillingPeriod,
    handleUpdateBillingPeriod,
    handleRemoveBillingPeriod,
  }
})
