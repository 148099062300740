import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { ActionRequest } from 'models/redux'
import { VirtualVisitDTO } from 'models/visuals'
import { createAPIAction } from 'redux/Helpers'

/** Describes general action for extending virtual visit's hosting */
export interface ActionExtendVirtualVisitHosting extends ActionRequest {
  payload: {
    missionId: string,
    isReactivation: boolean,
    paymentIntentId?: string,
    billingProductId: number,
    request: APIRequest<VirtualVisitDTO>,
  }
}

/** Creates an action to extend virtual visit hosting */
export function extendVirtualVisitHosting(missionId: string, isReactivation: boolean, billingProductId: number, paymentIntentId?: string): ActionExtendVirtualVisitHosting {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.EXTEND_VIRTUAL_VISIT_HOSTING], {
    missionId,
    isReactivation,
    paymentIntentId,
    billingProductId,
    request: new APIRequest(),
  })
}

/** Creates an action to purge extend virtual visit hosting state */
export function purgeExtendVirtualVisitHosting(missionId: string, isReactivation: boolean): ActionExtendVirtualVisitHosting {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.EXTEND_VIRTUAL_VISIT_HOSTING], {
    missionId,
    isReactivation,
    request: new APIRequest(),
  })
}
