import { SavedStripePaymentMethod, StripeBillingAddress } from 'models/user'
import { EntityKeys, QueryType, getMutation } from 'utils/reactQuery'

import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { StatusResponse } from 'models/redux'
import { useAPI } from 'utils/API'
import { UserQueryKeys } from './user.query'

enum Endpoints {
  GET_STRIPE_PAYMENT_METHODS = '/user/savedPaymentMethods',
  DELETE_SAVED_STRIPE_PAYMENT_METHODS = '/user/savedPaymentMethods/{stripeId}',
  UPDATE_SAVED_STRIPE_PAYMENT_METHODS = '/user/savedPaymentMethods/{paymentMethodType}/{paymentMethodId}',
}

export type UpdateStripePaymentValue = {
  card: {
    cardExpirationMonth: number
    cardExpirationYear: number
  }
} | {
  sepa: {
    billingAddress: StripeBillingAddress
  }
}

interface UpdateStripePaymentPayload {
  paymentMethodType: string,
  paymentMethodId: string,
  updateValues: UpdateStripePaymentValue
}

// QUERIES

export function useGetStripePaymentMethods() {
  const api = useAPI<Endpoints>()

  return useQuery<SavedStripePaymentMethod[] | null, Error>({
    queryKey: [EntityKeys.USER, QueryType.GET, UserQueryKeys.STRIPE_PAYMENT_METHOD],
    queryFn: () => api.get<SavedStripePaymentMethod[] | null>(
      Endpoints.GET_STRIPE_PAYMENT_METHODS,
      {},
      false
    ),
    enabled: false
  })
}

// MUTATIONS

export function useUpdateStripePaymentMethod() {
  const api = useAPI<Endpoints>()
  return getMutation<AxiosResponse<StatusResponse>, UpdateStripePaymentPayload>(
    ({ paymentMethodType, paymentMethodId, updateValues }) => api.put(
      Endpoints.UPDATE_SAVED_STRIPE_PAYMENT_METHODS,
      { paymentMethodType, paymentMethodId },
      updateValues,
      true
    ),
    (client) => {
      client.invalidateQueries({ queryKey: [EntityKeys.USER, QueryType.GET, UserQueryKeys.STRIPE_PAYMENT_METHOD] })
    }
  )
}

export function useDeleteStripePaymentMethod() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, { paymentMethodId: string }>(
    ({ paymentMethodId }) => api.delete(
      Endpoints.DELETE_SAVED_STRIPE_PAYMENT_METHODS,
      { stripeId: paymentMethodId },
      true
    ),
    (client) => {
      client.invalidateQueries({ queryKey: [EntityKeys.USER, QueryType.GET, UserQueryKeys.STRIPE_PAYMENT_METHOD] })
    }
  )
}
