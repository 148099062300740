import { Fragment, useMemo } from 'react'
import { useGallery, useGalleryAssignment, useGalleryComments } from '../../_main/contexts'

import { AssignmentDTOIsAdministratorDTO } from 'utils/typeguards'
import AutoFireActionInput from 'components/common/AutoFireActionInput/AutoFireActionInput'
import BlockInfo from 'components/common/BlockInfo/BlockInfo'
import Button from 'components/common/Button/Button'
import { LinkifyTextarea } from 'components/common/LinkifyTextarea/LinkifyTextarea'
import { MUIDivider } from 'components/common/MUIDivider'
import { StagingType } from 'models/virtualStaging'
import { editMissionCreativeInstructionsFromAdmin } from 'redux/Individual/Admin/EditMissionCreativeInstructionsFromAdmin'
import { editMissionInternalComments } from 'redux/Individual/Admin/EditMissionInternalComments'
import { externalNavigate } from 'utils/helpers'
import { multilineText } from 'utils/formatting'
import { useDispatch } from 'react-redux'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

/**
 * @component Gallery Admin Comment Section shows the comment and instruction 
 * @example
 * <GalleryAdminCommentSection />
 */
export const GalleryAdminCommentSection: React.FC = () => {
  const { t } = useTranslation(['gallery', 'deal_assignment', 'virtual_furniture_upgrade_category'])
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const { assignmentId } = useGallery()

  const { assignmentData, isBKBNDecide } = useGalleryAssignment()

  const {
    adminInternalComments,
    setAdminInternalComments,
    creativeInstructionsFromAdmin,
    setCreativeInstructionsFromAdmin,
    adminInternalCommentsResponse,
    creativeInstructionsFromAdminResponse,
  } = useGalleryComments()

  const contentForBKBNDecide = useMemo(() => {
    if (assignmentData && AssignmentDTOIsAdministratorDTO(assignmentData) && assignmentData.stagingDetails) {
      const stagingDetails = assignmentData.stagingDetails
      const bkbnStagingList = Object.entries(stagingDetails).reduce<string[]>((acc, [key, value]) => {
        if (value?.type !== StagingType.BKBN) return acc

        if (value?.wallPainting || value?.floor) {
          acc.push(`${key} ${t('selected_options')}: ${value.wallPainting ?? t('WALL_MATERIALS')}, ${value.floor ? t('FLOORING') : ''}`)
        } else {
          acc.push(`${key}`)
        }

        return acc
      }, [])

      return bkbnStagingList.join('\n----------\n')
    }

    return ''
  }, [assignmentData, t])

  const stagingComments = useMemo(() => {
    if (assignmentData && AssignmentDTOIsAdministratorDTO(assignmentData) && assignmentData.stagingDetails) {
      const bkbnStagingCommentsList = Object.entries(assignmentData.stagingDetails).reduce<string[]>((acc, [key, value]) => {

        if (value?.comment) {
          acc.push(`${key}:\n${value?.comment}`)
        }

        return acc
      }, [])

      return bkbnStagingCommentsList.join('\n----------\n')
    }

    return ''
  }, [assignmentData])


  if (!assignmentData || !AssignmentDTOIsAdministratorDTO(assignmentData)) return <></>

  return (
    <Fragment>

      {assignmentData.creativeInstructionsUrl &&
        <Fragment>
          <MUIDivider />
          <Button
            type="primary"
            onClick={() => externalNavigate(assignmentData.creativeInstructionsUrl)}
          >
            {t('follow_instruction_button')}
          </Button>
        </Fragment>
      }

      <p className="space-top"><strong>{t('shooting_instructions')}</strong></p>
      <BlockInfo className="blue">
        {multilineText(assignmentData.shootingInstructions)}
      </BlockInfo>
      <br />

      <p><strong>{t('meeting_instructions')}</strong></p>
      <BlockInfo className="blue">
        {multilineText(assignmentData.meetingInstructions)}
      </BlockInfo>
      <br />

      {!!stagingComments &&
        <>
          <p>
            <strong>{t('staging_comments')}</strong>
          </p>
          <BlockInfo className="blue">
            {multilineText(stagingComments)}
          </BlockInfo>
          <br />
        </>
      }

      <p><strong>{t('general_operations_comments')}</strong></p>
      <BlockInfo className="blue">
        {multilineText(assignmentData.generalOperationsComments)}
      </BlockInfo>
      <br />

      {isBKBNDecide &&
        <Fragment>
          <p>
            <strong>{t('staging_details')}</strong>
          </p>

          <BlockInfo className="blue">
            {multilineText(contentForBKBNDecide)}
          </BlockInfo>
          <br />
        </Fragment>
      }

      <label htmlFor="creative_instructions_from_admin"><strong>{t('creative_instructions_from_admin')}</strong></label>
      <AutoFireActionInput
        value={creativeInstructionsFromAdmin}
        request={creativeInstructionsFromAdminResponse}
        action={() => dispatch(editMissionCreativeInstructionsFromAdmin(assignmentId, creativeInstructionsFromAdmin, queryClient))}
        render={(className, state) => (
          <LinkifyTextarea
            className={className}
            name="creative_instructions_from_admin"
            id="creative_instructions_from_admin"
            value={creativeInstructionsFromAdmin}
            onChange={(e) => setCreativeInstructionsFromAdmin(e.target.value)}
          />
        )}
      />
      <br />

      <label htmlFor="adminInternalComments"><strong>{t('admin_internal_comments')}</strong></label>
      <AutoFireActionInput
        value={adminInternalComments}
        request={adminInternalCommentsResponse}
        action={() => dispatch(editMissionInternalComments(assignmentId, adminInternalComments, queryClient))}
        render={(className, state) => (
          <LinkifyTextarea
            className={className}
            name="adminInternalComments"
            id="adminInternalComments"
            value={adminInternalComments}
            onChange={(e) => setAdminInternalComments(e.target.value)}
          />
        )}
      />

    </Fragment>
  )
}
