/** All RESIDENTIAL property types. */
export enum ResidentialPropertyType {
  STUDIO = 'STUDIO',
  APARTMENT = 'APARTMENT',
  DETACHED_HOUSE = 'DETACHED_HOUSE',
  SEMI_DETACHED_HOUSE = 'SEMI_DETACHED_HOUSE',
  DUPLEX = 'DUPLEX',
  TOWNHOUSE = 'TOWNHOUSE',
  VILLA = 'VILLA',
  BUILDING = 'BUILDING',
  TERRACE = 'TERRACE',
  PENTHOUSE = 'PENTHOUSE',
}

/** All COMMERCIAL property types. */
export enum CommercialPropertyType {
  GYM = 'GYM',
  HOTEL = 'HOTEL',
  OFFICE = 'OFFICE',
  RESTAURANT = 'RESTAURANT',
  STORE = 'STORE',
  PLANT = 'PLANT_INDUSTRIAL',
  STORAGE = 'STORAGE_WAREHOUSE',
}

/** All property types. */
export enum VisualsMarketingPropertyType {
  STUDIO = ResidentialPropertyType.STUDIO,
  APARTMENT = ResidentialPropertyType.APARTMENT,
  DETACHED_HOUSE = ResidentialPropertyType.DETACHED_HOUSE,
  SEMI_DETACHED_HOUSE = ResidentialPropertyType.SEMI_DETACHED_HOUSE,
  DUPLEX = ResidentialPropertyType.DUPLEX,
  TOWNHOUSE = ResidentialPropertyType.TOWNHOUSE,
  VILLA = ResidentialPropertyType.VILLA,
  BUILDING = ResidentialPropertyType.BUILDING,
  TERRACE = ResidentialPropertyType.TERRACE,
  PENTHOUSE = ResidentialPropertyType.PENTHOUSE,
  GYM = CommercialPropertyType.GYM,
  HOTEL = CommercialPropertyType.HOTEL,
  OFFICE = CommercialPropertyType.OFFICE,
  PLANT = CommercialPropertyType.PLANT,
  RESTAURANT = CommercialPropertyType.RESTAURANT,
  STORAGE = CommercialPropertyType.STORAGE,
  STORE = CommercialPropertyType.STORE,
}

/** Listing goal enum for BOTH categories. */
export enum VisualsMarketingListingGoal {
  SELL = 'SELL',
  RENT = 'RENT'
}

/** Tone of voice enum for BOTH categories. */
export enum VisualsMarketingToneOfVoice {
  PROFESSIONAL = 'PROFESSIONAL',
  WARM_AND_FRIENDLY = 'WARM_AND_FRIENDLY',
  PRESTIGIOUS = 'PRESTIGIOUS',
  DELIGHTFUL = 'DELIGHTFUL',
}

/** Target audience enum for BOTH categories. */
export enum VisualsMarketingTargetAudience {
  YOUNG_PROFESSIONALS = 'YOUNG_PROFESSIONALS',
  FAMILIES = 'FAMILIES',
  RETIREES = 'RETIREES',
  INVESTORS = 'INVESTORS',
}

/** Property enum categories. */
export enum VisualsMarketingCategory {
  RESIDENTIAL = 'RESIDENTIAL',
  COMMERCIAL = 'COMMERCIAL',
}

/** Size units enum for BOTH categories. */
export enum VisualsMarketingSizeUnit {
  SQM = 'SQM',
  SQFT = 'SQFT',
}

/** Target Platform enum for BOTH categories. */
export enum VisualsMarketingTargetPlatform {
  REAL_ESTATE_BROCHURE = 'REAL_ESTATE_BROCHURE',
  INSTAGRAM = 'INSTAGRAM',
  LINKEDIN = 'LINKEDIN',
}

/** Room type enum only for RESIDENTIAL category. */
export enum VisualsMarketingRoomType {
  BEDROOM = 'BEDROOM',
  KITCHEN = 'KITCHEN',
  BATHROOM = 'BATHROOM',
  LIVING_ROOM = 'LIVING_ROOM',
}

/** Set of property types to display input floor selection. */
export const propertyTypesWithFloor = new Set([
  VisualsMarketingPropertyType.APARTMENT,
  VisualsMarketingPropertyType.DUPLEX,
  VisualsMarketingPropertyType.STUDIO,
  CommercialPropertyType.OFFICE,
  CommercialPropertyType.GYM,
  CommercialPropertyType.PLANT,
  CommercialPropertyType.RESTAURANT,
  CommercialPropertyType.STORAGE,
  CommercialPropertyType.STORE,
])
