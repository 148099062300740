import { BEIGE_600, GRAY_600, GRAY_900 } from 'constants/styling/theme'
import { FC, ReactNode } from 'react'
import { Stack, TableCell, Typography, useMediaQuery } from '@mui/material'

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUITooltip } from 'components/common/MUITooltip'
import { Path } from 'constants/router'
import { externalNavigate } from 'utils/helpers'
import { redesignMUITheme } from 'constants/styling/redesignTheme'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** Whether the dropdown is disabled or not */
  disabled: boolean
  /** Whether the dropdown is locked or not. It will enable click event to open Pricing page. */
  isLocked?: boolean
  /** Dropdown title */
  dropdownTitle: string
  /** Colunm span of the table cell */
  colSpan?: number
  children: ReactNode
}

/**
 * @component Workspace Dropdown component
 * @example
 * <WorkspaceDropdown dropdownTitle={Member} disabled={false}>
 *  <MenuItem>500</MenuItem>
 * </WorkspaceDropdown>
 */
export const WorkspaceDropdown: FC<Props> = ({
  disabled,
  isLocked = false,
  dropdownTitle,
  colSpan,
  children
}) => {
  const { t } = useTranslation(['workspace_profile'])
  const isMobileView = useMediaQuery(redesignMUITheme.breakpoints.down('sm'))

  return (
    <TableCell colSpan={colSpan} sx={{ padding: 0 }}>

      <MUIDropdown
        button={(isOpen, action) => (
          <Stack
            flexDirection="row"
            alignItems="center"
            boxSizing="border-box"
            height="5rem"
            sx={{
              padding: isMobileView ? '.6rem .8rem' : '.6rem 1.6rem',
              cursor: (disabled && !isLocked) ? 'default' : 'pointer',
              ':hover': {
                backgroundColor: (disabled && !isLocked) ? undefined : BEIGE_600
              },
              color: isLocked ? GRAY_600 : 'inherit'
            }}
            onClick={(e) => {
              if (isLocked) return externalNavigate(Path.PRICING)
              if (!disabled) return action(e)
            }}
          >
            <MUITooltip content={isLocked ? t('access_locked_description') : undefined}>
              <Stack flexDirection="row" alignItems="center">
                <Typography variant="text-sm" color={isLocked ? GRAY_600 : GRAY_900} fontWeight="medium" width={isMobileView ? 'unset' : '12rem'}>{dropdownTitle}</Typography>
                {(!disabled && !isLocked && !isMobileView) &&
                  (isOpen ?
                    <KeyboardArrowUpRoundedIcon fontSize="large" /> :
                    <KeyboardArrowDownRoundedIcon fontSize="large" />
                  )
                }
                {isLocked && <LockOutlinedIcon />}
              </Stack>
            </MUITooltip>
          </Stack>
        )}
      >

        {children}

      </MUIDropdown>

    </TableCell>
  )
}
