import '../../Dashboard/Dashboard.sass'

import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { DateTypeFilter, OwnershipScope, Params } from 'constants/misc'
import { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { Stack, Typography } from '@mui/material'

import { ClearButton } from '../ClearButton'
import { GRAY_700 } from 'constants/styling/theme'
import { MUIDatePicker } from 'components/common/MUIDatePicker'
import { Nullable } from 'models/helpers'
import { OrderListFilters } from 'dataQueries'
import moment from 'moment'
import { useBkbnSearchParams } from 'utils/hooks/useBkbnSearchParams'
import { useTimezone } from 'components/contexts/timezone.context'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Current order list filters */
  filters: OrderListFilters
}

/**
 * @component Dashboard assignment date filters section
 * @example <AssignmentDateFilters />
 * */
export const AssignmentDateFilters: FC<Props> = ({ filters }) => {
  const { t } = useTranslation(['dashboard'])
  const params = useBkbnSearchParams()
  const { userTimezone } = useTimezone()
  const { baseUserData, organizationData } = useUserData()

  const [creationDateStart, setCreationDateStart] = useState<Nullable<moment.Moment>>(null)
  const [creationDateEnd, setCreationDateEnd] = useState<Nullable<moment.Moment>>(null)
  const [shootingDateStart, setShootingDateStart] = useState<Nullable<moment.Moment>>(null)
  const [shootingDateEnd, setShootingDateEnd] = useState<Nullable<moment.Moment>>(null)

  const handleLogOrderDateSelection = useCallback((type: DateTypeFilter) => {
    if (!filters.scope) return

    logAnalyticsEvent(`${filters.scope === OwnershipScope.INDIVIDUAL ? AnalyticsEvent.DASHBOARD_ORDER_DATE_SELECTION_MY_VISUALS : AnalyticsEvent.DASHBOARD_ORDER_DATE_SELECTION_ALL_VISUALS}`, {
      userEmail: baseUserData?.email,
      organizationId: organizationData?.id,
      type,
    })
  }, [baseUserData?.email, filters.scope, organizationData?.id])

  useEffect(() => {
    if (creationDateStart && creationDateEnd) {
      params.set([Params.CREATION_DATE_START, creationDateStart?.format('YYYY-MM-DD')], [Params.CREATION_DATE_END, creationDateEnd.format('YYYY-MM-DD')])
      handleLogOrderDateSelection(DateTypeFilter.CREATION)
    }
    // params removed from dependency array to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creationDateStart, creationDateEnd])

  useEffect(() => {
    if (shootingDateStart && shootingDateEnd) {
      params.set([Params.SHOOTING_DATE_START, shootingDateStart.format('YYYY-MM-DD')], [Params.SHOOTING_DATE_END, shootingDateEnd.format('YYYY-MM-DD')])
      handleLogOrderDateSelection(DateTypeFilter.SHOOTING)
    }
    // params removed from dependency array to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shootingDateStart, shootingDateEnd])

  return (
    <Fragment>
      <Stack flexDirection="column" gap=".6rem" justifyContent="space-between" marginTop="2.4rem">

        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography variant="text-sm" fontWeight={600} color={GRAY_700} height="2rem">{t('dashboard:filter_by_creation_date')}</Typography>
          {(creationDateStart || creationDateEnd) &&
            <ClearButton
              text={t('clear_filter')}
              onClick={() => {
                params.set([Params.CREATION_DATE_START, null], [Params.CREATION_DATE_END, null])
                setCreationDateStart(null)
                setCreationDateEnd(null)
              }}
            />
          }
        </Stack>

        <Stack flexDirection="row" gap="1rem">
          {/* Creation From */}
          <Stack>
            <Typography variant="text-xs" color={GRAY_700}>{t('from')}</Typography>
            <MUIDatePicker
              defaultValue={undefined}
              value={creationDateStart}
              onChange={(date) => {
                setCreationDateStart(date)
                if (!!creationDateEnd && moment(date).isAfter(moment(creationDateEnd))) {
                  setCreationDateEnd(date)
                }
              }}
              timezone={userTimezone}
              format='DD/MM/YYYY'
              ampm={false}
              views={['month', 'day']}
            />
          </Stack>

          {/* Creation To */}
          <Stack>
            <Typography variant="text-xs" color={GRAY_700}>{t('to')}</Typography>
            <MUIDatePicker
              defaultValue={undefined}
              value={creationDateEnd}
              minDate={creationDateStart || undefined}
              onChange={(date) => setCreationDateEnd(date)}
              timezone={userTimezone}
              format='DD/MM/YYYY'
              ampm={false}
              views={['month', 'day']}
            />
          </Stack>
        </Stack>

      </Stack>

      <Stack flexDirection="column" gap=".6rem" justifyContent="space-between" margin="2.4rem 0">

        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography variant="text-sm" fontWeight={600} color={GRAY_700} height="2rem">{t('dashboard:filter_by_shooting_date')}</Typography>
          {(shootingDateStart || shootingDateEnd) &&
            <ClearButton
              text={t('clear_filter')}
              onClick={() => {
                params.set([Params.SHOOTING_DATE_START, null], [Params.SHOOTING_DATE_END, null])
                setShootingDateStart(null)
                setShootingDateEnd(null)
              }}
            />
          }
        </Stack>

        <Stack flexDirection="row" gap="1rem">
          {/* Shooting From */}
          <Stack>
            <Typography variant="text-xs" color={GRAY_700}>{t('from')}</Typography>
            <MUIDatePicker
              defaultValue={undefined}
              value={shootingDateStart}
              onChange={(date) => {
                setShootingDateStart(date)
                if (!!shootingDateEnd && moment(date).isAfter(moment(shootingDateEnd))) {
                  setShootingDateEnd(date)
                }
              }}
              timezone={userTimezone}
              format='DD/MM/YYYY'
              ampm={false}
              views={['month', 'day']}
            />
          </Stack>

          <Stack>
            {/* Shooting To */}
            <Typography variant="text-xs" color={GRAY_700}>{t('to')}</Typography>
            <MUIDatePicker
              defaultValue={undefined}
              value={shootingDateEnd}
              minDate={shootingDateStart || undefined}
              onChange={(date) => setShootingDateEnd(date)}
              timezone={userTimezone}
              format='DD/MM/YYYY'
              ampm={false}
              views={['month', 'day']}
            />
          </Stack>
        </Stack>

      </Stack>
    </Fragment>
  )
}
