import { VisualAdminDTO, VisualDTO, VisualListResponse, VisualListResponseAdministrator, VisualListResponseClient, VisualListResponseCreative } from 'models/visuals'

export function VisualListResponseIsClientResponse(response: VisualListResponse): response is VisualListResponseClient {
  return (
    (response as VisualListResponseClient).downloadCount !== undefined &&
    (response as VisualListResponseClient).additionalVisualPrice !== undefined &&
    (response as VisualListResponseCreative).maxUploadCount === undefined &&
    (response as VisualListResponseCreative).minUploadCount === undefined
  )
}

export function VisualListResponseIsCreativeResponse(response: VisualListResponse): response is VisualListResponseCreative {
  return (
    (response as VisualListResponseCreative).maxUploadCount !== undefined &&
    (response as VisualListResponseCreative).minUploadCount !== undefined &&
    (response as VisualListResponseClient).downloadCount === undefined &&
    (response as VisualListResponseClient).additionalVisualPrice === undefined
  )
}

export function VisualListResponseIsAdministratorResponse(response: VisualListResponse): response is VisualListResponseAdministrator {
  return (
    (response as VisualListResponseAdministrator).maxUploadCount !== undefined &&
    (response as VisualListResponseAdministrator).minUploadCount !== undefined &&
    (response as VisualListResponseAdministrator).downloadCount !== undefined &&
    (response as VisualListResponseAdministrator).additionalVisualPrice !== undefined
  )
}

export function VisualMetadataIsAdministratorResponse(visualMetadata: VisualDTO): visualMetadata is VisualAdminDTO {
  return (
    (visualMetadata as VisualAdminDTO).floorPlanServiceEditorUrl !== undefined &&
    (visualMetadata as VisualAdminDTO).floorPlanSimpleEditorUrl !== undefined
  )
}
