import { BaseInstructionCard, BaseInstructionCardPassDownProps } from '../BaseInstructionCard'
import { FC, useEffect, useMemo } from 'react'
import { getAddressWithCoordinatesFromPlace } from 'utils/location/googlePlaceUtils'
import { usePurchaseFlowConfig, useTargetOrderUser } from 'components/pages/PurchaseFlow/_main/contexts'

import { Country } from 'constants/country'
import { InstructionPrice } from '../../InstructionPrice'
import { MeetingInstructionsFields } from '../MeetingInstructionsCard'
import { ProductKind } from 'constants/product'
import { useGetKeyPickupTravelCost } from 'dataQueries/purchase.query'
import { usePurchaseFlowOrderMeta } from 'components/pages/PurchaseFlow/_main/contexts/PurchaseFlowOrderMeta.context'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @component
 * Card displaying instructions for key pickup.
 * 
 * @example 
 * <KeyPickupCard
 *   isCardDisabled={false}
 *   isSelectionDisabled={true}
 *   instructionOption={...}
 *   instructionType={...}
 *   onCheck={...}
 * />
 */
export const KeyPickupCard: FC<BaseInstructionCardPassDownProps> = ({
  isCardDisabled,
  isSelectionDisabled,
  instructionOption,
  instructionType,
}) => {

  const { adminData } = useUserData()

  const {
    sessionId,
    selectedAssignmentPlace,
    selectedCountryCode,
  } = usePurchaseFlowConfig()

  const {
    selectedMeetingType,
    selectedKeyPickupPlace,
    onCheckMeetingType,
    setKeyPickupTravelCostProduct,
  } = usePurchaseFlowOrderMeta()


  const { targetUser } = useTargetOrderUser()

  const selectedAssignmentAddress = useMemo(() => {
    if (!selectedAssignmentPlace) return undefined

    return {
      address: selectedAssignmentPlace?.formatted_address,
      coordinates: selectedAssignmentPlace?.geometry.location
    }
  }, [selectedAssignmentPlace])

  const getTravelCost = useGetKeyPickupTravelCost(sessionId!, {
    pickupAddress: getAddressWithCoordinatesFromPlace(selectedKeyPickupPlace),
    assignmentAddress: selectedAssignmentAddress,
    // Use shooting location country to avoid possible cross-borders problems (i.e. Geneve -> France)
    country: selectedCountryCode as Country ?? undefined,
    customerAuth0Id: adminData ? targetUser.id : undefined,
  })

  const travelCostProduct = useMemo(() => getTravelCost.data?.data.data, [getTravelCost.data])

  useEffect(() => {
    if (!travelCostProduct) {
      setKeyPickupTravelCostProduct(null)
      return
    }

    setKeyPickupTravelCostProduct(travelCostProduct)
  }, [travelCostProduct, setKeyPickupTravelCostProduct])

  if (instructionOption.kind !== ProductKind.KEYS_PICKUP) return null

  return (
    <BaseInstructionCard
      instructionType={instructionType}
      isSelectionDisabled={isSelectionDisabled}
      instructionOption={instructionOption}
      isCardDisabled={isCardDisabled}
      isChecked={selectedMeetingType === instructionOption.kind}
      onCheck={() => onCheckMeetingType(instructionOption.kind)}

      rightHeaderContent={<InstructionPrice feePrice={instructionOption.feePrice} />}
    >

      <MeetingInstructionsFields kind={instructionOption.kind} />

    </BaseInstructionCard>
  )
}
