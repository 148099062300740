import { AssignmentDTOIsAdministratorDTO, AssignmentDTOIsCreativeDTO } from 'utils/typeguards'
import { CreativeProductInstruction, chooseProductContainingVisuals } from 'utils/product'
import { Fragment, useMemo } from 'react'
import { ProductKind, ProductSegment, ProductType } from 'constants/product'
import { useLocation, useNavigate } from 'react-router-dom'

import { AssignmentDTO } from 'models/assignment'
import { AxiosResponse } from 'axios'
import Button from 'components/common/Button/Button'
import Icon from 'components/common/Icon/Icon'
import { IconType } from 'constants/assets'
import { Nullable } from 'models/helpers'
import { Path } from 'constants/router/paths'
import { ProductCategory } from 'models/product'
import galleryArrow from 'assets/img/elements/gallery_arrow.svg'
import styles from './GalleryBreadcrumbs.module.sass'
import { useAuth0 } from 'utils/auth'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
interface Props {
  /** The assignment */
  assignment?: Nullable<AxiosResponse<AssignmentDTO, any>>
}

/**
 * @component Display breadcrumbs in Gallery assignment
 * @example
 * <GalleryBreadcrumbs assignment={assignment} />
 */
export const GalleryBreadcrumbs: React.FC<Props> = ({
  assignment
}) => {
  const { roles } = useAuth0()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation(['gallery', 'category', 'product'])


  const galleryPathWithoutParams = Path.GALLERY.replace(/:.*/ig, '')
  const galleryAssignmentIdParam = parseInt(location.pathname.replace(galleryPathWithoutParams, ''))
  const product = useMemo(() => chooseProductContainingVisuals(assignment?.data?.products), [assignment])


  return (
    <div className={styles.breadcrumbs}>
      {location.key !== 'default' && !roles.isClient &&
        <Button
          className={styles.backbutton}
          type="secondary"
          onClick={() => navigate(-1)}
        >
          <Icon icon={IconType.CARET_LEFT} />
        </Button>
      }
      <div className={styles.texts}>
        <span className={styles.title}>
          #{galleryAssignmentIdParam}
          {' - '}
          {product ? t(`category:${product.category}`) : t('gallery:gallery')}
        </span>
        {product &&
          <span className={styles.text}>
            {roles.isCreative && assignment?.data && AssignmentDTOIsCreativeDTO(assignment.data) ?
              <CreativeProductInstruction
                category={product.category as ProductCategory}
                type={product.type as ProductType}
                kind={product.kind as ProductKind}
                attributeX={assignment.data.creativeInstructionAttributeX}
                attributeY={assignment.data.creativeInstructionAttributeY}
                count={product.quantity}
                segment={product.segments[0] as ProductSegment}
              />
              :
              <Fragment>
                {!!assignment?.data && !AssignmentDTOIsCreativeDTO(assignment.data) && !!assignment.data.reference &&
                  <span>{assignment.data.reference}</span>
                }
              </Fragment>
            }
          </span>
        }
      </div>
      {!roles.isCreative &&
        <Fragment>
          <object className={styles.arrow} data={galleryArrow} type="image/svg+xml">&gt;</object>
          {product &&
            <span className={styles.pack}>
              {(product.quantity > 1 ? `${product.quantity} x ` : '') + t(`product:p_${product.id}`)}
            </span>
          }
        </Fragment>
      }
      {roles.isAdmin && assignment?.data && AssignmentDTOIsAdministratorDTO(assignment.data) && assignment.data.client?.organizationName &&
        <Fragment>
          <object className={styles.arrow} data={galleryArrow} type="image/svg+xml">&gt;</object>
          <div className={styles.texts}>
            <span className={styles.title}>
              {assignment.data.client.organizationName}
            </span>
            {!!assignment.data.client.email &&
              <span className={styles.text}>
                {assignment.data.client.email}
              </span>
            }
          </div>
        </Fragment>
      }
    </div>
  )
}
