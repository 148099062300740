import '../../Dashboard/Dashboard.sass'

import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { FC, useCallback } from 'react'
import { OrderAssignmentStatusFilter, OwnershipScope, Params } from 'constants/misc'
import { OrderListFilters, useListOrders } from 'dataQueries/order.query'

import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import FilterTab from 'components/common/FilterTab/FilterTab.module'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import UpdateRoundedIcon from '@mui/icons-material/UpdateRounded'
import { useBkbnSearchParams } from 'utils/hooks/useBkbnSearchParams'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Placeholder in search input */
  filters: OrderListFilters
}

/**
 * @component Client dashboard assignment status filters tabs
 * @example <ClientAssignmentStatusFilters filters={filters}/>
 * */
export const ClientAssignmentStatusFilters: FC<Props> = ({ filters }) => {
  const { t } = useTranslation(['dashboard_client', 'dashboard'])
  const params = useBkbnSearchParams()
  const { clientData } = useUserData()

  const handleLogOrderStatusSelection = useCallback((scope: OwnershipScope, status: OrderAssignmentStatusFilter) => {
    logAnalyticsEvent(`${scope === OwnershipScope.INDIVIDUAL ? AnalyticsEvent.CLIENT_DASHBOARD_ORDER_STATUS_SELECTION_MY_VISUALS : AnalyticsEvent.CLIENT_DASHBOARD_ORDER_STATUS_SELECTION_ALL_VISUALS}`, {
      userEmail: clientData?.email,
      organizationId: clientData?.organizationId,
      status,
    })
  }, [clientData?.email, clientData?.organizationId])

  const ongoingCount = useListOrders(
    'CLIENT_ONGOING_ORDER_LIST',
    {
      ...filters,
      status: OrderAssignmentStatusFilter.ONGOING,
      page: 0,
    }
  )

  const deliveredCount = useListOrders(
    'CLIENT_DELIVERED_ORDER_LIST',
    {
      ...filters,
      status: OrderAssignmentStatusFilter.FINISHED,
      page: 0,
    }
  )

  const cancelledCount = useListOrders(
    'CLIENT_CANCELLED_ORDER_LIST',
    {
      ...filters,
      status: OrderAssignmentStatusFilter.CANCELLED,
      page: 0,
    }
  )

  return (
    <div className="order-status-filter-tabs">
      <span className="filter-section-label">{t('dashboard:filter_by_status')}</span>
      <FilterTab
        isActive={filters.status === OrderAssignmentStatusFilter.FINISHED}
        totalCount={deliveredCount.data?.data.totalElements.toString()}
        icon={<CheckCircleOutlineRoundedIcon fontSize='inherit' />}
        label={t('dashboard:completed')}
        onClick={() => {
          handleLogOrderStatusSelection(filters.scope!, OrderAssignmentStatusFilter.FINISHED)
          params.set([Params.STATUS, OrderAssignmentStatusFilter.FINISHED])
        }}
      />
      <FilterTab
        isActive={filters.status === OrderAssignmentStatusFilter.ONGOING}
        totalCount={ongoingCount.data?.data.totalElements.toString()}
        icon={<UpdateRoundedIcon fontSize='inherit' />}
        label={t('dashboard:ongoing')}
        onClick={() => {
          handleLogOrderStatusSelection(filters.scope!, OrderAssignmentStatusFilter.ONGOING)
          params.set([Params.STATUS, OrderAssignmentStatusFilter.ONGOING])
        }}
      />
      <FilterTab
        isActive={filters.status === OrderAssignmentStatusFilter.CANCELLED}
        totalCount={cancelledCount.data?.data.totalElements.toString()}
        icon={<HighlightOffIcon fontSize='inherit' />}
        label={t('dashboard:cancelled')}
        onClick={() => {
          handleLogOrderStatusSelection(filters.scope!, OrderAssignmentStatusFilter.CANCELLED)
          params.set([Params.STATUS, OrderAssignmentStatusFilter.CANCELLED])
        }}
      />
    </div>
  )
}
