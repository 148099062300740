import { ActionSelectVisuals, SelectVisualsStore } from '.'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { cloneDeep } from 'lodash'

/** Redux data reducer for select visuals */
export const reduceSelectVisuals = (state: SelectVisualsStore, action: ActionSelectVisuals) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.SELECT_VISUALS) return state
  const newState = cloneDeep(state)

  const { missionId, request } = action.payload

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
    case ActionTypeAPIEvent.FETCH:
    case ActionTypeAPIEvent.RECEIVED:
      newState[missionId] = request
      return newState
    default:
      return state
  }
}