import { APIRequestState, PurchaseFlowEndpoint } from 'constants/API'
import { DownloadImage, RootStore } from 'models/redux'
import { Nullable, NullableStringIndexSignature } from 'models/helpers'

import { ActionTypeAPIData } from 'constants/redux'
import { CONST_PRODUCT_SERIAL } from 'constants/product'
import { VisualFileType } from 'constants/visual'
import constate from 'constate'
import { useCallback } from 'react'
import { useDebouncedMemo } from 'utils/helpers'
import { useFileAPI } from 'components/common/FileAPI'
import { usePurchaseFlowConfig } from './PurchaseFlowConfig.context'
import { useSelector } from 'react-redux'

interface IdentifiedImage {
  id: string
  imageData: Nullable<NullableStringIndexSignature<DownloadImage>>
  productId: string
}

function useUploadedPurchaseFlowVisualsSetup() {
  const { sessionId } = usePurchaseFlowConfig()
  const sessionImages = useSelector((state: RootStore) => !sessionId ? undefined : state.APIData[ActionTypeAPIData.LOAD_PURCHASE_SESSION_VISUAL][sessionId])

  /**
   * Exposes whether all UPLOADs have been finished
   * flats down all uploaded images in purchase flow, that have been successfully uploaded into array
   *   - uses DOWNLOAD/UPLOAD manipulation key to determine which images are being uploaded and have finished uploading
   *   - filters out failed uploads 
   * maps all successfully uploaded images into product - images map
  */
  const {
    uploadedImagesByProduct,
    uploadedImagesArray,
    uploadFinished,
    uploadInProgress,
    uploadedImagesMap
  } = useDebouncedMemo(() => {

    if (!sessionImages) return {
      uploadedImagesByProduct: {},
      uploadedImagesArray: [],
      uploadedImagesMap: {},
      uploadFinished: false,
      uploadInProgress: false,
    }

    const imgMap: Record<string, IdentifiedImage> = {}
    const imgArray: IdentifiedImage[] = []
    const imgProductMap: Record<string, Record<string, IdentifiedImage>> = {}
    const uploadingProductKeys: string[] = []

    // Go through all products in state
    for (let productKey in sessionImages) {

      const productData = sessionImages[productKey]
      const referencedProductData = productData?.[CONST_PRODUCT_SERIAL]

      // Skip if no image data for current product key
      if (!productData || !referencedProductData) continue

      // Track if images are still uploading/processing/deleting
      if (Object.keys(referencedProductData.UPLOAD || {}).length > 0) uploadingProductKeys.push(productKey)

      // Skip if any images are still being uploaded
      if (uploadingProductKeys.length > 0) continue

      const uploadedImages = referencedProductData.DOWNLOAD || {}

      // Go through all images in product data
      for (let imageKey in uploadedImages) {
        const imageData = uploadedImages[imageKey]

        // Filter out failed uploads
        if (!imageData?.[VisualFileType.RAW_WEB]) continue
        if (!imageData[VisualFileType.RAW_WEB]?.request || imageData[VisualFileType.RAW_WEB]?.request.state !== APIRequestState.OK) continue

        // Filter out images that are being deleted
        if (imageData[VisualFileType.RAW_WEB]?.deleting) continue

        // Construct object with images, id of product it belongs to and image key
        const imageObject: IdentifiedImage = {
          id: imageKey,
          productId: productKey,
          imageData,
        }

        // add img to product key - images map
        imgProductMap[productKey] = {
          ...imgProductMap[productKey],
          [imageKey]: imageObject
        }

        // add img to imgMap
        imgMap[imageKey] = imageObject

        // append image into flat array
        imgArray.push(imageObject)
      }

    }

    return {
      uploadedImagesByProduct: imgProductMap,
      uploadedImagesArray: imgArray,
      uploadedImagesMap: imgMap,
      uploadFinished: uploadingProductKeys.length === 0,
      uploadInProgress: uploadingProductKeys.length !== 0
    }
  }, [sessionImages], 300)

  /**
   * Resolves URL of image with preference of smaller thumbnail size
   * @param imageId - id of image whose url is to be resolved
   * @returns either image URL or undefined
   */
  const getImageUrl = useCallback((imageId: string) => {
    const imgData = uploadedImagesMap[imageId]?.imageData

    if (!imgData) return undefined

    const resolvedImg = imgData[VisualFileType.RAW_THUMB] ?? imgData[VisualFileType.RAW_WEB]

    if (!resolvedImg) return undefined

    return resolvedImg.signedUrl

  }, [uploadedImagesMap])

  const documentFilesController = useFileAPI('PF_DOCS', {
    deleteHandler: sessionId
      ? (fileEntry, api) => api.delete(
        PurchaseFlowEndpoint.DELETE_UPLOADED_DOCUMENT,
        {
          sessionId: sessionId.toString(),
          filename: fileEntry.gcFilename || ''
        },
        true
      )
      : undefined
  })

  return {
    uploadedImagesArray,
    uploadedImagesMap,
    uploadedImagesByProduct,
    uploadFinished,
    uploadInProgress,
    documentFilesController,
    getImageUrl,
  }
}

export const [UploadedPurchaseFlowVisualsContextProvider, useUploadedPurchaseFlowVisuals] = constate(useUploadedPurchaseFlowVisualsSetup)
