import { AdminSendVisualsToEditorPayload, useSendVisualsToEditor } from 'dataQueries'
import { AxiosError, AxiosResponse } from 'axios'
import { Dispatch, FC, ReactNode, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { StageOrder, compareAssignmentStageOrder } from 'utils/stages'

import { AssignmentDTOIsAdministratorDTO } from 'utils/typeguards'
import AssignmentStageAdvice from 'components/common/AssignmentStageAdvice/AssignmentStageAdvice'
import { ESProduct } from 'constants/product'
import { Nullable } from 'models/helpers'
import { PIPEDRIVE_INFINITY } from 'constants/pipedrive'
import { StatusResponse } from 'models/redux'
import { UseMutationResult } from '@tanstack/react-query'
import { useActionPopup } from 'utils/hooks'
import { useAuth0 } from 'utils/auth'
import { useGalleryAssignment } from './GalleryAssignment.context'
import { useGalleryComments } from './GalleryComments.context'
import { useGalleryConstants } from './GalleryConstants.context'
import { useGalleryProduct } from './GalleryProduct.context'
import { useGalleryVisualSelection } from './GalleryVisualSelection.context'
import { useGalleryVisualsMeta } from './GalleryVisualsMeta.context'
import { useGetAdminEditorProducts } from 'dataQueries'
import { useTranslation } from 'react-i18next'

interface GalleryEditorProductInterface {
  editorProducts?: ESProduct[]
  editorProduct: ESProduct | 'NONE'
  setEditorProduct: Dispatch<SetStateAction<ESProduct | 'NONE'>>
  sendAssignmentVisualsToEditor?: Nullable<UseMutationResult<AxiosResponse<StatusResponse, any>, AxiosError<unknown, any>, AdminSendVisualsToEditorPayload, unknown>>
  canSendAssignmentVisualsToEditor: boolean
  assignmentVisualsAlreadySentToEditor: boolean
  cannotSendAssignmentVisualsToEditorInfo: string | JSX.Element
  canEditEditorCommentsAndProducts: boolean
  initiateSendAssignmentVisualsToEditor: () => Promise<void>
}

const defaultGalleryEditorProductValue: GalleryEditorProductInterface = {
  editorProduct: 'NONE',
  setEditorProduct: () => { throw new Error('setEditorProduct is undefined') },
  canSendAssignmentVisualsToEditor: false,
  assignmentVisualsAlreadySentToEditor: false,
  cannotSendAssignmentVisualsToEditorInfo: '',
  canEditEditorCommentsAndProducts: false,
  initiateSendAssignmentVisualsToEditor: async () => { throw new Error('initiateSendAssignmentVisualsToEditor is undefined') },
}

/** Gallery editor product context */
export const GalleryEditorProductContext = createContext<GalleryEditorProductInterface>(defaultGalleryEditorProductValue)
/** Gallery editor product context hook */
export const useGalleryEditorProduct = (): GalleryEditorProductInterface => useContext(GalleryEditorProductContext)

/** Context provider for gallery editor product */
export const GalleryEditorProductContextProvider: FC<{
  assignmentId: string
  children?: ReactNode
}> = ({
  assignmentId,
  children,
}) => {
    const { roles } = useAuth0()
    const { t } = useTranslation(['gallery', 'deal_assignment'])
    const { showConfirm } = useActionPopup()

    const {
      NONE_EDITOR_PRODUCT,
      stagesUnlockingSendAssignmentVisualsToEditor,
    } = useGalleryConstants()

    const {
      assignmentData,
      assignmentStage,
      assignment,
    } = useGalleryAssignment()

    const {
      isVirtualVisit,
      isFloorPlan,
      product,
      isVirtualVisitMatterport,
    } = useGalleryProduct()

    const {
      selectMaxValue,
    } = useGalleryVisualsMeta()

    const {
      selected,
    } = useGalleryVisualSelection()

    const {
      adminCommentsForEditor,
    } = useGalleryComments()

    const sendAssignmentVisualsToEditor = useSendVisualsToEditor()

    const assignmentIdLoaded = useRef<string | null>(null)

    // Fetch editor products for admin
    const editorProductsResponse = useGetAdminEditorProducts()
    const editorProducts = useMemo(() => editorProductsResponse.data, [editorProductsResponse])

    const [editorProduct, setEditorProduct] = useState<ESProduct | typeof NONE_EDITOR_PRODUCT>(NONE_EDITOR_PRODUCT)

    const canSendAssignmentVisualsToEditor = useMemo(() => {
      if (!assignmentStage) return false
      if (!stagesUnlockingSendAssignmentVisualsToEditor.has(assignmentStage)) return false
      if (!sendAssignmentVisualsToEditor?.isIdle) return false
      if (editorProduct === NONE_EDITOR_PRODUCT) return false

      if (isVirtualVisit || isFloorPlan) return true

      if (selectMaxValue === PIPEDRIVE_INFINITY) return selected.size > 0
      return selected.size >= selectMaxValue
    }, [NONE_EDITOR_PRODUCT, assignmentStage, editorProduct, isFloorPlan, isVirtualVisit, selectMaxValue, selected.size, sendAssignmentVisualsToEditor, stagesUnlockingSendAssignmentVisualsToEditor])

    const assignmentVisualsAlreadySentToEditor = useMemo(() => (!!assignmentStage && (compareAssignmentStageOrder(assignmentStage, stagesUnlockingSendAssignmentVisualsToEditor) === StageOrder.COMPARING_AFTER_COMPARED_TO)), [assignmentStage, stagesUnlockingSendAssignmentVisualsToEditor])
    const cannotSendAssignmentVisualsToEditorInfo = useMemo(() => {
      if ((!(!sendAssignmentVisualsToEditor.data || sendAssignmentVisualsToEditor.isIdle)) || assignmentVisualsAlreadySentToEditor) {
        return t('sent_to_editor')
      } else if (!assignmentStage || !stagesUnlockingSendAssignmentVisualsToEditor.has(assignmentStage)) {
        return <AssignmentStageAdvice id="send_to_editor" currentStage={assignmentStage} validStages={stagesUnlockingSendAssignmentVisualsToEditor} />
      } else if (editorProduct === NONE_EDITOR_PRODUCT) {
        return t('select_editor_product')
      } else if (!(isVirtualVisit && isFloorPlan) && !(selected.size >= selectMaxValue || (selectMaxValue === PIPEDRIVE_INFINITY && selected.size > 0))) {
        return t('select_more_visuals')
      } else {
        return ''
      }
    }, [NONE_EDITOR_PRODUCT, assignmentStage, assignmentVisualsAlreadySentToEditor, editorProduct, isFloorPlan, isVirtualVisit, selectMaxValue, selected.size, sendAssignmentVisualsToEditor, stagesUnlockingSendAssignmentVisualsToEditor, t])

    const canEditEditorCommentsAndProducts = useMemo(() => (!!assignmentStage && stagesUnlockingSendAssignmentVisualsToEditor.has(assignmentStage) && (!sendAssignmentVisualsToEditor || sendAssignmentVisualsToEditor.isIdle)), [assignmentStage, sendAssignmentVisualsToEditor, stagesUnlockingSendAssignmentVisualsToEditor])

    const initiateSendAssignmentVisualsToEditor = useCallback(async () => {
      if (!roles.isAdmin) return
      if (!canSendAssignmentVisualsToEditor) return
      if (editorProduct === NONE_EDITOR_PRODUCT) return
      if (!(await showConfirm(t('send_to_editor_prompt')))) return
      sendAssignmentVisualsToEditor.mutate({
        assignmentId,
        filenames: Array.from(selected),
        product: editorProduct,
        comment: adminCommentsForEditor
      })
    }, [NONE_EDITOR_PRODUCT, adminCommentsForEditor, assignmentId, canSendAssignmentVisualsToEditor, editorProduct, roles.isAdmin, selected, sendAssignmentVisualsToEditor, showConfirm, t])

    // Fill in editor product upon assignment received
    useEffect(() => {
      if (!assignment.isSuccess) return
      if (!assignmentData) return
      // Checks if it's first render or if the assignment changed
      if (!!assignmentIdLoaded.current && assignmentData.id === assignmentIdLoaded.current) return

      assignmentIdLoaded.current = assignmentData.id

      if (AssignmentDTOIsAdministratorDTO(assignmentData)) {
        if (assignmentData.editorProducts.length > 0 && ESProduct[assignmentData.editorProducts[0] as ESProduct]) {
          setEditorProduct(assignmentData.editorProducts[0])
        } else if (isFloorPlan) {
          // Prefill editor product for floor plan assignment
          setEditorProduct(ESProduct.FLOORPLAN_2D_ADVANCED_MATTERPORT_100)
        }
        // Validates product is virtual visit, real state category and kind HD
        if (isVirtualVisitMatterport) {
          // Prefill editor product to send matterport links directly to the editor
          setEditorProduct(ESProduct.QC_MATTERPORT)
        }
      }

    }, [isFloorPlan, assignmentData, assignment, product?.category, product, isVirtualVisitMatterport])

    useEffect(() => {
      sendAssignmentVisualsToEditor.reset()

      // Omit mutation to prevent looping
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignmentId])

    return (
      <GalleryEditorProductContext.Provider
        value={{
          editorProducts,
          editorProduct,
          setEditorProduct,
          sendAssignmentVisualsToEditor,
          canSendAssignmentVisualsToEditor,
          assignmentVisualsAlreadySentToEditor,
          cannotSendAssignmentVisualsToEditorInfo,
          canEditEditorCommentsAndProducts,
          initiateSendAssignmentVisualsToEditor,
        }}
      >
        {children}
      </GalleryEditorProductContext.Provider>
    )
  }
