import { ComputedStagingRoom, StagingRoom } from 'models/virtualStaging'
import { useCallback, useRef } from 'react'

import constate from 'constate'
import { useRoomInventory } from './RoomInventory.context'

interface RoomAPIProviderProps {
  imageUrlResolver: (imageId: string) => string | undefined
}

function useRoomAPISetup({
  imageUrlResolver = () => { throw new Error('Mandatory imageUrlResolver function is not defined!') }
}: RoomAPIProviderProps) {

  const {
    roomInventory,
    allRoomsCompleted,
    createRoomObject,
    addRoom,
    updateRoom,
    removeRoom,
    isRoomCompleted,
  } = useRoomInventory()

  /** Holds 1:1 relationships between image and room */
  const imageRoomPairings = useRef<Record<string, string>>({})

  /** Holds 1:N relationships between room and images  */
  const roomImagePairings = useRef<Record<string, string[]>>({})

  /**
  * Determines whether image has assigned room or not
  * @param imageId - id of image to be tested
  * @returns true/false
  */
  const isImageAssigned = useCallback((imageId: string) => !!imageRoomPairings.current[imageId], [])

  /**
   * Destroys relationship between given image and it's associated room
   * @param imageId - id of image whose relationships are to be severed
   * @returns true after destroying said relationship
   */
  const unassignRoomFromImage = useCallback((imageId: string) => {

    // Don't bother if not assigned
    if (!isImageAssigned(imageId)) return true

    const roomKey = imageRoomPairings.current[imageId]

    // Update image-room relationships
    const newImagePairings = {
      ...imageRoomPairings.current
    }
    delete newImagePairings[imageId]

    // Update room-image relationships
    imageRoomPairings.current = { ...newImagePairings }
    roomImagePairings.current = {
      ...roomImagePairings.current,
      [roomKey]: roomImagePairings.current[roomKey].filter((assignedImageId) => assignedImageId !== imageId)
    }

    return true
  }, [isImageAssigned])

  /**
   * Creates relationship between given image and room
   * @param imageId - id of image to associate with given room
   * @param roomKey - key of room to be associated with given image
   * @returns true after creating said relationship
   */
  const assignRoomToImage = useCallback((imageId: string, roomKey: string, reassign = true) => {

    const assignedRoomKey = imageRoomPairings.current[imageId]

    // Don't bother if already assigned the same room
    if (assignedRoomKey && assignedRoomKey === roomKey) return true

    // Skip assigning if image is already assigned a different room and force reassigning is off
    if (assignedRoomKey && assignedRoomKey !== roomKey && !reassign) return false

    // Unassign if image is already assigned a different room and force reassigning is on
    if (assignedRoomKey && assignedRoomKey !== roomKey && reassign) unassignRoomFromImage(imageId)

    // Update image-room relationships
    imageRoomPairings.current = {
      ...imageRoomPairings.current,
      [imageId]: roomKey
    }

    // Update room-image relationships
    roomImagePairings.current = {
      ...roomImagePairings.current,
      [roomKey]: [...(roomImagePairings.current[roomKey] || []), imageId]
    }

    return true
  }, [unassignRoomFromImage])

  /**
   * Clears all relationships
   * @return true after flushing
   */
  const unassignAll = useCallback(() => {
    imageRoomPairings.current = {}
    roomImagePairings.current = {}

    return true
  }, [])

  /**
   * Computes room object with images based on room-image relationships
   * @param roomKey - key of room to be received computed object of
   * @returns computed room object
   */
  const getRoom = useCallback((roomKey: string): ComputedStagingRoom => {
    const completedRoom = {
      ...roomInventory[roomKey],
      images: (roomImagePairings.current[roomKey] || []).map((imageId) => ({
        id: imageId,
        url: imageUrlResolver(imageId),
      }))
    }

    return completedRoom
  }, [imageUrlResolver, roomInventory])

  /**
   * Computes room objects for array of room keys
   * @param roomKeys - array of keys to be received computed room objects for
   * @returns array of computed room objects
   */
  const getRooms = useCallback((roomKeys: string[]) => {
    return roomKeys.map((roomKey) => getRoom(roomKey))
  }, [getRoom])

  /**
   * Computes room objects for all rooms in inventory
   * @returns array of computed room objects
   */
  const getAllRooms = useCallback(() => {
    return Object.keys(roomInventory).map((roomKey) => getRoom(roomKey))
  }, [getRoom, roomInventory])

  /**
   * Computes room objects for all rooms in inventory of certain product
   * @param productId - id of product all rooms are to be to obtained of
   * @returns array of computed room objects
   */
  const getAllRoomsOfProduct = useCallback((productId: number) => {
    return Object.values(roomInventory).filter((room) => room.productId === productId).map((room) => getRoom(room.key))
  }, [getRoom, roomInventory])

  /**
   * Deletes room and clears it's relationships
   * @param roomKey - key of room to be destroyed
   * @returns true after deleting room and destroying relationships
   */
  const deleteRoom = useCallback((roomKey: string) => {
    const roomImages = roomImagePairings.current[roomKey]

    roomImages.forEach((imageId) => unassignRoomFromImage(imageId))
    removeRoom(roomKey)

    return true
  }, [removeRoom, unassignRoomFromImage])

  /**
   * Creates new room object and adds it to inventory
   * @param productId - id of product to create a room for
   * @param roomData - partial object of room data to be merged into default one
   * @returns object of created room
   */
  const createNewRoom = useCallback((productId: number, roomData: Partial<StagingRoom>) => {
    const newRoom = createRoomObject(productId, roomData)
    addRoom(newRoom.key, newRoom)

    return newRoom
  }, [addRoom, createRoomObject])

  return {
    assignRoomToImage,
    unassignRoomFromImage,
    isImageAssigned,
    getRoom,
    getRooms,
    getAllRooms,
    deleteRoom,
    updateRoom,
    createNewRoom,
    unassignAll,
    isRoomCompleted,
    getAllRoomsOfProduct,
    allRoomsCompleted,
  }
}

export const [RoomAPIContextProvider, useRoomAPI] = constate(useRoomAPISetup)
