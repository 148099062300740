import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { cloneDeep } from 'lodash'
import { ActionAddWorkspaceMembers } from './addWorkspaceMembers.actions'
import { AddWorkspaceMembersStore } from './addWorkspaceMembers.interfaces'

/** Redux data reducer for adding workspace members */
export const reduceAddWorkspaceMembers = (state: AddWorkspaceMembersStore, action: ActionAddWorkspaceMembers) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.ADD_WORKSPACE_MEMBERS) return state

  const newState = cloneDeep(state)
  const { workspaceId, request } = action.payload

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
      if (!newState[workspaceId]) return state
      delete newState[workspaceId]
      return newState
    case ActionTypeAPIEvent.FETCH:
    case ActionTypeAPIEvent.RECEIVED:
      newState[workspaceId] = request
      return newState
    default:
      return state
  }
}
