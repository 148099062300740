import { FC, ReactNode, useMemo } from 'react'
import { getFormContext, isPostalCode, useExtendedForm } from 'utils/forms'

import { GetFormContextFields } from 'models/forms/getFormContext'
import { StripeBillingAddress } from 'models/user'
import { UseFormProps } from 'react-hook-form'
import { getDefaultErrorMessage } from 'utils/forms/getDefaultErrorMessage'

/** Describes saved sepa details values */
export interface SavedSepaDetailsFieldsValue extends StripeBillingAddress { }

/** Create saved sepa details fields context and hook */
export const [SavedSepaDetailsFieldsContext, useSavedSepaDetailsFields] = getFormContext<SavedSepaDetailsFieldsValue>()

/**
 * Provider for saved sepa details fields context
 * @param options - ...options = all form configuration options like *defaultValues*
 * @example
 * <SepaDetailFieldsContextProvider defaultValues={{ foo: 'bar' }}>
 *   ...children
 * </SepaDetailFieldsContextProvider>
 */
export const SavedSepaDetailsFieldsContextProvider: FC<
  {
    children?: ReactNode
  }
  &
  UseFormProps<SavedSepaDetailsFieldsValue>
> = ({
  children,
  ...options
}) => {

    const formUtils = useExtendedForm<SavedSepaDetailsFieldsValue>(
      {
        defaultValues: {
          name: '',
          line1: '',
          line2: '',
          city: '',
          country: '',
          postalCode: '',
        },
        ...options
      },
      !!options.defaultValues
    )

    const { register, values } = formUtils

    const sepaDetailsFields: GetFormContextFields<SavedSepaDetailsFieldsValue> = useMemo(() => ({
      name: register('name', { required: getDefaultErrorMessage('required') }),
      line1: register('line1', { required: getDefaultErrorMessage('required') }),
      line2: register('line2'),
      city: register('city', { required: getDefaultErrorMessage('required') }),
      postalCode: register('postalCode', {
        required: getDefaultErrorMessage('required'),
        validate: {
          isPostalCode: value => isPostalCode({ value: value || '', locale: values.country || 'any' })
        }
      }),
      country: register('country', { required: getDefaultErrorMessage('required'), deps: ['postalCode'] }),
    }), [register, values.country])

    return (
      <SavedSepaDetailsFieldsContext.Provider value={{
        fields: sepaDetailsFields,
        formUtils,
      }}>
        {children}
      </SavedSepaDetailsFieldsContext.Provider>
    )

  }
