/** Enumeration of possible states of a deal */
export enum DealState {
  NEW = 'NEW',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED',
}

/** Enumeration of available pipedrive deal stages */
export enum DealStage {
  ORDER_PLACED = 'ORDER_PLACED',
  INVOICE_CREATED = 'INVOICE_CREATED',
  INVOICE_SENT = 'INVOICE_SENT',
}
