import { CancelSubscriptionLinks, DeleteUserLinks } from 'constants/staticLinks'

export const getCancelSubscriptionLink = (language: string) => {
  switch (language.toLowerCase()) {
    case 'fr':
      return CancelSubscriptionLinks.FR
    case 'de':
      return CancelSubscriptionLinks.DE
    case 'es':
      return CancelSubscriptionLinks.ES
    case 'nl':
      return CancelSubscriptionLinks.NL
    case 'en':
    default:
      return CancelSubscriptionLinks.EN
  }
}

export const getDeleteUserLink = (language: string) => {
  switch (language.toLowerCase()) {
    case 'fr':
      return DeleteUserLinks.FR
    case 'de':
      return DeleteUserLinks.DE
    case 'es':
      return DeleteUserLinks.ES
    case 'nl':
      return DeleteUserLinks.NL
    case 'en':
    default:
      return DeleteUserLinks.EN
  }
}
