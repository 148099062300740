/** Possible automation states of order (known also as Deal) */
export enum OrderAutomationStatus {
  /** The automation has started */
  AUTOMATED = 'AUTOMATED',
  /** The order can be automated */
  AUTOMATION_READY = 'AUTOMATION_READY',
  /** The order cannot be automated because of the type of assignment selected */
  AUTOMATION_OUT_OF_SCOPE = 'AUTOMATION_OUT_OF_SCOPE',
  /** The CT has been assigned manually */
  MANUAL = 'MANUAL',
  /** Automation started on assignment level not from the entire order */
  AUTOMATED_INDIVIDUAL_ASSIGNMENTS = 'AUTOMATED_INDIVIDUAL_ASSIGNMENTS',
}
