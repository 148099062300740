import { APIRequestState, Endpoint, RoleMimetype } from 'constants/API'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, takeEvery } from 'typed-redux-saga'
import { EntityKeys, QueryType } from 'utils/reactQuery'

import { put } from 'redux-saga/effects'
import API from 'utils/API/API'
import { ActionStopAutomatedOrder } from './stopAutomatedOrder.actions'

/** Saga which stops the automation of the entire order */
export function* stopAutomatedOrderSaga(receivedAction: ActionStopAutomatedOrder) {
  const { orderId } = receivedAction.payload

  const url = Endpoint.STOP_AUTOMATED_ORDER.replace('{orderId}', encodeURI(orderId.toString()))

  const action: ActionStopAutomatedOrder = yield generalFetch(ActionTypeAPIData.STOP_AUTOMATED_ORDER,
    () => API.post(
      url,
      undefined,
      {
        headers: {
          Accept: RoleMimetype.ADMIN
        }
      },
      {
        endpoint: Endpoint.STOP_AUTOMATED_ORDER
      }
    )
  )

  const stopAutomatedOrderAction: ActionStopAutomatedOrder = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload
    }
  }

  yield put(stopAutomatedOrderAction)

  if (action.payload.request.state !== APIRequestState.OK) return
  receivedAction.queryClient?.invalidateQueries({ queryKey: [EntityKeys.ORDER, QueryType.GET_ONE, { orderId: receivedAction.payload.orderId }] })
  receivedAction.queryClient?.invalidateQueries({ queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET_ONE, { assignmentId: receivedAction.payload.assignmentId }] })
}

/** Watcher of stop automated order */
export function* stopAutomatedOrderWatcher() {
  yield all([
    takeEvery((action: ActionStopAutomatedOrder) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.STOP_AUTOMATED_ORDER]), stopAutomatedOrderSaga)
  ])
}
