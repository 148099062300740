import { SubscriptionPricingLinks } from 'constants/staticLinks/subscriptionPricing'

export const getSubscriptionPricingLink = (language: string) => {
  switch (language.toLowerCase()) {
    case 'fr':
      return SubscriptionPricingLinks.FR
    case 'de':
      return SubscriptionPricingLinks.DE
    case 'es':
      return SubscriptionPricingLinks.ES
    case 'nl':
      return SubscriptionPricingLinks.NL
    case 'en':
    default:
      return SubscriptionPricingLinks.EN
  }
}
