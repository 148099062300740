import { BEIGE_300 } from 'constants/styling/theme'
import { FC } from 'react'
import { InputWrap } from 'components/common/InputWrap'
import Stack from '@mui/material/Stack'
import { usePurchaseFlowProducts } from '../../_main/contexts/PurchaseFlowProducts.context'
import { useTranslation } from 'react-i18next'

/** @interface Props for the EditingProductComments component. */
interface Props {
  /** The unique identifier of the product. */
  productId: number
  /** Whether the selection is disabled. */
  disabledSelection?: boolean
}

/**
 * @component
 * EditingProductComments displays and handles the user comments related to a specific product.
 *
 * @example
 * <EditingProductComments productId={420} disabledSelection={false} />
 */
export const EditingProductComments: FC<Props> = ({ productId, disabledSelection }) => {

  const { t } = useTranslation(['order'])
  const { selectedProducts, setProductComment } = usePurchaseFlowProducts()

  return (
    <Stack marginTop={2}>
      <Stack gap={1}>
        <InputWrap
          label={
            !disabledSelection
              ? t('step_product.editing_product_comments_label')
              : t('step_product.editing_product_comments_header')
          }
        >
          <textarea
            id={`editing_product_comment_${productId}`}
            name={`editing_product_comment_${productId}`}
            placeholder={t('step_product.editing_product_comments_placeholder')}
            value={selectedProducts[productId]?.comment}
            disabled={disabledSelection}
            onChange={e => setProductComment(productId, e.target.value)}
            style={{
              backgroundColor: disabledSelection ? BEIGE_300 : 'inherit'
            }}
          ></textarea>
        </InputWrap>
      </Stack>
    </Stack>
  )
}
