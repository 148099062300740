import { FC, Fragment } from 'react'

import { StripePaymentContextProvider } from 'components/contexts/StripePaymentContext'
import { StripePaymentWrapperController } from './StripePaymentWrapper.controller'
import { useUpsellPayment } from '../_main/contexts'

/**
 * @component Wrapper containing stripe payment element
 * @example <StripePaymentWrapper />
 */
export const StripePaymentWrapper: FC = () => {

  const { paymentIntent } = useUpsellPayment()

  if (!paymentIntent) return <Fragment></Fragment>

  return (
    <StripePaymentContextProvider clientSecret={paymentIntent.clientSecret}>
      <StripePaymentWrapperController />
    </StripePaymentContextProvider>
  )
}
