import { FC, useCallback, useMemo } from 'react'
import { GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { MemberAccessItem, MemberRoleItem, accessDropdownItems, memberRoleItems, memberTableHead } from 'constants/workspaces'
import { Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material'
import { WorkspaceMember, WorkspaceRole } from 'models/user'

import { ChangeMemberType } from '../_main/WorkspacesProfile.controller'
import { MUIButton } from 'components/common/MUIButton'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MemberDrawerType } from '../WorkspaceProfileSettingDrawer'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { WorkspaceDropdown } from '../WorkspaceDropdown'
import { redesignMUITheme } from 'constants/styling/redesignTheme'
import { useTranslation } from 'react-i18next'
import { useWorkspacesProfileContext } from '../_main/WorkspacesProfile.context'

/**
 * @component Workspace Member Table
 * @example
 * <WorkspaceMemberTable />
 */
export const WorkspaceMemberTable: FC = () => {
  const { t } = useTranslation(['workspace_profile'])
  const isMobileView = useMediaQuery(redesignMUITheme.breakpoints.down('sm'))

  const {
    workspaceDataOfUser,
    sortedWorkspaceMembers,
    isSingleAdminInWorkspace,
    isWorkspaceEnterprise,
    setRemoveMember,
    setChangeMemberRole,
    setMemberSetting,
    setMemberDrawerType,
    setIsSettingDrawerOpen
  } = useWorkspacesProfileContext()

  const isWorkspaceRoleMember = useMemo(() => workspaceDataOfUser?.role === WorkspaceRole.MEMBER, [workspaceDataOfUser?.role])
  // Disabled access change when member role is ADMIN or workspace role is MEMBER
  const isAccessCellDisabled = useCallback((member: WorkspaceMember) => member.role === WorkspaceRole.ADMIN || isWorkspaceRoleMember, [isWorkspaceRoleMember])
  // Disabled role change and remove user when member role is last one ADMIN or workspace role is MEMBER
  const isRoleCellDisabled = useCallback((member: WorkspaceMember) => (member.role === WorkspaceRole.ADMIN && isSingleAdminInWorkspace) || isWorkspaceRoleMember, [isSingleAdminInWorkspace, isWorkspaceRoleMember])

  const onChangeMemberRole = useCallback((member: WorkspaceMember, item: MemberRoleItem) => {
    if (member.role === item.role) return
    setChangeMemberRole({
      type: ChangeMemberType.ROLE,
      memberId: member.userId,
      name: member.name,
      email: member.email,
      newRole: WorkspaceRole[item.role],
      isRestricted: member.isRestricted
    })
  }, [setChangeMemberRole])

  const onChangeMemberAccess = useCallback((member: WorkspaceMember, item: MemberAccessItem) => {
    if (member.isRestricted === item.isRestricted) return
    setChangeMemberRole({
      type: ChangeMemberType.ACCESS,
      memberId: member.userId,
      name: member.name,
      email: member.email,
      newRole: member.role,
      isRestricted: item.isRestricted
    })
  }, [setChangeMemberRole])

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {memberTableHead.map(title => (
            <TableCell key={title} sx={{ padding: isMobileView ? '.6rem .8rem' : '.6rem 1.6rem' }}>
              <Typography variant="text-xs" color={GRAY_700}>{t(title)}</Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {sortedWorkspaceMembers?.map((member) => (
          <TableRow
            key={member.userId}
            onClick={() => {
              if (isMobileView) {
                setIsSettingDrawerOpen(true)
                setMemberDrawerType(MemberDrawerType.SETTING)
                setMemberSetting({ ...member })
              }
            }}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell
              component="th"
              scope="row"
              sx={{ padding: isMobileView ? '.6rem .8rem' : '.6rem 1.6rem' }}
            >
              <Stack>
                <Typography variant="text-sm" fontWeight="bold" color={GRAY_700}>{member.name}</Typography>
                <Typography
                  variant="text-sm"
                  color={GRAY_700}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: isMobileView ? '10rem' : 'unset'
                  }}
                >
                  {member.email}
                </Typography>
              </Stack>
            </TableCell>

            <WorkspaceDropdown
              dropdownTitle={t(`role.${member.role}`)}
              disabled={isRoleCellDisabled(member) || isMobileView}
            >
              {memberRoleItems.map((item) => (
                <MUIDropdownItem
                  key={item.role}
                  isSelected={member.role === item.role}
                  onClick={() => onChangeMemberRole(member, item)}
                >
                  <Stack gap=".2rem">
                    <Typography variant="text-md" color={GRAY_900} fontWeight="medium">{t(item.title)}</Typography>
                    <Typography variant="text-sm" color={GRAY_700}>{t(item.subTitle)}</Typography>
                  </Stack>
                </MUIDropdownItem>
              ))}
            </WorkspaceDropdown>

            <WorkspaceDropdown
              colSpan={isRoleCellDisabled(member) ? 2 : 0}
              dropdownTitle={member.isRestricted ? t('restricted_access') : t('full_access')}
              disabled={isAccessCellDisabled(member) || isMobileView}
              isLocked={!isWorkspaceEnterprise}
            >
              {accessDropdownItems.map((item) => (
                <MUIDropdownItem
                  key={item.subTitle}
                  isSelected={member.isRestricted === item.isRestricted}
                  onClick={() => onChangeMemberAccess(member, item)}
                >
                  <Stack gap=".2rem">
                    <Typography variant="text-md" color={GRAY_900} fontWeight="medium">{t(item.title)}</Typography>
                    <Typography variant="text-sm" color={GRAY_700}>{t(item.subTitle)}</Typography>
                  </Stack>
                </MUIDropdownItem>
              ))}
            </WorkspaceDropdown>

            {!isRoleCellDisabled(member) &&
              <TableCell
                align="right"
                sx={{
                  padding: isMobileView ? '.6rem .8rem' : 'unset',
                  width: isMobileView ? '10%' : '6rem'
                }}
              >
                <MUIDropdown
                  button={(isOpen, action) => (
                    <MUIButton
                      isIconButton
                      size="sm"
                      type="secondaryNoBorder"
                      onClick={(e) => !isMobileView && action(e)}
                    >
                      <MoreHorizIcon fontSize="large" />
                    </MUIButton>
                  )}
                >
                  <MUIDropdownItem>
                    <MUIButton size="sm" type="orangeSecondaryNoBorder" onClick={() => setRemoveMember(member.email)}>
                      {t('remove_user')}
                    </MUIButton>
                  </MUIDropdownItem>
                </MUIDropdown>
              </TableCell>
            }
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
