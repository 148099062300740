import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { ActionLoadWatermarkLogo, WatermarkLogoStore } from '.'

import { APIRequestState } from 'constants/API'
import { APIRequest } from 'models/API'

/** Redux data reducer for load watermark logo */
export const reduceLoadWatermarkLogo = (state: WatermarkLogoStore, action: ActionLoadWatermarkLogo) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.LOAD_WATERMARK_LOGO) return state

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
      return new APIRequest(APIRequestState.BEFORE_START)
    case ActionTypeAPIEvent.FETCH:
      return new APIRequest(APIRequestState.RUNNING)
    case ActionTypeAPIEvent.RECEIVED:
      return action.payload.request
    default:
      return state
  }
}
