import { Path, PathTypeValue } from 'constants/router'
import { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router'

import constate from 'constate'
import { Nullable } from 'models/helpers'

export const [SubscriptionFlowNavigationGuardProvider] = constate(() => {
  const location = useLocation()
  const navigate = useNavigate()

  /** Keep track of previous route path */
  const previousPath = useRef<Nullable<PathTypeValue>>(null)

  // Handle redirecting/guard rules for routes and update previous route value
  useEffect(() => {
    // Save previous route before updating it
    const prevPath = previousPath.current

    /*
      Update previous path with current one - navigation can happen later, so handle it before.
      Check whether path is valid Path and if not, push 'null'
    */
    previousPath.current = (Object.values(Path) as string[]).includes(location.pathname)
      ? location.pathname as PathTypeValue
      : null

    switch (location.pathname) {
      // BKBN-518 Temporary hiding adding members in Subscription flow
      // Redirect user to root route if coming from any other page than 1st or validation step
      case Path.SUBSCRIPTION_MEMBERS:
        navigate(Path.SUBSCRIPTION)
        break

      // Redirect user to root route if coming from any other page than 2nd step
      case Path.SUBSCRIPTION_VALIDATION:
        if (!prevPath || prevPath !== Path.SUBSCRIPTION_CREATION) navigate(Path.SUBSCRIPTION)
        break

      default:
        break
    }
  }, [location, navigate])
})
