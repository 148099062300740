import { GRAY_700, GRAY_900 } from 'constants/styling/theme'
import React, { ReactNode, useMemo } from 'react'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

type SummaryType = 'normal' | 'total' | 'subtotal' | 'discount'

/** Props for the OrderSummaryContainer component. */
interface Props {
  /** The text or element for the summary. */
  summaryText: string | ReactNode
  /** The price to be displayed in the summary. */
  price: string
  /** The type of summary (normal, total, subtotal, discount). */
  type?: SummaryType
}

/**
 * @component
 * OrderSummaryContainer displays the order summary information.
 *
 * @example
 * <OrderSummaryContainer summaryText="Bears, beets, Battlestar Galactica." price="$100" type="total" />
 */
export const OrderSummaryContainer: React.FC<Props> = ({
  summaryText,
  price,
  type = 'normal',
}) => {

  const justifyContent = useMemo(() => {
    return (type === 'normal') ? 'inherit' : 'space-between'
  }, [type])

  return (
    <Stack direction="row" spacing={0.5} alignItems="center" justifyContent={justifyContent}>

      <Typography variant='text-sm' color={GRAY_700}>
        {summaryText}
      </Typography>

      {(type === 'normal' || type === 'discount') &&
        <Typography variant='text-sm' fontWeight={600} color={GRAY_900} whiteSpace="nowrap">
          {price}
        </Typography>
      }

      {type === 'subtotal' &&
        <Typography variant='h3' lineHeight={2}>
          {price}
        </Typography>
      }

      {type === 'total' &&
        <Typography variant='h2' fontSize={20} lineHeight={2}>
          {price}
        </Typography>
      }

    </Stack>
  )
}
