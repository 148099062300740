import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { ActionUpdatePreferredPaymentMethod, UpdatePreferredPaymentMethodStore } from '.'

/** Redux data reducer for update preferred payment method */
export const reduceUpdatePreferredPaymentMethod = (state: UpdatePreferredPaymentMethodStore, action: ActionUpdatePreferredPaymentMethod) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.UPDATE_PREFERRED_PAYMENT_METHOD) return state

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
    case ActionTypeAPIEvent.FETCH:
    case ActionTypeAPIEvent.RECEIVED:
      return {
        request: action.payload.request,
        paymentMethod: action.payload.paymentMethod
      }

    default:
      return state
  }
}