/** Enum of all available languages */
export enum Language {
  /** English */
  EN = 'EN',

  /** French */
  FR = 'FR',

  /** German */
  DE = 'DE',

  /** Spanish */
  ES = 'ES',

  /** Dutch */
  NL = 'NL',

  /** Italian */
  IT = 'IT',
}