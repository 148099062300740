import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { FC, MouseEvent, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { APIRequestState } from 'constants/API'
import { ActionTypeAPIData } from 'constants/redux'
import { MUIBadge } from '../MUIBadge'
import { MUIButton } from '../MUIButton'
import { PreferredPaymentMethodEnum } from 'constants/user'
import { PreferredPaymentMethodType } from 'models/user'
import { RootStore } from 'models/redux'
import styles from './PreferredPaymentMethodSwitch.module.sass'
import { updatePreferredPaymentMethod } from 'redux/Individual/User/UpdatePreferredPaymentMethod'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

interface Props {
  paymentMethod: PreferredPaymentMethodType
  /** Whether user me is directly updated on value change (default true) */
  directlyUpdateUserMe?: boolean
}

export const PreferredPaymentMethodSwitch: FC<Props> = ({
  paymentMethod,
  directlyUpdateUserMe = true,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['preferred_payment_method'])

  const { clientData, hasUserActiveSubscription } = useUserData()

  const updateMethodAction = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.UPDATE_PREFERRED_PAYMENT_METHOD])
  const initialPreferredMethod = useMemo(() => clientData?.preferredPaymentMethod || PreferredPaymentMethodEnum.NONE, [clientData])
  const updateMethodActionState = useMemo(() => updateMethodAction.request.state, [updateMethodAction.request])
  const activePreferredMethod = useMemo(() => (updateMethodActionState === APIRequestState.OK && updateMethodAction.paymentMethod) || initialPreferredMethod, [updateMethodActionState, updateMethodAction.paymentMethod, initialPreferredMethod])
  const isThisMethodBeingSet = useMemo(() => updateMethodAction.paymentMethod === paymentMethod, [updateMethodAction.paymentMethod, paymentMethod])

  const onSaveAsDefaultPayment = useCallback((e: MouseEvent) => {
    e.stopPropagation()

    logAnalyticsEvent(AnalyticsEvent.PURCHASE_FLOW_PAYMENT_METHOD_SAVED, {
      PaymentMethodSelected: paymentMethod,
      IsSubscribed: hasUserActiveSubscription
    })

    dispatch(updatePreferredPaymentMethod(paymentMethod, directlyUpdateUserMe))
  }, [directlyUpdateUserMe, dispatch, hasUserActiveSubscription, paymentMethod])

  return (
    <div className={styles.preferredPaymentMethodSwitch}>

      {(activePreferredMethod !== paymentMethod) &&
        <MUIButton
          className={styles.saveButton}
          disabled={updateMethodActionState === APIRequestState.RUNNING}
          onClick={e => onSaveAsDefaultPayment(e)}
          type="secondaryBorder"
          isLoading={isThisMethodBeingSet && updateMethodActionState === APIRequestState.RUNNING}
        >
          {t('save')}
        </MUIButton>
      }

      {isThisMethodBeingSet && updateMethodActionState === APIRequestState.ERROR &&
        <span className={styles.saveError}>
          {t('error')}
        </span>
      }

      {activePreferredMethod === paymentMethod &&
        <MUIBadge color="blue" variant="filled" label={t('badge')} />
      }

    </div>
  )
}
