/** Reasons for cancelling an order */
export enum CancellationReason {
  WEATHER = 'WEATHER',
  NOT_NEEDED_ANYMORE = 'NOT_NEEDED_ANYMORE',
  CONTACT_PERSON_NOT_AVAILABLE = 'CONTACT_PERSON_NOT_AVAILABLE',
  NOT_READY_FOR_SHOOTING = 'NOT_READY_FOR_SHOOTING',
  ILLNESS = 'ILLNESS',
  OTHER = 'OTHER',
}

export enum DocumentProductCancellationReason {
  NOT_NEEDED_ANYMORE = 'NOT_NEEDED_ANYMORE',
  OTHER = 'OTHER',
}

/** Maximum number of characters for specifying the reason for cancellation of the order when "OTHER" reason is selected */
export const maxCancellationReasonTextCharacters = 150
