import { MUIDivider } from 'components/common/MUIDivider'
import { OrderAssignmentStatusFilter } from 'constants/misc'
import { Type } from './AdminDashboard.page'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface AdminInfiniteListHeaderProps {
  filters: {
    status: OrderAssignmentStatusFilter
    userIds: string[]
    type: string
    search: string | null
  }
}

/**
 * @component Admin dashboard Infinite List Item
 * @example <AdminInfiniteListHeader filters={filters} />
 */
export const AdminInfiniteListHeader: React.FC<AdminInfiniteListHeaderProps> = ({ filters }) => {
  const { t } = useTranslation(['dashboard'])

  const orderCardTitle = useMemo(() => {
    switch (`${filters.type}-${filters.status}`) {
      case `${Type.ORDER}-${OrderAssignmentStatusFilter.ONGOING}`:
        return t('dashboard:ongoing_deals')
      case `${Type.ORDER}-${OrderAssignmentStatusFilter.FINISHED}`:
        return t('dashboard:finished_deals')
      case `${Type.ORDER}-${OrderAssignmentStatusFilter.CANCELLED}`:
        return t('dashboard:cancelled_deals')
      case `${Type.ASSIGNMENT}-${OrderAssignmentStatusFilter.ONGOING}`:
        return t('dashboard:ongoing_assignments')
      case `${Type.ASSIGNMENT}-${OrderAssignmentStatusFilter.FINISHED}`:
        return t('dashboard:finished_assignments')
      case `${Type.ASSIGNMENT}-${OrderAssignmentStatusFilter.CANCELLED}`:
        return t('dashboard:cancelled_assignments')

      default:
        return ''
    }
  }, [filters.status, filters.type, t])

  return (
    <div>
      <h2>{orderCardTitle}</h2>
      <MUIDivider />
    </div>
  )
}
