import { APIRequestState, Endpoint, RoleMimetype } from 'constants/API'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import API from 'utils/API/API'
import { ActionUpdatePreferredPaymentMethod } from './updatePreferredPaymentMethod.actions'
import { ActionUserMe } from '../UserMe'

/** Saga which fetches update preferred payment method */
export function* updatePreferredPaymentMethodSaga(receivedAction: ActionUpdatePreferredPaymentMethod) {
  const { paymentMethod, callUpdateUserMe } = receivedAction.payload

  const url = Endpoint.UPDATE_PREFERRED_PAYMENT_METHOD.replace('{paymentMethod}', encodeURI(paymentMethod.toString()))

  const action: ActionUpdatePreferredPaymentMethod = yield generalFetch(ActionTypeAPIData.UPDATE_PREFERRED_PAYMENT_METHOD, () => API.put(
    url,
    undefined,
    {
      headers: {
        Accept: RoleMimetype.CLIENT, // TODO: Fix on BE so we can send proper accept header
      },
    },
    {
      endpoint: Endpoint.UPDATE_PREFERRED_PAYMENT_METHOD,
    }
  ))

  const updatePreferredPaymentMethodAction: ActionUpdatePreferredPaymentMethod = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }

  yield put(updatePreferredPaymentMethodAction)

  if (action.payload.request.state !== APIRequestState.OK) return
  if (callUpdateUserMe) yield call(updateUserMe, updatePreferredPaymentMethodAction)
}

/** Saga which updates user me */
export function* updateUserMe(receivedAction: ActionUpdatePreferredPaymentMethod) {
  const userMeAction: ActionUserMe = {
    type: [ActionTypeAPIEvent.RECEIVED, ActionTypeAPIData.USER_ME],
    payload: {
      request: receivedAction.payload.request,
    },
  }
  yield put(userMeAction)
}

/** Watcher of update preferred payment method actions */
export function* updatePreferredPaymentMethodWatcher() {
  yield all([
    takeEvery((action: ActionUpdatePreferredPaymentMethod) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.UPDATE_PREFERRED_PAYMENT_METHOD]), updatePreferredPaymentMethodSaga),
  ])
}
