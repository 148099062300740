import { HelpCenterLinks } from 'constants/staticLinks'

export const getHelpCenterLink = (language: string) => {
  switch (language.toLowerCase()) {
    case 'fr':
      return HelpCenterLinks.FR
    case 'de':
      return HelpCenterLinks.DE
    case 'en':
    default:
      return HelpCenterLinks.EN
  }
}
