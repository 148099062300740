import { EnergeticMeasure, RenewableEnergySource, RenewableEnergySourceUsage } from 'constants/purchaseFlow/consumptionCertificateTypes'

import { Nullable } from 'models/helpers'
import constate from 'constate'
import { useState } from 'react'

export const [ConsumptionInformationContextProvider, useConsumptionInformation] = constate(() => {
  const [systemType, setSystemType] = useState<RenewableEnergySource>(RenewableEnergySource.NONE)
  const [useType, setUseType] = useState<RenewableEnergySourceUsage>(RenewableEnergySourceUsage.NONE)
  const [energeticConditionType, setEnergeticConditionType] = useState<Set<EnergeticMeasure>>(new Set([]))
  const [startDate, setStartDate] = useState<Nullable<moment.Moment>>(null)
  const [endDate, setEndDate] = useState<Nullable<moment.Moment>>(null)
  const [hasPropertyVacant, setHasPropertyVacant] = useState<boolean>(true)
  const [area, setArea] = useState<Nullable<number>>(null)

  return {
    systemType,
    setSystemType,
    useType,
    setUseType,
    energeticConditionType,
    setEnergeticConditionType,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    hasPropertyVacant,
    setHasPropertyVacant,
    area,
    setArea
  }
})
