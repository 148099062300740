import { cs, de, enGB, enUS, es, fr, it, nl } from 'date-fns/locale'

import { getUserLocale } from 'get-user-locale'
import { registerLocale } from 'react-datepicker'

/** Registered react-datepicker locales used for the purpose of testing and comparing with language enum */
export const reactDatepickerRegisteredLocales = {
  en: enUS,
  fr,
  de,
  es,
  nl,
  it,
}

// hardcoded locales
registerLocale('en-us', enUS)
registerLocale('en-gb', enGB)
registerLocale('cs-cz', cs)

// dynamic locales
for (const [key, locale] of Object.entries(reactDatepickerRegisteredLocales)) {
  registerLocale(key, locale)
}

export const getDatePickerLocale = (localeString: string) => {
  localeString = localeString.toLocaleLowerCase()

  switch (localeString) {
    case 'en-us':
    case 'en-gb':
    case 'cs-cz':
      return localeString
    default:
      return localeString.replace(/-[a-zA-Z]{2}/igm, '')
  }
}

export const getUserDatePickerLocale = () => getDatePickerLocale(getUserLocale())