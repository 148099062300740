import { DependencyList, EffectCallback, useEffect, useRef } from 'react'

/**
 * Custom hook for running callbacks ONLY when component unmounts but when dependencies on variables are needed.   
 * With normal useEffect this would result in running the cleanup on every re-render instead just unmount
 * @param unmountCallback - function to run on component unmount
 * @param dependencyArray - dependencies for callback that need to be upToDate when function is going to be ran
 */
export function useOnUnmountWithDeps(unmountCallback: EffectCallback, dependencyArray: DependencyList) {
  // Hold mark for whether component will unmount
  const willUnmount = useRef(false)

  // This effect only determines when component actually unmounts and then flips the flag for use in the callback effect
  useEffect(() => () => {
    willUnmount.current = true
  }, [])

  /*
    Use effect that performs the unmount callback only when willUnmount
    has been flagged, but updates dependencies on every change in depArray
  */
  useEffect(
    () => () => {
      if (willUnmount.current) {
        unmountCallback()
      }
    },
    // Dep for unmount callback not needed, since the dependecyArray should include all deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencyArray
  )
}
