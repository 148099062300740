import { AttachMoney, CurrencyExchange, ExpandMore } from '@mui/icons-material'
import { Box, Divider, Stack } from '@mui/material'
import { CommercialPropertyType, ResidentialPropertyType, VisualsMarketingCategory, VisualsMarketingListingGoal, VisualsMarketingSizeUnit, VisualsMarketingTargetAudience, VisualsMarketingTargetPlatform, VisualsMarketingToneOfVoice, propertyTypesWithFloor } from '../_main/VisulalsMarketing.constants'
import { FC, Fragment, useCallback, useMemo } from 'react'
import { useMarketingMaterials, useVisualsMarketing } from '../_main/contexts'

import { BEIGE_500 } from 'constants/styling/theme'
import Button from 'components/common/Button/Button'
import { Currency } from 'constants/misc'
import { FeatureFlag } from 'utils/featureFlags/featureFlags'
import { FormFieldWrap } from '../FormFieldWrap'
import { IconButton } from '@mui/material'
import { KeywordInput } from '../KeywordInput'
import { Language } from 'translations/Language'
import { QueryStatus } from 'components/common/QueryStatus'
import classnames from 'classnames'
import styles from './VisualsMarketingSidebar.module.sass'
import { useFlag } from '@unleash/proxy-client-react'
import { useTranslation } from 'react-i18next'
import { useVisualsMarketingForm } from '../_main/contexts/VisualsMarketing.form'

const GoalIconMap = {
  [VisualsMarketingListingGoal.RENT]: <CurrencyExchange />,
  [VisualsMarketingListingGoal.SELL]: <AttachMoney />,
}

interface Props {
  isMobile: boolean
  onClose?: () => void
}

/**
 * Sidebar with form for providing information for generating text
 */
export const VisualsMarketingSidebar: FC<Props> = ({ isMobile, onClose }) => {

  const allowPropertyKeywords = useFlag(FeatureFlag.ALLOW_PROPERTY_KEYWORDS)
  const allowFreeTextHighlights = useFlag(FeatureFlag.ALLOW_PROPERTY_FREETEXT)
  const allowCommercialCategory = useFlag(FeatureFlag.ALLOW_MARKETING_COMMERCIAL_CATEGORY)

  const { t } = useTranslation([
    'visual_marketing_property_form',
    'visual_marketing_property_type',
    'visual_marketing_category',
    'visual_marketing_goal',
    'visual_marketing_unit',
    'visual_marketing_tone_of_voice',
    'visual_marketing_target_audience',
    'common',
    'language',
  ])

  const {
    formUtils,
    category,
    propertyType,
    goal,
    floor,
    floorSize,
    floorSizeUnit,
    listingPrice,
    listingPriceUnit,
    propertyKeywords,
    bedrooms,
    bathrooms,
    livingRooms,
    kitchens,
    totalRooms,
    outputLanguage,
    toneOfVoice,
    targetAudience,
    name,
    phoneNumber,
    email,
    goalValue,
    propertyKeywordsValue,
    freeTextHighlightsValue,
    propertyTypeValue,
    savedPropertyDetailsQuery,
    propertyCategoryValue,
    fillWithFetchedData,
  } = useVisualsMarketingForm()

  const { isPropertyDetailsEditing, setIsPropertyDetailsEditing } = useVisualsMarketing()

  const { generateText, generators, marketingMaterials, saveDetails, savePropertyDetails } = useMarketingMaterials()

  const isGenerated = useMemo(() => !!marketingMaterials[VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE], [marketingMaterials])
  const isSaveDetailsRunning = useMemo(() => savePropertyDetails.isPending, [savePropertyDetails.isPending])

  const isGenerating = useMemo(
    () => generators.LINKEDIN.isPending || generators.INSTAGRAM.isPending || generators.REAL_ESTATE_BROCHURE.isPending,
    [generators.INSTAGRAM.isPending, generators.LINKEDIN.isPending, generators.REAL_ESTATE_BROCHURE.isPending]
  )

  const isContentDisabled = useMemo(() => {
    // Initial loading
    if (savedPropertyDetailsQuery.isPending) return true
    // When not editing after already having content
    if (isGenerated && !isPropertyDetailsEditing) return true
    if (isSaveDetailsRunning) return true
    if (isGenerating) return true
    return false
  }, [isGenerated, isGenerating, isPropertyDetailsEditing, isSaveDetailsRunning, savedPropertyDetailsQuery.isPending])

  const canGenerate = useMemo(() => {
    if (isContentDisabled) return false
    if (!formUtils.formState.isValid) return false
    return true
  }, [formUtils.formState.isValid, isContentDisabled])

  const canSave = useMemo(() => {
    if (!isGenerated) return false
    if (!formUtils.formState.isValid) return false
    if (isSaveDetailsRunning) return false
    if (isGenerating) return false
    return true
  }, [formUtils.formState.isValid, isGenerated, isGenerating, isSaveDetailsRunning])

  const canToggleEdit = useMemo(() => {
    if (isSaveDetailsRunning) return false
    if (isGenerating) return false
    return true
  }, [isGenerating, isSaveDetailsRunning])

  const availablePropertyCategories = useMemo(() => {
    if (allowCommercialCategory) {
      // Returns all property categories (residential and commercial)
      return Object.values(VisualsMarketingCategory)
    }
    return [VisualsMarketingCategory.RESIDENTIAL]
  }, [allowCommercialCategory])

  const propertyTypes = useMemo(() => {
    if (propertyCategoryValue === VisualsMarketingCategory.RESIDENTIAL) {
      return Object.values(ResidentialPropertyType)
    }
    // Returns commercial property types
    return Object.values(CommercialPropertyType)
  }, [propertyCategoryValue])

  const refresh = useCallback(() => {
    for (let [platform, materials] of Object.entries(marketingMaterials)) {
      if (!materials.id) continue
      generateText(platform as VisualsMarketingTargetPlatform, false, false)
    }
  }, [generateText, marketingMaterials])

  const saveAndRefresh = useCallback(async () => {
    saveDetails(() => {
      refresh()
      setIsPropertyDetailsEditing(false)
      setTimeout(() => savePropertyDetails.reset(), 3000)
    })
  }, [refresh, saveDetails, savePropertyDetails, setIsPropertyDetailsEditing])

  const handleCancel = useCallback(() => {
    setIsPropertyDetailsEditing(false)
    fillWithFetchedData()
  }, [fillWithFetchedData, setIsPropertyDetailsEditing])

  return (
    <Stack
      className={classnames(
        styles.marketingSidebar,
        { [styles.isMobile]: isMobile }
      )}
      sx={{
        height: '100%',
        overflow: 'hidden',
      }}
    >

      <Box flex="0 0 auto" padding="0 1.5rem">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <h2>
            {t('property_info')}
          </h2>
          {!!onClose && <IconButton size="medium" onClick={() => onClose()}><ExpandMore fontSize="large" /></IconButton>}
        </Stack>
        <Divider />
      </Box>

      <Box
        flex="1 1 100%"
        overflow="auto"
        padding="0 1.5rem"
        paddingTop={2}
      >

        <Box sx={{
          pointerEvents: isContentDisabled ? 'none' : 'all',
          opacity: isContentDisabled ? 0.65 : 1,
        }}>

          <h4>
            {t('general_info')}
          </h4>

          <FormFieldWrap
            label={t('category')}
            field={category}
            form={formUtils}
            className={styles.formField}
          >
            <select {...category}>
              {availablePropertyCategories.map(
                (category) => (
                  <option key={category} value={category}>
                    {t(`visual_marketing_category:${category}`)}
                  </option>
                )
              )}
              {/** TODO: Remove option after releasing new commercial category. */}
              {!allowCommercialCategory &&
                <option disabled>{t('more_soon')}</option>
              }
            </select>
          </FormFieldWrap>

          <FormFieldWrap
            label={t('property_type')}
            field={propertyType}
            form={formUtils}
            className={styles.formField}
          >
            <select {...propertyType}>
              {propertyTypes.map(
                (type) => (
                  <option key={type} value={type}>
                    {t(`visual_marketing_property_type:${type}`)}
                  </option>
                )
              )}
            </select>
          </FormFieldWrap>

          {/* SWITCH */}
          <FormFieldWrap
            label={t('goal')}
            field={goal}
            form={formUtils}
            className={styles.formField}
          >
            <Stack
              direction="row"
              gap={1}
              padding={.5}
              bgcolor={BEIGE_500}
              borderRadius="8px"
              className={styles.switch}
            >
              {Object.values(VisualsMarketingListingGoal).map(
                (goal) => (
                  <Box flex="1 1 50%" key={goal}>
                    <Button
                      className={classnames(
                        styles.switchButton,
                        { [styles.inactive]: goalValue !== goal }
                      )}
                      onClick={() => formUtils.setValue('goal', goal, { shouldValidate: true })}
                      textAndIcon
                    >
                      {GoalIconMap[goal]}
                      {t(`visual_marketing_goal:${goal}`)}
                    </Button>
                  </Box>
                )
              )}
            </Stack>
          </FormFieldWrap>

          {propertyTypesWithFloor.has(propertyTypeValue) &&
            <FormFieldWrap
              label={t('floor')}
              field={floor}
              form={formUtils}
              className={styles.formField}
            >
              <input type="number" {...floor} onWheel={e => e.currentTarget.blur()} />
            </FormFieldWrap>
          }

          <FormFieldWrap
            label={t('floorSize')}
            field={floorSize}
            form={formUtils}
            className={styles.formField}
          >
            <Stack direction="row" gap={2}>

              <Box flex="1 1 100%">
                <input type="number" {...floorSize} onWheel={e => e.currentTarget.blur()} />
              </Box>

              <Box flex="0 0 100px">
                <select {...floorSizeUnit}>
                  {Object.values(VisualsMarketingSizeUnit).map(
                    (unit) => (
                      <option key={unit} value={unit}>
                        {t(`visual_marketing_unit:${unit}`)}
                      </option>
                    )
                  )}
                </select>
              </Box>

            </Stack>
          </FormFieldWrap>

          <FormFieldWrap
            label={t(goalValue === VisualsMarketingListingGoal.RENT ? 'price_rent' : 'price')}
            field={listingPrice}
            form={formUtils}
            className={styles.formField}
          >
            <Stack direction="row" gap={2}>

              <Box flex="1 1 100%">
                <input type="number" {...listingPrice} />
              </Box>

              <Box flex="0 0 100px">
                <select {...listingPriceUnit}>
                  {Object.values(Currency).map(
                    (curency) => (
                      <option key={curency} value={curency}>
                        {curency}
                      </option>
                    )
                  )}
                </select>
              </Box>

            </Stack>
          </FormFieldWrap>

          {(allowPropertyKeywords || allowFreeTextHighlights) &&
            <FormFieldWrap
              label={t('property_keywords')}
              field={propertyKeywords}
              form={formUtils}
            >
              {allowFreeTextHighlights ?
                (
                  <textarea
                    className={styles.keywordInput}
                    value={freeTextHighlightsValue}
                    onChange={(e) => formUtils.setValue('freeTextHighlights', e.target.value)}
                    placeholder={t('freetext_placeholder', { ns: 'keyword_input' })}
                  ></textarea>
                ) :
                (
                  <KeywordInput
                    autofocus={false}
                    className={styles.keywordInput}
                    keywords={!!propertyKeywordsValue ? propertyKeywordsValue.split(';') : []}
                    onChange={(keywords) => formUtils.setValue('propertyKeywords', keywords.join(';'))}
                    fieldInfo={t('keywords_note')}
                  />
                )
              }
            </FormFieldWrap>
          }

          {!!propertyCategoryValue && propertyCategoryValue === VisualsMarketingCategory.RESIDENTIAL &&
            <Fragment>
              <h4>
                {t('room_details')}
              </h4>

              <Stack className={styles.wrapBox} direction="row" flexWrap="wrap" gap={2}>

                <Box flex="1 1 100%">
                  <FormFieldWrap label={t('num_of_rooms')} field={totalRooms} form={formUtils}>
                    <input type="number" {...totalRooms} onWheel={e => e.currentTarget.blur()} />
                  </FormFieldWrap>
                </Box>

                <Box flex="1 1 45%">
                  <FormFieldWrap label={t('bedrooms')} field={bedrooms} form={formUtils}>
                    <input type="number" {...bedrooms} onWheel={e => e.currentTarget.blur()} />
                  </FormFieldWrap>
                </Box>

                <Box flex="1 1 45%">
                  <FormFieldWrap label={t('bathrooms')} field={bathrooms} form={formUtils}>
                    <input type="number" {...bathrooms} onWheel={e => e.currentTarget.blur()} />
                  </FormFieldWrap>
                </Box>

                <Box flex="1 1 45%">
                  <FormFieldWrap label={t('kitchens')} field={kitchens} form={formUtils}>
                    <input type="number" {...kitchens} onWheel={e => e.currentTarget.blur()} />
                  </FormFieldWrap>
                </Box>

                <Box flex="1 1 45%">
                  <FormFieldWrap label={t('living_rooms')} field={livingRooms} form={formUtils}>
                    <input type="number" {...livingRooms} onWheel={e => e.currentTarget.blur()} />
                  </FormFieldWrap>
                </Box>

              </Stack>
            </Fragment>
          }

          <h4>
            {t('contact')}
          </h4>

          <FormFieldWrap
            label={t('common:Name')}
            field={name}
            form={formUtils}
            className={styles.formField}
          >
            <input type="text" {...name} />
          </FormFieldWrap>

          <FormFieldWrap
            label={t('common:Phone_number')}
            field={phoneNumber}
            form={formUtils}
            className={styles.formField}
          >
            <input type="text" {...phoneNumber} />
          </FormFieldWrap>

          <FormFieldWrap
            label={t('common:Email')}
            field={email}
            form={formUtils}
            className={styles.formField}
          >
            <input type="email" {...email} />
          </FormFieldWrap>

          <h4>
            {t('text_settings')}
          </h4>

          <FormFieldWrap
            label={t('output_lang')}
            field={outputLanguage}
            form={formUtils}
            className={styles.formField}
          >
            <select {...outputLanguage}>
              {Object.values(Language).map(
                (lang) => (
                  <option key={lang} value={lang}>
                    {t(`language:${lang}`)}
                  </option>
                )
              )}
            </select>
          </FormFieldWrap>

          <FormFieldWrap
            label={t('tone_of_voice')}
            field={toneOfVoice}
            form={formUtils}
            className={styles.formField}
          >
            <select {...toneOfVoice}>

              {/* Null option */}
              <option value={''}>
                {t('visual_marketing_tone_of_voice:NON_SPECIFIC')}
              </option>

              {Object.values(VisualsMarketingToneOfVoice).map(
                (tone) => (
                  <option key={tone} value={tone}>
                    {t(`visual_marketing_tone_of_voice:${tone}`)}
                  </option>
                )
              )}

            </select>
          </FormFieldWrap>


          <FormFieldWrap
            label={t('target_audience')}
            field={targetAudience}
            form={formUtils}
            className={styles.formField}
          >
            <select {...targetAudience}>

              {/* Null option */}
              <option value={''}>
                {t('visual_marketing_target_audience:NON_SPECIFIC')}
              </option>

              {Object.values(VisualsMarketingTargetAudience).map(
                (audience) => (
                  <option key={audience} value={audience}>
                    {t(`visual_marketing_target_audience:${audience}`)}
                  </option>
                )
              )}

            </select>
          </FormFieldWrap>


        </Box>
      </Box>

      <Box
        flex="1 1 auto"
        padding="0 1.5rem 1.5rem 1.5rem"
      >
        <Divider sx={{ marginBottom: '1.5rem' }} />

        <QueryStatus
          query={savePropertyDetails}
          spaceBottomRem={1}
          onPurge={() => savePropertyDetails.reset()}
        />

        {!isGenerated &&
          <QueryStatus
            query={generators.REAL_ESTATE_BROCHURE}
            spaceBottomRem={1}
            onPurge={() => generators.REAL_ESTATE_BROCHURE.reset()}
            showStates={['error']}
          />
        }

        <Stack direction="row" gap={2}>

          {!isPropertyDetailsEditing && isGenerated &&
            <Button
              className={styles.cta}
              type="secondary nobackground"
              disabled={!canToggleEdit}
              onClick={() => setIsPropertyDetailsEditing(true)}
            >
              {t('common:Edit')}
            </Button>
          }

          {isPropertyDetailsEditing &&
            <Box flex="1 0 auto">
              <Button
                type="secondary nobackground"
                disabled={!canToggleEdit}
                onClick={() => handleCancel()}
              >
                {t('common:Cancel')}
              </Button>
            </Box>
          }

          {!isGenerated &&
            <Button
              className={styles.cta}
              disabled={!canGenerate}
              onClick={() => generateText(VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE, false)}
            >
              {t('cta')}
            </Button>
          }

          {!!isGenerated && isPropertyDetailsEditing &&
            <Button
              className={styles.cta}
              disabled={!canSave}
              onClick={() => saveAndRefresh()}
            >
              {t('save_and_regenerate')}
            </Button>
          }
        </Stack>
      </Box>

    </Stack>
  )
}
