import { ActionListPurchaseSessionVisuals, ListPurchaseSessionVisualsStore } from '.'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'
import { cloneDeep } from 'lodash'

/** Redux data reducer for list all purcahse flow session visuals */
export const reduceListPurchaseSessionVisuals = (state: ListPurchaseSessionVisualsStore, action: ActionListPurchaseSessionVisuals) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.LIST_PURCHASE_SESSION_VISUALS) return state
  const newState = cloneDeep(state)

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
      newState[action.payload.sessionId] = new APIRequest(APIRequestState.BEFORE_START)
      return newState
    case ActionTypeAPIEvent.FETCH:
      newState[action.payload.sessionId] = new APIRequest(APIRequestState.RUNNING)
      return newState
    case ActionTypeAPIEvent.RECEIVED:
      newState[action.payload.sessionId] = action.payload.request
      return newState
    default:
      return state
  }
}