import { Box, Typography } from '@mui/material'

import { CentralizedHotWater } from './CentralizedHotWater.component'
import { DecentralizedHotWater } from './DecentralizedHotWater.component'
import { GRAY_900 } from 'constants/styling/theme'
import { HeatingCombinationType } from 'constants/purchaseFlow'
import { MUIDivider } from 'components/common/MUIDivider'
import React from 'react'
import { VentilationHeatingProps } from '../_main/VentilationAndHeating.component'
import { useTranslation } from 'react-i18next'
import { useVentilationAndHeating } from '../_main/VentilationAndHeating.context'

/**
 * @component HotWater 
 * @description Renders options for the user to provide information about centralized/decentralized hot water.
 * 
 * @example
 * <HotWater id="heating-system-1" />
 */
export const HotWater: React.FC<VentilationHeatingProps> = ({ id }) => {
  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.energy_heating_page.heating_system' })
  const { currentHeatingSystem } = useVentilationAndHeating()

  const isCentralHotWater = currentHeatingSystem(id)?.heatingCombinedWith?.has(HeatingCombinationType.CENTRAL_HOT_WATER_GENERATION) ?? false

  return (
    <>
      <Box paddingTop={2} width="100%">
        <Typography color={GRAY_900} variant='text-md' fontWeight={600}>{t(`${isCentralHotWater ? 'centralized_hot_water' : 'decentralized_hot_water'}`)}</Typography>
        <MUIDivider margin={2} />
      </Box>

      {isCentralHotWater
        ? <CentralizedHotWater id={id} />
        : <DecentralizedHotWater id={id} />
      }
    </>
  )
}
