import { FC, ReactNode, useCallback, useState } from 'react'

import { CounterInput } from 'components/common/CounterInput'
import { MUIExpandableCard } from 'components/common/MUICards/MUIExpandableCard'
import Stack from '@mui/material/Stack'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Whether the numeric input is on the left or right side */
  inputPosition?: 'left' | 'right'
  /** Optional content to display as expandable when quantity exceeds threshold */
  expandableContent?: ReactNode
  /** Whether content should be expanded or not or wheter it should be handled by internal component logic (auto = default) */
  expanded?: boolean | 'auto'
  /** Action to be called on quantity value change*/
  onQuantityChange?: (quantity: number | undefined) => void
  /** Default quantity (default 0) */
  defaultQuantity?: number
  /** Mininum possible quantity (default 0). If larger than defaultQuantity it will be used as a default */
  minQuantity?: number
  /** Maximum possible quantity (default 100) */
  maxQuantity?: number
  /** At what (at least) quantity will the content expand (default 1) */
  expandTreshold?: number
  /** Disables number input and applies styles */
  disabled?: boolean
  /** Whether the card should be highlighted */
  highlighted?: boolean
  /** Optional children rendered in the header of the card. */
  children?: ReactNode
  /** Whether to hide the counter input or not. */
  hideCounterInput?: boolean
}

/**
 * @component
 * Displays Expandable card with added numeric input in header which expands/closes automatically based on the numeric input's value.
 * Value is handled internally by default, but can be handled by custom function.
 * Externally held quantity can be passed down via prop and will have priority.
 * Min and max quantity can be changed as well as the threshold for expanding/hiding content.
 * 
 * @example
 * <IntegerCard expandableContent={contentNode} minQuantity={10} expandThreshold={15}>
 *   {headerNode} 
 * </IntegerCard>
 */
export const MUIIntegerCard: FC<Props> = ({
  inputPosition = 'left',
  expanded = 'auto',
  expandableContent,
  onQuantityChange,
  defaultQuantity = 0,
  minQuantity = 0,
  maxQuantity = 100,
  expandTreshold = 1,
  disabled = false,
  highlighted = false,
  hideCounterInput = false,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded !== 'auto' || defaultQuantity > 0)

  const handleQuantityChange = useCallback((quantity: number) => {
    if (onQuantityChange) onQuantityChange(isNaN(quantity) ? undefined : quantity)

    if (isExpanded && quantity === 0) setIsExpanded(false)
    if (quantity >= expandTreshold) setIsExpanded(true)

  }, [expandTreshold, isExpanded, onQuantityChange])

  return (
    <MUIExpandableCard
      isExpanded={isExpanded}
      isDisabled={disabled}
      isHighlighted={highlighted}
      expandableContent={expandableContent}
    >
      <Stack direction="row" gap={1} alignItems="center" sx={{ opacity: disabled ? 0.5 : 1 }}>

        {inputPosition === 'left' && !hideCounterInput &&
          <CounterInput
            value={defaultQuantity}
            minValue={minQuantity}
            maxValue={maxQuantity}
            onChange={handleQuantityChange}
          />
        }

        <Stack
          flex={1}
          marginLeft={inputPosition === 'left' ? 1 : 0}
          marginRight={inputPosition === 'right' ? 1 : 0}
        >
          {children}
        </Stack>

        {inputPosition === 'right' && !hideCounterInput &&
          <CounterInput
            value={defaultQuantity}
            minValue={minQuantity}
            maxValue={maxQuantity}
            onChange={handleQuantityChange}
          />
        }

      </Stack>
    </MUIExpandableCard>
  )
}
