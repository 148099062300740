import { FC, Fragment, ReactNode, useMemo } from 'react'
import { Power2, gsap } from 'gsap'
import { Transition, TransitionGroup } from 'react-transition-group'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'
import { Color } from 'constants/assets'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import ReactLoading from 'react-loading'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** The request from which is state taken */
  request: APIRequest<any>,
  /** Content displayed when request undefined */
  undefinedRequest?: ReactNode,
  /** Content displayed before start */
  beforeStart?: ReactNode,
  /** Content displayed while loading */
  loading?: ReactNode,
  /** Content displayed on error */
  error?: ReactNode,
  /** Whether the data slides in from bottom within the default timelines */
  slideIn?: boolean
  /** The tween of the placement in the timeline animation effect for enter transition (default=true). If false the items appends faster (used for long lists) */
  standardEnterEffectPosition?: boolean
  /** Timeline animation effect for enter transition */
  enterEffect?: { (node: HTMLElement, appears: boolean): void }
  /** Timeline animation effect for exit transition */
  exitEffect?: { (node: HTMLElement): void }
  /** Timeline for enter transition */
  timelineEnter?: gsap.core.Timeline
  /** Timeline for exit transition */
  timelineExit?: gsap.core.Timeline
  /** Timeout for enter transition on OK state, default=600 */
  timeoutEnterOk?: number
  /** Timeout for exit transition on OK state, default=150 */
  timeoutExitOk?: number
  /** Timeout for enter transition on Error state, default=600 */
  timeoutEnterError?: number
  /** Timeout for exit transition on Error state, default=150 */
  timeoutExitError?: number
  /** Timeout for enter transition on Loading state, default=600 */
  timeoutEnterLoading?: number
  /** Timeout for exit transition on Loading state, default=150 */
  timeoutExitLoading?: number
  /** Timeout for enter transition on BeforeStart state, default=600 */
  timeoutEnterBeforeStart?: number
  /** Timeout for exit transition on BeforeStart state, default=150 */
  timeoutExitBeforeStart?: number
  /** Timeout for enter transition on UndefinedRequest state, default=600 */
  timeoutEnterUndefinedRequest?: number
  /** Timeout for exit transition on UndefinedRequest state, default=150 */
  timeoutExitUndefinedRequest?: number
  children?: ReactNode
}

/**
 * @component Display aditional information in a styled orange box
 * @example
 * <FetchedContent
 *  request={useSelector((state: IRootStore) => state.APIData[ActionTypeAPIData.LIST_DEALS])}
 *  loading={<h3>Loading</h3>}
 * >
 *  <span>Results of api request here</span>
 * </FetchedContent>
 */
const FetchedContent: FC<Props> = ({
  children,
  slideIn = true,
  request,
  undefinedRequest = <Fragment></Fragment>,
  beforeStart = <Fragment></Fragment>,
  timelineEnter = gsap.timeline({ paused: true }),
  timelineExit = gsap.timeline({ paused: true }),
  standardEnterEffectPosition = true,
  enterEffect = (node: HTMLElement, appears: boolean) => {
    if (!node) return
    if (node.nodeType === node.TEXT_NODE) return
    timelineEnter.totalProgress(1).clear(true).totalProgress(0)
    if (node.classList && (node.classList.contains('loader') || node.classList.contains('loading') || node.classList.contains('spinner'))) {
      timelineEnter.fromTo(node, { autoAlpha: 0, ease: Power2.easeIn }, { autoAlpha: 1, ease: Power2.easeIn, duration: 0.15 })
    } else {
      timelineEnter.fromTo(
        node,
        { autoAlpha: 0, height: 0, y: slideIn ? 25 : 0, position: 'absolute', ease: Power2.easeIn },
        { autoAlpha: 1, height: 'auto', y: 0, position: 'static', ease: Power2.easeIn, duration: 0.3 },
        0.15
      )
      if (node.childNodes && node.childNodes.length > 0) {
        let omittedNodes = 0
        node.childNodes.forEach((child, key) => {
          if (child.nodeType === child.TEXT_NODE) {
            omittedNodes++
            return
          }
          timelineEnter.fromTo(child, { autoAlpha: 0, y: slideIn ? 25 : 0, ease: Power2.easeIn }, { autoAlpha: 1, y: 0, ease: Power2.easeIn, duration: 0.3 }, (standardEnterEffectPosition ? 0.45 + (key - omittedNodes) * 0.075 : 0.01 * key))
        })
      }
    }
    timelineEnter.play()
  },
  exitEffect = (node: HTMLElement) => {
    if (!node) return
    if (node.nodeType === node.TEXT_NODE) return
    timelineExit.totalProgress(1).clear(true).totalProgress(0)
    timelineExit.to(node, { autoAlpha: 0, zIndex: 0, height: 0, ease: Power2.easeOut, duration: 0.15 })
    timelineExit.play()
  },
  timeoutEnterOk = 600,
  timeoutExitOk = 150,
  timeoutEnterError = 600,
  timeoutExitError = 150,
  timeoutEnterLoading = 600,
  timeoutExitLoading = 150,
  timeoutEnterBeforeStart = 600,
  timeoutExitBeforeStart = 150,
  timeoutEnterUndefinedRequest = 600,
  timeoutExitUndefinedRequest = 150,
  loading = (
    <Fragment>
      <ReactLoading color={Color.GRAY_TEXT} type="cylon" className="spinner" />
    </Fragment>
  ),
  error = (
    <Fragment>
      <h3 className="error-heading"><ErrorMessage error_type={'error'} /></h3>
      <p><ErrorMessage error_type={request.error_type} /></p>
    </Fragment>
  ),
}) => {
  const { t } = useTranslation(['responses'])
  const defaultChildren = useMemo(() => <span>{t('success')}</span>, [t])
  return (
    <TransitionGroup component={null}>
      {request.state === APIRequestState.OK &&
        <Transition
          appear={true}
          in={request.state === APIRequestState.OK}
          onEnter={(node, appears) => enterEffect(node, appears)}
          onExit={(node) => exitEffect(node)}
          timeout={{ enter: timeoutEnterOk, exit: timeoutExitOk }}
        >
          {children || defaultChildren}
        </Transition>
      }
      {request.state === APIRequestState.ERROR &&
        <Transition
          appear={true}
          in={request.state === APIRequestState.ERROR}
          onEnter={(node, appears) => enterEffect(node, appears)}
          onExit={(node) => exitEffect(node)}
          timeout={{ enter: timeoutEnterError, exit: timeoutExitError }}
        >
          {error}
        </Transition>
      }
      {request.state === APIRequestState.RUNNING &&
        <Transition
          appear={true}
          in={request.state === APIRequestState.RUNNING}
          onEnter={(node, appears) => enterEffect(node, appears)}
          onExit={(node) => exitEffect(node)}
          timeout={{ enter: timeoutEnterLoading, exit: timeoutExitLoading }}
        >
          {loading}
        </Transition>
      }
      {request.state === APIRequestState.BEFORE_START &&
        <Transition
          appear={true}
          in={request.state === APIRequestState.BEFORE_START}
          onEnter={(node, appears) => enterEffect(node, appears)}
          onExit={(node) => exitEffect(node)}
          timeout={{ enter: timeoutEnterBeforeStart, exit: timeoutExitBeforeStart }}
        >
          {beforeStart}
        </Transition>
      }
      {(!request || !request.state) &&
        <Transition
          appear={true}
          in={(!request || !request.state)}
          onEnter={(node, appears) => enterEffect(node, appears)}
          onExit={(node) => exitEffect(node)}
          timeout={{ enter: timeoutEnterUndefinedRequest, exit: timeoutExitUndefinedRequest }}
        >
          {undefinedRequest}
        </Transition>
      }
    </TransitionGroup>
  )
}

export default FetchedContent
