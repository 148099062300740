import { AuthoritiesDocumentsProvider, CartContextProvider, ProductAndInstructionListsContextProvider, PurchaseFlowRealEstatePropertyContextProvider, PurchaseFlowRenovationProductContextProvider, TargetOrderUserProvider, UploadedPurchaseFlowVisualsContextProvider } from './contexts'
import { PurchaseFlowPaymentStatusContextProvider, PurchaseFlowPlaceOrderProvider } from '../steps/Validation/_main/contexts'

import { AdditionalInformationContextProvider } from '../common/AdditionalInformation'
import { BookCreativeContextProvider } from '../common/InstructionListing/_main/contexts'
import { ConsumptionCertificateStepStackProvider } from '../steps/ConsumptionCertificateStep/_main/ConsumptionCertificateStepStackProvider'
import { DynamicFlowProvider } from 'components/common/DynamicFlow'
import { EnergyCertificateStepStackProvider } from '../steps/EnergyCertificateStep/_main/EnergyCertificateStepStackProvider'
import { FloorPlanCertificationContextProvider } from '../steps/FloorPlanCertification/_main'
import { FloorPlanConfigProvider } from '../steps/FloorPlanConfig'
import { FloorPlanLogoProvider } from '../steps/FloorPlanConfig/_main/FloorPlanLogo.context'
import { MeasurementsContextProvider } from '../steps/MeasurementStep/MeasurementStep.context'
import { PowerOfAttorneyStepContextProvider } from '../steps/PowerOfAttorneyStep'
import { PropertyInformationStepContextProvider } from '../steps/PropertyInformationStep/PropertyInformationStep.context'
import { PurchaseFlowConfigProvider } from './contexts/PurchaseFlowConfig.context'
import { PurchaseFlowController } from './PurchaseFlow.controller'
import { PurchaseFlowOrderMetaProvider } from './contexts/PurchaseFlowOrderMeta.context'
import { PurchaseFlowProductsProvider } from './contexts/PurchaseFlowProducts.context'
import { PurchaseFlowStagingStack } from './contexts/PurchaseFlowStagingStack'
import { PurchaseFlowUtilityProvider } from './contexts/PurchaseFlowUtilities.context'
import React from 'react'
import { SavedStripePaymentMethodsContextProvider } from 'components/contexts/SavedStripePaymentMethodsContext'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { redesignMUITheme } from 'constants/styling/redesignTheme'
import { useAuth0 } from 'utils/auth'

/**
 * Page representing the overall purchase flow content.
 *
 * @example <PurchaseFlow />
*/
export const PurchaseFlow: React.FC = () => {
  const { roles } = useAuth0()

  return (
    <ThemeProvider theme={redesignMUITheme}>
      <DynamicFlowProvider>

        <TargetOrderUserProvider>
          <PurchaseFlowRealEstatePropertyContextProvider>
            <PurchaseFlowConfigProvider>
              <PurchaseFlowProductsProvider>

                <BookCreativeContextProvider>
                  <PurchaseFlowOrderMetaProvider>
                    <AuthoritiesDocumentsProvider>

                      <CartContextProvider>

                        <ProductAndInstructionListsContextProvider>

                          <UploadedPurchaseFlowVisualsContextProvider>
                            <PurchaseFlowRenovationProductContextProvider>
                              <PurchaseFlowStagingStack>

                                <SavedStripePaymentMethodsContextProvider autoFetch={!roles.isAdmin}>
                                  <PurchaseFlowPaymentStatusContextProvider>

                                    <FloorPlanConfigProvider>
                                      <FloorPlanLogoProvider>
                                        <FloorPlanCertificationContextProvider>

                                          <PropertyInformationStepContextProvider>
                                            <PowerOfAttorneyStepContextProvider>
                                              <MeasurementsContextProvider>
                                                <AdditionalInformationContextProvider>
                                                  <ConsumptionCertificateStepStackProvider>
                                                    <EnergyCertificateStepStackProvider>

                                                      <PurchaseFlowPlaceOrderProvider>

                                                        <PurchaseFlowUtilityProvider>

                                                          <PurchaseFlowController />

                                                        </PurchaseFlowUtilityProvider>

                                                      </PurchaseFlowPlaceOrderProvider>
                                                    </EnergyCertificateStepStackProvider>
                                                  </ConsumptionCertificateStepStackProvider>
                                                </AdditionalInformationContextProvider>
                                              </MeasurementsContextProvider>
                                            </PowerOfAttorneyStepContextProvider>
                                          </PropertyInformationStepContextProvider>

                                        </FloorPlanCertificationContextProvider>
                                      </FloorPlanLogoProvider>
                                    </FloorPlanConfigProvider>

                                  </PurchaseFlowPaymentStatusContextProvider>
                                </SavedStripePaymentMethodsContextProvider>

                              </PurchaseFlowStagingStack>
                            </PurchaseFlowRenovationProductContextProvider>
                          </UploadedPurchaseFlowVisualsContextProvider>

                        </ProductAndInstructionListsContextProvider>

                      </CartContextProvider>

                    </AuthoritiesDocumentsProvider>
                  </PurchaseFlowOrderMetaProvider>
                </BookCreativeContextProvider>

              </PurchaseFlowProductsProvider>
            </PurchaseFlowConfigProvider>
          </PurchaseFlowRealEstatePropertyContextProvider>
        </TargetOrderUserProvider>

      </DynamicFlowProvider>
    </ThemeProvider>
  )
}
