import './CreativeOnboarding.sass'

import { ColorClass, IconType } from 'constants/assets'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { QueryStatus } from 'components/common/QueryStatus'
import { getUserTimezone, timezoneNames } from 'utils/time'
import { useAdminOnboardCreative, useAdminResendCreativeInvitation } from 'dataQueries'

import BlockInfo from 'components/common/BlockInfo/BlockInfo'
import Button from 'components/common/Button/Button'
import Dropdown from 'components/common/Dropdown/Dropdown'
import Icon from 'components/common/Icon/Icon'
import { Language } from 'translations/Language'
import { PageTransition } from 'utils/animations'
import { Path } from 'constants/router'
import TriangleIcon from 'components/common/TriangleIcon/TriangleIcon'
import { useAuth0 } from 'utils/auth'
import { useNavigate } from 'react-router-dom'
import { useTimezone } from 'components/contexts/timezone.context'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

enum EntryType {
  ONBOARD,
  RESEND,
}

interface CreatvieData {
  email: string
  language: string
  timezone: string
  timestamp: number
  type: EntryType
}

interface CreativeState {
  data: CreatvieData
  query: any
}

interface CreativesState {
  [email: string]: CreativeState
}

const CreativeOnboarding: React.FC = () => {
  const { roles } = useAuth0()
  const navigate = useNavigate()
  const { t } = useTranslation(['language'])
  const [email, setEmail] = useState('')
  const [creativeLanguage, setCreativeLanguage] = useState<Language>(Language.EN)
  const [creativeTimezone, setCreativeTimezone] = useState<string>(getUserTimezone)
  const [justSent, setJustSent] = useState(false)
  const [onboardStore, setOnboardStore] = useState<CreativesState>({})
  const [resendStore, setResendStore] = useState<CreativesState>({})

  const { baseUserData } = useUserData()
  const { userTimezone } = useTimezone()

  const adminOnboardCreative = useAdminOnboardCreative()
  const adminResendInvitationCreative = useAdminResendCreativeInvitation()

  const sortedOnboardingEntries = useMemo(() => [
    ...Object.entries(onboardStore),
    ...Object.entries(resendStore)
  ].sort(([aKey, aObject], [bKey, bObject]) => {
    if (!aObject?.data?.timestamp || !bObject?.data?.timestamp) return 0
    if (aObject.data.timestamp < bObject.data.timestamp) return 1
    else if (aObject.data.timestamp > bObject.data.timestamp) return -1
    else return 0
  }), [onboardStore, resendStore])
  const onboardedEmails = useMemo(() => new Set(Object.values(onboardStore).map(item => item?.data?.email || '').filter(item => !!item)), [onboardStore])
  const resentEmails = useMemo(() => new Set(Object.values(resendStore).map(item => item?.data?.email || '').filter(item => !!item)), [resendStore])

  const isEmptyEmail = useMemo(() => email === '', [email])
  const isValidEmail = useMemo(() => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return emailRegex.test(email)
  }, [email])
  const isAlreadyOnboarded = useMemo(() => onboardedEmails.has(email), [onboardedEmails, email])
  const isAlreadyOnboardedPreviously = useMemo(() => isAlreadyOnboarded && !justSent, [isAlreadyOnboarded, justSent])
  const isAlreadyResent = useMemo(() => resentEmails.has(email), [resentEmails, email])
  const isAlreadyResentPreviously = useMemo(() => isAlreadyResent && !justSent, [isAlreadyResent, justSent])
  const isOnboardDisabled = useMemo(() => isEmptyEmail || !isValidEmail || isAlreadyOnboarded, [isEmptyEmail, isValidEmail, isAlreadyOnboarded])
  const isOnboardError = useMemo(() => isEmptyEmail || !isValidEmail || isAlreadyOnboardedPreviously, [isEmptyEmail, isValidEmail, isAlreadyOnboardedPreviously])
  const isResendDisabled = useMemo(() => isEmptyEmail || !isValidEmail || isAlreadyResent, [isEmptyEmail, isValidEmail, isAlreadyResent])
  const isResendError = useMemo(() => isEmptyEmail || !isValidEmail || isAlreadyResentPreviously, [isEmptyEmail, isValidEmail, isAlreadyResentPreviously])

  const creativeInfoObject = useCallback((type: EntryType) => {
    return {
      email,
      language: creativeLanguage,
      timezone: creativeTimezone,
      timestamp: Date.now(),
      type
    }
  }, [creativeLanguage, creativeTimezone, email])

  const handleResendInvitation = useCallback(() => {
    if (isResendDisabled) return

    adminResendInvitationCreative.mutate(
      { email, language: creativeLanguage, timezone: creativeTimezone },
      {
        onSettled: (data, error) => {
          setResendStore(prev => ({
            ...prev,
            [email]: {
              data: creativeInfoObject(EntryType.RESEND),
              query: {
                data,
                error,
                status: error ? 'error' : 'success'
              },
            }
          }))
        }
      }
    )
    setJustSent(true)
  }, [adminResendInvitationCreative, creativeInfoObject, creativeLanguage, creativeTimezone, email, isResendDisabled])

  const handleOnboardCreative = useCallback(() => {
    if (isOnboardDisabled) return

    adminOnboardCreative.mutate(
      { email, language: creativeLanguage, timezone: creativeTimezone },
      {
        onSettled: (data, error) => {
          setOnboardStore(prev => ({
            ...prev,
            [email]: {
              data: creativeInfoObject(EntryType.ONBOARD),
              query: {
                data,
                error,
                status: error ? 'error' : 'success'
              },
            }
          }))
        }
      }
    )
    setJustSent(true)
  }, [adminOnboardCreative, creativeInfoObject, creativeLanguage, creativeTimezone, email, isOnboardDisabled])

  /** React to userMe request and set default timezone */
  useEffect(() => {
    if (baseUserData) setCreativeTimezone(userTimezone)
  }, [baseUserData, userTimezone])

  if (!roles.isAdmin) {
    navigate(Path.INDEX)
    return (
      <PageTransition>
        <div className="page">
          <div className="page-content">
            <h1>ADMIN ONLY AREA</h1>
          </div>
        </div>
      </PageTransition>
    )
  }

  return (
    <PageTransition>
      <div className="page creative-onboarding">
        <div className="page-content">
          <h1>
            <span className="group">
              <TriangleIcon icon={IconType.CAMERA} type={ColorClass.PRIMARY_BLUE} />
              Onboard Creative
            </span>
          </h1>
          <BlockInfo color="blue" className="full-width">
            <h4>Instructions</h4>
            <ol className="instructions-list">
              <li className="instruction-item">On Pipedrive, make sure the Creative email address is <span className="bold">unique</span> (no duplicate persons are allowed).</li>
              <li className="instruction-item">On Pipedrive, make sure the person has the right <span className="bold">address</span> if they don't have organisation. If they do, make sure the organisation has the right address.</li>
            </ol>
          </BlockInfo>
          <div className="inputs">
            <div className="input-row">
              <div className="input-group language-group">
                <label htmlFor="creative-language">Creative language</label>
                <Dropdown className="language-dropdown" attachment="right-attached" button={(isOpen, action) => (
                  <Button type="secondary" onClick={action} textAndIcon={true}>
                    <span>{(t(`language:${(creativeLanguage || '').toUpperCase()}`) || '').toString()}</span>
                    <Icon icon={IconType.CARET_DOWN} className={`caret ${isOpen ? 'up' : 'down'}`} />
                  </Button>
                )}>
                  <Fragment>
                    {Object.values(Language).map(lang => {
                      const selected = lang === creativeLanguage
                      return (
                        <button
                          key={lang}
                          className={`language-button ${selected ? 'selected' : ''}`}
                          onClick={() => setCreativeLanguage(lang)}
                        >
                          {t(`language:${lang.toUpperCase()}`, lang)}
                        </button>
                      )
                    })}
                  </Fragment>
                </Dropdown>
              </div>
              <div className="input-group timezone-group">
                <label htmlFor="creative-timezone">Creative timezone</label>
                <select
                  name="creative-timezone"
                  id="creative-timezone"
                  value={creativeTimezone}
                  onChange={e => setCreativeTimezone(e.target.value)}
                >
                  {timezoneNames.map(tzn => (
                    <option value={tzn} key={tzn}>{tzn}</option>
                  ))}
                </select>
                {!creativeTimezone &&
                  <span className="error-message">Creative timezone_required</span>
                }
              </div>
            </div>
            <div className="input-row">
              <div className="input-group email-group">
                <label htmlFor="creative-email">Creative email address</label>
                <input
                  className={`email-input withicon right ${IconType.ENVELOPE} ${isOnboardError || isResendError ? 'error-input' : ''}`.trim()}
                  type="email"
                  name="creative-email"
                  id="creative-email"
                  placeholder="Creative email address"
                  value={email}
                  onChange={e => {
                    setJustSent(false)
                    setEmail(e.target.value)
                  }}
                />
                {isEmptyEmail &&
                  <span className="error-message">Creative email address cannot be empty</span>
                }
                {!isValidEmail && !isEmptyEmail &&
                  <span className="error-message">Creative email address is not valid</span>
                }
                {isAlreadyOnboardedPreviously &&
                  <span className="error-message">Creative email address already submitted for onboarding</span>
                }
                {isAlreadyResentPreviously &&
                  <span className="error-message">Creative email address already submitted for invitation resend</span>
                }
              </div>
              <div className="input-group">
                <Button
                  className="onboard-button"
                  type="primary"
                  disabled={isOnboardDisabled}
                  onClick={handleOnboardCreative}
                >
                  Onboard Creative
                </Button>
              </div>
              <div className="input-group">
                <Button
                  className="resend-button"
                  type="secondary"
                  disabled={isResendDisabled}
                  onClick={handleResendInvitation}
                >
                  Resend invitation
                </Button>
              </div>
            </div>
          </div>
          <h2>List of currently submitted Creatives</h2>
          <div className="onboarding-list">
            {sortedOnboardingEntries.map(([emailKey, item]) => (
              <div key={emailKey} className="item">
                <h3 className="email">{emailKey}</h3>
                <div className="status">
                  {item ?
                    <QueryStatus
                      query={item.query}
                      successMessage={item.data.type === EntryType.RESEND
                        ? 'Resent succesfully!'
                        : 'Onboarded successfully!'}
                    />
                    :
                    <BlockInfo color="blue">
                      <h4>Unknown</h4>
                    </BlockInfo>
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </PageTransition>
  )
}

export default CreativeOnboarding
