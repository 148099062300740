import * as Sentry from '@sentry/react'

import { Routes, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router'

import { ExtraErrorData } from '@sentry/integrations'
import { SentryEnhancerOptions } from '@sentry/react/types/redux'
import { ENVIRONMENT } from 'constants/application'
import { cloneDeep } from 'lodash'
import { APIRequest } from 'models/API'
import { Auth0User } from 'models/auth'
import { RootStore } from 'models/redux'
import React from 'react'

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
const RELEASE_VERSION = process.env.REACT_APP_SHORT_SHA

/** Sentry config object */
export const sentryConfig: Sentry.BrowserOptions = {
  debug: false,
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  normalizeDepth: 10,
  maxBreadcrumbs: 10,
  release: RELEASE_VERSION,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new ExtraErrorData({
      depth: 10
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.01,
  ignoreErrors: [
    /Object Not Found Matching Id:\d+/gm,
  ],
}

/** Enables sentry user tracking */
export const trackSentryUser = (user: Auth0User) => {
  if (user) Sentry.setUser({
    id: user.sub,
    email: user.email,
  })
}

/** Disabled sentry user tracking */
export const untrackSentryUser = () => {
  Sentry.configureScope(scope => scope.setUser(null))
}

/** Initializes Sentry (but only if REACT_APP_SENTRY_DSN env variable present) */
export const initSentry = () => {
  if (SENTRY_DSN) {
    Sentry.init(sentryConfig)
    return true
  }
  return false
}

const transformRootStore = (state: RootStore) => {
  const newState = cloneDeep(state)

  function deleteResponses(stateProp: any) {
    if (!stateProp) return
    if (stateProp instanceof APIRequest) return delete stateProp.response
    else if (typeof stateProp === 'object') for (const key of Object.keys(stateProp)) {
      deleteResponses(stateProp[key])
    }
  }

  deleteResponses(newState)
  return newState
}

/** Redux enhancer options */
export const reduxEnhancerOptions: Partial<SentryEnhancerOptions> | undefined = {
  stateTransformer: <IRootStore>(state: IRootStore | undefined) => {
    if (!state) return state
    else return transformRootStore(state as any)
  }
}

/** React-router routes enhanced with sentry tracking */
export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)
