import * as Sentry from '@sentry/react'

import { Endpoint, RoleMimetype, URL } from 'constants/API'
import { auth0ClientModule, clearAuth0LocalStorage, getRoles } from 'utils/auth'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import { APIErrorDTO } from 'models/API'
import { Auth0Roles } from 'models/auth'

export interface APIRequestConfig {
  /** Set to true if error should be ignored by Sentry */
  ignoreError?: boolean
  /** A method returning boolean value, if true returned error will be ignored by Sentry */
  ignoreErrorFactory?: (error: AxiosError) => boolean
  /** Endpoint string logged to Sentry */
  endpoint?: string
  /** If set to true, base API URL will be ignored */
  ignoreBaseUrl?: boolean
  /** If set to true, authorization headers will be ignored */
  ignoreAuthorizationHeaders?: boolean
}

/** API negotiator */
export class API {
  /** API baseURL to which all enpoints are appended */
  baseUrl: string

  /**
  * Creates an instance of API.
  * @param {string} baseURL
  */
  constructor(baseURL: string) {
    this.baseUrl = URL
  }

  /** Calls auth0 getTokenSilently() asynchronously */
  private async getToken() {
    try {
      return await auth0ClientModule.getTokenSilently()
    } catch (e) {
      clearAuth0LocalStorage()
      return ''
    }
  }


  /**
   * Calls internally this.getToken() asynchronously and sets up the authorization headers object
   * @returns object: {Authorization: `Bearer ${token}`}
   */
  private async getAuthorizationHeaders() {
    return {
      Authorization: `Bearer ${await this.getToken()}`
    }
  }

  /** Returns the role mimetype based on auth0 roles */
  public decideRoleMimeType(roles: Auth0Roles) {
    if (roles.isAdmin) return RoleMimetype.ADMIN
    if (roles.isCreative) return RoleMimetype.CREATIVE
    if (roles.isClient) return RoleMimetype.CLIENT

    return RoleMimetype.DEFAULT
  }

  /** Calls auth0 getUser() asynchronously and returns the role mimetype based on auth0 roles */
  public async getRoleMimeType() {
    const roles = await getRoles()
    return this.decideRoleMimeType(roles)
  }

  /**
   * Calls internally this.getRoleMimeType() asynchronously and sets up the Accept headers object with role mimetype
   * @returns object: {Accept: `application/{role}`}
   */
  private async geContentTypeRoleHeaders() {
    return {
      Accept: `${await this.getRoleMimeType()}`
    }
  }

  /** Combines argument axios config with authorization headers */
  private async createConfigWithAuthorization(config?: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    const authorizationHeaders = await this.getAuthorizationHeaders()
    const contentTypeRoleHeaders = await this.geContentTypeRoleHeaders()
    return {
      ...config,
      headers: {
        ...contentTypeRoleHeaders,
        ...config?.headers,
        ...authorizationHeaders,
      },
    }
  }

  /** Logs the axios error to Sentry */
  public logError<T>(error: AxiosError<T>, internalConfig?: APIRequestConfig) {
    if (internalConfig?.ignoreError) return
    if (internalConfig?.ignoreErrorFactory && internalConfig?.ignoreErrorFactory(error)) return

    let message = ''
    if (error.config?.method) message += `${error.config.method.toUpperCase()}`
    if (internalConfig?.endpoint) message += ` - ${internalConfig.endpoint}`
    else if (error.config?.url) message += ` - ${error.config.url}`
    if (error.response?.status) message += ` - ${error.response.status}`
    if (error.response?.data) {
      const data: APIErrorDTO = error.response.data
      if (data.message) message += ` - ${data.message}`
    }
    Sentry.captureMessage(message, scope => {
      scope.clearBreadcrumbs()
      scope.setFingerprint([message])
      scope.setExtra('params', error.config?.params)
      scope.setExtra('AxiosError', error)
      scope.setExtra('AxiosRequest', error.request)
      scope.setExtra('AxiosResponse', error.response)
      return scope
    })
  }

  /** Processes the request and logs erroneous responses */
  private async processRequest<T>(request: Promise<AxiosResponse<T>>, internalConfig?: APIRequestConfig) {
    try {
      return await request
    } catch (e) {
      const error = e as AxiosError<T>
      if (error.isAxiosError) this.logError(error, internalConfig)
      throw e
    }
  }

  /** Creates an API GET endpoint call factory */
  public async createGetFactory<T>(endpoint: Endpoint | string, config?: AxiosRequestConfig, internalConfig?: APIRequestConfig) {
    return async () => axios.get<T>(`${internalConfig?.ignoreBaseUrl ? '' : this.baseUrl}${endpoint}`, internalConfig?.ignoreAuthorizationHeaders ? {} : await this.createConfigWithAuthorization(config))
  }

  /** Calls an API endpoint (appending it to the baseURL) with authorization headers */
  public async get<T>(endpoint: Endpoint | string, config?: AxiosRequestConfig, internalConfig?: APIRequestConfig) {
    return this.processRequest(
      (await this.createGetFactory<T>(endpoint, config, internalConfig))(),
      internalConfig
    )
  }

  /** Creates an API POST endpoint call factory */
  public async createPostFactory<T>(endpoint: Endpoint | string, body: any, config?: AxiosRequestConfig, internalConfig?: APIRequestConfig) {
    return async () => axios.post<T>(`${internalConfig?.ignoreBaseUrl ? '' : this.baseUrl}${endpoint}`, body, internalConfig?.ignoreAuthorizationHeaders ? {} : await this.createConfigWithAuthorization(config))
  }

  /** Calls an API endpoint (appending it to the baseURL) with authorization headers */
  public async post<T>(endpoint: Endpoint | string, body: any, config?: AxiosRequestConfig, internalConfig?: APIRequestConfig) {
    return this.processRequest(
      (await this.createPostFactory<T>(endpoint, body, config, internalConfig))(),
      internalConfig
    )
  }

  /** Creates an API PUT endpoint call factory */
  public async createPutFactory<T>(endpoint: Endpoint | string, body: any, config?: AxiosRequestConfig, internalConfig?: APIRequestConfig) {
    return async () => axios.put<T>(`${internalConfig?.ignoreBaseUrl ? '' : this.baseUrl}${endpoint}`, body, internalConfig?.ignoreAuthorizationHeaders ? {} : await this.createConfigWithAuthorization(config))
  }

  /** Calls an API endpoint (appending it to the baseURL) with authorization headers */
  public async put<T>(endpoint: Endpoint | string, body: any, config?: AxiosRequestConfig, internalConfig?: APIRequestConfig) {
    return this.processRequest(
      (await this.createPutFactory<T>(endpoint, body, config, internalConfig))(),
      internalConfig
    )
  }

  /** Creates an API DELETE endpoint call factory */
  public async createDeleteFactory<T>(endpoint: Endpoint | string, config?: AxiosRequestConfig, internalConfig?: APIRequestConfig) {
    return async () => axios.delete<T>(`${internalConfig?.ignoreBaseUrl ? '' : this.baseUrl}${endpoint}`, internalConfig?.ignoreAuthorizationHeaders ? {} : await this.createConfigWithAuthorization(config))
  }

  /** Calls an API endpoint (appending it to the baseURL) with authorization headers */
  public async delete<T>(endpoint: Endpoint | string, config?: AxiosRequestConfig, internalConfig?: APIRequestConfig) {
    return this.processRequest(
      (await this.createDeleteFactory<T>(endpoint, config, internalConfig))(),
      internalConfig
    )
  }
}

const instance = new API(URL)

export default instance
