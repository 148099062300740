import { PurchaseFlowStepKey, usePurchaseFlowUtilities } from '../../_main/contexts'

import { DynamicFlowStep } from 'components/common/DynamicFlow'
import { FC } from 'react'
import { MeasurementStepController } from './MeasurementStep.controller'

/**
 * @component
 * Page in PurchaseFlow step for measurement information.
 * 
 * @example 
 * <MeasurementStep />
 */
export const MeasurementStep: FC = () => {
  const { isMeasurementRequirementsStepValid } = usePurchaseFlowUtilities()

  return (
    <DynamicFlowStep
      stepKey={PurchaseFlowStepKey.MEASUREMENT_REQUIREMENTS}
      isValid={isMeasurementRequirementsStepValid}
    >
      <MeasurementStepController />
    </DynamicFlowStep>
  )
}
