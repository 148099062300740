import { APIRequestErrorType, APIRequestState } from 'constants/API'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react'

import { APIRequest } from 'models/API'
import { ActionTypeAPIData } from 'constants/redux'
import { ArchiveVisualsTag } from 'redux/Individual/Visual/ArchiveVisuals'
import { RootStore } from 'models/redux'
import constate from 'constate'
import { resetDownloadArchiveVisuals } from 'redux/Individual/Visual/DownloadArchive'
import { Nullable } from 'models/helpers'

export const [VisualsEditingDownloadProvider, useVisualsEditingDownload] = constate(({
  assignmentId,
}: {
  assignmentId: Nullable<string>
}) => {
  const dispatch = useDispatch()

  const prepareVisualsDownloadSlice = useSelector((state: RootStore) => {
    if (!assignmentId) return null
    return state.APIData[ActionTypeAPIData.ARCHIVE_EDITING_VISUALS]?.[assignmentId]?.[ArchiveVisualsTag.CLIENT_ARCHIVE]
  })
  const prepareVisualsDownloadJobId = useMemo(() => (prepareVisualsDownloadSlice && prepareVisualsDownloadSlice.data?.id) || '', [prepareVisualsDownloadSlice])
  const downloadVisualsSlice = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.DOWNLOAD_ARCHIVE]?.[prepareVisualsDownloadJobId])
  const syntheticDownloadRequest = useMemo(() => {
    let request: APIRequest<null> = APIRequest.factoryRunning(null)

    if (downloadVisualsSlice?.state === APIRequestState.OK) {
      if (downloadVisualsSlice.data?.url) {
        request = APIRequest.factoryOK(null)
      } else {
        request = APIRequest.factoryError(APIRequestErrorType.UNKNOWN_ERROR, {
          data: {
            message: 'No url in returned data',
            status: 'BAD RESPONSE',
            timestamp: new Date().toISOString()
          }
        })
      }
    } else if (downloadVisualsSlice?.state === APIRequestState.RUNNING) {
      request = APIRequest.factoryRunning(null)
    } else if (prepareVisualsDownloadSlice?.state === APIRequestState.ERROR) {
      request = APIRequest.factoryError(prepareVisualsDownloadSlice.error_type, prepareVisualsDownloadSlice.error)
    } else if (downloadVisualsSlice?.state === APIRequestState.ERROR) {
      request = APIRequest.factoryError(downloadVisualsSlice.error_type, downloadVisualsSlice.error)
    }
    return request
  }, [prepareVisualsDownloadSlice, downloadVisualsSlice])

  const hasDownloadFailed = useMemo(() => syntheticDownloadRequest.state === APIRequestState.ERROR, [syntheticDownloadRequest])
  const isDownloadReady = useMemo(() => syntheticDownloadRequest.state === APIRequestState.OK, [syntheticDownloadRequest])
  const isDownloadRunning = useMemo(() => syntheticDownloadRequest.state === APIRequestState.RUNNING, [syntheticDownloadRequest])
  const downloadUrl = useMemo(() => downloadVisualsSlice?.data?.url, [downloadVisualsSlice?.data?.url])

  useEffect(() => {
    return () => {
      if (!assignmentId) return
      dispatch(resetDownloadArchiveVisuals(prepareVisualsDownloadJobId, assignmentId))
    }
  }, [assignmentId, dispatch, prepareVisualsDownloadJobId])

  return {
    assignmentId,
    downloadUrl,
    hasDownloadFailed,
    isDownloadReady,
    isDownloadRunning
  }
})
