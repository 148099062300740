import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { BaseInstructionCard, BaseInstructionCardPassDownProps } from '../../InstructionListing/instructionCards/BaseInstructionCard'
import { FC, useCallback, useMemo } from 'react'

import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { PreferredPaymentMethodSwitch } from 'components/common/PreferredPaymentMethodSwitch'
import { ProductKind } from 'constants/product'
import { SubscriptionInstructionUpsell } from '../../SubscriptionInstructionUpsell'
import { productKindIsPreferredPaymentMethod } from 'utils/typeguards'
import { useAuth0 } from 'utils/auth'
import { usePurchaseFlowOrderMeta } from 'components/pages/PurchaseFlow/_main/contexts/PurchaseFlowOrderMeta.context'
import { useTargetOrderUser } from 'components/pages/PurchaseFlow/_main/contexts'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

interface Props extends BaseInstructionCardPassDownProps {
  showSaveOption: boolean
}

/**
 * Instruction card for BILLING_PRIMARY type instructions (Billing methods).
 * Shows no price.
 * If enabled, shows if billing method is default or means of saving it as default.
 * 
 * @example
 * <BillingMethodInstructionCard
 *  showSaveOption={true}
 *  isCardDisabled={false}
 *  isSelectionDisabled={false}
 *  instructionOption={instructionOptionDTO}
 *  instructionType={InstructionType.BILLING_PRIMARY}
 *  onChange={(changed) => console.log}
 * />
 */
export const BillingMethodInstructionCard: FC<Props> = ({
  instructionOption,
  instructionType,
  isCardDisabled,
  isSelectionDisabled,
  showSaveOption = false,
}) => {
  const { t } = useTranslation(['card_new_user_invoice_paymethod', 'preferred_payment_method'])
  const { roles } = useAuth0()
  const { targetUser } = useTargetOrderUser()
  const { clientData, hasUserActiveSubscription } = useUserData()
  const { selectedPaymentMethod, setSelectedPaymentMethod } = usePurchaseFlowOrderMeta()

  // Disallow INVOICE_BY_EMAIL for New Unsubscribed Clients
  // Admins are allowed to order for any client via INVOICE - preferred direct method is guarded in Client step
  const isMethodAllowed = useMemo(
    () => {
      if (instructionOption.kind !== ProductKind.INVOICE_BY_EMAIL) return true
      if (roles.isAdmin) return true
      return targetUser.invoiceAllowed
    },
    [instructionOption.kind, roles.isAdmin, targetUser.invoiceAllowed]
  )

  const onCheckPaymentMethod = useCallback(() => {
    logAnalyticsEvent(AnalyticsEvent.PURCHASE_FLOW_PAYMENT_METHOD_SELECTED, {
      PaymentMethodSelected: instructionOption.kind,
      IsSubscribed: hasUserActiveSubscription
    })

    setSelectedPaymentMethod(instructionOption.kind)
  }, [hasUserActiveSubscription, instructionOption.kind, setSelectedPaymentMethod])

  return (
    <BaseInstructionCard
      instructionType={instructionType}
      isSelectionDisabled={isSelectionDisabled || !isMethodAllowed}
      instructionOption={instructionOption}
      isCardDisabled={isCardDisabled}
      onCheck={onCheckPaymentMethod}
      isChecked={selectedPaymentMethod === instructionOption.kind}
      rightHeaderContent={
        showSaveOption && productKindIsPreferredPaymentMethod(instructionOption.kind)
          && isMethodAllowed
          ? <PreferredPaymentMethodSwitch paymentMethod={instructionOption.kind} />
          : undefined
      }
      additionalBottomBoxContent={!isMethodAllowed &&
        <SubscriptionInstructionUpsell
          title={t('title')}
          text={t('description')}
          onClick={() =>
            logAnalyticsEvent('unblock_invoicing', {
              userEmail: clientData?.email,
              organizationId: clientData?.organizationId,
            })
          }
        />
      }
      badges={
        isMethodAllowed
          ? []
          : [{ color: 'coral', text: t('preferred_payment_method:badge_only_subscribed'), materialIcon: <LockOutlinedIcon /> }]
      }
    >
    </BaseInstructionCard>
  )
}
