import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { ActionUserMeta, UserMetaStore } from '.'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'

/** Redux data reducer for user meta */
export const reduceUserMeta = (state: UserMetaStore, action: ActionUserMeta) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.USER_META) return state

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
      return new APIRequest(APIRequestState.BEFORE_START)
    case ActionTypeAPIEvent.FETCH:
      return new APIRequest(APIRequestState.RUNNING)
    case ActionTypeAPIEvent.RECEIVED:
      return action.payload.request
    default:
      return state
  }
}