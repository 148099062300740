import { FC, ReactNode, useMemo } from 'react'
import { UseFormRegisterReturn, UseFormReturn } from 'react-hook-form'

import { Stack } from '@mui/material'
import classnames from 'classnames'
import { getDefaultErrorMessage } from 'utils/forms/getDefaultErrorMessage'
import styles from './FormFieldWrap.module.sass'

interface Props {
  children: ReactNode
  label: string
  form: UseFormReturn<any>
  field: UseFormRegisterReturn
  fieldNote?: string
  className?: string
}

/**
 * Wrapper for inputs used with react-hook-forms providing standardized labels, errors and field info messages.
 * @example
 * <FormFieldWrap label="Full name" form={formUtils} field={rhf.fields.fullName}>
 *   <input type="text" {...rhf.fields.fullName} />
 * </FormFieldWrap>
 */
export const FormFieldWrap: FC<Props> = ({
  children,
  label,
  fieldNote,
  form,
  field,
  className,
}) => {

  const error = useMemo(() => {
    const fieldError = form.formState.errors[field.name]
    if (!fieldError) return undefined
    if (fieldError.message && typeof fieldError.message === 'string') return fieldError.message
    return getDefaultErrorMessage('fallback')
  }, [field.name, form.formState])

  return (
    <Stack className={classnames(styles.formField, className, { [styles.error]: !!error })} gap={.5} >

      <Stack direction="row" justifyContent="space-between" className={styles.header}>
        <span className={styles.label}>
          {label}
        </span>
      </Stack>

      <div className={styles.inputWrap}>
        {children}
      </div>

      <div className={styles.footer}>
        {!!error && <span className={styles.error}>{error}</span>}
        {!!fieldNote && !error && <span className={styles.note}>{fieldNote}</span>}
      </div>

    </Stack>
  )
}
