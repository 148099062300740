import { Box, ClickAwayListener, Grow, MenuList, Paper, SxProps, listClasses, styled } from '@mui/material'
import React, { Fragment, MouseEvent, ReactNode, useRef, useState } from 'react'

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import { MUIButton } from '../MUIButton/MUIButton.component'
import { MUIDropdownPopper } from '../MUIDropdownPopper'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** Dropdown id */
  id?: string
  /** Custom dropdown sx styles */
  sx?: SxProps
  /** Custom dropdown button */
  button?: (isOpen: boolean, action: (e: MouseEvent<HTMLElement>) => void) => ReactNode
  children: ReactNode
}

const StyledMenuList = styled(MenuList)({
  [`&.${listClasses.root}`]: {
    padding: 0
  },

})
/**
 * @component MUI Dropdown
 * @example
 * <MUIDropdown />
 */
export const MUIDropdown: React.FC<Props> = ({
  id,
  sx,
  button = (isOpen, action) => (
    <MUIButton isIconButton size="sm" type="secondaryNoBorder" onClick={action}>
      {isOpen ?
        <KeyboardArrowUpRoundedIcon fontSize="large" /> :
        <KeyboardArrowDownRoundedIcon fontSize="large" />
      }
    </MUIButton>
  ),
  children
}) => {
  const buttonAnchorRef = useRef<HTMLAnchorElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const onOpenDropdown = (e: MouseEvent<HTMLElement>) => {
    setIsOpen(true)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  return (
    <Fragment>

      <Box ref={buttonAnchorRef}>
        {button(isOpen, onOpenDropdown)}
      </Box>

      <MUIDropdownPopper
        id={id}
        anchorEl={buttonAnchorRef.current}
        open={isOpen}
        onBlur={onClose}
        placement="bottom-start"
        transition
        disablePortal
        sx={sx}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'left top',
            }}
          >
            <Paper sx={{ overflow: 'auto' }}>
              <ClickAwayListener onClickAway={onClose}>
                <StyledMenuList
                  autoFocusItem={isOpen}
                  onClick={onClose}
                >
                  {children}
                </StyledMenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </MUIDropdownPopper>

    </Fragment>
  )
}
