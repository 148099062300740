import { BEIGE_600, GRAY_900 } from 'constants/styling/theme'
import { FC, ReactNode } from 'react'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { CircleIcon } from 'components/common/CircleIcon'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

/**
 * @interface Props
 */
interface Props {
  /** Icon for empty content info box */
  icon?: ReactNode
  /** Title of empty content info box */
  title: string
  /** Description of empty content info box */
  description?: string
  /** Action primary button of empty content info box */
  button?: ReactNode
  /** Empty content info box padding */
  padding?: string
  /** Background color of empty content info box */
  backgroundColor?: string
}

/**
 * @component
 * EmptyContent component displays a placeholder screen when there is no content to show.
 *
 * @example
 * <EmptyContent
 *   title="No Messages"
 *   description="Michael put them into the special filing cabinet"
 *   icon={<MessageIcon />}
 *   button={<Button onClick={handleReload}>Take them out of the bin</Button>}
 *   padding={16}
 *   backgroundColor={GRAY_200}
 * />
 */
export const EmptyContent: FC<Props> = ({
  icon,
  title,
  description,
  button,
  padding = 0,
  backgroundColor = BEIGE_600
}) =>
  <BorderBoxWrapper backgroundColor={backgroundColor} padding={padding}>

    <Stack
      gap={1}
      padding={4}
      display="column"
      flexWrap="nowrap"
      alignItems="center"
      justifyContent="center"
    >

      {/** CIRCLE WITH ICON */}
      {!!icon &&
        <CircleIcon
          icon={icon}
        />
      }

      {/** EMPTY INFO */}
      <Stack spacing={2} direction="column" alignItems="center" textAlign="center">
        <Typography variant="text-lg" fontWeight={600} color={GRAY_900}>{title}</Typography>
        {!!description && <Typography variant='text-sm'>{description}</Typography>}
        {!!button && button}
      </Stack>

    </Stack>

  </BorderBoxWrapper >
