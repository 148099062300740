import { FC, Fragment, useMemo } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { PageTransition } from 'utils/animations'
import { Path } from 'constants/router'
import { VisualsEditingContextProvider } from './VisualsEditing.context'
import { VisualsEditingController } from './VisualsEditingController.controller'
import { VisualsEditingDownloadProvider } from './VisualsEditingDownload.context'
import { WorkspacesProfileContextProvider } from '../Client/WorkspacesProfile/_main/WorkspacesProfile.context'
import { useGalleryVisualSelection } from '../Gallery/_main/contexts/GalleryVisualSelection.context'
import { useUserData } from 'components/contexts/UserDataContext'

export const VisualsEditing: FC = () => {
  const { id } = useParams<{ id?: string }>()

  const assignmentId = useMemo(() => (String(id)), [id])
  const { selected } = useGalleryVisualSelection()
  const { currentUserWorkspace } = useUserData()

  return (
    <Fragment>
      {!!selected.size ?
        <WorkspacesProfileContextProvider workspaceId={currentUserWorkspace?.id ?? ''}>
          <VisualsEditingDownloadProvider assignmentId={assignmentId}>
            <VisualsEditingContextProvider assignmentId={assignmentId}>
              <PageTransition>
                <VisualsEditingController />
              </PageTransition>
            </VisualsEditingContextProvider>
          </VisualsEditingDownloadProvider>
        </WorkspacesProfileContextProvider>
        :
        <Navigate to={Path.GALLERY.replace(':id', assignmentId.toString())} />
      }
    </Fragment>
  )
}
