/** Enum listing all possible automation statuses of the particular assignment (describes if the creative can be assigned for the particular assignment automatically by the platform) */
export enum AssignmentAutomationStatus {
  /** Assignment is ready to be automated (creative can be assigned automatically by the platform) */
  AUTOMATION_READY = 'AUTOMATION_READY',
  /** Automation of the assignment is not possible */
  AUTOMATION_OUT_OF_SCOPE = 'AUTOMATION_OUT_OF_SCOPE',
  /** Creative assigned manually for the assignment */
  MANUAL = 'MANUAL',
  /** Creative assigned automatically by the platform for the assignment */
  AUTOMATED = 'AUTOMATED',
  /** Automation started as part of the order */
  AUTOMATION_AS_PART_OF_ORDER = 'AUTOMATION_AS_PART_OF_ORDER',
  /** Automation has stopped */
  AUTOMATION_STOPPED = 'AUTOMATION_STOPPED'
}

/** Minimum number of hours before the assignment start so that assignment automation is possible */
export const minHoursForAutomation = 36
