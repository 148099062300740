export enum EntityKeys {
  UNAVAILABILITY = 'UNAVAILABILITY',
  ORDER = 'ORDER',
  WORKING_HOURS = 'WORKING_HOURS',
  ASSIGNMENT = 'ASSIGNMENT',
  ASSIGNMENT_VISUAL_PRICE = 'ASSIGNMENT_VISUAL_PRICE',
  USER = 'USER',
  VISUALS_EDITING = 'VISUALS_EDITING',
  VISUALS_MARKETING_PROPERTY_DETAILS = 'VISUALS_MARKETING_PROPERTY_DETAILS',
  VISUALS_MARKETING_MATERIALS = 'VISUALS_MARKETING_MATERIALS',
  JOB = 'JOB',
  WORKSPACE = 'WORKSPACE',
  USER_WORKSPACE = 'USER_WORKSPACE',
  TRAVEL_COST = 'TRAVEL_COST',
  PF_CATEGORY_CONFIG = 'PF_CATEGORY_CONFIG',
  PF_ADDRESS_CONFIG = 'PF_ADDRESS_CONFIG',
  PF_PRODUCTS = 'PF_PRODUCTS',
  PF_INSTRUCTIONS = 'PF_INSTRUCTIONS',
  VIRTUAL_STAGING_DECORATIONS = 'VIRTUAL_STAGING_DECORATIONS',
  VIRTUAL_STAGING_FURNITURE_STYLES = 'VIRTUAL_STAGING_FURNITURE_STYLES',
  CHECKOUT_LINK = 'CHECKOUT_LINK',
  PORTAL_LINK = 'PORTAL_LINK',
  UPSELL_CONFIG = 'UPSELL_CONFIG',
  FLOOR_PLAN_TEMPLATES = 'FLOOR_PLAN_TEMPLATES',
  SUBSCRIPTION_STATUS = 'SUBSCRIPTION_STATUS',
  USER_STATUS = 'USER_STATUS',
  VIRTUAL_VISIT_EXTENSION_PRICE = 'VIRTUAL_VISIT_EXTENSION_PRICE',
  PROPERTY = 'PROPERTY',
}

export enum QueryType {
  GET_ONE = 'GET_ONE',
  GET = 'GET',
  LIST = 'LIST',
  LIST_MINE = 'LIST_MINE',
  INFINITE_LIST = 'INFINITE_LIST',
}
