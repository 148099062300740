import { FC, useMemo } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import { BKBNThemeWrapper } from 'components/common/BKBNThemeWrapper'
import { Build } from '@mui/icons-material'
import { HeaderBar } from 'components/common/HeaderBar'
import { MarketingMaterialsContextProvider } from './contexts'
import { PageTransition } from 'utils/animations'
import { Path } from 'constants/router'
import { VisualsMarketingContextProvider } from './contexts/VisualsMarketing.context'
import { VisualsMarketingController } from './VisualsMarketing.controller'
import { VisualsMarketingFormProvider } from './contexts/VisualsMarketing.form'
import { useAuth0 } from 'utils/auth'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * Page showing tools to generate marketing text using AI for different content forms.
 * This page is only visible for clients with subscription.
 * 
 * @example <VisualsMarketing />
*/
export const VisualsMarketing: FC = () => {

  const { roles } = useAuth0()
  const { hasUserActiveSubscription } = useUserData()

  const { id } = useParams<{ id?: string }>()
  const assignmentId = useMemo(() => (String(id)), [id])

  const { t } = useTranslation(['common, gallery_visuals_marketing'])
  const navigate = useNavigate()

  if (!roles.isClient || !hasUserActiveSubscription) {
    return (
      <Navigate to={Path.GALLERY.replace(':id', assignmentId.toString())} />
    )
  }

  return (
    <PageTransition>
      <BKBNThemeWrapper>
        <VisualsMarketingFormProvider>
          <MarketingMaterialsContextProvider>
            <VisualsMarketingContextProvider>
              <div id={`visual-marketing-${assignmentId}`}>

                <HeaderBar
                  title={t('gallery_visuals_marketing:title')}
                  badges={[{ color: 'gray', text: t('common:beta').toUpperCase(), materialIcon: <Build /> }]}
                  onClose={() =>
                    navigate(Path.GALLERY.replace(':id', assignmentId.toString()))
                  }
                />

                <VisualsMarketingController />

              </div>
            </VisualsMarketingContextProvider>
          </MarketingMaterialsContextProvider>
        </VisualsMarketingFormProvider>
      </BKBNThemeWrapper>
    </PageTransition>
  )
}
