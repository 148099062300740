import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { ActionLoadEditedVisual, LoadEditedVisualStore } from '.'

import { cloneDeep } from 'lodash'

/** Redux data reducer for loading edited visuals */
export const reduceLoadEditedVisual = (state: LoadEditedVisualStore, action: ActionLoadEditedVisual) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.LOAD_EDITED_VISUAL) return state
  const { filename, visualType, jobId, signedUrl, request } = cloneDeep(action.payload)
  const newState = cloneDeep(state)

  const receivedState = {
    [jobId]: {
      ...newState[jobId],
      [filename]: {
        ...newState[jobId]?.[filename],
        [visualType]: {
          ...newState[jobId]?.[filename]?.[visualType],
          filename,
          signedUrl,
          request
        }
      }
    }
  }

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
      return {}
    case ActionTypeAPIEvent.FETCH:
    case ActionTypeAPIEvent.RECEIVED:
      return receivedState
    default:
      return state
  }
}
