/** Watermark Size enum */
export enum Size {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
}

/** Watermark Opacity enum */
export enum Opacity {
  DEFAULT = 80
}

/** Watermark Positioning enum */
export enum Positioning {
  TOP_LEFT = 'TOP_LEFT',
  TOP_CENTER = 'TOP_CENTER',
  TOP_RIGHT = 'TOP_RIGHT',
  CENTER_LEFT = 'CENTER_LEFT',
  CENTER = 'CENTER',
  CENTER_RIGHT = 'CENTER_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_CENTER = 'BOTTOM_CENTER',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
}

/** Export Options */
export enum ExportOption {
  ORIGINAL = 'ORIGINAL',
  WEB = 'WEB',
  PRINT = 'PRINT'
}

export enum RatioOption {
  ORIGINAL = 'ORIGINAL'
}

/** Visuals Editing Steps */
export enum VisualEditingSteps {
  SETTINGS,
  PREVIEW
}
