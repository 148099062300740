import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { EditedVisualFileType } from 'constants/visualEditing/visualEditingFiles'
import { APIRequest } from 'models/API'
import { ActionRequest } from 'models/redux'
import { createAPIAction } from 'redux/Helpers'
import { LoadEditedVisualStoreItem } from './loadEditedVisual.interfaces'

/** Describes general action to get thumbnails */
export interface ActionLoadEditedVisual extends ActionRequest {
  payload: {
    jobId: string
    assignmentId: string
    filename: string
    visualType: EditedVisualFileType
    request: LoadEditedVisualStoreItem
    signedUrl?: string
  },
}

/** Creates an action to get thumbnails for Visuals Editing preview */
export function loadEditedVisuals(jobId: string, assignmentId: string, visualType: EditedVisualFileType, filename: string): ActionLoadEditedVisual {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.LOAD_EDITED_VISUAL], {
    jobId,
    assignmentId,
    visualType,
    filename,
    request: new APIRequest()
  })
}

/** Creates an action to purge loaded visuals state */
export function purgeLoadEditedVisuals(): ActionLoadEditedVisual {
  // for now we work always only with one jobId, no need to pass it and keep previous job data
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.LOAD_EDITED_VISUAL], {})
}
