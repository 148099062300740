import 'styles/index.sass'
import 'styles/reset.css'
import 'styles/becka.css'
import './translations/i18n'
import './utils/polyfills'

import * as Sentry from '@sentry/react'
import * as serviceWorker from './serviceWorker'

import { CSSPlugin, gsap } from 'gsap'
import { initGoogleAnalytics, initHotjar, initIntercom, initMixpanel, initSentry } from 'utils/analytics'

import { App } from 'components/higher-order/App'
import Auth0Config from 'constants/auth/auth0'
import { Auth0Provider } from 'utils/auth'
import ErrorPage from 'components/pages/General/ErrorPage/ErrorPage'
import { Provider } from 'react-redux'
import { composedStore } from 'redux/RootComposedStore'
import { createRoot } from 'react-dom/client'

// Initialize google analytics
initGoogleAnalytics()

// Initialize sentry
initSentry()

// Initialize mixpanel
initMixpanel()

// Initialize Intercom
initIntercom()

// Initialize Hotjar
initHotjar()

// Register gsap CSS plugin
gsap.registerPlugin(CSSPlugin)

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <Auth0Provider
    domain={Auth0Config.domain}
    audience={Auth0Config.audience}
    client_id={Auth0Config.clientId}
    useRefreshTokens={true}
    cacheLocation={'localstorage'}
    redirect_uri={window.location.origin}
  >
    <Provider store={composedStore}>
      <Sentry.ErrorBoundary fallback={<ErrorPage />}>
        <App />
      </Sentry.ErrorBoundary>
    </Provider>
  </Auth0Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
