import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { CircularIconLoading } from './CircularIconLoading'
import { FC } from 'react'
import Stack from '@mui/material/Stack'
import { useTranslation } from 'react-i18next'

/**
 * @component
 * Loading component displays a loading screen for when the text is generating.
 * If the query fails the loading description is replaced by the query error text.
 *
 * @example
 * <EmptyContent />
 */
export const Loading: FC = () => {
  const { t } = useTranslation(['gallery_marketing_section'])

  // TODO: Add error text instead of loading if generating text query fails

  return (
    <BorderBoxWrapper padding="4rem" width="100%">
      <Stack
        gap={2}
        height="100%"
        direction="column"
        alignItems="center"
        justifyContent="center"
      >

        <CircularIconLoading />

        <Stack direction="column" alignItems="center" spacing={1}>
          <h3>{t('loading.title')}</h3>
          <span>{t('loading.description')}</span>
        </Stack>

      </Stack>
    </BorderBoxWrapper>
  )
}
