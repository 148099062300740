import { BLUE_600, GRAY_400, GRAY_900 } from 'constants/styling/theme'
import { BorderBoxWrapper, ShadowElevation } from 'components/common/BorderBoxWrapper'
import { ReactNode, useMemo } from 'react'

import { SimpleImage } from 'components/common/SimpleImage'
import Typography from '@mui/material/Typography'

/** @interface Props for the SelectableImageBox component. */
interface Props {
  /** The text to be displayed in the box. */
  text: string
  /** Image URL or icon as ReactNode. */
  image: ReactNode | string
  /** Width of the box in rem. */
  width?: number
  /** Height of the box in rem. */
  height?: number
  /** Specifies the size of the box shadow.  */
  elevation?: ShadowElevation
  /** Indicates whether the box is selected. */
  isSelected?: boolean
  /** Callback function triggered when the box is selected. */
  onSelect?: () => void
  /** Custom inactive border color. */
  inactiveBorderColor?: string
  /** Disabled the image box. */
  disabled?: boolean
}

/**
 * @component
 * SelectableImageBox displays a selectable box with an image or icon and text.
 * 
 * @example
 * <SelectableImageBox
 *   width={10}
 *   height={10}
 *   text="Slazy"
 *   isSelected={true}
 *   disabled={false}
 *   image="https://example.com/gudetama.png"
 *   onSelect={() => console.log('Slazy, lazy but still slaying!')}
 * />
 */
export const SelectableImageBox: React.FC<Props> = ({
  text,
  image,
  width,
  height,
  isSelected,
  elevation = 'none',
  inactiveBorderColor = GRAY_400,
  disabled,
  onSelect
}) => {
  const boxWidth = useMemo(() => width ? `${width}rem` : '100%', [width])
  const boxHeight = useMemo(() => height ? `${height}rem` : '100%', [height])

  return (
    <BorderBoxWrapper
      elevation={elevation}
      padding={1.6}
      borderColor={isSelected ? BLUE_600 : inactiveBorderColor}
      sx={{
        gap: '1rem',
        display: 'flex',
        cursor: disabled ? 'not-allowed' : 'pointer',
        alignItems: 'center',
        flexDirection: 'column',
        width: boxWidth,
        height: boxHeight,
      }}
      onClick={() => !disabled && onSelect?.()}
    >

      {typeof image === 'string'
        ? (
          <SimpleImage
            key={image}
            url={image}
            sx={{ width: '9.6rem', height: '9.6rem' }}
            borderRadius='none'
          />
        )
        : image // Render ReactNode for icons
      }

      <Typography variant='text-sm' fontWeight={500} color={GRAY_900}>
        {text}
      </Typography>

    </BorderBoxWrapper>
  )
}
