import { CSSProperties, FC } from 'react'

interface Props {
  /** Color used as an accent (either lighter background or colorful accent to neutral primary color) - displayed in the top-left half */
  accentColor: string
  /** Main color (the main visually striking color) - displayed in the right-bottom half */
  mainColor: string
  /** Width of the parent div (the icon is background, so it's better to keep the parent a square) */
  width?: CSSProperties['width']
  /** Height of the parent div (the icon is background, so it's better to keep the parent a square) */
  height?: CSSProperties['height']
}

/**
 * A circular icon component that displays preview of dual tone color theme as a circle with each color on one half.
 * @example <ColorThemePreview mainColor="black" accentColor="yellow" /> => (yellow/black) circle
 */
export const ColorThemePreview: FC<Props> = ({
  accentColor,
  mainColor,
  width = '40px',
  height = '40px',
}) => {
  return (
    <div style={{
      width,
      height,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundImage: `url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_827_975)"><path d="M20.4862 20.4844C25.1725 15.7981 25.1725 8.2001 20.4862 3.51381L3.51562 20.4844C8.20192 25.1707 15.7999 25.1707 20.4862 20.4844Z" fill="${encodeURIComponent(mainColor)}"/><path d="M3.51458 3.51472C-1.17171 8.20101 -1.17171 15.799 3.51458 20.4853L20.4851 3.51472C15.7988 -1.17157 8.20087 -1.17157 3.51458 3.51472Z" fill="${encodeURIComponent(accentColor)}"/></g><rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="%23DCDDDE"/><defs><clipPath id="clip0_827_975"><rect width="24" height="24" rx="12" fill="white"/></clipPath></defs></svg>')`
    }}>
    </div>
  )
}
