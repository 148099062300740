import { BLACK_TEXT, WHITE } from 'constants/styling/theme'

import { MUIButton } from '../../../common/MUIButton'

/** @interface Props for the CleanButton component. */
interface Props {
  /** Text of the button. */
  text?: string
  /** Handle action click triggered after the text is copied. */
  onClick?: () => void
}

/**
 * @component
 * Clear button in Client dashboard.
 * 
 * @example
 * <ClearButton
 *   text="Burn it all!"
 *   onClick={() => console.log('Firrrrreeee!')}
 * />
 */
export const ClearButton: React.FC<Props> = ({
  text,
  onClick,
}) => {

  return (
    <MUIButton
      type="secondaryNoBorder"
      borderStyle="sharp"
      variant="text"
      size="xs"
      sx={{
        borderBottom: '.1rem solid transparent',
        transition: 'border-color 0.3s ease',
        '&:hover': {
          backgroundColor: WHITE,
          borderBottomColor: BLACK_TEXT,
        },
        padding: 0,
        fontWeight: 500,
      }}
      onClick={() => onClick?.()}
    >
      {text}
    </MUIButton>
  )
}
