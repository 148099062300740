import React, { ReactNode } from 'react'

import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack'
import classnames from 'classnames'
import styles from './ListItem.module.sass'

/** 
 * @interface Props Input properties
 */
interface Props {
  /** Main content of the component */
  children: ReactNode
  /** Actions to be displayed on hover */
  actionButtons?: ReactNode
  /** Whether to display the loading progress */
  isLoading?: boolean
  /** Request status content displayed at the bottom */
  requestStatus?: ReactNode
  /** The additional classes to append */
  className?: string
}

/**
 * @component ListItem displays an item with dynamic content and handles actions and requests
 * @example
 * <ListItem>
 *  <h3>Basic list item example</h3>
 *  <p>Imagine this is some awesome text</p>
 * </ListItem>
 */
export const ListItem: React.FC<Props> = ({ children, actionButtons, isLoading, requestStatus, className }) => {
  return (
    <div className={classnames(styles.wrapper, className)}>

      {/** CONTENT */}
      <Stack
        gap={2}
        alignItems={{
          xs: 'flex-start',
          sm: 'center',
        }}
        justifyContent="space-between"
        direction={{ xs: 'column', sm: 'row' }}
      >
        {children}
      </Stack>

      {/** RESPONSES */}
      {requestStatus}

      {/** ACTION BUTTONS */}
      {!!actionButtons &&
        <div className={styles.actionsWrapper}>
          {actionButtons}
        </div>
      }

      {/** LOADING */}
      {isLoading &&
        <Box className={styles.progressBar}>
          <LinearProgress />
        </Box>
      }

    </div>
  )
}
