import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { FC, useEffect } from 'react'
import { usePurchaseFlowConfig, usePurchaseFlowProducts, useUploadedPurchaseFlowVisuals } from '../../_main/contexts'

import { StagingFlow } from 'components/common/StagingFlow/main'
import { StepWrapper } from '../../common/StepWrapper'
import TransitionAppear from 'components/common/TransitionAppear/TransitionAppear'
import { useDynamicFlowController } from 'components/common/DynamicFlow'
import { useStyleSelection } from 'components/common/StagingFlow/main/contexts'
import { useTranslation } from 'react-i18next'

export const StyleSelectionStepController: FC = () => {
  const { t } = useTranslation(['style_selection_order_step'])

  const { uploadFinished, uploadedImagesArray } = useUploadedPurchaseFlowVisuals()
  const { allRooms } = useStyleSelection()
  const flowCtrl = useDynamicFlowController()
  const { selectedCategory } = usePurchaseFlowConfig()
  const {
    selectedKinds,
    selectedProductTypes,
    selectedProducts,
  } = usePurchaseFlowProducts()

  // Take user a step back if no rooms have been generated, no images uploaded or upload haven't finished yet
  useEffect(() => {
    if (!flowCtrl.currentNode?.prev) return

    if (!uploadFinished) flowCtrl.setHead(flowCtrl.currentNode.prev)
    if (!uploadedImagesArray.length) flowCtrl.setHead(flowCtrl.currentNode.prev)
    if (!allRooms?.length) flowCtrl.setHead(flowCtrl.currentNode.prev)

    // No broad flowCtrl dependency to prevent looping 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRooms, flowCtrl.currentNode?.prev, uploadFinished, uploadedImagesArray.length])

  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvent.ENTER_ORDER_STAGING_PAGE_SCREEN, {
      productTypes: Array.from(selectedProductTypes),
      productKinds: Array.from(selectedKinds),
      products: Object.values(selectedProducts),
      category: selectedCategory,
    })

    // Only trigger when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TransitionAppear visible>
      <StepWrapper
        title={t('title')}
        subtitle={t('description')}
      >
        <StagingFlow />
      </StepWrapper>
    </TransitionAppear>
  )
}
