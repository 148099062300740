import { useEffect, useRef } from 'react'
import { usePurchaseFlowConfig, usePurchaseFlowOrderMeta } from 'components/pages/PurchaseFlow/_main/contexts'

import { BillingMethodInstructionCard } from '../BillingMethodInstructionCard'
import DynamicQueryContent from 'components/common/DynamicQueryContent/DynamicQueryContent'
import { InputWrap } from 'components/common/InputWrap'
import { InstructionType } from 'constants/purchaseFlow'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import { Stack } from '@mui/material'
import { StepWrapper } from 'components/pages/PurchaseFlow/common/StepWrapper'
import { useAuth0 } from 'utils/auth'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Whether to disable selecting/deselecting */
  disabledSelection?: boolean
  /** Whether to disable filling in the option fields */
  disabledFields?: boolean
}

/**
 * Shows Billing page in Purchase Flow.
 * 
 * @example
 * <Billing />
 */
export const Billing: React.FC<Props> = ({
  disabledSelection = false,
  disabledFields = false,
}) => {
  const { t } = useTranslation(['purchase_flow', 'product'])
  const { roles } = useAuth0()

  const {
    getInstructionCatalogue,
    availablePaymentMethods,
    externalReportingInstruction,
    billingAddress: extractedBillingAddress,
  } = usePurchaseFlowConfig()

  const {
    reference,
    setReference,
    billingAddress,
    setBillingAddress,
    VATNumber,
    setVATNumber,
    vatInputRequired,
    billingAddressError,
    propertyOwner,
    setPropertyOwner,
    externalReportingField,
    setExternalReportingField
  } = usePurchaseFlowOrderMeta()

  // Prefill billing address on load if empty
  const isBillingAddressPrefilled = useRef(false)
  useEffect(() => {
    if (billingAddress) return
    if (!getInstructionCatalogue.isSuccess) return
    if (isBillingAddressPrefilled.current) return

    setBillingAddress(extractedBillingAddress || '')
    isBillingAddressPrefilled.current = true
  }, [billingAddress, extractedBillingAddress, getInstructionCatalogue.isSuccess, setBillingAddress])

  return (
    <StepWrapper
      title={t('billing_step.section_title')}
      subtitle={t('billing_step.section_description')}
    >
      <DynamicQueryContent query={getInstructionCatalogue}>
        <Stack gap={2}>

          {/* Billing */}
          <SectionedBorderBox title={t('billing_step.order_information')}>
            <Stack gap={2}>

              <InputWrap label={t('billing_step.reference_field_label')} fieldNote={t('billing_step.reference_field_note')}>
                <input type="text" value={reference} onChange={(e) => setReference(e.target.value)} />
              </InputWrap>

              <InputWrap label={t('billing_step.property_owner_field_label')} fieldNote={t('billing_step.property_owner_field_note')}>
                <textarea value={propertyOwner} onChange={(e) => setPropertyOwner(e.target.value)}></textarea>
              </InputWrap>

              {vatInputRequired &&
                <InputWrap
                  label={t('billing_step.vat_field_label')}
                  fieldNote={t('billing_step.vat_field_note')}
                >
                  <input type="text" value={VATNumber} onChange={(e) => setVATNumber(e.target.value)} />
                </InputWrap>
              }

              <InputWrap
                label={t('billing_step.billing_address_field_label')}
                error={billingAddressError}
                required
              >
                <textarea value={billingAddress} onChange={(e) => setBillingAddress(e.target.value)}></textarea>
              </InputWrap>

              {!!externalReportingInstruction &&
                <InputWrap label={t(`product:p_${externalReportingInstruction.id}`)}>
                  <textarea
                    value={externalReportingField}
                    onChange={(e) => setExternalReportingField(e.target.value)}
                  ></textarea>
                </InputWrap>
              }

              <InputWrap label={t('common.payment_method')} required>
                <Stack gap={1}>
                  {Object.values(availablePaymentMethods).map((paymentMethod, _, paymentMethods) => (
                    <BillingMethodInstructionCard
                      key={paymentMethod.id}
                      showSaveOption={roles.isClient && paymentMethods.length > 0}
                      isCardDisabled={disabledFields}
                      isSelectionDisabled={disabledSelection}
                      instructionOption={paymentMethod}
                      instructionType={InstructionType.BILLING_PRIMARY}
                    />
                  ))}
                </Stack>
              </InputWrap>

            </Stack>
          </SectionedBorderBox>

        </Stack>
      </DynamicQueryContent>
    </StepWrapper>
  )
}
