import './EventTime.sass'

import { Color, ColorClass, IconType } from 'constants/assets'
import React, { useCallback, useMemo } from 'react'
import { getDateString, getTimeDurationString, getTimeString, isSameTimezone } from 'utils/time'

import { ISODateString } from 'models/misc'
import Icon from '../Icon/Icon'
import TriangleIcon from '../TriangleIcon/TriangleIcon'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Start date of event */
  eventStart: ISODateString,
  /** Duration of event in minutes */
  eventDuration: number,
  /** Timezone of event */
  eventTimezone: string | null
  /** Timezone of logged user */
  userTimezone: string,
  /** Display compact version */
  compactMode?: boolean,
  /** Color of leading icon's triangle */
  iconColorClass?: ColorClass,
  /** Color of duration box background */
  boxColor?: Color
}

/**
 * @component Displays date and time frame of given event
 * @example
 * <EventTime eventStart={new Date().toISOString()} eventDuration={300} eventTimezone="Europe/Berlin" userTimezone="Europe/Berlin" />
 */
export const EventTime: React.FC<Props> = ({
  eventStart,
  eventDuration,
  eventTimezone,
  userTimezone,
  compactMode = false,
  iconColorClass = ColorClass.PRIMARY_BLUE,
  boxColor = Color.PRIMARY_BLUE
}) => {

  const { t } = useTranslation(['deal_assignment'])
  const timezonesMatch = useMemo(() => !!eventTimezone && !!isSameTimezone(eventTimezone, userTimezone), [eventTimezone, userTimezone])
  const getEventDurationTimeString = useCallback((start: string, duration: number, timezone: string) => {
    const end = moment(start).add(duration, 'minutes').toISOString()
    const eventStartTimeString = getTimeString(start, { timezone })
    const eventEndTimeString = getTimeString(end, { timezone })
    const eventDurationTimeString = getTimeDurationString(start, end)
    const durationString = !!eventDurationTimeString ? ` (${t('maximum')} ${eventDurationTimeString})` : ''

    return `${eventStartTimeString} - ${eventEndTimeString}${durationString}`
  }, [t])

  return (
    <div className={`event-time ${compactMode ? 'is-compact' : ''}`}>

      {/*
        This block is shown everytime and displays start date of event and duration in user's timezone.
        If eventTimezone is not set or userTimezone is not the same as eventTimezone, location name is shown.
      */}
      <div className="time-block">
        {(!eventTimezone || !timezonesMatch) && !compactMode &&
          <span className="timezone">{t('deal_assignment:timezone:user_timezone')}: {userTimezone}</span>
        }
        <div className="time-line">
          <TriangleIcon icon={IconType.DATE} type={iconColorClass} />
          <span>{getDateString(eventStart, { timezone: userTimezone })}</span>
          <span className="duration" style={{ backgroundColor: boxColor }}>
            <Icon className="small" icon={IconType.CLOCK} />
            <span>{getEventDurationTimeString(eventStart, eventDuration, userTimezone)}</span>
          </span>
          {(!eventTimezone || !timezonesMatch) && compactMode &&
            <span className="timezone-compact">{t('deal_assignment:timezone:user_timezone')}: {userTimezone}</span>
          }
        </div>
      </div>

      {/*
        This block is shown if eventTimezone and userTimezone are not the same.
        Event's start date and duration is shown in event's timezone.
        Location name is shown.
      */}
      {!!eventTimezone && !timezonesMatch &&
        <div className="time-block">
          {!compactMode && <span className="timezone">{t('deal_assignment:timezone:location_timezone')}: {eventTimezone}</span>}
          <div className="time-line">
            <TriangleIcon icon={IconType.DATE} type={iconColorClass} />
            <span>{getDateString(eventStart, { timezone: eventTimezone })}</span>
            <span className="duration" style={{ backgroundColor: boxColor }}>
              <Icon className="small" icon={IconType.CLOCK} />
              <span>{getEventDurationTimeString(eventStart, eventDuration, eventTimezone)}</span>
            </span>
            {compactMode && <span className="timezone-compact">{t('deal_assignment:timezone:location_timezone')}: {eventTimezone}</span>}
          </div>
        </div>
      }

    </div>
  )
}
