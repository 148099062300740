export enum FeatureFlag {
  ALLOW_PROPERTY_KEYWORDS = 'allow-property-keywords', // By default ENABLED for both envs!! This is used on Visuals Marketing AI feature as a quick security flag in case someone will try to do XSS attack
  ALLOW_PROPERTY_FREETEXT = 'allow-marketing-property-freetext',
  ALLOW_REPEATED_UPSELL_PAYMENT = 'allow-repeated-upsell-payment', // Disabled on both envs feature not fully developed! If enabled there will be possibility to retry failed stripe payment in upsell payment popup
  IS_SITE_IN_MAINTANANCE = 'is-site-in-maintenance',   // Prevents using the site, therefore false on both envs in Gitlab
  ALLOW_RESUMABLE_UPLOADS = 'allow-resumable-uploads',
  ALLOW_FLOOR_PLAN_CONFIG_FLOW = 'allow-floor-plan-config-flow',
  ALLOW_AUTHORITIES_DOCUMENTS = 'allow-authorities-documents',
  ALLOW_COMPASS_AND_DISCLAIMER = 'allow_compass_and_disclaimer',
  ALLOW_VIRTUAL_VISIT_LINKS_COMMENTS = 'allow-virtual-visit-links-comments',
  ALLOW_MARKETING_COMMERCIAL_CATEGORY = 'allow-marketing-commercial-category',
  ALLOW_SIGNUP_REDESIGN = 'allow-signup-redesign',
  ALLOW_SPLIT_GALLERIES = 'allow-split-galleries',
  ALLOW_MEASUREMENT_STEP = 'allow-measurement-step',
  ALLOW_FLOOR_PLAN_CERTIFICATION_FLOW = 'allow-floor-plan-certification-flow',
  ALLOW_3D_FLOOR_PLANS = 'allow-3d-floor-plans',
  ALLOW_ENERGY_DOCUMENTS = 'allow-energy-documents',
  ALLOW_EACH_ROOM_DIMENSIONS = 'allow-each-room-dimensions',
  ALLOW_SD_FOR_MATTERPORT = 'allow-sd-for-matterport',
}
