import { Fragment, useCallback, useMemo } from 'react'
import { PlanPeriod, Plans, ProPlanPrice } from 'constants/pricing'

import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import Button from 'components/common/Button/Button'
import CheckIcon from '@mui/icons-material/Check'
import { Country } from 'constants/country'
import { Currency } from 'constants/misc'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { MUIDivider } from 'components/common/MUIDivider'
import { Path } from 'constants/router'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import classNames from 'classnames'
import { formatCurrency } from 'utils/price'
import { getAccessLink } from 'utils/localization'
import i18n from 'translations/i18n'
import { logAnalyticsEvent } from 'utils/analytics'
import styles from './PlanCard.module.sass'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Type of subscription plan */
  plan: string,
  /** Details of subscription plan */
  info: string[],
  /** Period time of subscription plan */
  planPeriod: PlanPeriod
}

/**
 * @component Plan Card
 * @example
 * <PlanCard />
 */
export const PlanCard: React.FC<Props> = ({
  plan,
  info,
  planPeriod = PlanPeriod.MONTHLY
}) => {
  const { t } = useTranslation(['pricing_page'])
  const navigate = useNavigate()
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)
  const { clientData } = useUserData()

  const planPrice = useCallback((price: number) => {
    const currency = clientData?.country === Country.CH ? Currency.CHF : Currency.EUR

    return price + formatCurrency(currency)
  }, [clientData?.country])

  const planLogo = useMemo(() => {
    switch (plan) {
      case Plans.PERSONAL:
        return <PersonOutlineOutlinedIcon fontSize='inherit' />
      case Plans.PRO:
        return <GroupOutlinedIcon fontSize='inherit' />
      case Plans.ENTERPRISE:
        return <BusinessOutlinedIcon fontSize='inherit' />
    }
  }, [plan])

  const subscribeLink = useCallback(() => {
    switch (plan) {
      case Plans.PRO:
        logAnalyticsEvent('SaaS Pricing - Click Team Subscription', {
          userEmail: clientData?.email,
          organizationId: clientData?.organizationId,
        })
        return navigate(Path.SUBSCRIPTION_CREATION)
      case Plans.ENTERPRISE:
        logAnalyticsEvent('SaaS Pricing - Click Enterprise Subscription', {
          userEmail: clientData?.email,
          organizationId: clientData?.organizationId,
        })
        return window.open(getAccessLink(i18n.language), '_blank', 'noopener noreferrer')
    }
  }, [clientData?.email, clientData?.organizationId, navigate, plan])

  return (
    <div className={classNames(styles.planCard, { [styles.mobile]: isMobileView })}>
      <div className={styles.cardWrap}>
        <div className={styles.planLogo}>
          <div className={styles.innerCircle}>
            <div className={styles.img}>
              {planLogo}
            </div>
          </div>
        </div>
        <div className={styles.header}>
          <div className={styles.title}>{t(`${plan}.${plan}`)}</div>
          <div className={styles.description}>{t(`${plan}.description`)}</div>
        </div>
        <div className={styles.planPrice}>
          {plan === Plans.PERSONAL &&
            <div className={styles.price}>
              {t(`${plan}.price`)}
            </div>
          }
          {plan === Plans.PRO &&
            <div className={styles.teamPrice}>
              <div className={styles.priceWrap}>
                <div className={styles.originalPrice}>
                  {planPeriod === PlanPeriod.YEARLY &&
                    <Fragment>
                      <div className={styles.strikeLine}></div>
                      {planPrice(ProPlanPrice[PlanPeriod.MONTHLY] * 12)}
                    </Fragment>
                  }
                </div>
                <div className={styles.discount}>
                  {planPrice(ProPlanPrice[planPeriod])}
                </div>
              </div>
              <div className={styles.userAmount}>
                <span className={styles.upToLabel}> / {t(`${plan}.per_user`)}</span>
              </div>
            </div>
          }
          {plan === Plans.ENTERPRISE &&
            <div className={styles.price
            }>
              {t(`${plan}.price`)}
            </div>
          }
        </div>
        <MUIDivider />
        <div className={styles.info}>
          {plan !== Plans.PERSONAL &&
            <div className={styles.note}>{t(`${plan}.note`)}</div>
          }
          {info.map((item, idx) =>
            <div key={idx} className={styles.detail}>
              <div className={styles.iconWrap}>
                <CheckIcon className={styles.checkIcon} />
              </div>
              <div className={styles.content}>{t(`${plan}.${item}`)}</div>
            </div>
          )}
        </div>
      </div>
      {plan !== Plans.PERSONAL &&
        <div className={styles.buttonWrap}>
          <Button className={styles.button} onClick={subscribeLink}>{t(`${plan}.button`)}</Button>
        </div>
      }
    </div>
  )
}
