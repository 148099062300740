import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'

import Button from 'components/common/Button/Button'
import { FC } from 'react'
import classnames from 'classnames'
import { getCancelSubscriptionLink } from 'utils/localization/subscriptionGoogleForms'
import i18n from 'translations/i18n'
import styles from '../_main/WorkspacesProfile.module.sass'
import { useTranslation } from 'react-i18next'
import { useWorkspacesProfileContext } from '../_main/WorkspacesProfile.context'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** Workspace ID */
  workspaceId: string
}

/**
 * @component Cancel subscription section
 * @example <CancelSubscription />
 */
export const CancelSubscription: FC<Props> = ({ workspaceId }) => {
  const { t } = useTranslation(['workspace_profile'])
  const { workspaceDataOfUser } = useWorkspacesProfileContext()

  return (
    <div className={classnames(styles.boxWrapper, styles.smallPadding)}>

      <div className={classnames(styles.infoWrapper, styles.spaceBetweenContentWrapper)}>

        <div>
          <span>
            {t('cancel_subscription_info')}
          </span>
        </div>

        <div className={styles.buttonWrapper}>
          <a href={getCancelSubscriptionLink(i18n.language)}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <Button type="secondary"
              className={styles.button}
              onClick={() => logAnalyticsEvent(AnalyticsEvent.WORKSPACE_PROFILE_CLICK_UNSUBSCRIBE, {
                userId: workspaceDataOfUser?.userId,
                userRole: workspaceDataOfUser?.role,
                planId: workspaceId,
              })}
            >
              {t('cancel_subscription')}
            </Button>
          </a>
        </div>

      </div>

    </div>
  )
}
