import 'first-input-delay'

import { Analytics, initializeAnalytics, setUserId } from 'firebase/analytics'
import { FirebaseApp, initializeApp } from 'firebase/app'
import { FirebasePerformance, initializePerformance } from 'firebase/performance'
import { RemoteConfig, getRemoteConfig } from 'firebase/remote-config'

import { Auth0User } from 'models/auth'

export * as firebaseAnalytics from 'firebase/analytics'
export * as firebaseApp from 'firebase/app'
export * as firebasePerformance from 'firebase/performance'

const API_KEY = process.env.REACT_APP_FIREBASE_API_KEY
const PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID
const SENDER_ID = process.env.REACT_APP_FIREBASE_SENDER_ID
const APP_ID = process.env.REACT_APP_FIREBASE_APP_ID
const G_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_G_MEASUREMENT_ID

export const firebaseConfig = {
  apiKey: API_KEY,
  authDomain: `${PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${PROJECT_ID}.firebaseio.com`,
  projectId: `${PROJECT_ID}`,
  storageBucket: `${PROJECT_ID}.appspot.com`,
  messagingSenderId: SENDER_ID,
  appId: APP_ID,
  measurementId: G_MEASUREMENT_ID,
}

/** The type of firebase controller singleton */
export type FirebaseController = {
  app: FirebaseApp | undefined
  analytics: Analytics | undefined
  performance: FirebasePerformance | undefined
  remoteConfig: RemoteConfig | undefined
}

/** A singleton object containing all firebase controls */
export const firebaseController: FirebaseController = {
  app: undefined,
  analytics: undefined,
  performance: undefined,
  remoteConfig: undefined,
}

export const trackFirebaseAnalyticsUser = (user: Auth0User) => {
  if (!firebaseController.analytics) return
  if (user?.sub) setUserId(firebaseController.analytics, user.sub)
}

export const initFirebase = () => {
  if (
    API_KEY &&
    PROJECT_ID &&
    SENDER_ID &&
    APP_ID &&
    G_MEASUREMENT_ID
  ) {
    const firebaseAppInstance = initializeApp(firebaseConfig)

    firebaseController.app = firebaseAppInstance
    firebaseController.analytics = initializeAnalytics(firebaseAppInstance)
    firebaseController.performance = initializePerformance(firebaseAppInstance)
    firebaseController.remoteConfig = getRemoteConfig(firebaseAppInstance)
    return firebaseController
  }
  return null
}