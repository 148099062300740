import { ProductType, STAGING_PRODUCT_KINDS } from 'constants/product'
import { useGalleryStagingFlow, useGalleryStagingFlowList } from '../_main/contexts'

import BlockInfo from 'components/common/BlockInfo/BlockInfo'
import Button from 'components/common/Button/Button'
import { Check } from '@mui/icons-material'
import { FC } from 'react'
import classnames from 'classnames'
import styles from './GalleryStagingFlowList.module.sass'
import { useTranslation } from 'react-i18next'

/**
 * Displays list of staging assignments available for staging.
 * Set up stagings are marked in green outline and checkmark.
 * 
 * @example
 * <GalleryStagingFlowList />
 */
export const GalleryStagingFlowList: FC = () => {

  const { t } = useTranslation(['visual_download_staging', 'product_kind'])

  const { initializeStaging, goNext } = useGalleryStagingFlow()
  const { assignmentsToStage, finishedStagings } = useGalleryStagingFlowList()

  return (
    <div className={styles.stagingList}>

      {assignmentsToStage.map((assignment) => {

        const ErrorCase = (
          <BlockInfo color="red">
            {t('empty_assignment_error')}
          </BlockInfo>
        )

        if (assignment.products.length === 0) return ErrorCase

        const isCompleted = finishedStagings.has(assignment.id)
        const stagingProduct = assignment.products.find(
          ({ kind, type }) => STAGING_PRODUCT_KINDS.has(kind) && type !== ProductType.STAGING_360
        )

        // Typeguard error
        if (!stagingProduct) return ErrorCase

        return (
          <div key={assignment.id} className={classnames(styles.stagingItem, { [styles.isCompleted]: isCompleted })}>

            <div className={styles.stagingTitle}>
              <span>{t(`product_kind:${assignment.products[0].kind}`)}</span>
              <h4>{t('list_item_title', { count: assignment.products[0].quantity })}</h4>
            </div>

            {isCompleted
              ? <Check className={styles.checkIcon} />
              : (
                <Button
                  onClick={() => {
                    goNext()
                    initializeStaging(assignment.id, assignment.products[0])
                  }}
                >
                  {t('start_staging')}
                </Button>
              )
            }

          </div>
        )
      })}
    </div>
  )
}
