import React, { useMemo } from 'react'
import { getDateString, getTimeString, isSameTimezone } from 'utils/time'

import { ISODateString } from 'models/misc'
import Icon from '../Icon/Icon'
import { IconType } from 'constants/assets'
import { WarningAmber } from '@mui/icons-material'
import classnames from 'classnames'
import moment from 'moment-timezone'
import styles from './DeadlineDate.module.sass'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Deadline date */
  deadlineDate: ISODateString
  /** Timezone of assignment */
  deadlineTimezone: string | null
  /** Timezone of logged user */
  userTimezone: string
}

/**
 * @component Displays date and time frame of given deadline
 * @example
 * <DeadlineTime deadlineDate={new Date().toISOString()} deadlineTimezone="Europe/Berlin" userTimezone="Europe/Berlin" />
*/
export const DeadlineDate: React.FC<Props> = ({
  deadlineDate,
  deadlineTimezone,
  userTimezone,
}) => {
  const { t } = useTranslation(['deal_assignment', 'deal_assignment_card'])
  const timezonesMatch = useMemo(() => !!deadlineTimezone && !!isSameTimezone(deadlineTimezone, userTimezone), [deadlineTimezone, userTimezone])

  /** Validates if the deadline is near (2 hours) or expired, changing colors from 'orange' to 'red' if critical */
  const isDeadlineCritical = useMemo(() => {
    const deadline = deadlineTimezone ? moment(deadlineDate).tz(deadlineTimezone) : moment(deadlineDate)
    const criticalThreshold = moment().add(2, 'hours')

    if (deadline.isBefore(criticalThreshold)) return true
    return false
  }, [deadlineDate, deadlineTimezone])

  return (
    <div className={classnames(styles.deadlineDate, isDeadlineCritical ? styles.critical : styles.warning)}>

      <div className={styles.dateBlock}>

        <span className={styles.dateGroup}>

          <WarningAmber color={isDeadlineCritical ? 'error' : 'warning'} fontSize="large" />

          <span>
            <span className={styles.label}>{t('deal_assignment_card:deadline')}: </span>
            <span>
              {getDateString(deadlineDate, { timezone: userTimezone })}
            </span>
            <div className={styles.dateHour}>
              <Icon className={styles.small} icon={IconType.CLOCK} />
              <span>
                {getTimeString(deadlineDate, { timezone: userTimezone })}
              </span>
            </div>
          </span>

        </span>

        {/** Shows user timezone if timezones are not the same */}
        {!timezonesMatch &&
          <span className={styles.dateGroup}>
            <span className={styles.label}>{t('deal_assignment:timezone:user_timezone')}: {userTimezone}</span>
          </span>
        }

      </div>
      {/**
        This block is shown if the timezones are not the same displaying assignment timezone
       */}
      {(!timezonesMatch && !!deadlineTimezone) &&
        <div className={classnames(styles.dateBlock, styles.locationTimezone)}>

          <span className={styles.dateGroup}>

            <WarningAmber color={isDeadlineCritical ? 'error' : 'warning'} fontSize="large" />

            <span>
              <span className={styles.label}>{t('deal_assignment_card:deadline')}: </span>
              <span>
                {getDateString(deadlineDate, { timezone: deadlineTimezone })}
              </span>
              <div className={styles.dateHour}>
                <Icon className={styles.small} icon={IconType.CLOCK} />
                <span>
                  {getTimeString(deadlineDate, { timezone: deadlineTimezone })}
                </span>
              </div>
            </span>
          </span>

          {(!deadlineTimezone || !timezonesMatch) &&
            <span className={styles.dateGroup}>
              <span className={styles.label}>{t('deal_assignment:timezone:location_timezone')}: {deadlineTimezone}</span>
            </span>
          }

        </div>
      }
    </div >
  )
}
