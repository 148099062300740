import { GRAY_300, MOBILE_VIEW_QUERY } from 'constants/styling/theme'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import Box from '@mui/material/Box'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import { FC } from 'react'
import { MUIDivider } from 'components/common/MUIDivider'
import { PostProps } from '../SocialMedia.module'
import RepeatIcon from '@mui/icons-material/Repeat'
import SendIcon from '@mui/icons-material/Send'
import Stack from '@mui/material/Stack'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import styles from './LinkedInPost.module.sass'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'

/**
 * @component
 * LinkedInPost component that represents an LinkedIn-like post.
 * 
 * @example
 * <LinkedInPost
 *   textInput={<p>This is an awesome post!</p>}
 * />
 */
export const LinkedInPost: FC<PostProps> = ({ textInput }) => {

  const { t } = useTranslation(['visual_marketing_social_media'])

  const isMobile = useMediaQuery(MOBILE_VIEW_QUERY)

  return (
    <BorderBoxWrapper elevation={'lg'}>

      {/** HEADER */}
      <div className={styles.header}>

        <div className={styles.photo} />

        <Stack direction="column">
          <p>AwesomeRealEstate</p>
          <span>{t('followers')}</span>
          <span>{t('time')}</span>
        </Stack>

      </div>

      {/** GENERATED TEXT INPUT */}
      <div className={styles.postText}>
        {textInput}
      </div>

      {/** IMAGE PLACEHOLDER */}
      <Box sx={{
        width: '100%',
        height: 240,
        backgroundColor: GRAY_300
      }} />

      {/** FOOTER */}
      <div className={styles.footer}>

        {/** SOCIAL DETAILS */}
        <Stack direction="row" spacing={0.5}>

          <Stack direction="row" spacing={-0.5}>
            <Box sx={{ width: 16, height: 16, borderRadius: '50%', backgroundColor: 'lightblue' }} />
            <Box sx={{ width: 16, height: 16, borderRadius: '50%', backgroundColor: 'lightgreen' }} />
            <Box sx={{ width: 16, height: 16, borderRadius: '50%', backgroundColor: 'lightpink' }} />
          </Stack>

          <span>{t('reactions')}</span>

        </Stack>

        {/** REACTIONS BAR */}
        <MUIDivider margin={12} />

        <Stack direction="row" justifyContent="space-between" padding="0 2rem">

          <Stack spacing={0.5} direction="row" alignItems="center">
            <ThumbUpOffAltIcon fontSize='large' />
            {!isMobile && <span>{t('like')}</span>}
          </Stack>

          <Stack spacing={0.5} direction="row" alignItems="center">
            <ChatBubbleOutlineIcon fontSize='large' />
            {!isMobile && <span>{t('comment')}</span>}
          </Stack>

          <Stack spacing={0.5} direction="row" alignItems="center">
            <RepeatIcon fontSize='large' />
            {!isMobile && <span>{t('repost')}</span>}
          </Stack>

          <Stack spacing={0.5} direction="row" alignItems="center">
            <SendIcon fontSize='large' />
            {!isMobile && <span>{t('send')}</span>}
          </Stack>

        </Stack>

      </div>

    </BorderBoxWrapper>
  )
}
