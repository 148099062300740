import { AssignmentDTO, AssignmentDTOClient } from 'models/assignment'
import { AssignmentDTOIsAdministratorDTO, AssignmentDTOIsClientDTO } from 'utils/typeguards'
import { FC, ReactNode, createContext, useContext, useEffect, useMemo } from 'react'
import { ProductKind, ProductKindsWithDocumentGallery, ProductKindsWithDocumentGalleryWithoutMeasurementOnSite, ProductType, STAGING_PRODUCT_KINDS } from 'constants/product'
import { UseMutationResult, UseQueryResult } from '@tanstack/react-query'

import { APIRequestErrorType } from 'constants/API'
import { DealDTO } from 'models/deal'
import { Nullable } from 'models/helpers'
import axios from 'axios'
import moment from 'moment'
import { useAuth0 } from 'utils/auth'
import { useGalleryAssignment } from './GalleryAssignment.context'
import { useGetOrder } from 'dataQueries/order.query'
import { useGetOrderMessageThreadCount } from 'dataQueries/messageThreads'

interface GalleryDealInterface {
  order?: UseMutationResult<any, any, any, any> | UseQueryResult<any, any>,
  dealId: number
  dealData?: Nullable<DealDTO>
  dealAssignmentsForTab: AssignmentDTO[]
  stagingAssignments: AssignmentDTO[]
  containsKeysPickup: boolean,
  dealError?: APIRequestErrorType
  isOneStagingSelection: boolean,
  shootingStart: Nullable<string>
  earliestAssignment: Nullable<AssignmentDTOClient>
  requiresPowerOfAttorneyDocuments?: boolean
  orderIncludesDocumentProducts?: boolean
  orderIncludesDocumentProductWithoutMeasurementOnSiteProduct: boolean
  totalRequestCountData?: number
  refetchTotalRequestsCount: () => void
}

const defaultGalleryDealValue: GalleryDealInterface = {
  dealId: 0,
  dealAssignmentsForTab: [],
  stagingAssignments: [],
  containsKeysPickup: false,
  isOneStagingSelection: false,
  shootingStart: null,
  earliestAssignment: null,
  requiresPowerOfAttorneyDocuments: false,
  orderIncludesDocumentProducts: false,
  orderIncludesDocumentProductWithoutMeasurementOnSiteProduct: false,
  totalRequestCountData: undefined,
  refetchTotalRequestsCount: () => { },
}

/** Gallery deal context */
export const GalleryDealContext = createContext<GalleryDealInterface>(defaultGalleryDealValue)
/** Gallery deal context hook */
export const useGalleryDeal = (keepPreviousData = false): GalleryDealInterface => useContext(GalleryDealContext)

/** Context provider for gallery deal */
export const GalleryDealContextProvider: FC<{
  assignmentId: string
  children?: ReactNode
  keepPreviousData?: boolean
}> = ({
  children,
  keepPreviousData = false
}) => {
    const { roles } = useAuth0()
    const { assignmentData, assignment } = useGalleryAssignment()

    const dealId = useMemo(() => (assignmentData && (AssignmentDTOIsAdministratorDTO(assignmentData) || AssignmentDTOIsClientDTO(assignmentData))) ? assignmentData.dealId : -1, [assignmentData])
    const order = useGetOrder(dealId, dealId > 0 && (roles.isAdmin || roles.isClient), keepPreviousData)

    const dealData = useMemo(() => order.data?.data, [order.data?.data])
    const dealAssignmentsForTab = useMemo(() => dealData?.missions && dealData.missions.length ? dealData.missions.filter((assignment) => !assignment.isDeleted).sort((a, b) => a.id.toString().localeCompare(b.id)) : [], [dealData])
    const containsKeysPickup = useMemo(() => !!dealData && dealData?.financials.products.some(product => product.kind === ProductKind.KEYS_PICKUP), [dealData])

    const earliestAssignment = useMemo(() => dealData?.missions?.length ? dealData.missions.sort((a, b) => moment(a.shootingStartDateTime).diff(moment(b.shootingStartDateTime)))?.[0] : null, [dealData])

    const shootingStart = useMemo(() => earliestAssignment?.timezone
      && earliestAssignment?.shootingStartDateTime ? moment(earliestAssignment.shootingStartDateTime).tz(earliestAssignment.timezone).toISOString() : null, [earliestAssignment])

    /** List of assignments containing STAGING product with exception for STAGING_360 product type */
    const stagingAssignments = useMemo(
      () => dealAssignmentsForTab.filter(
        (assignment) => assignment.products.some(
          (product) => STAGING_PRODUCT_KINDS.has(product.kind) && product.type !== ProductType.STAGING_360
        )
      ),
      [dealAssignmentsForTab]
    )

    const isOneStagingSelection = useMemo(() => stagingAssignments.length === 1, [stagingAssignments])

    const dealError = useMemo(() => axios.isAxiosError(assignment.error) && assignment.error.response?.data.message, [assignment])

    /** Whether the deal has some assignments with at least one document product kind. */
    const orderIncludesDocumentProducts = useMemo(() => dealData?.missions.some((assignment) => assignment.products.some((product) => ProductKindsWithDocumentGallery.has(product.kind))), [dealData])

    /** Whether the deal has some assignment with measurement on site product kind. */
    const orderIncludesDocumentProductWithoutMeasurementOnSiteProduct = useMemo(() => dealData?.missions.some((assignment) => assignment.products.some((product) => ProductKindsWithDocumentGalleryWithoutMeasurementOnSite.has(product.kind))) ?? false, [dealData])

    /** Whether the deal has some assignment with authorities document product kind. */
    const orderIncludeAuthoritiesDocumentsProduct = useMemo(() => dealData?.missions.some((assignment) => assignment.products.some((product) => product.kind === ProductKind.AUTHORITIES_DOCUMENTS)), [dealData])

    /** Whether the deal requires power of attorney documents and haven't been submitted yet. */
    const requiresPowerOfAttorneyDocuments = useMemo(() =>
      orderIncludeAuthoritiesDocumentsProduct && // Only Authorities documents requires PoA
      !dealData?.powerOfAttorney.hasDocumentsSubmitted && (dealData?.powerOfAttorney.requiresPowerOfAttorney || dealData?.powerOfAttorney.requiresPowerOfAttorneyRepresentatives),
      [dealData, orderIncludeAuthoritiesDocumentsProduct]
    )

    // CLIENT
    const totalRequestsCount = useGetOrderMessageThreadCount()
    const totalRequestCountData = useMemo(() => totalRequestsCount.data?.data, [totalRequestsCount.data])
    const refetchTotalRequestsCount = () => totalRequestsCount.reset()

    useEffect(() => {
      if (dealId > 0 && totalRequestsCount.status === 'idle') {
        totalRequestsCount.mutate({ orderId: dealId })
      }
      // No need to add dealId as a dependency
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalRequestsCount])

    return (
      <GalleryDealContext.Provider
        value={{
          order,
          dealId,
          dealData,
          dealAssignmentsForTab,
          stagingAssignments,
          containsKeysPickup,
          dealError,
          isOneStagingSelection,
          shootingStart,
          earliestAssignment,
          requiresPowerOfAttorneyDocuments,
          orderIncludesDocumentProducts,
          orderIncludesDocumentProductWithoutMeasurementOnSiteProduct,
          totalRequestCountData,
          refetchTotalRequestsCount,
        }}
      >
        {children}
      </GalleryDealContext.Provider>
    )
  }
