import { ProductKind } from 'constants/product'
import constate from 'constate'
import { CatalogueProduct } from 'dataQueries/purchase.query'
import { useMemo } from 'react'
import { usePurchaseFlowConfig } from './PurchaseFlowConfig.context'

export const [AuthoritiesDocumentsProvider, useAuthoritiesDocuments] = constate(() => {
  const { extraProducts } = usePurchaseFlowConfig()

  const searchProduct = useMemo<CatalogueProduct | undefined>(() => {
    return extraProducts[ProductKind.EXTRA_LAND_REGISTRATION_SEARCH]?.[0]
  }, [extraProducts])

  return {
    searchProduct
  }
})
