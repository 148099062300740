import './Signup.sass'

import { Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { IconType, countryToFlag } from 'constants/assets'
import { getUserTimezone, timezoneNames } from 'utils/time'
import { useDispatch, useSelector } from 'react-redux'

import { APIRequestState } from 'constants/API'
import { ActionTypeAPIData } from 'constants/redux'
import Button from 'components/common/Button/Button'
import { Country } from 'constants/country'
import Dropdown from 'components/common/Dropdown/Dropdown'
import FetchedContent from 'components/common/FetchedContent/FetchedContent'
import Icon from 'components/common/Icon/Icon'
import { Language } from 'translations/Language'
import { PageTransition } from 'utils/animations'
import { QueryStatus } from 'components/common/QueryStatus'
import { RequestStatus } from 'components/common/RequestStatus'
import { RootStore } from 'models/redux'
import { SignupHowDidYouHearAnswers } from 'constants/signupQuestions'
import TelephoneInput from 'components/common/TelephoneInput/TelephoneInput'
import { changeLanguage } from 'redux/Individual/User/ChangeLocale'
import i18n from 'translations/i18n'
import { logAnalyticsEvent } from 'utils/analytics'
import { useAuth0 } from 'utils/auth'
import { useCreateUser } from 'dataQueries'
import { useTranslation } from 'react-i18next'
import { userMeAlternative } from 'redux/Individual/User/UserMeAlternative'

enum Organization {
  isOrganizationCorrect = 'isOrganizationCorrect',
  hasOrganization = 'hasOrganization',
  noOrganization = 'noOrganization',
}

const Signup: React.FC = (props: any) => {
  const dispatch = useDispatch()
  const { refreshUser } = useAuth0()
  const { t } = useTranslation(['signup', 'country', 'language', 'common'])

  const userMe = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.USER_ME])
  const userMeAlternativeRequest = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.USER_ME_ALTERNATIVE])
  const userMetaRequest = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.USER_META])

  const i18nLang = useMemo(() => i18n.language.toUpperCase().replace(/-.*/igm, ''), [])

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [country, setCountry] = useState<Country>()
  const [phone, setPhone] = useState('')
  const [isPhoneValid, setIsPhoneValid] = useState(false)
  const [mobilePhone, setMobilePhone] = useState('')
  const [isMobilePhoneValid, setIsMobilePhoneValid] = useState(false)
  const [timezone, setTimezone] = useState(getUserTimezone)
  const [language, setLanguage] = useState<Language>(Language[i18nLang as Language] ? Language[i18nLang as Language] : Language.EN)
  const [organizationRadio, setOrganizationRadio] = useState<Organization>()
  const [organizationName, setOrganizationName] = useState('')
  const [organizationAddress, setOrganizationAddress] = useState('')
  const [VATNumber, setVATNumber] = useState('')
  const [howDidYouHear, setHowDidYouHear] = useState('')

  const isPrefilledOrganizationGrayed = useMemo(() => organizationRadio === Organization.hasOrganization || organizationRadio === Organization.noOrganization, [organizationRadio])
  const isContactValid = useMemo(() => firstName && lastName && country, [firstName, lastName, country])
  const isOrganizationValid = useMemo(() => organizationRadio !== Organization.hasOrganization || (organizationName && organizationAddress), [organizationRadio, organizationName, organizationAddress])
  const isPhonesValid = useMemo(() => {
    if (organizationRadio === Organization.noOrganization) {
      return !!(mobilePhone && isMobilePhoneValid)
    } else {
      return !!((phone || mobilePhone) && (isPhoneValid || isMobilePhoneValid))
    }
  }, [phone, isPhoneValid, mobilePhone, isMobilePhoneValid, organizationRadio])
  const createUser = useCreateUser()
  const isCreateUserRunning = useMemo(() => createUser.isPending, [createUser.isPending])
  const isCreateUserProcessed = useMemo(() => !(createUser.isIdle), [createUser.isIdle])
  const isDisabled = useMemo(() => !isContactValid || !isOrganizationValid || !isPhonesValid || isCreateUserProcessed || !howDidYouHear, [isContactValid, isOrganizationValid, isPhonesValid, isCreateUserProcessed, howDidYouHear])

  const SelectedCountryFlag = useMemo(() => country ? countryToFlag[country] : null, [country])

  const hasMetaOrganization = useMemo(() => !!(userMetaRequest.data?.hasOrganization && userMetaRequest.data.organization && userMetaRequest.data.organization.name && userMetaRequest.data.organization.billingAddress), [userMetaRequest])

  const maxVATNumberCharacters = 50
  const isMaxVATNumberCharacters = useMemo(() => VATNumber.length === maxVATNumberCharacters, [VATNumber])

  // log entering this page
  useEffect(() => {
    logAnalyticsEvent('enters_user_signup_details_screen', {})
  }, [])

  /** React to userMe request and set default timezone */
  useEffect(() => {
    if (userMe.data) setTimezone(getUserTimezone(userMe.data))
  }, [userMe])

  /** dispatch create user action */
  const dispatchCreateUser = useCallback(() => {
    if (!firstName || !lastName) return
    if (!country) return
    if (!language) return
    if (!timezone) return
    if (organizationRadio === Organization.noOrganization) {
      if (!mobilePhone) return
      if (!isMobilePhoneValid) return
    } else {
      if (!phone && !mobilePhone) return
      if (!isPhoneValid && !isMobilePhoneValid) return
    }
    if (organizationRadio === Organization.hasOrganization && !organizationName) return
    if (organizationRadio === Organization.hasOrganization && !organizationAddress) return

    const onboardingAnswerKey = Object.keys(SignupHowDidYouHearAnswers).find(
      (key) => SignupHowDidYouHearAnswers[key as keyof typeof SignupHowDidYouHearAnswers] === howDidYouHear
    ) as keyof typeof SignupHowDidYouHearAnswers | undefined

    createUser.mutate({
      name: `${firstName} ${lastName}`,
      firstName,
      lastName,
      country,
      phone,
      mobilePhone,
      locale: {
        language,
        timezone,
      },
      hasOrganization: !!organizationRadio && [Organization.isOrganizationCorrect, Organization.hasOrganization].includes(organizationRadio),
      organization: organizationRadio === Organization.hasOrganization ? {
        name: organizationName,
        billingAddress: organizationAddress,
        hasB2CInvoicing: false,
      } : null,
      isOrganizationCorrect: organizationRadio === Organization.isOrganizationCorrect,
      vatNumber: VATNumber ? VATNumber : undefined,
      onboardingAnswer: onboardingAnswerKey ? onboardingAnswerKey : undefined,
      refreshUserCallback: refreshUser
    })
  }, [firstName, lastName, country, language, timezone, organizationRadio, organizationName, organizationAddress, createUser, phone, mobilePhone, VATNumber, refreshUser, isMobilePhoneValid, isPhoneValid, howDidYouHear])

  // Cleanup create user request
  useLayoutEffect(() => {
    createUser.reset()
    return () => {
      createUser.reset()
    }
    // No dependencies needed to avoid loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Adjust the radio button to metadata
  useLayoutEffect(() => {
    if (!userMetaRequest.data) return
    if (userMetaRequest.state !== APIRequestState.OK) return
    if (hasMetaOrganization) {
      setOrganizationRadio(Organization.isOrganizationCorrect)
    } else {
      if (userMetaRequest.data.organization?.name) setOrganizationName(userMetaRequest.data.organization.name)
      if (userMetaRequest.data.organization?.billingAddress) setOrganizationAddress(userMetaRequest.data.organization.billingAddress)
    }
    const { country, name } = userMetaRequest.data
    const fullName = name.split(' ')
    if (name && name[0]) setFirstName(fullName[0])
    if (name && name[1]) setLastName(fullName.slice(1).join(' '))
    if (country && Country[country as Country]) setCountry(country as Country)
  }, [userMetaRequest, hasMetaOrganization])

  // start long polling for user me alternative
  useEffect(() => {
    window.setTimeout(() => {
      dispatch(userMeAlternative())
    }, 2000)
  }, [dispatch])

  // handle user me alternative
  useLayoutEffect(() => {
    if (userMeAlternativeRequest.state === APIRequestState.OK) {
      window.location.reload()
    } else if (userMeAlternativeRequest.state === APIRequestState.ERROR) {
      window.setTimeout(() => {
        dispatch(userMeAlternative())
      }, 2000)
    }
  }, [dispatch, userMeAlternativeRequest])

  return (
    <PageTransition>
      <div className="page triangle-stripe-bg signup">
        <div className="page-content">
          <div className="wrap">
            <div className="content">
              <section>
                <h1>{t('title')}</h1>
                <p>{t('text')}</p>
              </section>
              <section>
                <h2 className="decorated">{t('contact_details')}</h2>
                <div className="input-section align-start">
                  <div className="input-group">
                    <label htmlFor="firstName">{t('common:first_name')}</label>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      placeholder={t('common:first_name')}
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                      className={!firstName ? 'error-input' : ''}
                    />
                    {!firstName && <span className="error-message">{t('name_required')}</span>}
                  </div>
                  <div className="input-group">
                    <label htmlFor="lastName">{t('common:last_name')}</label>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      placeholder={t('common:last_name')}
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                      className={!lastName ? 'error-input' : ''}
                    />
                    {!lastName && <span className="error-message">{t('last_name_required')}</span>}
                  </div>
                  <div className="input-group">
                    <label htmlFor="language">{t('language')}</label>
                    <Dropdown
                      className="flag-dropdown"
                      button={(isOpen, action) => (
                        <Button
                          className='flag-button'
                          type="secondary nobackground"
                          borderStyle='sharp'
                          onClick={action}
                          textAndIcon={true}
                        >
                          <span>{(t(`language:${(language || '').toUpperCase()}`) || '').toString()}</span>
                          <Icon icon={IconType.CARET_DOWN} className={`caret ${isOpen ? 'up' : 'down'}`} />
                        </Button>
                      )}>
                      <Fragment>
                        {Object.values(Language).map(lang => {
                          const selected = lang === language
                          return (
                            <Button
                              id={lang}
                              type="secondary nobackground"
                              borderStyle='sharp'
                              className={selected ? 'selected' : ''}
                              onClick={() => {
                                setLanguage(lang)
                                dispatch(changeLanguage(lang))
                              }}
                            >
                              {t(`language:${lang.toUpperCase()}`, lang)}
                            </Button>
                          )
                        })}
                      </Fragment>
                    </Dropdown>
                    {!language &&
                      <span className="error-message">{t('language_required')}</span>
                    }
                  </div>
                  <div className="input-group">
                    <label htmlFor="country">{t('country')}</label>
                    <Dropdown
                      className="flag-dropdown"
                      attachment="right-attached"
                      button={(isOpen, action) => (
                        <Button
                          className={`${!country ? 'red-border' : ''} flag-button`}
                          type="secondary nobackground"
                          borderStyle='sharp'
                          onClick={action}
                          textAndIcon={true}
                        >
                          {!!SelectedCountryFlag &&
                            <SelectedCountryFlag className="flag" />
                          }
                          {!!country ?
                            <span>{t(`country:${country}`)}</span>
                            :
                            <span>{t('select_country')}</span>
                          }
                          <Icon icon={IconType.CARET_DOWN} className={`caret ${isOpen ? 'up' : 'down'}`} />
                        </Button>
                      )}
                    >
                      <Fragment>
                        {Object.values(Country).map(countryKey => {
                          const Flag = countryToFlag[countryKey]
                          const selected = countryKey === country
                          return (
                            <Button
                              id={countryKey}
                              type="secondary nobackground"
                              borderStyle='sharp'
                              className={selected ? 'selected' : ''}
                              onClick={() => {
                                setCountry(countryKey)
                              }}
                            >
                              <Flag className="flag" />
                              {t(`country:${countryKey}`, countryKey)}
                            </Button>
                          )
                        })}
                      </Fragment>
                      <Button
                        id='different-country'
                        type="secondary nobackground"
                        borderStyle='sharp'
                        disabled
                      >
                        {t('different_country')}
                      </Button>
                    </Dropdown>
                    {!country &&
                      <span className="error-message">{t('country_required')}</span>
                    }
                  </div>
                  <div className="input-group">
                    <label htmlFor="timezone">{t('timezone')}</label>
                    <select
                      name="timezone"
                      id="timezone"
                      value={timezone}
                      onChange={e => setTimezone(e.target.value)}
                    >
                      {timezoneNames.map(tzn => (
                        <option value={tzn} key={tzn}>{tzn}</option>
                      ))}
                    </select>
                    {!timezone &&
                      <span className="error-message">{t('timezone_required')}</span>
                    }
                  </div>
                </div>
              </section>

              <FetchedContent
                request={userMetaRequest}
                error={<RequestStatus request={userMetaRequest} errorMessage={userMetaRequest.error_type} />}
              >
                <Fragment>
                  <section>
                    <h2 className="decorated">{t('organization_details')}</h2>
                    {hasMetaOrganization ?
                      <Fragment>
                        <p>{t('part_of_organization')}</p>
                        <div className="input-section align-start margin-bottom-large">
                          <div className="input-group">
                            <label htmlFor="prefilledOrganizationName">{t('organization_name')}</label>
                            <input
                              type="text"
                              name="prefilledOrganizationName"
                              id="prefilledOrganizationName"
                              placeholder={t('organization_name')}
                              value={userMetaRequest.data?.organization?.name || ''}
                              disabled
                              className={isPrefilledOrganizationGrayed ? 'grayed' : ''}
                            />
                          </div>
                          <div className="input-group">
                            <label htmlFor="prefilledOrganizationAddress">{t('organization_address')}</label>
                            <input
                              type="text"
                              name="prefilledOrganizationAddress"
                              id="prefilledOrganizationAddress"
                              placeholder={t('organization_address')}
                              value={userMetaRequest.data?.organization?.billingAddress || ''}
                              disabled
                              className={isPrefilledOrganizationGrayed ? 'grayed' : ''}
                            />
                          </div>
                        </div>
                        <div className="input-section align-start margin-bottom">
                          <div className="input-group">
                            <label className="checkbox" htmlFor="isOrganizationCorrect">
                              <input
                                type="radio"
                                name="organizationRadio"
                                id="isOrganizationCorrect"
                                value={Organization.isOrganizationCorrect}
                                checked={organizationRadio === Organization.isOrganizationCorrect}
                                onChange={e => setOrganizationRadio(e.target.value as Organization)}
                              />
                              <span className="checkmark"></span>
                              <span className="label-after">{t('found_organization_correct')}</span>
                            </label>
                          </div>
                          <div className="input-group">
                            <label className="checkbox" htmlFor="hasOrganization">
                              <input
                                type="radio"
                                name="organizationRadio"
                                id="hasOrganization"
                                value={Organization.hasOrganization}
                                checked={organizationRadio === Organization.hasOrganization}
                                onChange={e => setOrganizationRadio(e.target.value as Organization)}
                              />
                              <span className="checkmark"></span>
                              <span className="label-after">{t('found_organization_has_organization')}</span>
                            </label>
                          </div>
                          <div className="input-group">
                            <label className="checkbox" htmlFor="noOrganization">
                              <input
                                type="radio"
                                name="organizationRadio"
                                id="noOrganization"
                                value={Organization.noOrganization}
                                checked={organizationRadio === Organization.noOrganization}
                                onChange={e => setOrganizationRadio(e.target.value as Organization)}
                              />
                              <span className="checkmark"></span>
                              <span className="label-after">{t('found_organization_no_organization')}</span>
                            </label>
                          </div>
                        </div>
                      </Fragment>
                      :
                      <Fragment>
                        <div className="input-section align-start">
                          <div className="input-group">
                            <label className="checkbox" htmlFor="hasOrganization">
                              <input
                                type="radio"
                                name="organizationRadio"
                                id="hasOrganization"
                                value={Organization.hasOrganization}
                                checked={organizationRadio === Organization.hasOrganization}
                                onChange={e => setOrganizationRadio(e.target.value as Organization)}
                              />
                              <span className="checkmark"></span>
                              <span className="label-after">{t('not_found_organization_has_organization')}</span>
                            </label>
                          </div>
                          <div className="input-group">
                            <label className="checkbox" htmlFor="noOrganization">
                              <input
                                type="radio"
                                name="organizationRadio"
                                id="noOrganization"
                                value={Organization.noOrganization}
                                checked={organizationRadio === Organization.noOrganization}
                                onChange={e => setOrganizationRadio(e.target.value as Organization)}
                              />
                              <span className="checkmark"></span>
                              <span className="label-after">{t('not_found_organization_no_organization')}</span>
                            </label>
                          </div>
                        </div>
                        {!organizationRadio &&
                          <span className="error-message">{t('company_details_required')}</span>
                        }
                      </Fragment>
                    }
                    {organizationRadio === Organization.hasOrganization &&
                      <div className="input-section align-start margin-top-large">
                        <div className="input-group">
                          <label htmlFor="organizationName">{t('organization_name')}</label>
                          <input
                            type="text"
                            name="organizationName"
                            id="organizationName"
                            placeholder={t('organization_name')}
                            value={organizationName}
                            onChange={e => setOrganizationName(e.target.value)}
                            className={!organizationName ? 'error-input' : ''}
                          />
                          {!organizationName &&
                            <span className="error-message">{t('organization_name_required')}</span>
                          }
                        </div>
                        <div className="input-group">
                          <label htmlFor="organizationAddress">{t('organization_address')}</label>
                          <input
                            type="text"
                            name="organizationAddress"
                            id="organizationAddress"
                            placeholder={t('organization_address')}
                            value={organizationAddress}
                            onChange={e => setOrganizationAddress(e.target.value)}
                            className={!organizationAddress ? 'error-input' : ''}
                          />
                          {!organizationAddress &&
                            <span className="error-message">{t('organization_address_required')}</span>
                          }
                        </div>
                      </div>
                    }
                    {!!(organizationRadio === Organization.hasOrganization || organizationRadio === Organization.isOrganizationCorrect) &&
                      <div className="input-section align-start margin-top-large">
                        <div className="input-group">
                          <label htmlFor="vatNumber">{t('vat_number')}</label>
                          <input
                            type="text"
                            name="vatNumber"
                            id="vatNumber"
                            placeholder={t('vat_number')}
                            value={VATNumber}
                            onChange={e => setVATNumber(e.target.value.replace(/(\r\n|\n|\r)/gm, '').slice(0, maxVATNumberCharacters))}
                          />
                          {!!isMaxVATNumberCharacters &&
                            <span className="error-message">{t('vat_number_characters_length_message', { count: maxVATNumberCharacters })}</span>
                          }
                        </div>
                        <div className="input-group">
                          <label htmlFor="phone">{t('business_phone')}</label>
                          <TelephoneInput
                            className="phone-input"
                            value={phone}
                            initialCountry={!phone ? country || 'auto' : undefined}
                            onValueChange={(formattedValue, validation) => {
                              setPhone(formattedValue)
                              setIsPhoneValid(validation.isValid)
                            }}
                            withInvalidErrorMessages={!isMobilePhoneValid}
                            withInvalidIndicator={!isMobilePhoneValid}
                          />
                        </div>
                      </div>
                    }
                    {organizationRadio === Organization.noOrganization &&
                      <div className="input-section align-start margin-top-large margin-bottom">
                        <div className="input-group">
                          <label htmlFor="phone">{t('mobile_phone')}</label>
                          <TelephoneInput
                            className="phone-input"
                            value={mobilePhone}
                            initialCountry={!mobilePhone ? country || 'auto' : undefined}
                            onValueChange={(formattedValue, validation) => {
                              setMobilePhone(formattedValue)
                              setIsMobilePhoneValid(validation.isValid)
                            }}
                            withInvalidErrorMessages={organizationRadio === Organization.noOrganization || !isPhoneValid}
                            withInvalidIndicator={organizationRadio === Organization.noOrganization || !isPhoneValid}
                          />
                        </div>
                      </div>
                    }
                  </section>

                  <section>
                    <h2 className="decorated">{t('how_did_you_hear_about_us.section_title')}</h2>
                    <div className="input-section align-start margin-bottom-large">
                      <div className="input-group">
                        <Dropdown
                          className="how-did-you-hear-dropdown"
                          attachment="right-attached"
                          button={(isOpen, action) => (
                            <Button
                              className={`${!howDidYouHear ? 'red-border' : ''} how-did-you-hear-button`}
                              type="secondary nobackground"
                              borderStyle='sharp'
                              onClick={action}
                              textAndIcon={true}
                            >
                              <span>{!!howDidYouHear ? t(`how_did_you_hear_about_us.answers.${howDidYouHear}`) : t('how_did_you_hear_about_us.please_select')}</span>

                              <Icon icon={IconType.CARET_DOWN} className={`caret ${isOpen ? 'up' : 'down'}`} />
                            </Button>
                          )}
                        >
                          {Object.values(SignupHowDidYouHearAnswers).map(answer => {
                            const selected = answer === howDidYouHear
                            return (
                              <Button
                                id={answer}
                                type="secondary nobackground"
                                borderStyle='sharp'
                                className={selected ? 'selected' : ''}
                                onClick={() => {
                                  setHowDidYouHear(answer)
                                }}
                              >
                                {t(`how_did_you_hear_about_us.answers.${answer}`)}
                              </Button>
                            )
                          })}
                        </Dropdown>
                        {!howDidYouHear &&
                          <span className="error-message">{t('how_did_you_hear_about_us.answer_required')}</span>
                        }
                      </div>
                    </div>
                  </section>

                  <section>
                    <div className="input-group">
                      <Button
                        id='verification'
                        type="primary"
                        borderStyle='rounded'
                        disabled={isDisabled}
                        onClick={e => dispatchCreateUser()}
                      >
                        {t('proceed')}
                      </Button>
                      {isDisabled &&
                        <span className="button-message">
                          {(() => {
                            if (!firstName) return t('name_required')
                            if (!lastName) return t('last_name_required')
                            if (!country) return t('country_required')
                            if (!language) return t('language_required')
                            if (!timezone) return t('timezone_required')
                            if (!organizationRadio) return t('company_details_required')
                            if (organizationRadio === Organization.noOrganization) {
                              if (!mobilePhone) return t('phone_required')
                              if (!isMobilePhoneValid) return t('phone_invalid')
                            } else {
                              if (!phone && !mobilePhone) return t('phone_required')
                              if (!isPhoneValid && !isMobilePhoneValid) return t('phone_invalid')
                            }
                            if (organizationRadio === Organization.hasOrganization && !organizationName) return t('organization_name_required')
                            if (organizationRadio === Organization.hasOrganization && !organizationAddress) return t('organization_address_required')
                            if (!howDidYouHear) return t('how_did_you_hear_about_us_required')
                            if (isCreateUserRunning) return t('processing_request')
                            if (isCreateUserProcessed) return t('request_processed')
                          })()}
                        </span>
                      }
                      <QueryStatus
                        query={createUser}
                        spaceTopRem={2}
                      />
                    </div>
                  </section>
                </Fragment>
              </FetchedContent>
            </div>
          </div>
        </div>
      </div>
    </PageTransition>
  )
}

export default Signup
