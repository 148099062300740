import { Box, Stack, paperClasses } from '@mui/material'
import Popover, { PopoverProps } from '@mui/material/Popover'
import React, { Dispatch, ReactNode, SetStateAction } from 'react'

import { BOX_SHADOW_LG } from 'constants/styling/theme'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { MUIButton } from 'components/common/MUIButton'
import { MUITooltip } from 'components/common/MUITooltip'
import { styled } from '@mui/material/styles'

const StyledPopover = styled((props: PopoverProps) => (
  <Popover
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))(() => ({
  [`& .${paperClasses.root}`]: {
    borderRadius: '.8rem',
    marginTop: '.4rem',
    minWidth: '20rem',
    boxShadow: BOX_SHADOW_LG,
  },
}))

/**
 * @interface Props Input properties
 */
interface Props {
  /** Icon in the start place of the dropdown button */
  startIcon: ReactNode
  /** Icon in the end place of the dropdown button */
  endIcon?: ReactNode
  /** Whether the dropdown button is disabled or not */
  disabled?: boolean
  /** Actions that being triggerd when button disabled */
  disabledActions?: () => void
  /** Title of button tooltip */
  tooltipTitle?: string
  /** Button label of dropdown */
  buttonLabel: string
  /** Dropdown id */
  dropdownId: string
  /** Anchor of dropdown */
  anchorEl?: HTMLButtonElement | null
  /** Set dropdown anchor */
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>
  /** OnClick action */
  onClick: () => void
  children: ReactNode
  displaySecondaryButton?: boolean
}

/**
 * @component Setting Dropdown component
 * @example 
 * <SettingDropdown startIcon={<Icon />} buttonLabel="Tool" dropdownId="888" >
 *  <Box>dropdown content</Box>
 * </SettingDropdown>
 */
export const SettingDropdown: React.FC<Props> = ({
  startIcon,
  endIcon,
  disabled = false,
  disabledActions,
  tooltipTitle,
  buttonLabel,
  dropdownId,
  anchorEl,
  setAnchorEl,
  onClick,
  children,
  displaySecondaryButton = true,
}) => {
  const open = Boolean(anchorEl)

  return (
    <Stack flexDirection="row" justifyContent="space-between" alignItems="center" gap=".4rem">
      <MUITooltip
        content={tooltipTitle}
      >
        <Box onClick={disabledActions}>
          <MUIButton
            type='secondaryBorder'
            size='sm'
            variant='outlined'
            startIcon={startIcon}
            endIcon={endIcon}
            disabled={disabled}
            onClick={onClick}
          >
            {buttonLabel}
          </MUIButton>
        </Box>
      </MUITooltip>
      {displaySecondaryButton && <MUIButton
        type='secondaryNoBorder'
        size='sm'
        isIconButton
        startIcon={<ExpandMoreRoundedIcon fontSize='large' />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />}
      <StyledPopover
        id={dropdownId}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {children}
      </StyledPopover>
    </Stack>
  )
}
