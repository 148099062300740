import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { FloorPlanRenderType, FloorPlanSelectionType, useFloorPlanConfig } from '../_main/FloorPlanConfigStep.context'
import React, { useCallback } from 'react'
import { usePurchaseFlowConfig, usePurchaseFlowProducts, useTargetOrderUser } from 'components/pages/PurchaseFlow/_main/contexts'

import { Box } from '@mui/material'
import { CONST_PRODUCT_SERIAL } from 'constants/product'
import { Color } from 'constants/assets'
import { FloorPlanColors } from '../FloorPlanPreview/basicFloorPlanConstants'
import { FloorPlanConfigOptions } from '../FloorPlanPreview/floorPlanOptions'
import { FloorPlanFormat } from '../FloorPlanLayout'
import { Language } from 'translations/Language'
import ReactLoading from 'react-loading'
import { SelectableImageBox } from '../SelectableImageBox'
import Stack from '@mui/material/Stack'
import { Styles } from '../FloorPlanPreview/floorPlan3DConstants'
import { uploadPurchaseSessionVisual } from 'redux/Individual/Visual/LoadPurchaseSessionVisual'
import { useDispatch } from 'react-redux'
import { useFloorPlanLogo } from '../_main/FloorPlanLogo.context'
import { useFloorPlanTemplates } from '../_main'

/**
 * @component
 * FloorPlanTemplates displays user available templates
 * 
 * @example
 * <FloorPlanTemplates />
 */
export const FloorPlanTemplates: React.FC = () => {

  const dispatch = useDispatch()
  const { targetUser, adminSelectedUserEmail } = useTargetOrderUser()
  const { sessionId, selectedCategory } = usePurchaseFlowConfig()
  const { isTemplateLogoUploading } = useFloorPlanLogo()
  const { floorPlanProduct } = usePurchaseFlowProducts()
  const {
    selectedTemplateId,
    selectedColor,
    selectedStyle,
    showCompass,
    showDimensionChain,
    showEachRoomDimensions,
    showFixtures,
    showFurniture,
    showRoomArea,
    showRoomNames,
    showScalebar,
    floorPlanFormat,
    outputLanguage,
    floorPlanTemplateType,
    showDisclaimer,
    disclaimerText,
    setOutputLanguage,
    setFloorPlanFormat,
    setCompanyLogo,
    setIsActiveLogo,
    setSelectedTemplateId,
    setSelectedColor,
    setSelectedStyle,
    setCurrentFloorPlanRenderType,
    setShowCompass,
    setShowDimensionChain,
    setShowEachRoomDimensions,
    setShowFixtures,
    setShowFurniture,
    setShowRoomArea,
    setShowRoomNames,
    setShowScalebar,
    setFloorPlanTemplateType,
    setIsActiveDimensions,
    setShowDisclaimer,
    setDisclaimerText,
  } = useFloorPlanConfig()

  const { availableTemplates, floorPlanTemplates } = useFloorPlanTemplates()

  if (!sessionId) throw new Error('Missing sessionId')

  const handleTemplateLogoUpload = useCallback((logoFile: File) => {
    if (!sessionId || !floorPlanProduct) return

    dispatch(uploadPurchaseSessionVisual(sessionId, floorPlanProduct.id, CONST_PRODUCT_SERIAL, logoFile, adminSelectedUserEmail))
  }, [adminSelectedUserEmail, dispatch, floorPlanProduct, sessionId])

  const applyTemplateConfig = useCallback((config: FloorPlanConfigOptions, renderType: FloorPlanRenderType) => {
    if (config.showCompass !== showCompass) setShowCompass(config.showCompass ?? false)
    if (config.showDimensionChain !== showDimensionChain) setShowDimensionChain(config.showDimensionChain ?? false)
    if (config.showEachRoomDimensions !== showEachRoomDimensions) setShowEachRoomDimensions(config.showEachRoomDimensions ?? false)
    if (config.showFixtures !== showFixtures) setShowFixtures(config.showFixtures ?? false)
    if (config.showFurniture !== showFurniture) setShowFurniture(config.showFurniture ?? false)
    if (config.showRoomArea !== showRoomArea) setShowRoomArea(config.showRoomArea ?? false)
    if (config.showRoomNames !== showRoomNames) setShowRoomNames(config.showRoomNames ?? false)
    if (config.showScalebar !== showScalebar) setShowScalebar(config.showScalebar ?? false)
    if (config.outputLanguage !== outputLanguage) setOutputLanguage(Language[(config.outputLanguage as Language)] ?? Language[(targetUser.language as Language)] ?? Language.EN)
    if (config.floorPlanFormat !== floorPlanFormat) setFloorPlanFormat(config.floorPlanFormat ?? { [FloorPlanFormat.JPG]: FloorPlanFormat.JPG })
    if (config.showDisclaimer !== showDisclaimer) setShowDisclaimer(config.showDisclaimer ?? false)
    if (config.disclaimerText !== disclaimerText) setDisclaimerText(config.disclaimerText ?? '')

    if (config.companyLogo && config.templateLogoFile) {
      setIsActiveLogo(true)
      setCompanyLogo(config.templateLogoFile.name)
      handleTemplateLogoUpload(config.templateLogoFile)
    } else {
      setIsActiveLogo(false)
      setCompanyLogo(null)
    }

    if (renderType === FloorPlanRenderType.RENDER_2D) {
      if (config.style !== selectedColor) setSelectedColor(config.style as FloorPlanColors)
    } else {
      if (config.style !== selectedStyle) setSelectedStyle(config.style as Styles)
    }

    if (config.showDimensionChain || config.showRoomArea || config.showEachRoomDimensions) {
      setIsActiveDimensions(true)
    } else {
      setIsActiveDimensions(false)
    }

  }, [disclaimerText, floorPlanFormat, handleTemplateLogoUpload, outputLanguage, selectedColor, selectedStyle, setCompanyLogo, setDisclaimerText, setFloorPlanFormat, setIsActiveDimensions, setIsActiveLogo, setOutputLanguage, setSelectedColor, setSelectedStyle, setShowCompass, setShowDimensionChain, setShowDisclaimer, setShowEachRoomDimensions, setShowFixtures, setShowFurniture, setShowRoomArea, setShowRoomNames, setShowScalebar, showCompass, showDimensionChain, showDisclaimer, showEachRoomDimensions, showFixtures, showFurniture, showRoomArea, showRoomNames, showScalebar, targetUser.language])

  const handleSelectTemplate = (templateId: string, config: FloorPlanConfigOptions, renderType: FloorPlanRenderType) => {
    setSelectedTemplateId(templateId)
    setCurrentFloorPlanRenderType(renderType)
    // TODO: Add 3D defaulting when implemented
    applyTemplateConfig(
      {
        ...config,
        style: renderType === FloorPlanRenderType.RENDER_2D && !config.style ? FloorPlanColors.BLACK : config.style
      },
      renderType
    )

    if (floorPlanTemplateType !== FloorPlanSelectionType.TEMPLATE) {
      setFloorPlanTemplateType(FloorPlanSelectionType.TEMPLATE)
    }

    logAnalyticsEvent(AnalyticsEvent.FLOOR_PLAN_TEMPLATE_SELECTED, {
      templateId,
      type: renderType,
      category: selectedCategory,
    })
  }

  return (
    <Stack marginTop={2} direction="row" justifyContent="center" alignItems="center" gap={2} sx={{ flexFlow: 'row wrap' }} minWidth="40rem">
      {Object.values(availableTemplates).map(({ id, name, imagePlaceholder, renderType, config }) =>
        <SelectableImageBox
          key={id}
          text={name}
          width={16.8}
          height={14.4}
          disabled={isTemplateLogoUploading}
          elevation='xs'
          image={(floorPlanTemplates?.isFetching) ? <Box marginTop={6}><ReactLoading color={Color.GRAY_TEXT} type="spin" width="2rem" height="2rem" /></Box> : imagePlaceholder}
          inactiveBorderColor='transparent'
          isSelected={id === selectedTemplateId}
          onSelect={() => !isTemplateLogoUploading && handleSelectTemplate(id, config, renderType)}
        />
      )}
    </Stack>
  )
}
