import { Nullable } from 'models/helpers'
import { GoogleAPIPlace } from 'models/purchaseFlow'
import { CARD_EXPIRATION_REGEX } from 'utils/helpers'
import { isAddressValid } from 'utils/validators'
import validator from 'validator'
import { getDefaultErrorMessage } from './getDefaultErrorMessage'

// TODO: continually add needed validators 

/**
 * Extended validator function.
 * Validator returns either error message string or undefined.
 * 
 * Validation logic is handled by *validator* lib
 * 
 * Basic Validator function definition: 
 * @param value - value to validate
 * @param customErrorMessage - message to be used instead of default one
 * @example
 * 
 * // Invalid
 * isEmail('notanemail') => 'default error string'
 * isEmail('notanemail', 'custom error') => 'custom error'
 * 
 * // Valid
 * isEmail('correct@email.com', 'custom error') => undefined 
 */
type ValidatorFunction<T = unknown> = (params: T) => string | undefined

/** {@link ValidatorFunction} */
type BasicValidator<T = string> = ValidatorFunction<{ value: T, customErrorMessage?: string }>

/**
 * Validator checking if value is email.
 * {@link ValidatorFunction}
 */
export const isEmail: BasicValidator = ({ value, customErrorMessage }) => {
  if (!validator.isEmail(value)) return customErrorMessage ?? getDefaultErrorMessage('isEmail')
  return undefined
}

/**
 * Validator checking if value is positive number.
 * If value is nullish, returns OK
 * {@link ValidatorFunction}
 */
export const isPositiveNumber: BasicValidator<Nullable<number>> = ({ value, customErrorMessage }) => {
  if (!value) return undefined
  if (value < 0) return customErrorMessage ?? getDefaultErrorMessage('isPositiveNumber')
  return undefined
}

/**
 * Validator checking if value is a phone number.
 * If empty, returns OK
 * {@link ValidatorFunction}
 */
export const isPhoneNumber: BasicValidator<string> = ({ value, customErrorMessage }) => {
  if (!value) return undefined
  if (!validator.isMobilePhone(value)) return customErrorMessage ?? getDefaultErrorMessage('isPhoneNumber')
  return undefined
}

/**
 * Validator checking if value is a phone number and is filled.
 * If empty, returns required error, if bad format returns isPhoneNumber error
 * {@link ValidatorFunction}
 */
export const isRequiredPhoneNumber: BasicValidator<string> = ({ value, customErrorMessage }) => {
  if (typeof value !== 'string' || value === '') return customErrorMessage ?? getDefaultErrorMessage('required')
  if (!validator.isMobilePhone(value)) return customErrorMessage ?? getDefaultErrorMessage('isPhoneNumber')
  return undefined
}

/**
 * Validator checking if value is string and filled in.
 * If not, returns 'required' error
 * {@link ValidatorFunction}
 */
export const isRequiredString: BasicValidator<string | undefined> = ({ value, customErrorMessage }) => {
  if (typeof value === 'string' && value !== '') return undefined
  return customErrorMessage ?? getDefaultErrorMessage('required')
}

/**
 * Validator checking if value is number larger than min
 * If value is nullish, returns OK
 * @param min - minimum number value
 * {@link ValidatorFunction}
 */
export const isMinNumber: ValidatorFunction<{
  value: Nullable<number>,
  min: number,
  customErrorMessage?: string
}> = ({ value, min, customErrorMessage }) => {
  if (typeof value !== 'number') return undefined
  if (value < min) return customErrorMessage ?? getDefaultErrorMessage('isMinNumber', { min })
  return undefined
}

/**
 * Validator checking if value is postalCode.
 * @param locale - optional locale ({@link validator.isPostalCodeLocales}) for checking postal codes against
 * {@link ValidatorFunction}
 */
export const isPostalCode: ValidatorFunction<{
  value: string,
  locale?: string,
  customErrorMessage?: string
}> = ({ value, locale, customErrorMessage }) => {

  const postalLocale: validator.PostalCodeLocale | undefined = !!locale && validator.isPostalCodeLocales.includes(locale as validator.PostalCodeLocale)
    ? locale as validator.PostalCodeLocale
    : undefined

  if (!validator.isPostalCode(value, postalLocale || 'any')) return customErrorMessage ?? getDefaultErrorMessage('isPostalCode')
  return undefined
}

export const isRequiredValidAddress: BasicValidator<GoogleAPIPlace | undefined> = ({ value, customErrorMessage }) => {
  if (!value) return getDefaultErrorMessage('required')

  if (!isAddressValid(value)) return customErrorMessage ?? getDefaultErrorMessage('isValidAddress')
}

/**
 * Validator checking if value is valid card expiration string in future.
 * {@link ValidatorFunction}
 */
export const isValidCardExpiration: BasicValidator = ({ value, customErrorMessage }) => {
  const error = customErrorMessage ?? getDefaultErrorMessage('isValidCardExpiration')
  if (!CARD_EXPIRATION_REGEX.test(value)) return error

  const [month, year] = value.split('/')
  if (validator.isBefore(`${month}/1/${year}`)) return error
  return undefined
}
