import { ActionExtendVirtualVisitHosting, ExtendVirtualVisitHostingStore } from '.'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'
import { cloneDeep } from 'lodash'

/** Redux data reducer for extend virtual visit hosting */
export const reduceExtendVirtualVisitHosting = (state: ExtendVirtualVisitHostingStore, action: ActionExtendVirtualVisitHosting) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.EXTEND_VIRTUAL_VISIT_HOSTING) return state
  const newState = cloneDeep(state)
  const { missionId, request, isReactivation } = action.payload

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
      newState[missionId] = {
        request: new APIRequest(APIRequestState.BEFORE_START),
        isReactivation
      }
      return newState
    case ActionTypeAPIEvent.FETCH:
      newState[missionId] = {
        request: new APIRequest(APIRequestState.RUNNING),
        isReactivation
      }
      return newState
    case ActionTypeAPIEvent.RECEIVED:
      newState[missionId] = {
        request,
        isReactivation
      }
      return newState
    default:
      return state
  }
}