import { APIRequest } from 'models/API'


/**
 * Function that takes error response from API and returns string containing formatted details about the error.   
 *   
 * @param error - error response from API (not typed unfortunately)
 * @returns Formatted string with error details
 */
export function getAPIErrorMetaString(error: APIRequest<any>) {
  if (!error?.response?.data) return ''
  return `${error.response.data.status} - <i>${error.response.data.message} (${error.response.data.timestamp})</i>`
}
