import { Stripe, loadStripe } from '@stripe/stripe-js'

import { Country } from 'constants/country'

export const STRIPE_API_KEY_EU = process.env.REACT_APP_STRIPE_API_KEY_EU
export const STRIPE_API_KEY_CH = process.env.REACT_APP_STRIPE_API_KEY_CH

if (!STRIPE_API_KEY_EU) throw new Error('STRIPE_API_KEY_EU is missing')
if (!STRIPE_API_KEY_CH) throw new Error('STRIPE_API_KEY_CH is missing')

export const stripeController: {
  stripePromise: Promise<Stripe | null> | null
} = {
  stripePromise: null,
}

/** Initialize Stripe based on users country (different countries have different country API KEY) */
export const initStripe = (country: Country) => {
  let STRIPE_API_KEY = ''

  switch (country) {
    case Country.CH:
      STRIPE_API_KEY = STRIPE_API_KEY_CH
      break
    case Country.DE:
    default:
      STRIPE_API_KEY = STRIPE_API_KEY_EU
      break
  }

  if (STRIPE_API_KEY) {
    const stripePromise = loadStripe(STRIPE_API_KEY)
    stripeController.stripePromise = stripePromise
    return true
  }
  return false
}