import { FC, ReactNode } from 'react'

import { VisualsEditingDownloadProvider } from 'components/pages/VisualsEditing/VisualsEditingDownload.context'
import { VisualsEditingSectionContextProvider } from '../../common'
import { WorkspacesProfileContextProvider } from 'components/pages/Client/WorkspacesProfile/_main/WorkspacesProfile.context'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * Stack of providers for gallery visuals editing
 * 
 * @example
 * <GalleryVisualsEditingStackProvider>
 *   <Consumer />
 * </GalleryVisualsEditingStackProvider>
 */
export const GalleryVisualsEditingStackProvider: FC<{
  assignmentId: string,
  children?: ReactNode
}> = ({
  assignmentId,
  children
}) => {
    const { currentUserWorkspace } = useUserData()

    return (
      <WorkspacesProfileContextProvider workspaceId={currentUserWorkspace?.id ?? ''}>
        <VisualsEditingDownloadProvider assignmentId={assignmentId}>
          <VisualsEditingSectionContextProvider assignmentId={assignmentId}>
            {children}
          </VisualsEditingSectionContextProvider>
        </VisualsEditingDownloadProvider>
      </WorkspacesProfileContextProvider>
    )
  }
