import { FC, ReactNode, useCallback } from 'react'
import { RenovationProductsContextProvider, StyleSelectionContextProvider, StyleSelectionOrigin, VirtualFurnitureStylesFilterContextProvider } from 'components/common/StagingFlow/main/contexts'

import { AutomaticRoomCreatorContextProvider } from './AutomaticRoomCreator.context'
import { CatalogueOptionProduct } from 'dataQueries/purchase.query'
import { ComputedStagingRoom } from 'models/virtualStaging'
import { RoomAPIContextProvider } from 'components/contexts/RoomAPI.context'
import { RoomInventoryContextProvider } from 'components/contexts/RoomInventory.context'
import { StagingDecorationsProvider } from 'components/contexts/StagingDecorations.context'
import { VirtualFurnitureDataContextProvider } from 'components/contexts/VirtualFurnitureData.context'
import { VirtualFurnitureUpgradesContextProvider } from 'components/contexts/VirtualFurnitureUpgradesContext'
import { usePurchaseFlowProducts } from './PurchaseFlowProducts.context'
import { usePurchaseFlowRenovationProduct } from './PurchaseFlowRenovationProduct.context'
import { useUploadedPurchaseFlowVisuals } from './UploadedPurchaseFlowVisuals.context'

/** Stack of providers for staging in PF */
export const PurchaseFlowStagingStack: FC<{
  children: ReactNode
}> = ({
  children
}) => {
    const { getImageUrl } = useUploadedPurchaseFlowVisuals()
    const { selectOptionProduct, selectedProductOptions, unselectOptionProduct } = usePurchaseFlowProducts()
    const { renovationProduct } = usePurchaseFlowRenovationProduct()

    /** Updates the selectedProductOptions every time there is a change in roomsWithRenovationProducts */
    const handleRenovationProductsChange = useCallback((activeRoom: ComputedStagingRoom, renovationProductCart: CatalogueOptionProduct, productQuantity: number) => {
      if (!activeRoom?.productId || !renovationProductCart) return

      const parentId = activeRoom.productId

      // Initialize adding the renovation product to the parent product as optional product.
      if (!selectedProductOptions[parentId] && productQuantity > 0) {
        selectOptionProduct(
          parentId,
          renovationProductCart,
          productQuantity
        )
      }

      // Update the quantity of the option product.
      if (
        !!selectedProductOptions[parentId] &&
        productQuantity > 0 &&
        selectedProductOptions[parentId][renovationProductCart.id]?.quantity !== productQuantity
      ) {
        selectOptionProduct(
          parentId,
          renovationProductCart,
          productQuantity
        )
      }

      // Remove the product option.
      if (!!selectedProductOptions[parentId] && productQuantity === 0) {
        unselectOptionProduct(parentId, renovationProductCart.id)
      }
    }, [selectOptionProduct, selectedProductOptions, unselectOptionProduct])

    return (
      <RoomInventoryContextProvider>
        <RoomAPIContextProvider imageUrlResolver={getImageUrl}>
          <AutomaticRoomCreatorContextProvider>
            <VirtualFurnitureDataContextProvider>
              <VirtualFurnitureUpgradesContextProvider>
                <RenovationProductsContextProvider renovationProduct={renovationProduct}>
                  <StyleSelectionContextProvider
                    onRenovationProductsChange={handleRenovationProductsChange}
                    origin={StyleSelectionOrigin.PURCHASE_FLOW}
                  >
                    <VirtualFurnitureStylesFilterContextProvider>
                      <StagingDecorationsProvider>
                        {children}
                      </StagingDecorationsProvider>
                    </VirtualFurnitureStylesFilterContextProvider>
                  </StyleSelectionContextProvider>
                </RenovationProductsContextProvider>
              </VirtualFurnitureUpgradesContextProvider>
            </VirtualFurnitureDataContextProvider>
          </AutomaticRoomCreatorContextProvider>
        </RoomAPIContextProvider>
      </RoomInventoryContextProvider>
    )
  }
