import React, { Fragment } from 'react'
import { bigFromFee, formatPrice, valueAfterDiscount, valueMultiplied } from 'utils/price'
import { useCart, usePurchaseFlowConfig } from 'components/pages/PurchaseFlow/_main/contexts'

import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { ProductItemSummary } from './ProductItemSummary'
import { TravelCostProduct } from 'models/purchaseFlow'
import { standardTimeoutConfig } from 'utils/animations'
import { useTranslation } from 'react-i18next'

/**
 * @component List of selected instruction products with prices
 * @example <InstructionProductList />
 */
export const InstructionProductList: React.FC = () => {
  const { t } = useTranslation(['product'])

  const { catalogueCurrency, catalogueDiscount } = usePurchaseFlowConfig()
  const { cartInstructionList } = useCart()

  const getTravelProductPriceAfterDiscount = (option: TravelCostProduct) => {
    return valueAfterDiscount(option.productUnitPrice, catalogueDiscount)
  }

  return (
    <Collapse in={cartInstructionList.length > 0} exit={true} timeout={standardTimeoutConfig}>
      <List disablePadding>
        {cartInstructionList
          .filter((instruction) => bigFromFee(instruction.feePrice).gt(0) || instruction.instructionProducts.length > 0)
          .map(({ id, instructionProducts, feePrice }) =>
            <Collapse key={id} in={true} timeout={standardTimeoutConfig}>
              <ListItem disablePadding>
                <ListItemText
                  disableTypography
                  primary={
                    <ProductItemSummary
                      name={t(`p_${id}`)}
                      price={valueMultiplied(feePrice, 1).gt(0) ? formatPrice(valueMultiplied(feePrice, 1), catalogueCurrency) : ''}
                      quantity={1}
                      priceAfterDiscount={
                        catalogueDiscount.gt(0)
                          ? formatPrice(
                            valueMultiplied(valueAfterDiscount(feePrice, catalogueDiscount), 1),
                            catalogueCurrency
                          )
                          : undefined
                      }
                    />
                  }
                  secondary={
                    <Fragment>
                      {instructionProducts.map((instructionProduct) =>
                        instructionProduct.productQuantity > 0 && (
                          <Collapse key={instructionProduct.productId} in={instructionProduct.productQuantity > 0} timeout={standardTimeoutConfig}>
                            <ProductItemSummary
                              key={instructionProduct.productId}
                              type='secondary'
                              quantity={instructionProduct.productQuantity}
                              name={t(`p_${instructionProduct.productId}`)}
                              price={formatPrice(
                                valueMultiplied(bigFromFee(instructionProduct.productUnitPrice), instructionProduct.productQuantity),
                                catalogueCurrency
                              )}
                              priceAfterDiscount={
                                catalogueDiscount.gt(0)
                                  ? formatPrice(
                                    valueMultiplied(getTravelProductPriceAfterDiscount(instructionProduct), instructionProduct.productQuantity),
                                    catalogueCurrency
                                  )
                                  : undefined
                              }
                            />
                          </Collapse>
                        )
                      )}
                    </Fragment>
                  }
                />
              </ListItem>
            </Collapse>
          )}
      </List>
    </Collapse>
  )
}
