import { ActionChangeLocale, UserChangeLocaleStore } from '.'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'

/** Redux data reducer for change user locale */
export const reduceChangeLocale = (state: UserChangeLocaleStore, action: ActionChangeLocale) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.USER_CHANGE_LOCALE) return state

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
      return new APIRequest(APIRequestState.BEFORE_START)
    case ActionTypeAPIEvent.FETCH:
      return new APIRequest(APIRequestState.RUNNING)
    case ActionTypeAPIEvent.RECEIVED:
      return action.payload.request
    default:
      return state
  }
}