import { AssignmentDetailsCard, CreativeAssignmentCard } from '../../../common'
import { DescriptionOutlined, UploadFileOutlined } from '@mui/icons-material'
import { FC, Fragment, ReactNode, useMemo } from 'react'

import { AssignmentDTO } from 'models/assignment'
import { AssignmentStage } from 'constants/assignment'
import { DocumentUploadType } from 'constants/documents'
import { MUIButton } from 'components/common/MUIButton'
import { ProductDTO } from 'models/product'
import { ProductKind } from 'constants/product'
import { SendToClient } from '../SendToClient'
import { useGalleryDocumentsContext } from '../../contexts'
import { useTranslation } from 'react-i18next'

interface AdminGalleryDocumentsPanelProps {
  /** Assignment ID */
  assignmentId: string
  /** Assignment */
  assignment?: AssignmentDTO
  /** Product of the assignment */
  product?: ProductDTO
}

const typeIconMap: Record<DocumentUploadType, ReactNode> = {
  [DocumentUploadType.INPUT]: <UploadFileOutlined fontSize="large" />,
  [DocumentUploadType.OUTPUT]: <DescriptionOutlined fontSize="large" />,
}

/**
 * Admin gallery documents panel component
 * 
 * @example
 * <AdminGalleryDocumentsPanel assignmentId={assignmentId}/>
 */
export const AdminGalleryDocumentsPanel: FC<AdminGalleryDocumentsPanelProps> = ({ assignmentId, assignment, product }) => {
  const { t } = useTranslation(['gallery'])

  const {
    documentTypeSelect,
    setDocumentTypeSelect,
  } = useGalleryDocumentsContext()

  const canSendAssignmentToClient = useMemo(() => {
    return assignment?.stage === AssignmentStage.DOCUMENTS_RECEIVED_FROM_PROCESSOR ||
      assignment?.stage === AssignmentStage.VISUALS_SENT_BY_EDITOR_TO_BKBN
  }, [assignment])

  const showSendToClient = useMemo(() => {
    return product?.kind === ProductKind.MEASUREMENT_ON_SITE && documentTypeSelect === DocumentUploadType.OUTPUT
  }, [documentTypeSelect, product])

  return (
    <Fragment>

      <AssignmentDetailsCard assignmentId={assignmentId} />

      <CreativeAssignmentCard />

      <div className="details">
        <div className="sticky">
          <div className="flex">
            <div className="documentTypeTabs">
              {Object.values(DocumentUploadType).map(type => (
                <MUIButton
                  key={type}
                  type={documentTypeSelect === type ? 'defaultPrimary' : 'secondaryBorder'}
                  startIcon={typeIconMap[type]}
                  onClick={() => {
                    setDocumentTypeSelect(type)
                  }}
                >
                  <span>{t(`document_type.${type}`)}</span>
                </MUIButton>
              )
              )}
            </div>
          </div>
        </div>
      </div>

      {showSendToClient &&
        <div className="details">
          <SendToClient canSendAssignmentVisualsToClient={canSendAssignmentToClient} assignmentId={assignmentId} visualsCount={0} selectMaxValue={0} />
        </div>
      }
    </Fragment>
  )
}
