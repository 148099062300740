import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { ActionRequest, StatusResponse } from 'models/redux'

import { QueryClient } from '@tanstack/react-query'
import { createAPIAction } from 'redux/Helpers'

/** Describes edit mission internal comments action  */
export interface ActionEditMissionInternalComments extends ActionRequest {
  payload: {
    missionId: string,
    comments: string,
    request: StatusResponse,
  },
}

/** Start edit mission internal comments */
export function editMissionInternalComments(missionId: string, comments: string, queryClient: QueryClient): ActionEditMissionInternalComments {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.EDIT_ASSIGNMENT_INTERNAL_COMMENTS], {
    missionId,
    comments,
  }, queryClient)
}

/** Reset edit mission internal comments action */
export function resetEditMissionInternalComments(missionId: string): ActionEditMissionInternalComments {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.EDIT_ASSIGNMENT_INTERNAL_COMMENTS], {
    missionId,
  })
}
