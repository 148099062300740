import { FC, ReactNode } from 'react'
import { GRAY_700, GRAY_900 } from 'constants/styling/theme'

import Stack from '@mui/material/Stack'
import { Typography } from '@mui/material'

interface Props {
  /** Title of the section */
  title?: string
  /** Subtitle of the section */
  subTitle?: string
  /** Custom actions block in title section */
  action?: ReactNode
  /** Custom pomponent in the section */
  children?: ReactNode
}

/**
 * Gallery section component with styled title.
 * @example <GallerySection><div>photos</div></GallerySection>
 */
export const GallerySection: FC<Props> = ({ title, subTitle, action, children }) =>
  <Stack
    width="100%"
    direction="column"
    padding="2.3rem 0"
  >
    <Stack
      marginBottom="1.6rem"
      flexDirection={{ sm: 'column', md: 'row' }}
      justifyContent={{ sm: 'flex-start', md: 'space-between' }}
      alignItems="flex-start"
      gap=".5rem"
    >
      <Stack>
        {!!title && <Typography variant="text-lg" color={GRAY_900} fontWeight="semiBold">{title}</Typography>}
        {!!subTitle && <Typography variant="text-sm" color={GRAY_700}>{subTitle}</Typography>}
      </Stack>

      {action}
    </Stack>

    {children}
  </Stack>
