import { FC, ReactNode } from 'react'

import { ConsumptionCertificateStepContextProvider } from './ConsumptionCertificateStep.context'
import { ConsumptionInformationContextProvider } from '../ConsumptionInformation'
import { HeatingSystemContextProvider } from '../HeatingSystem/HeatingSystem.context'
import { PropertyInformationContextProvider } from '../PropertyInformation'

/**
 * Stack of providers for consumption certificate step.
 * 
 * @example
 * <ConsumptionCertificateStepStackProvider>
 *   <Component />
 * </ConsumptionCertificateStepStackProvider>
 */
export const ConsumptionCertificateStepStackProvider: FC<{
  children?: ReactNode
}> = ({
  children
}) => (
    <HeatingSystemContextProvider>
      <PropertyInformationContextProvider>
        <ConsumptionInformationContextProvider>
          <ConsumptionCertificateStepContextProvider>
            {children}
          </ConsumptionCertificateStepContextProvider>
        </ConsumptionInformationContextProvider>
      </PropertyInformationContextProvider>
    </HeatingSystemContextProvider>
  )
