import { useMemo, useState } from 'react'

import { VirtualFurnitureRoomType } from 'constants/virtualFurniture'
import constate from 'constate'
import { useRoomAPI } from 'components/contexts/RoomAPI.context'

function useStyleSelectionSetup() {

  const { getAllRooms } = useRoomAPI()

  /** Keeps current room index */
  const [activeRoomIndex, setActiveRoomIndex] = useState<number>(0)

  /** All rooms in inventory sorted by order of their creation */
  const allRooms = useMemo(() => getAllRooms().sort((roomA, roomB) => roomA.roomCreationSortingOrder - roomB.roomCreationSortingOrder), [getAllRooms])
  const activeRoom = useMemo(() => allRooms[activeRoomIndex], [activeRoomIndex, allRooms])

  const imagesForImageStack: string[] = useMemo(() => {
    const images: string[] = []

    if (!activeRoom) return []

    // Prune undefined urls and push existing to array
    for (let image of activeRoom.images) {
      if (!image.url) continue
      images.push(image.url)
    }

    return images
  }, [activeRoom])

  const isEmptyRoomSelected = useMemo(() => activeRoom && activeRoom.roomTypes.has(VirtualFurnitureRoomType.EMPTY_ROOM), [activeRoom])

  return {
    allRooms,
    activeRoom,
    activeRoomIndex,
    imagesForImageStack,
    isEmptyRoomSelected,
    setActiveRoomIndex,
  }
}

export const [StyleSelectionContextProvider, useStyleSelectionContext] = constate(useStyleSelectionSetup)
