import { BEIGE_600, GRAY_700 } from 'constants/styling/theme'
import { Box, CircularProgress } from '@mui/material'
import { FC, Fragment, useMemo } from 'react'
import { GenerateTextFlowStep, useVisualsMarketing } from '../_main/contexts/VisualsMarketing.context'

import { AutoAwesomeOutlined } from '@mui/icons-material'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import { Color } from 'constants/assets'
import { CopyRoundButton } from '../CopyRoundButton'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { MUIDivider } from 'components/common/MUIDivider'
import { QueryStatus } from 'components/common/QueryStatus'
import { RoundButton } from '../RoundButton'
import Stack from '@mui/material/Stack'
import { VisualsMarketingTargetPlatform } from '../_main/VisulalsMarketing.constants'
import moment from 'moment-timezone'
import styles from './PropertyIntroduction.module.sass'
import { useMarketingMaterials } from '../_main/contexts'
import { useTimezone } from 'components/contexts/timezone.context'
import { useTranslation } from 'react-i18next'

/**
 * @component
 * PropertyIntroduction component displays information related to the form property data, including AI generated text and editing functionality.
 *
 * @example
 * <PropertyIntroduction />
 */
export const PropertyIntroduction: FC = () => {

  const { t } = useTranslation(['gallery_marketing_section', 'visual_marketing_social_media'])
  const { userTimezone } = useTimezone()

  const {
    marketingMaterials,
    editors,
    openedEdits,
    editForms,
    generators,
    onEditFieldChange,
    savePost,
    openEdit,
    closeEdit,
    generateText,
  } = useMarketingMaterials()

  const {
    generateTextStep,
    isPropertyDetailsEditing,
  } = useVisualsMarketing()

  const isEdit = useMemo(() => openedEdits[VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE], [openedEdits])

  const isSmallLoading = useMemo(
    () => generators.REAL_ESTATE_BROCHURE.isPending && !!marketingMaterials.REAL_ESTATE_BROCHURE?.id,
    [generators.REAL_ESTATE_BROCHURE.isPending, marketingMaterials.REAL_ESTATE_BROCHURE?.id]
  )

  return (
    <div>

      {/** GENERATED TEXT INFORMATION */}
      <BorderBoxWrapper
        className={styles.infoContainer}
        backgroundColor={isSmallLoading ? BEIGE_600 : Color.WHITE}
        elevation={isSmallLoading ? 'none' : 'xs'}
      >

        {isSmallLoading &&
          <CircularProgress
            size={72}
            sx={{
              position: 'absolute',
              top: 'calc(50% - 36px)',
              left: 'calc(50% - 36px)',
              '& svg': {
                color: GRAY_700,
              },
            }}
          />
        }

        <Box sx={{
          opacity: isSmallLoading || isPropertyDetailsEditing ? 0.3 : 1,
          pointerEvents: isSmallLoading || isPropertyDetailsEditing ? 'none' : 'all',
          transition: 'opacity 400ms ease',
        }}>

          {/** INFO HEADER */}
          <Stack
            gap={2}
            justifyContent="space-between"
            flexWrap="wrap"
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            direction={{ xs: 'column', sm: 'row' }}
          >

            <h3>{t(`property_introduction.title.${generateTextStep}`)}</h3>

            {/** Action buttons */}
            {generateTextStep === GenerateTextFlowStep.TEXT_EXISTS &&
              <Stack spacing={1} direction="row">
                <RoundButton
                  color='inherit'
                  disabled={isEdit}
                  sx={{
                    '&.MuiButton-outlined:disabled': {
                      backgroundColor: BEIGE_600
                    }
                  }}
                  startIcon={<EditOutlinedIcon />}
                  onClick={() => openEdit(VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE)}
                >
                  {t('edit_text')}
                </RoundButton>

                <CopyRoundButton
                  copyText={marketingMaterials[VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE]?.text || ''}
                  disabled={isEdit}
                  targetPlatform={VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE}
                />

                <RoundButton
                  color='inherit'
                  disabled={openedEdits[VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE]}
                  startIcon={<AutoAwesomeOutlined />}
                  onClick={() => generateText(VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE, true)}
                >
                  {t('regenerate_text')}
                </RoundButton>
              </Stack>
            }

          </Stack>

          <MUIDivider margin={12} />

          <QueryStatus
            query={generators[VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE]}
            onPurge={() => generators[VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE].reset()}
            spaceBottomRem={2}
            showStates={['error']}
          />

          {/** GENERATED TEXT INPUT */}
          <Fragment>
            {generateTextStep === GenerateTextFlowStep.TEXT_EXISTS && !isEdit
              ? (
                <>
                  <pre>
                    {marketingMaterials[VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE]?.text || ''}
                  </pre>
                  {!!marketingMaterials.REAL_ESTATE_BROCHURE?.lastUpdated &&
                    <span style={{ display: 'block', marginTop: '2rem' }}>
                      {t('visual_marketing_social_media:updated', { date: moment(marketingMaterials.REAL_ESTATE_BROCHURE?.lastUpdated).tz(userTimezone).format('DD. MM. YYYY HH:mm') })}
                    </span>
                  }
                </>
              )
              : (
                <textarea
                  name="propery-information-textarea"
                  id="propery-information-textarea"
                  value={editForms[VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE]}
                  rows={20}
                  onChange={(e) => onEditFieldChange(VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE, e.target.value)}
                >
                </textarea>
              )
            }
          </Fragment>

          {/** FOOTER */}
          <Fragment>

            {generateTextStep === GenerateTextFlowStep.TEXT_EXISTS && isEdit &&
              <Stack
                spacing={1}
                marginTop={3}
                flexWrap="wrap"
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
              >

                {/** Discard edit button */}
                <RoundButton
                  color='inherit'
                  onClick={() => closeEdit(VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE)}
                  startIcon={<DeleteOutlineIcon />}
                >
                  {t('discard_changes')}
                </RoundButton>

                {/** Save edit button */}
                <RoundButton
                  onClick={() => savePost(VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE)}
                  startIcon={<CheckCircleOutlineRoundedIcon />}
                >
                  {t('save_changes')}
                </RoundButton>

                <Box flex="1 1 100%">
                  <QueryStatus
                    query={editors[VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE]}
                    onPurge={() => editors[VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE].reset()}
                    spaceTopRem={2}
                  />
                </Box>
              </Stack>
            }

          </Fragment>

        </Box>

      </BorderBoxWrapper>

    </div>
  )
}
