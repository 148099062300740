import { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { ProductKind, ProductType } from 'constants/product'
import { bigFromDecimal, percentageValue, valueAfterDiscount } from 'utils/price'

import { AdditionalVisualPriceDTO } from 'models/visuals'
import { AxiosResponse } from 'axios'
import Big from 'big.js'
import { Currency } from 'constants/misc'
import { PIPEDRIVE_INFINITY } from 'constants/pipedrive'
import { ProductCategory } from 'models/product'
import { isEditingCategory } from 'utils/validators'
import { useAuth0 } from 'utils/auth'
import { useGalleryAssignment } from './GalleryAssignment.context'
import { useGalleryConstants } from './GalleryConstants.context'
import { useGalleryProduct } from './GalleryProduct.context'
import { useGalleryVisualSelection } from './GalleryVisualSelection.context'
import { useGalleryVisualType } from './GalleryVisualType.context'
import { useGalleryVisualsMeta } from './GalleryVisualsMeta.context'
import { useGetAdditionalVisualPrice } from 'dataQueries'

interface GalleryAdditionalVisualsInterface {
  getAdditionalVisualPriceResponse?: AxiosResponse<AdditionalVisualPriceDTO, any>
  currency?: Currency
  pricePerAdditionalVisual: Big
  vat: Big
  discount: Big
  selectedAdditionalVisualsCount: number
  totalForAdditionalVisualsBeforeDiscount: Big
  totalForAdditionalVisualsAfterDiscount: Big
  totalVatForAdditionalVisuals: Big
  totalAfterVatForAdditionalVisuals: Big
  areAdditionalVisualsSelected: boolean
  isAdditionalVisualPurchaseDisabled: boolean
  additionalVisualProductId?: number
  isAdditionalVisualsPurchaseModalOpen: boolean
  setIsAdditionalVisualsPurchaseModalOpen: Dispatch<SetStateAction<boolean>>
}

const defaultGalleryAdditionalVisualsValue: GalleryAdditionalVisualsInterface = {
  pricePerAdditionalVisual: new Big(0),
  vat: new Big(0),
  discount: new Big(0),
  selectedAdditionalVisualsCount: 0,
  totalForAdditionalVisualsBeforeDiscount: new Big(0),
  totalForAdditionalVisualsAfterDiscount: new Big(0),
  totalVatForAdditionalVisuals: new Big(0),
  totalAfterVatForAdditionalVisuals: new Big(0),
  areAdditionalVisualsSelected: false,
  isAdditionalVisualPurchaseDisabled: false,
  additionalVisualProductId: undefined,
  isAdditionalVisualsPurchaseModalOpen: false,
  setIsAdditionalVisualsPurchaseModalOpen: () => { }
}

/** Gallery additional visuals context */
export const GalleryAdditionalVisualsContext = createContext<GalleryAdditionalVisualsInterface>(defaultGalleryAdditionalVisualsValue)
/** Gallery additional visuals context hook */
export const useGalleryAdditionalVisuals = (): GalleryAdditionalVisualsInterface => useContext(GalleryAdditionalVisualsContext)

/** Context provider for gallery additional visuals */
export const GalleryAdditionalVisualsContextProvider: FC<{
  assignmentId: string
  children?: ReactNode
}> = ({
  assignmentId,
  children,
}) => {
    const { roles } = useAuth0()

    const {
      VisualTypeSelectEnum,
    } = useGalleryConstants()

    const {
      assignmentData,
      product,
    } = useGalleryAssignment()

    const {
      visualTypeSelect,
    } = useGalleryVisualType()

    const {
      isVirtualVisit,
    } = useGalleryProduct()

    const {
      purchasedVisualsExist,
      selectMaxValue,
    } = useGalleryVisualsMeta()

    const {
      selected,
      canSelectVisuals,
      selectedNotPurchasedVisualsCount,
    } = useGalleryVisualSelection()

    const [isAdditionalVisualsPurchaseModalOpen, setIsAdditionalVisualsPurchaseModalOpen] = useState(false)

    // React query
    const getAdditionalVisualPrice = useGetAdditionalVisualPrice(assignmentId)

    const getAdditionalVisualPriceResponse = getAdditionalVisualPrice.data

    const isAdditionalVisualPurchaseDisabled = useMemo(() => !assignmentData || assignmentData.type === ProductType.FLOOR_PLAN || assignmentData.type === ProductType.VIRTUAL_VISIT || assignmentData.type === ProductType.VIDEO || !product || isEditingCategory(product.category as ProductCategory) || product.kind === ProductKind.COMMERCIAL_STAGING || product.kind === ProductKind.RESIDENTIAL_STAGING, [assignmentData, product])
    const currency = useMemo(() => getAdditionalVisualPriceResponse?.data?.fee.currency, [getAdditionalVisualPriceResponse])
    const pricePerAdditionalVisual = useMemo(() => bigFromDecimal(getAdditionalVisualPriceResponse?.data?.fee.value || 0), [getAdditionalVisualPriceResponse])
    const vat = useMemo(() => bigFromDecimal(getAdditionalVisualPriceResponse?.data?.vat.value || 0), [getAdditionalVisualPriceResponse])
    const discount = useMemo(() => bigFromDecimal(getAdditionalVisualPriceResponse?.data?.discount.value || 0), [getAdditionalVisualPriceResponse])
    const additionalVisualProductId = useMemo(() => getAdditionalVisualPriceResponse?.data?.productId, [getAdditionalVisualPriceResponse])
    const selectedAdditionalVisualsCount = useMemo(() => {
      if (isAdditionalVisualPurchaseDisabled) return 0
      if (!purchasedVisualsExist) {
        if (selected.size > selectMaxValue) return (selected.size - selectMaxValue)
        return 0
      }
      return selectedNotPurchasedVisualsCount
    }, [isAdditionalVisualPurchaseDisabled, selectedNotPurchasedVisualsCount, purchasedVisualsExist, selected.size, selectMaxValue])
    const totalForAdditionalVisualsBeforeDiscount = useMemo(() => pricePerAdditionalVisual.times(selectedAdditionalVisualsCount), [pricePerAdditionalVisual, selectedAdditionalVisualsCount])
    const totalForAdditionalVisualsAfterDiscount = useMemo(() => valueAfterDiscount(totalForAdditionalVisualsBeforeDiscount, discount), [totalForAdditionalVisualsBeforeDiscount, discount])
    const totalVatForAdditionalVisuals = useMemo(() => percentageValue(totalForAdditionalVisualsAfterDiscount, vat), [totalForAdditionalVisualsAfterDiscount, vat])
    const totalAfterVatForAdditionalVisuals = useMemo(() => totalForAdditionalVisualsAfterDiscount.plus(totalVatForAdditionalVisuals), [totalForAdditionalVisualsAfterDiscount, totalVatForAdditionalVisuals])
    const areAdditionalVisualsSelected = useMemo(() => !isVirtualVisit && canSelectVisuals && (roles.isClient || roles.isAdmin) && (visualTypeSelect === VisualTypeSelectEnum.POST || visualTypeSelect === VisualTypeSelectEnum.POST_WATERMARK) && !isAdditionalVisualPurchaseDisabled && selectedAdditionalVisualsCount > 0 && selectMaxValue !== PIPEDRIVE_INFINITY && !getAdditionalVisualPriceResponse, [isVirtualVisit, canSelectVisuals, roles, visualTypeSelect, VisualTypeSelectEnum, getAdditionalVisualPriceResponse, selectedAdditionalVisualsCount, isAdditionalVisualPurchaseDisabled, selectMaxValue])

    // Fetch additional visual price action when selected size exceeds the bought value
    useEffect(() => {
      if (areAdditionalVisualsSelected) {
        getAdditionalVisualPrice.refetch()
      }
    }, [areAdditionalVisualsSelected, assignmentId, getAdditionalVisualPrice])

    return (
      <GalleryAdditionalVisualsContext.Provider
        value={{
          getAdditionalVisualPriceResponse,
          currency,
          pricePerAdditionalVisual,
          vat,
          discount,
          selectedAdditionalVisualsCount,
          totalForAdditionalVisualsBeforeDiscount,
          totalForAdditionalVisualsAfterDiscount,
          totalVatForAdditionalVisuals,
          totalAfterVatForAdditionalVisuals,
          areAdditionalVisualsSelected,
          isAdditionalVisualPurchaseDisabled,
          additionalVisualProductId,
          isAdditionalVisualsPurchaseModalOpen,
          setIsAdditionalVisualsPurchaseModalOpen,
        }}
      >
        {children}
      </GalleryAdditionalVisualsContext.Provider>
    )
  }
