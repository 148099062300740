import { Dispatch, FC, SetStateAction } from 'react'
import { GRAY_400, GRAY_500, GRAY_600, GRAY_900 } from 'constants/styling/theme'
import { RestrictedAccessOption, addMemebersAccessDropdownItems } from 'constants/workspaces/workspaceMemberTable'
import { Stack, Typography } from '@mui/material'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUITooltip } from 'components/common/MUITooltip'
import { Path } from 'constants/router'
import { externalNavigate } from 'utils/helpers'
import { useTranslation } from 'react-i18next'
import { useWorkspacesProfileContext } from 'components/pages/Client/WorkspacesProfile/_main/WorkspacesProfile.context'

/**
 * @interface Props Input properties
 */
interface Props {
  /** onChange action triggered when user changes restricted access option */
  onChange?: Dispatch<SetStateAction<RestrictedAccessOption>>
  /** Selected access - FULL_ACCESS is default */
  memberAccess: string
  /** Whether role change dropdown should be disabled */
  disabled: boolean
}

/**
 * @component Add restricted access to members
 * @example
 *  <AddMembersRole />
 */
export const AddMembersRole: FC<Props> = ({ onChange, memberAccess, disabled = false }) => {

  const { t } = useTranslation(['workspace_profile'])
  const { isWorkspaceEnterprise } = useWorkspacesProfileContext()

  const handleChange = (value: string) => {
    !!onChange && onChange(value as RestrictedAccessOption)
  }

  const isLockedFeature = !isWorkspaceEnterprise

  return (
    <Stack sx={{ maxWidth: 220 }}>
      <Typography sx={{ marginBottom: .4 }} variant="text-md" fontWeight={'medium'} >{t('access_setting')}</Typography>

      <MUIDropdown
        button={(isOpen, action) => (
          <MUITooltip
            content={isLockedFeature ? t('access_locked_description') : undefined}
            placement='right'
          >
            <BorderBoxWrapper
              borderColor={GRAY_400}
              component="div"
              padding="0.6rem 1.2rem"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                transition: '.3s ease',
                cursor: isLockedFeature || disabled ? 'not-allowed' : 'pointer'
              }}
              onClick={(e) => {
                if (isLockedFeature) return externalNavigate(Path.PRICING)
                if (!disabled) return action(e)
              }}
            >
              <Typography variant="text-md" color={isLockedFeature || disabled ? GRAY_600 : GRAY_900} fontWeight="medium">{t(memberAccess)}</Typography>

              {!isLockedFeature && !disabled &&
                (isOpen ? <KeyboardArrowUpRoundedIcon fontSize="large" /> : <KeyboardArrowDownRoundedIcon fontSize="large" />)
              }

              {isLockedFeature && <LockOutlinedIcon htmlColor={GRAY_500} />}
            </BorderBoxWrapper>
          </MUITooltip>
        )
        }
      >
        {
          addMemebersAccessDropdownItems.map((item) => (
            <MUIDropdownItem
              key={item.subTitle}
              isSelected={undefined}
              onClick={() => handleChange(item.title)}
            >
              <Stack gap=".2rem">
                <Typography variant="text-md" color={GRAY_900} fontWeight="medium">{t(item.title)}</Typography>
                <Typography variant="text-sm" color={GRAY_900}>{t(item.subTitle)}</Typography>
              </Stack>
            </MUIDropdownItem>
          ))
        }
      </MUIDropdown>
    </Stack>
  )
}
