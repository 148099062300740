import React, { useEffect } from 'react'

import Button from 'components/common/Button/Button'
import Modal from 'components/common/Modals/Modal/Modal'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import Stack from '@mui/material/Stack'
import classnames from 'classnames'
import styles from './VisualsEditingDownloadPopup.module.sass'
import { useTranslation } from 'react-i18next'
import { useVisualsEditingDownload } from '../VisualsEditingDownload.context'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Whether the popup is open */
  isOpen: boolean
  /** Onclick action to retry download */
  retryDownload: () => void
  /** Actions to trigger when exit the download popup */
  onEditingExit?: () => void
  /** Onclick action triggered when user clicks the close button */
  onClickClose?: (e: React.MouseEvent) => unknown
}

/**
 * @component VisualsEditingDownloadPopup 
 * @example
 * <VisualsEditingDownloadPopup isOpen={true} />
 */

export const VisualsEditingDownloadPopup: React.FC<Props> = ({
  isOpen,
  retryDownload,
  onEditingExit,
  onClickClose
}) => {
  const { t } = useTranslation(['visuals_editing'])
  const {
    hasDownloadFailed,
    isDownloadReady,
    downloadUrl,
  } = useVisualsEditingDownload()

  useEffect(() => {
    if (!isOpen) return
    !!downloadUrl && window.location.replace(downloadUrl)
  }, [downloadUrl, isOpen])

  return (
    <Modal
      modalContentClassName={styles.downloadPopup}
      isOpen={isOpen}
      onClickOutside={onClickClose}
      onClose={onClickClose}
    >
      <div className={styles.downloadLogo}>
        <div className={styles.innerCircle}>
          <div className={styles.img}>
            <SaveAltIcon fontSize='inherit' />
          </div>
        </div>
      </div>
      <Stack alignItems="center">
        <div className={styles.title}>
          {isDownloadReady && t('download_ready')}
          {hasDownloadFailed && t('download_error')}
        </div>
        <div className={styles.description}>
          {isDownloadReady && t('download_note')}
          {hasDownloadFailed && t('download_error_note')}
        </div>
      </Stack>

      <div className={classnames({ [styles.buttons]: !!onEditingExit })}>
        <Button className={styles.button} type="secondary" onClick={retryDownload}>
          <span>{t('download_retry')}</span>
        </Button>

        {!!onEditingExit &&
          <Button className={styles.button} type="primary" onClick={onEditingExit}>
            <span>{t('download_exit')}</span>
          </Button>
        }
      </div>
    </Modal>
  )
}
