import { useEffect, useRef, useState } from 'react'

/** A react hook which returns true if user is dragging a file and false otherwise */
export function useDraggingFile() {
  const dragTimer = useRef<number>()
  const [isDraggingFile, setIsDraggingFile] = useState(false)

  // Bind dragover and dragleave events
  useEffect(() => {
    const dragOverHandler = (e: DragEvent) => {
      if (e.dataTransfer?.types.indexOf('Files') !== -1) {
        setIsDraggingFile(true)
        if (dragTimer.current) window.clearTimeout(dragTimer.current)
      }
    }

    const dragEndHandler = (e: DragEvent) => {
      if (dragTimer.current) window.clearTimeout(dragTimer.current)
      dragTimer.current = window.setTimeout(() => {
        setIsDraggingFile(false)
      }, 25)
    }

    document.addEventListener('dragover', dragOverHandler)
    document.addEventListener('dragleave', dragEndHandler)
    document.addEventListener('dragend', dragEndHandler)
    document.addEventListener('drop', dragEndHandler)

    return () => {
      document.removeEventListener('dragover', dragOverHandler)
      document.removeEventListener('dragleave', dragEndHandler)
      document.removeEventListener('dragend', dragEndHandler)
      document.removeEventListener('drop', dragEndHandler)
    }
  }, [])

  return isDraggingFile
}