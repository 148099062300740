import React, { useMemo } from 'react'

import { Badge } from '../Badge'
import { CreativeAvailabilityStatus } from 'constants/creative'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** Availability status of the creative for the assignment */
  creativeStatus: CreativeAvailabilityStatus
  /** The additional classes to append */
  className?: string
}

/**
 * @component Badge which represents the status of the creative
 * @example
 * <CreativeStatusBadge creativeStatus={CreativeStatus.AVAILABLE} />
 */
export const CreativeStatusBadge: React.FC<Props> = ({
  creativeStatus,
  className = '',
}) => {
  const { t } = useTranslation(['creative_status'])

  const availabilityStatus = useMemo(() => {
    if (creativeStatus === CreativeAvailabilityStatus.SELECTED_ON_MULTIPLE_ORDERS) return CreativeAvailabilityStatus.AVAILABLE
    else return creativeStatus
  }, [creativeStatus])

  const color = useMemo(() => {
    switch (availabilityStatus) {
      case CreativeAvailabilityStatus.AVAILABLE:
        return 'green'
      case CreativeAvailabilityStatus.ON_AN_ASSIGNMENT:
        return 'orange'
      case CreativeAvailabilityStatus.ON_THIS_ORDER:
        return 'blue'
      case CreativeAvailabilityStatus.UNRESPONSIVE:
        return 'gray'
      case CreativeAvailabilityStatus.DECLINED:
      case CreativeAvailabilityStatus.UNAVAILABLE:
      case CreativeAvailabilityStatus.NOT_WORKING:
        return 'red'
    }
  }, [availabilityStatus])

  return (
    <Badge
      className={className}
      color={color}
      type="fillAlt"
    >
      {t(availabilityStatus)}
    </Badge>
  )
}
