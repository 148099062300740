import { PendingInvitation, WorkspaceMember } from 'models/user/userWorkspaceDTOs'

import { MUIButton } from 'components/common/MUIButton'
import { QueryStatus } from 'components/common/QueryStatus'
import { TIMEOUT_AFTER_SUCCESSFUL_CALLBACK } from 'constants/application'
import { logAnalyticsEvent } from 'utils/analytics'
import styles from './ResendInvitationButton.module.sass'
import { useDispatch } from 'react-redux'
import { useLayoutEffect } from 'react'
import { useResendWorkspaceInvitation } from 'dataQueries'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Workspace ID */
  workspaceId: string
  /** Invited member data */
  invitation: PendingInvitation,
  /** User's workspace data */
  workspaceDataOfUser?: WorkspaceMember
  /** User's workspace data */
  size?: 'xs' | 'lg'
}

/**
 * @component Resend invitation to specific user - not connected to BE right now, under FF
 * @example
 * <ResendInvitationButton workspaceId={workspaceId} memberEmail={email} />
 */
export const ResendInvitationButton: React.FC<Props> = ({
  workspaceId,
  invitation,
  workspaceDataOfUser,
  size = 'xs'
}) => {
  const dispatch = useDispatch()
  const resendInvitation = useResendWorkspaceInvitation()
  const { t } = useTranslation(['workspace_profile'])

  useLayoutEffect(() => {
    if (resendInvitation.isSuccess) {
      window.setTimeout(() => {
        resendInvitation.reset()
      }, TIMEOUT_AFTER_SUCCESSFUL_CALLBACK)
    }
  }, [resendInvitation, dispatch])

  return (
    <div className={styles.buttonsWrapper}>
      {resendInvitation.isIdle ?
        <MUIButton
          fullWidth
          size={size}
          type="defaultPrimary"
          onClick={() => {
            resendInvitation.mutate({ workspaceId, invitationId: invitation.id })
            !!workspaceDataOfUser && logAnalyticsEvent('Resend Invitation', {
              userId: workspaceDataOfUser.userId,
              userRole: workspaceDataOfUser.role,
              planId: workspaceId,
              invitedUserEmail: invitation.email,
            })
          }}
        >
          {t('resend_invitation')}
        </MUIButton>
        :
        <QueryStatus
          query={resendInvitation}
          successMessage={t('invitation_sent')}
        />
      }
    </div>
  )
}
