import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { Endpoint, RoleMimetype } from 'constants/API'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, put, takeEvery } from 'redux-saga/effects'

import API from 'utils/API/API'
import { ActionGetUpsellPaymentIntent } from './getUpsellPaymentIntent.actions'
import { ProductQuantityDTO } from 'models/purchaseFlow'

/** Saga which gets upsell payment intent from API */
export function* getUpsellPaymentIntentSaga(receivedAction: ActionGetUpsellPaymentIntent) {
  const { missionId, productId, quantity, paymentMethod } = receivedAction.payload

  const url = Endpoint.GET_UPSELL_PAYMENT_INTENT.replace('{assignmentId}', missionId.toString())

  const product: ProductQuantityDTO = {
    id: productId,
    count: quantity,
    isOption: false,
  }

  const action: ActionGetUpsellPaymentIntent = yield generalFetch(ActionTypeAPIData.GET_UPSELL_PAYMENT_INTENT, () => API.post(
    url,
    {
      products: [product],
      paymentMethodTypes: [paymentMethod],
    },
    {
      headers: {
        Accept: RoleMimetype.CLIENT, // TODO: Fix on BE so we can send proper accept header
      }
    },
    {
      endpoint: Endpoint.GET_UPSELL_PAYMENT_INTENT
    }
  ))

  const getUpsellPaymentIntentAction: ActionGetUpsellPaymentIntent = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }

  yield put(getUpsellPaymentIntentAction)
}

/** Watcher of get upsell payment intent */
export function* getUpsellPaymentIntentWatcher() {
  yield all([
    takeEvery((action: ActionGetUpsellPaymentIntent) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.GET_UPSELL_PAYMENT_INTENT]), getUpsellPaymentIntentSaga),
  ])
}
