import { DeclineAssignmentReason } from './assignmentDecline'

/** All possible event types displayed in assignment chagelog */
export type AssignmentEvent = SuggestionRequest | Outreach | OutreachResult | AutomationFail

/** Assignment changelog DTO */
export interface AssignmentChangelogDTO {
  events: AssignmentEvent[]
}

/** Describes creative suggestion request */
export interface SuggestionRequest extends BaseEvent {
  requester: string,
}

/** Describes creative outreach */
export interface Outreach extends BaseEvent {
  creative: string,
}

/** Describes the result of the creative outreach */
export interface OutreachResult extends BaseEvent {
  result: OutreachResultStatus,
  outreachId: string,
  rejectionReason?: DeclineAssignmentReason,
  rejectionReasonFreetext?: string
}

/** Describes the assignment automation if it has failed */
export interface AutomationFail extends BaseEvent {
  creative?: string,
  reason: AutomationFailedReason,
}

/** Base interface for the assignment event */
export interface BaseEvent {
  id: string,
  type: EventType,
  timestamp: string,
}

/** Enum listing all possible reasons when assignment automation failed */
export enum AutomationFailedReason {
  /** Assignment margin is below minimum margin */
  MARGIN_BELOW_THRESHOLD = 'MARGIN_BELOW_THRESHOLD',
  /** No creative is available for the assignment */
  NO_ONE_AVAILABLE = 'NO_ONE_AVAILABLE',
  /** The time frame for automation exceeded */
  EXCEEDED_TIME_FRAME = 'EXCEEDED_TIME_FRAME',
  /** Automation has been stopped by admin */
  STOPPED_BY_ADMIN = 'STOPPED_BY_ADMIN',
  /** The whole order was cancelled */
  ORDER_CANCELLED = 'ORDER_CANCELLED',
}

/** Enum listing all possible outreach result statuses */
export enum OutreachResultStatus {
  /** Creative has accepted the assignment */
  ACCEPTED = 'ACCEPTED',
  /** Creative has declined the assignment */
  DECLINED = 'DECLINED',
  /** Creative is not responding */
  UNRESPONSIVE = 'UNRESPONSIVE',
  /** Creative has been rejected by admin */
  REJECTED_BY_ADMIN = 'REJECTED_BY_ADMIN',
}

/** Enum listing all types of assignment events */
export enum EventType {
  /** Creative suggestion requested */
  SUGGESTION_REQUEST = 'SUGGESTION_REQUEST',
  /** Creative outreached */
  OUTREACH = 'OUTREACH',
  /** Result of the creative outreach */
  OUTREACH_RESULT = 'OUTREACH_RESULT',
  /** Assignment automation failed */
  AUTOMATION_FAIL = 'AUTOMATION_FAIL',
}
