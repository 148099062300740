import { ClientType, SubscriptionPeriod, SubscriptionPlan } from 'models/user'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'

import { ActionTypeAPIData } from 'constants/redux'
import { RootStore } from 'models/redux'
import { WORKSPACE_MEMBER_LIMIT_BY_PLAN } from 'constants/workspaces'
import constate from 'constate'
import { getUserWorkspaces } from 'redux/Individual/User/GetUserWorkspaces'
import { useRemoteConfig } from 'components/contexts/RemoteConfigContext'
import { useUserData } from 'components/contexts/UserDataContext'

export const [SubscriptionFlowContextProvider, useSubscriptionFlow] = constate(() => {

  const dispatch = useDispatch()
  const { ignoredOrganizationsForWorkspaceNameAsSet } = useRemoteConfig()
  const { clientData, organizationData } = useUserData()

  const getUserWorkspaceRequest = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.USER_GET_WORKSPACES])
  const createdWorkspace = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.CREATE_WORKSPACE].data)
  const createWorkspaceSubscriptionRequest = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.CREATE_WORKSPACE_SUBSCRIPTION])

  const userWorkspaces = useMemo(() => getUserWorkspaceRequest.data?.workspaces || null, [getUserWorkspaceRequest])

  const defaultWorkspaceName = useMemo(() => {
    if (!clientData) return
    if (clientData.clientType === ClientType.B2C) return clientData.name
    if (!clientData.organizationId) return clientData.name
    if (ignoredOrganizationsForWorkspaceNameAsSet.has(clientData.organizationId)) return clientData.name
    if (!organizationData) return clientData.name
    return organizationData.name
  }, [clientData, ignoredOrganizationsForWorkspaceNameAsSet, organizationData])

  const [workspaceMembers, setWorkspaceMembers] = useState<string[]>([])
  const [subscriptionBillingPeriod, setSubscriptionBillingPeriod] = useState<SubscriptionPeriod>(SubscriptionPeriod.MONTHLY)
  const [workspaceName, setWorkspaceName] = useState<string>('')

  // Add 1 to include the creator into the seat count
  const workspaceSeatCount = useMemo(() => workspaceMembers.length + 1, [workspaceMembers])

  // Max invites are 1 less, because creator is counted as 1 seat
  const maxInvitesCount = WORKSPACE_MEMBER_LIMIT_BY_PLAN[SubscriptionPlan.PRO_PER_SEAT] - 1

  // Retrieve users' workspaces on init
  useEffect(() => {
    dispatch(getUserWorkspaces())
  }, [dispatch])

  useEffect(() => {
    if (!defaultWorkspaceName) return
    setWorkspaceName(defaultWorkspaceName)
  }, [defaultWorkspaceName])

  return {
    createdWorkspace,
    getUserWorkspaceRequest,
    createWorkspaceSubscriptionRequest,
    defaultWorkspaceName,
    workspaceName,
    workspaceMembers,
    userWorkspaces,
    workspaceSeatCount,
    maxInvitesCount,
    subscriptionBillingPeriod,
    setSubscriptionBillingPeriod,
    setWorkspaceMembers,
    setWorkspaceName,
  }
})
