import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { ActionGetSubscriptionCheckoutLink } from './getCheckoutLink.actions'
import { GetSubscriptionCheckoutLinkStore } from './getCheckoutLink.interfaces'

/** Redux data reducer for getting subscription checkout link */
export const reduceGetSubscriptionCheckoutLink = (state: GetSubscriptionCheckoutLinkStore, action: ActionGetSubscriptionCheckoutLink) => {
  const [eventType, dataType] = action.type

  if (dataType !== ActionTypeAPIData.GET_SUBSCRIPTION_CHECKOUT_LINK) return state

  const { request } = action.payload

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
    case ActionTypeAPIEvent.FETCH:
    case ActionTypeAPIEvent.RECEIVED:
      return request
    default:
      return state
  }
}
