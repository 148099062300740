import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { Endpoint, RoleMimetype, awaitedUserMeSignupErrorStatusCodes } from 'constants/API'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, put, takeEvery } from 'redux-saga/effects'
import { getInitRoles, getRoles } from 'utils/auth'

import API from 'utils/API/API'
import { ActionUserMeAlternative } from '.'
import { trackUser } from 'utils/analytics'

/** Saga which fetches user me alternative */
export function* userMeAlternativeSaga(receivedAction: ActionUserMeAlternative) {
  let roles = getInitRoles()
  try {
    roles = yield getRoles()
  } catch (e) {
    throw new Error('Unable to get roles for user')
  }

  const roleNotSet = !roles || roles.all.size === 0
  const mimeType = roleNotSet ? RoleMimetype.CLIENT : API.decideRoleMimeType(roles)

  const url = Endpoint.USER_ME
  const action: ActionUserMeAlternative = yield generalFetch(ActionTypeAPIData.USER_ME_ALTERNATIVE, () => API.get(url, {
    headers: {
      Accept: mimeType,
    },
  }, {
    endpoint: `${Endpoint.USER_ME} - (alternative)`,
    ignoreErrorFactory: error => awaitedUserMeSignupErrorStatusCodes.has(error.response?.status ?? Number.MIN_VALUE),
  }))

  const userMeAlternativeAction: ActionUserMeAlternative = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }

  const user = action.payload.request.data
  if (user) trackUser(user)

  yield put(userMeAlternativeAction)
}

/** Watcher of user me alternative actions */
export function* userMeAlternativeWatcher() {
  yield all([
    takeEvery((action: ActionUserMeAlternative) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.USER_ME_ALTERNATIVE]), userMeAlternativeSaga),
  ])
}
