import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { cloneDeep } from 'lodash'
import { ActionRequest } from 'models/redux'
import { UploadWatermarkLogoStore } from './uploadWatermarkLogo.interfaces'

const reduceAction = (state: UploadWatermarkLogoStore, action: ActionRequest, progress: number) => {
  const uploadState = {
    ...cloneDeep(action.payload),
    progress,
  }

  return uploadState
}


/** Redux data reducer for upload watermark logo */
export const reduceUploadWatermarkLogo = (state: UploadWatermarkLogoStore, action: ActionRequest) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.UPLOAD_WATERMARK_LOGO) return state

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
    case ActionTypeAPIEvent.FETCH:
      return reduceAction(state, action, 0)
    case ActionTypeAPIEvent.PROGRESS:
      return reduceAction(state, action, action.payload.progress || 0)
    case ActionTypeAPIEvent.RECEIVED:
      return reduceAction(state, action, 100)
    default:
      return state
  }
}
