import { APIRequestState, Endpoint, RoleMimetype } from 'constants/API'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, put, takeEvery } from 'redux-saga/effects'

import API from 'utils/API/API'
import { ActionGetUserWorkspaces } from './getUserWorkspaces.actions'
import { trackIntercomUserWorkspaces } from 'utils/analytics'

export function* getUserWorkspacesSaga(receivedAction: ActionGetUserWorkspaces) {
  const url = Endpoint.USER_WORKSPACES

  const action: ActionGetUserWorkspaces = yield generalFetch(ActionTypeAPIData.USER_GET_WORKSPACES, () => API.get(
    url,
    {
      headers: {
        Accept: RoleMimetype.CLIENT, // TODO: Fix on BE so we can send proper accept header
      },
    },
    {
      endpoint: Endpoint.USER_WORKSPACES
    }
  ))

  const getUserWorkspacesAction: ActionGetUserWorkspaces = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }

  yield put(getUserWorkspacesAction)

  if (getUserWorkspacesAction.payload.request.state !== APIRequestState.OK) return
  if (!getUserWorkspacesAction.payload.request.data) return
  trackIntercomUserWorkspaces(getUserWorkspacesAction.payload.request.data)
}

export function* getUserWorkspacesWatcher() {
  yield all([
    takeEvery((action: ActionGetUserWorkspaces) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.USER_GET_WORKSPACES]), getUserWorkspacesSaga),
  ])
}
