import { DynamicFlowStep } from 'components/common/DynamicFlow'
import { FC } from 'react'
import { PowerOfAttorneyStepController } from './PoweOfAttorneyStep.controller'
import { PurchaseFlowStepKey } from '../../_main/contexts'
import { usePowerOfAttorneyStep } from './PowerOfAttorneyStep.context'

/**
 * @component
 * Page in PurchaseFlow step for configuring Power of Attorney for documents.
 * 
 * @example 
 * <PowerOfAttorneyStep />
 */
export const PowerOfAttorneyStep: FC = () => {
  const { isPowerOfAttorneyStepValid } = usePowerOfAttorneyStep()

  return (
    <DynamicFlowStep
      stepKey={PurchaseFlowStepKey.POWER_OF_ATTORNEY}
      isValid={isPowerOfAttorneyStepValid}
    >
      <PowerOfAttorneyStepController />
    </DynamicFlowStep>
  )
}
