import { ComputedStagingRoom, RoomInventory, StagingRoom, StagingType } from 'models/virtualStaging'
import { ImmutableMap, ImmutableSet } from 'models/helpers'
import { Reducer, useCallback, useMemo, useReducer, useRef } from 'react'
import { VirtualFurnitureRoomRemovableOption, VirtualFurnitureRoomType } from 'constants/virtualFurniture'

import constate from 'constate'
import { uniqueId } from 'lodash'

/** Possible actions for RoomInventory reducer */
enum RoomInventoryActionType {
  ADD_ROOM = 'ADD_ROOM',
  REMOVE_ROOM = 'REMOVE_ROOM',
  UPDATE_ROOM = 'UPDATE_ROOM',
  SET_ROOMS = 'SET_ROOMS'
}

/** Describes room inventory action */
interface RoomInventoryAction {
  type: RoomInventoryActionType
}

/** Action for adding room into inventory */
class AddAction implements RoomInventoryAction {
  readonly type = RoomInventoryActionType.ADD_ROOM
  constructor(
    public roomKey: string,
    public room: StagingRoom,
    public duplicityPolicy: 'skip' | 'overwrite'
  ) { }
}

/** Action for removing room from inventory */
class RemoveAction implements RoomInventoryAction {
  readonly type = RoomInventoryActionType.REMOVE_ROOM
  constructor(public roomKey: string) { }
}

/** Action for updating room in inventory */
class UpdateAction implements RoomInventoryAction {
  readonly type = RoomInventoryActionType.UPDATE_ROOM
  constructor(public roomKey: string, public roomData: Partial<StagingRoom>) { }
}

/** Action for setting content of entire room inventory */
class SetAction implements RoomInventoryAction {
  readonly type = RoomInventoryActionType.SET_ROOMS
  constructor(public inventory: RoomInventory) { }
}

/** Union type of possible actions */
type RoomInventoryActions = AddAction | RemoveAction | UpdateAction | SetAction

export const [RoomInventoryContextProvider, useRoomInventory] = constate(() => {

  const initialInventory: RoomInventory = {}
  const roomCreationSortingOrder = useRef<number>(0)

  /**
  * Checks whether the room has selected at least 1 room style.   
  * @param room - room or computed room object to be checked
  */
  const hasRoomSelectedAllTemplates = useCallback((room: StagingRoom | ComputedStagingRoom) => {
    return room.styleCodeMap.size === room.roomTypes.size
  }, [])

  /**
   * Checks if room is completed and ready to be submitted.   
   *    
   * Room is considered complete when following conditions are met:   
   *  - When no removal is selected - at least one of following things has to be selected:
   *    - style code for all room types
   *    - wall renovation
   *    - floor renovation
   *    - at least 1 decoration
   *  - When removal of anything is selected, room is considered finished without need of selecting any of the above
   * 
   * @param room - room or computed room object to be checked
   */
  const isRoomCompleted = useCallback((room: StagingRoom | ComputedStagingRoom) => {
    if (!room.roomTypes.size || !room.productId) return false

    const hasAllTemplates = hasRoomSelectedAllTemplates(room)

    // If furniture templates are being selected, all room types must be assigned one
    if (room.styleCodeMap.size > 0 && !hasAllTemplates) return false

    if (room.furnitureRemoval !== VirtualFurnitureRoomRemovableOption.NONE) return true

    // If clients select object removal, then toggle the BKBN decide, they can proceed the the staging to next step.
    if (room.furnitureRemoval === VirtualFurnitureRoomRemovableOption.NONE && room.type === StagingType.BKBN) return true

    return hasRoomSelectedAllTemplates(room)
      || !!room.renovations.get(VirtualFurnitureRoomType.WALL_MATERIALS)
      || !!room.renovations.get(VirtualFurnitureRoomType.FLOORING)
      || room.decorations.size > 0
  }, [hasRoomSelectedAllTemplates])

  /** Reducer function that handles updating roomInventory state according to dispatched actions */
  const reduceRoomInventory: Reducer<RoomInventory, RoomInventoryActions> = (state = initialInventory, action) => {
    switch (action.type) {
      case RoomInventoryActionType.ADD_ROOM: {
        if (!state[action.roomKey] || action.duplicityPolicy === 'overwrite') {

          return ({
            ...state,
            [action.roomKey]: action.room
          })
        }

        return { ...state }
      }

      case RoomInventoryActionType.REMOVE_ROOM: {
        const newInventory = { ...state }
        delete newInventory[action.roomKey]

        return newInventory
      }

      case RoomInventoryActionType.UPDATE_ROOM: {
        if (!state[action.roomKey]) return state

        const newRoom: StagingRoom = {
          ...state[action.roomKey],
          ...action.roomData
        }

        return ({
          ...state,
          [action.roomKey]: {
            ...newRoom,
            isComplete: isRoomCompleted(newRoom),
            hasSelectedAllStyleCodes: hasRoomSelectedAllTemplates(newRoom),
          }
        })
      }

      case RoomInventoryActionType.SET_ROOMS:
        return { ...action.inventory }

      default:
        return { ...state }
    }
  }

  /** Holds all rooms and data in string (roomKey) indexed object */
  const [roomInventory, dispatch] = useReducer(reduceRoomInventory, initialInventory)

  /**
   * Creates room object with default data
   * @param productId - mandatory since room must be associated with product, will be filled in the object
   * @param roomData - optional partial room object which will be merged into default room object
   * @returns StagingRoom object
  */
  const createRoomObject = useCallback((productId: number, roomData?: Partial<StagingRoom>) => {
    // Default unique id to be used as a roomKey
    const roomKey = uniqueId('room-')

    const newRoom: StagingRoom = {
      productId,
      key: roomKey,
      // TODO: replace with translation when/if we start using this property
      name: `Room ${roomCreationSortingOrder.current}`,
      propertyType: undefined,
      styleCategory: undefined,
      furnitureRemoval: VirtualFurnitureRoomRemovableOption.NONE,
      styleCodeMap: ImmutableMap([]),
      roomTypes: ImmutableSet([]),
      decorations: ImmutableSet([]),
      renovations: ImmutableMap([]),
      roomCreationSortingOrder: roomCreationSortingOrder.current,
      isComplete: false,
      hasSelectedAllStyleCodes: false,
      comment: null,
      type: StagingType.CUSTOM
    }

    roomCreationSortingOrder.current++

    return {
      ...newRoom,
      ...roomData,
    }
  }, [])

  /**
   * Adds provided room object into roomInventory, handles duplicity based on provided strategy
   * @param roomKey - key of room to use as an index in roomInventory
   * @param room - room object to be added into inventory
   * @param duplicityPolicy - *skip* will not add the room if duplicate exists in roomInventory | *overwrite* overwrites existing room with the new room object -- default is *skip*
   */
  const addRoom = useCallback((roomKey: string, room: StagingRoom, duplicityPolicy: 'skip' | 'overwrite' = 'skip') => {
    dispatch(new AddAction(roomKey, room, duplicityPolicy))
  }, [])

  /** Overwrites content of rooms object */
  const setRooms = useCallback((roomsToSet: RoomInventory) => {
    dispatch(new SetAction(roomsToSet))
  }, [])

  /**
   * Updates room object with partial roomData object using merge strategy
   * @param roomKey - key of room to be updated
   * @param roomData - partial room object containing data that the room is to be updated with
   */
  const updateRoom = useCallback((roomKey: string, roomData: Partial<StagingRoom>) => {
    dispatch(new UpdateAction(roomKey, roomData))
  }, [])

  /**
   * Removes room from inventory
   * @param roomKey - key of room to be removed
   */
  const removeRoom = useCallback((roomKey: string) => {
    dispatch(new RemoveAction(roomKey))
  }, [])

  /** Provide information whether all rooms are completed */
  const allRoomsCompleted = useMemo(() => Object.values(roomInventory).every(room => room.isComplete), [roomInventory])

  return {
    roomInventory,
    allRoomsCompleted,
    addRoom,
    updateRoom,
    setRooms,
    createRoomObject,
    removeRoom,
    isRoomCompleted,
  }
})
