import { FC, Fragment, useMemo } from 'react'

import { ThemeProvider } from '@mui/material'
import { WorkspacesProfileContextProvider } from './WorkspacesProfile.context'
import { WorkspacesProfileController } from './WorkspacesProfile.controller'
import { redesignMUITheme } from 'constants/styling/redesignTheme'
import { useParams } from 'react-router-dom'

export const WorkspacesProfilePage: FC = () => {
  const { id } = useParams<{ id?: string }>()
  const workspaceId = useMemo(() => (id && String(id)), [id])

  return (
    <Fragment>
      {typeof workspaceId !== 'undefined' &&
        <ThemeProvider theme={redesignMUITheme}>
          <WorkspacesProfileContextProvider workspaceId={workspaceId}>
            <WorkspacesProfileController workspaceId={workspaceId} />
          </WorkspacesProfileContextProvider>
        </ThemeProvider>
      }
    </Fragment>
  )
}
