import { Color, IconType } from 'constants/assets'
import { FC, Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { purgeUpdatePreferredPaymentMethod, updatePreferredPaymentMethod } from 'redux/Individual/User/UpdatePreferredPaymentMethod'
import { useDispatch, useSelector } from 'react-redux'

import { APIRequestState } from 'constants/API'
import { ActionTypeAPIData } from 'constants/redux'
import Button from 'components/common/Button/Button'
import Dropdown from 'components/common/Dropdown/Dropdown'
import Icon from 'components/common/Icon/Icon'
import { PreferredPaymentMethodEnum } from 'constants/user'
import { PreferredPaymentMethodType } from 'models/user/preferredPaymentMethod'
import ReactLoading from 'react-loading'
import { RootStore } from 'models/redux'
import styles from './PreferredPaymentMethodSelect.module.sass'
import { useAvailablePaymentMethods } from '../_main/contexts'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

interface Props {
  /** Class name to be added to main wrapper */
  className?: string
}

/**
 * @component Displays editable select prefilled with saved preferred payment method. Method can be changed and saved with included button.
 * @example
 *  <PreferredPaymentMethodSelect />
 */
export const PreferredPaymentMethodSelect: FC<Props> = ({
  className,
}) => {

  const { t } = useTranslation(['preferred_payment_method', 'product_kind'])
  const dispatch = useDispatch()

  const { clientData } = useUserData()
  const { availablePaymentMethods } = useAvailablePaymentMethods()

  // Append artificial NONE payment method
  const userAvailablePaymentMethods = useMemo(() => [...availablePaymentMethods, PreferredPaymentMethodEnum.NONE], [availablePaymentMethods])

  const userPreferredMethod = useMemo(() => clientData?.preferredPaymentMethod || PreferredPaymentMethodEnum.NONE, [clientData])
  const updateMethodActionState = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.UPDATE_PREFERRED_PAYMENT_METHOD].request.state)

  const [isPrefilled, setIsPrefilled] = useState<boolean>(false)
  const [preferredMethod, setPreferredMethod] = useState<PreferredPaymentMethodType>(PreferredPaymentMethodEnum.NONE)

  const handleValueChange = useCallback((method: PreferredPaymentMethodType) => {
    // Purge state if request already fired to remove success/error indication icon
    if (updateMethodActionState === APIRequestState.OK || updateMethodActionState === APIRequestState.ERROR) dispatch(purgeUpdatePreferredPaymentMethod())

    setPreferredMethod(method)
  }, [dispatch, updateMethodActionState])

  // Prefill select with saved method
  useLayoutEffect(() => {
    if (!isPrefilled && userPreferredMethod) {
      setPreferredMethod(userPreferredMethod)
      setIsPrefilled(true)
    }
  }, [isPrefilled, userPreferredMethod])

  // Purge update action state on destroy
  useEffect(() => {

    return () => {
      dispatch(purgeUpdatePreferredPaymentMethod())
    }
  }, [dispatch])

  return (
    <div className={`${styles.preferredPaymentMethodSelect} ${className}`}>

      <label htmlFor="preferred-payment-method-select">
        {t('label')}
      </label>

      <div className={styles.selectWrapper}>

        <Dropdown button={(isOpen, action) => (
          <Button type="secondary" onClick={action} textAndIcon={true}>
            <span>{t(`product_kind:${preferredMethod}`)}</span>
            <Icon icon={IconType.CARET_DOWN} className={`caret ${isOpen ? 'up' : 'down'}`} />
          </Button>
        )}>
          <Fragment>
            {userAvailablePaymentMethods.map(method => {
              const selected = method === preferredMethod
              return (
                <button
                  key={method.toString()}
                  className={selected ? 'selected' : ''}
                  onClick={() => handleValueChange(method)}
                >
                  {t(`product_kind:${method}`)}
                </button>
              )
            })}
          </Fragment>
        </Dropdown>

        {userPreferredMethod !== preferredMethod && updateMethodActionState !== APIRequestState.RUNNING &&
          <Button
            type="secondary"
            className={styles.saveButton}
            onClick={() => dispatch(updatePreferredPaymentMethod(preferredMethod))}>
            {t('save')}
          </Button>
        }

        {updateMethodActionState === APIRequestState.RUNNING &&
          <ReactLoading type="spin" className={styles.loading} color={Color.GRAY_SECONDARY} />
        }

        {updateMethodActionState === APIRequestState.OK &&
          <Icon icon={IconType.CHECK} className={styles.successIcon} color={Color.SECONDARY_GREEN_DARK} />
        }

        {updateMethodActionState === APIRequestState.ERROR &&
          <Icon icon={IconType.CROSS} className={styles.successIcon} color={Color.SECONDARY_RED_DARK} />
        }

      </div>

      {updateMethodActionState === APIRequestState.ERROR &&
        <p className={styles.error}>
          {t('error')}
        </p>
      }

    </div>
  )
}
