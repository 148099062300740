import { Auth0Roles, Auth0User } from 'models/auth'

import { UserRole } from 'constants/auth'
import { auth0ClientModule } from './auth0Context'

export const getRoles = async () => {
  return getRolesFromUser(await auth0ClientModule.getUser<NonNullable<Auth0User>>())
}

export const getInitRoles = (): Auth0Roles => {
  return {
    all: new Set(),
    isAdmin: false,
    isCreative: false,
    isClient: false,
  }
}

export const getRolesFromUser = (user: Auth0User) => {
  const result = getInitRoles()

  if (!process.env.REACT_APP_AUTH0_NAMESPACE) throw new Error('AUTH0_NAMESPACE env variable is missing')

  if (!user) return result
  if (!user[`${process.env.REACT_APP_AUTH0_NAMESPACE}/roles`]) return result

  const roles: Set<string> = new Set(user[`${process.env.REACT_APP_AUTH0_NAMESPACE}/roles`])

  result.all = roles
  if (roles.has(UserRole.ADMIN)) result.isAdmin = true
  if (roles.has(UserRole.CREATIVE)) result.isCreative = true
  if (roles.has(UserRole.CLIENT)) result.isClient = true

  return result
}