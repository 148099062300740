import { usePurchaseFlowConfig, usePurchaseFlowProducts } from 'components/pages/PurchaseFlow/_main/contexts'
import { FileManipulationKey, VisualFileType } from 'constants/visual'

import { CONST_PRODUCT_SERIAL } from 'constants/product'
import { ActionTypeAPIData } from 'constants/redux'
import constate from 'constate'
import { RootStore } from 'models/redux'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useDebouncedMemo } from 'utils/helpers'
import { useFloorPlanConfig } from './FloorPlanConfigStep.context'

export const [FloorPlanLogoProvider, useFloorPlanLogo] = constate(() => {
  const { sessionId } = usePurchaseFlowConfig()
  const { companyLogo } = useFloorPlanConfig()
  const { floorPlanProduct } = usePurchaseFlowProducts()

  const productId = useMemo(() => floorPlanProduct?.id ?? 0, [floorPlanProduct])
  const pfSessionId = useMemo(() => sessionId ?? '', [sessionId])

  const listedPurchaseSessionVisuals = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.LIST_PURCHASE_SESSION_VISUALS]?.[pfSessionId])
  const loadPurchaseSessionVisuals = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.LOAD_PURCHASE_SESSION_VISUAL]?.[pfSessionId])

  const listedProductVisuals = useMemo(() => listedPurchaseSessionVisuals?.data?.visuals?.[productId] ?? [], [listedPurchaseSessionVisuals, productId])
  const downloadProductVisuals = useMemo(() => loadPurchaseSessionVisuals?.[productId]?.[CONST_PRODUCT_SERIAL]?.[FileManipulationKey.DOWNLOAD] ?? {}, [loadPurchaseSessionVisuals, productId])
  const uploadProductVisuals = useMemo(() => loadPurchaseSessionVisuals?.[productId]?.[CONST_PRODUCT_SERIAL]?.[FileManipulationKey.UPLOAD] ?? {}, [loadPurchaseSessionVisuals, productId])

  // Download and upload visuals entries only for floor plan logo
  const downloadVisualsEntries = useMemo(() => (downloadProductVisuals ? Object.entries(downloadProductVisuals).filter(([key, allTypes]) => allTypes?.[VisualFileType.RAW_THUMB] && companyLogo === allTypes?.[VisualFileType.RAW_THUMB].originalFilename) : []), [companyLogo, downloadProductVisuals])
  const uploadVisualsEntries = useMemo(() => (uploadProductVisuals ? Object.entries(uploadProductVisuals).filter(([key, allTypes]) => allTypes?.[VisualFileType.RAW]) : []), [uploadProductVisuals])
  const downloadVisualsKeys = useMemo(() => (downloadVisualsEntries.filter(([key, allTypes]) => allTypes?.[VisualFileType.RAW_THUMB]).map(([key, allTypes]) => key)), [downloadVisualsEntries])
  const uploadVisualsKeys = useMemo(() => (uploadVisualsEntries.filter(([key, allTypes]) => allTypes?.[VisualFileType.RAW]).map(([key, allTypes]) => key)), [uploadVisualsEntries])
  const uploadVisualsOriginalFilenames = useMemo(() => Object.values(uploadProductVisuals ?? {}).map(visual => visual?.[VisualFileType.RAW]?.originalFilename ?? '').filter(filename => !!filename), [uploadProductVisuals])
  const visualsKeys = useMemo(() => [...uploadVisualsKeys, ...downloadVisualsKeys], [uploadVisualsKeys, downloadVisualsKeys])
  const visualsCount = useDebouncedMemo(() => visualsKeys.length || 0, [visualsKeys], 100)
  const listedVisualKeys = useMemo(() => (listedProductVisuals ?? []), [listedProductVisuals])

  const isTemplateLogoUploading = useMemo(() => uploadVisualsEntries.length > 0, [uploadVisualsEntries.length])

  return {
    productId,
    downloadVisualsEntries,
    uploadVisualsEntries,
    downloadVisualsKeys,
    uploadVisualsKeys,
    uploadVisualsOriginalFilenames,
    visualsKeys,
    visualsCount,
    listedVisualKeys,
    isTemplateLogoUploading
  }
})
