import { BEIGE_300, BEIGE_400, BEIGE_600, GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { Box, Stack, Typography } from '@mui/material'
import { FC, useCallback } from 'react'

import { AnalyticsEvent } from 'utils/analytics'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { MUIButton } from 'components/common/MUIButton'
import { useRoomInventory } from 'components/contexts/RoomInventory.context'
import { useStyleSelection } from '../main/contexts'
import { useTranslation } from 'react-i18next'

/** 
 * @component
 * StagingSpecialRequestSection displaying staging comments which can be edited and deleted.
 * 
 * @example
 * <StagingSpecialRequestSection />
 */
export const StagingSpecialRequestSection: FC = () => {
  const { t } = useTranslation(['style_selection_order_step', 'common'])

  const {
    setIsSpecialRequestPopupOpen,
    stagingFlowAnalytics,
    activeRoom,
  } = useStyleSelection()

  const { updateRoom } = useRoomInventory()

  const onEditComment = useCallback(() => {
    stagingFlowAnalytics(AnalyticsEvent.STAGING_EDIT_COMMENT_POPUP)
    setIsSpecialRequestPopupOpen(true)
  }, [stagingFlowAnalytics, setIsSpecialRequestPopupOpen])

  const onRemoveComment = useCallback(() => {
    stagingFlowAnalytics(AnalyticsEvent.STAGING_REMOVE_COMMENT)
    updateRoom(activeRoom.key, { comment: null })
  }, [stagingFlowAnalytics, activeRoom.key, updateRoom])

  return (
    <BorderBoxWrapper padding={2} height="100%" width="100%">

      <Stack gap="1.6rem">

        <Box paddingBottom="1.2rem" borderBottom={`.1rem solid ${BEIGE_600}`}>
          <Typography variant="text-md" fontWeight="semiBold" color={GRAY_900}>
            {t('special_request.title')}
          </Typography>
        </Box>

        <Typography variant="text-sm" color={GRAY_700}>
          {t('special_request.request_note')}
        </Typography>

        <BorderBoxWrapper padding={2} height="100%" width="100%" backgroundColor={BEIGE_300} borderColor={BEIGE_400} elevation="none">
          <Typography variant="text-sm" color={GRAY_900}>
            {activeRoom.comment}
          </Typography>
        </BorderBoxWrapper>

        <Stack gap=".8rem" flexDirection="row" justifyContent="flex-end">
          <MUIButton
            type="secondaryBorder"
            size="sm"
            startIcon={<EditOutlinedIcon fontSize="large" />}
            onClick={onEditComment}
          >
            {t('Edit')}
          </MUIButton>

          <MUIButton
            isIconButton
            type="orangeSecondaryNoBorder"
            size="sm"
            startIcon={<DeleteOutlineIcon fontSize="large" />}
            onClick={onRemoveComment}
          />
        </Stack>

      </Stack>

    </BorderBoxWrapper>
  )
}
