import { FC, ReactNode } from 'react'

import styles from './PageTabs.module.sass'

interface Props {
  children: ReactNode
}

/**
 * Page tabs component.
 * @example <PageTabs><Tab></PageTabs>
 */
export const PageTabs: FC<Props> = ({ children }) =>
  <div className={styles.pageTabs}>
    {children}
  </div>
