import { APIRequestErrorType, JobStatus } from 'constants/API'

import { AxiosResponse } from 'axios'
import { JobDTO } from 'models/misc'
import { useAPI } from 'utils/API'
import { decideErrorType } from 'utils/APIDecideErrorType'
import { getMutation } from 'utils/reactQuery'

enum Endpoints {
  GET_JOB = '/job/{id}'
}

/**
 * Mutation with flow for express visuals editing
 * Use a jobID from submit result and start longPolling it.
 * Resolve if long-polling succeeds
 * Reject otherwise
*/
export function useJobLongPoll() {
  const api = useAPI<Endpoints>()

  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

  return getMutation(
    async (id: string) => {

      const isError = (res: AxiosResponse) => {
        if (!res.request?.error) return false
        return decideErrorType(res.request.error) !== APIRequestErrorType.NONE
      }

      // Long poll preparations
      let retries = 100
      let done = false
      let error = false
      let poll: AxiosResponse<JobDTO>

      // Long-poll until job is Finished, Failed or Retry limit has been reached
      do {
        poll = await api.get<JobDTO>(Endpoints.GET_JOB, { id }, true)

        if ((!poll.status || isError(poll) || ![JobStatus.FINISHED, JobStatus.FAILED].includes(poll.data.status)) && retries-- > 0) {
          await delay(2000)
          continue
        }

        if (poll.data.status === JobStatus.FAILED || isError(poll) || retries <= 0) error = true
        done = true
      } while (done === false)

      if (error === true) {
        if (!poll.request.error && retries <= 0) {
          return Promise.reject({
            ...poll,
            response: {
              data: {
                status: 400,
                message: 'Polling retry limit reached',
                timestamp: new Date().getTime()
              }
            }
          })
        }
        return Promise.reject(poll.request.error)
      }

      return Promise.resolve(true)
    }
  )
}
