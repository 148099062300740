import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { FC, useCallback, useMemo } from 'react'
import { ProductKindsWithCheckbox } from 'constants/product'

import { MUICheckbox } from 'components/common/MUICheckBox'
import { Stack } from '@mui/material'
import { usePurchaseFlowOrderMeta } from 'components/pages/PurchaseFlow/_main/contexts/PurchaseFlowOrderMeta.context'
import { useTargetOrderUser } from 'components/pages/PurchaseFlow/_main/contexts'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

enum CheckboxTarget {
  ME = 'ME',
  OTHER_CLIENT = 'OTHER_CLIENT'
}

interface Props {
  /** For what meeting type is checkbox rendered */
  meetingType: ProductKindsWithCheckbox
}

/** 
 * Renders checkbox where user can select who will be waiting on-site
 * On select order target user contact information is prefilled for other fields in MeetingInstructionsFields.component
 *
 * DEPENDENCIES:
 * - MeetingInstructionsContextProvider
 * 
 * @example
 * <MeetingInstructionsContactPersonCheckbox
 *   instructionOption={instructionOption}
 * />
*/
export const MeetingInstructionsContactPersonCheckbox: FC<Props> = ({
  meetingType
}) => {
  const { t } = useTranslation(['order'])
  const { baseUserData } = useUserData()
  const { targetUser } = useTargetOrderUser()
  const { meetingInfoFillInCheckedMap, toggleFillInMeetingInfo } = usePurchaseFlowOrderMeta()

  const target = useMemo(() => targetUser.email !== baseUserData?.email
    ? CheckboxTarget.OTHER_CLIENT
    : CheckboxTarget.ME, [baseUserData?.email, targetUser.email])

  const onCheckFillInMeetingInfo = useCallback((meetingType: ProductKindsWithCheckbox) => {
    if (target === CheckboxTarget.ME) {
      logAnalyticsEvent(AnalyticsEvent.PURCHASE_FLOW_I_ON_SITE_SELECTED, { organizationMethod: meetingType })
    }

    toggleFillInMeetingInfo(meetingType)
  }, [target, toggleFillInMeetingInfo])

  return (
    <Stack direction="row" gap={1} alignItems="center">

      <MUICheckbox
        id={`fill-in-meeting-info-${meetingType}`}
        checked={meetingInfoFillInCheckedMap[meetingType]}
        onChange={() => onCheckFillInMeetingInfo(meetingType)}
      />
      <label

        htmlFor={`fill-in-meeting-info-${meetingType}`}
        style={{
          margin: '0',
          cursor: 'pointer',
        }}
      >
        {t(`step_instruction.meeting_info_checkbox.${meetingType}.${target}`)}
      </label>

    </Stack>
  )
}
