export const patternList = [
  {
    title: 'Original',
    description: null,
    ratio: '',
    value: 'ORIGINAL'
  },
  {
    title: '16:9',
    description: null,
    ratio: '16/9',
    value: 'RATIO_16_9'
  },
  {
    title: '4:3',
    description: null,
    ratio: '4/3',
    value: 'RATIO_4_3'
  },
  {
    title: '1:1',
    description: 'social_media_square',
    ratio: '1',
    value: 'RATIO_1_1'
  },
  {
    title: '4:5',
    description: 'instagram',
    ratio: '4/5',
    value: 'RATIO_4_5'
  },
  {
    title: '3:6',
    description: 'reels',
    ratio: '3/6',
    value: 'RATIO_3_6'
  }
]
