import { BasementType, ConstructionType, HeatingCombinationType, InformationType, VentilationSystemType } from 'constants/purchaseFlow'
import { BuildingType, GlobalShapeType, PhotovoltaicSystemType, VentilationType, handoverAndPumpingFields } from 'constants/purchaseFlow/energyCertificateOfNeedTypes'
import { EnergyCertificateDetailsDTO, HeatingSystemItemPayloaDTO } from 'models/purchaseFlow/energyCertificateOfNeedDTO'
import { useCallback, useMemo, useState } from 'react'

import constate from 'constate'
import { useAdditionalInformation } from 'components/pages/PurchaseFlow/common/AdditionalInformation'
import { useBuildingLayout } from '../BuildingLayout'
import { useConstructionMethod } from '../ConstructionMethod'
import { usePropertyInformation } from '../PropertyInformation'
import { useVentilationAndHeating } from '../VentilationAndHeating'

export const [EnergyCertificateStepContextProvider, useEnergyCertificateStep] = constate(() => {
  const [infoType, setInfoType] = useState<InformationType>(InformationType.PROPERTY_INFORMATION)

  const {
    buildingType,
    buildingLocationType,
    residentialUnit,
    constructionYear,
    livingSpace,
    commercialArea,
    commercialAreaType,
  } = usePropertyInformation()

  const {
    globalShapeType,
    orientationType,
    walls,
    buildingShapeUpload
  } = useBuildingLayout()

  const {
    constructionType,
    basementType,
    lightweightConstructionType,
    roofConditionType,
    solidConstructionType,
    basementCeilingType,
    windowType,
    thermalBridgeType,
    subsequentRoofInsulation,
    subsequentBasementInsulation,
    roofPitchType,
    numberOfFullFloors,
    floorHeight,
    yearOfManufacture,
    soilDepth,
    heatedBasementArea
  } = useConstructionMethod()

  const {
    ventilationAndHeatingSystem,
    photovoltaicFeatures,
    ventilationFeatures,
    photovoltaicSystem,
  } = useVentilationAndHeating()

  const {
    exteriorViewUpload,
    heaterNameplateUpload,
    message,
    energyCertificateReason,
  } = useAdditionalInformation()


  const isPropertyInformationValid = useMemo(() => {
    return !(
      !buildingType ||
      (buildingType === BuildingType.MIXED_USE_BUILDING && (!commercialArea || !commercialAreaType)) ||
      !buildingLocationType ||
      !constructionYear || constructionYear.length !== 4 ||
      !livingSpace
    )
  }, [buildingLocationType, buildingType, commercialArea, commercialAreaType, constructionYear, livingSpace])

  const isBuildingLayoutValid = useMemo(() => {
    if (!globalShapeType || !orientationType) return false

    if (globalShapeType !== GlobalShapeType.OTHER) {
      // Check mandatory length and optional fields for insulation which become mandatory only if the wall is "insulated"
      if (Object.values(walls).some(wall => !wall.length)) return false
      if (Object.values(walls).some(wall => wall.insulated && !wall.insulation)) return false

    } else {
      if (!buildingShapeUpload.allFilesArray.length) return false
    }

    return true
  }, [buildingShapeUpload.allFilesArray.length, globalShapeType, orientationType, walls])

  const isConstructionMethodValid = useMemo(() => {
    if (!constructionType ||
      (constructionType === ConstructionType.LIGHTWEIGHT_CONSTRUCTION && !lightweightConstructionType) ||
      (constructionType === ConstructionType.MASSIVE_CONSTRUCTION && !solidConstructionType) ||
      !roofConditionType ||
      !roofPitchType ||
      !numberOfFullFloors ||
      floorHeight === '' ||
      !yearOfManufacture ||
      !windowType ||
      !thermalBridgeType ||
      !basementType ||
      ((basementType === BasementType.HEATED_WINDOW_SOLAR_GAIN || basementType === BasementType.HEATED_NO_WINDOW) && (!soilDepth || !heatedBasementArea)) ||
      !basementCeilingType) return false

    return true
  }, [basementCeilingType, basementType, constructionType, floorHeight, heatedBasementArea, lightweightConstructionType, numberOfFullFloors, roofConditionType, roofPitchType, soilDepth, solidConstructionType, thermalBridgeType, windowType, yearOfManufacture])

  const isEnergyAndHeatingValid = useMemo(() => {
    const { areaOfFacility, peakPower, orientationType } = photovoltaicFeatures
    const { distribution, ventilation, fans, heatRecovery, ventilationSystem } = ventilationFeatures

    if (photovoltaicSystem === PhotovoltaicSystemType.YES && (!areaOfFacility || !orientationType || !peakPower)) return false

    if (!ventilationSystem?.size) return false

    if ((!!ventilationSystem?.size && ventilationSystem.has(VentilationSystemType.VENTILATION_SYSTEM_AVAILABLE)
      && ((!distribution || !ventilation || !fans || (ventilation !== VentilationType.EXHAUST_SYSTEM_NO_HEAT_RECOVERY && !heatRecovery))))
    ) return false

    const validateHandoverAndPumping = !!ventilationAndHeatingSystem.some(({ heatingFuel }) => heatingFuel ? handoverAndPumpingFields?.has(heatingFuel) : false)

    if (validateHandoverAndPumping && ventilationAndHeatingSystem.some(({
      circulatingPumpType,
      heatingPipeType,
      heatTransferType,
    }) => !circulatingPumpType || !heatingPipeType || !heatTransferType)) return false

    const isCentralHotWaterGeneration = ventilationAndHeatingSystem.some(({ heatingCombinedWith }) => heatingCombinedWith.has(HeatingCombinationType.CENTRAL_HOT_WATER_GENERATION))

    if (isCentralHotWaterGeneration && ventilationAndHeatingSystem.some(({ waterLocation, generatorYearOfManufacture }) => !waterLocation || !generatorYearOfManufacture)) return false
    if (!isCentralHotWaterGeneration && ventilationAndHeatingSystem.some(({ generatedBy, yearOfConstruction, decentralizedHeatingType }) => !generatedBy || !yearOfConstruction || !decentralizedHeatingType)) return false

    return ventilationAndHeatingSystem.every(({
      heaterLocation,
      nightService,
      heatingFuel,
      heatingFuelOption,
      totalHeatingOutput,
    }) =>
      [heatingFuel, heatingFuelOption, heaterLocation, nightService, totalHeatingOutput].every(Boolean)
    )
  }, [photovoltaicFeatures, photovoltaicSystem, ventilationAndHeatingSystem, ventilationFeatures])

  const isAdditionalInformationValid = useMemo(() => {
    if (!exteriorViewUpload.allFilesArray.length || !heaterNameplateUpload.allFilesArray.length) return false

    return true
  }, [exteriorViewUpload.allFilesArray.length, heaterNameplateUpload.allFilesArray.length])

  const isBasementTypeHeated = basementType === BasementType.HEATED_WINDOW_SOLAR_GAIN || basementType === BasementType.HEATED_NO_WINDOW

  const getEnergyCertificateRequestData = useCallback((): EnergyCertificateDetailsDTO => {

    const heaters: HeatingSystemItemPayloaDTO[] = ventilationAndHeatingSystem.toArray().map(([_, {
      isHydraulicBalancing,
      heatingFuel,
      heatingFuelOption,
      heatingDesignation,
      heaterLocation,
      waterLocation,
      nightService,
      heatTransferType,
      heatingPipeType,
      circulatingPumpType,
      generatedBy,
      generatorYearOfManufacture,
      heatingPower,
      yearOfConstruction,
      decentralizedHeatingType,
      totalHeatingOutput,
      heatingCombinedWith,
      isHotWaterCirculation,
      woodPalletsEnergySource,
    }]) => {
      const sendHandoverAndPumpingData = !!ventilationAndHeatingSystem.some(({ heatingFuel }) => heatingFuel ? handoverAndPumpingFields?.has(heatingFuel) : false)

      return {
        yearOfConstruction: generatorYearOfManufacture,
        location: heaterLocation,
        nightSwitch: nightService,
        typeDesignation: heatingDesignation,
        heatingType: heatingFuel,
        heatingSubType: heatingFuelOption,
        energySource: woodPalletsEnergySource,
        heatTransfer: sendHandoverAndPumpingData ? heatTransferType : null,
        heatingPipe: sendHandoverAndPumpingData ? heatingPipeType : null,
        circulatingPump: sendHandoverAndPumpingData ? circulatingPumpType : null,
        decentralizedHotWater: !heatingCombinedWith.has(HeatingCombinationType.CENTRAL_HOT_WATER_GENERATION) ? {
          energySource: generatedBy,
          constructionYear: yearOfConstruction,
          type: decentralizedHeatingType,
        } : undefined,
        centralHotWater: heatingCombinedWith.has(HeatingCombinationType.CENTRAL_HOT_WATER_GENERATION) ? {
          location: waterLocation,
          constructionYear: yearOfConstruction,
          hotWaterCirculation: isHotWaterCirculation,
        } : undefined,
        solarThermalHeatingSupport: heatingCombinedWith.has(HeatingCombinationType.SOLAR_THERMAL_ENERGY_FOR_HEATING_SUPPORT),
        solarThermalHotWaterSupport: heatingCombinedWith.has(HeatingCombinationType.SOLAR_THERMAL_ENERGY_FOR_HOT_WATER_SUPPORT),
        isHydraulicBalancingDone: isHydraulicBalancing,
        powerKw: heatingPower || 0,
        shareInTotalHeatingPower: totalHeatingOutput || 0,
        hasCentralHotWaterGeneration: heatingCombinedWith.has(HeatingCombinationType.CENTRAL_HOT_WATER_GENERATION),
      }
    })

    return {
      // Property info
      buildingType,
      livingUnits: residentialUnit,
      buildingLocation: buildingLocationType,
      totalLivingArea: livingSpace,
      commercialSpaceInSquareMeters: commercialArea,
      typeOfUseCommercial: commercialAreaType,
      // Building layout
      orientation: orientationType,
      buildingYear: constructionYear,
      buildingShape: globalShapeType,
      wallDimensions: Object.values(walls).map(wall => ({
        length: Number(wall.length),
        subsequentlyBuilt: wall.mounted,
        subsequentlyInsulated: wall.insulated,
        wallInsulation: Number(wall.insulation),
      })),
      // Construction method
      numberFullFloors: numberOfFullFloors,
      roofCondition: roofConditionType,
      floorHeightMeters: Number(floorHeight),
      roofPitch: roofPitchType,
      subsequentBasementInsulation: subsequentRoofInsulation ?? 0,
      subsequentRoofInsulation: subsequentBasementInsulation ?? 0,
      basement: basementType,
      soilDepth: isBasementTypeHeated ? Number(soilDepth) : null,
      heatedBasementArea: isBasementTypeHeated ? heatedBasementArea : null,
      basementCeilingType,
      windowConstructionYear: yearOfManufacture,
      windowType,
      constructionType,
      wallType: constructionType === ConstructionType.LIGHTWEIGHT_CONSTRUCTION ? lightweightConstructionType : solidConstructionType,
      thermalBridges: thermalBridgeType,
      // Energy and heating
      photovoltaics: photovoltaicSystem === PhotovoltaicSystemType.YES ? {
        area: Number(photovoltaicFeatures.areaOfFacility),
        peakPower: Number(photovoltaicFeatures.peakPower),
        geographicOrientation: photovoltaicFeatures.orientationType,
      } : null,
      hasWindowVentilation: ventilationFeatures.ventilationSystem.has(VentilationSystemType.WINDOW_VENTILATION),
      airDistribution: ventilationFeatures.distribution,
      ventilationType: ventilationFeatures.ventilation,
      fanType: ventilationFeatures.fans,
      heaters,
      heatRecovery: ventilationFeatures.heatRecovery,
      // Additional information
      reasonForEnergyPass: energyCertificateReason,
      messageToExpert: message,
    }
  }, [basementCeilingType, basementType, buildingLocationType, buildingType, commercialArea, commercialAreaType, constructionType, constructionYear, energyCertificateReason, floorHeight, globalShapeType, heatedBasementArea, isBasementTypeHeated, lightweightConstructionType, livingSpace, message, numberOfFullFloors, orientationType, photovoltaicFeatures.areaOfFacility, photovoltaicFeatures.orientationType, photovoltaicFeatures.peakPower, photovoltaicSystem, residentialUnit, roofConditionType, roofPitchType, soilDepth, solidConstructionType, subsequentBasementInsulation, subsequentRoofInsulation, thermalBridgeType, ventilationAndHeatingSystem, ventilationFeatures.distribution, ventilationFeatures.fans, ventilationFeatures.heatRecovery, ventilationFeatures.ventilation, ventilationFeatures.ventilationSystem, walls, windowType, yearOfManufacture])

  return {
    infoType,
    setInfoType,
    isPropertyInformationValid,
    isBuildingLayoutValid,
    isConstructionMethodValid,
    isEnergyAndHeatingValid,
    isAdditionalInformationValid,
    getEnergyCertificateRequestData
  }
})
