import { EntityKeys, QueryType } from 'utils/reactQuery'
import { SubscriptionPeriod, SubscriptionPlan } from 'models/user/userWorkspaceDTOs'

import { Path } from 'constants/router/paths'
import { useAPI } from 'utils/API'
import { useQuery } from '@tanstack/react-query'

enum Endpoints {
  GET_SUBSCRIPTION_CHECKOUT_LINK = '/workspace/{workspaceId}/subscribe',
  GET_SUBSCRIPTION_PORTAL_LINK = '/workspace/{workspaceId}/subscription/manage',
}

interface SubscriptionCheckoutDetails {
  plan: SubscriptionPlan
  period: SubscriptionPeriod
}

// QUERIES

// AddWorkspaceMembers saga, createWorkspace saga & CreateWorkspaceSubscription saga has to be rewritten in 
// task BKBN-634 (https://backbonephoto.atlassian.net/browse/BKBN-634) in order to use this RQ hook
export function useGetCheckoutLink(workspaceId: string, subscriptionDetails: SubscriptionCheckoutDetails) {
  const api = useAPI<Endpoints>()

  // Ensure escape for safety
  const returnUrl = encodeURI(`${window.location.origin}${Path.SUBSCRIPTION_STATUS}`)

  return useQuery({
    queryKey: [EntityKeys.CHECKOUT_LINK, QueryType.GET, { ...subscriptionDetails, returnUrl }],
    queryFn: () => api.post<{ subscriptionDetails: SubscriptionCheckoutDetails, returnUrl: string }>(
      Endpoints.GET_SUBSCRIPTION_CHECKOUT_LINK,
      { workspaceId: workspaceId?.toString() || '' },
      {
        ...subscriptionDetails,
        returnUrl
      },
      false
    )
  })
}

export function useGetPortalLink(workspaceId: string, returnUrl: string) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.PORTAL_LINK, QueryType.GET],
    queryFn: () => api.post<{ url: string }>(
      Endpoints.GET_SUBSCRIPTION_PORTAL_LINK,
      { workspaceId: workspaceId?.toString() || '' },
      {
        // Ensure escape for safety
        returnUrl: encodeURI(returnUrl)
      },
      false
    ),
    // Makes query not run automatically
    enabled: false
  })
}
