import { matchPath, useLocation } from 'react-router-dom'
import { useEffect, useMemo } from 'react'

import { NEW_DESIGN_PATHS } from 'constants/router'
import constate from 'constate'

const useAppStyleSwitchSetup = () => {
  const { pathname } = useLocation()

  // use react-router matching function to check if current path is included in list of paths with new design
  const routeHasNewDesign = useMemo(() => NEW_DESIGN_PATHS.some(path => !!matchPath(pathname, path)), [pathname])

  // change bkbn-style attr on html tag to reflect which style to be used accordingly to current route
  // (old styles are scoped using css selector to value 'old')
  useEffect(() => {
    const htmlEl = document.getElementsByTagName('html')[0]
    if (!htmlEl) return

    if (routeHasNewDesign) htmlEl.setAttribute('bkbn-style', 'new')
    else htmlEl.setAttribute('bkbn-style', 'old')
  }, [pathname, routeHasNewDesign])

  return {
    routeHasNewDesign
  }
}

export const [AppStyleSwitchProvider, useAppStyleSwitch] = constate(useAppStyleSwitchSetup)
