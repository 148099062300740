import { FC, Fragment, useMemo } from 'react'

import { VisualsMarketingTargetPlatform } from '../../_main/VisulalsMarketing.constants'
import moment from 'moment-timezone'
import { useMarketingMaterials } from '../../_main/contexts'
import { useTimezone } from 'components/contexts/timezone.context'
import { useTranslation } from 'react-i18next'

/**
 * Props for the SocialMediaPostInput component.
 * @interface Props
 */
interface Props {
  platform: VisualsMarketingTargetPlatform
}

/**
 * @component
 * Displays the input or preview of a social media post.
 * 
 * @example
 * <SocialMediaPostInput platform={VisualsMarketingTargetPlatform.LINKEDIN} />
 */
export const SocialMediaPostInput: FC<Props> = ({ platform }) => {

  const { t } = useTranslation(['visual_marketing_social_media'])
  const { userTimezone } = useTimezone()
  const { marketingMaterials, editForms, onEditFieldChange, openedEdits, editors } = useMarketingMaterials()

  const prefix = useMemo(() => {
    if (platform === VisualsMarketingTargetPlatform.INSTAGRAM) {
      return <><strong>AwesomeRealEstate</strong>&nbsp;</>
    }

    return null
  }, [platform])

  return (
    <Fragment>

      {openedEdits[platform] &&
        <textarea
          name={`${platform}-textarea`}
          id={`${platform}-textarea`}
          rows={20}
          value={editForms[platform]}
          onChange={(e) => onEditFieldChange(platform, e.target.value)}
          style={{
            resize: 'vertical'
          }}
          disabled={editors[platform].isPending}
        >
        </textarea>
      }

      {!openedEdits[platform] &&
        <>
          <pre>{prefix}{marketingMaterials[platform]?.text || ''}</pre>
          {!!marketingMaterials[platform]?.lastUpdated &&
            <span style={{ display: 'block', marginTop: '2rem' }}>
              {t('updated', { date: moment(marketingMaterials[platform]?.lastUpdated).tz(userTimezone).format('DD. MM. YYYY HH:mm') })}
            </span>
          }
        </>
      }

    </Fragment>
  )
}
