import { Box, IconButton, Stack } from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { WorkingHoursError, useWorkingHoursAPI } from '../../WorkingHoursAPI.context'

import AddIcon from '@mui/icons-material/Add'
import { CreativeWorkingHours } from 'models/creative/creativeWorkingHoursDTO'
import DatePicker from 'react-datepicker'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import classnames from 'classnames'
import { getUserDatePickerLocale } from 'utils/localization'
import { isEmpty } from 'lodash'
import moment from 'moment'
import styles from './WorkingHoursDrawerItem.module.sass'

/** Creative's working hours details */
export interface WorkingTimeItem {
  [key: string]: CreativeWorkingHours
}

/** 
 * @interface Props Input properties
 */
interface Props {
  /** Key of the weekday */
  dayKey: string
}

/**
 * @component WorkingHoursDrawerItem displays details of creative's working hours to edit
 * @example
 * <WorkingHoursDrawerItem dayKey="MONDAY" />
 */
export const WorkingHoursDrawerItem: React.FC<Props> = ({ dayKey }) => {
  const { t } = useTranslation(['profile'])

  const { workingHours, addSlot, editSlot, removeSlot } = useWorkingHoursAPI()

  const dayData = useMemo(() => workingHours[dayKey], [dayKey, workingHours])

  const getSelectedDateTime = useCallback((timeString: string) => {
    const [h, m] = timeString.split(':')
    const date = new Date()
    date.setHours(+h)
    date.setMinutes(+m)
    return date
  }, [])

  return (
    <div>

      <Stack
        gap={1}
        width="100%"
        direction="column"
      >

        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >

          {/** DAY OF THE WEEK */}
          <Trans
            t={t}
            parent="h3"
            className={classnames(styles.dayTitle, { [styles.errorText]: !!dayData.error })}
            i18nKey={`work_preferences.working_hours.${dayKey}`}
          />

          <IconButton className={styles.iconAdd} onClick={() => addSlot(dayKey)}>
            <AddIcon fontSize="large" />
          </IconButton>

        </Stack>

        {/** HOURS PICKER */}
        <Stack direction="column" gap={1}>
          {!isEmpty(dayData.slots)
            ? Object.entries(dayData.slots).map(([slotId, { hours, error }]) =>

              <Stack
                key={slotId}
                spacing={1}
                direction="row"
                alignItems="center"
              >

                {/** Starting and ending hour */}
                <div className={styles.timePickerWrapper}>

                  <Box>
                    <DatePicker
                      selected={hours.from ? getSelectedDateTime(hours.from) : null}
                      onChange={(date) => !!date && editSlot(dayKey, slotId, { from: moment(date).format('HH:mm') })}
                      className={classnames(styles.picker, { [styles.hasError]: error })}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      autoComplete="off"
                      strictParsing
                      timeFormat="HH:mm"
                      dateFormat="HH:mm"
                      placeholderText="HH:MM"
                      injectTimes={[moment().endOf('day').toDate()]}
                      locale={getUserDatePickerLocale()}
                    />
                  </Box>

                  <span>-</span>

                  <Box>
                    <DatePicker
                      selected={hours.to ? getSelectedDateTime(hours.to) : null}
                      className={classnames(styles.picker, { [styles.hasError]: error })}
                      onChange={(date) => !!date && editSlot(dayKey, slotId, { to: moment(date).format('HH:mm') })}
                      minTime={hours.from ? moment(getSelectedDateTime(hours.from)).add(1, 'minute').toDate() : undefined}
                      maxTime={hours.from ? moment(getSelectedDateTime(hours.from)).endOf('day').toDate() : undefined}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      autoComplete="off"
                      strictParsing
                      timeFormat="HH:mm"
                      dateFormat="HH:mm"
                      placeholderText="HH:MM"
                      injectTimes={[moment().endOf('day').toDate()]}
                      locale={getUserDatePickerLocale()}
                    />
                  </Box>

                </div>

                <IconButton
                  className={styles.iconDelete}
                  onClick={() => removeSlot(dayKey, slotId)}
                >
                  <DeleteOutlineOutlinedIcon fontSize="large" />
                </IconButton>

              </Stack>

            )
            : <span className={styles.notFoundText}>{t('work_preferences.unavailable')}</span>
          }
        </Stack>

        {dayData.error === WorkingHoursError.SLOT_COLLISIONS &&
          <span className={styles.errorText}>
            {t('work_preferences.working_hours_drawer.error_overlaping')}
          </span>
        }

      </Stack>

    </div>
  )

}
