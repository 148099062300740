import { Power2, gsap } from 'gsap'

/** The standard widely used from {autoAlpha: 0, height: 0} to {autoAlpha: 1, height: 'auto'} in 0.3s Power2.easeIn effect */
export const standardEnterEffect = (node: HTMLElement) => {
  if (!node) return
  if (!node.classList) return
  const timelineEnter = gsap.timeline({ paused: true })
  timelineEnter.totalProgress(1).clear(true).totalProgress(0)
  node.classList.add('entered')
  timelineEnter.fromTo(node, { autoAlpha: 0, height: 0, ease: Power2.easeIn }, { autoAlpha: 1, height: 'auto', ease: Power2.easeIn, duration: 0.3 })
  timelineEnter.play()
}

/** The from {autoAlpha: 0, height: 0} to {autoAlpha: 1, height: 'auto'} in 0.3s Power2.easeIn with tween 1s effect */
export const standardEnterEffectWithTween = (node: HTMLElement) => {
  if (!node) return
  if (!node.classList) return
  const timelineEnter = gsap.timeline({ paused: true })
  timelineEnter.totalProgress(1).clear(true).totalProgress(0)
  node.classList.add('entered')
  timelineEnter.fromTo(node, { autoAlpha: 0, height: 0, ease: Power2.easeIn }, { autoAlpha: 1, height: 'auto', ease: Power2.easeIn, duration: 0.3 }, '+=1')
  timelineEnter.play()
}

/** The standard widely used to {autoAlpha: 0, height: 0} in 0.3s Power2.easeIn effect */
export const standardExitEffect = (node: HTMLElement) => {
  if (!node) return
  if (!node.classList) return
  const timelineExit = gsap.timeline({ paused: true })
  timelineExit.totalProgress(1).clear(true).totalProgress(0)
  node.classList.remove('entered')
  timelineExit.to(node, { autoAlpha: 0, height: 0, ease: Power2.easeIn, duration: 0.3 })
  timelineExit.play()
}

/** Enter fade-in effect used to slowly fade elements in (autoAlpha: 0 -> 1 in 0.3 sec with Power2.easeIn) */
export const fadeInEnterEffect = (node: HTMLElement) => {
  if (!node) return
  if (node.nodeType === node.TEXT_NODE) return
  const timelineEnter = gsap.timeline({ paused: true })
  timelineEnter.totalProgress(1).clear(true).totalProgress(0)
  timelineEnter.fromTo(node, { autoAlpha: 0, ease: Power2.easeIn }, { autoAlpha: 1, ease: Power2.easeIn, duration: 0.3 }, 0)
  timelineEnter.play()
}

/** Exit fade-out effect used to slowly fade elements out (autoAlpha: 1 -> 0 in 0.3 sec with Power2.easeIn) */
export const fadeOutExitEffect = (node: HTMLElement) => {
  if (!node) return
  if (node.nodeType === node.TEXT_NODE) return
  const timelineExit = gsap.timeline({ paused: true })
  timelineExit.totalProgress(1).clear(true).totalProgress(0)
  timelineExit.fromTo(node, { autoAlpha: 1, ease: Power2.easeIn }, { autoAlpha: 0, ease: Power2.easeIn, duration: 0.3 }, 0)
  timelineExit.play()
}

/** The standard widely used {enter: 300, exit: 300} timeout */
export const standardTimeoutConfig = { enter: 300, exit: 300 }
