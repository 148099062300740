import { ActionRequest, StatusResponse } from 'models/redux'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'
import { VisualFileType } from 'constants/visual'
import { createAPIAction } from 'redux/Helpers'

/** Describes general action to select visuals */
export interface ActionSelectVisuals extends ActionRequest {
  payload: {
    missionId: string
    types: string[]
    type: VisualFileType
    productId: number
    billingProductId?: number
    filenames: string[]
    immediatelyDownload: boolean
    paymentIntentId?: string
    filenamesToDownload: string[]
    request: StatusResponse
  }
}

/** Creates an action to select visuals */
export function selectVisuals(
  missionId: string,
  types: string[],
  filenames: string[],
  type: VisualFileType,
  productId: number,
  billingProductId?: number,
  paymentIntentId?: string,
  immediatelyDownload = false,
  filenamesToDownload = filenames
): ActionSelectVisuals {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.SELECT_VISUALS], {
    missionId,
    types,
    type,
    productId,
    billingProductId,
    filenames,
    immediatelyDownload,
    paymentIntentId,
    filenamesToDownload,
    request: new APIRequest(APIRequestState.RUNNING),
  })
}

/** Creates action to purge select visuals state */
export function purgeSelectVisuals(missionId: string): ActionSelectVisuals {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.SELECT_VISUALS], {
    missionId,
    request: new APIRequest(APIRequestState.BEFORE_START),
  })
}
