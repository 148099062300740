import { FC, Fragment, useMemo } from 'react'

import { ProductListItem } from '../ProductListItem.component'
import { ProductSegmentSlider } from '../ProductSegmentSlider.component'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import { isEditingCategory } from 'utils/validators'
import { usePurchaseFlowConfig } from 'components/pages/PurchaseFlow/_main/contexts'
import { usePurchaseFlowProducts } from 'components/pages/PurchaseFlow/_main/contexts/PurchaseFlowProducts.context'
import { useTranslation } from 'react-i18next'

/**
 * @component
 * Shows filter for products in purchase flow.
 * User can toggle each existing product type and select area sizes (segments)
 * 
 * @example
 * <ProductFilter />
 */
export const ProductFilter: FC = () => {
  const { availableSegments, availableProductTypesPerSegment, availableProductKindsPerSegment, hasSegments, selectedCategory } = usePurchaseFlowConfig()
  const { toggleFilterKind, toggleFilterType, selectedFilterTypes, selectedFilterKinds, selectedSegment } = usePurchaseFlowProducts()

  const { t } = useTranslation(['order', 'product_type'])

  const availableBundleProducts = useMemo(() => {
    if (!selectedSegment) return []

    return availableProductTypesPerSegment[selectedSegment]?.bundle || []
  }, [availableProductTypesPerSegment, selectedSegment])

  const availableNonBundleProducts = useMemo(() => {
    if (!selectedSegment) return []

    return availableProductTypesPerSegment[selectedSegment]?.nonBundle || []
  }, [availableProductTypesPerSegment, selectedSegment])

  return (
    <Stack gap={2}>

      {/* SLIDER SELECTOR */}
      {availableSegments.length > 1 &&
        <ProductSegmentSlider />
      }

      {/* PRODUCT TYPE CHECKBOXES */}
      {!!selectedSegment && !!selectedCategory &&
        <Fragment>
          {isEditingCategory(selectedCategory) ?
            // FOR EDITING
            <SectionedBorderBox
              title={t('step_product.product_types')}
              description={
                t('step_product.product_types_editing_info') !== 'step_product.product_types_editing_info'
                  ? t('step_product.product_types_editing_info')
                  : ''
              }
            >
              <Stack>
                {(availableProductKindsPerSegment[selectedSegment] || [])
                  .map(({ productKind }) => (
                    <ProductListItem
                      key={productKind}
                      productKind={productKind}
                      selected={selectedFilterKinds.has(productKind)}
                      disabled={hasSegments && !selectedSegment}
                      onChange={_ => toggleFilterKind(productKind)}
                    />
                  ))
                }
              </Stack>
            </SectionedBorderBox>
            :
            // FOR SHOOTING CATEGORY
            <Fragment>

              {/* NON BUNDLE PRODUCTS */}
              {!!availableNonBundleProducts.length &&
                <SectionedBorderBox
                  title={t('step_product.product_types')}
                  description={
                    t('step_product.product_types_shooting_bundles_info') !== 'step_product.product_types_shooting_info'
                      ? t('step_product.product_types_shooting_info')
                      : ''
                  }
                >
                  <Stack>
                    {availableNonBundleProducts.map(({ productType }) =>
                      <ProductListItem
                        key={productType}
                        productType={productType}
                        selected={selectedFilterTypes.has(productType)}
                        disabled={!selectedSegment}
                        onChange={_ => toggleFilterType(productType)}
                      />
                    )}
                  </Stack>
                </SectionedBorderBox>
              }

              {/** BUNDLE PRODUCTS */}
              {!!availableBundleProducts.length &&
                <SectionedBorderBox
                  title={t('step_product.product_bundles')}
                  description={
                    t('step_product.product_types_shooting_bundles_info') !== 'step_product.product_types_shooting_bundles_info'
                      ? t('step_product.product_types_shooting_bundles_info')
                      : ''
                  }
                >
                  <Stack>
                    {availableBundleProducts.map(({ productType }) =>
                      <ProductListItem
                        key={productType}
                        productType={productType}
                        selected={selectedFilterTypes.has(productType)}
                        disabled={!selectedSegment}
                        onChange={_ => toggleFilterType(productType)}
                      />
                    )}
                  </Stack>
                </SectionedBorderBox>
              }
            </Fragment>
          }
        </Fragment>
      }
    </Stack>
  )
}
