import { DistributionType, FansType, HeatRecoveryPercentageTypes, VentilationSystemType, VentilationType } from 'constants/purchaseFlow'
import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'
import React, { useMemo } from 'react'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { GRAY_900 } from 'constants/styling/theme'
import { Label } from 'components/common/Label'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUIRadio } from 'components/common/MUIRadio'
import { PRIMARY_FIELD_WIDTH } from './HeatingSystem/_main'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import { SelectableButtonsGrid } from 'components/common/SelectableButtonsGrid'
import Stack from '@mui/material/Stack'
import { ToggleFilterValue } from 'components/common/ToggleFilter'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useVentilationAndHeating } from './_main/VentilationAndHeating.context'

/**
 * @component VentilationSystem 
 * @description Renders options for the user to provide information about the ventilation. 
 * 
 * @example
 *   <VentilationSystem />
 */
export const VentilationSystem: React.FC = () => {

  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.energy_heating_page.ventilation_system' })

  const {
    ventilationFeatures,
    setVentilationFeatures,
  } = useVentilationAndHeating()

  const { ventilation, ventilationSystem, distribution, fans, heatRecovery } = ventilationFeatures

  // Available ventilation system types mapped to objects
  const ventilationSystemToggleValues: ToggleFilterValue<VentilationSystemType>[] = useMemo(
    () => {
      const availableTypes = Object.values(VentilationSystemType)

      return availableTypes.map((type) => {
        return {
          value: type,
          displayName: t(`ventilation_system_type.${type}`),
        }
      })
      // [t] is not a dependency because it is a constant function
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []
  )

  const isShowHeatRecovery = useMemo(() => ventilation && ventilation !== VentilationType.EXHAUST_SYSTEM_NO_HEAT_RECOVERY, [ventilation])

  return (
    <SectionedBorderBox
      title={
        <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
          {t('ventilation_system_title')}
        </Typography>
      }
    >
      <Stack
        sx={{
          flexDirection: { xs: 'column', lg: 'row' }
        }}
        spacing={2}
        width="100%"
      >
        <Stack alignItems="flex-start" gap={1.6} width={{ xs: '100%' }} marginBottom={{ xs: '2.8rem', sm: 0 }}>

          <Label text={t('ventilation_system_required')} />

          <SelectableButtonsGrid
            values={ventilationSystemToggleValues}
            selectedValue={Array.from(ventilationSystem || [])}
            onSelect={type => setVentilationFeatures({ ...ventilationFeatures, ventilationSystem: new Set([...(ventilationSystem || []), type]) })}
            onUnselect={type => setVentilationFeatures({ ...ventilationFeatures, ventilationSystem: new Set([...ventilationSystem || []].filter(item => item !== type)) })}
            showCheckbox
            activeButtonClickable
          />

          {!!ventilationSystem?.size && ventilationSystem.has(VentilationSystemType.VENTILATION_SYSTEM_AVAILABLE) &&
            <BorderBoxWrapper padding={2} width='100%'>

              {/* DISTRIBUTION */}
              <Stack gap=".8rem">

                <Label text={t('distribution')} />

                {Object.values(DistributionType).map(type => {
                  return (
                    <Stack key={type} gap=".8rem" flexDirection="row">
                      <MUIRadio
                        checked={distribution === type}
                        onChange={() => setVentilationFeatures({ ...ventilationFeatures, distribution: type })}
                      />

                      <Label color={GRAY_900} text={t(`distribution_type.${type}`)} />
                    </Stack>
                  )
                })}

              </Stack>


              {/* TYPE OF VENTILATION */}
              <Stack gap=".8rem" paddingTop={1.6}>

                <Label text={t('ventilation')} />

                {Object.values(VentilationType).map(type => {
                  return (
                    <Stack key={type} gap=".8rem" flexDirection="row">
                      <MUIRadio
                        checked={ventilation === type}
                        onChange={() => setVentilationFeatures({ ...ventilationFeatures, ventilation: type })}
                      />

                      <Label color={GRAY_900} text={t(`ventilation_type.${type}`)} />
                    </Stack>
                  )
                })}

              </Stack>


              {/* FANS */}
              <Stack gap=".8rem" paddingTop={1.6}>

                <Label text={t('fans')} />

                {Object.values(FansType).map(type => {
                  return (
                    <Stack key={type} gap=".8rem" flexDirection="row">
                      <MUIRadio
                        checked={fans === type}
                        onChange={() => setVentilationFeatures({ ...ventilationFeatures, fans: type })}
                      />

                      <Label color={GRAY_900} text={t(`fans_type.${type}`)} />
                    </Stack>
                  )
                })}
              </Stack>

              {/* HEAT RECOVERY PERCENTAGE */}
              {isShowHeatRecovery &&
                <Stack gap="1.8rem" paddingTop={1.6} paddingBottom={1.6} width={{ xs: '100%', lg: PRIMARY_FIELD_WIDTH }} position="relative">
                  <MUIDropdown
                    sx={{ width: '100%' }}
                    button={(isOpen, action) => (
                      <MUIDropdownInput
                        readOnly
                        label={t('heat_recovery')}
                        type={DropdownInputTypes.SELECTOR}
                        value={t(!!heatRecovery
                          ? `heat_recovery_options.${heatRecovery}`
                          : 'select_heat_recovery_option')}
                        onClick={action}
                      />
                    )}
                  >
                    {Object.values(HeatRecoveryPercentageTypes).map(type => (
                      <MUIDropdownItem key={type} onClick={() => setVentilationFeatures({ ...ventilationFeatures, heatRecovery: type })}>
                        <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                          {t(`heat_recovery_options.${type}`)}
                        </Typography>
                      </MUIDropdownItem>
                    ))}
                  </MUIDropdown>
                </Stack>
              }

            </BorderBoxWrapper>
          }

        </Stack>
      </Stack>
    </SectionedBorderBox>
  )
}
