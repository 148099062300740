import { EditingCategory, STAGING_PRODUCT_KINDS_PROPERTY_TYPES } from 'constants/product'
import { useCallback, useEffect, useState } from 'react'

import { RoomInventory } from 'models/virtualStaging'
import constate from 'constate'
import { usePurchaseFlowConfig } from './PurchaseFlowConfig.context'
import { usePurchaseFlowProducts } from './PurchaseFlowProducts.context'
import { useRoomAPI } from 'components/contexts/RoomAPI.context'
import { useRoomInventory } from 'components/contexts/RoomInventory.context'
import { useUploadedPurchaseFlowVisuals } from './UploadedPurchaseFlowVisuals.context'

export const [AutomaticRoomCreatorContextProvider, useAutomaticRoomCreator] = constate(() => {
  const { selectedCategory } = usePurchaseFlowConfig()
  const { createRoomObject, setRooms, roomInventory } = useRoomInventory()
  const { assignRoomToImage, unassignAll } = useRoomAPI()
  const { uploadedImagesArray, uploadFinished } = useUploadedPurchaseFlowVisuals()
  const { selectedProducts } = usePurchaseFlowProducts()

  /** RoomInventory object holding generated rooms */
  const [generatedRooms, setGeneratedRooms] = useState<RoomInventory>({})

  /**
   * Creates roomInventory object generated from uploaded images.
   * - Generates 1 room per image
   * - Uses imageKey as roomKey to help with skipping duplicities and thus rewriting data when deleting/uploading additional images
   * - Updates/generates only when upload is finished
   * @returns RoomInventory object
  */
  const generateRooms = useCallback(() => {

    // Skip for any category other than STAGING (safety check)
    if (selectedCategory !== EditingCategory.STAGING) return {}

    // Don't update/generate rooms unless upload is finished
    if (!(uploadedImagesArray ?? []).length || !uploadFinished) return generatedRooms || {}

    // Create and return roomInventory object with room for every image entry
    // If there already is an existing room for image.id, use old data. This will assure no rewriting of possible style selection in case user
    // goes back and forth between picture upload and style selection step.
    // roomKey === imageId for sake of simple room generating and later assigning
    return uploadedImagesArray.reduce((roomMap, img) => {

      // skip orphan images (their product is not selected)
      if (!selectedProducts[Number(img.productId)]) return roomMap

      const productKind = selectedProducts[Number(img.productId)]?.kind
      const propertyType = productKind ? STAGING_PRODUCT_KINDS_PROPERTY_TYPES[productKind] : undefined

      const room = roomInventory[img.id] || createRoomObject(
        Number(img.productId),
        {
          key: img.id,
          propertyType
        }
      )

      return {
        ...roomMap,
        [room.key]: room
      }
    }, {})

  }, [selectedCategory, uploadedImagesArray, uploadFinished, generatedRooms, selectedProducts, roomInventory, createRoomObject])

  // Run room generation when callback - thus images and upload state - changes and save rooms into generatedRooms inventory
  useEffect(() => {
    setGeneratedRooms(generateRooms())
    // only generate new rooms, when images changed and upload has finished
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFinished, uploadedImagesArray, selectedProducts])

  // Add generated room to roomInventory after finished upload
  useEffect(() => {
    const generatedRoomKeys = Object.keys(generatedRooms)

    if (uploadFinished && generatedRoomKeys.length > 0) {

      // clear relationships
      unassignAll()

      // rewrite inventory with new rooms
      setRooms(generatedRooms)

      // assign images to rooms
      // roomKey === imageId for sake of simple room generating
      generatedRoomKeys.forEach(key => {
        assignRoomToImage(key, key)
      })

    }

    // ensure setting rooms when generated rooms change
  }, [assignRoomToImage, generatedRooms, setRooms, unassignAll, uploadFinished])

  return {
    uploadedImagesArray,
    generatedRooms,
    uploadFinished,
  }
})
