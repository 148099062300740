import { EmojiValue, RatingType } from 'constants/misc'
import { FC, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Button from 'components/common/Button/Button'
import Modal from 'components/common/Modals/Modal/Modal'
import { Nullable } from 'models/helpers'
import styles from './BadRatingPopup.module.sass'

export interface BadRatingPopupData {
  ratingType: RatingType
  rating: Nullable<EmojiValue>
}

interface Props {
  /** Decides when is modal open */
  isOpen: boolean
  /** Type of rating popup */
  data: Nullable<BadRatingPopupData>
  /** OnClick action triggered when user clicks the submit button */
  onSubmit: (text: string, rating: Nullable<EmojiValue>, ratingType: Nullable<RatingType>, e?: React.MouseEvent) => void
  /** OnClick action triggered when user clicks the close button */
  onClose: () => void
  /** OnChange action to be called after textarea value has changed */
  onChange?: (text: string, rating: Nullable<EmojiValue>, ratingType: Nullable<RatingType>, e?: any) => void
}

/**
 * Displays modal for additional rating popup.
 * Textarea for client to comment for unhappy rating.
 * 
 * @example
 * <BadRatingPopup
 *  isOpen={false}
 *  type={ratingType}
 *  onSubmit={submitTextRating}
 *  onClose={() => alert('Close the additional rating popup')}
 *  onChange={() => alert('Callback on textarea change')}
 * />
 */
export const BadRatingPopup: FC<Props> = ({
  isOpen,
  data,
  onSubmit,
  onClose,
  onChange
}) => {
  const { t } = useTranslation(['rating_popup'])

  const [textValue, setTextValue] = useState<string>('')

  const getRatingTypeString = () => {
    if (!data) return ''

    if (data.ratingType === RatingType.CREATIVE) {
      return t('rating_creative_label')
    } else if (data.ratingType === RatingType.SERVICE) {
      return t('rating_platform_label')
    } else {
      return t('rating_visuals_label')
    }
  }

  return (
    <Modal
      className="rating-popup-modal"
      isOpen={isOpen}
      modalContentClassName={styles.modalBody}
      afterClosed={() => setTextValue('')}
      stopClickEventPropagation
    >
      <div className={styles.title}>{t('title')}</div>
      <div className={styles.content}>
        <Trans t={t} i18nKey="content" values={{ type: getRatingTypeString() }}>
          &nbsp;
        </Trans>
      </div>
      <textarea
        className={styles.textarea}
        name='rating-popup-textarea'
        id='rating-popup-textarea'
        placeholder={t('placeholder')}
        rows={5}
        value={textValue}
        onChange={e => {
          setTextValue(e.target.value)
          onChange?.(e.target.value, data?.rating, data?.ratingType, e)
        }}
      >
      </textarea>
      <div className={styles.actions}>
        <Button
          className={styles.button}
          type="secondary nobackground"
          onClick={e => {
            onSubmit('', data?.rating, data?.ratingType, e)
            onClose()
          }}
        >{t('close')}</Button>
        <Button
          disabled={!textValue}
          onClick={e => {
            onSubmit?.(textValue, data?.rating, data?.ratingType, e)
            onClose()
          }}
        >{t('submit')}</Button>
      </div>
    </Modal>
  )
}
