import { useEffect, useMemo } from 'react'
import { DeleteOptions, FileAPIEntry, FileDeleteFnc, LoadUrlResolver, ReUploadOptions, ReUploadUrlResolver, UploadOptions, UploadUrlResolver } from './fileAPI.types'

import { useOnUnmountWithDeps } from 'utils/helpers'
import { useFileAPIController } from './FileAPIController.context'

export const EMPTY_FILE_TAG_KEY = 'EMPTY_TAG'

export interface Options {
  /** Axios function that calls delete endpoint for file */
  deleteHandler?: FileDeleteFnc
  /** Axios function calling BE to obtain signed url for loading files */
  loadUrlResolver?: LoadUrlResolver
  /** Axios function calling BE to obtain signed url for uploading files */
  uploadUrlResolver?: UploadUrlResolver
  /** Axios function calling BE to obtain signed url for uploading files */
  reUploadUrlResolver?: ReUploadUrlResolver
  /** Whether all entries of scope should be purged on hook destroy (provided default = true) */
  purgeOnDestroy?: boolean
}

/** Consumer hook for interacting with FileAPI */
export const useFileAPI = (
  scope: string,
  options: Options = {}
) => {

  const { deleteHandler, uploadUrlResolver, loadUrlResolver, reUploadUrlResolver } = options

  const ctrl = useFileAPIController()

  const files = useMemo(() => ctrl.fileInventory[scope] || {}, [ctrl.fileInventory, scope])

  const allFilesArray = useMemo(() => Object.values(ctrl.fileInventory[scope] ?? []), [ctrl.fileInventory, scope])

  const filesByTag = useMemo(
    () => Object.values(files)
      .reduce(
        (acc, file) => ({
          ...acc,
          [file.tag ?? EMPTY_FILE_TAG_KEY]: [
            ...(acc[file.tag ?? EMPTY_FILE_TAG_KEY] || []),
            file
          ]
        }),
        {} as Record<string, FileAPIEntry[]>
      ),
    [files]
  )

  /** Function that adds provided entries into scope -- subject to rename */
  const initializeFiles = (files: Array<FileAPIEntry>) => {
    return ctrl.initFiles(
      scope,
      files
    )
  }

  /** Obtains signedUrl for each file and proceeds with parallel upload of all files updating progress. Optional tag for potential grouping of files etc. */
  const uploadFiles = (files: FileList | Array<File>, uploadOptions: UploadOptions = {}) => {
    const resolver = uploadOptions.uploadUrlResolverFnc ?? uploadUrlResolver

    if (!resolver) {
      console.error('No uploadUrlResolver function provided, aborting')
      return
    }

    ctrl.uploadFiles(scope, files, resolver, uploadOptions)
  }

  /** Obtains replace signedUrl for file and proceeds with upload of file whilst updating progress. Optional tag for potential grouping of files etc. */
  const reUploadFile = (file: File, replaceId: string, reUploadOptions: ReUploadOptions = {}) => {
    const resolver = reUploadOptions.reUploadUrlResolverFnc ?? reUploadUrlResolver

    if (!resolver) {
      console.error('No reUploadUrlResolver function provided, aborting')
      return
    }

    ctrl.reUploadFile(scope, replaceId, file, resolver, reUploadOptions)
  }

  /** Deletes provided files from BE and then from inventory. */
  const deleteFiles = (ids: Array<string>, deleteOptions: DeleteOptions = {}) => {
    const handler = deleteOptions.deleteHandlerFnc ?? deleteHandler

    if (!handler) {
      console.error('No delete function provided, aborting')
      return
    }

    ctrl.deleteFiles(scope, ids, handler)
  }

  /** In progress */
  const loadFiles = (files: Array<FileAPIEntry>) => {
    if (!loadUrlResolver) {
      console.error('No load url resolver function provided, aborting')
      return
    }

    ctrl.loadFiles(scope, files, loadUrlResolver)
  }

  // Initialize scope
  useEffect(
    () => {
      ctrl.initScope(scope)
    },
    // Init only
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useOnUnmountWithDeps(
    () => {
      if (options.purgeOnDestroy !== false) {
        ctrl.purgeScope(scope)
      }
    },
    [scope, options.purgeOnDestroy]
  )

  return {
    uploadFiles,
    deleteFiles,
    initializeFiles,
    loadFiles,
    files,
    filesByTag,
    allFilesArray,
    createEntry: ctrl.createFileAPIEntry,
    reUploadFile,
    purgeFilesScope: ctrl.purgeScope,
  }
}

export type UseFileAPIReturn = ReturnType<typeof useFileAPI>
