import { FieldValues, UseFormProps, useForm } from 'react-hook-form'
import { useMemo, useRef } from 'react'

import { UseExtendedFormReturn } from 'models/forms'
import { isEqual } from 'lodash'

/**
 * Hook extending *useForm* from *react-hook-form*.
 * Provides all utils plus dynamically updated *values*.
 * 
 * Default options:
 *  - mode: *onTouched*
 * 
 * @typeParam T - Interface describing form values
 * @param options - useForm options *(defaultValues, mode, ...)* that will overwrite default ones
 * @returns All utilities of useForm plus *values* which are dynamically updated
 * @example
 * interface FormValues {
 *  foo: string
 *  bar: string
 * }
 * const { values, formState, register } = useExtendedForm<FormValues>()
 */
export function useExtendedForm<T extends FieldValues>(options: UseFormProps<T>, immediateValidation = false): UseExtendedFormReturn<T> {

  const immediateValidationDone = useRef(false)

  const formUtils = useForm<T>({
    mode: 'onTouched',
    ...options
  })

  if (immediateValidation && !immediateValidationDone.current) {
    immediateValidationDone.current = true

    // Phantom timeout needed to let the form update after registering fields
    // explanation for this issue in a great event-loop video: https://www.youtube.com/watch?v=8aGhZQkoFbQ
    window.setTimeout(() => formUtils.trigger(), 0)
  }

  const values = formUtils.watch()

  // Compare defaultValues to current values
  const hasChanges = useMemo(() => !isEqual(options.defaultValues, values), [options.defaultValues, values])

  return {
    ...formUtils,
    values,
    hasChanges,
  }
}
