import { FC, useLayoutEffect } from 'react'
import { GalleryStagingFlowContextProvider, GalleryStagingFlowStackProvider, GalleryStagingVisualSelectionProvider, GalleryUpsellProductsContextProvider } from './contexts'

import { GalleryStagingFlowController } from './GalleryStagingFlow.controller'
import { GalleryStagingFlowListContextProvider } from './contexts/GalleryStagingFlowList.context'
import { Path } from 'constants/router'
import { useClientGallery } from '../../../_main/ClientGallery/_main'
import { useGalleryDeal } from '../../../_main/contexts'
import { useNavigate } from 'react-router'

/**
 * @component GalleryStagingFlow page.
 * 
 * @example 
 * <GalleryStagingFlow />
 */
export const GalleryStagingFlow: FC = () => {
  const navigate = useNavigate()

  const { assignmentId } = useClientGallery()
  const { stagingAssignments } = useGalleryDeal()

  // edge case - Redirect to gallery if no staging assignments found.
  useLayoutEffect(() => {
    if (stagingAssignments.length === 0) {
      navigate(Path.GALLERY.replace(':id', assignmentId))
    }
    // No all deps needed, it should trigger only on the first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentId])

  return (
    <GalleryStagingVisualSelectionProvider>
      <GalleryUpsellProductsContextProvider assignmentId={stagingAssignments[0]?.id}>
        <GalleryStagingFlowStackProvider>
          <GalleryStagingFlowContextProvider>
            <GalleryStagingFlowListContextProvider>

              <GalleryStagingFlowController />

            </GalleryStagingFlowListContextProvider>
          </GalleryStagingFlowContextProvider>
        </GalleryStagingFlowStackProvider>
      </GalleryUpsellProductsContextProvider>
    </GalleryStagingVisualSelectionProvider>
  )
}
