import { ExportOption } from 'constants/misc'

/** Enumeration of edited visual types */
export enum EditedVisualFileType {
  POST = 'POST',
  POST_EXPORT_ORIGINAL_THUMB = 'POST_EXPORT_ORIGINAL_THUMB',
  POST_EXPORT_PRINT_THUMB = 'POST_EXPORT_PRINT_THUMB',
  POST_EXPORT_WEB_THUMB = 'POST_EXPORT_WEB_THUMB',
  POST_EXPORT_ORIGINAL_THUMB_WATERMARK = 'POST_EXPORT_ORIGINAL_THUMB_WATERMARK',
  POST_EXPORT_PRINT_THUMB_WATERMARK = 'POST_EXPORT_PRINT_THUMB_WATERMARK',
  POST_EXPORT_WEB_THUMB_WATERMARK = 'POST_EXPORT_WEB_THUMB_WATERMARK',
  POST_EXPORT_ORIGINAL_WEB = 'POST_EXPORT_ORIGINAL_WEB',
  POST_EXPORT_PRINT_WEB = 'POST_EXPORT_PRINT_WEB',
  POST_EXPORT_WEB_WEB = 'POST_EXPORT_WEB_WEB',
}

/** Removes the ['_THUMB', '_WEB', '_THUMB_WATERMARK', '_WEB_WATERMARK'] from visual types. Transfers types such as EditedVisualFileType.POST_EXPORT_PRINT_THUMB to EditedVisualFileType.POST */
export const editedVisualTypeToBaseType = {
  [EditedVisualFileType.POST_EXPORT_ORIGINAL_THUMB]: EditedVisualFileType.POST,
  [EditedVisualFileType.POST_EXPORT_PRINT_THUMB]: EditedVisualFileType.POST,
  [EditedVisualFileType.POST_EXPORT_WEB_THUMB]: EditedVisualFileType.POST,
  [EditedVisualFileType.POST_EXPORT_ORIGINAL_THUMB_WATERMARK]: EditedVisualFileType.POST,
  [EditedVisualFileType.POST_EXPORT_PRINT_THUMB_WATERMARK]: EditedVisualFileType.POST,
  [EditedVisualFileType.POST_EXPORT_WEB_THUMB_WATERMARK]: EditedVisualFileType.POST,
  [EditedVisualFileType.POST_EXPORT_ORIGINAL_WEB]: EditedVisualFileType.POST,
  [EditedVisualFileType.POST_EXPORT_PRINT_WEB]: EditedVisualFileType.POST,
  [EditedVisualFileType.POST_EXPORT_WEB_WEB]: EditedVisualFileType.POST
}

export const exportTypeToEditedVisualsType = {
  [ExportOption.ORIGINAL]: EditedVisualFileType.POST_EXPORT_ORIGINAL_THUMB,
  [ExportOption.WEB]: EditedVisualFileType.POST_EXPORT_WEB_THUMB,
  [ExportOption.PRINT]: EditedVisualFileType.POST_EXPORT_PRINT_THUMB
}

export const exportTypeToEditedVisualsWebType = {
  [ExportOption.ORIGINAL]: EditedVisualFileType.POST_EXPORT_ORIGINAL_WEB,
  [ExportOption.WEB]: EditedVisualFileType.POST_EXPORT_WEB_WEB,
  [ExportOption.PRINT]: EditedVisualFileType.POST_EXPORT_PRINT_WEB
}
