import { MutationFunction, MutationOptions, QueryClient, useMutation, useQueryClient } from '@tanstack/react-query'

import { AxiosError } from 'axios'

/**
 * Helper wrapper function for easy generating of basic mutations.   
 * This wrapper automatically injects queryClient and provides param for invalidating function triggered on mutation success.  
 *   
 * This is an early development version and things might change, keep that in mind.
 * @param mutationFnc 
 * @param invalidationFnc 
 * @param options 
 * @returns mutation result
 */
export const getMutation = <DataType, VarType, ErrorType = AxiosError>(
  mutationFnc: MutationFunction<DataType, VarType>,
  invalidationFnc?: (client: QueryClient, variables: VarType, data: any) => void,
  // For now just like this for basic customizaition
  options?: Omit<MutationOptions<DataType, unknown, VarType>, 'onSuccess'>
) => {
  return (() => {
    const client = useQueryClient()

    return useMutation<DataType, ErrorType, VarType>({
      mutationFn: mutationFnc,
      onSuccess: (data, variables: VarType) => { invalidationFnc?.(client, variables, data) },
      ...options,
    })
  })()
}
