import { ProductDTO } from 'models/product'

export function chooseProductContainingVisuals(products: ProductDTO[] | undefined): ProductDTO | undefined {
  // no products
  if (products === undefined) return undefined

  // product containing visuals
  let productWithVisuals = products.find(product => product.visuals === true)
  if (productWithVisuals) return productWithVisuals

  // empty products
  if (products.length === 0) return undefined
  // first product as a fallback
  else return products[0]
}
