import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import classNames from 'classnames'
import { getClientReferenceLogos } from 'constants/pricing/clientReferenceLogos'
import i18n from 'translations/i18n'
import styles from './ClientReference.module.sass'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'

/**
 * @component 
 * @example
 * <ClientReference />
 */
export const ClientReference = () => {
  const { t } = useTranslation(['pricing_page'])
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)

  return (
    <div className={classNames(styles.clientReference, { [styles.mobile]: isMobileView })}>
      <div className={styles.title}>{t('client_reference_title')}</div>
      <img className={styles.logos} src={getClientReferenceLogos(i18n.language)} alt="reference logo" />
    </div>
  )
}
