import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { ActionRequest } from 'models/redux'
import { ListPurchaseSessionVisualsStoreItem } from '.'
import { createAPIAction } from 'redux/Helpers'

/** Describes general action to list all purchase flow session visuals */
export interface ActionListPurchaseSessionVisuals extends ActionRequest {
  payload: {
    sessionId: string
    request: ListPurchaseSessionVisualsStoreItem
  },
}

/** Creates an action to list all available purchase flow session visuals */
export function listPurchaseSessionVisuals(sessionId: string): ActionListPurchaseSessionVisuals {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.LIST_PURCHASE_SESSION_VISUALS], {
    sessionId,
    request: new APIRequest()
  })
}

/** Creates an action to purge list of all available purchase flow session visuals */
export function purgeListPurchaseSessionVisuals(sessionId: string): ActionListPurchaseSessionVisuals {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.LIST_PURCHASE_SESSION_VISUALS], {
    sessionId,
    request: new APIRequest()
  })
}