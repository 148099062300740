import React, { ReactNode } from 'react'

import Box from '@mui/material/Box'
import { GRAY_700 } from 'constants/styling/theme'
import Stack from '@mui/material/Stack'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Title of the section list */
  title?: string
  children: ReactNode
}

/**
 * @component Section content list
 * @example 
 * <SectionContentList title="list title">
 *  <Box>insctructions</Box>
 * </SectionContentList>
 */
export const SectionContentList: React.FC<Props> = ({
  title,
  children
}) => (
  <Stack
    fontSize="1.4rem"
    paddingBottom="1.6rem"
  >

    {!!title &&
      <Box
        fontWeight={500}
        color={GRAY_700}
        marginBottom=".2rem"
        lineHeight="2rem"
      >
        {title}
      </Box>
    }

    {children}

  </Stack>
)
