import { FC, Fragment } from 'react'

import Button from 'components/common/Button/Button'
import { ConfiguredInput } from '../../common/ConfiguredInput'
import { Country } from 'constants/country'
import Dropdown from 'components/common/Dropdown/Dropdown'
import Icon from 'components/common/Icon/Icon'
import { IconType } from 'constants/assets'
import styles from './SavedSepaDetailsFields.module.sass'
import { useSavedSepaDetailsFields } from './SavedSepaDetails.form'
import { useTranslation } from 'react-i18next'

/**
 * Displays form fields for editing sepa details 
 * @example <SavedSepaDetailsFormFields />
 */
export const SavedSepaDetailsFormFields: FC = () => {

  const { t } = useTranslation(['sepa_edit_fields', 'country'])

  const { formUtils, fields } = useSavedSepaDetailsFields()

  if (!formUtils || !fields) return <Fragment></Fragment>

  const { setValue, values, trigger } = formUtils
  const { errors } = formUtils.formState

  return (
    <div className={styles.sepaDetailFields}>

      <ConfiguredInput
        name="name"
        label={t('name')}
        error={errors.name}
        control={fields.name}
      />

      <ConfiguredInput
        name="line1"
        label={t('line1')}
        error={errors.line1}
        control={fields.line1}
      />

      <ConfiguredInput
        name="line2"
        label={t('line2')}
        error={errors.line2}
        control={fields.line2}
      />

      <ConfiguredInput
        name="city"
        label={t('city')}
        error={errors.city}
        control={fields.city}
      />

      <ConfiguredInput
        name="postalCode"
        label={t('postal_code')}
        error={errors.postalCode}
        control={fields.postalCode}
      />

      <div className={`${styles.countrySelect} input-group`}>

        <label htmlFor="country">
          {t('country')}
        </label>

        <Dropdown
          className={styles.dropdown}
          button={(isOpen, action) => (
            <Button
              className={styles.dropdownButton}
              name="country"
              type="secondary"
              onClick={action}
              textAndIcon={true}
            >
              <span>{t(`country:${values.country}` || '')}</span>
              <Icon icon={IconType.CARET_DOWN} className={`caret ${isOpen ? 'up' : 'down'}`} />
            </Button>
          )}
        >
          <Fragment>
            {Object.keys(Country).map(country => {
              const selected = country === values.country
              return (
                <button
                  key={country}
                  className={`${styles.option} ${selected ? styles.isSelected : ''}`}
                  onClick={() => {
                    setValue('country', country, { shouldValidate: true })
                    trigger()
                  }}
                >
                  {t(`country:${country}`)}
                </button>
              )
            })}
          </Fragment>
        </Dropdown>

      </div>

    </div>
  )
}