import { Color, IconType } from 'constants/assets'
import { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'
import Button from '../Button/Button'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import Icon from '../Icon/Icon'
import { Nullable } from 'models/helpers'
import ReactLoading from 'react-loading'
import classnames from 'classnames'
import closeButtonStyles from 'components/styles/close-button.module.sass'
import { debounce } from 'lodash'
import styles from './RequestStatus.module.sass'

interface Props {
  request: Nullable<APIRequest<unknown>>
  heading?: string
  successMessage?: string
  errorMessage?: string
  className?: string
  spaceTopRem?: number
  spaceBottomRem?: number
  showStates?: APIRequestState[]
  onPurge?: () => void
}

const statesAllowingPurge = new Set([APIRequestState.OK, APIRequestState.ERROR])

/**
 * Displays loading/success/error states for request.
 * Accepts custom messages and optional purge action.
 * If purge action specified, X button appears to trigger it on click
 * 
 * States to be shown can be defined as Array of APIRequestStates  
 * *Default is [ APIRequestState.RUNNING, APIRequestState.OK, APIRequestState.ERROR ]*
 * 
 * Special space top/bottom attributes for smooth close/open height transform.
 * 
 * @example
 * <RequestStatus request={new APIRequest(APIRequestState.OK)} />
*/
export const RequestStatus: FC<Props> = ({
  request,
  heading,
  successMessage,
  errorMessage,
  className,
  spaceBottomRem = 0,
  spaceTopRem = 0,
  showStates = [APIRequestState.ERROR, APIRequestState.OK, APIRequestState.RUNNING],
  onPurge,
}) => {

  const { t } = useTranslation(['request_status'])

  const divRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState<number>(0)

  // Not memo for convenient access to "prev value"
  const [showState, setShowState] = useState(request?.state || APIRequestState.BEFORE_START)

  const isOpen = useMemo(() => {
    if (!request?.state) return false

    return showStates.includes(request.state)
  }, [request, showStates])

  /** Calculated spacing based on open/close state */
  const { spaceTop, spaceBottom } = useMemo(() => {
    if (!isOpen) return {
      spaceTop: 0,
      spaceBottom: 0,
    }

    return {
      spaceTop: spaceTopRem,
      spaceBottom: spaceBottomRem,
    }
  }, [isOpen, spaceTopRem, spaceBottomRem])

  // Cache state to prevent blinking of states that are not supposed to be shown
  // Not memo for convenient access to "prev value"
  useEffect(() => {
    setShowState((currentShowState) => {
      if (!request?.state) return APIRequestState.BEFORE_START

      if (showStates.includes(request.state)) {
        return request.state
      }

      return currentShowState
    })
  }, [request, showStates])

  // Calculate height on state change
  useEffect(
    () => {
      if (!isOpen) setHeight(0)
      else setHeight(divRef.current?.scrollHeight || 0)
    },
    // Needs to be recalculated on showState change as well to calculate appropriate height
    [isOpen, showState]
  )

  // Recalculate height on window resize - debounced
  useEffect(() => {
    const handlerFn = debounce(
      () => {
        if (!isOpen) setHeight(0)
        else setHeight(divRef.current?.scrollHeight || 0)
      },
      300,
      { maxWait: 500 }
    )

    // add listener
    window.addEventListener('resize', handlerFn)

    // cleanup listener
    return () => {
      window.removeEventListener('resize', handlerFn)
    }

  }, [isOpen])

  return (
    <div
      className={classnames(
        styles.requestStatus,
        className,
        !!request ? styles[showState] : undefined,
        { [styles.isClosed]: !isOpen }
      )}
      style={{
        height: `${height}px`,
        marginTop: `${spaceTop}rem`,
        marginBottom: `${spaceBottom}rem`
      }}
    >
      {!!request &&
        <div className={styles.content} ref={divRef}>

          {/* MAIN CONTENT */}
          <div className={classnames(styles.main, { [styles.isCenter]: !heading })}>

            {(showState === APIRequestState.ERROR || showState === APIRequestState.OK) &&
              <div>
                <Icon
                  className={styles.icon}
                  icon={showState === APIRequestState.ERROR ? IconType.DANGER : IconType.CHECK}
                  color={Color.WHITE}
                />
              </div>
            }

            {/* RUNNING */}
            {showState === APIRequestState.RUNNING &&
              <ReactLoading
                type="spin"
                color={Color.GRAY_TEXT}
                className={styles.loading}
              />
            }

            <div className={styles.wrapper}>
              {showState !== APIRequestState.BEFORE_START && !!heading &&
                <p className={styles.heading}>
                  <strong className={showState === APIRequestState.RUNNING ? styles.gray : styles.white}>
                    {heading}
                  </strong>
                </p>
              }

              {/* ERROR */}
              {showState === APIRequestState.ERROR &&
                <Fragment>

                  {/* To escape tags like strong/br/... */}
                  <Trans parent="p">
                    {errorMessage || <ErrorMessage error_type={request.error_type} />}
                  </Trans>

                </Fragment>
              }

              {/* OK */}
              {showState === APIRequestState.OK &&
                <Fragment>

                  {/* To escape tags like strong/br/... */}
                  <Trans parent="p">
                    {successMessage || t('success')}
                  </Trans>

                </Fragment>
              }

            </div>

            {/* Button for onPurge trigger */}
            {!!onPurge && statesAllowingPurge.has(showState) &&
              <div className={closeButtonStyles.closeWrap}>
                <Button
                  className={styles.close}
                  type="secondary nobackground noborder"
                  onClick={onPurge}
                  height="thin"
                >
                  <Icon icon={IconType.CROSS} color={Color.WHITE} />
                </Button>
              </div>
            }

          </div>

          {/* META */}
          {/* ERROR META INFO */}
          {showState === APIRequestState.ERROR && !!request.error?.data &&
            <div className={styles.meta}>
              {request.error.data.status} - <span className={styles.errorMessage}>{request.error.data.message}</span> ({request.error.data.timestamp})
            </div>
          }

        </div>
      }
    </div>
  )
}
