/** Enum of available building types */
export enum BuildingType {
  SINGLE_FAMILY_HOUSE = 'SINGLE_FAMILY_HOUSE',
  TWO_FAMILY_HOUSE = 'TWO_FAMILY_HOUSE',
  MULTI_FAMILY_HOUSE = 'MULTI_FAMILY_HOUSE',
  MIXED_USE_BUILDING = 'MIXED_USE_BUILDING',
}

/** EnergyPassReason enum */
export enum EnergyPassReason {
  NEW_RENTAL = 'NEW_RENTAL',
  SALE = 'SALE',
  OTHER = 'OTHER',
}
