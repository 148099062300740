import React, { FC } from 'react'

import { VisualDownloadPopupContextProvider } from './VisualDownloadPopup.context'
import { VisualDownloadPopupController } from './VisualDownloadPopup.controller'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Decides if modal is open */
  isOpen: boolean
  /** Onclick action triggered when user clicks on the close button */
  onClickClose?: (e?: React.MouseEvent) => unknown
}

/**
 * Popup with visual download status & CSAT rating.
 * 
 * @example
 * <VisualDownloadPopup isOpen={false} onClickClose={() => undefined} />
 */
export const VisualDownloadPopup: FC<Props> = ({
  isOpen,
  onClickClose
}) => {

  return (
    <VisualDownloadPopupContextProvider>
      <VisualDownloadPopupController isOpen={isOpen} onClickClose={onClickClose} />
    </VisualDownloadPopupContextProvider>
  )
}
