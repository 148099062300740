import { InstructionType } from './instructions'

export const instructionTypeSortingOrder = [
  InstructionType.REFERENCE,
  InstructionType.PROPERTY_OWNER,
  InstructionType.COMMENTS,
  InstructionType.STAGING_COMMENTS,
  InstructionType.ORGANIZATION,
  InstructionType.VAT_NUMBER,
  InstructionType.DELIVERY,
  InstructionType.CT_BOOKING,
  InstructionType.BILLING,
  InstructionType.BILLING_PRIMARY,
]
