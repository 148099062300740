import { changeLanguage, changeLocale } from 'redux/Individual/User/ChangeLocale'

import { Language } from 'translations/Language'
import Modal from 'components/common/Modals/Modal/Modal'
import { SetStateAction } from 'react'
import classnames from 'classnames'
import i18n from 'translations/i18n'
import styles from './LanguagePopup.module.sass'
import { useAuth0 } from 'utils/auth'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props for LanguagePopup component
 */
export interface Props {
  /** Whether the Modal for languages settings should be open */
  isLanguageOpen: boolean
  closeCallback: (value: SetStateAction<boolean>) => void
}

/**
 * @component A component displays on Profile Menu as an option to change language
 * @example <LanguagePopup isLanguageOpen={true}/>
 */
export const LanguagePopup: React.FC<Props> = ({
  isLanguageOpen,
  closeCallback
}) => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useAuth0()
  const { t } = useTranslation('topbar')

  return (
    <Modal
      modalContentClassName={styles.languagePopup}
      isOpen={isLanguageOpen}
      title={t('language_settings')}
      onClickOutside={() => closeCallback(!isLanguageOpen)}
      onClose={() => closeCallback(!isLanguageOpen)}
      hasHeaderContentDivider={true}
    >
      <div className={styles.container}>
        {Object.values(Language)?.map(lang => {
          const selected = lang.toUpperCase() === i18n.language.toUpperCase()
          return (
            <button
              key={lang}
              className={classnames(styles.languageButton, { [styles.selected]: selected })}
              onClick={() => {
                dispatch(changeLanguage(lang))
                if (!isAuthenticated) return
                dispatch(changeLocale(lang))
                closeCallback(!isLanguageOpen)
              }}
            >
              <span className={styles.language}>{t(`language:${lang.toUpperCase()}`, lang)}</span>
            </button>
          )
        })}
      </div>
    </Modal>
  )
}
