import { FC, ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react'
import { FirebaseController, initFirebase } from 'utils/analytics/firebase'

interface FirebaseContextInterface {
  firebaseInitialized: boolean
  firebaseController?: FirebaseController
}

const defaultFirebaseContext: FirebaseContextInterface = {
  firebaseInitialized: false,
}

/** Firebase controller context */
export const FirebaseContext = createContext<FirebaseContextInterface>(defaultFirebaseContext)
/** Firebase context hook */
export const useFirebase = (): FirebaseContextInterface => useContext(FirebaseContext)

/** Context provider for firebase controller */
export const FirebaseContextProvider: FC<{
  children?: ReactNode
}> = ({
  children,
}) => {
    const firebaseControllerRef = useRef<FirebaseContextInterface['firebaseController']>()
    const [firebaseInitialized, setFirebaseInitialized] = useState(false)

    // Initialize firebase
    useEffect(() => {
      const initializedFirebase = initFirebase()
      setFirebaseInitialized(!!initializedFirebase)
      if (initializedFirebase) firebaseControllerRef.current = initializedFirebase
    }, [])

    return (
      <FirebaseContext.Provider
        value={{
          firebaseInitialized,
          firebaseController: firebaseControllerRef.current,
        }}
      >
        {children}
      </FirebaseContext.Provider>
    )
  }
