import { BEIGE_600, BOX_SHADOW_XS, GRAY_700, GRAY_900, WHITE } from 'constants/styling/theme'
import { Box, Stack, Typography } from '@mui/material'
import { InputFieldStyleTypes, MUIInputField } from 'components/common/MUIInputField'
import { useGalleryConstants, useGalleryVirtualVisit } from '../../contexts'

import { FC } from 'react'
import { MUIRadio } from 'components/common/MUIRadio'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props
 */
interface Props {
  /** Replay animations after COPY click */
  replayAnimations?: () => void
}

/**
 * Client gallery virtual visit share card component
 * 
 * @example
 * <ClientGalleryVirtualVisitShareCard />
 */
export const ClientGalleryVirtualVisitShareCard: FC<Props> = ({ replayAnimations }) => {
  const { t } = useTranslation(['virtual_visit_share'])

  const { AvailableVirtualVisitLinkTypes } = useGalleryConstants()
  const {
    virtualVisitEmbedCode,
    virtualVisitShareLink,
    selectedVirtualVisitLinkType,
    setSelectedVirtualVisitLinkType
  } = useGalleryVirtualVisit()

  return (
    <Stack
      width="100%"
      borderRadius=".8rem"
      boxShadow={BOX_SHADOW_XS}
      padding="1.6rem"
      boxSizing="border-box"
      gap="1.6rem"
      sx={{ backgroundColor: WHITE }}
    >
      <Box
        paddingBottom="1.2rem"
        sx={{
          borderBottom: `solid .1rem ${BEIGE_600}`
        }}
      >
        <Typography variant="text-md" fontWeight="semiBold" color={GRAY_900}>{t('share_options')}</Typography>
      </Box>

      <Stack gap=".8rem">
        <Typography variant="text-sm" fontWeight="semiBold" color={GRAY_900}>{t('settings')}</Typography>

        {Object.values(AvailableVirtualVisitLinkTypes).map(linkType => (
          <Stack key={linkType} direction="row" justifyContent="flex-start" alignItems="start" gap=".8rem">
            <Box paddingTop=".1rem">
              <MUIRadio
                checked={selectedVirtualVisitLinkType === linkType}
                onChange={() => setSelectedVirtualVisitLinkType(AvailableVirtualVisitLinkTypes[linkType])}
              />
            </Box>
            <Stack>
              <Typography variant="text-sm" fontWeight="semiBold" color={GRAY_900}>{t(linkType)}</Typography>
              <Typography variant="text-sm" fontWeight="regular" color={GRAY_700}>{t(`${linkType}_des`)}</Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>

      <MUIInputField
        readOnly
        label={t('matterport_link')}
        type={InputFieldStyleTypes.COPY}
        value={virtualVisitShareLink}
        onClickCopy={() => {
          if (!virtualVisitShareLink) return
          navigator.clipboard.writeText(virtualVisitShareLink)
          replayAnimations?.()
        }}
      />

      <MUIInputField
        readOnly
        label={t('embed_code')}
        type={InputFieldStyleTypes.COPY}
        value={virtualVisitEmbedCode}
        onClickCopy={() => {
          if (!virtualVisitEmbedCode) return
          navigator.clipboard.writeText(virtualVisitEmbedCode)
          replayAnimations?.()
        }}
      />

    </Stack>

  )
}
