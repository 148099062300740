import './TabLinkBar.sass'

import { AssignmentDTO } from 'models/assignment'
import AssignmentTabLink from '../AssignmentTabLink/AssignmentTabLink'
import React from 'react'

/**
 * @interface Props Input properties
 */
interface Props {
  /** list of assignments to display links for */
  assignments: AssignmentDTO[]
  /** id of currently viewed assignment to mark it */
  activeAssignmentId: string
}

/**
 * @component Wrapper component which displays TabLinks for provided assignments
 * @example <TabLinkBar assignments={assignments} currentAssignmentId={currentAssignmentId}</TabLinkBar>
 */
const TabLinkBar: React.FC<Props> = ({
  assignments = [],
  activeAssignmentId = undefined
}) => (
  <div className="assignments">
    {assignments.map((assignment) => (
      <AssignmentTabLink key={assignment.id} assignment={assignment} isActive={activeAssignmentId === assignment.id}></AssignmentTabLink>
    ))
    }
  </div>
)

export default TabLinkBar
