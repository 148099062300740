import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { BaseInstructionCard, BaseInstructionCardPassDownProps } from '../BaseInstructionCard'
import { FC, Fragment, ReactNode, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { formatPrice, valueMultiplied } from 'utils/price'
import { usePurchaseFlowConfig, usePurchaseFlowOrderMeta, usePurchaseFlowProducts, useTargetOrderUser } from 'components/pages/PurchaseFlow/_main/contexts'

import Big from 'big.js'
import { Color } from 'constants/assets'
import { CreativesTable } from './CreativesTable.component'
import { Currency } from 'constants/misc'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { MUIBadge } from 'components/common/MUIBadge'
import { MUIButton } from 'components/common/MUIButton'
import { ProductKind } from 'constants/product'
import ReactLoading from 'react-loading'
import { ScienceOutlined } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { SubscriptionInstructionUpsell } from '../../../SubscriptionInstructionUpsell'
import TransitionAppear from 'components/common/TransitionAppear/TransitionAppear'
import { TravelCostProduct } from 'models/purchaseFlow'
import styles from './BookCreativeCard.module.sass'
import { useBookCreative } from '../../_main/contexts'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * Card for Choose your own creative organization instruction.
 * Fetches available creatives on load and displays either
 * - loading
 * - table with available creatives for selection
 * - selected CT
 * - badge with warning, that no CTs are available
 * 
 * For non-subscribed users the card is disabled with up-sell info and link.   
 * Admins are treated as unsubscribed by default
 * 
 * DEPENDENCIES:
 * - PurchaseFlowOverviewContextProvider
 * - TargetOrderUserProvider
 * - ProductAndInstructionsListProvider
 * - InstructionStepProvider
 * - BookCreativeContextProvider
 * 
 * @example
 * <BookCreativeCard
 *  isCardDisabled={false}
 *  isSelectionDisabled={false}
 *  instructionOption={instructionOptionDTO}
 *  instructionType={InstructionType.BILLING_PRIMARY}
 *  onChange={(changed) => console.log}
 * />
 */
export const BookCreativeCard: FC<BaseInstructionCardPassDownProps> = ({
  isCardDisabled,
  isSelectionDisabled,
  instructionOption,
  instructionType,
}) => {

  const { t } = useTranslation(['book_your_ct_card'])
  const { clientData } = useUserData()
  const { targetUser } = useTargetOrderUser()
  const { sessionId, selectedAssignmentPlace } = usePurchaseFlowConfig()
  const { selectedExtraServices, toggleExtraService, setSelectedExtraServices } = usePurchaseFlowOrderMeta()
  const { selectedProducts } = usePurchaseFlowProducts()
  const {
    availableCreatives,
    workspaceCreatives,
    getAvailableCTs,
    selectedCreative,
    setSelectedCreative,
  } = useBookCreative()

  const isSelected = useMemo(() => selectedExtraServices.has(ProductKind.FAVOURITE_CREATIVE), [selectedExtraServices])

  const isNoCTAvailable = useMemo(
    () => getAvailableCTs.isSuccess && !availableCreatives.length && !workspaceCreatives.length,
    [getAvailableCTs.isSuccess, availableCreatives.length, workspaceCreatives.length]
  )

  const offeredTransportCosts: { highestCost: number, lowestCost?: number, currency?: Currency } = useMemo(() => {
    let currency: Currency | undefined = undefined
    let highestCost: Big = Big(0)
    let lowestCost: Big | undefined = undefined

    const creativeList = [...availableCreatives, ...workspaceCreatives]

    if (creativeList.length > 0) {
      for (const creative of creativeList) {
        const callbackCost = (data: TravelCostProduct) => valueMultiplied(data.productUnitPrice, data.productQuantity)
        let cost = callbackCost(creative.travelCost)
        if (!lowestCost) lowestCost = cost
        if (cost > highestCost) highestCost = cost
        if (cost < lowestCost) lowestCost = cost
      }
      currency = creativeList[0].travelCost.productUnitPrice.currency
    }
    // Avoid empty value for lowest
    else lowestCost = highestCost

    return { highestCost: highestCost.toNumber(), lowestCost: lowestCost?.toNumber(), currency }
  }, [availableCreatives, workspaceCreatives])

  useEffect(() => {
    if (!isSelected) return
    if (!getAvailableCTs.isIdle) return
    if (isCardDisabled) return
    if (selectedCreative) return
    if (!targetUser.isSubscribed) return
    if (!targetUser.id) return
    if (!sessionId || !selectedAssignmentPlace) return
    if (availableCreatives.length > 0) return
    if (workspaceCreatives.length > 0) return

    const selectedProductIds = Object.values(selectedProducts).map(product => product.id)

    getAvailableCTs.mutate({
      sessionId,
      selectedProductIds,
      address: selectedAssignmentPlace.formatted_address,
      coordinates: selectedAssignmentPlace.geometry.location,
      clientUserId: targetUser.id,
    })
  }, [availableCreatives.length, getAvailableCTs, getAvailableCTs.isIdle, isCardDisabled, isSelected, selectedAssignmentPlace, selectedCreative, selectedProducts, sessionId, targetUser.isSubscribed, targetUser.id, workspaceCreatives.length])

  useEffect(() => {
    if (isSelected) {
      logAnalyticsEvent(AnalyticsEvent.SELECT_CREATIVE_OPTION_OPENED, {
        lowestTransportCost: offeredTransportCosts.lowestCost,
        highestTransportCost: offeredTransportCosts.highestCost,
        currency: offeredTransportCosts.currency,
      })
    }

    /* 
      Omit everything except isSelected to prevent re-trigger of logging when the values change
      as we are only interested in values current at the time of change os isSelected
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected])


  /** Ensure the card is unselected if noCT is available or user is not subscribed */
  useEffect(() => {
    if (isSelected && (isNoCTAvailable || !targetUser.isSubscribed)) {
      setSelectedExtraServices((currentServices) => {
        const newServices = new Set(currentServices)
        newServices.delete(instructionOption.kind)
        return newServices
      })
    }
  }, [isNoCTAvailable, targetUser.isSubscribed, instructionOption.kind, selectedExtraServices, setSelectedExtraServices, isSelected])

  const callCreativeNote: ReactNode = (
    <div className={styles.note}>

      <h3>{t('call_ct_title')}</h3>

      {/* Handles strong/br tags */}
      <Trans t={t} i18nKey="call_ct_text" parent="p">
        &nbsp;
      </Trans>

    </div>
  )

  return (
    <BaseInstructionCard
      instructionType={instructionType}
      isSelectionDisabled={isSelectionDisabled || isNoCTAvailable || !targetUser.isSubscribed}
      instructionOption={instructionOption}
      isCardDisabled={isCardDisabled || isNoCTAvailable || !targetUser.isSubscribed}
      isChecked={isSelected}
      additionalBottomBoxContent={!targetUser.isSubscribed && (
        <SubscriptionInstructionUpsell
          title={t('upsell_title')}
          text={t('upsell_text')}
          onClick={() =>
            logAnalyticsEvent('unblock_SaaS_select_creative', {
              userEmail: clientData?.email,
              organizationId: clientData?.organizationId,
            })
          }
        />
      )}
      onCheck={() => toggleExtraService(instructionOption.kind)}
      badges={[
        { color: 'gray', text: t('beta_badge'), materialIcon: <ScienceOutlined /> },
        (!targetUser.isSubscribed
          ? { color: 'coral', text: t('sub_only_badge'), materialIcon: <LockOutlinedIcon /> }
          : undefined
        )
      ]}
      rightHeaderContent={(isNoCTAvailable || getAvailableCTs.isPending) && (
        <Fragment>
          {getAvailableCTs.isPending && <ReactLoading className={styles.loading} color={Color.GRAY_TEXT} type="spin" />}
          {isNoCTAvailable && <MUIBadge color="coral" label={t('no_ct_badge')} />}
        </Fragment>
      )
      }
    >
      <div className={styles.bookCreativeCard}>

        {/* LOADING */}
        {getAvailableCTs.isPending && (
          <TransitionAppear visible>
            <ReactLoading className={styles.innerLoading} color={Color.GRAY_TEXT} type="spin" />
          </TransitionAppear>
        )}

        {/* LIST */}
        {getAvailableCTs.isSuccess && !selectedCreative &&
          <Stack gap="3rem">

            {callCreativeNote}

            {!!availableCreatives.length && <CreativesTable creatives={availableCreatives} tableTitle={t('previously_booked_cts')} />}

            {!!workspaceCreatives.length && <CreativesTable creatives={workspaceCreatives} tableTitle={t('workspace_favourite_cts')} />}

          </Stack>
        }

        {/* SELECTED */}
        {getAvailableCTs.isSuccess && !!selectedCreative &&
          <Stack gap="3rem">

            {callCreativeNote}

            <div>
              <h2>{t('chosen_ct')}</h2>

              <div className={styles.selectedCreative}>
                <span>{selectedCreative.fullName}</span>
                <span>{selectedCreative.phoneNumber || '-'}</span>
                <span>{
                  formatPrice(
                    valueMultiplied(
                      selectedCreative.travelCost.productUnitPrice,
                      selectedCreative.travelCost.productQuantity
                    ),
                    selectedCreative.travelCost.productUnitPrice.currency
                  )}
                </span>
                {!isSelectionDisabled && (
                  <MUIButton
                    type="secondaryBorder"
                    size="sm"
                    className={styles.action}
                    onClick={() => setSelectedCreative(null)}
                  >
                    {t('change')}
                  </MUIButton>
                )}
              </div>
            </div>

          </Stack>
        }

      </div>
    </BaseInstructionCard>
  )

}
