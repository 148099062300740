import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { FileManipulationKey, VisualFileType } from 'constants/visual'
import { exportTypeToEditedVisualsType, exportTypeToEditedVisualsWebType } from 'constants/visualEditing'
import { useDispatch, useSelector } from 'react-redux'

import { APIRequestErrorType } from 'constants/API/requestTypes'
import { ActionTypeAPIData } from 'constants/redux'
import { Color } from 'constants/assets'
import { ExpandedVisualPopup } from './ExpandedVisualPopup/ExpandedVisualPopup.module'
import Grid from '@mui/material/Grid'
import ReactLoading from 'react-loading'
import { RootStore } from 'models/redux'
import { Stack } from '@mui/material'
import { VisualEditingSteps } from 'constants/misc'
import { VisualsEditingPreviewGalleryImage } from 'components/pages/VisualsEditing/VisualsEditingPreviewGalleryImage.component'
import classnames from 'classnames'
import { downloadVisual } from 'redux/Individual/Visual/LoadVisual'
import styles from 'components/pages/VisualsEditing/VisualsEditingPreviewGallery.module.sass'
import { useGalleryOrder } from 'components/pages/Gallery/_main/contexts/GalleryOrder.context'
import { useImageRegex } from 'utils/hooks'
import { useTranslation } from 'react-i18next'
import { useVisualsEditing } from 'components/pages/VisualsEditing/VisualsEditing.context'

export enum Direction {
  LEFT,
  RIGHT
}

/**
 * @interface Props Input properties
 */
interface Props {
  /** Override or extend the styles applied to the component. */
  className?: string
  /** JustifyContent for preview root container */
  justifyContent?: 'center' | 'right' | 'left' | 'flex-start' | 'flex-end'
  /** AlignItems for preview root container */
  alignItems?: 'center' | 'flex-start' | 'flex-end'
}

/**
 * Preview Gallery for Visual Editing flow
 * @example <VisualsEditingPreviewGallery className={className}/>
 */
export const VisualsEditingPreviewGallery: FC<Props> = ({
  className,
  justifyContent = 'center',
  alignItems = 'center'
}) => {
  const { t } = useTranslation(['visuals_editing'])
  const dispatch = useDispatch()
  const { assignmentId, activeFlowStep, exportOption, editingJobId, visualEditingFilenames } = useVisualsEditing()
  const imageNameReplacePattern = useImageRegex(assignmentId)
  const {
    sortEntriesFunction,
  } = useGalleryOrder()

  const [isExpandedVisualOpen, setIsExpandedVisualOpen] = useState(false)
  const [expandedVisualIdx, setExpandedVisualIdx] = useState<number | undefined>(undefined)

  const downloadVisualsSelector = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.LOAD_VISUAL]?.[assignmentId]?.[FileManipulationKey.DOWNLOAD])
  const editingVisualsEntries = useMemo(() => (downloadVisualsSelector ? Object.entries(downloadVisualsSelector).filter(([key, allTypes]) => visualEditingFilenames.includes(key) && allTypes?.[VisualFileType.POST_THUMB]) : []).sort(sortEntriesFunction), [downloadVisualsSelector, sortEntriesFunction, visualEditingFilenames])
  const loadedEditedVisualsResponse = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.LOAD_EDITED_VISUAL][editingJobId])
  const loadEditVisualsEntries = useMemo(() => (loadedEditedVisualsResponse ? Object.entries(loadedEditedVisualsResponse).map(([key, allTypes]) => allTypes) : []), [loadedEditedVisualsResponse])

  useEffect(() => {
    if (activeFlowStep === VisualEditingSteps.SETTINGS) {
      for (const visual of visualEditingFilenames) {
        if (downloadVisualsSelector && downloadVisualsSelector[visual]?.[VisualFileType.POST_THUMB]) continue
        dispatch(downloadVisual(assignmentId, visual, VisualFileType.POST_THUMB))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const preloadVisuals = useCallback(() => {
    const tupleArray: [string, string, JSX.Element][] = visualEditingFilenames.map(filename => {
      return [filename, filename, (
        <Grid item key={filename}>
          <VisualsEditingPreviewGalleryImage fileName={filename.replace(imageNameReplacePattern, '')} />
        </Grid>
      )]
    })

    return tupleArray
  }, [imageNameReplacePattern, visualEditingFilenames])

  const editingVisuals = useCallback(() => {
    const tupleArray: [string, string, JSX.Element][] = editingVisualsEntries.map(([imageKey, allTypes]) => {
      const image = allTypes?.[VisualFileType.POST_THUMB]

      if (!image) throw new Error(`Image does not exist for key: ${imageKey} and visual type: ${VisualFileType.POST_THUMB}`)

      const filename = image?.file?.name ?? ''
      const isError = image.request.error_type !== APIRequestErrorType.NONE
      return [filename, filename, (
        <Grid item key={imageKey}>
          {isError ?
            <div className={styles.errorContainer}>
              <span className={styles.errorMessage}>{t('error_not_found')}</span>
            </div>
            : <VisualsEditingPreviewGalleryImage imageUrl={image.signedUrl} fileName={filename.replace(imageNameReplacePattern, '')} />
          }
        </Grid>
      )]
    })

    return tupleArray
  }, [editingVisualsEntries, imageNameReplacePattern, t])

  const visualsListing = useCallback(() => {
    const map: Map<string, [string, JSX.Element]> = new Map()

    for (let [key, reactKey, node] of preloadVisuals()) map.set(key, [reactKey, node])
    for (let [key, reactKey, node] of editingVisuals()) map.set(key, [reactKey, node])

    const entries: [string, [string, JSX.Element]][] = Array.from(map.entries())
    const sortedEntries = entries.sort(sortEntriesFunction)

    return (
      <Fragment>
        {sortedEntries.map(([key, [reactKey, node]]) => (
          <Fragment key={reactKey}>
            {node}
          </Fragment>
        ))}
      </Fragment>
    )
  }, [editingVisuals, preloadVisuals, sortEntriesFunction])

  const switchExpandedVisual = useCallback((direction: Direction) => {
    if (expandedVisualIdx === undefined) return
    const len = visualEditingFilenames.length
    let newVisual

    switch (direction) {
      case Direction.LEFT:
        newVisual = (expandedVisualIdx + len - 1) % len
        break
      case Direction.RIGHT:
        newVisual = (expandedVisualIdx + 1) % len
        break
    }

    setExpandedVisualIdx(newVisual)
  }, [expandedVisualIdx, visualEditingFilenames])

  const expandedVisual = useMemo(() => {
    if (expandedVisualIdx === undefined) return undefined
    if (!loadEditVisualsEntries[expandedVisualIdx]) return undefined
    return loadEditVisualsEntries[expandedVisualIdx]?.[exportTypeToEditedVisualsWebType[exportOption]]
  }, [expandedVisualIdx, exportOption, loadEditVisualsEntries])

  return (
    <Grid container justifyContent={justifyContent} alignItems={alignItems} className={classnames(styles.previewGallery)} direction="column">
      <Grid container justifyContent="center" spacing={{ xs: 3, lg: 3 }} columns={{ xs: 1, lg: 3 }} className={classnames(className)}>

        {activeFlowStep === VisualEditingSteps.SETTINGS && visualsListing()}

        {activeFlowStep === VisualEditingSteps.PREVIEW && loadEditVisualsEntries.map((file: any, index) => {
          const visualData = file?.[exportTypeToEditedVisualsType[exportOption]]

          if (!visualData) {
            return <Stack height="16.6rem" width="24.8rem" flexDirection="row" alignItems="center" justifyContent="center">
              <ReactLoading color={Color.GRAY_TEXT} type="spin" width="2rem" height="2rem" />
            </Stack>
          }

          const isError = visualData?.request.error_type !== APIRequestErrorType.NONE
          const filename = visualData.filename || ''
          const signedUrl = visualData.signedUrl ? visualData.signedUrl : ''
          return (
            <Grid item key={`edited_visual_preview-${visualData.filename}`}>
              {isError ?
                <div className={styles.errorContainer}>
                  <span className={styles.errorMessage}>{t('error_not_found')}</span>
                </div> :
                <VisualsEditingPreviewGalleryImage
                  imageUrl={signedUrl}
                  fileName={filename.replace(imageNameReplacePattern, '')}
                  onClick={() => {
                    setIsExpandedVisualOpen(true)
                    setExpandedVisualIdx(index)
                  }}
                />
              }
            </Grid>
          )
        })}

        <ExpandedVisualPopup
          isOpen={isExpandedVisualOpen}
          signedUrl={expandedVisual?.signedUrl}
          filename={expandedVisual?.filename}
          switchExpandedVisual={switchExpandedVisual}
          onClose={() => setIsExpandedVisualOpen(false)}
        />

      </Grid>
    </Grid>
  )
}
