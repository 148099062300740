import { FC, ReactNode, useMemo } from 'react'
import { ThemeProvider as MUIThemeProvider, ThemeOptions, createTheme } from '@mui/material'

import { ThemeProvider } from '@emotion/react'
import { lightTheme } from 'constants/styling/theme'

/** Provides Material theme and styles for children
 * @example
 *  <BKBNThemeWrapper>
 *    <Typography variant="h1">
 *      Hello World!
 *    </Typography>
 *  </BKBNThemeWrapper>
 */
export const BKBNThemeWrapper: FC<{
  themeOverrides?: Partial<ThemeOptions>
  children?: ReactNode
}> = ({ children, themeOverrides }) => {

  const theme = useMemo(() => createTheme({
    ...lightTheme,
    ...(themeOverrides ?? {}),
  }), [themeOverrides])

  return (
    <MUIThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>

        {/* For now not needed */}
        {/* <GlobalBKBNStyles /> */}

        {children}

      </ThemeProvider>
    </MUIThemeProvider>
  )
}
