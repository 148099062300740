import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, put, takeEvery } from 'redux-saga/effects'
import { getInitRoles, getRoles } from 'utils/auth'

import API from 'utils/API/API'
import { ActionUpdateUserMe } from '.'
import { Endpoint } from 'constants/API'

/** Saga which updates user me */
export function* updateUserMeSaga(receivedAction: ActionUpdateUserMe) {
  let roles = getInitRoles()
  try {
    roles = yield getRoles()
  } catch (e) {
    throw new Error('Unable to get roles for user')
  }

  if (!roles || roles.all.size === 0) throw new Error('The user does not have any role!')

  const { body } = receivedAction.payload
  const url = Endpoint.USER_ME
  const action: ActionUpdateUserMe = yield generalFetch(ActionTypeAPIData.UPDATE_USER_ME, () => API.put(url, {
    ...body
  }, {
    headers: {
      Accept: API.decideRoleMimeType(roles),
    },
  }, {
    endpoint: Endpoint.USER_ME,
  }))

  const updateUserMeAction: ActionUpdateUserMe = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }

  yield put(updateUserMeAction)
}

/** Watcher of update user me actions */
export function* updateUserMeWatcher() {
  yield all([
    takeEvery((action: ActionUpdateUserMe) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.UPDATE_USER_ME]), updateUserMeSaga),
  ])
}
