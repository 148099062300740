import { EntityKeys, QueryType } from 'utils/reactQuery/queryTypes'
import { ExportOption, Opacity, Positioning, RatioOption, Size, VisualEditingSteps } from 'constants/misc/editingOptions'
import { loadWatermarkLogo, purgeLoadWatermarkLogo } from 'redux/Individual/VisualsEditing/LoadWatermarkLogo'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useJobLongPoll, useTriggerVisualsEditing, useVisualsEditingTemplateOptions } from 'dataQueries'

import { ActionTypeAPIData } from 'constants/redux'
import { ArchiveVisualsTag } from 'redux/Individual/Visual/ArchiveVisuals'
import { RatioDTO } from 'models/visualsEditing'
import { RootStore } from 'models/redux'
import { archiveEditingVisuals } from 'redux/Individual/VisualsEditing/ArchivedEditingVisuals'
import constate from 'constate'
import { exportTypeToEditedVisualsType } from 'constants/visualEditing/visualEditingFiles'
import { logAnalyticsEvent } from 'utils/analytics'
import { patternList } from 'constants/misc'
import { purgeUploadWatermarkLogo } from 'redux/Individual/VisualsEditing/UploadWatermarkLogo/uploadWatermarkLogo.actions'
import { useGalleryAssignment } from 'components/pages/Gallery/_main/contexts/GalleryAssignment.context'
import { useGalleryVisualSelection } from 'components/pages/Gallery/_main/contexts/GalleryVisualSelection.context'
import { useGalleryVisualsMeta } from '../Gallery/_main/contexts/GalleryVisualsMeta.context'
import { useQueryClient } from '@tanstack/react-query'
import { useUserData } from 'components/contexts/UserDataContext'
import { useVisualsEditingDownload } from './VisualsEditingDownload.context'
import { useWorkspacesProfileContext } from '../Client/WorkspacesProfile/_main/WorkspacesProfile.context'

const DEFAULT_RATIO_PATTERN = patternList[0]

export const [VisualsEditingContextProvider, useVisualsEditing] = constate(({
  assignmentId,
}: {
  assignmentId: string
}) => {
  const {
    baseUserData,
    organizationData,
    currentUserWorkspace
  } = useUserData()
  const { product } = useGalleryAssignment()
  const { selected } = useGalleryVisualSelection()
  const dispatch = useDispatch()
  const { allUserWorkspacesData } = useWorkspacesProfileContext()
  const {
    downloadUrl,
    hasDownloadFailed,
    isDownloadReady,
    isDownloadRunning
  } = useVisualsEditingDownload()
  const {
    purchasedVisualsKeys,
  } = useGalleryVisualsMeta()
  const client = useQueryClient()

  // Data to all workspaces the user is a member of
  const currentWorkspaceData = allUserWorkspacesData?.find((workspace) => workspace.workspaceId === currentUserWorkspace?.id)

  const visualEditingFilenames = Array.from(selected).filter(filename => purchasedVisualsKeys.has(filename))

  // Job ID for editing visuals
  const [editingJobId, setEditingJobId] = useState('')

  // Visuals Editing flow progress
  const [activeFlowStep, setActiveFlowStep] = useState(VisualEditingSteps.SETTINGS)

  // Save template (Preview page action) status state
  const [saveTemplateStatusSuccess, setSaveTemplateStatusSuccess] = useState(false)

  // Settings of editor options
  const [isTemplateApplied, setIsTemplateApplied] = useState(false)
  const [isRatioActive, setIsRatioActive] = useState(false)
  const [isWatermarkActive, setIsWatermarkActive] = useState(false)
  const [selectedRatioPattern, setSelectedRatioPattern] = useState<RatioDTO>(DEFAULT_RATIO_PATTERN)
  const [selectedWatermarkPosition, setSelectedWatermarkPosition] = useState<Positioning>(Positioning.CENTER)
  const [selectedWatermarkSize, setSelectedWatermarkSize] = useState(Size.MEDIUM)
  const [selectedWatermarkOpacity, setSelectedWatermarkOpacity] = useState<number>(Opacity.DEFAULT)
  const [exportOption, setExportOption] = useState<ExportOption>(ExportOption.ORIGINAL)

  const watermarkId = !!currentWorkspaceData?.watermarks.length ? currentWorkspaceData?.watermarks[0].id : null
  const templateId = !!currentWorkspaceData?.visualEditingTemplates.length ? currentWorkspaceData?.visualEditingTemplates[0].id : null

  const savedTemplateOptionsResponse = useVisualsEditingTemplateOptions(currentUserWorkspace?.id, templateId, !!templateId)
  const savedTemplateOptionsData = savedTemplateOptionsResponse?.data

  const isWatermarkLockedToTemplate = savedTemplateOptionsData?.watermarkId === watermarkId

  const uploadedWatermarkLogo = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.LOAD_WATERMARK_LOGO].data)
  const uploadedWatermarkLogoResponse = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.UPLOAD_WATERMARK_LOGO])
  const loadWatermarkLogoResponse = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.LOAD_WATERMARK_LOGO])
  const showExitPopup = useMemo(() => isWatermarkActive || isRatioActive || exportOption !== ExportOption.ORIGINAL, [exportOption, isRatioActive, isWatermarkActive])

  const triggerVisualEditing = useTriggerVisualsEditing()
  const longPollJob = useJobLongPoll()

  // The user hasn't made any changes to the visuals. We use this to disable sumbit button and show validation message.
  const processPreviewDisabled = useMemo(() => (
    (!isWatermarkActive || (isWatermarkActive && !uploadedWatermarkLogo?.signedURL)) &&
    (!isRatioActive || (isRatioActive && selectedRatioPattern.value === RatioOption.ORIGINAL)) &&
    (exportOption === ExportOption.ORIGINAL)
  ), [exportOption, isRatioActive, isWatermarkActive, selectedRatioPattern.value, uploadedWatermarkLogo?.signedURL])

  const handleEditingOptionChange = (callback: () => void) => {
    // Update the isTemplateApplied state to indicate that the template is no longer applied after user changes options
    if (isTemplateApplied) setIsTemplateApplied(false)
    // Enable user to re-save template when they change options by going back on Settings page after template was saved
    if (saveTemplateStatusSuccess) setSaveTemplateStatusSuccess(false)

    callback()
  }

  const logEditEvent = useCallback((eventName: string, params?: {}) => {
    logAnalyticsEvent(eventName, {
      userEmail: baseUserData?.email,
      organizationId: organizationData?.id,
      assignmentId,
      ...params
    })
  }, [assignmentId, baseUserData?.email, organizationData?.id])

  const retryDownload = useCallback(() => {
    logEditEvent('Retry Visuals Editing Download')
    const visualsType = exportTypeToEditedVisualsType[exportOption]

    if (editingJobId) {
      hasDownloadFailed && dispatch(archiveEditingVisuals(assignmentId, editingJobId, [visualsType], visualEditingFilenames, ArchiveVisualsTag.CLIENT_ARCHIVE, false))
      !!downloadUrl && window.location.replace(downloadUrl)
    }
  }, [logEditEvent, editingJobId, assignmentId, dispatch, downloadUrl, hasDownloadFailed, visualEditingFilenames, exportOption])

  // Logs for editor options
  const productParams = {
    productCategory: product?.category,
    productType: product?.type,
    productKind: product?.kind,
    numberOfVisuals: visualEditingFilenames.length
  }

  const logSubmitEdit = () => {
    const options = {
      isLogoEdited: isWatermarkActive,
      isRatioEdited: isRatioActive,
      isExportEdited: exportOption !== ExportOption.ORIGINAL
    }

    if (isWatermarkActive) {
      Object.assign(options, {
        logoSize: selectedWatermarkSize,
        logoOpacity: selectedWatermarkOpacity,
        logoPositioning: selectedWatermarkPosition
      })
    }

    if (isRatioActive) {
      Object.assign(options, { ratio: selectedRatioPattern })
    }

    Object.assign(options, { exportOption })

    logEditEvent('Submit Bulk Editing', { ...options, ...productParams })
  }

  const resetWatermarkContext = () => {
    setSelectedWatermarkSize(Size.MEDIUM)
    setSelectedWatermarkOpacity(Opacity.DEFAULT)
    setSelectedWatermarkPosition(Positioning.CENTER)
    setIsWatermarkActive(false)
    purgeLoadWatermarkLogo()
    purgeUploadWatermarkLogo()
  }

  const resetVisualsEditingContext = () => {
    resetWatermarkContext()
    setIsRatioActive(false)
    setSelectedRatioPattern(DEFAULT_RATIO_PATTERN)
    setExportOption(ExportOption.ORIGINAL)
    setIsTemplateApplied(false)
  }

  useEffect(() => {
    if (!currentUserWorkspace || !watermarkId) return
    // everytime uploaded watermarkId changes, we want to fetch and load logo based on current watermarkId
    dispatch(loadWatermarkLogo(currentUserWorkspace.id, watermarkId))

    return () => {
      dispatch(purgeLoadWatermarkLogo())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserWorkspace, watermarkId])

  useEffect(() => {
    // When we upload the watermark we need to invalidate query for useGetUserWorkspaces(), 
    // what will fetch our workspace data and we will able to load watermark based on watermarkId
    if (uploadedWatermarkLogoResponse.signedUrl) {
      client.invalidateQueries({ queryKey: [EntityKeys.USER_WORKSPACE, QueryType.LIST_MINE] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedWatermarkLogoResponse.signedUrl])

  useEffect(() => {
    if (triggerVisualEditing.isSuccess && triggerVisualEditing.data) {
      const { id } = triggerVisualEditing.data.data
      setEditingJobId(id)
    }
  }, [triggerVisualEditing])

  useEffect(() => {
    // Set data when user wants to apply template in VE settings page
    if (isTemplateApplied && savedTemplateOptionsData) {
      setIsWatermarkActive(Boolean(savedTemplateOptionsData.watermarkId))
      setSelectedWatermarkSize(savedTemplateOptionsData.watermarkSize ?? Size.MEDIUM)
      setSelectedWatermarkOpacity(savedTemplateOptionsData.watermarkOpacity ?? Opacity.DEFAULT)
      setSelectedWatermarkPosition(savedTemplateOptionsData.watermarkPositioning ?? Positioning.CENTER)
      setIsRatioActive(Boolean(savedTemplateOptionsData.imageRatio))
      setSelectedRatioPattern(patternList.find((patttern) => patttern.value === savedTemplateOptionsData.imageRatio) ?? DEFAULT_RATIO_PATTERN)
      setExportOption(ExportOption[savedTemplateOptionsData.export])
    }
  }, [isTemplateApplied, savedTemplateOptionsData])

  return {
    assignmentId,
    isWatermarkActive,
    setIsWatermarkActive,
    selectedWatermarkPosition,
    setSelectedWatermarkPosition,
    selectedWatermarkSize,
    setSelectedWatermarkSize,
    selectedWatermarkOpacity,
    setSelectedWatermarkOpacity,
    isRatioActive,
    setIsRatioActive,
    selectedRatioPattern,
    setSelectedRatioPattern,
    exportOption,
    setExportOption,
    resetWatermarkContext,
    productParams,
    logEditEvent,
    logSubmitEdit,
    showExitPopup,
    processPreviewDisabled,
    uploadedWatermarkLogo,
    uploadedWatermarkLogoResponse,
    loadWatermarkLogoResponse,
    setActiveFlowStep,
    activeFlowStep,
    visualEditingFilenames,
    downloadUrl,
    hasDownloadFailed,
    isDownloadReady,
    isDownloadRunning,
    retryDownload,
    editingJobId,
    currentWorkspaceData,
    watermarkId,
    templateId,
    setIsTemplateApplied,
    isTemplateApplied,
    resetVisualsEditingContext,
    savedTemplateOptionsResponse,
    savedTemplateOptionsData,
    handleEditingOptionChange,
    setSaveTemplateStatusSuccess,
    saveTemplateStatusSuccess,
    isWatermarkLockedToTemplate,
    triggerVisualEditing,
    longPollJob
  }
})
