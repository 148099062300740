import './ErrorPage.sass'

import { PageTransition } from 'utils/animations'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ErrorPage: React.FC = () => {
  const { t } = useTranslation('errorpage')
  return (
    <PageTransition>
      <div className="page triangle-stripe-bg errorpage">
        <div className="page-content">
          <div className="wrap">
            <div className="content">
              <h1>{t('title')}</h1>
              <p>{t('text')}</p>
              <br /><br />
              <a href="/" className="button primary">{t('button')}</a>
            </div>
          </div>
        </div>
      </div>
    </PageTransition>
  )
}

export default ErrorPage
