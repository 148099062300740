import { SignedURLDTO, UrlDTO } from 'models/visuals'

import { AxiosResponse } from 'axios'
import { Nullable } from 'models/helpers'
import { UseAPIReturn } from 'utils/API'

export enum FileState {
  IDLE = 'IDLE',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  RUNNING = 'RUNNING',
  CANCELLED = 'CANCELLED',
}

export enum FileAction {
  UPLOAD = 'UPLOAD',
  RE_UPLOAD = 'RE_UPLOAD',
  LOAD = 'LOAD',
  DELETE = 'DELETE',
  INIT = 'INIT',
}

export interface FileAPIEntry {
  id: string
  state: FileState
  action: FileAction
  progress: number
  signedUrl: Nullable<SignedURLDTO>
  displayUrl: Nullable<SignedURLDTO>
  abortController: Nullable<AbortController>
  originalFilename: Nullable<string>
  gcFilename: Nullable<string>
  tag: Nullable<string>
  fileObject: File
  order: number
}

export type UploadUrlResolver = (fileEntry: FileAPIEntry, api: UseAPIReturn<string>) => Promise<AxiosResponse<SignedURLDTO>>
export type ReUploadUrlResolver = (fileEntry: FileAPIEntry, api: UseAPIReturn<string>) => Promise<AxiosResponse<SignedURLDTO>>
export type LoadUrlResolver = (fileEntry: FileAPIEntry, api: UseAPIReturn<string>) => Promise<AxiosResponse<UrlDTO>>
export type FileDeleteFnc = (fileEntry: FileAPIEntry, api: UseAPIReturn<string>) => Promise<AxiosResponse<any, any>>

export interface UploadOptions {
  tag?: string
  onSettled?: (successIds: string[], errorIds: string[], cancelledIds: string[]) => void
  uploadUrlResolverFnc?: UploadUrlResolver
}

export interface ReUploadOptions {
  tag?: string
  onSuccess?: () => void
  onError?: () => void
  onCancel?: () => void
  reUploadUrlResolverFnc?: ReUploadUrlResolver
}

export interface DeleteOptions {
  deleteHandlerFnc?: FileDeleteFnc
}
