import { UseMutationResult, UseQueryResult } from '@tanstack/react-query'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'
import Button from 'components/common/Button/Button'
import { CircularIconLoading } from 'components/pages/VisualsMarketing/Loading'
import Icon from 'components/common/Icon/Icon'
import { IconType } from 'constants/assets'
import Modal from 'components/common/Modals/Modal/Modal'
import { Nullable } from 'models/helpers'
import { QueryStatus } from 'components/common/QueryStatus'
import React from 'react'
import { RequestStatus } from 'components/common/RequestStatus'
import { Stack } from '@mui/material'
import styles from './EditProcessingPopup.module.sass'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Whether the popup is open */
  isOpen: boolean
  /** Whether the visual proccessing is failed or not */
  isError: boolean
  /** Whether is using query status or not */
  isQueryStatus?: boolean
  /** React query response for displaying QueryStatus */
  query?: UseMutationResult<any, any, any, any> | UseQueryResult<any, any>
  /** Edit visual job response for displaying RequestStatus */
  statusResponse?: Nullable<APIRequest<unknown>>
  /** Onclick action to close the popup  */
  onClose: () => void
}

/**
 * @component EditProcessingPopup 
 * @example
 * <EditProcessingPopup isOpen={true} />
 */

export const EditProcessingPopup: React.FC<Props> = ({
  isOpen,
  isError = false,
  isQueryStatus = false,
  query,
  statusResponse,
  onClose
}) => {
  const { t } = useTranslation(['visuals_editing'])

  return (
    <Modal
      modalContentClassName={styles.editProcessingPopup}
      isOpen={isOpen}
    >

      {!!onClose && (isError || isQueryStatus) &&
        <Button className={styles.closeBtnRight} type="secondary noborder" onClick={onClose}>
          <Icon icon={IconType.CROSS} />
        </Button>
      }

      <div className={styles.iconWrap}>
        <div className={styles.icon}>
          <CircularIconLoading />
        </div>
      </div>

      <Stack alignItems="center">
        <div className={styles.title}>{t(`edit_processing_popup.${isError ? 'error_title' : 'title'}`)}</div>
        <div className={styles.description}>{t(`edit_processing_popup.${isError ? 'error_description' : 'description'}`)}</div>
      </Stack>

      {(isQueryStatus && query) ?
        <QueryStatus
          query={query}
          showStates={['error']}
          spaceTopRem={2}
          spaceBottomRem={0}
        />
        :
        <RequestStatus
          request={statusResponse}
          showStates={[APIRequestState.ERROR]}
          spaceTopRem={2}
        />}

    </Modal>
  )
}
