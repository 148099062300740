import React, { useCallback, useEffect, useState } from 'react'
import axios, { AxiosRequestConfig } from 'axios'

import Button from 'components/common/Button/Button'
import { Language } from 'translations/Language'
import { PageTransition } from 'utils/animations'
import { RoleMimetype } from 'constants/API'
import { useAuth0 } from 'utils/auth'
import { useRemoteConfig } from 'components/contexts/RemoteConfigContext'

const APIDebug: React.FC = () => {
  const { getTokenSilently, roles } = useAuth0()
  const { remoteConfigIsLoaded, remoteConfigFailedToLoad, remoteConfigIsWorking } = useRemoteConfig()
  const [apiMessage, setApiMessage] = useState<any>('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>('')
  const [auth0Token, setAuth0Token] = useState('')

  const [productRefreshCountryCode, setProductRefreshCountryCode] = useState('')
  const [userLocaleEmail, setUserLocaleEmail] = useState('')
  const [userLocaleLangauge, setUserLocaleLangauge] = useState(Language.EN)
  const [deleteTranslationKey, setDeleteTranslationKey] = useState('')
  const [deleteTranslationLanguageParam, setDeleteTranslationLanguageParam] = useState<Language | 'all'>('all')

  useEffect(() => {
    console.log('remoteConfigIsLoaded', remoteConfigIsLoaded)
  }, [remoteConfigIsLoaded])

  useEffect(() => {
    console.log('remoteConfigFailedToLoad', remoteConfigFailedToLoad)
  }, [remoteConfigFailedToLoad])

  useEffect(() => {
    console.log('remoteConfigIsWorking', remoteConfigIsWorking)
  }, [remoteConfigIsWorking])

  const callApi = useCallback(async (path: string, options?: AxiosRequestConfig) => {
    try {
      setError(null)
      setLoading(true)
      const token = await getTokenSilently()
      setAuth0Token(token)
      console.log(token)

      if (path === '/token') {
        setApiMessage(token)
        return
      }

      if (!process.env.REACT_APP_API_URL) throw new Error('API_URL env variable is missing')

      let method: AxiosRequestConfig['method'] = 'GET'

      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}${path}`,
        method,
        ...options,
        headers: {
          Authorization: `Bearer ${token}`,
          ...options?.headers,
        },
      })

      setApiMessage(response.data)
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setLoading(false)
    }
  }, [getTokenSilently])

  const deleteApiTranslationKeys = useCallback(() => {
    const keys = deleteTranslationKey.split(',').map(str => str.trim().replace(/\s+/g, ''))
    const params = deleteTranslationLanguageParam !== 'all' ? { language: deleteTranslationLanguageParam } : {}

    for (const [index, key] of keys.entries()) {
      if (!key) continue
      window.setTimeout(() => {
        callApi(`/translation/${key}`, {
          method: 'DELETE',
          params,
          headers: {
            Accept: RoleMimetype.ADMIN,
          },
        })
      }, index * 100)
    }
  }, [callApi, deleteTranslationKey, deleteTranslationLanguageParam])

  if (!roles.isAdmin) return (
    <PageTransition>
      <div className="page">
        <div className="page-content">
          <h1>ADMIN ONLY AREA</h1>
        </div>
      </div>
    </PageTransition>
  )

  return (
    <PageTransition>
      <div className="page">
        <div className="page-content">
          <h1 style={{ fontSize: '2em' }}>API methods</h1>
          <div className="content">
            <h4>Token</h4>
            <Button onClick={() => callApi('/token')}>Generate Token</Button>
            <br /><br /><br /><br /><br /><br />
            <h4>API Test</h4>
            <Button onClick={() => callApi('/hello')}>Ping API: /hello</Button>
            <br /><br /><br /><br /><br /><br />
            <h4>User actions (self)</h4>
            <Button onClick={() => callApi('/user/me')}>Ping API: /user/me</Button>
            <br /><br />
            <hr />
            <br />
            <Button onClick={() => callApi('/user/resetPassword')}>Ping API: /user/resetPassword</Button>
            <br /><br />
            <hr />
            <br />
            <Button onClick={() => callApi('/user/verify')}>Ping API: /user/verify</Button>
            <br /><br /><br /><br /><br /><br />
            <h4>Product management</h4>
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              <label
                htmlFor="productRefreshCountryCode"
              >
                (Optional) countryCode
              </label>
              <input
                style={{
                  marginLeft: '2rem',
                  flexGrow: 1,
                }}
                type="text"
                name="productRefreshCountryCode"
                id="productRefreshCountryCode"
                placeholder="countryCode"
                value={productRefreshCountryCode}
                onChange={e => setProductRefreshCountryCode(e.target.value)}
              />
            </div>
            <br /><br />
            <Button onClick={() => callApi('/product/refresh', productRefreshCountryCode !== '' ? {
              params: {
                countryCode: productRefreshCountryCode,
              },
            } : undefined)}>Ping API: /product/refresh</Button>
            <br /><br />
            <hr />
            <br />
            <Button onClick={() => callApi('/product/all', { headers: { Accept: RoleMimetype.ADMIN } })}>Ping API: /product/all</Button>
            <br /><br /><br /><br /><br /><br />
            <h4>User actions (other)</h4>
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              <label
                htmlFor="userLocaleEmail"
              >
                Client email address
              </label>
              <input
                style={{
                  marginLeft: '2rem',
                  flexGrow: 1,
                }}
                type="email"
                name="userLocaleEmail"
                id="userLocaleEmail"
                placeholder="Client email address"
                value={userLocaleEmail}
                onChange={e => setUserLocaleEmail(e.target.value)}
              />
            </div>
            <br /><br />
            <label
              htmlFor="userLocaleLangauge"
            >
              Client language
            </label>
            <select
              style={{
                marginLeft: '2rem',
                display: 'inline-block',
              }}
              name="userLocaleLangauge"
              id="userLocaleLangauge"
              defaultValue={userLocaleLangauge}
              onChange={e => setUserLocaleLangauge(Language[e.target.value as Language] || Language.EN)}
            >
              {Object.values(Language).map(lang => (
                <option key={lang} value={lang}>{lang}</option>
              ))}
            </select>
            <br /><br /><br />
            <Button onClick={() => callApi('/user/locale', userLocaleEmail !== '' ? {
              method: 'PUT',
              params: {
                email: userLocaleEmail,
              },
              headers: {
                Accept: RoleMimetype.ADMIN,
              },
              data: {
                language: userLocaleLangauge,
              },
            } : undefined)}>PUT API: /user/locale?email={userLocaleEmail}</Button>
            <br /><br /><br /><br /><br /><br />
            <h4>Translations</h4>
            <label
              htmlFor="deleteTranslationKey"
            >
              Translation key
            </label>
            <textarea
              cols={50}
              rows={12}
              name="deleteTranslationKey"
              id="deleteTranslationKey"
              placeholder="Translation key(s) to delete. Comma separated key list. All whitespaces will be truncated."
              value={deleteTranslationKey}
              onChange={e => setDeleteTranslationKey(e.target.value)}
            >
            </textarea>
            <br /><br />
            <label
              htmlFor="deleteTranslationLanguageParam"
            >
              (optional) Translation language
            </label>
            <select
              style={{
                marginLeft: '2rem',
                display: 'inline-block',
              }}
              name="deleteTranslationLanguageParam"
              id="deleteTranslationLanguageParam"
              defaultValue={deleteTranslationLanguageParam}
              onChange={e => setDeleteTranslationLanguageParam(e.target.value === 'all' ? e.target.value : Language[e.target.value as Language] || Language.EN)}
            >
              <option value={'all'}>All</option>
              {Object.values(Language).map(lang => (
                <option key={lang} value={lang}>{lang}</option>
              ))}
            </select>
            <br /><br /><br />
            <Button onClick={() => deleteApiTranslationKeys()}>DELETE API: /translation/{'{translationKey}'}</Button>
            <br /><br /><br /><br /><br /><br />
            <h4>Results will appear here...</h4>
            <h2>Token:</h2>
            <textarea name="auth0Token" id="auth0Token" cols={50} rows={12} readOnly value={auth0Token || ''}></textarea>
            <br /><br />
            {loading &&
              <p>Loading...</p>
            }
            <h2>Result:</h2>
            <textarea name="result" id="result" cols={50} rows={12} readOnly value={apiMessage ? JSON.stringify(apiMessage, null, 2) : ''}></textarea>
            <br /><br />
            <h2>Error:</h2>
            <textarea name="error" id="error" cols={50} rows={12} readOnly value={error ? JSON.stringify(error, null, 2) : ''}></textarea>
          </div>
        </div>
      </div>
    </PageTransition>
  )
}

export default APIDebug
