import { BLUE_100, BLUE_300, CORAL_100, CORAL_400, GRAY_400, WHITE } from 'constants/styling/theme'
import React, { ReactNode, useMemo } from 'react'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'

type UploadStyleType = 'default' | 'error' | 'information'

/** @interface Props for the UploadWrapper component. */
interface Props {
  /** The content to be wrapped. */
  children: ReactNode
  /** The wrapper style changing the border and background color. */
  type?: UploadStyleType
}

/**
 * @component
 * UploadWrapper component designed for wrapping uploaded content with specific styling.
 *
 * @example
 * <UploadWrapper style="error">
 *   <YourContent />
 * </UploadWrapper>
 */
export const UploadWrapper: React.FC<Props> = ({ children, type: style = 'default' }) => {

  const borderColor = useMemo(() => {
    switch (style) {
      case 'error':
        return CORAL_400
      case 'information':
        return BLUE_300
      default:
        return GRAY_400
    }
  }, [style])

  const backgroundColor = useMemo(() => {
    switch (style) {
      case 'error':
        return CORAL_100
      case 'information':
        return BLUE_100
      default:
        return WHITE
    }
  }, [style])

  return (
    <BorderBoxWrapper
      sx={{
        gap: 1,
        padding: 1,
        marginTop: 1,
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
      }}
      elevation='none'
      borderColor={borderColor}
      backgroundColor={backgroundColor}
    >
      {children}
    </BorderBoxWrapper>
  )
}
