import { ContentLibraryLinks } from 'constants/staticLinks'

// TODO: Add it and nl version
export const getContentLibraryLink = (language: string) => {
  switch (language.toLowerCase()) {
    case 'fr':
      return ContentLibraryLinks.FR
    case 'de':
      return ContentLibraryLinks.DE
    case 'en':
      return ContentLibraryLinks.EN
    case 'es':
      return ContentLibraryLinks.ES
    default:
      return ContentLibraryLinks.GENERAL
  }
}
