import constate from 'constate'
import { useGetUpsellProducts } from 'dataQueries'
import { useMemo } from 'react'

/** Props required for the RenovationProductsProvider context. */
interface UpsellProductsProviderProps {
  /** The session ID of the user. */
  assignmentId: string
}

function useGalleryUpsellProductsAPI({
  assignmentId,
}: UpsellProductsProviderProps) {

  const getUpsellProduct = useGetUpsellProducts(assignmentId)

  // Query response data returning the renovation product kind (COMMERCIAL_RENOVATION).
  const upsellRenovationProduct = useMemo(() => {
    if (!getUpsellProduct.isSuccess || !getUpsellProduct.data?.data) return undefined

    return getUpsellProduct.data.data
  }, [getUpsellProduct.data?.data, getUpsellProduct.isSuccess])

  return {
    upsellRenovationProduct
  }
}

export const [GalleryUpsellProductsContextProvider, useGalleryUpsellProducts] = constate(useGalleryUpsellProductsAPI)
