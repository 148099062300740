import { FC, ReactNode } from 'react'

import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import Stack from '@mui/material/Stack'
import TotalCount from 'components/common/TotalCount/TotalCount'
import classNames from 'classnames'
import styles from './PageTab.module.sass'
import useMediaQuery from '@mui/material/useMediaQuery'

interface Props {
  /** Tab */
  tab: string
  /** Status of Active tab */
  activeTab: string
  /** Text of tab */
  tabText: string
  /** OnClick action to switch tab */
  onClick: () => void
  /** Disable onClick action on tab */
  disabled?: boolean
  /** Trailing icon next to the text */
  trailingIcon?: ReactNode
  /** Total count to be displayed next to the text and trailingIcon (if set) */
  totalCount?: number | null
}

/**
 * Page tab component.
 * @example <PageTab activeTab="Gallery" tab="Order" tabText="Order" onClick={() => {}} />
 */
export const PageTab: FC<Props> = ({ activeTab, tab, tabText, onClick, trailingIcon, disabled = false, totalCount }) => {
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)

  const handleClick = () => {
    if (!disabled) {
      onClick()
    }
  }

  return (
    <button
      className={classNames(styles.tab, { [styles.active]: activeTab === tab, [styles.mobile]: isMobileView, [styles.disabled]: disabled })}
      onClick={handleClick}
    >
      <Stack gap={0.6} direction="row" alignItems="center">

        {tabText} {trailingIcon}

        {typeof totalCount !== 'undefined' && <TotalCount totalCount={totalCount} isActive={true} className={styles.totalCountText} />}

      </Stack>
    </button>
  )
}
