import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { Endpoint, RoleMimetype } from 'constants/API'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, put, takeEvery } from 'redux-saga/effects'

import API from 'utils/API/API'
import { ActionSearchOrganizations } from '.'

/** Saga which searches organizations by VAT number */
export function* searchOrganizationsSaga(receivedAction: ActionSearchOrganizations) {
  const { vatNumber, page, size } = receivedAction.payload
  const url = Endpoint.ORGANIZATION_SEARCH
  const action: ActionSearchOrganizations = yield generalFetch(ActionTypeAPIData.SEARCH_ORGANIZATIONS, () => API.get(url, {
    params: {
      vatNumber,
      page: page.toString(),
      size: size.toString(),
    },
    headers: {
      Accept: RoleMimetype.ADMIN,
    },
  }, {
    endpoint: Endpoint.ORGANIZATION_SEARCH,
  }))
  const searchOrganizationsAction: ActionSearchOrganizations = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }
  yield put(searchOrganizationsAction)
}

/** Watcher of search organizations actions */
export function* searchOrganizationsWatcher() {
  yield all([
    takeEvery((action: ActionSearchOrganizations) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.SEARCH_ORGANIZATIONS]), searchOrganizationsSaga),
  ])
}
