import { FC, ReactNode } from 'react'

import Stack from '@mui/material/Stack'
import styles from './GalleryInfoCard.module.sass'

interface Props {
  /** Title of the card content */
  title?: string
  /** Custom pomponent in the card content */
  children: ReactNode
  alignItems?: 'center' | 'left'
}

/**
 * Gallery information card component.
 * @example <GalleryInfoCard><div>Summary</div></GalleryInfoCard>
 */
export const GalleryInfoCard: FC<Props> = ({ title, children, alignItems = 'center' }) =>
  <Stack className={styles.galleryInfoCard} width="100%" direction="column" alignItems={alignItems} justifyContent="space-between" boxSizing="border-box" gap={1.6}>
    {!!title &&
      <div className={styles.title}>{title}</div>
    }
    {children}
  </Stack>
