import { Badge, CreativeStatusBadge } from '../Badges'
import { DEBOUNCE_TIMEOUT, TIMEOUT_AFTER_SUCCESSFUL_CALLBACK } from 'constants/application'
import { bigFromFee, decimalFromBig, formatCurrency, formatPrice } from 'utils/price'
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'

import BlockInfo from '../BlockInfo/BlockInfo'
import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import { IconType } from 'constants/assets'
import { QueryStatus } from '../QueryStatus'
import { SuggestedCreativeDTO } from 'models/creative'
import { debounceEffect } from 'utils/helpers'
import inputStyles from '../../styles/input.module.sass'
import { multilineText } from 'utils/formatting'
import styles from '../AssignCreative/AssignCreative.module.sass'
import { useAdminSelectCreative } from 'dataQueries'
import { useGalleryDeal } from 'components/pages/Gallery/_main/contexts/GalleryDeal.context'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** The additional classes to append */
  className?: string
  /** Assignment ID */
  assignmentId: string
  /** Data of the chosen creative */
  creativeData: SuggestedCreativeDTO
  /** On button "Back" click action to be called */
  onClickBack?: (e: React.MouseEvent) => void
  /** Function called after creative selected with successful response */
  callbackAfterSuccessfulResponse?: () => void
}

/**
 * @component Assign chosen creative detail for admin
 * @example
 * <AssignChosenCreativeDetail assignmentId={assignmentId} creativeData={SuggestedCreative} />
 */
const AssignChosenCreativeDetail: React.FC<Props> = ({
  className = '',
  assignmentId,
  creativeData,
  onClickBack,
  callbackAfterSuccessfulResponse,
}) => {
  const { t } = useTranslation(['assign_creative'])
  const { dealId } = useGalleryDeal()

  const adminSelectCreative = useAdminSelectCreative()

  const initRemuneration = useMemo(() => bigFromFee(creativeData.remuneration || 0), [creativeData])
  const initTransportCost = useMemo(() => bigFromFee(creativeData.transportRemuneration || 0), [creativeData])
  const initBonus = useMemo(() => bigFromFee(creativeData.bonus || 0), [creativeData])
  const initTotalRemuneration = useMemo(() => bigFromFee(initRemuneration.plus(initTransportCost).plus(initBonus)), [initRemuneration, initTransportCost, initBonus])
  const initMargin = useMemo(() => bigFromFee(creativeData.margin || 0), [creativeData])
  const currency = useMemo(() => creativeData.remuneration.currency, [creativeData])
  const formattedCurrency = useMemo(() => formatCurrency(currency), [currency])
  const availabilityStatus = useMemo(() => creativeData.availabilityStatus, [creativeData])


  const [transportCostValue, setTransportCostValue] = useState(formatPrice(initTransportCost))
  const [transportCostDifference, setTransportCostDifference] = useState(formatPrice(0))
  const [bonusValue, setBonusValue] = useState(formatPrice(initBonus))
  const [bonusDifference, setBonusDifference] = useState(formatPrice(0))
  const [remunerationValue, setRemunerationValue] = useState(formatPrice(initRemuneration))
  const [remunerationDifference, setRemunerationDifference] = useState(formatPrice(0))
  const [totalRemunerationValue, setTotalRemunerationValue] = useState(formatPrice(initTotalRemuneration))
  const [marginValue, setMarginValue] = useState(formatPrice(initMargin))

  const debounceTotalRemunerationTimeoutRef = useRef<number | undefined>(undefined)
  const firstDebounceOccured = useRef<boolean>(false)

  const isCreativeTransportCostEmpty = useMemo(() => transportCostValue === '', [transportCostValue])
  const isCreativeBonusEmpty = useMemo(() => bonusValue === '', [bonusValue])

  useEffect(() => {
    debounceEffect(() => {
      if (transportCostValue && bonusValue) {
        setTransportCostDifference(formatPrice(bigFromFee(initTransportCost).minus(bigFromFee(transportCostValue))))
        setBonusDifference(formatPrice(bigFromFee(initBonus).minus(bigFromFee(bonusValue))))
        setRemunerationDifference(formatPrice(bigFromFee(initRemuneration).minus(bigFromFee(remunerationValue))))
        setMarginValue(formatPrice(bigFromFee(initMargin).plus(bigFromFee(transportCostDifference)).plus(bigFromFee(bonusDifference).plus(remunerationDifference))))
        setTotalRemunerationValue(formatPrice(bigFromFee(remunerationValue).plus(bigFromFee(transportCostValue)).plus(bigFromFee(bonusValue))))
      }
    }, debounceTotalRemunerationTimeoutRef, firstDebounceOccured.current ? DEBOUNCE_TIMEOUT : 0)
  }, [initBonus, initTransportCost, remunerationValue, initMargin, transportCostValue, transportCostDifference, bonusValue, bonusDifference, initRemuneration, remunerationDifference])


  useLayoutEffect(() => {
    if (!callbackAfterSuccessfulResponse) return
    window.setTimeout(() => {
      if (adminSelectCreative.isSuccess) {
        callbackAfterSuccessfulResponse()
      }
    }, TIMEOUT_AFTER_SUCCESSFUL_CALLBACK)
  }, [adminSelectCreative, callbackAfterSuccessfulResponse])

  const bookCreative = useCallback(() => {
    let creativeRemuneration = creativeData.remuneration
    creativeRemuneration.value = decimalFromBig(remunerationValue)
    let creativeTransportRemuneration = creativeData.transportRemuneration
    creativeTransportRemuneration.value = decimalFromBig(transportCostValue)
    let creativeBonus = creativeData.bonus
    creativeBonus.value = decimalFromBig(bonusValue)

    adminSelectCreative.mutate({
      dealId,
      assignmentId,
      creativeId: creativeData.id,
      remuneration: creativeRemuneration,
      transportRemuneration: creativeTransportRemuneration,
      bonus: creativeBonus
    })
  }, [adminSelectCreative, assignmentId, bonusValue, creativeData.bonus, creativeData.id, creativeData.remuneration, creativeData.transportRemuneration, dealId, remunerationValue, transportCostValue])

  return (
    <div className={className}>
      <div className={`${styles.line} ${styles.badges}`}>
        <CreativeStatusBadge
          className={`${styles.creativeBadge} ${styles.marginRight}`}
          creativeStatus={availabilityStatus}
        />
        <Badge color={(parseInt(marginValue) >= 0) ? 'gray' : 'red'}>
          <strong className={`${styles.total} ${styles.marginRight}`}>{t('margin')}</strong>
          <strong className={(parseInt(marginValue) >= 0) ? `${styles.black}` : ''}>{formatPrice(marginValue, currency)}</strong>
        </Badge>
      </div>
      <div className={styles.line}>
        <div className={styles.nameWrapper}>
          <span className={`${styles.name} ${styles.marginRight}`}>{t('name')}</span>
          <strong className={`${styles.title} ${styles.black}`}>{creativeData.name}</strong>
        </div>
      </div>
      <div className={`${styles.line} ${styles.detail} ${styles.marginVertical}`}>
        <div className={styles.contactWrapper}>
          {!!creativeData.phone &&
            <span className={styles.group}>
              <a href={`tel:${creativeData.phone}`}>
                <Icon className={styles.smallIcon} icon={IconType.PHONE} />
                <span>{creativeData.phone}</span>
              </a>
            </span>
          }
          <span className={styles.group}>
            <span className={styles.separateLeft}>
              <a href={`mailto:${creativeData.email}`}>
                <Icon className={styles.smallIcon} icon={IconType.ENVELOPE} />
                <span>{creativeData.email}</span>
              </a>
            </span>
          </span>
        </div>
      </div>
      <div className={`${styles.line} ${styles.marginTopLarge}`}>
        <strong className={`${styles.total} ${styles.marginRight}`}>{t('total_remuneration')}</strong>
        <strong className={styles.black}>{formatPrice(totalRemunerationValue, currency)}</strong>
      </div>
      <div className={`${styles.line} ${styles.detail}`}>
        <span className={styles.marginRight}>{t('remuneration')}</span>
        <div className={`input-group ${inputStyles.inputGroup}`}>
          <input
            className={`${inputStyles.numberInput} ${styles.marginRight}`}
            type="number"
            name="remuneration"
            id="remuneration"
            value={remunerationValue}
            min="0"
            step="0.01"
            placeholder={t('number')}
            onWheel={e => e.currentTarget.blur()}
            onChange={e => setRemunerationValue(formatPrice(e.target.value))}
          />
          <strong>{formattedCurrency}</strong>
        </div>
      </div>
      <div className={`${styles.line} ${styles.detail}`}>
        <span className={styles.marginRight}>{t('transport_cost')}</span>
        <div className={`input-group ${inputStyles.inputGroup}`}>
          <input
            className={`${inputStyles.numberInput} ${styles.marginRight} ${isCreativeTransportCostEmpty ? 'error-input' : ''}`.trim()}
            type="number"
            name="transport-cost"
            id="transport-cost"
            value={transportCostValue}
            min="0"
            step="0.01"
            placeholder={t('number')}
            onWheel={e => e.currentTarget.blur()}
            onChange={e => setTransportCostValue(formatPrice(e.target.value))}
          />
          <strong>{formattedCurrency}</strong>
          {isCreativeTransportCostEmpty &&
            <span className={`error-message ${inputStyles.errorMessage}`}>{t('field_empty')}</span>
          }
        </div>
      </div>
      <div className={`${styles.line} ${styles.detail} ${styles.marginBottom}`}>
        <span className={styles.marginRight}>{t('bonus')}</span>
        <div className={`input-group ${inputStyles.inputGroup}`}>
          <input
            className={`${inputStyles.numberInput} ${styles.marginRight} ${isCreativeBonusEmpty ? 'error-input' : ''}`.trim()}
            type="number"
            name="bonus"
            id="bonus"
            value={bonusValue}
            min="0"
            step="0.01"
            placeholder={t('number')}
            onWheel={e => e.currentTarget.blur()}
            onChange={e => setBonusValue(formatPrice(e.target.value))}
          />
          <strong>{formattedCurrency}</strong>
          {isCreativeBonusEmpty &&
            <span className={`error-message ${inputStyles.errorMessage}`}>{t('field_empty')}</span>
          }
        </div>
      </div>
      <div className={`${styles.line} ${styles.marginTopLarge}`}>
        <strong>{t('admin_comments_about_creative')}</strong>
      </div>
      <BlockInfo className={styles.blockinfo} color="blue">
        {multilineText(creativeData.adminComments || '')}
      </BlockInfo>
      <div className={`${styles.line} ${styles.marginTopLarge}`}>
        <div className={styles.buttonsWrapper}>
          <Button
            type="secondary"
            className={`${styles.book} ${styles.marginRight}`}
            onClick={() => bookCreative()}
            disabled={adminSelectCreative.isPending || adminSelectCreative.isSuccess}
          >
            {t('validate_and_book')}
          </Button>
          <Button
            type="secondary red"
            className={styles.back}
            onClick={onClickBack}
            disabled={adminSelectCreative.isPending || adminSelectCreative.isSuccess}
          >
            {t('back')}
          </Button>
        </div>
      </div>

      <QueryStatus
        query={adminSelectCreative}
        successMessage={t('success')}
        spaceTopRem={2}
      />

    </div>
  )
}

export default AssignChosenCreativeDetail
