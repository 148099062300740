import { GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { Stack, Typography } from '@mui/material'

import { FC } from 'react'
import { MUICheckbox } from 'components/common/MUICheckBox'
import { MUIRadio } from 'components/common/MUIRadio'

/** @typedef CheckButtonType Available check button type */
type CheckButtonType = 'check' | 'radio'


/**
 * @interface Props CheckboxItem properties
 */
export interface Props {
  /** Type of check button */
  type?: CheckButtonType,
  /** Title of checkbox item */
  title: string,
  /** Description of checkbox item */
  description?: string,
  /** Whether the checkbox is checked */
  checked: boolean,
  /** Function to run when the checkbox is clicked */
  onClick: () => void
}

/**
 * @component
 * Checkbox item for purchase flow.
 * 
 * @example 
 * <CheckboxItem />
 */
export const CheckboxItem: FC<Props> = ({
  type = 'check',
  title,
  description,
  checked,
  onClick
}) => (
  <Stack flexDirection="row" gap=".8rem" alignItems="flex-start" onClick={onClick}>

    {type === 'check' && <MUICheckbox checked={checked} sx={{ padding: '.5rem 0' }} />}

    {type === 'radio' && <MUIRadio checked={checked} sx={{ padding: '.5rem 0' }} />}

    <Stack>
      <Typography variant="text-md" color={GRAY_900}>{title}</Typography>

      {description &&
        <Typography variant="text-sm" color={GRAY_700}>{description}</Typography>
      }
    </Stack>
  </Stack>
)
