import './BlockInfo.sass'

import React, { ReactNode } from 'react'

import { LinkifyWrapper } from '../LinkifyWrapper/LinkifyWrapper.module'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** The additional classes to append */
  className?: string
  /** Color class of the box */
  color?: 'orange' | 'blue' | 'green' | 'red'
  children?: ReactNode
}

/**
 * @component Display aditional information in a styled orange box
 * @example
 * <BlockInfo color="orange" className="someClass">
 *    <h4>Some Heading</h4>
 *    <p>Some text</p>
 * </BlockInfo>
 */
const BlockInfo: React.FC<Props> = ({
  className = '',
  color = 'orange',
  children,
}) => (
  <LinkifyWrapper color={color}>
    <div className={`blockinfo ${color} ${className}`.replace(/\s+/igm, ' ').trim()}>{children}</div>
  </LinkifyWrapper>
)

export default BlockInfo
