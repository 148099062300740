import { useCallback, useState } from 'react'

export function useDateAndTime(orderDateTime?: Date) {

  const [dateTime, setDateTime] = useState(orderDateTime)
  const [date, setDate] = useState(orderDateTime)
  const [time, setTime] = useState(orderDateTime)

  const onDateChange = useCallback((newDate: Date) => {
    if (!newDate) return

    const newDateTime = new Date(dateTime || new Date())
    newDateTime.setDate(newDate.getDate())
    newDateTime.setMonth(newDate.getMonth())
    newDateTime.setFullYear(newDate.getFullYear())

    setDate(newDate)
    setDateTime(newDateTime)
  }, [dateTime])

  const onTimeChange = useCallback((newTime: Date) => {
    if (!newTime) return

    const newDateTime = new Date(dateTime || new Date())
    newDateTime.setHours(newTime.getHours())
    newDateTime.setMinutes(newTime.getMinutes())

    setTime(newTime)
    setDateTime(newDateTime)
  }, [dateTime])

  return {
    date,
    time,
    dateTime,
    onDateChange,
    onTimeChange,
  }
}
