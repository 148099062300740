import { Power2, gsap } from 'gsap'
import React, { FC, ReactNode } from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'

import classnames from 'classnames'
import styles from './ExpandableCard.module.sass'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Cypress data */
  'data-cy'?: string
  /** The additional classes to append */
  className?: string
  /** Content to be shown when expanded */
  expandableContent?: ReactNode
  /** Custom className for expandable content */
  expandableContentClassName?: string
  /** Wheter card is expanded and content is visible */
  isExpanded: boolean
  /** Wheter the card is disabled */
  isDisabled?: boolean
  /** Wheter the card is highlighted */
  isHighlighted?: boolean
  /** Displays pointer cursor on hover over header part of card and allows for onHeaderClick event */
  isHeaderClickable?: boolean
  /** Event triggered specifically when clicked on header */
  onHeaderClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  /** Optional children rendered in the header of the card */
  children?: ReactNode
}

/**
 * @component
 * Displays provided content as a card with a header and an expandable container.
 * Header content is injected via children and content for expandable container as a prop.
 * Expanding/closing is handled manually by property "isExpanded"
 * 
 * @example
 * <ExpandableCard isExpanded={booleanValue} expandableContent={headerNode}>
 *   {headerNode}
 * </ExpandableCard>
 */
export const ExpandableCard: FC<Props> = ({
  'data-cy': dataCy,
  className = '',
  expandableContent,
  expandableContentClassName,
  isExpanded = false,
  isDisabled = false,
  isHighlighted = false,
  isHeaderClickable = false,
  onHeaderClick,
  children,
}) => {

  const timelineEnter = gsap.timeline({ paused: true })
  const timelineExit = gsap.timeline({ paused: true })

  const enterEffect = (node: HTMLElement, appears: boolean) => {
    if (process.env.NODE_ENV === 'test') return
    timelineEnter.totalProgress(1).clear(true).totalProgress(0)
    timelineEnter.fromTo(node, { autoAlpha: 0, height: 0, paddingTop: 0, paddingBottom: 0, ease: Power2.easeIn }, { autoAlpha: 1, height: 'auto', paddingTop: '2rem', paddingBottom: '2rem', ease: Power2.easeIn, duration: 0.3 })
    timelineEnter.play()
  }

  const exitEffect = (node: HTMLElement) => {
    timelineExit.totalProgress(1).clear(true).totalProgress(0)
    timelineExit.to(node, { autoAlpha: 0, height: 0, paddingTop: 0, paddingBottom: 0, ease: Power2.easeIn, duration: 0.3 })
    timelineExit.play()
  }

  return (
    <div className={`${styles.expandableCard} ${className}  ${isDisabled ? styles.disabled : ''} ${isHighlighted ? styles.highlighted : ''}`} data-cy={dataCy || 'expandable-card'}>
      <div className={`${styles.header} ${isHeaderClickable && !isDisabled ? styles.isClickable : ''}`} onClick={isHeaderClickable && !isDisabled ? onHeaderClick : undefined}>
        {children}
      </div>
      <TransitionGroup component={null}>
        {isExpanded && expandableContent &&
          <Transition
            appear={true}
            in={isExpanded}
            onEnter={(node, appears) => enterEffect(node, appears)}
            onExit={(node) => exitEffect(node)}
            timeout={{ enter: 300, exit: 300 }}
          >
            <div className={classnames(styles.content, expandableContentClassName)}>
              {expandableContent}
            </div>
          </Transition>
        }
      </TransitionGroup>
    </div>
  )
}
