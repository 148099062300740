import { useEffect, useMemo } from 'react'

import { NO_HEADER_PATHS } from 'constants/router'
import constate from 'constate'
import { useLocation } from 'react-router-dom'

/**
 * Custom hook for checking if the current pathname should have the header rendered.
 * @returns {boolean} Whether the header should be rendered or not.
 * @example
 * import { useAppHeaderSwitch } from '../AppHeaderSwitch'
 *
 * const { routeHasNoHeader } = useAppHeaderSwitch()
 */
const useAppHeaderSwitchSetup = () => {
  const { pathname } = useLocation()

  /**
    * Checks if the current pathname matches any of the NO_HEADER_PATHS values,
    * skipping dynamic /:id/ parts, and determines whether the header should be rendered.
    */
  const routeHasNoHeader = useMemo(() => NO_HEADER_PATHS.some(path => {
    const pathSegments = pathname.split('/').filter((it) => it !== '')
    const patternSegments = path.split('/').filter((it) => it !== '')

    if (pathSegments.length !== patternSegments.length) return false


    for (let i = 0; i < patternSegments.length; i++) {
      // Skip dynamic path segments
      if (patternSegments[i].startsWith(':')) continue

      // Mismatched path segment
      if (patternSegments[i] !== pathSegments[i]) return false
    }

    // All path segments matched
    return true
  }), [pathname])


  // Update page CSS for pages without header add class
  useEffect(() => {
    const pageClassEl = document.getElementsByClassName('page')[0]

    if (!pageClassEl) return

    if (routeHasNoHeader) {
      pageClassEl.classList.add('isPageWithoutHeader')
    }

  }, [pathname, routeHasNoHeader])

  return {
    routeHasNoHeader
  }
}

export const [AppHeaderSwitchProvider, useAppHeaderSwitch] = constate(useAppHeaderSwitchSetup)
