import { FC, Fragment, ReactNode } from 'react'

import FilterListIcon from '@mui/icons-material/FilterList'
import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { MobileControlPanel } from 'components/common/MobileControlPanel/MobileControlPanel.component'
import styles from './ControlPanel.module.sass'
import useMediaQuery from '@mui/material/useMediaQuery'

/**
 * @interface Props Input properties of ControlPanel component
 */
interface Props {
  /** Content of the control panel */
  children: ReactNode
  /** Title of the control panel in mobile version */
  title: string
}

/**
 * @component Control filters in client dashboard 
 * @example <ControlPanel children={children} />
 * */
export const ControlPanel: FC<Props> = ({ children, title }) => {
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)

  return (
    <Fragment>
      {isMobileView ?
        <MobileControlPanel children={children} title={title} buttonIcon={<FilterListIcon className='icon' fontSize='large' />} />
        :
        <div className={styles.controlPanel}>
          {children}
        </div>}
    </Fragment>
  )
}
