import { useCallback, useMemo, useState } from 'react'

import { AlertColor } from '@mui/material'
import constate from 'constate'
import { List } from 'immutable'
import { uniqueId } from 'lodash'
import { APIRequest } from 'models/API'
import { getAPIErrorMetaString } from 'utils/serialization/getApiErrorMetaString'

const DEFAULT_HIDE_TIMEOUT = 5000

const DEFAULT_MAX_QUEUE = 4

export interface ToastMessage {
  /** Unique identifier of message */
  id: string
  /** Text of message */
  message: string
  /** Style variant of toast (success, error, ...) */
  variant: AlertColor
  /** Time (in ms) after which should be message auto hidden */
  timeout: number
  /** Optional title of the toast */
  title?: string
  /** Optional text displayed in small font under the main message */
  subtext?: string
}

interface SpawnFncOptions {
  /** Title of toast */
  title?: string
  /** Time (in ms) after which should be message auto hidden */
  timeout?: number
  /** Optional text displayed in small font under the main message */
  subtext?: string
}

interface SpawnErrorOptions extends SpawnFncOptions {
  /** API error whose meta info should be displayed in the toast message (unfortunately untyped) */
  error?: APIRequest<unknown>
}

interface ContextProps {
  /** Time (in ms) after which should be message auto hidden */
  defaultAutoHideTimeout?: number
  /** Maximum amount of toast messages displayed at once */
  maxQueueSize?: number
}

export const [SnackbarServiceProvider, useSnackbar] = constate(({ defaultAutoHideTimeout, maxQueueSize = DEFAULT_MAX_QUEUE }: ContextProps) => {

  const autoHideTimeout = useMemo(() => defaultAutoHideTimeout ?? DEFAULT_HIDE_TIMEOUT, [defaultAutoHideTimeout])

  const [messageQueue, setMessageQueue] = useState<List<ToastMessage>>(List([]))

  /**
   * Function that adds toast message to message queue and limits the queue size accordingly to maxQueueSize number in LIFO style
   */
  const addMessageToQueue = useCallback((message: ToastMessage) => {
    setMessageQueue((queue) => queue.push(message).slice(-maxQueueSize))
  }, [maxQueueSize])

  /**
   * Function that removes toast message from message queue by id
   */
  const removeMessageFromQueue = useCallback((messageId: string) => {
    setMessageQueue((queue) => queue.filter(({ id }) => id !== messageId))
  }, [])

  /**
   * Function that composes ToastMessage object with unique id
   * @returns ToastMessage object with unique id
   */
  const getSnackMessage = useCallback((message: string, variant: AlertColor, timeout: number, title?: string, subtext?: string): ToastMessage => {
    return {
      id: uniqueId('snack-message'),
      message,
      variant,
      timeout,
      title,
      subtext,
    }
  }, [])

  /** Creates Info variant toast message and adds it into the message queue */
  const spawnInfoToast = useCallback((message: string, options?: SpawnFncOptions) => {
    const snack = getSnackMessage(
      message,
      'info',
      options?.timeout ?? autoHideTimeout,
      options?.title,
      options?.subtext
    )

    addMessageToQueue(snack)
  }, [addMessageToQueue, autoHideTimeout, getSnackMessage])

  /** Creates Success variant toast message and adds it into the message queue */
  const spawnSuccessToast = useCallback((message: string, options?: SpawnFncOptions) => {
    const snack = getSnackMessage(
      message,
      'success',
      options?.timeout ?? autoHideTimeout,
      options?.title,
      options?.subtext
    )

    addMessageToQueue(snack)
  }, [addMessageToQueue, autoHideTimeout, getSnackMessage])

  /** Creates Warning variant toast message and adds it into the message queue */
  const spawnWarningToast = useCallback((message: string, options?: SpawnFncOptions) => {
    const snack = getSnackMessage(
      message,
      'warning',
      options?.timeout ?? autoHideTimeout,
      options?.title,
      options?.subtext
    )

    addMessageToQueue(snack)
  }, [addMessageToQueue, autoHideTimeout, getSnackMessage])

  /**
   * Creates Error variant toast message and adds it into the message queue
   * Errors are by default not hidden automatically
  */
  const spawnErrorToast = useCallback((message: string, options?: SpawnErrorOptions) => {
    const snack = getSnackMessage(
      message,
      'error',
      options?.timeout ?? 0,
      options?.title,
      options?.error ? getAPIErrorMetaString(options.error) : options?.subtext
    )

    addMessageToQueue(snack)
  }, [addMessageToQueue, getSnackMessage])

  return {
    messageQueue,
    autoHideTimeout,
    addMessageToQueue,
    removeMessageFromQueue,
    spawnInfoToast,
    spawnSuccessToast,
    spawnWarningToast,
    spawnErrorToast,
  }
})
