import { Link, useNavigate } from 'react-router-dom'
import { getCookiePolicyLink, getPrivacyPolicyLink, getTermsLink } from 'utils/localization'

import Button from 'components/common/Button/Button'
import { FC } from 'react'
import Icon from 'components/common/Icon/Icon'
import { IconType } from 'constants/assets'
import { LegalImprint } from '../LegalImprint'
import styles from './TermsAndPrivacy.module.sass'
import { useTranslation } from 'react-i18next'

/**
 * Controller for Terms and privacy page
 * @example <TermsAndPrivacyController />
 */
export const TermsAndPrivacyController: FC = () => {
  const { t, i18n } = useTranslation(['terms_and_privacy'])
  const navigate = useNavigate()

  const externalNavigate = (url: string) => {
    window.open(url, '_blank', 'noopener noreferrer')
  }

  return (
    <div className="page profile blue-bg">
      <div className="page-content">

        <div className="top-row">
          <Link
            to="/"
            onClick={e => { e.preventDefault(); navigate(-1) }}
            className="backbutton button secondary nobackground"
          >
            <Icon icon={IconType.CARET_LEFT} />
          </Link>
          <h1>{t('page_title')}</h1>
        </div>

        <div className="white-content">

          <div className={styles.links}>
            <Button
              type="secondary"
              textAndIcon
              onClick={() => externalNavigate(getTermsLink(i18n.language))}
            >
              <span>{t('terms')}</span>
              <Icon icon={IconType.EXTERNALLINK} />
            </Button>
            <Button
              type="secondary"
              textAndIcon
              onClick={() => externalNavigate(getCookiePolicyLink(i18n.language))}
            >
              <span>{t('cookies')}</span>
              <Icon icon={IconType.EXTERNALLINK} />
            </Button>
            <Button
              type="secondary"
              textAndIcon
              onClick={() => externalNavigate(getPrivacyPolicyLink(i18n.language))}
            >
              <span>{t('privacy')}</span>
              <Icon icon={IconType.EXTERNALLINK} />
            </Button>

          </div>

          <LegalImprint location="germany" />
          <LegalImprint location="switzerland" />

        </div>

      </div>
    </div>
  )
}
