import '../TopBar.sass'

import { Dispatch, SetStateAction } from 'react'

import Button from 'components/common/Button/Button'
import Dropdown from 'components/common/Dropdown/Dropdown'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import { getHelpCenterLink } from 'utils/localization/helpCenter'
import i18n from 'translations/i18n'
import { logAnalyticsEvent } from 'utils/analytics'
import styles from './HelpCenter.module.sass'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @interface Props for HelpCenter component
 */
export interface Props {
  /** Wheather the contact us popup is open or not */
  isContactUsOpen: boolean,
  /** Action to control open contact us popup */
  setIsContactUsOpen: Dispatch<SetStateAction<boolean>>
}

/**
 * @component HelpCenter displayed on Top Bar Menu includes a link to Help Center and Contact Us popup
 * @example <HelpCenter />
 */
export const HelpCenter: React.FC<Props> = ({
  isContactUsOpen,
  setIsContactUsOpen
}) => {
  const { t } = useTranslation('topbar')
  const { clientData } = useUserData()

  return (
    <Dropdown
      innerMenuClassName='menu-wrap help-center-menu'
      attachment="right-attached"
      button={(isOpen, action) => (
        <Button
          type="secondary nobackground noborder"
          className={styles.helpCenterButton}
          textAndIcon={true}
          onClick={(e) => {
            logAnalyticsEvent('Click Help Center', {
              userEmail: clientData?.email,
              organizationId: clientData?.organizationId,
            })
            action(e)
          }}
        >
          <HelpOutlineOutlinedIcon className={styles.icon} />
          {t('help_center')}
        </Button>
      )}
    >
      <div className='main-menu'>
        <div className='section light'>
          <a
            href={getHelpCenterLink(i18n.language)}
            rel="nofollow noopener noreferrer"
            target="_blank"
            onClick={(e) => {
              logAnalyticsEvent('Click Help Center - Help Center', {
                userEmail: clientData?.email,
                organizationId: clientData?.organizationId,
              })
            }}
          >
            {t('help_center')}
            <OpenInNewOutlinedIcon className='icon external-link' />
          </a>
          <Button
            type="secondary nobackground noborder"
            className={styles.contactUsButton}
            onClick={(e) => {
              logAnalyticsEvent('Click Help Center - Contact Us', {
                userEmail: clientData?.email,
                organizationId: clientData?.organizationId,
              })
              // stop propagation onClick from parent to keep the popup open
              e.stopPropagation()
              setIsContactUsOpen(!isContactUsOpen)
            }}>
            {t('contact_us')}
          </Button>
        </div>
      </div>
    </Dropdown>
  )
}
