import { EntityKeys, QueryType } from 'utils/reactQuery'

import { useQuery } from '@tanstack/react-query'
import { ESProduct } from 'constants/product'
import { useAPI } from 'utils/API'

enum Endpoints {
  // Admin
  GET_EDITOR_PRODUCTS = '/editor/products',
}


// QUERIES

export function useGetAdminEditorProducts() {
  const api = useAPI<Endpoints>()

  return useQuery<Array<ESProduct>, Error>({
    queryKey: [EntityKeys.ASSIGNMENT, QueryType.LIST],
    queryFn: () => api.get<Array<ESProduct>>(
      Endpoints.GET_EDITOR_PRODUCTS,
      {},
      false
    )
  })
}

// MUTATIONS
