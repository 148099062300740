import { useCallback, useMemo, useState } from 'react'

import constate from 'constate'

export interface GalleryStagingVisual {
  id: string
  url: string | undefined
}

/** Setup hook for gallery staging visuals selection context */
function useGalleryStagingVisualSelectionSetup() {

  const [selectedVisuals, setSelectedVisuals] = useState<Record<string, GalleryStagingVisual>>({})

  /** Adds visual to selected visuals map */
  const selectVisual = useCallback(
    (visual: GalleryStagingVisual) => setSelectedVisuals({
      ...selectedVisuals,
      [visual.id]: visual
    }),
    [selectedVisuals]
  )

  /** Removes visual from selected visuals map */
  const unselectVisual = useCallback(
    (visualId: string) => {
      const newVisuals = { ...selectedVisuals }
      delete newVisuals[visualId]

      setSelectedVisuals(newVisuals)
    },
    [selectedVisuals]
  )

  /** Count of how many visuals are selected */
  const selectedVisualCount = useMemo(() => Object.keys(selectedVisuals).length, [selectedVisuals])

  return {
    selectedVisuals,
    selectedVisualCount,
    selectVisual,
    setSelectedVisuals,
    unselectVisual,
  }
}

export const [GalleryStagingVisualSelectionProvider, useGalleryStagingVisualSelection] = constate(useGalleryStagingVisualSelectionSetup)
