import { APIRequestErrorType, Endpoint, RoleMimetype } from 'constants/API'

import API from 'utils/API/API'
import { APIRequest } from 'models/API'
import { ActionLoadPurchaseSessionVisual } from '../LoadPurchaseSessionVisual'
import { ActionRequest } from 'models/redux'
import { ActionTypeAPIData } from 'constants/redux'
import { AxiosError } from 'axios'
import { UrlDTO } from 'models/visuals'
import { delay } from 'typed-redux-saga'
import { generalFetch } from 'redux/Helpers'
import { put } from 'redux-saga/effects'

const awaitedErrorCodes = new Set([404, 503])
const delayTime = 2000
const iterationCount = 30

/** Saga which handles downloading of purchase flow session visual */
export function* downloadPurchaseSessionVisualSaga(receivedAction: ActionLoadPurchaseSessionVisual) {
  const url = Endpoint.VISUAL_PURCHASE_SESSION_DOWNLOAD_URL
    .replace('{sessionId}', encodeURIComponent(receivedAction.payload.sessionId.toString()))
    .replace('{filename}', encodeURIComponent(receivedAction.payload.file.name.toString()))
    .replace('{type}', encodeURIComponent(receivedAction.payload.type.toString()))

  const fetchFunction = () => generalFetch(ActionTypeAPIData.LOAD_PURCHASE_SESSION_VISUAL, () => API.post<UrlDTO>(url, {}, {
    headers: {
      Accept: RoleMimetype.CLIENT, // TODO: Fix on BE so we can send proper accept header
    },
  }, {
    endpoint: Endpoint.VISUAL_PURCHASE_SESSION_DOWNLOAD_URL,
    ignoreErrorFactory: error => awaitedErrorCodes.has(error.response?.status || -1)
  }))

  let actionContainingSignedUrl: ActionRequest = yield fetchFunction()
  for (let i = 0; i < iterationCount; i++) {
    if (actionContainingSignedUrl.payload.request.response?.status !== 503) break
    yield delay(delayTime)
    actionContainingSignedUrl = yield fetchFunction()
  }

  const URLResponse: UrlDTO = actionContainingSignedUrl.payload.request.data
  const downloadedAction: ActionLoadPurchaseSessionVisual = {
    ...actionContainingSignedUrl,
    payload: {
      ...receivedAction.payload,
      ...actionContainingSignedUrl.payload,
      signedUrl: URLResponse?.url,
    }
  }

  if (!URLResponse?.url) {
    const error_message = 'signedUrl is undefined, null or empty'
    console.error(error_message)
    downloadedAction.payload.request.error_type = APIRequestErrorType.UNKNOWN_ERROR
    downloadedAction.payload.request.error = error_message
    yield put(downloadedAction)
    return
  }

  for (let i = 0; i < iterationCount; i++) {
    try {
      yield API.get<unknown>(URLResponse.url, undefined, {
        endpoint: 'VISUAL_SIGNED_URL',
        ignoreBaseUrl: true,
        ignoreAuthorizationHeaders: true,
        ignoreErrorFactory: (error: AxiosError) => awaitedErrorCodes.has(error.response?.status || -1)
      })
      yield put(downloadedAction)
      return
    } catch (error) {
      const axiosErr = error as AxiosError
      if (!axiosErr.response || !awaitedErrorCodes.has(axiosErr.response.status)) {
        yield put(downloadedAction)
      }
    }
    yield delay(delayTime)
  }

  downloadedAction.payload.request = APIRequest.factoryError(APIRequestErrorType.NOTFOUND_ERROR, 'Visual not found')
  yield put(downloadedAction)
}
