import { Box, Stack } from '@mui/material'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import { FC, ReactNode } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { MUIButton } from 'components/common/MUIButton'
import { SimpleImage } from 'components/common/SimpleImage'

/** @interface Props for the SelectedStagingItemPreview component. */
interface Props {
  /** Url of preview image */
  imageUrl?: string
  /** When not editable, buttons are not shown (default false) */
  notEditable?: boolean
  /** Main text in the info section (accepts basic text format tags like strong) */
  title: string
  /** Secondary text in the info section (accepts custom component like <Typography />) */
  description: ReactNode
  /** Handler for edit button click */
  onEdit?: () => void
  /** Handler for delete button click */
  onDelete?: () => void
}

/**
 * @component
 * Component for displaying of selected decoration/furniture/addon for staging.
 * 
 * @example
 * <SelectedStagingItemPreview
 *   imageUrl="https://example.com/preview.jpg"
 *   title="Goku's Power Level"
 *   description={<span>It's over 9000!.</span>}
 *   onEdit={() => console.log('Vegeta: "What?! 9000? There's no way that can be right!"')}
 *   onDelete={() => console.log('Frieza: "You fool!"')}
 * />
 */
export const SelectedStagingItemPreview: FC<Props> = ({
  imageUrl = '',
  title,
  description,
  notEditable = false,
  onEdit,
  onDelete,
}) => {

  const { t } = useTranslation(['common'])

  return (
    <Stack direction="row" alignItems="flex-start" justifyContent="space-between" gap={2}>

      {/* Image */}
      <Box flex="0 0 auto">
        <SimpleImage url={imageUrl} sx={{ maxHeight: '8rem', width: '14rem' }} />
      </Box>

      {/* Info */}
      <Stack gap={.5} alignItems="flex-start" flex="1 1 auto">

        <Trans parent="h3" style={{ margin: '0', fontWeight: '500' }}>
          {title}
        </Trans>

        <Trans parent="span" style={{ fontSize: '1.4rem' }}>
          {description}
        </Trans>

      </Stack>

      {/* Buttons */}
      {!notEditable &&
        <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={1}>

          <MUIButton
            type="secondaryBorder"
            startIcon={<EditOutlined fontSize="large" />}
            onClick={onEdit}
          >
            {t('Edit')}
          </MUIButton>

          <MUIButton
            type="orangeSecondaryNoBorder"
            isIconButton
            sx={{ width: '3.8rem' }}
            onClick={onDelete}
          >
            <DeleteOutline fontSize="large" />
          </MUIButton>

        </Stack>
      }

    </Stack>
  )
}
