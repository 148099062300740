import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { APIRequestState } from 'constants/API'
import { ActionPage } from 'components/common/ActionPage/ActionPage.component'
import { ActionTypeAPIData } from 'constants/redux'
import { EditProcessingPopup } from './EditProcessingPopup'
import { EditorOptionApplyTemplate } from './EditorOptions/EditorOptionApplyTemplate/EditorOptionApplyTemplate.module'
import { EditorOptionExporting } from './EditorOptions/EditorOptionExporting'
import { EditorOptionRatio } from './EditorOptions/EditorOptionRatio'
import { EditorOptionWatermark } from './EditorOptions/EditorOptionWatermark'
import { Path } from 'constants/router'
import { RootStore } from 'models/redux'
import { VisualEditingSteps } from 'constants/misc'
import { VisualsEditingDownloadPopup } from './VisualsEditingDownloadPopup'
import { VisualsEditingFooter } from './VisualEditingFooter'
import { VisualsEditingPreviewGallery } from 'components/pages/VisualsEditing/VisualsEditingPreviewGallery.module'
import { VisualsEditingSaveTemplatePopup } from './VisualsEditingSaveTemplatePopup'
import { exportTypeToEditedVisualsType } from 'constants/visualEditing'
import { purgeArchiveEditingVisuals } from 'redux/Individual/VisualsEditing/ArchivedEditingVisuals'
import { purgeLoadEditedVisuals } from 'redux/Individual/VisualsEditing/LoadEditedVisuals'
import styles from 'components/pages/VisualsEditing/VisualsEditing.module.sass'
import { useActionPopup } from 'utils/hooks'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'
import { useVisualsEditing } from 'components/pages/VisualsEditing/VisualsEditing.context'

/**
 * Visual editing controller for selectedVisualsIds from Gallery
 * @example <VisualsEditingController />
 */
export const VisualsEditingController: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { t } = useTranslation(['visuals_editing'])
  const { showConfirm } = useActionPopup()
  const { currentUserWorkspace } = useUserData()

  const {
    assignmentId,
    showExitPopup,
    logEditEvent,
    activeFlowStep,
    setActiveFlowStep,
    visualEditingFilenames,
    exportOption,
    retryDownload,
    editingJobId,
    setSaveTemplateStatusSuccess,
    triggerVisualEditing,
    longPollJob
  } = useVisualsEditing()

  const flowStepperSteps = Object.values(VisualEditingSteps).filter(value => typeof value === 'string') as Array<string>
  const loadedEditedVisualsResponse = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.LOAD_EDITED_VISUAL][editingJobId])
  const isLoadVisualFailed = useMemo(() => longPollJob.isError, [longPollJob.isError])
  const isSubmitFailed = useMemo(() => triggerVisualEditing.isError, [triggerVisualEditing.isError])
  const showRequestError = useMemo(() => (isSubmitFailed || isLoadVisualFailed), [isLoadVisualFailed, isSubmitFailed])
  const errorStatusResponse = useMemo(() => isSubmitFailed ? triggerVisualEditing : longPollJob, [isSubmitFailed, longPollJob, triggerVisualEditing])

  const [editProcessingPopup, setEditProcessingPopup] = useState(false)
  const [downloadPopup, setDownloadPopup] = useState(false)
  const [saveTemplatePopup, setSaveTemplatePopup] = useState(false)

  const onActionPageClose = useCallback(async () => {
    switch (activeFlowStep) {
      case VisualEditingSteps.SETTINGS:
        logEditEvent('Exit Bulk Editing')
        if (
          showExitPopup &&
          !(await showConfirm(t('exit_edit_popup.content'), {
            title: t('exit_edit_popup.title'),
            confirmAcceptText: t('exit_edit_popup.confirm_accept'),
            confirmDenyText: t('exit_edit_popup.confirm_deny')
          }))
        ) return
        break
      case VisualEditingSteps.PREVIEW:
        logEditEvent('Cancel Download Bulk Editing')
        if (
          !(await showConfirm(t('exit_preview_popup.content'), {
            title: t('exit_preview_popup.title'),
            confirmAcceptText: t('exit_preview_popup.confirm_accept'),
            confirmDenyText: t('exit_preview_popup.confirm_deny')
          }))
        ) return
        break
    }

    navigate(Path.GALLERY.replace(':id', assignmentId.toString()))
  }, [activeFlowStep, navigate, assignmentId, logEditEvent, showExitPopup, showConfirm, t])

  // we want to check if any of the files in response exist with OK request state before we move user to Preview
  const isPreviewReady = useMemo(() => Object.values(loadedEditedVisualsResponse || {}).some(
    file => file?.[exportTypeToEditedVisualsType[exportOption]]?.request?.state === APIRequestState.OK
  ), [loadedEditedVisualsResponse, exportOption])

  const closeProcessingPopup = () => {
    if (!currentUserWorkspace) return
    setEditProcessingPopup(false)
    dispatch(purgeLoadEditedVisuals())
  }

  const onEditingExit = useCallback(() => {
    logEditEvent('Exit Visuals Editing Download')
    dispatch(purgeLoadEditedVisuals())
    dispatch(purgeArchiveEditingVisuals(assignmentId))
    navigate(Path.GALLERY.replace(':id', assignmentId.toString()))
  }, [logEditEvent, assignmentId, dispatch, navigate])

  useEffect(() => {
    if (isPreviewReady && triggerVisualEditing.isSuccess) {
      setActiveFlowStep(VisualEditingSteps.PREVIEW)
      setEditProcessingPopup(false)
    }
  }, [dispatch, isPreviewReady, setActiveFlowStep, triggerVisualEditing.isSuccess])

  // Reset VE result when exit the VE page
  useEffect(() => {
    return () => {
      setActiveFlowStep(VisualEditingSteps.SETTINGS)
      dispatch(purgeArchiveEditingVisuals(assignmentId))
      dispatch(purgeLoadEditedVisuals())
    }
  }, [assignmentId, dispatch, setActiveFlowStep])

  return (
    <ActionPage
      id={`visual-editing-assignment-${assignmentId}`}
      title={t('title')}
      subtitle={t('n_visuals_selected', { count: visualEditingFilenames.length })}
      onClose={onActionPageClose}
      flowStepperSteps={flowStepperSteps}
      flowStepperActiveStep={activeFlowStep}
      footerChildren={
        <VisualsEditingFooter
          setEditProcessingPopup={setEditProcessingPopup}
          setSaveTemplatePopup={setSaveTemplatePopup}
          setDownloadPopup={setDownloadPopup}
        />
      }
    >
      <div className={styles.children}>

        {/* VISUALS EDITING SETTINGS STEP */}
        {activeFlowStep === VisualEditingSteps.SETTINGS &&
          <Fragment>

            <VisualsEditingPreviewGallery className={styles.leftContainer} justifyContent='left' alignItems='flex-start' />

            <div className={styles.rightContainer}>
              <div className={styles.header}>
                <div className={styles.title}>{t('editor_options.header')}</div>
              </div>
              <div className={styles.body}>

                <EditorOptionApplyTemplate />

                <EditorOptionWatermark />

                <EditorOptionRatio />

                <EditorOptionExporting />

              </div>
            </div>

            <EditProcessingPopup
              isOpen={editProcessingPopup}
              isError={showRequestError}
              isQueryStatus={true}
              query={errorStatusResponse}
              onClose={closeProcessingPopup}
            />

          </Fragment>}

        {/* VISUALS EDITING PREVIEW STEP */}
        {activeFlowStep === VisualEditingSteps.PREVIEW && <VisualsEditingPreviewGallery className={styles.editedPreview} justifyContent='flex-start' />}

        {/* Visuals Editing Download Popup */}
        <VisualsEditingDownloadPopup
          isOpen={downloadPopup}
          retryDownload={retryDownload}
          onEditingExit={onEditingExit}
          onClickClose={() => setDownloadPopup(false)}
        />

        {/* Visuals Editing Save Template Popup */}
        <VisualsEditingSaveTemplatePopup
          isOpen={saveTemplatePopup}
          onClose={() => setSaveTemplatePopup(false)}
          currentUserWorkspace={currentUserWorkspace}
          onSaved={() => { setSaveTemplatePopup(false); setSaveTemplateStatusSuccess(true) }}
        />
      </div>
    </ActionPage>
  )
}
