import { GRAY_800, GRAY_900, WHITE } from 'constants/styling/theme'
import InputBase, { inputBaseClasses } from '@mui/material/InputBase'
import React, { useEffect, useMemo, useState } from 'react'

import AttachFileIcon from '@mui/icons-material/AttachFile'
import Box from '@mui/material/Box'
import { Color } from 'constants/assets'
import { FileAPIEntry } from 'components/common/FileAPI'
import IconButton from '@mui/material/IconButton'
import ReactLoading from 'react-loading'
import SendRoundedIcon from '@mui/icons-material/SendRounded'
import Stack from '@mui/material/Stack'
import { UploadVisualAPIItem } from 'components/common/UploadVisuals/UploadVisualAPIItem.component'
import { styled } from '@mui/material/styles'
import { useThreads } from '../../context'
import { useTranslation } from 'react-i18next'

/** @interface Props for the ThreadInput component. */
interface Props {
  /** The ID of the assignment linked to the thread. */
  assignmentId: string
  /** The ID of the thread. */
  threadId: string
  /** Whether the send message and file buttons are disabled. */
  isDisabled?: boolean
  /** Whether the message is being sent. */
  isSendingMessage?: boolean
  /** The files attached to the message. */
  attachedFiles?: FileAPIEntry[]
  /** Function to trigger when the upload button is clicked. */
  onUploadClick?: () => void
  /** Function to trigger when a file is deleted. */
  onDeleteFile?: (filesId: string[]) => void
  /** Function to handle files to purge. */
  onPurgeFiles?: () => void
}

/** A styled component based on MUI's InputBase. */
const StyledMUIInput = styled(InputBase)(() => {
  return {
    width: '100%',
    flex: '1 1 6rem',
    [`.${inputBaseClasses.input}`]: {
      minHeight: '2rem',
      border: 'unset !important',
      borderRadius: '.8rem',
      color: `${GRAY_900} !important`,
      backgroundColor: WHITE,
      boxShadow: 'unset !important',
      ':focus': {
        color: `${GRAY_900} !important`
      },
      ':disabled': {
        backgroundColor: WHITE,
      }
    },
  }
})

/**
 * @component
 * ThreadInput allows users to input and send messages or attachments.
 *
 * @example
 * <ThreadInput />
 */
export const ThreadInput: React.FC<Props> = ({
  threadId,
  assignmentId,
  isDisabled,
  attachedFiles,
  isSendingMessage,
  onUploadClick,
  onDeleteFile,
  onPurgeFiles,
}) => {

  const { t } = useTranslation(['threads'])

  const { allowUploadOfDocuments, onSubmitMessage } = useThreads()

  const [message, setMessage] = useState<string>('')

  const disableSendMessage = useMemo(() => isDisabled || isSendingMessage, [isDisabled, isSendingMessage])

  const fileEntries: FileAPIEntry[] = useMemo(() => {
    if (!attachedFiles || attachedFiles.length === 0) return []

    return attachedFiles
  }, [attachedFiles])

  /**
   * Event handler for changes in the message input field.
   *
   * @param event - The change event triggered by the input field.
   */
  const onMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value)
  }

  useEffect(() => {
    if (isSendingMessage) {
      setMessage('')
      onPurgeFiles?.()
    }
  }, [isSendingMessage, onPurgeFiles])

  return (
    <Stack direction="row" gap={0.5} alignItems="flex-end">

      {/** TEXT INPUT */}
      <Box
        flex={1}
        noValidate
        component="form"
        autoComplete="off"
      >
        <StyledMUIInput
          id="sender-message"
          multiline
          maxRows={2}
          value={message}
          disabled={disableSendMessage}
          placeholder={`${t('send_message')}...`}
          onChange={onMessageChange}
        />

        {/** List of uploaded files */}
        <Stack direction="row" flexWrap="wrap" gap={1}>
          {fileEntries.length > 0 && fileEntries.map((fileEntry, index) => (
            <UploadVisualAPIItem
              key={fileEntry.id}
              fileEntry={fileEntry}
              disabledSelection={disableSendMessage}
              onDelete={(id) => onDeleteFile?.([id])}
            />
          ))}
        </Stack>
      </Box>

      {/** ATTACH FILE BUTTON */}
      {allowUploadOfDocuments &&
        <IconButton aria-label="attachment" onClick={onUploadClick} disabled={disableSendMessage}>
          <AttachFileIcon sx={{ color: GRAY_800, fontSize: '1.8rem' }} />
        </IconButton>
      }

      {/** SEND MESSAGE BUTTON */}
      <IconButton
        aria-label="send-message"
        disabled={disableSendMessage || !message}
        onClick={() => {
          const attachedFilenames = fileEntries.map((file) => file.id)
          onSubmitMessage(assignmentId, threadId, message, attachedFilenames)
        }}
      >
        {isSendingMessage
          ? (
            <Stack height="2rem" flexDirection="row" alignItems="center">
              <ReactLoading color={Color.GRAY_TEXT} type="spin" width="1.8rem" height="1.8rem" />
            </Stack>
          )
          : <SendRoundedIcon sx={{ color: GRAY_800, fontSize: '1.8rem' }} />
        }
      </IconButton>

    </Stack>
  )
}
