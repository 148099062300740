import { Box, Stack } from '@mui/material'
import { FC, useCallback, useEffect, useMemo, useRef } from 'react'

import Button from 'components/common/Button/Button'
import { Nullable } from 'models/helpers'
import { QueryStatus } from 'components/common/QueryStatus'
import { ResponsiveDrawer } from 'components/common/ResponsiveDrawer'
import { WorkingHoursDrawerItem } from './WorkingHoursDrawerItem'
import { useTranslation } from 'react-i18next'
import { useWorkingHoursAPI } from '../WorkingHoursAPI.context'
import { useWorkingHoursDrawer } from './WorkingHoursDrawer.context'

/**
 * Uses Responsive drawer to render slide-out form for editing CT working hours
 * 
 * DEPENDENCIES:
 * - WorkingHoursDrawerContext
 * 
 * @example <WorkingHoursDrawer />
 */
export const WorkingHoursDrawer: FC = () => {

  const { t } = useTranslation(['profile', 'actions', 'common'])

  const {
    workingHours,
    getSerializedData,
  } = useWorkingHoursAPI()

  const {
    isDrawerOpen,
    editWorkingHours,
    closeWorkingHoursDrawer
  } = useWorkingHoursDrawer()

  const isFormValid = useMemo(() => Object.values(workingHours).every((day) => !day.error), [workingHours])

  const handleSubmit = useCallback(() => {
    if (!isFormValid) return
    editWorkingHours.mutate(getSerializedData())
  }, [editWorkingHours, getSerializedData, isFormValid])

  // Automatically close the drawer after action success
  const closeTimeoutRef = useRef<Nullable<number>>(null)
  useEffect(() => {
    if (!editWorkingHours.isSuccess) return
    if (!!closeTimeoutRef.current) window.clearTimeout(closeTimeoutRef.current)
    closeTimeoutRef.current = window.setTimeout(() => {
      editWorkingHours.reset()
      closeWorkingHoursDrawer()
      closeTimeoutRef.current = null
    }, 1000)
  }, [editWorkingHours, closeWorkingHoursDrawer])

  return (
    <ResponsiveDrawer
      isOpen={isDrawerOpen}
      onClose={closeWorkingHoursDrawer}
      title={t('work_preferences.working_hours_drawer.title')}
      actionsSlot={
        <>
          <Box flex="0 0 100%">
            <QueryStatus
              query={editWorkingHours}
              spaceBottomRem={2}
              onPurge={() => editWorkingHours.reset()}
            />
          </Box>

          <Button
            type="secondary nobackground noborder"
            onClick={closeWorkingHoursDrawer}
          >
            {t('actions:Close')}
          </Button>

          {!editWorkingHours.isSuccess &&
            <Button
              disabled={!isFormValid || editWorkingHours.isPending}
              onClick={() => handleSubmit()}
            >
              {t('actions:Save')}
            </Button>
          }

        </>
      }
    >

      <Stack spacing={2}>
        {Object.keys(workingHours).map((dayKey) => (

          <WorkingHoursDrawerItem
            key={`dayOfWeek-${dayKey}`}
            dayKey={dayKey}
          />

        ))
        }

      </Stack>

    </ResponsiveDrawer>
  )
}
