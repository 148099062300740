import { BEIGE_300, GRAY_900, HKGroteskFamily } from 'constants/styling/theme'
import { ErrorMessageType, errorMessageType } from 'utils/helpers'
import { FC, useCallback } from 'react'
import { Stack, Typography } from '@mui/material'
import { useGallery, useGalleryVisualType, useGalleryVisuals } from '../../_main/contexts'

import { DownloadImage } from 'models/redux'
import { MUIButton } from 'components/common/MUIButton'
import Modal from 'components/common/Modals/Modal/Modal'
import styles from './GalleryUploadErrorPopup.module.sass'
import { useImageRegex } from 'utils/hooks'
import { useTranslation } from 'react-i18next'

interface Props {
  /** Whether the popup is open or not */
  isOpen: boolean
  /** OnClick action to close the popup */
  onClose: () => void
}

/**
 * Gallery upload error popup.
 * 
 * @example <GalleryUploadErrorPopup />
 */
export const GalleryUploadErrorPopup: FC<Props> = ({
  isOpen,
  onClose
}) => {
  const { t } = useTranslation(['gallery'])

  const { assignmentId } = useGallery()
  const { downloadVisualsWithErrorEntries } = useGalleryVisuals()
  const { thumbnailType } = useGalleryVisualType()
  const imageNameReplacePattern = useImageRegex(assignmentId)

  const imageLabel = useCallback((img: DownloadImage) => {
    const fileName = img.file ? img.file.name.replace(imageNameReplacePattern, '') : ''
    const originalFilename = img.originalFilename

    if (fileName) {
      return fileName + (originalFilename ? ` | ${originalFilename}` : '')
    }

    return originalFilename ?? ''
  }, [imageNameReplacePattern])

  const uploadErrorList = useCallback(() => {
    const list = downloadVisualsWithErrorEntries.map(([key, allTypes]) => {
      const img = allTypes?.[thumbnailType]
      if (!img) return <></>
      const label = imageLabel(img)
      const errorMsg = img.request.error
      const isOtherMsg = errorMessageType(errorMsg) === ErrorMessageType.OTHER

      return (
        <Typography key={label} color={GRAY_900} fontSize="1.4rem" fontFamily={HKGroteskFamily}>
          {label} - {t('gallery_upload_error_popup.error')} {isOtherMsg ? errorMsg : t(`image_processing_error.${errorMsg}`)}
        </Typography>
      )
    })

    return list
  }, [downloadVisualsWithErrorEntries, thumbnailType, imageLabel, t])

  return (
    <Modal
      isOpen={isOpen}
      className={styles.galleryUploadErrorPopup}
      modalContentClassName={styles.popupContent}
      title={t('gallery_upload_error_popup.title')}
    >
      <Stack width="100%" gap="2rem">

        <Stack
          gap=".5rem"
          maxHeight="25rem"
          padding=".8rem"
          borderRadius=".4rem"
          sx={{
            backgroundColor: BEIGE_300,
            overflowY: 'scroll',
            '::-webkit-scrollbar': {
              display: 'none'
            }
          }}
        >
          {uploadErrorList()}
        </Stack>

        <Typography color={GRAY_900} fontSize="1.4rem" fontWeight={600} fontFamily={HKGroteskFamily}>{t('gallery_upload_error_popup.note')}</Typography>

        <Stack width="100%" alignItems="flex-end">
          <MUIButton type="darkPrimary" onClick={onClose}>
            {t('gallery_upload_error_popup.confirm_button')}
          </MUIButton>
        </Stack>

      </Stack>
    </Modal>
  )
}
