/** GCP Job status enum */
export enum JobStatus {
  /** Job has been created and is persisted in the database. */
  CREATED = 'CREATED',
  /** Job has been enqueued into the publish/subscribe queue and will be processed. */
  ENQUEUED = 'ENQUEUED',
  /** Job has successfully finished processing. */
  FINISHED = 'FINISHED',
  /** Job has failed processing. */
  FAILED = 'FAILED',
}