import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { PlatformUser, PreferredPaymentMethodType } from 'models/user'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'
import { ActionRequest } from 'models/redux'
import { createAPIAction } from 'redux/Helpers'

/** Describes action for updating preferred payment method */
export interface ActionUpdatePreferredPaymentMethod extends ActionRequest {
  payload: {
    paymentMethod: PreferredPaymentMethodType
    callUpdateUserMe: boolean
    request: APIRequest<PlatformUser | null>
  }
}

/** Creates action for updating preferred payment method */
export function updatePreferredPaymentMethod(paymentMethod: PreferredPaymentMethodType, callUpdateUserMe = true): ActionUpdatePreferredPaymentMethod {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.UPDATE_PREFERRED_PAYMENT_METHOD], {
    paymentMethod,
    callUpdateUserMe,
    request: new APIRequest(APIRequestState.RUNNING)
  })
}

/** Creates action for purging update preferred payment method state */
export function purgeUpdatePreferredPaymentMethod(): ActionUpdatePreferredPaymentMethod {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.UPDATE_PREFERRED_PAYMENT_METHOD], {
    paymentMethod: undefined,
    request: new APIRequest(APIRequestState.BEFORE_START)
  })
}
