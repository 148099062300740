import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { ActionRequest } from 'models/redux'
import { Organization } from 'models/organization'
import { PageableResponse } from 'models/misc'
import { createAPIAction } from 'redux/Helpers'

/** Describes search organizations action */
export interface ActionSearchOrganizations extends ActionRequest {
  payload: {
    /** Organization's VAT number */
    vatNumber?: string,
    /** Page to fetch */
    page: number,
    /** Size of the page (number of results on page) */
    size: number,
    request: APIRequest<PageableResponse<Organization[]>>,
  },
}

/** Start search organizations action */
export function searchOrganizations(vatNumber?: string, page = 0, size = 10): ActionSearchOrganizations {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.SEARCH_ORGANIZATIONS], {
    vatNumber,
    page,
    size,
  })
}

/** Purge search organizations action */
export function purgeSearchOrganizations(): ActionSearchOrganizations {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.SEARCH_ORGANIZATIONS], {
    request: new APIRequest(),
  })
}
