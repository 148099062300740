import { AssignmentDTOIsAdministratorDTO, AssignmentDTOIsCreativeDTO } from 'utils/typeguards'
import { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useEffect, useRef, useState } from 'react'
import { RootStore, StatusResponse } from 'models/redux'

import { ActionTypeAPIData } from 'constants/redux'
import { Nullable } from 'models/helpers'
import { useGalleryAssignment } from './GalleryAssignment.context'
import { useGalleryConstants } from './GalleryConstants.context'
import { useGalleryProduct } from './GalleryProduct.context'
import { useGalleryVirtualVisit } from './GalleryVirtualVisit.context'
import { useSelector } from 'react-redux'

interface GalleryCommentsInterface {
  adminCommentsForEditor: string
  setAdminCommentsForEditor: Dispatch<SetStateAction<string>>
  adminInternalComments: string
  setAdminInternalComments: Dispatch<SetStateAction<string>>
  creativeInstructionsFromAdmin: string
  setCreativeInstructionsFromAdmin: Dispatch<SetStateAction<string>>
  creativeCommentsForAdmin: string
  setCreativeCommentsForAdmin: Dispatch<SetStateAction<string>>
  adminCommentsForEditorResponse?: Nullable<StatusResponse<{}>>
  adminInternalCommentsResponse?: Nullable<StatusResponse<{}>>
  creativeInstructionsFromAdminResponse?: Nullable<StatusResponse<{}>>
}

const defaultGalleryCommentsValue: GalleryCommentsInterface = {
  adminCommentsForEditor: '',
  setAdminCommentsForEditor: () => { throw new Error('setAdminCommentsForEditor is undefined') },
  adminInternalComments: '',
  setAdminInternalComments: () => { throw new Error('setAdminInternalComments is undefined') },
  creativeInstructionsFromAdmin: '',
  setCreativeInstructionsFromAdmin: () => { throw new Error('setCreativeInstructionsFromAdmin is undefined') },
  creativeCommentsForAdmin: '',
  setCreativeCommentsForAdmin: () => { throw new Error('setCreativeCommentsForAdmin is undefined') },
}

/** Gallery comments context */
export const GalleryCommentsContext = createContext<GalleryCommentsInterface>(defaultGalleryCommentsValue)
/** Gallery comments context hook */
export const useGalleryComments = (): GalleryCommentsInterface => useContext(GalleryCommentsContext)

/** Context provider for gallery comments */
export const GalleryCommentsContextProvider: FC<{
  assignmentId: string
  children?: ReactNode
}> = ({
  assignmentId,
  children,
}) => {
    const {
      AvailableVirtualVisitLinkTypes,
    } = useGalleryConstants()

    const {
      assignmentData,
      assignment
    } = useGalleryAssignment()

    const {
      isFloorPlan,
    } = useGalleryProduct()

    const {
      virtualVisitID,
      generateVirtualVisitShareLink,
    } = useGalleryVirtualVisit()

    const [adminCommentsForEditor, setAdminCommentsForEditor] = useState('')
    const [adminInternalComments, setAdminInternalComments] = useState('')
    const [creativeInstructionsFromAdmin, setCreativeInstructionsFromAdmin] = useState('')
    const [creativeCommentsForAdmin, setCreativeCommentsForAdmin] = useState('')
    const adminCommentsForEditorResponse = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.EDIT_ASSIGNMENT_ADMIN_COMMENTS_FOR_EDITOR]?.[assignmentId])
    const adminInternalCommentsResponse = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.EDIT_ASSIGNMENT_INTERNAL_COMMENTS]?.[assignmentId])
    const creativeInstructionsFromAdminResponse = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.EDIT_ASSIGNMENT_CREATIVE_INSTRUCTIONS_FROM_ADMIN]?.[assignmentId])

    const assignmentAlreadyLoaded = useRef('')

    // Fill in comment / instruction states upon assignment received
    useEffect(() => {
      if (!assignment.isSuccess) return
      if (!assignmentData) return
      if (assignmentAlreadyLoaded.current === assignmentData.id) return
      assignmentAlreadyLoaded.current = assignmentData.id

      if (AssignmentDTOIsCreativeDTO(assignmentData)) {
        setCreativeInstructionsFromAdmin(assignmentData.creativeInstructionsFromAdmin)
        setCreativeCommentsForAdmin(assignmentData.creativeCommentsForAdmin)
      } else if (AssignmentDTOIsAdministratorDTO(assignmentData)) {
        setCreativeInstructionsFromAdmin(assignmentData.creativeInstructionsFromAdmin)
        setCreativeCommentsForAdmin(assignmentData.creativeCommentsForAdmin)
        setAdminCommentsForEditor(assignmentData.adminCommentsForEditor)
        setAdminInternalComments(assignmentData.adminInternalComments)
      }
    }, [assignmentData, assignment])

    // Update virtual visit ID in admin comments
    useEffect(() => {
      if (virtualVisitID && isFloorPlan) {
        // Prefill matterport link into comment 
        setAdminCommentsForEditor(previousAdminCommentsForEditor => {
          if (previousAdminCommentsForEditor) return previousAdminCommentsForEditor
          return generateVirtualVisitShareLink(virtualVisitID, AvailableVirtualVisitLinkTypes.BRANDED)
        })
      }
    }, [AvailableVirtualVisitLinkTypes, generateVirtualVisitShareLink, isFloorPlan, setAdminCommentsForEditor, virtualVisitID])

    return (
      <GalleryCommentsContext.Provider
        value={{
          adminCommentsForEditor,
          setAdminCommentsForEditor,
          adminInternalComments,
          setAdminInternalComments,
          creativeInstructionsFromAdmin,
          setCreativeInstructionsFromAdmin,
          creativeCommentsForAdmin,
          setCreativeCommentsForAdmin,
          adminCommentsForEditorResponse,
          adminInternalCommentsResponse,
          creativeInstructionsFromAdminResponse,
        }}
      >
        {children}
      </GalleryCommentsContext.Provider>
    )
  }
