import { Fragment, useMemo } from 'react'
import { changeLanguage, changeLocale } from 'redux/Individual/User/ChangeLocale'

import Button from 'components/common/Button/Button'
import Dropdown from 'components/common/Dropdown/Dropdown'
import { Language } from 'translations/Language'
import { coerceLanguage } from 'utils/localization'
import i18n from 'translations/i18n'
import { useAuth0 } from 'utils/auth'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

/**
 * @component LanguageDropdown displayed TopBar
 * @example <LanguageDropdown />
 */
export const LanguageDropdown: React.FC = () => {
  const { t } = useTranslation('topbar')
  const dispatch = useDispatch()
  const { isAuthenticated } = useAuth0()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedLanguage = useMemo(() => coerceLanguage(i18n.language), [i18n.language])

  return (
    <Dropdown className="language-dropdown" attachment="right-attached" button={(isOpen, action) => (
      <Button type="secondary noborder" height='thin' className="language-trigger" onClick={action}>
        {selectedLanguage}
      </Button>
    )}>
      <Fragment>
        {Object.values(Language)?.map(lang => {
          const selected = lang.toUpperCase() === selectedLanguage
          return (
            <button
              key={lang}
              className={`language-button ${selected ? 'selected' : ''}`}
              onClick={() => {
                dispatch(changeLanguage(lang))
                if (!isAuthenticated) return
                dispatch(changeLocale(lang))
              }}
            >
              {t(`language:${lang.toUpperCase()}`, lang)}
            </button>
          )
        })}
      </Fragment>
    </Dropdown>
  )
}
