import { ActionChangeLanguage, changeLanguage } from '../ChangeLocale'
import { ActionStringType, ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { all, put, takeEvery } from 'redux-saga/effects'
import i18n, { languageResources } from 'translations/i18n'

import { ActionUserMe } from '../UserMe'
import { Language } from 'translations/Language'
import { actionTypeTupleTest } from 'redux/Helpers'
import { call } from 'typed-redux-saga'
import moment from 'moment'
import momentTZ from 'moment-timezone'

/** Saga which processes changes in language preference */
export function* processLanguageChangeFromUserMeSaga(receivedAction: ActionUserMe) {
  const language = receivedAction.payload.request.data?.language
  if (!language) return

  const refinedLanguage: Language | undefined = Language[language.toUpperCase() as Language]
  if (!refinedLanguage) return

  // Check if the new language differs
  if (i18n.language === refinedLanguage.toLowerCase()) return

  yield put(changeLanguage(refinedLanguage))
}

/** Saga which responds to language change action */
export function* respondToLanguageChangeSaga(receivedAction: ActionChangeLanguage) {
  const { language } = receivedAction.payload
  if (!language) return

  const lowerCaseLanguage = language.toLowerCase()
  if (!Object.keys(languageResources).includes(lowerCaseLanguage)) return

  const storedLanguage = yield* call([localStorage, 'getItem'], 'i18nextLng')
  if (storedLanguage !== lowerCaseLanguage) yield localStorage.setItem('i18nextLng', lowerCaseLanguage)

  if (i18n.language === lowerCaseLanguage) return
  i18n.changeLanguage(lowerCaseLanguage)
  moment.locale(lowerCaseLanguage)
  momentTZ.locale(lowerCaseLanguage)
}

/** Watcher of user me actions dispatching to processor */
export function* processLanguageChangeWatcher() {
  yield all([
    takeEvery((action: ActionUserMe) => actionTypeTupleTest(action, [ActionTypeAPIEvent.RECEIVED, ActionTypeAPIData.USER_ME]), processLanguageChangeFromUserMeSaga),
    takeEvery(ActionStringType.CHANGE_LANGUAGE, respondToLanguageChangeSaga),
  ])
}
