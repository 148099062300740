import { Color, ColorClass, IconType } from 'constants/assets'
import { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import { APIRequestState } from 'constants/API'
import Button from 'components/common/Button/Button'
import Icon from 'components/common/Icon/Icon'
import { Path } from 'constants/router'
import ReactLoading from 'react-loading'
import { SubscriptionPlan } from 'models/user'
import { SubscriptionStepTemplate } from '../../SubscriptionStepTemplate'
import classnames from 'classnames'
import { logAnalyticsEvent } from 'utils/analytics'
import styles from './PlanCreation.module.sass'
import { useSubscriptionFlow } from '../../_main/SubscriptionFlow.context'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * Plan creation step (STEP 1) of subscription flow.   
 * Extends base template.   
 * 
 * @example
 *  <PlanCreation />
 */
export const PlanCreation: FC = () => {

  const navigate = useNavigate()
  const { t } = useTranslation(['subscription_flow'])
  const { baseUserData, organizationData } = useUserData()
  const {
    userWorkspaces,
    getUserWorkspaceRequest,
    workspaceName,
    setWorkspaceName,
  } = useSubscriptionFlow()

  /** Holds boolean value of whether user already has / is part of workspace with the same name */
  const hasWorkspaceDuplicitName = useMemo(() => {
    if (!userWorkspaces || !userWorkspaces.length || !workspaceName) return undefined
    return userWorkspaces.find(workspace => workspace.name === workspaceName)
  }, [userWorkspaces, workspaceName])

  const [isEdit, setIsEdit] = useState<boolean>(false)

  /** Log user entering this page */
  const enterLogged = useRef<boolean>(false)
  useEffect(() => {
    if (!enterLogged.current) {
      logAnalyticsEvent('Enter Subscription Flow', {
        userEmail: baseUserData?.email,
        organizationId: organizationData?.id,
        planType: SubscriptionPlan.PRO_PER_SEAT,
      })

      enterLogged.current = true
    }
  }, [baseUserData?.email, organizationData?.id])

  if (getUserWorkspaceRequest.state === APIRequestState.RUNNING) return (
    <ReactLoading
      className={styles.loading}
      color={Color.GRAY_TEXT}
      type="cylon"
    />
  )

  return (
    <SubscriptionStepTemplate
      title={t('steps.plan_creation.step_title')}
      boxContent={
        <div className={classnames(
          styles.workspaceName
        )}
        >
          <div className={styles.divider} />
          <div className={styles.inputWrap}>
            {isEdit ?
              <input
                name="workspace_name_input"
                id="workspace_name_input"
                className={styles.workspaceInput}
                value={workspaceName}
                placeholder={t('steps.plan_creation.input_placeholder')}
                onChange={e => setWorkspaceName(e.target.value)}
                autoFocus
              /> :
              <>
                <div>{workspaceName}</div>
                <button className={styles.editButton} onClick={() => setIsEdit(true)}>
                  <Icon className={styles.editIcon} colorClass={ColorClass.WHITE} icon={IconType.EDIT_BASIC} />
                </button>
              </>
            }
          </div>
          <div className={styles.divider} />

          {!!hasWorkspaceDuplicitName &&
            <Trans parent="p" t={t} i18nKey="workspace_name_already_exists" className={styles.hasDuplicatedName}>
              &nbsp;
            </Trans>
          }
        </div >
      }
      action={
        <Button
          onClick={() => {
            isEdit && logAnalyticsEvent('Named Subscription', { planName: workspaceName })
            navigate(Path.SUBSCRIPTION_VALIDATION)
          }}
          disabled={!workspaceName || !!hasWorkspaceDuplicitName}>
          {t('next')}
        </Button>
      }
      alignAction="right"
    >
      <Fragment>
        <p>{t('steps.plan_creation.free_trial')}</p>

        <Trans parent="p" t={t} i18nKey="terms_and_conditions_link">
          <Link to={Path.TERMS_AND_PRIVACY} target="_blank" rel="nofollow noopener noreferrer">
            &nbsp;
          </Link>
        </Trans>
      </Fragment>
    </SubscriptionStepTemplate>
  )
}
