import { AnalyticsEvent } from 'utils/analytics'
import { FC, useMemo } from 'react'
import { StagingItemSelectionPopup, StagingItemSelectionTab } from '../StagingItemSelectionPopup'

import { ImmutableSet } from 'models/helpers'
import { StagingDecorationDTO } from 'models/virtualFurniture'
import { ToggleFilterValue } from 'components/common/ToggleFilter'
import { uniqBy } from 'lodash'
import { useRoomAPI } from 'components/contexts/RoomAPI.context'
import { useStagingDecorations } from 'components/contexts/StagingDecorations.context'
import { useStyleSelection } from '../main/contexts'
import { useTranslation } from 'react-i18next'

/** 
 * @component
 * Displays popup with all available decorations for selection.
 * 
 * @example
 * <DecorationsItemSelectionPopup isOpen={true} />
 */
export const DecorationsSelectionPopup: FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const { t } = useTranslation(['style_selection_order_step'])

  const { updateRoom } = useRoomAPI()

  const { stagingDecorationsByRoom } = useStagingDecorations()

  const {
    activeRoom,
    setRoomItemPopupType,
    stagingFlowAnalytics
  } = useStyleSelection()

  const decorationValuesForToggle: ToggleFilterValue<string>[] = useMemo(() => {
    if (activeRoom.roomTypes.isEmpty()) return []

    const currentDecorations: StagingDecorationDTO[] = []

    for (let roomType of activeRoom.roomTypes.values()) {
      currentDecorations.push(...(stagingDecorationsByRoom[roomType] || []))
    }

    const toggleValues: ToggleFilterValue<string>[] = uniqBy(currentDecorations, 'item').map((decoration) => ({
      value: decoration.item,
      displayName: t(`staging_decorations:${decoration.item}`)
    }))

    return toggleValues
  }, [activeRoom, stagingDecorationsByRoom, t])

  return (
    <StagingItemSelectionPopup
      title={t('room_items.DECORATION')}
      isOpen={isOpen}
      availableButtonItems={{ [StagingItemSelectionTab.ALL]: decorationValuesForToggle }}
      onClose={() => {
        stagingFlowAnalytics(AnalyticsEvent.DECORATION_FLOW_CLOSED, {})
        setRoomItemPopupType(null)
      }}
      selectedItemsMulti={{ [StagingItemSelectionTab.ALL]: new Set(activeRoom.decorations) }}
      onSelectMulti={(selectedStyles) => {
        stagingFlowAnalytics(AnalyticsEvent.DECORATION_TEMPLATES_SAVED, { TemplateIDs: Array.from(selectedStyles[StagingItemSelectionTab.ALL]) })
        updateRoom(activeRoom.key, { decorations: ImmutableSet(selectedStyles[StagingItemSelectionTab.ALL]) })
        setRoomItemPopupType(null)
      }}
      onSelectAnalyticEvent={(style) => stagingFlowAnalytics(AnalyticsEvent.DECORATION_TEMPLATES_SELECTED, { TemplateIDs: style })}
    />
  )
}
