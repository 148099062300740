import { supportedDocumentFileTypes, supportedImageFileTypes } from 'constants/misc'

import { CatalogueProduct } from 'dataQueries/purchase.query'
import { DocumentUploadType } from 'constants/documents'
import { FC } from 'react'
import { FileAPIUpload } from 'components/common/FileAPIUpload'
import { PurchaseFlowEndpoint } from 'constants/API'
import { UseFileAPIReturn } from 'components/common/FileAPI'
import { usePurchaseFlowConfig } from '../../_main/contexts/PurchaseFlowConfig.context'
import { useTargetOrderUser } from '../../_main/contexts'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props for the DocumentProductUpload component.
 */
interface Props {
  /** Product object */
  product: CatalogueProduct
  /** Instance of file api consumer */
  fileAPIInstance: UseFileAPIReturn
  /** Whether to disable file selection */
  selectionDisabled?: boolean
  /** File count limit (default = infinite) */
  maxNumberOfFiles?: number
}

/**
 * @component Component which displays editing product upload
 * @example
 * <DocumentProductUpload />
 */
export const DocumentProductUpload: FC<Props> = ({
  product,
  fileAPIInstance,
  selectionDisabled,
  maxNumberOfFiles = Number.POSITIVE_INFINITY,
}) => {
  const { t } = useTranslation(['upload_files'])
  const { sessionId } = usePurchaseFlowConfig()
  const { targetUser } = useTargetOrderUser()

  if (!sessionId) throw new Error('Missing sessionId')

  return (
    <FileAPIUpload
      title={t('upload_file_title')}
      files={fileAPIInstance.allFilesArray}
      acceptedFileTypes={[...supportedImageFileTypes, ...supportedDocumentFileTypes]}
      disabled={selectionDisabled}
      maxNumberOfFiles={maxNumberOfFiles}
      onDelete={(fileId) => fileAPIInstance.deleteFiles([fileId])}
      uploadHandler={(acceptedFiles) => fileAPIInstance.uploadFiles(
        acceptedFiles,
        {
          tag: product.id.toString(),
          // Could be abstracted to reusable factory when reuse opportunity comes
          uploadUrlResolverFnc: (fileEntry, api) => api.post(
            PurchaseFlowEndpoint.GET_DOCUMENT_UPLOAD_URL,
            { sessionId: sessionId.toString() },
            {
              email: targetUser.email,
              productId: product.id.toString(),
              documentMetadata: {
                filename: fileEntry.originalFilename,
                contentType: fileEntry.fileObject.type,
                type: DocumentUploadType.INPUT,
              }
            },
            true
          )
        }
      )}
    />
  )
}
