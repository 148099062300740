import { APIRequestErrorType } from 'constants/API'
import React from 'react'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** The additional classes to append */
  className?: string,
  /** The api error type */
  error_type: APIRequestErrorType | 'error',
}

/**
 * @component Display aditional information in a styled orange box
 * @example
 * <ErrorMessage className="someClass" error_type={APIRequestErrorType.UNAUTHORIZED_ERROR}/>>
 */
const ErrorMessage: React.FC<Props> = ({
  className = '',
  error_type,
}) => {
  const { t } = useTranslation('errors')
  let message = ''

  switch (error_type) {
    case APIRequestErrorType.NONE:
      message = t('none')
      break
    case APIRequestErrorType.UNKNOWN_ERROR:
      message = t('unknown')
      break
    case APIRequestErrorType.CLIENT_ERROR:
      message = t('client')
      break
    case APIRequestErrorType.SERVER_ERROR:
      message = t('server')
      break
    case APIRequestErrorType.TIMEOUT_ERROR:
      message = t('timeout')
      break
    case APIRequestErrorType.CANCEL_ERROR:
      message = t('cancel')
      break
    case APIRequestErrorType.CONNECTION_ERROR:
      message = t('connection')
      break
    case APIRequestErrorType.NETWORK_ERROR:
      message = t('network')
      break
    case APIRequestErrorType.UNAUTHORIZED_ERROR:
      message = t('unauthorized')
      break
    case APIRequestErrorType.FORBIDDEN_ERROR:
      message = t('forbidden')
      break
    case APIRequestErrorType.PAYMENT_REQUIRED_ERROR:
      message = t('payment required')
      break
    case APIRequestErrorType.NOTFOUND_ERROR:
      message = t('notfound')
      break
    case 'error':
    default:
      message = t('error')
  }

  return <span className={`${className}`}>{message}</span>
}

export default ErrorMessage
