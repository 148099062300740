import { Box, Stack, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import { GallerySection } from 'components/common/Gallery/GallerySection'
import { useGalleryVisuals } from '../../contexts'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props
 */
interface Props {
  /** JSX elements what we render in section as purchased */
  favouritesPurchasedVisuals: JSX.Element
  /** JSX elements what we render in section as unpurchased */
  favouritesUnPurchasedVisuals: JSX.Element
}

/**
 * Client gallery photo section for Favourites filter.
 * 
 * @example <ClientGalleryPhotoSectionFilteredFavourites 
 * favouritesPurchasedVisuals={favouritesPurchasedVisuals}
 * favouritesUnPurchasedVisuals={favouritesUnPurchasedVisuals}
 * />
 */
export const ClientGalleryPhotoSectionFilteredFavourites: FC<Props> = ({
  favouritesPurchasedVisuals,
  favouritesUnPurchasedVisuals
}) => {
  const { t } = useTranslation(['gallery'])

  const { unPurchasedFavoritedVisualsLength, favoritedVisuals } = useGalleryVisuals()

  const [unpurchasedFavouritedLength, setUnpurchasedFavourited] = useState(unPurchasedFavoritedVisualsLength)

  useEffect(() => {
    setUnpurchasedFavourited(unPurchasedFavoritedVisualsLength)
  }, [favoritedVisuals, unPurchasedFavoritedVisualsLength])

  const hasFavouritesVisuals = favoritedVisuals.size

  return (
    <>
      {hasFavouritesVisuals ?
        <Stack>

          <GallerySection>{favouritesPurchasedVisuals}</GallerySection>

          {!!unpurchasedFavouritedLength && <GallerySection
            title={t('visuals_unpurchased_title')}
            subTitle={t('visuals_unpurchased_subtitle')}
          >
            {favouritesUnPurchasedVisuals}
          </GallerySection>}

        </Stack>
        :
        <Box marginTop="4rem">
          <Typography variant="text-md">{t('gallery:filters.no_favorites')}</Typography>
        </Box>
      }
    </>
  )
}
