import React, { ReactNode } from 'react'

import Box from '@mui/material/Box'
import { GRAY_900 } from 'constants/styling/theme'
import Stack from '@mui/material/Stack'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Title of the drawer section */
  title?: string
  children: ReactNode
}

/**
 * @component Drawer content section component
 * @example 
 * <DrawerContentSection title="section title">
 *  <Box>blabla</Box>
 * </DrawerContentSection>
 */
export const DrawerContentSection: React.FC<Props> = ({
  title,
  children
}) => (
  <Stack
    paddingBottom="2.4rem"
  >

    {!!title &&
      <Box
        fontSize="1.6rem"
        fontWeight={600}
        color={GRAY_900}
        marginBottom="1.6rem"
      >
        {title}
      </Box>
    }

    {children}

  </Stack>
)
