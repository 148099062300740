import { PlatformUserBase } from 'models/user'

// Not 100% defined, just for purpose of ts-check
interface BeckaService {
  settings: {
    language: string
  }
  init: (token: string, name: string, url: string, options: object) => void
  isLocalStorageAvailable: boolean
  userVerificationAPI: {
    setIdentityToken: (params: { identityTokenType: string, identityToken: string }) => unknown
    clearSession: () => void
  }
  prechatAPI: {
    /**
     * All prechat fields can be set as public using the function setVisiblePrechatFields, 
     * for testing purposes, has to be removed in production.
     * DOCS: https://help.salesforce.com/s/articleView?id=service.miaw_pre_chat_api_2.htm&type=5
     */
    setVisiblePrechatFields: (fields: {
      [key: string]: {
        value: string
        isEditableByEndUser: boolean
      }
    }) => void
    setHiddenPrechatFields: (fields: { [key: string]: string }) => void
  }
}

declare global {
  interface Window {
    embeddedservice_bootstrap: BeckaService
  }
}

const BECKA_ID = process.env.REACT_APP_BECKA_ID
const BECKA_NAME = process.env.REACT_APP_BECKA_NAME
const BECKA_URL = process.env.REACT_APP_BECKA_URL
const BECKA_SCRT_URL = process.env.REACT_APP_BECKA_SCRT_URL

export function initBecka(token: string, userData: PlatformUserBase, isoLanguage: string = 'en_US') {
  if (!BECKA_ID) throw new Error('BECKA_ID is undefined')
  if (!BECKA_NAME) throw new Error('BECKA_NAME is undefined')
  if (!BECKA_URL) throw new Error('BECKA_URL is undefined')
  if (!BECKA_SCRT_URL) throw new Error('BECKA_SCRT_URL is undefined')

  try {
    // Set the language before initializing
    window.embeddedservice_bootstrap.settings.language = isoLanguage // For example, 'en' or 'en-US'

    // Initialize the embedded messaging
    window.embeddedservice_bootstrap.init(
      BECKA_ID,
      BECKA_NAME,
      BECKA_URL,
      {
        scrt2URL: BECKA_SCRT_URL,
      }
    )

    // Set up the event listener for when the embedded messaging is ready
    window.addEventListener('onEmbeddedMessagingReady', () => {

      // Set the identity token for user verification
      window.embeddedservice_bootstrap.userVerificationAPI.setIdentityToken({
        identityTokenType: 'JWT',
        identityToken: token,
      })

      // Set hidden pre-chat fields
      window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
        // Use the pre-chat API names as keys
        'PlatformPageURL': window.location.href,
        'PlatformUserID': userData.publicId?.toString() ?? '',
        'PlatformUserEmail': userData.email ?? '',
      })
    })

    return true
  } catch (err) {
    console.error('Error loading Becka: ', err)
    return false
  }
}
