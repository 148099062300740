import { FC, ReactNode } from 'react'

import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import classNames from 'classnames'
import styles from './PageContent.module.sass'
import useMediaQuery from '@mui/material/useMediaQuery'

interface Props {
  /** Custom component to display in the page content */
  children: ReactNode
}

/**
 * Page actions component.
 * @example <PageActions />
 */
export const PageContent: FC<Props> = ({ children }) => {
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)

  return (
    <div className={classNames(styles.pageContent, { [styles.mobile]: isMobileView })}>
      <div className={styles.container}>
        {children}
      </div>
    </div>
  )
}
