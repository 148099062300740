import { FC, ReactNode } from 'react'

import { GalleryStagingFlowContextProvider } from './GalleryStagingFlow.context'
import { GalleryStagingRoomAPIProvider } from './GalleryStagingRoomAPIProvider'
import { GalleryStagingVisualSelectionProvider } from './GalleryStagingVisualSelection.context'
import { RoomInventoryContextProvider } from 'components/contexts/RoomInventory.context'
import { StagingDecorationsProvider } from 'components/contexts/StagingDecorations.context'
import { StyleSelectionContextProvider } from 'components/common/StagingSelection'
import { VirtualFurnitureDataContextProvider } from 'components/contexts/VirtualFurnitureData.context'
import { VirtualFurnitureStylesFilterContextProvider } from 'components/contexts/VirtualFurnitureStylesFilter.context'
import { VirtualFurnitureUpgradesContextProvider } from 'components/contexts/VirtualFurnitureUpgradesContext'

/**
 * Stack of providers for gallery staging
 * 
 * @example
 * <GalleryStagingStackProvider>
 *   <Consumer />
 * </GalleryStagingStackProvider>
 */
export const GalleryStagingStackProvider: FC<{
  children?: ReactNode
}> = ({
  children
}) => {

    return (
      <GalleryStagingVisualSelectionProvider>
        <RoomInventoryContextProvider>
          <GalleryStagingRoomAPIProvider>
            <VirtualFurnitureDataContextProvider>
              <VirtualFurnitureUpgradesContextProvider>
                <StyleSelectionContextProvider>
                  <VirtualFurnitureStylesFilterContextProvider>
                    <StagingDecorationsProvider>
                      <GalleryStagingFlowContextProvider>
                        {children}
                      </GalleryStagingFlowContextProvider>
                    </StagingDecorationsProvider>
                  </VirtualFurnitureStylesFilterContextProvider>
                </StyleSelectionContextProvider>
              </VirtualFurnitureUpgradesContextProvider>
            </VirtualFurnitureDataContextProvider>
          </GalleryStagingRoomAPIProvider>
        </RoomInventoryContextProvider>
      </GalleryStagingVisualSelectionProvider>
    )
  }
