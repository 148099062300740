import { FC, memo } from 'react'
import { GRAY_700, GRAY_900 } from 'constants/styling/theme'
import Typography, { TypographyProps } from '@mui/material/Typography'

import Stack from '@mui/material/Stack'

type ProductItemType = 'primary' | 'secondary'

/** @interface Props for the ProductItemSummary component. */
interface Props {
  /** The name of the product. */
  name: string
  /** The original price of the product. */
  price: string
  /** The discounted price of the product. */
  priceAfterDiscount?: string
  /** The type of the product item (primary or secondary). */
  type?: ProductItemType
  /** The quantity of the product. */
  quantity?: number
}

interface ProductPriceTextProps extends TypographyProps {
  /** Whether the product has discount (if true, applies line-through style) */
  hasDiscount?: boolean
}

/** ProductPriceText represents a memoized Typography component for displaying the price of a product item. */
const ProductPriceText = memo<ProductPriceTextProps>(({
  hasDiscount,
  ...defaultProps
}) => (
  <Typography
    {...defaultProps}
    variant='text-sm'
    fontWeight={600}
    textAlign="end"
    color={hasDiscount ? GRAY_700 : GRAY_900}
    sx={{ textDecoration: hasDiscount ? 'line-through' : 'none' }}
  />
))

interface ProductNameTextProps extends TypographyProps {
  /** Whether the product is type secondary (if true, applies list items styles) */
  isSecondaryProduct?: boolean
}

/** ProductNameText represents a memoized Typography component for displaying the name of a product item. */
const ProductNameText = memo<ProductNameTextProps>(({
  isSecondaryProduct,
  ...defaultProps
}) => (
  <Typography
    {...defaultProps}
    fontWeight={600}
    marginRight={0.5}
    variant={isSecondaryProduct ? 'text-sm' : 'text-md'}
    color={isSecondaryProduct ? GRAY_700 : GRAY_900}
    marginLeft={isSecondaryProduct ? 3 : 0}
    display={isSecondaryProduct ? 'list-item' : 'initial'}
    sx={{ listStyle: 'disc' }}
  />
))

/**
 * @component
 * ProductItemSummary displays a summary of a product item.
 *
 * @example
 * <ProductItemSummary
 *   name="Dunder Mifflin Notepad"
 *   price="$10"
 *   priceAfterDiscount="$8"
 *   type="primary"
 *   quantity={3}
 * />
 */
export const ProductItemSummary: FC<Props> = ({ name, price, priceAfterDiscount, type = 'primary', quantity }) => {
  return (
    <Stack direction="row" justifyContent="space-between" marginBottom={0.5} alignItems="baseline">

      <ProductNameText isSecondaryProduct={type === 'secondary'}>
        {/** Product quantity */}
        {quantity && quantity > 1 && `${quantity} x `}

        {/** Product name */}
        {name}
      </ProductNameText>

      <Stack direction="column">
        {/** Product price discounted */}
        {priceAfterDiscount &&
          <ProductPriceText>
            {priceAfterDiscount}
          </ProductPriceText>
        }

        {/** Product price without discount */}
        <ProductPriceText hasDiscount={!!priceAfterDiscount}>
          {price}
        </ProductPriceText>
      </Stack>

    </Stack>
  )
}
