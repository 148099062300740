import { FC, ReactNode } from 'react'
import { UpsellPaymentContextProvider, UpsellPurchaseConfigurationContextProvider } from './contexts'

import { SavedStripePaymentMethodsContextProvider } from 'components/contexts/SavedStripePaymentMethodsContext'
import { UpsellPaymentController } from './UpsellPayment.controller'
import { UpsellPurchaseConfiguration } from './upsellPurchaseConfiguration.interface'

/**
 * @component Payment modal for upsell in gallery.
 * @example
 * <UpsellPayment
 *   assignmentId={44688}
 *   isOpen={isModalOpen}
 *   productId={4565}
 *   total={FeeDTO}
 *   productQuantity={4}
 *   beforeConfirmAction={() => dispatch(selectVisuals())}
 *   beforeConfirmActionState={APIRequestState.RUNNING}
 *   onClose={() => setIsModalOpen(false)}
 *   afterClose={() => dispatch(purgeSelectVisuals())}
 * >
 *  After paying, your visuals will be available for download.
 * </UpsellPayment>
 */
export const UpsellPayment: FC<
  {
    children?: ReactNode
  }
  &
  UpsellPurchaseConfiguration
> = ({ children, ...configuration }) => {
  return (
    <UpsellPurchaseConfigurationContextProvider {...configuration}>
      <SavedStripePaymentMethodsContextProvider>
        <UpsellPaymentContextProvider>
          <UpsellPaymentController>
            {children}
          </UpsellPaymentController>
        </UpsellPaymentContextProvider>
      </SavedStripePaymentMethodsContextProvider>
    </UpsellPurchaseConfigurationContextProvider>
  )
}
