import { VirtualFurnitureRoomType, VirtualFurnitureStyleCategory } from 'constants/virtualFurniture'

import { VirtualFurnitureStyleDTO } from 'models/virtualFurniture'
import constate from 'constate'
import { useMemo } from 'react'
import { useStyleSelectionContext } from 'components/common/StagingSelection'
import { useVirtualFurnitureData } from 'components/contexts/VirtualFurnitureData.context'

function useVirtualFurnitureStylesFilterSetup() {

  const { virtualFurnitureStyles } = useVirtualFurnitureData()
  const { activeRoom } = useStyleSelectionContext()

  /** Progressively filters styles and available options for filters based on present data */
  const { availableCategories, availableRoomTypes, filteredStyles } = useMemo(() => {
    const availableRoomTypes: Set<VirtualFurnitureRoomType> = new Set([])
    const availableCategories: Set<VirtualFurnitureStyleCategory> = new Set([])
    const filteredStyles: VirtualFurnitureStyleDTO[] = []

    if (activeRoom) {
      const { propertyType, roomTypes, styleCategory } = activeRoom

      for (const style of virtualFurnitureStyles) {

        if (!!propertyType && propertyType !== style.propertyType) continue
        availableRoomTypes.add(style.roomType)

        if (!roomTypes.has(style.roomType)) continue
        availableCategories.add(style.style)

        if (!!styleCategory && styleCategory !== style.style) continue

        filteredStyles.push(style)
      }
    }

    return {
      availableRoomTypes: Array.from(availableRoomTypes),
      availableCategories: Array.from(availableCategories),
      filteredStyles
    }
  }, [activeRoom, virtualFurnitureStyles])

  return {
    filteredVirtualFurnitureStyles: filteredStyles,
    availableRoomTypes,
    availableCategories,
  }
}

export const [VirtualFurnitureStylesFilterContextProvider, useVirtualFurnitureStylesFilter] = constate(useVirtualFurnitureStylesFilterSetup)
