import React, { useEffect, useMemo } from 'react'

import { ResponsiveDrawer } from 'components/common/ResponsiveDrawer'
import { Typography } from '@mui/material'
import { GRAY_700 } from 'constants/styling/theme'
import { useWorkspacesProfileContext } from '../_main/WorkspacesProfile.context'
import { MemberSettingDrawerContent } from '../MemberSettingDrawerContent'
import { PendingInvitationDrawerContent } from '../PendingInvitationDrawerContent'

export enum MemberDrawerType {
  SETTING,
  RESEND_INVITATION,
  INVITE_MEMBER
}

export interface Props {
  /** Whether the member setting drawer is open or not */
  isOpen: boolean,
  /** OnClick action to close the drawer */
  onClose: () => void
}

/**
 * @component Workspace profile setting drawer
 * @example <WorkspaceProfileSettingDrawer isOpen={true} onClose={onClose} />
 */
export const WorkspaceProfileSettingDrawer: React.FC<Props> = ({
  isOpen,
  onClose
}) => {
  const {
    memberSetting,
    pendingInvitationSetting,
    memberDrawerType,
    resetDrawer
  } = useWorkspacesProfileContext()

  const drawerHeight = useMemo(() => {
    switch (memberDrawerType) {
      case MemberDrawerType.SETTING:
        return '52rem'
      case MemberDrawerType.RESEND_INVITATION:
        return '22rem'
      case MemberDrawerType.INVITE_MEMBER:
        return '36rem'
    }
  }, [memberDrawerType])

  const drawerTitle = useMemo(() => {
    switch (memberDrawerType) {
      case MemberDrawerType.SETTING:
        return memberSetting?.name
      case MemberDrawerType.RESEND_INVITATION:
        return pendingInvitationSetting?.email
      case MemberDrawerType.INVITE_MEMBER:
        // TODO: will be updated in adding member popup task
        return 'Invite'
    }
  }, [memberSetting?.name, pendingInvitationSetting?.email, memberDrawerType])

  const subTitle = useMemo(() => {
    if (memberDrawerType !== MemberDrawerType.SETTING) return <></>
    return <Typography variant="text-xs" color={GRAY_700}>{memberSetting?.email}</Typography>
  }, [memberSetting?.email, memberDrawerType])

  useEffect(() => {
    return () => {
      resetDrawer()
    }
  }, [resetDrawer])

  return (
    <ResponsiveDrawer
      isOpen={isOpen}
      onClose={onClose}
      height={drawerHeight}
      title={drawerTitle ?? ''}
      subTitleSlot={subTitle}
    >
      {memberDrawerType === MemberDrawerType.SETTING && <MemberSettingDrawerContent />}

      {memberDrawerType === MemberDrawerType.RESEND_INVITATION && <PendingInvitationDrawerContent />}


    </ResponsiveDrawer>
  )
}
