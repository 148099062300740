import { ReactComponent as icon_arrow } from 'assets/img/icons/arrow.svg'
import { ReactComponent as icon_burgermenu } from 'assets/img/icons/burgermenu.svg'
import { ReactComponent as icon_camera } from 'assets/img/icons/camera.svg'
import { ReactComponent as icon_car } from 'assets/img/icons/car.svg'
import { ReactComponent as icon_card } from 'assets/img/icons/card.svg'
import { ReactComponent as icon_caret_down } from 'assets/img/icons/caret_down.svg'
import { ReactComponent as icon_caret_left } from 'assets/img/icons/caret_left.svg'
import { ReactComponent as icon_caret_right } from 'assets/img/icons/caret_right.svg'
import { ReactComponent as icon_caret_up } from 'assets/img/icons/caret_up.svg'
import { ReactComponent as icon_check } from 'assets/img/icons/check.svg'
import { ReactComponent as icon_clock } from 'assets/img/icons/clock.svg'
import { ReactComponent as icon_computer } from 'assets/img/icons/computer.svg'
import { ReactComponent as icon_cross } from 'assets/img/icons/cross.svg'
import { ReactComponent as icon_danger } from 'assets/img/icons/danger.svg'
import { ReactComponent as icon_date } from 'assets/img/icons/date.svg'
import { ReactComponent as icon_edit } from 'assets/img/icons/edit.svg'
import { ReactComponent as icon_edit_basic } from 'assets/img/icons/edit_basic.svg'
import { ReactComponent as icon_envelope } from 'assets/img/icons/envelope.svg'
import { ReactComponent as icon_exit } from 'assets/img/icons/exit.svg'
import { ReactComponent as icon_externallink } from 'assets/img/icons/externallink.svg'
import { ReactComponent as icon_eye } from 'assets/img/icons/eye.svg'
import { ReactComponent as icon_file } from 'assets/img/icons/file.svg'
import { ReactComponent as icon_handshake } from 'assets/img/icons/handshake.svg'
import { ReactComponent as icon_home } from 'assets/img/icons/home.svg'
import { ReactComponent as icon_info } from 'assets/img/icons/info.svg'
import { ReactComponent as icon_language } from 'assets/img/icons/language.svg'
import { ReactComponent as icon_lightbulb } from 'assets/img/icons/lightbulb.svg'
import { ReactComponent as icon_link } from 'assets/img/icons/link.svg'
import { ReactComponent as icon_list } from 'assets/img/icons/list.svg'
import { ReactComponent as icon_loading } from 'assets/img/icons/loading.svg'
import { ReactComponent as icon_lock } from 'assets/img/icons/lock.svg'
import { ReactComponent as icon_map } from 'assets/img/icons/map.svg'
import { ReactComponent as icon_marker } from 'assets/img/icons/marker.svg'
import { ReactComponent as icon_pack } from 'assets/img/icons/pack.svg'
import { ReactComponent as icon_paint_brush } from 'assets/img/icons/paint_brush.svg'
import { ReactComponent as icon_phone } from 'assets/img/icons/phone.svg'
import { ReactComponent as icon_photo } from 'assets/img/icons/photo.svg'
import { ReactComponent as icon_plus } from 'assets/img/icons/plus.svg'
import { ReactComponent as icon_profile } from 'assets/img/icons/profile.svg'
import { ReactComponent as icon_question } from 'assets/img/icons/question.svg'
import { ReactComponent as icon_refresh } from 'assets/img/icons/refresh.svg'
import { ReactComponent as icon_salary } from 'assets/img/icons/salary.svg'
import { ReactComponent as icon_sell_outline } from 'assets/img/icons/sell_outline.svg'
import { ReactComponent as icon_star } from 'assets/img/icons/star.svg'
import { ReactComponent as icon_star_empty } from 'assets/img/icons/star_empty.svg'
import { ReactComponent as icon_stopwatch } from 'assets/img/icons/stopwatch.svg'
import { ReactComponent as icon_threedots } from 'assets/img/icons/threedots.svg'
import { ReactComponent as icon_trash } from 'assets/img/icons/trash.svg'
import { ReactComponent as icon_triangle } from 'assets/img/icons/triangle.svg'

export const arrow = icon_arrow
export const camera = icon_camera
export const car = icon_car
export const card = icon_card
export const caret_down = icon_caret_down
export const caret_up = icon_caret_up
export const caret_right = icon_caret_right
export const caret_left = icon_caret_left
export const clock = icon_clock
export const computer = icon_computer
export const cross = icon_cross
export const danger = icon_danger
export const date = icon_date
export const envelope = icon_envelope
export const exit = icon_exit
export const externallink = icon_externallink
export const file = icon_file
export const check = icon_check
export const loading = icon_loading
export const map = icon_map
export const phone = icon_phone
export const profile = icon_profile
export const salary = icon_salary
export const star = icon_star
export const star_empty = icon_star_empty
export const stopwatch = icon_stopwatch
export const threedots = icon_threedots
export const triangle = icon_triangle
export const burgermenu = icon_burgermenu
export const photo = icon_photo
export const eye = icon_eye
export const marker = icon_marker
export const pack = icon_pack
export const trash = icon_trash
export const question = icon_question
export const info = icon_info
export const home = icon_home
export const list = icon_list
export const refresh = icon_refresh
export const handshake = icon_handshake
export const lightbulb = icon_lightbulb
export const edit = icon_edit
export const edit_basic = icon_edit_basic
export const plus = icon_plus
export const paint_brush = icon_paint_brush
export const language = icon_language
export const link = icon_link
export const lock = icon_lock
export const sell_outline = icon_sell_outline
