import { getOnboardingCTLinks } from 'constants/staticLinks'

export const getOnboardingCTLink = (language: string) => {
  switch (language.toLowerCase()) {
    case 'de':
      return getOnboardingCTLinks.DE
    case 'fr':
      return getOnboardingCTLinks.FR
    case 'nl':
      return getOnboardingCTLinks.NL
    case 'en':
    default:
      return getOnboardingCTLinks.EN
  }
}
