import { BLUE_500, GRAY_200 } from 'constants/styling/theme'
import { Box, Stack } from '@mui/material'
import { InlineFilterItem, InlineFilters } from 'components/common/InlineFilters'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { Check } from '@mui/icons-material'
import { MUIButton } from 'components/common/MUIButton'
import { MUIDivider } from 'components/common/MUIDivider'
import Modal from 'components/common/Modals/Modal/Modal'
import { Nullable } from 'models/helpers'
import { PageTab } from 'components/common/Page/PageTab'
import { SelectableButtonsGrid } from 'components/common/SelectableButtonsGrid'
import { ToggleFilterValue } from 'components/common/ToggleFilter'
import { VirtualFurnitureStyleDTO } from 'models/virtualFurniture'
import styles from './StagingItemSelectionPopup.module.sass'
import { useTranslation } from 'react-i18next'

export enum StagingItemSelectionTab {
  ALL = 'ALL'
}

type MapIndex = StagingItemSelectionTab | string

interface DefaultProps<FilterValueType> {
  /** Whether the popup is open or not */
  isOpen: boolean
  /** Array of items with image per tab index for selection (should be pre-filtered if using filters) which will be rendering as list of images */
  availableItems?: Record<MapIndex, VirtualFurnitureStyleDTO[]>
  /** Array of button items per tab index for selection (should be pre-filtered if using filters) which will be rendered in SelectableButtonsGrid */
  availableButtonItems?: Record<string, ToggleFilterValue<string>[]>
  /** Array of available filters */
  filters?: Record<string, InlineFilterItem<FilterValueType>[]>
  /** Active filter value */
  activeFilter?: Nullable<FilterValueType>
  /** Title for modal */
  title: string
  /** Sub-title for modal */
  subtitle?: string
  /** Function that translated tab value to text (optional, if undefined, the value will be used) */
  tabTranslationResolver?: (tab: string) => string
  /** Handler for filter update event */
  onFilterUpdate?: (activeFilter: Nullable<FilterValueType>) => void
  /** Handler for close event */
  onClose: () => void
  /** Handler for triggering select analytic event */
  onSelectAnalyticEvent?: (value: string) => void
}

interface MultiSelectProps<FilterValueType> extends DefaultProps<FilterValueType> {
  /** Set of selected items for each tab index */
  selectedItemsMulti: Record<MapIndex, Set<string>>
  /** Handler for selection event of multi-select version */
  onSelectMulti: (selectedItems: Record<MapIndex, Set<string>>) => void
  /** Singular selected item for each tab index (single-select version) */
  selectedItems?: never
  /** Handler for selection event of single-select version */
  onSelect?: never
}

interface SingleSelectProps<FilterValueType> extends DefaultProps<FilterValueType> {
  /** Handler for selection event of single-select version */
  onSelect: (selectedItems: Record<MapIndex, string>) => void
  /** Singular selected item for each tab index (single-select version) */
  selectedItems: Record<MapIndex, string>
  /** Set of selected items for each tab index */
  selectedItemsMulti?: never
  /** Handler for selection event of multi-select version */
  onSelectMulti?: never
}

type FinalProps<FilterValueType> = MultiSelectProps<FilterValueType> | SingleSelectProps<FilterValueType>


/**
 * Popup component for handling selection of furniture templates / decorations / renovations in VirtualFurnitureStyleDTO format.   
 * Supports dividing items by Tabs => input data must therefore be in Record<string, VirtualFurnitureStyleDTO[]> format even if only one tab is to be used.   
 *    
 * Popup handles multi-select and single-select mode (one/multiple items per Tab)
 *  - For multi-select variant use the **selectedItemsMulti** and **onSelectMulti** properties for handling the selection   
 *  - For single-select variant use the **selectedItems** and **onSelect** properties for handling the selection
 *    
 * Popup also supports displaying of only basic ToggleButton like selection, for this **availableButtonItems** prop shall be used   
 * 
 * Simple filtering (toggle buttons displayed in row) is available.   
 * To use this, provide all available filters for each tab that should include them (property **filters**) in Record<string, InlineFilterItem<FilterValueType>[]> format, where FilterValueType is a generic.
 * Since the filtering of data should be handled outside and popup should only receive already filtered data **activeFilter** and **onFilterUpdate** properties are exposed.   
 *    
 * @example
 * <StagingItemSelectionPopup
 *   availableItems={filteredVirtualFurnitureStyles}
 *   title="Select furniture style"
 *   isOpen={isOpen}
 *   selectedItems={{
 *     'TAB_1': 'ITEM_CODE_556',
 *     'TAB_2': 'ITEM_CODE_556',
 *   }}
 *   filters={{
 *     'TAB_1': roomStyleCategoryToggleValues.map((value) => ({
 *       label: value.displayName || '',
 *       value: value.value,
 *       count: 12,
 *     }))
 *   }
 *   activeFilter={activeRoom.styleCategory || undefined}
 *   onClose={() => setIsOpen(false)}
 *   onFilterUpdate={style => updateRoom(activeRoom.key, { styleCategory: style })}
 *   onSelect={(selectedStyles) => {
 *     updateRoom(activeRoom.key, { styleCode: selectedStyles[0] ?? undefined })
 *     setIsOpen(false)
 *   }}
 * />
 */
export function StagingItemSelectionPopup<FilterValueType = string>({
  isOpen,
  filters,
  activeFilter,
  availableItems,
  availableButtonItems,
  selectedItems,
  selectedItemsMulti,
  title,
  subtitle,
  tabTranslationResolver,
  onClose,
  onSelect,
  onSelectMulti,
  onFilterUpdate,
  onSelectAnalyticEvent,
}: FinalProps<FilterValueType>) {
  const { t } = useTranslation(['common'])

  const availableTabs = useMemo(() => Object.keys(availableItems || availableButtonItems || {}), [availableButtonItems, availableItems])
  const [activeTab, setActiveTab] = useState<MapIndex>(availableTabs[0])

  const isMultiselect = useMemo(() => {
    if (onSelectMulti) return true
    return false
  }, [onSelectMulti])

  const [preselected, setPreselected] = useState<Record<MapIndex, string>>({})
  const [preselectedMulti, setPreselectedMulti] = useState<Record<MapIndex, Set<string>>>({})

  const isItemSelected = useCallback((itemValue: string) => {
    if (isMultiselect) return preselectedMulti[activeTab].has(itemValue)
    return preselected[activeTab] === itemValue
  }, [activeTab, isMultiselect, preselected, preselectedMulti])

  const toggleSelection = useCallback((tab: string, value: string) => {

    onSelectAnalyticEvent?.(value)

    if (!isMultiselect) {
      if (preselected[tab] && preselected[tab] === value) setPreselected((prev) => {
        const updated = { ...prev }
        delete updated[tab]

        return updated
      })
      else setPreselected((prev) => {
        const updated = { ...prev }
        updated[tab] = value

        return updated
      })
      return
    }

    setPreselectedMulti((prev) => {
      const updated = { ...prev }
      const newSelection = updated[tab] ?? new Set([])

      if (newSelection.has(value)) newSelection.delete(value)
      else newSelection.add(value)

      updated[tab] = newSelection
      return updated
    })

  }, [isMultiselect, onSelectAnalyticEvent, preselected])

  useEffect(() => {
    if (!isOpen) return

    if (isMultiselect && selectedItemsMulti) {
      setPreselectedMulti(selectedItemsMulti)
    }

    if (!isMultiselect && selectedItems) {
      setPreselected(selectedItems)
    }

    setActiveTab(availableTabs[0])

    // Omit everything except isOpen to only do this on modal open with current values at that time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const currentItems = useMemo(() => {
    if (!activeTab || !availableItems?.[activeTab]) return []

    return availableItems[activeTab]
  }, [activeTab, availableItems])

  const selectedArray = useMemo(() => {
    if (!activeTab) return []

    if (isMultiselect) return Array.from(preselectedMulti[activeTab] ?? [])

    return [preselected[activeTab]]
  }, [activeTab, isMultiselect, preselected, preselectedMulti])

  const isConfirmDisabled = useMemo(() => {
    let assignedTabs: string[] = []

    if (isMultiselect) {
      assignedTabs = Object.keys(preselectedMulti).filter((key) => preselectedMulti[key].size === 0)
    } else {
      assignedTabs = Object.keys(preselected).filter((key) => !!preselected[key])
    }

    return assignedTabs.length !== 0 && assignedTabs.length !== availableTabs.length
  }, [availableTabs.length, isMultiselect, preselected, preselectedMulti])

  return (
    <Modal
      isOpen={isOpen}
      onClickOutside={onClose}
      onClose={onClose}
      title={title}
      subtitle={subtitle}
      modalContentClassName={styles.modal}
      afterClosed={() => {
        setPreselected({})
        setPreselectedMulti({})
      }}
      footerContent={
        <Stack direction="row" justifyContent="flex-end" paddingTop={2} gap={2}>

          <MUIButton type="secondaryBorder" onClick={onClose}>
            {t('Cancel')}
          </MUIButton>

          <MUIButton
            disabled={isConfirmDisabled}
            onClick={() => {
              if (isMultiselect) onSelectMulti?.(preselectedMulti)
              else onSelect?.(preselected)
            }}
          >
            {t('Confirm_selection')}
          </MUIButton>

        </Stack>
      }
      extraHeaderContent={
        <>
          {/* TABS */}
          {availableTabs.length > 1 &&
            <>
              {availableTabs.map((tab) => (
                <PageTab
                  key={tab}
                  tab={tab}
                  activeTab={activeTab}
                  tabText={tabTranslationResolver ? tabTranslationResolver(tab) : tab}
                  onClick={() => {
                    onFilterUpdate?.(undefined)
                    setActiveTab(tab)
                  }}
                  trailingIcon={(isMultiselect && preselectedMulti[tab].size) || (!isMultiselect && preselected[tab]) ? <Check color="success" fontSize="medium" /> : undefined}
                />
              ))
              }
              <MUIDivider sx={{
                marginTop: '0 !important',
                marginBottom: '2rem',
              }} />
            </>
          }

          {/* FILTERS */}
          {filters?.[activeTab]?.length &&
            <InlineFilters<FilterValueType>
              filters={filters[activeTab]}
              activeFilter={activeFilter}
              onFilterUpdate={(value) => onFilterUpdate?.(value)}
            />
          }</>
      }
    >

      {!!activeTab &&
        <>

          {/* ITEMS */}
          <div className={styles.grid}>

            {currentItems.map((item) =>
              <Box
                sx={{
                  backgroundImage: `url('${item.stagingImageUrl}')`,
                  backgroundPosition: 'center center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  border: '3px solid transparent',
                  borderRadius: '8px',
                  borderColor: isItemSelected(item.code) ? BLUE_500 : GRAY_200,
                  cursor: 'pointer',
                  height: '20rem',
                }}
                onClick={() => toggleSelection(activeTab, item.code)}
                key={[item.code, item.style, item.roomType].join(':')}
              >
              </Box>
            )}

          </div>

          {!!availableButtonItems?.[activeTab] &&
            <SelectableButtonsGrid<string>
              values={availableButtonItems[activeTab]}
              selectedValue={selectedArray}
              onSelect={value => toggleSelection(activeTab, value)}
              onUnselect={value => toggleSelection(activeTab, value)}
              showCheckbox
              activeButtonClickable
            />
          }
        </>
      }

    </Modal>
  )
}
