export async function downloadLogo(signedURL: string, headers: Record<string, string>): Promise<Blob> {
  const response = await fetch(signedURL, {
    method: 'GET',
    headers: new Headers(headers),
  })
  if (!response.ok) {
    throw new Error(`Failed to download logo: ${response.statusText}`)
  }
  return response.blob()
}

export const createFileFromBlob = (blob: Blob, filename: string): File => {
  return new File([blob], filename, { type: blob.type })
}
