import { VirtualFurnitureStyleDTO } from 'models/virtualFurniture'
import constate from 'constate'
import { useGetVirtualStagingFurnitureStyles } from 'dataQueries'
import { useMemo } from 'react'

function useVirtualFurnitureDataSetup() {

  const virtualFurnitureStyles = useGetVirtualStagingFurnitureStyles()
  const virtualFurnitureStylesData = useMemo(() => virtualFurnitureStyles.data || [], [virtualFurnitureStyles.data])
  const virtualFurnitureStylesMap = useMemo<Record<string, VirtualFurnitureStyleDTO>>(() => virtualFurnitureStylesData.reduce((map, item) => ({
    ...map,
    [item.code]: item
  }), {}), [virtualFurnitureStylesData])

  return {
    virtualFurnitureStyles: virtualFurnitureStylesData,
    virtualFurnitureStylesMap,
  }
}

export const [VirtualFurnitureDataContextProvider, useVirtualFurnitureData] = constate(useVirtualFurnitureDataSetup)
