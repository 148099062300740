import { Trans, useTranslation } from 'react-i18next'

import BlockInfo from 'components/common/BlockInfo/BlockInfo'
import { FC } from 'react'
import { MultiEmailInput } from 'components/common/MultiEmailInput'
import { SubscriptionPlan } from 'models/user'
import { WORKSPACE_MEMBER_LIMIT_BY_PLAN } from 'constants/workspaces'
import { getSubscriptionPricingLink } from 'utils/localization'
import styles from './AddWorkspaceMembersList.module.sass'
import { supportEmailHref } from 'constants/contacts'
import { useSubscriptionFlow } from 'components/pages/SubscriptionFlow/_main/SubscriptionFlow.context'

/**
 * Displays info and input for adding emails of future members for later submission.
 * 
 * @example
 * <AddWorkspaceMembersList />
 */
export const AddWorkspaceMembersList: FC = () => {

  const { t, i18n } = useTranslation(['add_workspace_members_list'])
  const { workspaceMembers, setWorkspaceMembers, maxInvitesCount } = useSubscriptionFlow()

  return (
    <section className={styles.addWorkspaceMembersList}>

      <h2>{t('title')}</h2>

      {/* Handles br/strong tags */}
      <Trans
        className={styles.info}
        parent="p"
        t={t}
        i18nKey="description"
      >
        <a href={supportEmailHref()}>&nbps;</a>
      </Trans>

      <div className={styles.addMembersSection}>
        <MultiEmailInput
          emails={workspaceMembers}
          onChange={(emails) => setWorkspaceMembers(emails)}
          maxEmails={maxInvitesCount}
        />
      </div>

      {workspaceMembers.length >= maxInvitesCount &&
        <BlockInfo className={styles.maxMembersWarning}>
          <Trans
            t={t}
            i18nKey="max_members_warning"
            values={{ maxMembers: WORKSPACE_MEMBER_LIMIT_BY_PLAN[SubscriptionPlan.PRO_PER_SEAT] }}
          >
            <a
              href={getSubscriptionPricingLink(i18n.language)}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              &nbsp;
            </a>
          </Trans>
        </BlockInfo>
      }

    </section>
  )
}
