import { Color, IconType } from 'constants/assets'
import { FC, useEffect, useMemo, useState } from 'react'
import { Link, createSearchParams } from 'react-router-dom'
import { PaymentMethodTypes, StripePaymentIntentStatuses } from 'constants/payments'
import { Trans, useTranslation } from 'react-i18next'

import Button from 'components/common/Button/Button'
import Icon from 'components/common/Icon/Icon'
import { OrderAssignmentStatusFilter } from 'constants/misc'
import { Path } from 'constants/router'
import { PaymentIntent } from '@stripe/stripe-js'
import styles from './PaymentStatus.module.sass'
import { useStripe } from '@stripe/react-stripe-js'

interface Props {
  clientSecret: string,
  dealId: string | null,
  assignmentId: string | null,
  reference: string | null,
}

enum Statuses {
  SUCCESS = 'SUCCESS',
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR',
}

enum EntityTypes {
  DEAL = 'DEAL',
  ASSIGNMENT = 'ASSIGNMENT'
}

/** Controller for PaymentStatus */
export const PaymentStatusController: FC<Props> = ({
  clientSecret,
  dealId,
  assignmentId,
  reference,
}) => {

  const statusColorMap = new Map<Statuses, Color>([
    [Statuses.SUCCESS, Color.SECONDARY_GREEN_DARK],
    [Statuses.PROCESSING, Color.SECONDARY_BLUE_DARK],
    [Statuses.ERROR, Color.SECONDARY_ORANGE_DARK],
  ])

  const statusIconMap = new Map<Statuses, IconType>([
    [Statuses.SUCCESS, IconType.CHECK],
    [Statuses.PROCESSING, IconType.LOADING],
    [Statuses.ERROR, IconType.DANGER],
  ])

  const paymentMethodStatusMap = useMemo(() => ({
    // SEPA's PROCESSING state is taken as a SUCCESS, since it waits until money are transferred to flip into SUCCESS state
    [PaymentMethodTypes.SEPA_DEBIT]: {
      [StripePaymentIntentStatuses.succeeded]: Statuses.SUCCESS,
      [StripePaymentIntentStatuses.processing]: Statuses.SUCCESS,
      [StripePaymentIntentStatuses.canceled]: Statuses.ERROR,
      [StripePaymentIntentStatuses.requires_action]: Statuses.ERROR,
      [StripePaymentIntentStatuses.requires_capture]: Statuses.ERROR,
      [StripePaymentIntentStatuses.requires_confirmation]: Statuses.ERROR,
      [StripePaymentIntentStatuses.requires_payment_method]: Statuses.ERROR,
    },
    [PaymentMethodTypes.CARD]: {
      [StripePaymentIntentStatuses.succeeded]: Statuses.SUCCESS,
      [StripePaymentIntentStatuses.processing]: Statuses.PROCESSING,
      [StripePaymentIntentStatuses.canceled]: Statuses.ERROR,
      [StripePaymentIntentStatuses.requires_action]: Statuses.ERROR,
      [StripePaymentIntentStatuses.requires_capture]: Statuses.ERROR,
      [StripePaymentIntentStatuses.requires_confirmation]: Statuses.ERROR,
      [StripePaymentIntentStatuses.requires_payment_method]: Statuses.ERROR,
    }
  }), [])

  const { t } = useTranslation(['payment_status_page'])
  const stripe = useStripe()

  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent>()
  const paymentMethod = useMemo(() => !!paymentIntent ? paymentIntent.payment_method_types[0].toUpperCase() as PaymentMethodTypes : undefined, [paymentIntent])
  const isDealOrAssignment = useMemo(() => !!dealId && !assignmentId ? EntityTypes.DEAL : EntityTypes.ASSIGNMENT, [assignmentId, dealId])
  const isDeal = useMemo(() => isDealOrAssignment === EntityTypes.DEAL, [isDealOrAssignment])
  const isAssignment = useMemo(() => isDealOrAssignment === EntityTypes.ASSIGNMENT, [isDealOrAssignment])
  const dealOrAssignmentId = useMemo(() => isDealOrAssignment === EntityTypes.DEAL ? dealId : assignmentId, [dealId, assignmentId, isDealOrAssignment])

  const status = useMemo(() => {
    if (!paymentIntent?.status) return undefined
    if (!paymentMethod) return undefined

    return paymentMethodStatusMap[paymentMethod][paymentIntent.status] || Statuses.ERROR
  }, [paymentIntent, paymentMethod, paymentMethodStatusMap])

  // Fetch payment intent and update it every 5 seconds
  useEffect(() => {
    let timer: number

    if (!!stripe) {
      const loadIntent = async () => {
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret)
        setPaymentIntent(paymentIntent)
      }

      loadIntent()
      timer = window.setInterval(loadIntent, 5000)
    }

    return () => {
      window.clearInterval(timer)
    }

  }, [clientSecret, stripe])

  return (
    <div className={`page ${styles.paymentStatus} ${!!status ? styles[status] : ''}`}>
      <div className="page-content">
        {!!paymentIntent && !!paymentMethod && !!status &&
          <div className={styles.statusBox}>

            <Icon className={`${styles.statusIcon} ${styles[status]}`} color={statusColorMap.get(status) || Color.SECONDARY_RED_DARK} icon={statusIconMap.get(status) || IconType.CROSS} />

            <h2 className={styles.title}>
              {t(`payment_status_page:${isDealOrAssignment}:${paymentMethod}:${status}:title`, { id: dealOrAssignmentId, reference })}
            </h2>

            <p className={styles.text}>
              <Trans
                t={t}
                i18nKey={`payment_status_page:${isDealOrAssignment}:${paymentMethod}:${status}:text`}
                values={{ id: dealOrAssignmentId, reference, context: !!reference ? 'reference' : '' }}
              >
                <span></span>
              </Trans>
            </p>

            {isAssignment && status === Statuses.SUCCESS &&
              <p className={styles.textContrast}>
                <Trans
                  t={t}
                  i18nKey={`payment_status_page:${isDealOrAssignment}:${paymentMethod}:${status}:text_reminder_staging`}
                >
                  <span></span>
                </Trans>
              </p>
            }

            {isDeal && status !== Statuses.PROCESSING &&
              <Link to={{
                pathname: Path.INDEX,
                search: createSearchParams({ status: OrderAssignmentStatusFilter.ONGOING }).toString(),
              }}>
                <Button className={styles.cta}>
                  {t('go_to_dashboard')}
                </Button>
              </Link>
            }

            {isAssignment && status !== Statuses.PROCESSING &&
              <Link to={Path.GALLERY.replace(':id', assignmentId ?? '')}>
                <Button className={styles.cta}>
                  {t('back_to_gallery')}
                </Button>
              </Link>
            }

          </div>
        }
      </div>
    </div>
  )
}
