import { FC, ReactNode } from 'react'

import styles from './OwnerFilterTab.module.sass'

/**
 * @interface Props Owner filter properties
 */
interface Props {
  /** The additional classes to append */
  className?: string
  /** Icon display on the left side of tab */
  icon?: ReactNode
  /** Text display on the tab */
  text: string
  /** Whether the Owner filter tab is active or not */
  active?: boolean
  /** Whether the Owner filter tab is unavailable */
  unavailable?: boolean
  /** Onclick action to be called */
  onClick?: (e: React.MouseEvent) => unknown
}

/**
 * @component Owner filter tab component
 * @example <OwnerFilterTab active={true} onClick={onClickAction}</OwnerFilterTab>
 */
export const OwnerFilterTab: FC<Props> = ({
  className = '',
  active,
  unavailable = false,
  icon,
  text,
  onClick,
}) => {
  if (active) className += ` ${styles.active}`
  if (unavailable) className += ` ${styles.unavailable}`

  return (
    <div
      className={`${styles.ownerFilterTab} ${className}`.replace(/\s+/igm, ' ').trim()}
      onClick={e => onClick && onClick(e)}
    >
      <div className={styles.contentWrapper}>
        {icon && <span className={styles.tabIcon}>{icon}</span>}
        <span>{text}</span>
      </div>
    </div>
  )
}
