import { getImageMetadata } from 'utils/serialization'

/**
 * Function that determines whether image is of good quality or not.  
 * Good quality image is one, whose longer side is no smaller than 1920px.
 * @param imageFile - image file to check
 * @returns Promise which resolves **true** when image passes quality check and **false** when not
*/
export async function isImageGoodQuality(imageFile: File) {
  try {
    const metadata = await getImageMetadata(imageFile)

    if (Math.max(metadata.height, metadata.width) < 1920) return false

    return true

  } catch (e) {
    return false
  }
}
