import { Box, Snackbar as MUISnackbar, SnackbarProps, ThemeProvider } from '@mui/material'

import { FC } from 'react'
import { SnackbarToast } from './SnackbarToast.component'
import { redesignMUITheme } from 'constants/styling/redesignTheme'
import { useSnackbar } from 'components/contexts/SnackbarService.context'

interface Props {
  /** Object defining vertical and horizontal positioning of the snackbar */
  anchorOrigin?: SnackbarProps['anchorOrigin']
}

/**
 * Snackbar component for displaying stacked toast messages
 * @example <Snackbar />
 */
export const Snackbar: FC<Props> = ({
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  }
}) => {
  const { messageQueue, removeMessageFromQueue } = useSnackbar()

  return (
    <ThemeProvider theme={redesignMUITheme}>
      <MUISnackbar
        open={messageQueue.size > 0}
        autoHideDuration={null}
        transitionDuration={0}
        anchorOrigin={anchorOrigin}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}>
          {messageQueue.map((message) => (
            <SnackbarToast
              key={message.id}
              autoHideTimeout={message.timeout}
              onClose={() => removeMessageFromQueue(message.id)}
              {...message}
            />
          ))}
        </Box>
      </MUISnackbar>
    </ThemeProvider>
  )
}
