import { bigFromDecimal, bigFromFee, percentageValue, valueAfterDiscount } from 'utils/price'
import { DealDTOIsClientDTO, DealDTOOrAssignmentDTOIsDealDTO } from 'utils/typeguards'

import Big from 'big.js'
import { Currency } from 'constants/misc'
import { AssignmentDTO } from 'models/assignment'
import { DealDTO } from 'models/deal'
import { useMemo } from 'react'

const defaultsPriceData = {
  discountedTotal: new Big(0),
  totalWithoutDiscount: new Big(0),
  vat: new Big(0),
  percentageDiscount: new Big(0),
  currency: Currency.CHF,
}

/** React hook which provide the Order price details.  
 * @returns Price details include discountedTotal, totalWithoutDiscount, vat, percentageDiscount and currency. Computed totalVat and totalAfterVat.
*/
export function useOrderPriceBreakdown(data?: DealDTO | AssignmentDTO | null) {

  const priceData = useMemo(() => {
    if (!data || !DealDTOOrAssignmentDTOIsDealDTO(data) || !DealDTOIsClientDTO(data)) return defaultsPriceData

    return {
      discountedTotal: bigFromFee(data.discountedFeePrice),
      totalWithoutDiscount: bigFromFee(data.feePrice),
      vat: bigFromDecimal(data.financials.vat),
      percentageDiscount: bigFromDecimal(data.financials.discount),
      currency: data.feePrice.currency,
    }
  }, [data])

  // TODO: Update the calculation based on correct BE data after the issue is solved.
  // It's a work around to handle unsolved issue from financial discount data which occur before/after client cancel the order.
  // Computed calculation is based on financials.discount to get total with discount
  const computedTotalAfterDiscount = useMemo(() => valueAfterDiscount(priceData.totalWithoutDiscount, priceData.percentageDiscount), [priceData.percentageDiscount, priceData.totalWithoutDiscount])
  const computedTotalVat = useMemo(() => percentageValue(computedTotalAfterDiscount, priceData.vat), [computedTotalAfterDiscount, priceData.vat])
  const computedTotalAfterVat = useMemo(() => computedTotalAfterDiscount.plus(computedTotalVat), [computedTotalAfterDiscount, computedTotalVat])
  // Discounted total directly use the discountedFeePrice.value which is calculated from BE
  const discountedTotalVat = useMemo(() => percentageValue(priceData.discountedTotal, priceData.vat), [priceData.discountedTotal, priceData.vat])
  const discountedTotalAfterVat = useMemo(() => priceData.discountedTotal.plus(discountedTotalVat), [discountedTotalVat, priceData.discountedTotal])

  const discountedValue = useMemo(() => percentageValue(priceData.totalWithoutDiscount, priceData.percentageDiscount), [priceData.percentageDiscount, priceData.totalWithoutDiscount])

  return { ...priceData, discountedTotalVat, discountedTotalAfterVat, computedTotalVat, computedTotalAfterVat, computedTotalAfterDiscount, discountedValue }
}
