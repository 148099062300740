import { Trans, useTranslation } from 'react-i18next'

import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import { EditorOptionButton } from '../EditorOptionButton'
import React from 'react'
import { WorkspaceRole } from 'models/user'
import styles from './NoAvailableTemplate.module.sass'
import { useWorkspacesProfileContext } from 'components/pages/Client/WorkspacesProfile/_main/WorkspacesProfile.context'

/**
 * @component NoAvailableTemplate 
 * @example
 * <NoAvailableTemplate />
 */
export const NoAvailableTemplate: React.FC = () => {
  const { t } = useTranslation(['visuals_editing', 'editor_options'])
  const { workspaceDataOfUser } = useWorkspacesProfileContext()

  const isWorkspaceAdmin = workspaceDataOfUser?.role === WorkspaceRole.ADMIN

  return (
    <div className={styles.noTemplateAvailable}>
      <EditorOptionButton
        disabled
        title={t('editor_options.apply_template.button_no_available')}
        icon={<BookmarkBorderIcon fontSize='inherit' />}
        className={styles.button}
      />

      <span className={styles.hint}>
        {/* Handles strong/br tags */}
        <Trans t={t} i18nKey={isWorkspaceAdmin ? 'editor_options.apply_template.admin_hint' : 'editor_options.apply_template.client_hint'} parent="p">
          &nbsp;
        </Trans>
      </span>
    </div>
  )
}
