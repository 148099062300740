import { BadRatingPopup, BadRatingPopupData } from '../BadRatingPopup'
import { EmojiValue, RatingType } from 'constants/misc'
import { FC, useCallback, useState } from 'react'
import { getDebouncedLogAnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'

import { Nullable } from 'models/helpers'
import RatingGeneral from 'components/common/RatingGeneral/RatingGeneral'
import styles from './CustomerRating.module.sass'
import { useGalleryAssignment } from '../../../_main/contexts'
import { useSubmitRating } from 'dataQueries'
import { useUserData } from 'components/contexts/UserDataContext'

const _longDebouncedLogEvent = getDebouncedLogAnalyticsEvent(2000, { maxWait: 4000, leading: true })

/**
 * Displays multiple cards for rating of assignment.
 * Cards when submitting bad review without reason displays prompt asking for some feedback which can be ignored.
 *    
 * DEPENDENCIES:
 * - UserDataContext
 * - GalleryAssignmentContext
 *    
 * @example
 * <CustomerRating />
 */
export const CustomerRating: FC = () => {

  const { clientData } = useUserData()

  const {
    creative,
    assignmentData,
    assignmentRatings,
    product
  } = useGalleryAssignment()

  const submitRatingQuery = useSubmitRating()

  const [ratingPopupData, setRatingPopupData] = useState<Nullable<BadRatingPopupData>>(null)

  const handleSubmitRating = useCallback((rating: Nullable<EmojiValue>, ratingText: Nullable<string>, ratingType: Nullable<RatingType>, isPopup: boolean, isUpdate: boolean) => {
    if (!assignmentData) return
    if (!rating || !ratingType) return

    // Open in-ya-face popup when submitting bad rating without comment
    if (!isUpdate && !isPopup && rating === EmojiValue.UNHAPPY && !ratingText) {
      setRatingPopupData({
        ratingType,
        rating
      })
      return
    }


    submitRatingQuery.mutate({
      assignmentId: assignmentData.id,
      type: ratingType,
      rating,
      ratingText: ratingText || '',
      isUpdate
    })

    // ANALYTICS
    logAnalyticsEvent('Customer rating', {
      assignmentId: assignmentData.id,
      ratingType,
      ratingNumber: rating,
      rating: rating ? EmojiValue[rating] : undefined,
      ratingText: ratingText || '',
      organizationId: clientData?.organizationId,
      pipedriveId: clientData?.pipedriveId,
      withPopup: isPopup,
      productKind: product?.kind
    })

  }, [assignmentData, submitRatingQuery, clientData?.organizationId, clientData?.pipedriveId, product?.kind])

  // Debounce tracking typing of rating comment, sending different log name in rating popup/rating box
  const logRatingCommentTyping = useCallback((text: string, rating: Nullable<EmojiValue>, ratingType: Nullable<RatingType>, isPopup: boolean) => {
    if (!ratingType) return

    _longDebouncedLogEvent(`Typing rating comment ${isPopup ? 'in rating popup' : 'in rating box'}`, {
      assignmentId: assignmentData?.id,
      ratingType,
      ratingNumber: rating,
      rating: rating ? EmojiValue[rating] : undefined,
      ratingText: text || '',
      organizationId: clientData?.organizationId,
      pipedriveId: clientData?.pipedriveId,
    })
  }, [assignmentData, clientData])

  // Edge case and a typeguard
  if (!assignmentData) return <>No assignment data</>

  return (
    <>
      <RatingGeneral
        className={styles.csatItem}
        textareaName="rating-visuals"
        textareaId="rating-visuals"
        ratingType={RatingType.VISUALS}
        rating={assignmentRatings?.[RatingType.VISUALS]}
        ratingRequest={submitRatingQuery.variables?.type === RatingType.VISUALS ? submitRatingQuery : null}
        onSubmitRating={(emoji, text, ratingType, isUpdate) => handleSubmitRating(emoji, text, ratingType, false, isUpdate)}
        onTextChange={(text, rating, ratingType) => logRatingCommentTyping(text, rating, ratingType, false)}
      />

      {!!creative &&
        <RatingGeneral
          className={styles.csatItem}
          textareaName="rating-creative"
          textareaId="rating-creative"
          ratingType={RatingType.CREATIVE}
          rating={assignmentRatings?.[RatingType.CREATIVE]}
          ratingRequest={submitRatingQuery.variables?.type === RatingType.CREATIVE ? submitRatingQuery : null}
          onSubmitRating={(emoji, text, ratingType, isUpdate) => handleSubmitRating(emoji, text, ratingType, false, isUpdate)}
          onTextChange={(text, rating, ratingType) => logRatingCommentTyping(text, rating, ratingType, false)}
        />
      }

      <RatingGeneral
        className={styles.csatItem}
        textareaName="rating-platform"
        textareaId="rating-platform"
        ratingType={RatingType.SERVICE}
        rating={assignmentRatings?.[RatingType.SERVICE]}
        ratingRequest={submitRatingQuery.variables?.type === RatingType.SERVICE ? submitRatingQuery : null}
        onSubmitRating={(emoji, text, ratingType, isUpdate) => handleSubmitRating(emoji, text, ratingType, false, isUpdate)}
        onTextChange={(text, rating, ratingType) => logRatingCommentTyping(text, rating, ratingType, false)}
      />

      <BadRatingPopup
        isOpen={!!ratingPopupData}
        data={ratingPopupData}
        onSubmit={(text, rating, ratingType) => handleSubmitRating(rating, text, ratingType, true, false)}
        onChange={(text, rating, ratingType) => logRatingCommentTyping(text, rating, ratingType, true)}
        onClose={() => setRatingPopupData(null)}
      />
    </>
  )
}
