import React, { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Collapse from '@mui/material/Collapse'
import { ProductSegment } from 'constants/product'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import Typography from '@mui/material/Typography'
import { standardTimeoutConfig } from 'utils/animations'
import { usePurchaseFlowProducts } from '../../_main/contexts/PurchaseFlowProducts.context'

/** @interface Props for the ProductListHeader component. */
interface Props {
  /** The title to be displayed in the product list header. */
  title: string
}

/**
 * @component
 * ProductListHeader is a component that displays the title of the product list with an optional segment.
 * 
 * @example
 * <ProductListHeader title="You miss 100% of the shots you don't take." />
 */
export const ProductListHeader: React.FC<Props> = ({ title }) => {
  const { t } = useTranslation(['order'])
  const { selectedSegment } = usePurchaseFlowProducts()

  return (
    <TransitionGroup component={null}>
      <Collapse
        in={true}
        timeout={standardTimeoutConfig}
      >
        <Typography variant='h3' fontWeight={600}>
          {title}
          {!!selectedSegment && selectedSegment !== ProductSegment.all &&
            <Fragment>
              {' ('}
              <Trans t={t} i18nKey={`segment:${selectedSegment}`}>
                <sup></sup>
              </Trans>
              {')'}
            </Fragment>
          }
        </Typography>
      </Collapse>
    </TransitionGroup>
  )
}
