import { ComputedStagingRoom, StagingType } from 'models/virtualStaging'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'

import { useRoomAPI } from 'components/contexts/RoomAPI.context'
import { VirtualFurnitureRoomType } from 'constants/virtualFurniture'
import constate from 'constate'
import { CatalogueOptionProduct } from 'dataQueries/purchase.query'
import { roomItemOpenPopupEventName } from 'utils/purchaseFlow'
import { useRenovationProducts } from './RenovationProducts.context'

/** Enumeration representing the possible types of room item. */
export enum RoomItemPopupType {
  FURNITURE = 'FURNITURE',
  DECORATION = 'DECORATION',
  WALL = 'WALL',
  FLOOR = 'FLOOR',
}

export enum StyleSelectionOrigin {
  PURCHASE_FLOW = 'Purchase Flow',
  GALLERY = 'Gallery',
}

/** Props required for the StyleSelectionContextProvider context. */
interface StyleSelectionProviderProps {
  /**  */
  origin: StyleSelectionOrigin
  /** Function called when the renovation products change. */
  onRenovationProductsChange?: (activeRoom: ComputedStagingRoom, renovationProduct: CatalogueOptionProduct, productQuantity: number) => void
}

function useStyleSelectionSetup({ onRenovationProductsChange, origin }: StyleSelectionProviderProps) {

  const { getAllRooms } = useRoomAPI()
  const { getRoomsWithRenovationProducts, renovationCartProduct } = useRenovationProducts()

  /** Keeps current room index */
  const [activeRoomIndex, setActiveRoomIndex] = useState<number>(0)
  const [isSpecialRequestPopupOpen, setIsSpecialRequestPopupOpen] = useState<boolean>(false)

  const [roomItemPopupType, setRoomItemPopupType] = useState<RoomItemPopupType | null>(null)

  /** All rooms in inventory sorted by order of their creation */
  const allRooms = useMemo(() => getAllRooms().sort((roomA, roomB) => roomA.roomCreationSortingOrder - roomB.roomCreationSortingOrder), [getAllRooms])
  const activeRoom = useMemo(() => allRooms[activeRoomIndex], [activeRoomIndex, allRooms])

  const selectedRemovableObject = useMemo(() => activeRoom?.renovations.get(VirtualFurnitureRoomType.WHOLE_ROOM) ?? activeRoom?.furnitureRemoval, [activeRoom])
  const isSomeFurnitureSelected = useMemo(() => activeRoom?.styleCodeMap.size > 0, [activeRoom?.styleCodeMap])
  const isAllFurnitureSelected = useMemo(() => activeRoom?.hasSelectedAllStyleCodes, [activeRoom?.hasSelectedAllStyleCodes])
  const isDecorationSelected = useMemo(() => !!activeRoom?.decorations.size, [activeRoom?.decorations])
  const isWallSelected = useMemo(() => !!activeRoom?.renovations.get(VirtualFurnitureRoomType.WALL_MATERIALS), [activeRoom?.renovations])
  const isFloorSelected = useMemo(() => !!activeRoom?.renovations.get(VirtualFurnitureRoomType.FLOORING), [activeRoom?.renovations])
  const isRoomItemSelected = useMemo(() => isSomeFurnitureSelected || isDecorationSelected || isWallSelected || isFloorSelected, [isDecorationSelected, isFloorSelected, isSomeFurnitureSelected, isWallSelected])
  const isBKBNDecide = useMemo(() => activeRoom?.type === StagingType.BKBN, [activeRoom?.type])

  const isRoomEmptyType = useMemo(() => activeRoom?.roomTypes.size === 1 && activeRoom.roomTypes.has(VirtualFurnitureRoomType.EMPTY_ROOM), [activeRoom?.roomTypes])

  const imagesForImageStack: string[] = useMemo(() => {
    const images: string[] = []

    if (!activeRoom) return []

    // Prune undefined urls and push existing to array
    for (let image of activeRoom.images) {
      if (!image.url) continue
      images.push(image.url)
    }

    return images
  }, [activeRoom])

  const renovationCountForActiveRoomsProduct = useMemo(() => {
    if (!activeRoom?.productId) return 0

    return getRoomsWithRenovationProducts(allRooms.filter(room => room.productId === activeRoom.productId)).size
  }, [activeRoom?.productId, allRooms, getRoomsWithRenovationProducts])

  const stagingFlowAnalytics = useCallback((event: AnalyticsEvent | string, params?: {}) => {
    logAnalyticsEvent(event, { ...params, Flow: origin })
  }, [origin])

  const openRoomItemPopup = useCallback((popupType: RoomItemPopupType) => {
    stagingFlowAnalytics(roomItemOpenPopupEventName(popupType), {})
    setRoomItemPopupType(popupType)
  }, [stagingFlowAnalytics])

  // Triggers every time roomsWithRenovationProducts changes to update the option product in the cart.
  useEffect(() => {
    if (onRenovationProductsChange && renovationCartProduct) {
      onRenovationProductsChange(activeRoom, renovationCartProduct, renovationCountForActiveRoomsProduct)
    }
    // exclude `activeRoom` `renovationProductCart` `roomsWithRenovationProducts`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renovationCountForActiveRoomsProduct])

  return {
    allRooms,
    activeRoom,
    activeRoomIndex,
    roomItemPopupType,
    imagesForImageStack,
    selectedRemovableObject,
    isAllFurnitureSelected,
    isSomeFurnitureSelected,
    isDecorationSelected,
    isWallSelected,
    isRoomEmptyType,
    isFloorSelected,
    isRoomItemSelected,
    isSpecialRequestPopupOpen,
    isBKBNDecide,
    openRoomItemPopup,
    setActiveRoomIndex,
    setRoomItemPopupType,
    setIsSpecialRequestPopupOpen,
    stagingFlowAnalytics,
  }
}

export const [StyleSelectionContextProvider, useStyleSelection] = constate(useStyleSelectionSetup)
