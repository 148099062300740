import { FC, ReactNode } from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'
import { standardEnterEffect, standardExitEffect, standardTimeoutConfig } from 'utils/animations'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** Delegates to in property of Transition element */
  visible: boolean
  /** Delegates to onEnter property of Transition element, defaults to standardEnterEffect */
  onEnter?: (node: HTMLElement, appears: boolean) => void
  /** Delegates to onExit property of Transition element, defaults to standardExitEffect */
  onExit?: (node: HTMLElement) => void
  /** Delegates to timeout property of Transition element, defaults to standardTimeoutConfig */
  timeout?: { enter: number, exit: number }
  children?: ReactNode
}

/** Component used for simple transition in animations using the standard effects and timeouts */
const TransitionAppear: FC<Props> = ({
  visible,
  onEnter = standardEnterEffect,
  onExit = standardExitEffect,
  timeout = standardTimeoutConfig,
  children,
}) => {
  return (
    <TransitionGroup component={null}>
      {visible &&
        <Transition
          appear={true}
          in={visible}
          onEnter={onEnter}
          onExit={onExit}
          timeout={timeout}
        >
          {children}
        </Transition>
      }
    </TransitionGroup>
  )
}

export default TransitionAppear
