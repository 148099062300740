import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { Stack, Typography } from '@mui/material'

import { GRAY_700 } from 'constants/styling/theme'
import { SelectableButtonsGrid } from 'components/common/SelectableButtonsGrid'
import { ToggleFilterValue } from 'components/common/ToggleFilter'
import { useTranslation } from 'react-i18next'

enum ToggleOptions {
  On = 'on',
  Off = 'off',
}

interface FloorPlanToggleProps {
  /** The title of toggle section. */
  title: string
  /** A boolean indicating if the toggle is currently active. */
  value: boolean
  /** The function to be called when the toggle is selected. */
  setValue: Dispatch<SetStateAction<boolean>>
}

/**
 * @component
 * Displays the toggle control of floorplan setting.
 * 
 * @example 
 * <FloorPlanToggleSetting />
 */
export const FloorPlanToggleSetting: FC<FloorPlanToggleProps> = ({
  title,
  value,
  setValue
}) => {
  const { t } = useTranslation(['purchase_flow'])

  const floorplanToggleValues: ToggleFilterValue<ToggleOptions>[] = useMemo(
    () => [ToggleOptions.Off, ToggleOptions.On].map((option) => ({
      value: option,
      displayName: t(`floor_plan_step.layout.${option}`)
    })),
    [t]
  )

  return (
    <Stack gap=".6rem">

      <Typography variant='text-sm' fontWeight={500} color={GRAY_700}>
        {title}
      </Typography>

      <SelectableButtonsGrid
        activeButtonClickable
        values={floorplanToggleValues}
        selectedValue={value ? ToggleOptions.On : ToggleOptions.Off}
        onSelect={option => setValue(option === ToggleOptions.On)}
      />

    </Stack>
  )
}
