import 'translations/i18n'

import { applyMiddleware, compose, createStore } from 'redux'

import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { logger } from 'redux-logger'
import { rootReducer } from 'redux/RootReducer'
import { rootSaga } from 'redux/RootSaga'

/** Create root Store mockup used for storybook */
export const createStoreMockup = () => {
  const sagaMiddleware = createSagaMiddleware()
  const storeMockup = createStore(
    rootReducer,
    process.env.NODE_ENV === 'development' ? composeWithDevTools(
      applyMiddleware(sagaMiddleware, logger)
    ) : compose(
      applyMiddleware(sagaMiddleware)
    )
  )
  sagaMiddleware.run(rootSaga)
  return storeMockup
}