import ai_generation from 'assets/img/features/ai_generation.jpg'
import api from 'assets/img/features/api.jpg'
import editing_tool from 'assets/img/features/editing_tool.jpg'
import gallery_sharing from 'assets/img/features/gallery_sharing.jpg'
import team_dashboard from 'assets/img/features/team_dashboard.jpg'
import visual_asset from 'assets/img/features/visual_asset.jpg'

export const features = [
  {
    image: editing_tool,
    title: 'editing_tool',
    tag: true
  },
  {
    image: team_dashboard,
    title: 'team_dashboard',
    tag: false
  },
  {
    image: gallery_sharing,
    title: 'gallery_sharing',
    tag: false
  },
  {
    image: visual_asset,
    title: 'visual_asset',
    tag: false
  },
  {
    image: api,
    title: 'api',
    tag: false
  },
  {
    image: ai_generation,
    title: 'ai_generation',
    tag: true
  },
]
