import { FC, ReactNode, memo, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { MUITooltip } from 'components/common/MUITooltip'
import { Nullable } from 'models/helpers'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import { SliderBar } from 'components/common/SliderBar'
import { SliderMark } from '@mui/material'
import Stack from '@mui/material/Stack'
import { TFunction } from 'i18next'
import { usePurchaseFlowConfig } from 'components/pages/PurchaseFlow/_main/contexts'
import { usePurchaseFlowProducts } from 'components/pages/PurchaseFlow/_main/contexts/PurchaseFlowProducts.context'

/** Props for the  SegmentLabelProps component. */
interface SegmentLabelProps {
  /** The translation function for i18n. */
  t: TFunction
  /** The key for the segment translation. */
  segmentKey: Nullable<string>
}

/**
 * SegmentLabel is a memoized component for rendering a segment label with i18n translation.
 * 
 * @example
 * <SegmentLabel t={t} segmentKey="yourSegmentKey" />
 */
const SegmentLabel = memo<SegmentLabelProps>(({ t, segmentKey }) => {
  return (
    <Trans t={t} i18nKey={segmentKey ? `segment:${segmentKey}` : 'step_product.none_selected'}>
      <sup></sup>
    </Trans>
  )
})

/**
 * Custom SliderMark props for using the Tooltip component together with the Mark component. 
 * Not the official props as there are none yet, only using the needed props.
 */
interface SliderMarkProps {
  /** The index of the mark. */
  'data-index': number
  /** The state of the owner mark component, containing marks with optional labels. */
  ownerState: {
    marks: { label?: string }[]
  }
}

/**
 * TooltipMark represents a custom mark component on a slider with an associated tooltip.
 * It displays a tooltip with the label of the current mark.
 *
 * @example
 * <TooltipMark data-index={index} ownerState={ownerState} />
 */
const TooltipMark = (props: SliderMarkProps) => {
  const index = props['data-index'] as number
  const currentMark = props.ownerState.marks[index]
  const tooltipLabel = currentMark?.label || ''

  return (
    <SliderMark {...props}>
      <MUITooltip content={tooltipLabel}>
        <span style={{ visibility: 'hidden' }}>aa</span>
      </MUITooltip>
    </SliderMark>
  )
}

/** 
 * Empty span component to avoid displaying the label bellow the marks.
 * Allows the TooltipMark component to display the label inside the Tooltip.
 */
const GhostLabel = () => (
  <span></span>
)

/**
 * Displays filter section with slider selector for choosing which area size (segment) user wants products for.
 * 
 * @example
 * <ProductSegmentSlider onClick={() => alert("Well hello there!")} onChange={() => alert("General Kenobi!")} />
 */
export const ProductSegmentSlider: FC = () => {

  const { t } = useTranslation(['order', 'segment', 'segment_title'])
  const { selectedCategory, availableSegments } = usePurchaseFlowConfig()
  const { setSegment, selectedSegment } = usePurchaseFlowProducts()

  const availableMarks = useMemo(() => {
    const allMarks: { value: number, label: string | ReactNode }[] = []
    availableSegments.forEach((segment, index) => {
      allMarks.push({
        value: index,
        label: <SegmentLabel t={t} segmentKey={segment} />
      })
    })
    return allMarks
  }, [availableSegments, t])

  return (
    <SectionedBorderBox title={t(`segment_title:${selectedCategory}`)}>
      <Stack>
        <SliderBar
          min={-1} // -1 represents undefined segment as 'none selected'
          step={1}
          sx={{ marginTop: '2rem' }}
          max={availableSegments.length - 1}
          defaultValue={undefined}
          data-cy="product-segment-slider"
          valueLabelFormat={() =>
            <SegmentLabel
              t={t}
              segmentKey={selectedSegment}
            />
          }
          marks={availableMarks}
          slots={{
            mark: TooltipMark,
            markLabel: GhostLabel
          }}
          onChange={(_, index) => setSegment(availableSegments[index as number], index as number)}
        />
      </Stack>
    </SectionedBorderBox>
  )
}
