import { FC, ReactNode, useEffect } from 'react'

import { useDynamicFlowController } from './DynamicFlow.context'

interface StepProps {
  isValid: boolean
  stepKey: string
  children: ReactNode
}

/**
 * Wrapper for DynamicFlow that automatically handles validityMap changes based on provided *isValid* prop
 * Content is not rendered unless the current step corresponds to the key of the wrapper.
 * However the outside is rendered at all times (is rendered by DynamicFlowView) so the validity is always updated.
 * 
 * @example
 * <DynamicFlowStep
 *   isValid={<isStepValid - most likely computed value>}
 *   stepKey={'PRODUCT'}
 * >
 *   <ProductStep />
 * </DynamicFlowStep> 
 */
export const DynamicFlowStep: FC<StepProps> = ({ isValid, stepKey, children }) => {
  const flowCtrl = useDynamicFlowController()

  // Hook in to ctrl validity switching
  useEffect(() => {
    flowCtrl.setValidityMap((prev) => {
      const newMap = new Map(prev)
      newMap.set(stepKey, isValid)

      return newMap
    })

    // Omit flowCtrl dependency to prevent loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, stepKey])

  if (flowCtrl.currentNode?.key !== stepKey) return null

  return <>{children}</>
}
