import { Nullable } from 'models/helpers'
import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

/**
 * Custom hook extending basic useSearchParams of react-router
 * Easier straight-forward api, handles setting array values to params, no mutations
 * @returns Hook providing functions (get, set, getArray, delete)
 */
export const useBkbnSearchParams = () => {

  const [params, setParams] = useSearchParams()

  const _decodeParamArray = useCallback((value: Nullable<string>): string[] => !!value ? decodeURI(value).split(',') : [], [])
  const _encodeParamArray = useCallback((value: string[]): string => value.join(','), [])

  const setMany = useCallback((...props: [string, string | string[] | null | undefined][]) => {
    const newParams = new URLSearchParams(params)

    for (const [name, value] of props) {
      if (typeof value === 'string' && value !== '') newParams.set(name, value)
      else if (!value) newParams.delete(name)
      else newParams.set(name, _encodeParamArray(value))
    }

    setParams(newParams)
  }, [_encodeParamArray, params, setParams])


  const getParam = useCallback((name: string) => {
    return params.get(name)
  }, [params])

  const getArrayParam = useCallback((name: string) => {
    return _decodeParamArray(params.get(name))
  }, [params, _decodeParamArray])

  const deleteParam = useCallback((name: string) => {
    const newParams = new URLSearchParams(params)
    newParams.delete(name)
    setParams(newParams)
  }, [params, setParams])

  return {
    /**
     * Gets value of specified query parameter
     * @param name - name of parameter
     * @returns query parameter value (string)
     * @example get('Elon') => 'Tusk'
     */
    get: getParam,
    /**
     * Sets the new specified params, if param already exists, it gets overwritten
     * @param props - name-value tuples
     * @returns void
     * @example set(['foo', 'bar'], ['Elon', 'Tusk'])
     */
    set: setMany,
    /**
     * Gets decoded array value of specified query parameter.   
     * Used for parameters that have array-encoded values.
     * @param name - name of parameter
     * @returns query parameter value (string[])
     * @example getArray('Elon') => ['the Joke', 'Tusk']
     */
    getArray: getArrayParam,
    /**
    * Removes specified query parameter
    * @param name - name of parameter
    * @returns void
    * @example delete('Elon')
    */
    delete: deleteParam,
  }
}
