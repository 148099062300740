import { DebounceSettings, debounce } from 'lodash'
import { firebaseAnalytics, firebaseController, trackFirebaseAnalyticsUser } from './firebase'
import { googleAnalyticsController, trackGoogleAnalyticsUser } from './googleAnalytics'
import { intercomController, trackIntercomUser } from './intercom'
import { mixpanelController, trackMixpanelUser } from './mixpanel'

import { Auth0User } from 'models/auth'
import { PlatformUser } from 'models/user'
import { trackSentryUser } from './sentry'

export { AnalyticsEvent } from './AnalyticsEvent'

/** Track the user to all analytics services [firebase, firebase-analytics, google-analytics, mixpanel, sentry, intercom] */
export const trackUser = (user: Auth0User | PlatformUser) => {
  if (user && 'sub' in user) {
    trackFirebaseAnalyticsUser(user)
    trackGoogleAnalyticsUser(user)
    trackSentryUser(user)
  }

  trackMixpanelUser(user)
  trackIntercomUser(user)
}

/** Untrack the user */
export const unTrackUser = () => {

}

/** Logs an analytics event into analytics services  [firebase, firebase-analytics, google-analytics, mixpanel, intercom] */
export const logAnalyticsEvent = (name: string, body: object) => {
  if (firebaseController.analytics) firebaseAnalytics.logEvent(firebaseController.analytics, name, body)
  googleAnalyticsController.instance?.track(name, body)
  mixpanelController.instance?.track(name, body)
  intercomController.instance && intercomController.instance('trackEvent', name, body)
}

/** Higher order function generating debounced logAnalyticsEvent function based on parameters */
export const getDebouncedLogAnalyticsEvent = (debounceTimeout: number, options: DebounceSettings) => debounce(logAnalyticsEvent, debounceTimeout, options)

/**
 * Debounced logAnalyticsEvent function with default debounce parameters.   
 * *(debounceTimeout: 500ms, maxWait: 2000ms, leading: true)*   
 *    
 * Debounced function with custom debounce parameters can be generated by **getDebouncedAnalyticsEvent**
*/
export const standardDebouncedLogAnalyticsEvent = getDebouncedLogAnalyticsEvent(500, { maxWait: 2000, leading: true })
