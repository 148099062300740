import { SubscriptionPricingLink } from 'constants/staticLinks'

export const getSubscriptionPricingLink = (language: string) => {
  switch (language.toLowerCase()) {
    case 'fr':
      return SubscriptionPricingLink.FR
    case 'de':
      return SubscriptionPricingLink.DE
    case 'es':
      return SubscriptionPricingLink.ES
    case 'nl':
      return SubscriptionPricingLink.NL
    case 'en':
    default:
      return SubscriptionPricingLink.EN
  }
}
