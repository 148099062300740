import { FC, useCallback, useEffect, useMemo } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useUpsellPayment, useUpsellPurchaseConfiguration } from '../_main/contexts'

import { APIRequestState } from 'constants/API'
import { Path } from 'constants/router'
import { PreferredPaymentMethodEnum } from 'constants/user/preferredPaymentMethod'
import { ReceiptEmailInput } from 'components/common/ReceiptEmailInput'
import { SavedPaymentMethodsSelect } from 'components/common/SavedPaymentMethodsSelect'
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js'
import { productKindIsPreferredPaymentMethod } from 'utils/typeguards'
import styles from './StripePaymentWrapper.module.sass'
import { useSavedStripePaymentMethods } from 'components/contexts/SavedStripePaymentMethodsContext'
import { useTranslation } from 'react-i18next'

/**
 * @component Displays and handles Stripe's PaymentElement
 * @example <StripePaymentWrapperController />
 */
export const StripePaymentWrapperController: FC = () => {

  const { t } = useTranslation(['product', 'saved_payment_methods'])

  const stripe = useStripe()
  const elements = useElements()

  const { savedStripePaymentMethodsMap } = useSavedStripePaymentMethods()

  const {
    assignmentId,
    productId,
    productQuantity,
    beforeConfirmActionState
  } = useUpsellPurchaseConfiguration()

  const {
    paymentIntent,
    isStripeUsed,
    isStripeConfirmed,
    receiptEmail,
    isPaymentElementReady,
    saveStripePaymentMethod,
    selectedSavedPaymentMethod,
    selectedPaymentProduct,
    setIsPaymentElementReady,
    setIsPaymentElementValid,
    setStripeError,
    setIsStripeProcessing,
    setIsStripeConfirmed,
    setReceiptEmail,
    setIsReceiptEmailValid,
    setSaveStripePaymentMethod,
    setSelectedSavedPaymentMethod,
  } = useUpsellPayment()

  const savedPaymentMethods = useMemo(() => {
    if (!selectedPaymentProduct || !productKindIsPreferredPaymentMethod(selectedPaymentProduct.kind)) return []
    return savedStripePaymentMethodsMap[selectedPaymentProduct.kind] || []
  }, [savedStripePaymentMethodsMap, selectedPaymentProduct])

  const showReceiptInput = useMemo(() => {

    if (selectedSavedPaymentMethod === undefined) return false

    if (selectedSavedPaymentMethod === null) {
      if (!isPaymentElementReady) return false
      return true
    }

    if (selectedSavedPaymentMethod.type in PreferredPaymentMethodEnum) return true
    return false

  }, [isPaymentElementReady, selectedSavedPaymentMethod])

  /** Updates validity of paymentElement */
  const onElementsChange = useCallback((e: StripePaymentElementChangeEvent) => {
    if (e.complete) setIsPaymentElementValid(true)
    else setIsPaymentElementValid(false)
  }, [setIsPaymentElementValid])

  /**
   * @function getReturnUrl - Serializes and encodes return URL for stripe payment with provided params
   * @returns Formatted encoded URL string
   * @param params(URLSearchParams) to be appended to URL
   */
  const getReturnUrl = useCallback((params: URLSearchParams) => {
    return encodeURI(`${window.location.origin}${Path.PAYMENT_STATUS}?${params.toString()}`)
  }, [])

  const confirmStripePayment = useCallback(async () => {
    if (!paymentIntent?.clientSecret) throw new Error('Payment intent was not received')
    if (!stripe) throw new Error('Stripe was not initialized')
    if (!elements) throw new Error('Elements were not initialized')
    if (!assignmentId) throw new Error('Assignment id is not defined')
    if (!productId || !productQuantity) throw new Error('Purchase metadata have not been set')
    if (selectedSavedPaymentMethod === undefined) throw new Error('Payment method is not selected')

    setStripeError(null)
    setIsStripeProcessing(true)

    const reference = `${productQuantity}x ${t(`product:p_${productId}`)}`
    const urlParams = new URLSearchParams({
      assignmentId: assignmentId.toString(),
      reference,
    })

    // Set confirmed flag
    setIsStripeConfirmed(true)

    /** This will hold potential stripe error */
    let error

    const returnUrl = getReturnUrl(urlParams)

    // If no saved method is used, use general confirm
    // If stripe fails, push error to the error variable
    if (selectedSavedPaymentMethod === null) {
      error = (await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: returnUrl,
          receipt_email: receiptEmail,
          // whether stripe will save this payment method
          save_payment_method: saveStripePaymentMethod,
          payment_method_data: {
            billing_details: {
              email: receiptEmail,
            },
          },
        },
      })).error
    }

    // If saved card payment method is to be used, use specific card confirm
    // General confirm cannot accept saved payment method
    // If stripe fails, push error to the error variable
    if (selectedSavedPaymentMethod?.type === PreferredPaymentMethodEnum.CARD_PAYMENT) {
      error = (await stripe.confirmCardPayment(paymentIntent.clientSecret, {
        payment_method: selectedSavedPaymentMethod.stripeId,
        receipt_email: receiptEmail,
        return_url: returnUrl,
      })).error
    }

    // If saved SEPA payment method is to be used, use specific SEPA confirm
    // General confirm cannot accept saved payment method
    // If stripe fails, push error to the error variable
    if (selectedSavedPaymentMethod?.type === PreferredPaymentMethodEnum.SEPA_DIRECT_DEBIT) {
      error = (await stripe.confirmSepaDebitPayment(paymentIntent.clientSecret, {
        payment_method: selectedSavedPaymentMethod.stripeId,
        receipt_email: receiptEmail,
        return_url: returnUrl,
      })).error
    }

    // If stripe successfully finishes without redirect, redirect user manually to payment status page
    if (!error) {
      urlParams.append('payment_intent_client_secret', paymentIntent.clientSecret)
      window.location.replace(getReturnUrl(urlParams))
    }

    // If stripe fails, reset the process and set stripe error state
    if (error) {
      setStripeError(error)
      setIsStripeProcessing(false)
    }
  }, [paymentIntent, stripe, elements, assignmentId, productId, productQuantity, selectedSavedPaymentMethod, setStripeError, setIsStripeProcessing, t, setIsStripeConfirmed, getReturnUrl, receiptEmail, saveStripePaymentMethod])

  // Confirm stripe payment after order is placed
  // Only confirm once when stripe is used and after beforeConfirmAction is successful
  useEffect(() => {
    if (beforeConfirmActionState === APIRequestState.OK && isStripeUsed && !isStripeConfirmed) confirmStripePayment()
  }, [confirmStripePayment, beforeConfirmActionState, isStripeUsed, isStripeConfirmed])

  return (
    <div className={styles.stripePaymentWrapper}>

      {!!selectedPaymentProduct && productKindIsPreferredPaymentMethod(selectedPaymentProduct.kind) && savedPaymentMethods.length > 0 &&
        <SavedPaymentMethodsSelect
          className={styles.savedMethodsList}
          paymentMethodType={selectedPaymentProduct.kind}
          savedPaymentMethods={savedPaymentMethods}
          selectedPaymentMethod={selectedSavedPaymentMethod}
          onSelected={(method) => setSelectedSavedPaymentMethod(method)}
        />
      }

      {showReceiptInput &&
        <ReceiptEmailInput
          className={styles.receiptEmail}
          isRequired={true}
          onValidityChange={(isValid) => setIsReceiptEmailValid(isValid)}
          onValueChange={(value) => setReceiptEmail(value)}
        />
      }

      {selectedSavedPaymentMethod === null &&
        <PaymentElement
          onReady={() => setIsPaymentElementReady(true)}
          onChange={(e) => onElementsChange(e)}
          options={{
            terms: {
              card: 'never',
              sepaDebit: 'never',
            },
            fields: {
              billingDetails: {
                email: 'never',
              },
            },
          }}
        />
      }

      {selectedSavedPaymentMethod === null && isPaymentElementReady &&
        <label className={`checkbox square ${styles.saveMethodCheckbox}`} htmlFor="save-payment-method-checkbox">
          <input
            type="checkbox"
            name="save-payment-method-checkbox"
            id="save-payment-method-checkbox"
            checked={saveStripePaymentMethod}
            onChange={e => setSaveStripePaymentMethod(e.target.checked)}
          />
          <span className="checkmark"></span>
          <span className={`label-after ${styles.label}`}>
            {t('saved_payment_methods:save')}
          </span>
        </label>
      }

    </div>
  )
}
