/** Enum of all available countries */
export enum Country {
  /** Switzerland */
  CH = 'CH',

  /** Germany */
  DE = 'DE',

  /** Austria */
  AT = 'AT',

  /** France */
  FR = 'FR',

  /** Luxembourg */
  LU = 'LU',

  /** United Kingdom (ISO compliant) */
  GB = 'GB',

  /** Croatia */
  HR = 'HR',

  /** Spain */
  ES = 'ES',

  /** Belgium */
  BE = 'BE',

  /** Netherlands */
  NL = 'NL',
}