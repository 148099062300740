import { DocumentCategory, EditingCategory, ShootingCategory } from 'constants/product'

import { ProductCategory } from 'models/product'

/** Evaluates if category belongs in ShootingCategory enum and returns a boolean value */
export const isShootingCategory = (category: ProductCategory) => {
  return !!(Object.values(ShootingCategory).includes(category as ShootingCategory))
}

/** Evaluates if category belongs in EditingCategory enum and returns a boolean value */
export const isEditingCategory = (category: ProductCategory) => {
  return !!(Object.values(EditingCategory).includes(category as EditingCategory))
}

/** Evaluates if category belongs in DocumentCategory enum and returns a boolean value */
export const isDocumentCategory = (category: ProductCategory) => {
  return !!(Object.values(DocumentCategory).includes(category as DocumentCategory))
}
