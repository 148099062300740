import { FC, ReactNode } from 'react'

import { RoomAPIContextProvider } from 'components/contexts/RoomAPI.context'
import { useGalleryStagingVisualSelection } from './GalleryStagingVisualSelection.context'

/**
 * RoomAPI provider with specified imageUrlResolver made for staging in gallery
 * 
 * @example
 * <GalleryStagingRoomAPIProvider>
 *   <Consumer />
 * </GalleryStagingRoomAPIProvider>
 */
export const GalleryStagingRoomAPIProvider: FC<{
  children?: ReactNode
}> = ({
  children
}) => {

    const { selectedVisuals } = useGalleryStagingVisualSelection()

    const imageUrlResolver = (visualId: string) => {
      const visual = selectedVisuals[visualId]

      return visual?.url
    }

    return (
      <RoomAPIContextProvider imageUrlResolver={imageUrlResolver}>
        {children}
      </RoomAPIContextProvider>
    )
  }
