import { FC, ReactNode } from 'react'

import styles from './TimelineEvent.module.sass'

/**
 * @interface Props Input properties
 */
interface Props {
  /** The additional classes to append */
  className?: string
  /** Date of the event */
  dateString: string
  /** Time of the event */
  timeString: string
  children?: ReactNode
}

/**
 * @component Timeline event used for each point on the timeline
 * @example
 * <TimelineEvent dateString='Thursday March 10, 2022' timeString='9h00'>
 * Some event
 * </TimelineEvent>
 */
export const TimelineEvent: FC<Props> = ({
  className = '',
  dateString,
  timeString,
  children,
}) => {

  return (
    <li className={`${className} ${styles.eventWrapper}`}>
      <span className={styles.eventDate}>{dateString} {timeString}</span>
      <span className={styles.eventText}>
        {children}
      </span>
    </li>
  )
}
