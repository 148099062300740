import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'
import { ActionUserMe } from './userMe.actions'
import { UserMeStore } from './userMe.interfaces'

/** Redux data reducer for user me API actions */
export const reduceUserMeAPI = (state: UserMeStore, action: ActionUserMe) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.USER_ME) return state

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
      return new APIRequest(APIRequestState.BEFORE_START)
    case ActionTypeAPIEvent.FETCH:
      return new APIRequest(APIRequestState.RUNNING)
    case ActionTypeAPIEvent.RECEIVED:
      return action.payload.request
    default:
      return state
  }
}

/** Redux data reducer for offline silent update of userMe store data */
export const reduceUpdateUserMeStoreData = (state: UserMeStore, action: ActionUserMe) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.UPDATE_USER_ME_STORE_DATA) return state

  if (eventType === ActionTypeAPIEvent.RECEIVED) return {
    ...state,
    data: {
      ...state.data,
      ...action.payload.request.data,
    }
  }

  return state
}

/** Unified reducer for all userMe operations */
export const reduceUserMe = (state: UserMeStore, action: ActionUserMe) => {
  const [, dataType] = action.type

  if (dataType === ActionTypeAPIData.USER_ME) return reduceUserMeAPI(state, action)
  if (dataType === ActionTypeAPIData.UPDATE_USER_ME_STORE_DATA) return reduceUpdateUserMeStoreData(state, action)

  return state
}
