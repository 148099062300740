import './LoadingPage.sass'

import { Color } from 'constants/assets'
import { PageTransition } from 'utils/animations'
import React from 'react'
import ReactLoading from 'react-loading'

const LoadingPage: React.FC = (props: any) => {
  return (
    <PageTransition>
      <div className="page loading-page">
        <div className="page-content">
          <ReactLoading color={Color.GRAY_TEXT} type="cylon" className="spinner loading infinite" />
        </div>
      </div>
    </PageTransition>
  )
}

export default LoadingPage
