export enum NodeName {
  DIV = 'DIV',
  BUTTON = 'BUTTON',
  A = 'A'
}

export const clickPreventionList = new Set<string>([
  NodeName.BUTTON,
  NodeName.A,
])
