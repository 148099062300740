import { ArchiveVisualsTag, archiveVisuals } from 'redux/Individual/Visual/ArchiveVisuals'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { APIRequestState } from 'constants/API'
import { ActionTypeAPIData } from 'constants/redux'
import { AnalyticsEvent } from 'utils/analytics'
import { PIPEDRIVE_INFINITY } from 'constants/pipedrive'
import { RootStore } from 'models/redux'
import { VisualFileType } from 'constants/visual'
import constate from 'constate'
import { selectVisuals } from 'redux/Individual/Visual/SelectVisuals'
import { useActionPopup } from 'utils/hooks'
import { useAuth0 } from 'utils/auth'
import { useGalleryAdditionalVisuals } from './GalleryAdditionalVisuals.context'
import { useGalleryAssignment } from './GalleryAssignment.context'
import { useGalleryDeal } from './GalleryDeal.context'
import { useGalleryVisualSelection } from './GalleryVisualSelection.context'
import { useGalleryVisualType } from './GalleryVisualType.context'
import { useGalleryVisualsMeta } from './GalleryVisualsMeta.context'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

export const [GalleryVisualsDownloadArchiveContextProvider, useGalleryVisualsDownloadArchive] = constate(({
  assignmentId,
}: {
  assignmentId: string
}) => {
  const dispatch = useDispatch()
  const { showConfirm } = useActionPopup()
  const { t } = useTranslation(['gallery'])
  const { roles } = useAuth0()
  const {
    nonPersonalUserWorkspaces
  } = useUserData()
  const {
    logGalleryEvent,
    product
  } = useGalleryAssignment()
  const { dealData } = useGalleryDeal()
  const {
    allVisualsFilenames,
    purchasedVisualsExist,
    selectMaxValue,
  } = useGalleryVisualsMeta()
  const {
    selected,
  } = useGalleryVisualSelection()
  const {
    isAdditionalVisualPurchaseDisabled,
    selectedAdditionalVisualsCount,
    setIsAdditionalVisualsPurchaseModalOpen
  } = useGalleryAdditionalVisuals()
  const {
    normalizedOriginalType,
    transformTypes,
  } = useGalleryVisualType()

  const [isDownloadModalOpen, setDownloadModalOpen] = useState(false)

  const initiateDownloadVisualsArchive = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.ARCHIVE_VISUALS]?.[assignmentId]?.[ArchiveVisualsTag.CLIENT_ARCHIVE])
  const visualsArchiveDownloadJobId = useMemo(() => (initiateDownloadVisualsArchive && initiateDownloadVisualsArchive.data?.id) || '', [initiateDownloadVisualsArchive])
  const downloadVisualsArchive = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.DOWNLOAD_ARCHIVE]?.[visualsArchiveDownloadJobId])
  const archiveAdmin = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.ARCHIVE_VISUALS]?.[assignmentId]?.[ArchiveVisualsTag.ADMIN_ARCHIVE])
  const visualsArchiveAdminDownloadJobId = useMemo(() => (archiveAdmin && archiveAdmin.data?.id) || '', [archiveAdmin])
  const downloadVisualsArchiveAdmin = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.DOWNLOAD_ARCHIVE]?.[visualsArchiveAdminDownloadJobId])

  const isArchiveDisabled = useMemo(() => {
    if (initiateDownloadVisualsArchive && initiateDownloadVisualsArchive.state === APIRequestState.RUNNING) return true
    if (!product) return true
    if (purchasedVisualsExist) return false
    if (selected.size === 0) return true
    if (selectMaxValue !== PIPEDRIVE_INFINITY) {
      if (selected.size < selectMaxValue) return true
      if (selected.size > selectMaxValue && isAdditionalVisualPurchaseDisabled) return true
    }
    return false
  }, [selected, selectMaxValue, initiateDownloadVisualsArchive, product, purchasedVisualsExist, isAdditionalVisualPurchaseDisabled])

  const typesToDownload = useMemo(() => {
    const types = transformTypes()
    if (product?.type === 'VIDEO' || product?.type === 'FLOOR_PLAN') {
      return [VisualFileType.POST]
    } else {
      return types
    }
  }, [transformTypes, product])

  const initiateArchiveDownload = useCallback(async () => {
    if (isArchiveDisabled) return
    if (!product) return

    // If there is limit for downloading/selecting visuals, proceed with additional checks
    if (selectMaxValue !== PIPEDRIVE_INFINITY) {

      // If additional visuals are selected, open purchase modal
      if (selectedAdditionalVisualsCount > 0) {
        setIsAdditionalVisualsPurchaseModalOpen(true)
        return
      }

      // If no additional visuals are selected and no visuals have been purchased yet
      // check, that required amount have been selected, show warning prompt and select those visuals with immediate download after selection completes
      if (!purchasedVisualsExist) {
        if (selected.size !== selectMaxValue || !(await showConfirm(t('purchase_warning_prompt'), { title: t('purchase_warning_prompt_title') }))) return
        dispatch(selectVisuals(assignmentId, typesToDownload, Array.from(selected), normalizedOriginalType, product.id, undefined, undefined, true))
        // Open visual download popup
        setDownloadModalOpen(true)
        return
      }
    }

    // If there is no limit for downloading/selecting visuals, but no visuals have been purchased, select all visuals and download those selected
    // Currently client still can select any number of visuals but here we will automatically select all for client.
    if (!purchasedVisualsExist) {
      if (!allVisualsFilenames.length) console.warn('No visual filenames found!')
      dispatch(selectVisuals(assignmentId, typesToDownload, allVisualsFilenames, normalizedOriginalType, product.id, undefined, undefined, true, Array.from(selected)))
      // Open visual download popup
      setDownloadModalOpen(true)
      return
    }

    // If there is no limit for downloading/selecting visuals
    // OR no additional visuals are selected and visuals have already been purchased
    // Trigger downloading of selected visuals
    dispatch(archiveVisuals(assignmentId, typesToDownload, Array.from(selected), ArchiveVisualsTag.CLIENT_ARCHIVE, false))

    // Open visual download popup
    setDownloadModalOpen(true)

    // Track archive download trigger
    logGalleryEvent(AnalyticsEvent.GALLERY_DOWNLOAD_TRIGGERED, {
      role: Array.from(roles.all.values()).join(','),
      workspaceIDs: nonPersonalUserWorkspaces.length ? nonPersonalUserWorkspaces.map(({ id }) => id).join(', ') : '',
      productCategory: product.category,
      productType: product.type,
      productKind: product.kind,
      productSegments: product.segments.join(','),
      assignmentOwnerId: dealData?.customer.id,
      assignmentOwnerEmail: dealData?.customer.email,
    })

  }, [isArchiveDisabled, product, selectMaxValue, purchasedVisualsExist, dispatch, assignmentId, typesToDownload, selected, logGalleryEvent, roles.all, nonPersonalUserWorkspaces, dealData?.customer.id, dealData?.customer.email, selectedAdditionalVisualsCount, setIsAdditionalVisualsPurchaseModalOpen, showConfirm, t, normalizedOriginalType, allVisualsFilenames])

  return {
    assignmentId,
    isArchiveDisabled,
    isDownloadModalOpen,
    setDownloadModalOpen,
    initiateDownloadVisualsArchive,
    downloadVisualsArchive,
    archiveAdmin,
    downloadVisualsArchiveAdmin,
    initiateArchiveDownload
  }
})
