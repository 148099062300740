import { Link, useNavigate } from 'react-router-dom'
import { getContentLibraryLink, getHelpCenterLink } from 'utils/localization'

import { AnalyticsEvent } from 'utils/analytics'
import Button from 'components/common/Button/Button'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { Fade } from '@mui/material'
import { Fragment } from 'react'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { MUIBadge } from 'components/common/MUIBadge'
import { MUIDivider } from 'components/common/MUIDivider'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import { Path } from 'constants/router'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import i18n from 'translations/i18n'
import { useAppMeta } from 'components/higher-order/App/appMeta.context'
import { useAuth0 } from 'utils/auth'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @interface Props for the TopBarDropdownMobile component.
 */
export interface Props {
  /** Invitation workspace name property */
  invitationWorkspaceName?: string
  /** Whether the workspace name is showed or not */
  isShowWorkspaceName: boolean,
  /** Whether the menu is open or not */
  showMenu: boolean,
  /** Function to set the language open */
  setIsLanguageOpen: () => void
  /** Function to set the contact us open */
  setIsContactUsOpen: () => void
  /** Function to close the menu */
  closeMenuHandler: (e: React.MouseEvent<Element, MouseEvent>) => void
  /** Function to handle the logout */
  handleLogout: (e: React.MouseEvent<Element, MouseEvent>) => void
  /** Function to handle the analytics event */
  handleLogAnalyticsEvent: (event: AnalyticsEvent, options?: {}) => void
}

/**
 * @component TopBarDropdownMobile displayed TopBar
 * @example <TopBarDropdownMobile />
 */
export const TopBarDropdownMobile: React.FC<Props> = ({
  showMenu,
  isShowWorkspaceName,
  invitationWorkspaceName,
  setIsLanguageOpen,
  setIsContactUsOpen,
  closeMenuHandler,
  handleLogout,
  handleLogAnalyticsEvent
}) => {
  const { t } = useTranslation('topbar')
  const navigate = useNavigate()
  const { isUserSet } = useAppMeta()
  const {
    isAuthenticated,
    loading,
    user,
    roles
  } = useAuth0()
  const {
    activeSubscribedUserWorkspaces,
    hasUserActiveSubscription,
    currentUserWorkspace,
    clientData
  } = useUserData()

  return (
    <Fragment>
      {showMenu &&
        <Fade in={showMenu} easing="easing">

          <div className="menu-wrap">

            <div id='mobile-main-menu' className="mobile-main-menu">

              {!loading &&
                <div className='menu-list'>

                  {isAuthenticated && user &&
                    <Fragment>

                      {isShowWorkspaceName &&
                        <Fragment>

                          <div className='section'>
                            <Link
                              to={Path.WORKSPACES_PROFILE.replace(':id', currentUserWorkspace?.id ?? '')}
                              onClick={(e) => {
                                handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU_MY_PLAN, { planId: currentUserWorkspace?.id })
                                closeMenuHandler(e)
                              }}
                            >
                              <div className='workspace-name'>{currentUserWorkspace?.name}</div>
                            </Link>
                          </div>

                          <MUIDivider margin={16} />

                        </Fragment>
                      }

                      <div className='section'>

                        {isUserSet &&
                          <Link
                            to={Path.INDEX}
                            onClick={(e) => {
                              handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU_DASHBOARD)
                              closeMenuHandler(e)
                            }}
                          >
                            <HomeOutlinedIcon className='icon' />
                            {t('dashboard')}
                          </Link>
                        }

                        <Button
                          type="secondary nobackground noborder"
                          className="button language-setting"
                          textAndIcon
                          onClick={() => {
                            handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU_LANGUAGE)
                            setIsLanguageOpen()
                          }}
                        >
                          <ChatOutlinedIcon className='icon' />
                          {t('language_settings')}
                        </Button>

                        {roles.isClient && hasUserActiveSubscription && !!activeSubscribedUserWorkspaces &&
                          <Link
                            to={Path.WORKSPACES_PROFILE.replace(':id', activeSubscribedUserWorkspaces[0].id)}
                            onClick={(e) => {
                              handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU_MY_PLAN, { planId: activeSubscribedUserWorkspaces[0].id })
                              closeMenuHandler(e)
                            }}
                          >
                            <CreditCardOutlinedIcon className='icon' />
                            {t('workspace_profile')}
                            {!!invitationWorkspaceName && <MUIBadge color="coral" size="sm" label={t('pending')} />}
                          </Link>
                        }

                        {roles.isClient && !hasUserActiveSubscription &&
                          <Link
                            to={Path.PRICING}
                            onClick={(e) => {
                              handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU_LOCKED_PLAN_UPSELL)
                              closeMenuHandler(e)
                            }}
                          >
                            <LockOpenOutlinedIcon className='icon' />
                            {t('unlock_workspaces')}
                          </Link>
                        }

                        {roles.isCreative &&
                          <a href={getContentLibraryLink(i18n.language)} rel="nofollow noopener noreferrer" target="_blank">
                            <LockOpenOutlinedIcon className='icon' />
                            {t('content_library')}
                            <OpenInNewOutlinedIcon className='icon external-link' />
                          </a>
                        }

                        {isUserSet &&
                          <Link
                            to={Path.PROFILE}
                            onClick={(e) => {
                              handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU_PROFILE)
                              closeMenuHandler(e)
                            }}
                          >
                            <PersonOutlineIcon className='icon' />
                            {t('profile')}
                          </Link>
                        }

                      </div>

                      <MUIDivider margin={16} />

                      {roles.isAdmin &&
                        <Fragment>

                          <div className='section light'>

                            <Link to="/API" onClick={closeMenuHandler}>
                              <DataObjectOutlinedIcon className='icon' />
                              API
                            </Link>

                            <Link to={Path.ADMIN_CLIENT_ONBOARDING} onClick={closeMenuHandler}>
                              <PersonAddAltOutlinedIcon className='icon' />
                              Client onboarding
                            </Link>

                            <Link to={Path.ADMIN_CREATIVE_ONBOARDING} onClick={closeMenuHandler}>
                              <CameraAltOutlinedIcon className='icon' />
                              Creative onboarding
                            </Link>

                          </div>

                          <MUIDivider margin={16} />

                          <div className='section light'>
                            <a href={getHelpCenterLink(i18n.language)} rel="nofollow noopener noreferrer" target="_blank">
                              <HelpOutlineOutlinedIcon className='icon' />
                              {t('help_center')}
                              <OpenInNewOutlinedIcon className='icon external-link' />
                            </a>
                          </div>

                        </Fragment>
                      }

                      {!roles.isAdmin &&
                        <Fragment>
                          <div className='section light'>

                            {isUserSet &&
                              <Fragment>

                                <a
                                  href={getHelpCenterLink(i18n.language)}
                                  rel="nofollow noopener noreferrer"
                                  target="_blank"
                                  onClick={() =>
                                    handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU_HELP_CENTER)
                                  }
                                >
                                  <HelpOutlineOutlinedIcon className='icon' />
                                  {t('help_center')}
                                  <OpenInNewOutlinedIcon className='icon external-link' />
                                </a>

                                <Button
                                  type="secondary nobackground noborder"
                                  className="button contact-us"
                                  textAndIcon
                                  onClick={() => {
                                    handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU_CONTACT_US)
                                    setIsContactUsOpen()
                                  }}
                                >
                                  <LocalPhoneOutlinedIcon className='icon' />
                                  {t('contact_us')}
                                </Button>

                              </Fragment>
                            }

                            <Link
                              to={Path.TERMS_AND_PRIVACY}
                              onClick={(e) => {
                                handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU_TERMS_AND_POLICY)
                                closeMenuHandler(e)
                              }}
                            >
                              <DescriptionOutlinedIcon className='icon' />
                              {t('terms_and_privacy')}
                            </Link>

                          </div>
                        </Fragment>
                      }

                      <div className='footer'>

                        {(roles.isClient || roles.isAdmin) &&
                          <Button
                            type="primary"
                            className='menu-order-visuals-button'
                            onClick={(e) => {
                              handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU_ORDER_NEW_VISUALS)
                              navigate(Path.PURCHASE_FLOW)
                              closeMenuHandler(e)
                            }}
                          >
                            {t('dashboard_client:call_to_action_order.button')}
                          </Button>
                        }

                        <MUIDivider margin={16} />

                        <div className='section light user-block'>

                          <Link
                            to={Path.PROFILE}
                            onClick={(e) => {
                              handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU_USER_PROFILE)
                              closeMenuHandler(e)
                            }}
                          >
                            <div className='user'>
                              <div className='name'>{clientData?.name ?? user.name}</div>
                              <div className='email'>{user.email}</div>
                            </div>
                          </Link>

                          <a
                            href="#logout"
                            onClick={(e) => {
                              handleLogAnalyticsEvent(AnalyticsEvent.CLICK_MAIN_MENU_LOG_OUT)
                              handleLogout(e)
                            }}>
                            <LogoutOutlinedIcon className='icon' />
                            {t('authentication:log_out')}
                          </a>

                        </div>

                      </div>

                    </Fragment>
                  }
                </div>
              }
            </div>
          </div>
        </Fade>
      }
    </Fragment>
  )
}
