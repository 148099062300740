import { FC, Fragment, ReactNode } from 'react'

import { MUICheckbox } from 'components/common/MUICheckBox'
import { MUIExpandableCard } from 'components/common/MUICards/MUIExpandableCard'
import { MUIRadio } from 'components/common/MUIRadio'
import Stack from '@mui/material/Stack'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Optional content to display as expandable when checkbox checked */
  expandableContent?: ReactNode
  /** Checkbox input name */
  inputName?: string
  /** Checkbox input ID */
  inputID?: string
  /** Checkbox input value */
  inputValue?: string
  /** Whether the checkbox is radio instead */
  isRadio?: boolean
  /** Whether the card should be highlighted */
  highlighted?: boolean
  /** OnCheck action to be called */
  onCheck?: () => void
  /** Whether any sort of checkbox/radio is displayed */
  showSelectElement?: boolean
  /** Whether the checkbox should be checked */
  checked?: boolean
  /** Whether the checkbox should be checked by default */
  defaultChecked?: boolean
  /** Disables checkbox selection and applies styling */
  disabled?: boolean
  /** Disables checkbox selection without applying styling */
  disabledSelection?: boolean
  /** Whether click inside the element triggers the checkbox */
  isHeaderClickable?: boolean
  /** Optional children rendered in the header of the card */
  children?: ReactNode
  /** Optional content, that will be rendered as a footer of a card, displayed even when card is collapsed (used mainly for upsell marketing on disabled cards) */
  alwaysOnFooterContent?: ReactNode
}

/**
 * @component
 * MUICheckCard represents a card with an optional checkbox or radio button.
 *
 * @example
 * <MUICheckCard
 *   expandableContent={<div>Additional content when expanded</div>}
 *   isRadio={true}
 *   inputName="dwightCheckbox"
 *   inputID="dwightCheckbox"
 *   inputValue="dwightValue"
 *   onCheck={(checked) => console.log(`Dwight checked: ${checked}`)}
 * >
 *   <div>Card content goes here</div>
 * </MUICheckCard>
 */
export const MUICheckCard: FC<Props> = ({
  expandableContent,
  inputName,
  inputID,
  inputValue,
  isRadio = false,
  highlighted = false,
  onCheck,
  checked,
  defaultChecked,
  disabled = false,
  disabledSelection = false,
  isHeaderClickable = false,
  showSelectElement = true,
  children,
  alwaysOnFooterContent,
}) => {

  return (
    <MUIExpandableCard
      isExpanded={checked || defaultChecked || false}
      isDisabled={disabled}
      isHighlighted={highlighted}
      expandableContent={expandableContent}
      onHeaderClick={() => onCheck?.()}
      isHeaderClickable={!disabledSelection && isHeaderClickable}
      alwaysOnFooterContent={alwaysOnFooterContent}
    >
      <Stack direction="row" gap={2} sx={{ opacity: disabled ? 0.5 : 1 }}>

        {showSelectElement &&
          <Fragment>
            {isRadio
              ? (
                <MUIRadio
                  checked={checked || defaultChecked || false}
                  disabled={disabled || disabledSelection}
                  onChange={(e) => e.preventDefault()}
                />
              )
              : (
                <MUICheckbox
                  name={inputName}
                  id={inputID}
                  value={inputValue}
                  checked={checked}
                  onChange={(e) => e.preventDefault()}
                  disabled={disabled || disabledSelection}
                />
              )
            }
          </Fragment>
        }

        <Stack width="100%">
          {children}
        </Stack>

      </Stack>
    </MUIExpandableCard>
  )
}
