import { ReactComponent as icon_at } from 'flag-icons/flags/4x3/at.svg'
import { ReactComponent as icon_be } from 'flag-icons/flags/4x3/be.svg'
import { ReactComponent as icon_ch } from 'flag-icons/flags/4x3/ch.svg'
import { ReactComponent as icon_de } from 'flag-icons/flags/4x3/de.svg'
import { ReactComponent as icon_es } from 'flag-icons/flags/4x3/es.svg'
import { ReactComponent as icon_fr } from 'flag-icons/flags/4x3/fr.svg'
import { ReactComponent as icon_gb } from 'flag-icons/flags/4x3/gb.svg'
import { ReactComponent as icon_hr } from 'flag-icons/flags/4x3/hr.svg'
import { ReactComponent as icon_lu } from 'flag-icons/flags/4x3/lu.svg'
import { ReactComponent as icon_nl } from 'flag-icons/flags/4x3/nl.svg'
import { Country } from '../country'

export const ch = icon_ch
export const de = icon_de
export const at = icon_at
export const fr = icon_fr
export const lu = icon_lu
export const gb = icon_gb
export const hr = icon_hr
export const es = icon_es
export const be = icon_be
export const nl = icon_nl

/** Object containing all used flags */
export const Flags = {
  /** Switzerland */
  CH: ch,

  /** Germany */
  DE: de,

  /** Austria */
  AT: at,

  /** France */
  FR: fr,

  /** Luxembourg */
  LU: lu,

  /** United Kingdom (ISO compliant) */
  GB: gb,

  /** Croatia */
  HR: hr,

  /** Spain */
  ES: es,

  /** Belgium */
  BE: be,

  /** Netherlands */
  NL: nl,
}

/** Dictionary of countries to flags */
export const countryToFlag = {
  [Country.CH]: ch,
  [Country.DE]: de,
  [Country.AT]: at,
  [Country.FR]: fr,
  [Country.LU]: lu,
  [Country.GB]: gb,
  [Country.HR]: hr,
  [Country.ES]: es,
  [Country.BE]: be,
  [Country.NL]: nl,
}
