import { Endpoint, RoleMimetype } from 'constants/API'

import API from 'utils/API/API'
import { ActionLoadVisual } from '../LoadVisual'
import { auth0ClientModule } from 'utils/auth'
import axios from 'axios'
import { downloadVisualWorkerType } from 'workers/downloadVisualWorker'
import { put } from 'redux-saga/effects'
import { wrap } from 'comlink'

const awaitedErrorCodes = new Set([404, 503])

// Initiates workers for visual downloading
const initWorkers = () => {
  const workerCount = process.env.NODE_ENV === 'test'
    ? 0
    : navigator.hardwareConcurrency || 4

  const workerPool = [...Array(workerCount).keys()]
    .map(() => {
      const worker = new Worker(
        new URL('workers/downloadVisualWorker', import.meta.url),
        {
          type: 'module',
        }
      )
      return wrap<downloadVisualWorkerType>(worker)
    })

  let i = 0

  /** Calls downloadVisualWorker */
  return (action: ActionLoadVisual, token: string, mimetype: RoleMimetype) => {
    const result = workerPool[i](action, token, mimetype)
    i = ++i % workerCount
    return result
  }
}

/** Calls downloadVisualWorker */
const getWorkDone = initWorkers()

const runWorker = async (action: ActionLoadVisual) => {
  if (process.env.NODE_ENV === 'test') return
  const token = await auth0ClientModule.getTokenSilently()
  const mimetype = await API.getRoleMimeType()
  const result = await getWorkDone(action, token, mimetype)
  return result
}

export function* downloadVisualSaga(receivedAction: ActionLoadVisual) {
  const action: ActionLoadVisual = yield runWorker(receivedAction)
  const { error } = action.payload.request
  if (error && axios.isAxiosError(error)) {
    API.logError(error, {
      endpoint: Endpoint.VISUAL_DOWNLOAD_URL,
      ignoreErrorFactory: error => awaitedErrorCodes.has(error.response?.status || -1)
    })
  }
  yield put(action)
}
