import { FC, ReactNode } from 'react'

import styles from './Timeline.module.sass'

/**
 * @interface Props Input properties
 */
interface Props {
  /** The additional classes to append */
  className?: string
  children?: ReactNode
}

/**
 * @component Vertical event timeline
 * @example
 * <Timeline>
 *  <TimelineEvent dateString='Thursday March 10, 2022' timeString='9h00'>
 *    First event comes here
 *  </TimelineEvent>
 *  <TimelineEvent dateString='Friday March 11, 2022' timeString='12h00'>
*    Second event comes here
 *  </TimelineEvent>
 * </Timeline>
 */
export const Timeline: FC<Props> = ({
  className = '',
  children,
}) => {
  return (
    <section className={`${className} ${styles.timeline}`}>
      <ul>
        {children}
      </ul>
    </section>
  )
}
