import { ProductKind } from './productTypes'
import { VirtualFurniturePropertyType } from 'constants/virtualFurniture'

export const STAGING_PRODUCT_KINDS = new Set([ProductKind.RESIDENTIAL_STAGING, ProductKind.COMMERCIAL_STAGING])

export const STAGING_PRODUCT_KINDS_PROPERTY_TYPES: {
  [key in ProductKind]?: VirtualFurniturePropertyType
} = {
  [ProductKind.RESIDENTIAL_STAGING]: VirtualFurniturePropertyType.STANDARD_CATALOG,
  [ProductKind.COMMERCIAL_STAGING]: VirtualFurniturePropertyType.COMMERCIAL,
}

export const PRODUCTS_WITH_OPTIONAL_STAGING = new Set([ProductKind.GROUND_PHOTO, ProductKind.GROUND_PHOTO_PRESTIGE])
