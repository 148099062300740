import { BadgeColor, MUIBadge } from 'components/common/MUIBadge'
import { FC, ReactNode, useMemo } from 'react'
import { InstructionType, chooseOneInstructionTypeKeys } from 'constants/purchaseFlow'
import { Trans, useTranslation } from 'react-i18next'

import { InstructionOptionDTO } from 'models/purchaseFlow'
import { MUICheckCard } from 'components/common/MUICards'
import i18n from 'translations/i18n'
import styles from './BaseInstructionCard.module.sass'

interface InstructionBadge {
  color: BadgeColor,
  text: string,
  materialIcon?: JSX.Element
}

export interface BaseInstructionCardPassDownProps {
  isCardPreselected?: boolean
  isSelectionDisabled: boolean
  isCardDisabled: boolean
  instructionOption: InstructionOptionDTO
  instructionType: InstructionType
}

interface Props extends BaseInstructionCardPassDownProps {
  isChecked: boolean
  children?: ReactNode
  rightHeaderContent?: ReactNode
  additionalBottomBoxContent?: ReactNode
  badges?: (InstructionBadge | undefined)[]
  title?: string
  onCheck: () => void
}

/**
 * Basic card acting as a building block for various instruction cards.
 * Takes care of layout and basic info, like title, description and disclaimer.
 * 
 * @example
 * <BaseInstructionCard
 *  isCardDisabled={false}
 *  isSelectionDisabled={false}
 *  instructionOption={instructionOptionDTO}
 *  instructionType={InstructionType.BILLING_PRIMARY}
 *  rightHeaderContent={<InstructionPrice />}
 *  onCange={(changed) => console.log}
 * >
 *  { expandableContent }
 * </BaseInstructionCard>
 */
export const BaseInstructionCard: FC<Props> = ({
  children,
  rightHeaderContent,
  additionalBottomBoxContent,
  isSelectionDisabled,
  instructionOption,
  isCardDisabled = false,
  instructionType,
  badges = [],
  title,
  isChecked,
  onCheck,
}) => {

  const { t } = useTranslation(['product', 'product_kind_description', 'product_kind_disclaimer'])

  const defaultTitle = useMemo(() => title ?? t(`product:p_${instructionOption.id}`), [instructionOption.id, t, title])

  return (
    <div className="animation-wrap">
      <div className={styles.instructionCardWrap}>
        <MUICheckCard
          data-cy={`instruction-card-${instructionOption.id}`}
          isRadio={chooseOneInstructionTypeKeys.has(instructionType)}
          checked={isChecked}
          onCheck={isSelectionDisabled
            ? () => { }
            : onCheck
          }
          isHeaderClickable={!isSelectionDisabled}
          highlighted={isChecked}
          disabledSelection={isSelectionDisabled}
          showSelectElement={!isSelectionDisabled}
          expandableContent={children ? children : undefined}
          alwaysOnFooterContent={additionalBottomBoxContent}
        >
          <div className={`${styles.header} ${isCardDisabled ? styles.disabled : ''}`}>

            <div className={styles.content}>

              <strong className={styles.title}>
                {defaultTitle}
              </strong>

              {i18n.exists(`product_kind_description:${instructionOption.kind}`) &&
                <p>
                  <Trans t={t} i18nKey={`product_kind_description:${instructionOption.kind}`}>
                    <span className={styles.newline}></span>
                  </Trans>
                </p>
              }

              {i18n.exists(`product_kind_disclaimer:${instructionOption.kind}`) &&
                <p className={styles.disclaimer}>
                  {t(`product_kind_disclaimer:${instructionOption.kind}`, { currency: instructionOption.feePrice.currency })}
                </p>
              }

            </div>

            {!!badges.length &&
              <div className={styles.badges}>
                {badges.map((badge) => {
                  if (!badge) return undefined
                  const { color, text, materialIcon } = badge

                  return (
                    <MUIBadge
                      color={color}
                      key={`${color}_${text}`}
                      icon={materialIcon}
                      label={text}
                    />
                  )
                })}
              </div>
            }

            {!!rightHeaderContent &&
              <div className={styles.rightContent}>
                {rightHeaderContent}
              </div>
            }

          </div>
        </MUICheckCard>
      </div>
    </div>
  )
}
