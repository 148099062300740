import { FC } from 'react'
import { GallerySection } from 'components/common/Gallery/GallerySection/GallerySection.component'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props
 */
interface Props {
  /** JSX elements that we render in section as unpurchased */
  unPurchasedVisuals: JSX.Element
}

/**
 * Client gallery photo section unpurchased visuals
 * 
 * @example <ClientGalleryPhotoSectionFilteredUnpurchased />
 */
export const ClientGalleryPhotoSectionFilteredUnpurchased: FC<Props> = ({ unPurchasedVisuals }) => {

  const { t } = useTranslation(['gallery'])

  return (
    <GallerySection
      title={t('visuals_unpurchased_title')}
      subTitle={t('visuals_unpurchased_subtitle')}
    >
      {unPurchasedVisuals}
    </GallerySection>
  )
}
