import { EnterHandler, ExitHandler } from 'react-transition-group/Transition'
import { Power1, gsap } from 'gsap'

import ScrollToPlugin from 'gsap/dist/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)
/** timeline for enter animations */
const timeline_enter = gsap.timeline({ paused: true })
/** timeline for exit animations */
const timeline_exit = gsap.timeline({ paused: true })

/** animaton timeline for page enter */
export const enter: EnterHandler<undefined> = (node: HTMLElement, isAppearing: boolean) => {
  if (!node) return
  const delay = isAppearing ? .6 : 0.3
  timeline_enter.totalProgress(1).clear(true).totalProgress(0)
  timeline_enter.fromTo(node, { autoAlpha: 0, delay, ease: Power1.easeIn }, { autoAlpha: 1, delay, ease: Power1.easeIn, duration: 0.3 }, 0)
  node.querySelector('.page-content')?.childNodes.forEach((child, key) => {
    timeline_enter.fromTo(child, { autoAlpha: 0, y: 25, delay, ease: Power1.easeIn }, { autoAlpha: 1, y: 0, delay, ease: Power1.easeIn, duration: 0.3 }, key * 0.075)
  })
  timeline_enter.play()
}

/** animaton timeline for page exit */
export const exit: ExitHandler<undefined> = (node: HTMLElement) => {
  if (!node) return
  timeline_exit.totalProgress(1).clear(true).totalProgress(0)
  timeline_exit.to(node, { autoAlpha: 0, zIndex: 0, ease: Power1.easeOut, duration: 0.15 })
  timeline_exit.play()

  gsap.to(window, { duration: 0.3, scrollTo: 0 })
}
