import moment from 'moment-timezone'
import { defaultTimezone } from './timezoneUtils'

/** Utility function to return boolean value whether the day is business day (Monday-Friday) */
export const isBusinessDay = (date: moment.Moment, timezone = defaultTimezone) => {
  let selectedDate = date.tz(timezone)
  return selectedDate.isoWeekday() <= 5
}

/** 
 * Utility function to return an array of dates between date and number of business days.
 * Defaults to parameter timezone = defaultTimezone = 'Europe/Zurich'
 * 
 * @example getDatesBetweenDateAndNumberOfBusinessDays(new Date('2021-05-17T17:00:00+0200'), true, 5)
 */
export const getDatesBetweenDateAndNumberOfBusinessDays = (date: Date | moment.Moment = moment(), isStartingDayIncluded: boolean, businessDaysNumber: number, timezone = defaultTimezone): Date[] => {
  if (businessDaysNumber < 1) throw new Error('Invalid number of business days')

  let selectedDate = date instanceof Date ? moment(date).tz(timezone) : date.tz(timezone)
  if (!isStartingDayIncluded && isBusinessDay(selectedDate)) businessDaysNumber++

  let dates = []
  let count = 0

  while (count < businessDaysNumber) {
    dates.push(selectedDate.toDate())
    if (isBusinessDay(selectedDate)) count++
    selectedDate = selectedDate.clone().add(1, 'd')
  }

  return dates
}

/**
 * Utility function to return boolean value whether the time is before end of working hours (regardless of particular day).
 * Defaults to parameter timezone = defaultTimezone = 'Europe/Zurich'
 * 
 * @example isBeforeEndOfWorkingHours(new Date('2021-05-17T17:00:00+0200'))
 */
export const isBeforeEndOfWorkingHours = (time: Date | moment.Moment, timezone = defaultTimezone) => {
  const endOfWorkingHoursHour = 18
  const endOfWorkingHoursMinutes = 0

  const selectedTime = time instanceof Date ? moment(time).tz(timezone) : time.tz(timezone)
  const selectedHour = selectedTime.get('hours')
  const selectedMinutes = selectedTime.get('minutes')

  if (selectedHour < endOfWorkingHoursHour) return true
  if (selectedHour === endOfWorkingHoursHour && selectedMinutes < endOfWorkingHoursMinutes) return true
  else return false
}

export const WEEKEND_HOURS_START = {
  isoWeekday: 5, // Friday
  hour: 17, // 5pm
}

export const WEEKEND_HOURS_END = {
  isoWeekday: 1, // Monday
  hour: 8, // 8am
}

/** 
 * Utility function to return boolean value whether the time is after end of working hours on Friday (past 5pm)
 * and before start of working hours on Monday (before 8am).
 * Defaults to parameter timezone = defaultTimezone = 'Europe/Zurich'
 * 
 * @example isDuringWeekendHours(new Date('2021-05-17T17:00:00+0200'))
 */
export const isDuringWeekendHours = (dateTime: Date | moment.Moment, timezone = defaultTimezone) => {

  const selectedDateTime = dateTime instanceof Date ? moment(dateTime).tz(timezone) : dateTime.tz(timezone)
  const selectedDay = selectedDateTime.get('isoWeekday')
  const selectedHour = selectedDateTime.get('hours')

  switch (selectedDay) {
    case 5: // isoWeekDay Friday
      return selectedHour >= WEEKEND_HOURS_START.hour // after 5pm
    case 6: // isoWeekDay Saturday
    case 7: // isoWeekDay Sunday
      return true
    case 1: // isoWeekDay Monday
      return selectedHour < WEEKEND_HOURS_END.hour // before 8am
    default:
      return false // everything else
  }
}

/** 
 * Utility function to return minDate for order placement when selecting next availble date.
 * Takes into consideration weekends and business hours.
 * Defaults to parameters dateTimeNow = moment() & timezone = defaultTimezone = 'Europe/Zurich'
 * 
 * @returns
 * Sets date to upcoming Wednesday (Tuesday if next day assignment is enabled) at 8am if dateTimeNow is during weekend hours (Friday 6pm - Monday 8am).
 * Sets next Tuesday (Monday if next day assignment is enabled) current time for Friday before start of weekend hours.
 * Sets now + 48 hours (+ 24 hours if next day assignment is enabled) otherwise.
 * 
 * @param {(Date | moment.Moment)} [dateTimeNow=moment()]
 * @param {string} [timezone=defaultTimezone]
 * @example getMinDateTimeForOrder()
 */
export const getMinDateTimeForOrder = (dateTimeNow: Date | moment.Moment = moment(), timezone: string = defaultTimezone, isCreativeExtraServiceSelected?: boolean) => {
  const now = moment(dateTimeNow).tz(timezone)
  const startOfToday = moment(dateTimeNow).startOf('day').tz(timezone)

  // is during weekend hours
  const duringWeekendHours = isDuringWeekendHours(now, timezone)
  const isoWeekDay = now.get('isoWeekday')

  // If the order is marked as favorite, return the current date and time
  if (isCreativeExtraServiceSelected) return now.toDate()

  if (duringWeekendHours) {
    // is during Sunday
    const isSunday = isoWeekDay === 7
    // is during Monday early hours
    const isMonday = isoWeekDay === 1

    // set Wednesday if during Sunday
    if (isSunday) return startOfToday.day(3).hours(8).toDate()

    // set Wednesday if during early Monday hours (before work hours)
    if (isMonday) return startOfToday.day(3).hours(8).toDate()

    // set next Wednesday if during weekend hours
    return startOfToday.day(3 + 7).hours(8).toDate()
  }

  // set next Tuesday same time if during Friday before weekend hours
  if (isoWeekDay === 5) return now.add(4, 'days').toDate()

  // set now + 48 hours
  return now.add(2, 'day').toDate()
}

/** 
 * Utility function to return minDate for order placement when selecting next availble date when order contains a Prestige product.
 * Takes into consideration weekends and business hours.
 * Defaults to parameters dateTimeNow = moment() & timezone = defaultTimezone = 'Europe/Zurich'
 * 
 * @returns
 * If order is placed on Monday, Tuesday, Wednesday, Thursday -> First available time is 48h from now
 * If order is placed Friday before 3pm -> First available time is Tuesday 8:00
 * If order is placed Friday after 3pm, Saturday or Sunday -> First available time is Wednesday 8:00
 * @param {(Date | moment.Moment)} [dateTimeNow=moment()]
 * @param {string} [timezone=defaultTimezone]
 * @example getMinDateTimeForOrderWithPrestigeProduct()
 */
export const getMinDateTimeForOrderWithPrestigeProduct = (dateTimeNow: Date | moment.Moment = moment(), timezone: string = defaultTimezone) => {
  const now = moment(dateTimeNow).tz(timezone)
  const startOfToday = moment(dateTimeNow).startOf('day').tz(timezone)

  const isoWeekDay = now.get('isoWeekday')

  if (isoWeekDay < 5) {
    return now.add(2, 'days').toDate()
  }

  if (isoWeekDay === 5 && now.get('hours') < 15) {
    return startOfToday.add(4, 'days').hours(8).toDate()
  }

  // Sunday is taken as a new week
  return startOfToday.add(startOfToday.get('isoWeekday') === 7 ? 0 : 7, 'days').day(3).hours(8).toDate()
}
