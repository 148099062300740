import { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { supportEmail, supportEmailHref } from 'constants/contacts'

import BlockInfo from 'components/common/BlockInfo/BlockInfo'
import { PageTransition } from 'utils/animations'
import { Path } from 'constants/router'
import { PaymentStatusController } from './PaymentStatus.controller'
import { StripePaymentContextProvider } from 'components/contexts/StripePaymentContext'
import { useLocation } from 'react-router-dom'

/** Routed assembly page for PaymentStatus */
export const PaymentStatusPage: FC = () => {

  const { t } = useTranslation(['payment_status_page'])
  const location = useLocation()
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location])
  const clientSecret = useMemo(() => queryParams.get('payment_intent_client_secret'), [queryParams])
  const dealId = useMemo(() => queryParams.get('dealId'), [queryParams])
  const assignmentId = useMemo(() => queryParams.get('assignmentId'), [queryParams])
  const reference = useMemo(() => queryParams.get('reference'), [queryParams])
  // check whether either dealId OR assignmentId is set, not both, not neither
  const onlyOneIdIsSet = useMemo(() => !!dealId !== !!assignmentId, [assignmentId, dealId])

  return (
    <PageTransition>
      {!!clientSecret && onlyOneIdIsSet
        ?
        <StripePaymentContextProvider clientSecret={clientSecret}>
          <PaymentStatusController
            clientSecret={clientSecret}
            dealId={dealId}
            assignmentId={assignmentId}
            reference={reference}
          />
        </StripePaymentContextProvider>
        :
        <div className="page">
          <div className="page-content">
            <BlockInfo color="red">
              <Trans t={t} i18nKey="error" values={{ supportEmail }}>
                <a href={Path.INDEX}>&nbsp;</a>
                <a href={supportEmailHref()}>&nbsp;</a>
              </Trans>
              {!clientSecret &&
                <p>ERROR: payment_intent_client_secret parameter is not defined</p>
              }
              {!onlyOneIdIsSet &&
                <p>ERROR: None or both IDs has been set</p>
              }
            </BlockInfo>
          </div>
        </div>
      }
    </PageTransition>
  )
}
