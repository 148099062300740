import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { usePurchaseFlowPaymentIntent, usePurchaseFlowPaymentStatus, usePurchaseFlowPlaceOrder } from '../_main/contexts'

import { Color } from 'constants/assets'
import FormControlLabel from '@mui/material/FormControlLabel'
import { GRAY_900 } from 'constants/styling/theme'
import { MUICheckbox } from 'components/common/MUICheckBox'
import { Path } from 'constants/router'
import { PreferredPaymentMethodEnum } from 'constants/user'
import ReactLoading from 'react-loading'
import { ReceiptEmailInput } from 'components/common/ReceiptEmailInput'
import { SavedPaymentMethodsSelect } from 'components/common/SavedPaymentMethodsSelect'
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js'
import Typography from '@mui/material/Typography'
import classnames from 'classnames'
import { productKindIsPreferredPaymentMethod } from 'utils/typeguards'
import styles from './PurchaseFlowPayment.module.sass'
import { usePurchaseFlowOrderMeta } from 'components/pages/PurchaseFlow/_main/contexts'
import { useSavedStripePaymentMethods } from 'components/contexts/SavedStripePaymentMethodsContext'
import { useTranslation } from 'react-i18next'

export const PurchaseFlowPayment: FC<{ disabled?: boolean }> = ({ disabled = false }) => {

  const { t } = useTranslation('saved_payment_methods')

  // STRIPE
  const stripe = useStripe()
  const elements = useElements()
  const { clientSecret } = usePurchaseFlowPaymentIntent()

  const { selectedPaymentMethod, reference } = usePurchaseFlowOrderMeta()
  const {
    savedStripePaymentMethodsMap,
    stripePaymentMethods,
  } = useSavedStripePaymentMethods()

  const { orderHasBeenPlaced, placeOrderMutation } = usePurchaseFlowPlaceOrder()

  const {
    isStripeConfirmed,
    receiptEmail,
    saveStripePaymentMethod,
    selectedSavedPaymentMethod,
    stripeInputElementsAreValid,
    setReceiptEmail,
    setIsReceiptEmailValid,
    setStripeError,
    setIsStripeProcessing,
    setStripeInputElementsAreValid,
    setIsStripeConfirmed,
    setSaveStripePaymentMethod,
    setSelectedSavedPaymentMethod,
  } = usePurchaseFlowPaymentStatus()

  const [isPaymentElementReady, setIsPaymentElementReady] = useState(false)

  const dealId = useMemo(() => placeOrderMutation.data?.data.id, [placeOrderMutation.data?.data.id])

  /**
   * @function getReturnUrl - Serializes and encodes return URL for stripe payment with provided params
   * @returns Formatted encoded URL string
   * @param params - (URLSearchParams) to be appended to URL
   */
  const getReturnUrl = useCallback((params: URLSearchParams) => {
    return encodeURI(`${window.location.origin}${Path.PAYMENT_STATUS}?${params.toString()}`)
  }, [])

  const isLoading = useMemo(() => {
    if (!stripePaymentMethods?.isSuccess) return true
    if (selectedSavedPaymentMethod === null && !isPaymentElementReady) return true
    return false
  }, [isPaymentElementReady, stripePaymentMethods, selectedSavedPaymentMethod])

  const savedPaymentMethods = useMemo(() => {
    if (!selectedPaymentMethod || !productKindIsPreferredPaymentMethod(selectedPaymentMethod)) return []
    return savedStripePaymentMethodsMap[selectedPaymentMethod] || []
  }, [selectedPaymentMethod, savedStripePaymentMethodsMap])

  const showReceiptInput = useMemo(() => {

    if (selectedSavedPaymentMethod === undefined) return false

    if (selectedSavedPaymentMethod === null) {
      if (!isPaymentElementReady) return false
      return true
    }

    if (selectedSavedPaymentMethod.type in PreferredPaymentMethodEnum) return true
    return false

  }, [isPaymentElementReady, selectedSavedPaymentMethod])

  const onElementsChange = useCallback((e: StripePaymentElementChangeEvent) => {
    if (e.complete) setStripeInputElementsAreValid(true)
    else setStripeInputElementsAreValid(false)
  }, [setStripeInputElementsAreValid])

  const confirmStripePayment = useCallback(async () => {
    if (!clientSecret) throw new Error('Payment intent was not received')
    if (!stripe || !elements) throw new Error('Stripe was not initialized')
    if (!dealId) throw new Error('Parameter dealId is missing in placeOrderResponse')

    const urlParams = new URLSearchParams({
      dealId: dealId.toString(),
      reference,
    })

    setIsStripeConfirmed(true)

    /** This will hold potential stripe error */
    let error

    const returnUrl = getReturnUrl(urlParams)

    // If no saved method is used, use general confirm
    // If stripe fails, push error to the error variable
    if (selectedSavedPaymentMethod === null) {
      error = (await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: returnUrl,
          receipt_email: receiptEmail,
          // Whether sripe will save this payment method
          save_payment_method: saveStripePaymentMethod,
          payment_method_data: {
            billing_details: {
              email: receiptEmail,
            },
          },
        },
      })).error
    }

    // If saved card payment method is to be used, use specific card confirm
    // General confirm cannot accept saved payment method
    // If stripe fails, push error to the error variable
    if (selectedSavedPaymentMethod?.type === PreferredPaymentMethodEnum.CARD_PAYMENT) {
      error = (await stripe.confirmCardPayment(clientSecret, {
        payment_method: selectedSavedPaymentMethod.stripeId,
        receipt_email: receiptEmail,
        return_url: returnUrl,
      })).error
    }

    // If saved SEPA payment method is to be used, use specific SEPA confirm
    // General confirm cannot accept saved payment method
    // If stripe fails, push error to the error variable
    if (selectedSavedPaymentMethod?.type === PreferredPaymentMethodEnum.SEPA_DIRECT_DEBIT) {
      error = (await stripe.confirmSepaDebitPayment(clientSecret, {
        payment_method: selectedSavedPaymentMethod.stripeId,
        receipt_email: receiptEmail,
        return_url: returnUrl,
      })).error
    }

    // If stripe successfully finishes without redirect, redirect user manually to payment status page
    if (!error) {
      urlParams.append('payment_intent_client_secret', clientSecret)
      window.location.replace(getReturnUrl(urlParams))
    }

    // If stripe fails, reset the process and set stripe error state
    if (error) {
      setStripeError(error)
      setIsStripeProcessing(false)
    }

  }, [clientSecret, dealId, elements, getReturnUrl, receiptEmail, reference, saveStripePaymentMethod, selectedSavedPaymentMethod, setIsStripeConfirmed, setIsStripeProcessing, setStripeError, stripe])

  // Confirm stripe payment after order is placed
  useEffect(() => {
    if (orderHasBeenPlaced && clientSecret && !isStripeConfirmed) confirmStripePayment()
  }, [confirmStripePayment, clientSecret, isStripeConfirmed, orderHasBeenPlaced])

  // Reset readiness and validity of payment element when method changes to saved/undefined
  useEffect(() => {
    if (selectedSavedPaymentMethod !== null) {
      if (stripeInputElementsAreValid) setStripeInputElementsAreValid(false)
      if (isPaymentElementReady) setIsPaymentElementReady(false)
    }
  }, [isPaymentElementReady, selectedSavedPaymentMethod, setIsPaymentElementReady, setStripeInputElementsAreValid, stripeInputElementsAreValid])

  return (
    <div className={classnames(styles.wrap, { [styles.disabled]: disabled })}>

      {!!selectedPaymentMethod && productKindIsPreferredPaymentMethod(selectedPaymentMethod) && savedPaymentMethods.length > 0 &&
        <SavedPaymentMethodsSelect
          isNewDesign={true}
          className={styles.savedMethodsList}
          paymentMethodType={selectedPaymentMethod}
          savedPaymentMethods={savedPaymentMethods}
          selectedPaymentMethod={selectedSavedPaymentMethod}
          onSelected={(method) => setSelectedSavedPaymentMethod(method)}
        />
      }

      {showReceiptInput &&
        <ReceiptEmailInput
          isRequired={true}
          isNewDesign={true}
          className={styles.receiptEmail}
          onValidityChange={(isValid) => setIsReceiptEmailValid(isValid)}
          onValueChange={(value) => setReceiptEmail(value)}
        />
      }

      {selectedSavedPaymentMethod === null &&
        <PaymentElement
          className={
            classnames(
              styles.paymentElement,
              {
                [styles.disabled]: disabled,
                [styles.loading]: !isPaymentElementReady,
              }
            )
          }
          onReady={() => setIsPaymentElementReady(true)}
          onChange={(e) => onElementsChange(e)}
          options={{
            terms: {
              card: 'never',
              sepaDebit: 'never',
            },
            fields: {
              billingDetails: {
                email: 'never',
              },
            },
          }}
        />
      }

      {selectedSavedPaymentMethod === null && isPaymentElementReady &&
        <div className={styles.checkboxWrapper}>
          <FormControlLabel
            label={
              <Typography color={GRAY_900} fontWeight={500} variant='text-md'>
                {t('save')}
              </Typography>
            }
            onChange={(e, checked) => setSaveStripePaymentMethod(checked)}
            control={
              <MUICheckbox
                name="save-payment-method-checkbox"
                id="save-payment-method-checkbox"
                checked={saveStripePaymentMethod}
                sx={{ marginRight: '1rem', padding: 0 }}
              />
            }
          />
        </div>
      }

      {isLoading &&
        <ReactLoading color={Color.GRAY_TEXT} type="cylon" />
      }

    </div>
  )
}
