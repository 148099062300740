import { FC, Fragment, ReactNode, useMemo } from 'react'

import { ExpandableCard } from 'components/common/ExpandableCard'
import { PreferredPaymentMethodEnum } from 'constants/user'
import { SavedStripePaymentMethod } from 'models/user'
import { getCardExpirationString } from 'utils/helpers'
import { getSavedPaymentMethodAddressString } from 'utils/payment'
import styles from './SavedPaymentMethodListCard.module.sass'
import { useTranslation } from 'react-i18next'

interface Props {
  className?: string
  paymentMethod: SavedStripePaymentMethod
  children?: ReactNode
}

/**
 * Displays simple card with info about payment method.
 * Children will be positioned to the right and given row flex (suited for action buttons).
 * @component SavedPaymentMethodListCard
 * @example
 * <SavedPaymentMethodListCard paymentMethod={methodObject}>
 *   <Button type="primary">Action</Button>
 * </SavedPaymentMethodListCard>
 */
export const SavedPaymentMethodListCard: FC<Props> = ({
  paymentMethod,
  className,
  children,
}) => {

  const { t } = useTranslation('product_kind')

  const expirationDateString = useMemo(() => {
    if (!paymentMethod.cardExpirationMonth || !paymentMethod.cardExpirationYear) return undefined
    return getCardExpirationString(paymentMethod.cardExpirationMonth, paymentMethod.cardExpirationYear)
  }, [paymentMethod.cardExpirationMonth, paymentMethod.cardExpirationYear])

  return (
    <ExpandableCard className={className} isExpanded={false}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.type}>{t(paymentMethod.type)}</div>
          <div className={styles.info}>
            {paymentMethod.type === PreferredPaymentMethodEnum.CARD_PAYMENT &&
              <Fragment>
                <span>**** **** **** {paymentMethod.last4}</span>

                {!!expirationDateString &&
                  <span>
                    {expirationDateString}
                  </span>
                }
              </Fragment>
            }
            {paymentMethod.type === PreferredPaymentMethodEnum.SEPA_DIRECT_DEBIT &&
              <Fragment>
                <span>********{paymentMethod.last4}</span>

                <span>
                  {getSavedPaymentMethodAddressString(paymentMethod)}
                </span>
              </Fragment>
            }
          </div>
        </div>
        {!!children &&
          <div className={styles.actions}>
            {children}
          </div>
        }
      </div>
    </ExpandableCard>
  )
}
