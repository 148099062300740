import { FC, useCallback } from 'react'

import { CopyButton } from 'components/common/CopyButton'
import { VisualsMarketingTargetPlatform } from '../_main/VisulalsMarketing.constants'
import { logAnalyticsEvent } from 'utils/analytics'
import { useGalleryAssignment } from 'components/pages/Gallery/_main/contexts/GalleryAssignment.context'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * Props for the component CopyRoundButton that extends ButtonProps.
 *
 * @interface Props
 */
interface Props {
  /** The text to be copied to the clipboard. */
  copyText: string
  /** The target platform from where the text will be copied. */
  targetPlatform: VisualsMarketingTargetPlatform
  /** Whether to disble the copy button or not. */
  disabled: boolean
}

/**
 * @component
 * CopyRoundButton component that allows copying the specified text to the clipboard.
 * 
 * @example
 * <CopyRoundButton copyText="Hello, world!" />
 */
export const CopyRoundButton: FC<Props> = ({ copyText, targetPlatform, disabled }) => {

  const { clientData, currentUserWorkspace } = useUserData()
  const { assignmentData } = useGalleryAssignment()

  const { t } = useTranslation(['common'])

  const onCopyGeneratedText = useCallback(() => {
    // ANALYTICS
    logAnalyticsEvent('Copy Text', {
      userEmail: clientData?.email,
      planId: currentUserWorkspace?.id,
      planName: currentUserWorkspace?.name,
      assignmentId: assignmentData?.id,
      targetPlatform,
    })
  }, [clientData?.email, currentUserWorkspace?.id, currentUserWorkspace?.name, assignmentData?.id, targetPlatform])

  return (
    <CopyButton
      disabled={disabled}
      showIcon={true}
      showTooltip={false}
      copyText={copyText}
      onCopy={onCopyGeneratedText}
      textContent={t('copy_text')}
    />
  )
}
