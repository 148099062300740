/**
 * Utility function to create a K:V from a list of strings
 * @example
 * const AvailableFileTypes = stringArrayAsType(['WEB', 'HD', 'Original'])
 * type AvailableFileTypes = keyof typeof AvailableFileTypes
 */
export function stringArrayAsType<T extends string>(o: Array<T>): { [K in T]: K } {
  return o.reduce((res, key) => {
    res[key] = key
    return res
  }, Object.create(null))
}