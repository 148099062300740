import { EmojiValue, RatingType } from 'constants/misc'
import { Trans, useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'

import BlockInfo from '../BlockInfo/BlockInfo'
import classnames from 'classnames'
import styles from './RatingText.module.sass'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** Which emoji was clicked */
  activeEmoji?: EmojiValue
  /** The additional classes to append */
  className?: string
  /** Rating type */
  ratingType: RatingType
  /** Textarea name */
  textareaName?: string
  /** Textarea ID */
  textareaId?: string
  /** Textarea value */
  textareaValue?: string
  /** Whether the textarea is disabled or not */
  textareaDisabled?: boolean
  /** Whether the submit button is disabled or not */
  buttonDisabled?: boolean
  /** Is text already Submitted => do not show "Oh no" box */
  isSubmitted: boolean
  /** OnChange action to be called after textarea value has changed */
  onChange?: (value: string, e?: any) => void
  /** OnClick action to be called after submit button has been clicked */
  onSubmitClick?: (value: string, e?: React.MouseEvent) => void
}

/**
 * @component Display rating with text field
 * @example
 * <RatingText ratingType={RatingType.VISUALS} onSubmitClick={action(('submit button clicked'))} textareaDisabled={false} buttonDisabled={false} />
 */
const RatingText: React.FC<Props> = ({
  activeEmoji,
  className = '',
  textareaName,
  isSubmitted,
  textareaId,
  textareaValue,
  textareaDisabled,
  onChange,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const { t } = useTranslation(['rating'])
  const [textValue, setTextValue] = useState(textareaValue || '')
  const [isTextareaFocused, setIsTextareaFocused] = useState(!!(textareaValue || textValue))

  // If passed value changes, change internal state as well
  useEffect(() => {
    if (typeof textareaValue === 'string') setTextValue(textareaValue)
  }, [textareaValue])


  // Focus textarea if user selects bad rating
  useEffect(() => {
    if (!textareaRef.current) return

    if (activeEmoji === EmojiValue.UNHAPPY) {
      textareaRef.current!.focus()
    }
  }, [activeEmoji])

  return (
    <div className={classnames(styles.ratingText, className)}>
      <div className={styles.ratingTextWrapper}>

        <span className={classnames(styles.label, { [styles.isTextareaFocused]: isTextareaFocused })}>
          {t('rating_description_hint')}
        </span>

        <textarea
          name={textareaName}
          ref={textareaRef}
          id={textareaId}
          value={textareaValue || textValue}
          onChange={e => {
            setTextValue(e.target.value)
            onChange && onChange(e.target.value, e)
          }}
          disabled={textareaDisabled}
          placeholder={t('rating_description_placeholder')}
          onFocus={() => setIsTextareaFocused(true)}
          onBlur={() => !(textareaValue || textValue) ? setIsTextareaFocused(false) : undefined}
        >
        </textarea>

        {activeEmoji === EmojiValue.UNHAPPY && !isSubmitted && <BlockInfo className={styles.ratingUnhappyWarning}>
          <Trans t={t} i18nKey="rating_unhappy_flow_warning">
            &nbsp;
          </Trans>
        </BlockInfo>}

      </div>
    </div>
  )
}

export default RatingText
