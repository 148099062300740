import { APIRequestState, Endpoint, RoleMimetype } from 'constants/API'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { actionTypeTupleTest, createAPIAction, generalFetch } from 'redux/Helpers'
import { all, select, takeEvery } from 'typed-redux-saga'
import { call, put } from 'redux-saga/effects'

import API from 'utils/API/API'
import { ActionExtendVirtualVisitHosting } from '.'
import { ActionListVisuals } from '../ListVisuals'
import { RootStore } from 'models/redux'
import { VisualFileType } from 'constants/visual'
import { cloneDeep } from 'lodash'

/** Saga which extends virtual visit hosting */
export function* extendVirtualVisitSaga(receivedAction: ActionExtendVirtualVisitHosting) {
  const { missionId, paymentIntentId, billingProductId } = receivedAction.payload

  const url = Endpoint.VISUAL_EXTEND_VIRTUAL_VISIT.replace('{assignmentId}', missionId.toString())
  const action: ActionExtendVirtualVisitHosting = yield generalFetch(ActionTypeAPIData.EXTEND_VIRTUAL_VISIT_HOSTING, () => API.put(
    url,
    {},
    {
      headers: {
        Accept: RoleMimetype.CLIENT, // TODO: Fix on BE so we can send proper accept header
      },
      params: {
        stripePaymentIntentId: paymentIntentId,
        primaryBillingProductId: billingProductId,
      },
    },
    {
      endpoint: Endpoint.VISUAL_EXTEND_VIRTUAL_VISIT
    }
  ))

  const extendVirtualVisitAction: ActionExtendVirtualVisitHosting = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }

  yield put(extendVirtualVisitAction)

  if (action.payload.request.state !== APIRequestState.OK) return
  yield call(editListVisuals, extendVirtualVisitAction)
}

/** Saga which edits fetched visuals */
function* editListVisuals(receivedAction: ActionExtendVirtualVisitHosting) {
  const { missionId } = receivedAction.payload
  const getListVisuals = (state: RootStore) => state.APIData[ActionTypeAPIData.LIST_VISUALS][missionId]?.[VisualFileType.POST]?.data
  const listedVisuals = yield* select(getListVisuals)

  if (!listedVisuals) return

  const listVisualsAction = createAPIAction<ActionListVisuals>([ActionTypeAPIEvent.RECEIVED, ActionTypeAPIData.LIST_VISUALS], {
    missionId,
    type: VisualFileType.POST,
    request: {
      ...cloneDeep(receivedAction.payload.request),
      data: {
        ...listedVisuals,
        virtualVisit: { ...receivedAction.payload.request.data }
      }
    }
  })

  yield put(listVisualsAction)
}

/** Watcher of extend virtual visit hosting */
export function* extendVirtualVisitWatcher() {
  yield all([
    takeEvery((action: ActionExtendVirtualVisitHosting) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.EXTEND_VIRTUAL_VISIT_HOSTING]), extendVirtualVisitSaga),
  ])
}
