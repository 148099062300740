import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { QueryClient } from '@tanstack/react-query'
import { APIRequestState } from 'constants/API'
import { APIRequest } from 'models/API'
import { ActionRequest } from 'models/redux'
import { createAPIAction } from 'redux/Helpers'
import { StopAutomatedOrderStoreItem } from './stopAutomatedOrder.interfaces'

/** Describes general action to stop automated order */
export interface ActionStopAutomatedOrder extends ActionRequest {
  payload: {
    orderId: number,
    assignmentId: string,
    request: StopAutomatedOrderStoreItem
  }
}

/** Creates an action to stop automated order */
export function stopAutomatedOrder(orderId: number, assignmentId: string | null, queryClient: QueryClient): ActionStopAutomatedOrder {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.STOP_AUTOMATED_ORDER], {
    orderId,
    assignmentId,
    request: new APIRequest(APIRequestState.RUNNING)
  }, queryClient)
}

/** Creates an action to purge the state of stop automated order */
export function purgeStopAutomatedOrder(orderId: number): ActionStopAutomatedOrder {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.STOP_AUTOMATED_ORDER], {
    orderId,
    request: new APIRequest(APIRequestState.BEFORE_START)
  })
}
