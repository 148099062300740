import { DocumentCategory, EditingCategory, ShootingCategory } from 'constants/product'
import { useCallback, useEffect, useMemo } from 'react'
import { usePurchaseFlowConfig, useTargetOrderUser } from '../../../_main/contexts'

import { CategoryCard } from '../CategoryCard.component'
import { FeatureFlag } from 'utils/featureFlags'
import { ProductCategory } from 'models/product'
import { Stack } from '@mui/material'
import { StepWrapper } from '../../../common/StepWrapper'
import TransitionAppear from 'components/common/TransitionAppear/TransitionAppear'
import authorities from 'assets/img/product_categories/category_authorities_documents.webp'
import corporate from 'assets/img/product_categories/corporate.jpg'
import editing from 'assets/img/product_categories/category_editing.webp'
import energy from 'assets/img/product_categories/category_energy_documents.webp'
import event from 'assets/img/product_categories/event.jpg'
import food from 'assets/img/product_categories/food.jpg'
import { logAnalyticsEvent } from 'utils/analytics'
import product from 'assets/img/product_categories/product.jpg'
import real_estate from 'assets/img/product_categories/category_shootings.webp'
import staging from 'assets/img/product_categories/category_staging.webp'
import { useAuth0 } from 'utils/auth'
import { useDynamicFlowController } from 'components/common/DynamicFlow'
import { useFlag } from '@unleash/proxy-client-react'
import { useTranslation } from 'react-i18next'

const categoryImagePath = {
  [ShootingCategory.REAL_ESTATE]: real_estate,
  [ShootingCategory.FOOD]: food,
  [ShootingCategory.EVENT]: event,
  [ShootingCategory.PRODUCT]: product,
  [ShootingCategory.CORPORATE]: corporate,
  [EditingCategory.EDITING]: editing,
  [EditingCategory.STAGING]: staging,
  [DocumentCategory.AUTHORITIES_DOCUMENTS]: authorities,
  [DocumentCategory.ENERGY_CERTIFICATE]: energy,
}

// Union parent type asserted
const mainCategoriesOrder = new Set<ProductCategory>([
  ShootingCategory.REAL_ESTATE,
  EditingCategory.EDITING,
  EditingCategory.STAGING,
  DocumentCategory.AUTHORITIES_DOCUMENTS,
  DocumentCategory.ENERGY_CERTIFICATE,
])

// Union parent type asserted
const secondaryCategoriesOrder = new Set<ProductCategory>([
  ShootingCategory.PRODUCT,
  ShootingCategory.FOOD,
  ShootingCategory.EVENT,
  ShootingCategory.CORPORATE,
])

type OrderType = 'main' | 'secondary'

type CategoryItems = Record<OrderType, Array<ProductCategory>>

/**
 * @component
 * CategoryStep component represents the step in the purchase flow where the user selects a category for their order.
 *
 * @example
 * <CategoryStepController />
 */
export const CategoryStepController: React.FC = () => {
  const { roles } = useAuth0()
  const { t } = useTranslation(['order', 'category'])
  const {
    setSelectedCategory,
    editingCategories,
    shootingCategories,
    skipCategoryStep,
    defaultCategory,
    getCategoryConfig,
    documentCategories,
    getProductCatalogue,
    getExtraProducts,
    selectedCountryCode,
    selectedCity,
  } = usePurchaseFlowConfig()
  const { adminSelectedUserEmail } = useTargetOrderUser()

  const allowAuthoritiesDocs = useFlag(FeatureFlag.ALLOW_AUTHORITIES_DOCUMENTS)
  const allowEnergyDocuments = useFlag(FeatureFlag.ALLOW_ENERGY_DOCUMENTS)

  const flowCtrl = useDynamicFlowController()

  // Category configuration data  
  const categoryItems: CategoryItems = useMemo(() => {

    const allCategories: Array<ProductCategory> = [...shootingCategories, ...editingCategories, ...documentCategories]

    return {
      main: allCategories.filter(category => {
        if (!allowAuthoritiesDocs && category === DocumentCategory.AUTHORITIES_DOCUMENTS) return false
        if (!allowEnergyDocuments && category === DocumentCategory.ENERGY_CERTIFICATE) return false

        return mainCategoriesOrder.has(category)
      }),
      secondary: allCategories.filter(category => secondaryCategoriesOrder.has(category))
    }
  }, [allowAuthoritiesDocs, allowEnergyDocuments, documentCategories, editingCategories, shootingCategories])

  /** Sets category in config and if requested, triggers redirect to next step */
  const selectCategory = useCallback((category: ProductCategory, immediatelyNext: boolean = false) => {
    setSelectedCategory(category)

    let nextNode = flowCtrl.currentNode?.next

    if (immediatelyNext && nextNode) {
      // Manual reset since the useEffect in Config context is not enough for some reason when immediately going next step
      getProductCatalogue.reset()
      getExtraProducts.reset()

      flowCtrl.setHead(nextNode)
    }
  }, [setSelectedCategory, flowCtrl, getProductCatalogue, getExtraProducts])

  /** Fetch the category config */
  useEffect(() => {
    if (roles.isAdmin && !adminSelectedUserEmail) return
    if (!getCategoryConfig.isIdle) return

    getCategoryConfig.mutate({ email: adminSelectedUserEmail })
  }, [adminSelectedUserEmail, roles.isAdmin, getCategoryConfig])

  /** Handle auto-selection of default category when provided in obtained config and immediately redirect if step is configured to be skipped */
  useEffect(() => {
    if (defaultCategory) selectCategory(defaultCategory, skipCategoryStep)

    // Skip selectedCategory and selectCategory to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipCategoryStep, defaultCategory])

  // Log entering this page
  useEffect(() => {
    logAnalyticsEvent('enters_order_category_selection_screen', {
      country: selectedCountryCode,
      city: selectedCity,
    })
    // No dependencies to only log it once upon render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TransitionAppear visible>
      <StepWrapper title={t('step_category.title')} subtitle={t('step_category.text')} >

        <Stack gap={10} paddingBottom={4}>
          <Stack gap={{ xs: 2, md: 3 }} direction={{ xs: 'column', md: 'row' }} flexWrap="wrap" justifyContent="flex-start">
            {categoryItems.main.map((category) => (
              <CategoryCard
                key={category}
                title={t(`category:${category}`)}
                description={t(`step_category.${category}.text`)}
                image={categoryImagePath[category]}
                onClick={() => selectCategory(category, true)}
              />
            ))}
          </Stack>

          <Stack gap={2} direction={{ xs: 'column', md: 'row' }} flexWrap="wrap" justifyContent="space-between">
            {categoryItems.secondary.map((category) => (
              <CategoryCard
                key={category}
                direction='row'
                title={t(`category:${category}`)}
                description={t(`step_category.${category}.text`)}
                image={categoryImagePath[category]}
                onClick={() => selectCategory(category, true)}
              />
            ))}
          </Stack>
        </Stack>

      </StepWrapper>
    </TransitionAppear>
  )
}
