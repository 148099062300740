import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { ActionRequest } from 'models/redux'
import { UserMeStore } from '../UserMe'
import { createAPIAction } from 'redux/Helpers'

export interface ActionUserMeAlternative extends ActionRequest {
  payload: {
    request: UserMeStore
  }
}

export function userMeAlternative(): ActionUserMeAlternative {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.USER_ME_ALTERNATIVE], {})
}

export function purgeUserMeAlternative(): ActionUserMeAlternative {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.USER_ME_ALTERNATIVE], {})
}