import { BOX_SHADOW_LG, BOX_SHADOW_XS, GRAY_1000, HKGroteskFamily, WHITE } from 'constants/styling/theme'
import React, { ReactElement, ReactNode } from 'react'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

import { styled } from '@mui/material/styles'

/**
 * @typedef TooltipPlacement Available tooltip placements
 */
export type TooltipPlacement = 'top' | 'bottom' | 'left' | 'right' | 'top-start' | 'top-end' | 'bottom-start' | 'bottom-end' | 'left-start' | 'left-end' | 'right-start' | 'right-end'

/** @typedef TooltipType Available tooltip types as colors */
type TooltipType = 'primary' | 'secondary'

/**
 * @interface Props
 */
interface Props {
  /** Tooltip id */
  id?: string
  /** Override or extend the styles applied to the component. See CSS API for more details.  https://mui.com/material-ui/api/tooltip/#css */
  className?: string
  /** Tooltip content */
  content: ReactNode
  children: ReactElement
  /** Whether the tooltip is controlled conditionally, 
   * if not used, it is set to undefined and default behavior to show tooltip on hover works 
   * */
  isOpen?: boolean
  /** Prefered tooltip position, if enough space will be used, default we set "top" */
  placement?: TooltipPlacement
  /** Whether the tooltip has arrow or not */
  arrow?: boolean
  /** Delay time before the tooltip is shown */
  enterDelay?: number
  /** Delay time after the tooltip is hidden */
  leaveDelay?: number
  /** 
   * Type of the tooltip (default is primary).
   * When using secondary type the arrow is removed as it cannot have shadows 
   */
  type?: TooltipType
}

const StyledMUITooltip = styled(({ className, ...props }: TooltipProps & { type: TooltipType }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ type }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: type === 'primary' ? GRAY_1000 : WHITE,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: type === 'primary' ? GRAY_1000 : WHITE,
    fontSize: '1.2rem',
    fontWeight: 400,
    fontFamily: HKGroteskFamily,
    lineHeight: '1.8rem',
    borderRadius: '0.4rem',
    padding: '0.4rem, 0.8rem',
    color: type === 'primary' ? WHITE : GRAY_1000,
    boxShadow: type === 'primary' ? BOX_SHADOW_XS : BOX_SHADOW_LG
  }
}))

/**
 * @component MUITooltip 
 * @example
 * <MUITooltip content={content} placement="bottom">
 *  {children}
 * </MUITooltip>
 */
export const MUITooltip: React.FC<Props> = React.forwardRef(({
  id,
  className = '',
  content,
  children,
  isOpen = undefined,
  placement = 'top',
  arrow = true,
  leaveDelay,
  enterDelay,
  type = 'primary'
}, ref: React.Ref<HTMLInputElement>) => {
  // By default disabled elements like <button> do not trigger user interactions 
  // so a Tooltip will not activate on normal events like hover. To accommodate 
  // disabled elements we add a simple wrapper <span></span>
  // https://mui.com/material-ui/react-tooltip/#disabled-elements
  // + It looks like Tooltip doesn't like other components' ref for some reason. https://github.com/mui/material-ui/issues/33476
  const wrappedChildren = <span ref={ref}>{children}</span>

  return (
    <StyledMUITooltip
      id={id}
      className={className}
      title={content}
      open={isOpen}
      placement={placement}
      arrow={type === 'primary' ? arrow : false}
      leaveDelay={leaveDelay}
      enterDelay={enterDelay}
      type={type}
    >
      {wrappedChildren}
    </StyledMUITooltip>
  )
})
