import { InstructionOptionFieldValueType } from 'models/purchaseFlow'
import { ReactGeocodePlace } from 'react-geocode'

/** Typeguard */
export function InstructionOptionFieldValueTypeIsReactGeocodePlace(value: InstructionOptionFieldValueType): value is ReactGeocodePlace {
  return (
    typeof (value) === 'object' &&
    value !== null &&
    value !== undefined &&
    (value as ReactGeocodePlace)?.formatted_address !== undefined &&
    (value as ReactGeocodePlace)?.address_components !== undefined &&
    (value as ReactGeocodePlace)?.geometry !== undefined
  )
}