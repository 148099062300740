import EditIcon from '@mui/icons-material/Edit'
import { BEIGE_600, BEIGE_700, BLUE_100, BLUE_400, BLUE_600, MOBILE_VIEW_QUERY, WHITE } from 'constants/styling/theme'
import { Box, BoxProps, styled, useMediaQuery } from '@mui/material'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import React, { MouseEvent, ReactNode, useMemo } from 'react'

import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
import { useTranslation } from 'react-i18next'
import { Nullable } from 'models/helpers'
import { VisualClientDTO } from 'models/visuals'

/** Props for the ExpandedVisual component. */
type Props = {
  /** Whether the preview image is selected or not */
  isSelected?: boolean
  /** Whether the preview image is selectable or not */
  isSelectable?: boolean
  /** Whether the gallery image can be marked as favorite or not */
  isFavoriteSelectable?: boolean
  /** Whether the gallery image is marked as favorite or not */
  isFavorite?: boolean
  /** Signed Url for displaying image in popup */
  signedUrl?: string
  /** OnSelect action to be called */
  onSelect?: (e: MouseEvent) => void
  /** onClick action to toggle favorite image */
  onClickFavButton?: () => void
  /** Metadata of a visual */
  visualMetadata?: Nullable<VisualClientDTO>
}

/** Props for the Visual Box component. */
type VisualBoxProps = {
  signedUrl?: string
  isSelected?: boolean
  isSelectable?: boolean
} & BoxProps

/** Props for the Visual IconButton component. */
type VisualIconButtonProps = {
  isMobileView: boolean
  isSelected?: boolean
  children: ReactNode
} & IconButtonProps

/** Visual Box component with custom styles */
const StyledVisualBox = styled(({ signedUrl, isSelected, isSelectable, ...defaultProps }: VisualBoxProps) => (
  <Box {...defaultProps} />
))(({ signedUrl, isSelected, isSelectable }) => ({
  position: 'relative',
  aspectRatio: '3/2',
  maxHeight: '100%',
  width: '100%',
  borderRadius: '.8rem',
  backgroundColor: BEIGE_600,
  backgroundPosition: 'center center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundImage: signedUrl && `url('${signedUrl}')`,
  transition: 'background .15s ease',
  outline: isSelected ? `solid .4rem ${BLUE_400}` : '',
}))

/** Visual IconButton component with custom styles */
const StyledIconButton = styled(({ isMobileView, isSelected, children, ...defaultProps }: VisualIconButtonProps) => (
  <IconButton {...defaultProps}>{children}</IconButton>
))(({ isMobileView, isSelected }) => ({
  height: isMobileView ? '2.4rem' : '3.2rem',
  width: isMobileView ? '2.4rem' : '3.2rem',
  border: `1px solid ${isSelected ? BLUE_600 : BEIGE_700}`,
  padding: '.8rem',
  backgroundColor: isSelected ? BLUE_100 : WHITE,
  color: BLUE_600,
  ':hover': { backgroundColor: WHITE }
}))

/**
 * @component
 * Expanded Visual block in popup
 *
 * @example
 * <ExpandedVisual isSelected={isSelected} isSelectable={isSelectable} onSelect={onSelect} onClickFavButton={() => {}} />
 */
export const ExpandedVisual: React.FC<Props> = ({
  signedUrl,
  isSelected,
  isSelectable,
  isFavorite,
  isFavoriteSelectable,
  onSelect,
  onClickFavButton,
  visualMetadata
}) => {
  const { t } = useTranslation(['gallery'])
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)

  const floorPlannerLink = useMemo(() => {
    return visualMetadata?.floorPlanSelfEditUrl
  }, [visualMetadata])


  return (
    <StyledVisualBox
      signedUrl={signedUrl}
      isSelected={isSelected}
      isSelectable={isSelectable}
    >

      {isSelectable &&
        <StyledIconButton
          isMobileView={isMobileView}
          isSelected={isSelected}
          sx={{
            position: 'absolute',
            top: '.8rem',
            left: '.8rem'
          }}
          onClick={onSelect}
        >
          {isSelected && <CheckRoundedIcon fontSize="large" />}
        </StyledIconButton>
      }

      {isFavoriteSelectable &&
        <StyledIconButton
          isMobileView={isMobileView}
          sx={{
            position: 'absolute',
            top: '.8rem',
            right: '.8rem'
          }}
          onClick={(e) => {
            e.preventDefault()
            onClickFavButton?.()
          }}
        >
          {isFavorite ?
            <StarIcon sx={{ color: BLUE_600 }} />
            :
            <StarBorderIcon sx={{ color: BLUE_600 }} />
          }
        </StyledIconButton>
      }

      {!!floorPlannerLink &&
        <StyledIconButton
          isMobileView={isMobileView}
          isSelected={isSelected}
          sx={{
            position: 'absolute',
            top: '.8rem',
            right: 'calc(26px + 1.5rem)'
          }}
          onClick={e => e.stopPropagation()}
        >
          <a
            href={floorPlannerLink}
            target="_blank"
            rel="noopener noreferrer"
            title={t('edit_floor_plan')}
          >
            <EditIcon fontSize="large" sx={{ color: BLUE_600 }} />
          </a>
        </StyledIconButton>
      }

    </StyledVisualBox>
  )
}
