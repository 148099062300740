import { FC, ReactNode, useCallback } from 'react'
import { RenovationProductsContextProvider, StyleSelectionContextProvider, StyleSelectionOrigin, VirtualFurnitureStylesFilterContextProvider } from 'components/common/StagingFlow/main/contexts'

import { RoomAPIContextProvider } from 'components/contexts/RoomAPI.context'
import { RoomInventoryContextProvider } from 'components/contexts/RoomInventory.context'
import { StagingDecorationsProvider } from 'components/contexts/StagingDecorations.context'
import { VirtualFurnitureDataContextProvider } from 'components/contexts/VirtualFurnitureData.context'
import { VirtualFurnitureUpgradesContextProvider } from 'components/contexts/VirtualFurnitureUpgradesContext'
import { useGalleryStagingVisualSelection } from './GalleryStagingVisualSelection.context'
import { useGalleryUpsellProducts } from './GalleryStagingFlowUpsellProducts.context'

/**
 * Stack of providers for gallery staging
 * 
 * @example
 * <GalleryStagingFlowStackProvider>
 *   <Consumer />
 * </GalleryStagingFlowStackProvider>
 */
export const GalleryStagingFlowStackProvider: FC<{
  children?: ReactNode
}> = ({
  children
}) => {
    const { selectedVisuals } = useGalleryStagingVisualSelection()
    const { upsellRenovationProduct } = useGalleryUpsellProducts()

    const getImageUrlResolver = useCallback((visualId: string) => {
      const visual = selectedVisuals[visualId]

      return visual?.url
    }, [selectedVisuals])

    return (
      <RoomInventoryContextProvider>
        <RoomAPIContextProvider imageUrlResolver={getImageUrlResolver}>
          <VirtualFurnitureDataContextProvider>
            <VirtualFurnitureUpgradesContextProvider>
              <RenovationProductsContextProvider renovationProduct={upsellRenovationProduct}>
                <StyleSelectionContextProvider origin={StyleSelectionOrigin.GALLERY}>
                  <VirtualFurnitureStylesFilterContextProvider>
                    <StagingDecorationsProvider>
                      {children}
                    </StagingDecorationsProvider>
                  </VirtualFurnitureStylesFilterContextProvider>
                </StyleSelectionContextProvider>
              </RenovationProductsContextProvider>
            </VirtualFurnitureUpgradesContextProvider>
          </VirtualFurnitureDataContextProvider>
        </RoomAPIContextProvider>
      </RoomInventoryContextProvider>
    )
  }
