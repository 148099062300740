import { PlatformUser, SubscriptionPlan, UserWorkspaceMembershipList } from 'models/user'
import { UserIsAuth0User, UserIsPlatformUserClient } from 'utils/typeguards'

import { DEBOUNCE_TIMEOUT } from 'constants/application'
import { Auth0User } from 'models/auth'
import { ActiveSubscriptionStatuses } from 'models/user/userWorkspaceDTOs'
import { getRolesFromUser } from 'utils/auth'
import initializeIntercom from '../external/intercom'

export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID

/** Type used for intercom configuration */
type intercomSettings = {
  [key: string]: any
  /** Intercom APP ID */
  app_id: string | undefined
  /** Full name */
  name: string | undefined
  /** Email address */
  email: string | undefined
  /** Current user ID */
  user_id: string | undefined
  /** Timestamp when was Intercom created */
  // created_at: number | undefined
  /** Whether to hide the launcher icon/button/link */
  hide_default_launcher: boolean

  // Custom fields
  /** User country */
  ['Country']?: string
  /** User language */
  ['Language']?: string
  /** User roles */
  ['Roles']?: string
}

/** Type used for window.Intercom */
type intercomType = undefined | ((event: string, method?: string | {
  [key: string]: any
}, data?: {
  [key: string]: any
}) => void)

/** Declare the global variable for window.intercomSettings */
declare global {
  interface Window {
    intercomSettings: intercomSettings
    Intercom: intercomType
  }
}

window.intercomSettings = {
  app_id: undefined,
  name: undefined,
  email: undefined,
  user_id: undefined,
  // created_at: undefined,
  hide_default_launcher: true,
}

export const intercomController: {
  instance: intercomType
} = {
  instance: undefined,
}

export const trackIntercomUser = (user: Auth0User | PlatformUser) => {
  if (!user) return

  if (user.email) window.intercomSettings.user_id = user.email
  if (user.email) window.intercomSettings.email = user.email
  if (user.name) window.intercomSettings.name = user.name
  if (user.country) window.intercomSettings['Country'] = user.country
  if (user.language) window.intercomSettings['Language'] = user.language
  if (UserIsAuth0User(user)) {
    const roles = getRolesFromUser(user)
    const rolesString = Array.from(roles.all.values()).join(', ')
    window.intercomSettings['Roles'] = rolesString
  } else {
    if (UserIsPlatformUserClient(user)) {
      const { workspaces } = user
      const nonPersonalWorkspaces = workspaces.filter(workspace => workspace.plan !== SubscriptionPlan.PERSONAL)
      const subscribedWorkspaces = nonPersonalWorkspaces.filter(workspace => ActiveSubscriptionStatuses.has(workspace.status))
      const subscribedWorkspacesIds = subscribedWorkspaces.map(workspace => workspace.id).join(',')
      window.intercomSettings['subscribedWorkspaceIDs'] = subscribedWorkspacesIds
    }
  }
  // window.intercomSettings.hide_default_launcher = true
  // window.intercomSettings.created_at = Date.now()
  window.setTimeout(() => {
    if (window.Intercom) {
      window.Intercom('update', window.intercomSettings)
      // intercomController.instance('show')
    }
  }, DEBOUNCE_TIMEOUT)
}

/** Tracks user workspaces */
export const trackIntercomUserWorkspaces = (data: UserWorkspaceMembershipList) => {
  const { workspaces } = data
  const nonPersonalWorkspaces = workspaces.filter(workspace => workspace.plan !== SubscriptionPlan.PERSONAL)
  const subscribedWorkspaces = nonPersonalWorkspaces.filter(workspace => ActiveSubscriptionStatuses.has(workspace.workspaceStatus))
  const subscribedWorkspacesIds = subscribedWorkspaces.map(workspace => workspace.workspaceId).join(',')
  window.intercomSettings['subscribedWorkspaceIDs'] = subscribedWorkspacesIds

  window.setTimeout(() => {
    if (window.Intercom) {
      window.Intercom('update', window.intercomSettings)
    }
  }, DEBOUNCE_TIMEOUT)
}

export const unTrack = () => {
  if (!intercomController.instance) return
  window.intercomSettings.name = undefined
  window.intercomSettings.email = undefined
  window.intercomSettings.user_id = undefined
  window.intercomSettings['Roles'] = undefined
  window.intercomSettings['subscribedWorkspaceIDs'] = undefined
  // window.intercomSettings.hide_default_launcher = true
  window.setTimeout(() => {
    if (window.Intercom) {
      window.Intercom('update', window.intercomSettings)
      // window.Intercom('hide')
      // window.Intercom('shutdown')
    }
  }, DEBOUNCE_TIMEOUT)
}

export const trackIntercomEvent = (eventKey: string, metadata?: Record<string, unknown>) => {
  if (!window.Intercom || !intercomController.instance) {
    console.warn('Intercom instance is not initialized! Event will not be executed.')
    return
  }
  window.Intercom('trackEvent', eventKey, metadata)
}

export const initIntercom = () => {
  if (INTERCOM_APP_ID) {
    window.intercomSettings.app_id = INTERCOM_APP_ID
    // window.intercomSettings.created_at = Date.now()
    window.intercomSettings.api_base = 'https://api-iam.eu.intercom.io' // Use EU region
    intercomController.instance = initializeIntercom(INTERCOM_APP_ID)
    if (window.Intercom) intercomController.instance = window.Intercom
    window.setTimeout(() => {
      if (window.Intercom) {
        intercomController.instance = window.Intercom
        window.Intercom('boot', window.intercomSettings)
        // window.Intercom('hide')
      }
    }, 0)
    return true
  }
  return false
}
