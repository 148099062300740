import { BEIGE_500, GRAY_700, GRAY_900 } from 'constants/styling/theme'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import React from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

/**
 * @component
 * EmptyThread component displays a message when there are no threads available.
 *
 * @example
 * <EmptyThread title="Heyoo!" description="I'm hungy because I didn't have breakfast today :(" />
 */
export const EmptyThread: React.FC = () => {

  const { t } = useTranslation(['threads'])

  return (
    <BorderBoxWrapper
      gap={1}
      padding={2}
      height={250}
      marginTop={2}
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      backgroundColor={BEIGE_500}
    >

      <Typography variant='text-md' fontWeight={600} color={GRAY_900} textAlign="center">
        {t('empty_thread.title')}
      </Typography>

      <Typography variant='text-sm' color={GRAY_700} textAlign="center">
        {t('empty_thread.description')}
      </Typography>

    </BorderBoxWrapper>
  )
}
