import { phoneSupportNumber, phoneSupportNumberToHref } from 'constants/contacts/support'

import { Country } from 'constants/country/country'
import Modal from 'components/common/Modals/Modal/Modal'
import { SetStateAction } from 'react'
import { countryToFlag } from 'constants/assets/flags'
import styles from './ContactUsPopup.module.sass'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props for ContactUsPopup component
 */
export interface Props {
  /** Whether the Modal with Contact Us info should be open */
  isContactUsOpen: boolean
  /** Set ContactPopup open/close state callback */
  setIsContactUsOpen: (value: SetStateAction<boolean>) => void
}

/**
 * @component ContactUsPopup displayed in Help Center dropdown
 * @example <ContactUsPopup />
 */
export const ContactUsPopup: React.FC<Props> = ({ isContactUsOpen, setIsContactUsOpen }) => {
  const { t } = useTranslation('topbar')

  return (
    <Modal
      modalContentClassName={styles.contactUsPopup}
      isOpen={isContactUsOpen}
      title={t('contact_us')}
      onClickOutside={() => setIsContactUsOpen(!isContactUsOpen)}
      onClose={() => setIsContactUsOpen(!isContactUsOpen)}
      hasHeaderContentDivider
    >
      <div className={styles.container}>

        <div className={styles.infoContainer}>

          <strong className={styles.supportHours}>
            {t('support_hours')}
          </strong>

          <span className={styles.officeHours}>
            {t('office_hours')}
          </span>

          <em className={styles.chatbotInfo}>
            {t('chatbot_info')}
          </em>

        </div>

        {Object.values(Country)
          .filter(countryCode => phoneSupportNumber[countryCode])
          .map((countryCode) => {
            const phoneSupport = phoneSupportNumber[countryCode]
            const Flag = countryToFlag[countryCode]
            return (
              <a key={countryCode} href={phoneSupportNumberToHref(phoneSupport)} className={styles.phone}>
                <Flag className={styles.countryFlag} />
                {phoneSupport}
              </a>
            )
          })}

      </div>
    </Modal>
  )
}
