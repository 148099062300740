import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'

import { ExpandableCard } from '../ExpandableCard/ExpandableCard'
import classnames from 'classnames'
import styles from './CheckCard.module.sass'

export type CheckCardCheckboxType = 'circle' | 'square'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Cypress data */
  'data-cy'?: string
  /** The additional classes to append */
  className?: string
  /** Optional content to display as expandable when checkbox checked */
  expandableContent?: ReactNode
  /** Checkbox appearance if undefined, there is no checkbox */
  checkbox?: CheckCardCheckboxType
  /** Checkbox input name */
  inputName?: string
  /** Checkbox input ID */
  inputID?: string
  /** Checkbox input value */
  inputValue?: string
  /** Whether the checkbox is radio instead */
  isRadio?: boolean
  /** Whether the card should be highlighted */
  highlighted?: boolean
  /** OnCheck action to be called */
  onCheck?: (checked: boolean) => void
  /** Whether the checkbox should be checked */
  checked?: boolean
  /** Whether the checkbox should be checked by default */
  defaultChecked?: boolean
  /** Disables checkbox selection and applies styling */
  disabled?: boolean
  /** Disables checkbox selection without applying styling */
  disabledSelection?: boolean
  /** Whether click inside the element triggers the checkbox */
  isHeaderClickable?: boolean
  /** Custom class for expandable content wrapper */
  expandableContentClassName?: string
  /** Optional children rendered in the header of the card */
  children?: ReactNode
}

/**
 * @component
 * Displays Expandable card with added checkbox which expands/closes automatically based on the checkbox value.
 * Value is handled internally by default, but can be handled by custom function.
 * Externally held value can be passed down via prop and will have priority.
 * 
 * @example
 * <CheckCard expandableContent={contentNode}>
 *   {headerNode}
 * </CheckCard>
 */
export const CheckCard: FC<Props> = ({
  'data-cy': dataCy,
  className = '',
  expandableContentClassName,
  expandableContent,
  checkbox,
  inputName,
  inputID,
  inputValue,
  isRadio = false,
  highlighted = false,
  onCheck,
  checked,
  defaultChecked,
  disabled = false,
  disabledSelection = false,
  isHeaderClickable = false,
  children,
}) => {
  let shouldBeChecked = false
  if (defaultChecked !== undefined) shouldBeChecked = defaultChecked
  if (checked !== undefined) shouldBeChecked = checked
  const [isChecked, setChecked] = useState<boolean>(shouldBeChecked)
  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!onCheck) setChecked(e.target.checked)
    onCheck && onCheck(e.target.checked)
  }
  const checkboxRef = useRef<HTMLLabelElement>(null)
  const checkboxInputRef = useRef<HTMLInputElement>(null)
  const headerClick = (e: React.MouseEvent<HTMLDivElement> | any) => {
    if (e.target === checkboxRef.current || checkboxRef.current?.contains(e.target)) return
    checkboxRef.current?.click()
  }

  useEffect(() => {
    if (checked !== undefined) setChecked(checked)
  }, [checked])


  return (
    <ExpandableCard
      data-cy={dataCy}
      className={`${styles.checkcard} ${className}`}
      isExpanded={isChecked}
      isDisabled={disabled}
      isHighlighted={highlighted}
      expandableContentClassName={expandableContentClassName}
      expandableContent={expandableContent &&
        <div className={classnames(
          styles.expandableContent,
          {
            [styles.hasPaddingLeft]: !!checkbox,
          }
        )}>
          {expandableContent}
        </div>
      }
      isHeaderClickable={!disabledSelection && isHeaderClickable}
      onHeaderClick={headerClick}
    >
      <div className={`${styles.header} ${disabled ? styles.disabled : ''}`}>
        {checkbox &&
          <label
            className={`checkbox ${checkbox === 'square' ? checkbox : ''}`}
            htmlFor={inputID ? inputID : undefined}
            ref={checkboxRef}>
            <input
              type={isRadio ? 'radio' : 'checkbox'}
              name={inputName ? inputName : undefined}
              id={inputID ? inputID : undefined}
              value={inputValue ? inputValue : undefined}
              checked={isChecked}
              onChange={handleCheck}
              disabled={disabled || disabledSelection}
              ref={checkboxInputRef} />
            <span className="checkmark"></span>
          </label>
        }
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </ExpandableCard>
  )
}
