import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { ActionRequest, StatusResponseBody } from 'models/redux'

import { APIRequestState } from 'constants/API'
import { APIRequest } from 'models/API'
import { UrlDTO } from 'models/visuals'
import { createAPIAction } from 'redux/Helpers'

export interface ActionUploadWatermarkLogo extends ActionRequest {
  payload: {
    workspaceId: string
    file: File
    request: APIRequest<UrlDTO | null | StatusResponseBody>
    signedUrl?: string
    progress?: number
  },
}

/** Action which initializes upload of user's Watermark logo */
export function uploadWatermarkLogo(workspaceId: string, file: File): ActionUploadWatermarkLogo {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.UPLOAD_WATERMARK_LOGO], {
    workspaceId,
    file,
    request: new APIRequest(APIRequestState.RUNNING)
  })
}

/** Action that purges upload of user's uploaded Watermark logo */
export function purgeUploadWatermarkLogo(): ActionUploadWatermarkLogo {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.UPLOAD_WATERMARK_LOGO], {
    request: new APIRequest(APIRequestState.BEFORE_START)
  })
}
