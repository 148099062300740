import { useSaveAndGenerateMarketingMaterial, useSaveMarketingMaterial, useSaveMarketingPropertyDetails, useSavedMarketingMaterials } from 'dataQueries/visualsMarketing.query'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { EntityKeys, QueryType } from 'utils/reactQuery'

import { useQueryClient } from '@tanstack/react-query'
import { useUserData } from 'components/contexts/UserDataContext'
import { useGalleryAssignment } from 'components/pages/Gallery/_main/contexts/GalleryAssignment.context'
import { useGalleryDeal } from 'components/pages/Gallery/_main/contexts/GalleryDeal.context'
import constate from 'constate'
import { logAnalyticsEvent } from 'utils/analytics'
import { VisualsMarketingTargetPlatform } from '../VisulalsMarketing.constants'
import { useVisualsMarketingForm } from './VisualsMarketing.form'

export const [MarketingMaterialsContextProvider, useMarketingMaterials] = constate(() => {

  const client = useQueryClient()
  const { currentUserWorkspace, baseUserData } = useUserData()
  const { assignmentData } = useGalleryAssignment()

  const { dealId } = useGalleryDeal()
  const { getSerializedData } = useVisualsMarketingForm()

  const savePropertyDetails = useSaveMarketingPropertyDetails()

  const saveBrochure = useSaveMarketingMaterial()
  const saveLinkedin = useSaveMarketingMaterial()
  const saveInstagram = useSaveMarketingMaterial()

  const generateBrochure = useSaveAndGenerateMarketingMaterial(VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE)
  const generateInstagram = useSaveAndGenerateMarketingMaterial(VisualsMarketingTargetPlatform.INSTAGRAM)
  const generateLinkedin = useSaveAndGenerateMarketingMaterial(VisualsMarketingTargetPlatform.LINKEDIN)

  const savedMarketingMaterials = useSavedMarketingMaterials(dealId)
  const marketingMaterials = useMemo(() => savedMarketingMaterials.data || {}, [savedMarketingMaterials.data])


  /** Map of generate mutations */
  const generators = useMemo<Record<VisualsMarketingTargetPlatform, typeof generateInstagram>>(() => ({
    [VisualsMarketingTargetPlatform.INSTAGRAM]: generateInstagram,
    [VisualsMarketingTargetPlatform.LINKEDIN]: generateLinkedin,
    [VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE]: generateBrochure,
  }), [generateBrochure, generateInstagram, generateLinkedin])

  /** Map of edit mutations */
  const editors = useMemo<Record<VisualsMarketingTargetPlatform, typeof saveBrochure>>(() => ({
    [VisualsMarketingTargetPlatform.INSTAGRAM]: saveInstagram,
    [VisualsMarketingTargetPlatform.LINKEDIN]: saveLinkedin,
    [VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE]: saveBrochure,
  }), [saveBrochure, saveInstagram, saveLinkedin])

  /** Map of opened edit modes */
  const [openedEdits, setOpenedEdits] = useState<Record<VisualsMarketingTargetPlatform, boolean>>({
    [VisualsMarketingTargetPlatform.INSTAGRAM]: false,
    [VisualsMarketingTargetPlatform.LINKEDIN]: false,
    [VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE]: false,
  })

  /** Map of values for editing inputs */
  const [editForms, setEditForms] = useState<Record<VisualsMarketingTargetPlatform, string>>({
    [VisualsMarketingTargetPlatform.INSTAGRAM]: '',
    [VisualsMarketingTargetPlatform.LINKEDIN]: '',
    [VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE]: '',
  })

  /** Flag for initial hydration */
  const isHydrated = useRef(false)

  /** Handler for edit form field change */
  const onEditFieldChange = useCallback((platform: VisualsMarketingTargetPlatform, text: string) => {
    setEditForms((prev) => ({
      ...prev,
      [platform]: text
    }))
  }, [])

  /** Flips openedEdit switch for provided platform  to true and prefills data from fetched materials */
  const openEdit = useCallback((platform: VisualsMarketingTargetPlatform) => {
    setEditForms((prev) => ({
      ...prev,
      [platform]: marketingMaterials[platform]?.text || ''
    }))

    setOpenedEdits((prev) => ({
      ...prev,
      [platform]: true
    }))
  }, [marketingMaterials])

  /** Flips openedEdit switch for provided platform  to false */
  const closeEdit = useCallback((platform: VisualsMarketingTargetPlatform) => {
    setOpenedEdits((prev) => ({
      ...prev,
      [platform]: false
    }))
  }, [])

  /** Starts generateText flow for target platform. If shouldSave is false, saveDetails step will be skipped */
  const generateText = useCallback((platform: VisualsMarketingTargetPlatform, isReGenerate: boolean, shouldSave = true) => {
    if (!dealId || dealId <= 0) return

    const data = getSerializedData()
    if (!data) return

    if (isReGenerate) {
      logAnalyticsEvent('Re-Generate Text', {
        userEmail: baseUserData?.email,
        planId: currentUserWorkspace?.id,
        planName: currentUserWorkspace?.name,
        assignmentId: assignmentData?.id,
        targetPlatform: platform,
      })
    }

    generators[platform]?.mutate({
      data: data.propertyInfo,
      payload: data.textSettings,
      orderId: dealId,
      shouldSave,
    })
  }, [assignmentData?.id, baseUserData?.email, currentUserWorkspace?.id, currentUserWorkspace?.name, dealId, generators, getSerializedData])

  /** Saves property details */
  const saveDetails = useCallback((onSuccess?: () => void) => {
    if (!dealId || dealId <= 0) return

    const data = getSerializedData()
    if (!data) return

    savePropertyDetails.mutate({
      orderId: dealId,
      data: data.propertyInfo,
      onSuccess,
    })
  }, [dealId, getSerializedData, savePropertyDetails])

  /** Saves changes of target platform text and closes Edit mode on success */
  const savePost = useCallback((platform: VisualsMarketingTargetPlatform) => {
    if (!dealId || dealId <= 0) return
    const data = marketingMaterials[platform]
    if (!data?.id) return

    editors[platform].mutate({
      orderId: dealId,
      id: data.id,
      text: editForms[platform],
      platform,
      onSuccess: () => closeEdit(platform)
    })

  }, [closeEdit, dealId, editForms, editors, marketingMaterials])

  // INIT HYDRATIONS
  useEffect(() => {
    if (savedMarketingMaterials.isFetched && !isHydrated.current) {
      isHydrated.current = true
    }
  }, [savedMarketingMaterials.isFetched])

  // PURGE ALL ON DESTROY
  useEffect(() => {
    return () => {
      savePropertyDetails.reset()
      saveBrochure.reset()
      saveLinkedin.reset()
      saveInstagram.reset()
      generateBrochure.reset()
      generateInstagram.reset()
      generateLinkedin.reset()
      client.resetQueries({ queryKey: [EntityKeys.VISUALS_MARKETING_PROPERTY_DETAILS, QueryType.GET, { orderId: dealId }] })
      isHydrated.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    marketingMaterials,
    generators,
    editors,
    openedEdits,
    editForms,
    savePropertyDetails,
    savedMarketingMaterials,
    saveDetails,
    generateText,
    openEdit,
    closeEdit,
    savePost,
    onEditFieldChange,
  }
})
