import { BEIGE_500, MOBILE_VIEW_QUERY } from 'constants/styling/theme'

import Box from '@mui/material/Box'
import Button from 'components/common/Button/Button'
import { EmptyContent } from 'components/common/EmptyContent'
import { FC } from 'react'
import styles from './ClientGalleryEmptyContent.module.sass'
import useMediaQuery from '@mui/material/useMediaQuery'

/**
 * @interface Props
 */
interface Props {
  /** Empty content box title */
  title: string
  /** Empty content box description */
  description?: string
  /** Button text */
  buttonText?: string
  /** Button click callback */
  onButtonClick?: () => void
}

/**
 * Client gallery empty content info box with custom props.
 * 
 * @example <ClientGalleryEmptyContent />
 */
export const ClientGalleryEmptyContent: FC<Props> = ({
  title,
  description,
  buttonText,
  onButtonClick
}) => {
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)

  return (
    <Box maxWidth="60rem" width="100%" margin="auto" marginTop="8rem">

      <EmptyContent
        title={title}
        description={description}
        padding={isMobileView ? '' : '1rem 8rem'}
        backgroundColor={BEIGE_500}
        button={buttonText &&
          <Button
            className={styles.button}
            type='primary'
            onClick={onButtonClick}
          >
            {buttonText}
          </Button>
        }
      />

    </Box>
  )
}
