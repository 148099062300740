import { ReactNode } from 'react'

/** Enum of available types of action popup */
export enum ActionPopupTypes {
  ALERT = 'ALERT',
  CONFIRM = 'CONFIRM',
}

/** Options received by action popup */
export interface ActionPopupOptions {
  title?: string
  alertCloseText?: string
  confirmDenyText?: string
  confirmAcceptText?: string
  confirmDisabledText?: string
  disableGlobalActionPopup?: boolean
  hideSecondaryButton?: boolean
}

/** Store for action popup */
export interface ActionPopupStore {
  body: string | ReactNode
  type: ActionPopupTypes | undefined
  isShown: boolean
  options: ActionPopupOptions
}
