import i18n, { transformLanguageToAuth0Value } from 'translations/i18n'

import { FC } from 'react'
import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { SignupCard } from './SignupCard/SignupCard.component'
import classNames from 'classnames'
import { getOnboardingCTLink } from 'utils/localization/getOnboardingCTLink'
import signup_client from 'assets/img/bg/signup_client.svg'
import signup_creative from 'assets/img/bg/signup_creative.svg'
import styles from './SignupPortal.module.sass'
import { useAuth0 } from 'utils/auth'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import Button from '../../../common/Button/Button'
import { useNavigate } from 'react-router-dom'
import { Path } from '../../../../constants/router'

export const SignupPortal: FC = () => {
  const { loginWithRedirect, isAuthenticated, user, logout } = useAuth0()
  const { t } = useTranslation(['signup_portal'])
  const navigate = useNavigate()

  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)
  const params = new URLSearchParams(window.location.search)

  const handleLogout = () => {
    logout({ returnTo: window.location.href })
  }

  return (
    <div className={classNames(styles.signupPortal, { [styles.mobile]: isMobileView })}>
      {isAuthenticated ? (
        <Stack direction="column" className={styles.userLoggedIn}>
          <h2>{t('sign_up')}</h2>

          <p>{t('user_logged_in_message', { username: user?.name })}</p>

          <Stack direction="row" alignItems="center" justifyContent="space-between" marginTop={2}>
            <Button type="primary" onClick={() => navigate(Path.INDEX)}>{t('continue_to_dashboard')}</Button>

            <Button type="secondary nobackground" onClick={handleLogout}>{t('authentication:log_out')}</Button>
          </Stack>
        </Stack>
      ) : (
        <>
          <SignupCard
            title={t('client')}
            image={signup_client}
            description={t('client_des')}
            button={t('client_signup')}
            onClick={() => {
              loginWithRedirect({
                mode: 'signUp',
                language: transformLanguageToAuth0Value(i18n.language),
                login_hint: params.get('userEmail') ?? undefined,
                appState: { targetUrl: Path.INDEX },
              })
            }}
          />

          <SignupCard
            title={t('creative')}
            image={signup_creative}
            description={t('creative_des')}
            button={t('creative_signup')}
            note={t('creative_note')}
            onClick={() => {
              window.open(getOnboardingCTLink(i18n.language), '_self', 'noopener noreferrer')
            }}
          />
        </>
      )}
    </div>
  )
}
