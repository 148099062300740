import { APIRequestState, Endpoint, RoleMimetype } from 'constants/API'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { actionTypeTupleTest, createAPIAction, generalFetch } from 'redux/Helpers'
import { all, select, takeEvery } from 'typed-redux-saga'
import { call, put } from 'redux-saga/effects'

import API from 'utils/API/API'
import { ActionDeleteVirtualVisit } from '.'
import { ActionListVisuals } from '../ListVisuals'
import { RootStore } from 'models/redux'
import { cloneDeep } from 'lodash'

/** Saga which deletes virtual visit */
export function* deleteVirtualVisitSaga(receivedAction: ActionDeleteVirtualVisit) {
  const { missionId } = receivedAction.payload
  const url = Endpoint.VISUAL_DELETE_VIRTUAL_VISIT
    .replace('{assignmentId}', encodeURI(missionId.toString()))
  const action: ActionDeleteVirtualVisit = yield generalFetch(ActionTypeAPIData.DELETE_VIRTUAL_VISIT, () => API.delete(url, {
    headers: {
      Accept: RoleMimetype.ADMIN,
    },
  }, {
    endpoint: Endpoint.VISUAL_DELETE_VIRTUAL_VISIT,
  }))

  const deleteVirtualVisitAction: ActionDeleteVirtualVisit = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }

  yield put(deleteVirtualVisitAction)

  if (action.payload.request.state !== APIRequestState.OK) return
  yield call(editFetchedVisuals, deleteVirtualVisitAction)
}

/** Saga which edits fetched visuals DTO */
function* editFetchedVisuals(receivedAction: ActionDeleteVirtualVisit) {
  const { missionId } = receivedAction.payload
  if (!receivedAction.payload.request.data) return
  const getAllFetchedVisuals = (state: RootStore) => state.APIData[ActionTypeAPIData.LIST_VISUALS]?.[missionId]
  const allFetchedVisuals = yield* select(getAllFetchedVisuals)
  if (!allFetchedVisuals) return

  const allFetchedVisualKeys = Object.keys(allFetchedVisuals)

  for (const key of allFetchedVisualKeys) {
    const allFetchedVisualsTypeClone = cloneDeep(allFetchedVisuals[key])
    if (!allFetchedVisualsTypeClone?.data) continue

    allFetchedVisualsTypeClone.data.virtualVisit = null

    const listVisualsAction = createAPIAction<ActionListVisuals>([ActionTypeAPIEvent.RECEIVED, ActionTypeAPIData.LIST_VISUALS], {
      missionId,
      type: key,
      request: allFetchedVisualsTypeClone,
    })

    yield put(listVisualsAction)
  }
}

/** Watcher of delete virtual visit actions */
export function* deleteVirtualVisitWatcher() {
  yield all([
    takeEvery((action: ActionDeleteVirtualVisit) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.DELETE_VIRTUAL_VISIT]), deleteVirtualVisitSaga),
  ])
}
