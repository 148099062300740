/** Url parameters enum */
export enum Params {
  OWNERSHIP = 'ownership',
  STATUS = 'status',
  USER = 'user',
  SEARCH = 'search',
  USERS = 'users',
  TYPE = 'type',
  SORT_DIRECTION = 'sort_direction',
  CREATION_DATE_START = 'creation_date_start',
  CREATION_DATE_END = 'creation_date_end',
  SHOOTING_DATE_START = 'shooting_date_start',
  SHOOTING_DATE_END = 'shooting_date_end',
  SORT_BY = 'sort_by',
}
