import { CSSProperties, FC } from 'react'

interface Props {
  /** Url for image */
  url: string
  /** Extra styles for the image component */
  sx?: CSSProperties
  /** Alt text - using url by default */
  alt?: string
  /** Border radius */
  borderRadius?: string
}

/**
 * Component for displaying simple image
 * @example <SimpleImage url="https://picsum.photos/200" />
*/
export const SimpleImage: FC<Props> = ({
  url,
  sx,
  alt,
  borderRadius = '8px'
}) => {
  return (
    <img
      style={{
        borderRadius,
        ...(sx ?? {}),
      }}
      src={url}
      alt={alt ?? url}
    />
  )
}
