import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { FileManipulationKey, VisualFileType } from 'constants/visual'
import { ActionRequest, StatusResponseBody } from 'models/redux'

import { CancelTokenSource } from 'axios'
import { APIRequest } from 'models/API'
import { UrlDTO } from 'models/visuals'
import { createAPIAction } from 'redux/Helpers'

type UploadType = 'resumable' | 'standard'

/** Describes general action to load visual */
export interface ActionLoadVisual extends ActionRequest {
  payload: {
    manipulation: FileManipulationKey
    missionId: string
    file: File
    type: VisualFileType
    request: APIRequest<UrlDTO | null | StatusResponseBody>
    signedUrl?: string
    cancelToken?: CancelTokenSource
    progress?: number
    originalFilename?: string
    replaces?: string
    droppedIn?: number
    uploadType: UploadType,
  },
}

/** Action which initializes upload of a file */
export function uploadVisual(missionId: string, file: File, type: Extract<VisualFileType, VisualFileType.RAW | VisualFileType.POST>, uploadType: UploadType): ActionLoadVisual {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.LOAD_VISUAL], {
    manipulation: FileManipulationKey.UPLOAD,
    missionId,
    file,
    type,
    droppedIn: Date.now(),
    request: new APIRequest(),
    uploadType
  })
}

/** Action which initializes upload of a file replacement */
export function uploadVisualReplacement(missionId: string, replaces: string, file: File, type: Extract<VisualFileType, VisualFileType.RAW | VisualFileType.POST>, uploadType: UploadType): ActionLoadVisual {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.LOAD_VISUAL], {
    manipulation: FileManipulationKey.UPLOAD,
    missionId,
    file,
    type,
    replaces,
    droppedIn: Date.now(),
    request: new APIRequest(),
    uploadType
  })
}

/** Action which initializes download of a file */
export function downloadVisual(missionId: string, filename: string, type: Exclude<VisualFileType, VisualFileType.RAW | VisualFileType.POST>, originalFilename?: string, droppedIn?: number): ActionLoadVisual {
  return createAPIAction<ActionLoadVisual>([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.LOAD_VISUAL], {
    manipulation: FileManipulationKey.DOWNLOAD,
    missionId,
    type,
    file: new File([], filename),
    originalFilename,
    droppedIn,
    request: new APIRequest()
  })
}

/** Action which initializes deletion of a file */
export function deleteVisual(missionId: string, filename: string, type: Extract<VisualFileType, VisualFileType.RAW | VisualFileType.POST>): ActionLoadVisual {
  return createAPIAction<ActionLoadVisual>([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.LOAD_VISUAL], {
    manipulation: FileManipulationKey.DELETE,
    missionId,
    type,
    file: new File([], filename),
    request: new APIRequest()
  })
}
