import { useRenovationProducts, useStyleSelection } from 'components/common/StagingFlow/main/contexts'
import { RoomInventory, StagingConfiguration, StagingRequestDTO, StagingUpsellInformationDTO } from 'models/virtualStaging'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { purgeSelectVisualsForStaging, selectVisualsForStaging } from 'redux/Individual/Visual/SelectVisualsForStaging'

import { useQueryClient } from '@tanstack/react-query'
import { useStyleSelectionContext } from 'components/common/StagingSelection'
import { useRoomAPI } from 'components/contexts/RoomAPI.context'
import { useRoomInventory } from 'components/contexts/RoomInventory.context'
import { APIRequestState } from 'constants/API'
import { AssignmentStage } from 'constants/assignment'
import { STAGING_PRODUCT_KINDS_PROPERTY_TYPES } from 'constants/product'
import { ActionTypeAPIData } from 'constants/redux'
import constate from 'constate'
import { AssignmentDTO } from 'models/assignment'
import { ProductDTO } from 'models/product'
import { RootStore } from 'models/redux'
import { getAPIRoomItemFromStagingRoom } from 'utils/serialization/getAPIRoomItemFromStagingRoom'
import { AssignmentDTOIsClientDTO } from 'utils/typeguards'
import { useGalleryAssignment } from '../../../../_main/contexts'
import { useGalleryStagingVisualSelection } from './GalleryStagingVisualSelection.context'

/** Enum of all the staging flow steps. */
export enum GalleryStagingStep {
  STAGING_LIST = 'STAGING_LIST',
  VISUAL_SELECTION = 'VISUAL_SELECTION',
  VISUAL_STAGING = 'VISUAL_STAGING',
}

/** Staging Flow steps with ascending weights to match intended order. */
export const stagingFlowStepsOrder: Record<GalleryStagingStep, number> = {
  STAGING_LIST: 0,
  VISUAL_SELECTION: 1,
  VISUAL_STAGING: 2,
}

export const [GalleryStagingFlowContextProvider, useGalleryStagingFlow] = constate(() => {

  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const { setActiveRoomIndex } = useStyleSelectionContext()
  const { selectedVisuals, setSelectedVisuals } = useGalleryStagingVisualSelection()
  const { createRoomObject, setRooms, roomInventory } = useRoomInventory()
  const { assignRoomToImage, unassignAll, allRoomsCompleted } = useRoomAPI()
  const { getRoomsWithRenovationProducts, renovationCartProduct } = useRenovationProducts()
  const { assignmentData } = useGalleryAssignment()
  const { allRooms } = useStyleSelection()

  const [stagingAssignmentId, setStagingAssignmentId] = useState<string | null>(null)
  const [assignmentsToStage, setAssignmentsToStage] = useState<AssignmentDTO[]>([])
  const [stagingProduct, setStagingProduct] = useState<ProductDTO | null>(null)
  const [activeStep, setActiveStep] = useState<GalleryStagingStep>(GalleryStagingStep.VISUAL_SELECTION)
  const [isAdvanceStagingPurchaseModalOpen, setIsAdvanceStagingPurchaseModalOpen] = useState<boolean>(false)

  const stagingRequest = useSelector((state: RootStore) => stagingAssignmentId
    ? state.APIData[ActionTypeAPIData.SELECT_VISUALS_FOR_STAGING][stagingAssignmentId]
    : undefined
  )

  const stagingRequestState = useMemo(() => stagingRequest?.state || APIRequestState.BEFORE_START, [stagingRequest])

  /** Remaining assignments with stagings which are not finished */
  const remainingStagingsCount = useMemo(() => {
    const unfinishedStagingAssignments = assignmentsToStage.filter(assignment =>
      assignment.stage === AssignmentStage.MISSION_ORDER_PLACED || assignment.stage === AssignmentStage.PRE_PRODUCTION
    )
    return unfinishedStagingAssignments.length
  }, [assignmentsToStage])

  /** Derives how many visuals have to be selected from staging product quantity */
  const requiredSelectionCount = useMemo(() => stagingProduct ? stagingProduct.quantity : 0, [stagingProduct])

  /** Returns the rooms ids with renovation products included */
  const roomsWithRenovationProducts = useMemo(() => getRoomsWithRenovationProducts(allRooms), [allRooms, getRoomsWithRenovationProducts])

  /**
   * Generates one room for each selected image.
   * Assigns room key of visual id and property type according to staging product.
   * Pushes rooms to inventory and assigns images to rooms 
   */
  const generateRoomsForSelectedImages = useCallback(() => {
    if (!stagingProduct) return false

    const rooms: RoomInventory = {}

    for (const visual of Object.values(selectedVisuals)) {

      if (!visual) continue

      rooms[visual.id] = roomInventory[visual.id] || createRoomObject(stagingProduct.id, {
        key: visual.id,
        propertyType: STAGING_PRODUCT_KINDS_PROPERTY_TYPES[stagingProduct.kind]
      })

      assignRoomToImage(visual.id, visual.id)
    }

    setRooms(rooms)

    return true
  }, [assignRoomToImage, createRoomObject, roomInventory, selectedVisuals, setRooms, stagingProduct])

  /** Resets states of image selection and staging */
  const cleanupStaging = () => {
    setStagingProduct(null)
    setStagingAssignmentId(null)
    setSelectedVisuals({})
    unassignAll()
    setRooms({})
    setActiveRoomIndex(0)
    setAssignmentsToStage([])
    setActiveStep(GalleryStagingStep.VISUAL_SELECTION)
    setIsAdvanceStagingPurchaseModalOpen(false)

    if (!stagingAssignmentId) return
    dispatch(purgeSelectVisualsForStaging(stagingAssignmentId))
  }

  /** Sets the staging product and stagingAssignmentId */
  const initializeStaging = (stagingAssignmentId: string, stagingProduct: ProductDTO) => {
    setStagingAssignmentId(stagingAssignmentId)
    setStagingProduct(stagingProduct)
  }

  /** Serializes staging configuration information into payload */
  const getStagingConfig = useCallback((upsellInformation?: StagingUpsellInformationDTO): StagingRequestDTO | undefined => {

    // Reduce room objects to Records of config items indexed by image id
    const roomDetails = allRooms.reduce((stagingMap, room) => {
      // typeguards
      if (room.roomTypes.isEmpty()) return stagingMap

      const roomConfig = getAPIRoomItemFromStagingRoom(room)

      const imageStyleMap: StagingConfiguration = room.images.reduce((imageMap, { id }) => ({
        ...imageMap,
        [id]: roomConfig
      }), {})

      return {
        ...stagingMap,
        ...imageStyleMap,
      }
    }, {})

    return {
      details: roomDetails,
      upsellInformation,
    }

  }, [allRooms])

  /** Completes the current staging. */
  const onStageVisuals = useCallback((upsellInformation?: { paymentIntentId?: string, billingProductId: number }) => {
    if (!assignmentData) return
    if (!AssignmentDTOIsClientDTO(assignmentData)) return
    if (activeStep !== GalleryStagingStep.VISUAL_STAGING) return
    if (!allRoomsCompleted) return
    if (!stagingAssignmentId) return

    let stagingConfig = null

    if (upsellInformation && renovationCartProduct) {
      stagingConfig = getStagingConfig({
        productId: renovationCartProduct.id,
        primaryBillingProductId: upsellInformation.billingProductId,
        stripePaymentIntentId: upsellInformation.paymentIntentId ?? ''
      })
    } else {
      stagingConfig = getStagingConfig()
    }
    if (!stagingConfig) return

    dispatch(selectVisualsForStaging(
      assignmentData.id,
      assignmentData.dealId,
      stagingAssignmentId,
      stagingConfig,
      queryClient
    ))
  }, [activeStep, allRoomsCompleted, assignmentData, dispatch, getStagingConfig, queryClient, renovationCartProduct, stagingAssignmentId])

  // NAVIGATION
  const stagingSteps = useMemo(() => {
    const defaultSteps = [GalleryStagingStep.VISUAL_SELECTION, GalleryStagingStep.VISUAL_STAGING]
    if (assignmentsToStage.length > 0) defaultSteps.push(GalleryStagingStep.STAGING_LIST)

    return defaultSteps.sort((aStep, bStep) => stagingFlowStepsOrder[aStep] - stagingFlowStepsOrder[bStep])
  }, [assignmentsToStage.length])
  const currentStepIndex = useMemo(() => stagingSteps.findIndex(step => step === activeStep), [activeStep, stagingSteps])
  const isLastStep = useMemo(() => activeStep === GalleryStagingStep.VISUAL_STAGING, [activeStep])

  const goNext = useCallback(() => {
    const nextStep = stagingSteps[currentStepIndex + 1]
    if (nextStep === GalleryStagingStep.VISUAL_STAGING) {
      generateRoomsForSelectedImages()
    }
    setActiveStep(stagingSteps[currentStepIndex + 1])
  }, [currentStepIndex, generateRoomsForSelectedImages, stagingSteps])

  const goBack = useCallback(() => {
    setActiveStep(stagingSteps[currentStepIndex - 1])
  }, [currentStepIndex, stagingSteps])

  const isVisualSelectionValid = useMemo(() => {
    return requiredSelectionCount === Object.values(selectedVisuals).length
  }, [requiredSelectionCount, selectedVisuals])

  const isVisualStagingValid = useMemo(() => {
    return allRoomsCompleted
  }, [allRoomsCompleted])

  const stepValidityMap = useMemo<Record<GalleryStagingStep, boolean>>(() => ({
    [GalleryStagingStep.STAGING_LIST]: false, // No need to enable next step on the staging list step
    [GalleryStagingStep.VISUAL_SELECTION]: isVisualSelectionValid,
    [GalleryStagingStep.VISUAL_STAGING]: isVisualStagingValid
  }), [isVisualSelectionValid, isVisualStagingValid])

  return {
    allRooms,
    activeStep,
    stagingProduct,
    requiredSelectionCount,
    stagingAssignmentId,
    stagingSteps,
    currentStepIndex,
    stepValidityMap,
    isLastStep,
    assignmentsToStage,
    roomsWithRenovationProducts,
    isAdvanceStagingPurchaseModalOpen,
    remainingStagingsCount,
    stagingRequest,
    stagingRequestState,
    setActiveStep,
    setAssignmentsToStage,
    getStagingConfig,
    generateRoomsForSelectedImages,
    initializeStaging,
    cleanupStaging,
    onStageVisuals,
    setIsAdvanceStagingPurchaseModalOpen,
    goNext,
    goBack,
  }
})
