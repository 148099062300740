import { useEffect, useMemo, useState } from 'react'

import { useUserData } from 'components/contexts/UserDataContext'
import constate from 'constate'
import { useGetPropertyDetail, useGetPropertyDetailWithObjectReferenceId } from 'dataQueries'
import { Nullable } from 'models/helpers'
import { PropertyType } from 'models/property'
import { GoogleAPIPlace } from 'models/purchaseFlow'
import { useBkbnSearchParams } from 'utils/hooks/useBkbnSearchParams'
import { getPropertyAddressFromGooglePlace } from 'utils/location/googlePlaceUtils'

export const [PurchaseFlowRealEstatePropertyContextProvider, usePurchaseFlowRealEstateProperty] = constate(() => {

  const { currentUserWorkspace, personalUserWorkspaces } = useUserData()

  const params = useBkbnSearchParams()

  const requestedPropertyId = useMemo(() => params.get('realtyId') ?? null, [params])
  const objectReferenceId = useMemo(() => params.get('objectReferenceId') ?? null, [params])
  const propertyWorkspaceId = useMemo(() => (currentUserWorkspace ?? personalUserWorkspaces[0])?.id, [currentUserWorkspace, personalUserWorkspaces])

  const getPropertyDetailQuery = useGetPropertyDetail(requestedPropertyId, propertyWorkspaceId, false)
  const getPropertyObjectReferenceQuery = useGetPropertyDetailWithObjectReferenceId(objectReferenceId, propertyWorkspaceId, false)

  // We want to use one query preferably with internal real estate property id
  const getPropertyQuery = useMemo(() => {
    if (requestedPropertyId) {
      return getPropertyDetailQuery
    } else {
      return getPropertyObjectReferenceQuery
    }
  },[getPropertyDetailQuery, getPropertyObjectReferenceQuery, requestedPropertyId])
  // We want to use one property data preferably with internal real estate property id
  const requestedPropertyData = useMemo(() => {
    if (requestedPropertyId) {
      return getPropertyDetailQuery.data
    } else {
      return getPropertyObjectReferenceQuery.data
    }
  }, [getPropertyDetailQuery.data, getPropertyObjectReferenceQuery.data, requestedPropertyId])

  const [realEstatePropertyId, setRealEstatePropertyId] = useState<string | null>(null)
  const [isPropertyPrefilled, setIsPropertyPrefilled] = useState<boolean | null>(null)
  const [selectedPropertyType, setSelectedPropertyType] = useState<Nullable<PropertyType>>(null)
  const [selectedAssignmentPlace, setSelectedAssignmentPlace] = useState<GoogleAPIPlace | null>(null)

  const realEstateProperty = useMemo(() => {
    let address: GoogleAPIPlace | null = null
    if (selectedAssignmentPlace) address = selectedAssignmentPlace

    return {
      propertyType: selectedPropertyType,
      address: getPropertyAddressFromGooglePlace(address ?? null),
    }
  }, [selectedAssignmentPlace, selectedPropertyType])

  // prefill data on realtyId
  useEffect(() => {
    if (isPropertyPrefilled !== null) return

    if (!requestedPropertyId && !objectReferenceId) {
      setIsPropertyPrefilled(false)
      return
    }

    if (getPropertyQuery.isError) {
      setIsPropertyPrefilled(false)
      return
    }

    if (getPropertyQuery.isSuccess && requestedPropertyData) {
      setSelectedPropertyType(requestedPropertyData.propertyType)
      setRealEstatePropertyId(requestedPropertyData.id)

      setIsPropertyPrefilled(true)
    }

    // Omit "isPropertyPrefilled"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPropertyQuery.isError, getPropertyQuery.isSuccess, objectReferenceId, requestedPropertyData, requestedPropertyId])

  return {
    selectedPropertyType,
    setSelectedPropertyType,
    selectedAssignmentPlace,
    setSelectedAssignmentPlace,
    realEstateProperty,
    realEstatePropertyId,
    isPropertyPrefilled,
    requestedPropertyData,
    getPropertyQuery,
  }
})
