import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { EntityKeys, QueryType, getMutation } from 'utils/reactQuery'

import { AxiosResponse } from 'axios'
import { FeeDTO } from 'models/fee'
import { StatusResponse } from 'models/redux'
import { useAPI } from 'utils/API'

enum Endpoints {
  ADMIN_REMOVE_CREATIVE = '/assignment/{assignmentId}/removeCreative',
  ADMIN_ONBOARD_CREATIVE = '/user/creative',
  ADMIN_RESEND_CREATIVE_INVITATION = '/user/resendInvitation',
  ADMIN_SELECT_CREATIVE = '/assignment/{assignmentId}/creative/{creativeId}/select',
}

// MUTATIONS

interface AdminRemoveCreativePayload {
  dealId: number
  assignmentId: string
}

interface AdminSelectCreativePayload {
  dealId: number
  assignmentId: string
  creativeId: string
  remuneration: FeeDTO,
  transportRemuneration: FeeDTO,
  bonus: FeeDTO,
}

interface AdminOnboardCreativePayload {
  email: string,
  language: string,
  timezone: string,
}

export function useAdminRemoveCreative() {
  const api = useAPI<Endpoints>()
  return getMutation<AxiosResponse<StatusResponse>, AdminRemoveCreativePayload>(
    ({ assignmentId }) => api.put(
      Endpoints.ADMIN_REMOVE_CREATIVE,
      { assignmentId },
      {},
      true
    ),
    (client, { dealId, assignmentId }) => {
      client.invalidateQueries({ queryKey: [EntityKeys.ORDER, QueryType.GET_ONE, { orderId: dealId }] })
      client.invalidateQueries({ queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET_ONE, { assignmentId }] })
      logAnalyticsEvent(AnalyticsEvent.CREATIVE_REMOVED_BY_ADMIN, { assignmentId })
    }
  )
}

export function useAdminSelectCreative() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, AdminSelectCreativePayload>(
    ({ creativeId, assignmentId, transportRemuneration, bonus, remuneration }) => api.post(
      Endpoints.ADMIN_SELECT_CREATIVE,
      { assignmentId, creativeId },
      {
        transportRemuneration,
        bonus,
        customBaseRemuneration: remuneration
      },
      true
    ),
    (client, { creativeId, assignmentId, transportRemuneration, bonus, remuneration, dealId }) => {
      client.invalidateQueries({ queryKey: [EntityKeys.ORDER, QueryType.GET_ONE, { orderId: dealId }] })
      client.invalidateQueries({ queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET_ONE, { assignmentId }] })
      logAnalyticsEvent(AnalyticsEvent.CREATIVE_SELECTED_BY_ADMIN, {
        assignmentId,
        creativeId,
        remuneration,
        transportRemuneration,
        bonus,
      })
    }
  )
}

export function useAdminOnboardCreative() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, AdminOnboardCreativePayload>(
    ({ email, language, timezone }) => api.post(
      Endpoints.ADMIN_ONBOARD_CREATIVE,
      {},
      {},
      true,
      {
        params: {
          email,
          language,
          timezone,
        },
      }
    ),
    (_, { email, language, timezone }) => {
      logAnalyticsEvent(AnalyticsEvent.CREATIVE_ONBOARDED_BY_ADMIN, {
        email,
        language,
        timezone,
      })
    }
  )
}

export function useAdminResendCreativeInvitation() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, AdminOnboardCreativePayload>(
    ({ email, language, timezone }) => api.post(
      Endpoints.ADMIN_RESEND_CREATIVE_INVITATION,
      {},
      {},
      true,
      {
        params: {
          email,
          language,
          timezone,
        },
      }
    )
  )
}
