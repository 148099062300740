import './Dropdown.sass'

import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { Power2, gsap } from 'gsap'
import { Transition, TransitionGroup } from 'react-transition-group'

import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import { IconType } from 'constants/assets'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** The additional classes to append */
  className?: string
  /** The additional classes to append to inner menu */
  innerMenuClassName?: string
  /** The side where is the dropdown inner menu attached to in relation to the button */
  attachment?: 'left-attached' | 'right-attached'
  /** Whether the dropdown closes after clicking on the dropdown button */
  closeAfterButtonClick?: boolean
  /** Whether the dropdown closes after clicking inside of it */
  closeAfterClickInside?: boolean
  /** A factory function producing <Button></Button> component, the function accepts 2 arguments: isOpen: boolean and onclick action */
  button?: (isOpen: boolean, action: (e: React.MouseEvent<Element, MouseEvent>) => void) => ReactNode
  children?: ReactNode
}

/**
 * @component A dropdown component, dropdown button can be determined by button property (passing custom button) or a buttonText and buttonType property, attachment property determines on which side of the button is the dropdown menu attached and children property is the content of a dropdown menu.
 * @example
 * <Dropdown>
 *  <Fragment>
 *    <a href="#profile"><Icon icon={IconType.PROFILE} /> Profile</a>
 *    <a href="#availability"><Icon icon={IconType.DATE} /> Availability</a>
 *    <a href="#billing"><Icon icon={IconType.FILE} /> Billing</a>
 *  </Fragment>
 * </Dropdown>
 * 
 * @example
 * <Dropdown button={(isOpen, action) => (
 *  <Button type="secondary" onClick={action}>
 *    <span>Menu</span>
 *    <Icon icon={IconType.CARET_DOWN} className={`caret ${isOpen ? 'up' : 'down'}`} />
 *  </Button>
 * )} attachment="right-attached">
 *  <Fragment>
 *    <a href="#profile"><Icon icon={IconType.PROFILE} /> Profile</a>
 *    <a href="#availability"><Icon icon={IconType.DATE} /> Availability</a>
 *    <a href="#billing"><Icon icon={IconType.FILE} /> Billing</a>
 *  </Fragment>
 * </Dropdown>
 */
const Dropdown: FC<Props> = ({
  className = '',
  innerMenuClassName = '',
  attachment = 'left-attached',
  closeAfterClickInside = true,
  closeAfterButtonClick = true,
  children,
  button = (isOpen, action) => (
    <Button type="secondary" onClick={action}>
      <Icon icon={IconType.CARET_DOWN} className={`caret ${isOpen ? 'up' : 'down'}`} />
    </Button>
  ),
}) => {
  // Open / Close logic
  /** inner menu element reference */
  const menuRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLDivElement>(null)
  const [showMenu, setShowMenu] = useState(false)

  /** action called upon dropdown menu closing */
  const closeMenuAction = useCallback((e: any) => {
    if (!closeAfterClickInside && menuRef.current?.contains(e.target)) return
    if (!closeAfterButtonClick && buttonRef.current?.contains(e.target)) return

    setShowMenu(false)
    document.removeEventListener('click', closeMenuAction)
  }, [closeAfterClickInside, closeAfterButtonClick])

  /** action called upon dropdown menu opening */
  const openMenuAction = useCallback((e: any) => {
    if (showMenu) return

    setShowMenu(true)
    window.setTimeout(() => {
      document.addEventListener('click', closeMenuAction)
    }, 0)
  }, [showMenu, closeMenuAction])

  // Unsubscribe event listener
  useEffect(() => {
    return () => {
      document.removeEventListener('click', closeMenuAction)
    }
  }, [closeMenuAction])

  // Animations
  /** timeline for open animations */
  const timeline_open = gsap.timeline({ paused: true })
  /** timeline for close animations */
  const timeline_close = gsap.timeline({ paused: true })


  /** animaton timeline for dropdown open effect */
  const openEffect = (node: HTMLElement, appears: boolean) => {
    timeline_open.totalProgress(1).clear(true).totalProgress(0)
    timeline_open.fromTo(node, { height: 0, ease: Power2.easeOut }, { height: 'auto', ease: Power2.easeOut, duration: 0.3 })
    node.childNodes.forEach((child, key) => {
      timeline_open.fromTo(child, { autoAlpha: 0, y: 20, ease: Power2.easeOut }, { autoAlpha: 1, y: 0, ease: Power2.easeOut, duration: 0.3 }, key * 0.075)
    })
    timeline_open.play()
  }

  /** animaton timeline for dropdown close effect */
  const closeEffect = (node: HTMLElement) => {
    timeline_close.totalProgress(1).clear(true).totalProgress(0)
    node.childNodes.forEach((child, key) => {
      timeline_close.to(child, { autoAlpha: 0, y: 20, ease: Power2.easeIn, duration: 0.2 }, (node.childNodes.length - 1 - key) * 0.05)
    })
    timeline_close.to(node, { height: 0, ease: Power2.easeIn, duration: node.childNodes.length * 0.05 + 0.2 }, 0)
    timeline_close.play()
  }

  return (
    <div className={`dropdown ${attachment} ${className}`}>
      <div
        className="dropdown-button"
        ref={buttonRef}
      >
        {button(showMenu, openMenuAction)}
      </div>
      <TransitionGroup component={null}>
        {showMenu &&
          <Transition
            appear={true}
            in={showMenu}
            onEnter={(node, appears) => openEffect(node, appears)}
            onExit={(node) => closeEffect(node)}
            timeout={{ enter: 0, exit: 700 }}
          >
            <div
              className={`inner-menu ${innerMenuClassName}`}
              ref={menuRef}
            >
              {children}
            </div>
          </Transition>
        }
      </TransitionGroup>
    </div>
  )
}

export default Dropdown
