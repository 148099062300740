/** Rating Type enum */
export enum RatingType {
  /** Rating of the received visuals */
  VISUALS = 'VISUALS',
  /** Rating of the creative for the particular shooting */
  CREATIVE = 'CREATIVE',
  /** Rating of the BKBN platform services */
  SERVICE = 'SERVICE',
}

/** An enumeration of values of all available rating emojis */
export enum EmojiValue {
  UNHAPPY = 1,
  NEUTRAL = 2,
  HAPPY = 3,
}
