import { Fragment, useLayoutEffect, useState } from 'react'
import { changeLocale, purgeChangeLocale } from 'redux/Individual/User/ChangeLocale'
import { useDispatch, useSelector } from 'react-redux'

import { ActionTypeAPIData } from 'constants/redux'
import Button from 'components/common/Button/Button'
import Dropdown from 'components/common/Dropdown/Dropdown'
import Icon from 'components/common/Icon/Icon'
import { IconType } from 'constants/assets'
import { Language } from 'translations/Language'
import { RequestStatus } from 'components/common/RequestStatus'
import { RootStore } from 'models/redux'
import { timezoneNames } from 'utils/time'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @component User Preferences displayed in Profile page
 * @example
 * <Preferences userData={userMeRequest.data} />
 */
export const Preferences: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['profile', 'language'])

  const { baseUserData } = useUserData()

  const changeLocaleRequest = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.USER_CHANGE_LOCALE])

  const [currentLanguage, setCurrentLanguage] = useState<Language>()
  const [currentTimezone, setCurrentTimezone] = useState<string>()

  // purge change locale request
  useLayoutEffect(() => {
    dispatch(purgeChangeLocale())
    return () => {
      dispatch(purgeChangeLocale())
    }
  }, [dispatch])

  useLayoutEffect(() => {
    if (baseUserData?.language) setCurrentLanguage(Language[(baseUserData.language as Language)])
  }, [baseUserData])

  useLayoutEffect(() => {
    if (baseUserData?.defaultTimezone) setCurrentTimezone((baseUserData.defaultTimezone))
  }, [baseUserData])

  return (
    <Fragment>
      <h2 className="decorated">{t('preferences.title')}</h2>
      <div className="section align-start">
        <div className="input-group">
          <label htmlFor="language">{t('language.label')}</label>
          <Dropdown button={(isOpen, action) => (
            <Button type="secondary" onClick={action} textAndIcon={true}>
              <span>{(t(`language:${(currentLanguage || '').toUpperCase()}`) || '').toString()}</span>
              <Icon icon={IconType.CARET_DOWN} className={`caret ${isOpen ? 'up' : 'down'}`} />
            </Button>
          )}>
            <Fragment>
              {Object.values(Language).map(lang => {
                const selected = lang === currentLanguage
                return (
                  <button
                    key={lang}
                    className={`language-button ${selected ? 'selected' : ''}`}
                    onClick={() => {
                      setCurrentLanguage(lang)
                      dispatch(changeLocale(lang))
                    }}
                  >
                    {t(`language:${lang.toUpperCase()}`, lang)}
                  </button>
                )
              })}
            </Fragment>
          </Dropdown>
        </div>
        <div className="input-group">
          <label htmlFor="timezone">{t('timezone.label')}</label>
          <select
            className="timezone-select"
            name="timezone"
            id="timezone"
            value={currentTimezone}
            onChange={e => {
              setCurrentTimezone(e.target.value)
              dispatch(changeLocale(undefined, e.target.value))
            }}
          >
            {timezoneNames.map(tzn => (
              <option value={tzn} key={tzn}>{tzn}</option>
            ))}
          </select>
        </div>
      </div>
      <RequestStatus
        request={changeLocaleRequest}
        successMessage={t('preferences.success')}
        spaceTopRem={2}
      />
    </Fragment>
  )
}
