import { WorkingHours, WorkingHoursAPIProvider } from '../WorkingHours'

import React from 'react'
import { Stack } from '@mui/material'
import { Unavailabilities } from '../Unavailabilities'
import { UnavailabilityDrawerContextProvider } from '../Unavailabilities/UnavailabilityDrawer'
import { WorkingHoursDrawerContextProvider } from '../WorkingHours/WorkingHoursDrawer'
import { useTranslation } from 'react-i18next'

/**
 * @component User Work Preferences displayed in Profile page only for creatives
 * @example
 * <WorkPreferences userData={userMeRequest.data} />
 */
export const WorkPreferences: React.FC = () => {
  const { t } = useTranslation(['profile'])

  return (
    <UnavailabilityDrawerContextProvider>
      <WorkingHoursAPIProvider>
        <WorkingHoursDrawerContextProvider>

          <h2 className="decorated">
            {t('work_preferences.title')}
          </h2>

          <Stack
            width="100%"
            justifyContent="space-between"
            spacing={{ xs: 4, sm: 6, md: 10 }}
            direction={{ xs: 'column', sm: 'row' }}
          >

            <WorkingHours />

            <Unavailabilities />

          </Stack>

        </WorkingHoursDrawerContextProvider>
      </WorkingHoursAPIProvider>
    </UnavailabilityDrawerContextProvider>
  )
}
