import { FC, Fragment, ReactNode, useMemo } from 'react'

import { CheckCard } from 'components/common/ExpandableCard'
import { MUICheckCard } from 'components/common/MUICards'
import { PreferredPaymentMethodEnum } from 'constants/user'
import { SavedStripePaymentMethod } from 'models/user'
import { getCardExpirationString } from 'utils/helpers'
import { getSavedPaymentMethodAddressString } from 'utils/payment'
import styles from './SavedPaymentMethodSelectCard.module.sass'

interface Props {
  paymentMethod: SavedStripePaymentMethod
  isSelected: boolean
  onSelected: (selected: boolean) => void
  children?: ReactNode
  isNewDesign?: boolean
}

/**
 * Displays check card with info about payment method.
 * Selected state is to be handled outside of component, component accepts state and callback for selection change.
 * Children will be positioned to the right and given row flex (suited for action buttons).
 * @component SavedPaymentMethodSelectCard
 * @example
 * <SavedPaymentMethodSelectCard
 *  paymentMethod={methodObject}
 *  isSelected={outsideStateValue}
 *  onSelected={(selected: boolean) => selectionHandlerFnc(selected)}
 * >
 *   <Button type="primary">Action</Button>
 * </SavedPaymentMethodSelectCard>
 */
export const SavedPaymentMethodSelectCard: FC<Props> = ({
  paymentMethod,
  isSelected,
  onSelected,
  children,
  isNewDesign = false
}) => {

  const expirationDateString = useMemo(() => {
    if (!paymentMethod.cardExpirationMonth || !paymentMethod.cardExpirationYear) return undefined
    return getCardExpirationString(paymentMethod.cardExpirationMonth, paymentMethod.cardExpirationYear)
  }, [paymentMethod.cardExpirationMonth, paymentMethod.cardExpirationYear])

  return (
    <Fragment>
      {isNewDesign
        ? (
          <MUICheckCard
            isRadio={true}
            isHeaderClickable={true}
            checked={isSelected}
            onCheck={() => onSelected(!isSelected)}
          >
            <div className={styles.wrapper}>
              <div className={styles.content}>
                {paymentMethod.type === PreferredPaymentMethodEnum.CARD_PAYMENT &&
                  <Fragment>
                    <span>**** **** **** {paymentMethod.last4}</span>
                    {!!expirationDateString &&
                      <span>
                        {expirationDateString}
                      </span>
                    }
                  </Fragment>
                }
                {paymentMethod.type === PreferredPaymentMethodEnum.SEPA_DIRECT_DEBIT &&
                  <Fragment>
                    <span>********{paymentMethod.last4}</span>
                    <span>
                      {getSavedPaymentMethodAddressString(paymentMethod)}
                    </span>
                  </Fragment>
                }
              </div>
              {!!children &&
                // prevent bubbling of clicks on actions to avoid selecting of method as a side effect of clicking on the action
                <div className={styles.actions} onClick={(e) => e.stopPropagation()}>
                  {children}
                </div>
              }
            </div>
          </MUICheckCard>
        )
        : (
          <CheckCard
            checkbox='circle'
            isRadio={true}
            isHeaderClickable={true}
            checked={isSelected}
            onCheck={onSelected}
          >
            <div className={styles.wrapper}>
              <div className={styles.content}>
                {paymentMethod.type === PreferredPaymentMethodEnum.CARD_PAYMENT &&
                  <Fragment>
                    <span>**** **** **** {paymentMethod.last4}</span>
                    {!!expirationDateString &&
                      <span>
                        {expirationDateString}
                      </span>
                    }
                  </Fragment>
                }
                {paymentMethod.type === PreferredPaymentMethodEnum.SEPA_DIRECT_DEBIT &&
                  <Fragment>
                    <span>********{paymentMethod.last4}</span>
                    <span>
                      {getSavedPaymentMethodAddressString(paymentMethod)}
                    </span>
                  </Fragment>
                }
              </div>
              {!!children &&
                // prevent bubbling of clicks on actions to avoid selecting of method as a side effect of clicking on the action
                <div className={styles.actions} onClick={(e) => e.stopPropagation()}>
                  {children}
                </div>
              }
            </div>
          </CheckCard>
        )
      }
    </Fragment>
  )
}
