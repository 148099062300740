import { FC, ReactNode, useMemo } from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'
import { enter, exit } from '../timelines'

import { useAppMeta } from 'components/higher-order/App/appMeta.context'
import { useAppStyleSwitch } from 'components/higher-order/AppStyleSwitch'
import { useAuth0 } from 'utils/auth'
import { useLocation } from 'react-router-dom'

export const PageTransition: FC<{ children?: ReactNode }> = ({ children }) => {
  const { pathname } = useLocation()
  const {
    isLoading,
    isUserVerified,
    isUserSet,
  } = useAppMeta()

  const { isAuthenticated } = useAuth0()
  const { routeHasNewDesign } = useAppStyleSwitch()

  const displayKey = useMemo(
    () => [pathname, isLoading, isAuthenticated, isUserVerified, isUserSet].join('-'),
    [isAuthenticated, isLoading, isUserSet, isUserVerified, pathname]
  )

  const canTransition = !routeHasNewDesign

  return (
    <TransitionGroup component={null}>
      <Transition
        key={displayKey}
        appear={true}
        onEnter={
          canTransition
            ? (node, appears) => enter(node, appears)
            : undefined
        }
        onExit={
          canTransition
            ? (node) => exit(node)
            : undefined
        }
        timeout={{ enter: 600, exit: 150 }}
      >
        {children}
      </Transition>
    </TransitionGroup>
  )
}
