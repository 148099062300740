import { getConfiguredForm, isValidCardExpiration } from 'utils/forms'

import { getDefaultErrorMessage } from 'utils/forms/getDefaultErrorMessage'

/** Describes saved card details fields value */
export interface SavedCardDetailsFieldsValue {
  cardExpiration: string
}

/** Create configured form for edit card fields */
export const [SavedCardDetailsFieldsProvider, useSavedCardDetailsFields] = getConfiguredForm<SavedCardDetailsFieldsValue>({
  'cardExpiration': {
    value: '',
    required: getDefaultErrorMessage('required'),
    validate: {
      isValidCardExpiration: value => isValidCardExpiration({ value })
    }
  }
})