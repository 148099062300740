import { ChangeEvent, FC, Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { Color, IconType } from 'constants/assets'

import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import { InputWrap } from '../InputWrap'
import { MUIButton } from '../MUIButton'
import ReactLoading from 'react-loading'
import classnames from 'classnames'
import styles from './ReceiptEmailInput.module.sass'
import { useTranslation } from 'react-i18next'
import { useUpdateReceiptEmail } from 'dataQueries'
import { useUserData } from 'components/contexts/UserDataContext'

interface Props {
  /** Should Stripe-like styling be used (default true)*/
  useStripeStyling?: boolean
  /** Is the field required? Will be invalid if no value (default false)*/
  isRequired?: boolean
  /** Whether user me is directly updated on value change (default true) */
  directlyUpdateUserMe?: boolean
  /** Class name to be added to main wrapper */
  className?: string
  /** Whether the new redesign should be displayed. */
  isNewDesign?: boolean
  /** Function to be run every time value changes */
  onValueChange?: (value: string) => void
  /** Function to be run every time validity changes */
  onValidityChange?: (isValid: boolean) => void
}

/**
 * @component Displays editable input prefilled with email from users profile. Email can be saved with included button.
 * @example
 *  <ReceiptEmailInput
 *    onValueChange={(value) => setValueInSomeExternalState(value)}
 *    onValidityChange={(isValid) => setValidityInSomeExternalState(validity)}
 *  />
 */
export const ReceiptEmailInput: FC<Props> = ({
  useStripeStyling = true,
  isRequired = false,
  directlyUpdateUserMe = true,
  isNewDesign = false,
  className,
  onValueChange,
  onValidityChange,
}) => {

  const { t } = useTranslation(['receipt_email_input'])

  const { clientData } = useUserData()

  const userEmail = useMemo(() => clientData?.billingEmail || '', [clientData])

  const updateEmailMutation = useUpdateReceiptEmail()

  const inputRef = useRef<HTMLInputElement>(null)

  const [isEmailInputTouched, setIsEmailInputTouched] = useState<boolean>(false)
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false)
  const [value, setValue] = useState<string>('')

  const errorMessage = useMemo(() => {
    if (!updateEmailMutation.isError && !isEmailValid && isEmailInputTouched) {
      return isRequired ? t('invalid_error_required') : t('invalid_error')
    }

    if (updateEmailMutation.isError) {
      return t('save_error')
    }
    return undefined
  }, [isEmailInputTouched, isEmailValid, isRequired, t, updateEmailMutation.isError])

  const handleValueChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    // purge state if request already fired to remove icon/error
    if (updateEmailMutation.isSuccess || updateEmailMutation.isError) updateEmailMutation.reset()

    setValue(e.target.value)
  }, [updateEmailMutation])

  // Prefill email with saved one
  useLayoutEffect(() => {
    if (!isEmailInputTouched && userEmail) {
      setValue(userEmail)
      setIsEmailInputTouched(true)
    }
  }, [isEmailInputTouched, userEmail])

  // Run onValueChange and update validity when value changes
  useEffect(() => {
    setIsEmailValid(inputRef.current?.validity.valid || false)

    if (onValueChange) onValueChange(value)
  }, [onValueChange, value])

  // Run onValidityChange when validity changes
  useEffect(() => {
    if (onValidityChange) onValidityChange(isEmailValid)
  }, [isEmailValid, onValidityChange])

  // Purge updateReceiptEmail state on page leave
  useEffect(() => {
    return () => {
      updateEmailMutation.reset()
    }
    // Only trigger once on load otherwise goes to infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classnames(styles.receiptEmail, className, { [styles.useStripe]: useStripeStyling })}>

      {isNewDesign &&
        <div className={styles.inputButtonWrapper}>

          <InputWrap
            label={t('label')}
            error={errorMessage}
            showError={!!errorMessage}
            className={styles.inputWrapper}
          >
            <input
              ref={inputRef}
              required={isRequired}
              type="email"
              name="receipt-input"
              id="receipt-input"
              placeholder={t('placeholder')}
              value={value}
              onBlur={(e) => {
                setIsEmailValid(e.target.validity.valid)
                setIsEmailInputTouched(true)
              }}
              disabled={updateEmailMutation.isPending}
              onChange={handleValueChange}
            />
          </InputWrap>

          {userEmail !== value &&
            <MUIButton
              type="secondaryBorder"
              disabled={!isEmailValid}
              className={styles.saveButton}
              isLoading={updateEmailMutation.isPending}
              onClick={() => updateEmailMutation.mutate({ email: value, callUpdateUserMe: directlyUpdateUserMe })}
            >
              {t('cta')}
            </MUIButton>
          }

        </div>
      }

      {!isNewDesign &&
        <Fragment>

          <label htmlFor="receipt-input">
            {t('label')}
          </label>

          <div className={styles.receiptEmailInputWrapper}>

            <input
              ref={inputRef}
              required={isRequired}
              type="email"
              name="receipt-input"
              id="receipt-input"
              placeholder={t('placeholder')}
              className={!isEmailValid && isEmailInputTouched ? styles.isInvalid : ''}
              value={value}
              onBlur={(e) => {
                setIsEmailValid(e.target.validity.valid)
                setIsEmailInputTouched(true)
              }}
              disabled={updateEmailMutation.isPending}
              onChange={handleValueChange}
            />

            {userEmail !== value && !updateEmailMutation.isPending &&
              (
                <Button
                  type="secondary"
                  disabled={!isEmailValid}
                  className={styles.saveButton}
                  onClick={() => updateEmailMutation.mutate({ email: value, callUpdateUserMe: directlyUpdateUserMe })}>
                  {t('cta')}
                </Button>
              )
            }

            {updateEmailMutation.isPending &&
              <ReactLoading type="spin" className={styles.loading} color={Color.GRAY_SECONDARY} />
            }

            {updateEmailMutation.isSuccess &&
              <Icon icon={IconType.CHECK} className={styles.successIcon} color={Color.SECONDARY_GREEN_DARK} />
            }

            {updateEmailMutation.isError &&
              <Icon icon={IconType.CROSS} className={styles.successIcon} color={Color.SECONDARY_RED_DARK} />
            }

          </div>

        </Fragment>
      }

      {!isNewDesign &&
        <Fragment>

          {!updateEmailMutation.isError && !isEmailValid && isEmailInputTouched &&
            <p className={styles.error}>
              {isRequired
                ? t('invalid_error_required')
                : t('invalid_error')
              }
            </p>
          }

          {updateEmailMutation.isError &&
            <p className={styles.error}>
              {t('save_error')}
            </p>
          }

        </Fragment>
      }

    </div>
  )
}
