import { AssignmentEvent, AutomationFail, Outreach, OutreachResult, SuggestionRequest } from 'constants/assignment/assignmentChangelog'

export function AssignmentEventIsSuggestionRequest(assignmentEvent: AssignmentEvent): assignmentEvent is SuggestionRequest {
  return (
    (assignmentEvent as SuggestionRequest).requester !== undefined &&
    (assignmentEvent as Outreach).creative === undefined &&
    (assignmentEvent as OutreachResult).result === undefined &&
    (assignmentEvent as AutomationFail).reason === undefined
  )
}

export function AssignmentEventIsOutreach(assignmentEvent: AssignmentEvent): assignmentEvent is Outreach {
  return (
    (assignmentEvent as Outreach).creative !== undefined &&
    (assignmentEvent as SuggestionRequest).requester === undefined &&
    (assignmentEvent as OutreachResult).result === undefined &&
    (assignmentEvent as AutomationFail).reason === undefined
  )
}

export function AssignmentEventIsOutreachResult(assignmentEvent: AssignmentEvent): assignmentEvent is OutreachResult {
  return (
    (assignmentEvent as OutreachResult).result !== undefined &&
    (assignmentEvent as SuggestionRequest).requester === undefined &&
    (assignmentEvent as Outreach).creative === undefined &&
    (assignmentEvent as AutomationFail).reason === undefined
  )
}

export function AssignmentEventIsAutomationFail(assignmentEvent: AssignmentEvent): assignmentEvent is AutomationFail {
  return (
    (assignmentEvent as AutomationFail).reason !== undefined &&
    (assignmentEvent as SuggestionRequest).requester === undefined &&
    (assignmentEvent as OutreachResult).result === undefined
  )
}
