import { ActiveSubscriptionStatuses, PlatformUserBase, PlatformUserClient, PlatformUserCreative, SubscriptionPlan } from 'models/user'
import { UserIsPlatformUserBase, UserIsPlatformUserClient, UserIsPlatformUserCreative } from 'utils/typeguards'

import { ActionTypeAPIData } from 'constants/redux'
import { RootStore } from 'models/redux'
import constate from 'constate'
import { useAuth0 } from 'utils/auth'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

function useUserSetupHook() {
  const userMeData = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.USER_ME].data)
  const organizationData = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.ORGANIZATION_ME].data || undefined)

  const { roles } = useAuth0()

  // Base data of user available for each role
  const baseUserData = useMemo<PlatformUserBase | undefined>(() => {
    if (!userMeData) return undefined

    return {
      id: userMeData.id,
      publicId: userMeData.publicId,
      name: userMeData.name,
      email: userMeData.email,
      phone: userMeData.phone,
      country: userMeData.country,
      defaultTimezone: userMeData.defaultTimezone,
      language: userMeData.language,
    }
  }, [userMeData])

  /** Client user data, if user is not client this will be undefined */
  const clientData = useMemo(() => {
    if (!userMeData) return undefined
    if (!roles.isClient) return undefined
    if (!UserIsPlatformUserClient(userMeData)) return undefined
    return userMeData as PlatformUserClient
  }, [roles.isClient, userMeData])

  /** Admin user data, if user is not admin, this will be undefined */
  const adminData = useMemo(() => {
    if (!userMeData) return undefined
    if (!roles.isAdmin) return undefined
    if (!UserIsPlatformUserBase(userMeData)) return undefined
    return userMeData as PlatformUserBase
  }, [roles.isAdmin, userMeData])

  /** Creative user data, if user is not creative, this will be undefined */
  const creativeData = useMemo(() => {
    if (!userMeData) return undefined
    if (!roles.isCreative) return undefined
    if (!UserIsPlatformUserCreative(userMeData)) return undefined
    return userMeData as PlatformUserCreative
  }, [roles.isCreative, userMeData])

  /** Array of all workspaces the user is a member of and are non-personal */
  const nonPersonalUserWorkspaces = useMemo(() => roles.isClient && !!clientData && clientData.workspaces && clientData.workspaces.filter(workspace => workspace.plan !== SubscriptionPlan.PERSONAL) || [], [roles, clientData])

  /** Array of all workspaces the user is a member of and are personal */
  const personalUserWorkspaces = useMemo(() => roles.isClient && !!clientData && clientData.workspaces && clientData.workspaces.filter(workspace => workspace.plan === SubscriptionPlan.PERSONAL) || [], [roles, clientData])

  /** Array of all workspaces the user is actively subscribed to */
  const activeSubscribedUserWorkspaces = useMemo(() => nonPersonalUserWorkspaces.filter(workspace => ActiveSubscriptionStatuses.has(workspace.status)) || [], [nonPersonalUserWorkspaces])

  /** Whether the user has any active subscription (is a member of any non-personal workspace) */
  const hasUserActiveSubscription = useMemo(() => activeSubscribedUserWorkspaces.length !== 0, [activeSubscribedUserWorkspaces])

  /** Whether user is allowed to use invoice by email or not */
  const userCanPayByInvoice = useMemo(() => {
    if (roles.isAdmin) return true
    return hasUserActiveSubscription || !!organizationData?.invoiceAllowed
  }, [hasUserActiveSubscription, organizationData?.invoiceAllowed, roles.isAdmin])

  /** Current user workspace, currently we use the first one from activeSubscribedUserWorkspaces and update it in local storage workspace id for keeping it consistence */
  const currentUserWorkspace = useMemo(() => {
    if (!activeSubscribedUserWorkspaces.length) return null

    const localWorkspaceId = localStorage.getItem('workspaceId')
    // find matched active workspace with local workspace id
    const currentActiveWorkspace = activeSubscribedUserWorkspaces.find(workspace => workspace.id === localWorkspaceId)
    if (!!currentActiveWorkspace) return currentActiveWorkspace
    // local workspace id is expired or no local workspace exist, update local storage and return first active workspace
    localStorage.setItem('workspaceId', activeSubscribedUserWorkspaces[0].id)
    return activeSubscribedUserWorkspaces[0]
  }, [activeSubscribedUserWorkspaces])

  return {
    clientData,
    adminData,
    creativeData,
    baseUserData,
    organizationData,
    nonPersonalUserWorkspaces,
    activeSubscribedUserWorkspaces,
    hasUserActiveSubscription,
    userCanPayByInvoice,
    currentUserWorkspace,
    personalUserWorkspaces,
  }
}

export const [UserDataContextProvider, useUserData] = constate(useUserSetupHook)
