import { BEIGE_500, BLUE_400, GRAY_200 } from 'constants/styling/theme'
import React, { FC, Fragment, ReactNode } from 'react'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { Box } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import { MUIDivider } from 'components/common/MUIDivider'
import Stack from '@mui/material/Stack'
import { TransitionGroup } from 'react-transition-group'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Content to be shown when expanded */
  expandableContent?: ReactNode
  /** Wheter card is expanded and content is visible */
  isExpanded: boolean
  /** Wheter the card is disabled */
  isDisabled?: boolean
  /** Wheter the card is highlighted */
  isHighlighted?: boolean
  /** Displays pointer cursor on hover over header part of card and allows for onHeaderClick event */
  isHeaderClickable?: boolean
  /** Event triggered specifically when clicked on header */
  onHeaderClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  /** Optional children rendered in the header of the card */
  children?: ReactNode
  /** Optional content, that will be rendered as a footer of a card, displayed even when card is collapsed (used mainly for upsell marketing on disabled cards) */
  alwaysOnFooterContent?: ReactNode
}

/**
 * @component
 * Displays provided content as a card with a header and an expandable container.
 * Header content is injected via children and content for expandable container as a prop.
 * Expanding/closing is handled manually by property "isExpanded"
 * 
 * @example
 * <ExpandableCard isExpanded={booleanValue} expandableContent={headerNode}>
 *   {headerNode}
 * </ExpandableCard>
 */
export const MUIExpandableCard: FC<Props> = ({
  expandableContent,
  isExpanded = false,
  isDisabled = false,
  isHighlighted = false,
  isHeaderClickable = false,
  children,
  alwaysOnFooterContent,
  onHeaderClick,
}) => {

  return (
    <BorderBoxWrapper
      padding={2}
      sx={{ opacity: isDisabled ? 0.5 : 1 }}
      borderColor={isHighlighted ? BLUE_400 : BEIGE_500}
    >

      <Stack
        sx={{
          cursor: isHeaderClickable ? 'pointer' : 'inherit',
          userSelect: isHeaderClickable ? 'none' : 'auto'
        }}
        onClick={onHeaderClick}
      >
        {children}
      </Stack>

      <TransitionGroup component={null}>
        {isExpanded && !!expandableContent &&

          <Collapse
            in={isExpanded}
            timeout={{ enter: 300, exit: 300 }}
          >
            <Fragment>
              <MUIDivider />

              {expandableContent}
            </Fragment>
          </Collapse>

        }
      </TransitionGroup>

      {!!alwaysOnFooterContent && (
        <Box marginTop={2} paddingTop={2} borderTop={`1px solid ${GRAY_200}`}>
          {alwaysOnFooterContent}
        </Box>
      )}

    </BorderBoxWrapper>
  )
}
