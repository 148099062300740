import { FC, Fragment, ReactNode } from 'react'
import { Power2, gsap } from 'gsap'
import { QueryStatus, QueryStatusStateVariant } from '../QueryStatus'
import { Transition, TransitionGroup } from 'react-transition-group'
import { UseMutationResult, UseQueryResult } from '@tanstack/react-query'

import { Color } from 'constants/assets'
import ReactLoading from 'react-loading'

export type QueryStatusPosition = 'above' | 'below'

/**
 * @interface Props DynamicQueryContent properties
 */
export interface Props {
  /** Query results */
  query: UseMutationResult<any, any, any, any> | UseQueryResult<any, any>,
  /** onPurge callback to be passed to QueryStatus for additional logic working */
  onPurge?: () => void,
  children?: ReactNode,
  /** Array of statuses we want to render, as default we show 'error' state as other states doesn't make sense to show for DynamicQueryContent */
  showStates?: QueryStatusStateVariant[],
  /** Wheter the QueryStatus component will be rendered above or bellow the children, as default we render on 'bellow' */
  statusPosition?: QueryStatusPosition
}

/**
 * @component Display query results as children wrapped with Transition and QueryStatus error rendering by default
 * @example
 * <DynamicQueryContent
 *  query={useQuery result}
 *  showStates={['error']}
 *  onPurge={useQuery.reset()}
 * >
 *  <span>Content for query results here</span>
 * </FetchedContent>
 */
const DynamicQueryContent: FC<Props> = ({
  children,
  query,
  onPurge,
  showStates = ['error'],
  statusPosition = 'below'
}) => {
  const timeline = gsap.timeline({ paused: true })

  const enterEffect = (node: HTMLElement) => {
    if (!node) return
    if (node.nodeType === node.TEXT_NODE) return
    timeline.totalProgress(1).clear(true).totalProgress(0)
    if (node.classList && (node.classList.contains('loader') || node.classList.contains('loading') || node.classList.contains('spinner'))) {
      timeline.fromTo(node, { autoAlpha: 0, ease: Power2.easeIn }, { autoAlpha: 1, ease: Power2.easeIn, duration: 0.15 })
    } else {
      timeline.fromTo(
        node,
        { autoAlpha: 0, height: 0, y: 25, position: 'absolute', ease: Power2.easeIn },
        { autoAlpha: 1, height: 'auto', y: 0, position: 'static', ease: Power2.easeIn, duration: 0.3 },
        0.15
      )
      if (node.childNodes && node.childNodes.length > 0) {
        let omittedNodes = 0
        node.childNodes.forEach((child, key) => {
          if (child.nodeType === child.TEXT_NODE) {
            omittedNodes++
            return
          }
          timeline.fromTo(child, { autoAlpha: 0, y: 25, ease: Power2.easeIn }, { autoAlpha: 1, y: 0, ease: Power2.easeIn, duration: 0.3 }, (0.45 + (key - omittedNodes) * 0.075))
        })
      }
    }
    timeline.play()
  }

  const exitEffect = (node: HTMLElement) => {
    if (!node) return
    if (node.nodeType === node.TEXT_NODE) return
    timeline.totalProgress(1).clear(true).totalProgress(0)
    timeline.to(node, { autoAlpha: 0, zIndex: 0, height: 0, ease: Power2.easeOut, duration: 0.15 })
    timeline.play()
  }

  return (
    <Fragment>
      {!!query && <TransitionGroup component={null}>
        <Transition
          appear={true}
          in={!!query}
          onEnter={(node: HTMLElement) => enterEffect(node)}
          onExit={(node: HTMLElement) => exitEffect(node)}
          timeout={{ enter: 600, exit: 150 }}
        >
          <Fragment>

            {query.isPending && <ReactLoading color={Color.GRAY_TEXT} type="cylon" className="spinner" />}

            {statusPosition === 'above' &&
              <QueryStatus
                query={query}
                onPurge={onPurge}
                showStates={showStates}
              />
            }

            {!!query.data && children}

            {statusPosition === 'below' &&
              <QueryStatus
                query={query}
                onPurge={onPurge}
                showStates={showStates}
              />
            }
          </Fragment>

        </Transition>
      </TransitionGroup>}
    </Fragment>
  )
}

export default DynamicQueryContent
