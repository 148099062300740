import { ButtonType, MUIButton } from '../MUIButton'
import { MUITooltip, TooltipPlacement } from '../MUITooltip'
import { useCallback, useMemo, useState } from 'react'

import { Box } from '@mui/material'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Typography from '@mui/material/Typography'
import { WHITE } from 'constants/styling/theme'
import { useTranslation } from 'react-i18next'

/** Type representing the style of the copy button. */
type CopyButtonType = 'primary' | 'secondary'
/** Type representing the variant of the copy container. */
type CopyButtonVariant = 'text' | 'button'

/** @interface Props for the CopyButton component. */
interface Props {
  /** Text to be copied to the clipboard. */
  copyText?: string
  /** Custom text to be displayed when the button or text is successfully clicked. */
  copySuccessText?: string
  /** Custom text content. */
  textContent?: string
  /** Type of copy button, primary is black button and secondary is white. Not applicable fot text variant. */
  type?: CopyButtonType
  /** Whether to display the tooltip or not. Default is true. */
  showTooltip?: boolean
  /** Placement of the tooltip. */
  tooltipPlacement?: TooltipPlacement
  /** Whether to display an icon at the start of the content. Default is false. */
  showIcon?: boolean
  /** Variant of the copy container, it can be text only or button, default is button. */
  variant?: CopyButtonVariant
  /** Whether the button is disabled, not applicable when type is text. */
  disabled?: boolean
  /** Handle action click triggered after the text is copied. */
  onCopy?: () => void
}

/** Icon component for displaying a copy or check icon based on the text copy state. */
const CopyIcon: React.FC<{ isTextCopied: boolean }> = ({ isTextCopied }) => {
  return (
    isTextCopied
      ? <CheckRoundedIcon />
      : <ContentCopyIcon />
  )
}

/**
 * @component
 * A button or text for copying text to the clipboard.
 * 
 * @example
 * <CopyButton
 *   copyText="Miau, miau!"
 *   showIcon={true}
 *   textContent="Click here to copy some text"
 *   tooltipPlacement='bottom'
 *   onCopy={() => console.log('Miau, miau!')}
 * />
 */
export const CopyButton: React.FC<Props> = ({
  type = 'primary',
  showTooltip = true,
  showIcon = false,
  variant = 'button',
  disabled,
  copyText,
  textContent,
  copySuccessText,
  tooltipPlacement,
  onCopy,
}) => {
  const { t } = useTranslation(['common'])

  const [isTextCopied, setTextCopied] = useState<boolean>(false)

  const buttonType: ButtonType = useMemo(() => {
    if (type === 'secondary') return 'secondaryBorder'
    return 'defaultPrimary'
  }, [type])

  const text = useMemo(() => {
    if (!showTooltip) {
      return isTextCopied ? copySuccessText ?? t('copied') : textContent ?? t('copy')
    }

    return textContent ?? t('copy')
  }, [showTooltip, textContent, t, isTextCopied, copySuccessText])

  const handleCopyText = useCallback(() => {
    if (isTextCopied) return

    setTextCopied(true)

    if (copyText) navigator.clipboard.writeText(copyText)

    setTimeout(() => {
      setTextCopied(false)
    }, 2000)

    onCopy?.()
  }, [isTextCopied, copyText, onCopy])

  return (
    <MUITooltip
      content={isTextCopied && showTooltip ? copySuccessText ?? t('copied') : ''}
      type={type === 'primary' ? 'secondary' : 'primary'} // invert the colors
      placement={tooltipPlacement}
      leaveDelay={300}
    >
      {variant === 'button'
        ? (
          <MUIButton
            type={buttonType}
            onClick={handleCopyText}
            disabled={disabled}
            startIcon={showIcon && <CopyIcon isTextCopied={isTextCopied} />}
            sx={type === 'secondary' ? { '&:hover': { backgroundColor: WHITE } } : {}}
          >
            {text}
          </MUIButton>
        )
        : (
          <Box
            sx={{
              gap: '1rem',
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
            }}
            onClick={handleCopyText}
          >

            <Typography variant="text-md" fontWeight="medium">
              {text}
            </Typography>

            {showIcon && <CopyIcon isTextCopied={isTextCopied} />}

          </Box>
        )
      }
    </MUITooltip>
  )
}
