import { EntityKeys, QueryType, getMutation } from 'utils/reactQuery'

import { VirtualVisitLinksDTO } from 'models/assignment'
import { useAPI } from 'utils/API'

enum Endpoints {
  SAVE_LINKS = '/assignment/{assignmentId}/virtualVisit/submit',
  VISUAL_UPDATE_VIRTUAL_VISIT = '/assignment/{assignmentId}/virtualVisit',
}

interface SaveVirtualVisitPayloadDTO {
  assignmentId: string
  // TODO: After BE merges the changes, remove the string[] type and replace it with VirtualVisitLinksDTO
  payload: VirtualVisitLinksDTO | string[]
}

interface UpdateVirtualVisitPayloadDTO {
  assignmentId: string
  matterportId: string
}

// MUTATIONS

/** Mutation for saving the virtual visit links. */
export function useSaveVirtualVisitLinks() {
  const api = useAPI<Endpoints>()

  return getMutation<{}, SaveVirtualVisitPayloadDTO>(
    ({ assignmentId, payload }) => api.post(
      Endpoints.SAVE_LINKS,
      { assignmentId: assignmentId.toString() },
      payload,
      true
    ),
    (client, { assignmentId }) => {
      client.invalidateQueries({ queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET_ONE, { assignmentId }] })
    }
  )
}

/** Mutation for updating the virtual visit link on Admin Gallery. */
export function useUpdateVirtualVisit() {
  const api = useAPI<Endpoints>()

  return getMutation<{}, UpdateVirtualVisitPayloadDTO>(
    ({ assignmentId, matterportId }) => api.put(
      Endpoints.VISUAL_UPDATE_VIRTUAL_VISIT,
      { assignmentId },
      { matterportId },
      true
    ),
    (client, { assignmentId }) => {
      client.invalidateQueries({ queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET_ONE, { assignmentId }] })
    }
  )
}
