import { ActionListVisuals, ListVisualsStore } from '.'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { APIRequestState } from 'constants/API'
import { cloneDeep } from 'lodash'

/** Redux data reducer for list all mission visuals */
export const reduceListVisuals = (state: ListVisualsStore, action: ActionListVisuals) => {
  const [eventType, dataType] = action.type
  if (dataType !== ActionTypeAPIData.LIST_VISUALS) return state
  const newState = cloneDeep(state)

  switch (eventType) {
    case ActionTypeAPIEvent.FETCH:
      newState[action.payload.missionId] = {
        ...newState[action.payload.missionId],
        [action.payload.type]: new APIRequest(APIRequestState.RUNNING),
      }
      return newState
    case ActionTypeAPIEvent.RECEIVED:
      newState[action.payload.missionId] = {
        ...newState[action.payload.missionId],
        [action.payload.type]: action.payload.request,
      }
      return newState
    default:
      return state
  }
}