import './TriangleIcon.sass'

import { ColorClass, IconType } from 'constants/assets'

import Icon from '../Icon/Icon'
import React from 'react'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** Background color, default to orange */
  type?: ColorClass
  /** Icon to be used inside the triangle */
  icon: IconType
  /** The additional classes to append */
  className?: string
  /** The title of the wrapping element */
  title?: string
  /** react-tooltip property for tooltip enabling */
  'data-tip'?: boolean
  /** react-tooltip property for tooltip assignment */
  'data-for'?: string
}

/**
 * @component Graphical component with an icon in a triangle
 * @example
 * <TriangleIcon type="orange" icon={IconType.PHONE} className="class" />
 */
const TriangleIcon: React.FC<Props> = ({
  type = ColorClass.PRIMARY_ORANGE,
  icon,
  className = '',
  title,
  'data-tip': dataTip,
  'data-for': dataFor,
}) => {
  return (
    <span className={`triangleicon ${className || ''}`} data-tip={dataTip} data-for={dataFor} title={title}>
      <Icon icon={IconType.TRIANGLE} className="triangle" colorClass={type} />
      <Icon icon={icon} className="inner-icon" colorClass={ColorClass.BLACK} />
    </span>
  )
}

export default TriangleIcon