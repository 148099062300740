import { FC, ReactNode } from 'react'

import Stack from '@mui/material/Stack'

interface Props {
  /** Actions components deside the header content */
  actions?: ReactNode
  /** Custom header content */
  children: ReactNode
}

/**
 * Page header component
 * @example 
 * <PageHeader actions={<PageActions />}><div>Title</div></PageHeader>
 */
export const PageHeader: FC<Props> = ({ actions, children }) =>
  <Stack direction={{ xs: 'column', lg: 'row' }} gap={{ xs: '2.4rem', lg: 0 }} justifyContent="space-between" margin="0 0 2.4rem 0" alignItems={{ xs: 'flex-start', lg: 'center' }}>
    {children}
    {!!actions && actions}
  </Stack>
