export enum Plans {
  PERSONAL = 'PERSONAL',
  PRO = 'PRO',
  ENTERPRISE = 'ENTERPRISE'
}

export enum PlanPeriod {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY'
}

export const PlansInfo: Record<Plans, string[]> = {
  PERSONAL: [
    'user', 'order', 'delivery', 'payment'
  ],
  PRO: [
    'user', 'team_access', 'management', 'editing', 'ai_generator', 'delivery', 'choose_creative', 'payment'
  ],
  ENTERPRISE: [
    'user', 'access', 'packs', 'user_access_settings', 'monthly_invoicing', 'management_solutions'
  ],
}

export const ProPlanPrice: Record<PlanPeriod, number> = {
  MONTHLY: 19,
  YEARLY: 190
}
