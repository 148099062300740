import './RatingEmojis.sass'

import { EmojiValue, RatingType } from 'constants/misc'
import { useCallback, useEffect, useState } from 'react'

import Emoji from '../Emoji/Emoji'
import { EmojiType } from 'constants/assets'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** The additional classes to append */
  className?: string
  /** Rating type */
  ratingType: RatingType
  /** Which emoji was clicked */
  activeEmoji?: EmojiValue
  /** Whether the emojis are disabled or not  */
  disabled?: boolean
  /** OnClick action to be called after emoji rating has been clicked */
  onClick?: (value: EmojiValue | undefined) => void
}

/**
 * @component Display rating with emojis
 * @example
 * <RatingEmojis ratingType={RatingType.VISUALS} activeEmoji={EmojiValue.HAPPY} onClick={action(('clicked an emoji'))} disabled={false} />
 */
const RatingEmojis: React.FC<Props> = ({
  className = '',
  ratingType,
  activeEmoji,
  disabled,
  onClick,
}) => {
  const { t } = useTranslation(['rating'])
  const [clickedEmoji, setClickedEmoji] = useState(activeEmoji || undefined)

  // If passed active emoji changes, change internal state as well
  useEffect(() => {
    if (typeof activeEmoji === 'number') setClickedEmoji(activeEmoji)
  }, [activeEmoji])

  const handleClick = useCallback((emoji: EmojiValue) => {
    const emojiToBe = emoji === activeEmoji ? undefined : emoji
    onClick && onClick(emojiToBe)
    setClickedEmoji(emojiToBe)
  }, [onClick, activeEmoji])

  return (
    <div className={`rating-emojis ${className}`}>
      <div className="rating-emojis-wrapper">
        <span className="rating-title">{t(`rating:title:${ratingType}:emojis`)}</span>
        <span className="rating-hint">{t(`rating:title:${ratingType}:text`)}</span>
        <div className="emojis-wrapper">
          <div className="emoji-wrapper">
            <button
              className={`emoji-button ${(activeEmoji || clickedEmoji) === EmojiValue.UNHAPPY ? 'active' : ''}`}
              onClick={() => handleClick(EmojiValue.UNHAPPY)}
              disabled={disabled}
            >
              <Emoji emoji={EmojiType.UNHAPPY} />
            </button>
          </div>

          <div className="emoji-wrapper">
            <button
              className={`emoji-button ${(activeEmoji || clickedEmoji) === EmojiValue.NEUTRAL ? 'active' : ''}`}
              onClick={() => handleClick(EmojiValue.NEUTRAL)}
              disabled={disabled}
            >
              <Emoji emoji={EmojiType.NEUTRAL} />
            </button>
          </div>

          <div className="emoji-wrapper">
            <button
              className={`emoji-button ${(activeEmoji || clickedEmoji) === EmojiValue.HAPPY ? 'active' : ''}`}
              onClick={() => handleClick(EmojiValue.HAPPY)}
              disabled={disabled}
            >
              <Emoji emoji={EmojiType.HAPPY} />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RatingEmojis
