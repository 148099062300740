import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'

import { APIRequest } from 'models/API'
import { ActionRequest } from 'models/redux'
import { ListVisualsStoreItem } from '.'
import { VisualFileType } from 'constants/visual'
import { createAPIAction } from 'redux/Helpers'

/** Describes general action to list all mission visuals */
export interface ActionListVisuals extends ActionRequest {
  payload: {
    missionId: string
    type: VisualFileType
    request: ListVisualsStoreItem
  },
}

/** Creates an action to list all visuals available for a mission */
export function listVisuals(missionId: string, type: VisualFileType): ActionListVisuals {
  return createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.LIST_VISUALS], {
    missionId,
    type,
    request: new APIRequest()
  })
}
