import { Box, Stack } from '@mui/material'
import { FC, ReactNode } from 'react'

import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import styles from './CircularIconLoading.module.sass'

/** Props for CircularIconLoading */
interface Props {
  /** Icon to display in the loader - default is ImageOutlinedIcon */
  icon?: ReactNode
}

/**
 * @component
 * CircularIconLoading component displays a circular loading indicator with an icon in the center.
 *
 * @example
 * <CircularIconLoading />
 *
 */
export const CircularIconLoading: FC<Props> = ({
  icon = <ImageOutlinedIcon
    sx={{ fontSize: 24 }}
    className={styles.icon}
  />
}) => {

  return (
    <Box
      className={styles.mainCircle}
      sx={{
        width: '5rem',
        height: '5rem',
        borderRadius: '50%',
        position: 'relative'
      }}
    >
      <Stack height="100%" alignItems="center" justifyContent="center">

        <div className={styles.circle}></div>
        <div className={styles.circle}></div>

        <div className={styles.iconWrapper}>
          {icon}
        </div>

      </Stack>

    </Box>
  )
}
