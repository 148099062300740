import { FC, Fragment, useMemo } from 'react'

import { AvailableAssignmentController } from './AvailableAssignment.controller'
import NotFound from 'components/pages/General/NotFound/NotFound'
import { PageTransition } from 'utils/animations'
import { useParams } from 'react-router-dom'

/**
 * Page for displaying card with specified assignment to be accepted/declined.   
 * Liked from email.   
 * @example <AvailableAssignmentPage />
 */
export const AvailableAssignmentPage: FC = () => {
  const { id } = useParams<{ id?: string }>()
  const assignmentId = useMemo(() => id || undefined, [id])

  return (
    <Fragment>
      {typeof assignmentId !== 'undefined' ?
        <PageTransition>
          <AvailableAssignmentController assignmentId={assignmentId} />
        </PageTransition>
        :
        <NotFound />
      }
    </Fragment>
  )
}
