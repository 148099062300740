import { FC, ReactNode } from 'react'

import styles from './GeneralTab.module.sass'

/**
 * @interface Props Input properties
 */
interface Props {
  /** The additional classes to append */
  className?: string
  /** Whether the general tab is active or not */
  active?: boolean
  /** Whether the general tab is unavailable */
  unavailable?: boolean
  /** Onclick action to be called */
  onClick?: (e: React.MouseEvent) => unknown
  children?: ReactNode
}

/**
 * @component General tab component
 * @example <GeneralTab active={true} onClick={onClickAction}</GeneralTab>
 */
export const GeneralTab: FC<Props> = ({
  className = '',
  active,
  unavailable = false,
  onClick,
  children,
}) => {
  if (active) className += ` ${styles.active}`
  if (unavailable) className += ` ${styles.unavailable}`

  return (
    <div
      className={`${styles.generalTab} ${className}`.replace(/\s+/igm, ' ').trim()}
      onClick={e => onClick && onClick(e)}
    >
      {children}
    </div>
  )
}
