import { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from 'react'

import { Nullable } from 'models/helpers'
import { STRIPE_PRIMARY_PRODUCT_KINDS } from 'constants/payments'
import { SavedStripePaymentMethod } from 'models/user'
import { StripeError } from '@stripe/stripe-js'
import { productKindIsPreferredPaymentMethod } from 'utils/typeguards'
import { usePurchaseFlowOrderMeta } from 'components/pages/PurchaseFlow/_main/contexts'
import { useSavedStripePaymentMethods } from 'components/contexts/SavedStripePaymentMethodsContext'

interface PurchaseFlowPaymentStatusContextInterface {
  stripeError: StripeError | null,
  isStripeProcessing: boolean,
  isStripeConfirmed: boolean,
  stripeInputElementsAreValid: boolean,
  receiptEmail: string,
  isReceiptEmailValid: boolean
  saveStripePaymentMethod: boolean,
  selectedSavedPaymentMethod: Nullable<SavedStripePaymentMethod>
  setStripeError: Dispatch<SetStateAction<StripeError | null>>
  setIsStripeProcessing: Dispatch<SetStateAction<boolean>>,
  setIsStripeConfirmed: Dispatch<SetStateAction<boolean>>,
  setStripeInputElementsAreValid: Dispatch<SetStateAction<boolean>>,
  setReceiptEmail: Dispatch<SetStateAction<string>>
  setIsReceiptEmailValid: Dispatch<SetStateAction<boolean>>
  setSaveStripePaymentMethod: Dispatch<SetStateAction<boolean>>
  setSelectedSavedPaymentMethod: Dispatch<SetStateAction<Nullable<SavedStripePaymentMethod>>>
}

const defaultPurchaseFlowPaymentStatusContextValue: PurchaseFlowPaymentStatusContextInterface = {
  stripeError: null,
  isStripeProcessing: false,
  isStripeConfirmed: false,
  stripeInputElementsAreValid: false,
  receiptEmail: '',
  isReceiptEmailValid: false,
  saveStripePaymentMethod: false,
  selectedSavedPaymentMethod: null,
  setStripeError: () => { },
  setIsStripeProcessing: () => { },
  setIsStripeConfirmed: () => { },
  setStripeInputElementsAreValid: () => { },
  setReceiptEmail: () => { },
  setIsReceiptEmailValid: () => { },
  setSaveStripePaymentMethod: () => { },
  setSelectedSavedPaymentMethod: () => { },
}

/** Shared payment status context */
export const PurchaseFlowPaymentStatusContext = createContext<PurchaseFlowPaymentStatusContextInterface>(defaultPurchaseFlowPaymentStatusContextValue)
/** Shared payment status context hook */
export const usePurchaseFlowPaymentStatus = (): PurchaseFlowPaymentStatusContextInterface => useContext(PurchaseFlowPaymentStatusContext)

/** Shared payment status context provider */
export const PurchaseFlowPaymentStatusContextProvider: FC<{
  children?: ReactNode
}> = ({
  children
}) => {

    const { selectedPaymentMethod } = usePurchaseFlowOrderMeta()

    const { savedStripePaymentMethodsMap, stripePaymentMethods } = useSavedStripePaymentMethods()

    // saving of payment methods
    const [saveStripePaymentMethod, setSaveStripePaymentMethod] = useState<boolean>(defaultPurchaseFlowPaymentStatusContextValue.saveStripePaymentMethod)
    const [selectedSavedPaymentMethod, setSelectedSavedPaymentMethod] = useState<Nullable<SavedStripePaymentMethod>>(defaultPurchaseFlowPaymentStatusContextValue.selectedSavedPaymentMethod)

    const [stripeError, setStripeError] = useState<StripeError | null>(defaultPurchaseFlowPaymentStatusContextValue.stripeError)
    const [isStripeProcessing, setIsStripeProcessing] = useState(defaultPurchaseFlowPaymentStatusContextValue.isStripeProcessing)
    const [isStripeConfirmed, setIsStripeConfirmed] = useState(defaultPurchaseFlowPaymentStatusContextValue.isStripeConfirmed)
    const [stripeInputElementsAreValid, setStripeInputElementsAreValid] = useState(defaultPurchaseFlowPaymentStatusContextValue.isStripeProcessing)

    const [receiptEmail, setReceiptEmail] = useState<string>(defaultPurchaseFlowPaymentStatusContextValue.receiptEmail)
    const [isReceiptEmailValid, setIsReceiptEmailValid] = useState<boolean>(defaultPurchaseFlowPaymentStatusContextValue.isReceiptEmailValid)

    // If no saved methods for selected payment, push null to selectedSavedMethod
    useEffect(() => {
      if (!stripePaymentMethods?.isSuccess) return
      if (!selectedPaymentMethod || !productKindIsPreferredPaymentMethod(selectedPaymentMethod)) return
      if (!STRIPE_PRIMARY_PRODUCT_KINDS.has(selectedPaymentMethod)) return

      if (!selectedPaymentMethod || selectedPaymentMethod.length === 0) setSelectedSavedPaymentMethod(null)

    }, [savedStripePaymentMethodsMap, stripePaymentMethods, selectedPaymentMethod])

    return (
      <PurchaseFlowPaymentStatusContext.Provider value={{
        stripeError,
        isStripeProcessing,
        isStripeConfirmed,
        stripeInputElementsAreValid,
        receiptEmail,
        isReceiptEmailValid,
        saveStripePaymentMethod,
        selectedSavedPaymentMethod,
        setStripeError,
        setIsStripeProcessing,
        setIsStripeConfirmed,
        setStripeInputElementsAreValid,
        setReceiptEmail,
        setIsReceiptEmailValid,
        setSaveStripePaymentMethod,
        setSelectedSavedPaymentMethod,
      }}>
        {children}
      </PurchaseFlowPaymentStatusContext.Provider>
    )
  }
