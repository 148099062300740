import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { FC, useEffect } from 'react'
import { usePurchaseFlowConfig, usePurchaseFlowProducts, useTargetOrderUser } from '../../_main/contexts'

import { FileAPIUpload } from 'components/common/FileAPIUpload'
import { FileType } from 'constants/misc'
import { PowerOfAttorneyUpload } from 'components/common/PowerOfAttorneyUpload'
import { Stack } from '@mui/material'
import { StepWrapper } from '../../common/StepWrapper'
import { usePowerOfAttorneyStep } from './PowerOfAttorneyStep.context'
import { useTranslation } from 'react-i18next'

export const PowerOfAttorneyStepController: FC = () => {
  const { t } = useTranslation(['power_of_attorney', 'purchase_flow'])

  const { targetUser } = useTargetOrderUser()
  const { powerOfAttorneyUpload, powerOfAttorneyRepsUpload } = usePowerOfAttorneyStep()
  const { selectedCategory } = usePurchaseFlowConfig()
  const {
    selectedProducts,
    selectedKinds,
    selectedProductTypes
  } = usePurchaseFlowProducts()

  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvent.ENTER_ORDER_POA_PAGE_SCREEN, {
      productTypes: Array.from(selectedProductTypes),
      productKinds: Array.from(selectedKinds),
      products: Object.values(selectedProducts),
      category: selectedCategory,
    })

    // Only trigger when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StepWrapper
      title={t('purchase_flow:power_of_attorney_step.section_title')}
      subtitle={t('purchase_flow:power_of_attorney_step.section_description')}
    >
      <Stack direction="column" gap={2} alignItems="stretch" justifyContent="flex-start">

        <PowerOfAttorneyUpload
          variant="owners"
          boxStyle="elevated"
          uploadSlot={
            <FileAPIUpload
              title={t('power_of_attorney')}
              files={powerOfAttorneyUpload?.allFilesArray}
              maxNumberOfFiles={1}
              isDraggingFile={false}
              acceptedFileTypes={[FileType.PDF]}
              uploadHandler={(acceptedFiles) => powerOfAttorneyUpload.uploadFiles(acceptedFiles)}
              onDelete={(fileId) => powerOfAttorneyUpload.deleteFiles([fileId])}
            />
          }
        />

        {targetUser.requiresRepresentativePowerOfAttorney &&
          <PowerOfAttorneyUpload
            variant="representatives"
            boxStyle="elevated"
            uploadSlot={
              <FileAPIUpload
                title={t('power_of_attorney_reps')}
                files={powerOfAttorneyRepsUpload?.allFilesArray}
                maxNumberOfFiles={1}
                isDraggingFile={false}
                acceptedFileTypes={[FileType.PDF]}
                uploadHandler={(acceptedFiles) => powerOfAttorneyRepsUpload.uploadFiles(acceptedFiles)}
                onDelete={(fileId) => powerOfAttorneyRepsUpload.deleteFiles([fileId])}
              />
            }
          />
        }

      </Stack>
    </StepWrapper>
  )
}
