/** Pairing state of creative with the assignment */
export enum PairingState {
  /** Creative was suggested by Platform, not selected yet */
  SUGGESTED = 'SUGGESTED',
  /** Creative was selected for the assignment, pending creative's approval */
  SELECTED = 'SELECTED',
  /** Creative accepted the assignment */
  ACCEPTED = 'ACCEPTED',
  /** Creative rejected the assignment */
  REJECTED_BY_CREATIVE = 'REJECTED_BY_CREATIVE',
}
