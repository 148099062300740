import { GRAY_100, GRAY_300 } from 'constants/styling/theme'

import Box from '@mui/material/Box'
import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded'
import { useTranslation } from 'react-i18next'

/** @interface Props for the DraggableAreaView component. */
interface Props {
  /** The name of the thread to which the file is being uploaded. */
  threadName: string
}

/**
 * @component
 * DraggableAreaView renders a draggable area with a blurred background for file upload.
 * 
 * @example
 * <DraggableAreaView fileName="document.pdf" />
 */
export const DraggableAreaView: React.FC<Props> = ({ threadName }) => {
  const { t } = useTranslation(['threads'])

  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        zIndex: 1,
        width: '100%',
        height: '100%',
        position: 'absolute',
        borderRadius: '0.8rem',
        backdropFilter: 'blur(5px)',
        backgroundColor: 'rgba(19, 24, 29, 0.9)',
      }}
    >

      <Stack gap={1} alignItems="center" justifyContent="center" height="100%">

        <UploadFileRoundedIcon sx={{ color: GRAY_300, fontSize: '4.8rem' }} />

        <Typography variant='text-lg' fontWeight={600} color={GRAY_100}>
          {`${t('upload_file_to')} “${threadName}”`}
        </Typography>

      </Stack>

    </Box>
  )
}
