import { BEIGE_400, BEIGE_600, GRAY_700 } from 'constants/styling/theme'
import { FC, ReactNode, useMemo } from 'react'
import { useMarketingMaterials, useVisualsMarketing } from '../../_main/contexts'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { CircleIcon } from 'components/common/CircleIcon'
import { CircularProgress } from '@mui/material'
import { RoundButton } from '../../RoundButton'
import Stack from '@mui/material/Stack'
import { VisualsMarketingTargetPlatform } from '../../_main/VisulalsMarketing.constants'
import { useTranslation } from 'react-i18next'

/**
 * Props for the SocialMediaCreate component.
 * @interface Props
 */
interface Props {
  /** The type of the social media platform. */
  platform: VisualsMarketingTargetPlatform
  /** Icon representing the social media */
  icon: ReactNode
  /** Whether content is being generated in the moment */
  isLoading: boolean
  /** Function to be called when creating a new post. */
  onCreate: () => void
}

/**
 * @component
 * CreateSocialMediaPost component that represents a section for creating a new post for a social media platform.
 * It displays an icon inside a circular container and a button to create a new post.
 * @example
 * <CreateSocialMediaPost
 *   platform={VisualsMarketingTargetPlatform.INSTAGRAM}
 *   icon={<Instagram />}
 *   loading={true}
 *   onCreate={() => handleCreatePost()}
 * />
 */
export const CreateSocialMediaPost: FC<Props> = ({
  icon,
  platform,
  isLoading,
  onCreate,
}) => {

  const { t } = useTranslation(['visual_marketing_social_media'])
  const {
    marketingMaterials,
    savePropertyDetails,
  } = useMarketingMaterials()

  const { isPropertyDetailsEditing } = useVisualsMarketing()

  const isGenerated = useMemo(() => !!marketingMaterials[VisualsMarketingTargetPlatform.REAL_ESTATE_BROCHURE], [marketingMaterials])
  const isSaveDetailsRunning = useMemo(() => savePropertyDetails.isPending, [savePropertyDetails.isPending])

  const isDisabled = useMemo(() => {
    if (isSaveDetailsRunning) return true
    if (isGenerated && isPropertyDetailsEditing) return true
    return false
  }, [isGenerated, isPropertyDetailsEditing, isSaveDetailsRunning])

  return (
    <BorderBoxWrapper
      width="100%"
      height={200}
      padding="4rem"
      position="relative"
      backgroundColor={BEIGE_600}
      sx={{
        opacity: isDisabled ? 0.3 : 1,
        pointerEvents: isDisabled ? 'none' : 'all',
      }}
    >
      <Stack
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >

        <CircleIcon
          circleColor={BEIGE_400}
          icon={icon}
        />

        {!isLoading &&
          <RoundButton color='primary' onClick={() => onCreate()}>
            {t(`create_post.${platform}`)}
          </RoundButton>
        }

      </Stack>

      {isLoading &&
        <CircularProgress size={72} thickness={2} color="inherit" sx={{
          position: 'absolute',
          top: 'calc(50% - 36px)',
          left: 'calc(50% - 36px)',
          '& svg': {
            color: GRAY_700,
          }
        }} />
      }

    </BorderBoxWrapper>
  )
}
