import { GetAccessLink } from 'constants/staticLinks'

export const getAccessLink = (language: string) => {
  switch (language.toLowerCase()) {
    case 'fr':
      return GetAccessLink.FR
    case 'de':
      return GetAccessLink.DE
    case 'es':
      return GetAccessLink.ES
    case 'nl':
      return GetAccessLink.NL
    case 'en':
    default:
      return GetAccessLink.EN
  }
}
