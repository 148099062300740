import { PurchaseFlowStepKey, usePurchaseFlowUtilities } from '../../../_main/contexts'

import { AddressStepController } from './AddressStep.controller'
import { DynamicFlowStep } from 'components/common/DynamicFlow'
import { FC } from 'react'

export const AddressStep: FC = () => {

  const { isAddressStepValid } = usePurchaseFlowUtilities()

  return (
    <DynamicFlowStep
      stepKey={PurchaseFlowStepKey.ADDRESS}
      isValid={isAddressStepValid}
    >
      <AddressStepController />
    </DynamicFlowStep>
  )
}
