import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, takeEvery } from 'typed-redux-saga'

import API from 'utils/API/API'
import { ActionListVisuals } from '.'
import { Endpoint } from 'constants/API'
import { put } from 'redux-saga/effects'

/** Saga which fetches list of all visuals available for a mission */
export function* listVisualsSaga(receivedAction: ActionListVisuals) {
  const url = Endpoint.VISUAL_LIST_ALL
    .replace('{assignmentId}', encodeURI(receivedAction.payload.missionId.toString()))
    .replace('{type}', encodeURI(receivedAction.payload.type.toString()))

  const action: ActionListVisuals = yield generalFetch(ActionTypeAPIData.LIST_VISUALS, () => API.get(url, undefined, {
    endpoint: Endpoint.VISUAL_LIST_ALL,
  }))
  const listVisualsAction: ActionListVisuals = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }

  yield put(listVisualsAction)
}

/** Watcher of visual list actions */
export function* listVisualsWatcher() {
  yield all([
    takeEvery((action: ActionListVisuals) => actionTypeTupleTest(action, [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.LIST_VISUALS]), listVisualsSaga),
  ])
}
