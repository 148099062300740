/** Leads to home page (dashboard) */
export const virtualFurnitureHomeLink = 'https://www.virtualfurniture.io/?c=rQAwMGWj5X'
/** Leads to list of furniture sets for residential staging */
export const residentialVirtualFurnitureSetListLink = 'https://www.virtualfurniture.io/room?c=rQAwMGWj5X&sort=newest&property_type=4'
/** Leads to list of furniture sets for commercial staging */
export const commercialVirtualFurnitureSetListLink = 'https://www.virtualfurniture.io/room?c=rQAwMGWj5X&sort=newest&property_type=2'
/** Slug of URL for linking orders from salesforce */
export const salesforceOrderSlug = 'lightning/r/WorkOrder/{salesforceId}/view'
/** Slug of URL for linking assignments from salesforce */
export const salesforceAssignmentSlug = 'lightning/r/WorkOrderLineItem/{salesforceId}/view'
