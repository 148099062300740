import { Stack } from '@mui/material'

import { GRAY_900 } from 'constants/styling/theme'
import { Label } from 'components/common/Label'
import { MUIRadio } from 'components/common/MUIRadio'
import { NightServiceType } from 'constants/purchaseFlow'
import React from 'react'
import { VentilationHeatingProps } from '../_main'
import { useTranslation } from 'react-i18next'
import { useVentilationAndHeating } from '../_main/VentilationAndHeating.context'

/**
 * @component NightService 
 * @description Renders options for the user to provide information about the night service.
 * 
 * @example
 * <NightService id="heating-system-1" />
 */
export const NightService: React.FC<VentilationHeatingProps> = ({ id }) => {
  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.energy_heating_page.heating_system' })
  const { currentHeatingSystem, handleUpdateHeatingSystemItem } = useVentilationAndHeating()
  const { nightService } = currentHeatingSystem(id) || {}

  return (
    <Stack gap=".8rem">
      <Label text={t('night_service')} />

      {Object.values(NightServiceType).map(type => (
        <Stack key={type} gap=".8rem" flexDirection="row">
          <MUIRadio
            checked={nightService === type}
            onChange={() => handleUpdateHeatingSystemItem(id, { nightService: type })}
          />

          <Label color={GRAY_900} text={t(`night_service_type.${type}`)} />
        </Stack>
      ))}
    </Stack>
  )
}
