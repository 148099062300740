import { useMemo } from 'react'

/**
 * useImageRegex
 * @param id - will be used as part of regex search
 * @returns BKBN Image RegExp with pattern, flag
 */

export function useImageRegex(
  id: string | number
): RegExp {
  return useMemo(() => new RegExp(`${id}-(backbonephoto|backbone|bkbn)-`, 'gmi'), [id])
}
