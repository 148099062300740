import { BEIGE_500, BEIGE_600, GRAY_1000, GRAY_900, WHITE } from 'constants/styling/theme'
import { Button, ButtonProps, styled } from '@mui/material'

import { FC } from 'react'

/**
 * Styled component that extends the Button component from MUI with custom styles.
 * The `color` prop is used to determine the button color.
 * 
 * @example
 * // Displays a button with beige color.
 * <StyledOutlinedButton color="inherit">Button</StyledOutlinedButton>
 * 
 * // Displays a button with black color.
 * <StyledOutlinedButton color="primary">Button</StyledOutlinedButton>
 */
const StyledOutlinedButton = styled(Button)<ButtonProps>(() => ({
  color: GRAY_900,
  fontSize: '1.2rem',
  borderRadius: '40rem',
  textTransform: 'none',

  '&.MuiButton-outlined': {
    borderColor: BEIGE_600,
  },

  '&.MuiButton-outlined:disabled': {
    borderColor: BEIGE_600,
  },

  '&.MuiButton-outlined:hover, &.Mui-focusVisible': {
    backgroundColor: BEIGE_500,
  },

  '&.MuiButton-outlinedPrimary': {
    color: WHITE,
    borderColor: GRAY_1000,
    backgroundColor: GRAY_1000,
  },

  '&.MuiButton-outlinedPrimary:hover': {
    backgroundColor: GRAY_900,
  },

}))

/**
 * @component
 * RoundButton component is a customized button from MUI with rounded shape.
 *
 * @example
 * <RoundButton color="primary" onClick={handleClick}>
 *   Submit
 * </RoundButton>
 */
export const RoundButton: FC<ButtonProps> = ({ ...props }) => {
  return (
    <StyledOutlinedButton
      {...props}
      variant='outlined'
      disabled={props.disabled}
    />
  )
}
