import { AssignmentAutomationStatus, AssignmentStage } from 'constants/assignment'
import React, { useMemo } from 'react'

import { Badge } from '..'
import { IconType } from 'constants/assets'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Automation status for the assignment */
  automationStatus: AssignmentAutomationStatus
  /** Assignment stage */
  assignmentStage: AssignmentStage
  /** Whether the badge with or without the arrow shape is used. Default is "false" (i.e. BadgeWithIcon) */
  withArrow?: boolean
  /** The additional classes to append */
  className?: string
}

/**
 * @component Badge which represents the automation status of the assignment
 * @example
 * <AutomationStatusBadge automationStatus={AssignmentAutomationStatus.MANUAL} />
 */
export const AutomationStatusBadge: React.FC<Props> = ({
  automationStatus,
  assignmentStage,
  withArrow = false,
  className = '',
}) => {
  const { t } = useTranslation(['automation_status'])

  const color = useMemo(() => {
    switch (automationStatus) {
      case AssignmentAutomationStatus.AUTOMATED:
      case AssignmentAutomationStatus.AUTOMATION_AS_PART_OF_ORDER:
        return 'gray'
      case AssignmentAutomationStatus.MANUAL:
        if (assignmentStage === AssignmentStage.MISSION_ORDER_PLACED || assignmentStage === AssignmentStage.PRE_PRODUCTION) return 'orange'
        return 'gray'
      case AssignmentAutomationStatus.AUTOMATION_READY:
        return 'orange'
      case AssignmentAutomationStatus.AUTOMATION_OUT_OF_SCOPE:
        return 'orange'
    }
  }, [automationStatus, assignmentStage])

  const icon = useMemo(() => {
    switch (automationStatus) {
      case AssignmentAutomationStatus.AUTOMATED:
      case AssignmentAutomationStatus.AUTOMATION_AS_PART_OF_ORDER:
        return IconType.COMPUTER
      case AssignmentAutomationStatus.MANUAL:
        return IconType.PROFILE
      case AssignmentAutomationStatus.AUTOMATION_READY:
        return IconType.COMPUTER
      case AssignmentAutomationStatus.AUTOMATION_OUT_OF_SCOPE:
        return IconType.PROFILE
    }
  }, [automationStatus])

  return (
    <Badge
      className={className}
      icon={icon}
      color={color}
      type="fill"
      displayArrow={withArrow ? true : false}
    >
      {t(automationStatus)}
    </Badge>
  )
}
