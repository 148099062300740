import logos_de from 'assets/img/clientReferenceLogos/logos_de.svg'
import logos_en from 'assets/img/clientReferenceLogos/logos_en.svg'
import logos_es from 'assets/img/clientReferenceLogos/logos_es.svg'
import logos_fr from 'assets/img/clientReferenceLogos/logos_fr.svg'
import logos_nl from 'assets/img/clientReferenceLogos/logos_nl.svg'

export const getClientReferenceLogos = (language: string) => {
  switch (language.toLowerCase()) {
    case 'fr':
      return logos_fr
    case 'de':
      return logos_de
    case 'es':
      return logos_es
    case 'nl':
      return logos_nl
    case 'en':
    case 'it':
    default:
      return logos_en
  }
}
