import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { ActionUpdateUserMe, UpdateUserMeStore } from '.'

/** Redux data reducer for update user me */
export const reduceUpdateUserMe = (state: UpdateUserMeStore, action: ActionUpdateUserMe) => {
  const [eventType, dataType] = action.type
  const { request } = action.payload
  if (dataType !== ActionTypeAPIData.UPDATE_USER_ME) return state

  switch (eventType) {
    case ActionTypeAPIEvent.BEFORE_START:
    case ActionTypeAPIEvent.FETCH:
    case ActionTypeAPIEvent.RECEIVED:
      return request
    default:
      return state
  }
}