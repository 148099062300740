import { SignedURLDTO, UrlDTO } from 'models/visuals'
import axios, { AxiosProgressEvent } from 'axios'

import { FileAction } from './fileAPI.types'
import { Nullable } from 'models/helpers'
import { loadVisualWorkerType } from 'workers/loadFileWorker'
import { wrap } from 'comlink'

export const FileAvailableActions = new Set([
  FileAction.UPLOAD,
  FileAction.RE_UPLOAD,
  FileAction.LOAD,
])

export const FileUnavailableActions = new Set([
  FileAction.DELETE,
  FileAction.INIT,
])

interface ContentRange {
  start: number
  end: number
  totalSize: number
}

interface UploadChunkParams {
  uri: SignedURLDTO
  chunk: Blob
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void
  abortSignal: AbortSignal
  contentRange: Nullable<ContentRange>
}

// -- PREP FOR RESUMABLE IMPLEMENTATION --
// const contentRangeRegex = /bytes (\d+)-(\d+)\/(\d+)/

// const removeFileExtensionRegex = /\..+/

// function parseContentRange(contentRange: string): ContentRange {
//   const match = contentRange.match(
//     contentRangeRegex
//   )
//   if (!match) throw new Error('Invalid Content-Range header')

//   const [, start, end, totalSize] = match
//   return {
//     start: parseInt(start),
//     end: parseInt(end),
//     totalSize: parseInt(totalSize),
//   }
// }

function _uploadChunk(params: UploadChunkParams) {
  const { abortSignal, onUploadProgress, uri, chunk } = params
  const contentRangeHeader = params.contentRange ? {
    'Content-Range': `bytes ${params.contentRange.start}-${params.contentRange.end}/${params.contentRange.totalSize}`,
  } : {}
  return axios.put(
    uri.signedURL,
    chunk,
    {
      signal: abortSignal,
      onUploadProgress,
      headers: {
        'Content-Type': chunk.type,
        ...contentRangeHeader,
        ...uri.headers,
      },
    }
  )
}

/** Axios call to signedUrl with chunked upload and progress updates */
export const uploadToGC = async (
  file: File,
  signedUrl: SignedURLDTO,
  abortSignal: AbortSignal,
  onProgress: (percentCompleted: number) => void,
  onSuccess: () => void,
  onCancel: () => void,
  onError: () => void
) => {
  return _uploadChunk({
    uri: signedUrl,
    chunk: file,
    abortSignal,
    contentRange: null,
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / (progressEvent.total || 1)
      )

      onProgress(percentCompleted)
    },
  })
    .then(() => onSuccess())
    .catch((e) => {
      if (axios.isCancel(e)) {
        onCancel()
      } else {
        onError()
      }
    })
}

// Initiates workers for visual loading
export const startLoadWorker = (() => {

  const workerCount = process.env.NODE_ENV === 'test'
    ? 0
    : navigator.hardwareConcurrency || 4

  const workerPool = [...Array(workerCount).keys()]
    .map(() => {
      const worker = new Worker(
        new URL('workers/loadFileWorker', import.meta.url),
        {
          type: 'module',
        }
      )
      return wrap<loadVisualWorkerType>(worker)
    })

  let i = 0

  /** Calls loadVisualWorker */
  return (loadUrl: UrlDTO) => {
    const result = workerPool[i](loadUrl)
    i = ++i % workerCount
    return result
  }
})()
