/** Enumeration of KeyboardEvent.key string values */
export enum KeyboardEventKey {
  ESCAPE = 'Escape',
  ENTER = 'Enter',
  SPACE = ' ',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_LEFT = 'ArrowLeft',
  TAB = 'Tab',
  BACKSPACE = 'Backspace',
  COMMA = ',',
  SEMICOLON = ';',
}
