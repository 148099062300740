import { usePurchaseFlowConfig, usePurchaseFlowProducts } from '../../_main/contexts'

import { CatalogueProduct } from 'dataQueries/purchase.query'
import DynamicQueryContent from 'components/common/DynamicQueryContent/DynamicQueryContent'
import { FC } from 'react'
import { MUICheckCard } from 'components/common/MUICards'
import { ProductCardHeader } from '../ProductCardHeader'
import { Stack } from '@mui/material'

interface Props {
  onlySelected?: boolean
  disabledSelection?: boolean
  isProductPreview?: boolean
}

export const EnergyProductListing: FC<Props> = ({
  disabledSelection = false,
  isProductPreview = false,
  onlySelected = false,
}) => {
  const { getProductCatalogue, allProducts, catalogueCurrency, selectedCategory } = usePurchaseFlowConfig()
  const { selectProduct, unselectProduct, selectedProducts } = usePurchaseFlowProducts()

  const handleSelect = (productToSelect: CatalogueProduct, willBeSelected: boolean) => {

    // Unselect all other products
    for (const product of Object.values(selectedProducts)) {
      unselectProduct(product.id)
    }

    if (willBeSelected) selectProduct(productToSelect, 1)
  }

  return (
    <DynamicQueryContent
      query={getProductCatalogue}
    >
      <Stack gap={2}>
        {allProducts
          .filter((product) => onlySelected ? !!selectedProducts[product.id] : true)
          .map((product) => (
            <MUICheckCard
              key={product.id}
              isRadio
              showSelectElement={!isProductPreview}
              onCheck={() => !disabledSelection && handleSelect(product, !selectedProducts[product.id])}
              checked={!!selectedProducts[product.id]}
              isHeaderClickable={!disabledSelection}
              disabledSelection={disabledSelection}
            >
              {!!catalogueCurrency && !!selectedCategory &&
                <ProductCardHeader
                  displayPrice={!isProductPreview}
                  product={product}
                  disabled={false}
                  typeKey={product.type}
                  category={selectedCategory}
                  currency={catalogueCurrency}
                  quantity={isProductPreview ? selectedProducts[product.id] ? 1 : 0 : undefined}
                />
              }
            </MUICheckCard>
          ))}

      </Stack>
    </DynamicQueryContent>
  )
}
