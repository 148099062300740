import { RenovationTypes, roomTypeIsRenovationType } from 'models/virtualStaging'
import { VirtualFurniturePropertyType, VirtualFurnitureRoomType } from 'constants/virtualFurniture'

import { VirtualFurnitureStyleDTO } from 'models/virtualFurniture'
import constate from 'constate'
import { useMemo } from 'react'
import { useVirtualFurnitureData } from './VirtualFurnitureData.context'

export const [VirtualFurnitureUpgradesContextProvider, useVirtualFurnitureUpgrades] = constate(() => {

  const { virtualFurnitureStyles } = useVirtualFurnitureData()

  const [virtualFurnitureUpgradesMap, virtualFurnitureUpgradeCategories] = useMemo(() => {
    const roomTypeStyleMap: Map<VirtualFurnitureRoomType, VirtualFurnitureStyleDTO[]> = new Map()
    const upgradeCategories: Set<RenovationTypes> = new Set([])

    // Keeps codes for duplicity check
    const styleCodes: Set<string> = new Set([])

    for (let style of virtualFurnitureStyles) {

      // Only consider Materials, Renovation room types and non duplicate
      if ((style.propertyType !== VirtualFurniturePropertyType.MATERIALS)) continue
      if (!roomTypeIsRenovationType(style.roomType)) continue
      if (styleCodes.has(style.code)) continue

      styleCodes.add(style.code)
      upgradeCategories.add(style.roomType)

      const currentStyles = roomTypeStyleMap.get(style.roomType) || []
      roomTypeStyleMap.set(style.roomType, [...currentStyles, style])
    }

    return [
      roomTypeStyleMap,
      Array.from(upgradeCategories),
    ]
  }, [virtualFurnitureStyles])


  return {
    virtualFurnitureUpgradesMap,
    virtualFurnitureUpgradeCategories,
  }
})
